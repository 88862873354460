import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {FileUpload} from 'primereact/components/fileupload/FileUpload';

import {dropDownWithOrdinal, inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";

export default class AddSystemTemplate extends Component {

    constructor(props) {
        super();

        this.state = {
            template: {
                visibleName: '',
                notes: '',
                createdBy: props.loginIdentity,
                reportType: {label: '', value: ''},
            }
        };

        this.onChange = this.onChange.bind(this);
        this.onBeforeUpload = this.onBeforeUpload.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onBeforeUpload(event) {

    }

    onSelect(event) {

        const template = {...this.state.template};
        template.fileName = event.files[0].name;
        this.setState({template});
    }

    onProgress(event) {

    }

    render() {

        const templateDefined = this.state.template.reportType.value !== '' && this.state.template.name !== '';
        const emptyTemplate = templateDefined ? '' : 'disabled';
        const externalTemplate = this.state.template.fileName !== '' && templateDefined ? '' : 'disabled';
        const origin = this.props.origin;

        const footer = (
            <div>
                <Button label="Create Empty Template"
                        icon={ICON_OK}
                        onClick={()=>{this.props.onSaveDialog(this.state.template)}}
                        disabled={emptyTemplate}/>
                <Button label="Use External Template"
                        icon={ICON_OK}
                        onClick={()=>{this.props.onSaveDialog(this.state.template)}}
                        disabled={externalTemplate}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={()=>{this.props.onHideDialog(origin)}}/>
            </div>
        );

        const props = {
            types: _.orderBy(this.props.reportTypes, 'label', 'asc'),
            onChange: this.onChange,
            target: 'template',
            template: this.state.template,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={this.props.visible}
                    resizable={true}
                    onHide={()=>{this.props.onHideDialog(origin)}}>

                <div id="detailPanel">
                    <Panel header='Document Template Details'>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-5">
                                <label>Document Template Name</label>
                            </div>
                            <div className="p-col-7">
                                {inputText(props, 'visibleName', '', false, true)}
                            </div>
                            <div className="p-col-5">
                                <label>Notes</label>
                            </div>
                            <div className="p-col-7">
                                {inputTextArea(props, 'notes', 5, 30, false, false)}
                            </div>
                            <div className="p-col-5">
                                <label htmlFor="title">Template Type</label>
                            </div>
                            <div className="p-col-7">
                                {dropDownWithOrdinal(0, props, 'types', 'reportType', '', true, false)}
                            </div>
                        </div>
                    </Panel>

                    <Panel header='External Template Details'>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-2">
                                <label>File Name</label>
                            </div>
                            <div className="p-col-7">
                                {inputText(props, 'fileName', '')}
                            </div>
                            <div className="p-col-3">
                                <FileUpload name="demo"
                                            url={'fix-me'}
                                            mode="basic"
                                            auto={true}
                                            onSelect={this.onSelect}
                                            onBeforeUpload={this.onBeforeUpload}
                                            onProgress={this.onProgress}
                                />
                            </div>
                        </div>
                    </Panel>
                </div>
            </Dialog>
        )
    }
};
