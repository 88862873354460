export const APP_TREATMENTS = 0;
export const APP_NOTES = 1;
export const APP_IMAGES = 2;
export const APP_ORTHO = 3;
export const APP_TREATMENT_HISTORY = 4;
export const APP_DOCUMENTS = 5;
export const APP_PATASS = 6;
export const APP_PERIO = 7;
export const APP_NHS_REG = 8;
export const APP_VIDEO_CONF = 9;
export const APP_LAB_HISTORY = 10;
export const APP_MEDICAL_HISTORY = 11;

export const TB_CHECK_IN = {
    id: 'TB_CHECK_IN',
    text: 'Check patient into chair',
    icon: 'fad fa-chair-office'
};

export const TB_CHECK_OUT = {
    id: 'TB_CHECK_OUT',
    text: 'Check patient out of chair',
    icon: 'fas fa-door-open'
};

export const TB_CHECKED_OUT = {
    id: 'TB_CHECKED_OUT',
    text: 'Patient checked out of chair',
    icon: 'far fa-calendar-check'
};
