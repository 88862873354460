import moment from "moment";
import {ac} from "../../index";
import {SM_REFERRER_DATA} from "../stateManagement";
import {RES_REFERRER_DETAILS} from "../findReferrers";

export const saveReferrer = (dispatch, sm, source, clearAfterSaveRD) => {

    if (sm[`${SM_REFERRER_DATA.id}_${source.smRef}`] !== undefined && sm[`${SM_REFERRER_DATA.id}_${source.smRef}`] !== null) {

        const referrerData = {...sm[`${SM_REFERRER_DATA.id}_${source.smRef}`].data.referrerData};

        referrerData.patient = referrerData.patient ? {id: referrerData.patient.id} : null;
        referrerData.provider = referrerData.provider ? {id: referrerData.provider.id} : null

        const registeredOn = moment(referrerData.registeredOn, 'YYYY/MM/DD');

        referrerData.registeredOn = registeredOn.format('YYYY-MM-DD');

        ac.fetchClient().post(`${RES_REFERRER_DETAILS.SAVE.url}?mcId=${ac.getMcId()}`, referrerData)
            .then((res) => {

                if (Boolean(clearAfterSaveRD)) {
                    dispatch({
                        type: RES_REFERRER_DETAILS.CLEAR.action, payload: source.id, smRef: source.smRef
                    });
                } else {
                    dispatch({type: RES_REFERRER_DETAILS.SAVE.action, payload: res.data})
                }
            });
    }
};
