import React from 'react';
import {
    JawLower,
    JawUpper,
    RootFillColor,
    RootUnfilledColor,
    TMApicectomy,
    TMNone,
    TMRetained,
    TMRootCanal
} from "../Model/Constants";
import {addRootExtras, getToothNumber, getTranslate} from "./ChartingUtils";
import {getRandomKey} from "../Utils/general";
import {ToothBaseComponent} from "./ToothBaseComponent";

const LowerRootPoints = [4, 4, 44, 4, 44, 34, 40, 36, 40, 88, 26, 88, 26, 36, 22, 36, 22, 88, 14, 88, 8, 88, 8, 36, 4, 34, 4, 4];

const LowerChippedRootPoints = [14, 4, 44, 4, 44, 34, 40, 36, 40, 88, 26, 88, 26, 36, 22, 36, 22, 88, 14, 88, 8, 88, 8, 36, 4, 34, 4, 20, 14, 4];

const UpperRootPoints = [6, 4, 16, 4, 16, 56, 19, 56, 19, 4, 29, 4, 29, 56, 32, 56, 32, 4, 42, 4, 42, 56, 44, 58, 44, 88, 4, 88, 4, 58, 6, 56, 6, 4];

const UpperChippedRootPoints = [6, 4, 16, 4, 16, 56, 19, 56, 19, 4, 29, 4, 29, 56, 32, 56, 32, 4, 42, 4, 42, 56, 44, 58, 44, 78, 34, 88, 4, 88, 4, 58, 6, 56, 6, 4];

const LowerPostPoints = [4, 24, 44, 24, 44, 34, 40, 36, 40, 88, 26, 88, 26, 36, 22, 36, 22, 88, 14, 88, 8, 88, 8, 36, 4, 34, 4, 24];

const UpperPostPoints = [6, 4, 16, 4, 16, 56, 19, 56, 19, 4, 29, 4, 29, 56, 32, 56, 32, 4, 42, 4, 42, 56, 44, 58, 44, 68, 4, 68, 4, 58, 6, 56, 6, 4];

const CanalPoints = [22, 4, 26, 4, 26, 50, 22, 50, 22, 4];

const LowerRootApiPoints = [4, 4, 44, 4, 44, 34, 40, 36, 40, 58, 26, 58, 26, 36, 22, 36, 22, 58, 14, 58, 8, 58, 8, 36, 4, 34, 4, 4];

const LowerRootApiPostPoints = [4, 24, 44, 24, 44, 34, 40, 36, 40, 58, 26, 58, 26, 36, 22, 36, 22, 58, 14, 58, 8, 58, 8, 36, 4, 34, 4, 24];

const UpperRootApiPoints = [6, 34, 16, 34, 16, 56, 19, 56, 19, 34, 29, 34, 29, 56, 32, 56, 32, 34, 42, 34, 42, 56, 44, 58, 44, 88, 4, 88, 4, 58, 6, 56, 6, 34];

const UpperRootApiPostPoints = [6, 34, 16, 34, 16, 56, 19, 56, 19, 34, 29, 34, 29, 56, 32, 56, 32, 34, 42, 34, 42, 56, 44, 58, 44, 68, 4, 68, 4, 58, 6, 56, 6, 34];

const CanalApiPoints = [22, 4, 26, 4, 26, 20, 22, 20, 22, 4];

const LowerRetainedRootPoints = [44, 34, 40, 36, 40, 88, 26, 88, 26, 36, 22, 36, 22, 88, 14, 88, 8, 88, 8, 36, 4, 34];

const UpperRetainedRootPoints = [6, 4, 16, 4, 16, 56, 19, 56, 19, 4, 29, 4, 29, 56, 32, 56, 32, 4, 42, 4, 42, 56, 44, 58, 4, 58, 6, 56, 6, 4];

export class AdultMolar extends ToothBaseComponent {

    constructor(props) {
        super();

        this.state = {
            cursor: '',
        };

        this.points = [];
        this.canalPoints1 = [];
        this.canalPoints2 = [];
        this.canalPoints3 = [];
        this.fracturePoints = [];
        this.canalFill = RootUnfilledColor;

        this.rootPoints = [];
        this.rootApiPoints = [];
        this.canalPoints = [];
        this.canalApiPoints = [];
        this.retainedRootPoints = [];
        this.postPoints = [];
        this.rootApiPostPoints = [];

        this.translate = '';
        this.labelTranslate = '';
        this.canalTranslate1 = '';
        this.canalTranslate2 = '';
        this.canalTranslate3 = '';
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    setPoints(rootPoints, rootApiPoints, canalPoints, canalApiPoints, retainedRootPoints, postPoints, rootApiPostPoints) {

        this.rootPoints = rootPoints;
        this.rootApiPoints = rootApiPoints;

        this.canalPoints = canalPoints;
        this.canalApiPoints = canalApiPoints;

        this.retainedRootPoints = retainedRootPoints;

        this.postPoints = postPoints;
        this.rootApiPostPoints = rootApiPostPoints;
    };

    addRoot(modifier, post) {

        switch (modifier) {
            case TMRetained :
                this.points = this.retainedRootPoints;
                break;
            case TMNone :
            case TMRootCanal :
                this.points = post ? this.postPoints : this.rootPoints;
                break;
            case TMApicectomy :
                this.points = post ? this.rootApiPostPoints : this.rootApiPoints;
                break;
            default:
                break;
        }
    };

    addCanal(modifier) {

        switch (modifier) {
            case TMRetained :
            case TMNone :
            case TMRootCanal :
                switch (this.props.data.jaw) {
                    case JawLower:
                        this.canalPoints1 = this.canalPoints;
                        this.canalPoints2 = this.canalPoints;
                        break;
                    case JawUpper:
                        this.canalPoints1 = this.canalPoints;
                        this.canalPoints2 = this.canalPoints;
                        this.canalPoints3 = this.canalPoints;
                        break;
                    default:
                        break;
                }
                break;
            case TMApicectomy :
                switch (this.props.data.jaw) {
                    case JawLower:
                        this.canalPoints1 = this.canalApiPoints;
                        this.canalPoints2 = this.canalApiPoints;
                        break;
                    case JawUpper:
                        this.canalPoints1 = this.canalApiPoints;
                        this.canalPoints2 = this.canalApiPoints;
                        this.canalPoints3 = this.canalApiPoints;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        this.canalFill = modifier === TMRootCanal ? RootFillColor : RootUnfilledColor;
    }

    render() {

        const data = this.props.data;

        switch (this.props.data.jaw) {
            case JawUpper:
                if (this.props.data.chipped)
                    this.setPoints(UpperChippedRootPoints, UpperRootApiPoints, CanalPoints, CanalApiPoints, UpperRetainedRootPoints, UpperPostPoints, UpperRootApiPostPoints);
                else
                    this.setPoints(UpperRootPoints, UpperRootApiPoints, CanalPoints, CanalApiPoints, UpperRetainedRootPoints, UpperPostPoints, UpperRootApiPostPoints);
                break;
            case JawLower:
                if (this.props.data.chipped)
                    this.setPoints(LowerChippedRootPoints, LowerRootApiPoints, CanalPoints, CanalApiPoints, LowerRetainedRootPoints, LowerPostPoints, LowerRootApiPostPoints);
                else
                    this.setPoints(LowerRootPoints, LowerRootApiPoints, CanalPoints, CanalApiPoints, LowerRetainedRootPoints, LowerPostPoints, LowerRootApiPostPoints);
                break;
            default:
                break;
        }

        this.translate = getTranslate(data, 1, 0, 1, 57, false);
        this.labelTranslate = getTranslate(data, 15, 78, 15, 77, false);
        this.canalTranslate1 = getTranslate(data, -12, 0, -8, 95, true);
        this.canalTranslate2 = getTranslate(data, 1, 0, 10, 95, true);
        this.canalTranslate3 = getTranslate(data, 14, 0, -5, 0, true);

        this.addRoot(data.tm, data.post.present || data.postAndCore.present);
        this.addCanal(data.tm);

        const bgColour = this.props.data.coreMaterial === undefined ? RootUnfilledColor : '';

        switch (this.props.data.jaw) {
            case JawLower:
                return (
                    <g key={getRandomKey()}

                       onContextMenu={(event) => {

                           event.preventDefault();

                           if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
                               this.props.data.currentCommand.setTarget(this.props.data);
                           }
                           this.props.setCurrentItem(this.props.data);
                           this.props.cm(event);
                       }}
                       onMouseEnter={() => this.handleEnter()}
                       onMouseLeave={() => this.handleLeave()}

                       style={{cursor: this.state.cursor}}

                       onClick={() => {
                           if (this.props.data.currentCommand != null && this.props.data.currentCommand.canExecute(this))
                               this.props.onClick(this.props.data);
                       }}
                    >
                        <polygon
                            points={this.points}
                            style={{fill: bgColour, strokeWidth: '1px', stroke: 'black'}}
                            transform={this.translate}
                        />
                        <polygon
                            points={this.canalPoints1}
                            style={{fill: this.canalFill, strokeWidth: '1px', stroke: 'black'}}
                            transform={this.canalTranslate1}
                        />
                        <polygon
                            points={this.canalPoints2}
                            style={{fill: this.canalFill, strokeWidth: '1px', stroke: 'black'}}
                            transform={this.canalTranslate2}
                        />

                        {addRootExtras(this.props.data)}

                        <text x={0} y={0} fill={'black'} fontSize={10}
                              transform={this.labelTranslate}>{getToothNumber(data)}</text>
                    </g>
                );

            case JawUpper:
                return (
                    <g key={getRandomKey()}

                       onContextMenu={(event) => {

                           event.preventDefault();

                           if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
                               this.props.data.currentCommand.setTarget(this.props.data);
                           }
                           this.props.setCurrentItem(this.props.data);
                           this.props.cm(event);
                       }}
                       onMouseEnter={() => this.handleEnter()}
                       onMouseLeave={() => this.handleLeave()}

                       style={{cursor: this.state.cursor}}

                       onClick={() => {
                           if (this.props.data.currentCommand != null && this.props.data.currentCommand.canExecute(this))
                               this.props.onClick(this.props.data);
                       }}
                    >
                        <polygon
                            points={this.points}
                            style={{fill: bgColour, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                            transform={this.translate}
                        />
                        <polygon
                            points={this.canalPoints1}
                            style={{fill: this.canalFill, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                            transform={this.canalTranslate1}
                        />
                        <polygon
                            points={this.canalPoints2}
                            style={{fill: this.canalFill, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                            transform={this.canalTranslate2}
                        />
                        <polygon
                            points={this.canalPoints3}
                            style={{fill: this.canalFill, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                            transform={this.canalTranslate3}
                        />

                        {addRootExtras(this.props.data)}

                        <text x={0} y={0} fill={'black'} fontSize={10}
                              transform={this.labelTranslate}>{getToothNumber(data)}</text>
                    </g>
                );
            default:
                break;
        }
    }
}