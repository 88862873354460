import React from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {password} from "../../../PatientDynamicItems/OnChangeUtils";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {HM_PaymentFilePassword} from "../../../../Constants";

export const UpdatePaymentPassword = (props) => {

    const footer = (
        <div>
            <Button label="OK"
                    icon={ICON_OK}
                    onClick={() => {
                        props.onOKDialog()
                    }}
            />
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(HM_PaymentFilePassword.id)
                    }}/>
        </div>
    );

    return (
        <Dialog header={HM_PaymentFilePassword.header}
                style={{width: '25%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(HM_PaymentFilePassword.id)
                }}>
            <div className="p-grid p-fluid p-col-12 form-group">
                <div className="p-col-2">
                    <label htmlFor="pin">Password</label>
                </div>
                <div className="p-col-10">
                    {password(props, 'password', '', false, true)}
                </div>
            </div>
        </Dialog>
    )
};
