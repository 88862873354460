import React from 'react';
import {connect} from "react-redux";
import {setState, SM_PATIENT_XRAY_RECORDS} from "../../../../actions/stateManagement";
import {
    HM_AddPatientXRAYRecord,
    HM_DeletePatientXRAYRecord,
    HM_EditPatientXRAYRecord,
    HM_notImplemented,
    HM_PatientXrayHistory
} from "../../../../Constants";
import {BaseComponent} from "../../../BaseComponent";

import {ShowMessageDialog, ShowQuestionDialog} from "../../Diary/components/EventComponent";
import {ProgressBar} from "primereact/progressbar";
import _ from "lodash";
import {Button} from "primereact/components/button/Button";
import {UPDATE_TAB} from "../../../../actions";
import AddPatientXRAYRecord from "../../AppointmentDetails/Dialogs/AddPatientXRAYRecord";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {dateTemplateVeryShort} from "../../../PatientDynamicItems/Utils";
import {deleteXRAYRecord, getResource, RES_PATIENT_XRAY_RECORDS} from "../../../../actions/personal";
import {getResource as getCatResource, RES_PAT_CATEGORIES} from "../../../../actions/categories";

class ConnectedXrayHistorySection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.xrayRecordsId,

                xrayRecordsLoaded: false,
                xrayRecords: [],

                newRecordId: -100,

                selection: null,
                first: 0,
                rows: 5,
            };

            this.showDialogs = this.showDialogs.bind(this);
            this.onChange = this.onChange.bind(this);

            this.toolbarCallbacks = {

                [HM_notImplemented.id]: this.onNotImplemented,
            }
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getPatientCategories();
            this.props.getXRAYRecords(this.props.patientId, this.props.appointmentId)
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_XRAY_RECORDS.GET.receive:
                case RES_PATIENT_XRAY_RECORDS.DELETE.action:

                    this.setState({xrayRecords: this.props.xrayRecords, xrayRecordsLoaded: true}, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                    });
                    break;

                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {
            case 'patientXRAYRecord.type' :
            case 'patientXRAYRecord.rating' :
                _.set(state, event.owner, event.value.ordinal);
                break;
            default:
                _.set(state, event.owner, event.value);
                break;
        }
        this.setState(state);
    }

    onSelectionChange = (e) => {

        this.setState({selection: e.value});
    };

    addNewPatientXRAYRecord = (patientXRAYRecord) => {

        patientXRAYRecord.id = this.state.newRecordId;
        patientXRAYRecord.patient = {id: this.props.treatmentPlan.patient.id};

        const xrayRecords = [...this.state.xrayRecords];
        xrayRecords.unshift(patientXRAYRecord);

        this.setState({
            xrayRecords,
            [HM_AddPatientXRAYRecord.id]: false,
            newRecordId: this.state.newRecordId + 1
        }, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
        });
    }

    updatePatientXRAYRecord = (updatedPatientXRAYRecord) => {

        updatedPatientXRAYRecord.edited = true;

        const xrayRecords = [...this.state.xrayRecords];

        const index = _.findIndex(xrayRecords, record => record.id === updatedPatientXRAYRecord);
        xrayRecords[index] = updatedPatientXRAYRecord;

        this.setState({xrayRecords, [HM_EditPatientXRAYRecord.id]: false}, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
        });
    }

    onDeletePatientXRAYRecord = () => {
        this.onHideMenuEntry(HM_DeletePatientXRAYRecord.id);
        this.props.deleteXRAYRecord(this.props.appointmentId, this.state.selection.id);
    }

    showDialogs() {

        const content = [];

        if (this.state[HM_AddPatientXRAYRecord.id]) {
            content.push(
                <AddPatientXRAYRecord loginIdentity={this.props.loginIdentity}
                                      patId={this.props.patId}
                                      header={HM_AddPatientXRAYRecord.header}
                                      editing={false}
                                      onOkDialog={this.addNewPatientXRAYRecord}
                                      onHideDialog={this.onHideMenuEntry}
                                      onChange={this.props.onChange}
                                      usersShort={this.props.usersShort}
                                      categories={this.props.categories}
                />
            );
        } else if (this.state[HM_EditPatientXRAYRecord.id]) {
            content.push(
                <AddPatientXRAYRecord loginIdentity={this.props.loginIdentity}
                                      patId={this.props.patId}
                                      header={HM_EditPatientXRAYRecord.header}
                                      editing={true}
                                      record={this.state.selection}
                                      onOkDialog={this.updatePatientXRAYRecord}
                                      onHideDialog={this.onHideMenuEntry}
                                      onChange={this.props.onChange}
                                      usersShort={this.props.usersShort}
                                      categories={this.props.categories}
                />
            );
        } else {
            content.push(ShowQuestionDialog(this, HM_DeletePatientXRAYRecord, this.onDeletePatientXRAYRecord));
            content.push(ShowMessageDialog(this, HM_notImplemented));
        }
        return content;
    }

    buildMenuItems = () => {

        if (this.props.selection === null) {
            return [];
        }
        const items = [];

        items.push({
            label: HM_EditPatientXRAYRecord.label,
            icon: HM_EditPatientXRAYRecord.icon,
            command: (e) => this.setState({[HM_EditPatientXRAYRecord.id]: true}),
        });

        items.push({
            label: HM_DeletePatientXRAYRecord.label,
            icon: HM_DeletePatientXRAYRecord.icon,
            command: (e) => this.setState({[HM_DeletePatientXRAYRecord.id]: true}),
        });
        return items;
    }

    providerTemplate(props, row) {
        return _.find(props.usersShort, userShort => userShort.id === row.takenBy.id).fullName;
    };

    render() {

        if (!this.state.xrayRecordsLoaded || !this.props.categoriesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        let cm = null;
        const items = this.buildMenuItems();

        const header = (
            <div className='panel-header-centered-content'><label
                id='panel-header'>{HM_PatientXrayHistory.header}</label>
                <Button tooltip={HM_PatientXrayHistory.message}
                        tooltipOptions={{position: 'right'}}
                        icon={HM_PatientXrayHistory.icon}
                        onClick={(e) => {
                            this.setState({[HM_AddPatientXRAYRecord.id]: true})
                        }}>
                </Button>
            </div>
        );

        return (
            <React.Fragment>
                {this.showDialogs()}

                <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

                <DataTable value={this.state.xrayRecords}
                           header={header}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}
                           selection={this.state.selection}
                           onSelectionChange={this.onSelectionChange}
                           contextMenuSelection={this.state.selection}
                           onContextMenuSelectionChange={this.onSelectionChange}
                           onContextMenu={e => cm.show(e.originalEvent)}
                >
                    <Column body={row => dateTemplateVeryShort(row.takenOn)}
                            header="Taken On"
                            style={{width: '20%'}}/>
                    <Column body={row => this.providerTemplate(this.props, row)}
                            header="Taken By"
                            style={{width: '30%'}}/>
                    <Column field='type'
                            header="Type"
                            style={{width: '20%'}}/>
                    <Column field='rating'
                            header="Rating"
                            style={{width: '20%'}}/>
                    <Column field='noOfRepeats'
                            header="Repeats"
                            style={{width: '10%', textAlign: 'right'}}/>
                </DataTable>
            </React.Fragment>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const xrayRecordsLoadedId = `${SM_PATIENT_XRAY_RECORDS.loaded}_${ownProps.appointmentId}`;
    const xrayRecordsId = `${SM_PATIENT_XRAY_RECORDS.id}_${ownProps.appointmentId}`;

    let xrayRecordsLoaded = state.patients[xrayRecordsLoadedId];
    xrayRecordsLoaded = xrayRecordsLoaded === undefined ? false : xrayRecordsLoaded;

    const patientId = ownProps.treatmentPlan.patient.id;

    return {
        message: state.stateManagement.message,

        patientId,
        xrayRecordsLoaded,
        xrayRecordsId,
        xrayRecords: state.patients[xrayRecordsId],

        categoriesLoaded: state.pac.categoriesLoaded,
        categories: state.pac.categories.categories,

        currentState: state.stateManagement[xrayRecordsId],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getXRAYRecords: (patientId, appointmentId) => dispatch(getResource(RES_PATIENT_XRAY_RECORDS.GET, {
            patientId,
            appointmentId
        })),
        deleteXRAYRecord: (appointmentId, recordId) => dispatch(deleteXRAYRecord(RES_PATIENT_XRAY_RECORDS.DELETE, appointmentId, recordId)),
        getPatientCategories: () => dispatch(getCatResource(RES_PAT_CATEGORIES.GET)),

        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const XrayHistorySection = connect(MapStateToProps, MapDispatchToProps)(ConnectedXrayHistorySection);

export default XrayHistorySection;

