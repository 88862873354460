import React, {Component} from 'react';
import _ from 'lodash';
import {
    PALETTE_GRP_APP,
    PALETTE_GRP_DETS,
    PALETTE_GRP_DTSK,
    PALETTE_GRP_MATM,
    PALETTE_GRP_OATM,
    PALETTE_GRP_RAPS,
    PALETTE_GRP_SMSIB,
    PALETTE_GRP_USPR,
    PALETTE_NORMAL_APP,
    PALETTE_ZONE_APP
} from "../Constants";
import {UnscheduledProvider} from "../components/UnscheduledProvider";
import {DiaryTaskComponent} from "../components/DiaryTaskComponent";
import {PaletteDiaryEventComponent} from "../components/PaletteDiaryEventComponent";
import {PaletteMovingComponent} from "../components/PaletteMovingComponent";
import {PaletteAppointmentComponent} from "../components/PaletteAppointmentComponent";
import {PaletteBaseComponent} from "../components/PaletteBaseComponent";
import {ScrollPanel} from "primereact/scrollpanel";
import {COLOR_ChiralPalette} from "../../../Client/Constants";
import {ICON_ZONING_ON} from "../../../../icons";

export class DiaryPalette extends Component {

    constructor(props) {
        super(props);

        this.state = {
            diaryPaletteGroups: [
                {
                    label: 'Appointments',
                    open: true,
                    type: PALETTE_GRP_APP,
                    contents: [
                        {
                            id: PALETTE_NORMAL_APP,
                            label: 'New Appointment',
                            icon: 'fas fa-calendar-plus',
                            paletteType: PALETTE_GRP_APP
                        },
                        {
                            id: PALETTE_ZONE_APP,
                            label: 'Snap to Zones',
                            icon: ICON_ZONING_ON,
                            paletteType: PALETTE_GRP_APP
                        },
                    ]
                },
                {
                    label: 'Diary Events',
                    open: false,
                    type: PALETTE_GRP_DETS,
                    contents: []
                },
                {
                    label: 'My appointments to move',
                    open: false,
                    type: PALETTE_GRP_MATM,
                    contents: []
                },
                {
                    label: 'Other user appointments to move',
                    open: false,
                    type: PALETTE_GRP_OATM,
                    contents: []
                },
                {
                    label: 'My recall appointments',
                    open: false,
                    type: PALETTE_GRP_RAPS,
                    contents: []
                },
                {
                    label: 'Diary Tasks',
                    open: false,
                    type: PALETTE_GRP_DTSK,
                    contents: []
                },
                {
                    label: 'SMS Inbox',
                    open: false,
                    type: PALETTE_GRP_SMSIB,
                    contents: []
                },
                {
                    label: 'Unscheduled Clinicians',
                    open: true,
                    type: PALETTE_GRP_USPR,
                    contents: []
                },
            ],
            newAppointment: false,

            diaryEvents: [...props.diaryEvents],

            selectedDiaryEvent: null,
        };

        // set the palette type for all diary events
        this.state.diaryEvents.forEach(det => det.paletteType = PALETTE_GRP_DETS);

        this.paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps !== this.props) {
            this.setState({state: {...this.state}});
        }
    }

    changeGroupState = (group) => {

        const diaryPaletteGroups = _.cloneDeep(this.state.diaryPaletteGroups);

        let thisGroup = _.find(diaryPaletteGroups, (target) => {
            return group.label === target.label;
        });
        thisGroup.open = !thisGroup.open;

        this.setState({diaryPaletteGroups});
    }

    insertTasksContent(group, diaryTasks) {

        const results = [];
        diaryTasks.forEach((diaryTask) => {

            results.push(
                <DiaryTaskComponent diaryTask={diaryTask}
                                    selectedItem={this.selectedItem}
                                    onItemSelected={(item) => {
                                        this.selectedItem = item.id;
                                        this.props.onEventSelected(item);
                                    }}
                                    key={`itc_${diaryTask.id}`}
                                    onShowRightClickTasks={this.props.onShowRightClickTasks}
                />
            )
        });
        return results;
    }

    insertDiaryTasks(group, diaryTasks) {

        const results = [];
        let key = 1;

        if (group.open) {

            results.push(
                <ScrollPanel style={{
                    border: 1,
                    width: '100%',
                    height: '300px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'gray'
                }}
                             className='custom'
                             key={`idt_${key++}`}
                >
                    {this.insertTasksContent(group, diaryTasks)}
                </ScrollPanel>
            )
        }
        return results;
    }

    insertUnscheduledProvidersContent(resources) {

        const results = [];

        const unscheduledResources = [...resources];

        unscheduledResources.forEach((unscheduledResource) => {

            results.push(
                <UnscheduledProvider unscheduledResource={unscheduledResource}
                                     selectedItem={this.selectedItem}
                                     onItemSelected={(item) => {
                                         this.selectedItem = item.id;
                                         this.props.onEventSelected(item);
                                     }}
                                     key={`un_${unscheduledResource.username}`}
                                     onShowRightClickProviders={this.props.onShowRightClickProviders}
                />
            )
        });
        return results;
    }

    insertUnscheduledProviders(group, resources) {

        let key = Math.floor(Math.random() * 1000);

        const results = [];

        if (group.open) {

            results.push(
                <ScrollPanel style={{
                    border: 1,
                    width: '100%',
                    height: '300px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'gray'
                }}
                             key={`iup_${key++}`}
                             id={group.id}
                             className='custom'
                >
                    {this.insertUnscheduledProvidersContent(resources)}
                </ScrollPanel>
            )
        }
        return results;
    }

    insertMoveContent(group, movingEvents) {

        const results = [];

        movingEvents.forEach((moveEvent) => {

            results.push(
                <PaletteMovingComponent moveEvent={moveEvent}
                                        selectedItem={this.selectedItem}
                                        onItemSelected={(item) => {
                                            this.selectedItem = item.id;
                                            this.props.onEventSelected(item);
                                        }}
                                        key={`imc_${moveEvent.id}`}
                                        onShowRightClickMoves={this.props.onShowRightClickMoves}
                                        usersShort={this.props.usersShort}
                />
            )
        });
        return results;
    }

    insertMoveEvents = (group, movingEvents) => {

        const results = [];
        let key = 1;

        if (group.open) {

            results.push(
                <ScrollPanel style={{
                    border: 1,
                    width: '100%',
                    height: '300px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'gray'
                }} className='custom'
                             key={`ime_${key++}`}
                >
                    {this.insertMoveContent(group, movingEvents)}
                </ScrollPanel>
            )
        }
        return results;
    }

    insertDiaryContent() {

        const results = [];

        this.state.diaryEvents.forEach((diaryEvent) => {

            if (!diaryEvent.archived) {
                results.push(
                    <PaletteDiaryEventComponent diaryEvent={diaryEvent}
                                                selectedItem={this.selectedItem}
                                                onItemSelected={(item) => {
                                                    this.selectedItem = item.id;
                                                    this.props.onEventSelected(item);
                                                }}
                                                key={`idc_${diaryEvent.id}`}
                    />
                )
            }
        });
        return results;
    };

    insertDiaryEvents = (group) => {

        const results = [];
        let key = 1;

        if (group.open) {

            results.push(
                <ScrollPanel style={{
                    border: 1,
                    width: '100%',
                    height: '300px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'gray'
                }}
                             className='custom'
                             key={`ide_${key++}`}>
                    {this.insertDiaryContent(group)}
                </ScrollPanel>
            )
        }
        return results;
    };

    buildPalette = () => {

        let results = [];
        let key = Math.floor(Math.random() * 1000);

        this.state.diaryPaletteGroups.forEach((group) => {

            const icon = group.open ? 'fas fa-folder-open' : 'fas fa-folder';

            let groupContent = [];
            let movingEvents = [];
            let groupCount = 0;

            switch (group.type) {
                case PALETTE_GRP_DETS:

                    groupContent = this.insertDiaryEvents(group);
                    break;
                case PALETTE_GRP_MATM:

                    movingEvents = this.props.movingEvents.filter(event => event.updatedBy === this.props.userId);
                    groupCount = movingEvents.length;
                    groupContent = this.insertMoveEvents(group, movingEvents);
                    break;
                case PALETTE_GRP_OATM:

                    movingEvents = this.props.movingEvents.filter(event => event.updatedBy !== this.props.userId);
                    groupCount = movingEvents.length;
                    groupContent = this.insertMoveEvents(group, movingEvents);
                    break;
                case PALETTE_GRP_RAPS:

                    groupCount = this.props.recallEvents.length;
                    groupContent = this.insertMoveEvents(group, this.props.recallEvents);
                    break;
                case PALETTE_GRP_DTSK:

                    groupCount = this.props.diaryTasks.length;
                    groupContent = this.insertDiaryTasks(group, this.props.diaryTasks);
                    break;
                case PALETTE_GRP_USPR:

                    groupCount = this.props.unscheduledResources === undefined ? 0 : this.props.unscheduledResources.length;
                    groupContent = this.insertUnscheduledProviders(group, this.props.unscheduledResources === undefined ? [] : this.props.unscheduledResources);
                    break;
                default:
                    if (group.open) {
                        group.contents.forEach((menuItem, index) => {

                            groupContent.push(
                                <PaletteAppointmentComponent menuItem={menuItem}
                                                             selectedItem={this.selectedItem}
                                                             onItemSelected={(item) => {
                                                                 this.selectedItem = item.id;
                                                                 this.props.onEventSelected(item);
                                                             }}
                                                             key={`pac_${index}`}/>
                            )
                        });
                    }
            }

            results.push(
                <div key={`bp_${key++}`}
                     style={{backgroundColor: '#f5f5f5', borderTop: '1px solid grey', borderBottom: '1px dotted grey'}}
                     onClick={(item) => {
                         this.changeGroupState(group);
                     }}
                >
                    <span className={icon}
                          style={{fontSize: '20px', color: COLOR_ChiralPalette, marginRight: '5px', marginTop: '5px'}}/>

                    {(groupCount === 0 || group.open) ? group.label : `${group.label}+`}
                </div>
            );
            results = [...results, ...groupContent];
        });
        return results;
    };

    render() {

        let key = Math.floor(Math.random() * 1000);

        return (
            <PaletteBaseComponent key={`key_${key}`}
                                  buildPalette={this.buildPalette}
                                  onItemSelected={(item) => {
                                      this.selectedItem = null;
                                      this.props.onEventSelected(item);
                                  }}
            />
        )
    };
}