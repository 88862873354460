import {AdultPreMolar} from "../Parts/AdultPreMolar";
import {AdultIncisor} from "../Parts/AdultIncisor";
import {AdultMolar} from "../Parts/AdultMolar";
import {AdultPreMolarBicusp} from "../Parts/AdultPreMolarBicusp";
import {_getCommandContextMenuItems} from "../Parts/ChartingUtils";
import * as TC from "../Model/Constants";
import {CrownOnImplant} from "../Model/Constants";
import {PlaceHolder} from "../PlaceHolder";
import {ChartItemBase} from "./ChartItemBase";

export class ComCrownOnImplant extends ChartItemBase {

    constructor() {
        super();

        this.state = {};
        this.target = null;
        this.mouth = null;
        this.target = null;
        this.material = null;

        this.setTarget = this.setTarget.bind(this);
        this.getCommandContextMenuItems = this.getCommandContextMenuItems.bind(this);
        this.executeTreatment = this.executeTreatment.bind(this);
        this.executeTreatmentOption = this.executeTreatmentOption.bind(this);
    };

    toString() {
        return CrownOnImplant;
    }

    canExecute(target) {

        try {

            return (target instanceof AdultIncisor) ||
                (target instanceof AdultMolar) ||
                (target instanceof AdultPreMolar) ||
                (target instanceof AdultPreMolarBicusp) ||
                (target instanceof PlaceHolder);
        } catch (e) {
            return false;
        }
    }

    executeBase(mouth, target) {
    }

    executeTreatment(mouth, target) {
    }

    executeBaseOption(mouth, target, material) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, TC.CrownOnImplant, true);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;
        entry.material = material;

        mouth.props.addChartEntry(entry);
    }

    executeTreatmentOption(mouth, target, material) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, TC.CrownOnImplant, false);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;
        entry.material = material;

        mouth.props.addTreatmentHandler(entry);
    }

    setTarget(target) {
        this.target = target;
    }

    getCommandContextMenuItems(mouth) {
        return _getCommandContextMenuItems(mouth, this)
    }

    clear() {
    }
}
