import React from "react";

import {Panel} from 'primereact/components/panel/Panel';

import {SM_REFERRER_ADD, SM_REFERRER_DATA, SM_REFERRER_PATIENT_DATA} from "../../../actions/stateManagement";
import {dropDown, inputText} from "../../PatientDynamicItems/OnChangeUtils";

export const PERSONAL = 0;
export const REFERRED_PATIENTS = 1;

export const TB_REFERRER_DETAILS = {id: SM_REFERRER_DATA.id, text: SM_REFERRER_DATA.label, icon: SM_REFERRER_DATA.tabIcon};
export const TB_REFERRER_PATIENT = {id: SM_REFERRER_PATIENT_DATA.id, text: SM_REFERRER_PATIENT_DATA.label, icon: SM_REFERRER_PATIENT_DATA.tabIcon};
export const TB_REFERRER_ADD = {id: SM_REFERRER_ADD.id, text: SM_REFERRER_ADD.label, icon: SM_REFERRER_ADD.tabIcon};


export const Name = (props) => {

    return (
        <Panel header='Name'>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-3">
                    <label htmlFor="title">Title</label>
                </div>
                <div className="p-col-9">
                    {dropDown(props, 'titles', 'title', 'abbreviation', props.mandatory)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="firstName">First Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'firstName', 'First Name', false, props.mandatory)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="lastName">Last Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'lastName', 'Last Name', false, props.mandatory)}
                </div>
            </div>
        </Panel>
    )
};
