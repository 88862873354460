import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';

import StackedSection from './Sections/Stacked';

import {
    HELP_REP_NHS_MANAGEMENT,
    setState,
    SM_NHS_MANAGEMENT,
    SM_NHS_MANAGEMENT_REJECTED,
    SM_NHS_MANAGEMENT_REMOVED,
    SM_NHS_MANAGEMENT_SCHEDULED,
    SM_NHS_MANAGEMENT_STACKED,
    SM_NHS_MANAGEMENT_SUBMITTED
} from "../../../actions/stateManagement";
import * as Sections from "./Constants";
import {
    EDI_DATE_BACK,
    EDI_DATE_FORWARD,
    EDI_DATE_TODAY,
    EDI_REJECTED,
    EDI_REMOVED,
    EDI_SCHEDULED,
    EDI_ST_Deleted,
    EDI_ST_Stacked,
    EDI_STACKED,
    EDI_SUBMITTED
} from "./Constants";
import SubmittedSection from "./Sections/Submitted";
import RejectedSection from "./Sections/Rejected";
import RemovedSection from "./Sections/Removed";
import ScheduledSection from "./Sections/Scheduled";
import {connect} from "react-redux";
import {
    HM_ClaimNotFound,
    HM_ClaimSearchEdit, HM_DeleteClaim,
    HM_EditClaim,
    HM_notImplemented, HM_PerformerPinValidation,
    HM_ResubmitClaim,
    HM_SubmitClaim,
    TB_SAVE,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PRINT, ICON_SCREEN,} from "../../../icons";
import {TAB_EXIT} from "../Housekeeping/Constants";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {TabBaseComponent} from "../../TabBaseComponent";
import NHSManagementErrorBoundary from "./Utils";
import {tb_boilerPlate2, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_MONTH, DIARY_FOR_MONTH, DIARY_TODAY} from "../Diary/Constants";
import {TextNHSManagement} from "../../../Messages";
import {
    deleteClaim,
    getResource,
    RES_NHS_MANAGEMENT_CLAIM,
    RES_NHS_MANAGEMENT_CLAIMS, resubmitClaim,
    saveClaim
} from "../../../actions/nhsManagement";
import {ClaimSearch} from "./dialogs/ClaimSearch";
import {TB_USER_UDA_UOA_DETAILS} from "../UserDetails/Constants";
import EditClaim from "./dialogs/EditClaim";
import {ETH_GROUP} from "../../PatientDynamicItems/NHSDetails";
import {Band} from "../../../NHSConstants";
import {SubmitClaim} from "./dialogs/SubmitClaim";
import {DeleteClaim} from "./dialogs/DeleteClaim";

class ConnectedNHSManagement extends TabBaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_NHS_MANAGEMENT.id,

                canSave: {status: false, activeIndex: 0, source: RES_NHS_MANAGEMENT_CLAIMS.CLEAR},
                activeIndex: EDI_STACKED,
                showSaveDialog: false,

                removedDate: new Date(),
                scheduledDate: new Date(),

                claimSearch: {number: ''},
                claimDAO: null,

                provider: {providerPIN: ''},

                submitClaimLoaded: false,
                submitClaim: null,

                pinValid: false,

                [HM_ClaimSearchEdit.id]: false,
                [HM_EditClaim.id]: false,
                [HM_SubmitClaim.id]: false,
                [TB_USER_UDA_UOA_DETAILS.id]: false,
            }
        }

        this.onTabChange = this.onTabChange.bind(this);
        this.setDate = this.setDate.bind(this);

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onClose,
        }
    }

    componentDidMount() {

        const index = _.findIndex(this.tabStack, tab => tab.id === this.props.defaultTab);
        const defaultTabIndex = index === -1 ? 0 : index;
        const canSave = {...this.state.canSave, activeIndex: defaultTabIndex};
        this.setState({canSave});
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {

            switch (this.props.message.type) {

                case RES_NHS_MANAGEMENT_CLAIMS.FIND_BY_REF.receive:

                    if (this.props.claimDAO) {
                        this.setState({
                            [HM_EditClaim.id]: true, allowSubmit: true,
                        })
                    } else {
                        this.setState({
                            [HM_ClaimNotFound.id]: true,
                        })
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onClose() {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true}, () => {
                this.props.setState(this.state.stateManagementId, this.state);
            })
        } else {
            this.onExit(false);
        }
    }

    onExit = (key) => {

        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: SM_NHS_MANAGEMENT.id});
    }

    onTabChange(index) {

        switch (index) {
            case EDI_STACKED:
                break;
            case EDI_SUBMITTED:
                break;
            case EDI_REJECTED:
                break;
            case EDI_REMOVED:
                break;
            case EDI_SCHEDULED:
                break;
            default:
                break;
        }
        this.setState({activeIndex: index}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
        });
    }

    _setDate(date, action) {
        switch (action) {
            case EDI_DATE_BACK :
                return new Date(date.setMonth(date.getMonth() - 1));
            case EDI_DATE_TODAY:
                return new Date();
            case EDI_DATE_FORWARD:
                return new Date(date.setMonth(date.getMonth() + 1));
            default:
                break;
        }
    };

    setDate(action) {

        switch (this.state.activeIndex) {
            case EDI_REMOVED:
                const removedDate = this._setDate(this.state.removedDate, action);
                this.setState({removedDate});
                break;
            case EDI_SCHEDULED:
                const scheduledDate = this._setDate(this.state.scheduledDate, action);
                this.setState({scheduledDate});
                break;
            default :
                break;
        }
    }

    onSearchChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onSearchCancel = () => {

        this.setState({[HM_ClaimSearchEdit.id]: false, claimSearch: {number: ''}});
    }

    onClaimSearch = () => {

        const claimNumber = this.state.claimSearch.number;

        this.setState({[HM_ClaimSearchEdit.id]: false, claimSearch: {number: ''}}, () => {
            this.props.claimSearch(claimNumber);
        });
    }

    updateClaim = (editedClaim, NHSRegistration, patient, claimAction, resubmit) => {

        const claim = {...editedClaim};
        claim.patientNHSDetails = {...NHSRegistration};
        claim.patientNHSDetails.patient = {id: claim.patientNHSDetails.patient.id};
        claim.patientNHSDetails.ethnicGroup = _.find(ETH_GROUP, group => group.code === claim.patientNHSDetails.ethnicGroup.code).value;
        claim.completedBand = _.find(Band, band => band.description === claim.completedBand.description).id;
        claim.incompletedBand = _.find(Band, band => band.description === claim.incompletedBand.description).id;
        claim.performer = {id: claim.performer.id};
        claim.responseCode = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        claim.SQInd = claimAction.SQInd;

        const details = {
            patient,
            claim,
        }

        switch (claim.status) {
            case EDI_ST_Stacked:
                break;
            default:
                if (!resubmit) {
                    claim.status = EDI_ST_Deleted;
                }
                break;
        }

        if (resubmit) {
            this.setState({[HM_ResubmitClaim.id]: true, [HM_EditClaim.id]: false, claim: editedClaim}, () => {
                this.props.saveClaim(details)
            })
        } else {
            this.setState({[HM_EditClaim.id]: false}, () => {
                this.props.saveClaim(details)
            })
        }
    }

    deleteClaim = (editedClaim, NHSRegistration, patient, claimAction, resubmit) => {
        this.setState({[HM_DeleteClaim.id]: true, [HM_EditClaim.id]: false, selectedClaim: editedClaim})
    }

    onDeleteClaim = () => {

        if (this.state.selectedClaim.performer.providerPIN !== this.state.selectedClaim.providerPIN) {
            this.setState({[HM_DeleteClaim.id]: false, [HM_PerformerPinValidation.id]: true});
        } else {

            const deletedClaim = {...this.state.selectedClaim};

            const details = {
                performerPIN: deletedClaim.providerPIN,
                claimDAO: {id: deletedClaim.id, SQInd: true},
                narrative: deletedClaim.narrative
            };
            this.setState({[HM_DeleteClaim.id]: false, pinValid: true, selectedClaim: null}, () => {

                this.props.deleteClaim(details);
            });
        }
    }

    onResubmitClaim = () => {

        if (this.state.claim.performer.providerPIN !== this.state.provider.providerPIN) {
            this.setState({[HM_ResubmitClaim.id]: false, [HM_PerformerPinValidation.id]: true});
        } else {
            const claim = {id: this.state.claim.id, SQInd: this.state.claim.SQInd};
            this.setState({[HM_ResubmitClaim.id]: false, pinValid: true}, () => {

                const details = {performerPIN: this.state.provider.providerPIN, claimDAO: claim};

                this.props.resubmitClaim(details);
            });
        }
    }

    showDialogs = () => {

        if (this.state[HM_ClaimSearchEdit.id]) {
            return (
                <ClaimSearch target={'claimSearch'}
                             claimSearch={this.state.claimSearch}
                             onChange={this.onSearchChange}
                             onOK={this.onClaimSearch}
                             onHideDialog={this.onSearchCancel}
                />
            );
        } else if (this.state[HM_ClaimNotFound.id]) {
            return (
                ShowMessageDialog(this, HM_ClaimNotFound)
            )
        } else if (this.state[HM_EditClaim.id]) {
            return (
                <EditClaim onHideDialog={this.onHideMenuEntry}
                           onOk={this.updateClaim}
                           onDelete={this.deleteClaim}
                           claimDAO={this.props.claimDAO}
                           allowSubmit={this.state.allowSubmit}
                />
            )
        } else if (this.state[HM_ResubmitClaim.id]) {
            return (
                <SubmitClaim provider={this.state.provider}
                             target='provider'
                             dialogId={HM_ResubmitClaim.id}
                             onChange={this.onChange}
                             onOK={this.onResubmitClaim}
                             onHideDialog={this.onHideMenuEntry}
                             pinValid={this.state.pinValid}
                />
            )
        } else if (this.state[TB_USER_UDA_UOA_DETAILS.id]) {
            return (
                <ClaimSearch target={'claimSearch'}
                             claimSearch={this.state.claimSearch}
                             onChange={this.onSearchChange}
                             onOK={this.onClaimSearch}
                             onHideDialog={this.onSearchCancel}
                />
            );
        } else if (this.state[HM_DeleteClaim.id]) {

            return (
                <DeleteClaim selectedClaim={this.state.selectedClaim}
                             target='selectedClaim'
                             dialogId={HM_DeleteClaim.id}
                             onChange={this.onChange}
                             onOK={this.onDeleteClaim}
                             onHideDialog={this.onHideMenuEntry}
                />
            )
        } else if (this.state[HM_PerformerPinValidation.id]) {
            return ShowMessageDialog(this, HM_PerformerPinValidation);
        } else {
            return (
                ShowMessageDialog(this, HM_ClaimNotFound),
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.EDI_STACKED,
                id: SM_NHS_MANAGEMENT_STACKED.id,
                content: <TabPanel key={SM_NHS_MANAGEMENT_STACKED.id}
                                   header={SM_NHS_MANAGEMENT_STACKED.label}>
                    <NHSManagementErrorBoundary>
                        <StackedSection onChange={this.onChange}
                                        {...this.props}
                        />
                    </NHSManagementErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.EDI_SUBMITTED,
                id: SM_NHS_MANAGEMENT_SUBMITTED.id,
                content: <TabPanel key={SM_NHS_MANAGEMENT_SUBMITTED.id}
                                   header={SM_NHS_MANAGEMENT_SUBMITTED.label}>
                    <NHSManagementErrorBoundary>
                        <SubmittedSection onChange={this.onChange}
                                          {...this.props}
                        />
                    </NHSManagementErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.EDI_REJECTED,
                id: SM_NHS_MANAGEMENT_REJECTED.id,
                content: <TabPanel key={SM_NHS_MANAGEMENT_REJECTED.id}
                                   header={SM_NHS_MANAGEMENT_REJECTED.label}>
                    <NHSManagementErrorBoundary>
                        <RejectedSection onChange={this.onChange}
                                         {...this.props}
                        />
                    </NHSManagementErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.EDI_REMOVED,
                id: SM_NHS_MANAGEMENT_REMOVED.id,
                content: <TabPanel key={SM_NHS_MANAGEMENT_REMOVED.id}
                                   header={`${SM_NHS_MANAGEMENT_REMOVED.label} (${moment(this.state.removedDate).format('MMM YYYY')})`}>
                    <NHSManagementErrorBoundary>
                        <RemovedSection onChange={this.onChange}
                                        claimDate={this.state.removedDate}
                                        {...this.props}
                        />
                    </NHSManagementErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.EDI_SCHEDULED,
                id: SM_NHS_MANAGEMENT_SCHEDULED.id,
                content: <TabPanel key={SM_NHS_MANAGEMENT_SCHEDULED.id}
                                   header={`${SM_NHS_MANAGEMENT_SCHEDULED.label} (${moment(this.state.scheduledDate).format('MMM YYYY')})`}>
                    <NHSManagementErrorBoundary>
                        <ScheduledSection onChange={this.onChange}
                                          claimDate={this.state.scheduledDate}
                                          {...this.props}
                        />
                    </NHSManagementErrorBoundary>
                </TabPanel>
            });

        const navigationDisabled = (this.state.activeIndex === 0 || this.state.activeIndex === 1 || this.state.activeIndex === 2);

        let key = 1000;

        return (
            <div id="detailPanel">

                {this.showDialogs()}

                <Toolbar
                    left={<React.Fragment>
                        {tb_boilerPlate2(() => this.setDate(EDI_DATE_BACK), DIARY_BACK_MONTH.icon, navigationDisabled, DIARY_BACK_MONTH.tooltip, key++)}
                        {tb_boilerPlate2(() => this.setDate(EDI_DATE_TODAY), DIARY_TODAY.icon, navigationDisabled, DIARY_TODAY.tooltip, key++)}
                        {tb_boilerPlate2(() => this.setDate(EDI_DATE_FORWARD), DIARY_FOR_MONTH.icon, navigationDisabled, DIARY_FOR_MONTH.tooltip, key++)}

                        <i key={key++} className='p-toolbar-group-left fa separator24'/>

                        {tb_boilerPlate2(() => {
                            this.setState({[HM_ClaimSearchEdit.id]: true})
                        }, HM_ClaimSearchEdit.icon, false, HM_ClaimSearchEdit.label, key++)}
                    </React.Fragment>}
                    right={<React.Fragment>
                        {tb_boilerPlateRight2(this.onShowReport, ICON_SCREEN, 'Show ' + TextNHSManagement, key++, false)}
                        {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextNHSManagement, key++, false)}
                        {tb_boilerPlateRight2(this.onDownload, ICON_DOWNLOAD, `Download ${TextNHSManagement} Report`, key++, false)}
                        {tb_boilerPlateRight(() => window.open(HELP_REP_NHS_MANAGEMENT), ICON_HELP, 'Knowledge Base', key++, true)}
                        {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                    </React.Fragment>}
                />

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        claimDAOLoaded: state.nhsManagement.claimSearchLoaded,
        claimDAO: state.nhsManagement.claimSearch,

        submitClaimLoaded: state.nhsManagement.claimLoaded,
        submitClaim: state.nhsManagement.claim,

        currentState: state.stateManagement[SM_NHS_MANAGEMENT.id],
    }
};

const MapDispatchToProps = dispatch => {

    return {

        claimSearch: (number) => dispatch(getResource(RES_NHS_MANAGEMENT_CLAIMS.FIND_BY_REF, {claimRef: number})),
        saveClaim: (details) => dispatch(saveClaim(RES_NHS_MANAGEMENT_CLAIM.SAVE, details)),
        resubmitClaim: (details) => dispatch(resubmitClaim(RES_NHS_MANAGEMENT_CLAIMS.RESUBMIT, details)),
        deleteClaim: (details) => dispatch(deleteClaim(RES_NHS_MANAGEMENT_CLAIM.DELETE, details)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const NHSManagement = connect(MapStateToProps, MapDispatchToProps)(ConnectedNHSManagement);

export default NHSManagement;

