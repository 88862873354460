import {Component} from 'react';

export class ToothBaseComponent extends Component {

    constructor(props) {
        super(props);
    };

    handleEnter = () => {
        if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
            this.setState({cursor: 'url(/src/images/arrowPlus16.gif) 1 1, pointer'})
        }
    }

    handleLeave = () => {
        this.setState({cursor: ''})
    }
}
