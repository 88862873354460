import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {InputText} from 'primereact/components/inputtext/InputText';
import {RadioButton} from 'primereact/components/radiobutton/RadioButton';
import {ageTemplate, dateTemplateShort} from "./Utils";
import {
    RRT_RECALL_EMAIL,
    RRT_RECALL_HOMEPHONE,
    RRT_RECALL_MOBILE,
    RRT_RECALL_OTHERPHONE,
    RRT_RECALL_SMS,
    RRT_RECALL_SNAILMAIL,
    RRT_RECALL_WORKPHONE,
    RRT_REMINDER_EMAIL,
    RRT_REMINDER_HOMEPHONE,
    RRT_REMINDER_MOBILE,
    RRT_REMINDER_OTHERPHONE,
    RRT_REMINDER_SMS,
    RRT_REMINDER_SNAILMAIL,
    RRT_REMINDER_WORKPHONE
} from "../../Constants";

const showAppointmentDate = (isAppointmentDateShown, appointmentDate) => {

    if (isAppointmentDateShown) {

        return (
            <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                <label className='p-col-5'>Appointment Date</label>
                <InputText className='p-col-7' value={dateTemplateShort(appointmentDate)} disabled={true}/>
            </div>
        );
    } else {
        return null;
    }
};

const contactTemplate = (label, value) => {

    let result = <label htmlFor='rb1' className='p-col-3'>{label}</label>

    if (value) {
        if (value.length > 0) {
            result = <label htmlFor='rb1' className='p-col-3'><tcxspan tcxhref={value} title='Click to Call'>{label}</tcxspan></label>;
        }
    }
    return result;
}

export const RecallReminderPatientDetails = (props) => {

    const {appointmentDate, isAppointmentDateShown, patientFirstName, patientLastName, dob, homeTelephone, workTelephone, otherTelephone, mobile, email} = props.selectedItem;

    return (
        <Panel header='Patient Details'
               style={{paddingTop: '5px'}}
        >
            <div className="p-grid p-col-12" style={{fontSize: 'small'}}>

                <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                    <label className='p-col-5'>Patient</label>
                    <InputText className='p-col-7'
                               value={`${patientFirstName} ${patientLastName}`}
                               disabled={true}
                    />
                </div>

                {showAppointmentDate(isAppointmentDateShown, appointmentDate)}

                <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                    <label className='p-col-5'>Age</label>
                    <InputText className='p-col-7' value={ageTemplate(dob)} disabled={true}
                    />
                </div>

                <label className='p-col-12' style={{paddingTop: '5px'}}/>

                <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                    <RadioButton className='p-col-2' inputId="rb0" name="method" value='Letter'
                                 checked={props.contactMethod === RRT_RECALL_SNAILMAIL.ordinal || props.contactMethod === RRT_REMINDER_SNAILMAIL.name}
                                 onChange={(e) => {
                                     props.onChangeMethod(RRT_REMINDER_SNAILMAIL.ordinal, RRT_RECALL_SNAILMAIL.ordinal)
                                 }}
                    />
                    <label htmlFor='rb0' className='p-col-10'>Letter</label>
                </div>

                <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                    <RadioButton className='p-col-2' inputId="rb1" name="method" value='Home Tel.'
                                 checked={props.contactMethod === RRT_RECALL_HOMEPHONE.ordinal || props.contactMethod === RRT_REMINDER_HOMEPHONE.name}
                                 onChange={(e) => {
                                     props.onChangeMethod(RRT_REMINDER_HOMEPHONE.ordinal, RRT_RECALL_HOMEPHONE.ordinal)
                                 }}
                    />
                    {contactTemplate('Home Tel.', homeTelephone)}
                    <InputText className='p-col-7' value={homeTelephone}
                               onChange={(e) => {
                                   props.onChange(RRT_REMINDER_HOMEPHONE.ordinal, RRT_RECALL_HOMEPHONE.ordinal, e.target.value)
                               }}
                    />
                </div>

                <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                    <RadioButton className='p-col-2' inputId="rb2" name="method" value='Work Tel.'
                                 checked={props.contactMethod === RRT_RECALL_WORKPHONE.ordinal || props.contactMethod === RRT_REMINDER_WORKPHONE.name}
                                 onChange={(e) => {
                                     props.onChangeMethod(RRT_REMINDER_WORKPHONE.ordinal, RRT_RECALL_WORKPHONE.ordinal)
                                 }}
                    />
                    {contactTemplate('Work Tel.', workTelephone)}
                    <InputText className='p-col-7' value={workTelephone}
                               onChange={(e) => {
                                   props.onChange(RRT_REMINDER_WORKPHONE.ordinal, RRT_RECALL_WORKPHONE.ordinal, e.target.value)
                               }}
                    />
                </div>

                <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                    <RadioButton className='p-col-2' inputId="rb3" name="method" value='Other Tel.'
                                 checked={props.contactMethod === RRT_RECALL_OTHERPHONE.ordinal || props.contactMethod === RRT_REMINDER_OTHERPHONE.name}
                                 onChange={(e) => {
                                     props.onChangeMethod(RRT_REMINDER_OTHERPHONE.ordinal, RRT_RECALL_OTHERPHONE.ordinal)
                                 }}
                    />
                    {contactTemplate('Other Tel.', otherTelephone)}
                    <InputText className='p-col-7' value={otherTelephone}
                               onChange={(e) => {
                                   props.onChange(RRT_REMINDER_OTHERPHONE.ordinal, RRT_RECALL_OTHERPHONE.ordinal, e.target.value)
                               }}
                    />
                </div>

                <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                    <RadioButton className='p-col-2' inputId="rb4" name="method" value='Mobile'
                                 checked={props.contactMethod === RRT_RECALL_MOBILE.ordinal || props.contactMethod === RRT_REMINDER_MOBILE.name}
                                 onChange={(e) => {
                                     props.onChangeMethod(RRT_REMINDER_MOBILE.ordinal, RRT_RECALL_MOBILE.ordinal)
                                 }}
                    />
                    {contactTemplate('Mobile', mobile)}
                    <InputText className='p-col-7' value={mobile}
                               onChange={(e) => {
                                   props.onChange(RRT_REMINDER_MOBILE.ordinal, RRT_RECALL_MOBILE.ordinal, e.target.value)
                               }}
                    />
                </div>

                <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                    <RadioButton className='p-col-2' inputId="rb5" name="method" value='SMS'
                                 checked={props.contactMethod === RRT_RECALL_SMS.ordinal || props.contactMethod === RRT_REMINDER_SMS.name}
                                 onChange={(e) => {
                                     props.onChangeMethod(RRT_REMINDER_SMS.ordinal, RRT_RECALL_SMS.ordinal)
                                 }}
                    />
                    <label htmlFor='rb5' className='p-col-10' style={{paddingTop: '5px'}}>SMS</label>
                </div>

                <div className="p-grid p-col-12" style={{paddingTop: '5px'}}>
                    <RadioButton className='p-col-2' inputId="rb6" name="method" value='Email'
                                 checked={props.contactMethod === RRT_RECALL_EMAIL.ordinal || props.contactMethod === RRT_REMINDER_EMAIL.name}
                                 onChange={(e) => {
                                     props.onChangeMethod(RRT_REMINDER_EMAIL.ordinal, RRT_RECALL_EMAIL.ordinal)
                                 }}
                    />
                    <label htmlFor='rb6' className='p-col-3'>Email</label>
                    <InputText className='p-col-7' value={email}
                               onChange={(e) => {
                                   props.onChange(RRT_REMINDER_EMAIL.ordinal, RRT_RECALL_EMAIL.ordinal, e.target.value)
                               }}
                    />
                </div>
            </div>
        </Panel>
    )
};
