import {BaseComponent} from "../../BaseComponent";
import {
    DateOfBirthBetween,
    DateOfBirthGreaterThan,
    DateOfBirthLessThan,
    Female, HM_notImplemented,
    Male, MSOperators,
    NextAppointmentAfter,
    NextAppointmentBefore,
    NextAppointmentBetween,
    Null, PAT_STATUSES,
    PatientJourneyBetween,
    PatientJourneyCode, PatientStatus,
    RegisteredAfter,
    RegisteredBefore,
    RegisteredBetween,
    WithProvider
} from "../../../Constants";
import _ from "lodash";
import {Dropdown} from "primereact/dropdown";
import React from "react";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {calendarCompact} from "../../PatientDynamicItems/OnChangeUtils";
import {Button} from "primereact/button";
import {showPatientDetailsPage} from "../PatientDetails/Utils";

export class MailShotBase extends BaseComponent {

    onOperatorChange = (event, row, action) => {

        const mailShot = {...this.state.shot};

        // first check for existing operator instance
        if (_.findIndex(mailShot.operators, data => data.value === event.value) > -1) {
            return;
        }

        mailShot.edited = true;

        switch (event.value) {
            case DateOfBirthLessThan.value: {

                const index = _.findIndex(mailShot.operators, data => data.value === DateOfBirthBetween.value);

                if (index > -1 && index !== row.rowIndex) {
                    return;
                }
                mailShot.dateOfBirthOne = new Date();
                break;
            }
            case DateOfBirthGreaterThan.value: {

                const index = _.findIndex(mailShot.operators, data => data.value === DateOfBirthBetween.value);

                if (index > -1 && index !== row.rowIndex) {
                    return;
                }
                mailShot.dateOfBirthOne = new Date();
                break;
            }
            case DateOfBirthBetween.value: {

                if (this.checkBetweens(row, DateOfBirthLessThan, DateOfBirthGreaterThan)) {
                    return;
                }
                mailShot.dateOfBirthOne = new Date();
                mailShot.dateOfBirthTwo = new Date();
                break;
            }
            case NextAppointmentBefore.value: {

                const index = _.findIndex(mailShot.operators, data => data.value === NextAppointmentBetween.value);

                if (index > -1 && index !== row.rowIndex) {
                    return;
                }
                mailShot.nextAppointmentOne = new Date();
                break;
            }
            case NextAppointmentAfter.value: {

                const index = _.findIndex(mailShot.operators, data => data.value === NextAppointmentBetween.value);

                if (index > -1 && index !== row.rowIndex) {
                    return;
                }
                mailShot.nextAppointmentOne = new Date();
                break;
            }
            case NextAppointmentBetween.value: {

                if (this.checkBetweens(row, NextAppointmentBefore, NextAppointmentAfter)) {
                    return;
                }
                mailShot.nextAppointmentOne = new Date();
                mailShot.nextAppointmentTwo = new Date();
                break;
            }
            case RegisteredBefore.value: {

                const index = _.findIndex(mailShot.operators, data => data.value === RegisteredBetween.value);

                if (index > -1 && index !== row.rowIndex) {
                    return;
                }
                mailShot.registeredOne = new Date();
                break;
            }
            case RegisteredAfter.value: {

                const index = _.findIndex(mailShot.operators, data => data.value === RegisteredBetween.value);

                if (index > -1 && index !== row.rowIndex) {
                    return;
                }
                mailShot.registeredOne = new Date();
                break;
            }
            case RegisteredBetween.value: {

                if (this.checkBetweens(row, RegisteredBefore, RegisteredAfter)) {
                    return;
                }
                mailShot.registeredOne = new Date();
                mailShot.registeredTwo = new Date();
                break;
            }
            case Male.value: {

                const index = _.findIndex(mailShot.operators, data => data.value === Female.value);

                if (index > -1 && index !== row.rowIndex) return;

                mailShot.male = true;
                break;
            }
            case Female.value: {

                const index = _.findIndex(mailShot.operators, data => data.value === Male.value);

                if (index > -1 && index !== row.rowIndex) return;

                mailShot.male = false;
                break;
            }
            case WithProvider.value:
                break;
            case PatientJourneyCode.value:
                break;
            case PatientJourneyBetween.value:
                mailShot.patientJourneyOne = new Date();
                mailShot.patientJourneyTwo = new Date();
                break;
            default:
                break;
        }
        const {rowIndex} = row;
        mailShot.operators[rowIndex] = event.value;

        mailShot.edited = true;

        this.setState({shot: mailShot}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {id: this.state.stateManagementId, action}
            });
        });
    }

    addOperator = (action) => {

        if (this.state.operatorIndex === 20) return;

        const mailShot = {...this.state.shot};

        mailShot.edited = true;

        mailShot.operators.push(Null);

        this.setState({shot: mailShot, operatorIndex: this.state.operatorIndex + 1}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {id: this.state.stateManagementId, action}
            });
        })
    }

    delOperator = (operator, rowIndex, action) => {

        const mailShot = {...this.state.shot};

        mailShot.edited = true;

        mailShot.operators.splice(rowIndex, 1);
        mailShot.patientJourneyCodeIds[rowIndex] = null;

        this.setState({shot: mailShot, operatorIndex: this.state.operatorIndex - 1}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {id: this.state.stateManagementId, action}
            });
        });
    }

    onChange = (event) => {

        const state = {...this.state};

        if (event.owner === 'shot.withProviderId') {
            _.set(state, event.owner, event.value.id);
        } else if (event.owner.startsWith('shot.patientJourneyCodeIds')) {
            _.set(state, event.owner, event.value.id);
        } else {
            _.set(state, event.owner, event.value);
        }

        state.shot.edited = true;

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {id: this.state.stateManagementId, action: this.state.saveAction}
            });
        });
    }

    checkBetweens = (row, check1, check2) => {

        const index1 = _.findIndex(this.state.shot.operators, data => data.value === check2.value);
        const index2 = _.findIndex(this.state.shot.operators, data => data.value === check1.value);

        return ((index1 > -1 && index2 > -1) || (index1 > -1 ? index1 : index2) !== row.rowIndex && (index1 + index2 !== -2));
    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    column1Template = (operator, row) => {

        const value = this.state.shot.operators[row.rowIndex];

        return <Dropdown key={`column1Template__${row.rowIndex}`}
                         options={MSOperators}
                         value={value}
                         optionLabel='label'
                         onChange={(e) => this.onOperatorChange(e, row, this.state.saveAction)}
        />
    }

    insertCalendar = (owner) => {

        const props = {
            onChange: this.onChange,
            target: 'shot',
            shot: this.state.shot,
        };

        return calendarCompact(props, owner, false, false);
    }

    column2aTemplate = (data, row) => {

        switch (data) {

            case DateOfBirthLessThan.value:
            case DateOfBirthGreaterThan.value:
            case DateOfBirthBetween.value:
                return this.insertCalendar(`dateOfBirthOne`);
            case NextAppointmentBefore.value:
            case NextAppointmentAfter.value:
            case NextAppointmentBetween.value:
                return this.insertCalendar(`nextAppointmentOne`);
            case RegisteredBefore.value:
            case RegisteredAfter.value:
            case RegisteredBetween.value:
                return this.insertCalendar(`registeredOne`);
            case PatientJourneyBetween.value:
                return this.insertCalendar(`patientJourneyOne`);
            case WithProvider.value:

                const provider = this.state.shot.withProviderId === null ? undefined : _.find(this.state.sortedClinicians, clinician => clinician.id === this.state.shot.withProviderId);

                return <Dropdown options={this.state.sortedClinicians}
                                 optionLabel='fullName'
                                 placeHolder='Provider'
                                 value={provider}
                                 onChange={e => {
                                     this.onChange({owner: `shot.withProviderId`, value: e.value})
                                 }}
                />
            case PatientJourneyCode.value:

                const stage = this.state.shot.patientJourneyCodeIds[row.rowIndex] === null ? undefined : _.find(this.props.journeyStages, stage => stage.id === this.state.shot.patientJourneyCodeIds[row.rowIndex]);

                return <Dropdown placeHolder='Journey Code'
                                 value={stage}
                                 options={this.props.journeyStages}
                                 optionLabel={`description`}
                                 onChange={e => {
                                     this.onChange({owner: `shot.patientJourneyCodeIds[${row.rowIndex}]`, value: e.value})
                                 }}
                />
            case PatientStatus.value:
                return <Dropdown value={this.state.shot.status}
                          options={PAT_STATUSES}
                          onChange={(e) => {
                              this.onChange({owner: `shot.status`, value: e.value});
                          }}
                />
            default:
                break;
        }
    }

    column2bTemplate = (data) => {

        switch (data) {

            case DateOfBirthBetween.value:
                return this.insertCalendar(`dateOfBirthTwo`);
            case NextAppointmentBetween.value:
                return this.insertCalendar(`nextAppointmentTwo`);
            case RegisteredBetween.value:
                return this.insertCalendar(`registeredTwo`);
            case PatientJourneyBetween.value:
                return this.insertCalendar(`patientJourneyTwo`);
            default:
                break;
        }
    }

    column3Template = (operator, rowData) => {
        return <Button label='+'
                       onClick={() => this.addOperator(this.state.saveAction)}
                       disabled={this.state.shot.operators[rowData.rowIndex].ordinal === Null.ordinal}
        />
    }

    column4Template = (operator, row) => {

        if (row.rowIndex !== 0 || (row.rowIndex === 0 && this.state.shot.operators.length>1)) {
            return <Button label='-'
                           onClick={() => this.delOperator(operator, row.rowIndex, this.state.saveAction)}
            />
        }
    }

    runSelection = () => {

        const shot = {...this.state.shot};
        shot.operators = _.map(shot.operators, data => _.find(MSOperators, operator => operator.value === data).ordinal);
        shot.content = null;

        if (this.state.emailShot) {
            this.props.runSelection({emsShot: shot});
        } else {
            this.props.runSelection({smsShot: shot});
        }
    }

    providerTemplate(row) {

        const provider = _.find(this.props.providers, provider => provider.id === row.providerId);
        return provider === undefined ? '' : `${provider.fullName}`.trim();
    };

    onShowDetails = ({id, firstName, lastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    removeRecipient = () => {

        const recipients = _.filter(this.state.recipients, recipient => recipient.id !== this.state.dataTableSelectValue.id);

        this.setState({recipients, dataTableSelectValue: null}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {id: this.state.stateManagementId, action: this.state.saveAction}
            });
        })
    }
}
