import React from 'react';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';

import _ from "lodash";
import * as DefaultData from "../DefaultData";
import {connect} from "react-redux";
import {
    HELP_ADD_SUBSCRIPTION,
    setState,
    SM_ADMIN_ADD_SUBSCRIPTION,
    stateRequest
} from "../../../actions/stateManagement";
import * as Actions from "../../../actions";
import {
    ACC_LEVEL_1,
    ACC_LEVEL_2,
    ACC_LEVEL_3,
    ADMIN_SUB_ADD_RESULT_ALREADY_EXISTS,
    ADMIN_SUB_ADD_RESULT_DUPLICATE_NAME,
    ADMIN_SUB_ADD_RESULT_ERROR,
    ADMIN_SUB_ADD_RESULT_OK, NewPassword,
    TB_SAVE_AND_EXIT, TB_SEND_SUB_EMAIL
} from "../../../Constants";
import {ICON_HELP, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED,} from "../../../icons";
import {BaseComponent} from "../../BaseComponent";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../../FixedItems/Housekeeping/Constants";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import {HM_SubscriptionSave, HM_SubscriptionSaveNOK, HM_SubscriptionSaveOK} from "./Constants";
import {adminSubscriptionAdd, RES_ADMIN_GROUP_SUBSCRIPTION} from "../../../actions/admin";
import {AccountInfo} from "./Sections/AccountInfo";
import {Details} from "./Sections/Details";
import {Address} from "../../DynamicItems/Address";
import crypto from "crypto";

class ConnectedAddGroupSubscription extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_ADMIN_ADD_SUBSCRIPTION.id,
                subscription: _.cloneDeep(DefaultData.GroupSubscriptionData(this.props.groupId)),
                canSave: {status: false, count: 0},
                init: false,
                [HM_SubscriptionSave.id]: false,
                [HM_SubscriptionSaveOK.id]: false,
                [HM_SubscriptionSaveNOK.id]: false,
                showErrorMessage: '',
            };
        }
        this.exitState = TAB_PARENT_CHANGE;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {

            switch (this.props.message.type) {

                case Actions.ADD_ADMIN_GROUP_SUBSCRIPTION:

                    switch (this.props.result.result) {
                        case ADMIN_SUB_ADD_RESULT_OK:
                            this.setState({[HM_SubscriptionSaveOK.id]: true});
                            break;
                        case ADMIN_SUB_ADD_RESULT_DUPLICATE_NAME:
                            this.setState({
                                [HM_SubscriptionSaveNOK.id]: true,
                                showErrorMessage: 'Duplicate subscription name'
                            });
                            break;
                        case ADMIN_SUB_ADD_RESULT_ALREADY_EXISTS:
                            this.setState({
                                [HM_SubscriptionSaveNOK.id]: true,
                                showErrorMessage: 'subscription name already exists'
                            });
                            break;
                        case ADMIN_SUB_ADD_RESULT_ERROR:
                            this.setState({
                                [HM_SubscriptionSaveNOK.id]: true,
                                showErrorMessage: 'An error occurred during the subscription creation process'
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onShowDialogs = () => {

        if (this.state[HM_SubscriptionSaveOK.id]) {

            const footer = <Button label="OK" icon="fas fa-times" onClick={() => {
                this.onExit();
            }}/>;

            return (
                <Dialog header={HM_SubscriptionSaveOK.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_SubscriptionSaveOK.id]: false}, () => {
                                this.onExit();
                            })
                        }}
                >
                    {HM_SubscriptionSaveOK.message}
                </Dialog>
            )
        } else if (this.state[HM_SubscriptionSaveNOK.id]) {

            const footer = <Button label="OK" icon="fas fa-times" onClick={() => {
                this.setState({[HM_SubscriptionSaveNOK.id]: false})
            }}/>;

            return (
                <Dialog header={HM_SubscriptionSaveNOK.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_SubscriptionSaveNOK.id]: false})
                        }}
                >
                    {this.state.showErrorMessage}
                </Dialog>
            )
        }
        return null;
    }

    onSubscriptionCreate = () => {

        const subscription = {...this.state.subscription}

        switch (subscription.level) {
            case ACC_LEVEL_1.name:
                subscription.maximumActiveUsers = 5;
                break;
            case ACC_LEVEL_2.name:
                subscription.maximumActiveUsers = 10;
                break;
            case ACC_LEVEL_3.name:
                subscription.maximumActiveUsers = 10000;
                break;
            default:
                break;
        }
        this.props.addSubscription(subscription);
    }

    onClose = () => {

        this.onExit();
    }

    onExit = () => {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: SM_ADMIN_ADD_SUBSCRIPTION.id});
    }

    onChange = (event) => {

        const state = {...this.state};

        if (event.owner === 'subscription.passwd') {

            let passwd = event.value;

            if (state.subscription.passwd.startsWith(NewPassword)) {
                passwd = event.value.replace(NewPassword, '');
            }
            // Creating a unique salt for a particular user
            state.subscription.salt = crypto.randomBytes(16).toString('hex');

            // Hashing user's salt and password with 1000 iterations, 64 length and sha512 digest
            state.subscription.hash = crypto.pbkdf2Sync(passwd, state.subscription.salt, 1000, 64, `sha512`).toString(`hex`);
            state.subscription.passwd = passwd;
        } else {
            _.set(state, event.owner, event.value);
        }

        _.set(state, 'canSave.status', true);

        this.setState(state, () => {
            this.props.setState(SM_ADMIN_ADD_SUBSCRIPTION.id, this.state);
        });
    }

    render() {

        const {name, contact, telephone, email, passwd} = this.state.subscription;

        const canSave = this.state.canSave.status
            && passwd.trim() !== ''
            && passwd.trim() !== NewPassword
            && name.trim() !== ''
            && contact.trim() !== ''
            && telephone.trim() !== ''
            && email.trim() !== '';

        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        return (

            <div id="detailPanel">

                {this.onShowDialogs()}

                <Toolbar
                    left={tb_boilerPlate2((e) => this.onSubscriptionCreate(), saveIcon, !canSave, SM_ADMIN_ADD_SUBSCRIPTION.label, 1)}
                    right={<React.Fragment>
                        {tb_boilerPlateRight((e) => window.open(HELP_ADD_SUBSCRIPTION), ICON_HELP, 'Knowledge Base', 1, true)}
                        {tb_boilerPlateRight(this.onClose, TB_SEND_SUB_EMAIL.icon, TB_SEND_SUB_EMAIL.text, 2, true)}
                        {tb_boilerPlateRight(this.onClose, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 3)}
                    </React.Fragment>}
                />

                <div className="p-grid p-fluid p-col-12">
                    <div className="p-lg-12 p-md-12">
                        <AccountInfo onChange={this.onChange}
                                     target='subscription'
                                     subscription={this.state.subscription}
                        />
                    </div>
                    <div className="p-lg-6 p-md-12">
                        <Details onChange={this.onChange}
                                 target='subscription'
                                 subscription={this.state.subscription}
                        />
                    </div>
                    <div className="p-lg-6 p-md-12">
                        <Address onChange={this.onChange}
                                 target='subscription.address'
                                 subscription={this.state.subscription}
                                 header='Address'
                                 postCodeMandatory={true}
                                 countries={[]}
                        />
                    </div>
                </div>
            </div>);
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({action: Actions.CLEAR_SUBSCRIPTION_ADD, id: SM_ADMIN_ADD_SUBSCRIPTION.id});
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        result: state.admin.result,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_ADMIN_ADD_SUBSCRIPTION.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addSubscription: (subscription) => dispatch(adminSubscriptionAdd(RES_ADMIN_GROUP_SUBSCRIPTION.ADD, subscription)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddGroupSubscription = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddGroupSubscription);

export default AddGroupSubscription;
