import React, {Component} from 'react';

import {connect} from 'react-redux';
import {ac} from './index'

import 'nanoscroller';

import 'primereact/resources/primereact.min.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'nanoscroller/bin/css/nanoscroller.css';
import './login.css';

import {doLogin, getPracticeLoginPageDetails} from './actions/login';
import {Button} from 'primereact/components/button/Button';
import './App.scss';
import {ACC_ST_ACTIVE, HM_VERSION_MISMATCH, TABLET} from "./Constants";
import {SEL_BTN_LOGIN_BUTTON, SEL_INP_LOGIN_PASSWORD, SEL_INP_LOGIN_USERNAME} from "./selenium/Constants";
import * as Actions from "./actions";
import _ from "lodash";
import {
    SM_404_CLOUD_PAGE_ERROR,
    SM_500_CLOUD_PAGE_ERROR,
    SM_CLOUD_LOGGED_IN,
    SM_TABLET_LOGGED_IN
} from "./actions/stateManagement";
import {VALID_GROUPS} from "./actions/fetchClient";
import {ProgressBar} from "primereact/progressbar";
import packageJson from "./package.json";
import {ShowOKMessageDialog} from "./components/FixedItems/Diary/components/EventComponent";

class ConnectedLogin extends Component {

    constructor(props) {
        super(props);

        this.state = {

            getPracticeDetails: {
                practiceName: '',
                telephone: '',
            },

            username: '',
            password: '',
        };
        const urlParts = window.location.pathname.split('/');
        ac.setGroupId(window.location);

        this.state.mcId = parseInt(urlParts[2], 10);

        const groupIndex = _.findIndex(VALID_GROUPS, group => group === urlParts[1]);
        const practiceId = parseInt(urlParts[2], 10);

        if (groupIndex === -1 || isNaN(practiceId) || practiceId < 1) {
            window.location.hash = SM_404_CLOUD_PAGE_ERROR.route;
        }
        this.password = this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e) {

        e.preventDefault();

        const loginDetails = {
            mcId: this.state.mcId,
            username: this.state.username,
            password: this.state.password,
        };

        this.props.doLogin(loginDetails);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps !== this.props) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS:

                    if (this.props.practiceLoginPageDetails === null) {
                        window.location.hash = SM_404_CLOUD_PAGE_ERROR.route;
                    } else {
                        const practiceLoginPageDetails = {
                            practiceName: this.props.practiceLoginPageDetails[0],
                            telephone: this.props.practiceLoginPageDetails[1],
                            accountStatus: this.props.practiceLoginPageDetails[3],
                            clientVersion: this.props.practiceLoginPageDetails[4],
                        }

                        switch (practiceLoginPageDetails.accountStatus) {
                            case ACC_ST_ACTIVE.name:

                                    this.setState({
                                        practiceLoginPageDetails: practiceLoginPageDetails,
                                        practiceLoginPageDetailsLoaded: true,
                                        [HM_VERSION_MISMATCH.id]: practiceLoginPageDetails.clientVersion !== packageJson.version
                                    });
                                break;
                            default:
                                window.location.hash = SM_500_CLOUD_PAGE_ERROR.route;
                                break;
                        }
                    }
                    break;

                case Actions.RECEIVE_LOGIN_USER_DETAILS:

                    if (this.props.loginIdentity.myRole.type === TABLET) {
                        window.location.hash = SM_TABLET_LOGGED_IN.route;
                    } else {
                        window.location.hash = SM_CLOUD_LOGGED_IN.route;
                    }
                    break;
                case Actions.LOGIN_FAILURE:

                    this.setState({result: Actions.LOGIN_FAILURE})
                    break;
                default:
                    break;
            }
        }
    }

    componentDidMount() {

        this.props.getPracticeLoginPageDetails(this.state.mcId);
    }

    onUpdate = () => {
        window.location.reload(true);
    }

    onShowDialogs = () => {

        if (this.state[HM_VERSION_MISMATCH.id]) {
            return ShowOKMessageDialog(this, HM_VERSION_MISMATCH, this.onUpdate);
        } else
            return null;
    }

    render() {

        if (!this.state.practiceLoginPageDetailsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const usernameMessage = this.state.result !== Actions.LOGIN_FAILURE ? 'User Name' : 'User Name (or password incorrect)';

        return (

            <div className="login-image"
                 style={{backgroundImage: 'url(/src/images/chiralLogin2.jpg)'}}
            >

                {this.onShowDialogs()}

                <div className="card login-panel p-fluid">
                    <form method="post" onSubmit={this.onSubmit.bind(this)}>
                        <div className="login-panel-content">
                            <div className="p-grid">
                                <div className="p-col-3" style={{textAlign: 'left'}}>
                                    <i className='fa csIcon'/>
                                </div>
                                <div className="p-col-9" style={{textAlign: 'right'}}>
                                    <h1 className="welcome-text-main">Welcome</h1>
                                    <label className="welcome-text">Sign in to Chiral Cloud</label>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12" style={{textAlign: 'left'}}>
                                        <label
                                            className="welcome-text">{this.state.practiceLoginPageDetails.practiceName} : {this.state.practiceLoginPageDetails.telephone}</label>
                                    </div>

                                    <div className="p-col-12" style={{textAlign: 'left'}}>
                                        <label className="login-label">{usernameMessage}</label>
                                        <div className="login-input">
                                            <input id={SEL_INP_LOGIN_USERNAME} name="username" type="text"
                                                   value={this.state.username}
                                                   className="p-inputtext p-widget p-state-default p-corner-all"
                                                   onChange={(e) => {
                                                       this.setState({username: e.target.value})
                                                   }}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12" style={{textAlign: 'left'}}>
                                        <label className="login-label">Password</label>
                                        <div className="login-input">
                                            <input id={SEL_INP_LOGIN_PASSWORD} name="password" type="password"
                                                   value={this.state.password}
                                                   className="p-inputtext p-widget p-state-default p-corner-all"
                                                   onChange={(e) => {
                                                       this.setState({password: e.target.value})
                                                   }}/>
                                            <input name="mcId" type="hidden" value="1"
                                                   className="p-inputtext p-widget p-state-default p-corner-all"/>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-6 button-pane">
                                        <Button id={SEL_BTN_LOGIN_BUTTON} label='Sign In'
                                                className="p-button p-widget p-state-default p-corner-all p-button-text-only"/>
                                    </div>
                                    <div className="p-col-12 p-md-6 link-pane">
                                        <a className="forgot-password" href="/forgot">Forgot Password?</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        practiceLoginPageDetailsLoaded: state.login.practiceLoginPageDetailsLoaded,
        practiceLoginPageDetails: state.login.practiceLoginPageDetails,

        loggedIn: state.login.loggedIn,
        loginIdentity: state.login.user,
        capabilities: state.login.capabilities,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPracticeLoginPageDetails: (mcId) => dispatch(getPracticeLoginPageDetails(mcId)),
        doLogin: (loginDetails) => dispatch(doLogin(loginDetails)),
    };
};

const Login = connect(mapStateToProps, mapDispatchToProps)(ConnectedLogin);

export default Login;
