import React from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {calendarCompact, inputNumberText, inputTextArea, password} from "../../../PatientDynamicItems/OnChangeUtils";
import {HM_DeleteClaim} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Panel} from "primereact/panel";
import {EDI_DeletionNarrative, EDI_Narrative, EDI_PerformerPin, nameTemplate} from "../Constants";
import {TB_USER_UDA_UOA_DETAILS} from "../../UserDetails/Constants";

export const EditUDAUOADetails = (props) => {

    const footer = (
        <div>
            <Button label="OK"
                    icon={ICON_OK}
                    onClick={() => {
                        props.onOK()
                    }}/>
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(TB_USER_UDA_UOA_DETAILS.id)
                    }}/>
        </div>
    );

    const header = `${TB_USER_UDA_UOA_DETAILS.text} : ${nameTemplate(props.selectedUser)}`;

    const userProps = {
        onChange: props.onChange,
        target: 'selectedUser',
        selectedUser: props.selectedUser
    }

    return (
        <Dialog header={header}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(HM_DeleteClaim.id)
                }}>
            <Panel header='UDA Allocation'>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-3">
                        <label htmlFor="comment">Allocation Start Date</label>
                    </div>
                    <div className="p-col-9">
                        {calendarCompact(userProps, 'udaStartDate', false, true)}
                    </div>

                    {/*UDA Details*/}
                    <div className="p-col-3">
                        <label htmlFor="pin">Target</label>
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="pin">
                            {inputNumberText(userProps, 'udaTarget', 'target', false, true)}
                        </label>
                    </div>

                    <div className="p-col-3">
                        <label htmlFor="pin">Usage</label>
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="pin">
                            {inputNumberText(userProps, 'udaUsage', 'usage', false, true)}
                        </label>
                    </div>
                </div>
            </Panel>
            <Panel header='UOA Allocation'>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-3">
                        <label htmlFor="comment">Allocation Start Date</label>
                    </div>
                    <div className="p-col-9">
                        {calendarCompact(userProps, 'uoaStartDate', false, true)}
                    </div>

                    {/*UOA Details*/}
                    <div className="p-col-3">
                        <label htmlFor="pin">Target</label>
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="pin">
                            {inputNumberText(userProps, 'uoaTarget', 'target', false, true)}
                        </label>
                    </div>

                    <div className="p-col-3">
                        <label htmlFor="pin">Usage</label>
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="pin">
                            {inputNumberText(userProps, 'uoaUsage', 'usage', false, true)}
                        </label>
                    </div>
                </div>
            </Panel>
        </Dialog>
    )
};
