import React from 'react';

import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {Column} from 'primereact/components/column/Column';
import {ColorPicker} from 'primereact/components/colorpicker/ColorPicker';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_DCS} from "../../../../actions/stateManagement";
import {RES_PREFERENCES, RES_PREFERENCES_DCS} from "../../../../actions/preferences";
import _ from "lodash";
import {getAllUsers} from "../../../../actions/users";
import * as Actions from "../../../../actions";
import {BaseComponent} from "../../../BaseComponent";
import {SU_STATUS_ACTIVE} from "../../../../Constants";
import {DEFAULT_DIARY_COLOUR, HOLIDAY_COLOUR, LUNCH_COLOUR, OUTOFHOURS_COLOUR, RESERVED_COLOUR} from "../Constants";
import {getObjectStore, RES_OBJECT_STORE} from "../../../../actions/objectStore";
import {getObjectStoreIds} from "../Utils";
import {getColour} from "../../fixedItemUtils";

const requiredObject = [LUNCH_COLOUR.value, RESERVED_COLOUR.value, HOLIDAY_COLOUR.value, OUTOFHOURS_COLOUR.value];

class ConnectedDiaryColourSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_DCS.id,

                usersShort: [],
                selectedUser: null,

                includeInactives: false,
                showColourPicker: false,

                first: 0,
                rows: 5,
            }
        }

        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onColourSelect = this.onColourSelect.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getAllUsersShort(this.props.usersLoaded);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:

                    this.setState({usersShort: this.props.usersShort});
                    this.props.getObjectStore(requiredObject);
                    break;

                case RES_OBJECT_STORE.GET.receive: {

                    const objects = this.props.objects;

                    const initialisedObjects = {
                        LUNCH_COLOUR: getColour(objects, LUNCH_COLOUR, DEFAULT_DIARY_COLOUR),
                        RESERVED_COLOUR: getColour(objects, RESERVED_COLOUR, DEFAULT_DIARY_COLOUR),
                        HOLIDAY_COLOUR: getColour(objects, HOLIDAY_COLOUR, DEFAULT_DIARY_COLOUR),
                        OUTOFHOURS_COLOUR: getColour(objects, OUTOFHOURS_COLOUR, DEFAULT_DIARY_COLOUR),
                    };

                    // add in the synthetic entries
                    const usersShort = [...this.state.usersShort];

                    usersShort.push(initialisedObjects.RESERVED_COLOUR);
                    usersShort.push(initialisedObjects.LUNCH_COLOUR);
                    usersShort.push(initialisedObjects.HOLIDAY_COLOUR);
                    usersShort.push(initialisedObjects.OUTOFHOURS_COLOUR);

                    this.setState({usersShort, objects: initialisedObjects}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;
                }
                case RES_PREFERENCES_DCS.SAVE.action:

                    const usersShort = [...this.state.usersShort];
                    const user = _.find(usersShort, (user) => this.state.selectedUser.id === user.id);
                    user.setEdited = false;

                    this.setState({usersShort});
                    break;

                default:
                    break;
            }
        }
    }

    onSelectionChange(selection) {

        this.setState({selectedUser: selection.value, showColourPicker: true});
    }

    onColourSelect({value}) {

        const usersShort = [...this.state.usersShort];

        const user = _.find(usersShort, (user) => Boolean(user.special) ? this.state.selectedUser.value === user.value : this.state.selectedUser.id === user.id);

        user.red = value.r;
        user.green = value.g;
        user.blue = value.b;
        user.edited = true;

        this.setState({usersShort, selectedUser: user});

        this.props.setState(this.state.stateManagementId, {...this.state});

        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_PREFERENCES.SAVE.action,
                saveState: true,
                saveObjects: true
            }
        })
    }

    render() {

        if (!this.props.usersLoaded) {
            return null;
        }
      

        const visibleUsers = this.state.usersShort.filter(user => this.state.includeInactives ? true : (user.status === SU_STATUS_ACTIVE) || user.special);

        const su = this.state.selectedUser;
        const colorValue = this.state.showColourPicker ? {r: su.red, g: su.green, b: su.blue} : {
            r: 255,
            g: 255,
            b: 255
        };

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>Include Inactives</span>
                        <Checkbox checked={this.state.includeInactives}
                                  className='p-checkbox-label'
                                  onChange={(e) => {
                                      this.setState({includeInactives: !this.state.includeInactives})
                                  }}
                        />
                        <label id='panel-header'>Select Colour</label>
                        <ColorPicker format='rgb'
                                     value={colorValue}
                                     onChange={this.onColourSelect}
                                     disabled={!this.state.showColourPicker}
                        />
                    </div>
                    <div className="items-margin d-flex d-align-center">
                    </div>
                </div>;

        return (
            <div>
                <Panel headerTemplate={header}>

                    <DataTable value={visibleUsers}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedUser}
                               onSelectionChange={this.onSelectionChange}
                    >

                        <Column field="username"
                                body={this.psrUsernameTemplate}
                                header="Username"
                                style={{width: '35%'}}/>
                        <Column header="Name"
                                body={this.psrNameTemplate}
                                style={{width: '45%'}}/>
                        <Column field="colour"
                                header=""
                                body={this.psrColourTemplate}
                                style={{width: '20%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        currentState: state.stateManagement[SM_PREFERENCES_DCS.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const DiaryColourSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedDiaryColourSection);

export default DiaryColourSection;
