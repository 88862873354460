import * as Actions from "./index";
import {ac} from '../index'

const baseURL = '/Reports';
const baseServiceURL = '/api/v1/reports';

export const RES_REPORT_DAILYCASH = {
    GET: {
        request: Actions.REQUEST_REPORT_DAILYCASH,
        receive: Actions.RECEIVE_REPORT_DAILYCASH,
        url: `${baseURL}/daysCash`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_DAILYCASH_PDF,
        receive: Actions.RECEIVE_REPORT_DAILYCASH_PDF,
        url: `${baseURL}/daysCash.pdf`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_DAILYCASH_XLS,
        receive: Actions.RECEIVE_REPORT_DAILYCASH_XLS,
        url: `${baseURL}/daysCash.xls`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_DAILYCASH}
};

export const RES_REPORT_PAYMENT_PLANS = {
    ACTIVE: {
        request: Actions.REQUEST_REPORT_ACT_PPS,
        receive: Actions.RECEIVE_REPORT_ACT_PPS,
        url: `${baseURL}/ActivePatientPlans`
    },
    ACTIVE_PDF: {
        request: Actions.REQUEST_REPORT_ACT_PPS_PDF,
        receive: Actions.RECEIVE_REPORT_ACT_PPS_PDF,
        url: `${baseURL}/ActivePatientPlans.pdf`
    },
    FINISHED: {
        request: Actions.REQUEST_REPORT_FIN_PPS,
        receive: Actions.RECEIVE_REPORT_FIN_PPS,
        url: `${baseURL}/FinishedPatientPlans`
    },
    FINISHED_PDF: {
        request: Actions.REQUEST_REPORT_FIN_PPS_PDF,
        receive: Actions.RECEIVE_REPORT_FIN_PPS_PDF,
        url: `${baseURL}/FinishedPatientPlans.pdf`
    },
    SUSPENDED: {
        request: Actions.REQUEST_REPORT_SUS_PPS,
        receive: Actions.RECEIVE_REPORT_SUS_PPS,
        url: `${baseURL}/SuspendedPatientPlans`
    },
    SUSPENDED_PDF: {
        request: Actions.REQUEST_REPORT_SUS_PPS_PDF,
        receive: Actions.RECEIVE_REPORT_SUS_PPS_PDF,
        url: `${baseURL}/SuspendedPatientPlans.pdf`
    },
    WRITTEN_OFF: {
        request: Actions.REQUEST_REPORT_WRO_PPS,
        receive: Actions.RECEIVE_REPORT_WRO_PPS,
        url: `${baseURL}/WrittenOffPatientPlans`
    },
    WRITTEN_OFF_PDF: {
        request: Actions.REQUEST_REPORT_WRO_PPS_PDF,
        receive: Actions.RECEIVE_REPORT_WRO_PPS_PDF,
        url: `${baseURL}/WrittenOffPatientPlans.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_PPS},
};

export const RES_REPORT_PAT_JOURNEYS = {
    GET: {
        request: Actions.REQUEST_REPORT_PAT_JOURNEYS,
        receive: Actions.RECEIVE_REPORT_PAT_JOURNEYS,
        url: `${baseURL}/patientJourneys`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_PAT_JOURNEYS_PDF,
        receive: Actions.RECEIVE_REPORT_PAT_JOURNEYS_PDF,
        url: `${baseURL}/patientJourneys.pdf`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_PAT_JOURNEYS_XLS,
        receive: Actions.RECEIVE_REPORT_PAT_JOURNEYS_XLS,
        url: `/api/v1/reports/patientJourneys.xls`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_PAT_JOURNEYS},
};

export const RES_REPORT_PAT_JOURNEYS_SS = {
    GET: {
        request: Actions.REQUEST_REPORT_PAT_JOURNEYS_SS,
        receive: Actions.RECEIVE_REPORT_PAT_JOURNEYS_SS,
        url: `${baseURL}/patientJourneysSS`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_PAT_JOURNEYS_SS_PDF,
        receive: Actions.RECEIVE_REPORT_PAT_JOURNEYS_SS_PDF,
        url: `${baseURL}/patientJourneysSS.pdf`
    },
    XSL: {
        request: Actions.REQUEST_REPORT_PAT_JOURNEYS_SS_XSL,
        receive: Actions.RECEIVE_REPORT_PAT_JOURNEYS_SS_XSL,
        url: `${baseURL}/patientJourneysSS.xlsx`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_PAT_JOURNEYS_SS},
};

export const RES_REPORT_ACC_ITEMS = {
    GET: {
        request: Actions.REQUEST_REPORT_ACC_ITEM,
        receive: Actions.RECEIVE_REPORT_ACC_ITEM,
        url: `${baseURL}/accountingItem.pdf`
    },
    INV: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.invoice.pdf`
    },
    RCT: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.pdf`
    },
    REF: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.pdf`
    },
    PAY: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.pdf`
    },
    CRN: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.pdf`
    },
    ADJ: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.pdf`
    },
};

export const RES_REPORT_WEEKLY_FTA = {
    GET: {
        request: Actions.REQUEST_REPORT_WEEKLY_FTA,
        receive: Actions.RECEIVE_REPORT_WEEKLY_FTA,
        url: `${baseURL}/weeklyFTA`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_WEEKLY_FTA_PDF,
        receive: Actions.RECEIVE_REPORT_WEEKLY_FTA_PDF,
        url: `${baseURL}/weeklyFTA.pdf`
    },
};

export const RES_REPORT_WEEKLY_XRAY = {
    GET_SUMMARY: {
        request: Actions.REQUEST_REPORT_WEEKLY_XRAY_SUMMARY,
        receive: Actions.RECEIVE_REPORT_WEEKLY_XRAY_SUMMARY,
        url: `${baseURL}/xraySummary`
    },
    SUMMARY_PDF: {
        request: Actions.REQUEST_REPORT_WEEKLY_XRAY_SUMMARY_PDF,
        receive: Actions.RECEIVE_REPORT_WEEKLY_XRAY_SUMMARY_PDF,
        url: `${baseURL}/xraySummary.pdf`
    },
    CLEAR_SUMMARY: {action: Actions.CLEAR_REPORT_WEEKLY_XRAY_SUMMARY},
    GET_REPORT: {
        request: Actions.REQUEST_REPORT_WEEKLY_XRAY_REPORT,
        receive: Actions.RECEIVE_REPORT_WEEKLY_XRAY_REPORT,
        url: `${baseURL}/xrayRecord`
    },
    REPORT_PDF: {
        request: Actions.REQUEST_REPORT_WEEKLY_XRAY_REPORT_PDF,
        receive: Actions.RECEIVE_REPORT_WEEKLY_XRAY_REPORT_PDF,
        url: `${baseURL}/xrayRecord.pdf`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_WEEKLY_XRAY_REPORT_XLS,
        receive: Actions.RECEIVE_REPORT_WEEKLY_XRAY_REPORT_XLS,
        url: `${baseURL}/xrayRecord.xls`
    },
    CLEAR_REPORT: {action: Actions.CLEAR_REPORT_WEEKLY_XRAY_REPORT},
};

export const RES_REPORT_WEEKLY_SEDATION = {
    GET_SUMMARY: {
        request: Actions.REQUEST_REPORT_WEEKLY_SEDATION_SUMMARY,
        receive: Actions.RECEIVE_REPORT_WEEKLY_SEDATION_SUMMARY,
        url: `${baseURL}/sedationSummary`
    },
    SUMMARY_PDF: {
        request: Actions.REQUEST_REPORT_WEEKLY_SEDATION_SUMMARY_PDF,
        receive: Actions.RECEIVE_REPORT_WEEKLY_SEDATION_SUMMARY_PDF,
        url: `${baseURL}/sedationSummary.pdf`
    },
    CLEAR_SUMMARY: {action: Actions.CLEAR_REPORT_WEEKLY_SEDATION_SUMMARY},
    GET_REPORT: {
        request: Actions.REQUEST_REPORT_WEEKLY_SEDATION_REPORT,
        receive: Actions.RECEIVE_REPORT_WEEKLY_SEDATION_REPORT,
        url: `${baseServiceURL}/sedationRecords`
    },
    REPORT_PDF: {
        request: Actions.REQUEST_REPORT_WEEKLY_SEDATION_REPORT_PDF,
        receive: Actions.RECEIVE_REPORT_WEEKLY_SEDATION_REPORT_PDF,
        url: `${baseURL}/sedationRecord.pdf`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_WEEKLY_SEDATION_REPORT_XLS,
        receive: Actions.RECEIVE_REPORT_WEEKLY_SEDATION_REPORT_XLS,
        url: `${baseServiceURL}/sedationRecord.xls`
    },
    CLEAR_REPORT: {action: Actions.CLEAR_REPORT_WEEKLY_SEDATION_REPORT},
};

export const RES_REPORT_VOIDS = {
    GET: {
        request: Actions.REQUEST_REPORT_VOIDS,
        receive: Actions.RECEIVE_REPORT_VOIDS,
        url: `${baseURL}/voids`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_VOIDS_PDF,
        receive: Actions.RECEIVE_REPORT_VOIDS_PDF,
        url: `${baseURL}/voids.pdf`
    },
    DOWNLOAD: {
        request: Actions.REQUEST_REPORT_VOIDS_PDF,
        receive: Actions.RECEIVE_REPORT_VOIDS_PDF,
        url: `${baseServiceURL}/voidedTransactions.xls`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_VOIDS},
};

export const RES_REPORT_IN_CREDIT = {
    GET: {
        request: Actions.REQUEST_REPORT_IN_CREDIT,
        receive: Actions.RECEIVE_REPORT_IN_CREDIT,
        url: `${baseServiceURL}/accountsInCredit`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_IN_CREDIT_PDF,
        receive: Actions.RECEIVE_REPORT_IN_CREDIT_PDF,
        url: `${baseURL}/accountsInCredit.pdf`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_IN_CREDIT_XLS,
        receive: Actions.RECEIVE_REPORT_IN_CREDIT_XLS,
        url: `${baseServiceURL}/accountsInCredit.xls`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_IN_CREDIT}
};

export const RES_REPORT_COP_REPORT1 = {
    XLS: {
        request: Actions.REQUEST_REPORT_COP1_XLS,
        receive: Actions.RECEIVE_REPORT_COP1_XLS,
        url: `${baseServiceURL}/reportCop1Queue`
    },
};

export const RES_REPORT_TREATMENT_CODE_USAGE = {
    GET: {
        request: Actions.REQUEST_REPORT_TREATMENT_CODE_USAGE,
        receive: Actions.RECEIVE_REPORT_TREATMENT_CODE_USAGE,
        url: `${baseServiceURL}/treatmentCodeUsage`
    },
};

export const RES_REPORT_CLINICIAN_USAGE = {
    GET: {
        request: Actions.REQUEST_REPORT_CLINICIAN_USAGE,
        receive: Actions.RECEIVE_REPORT_CLINICIAN_USAGE,
        url: `${baseServiceURL}/timeUtilisation`
    },
};

export const RES_REPORT_COST_CENTER = {
    GET: {
        request: Actions.REQUEST_REPORT_COST_CENTER,
        receive: Actions.RECEIVE_REPORT_COST_CENTER,
        url: `${baseURL}/costCenter`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_COST_CENTER_PDF,
        receive: Actions.RECEIVE_REPORT_COST_CENTER_PDF,
        url: `${baseURL}/costCenter.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_COST_CENTER},
};

export const RES_REPORT_COP_REPORT_9 = {
    GET: {
        request: Actions.REQUEST_REPORT_COP9,
        receive: Actions.RECEIVE_REPORT_COP9,
        url: `${baseURL}/copReport9`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_COP9_XLS,
        receive: Actions.RECEIVE_REPORT_COP9_XLS,
        url: `/api/v1/reports/reportCop9Queue`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_COP9}
};

export const RES_REPORT_DAILY_CANCELS = {
    GET: {
        request: Actions.REQUEST_REPORT_DAILY_CANCELS,
        receive: Actions.RECEIVE_REPORT_DAILY_CANCELS,
        url: `${baseURL}/dailyCancels`
    },
    XLS: {
        url: `${baseServiceURL}/dailyCancelsDeletions.xls`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_DAILY_CANCELS},
};

export const RES_REPORT_DAILY_DELETES = {
    GET: {
        request: Actions.REQUEST_REPORT_DAILY_DELETES,
        receive: Actions.RECEIVE_REPORT_DAILY_DELETES,
        url: `${baseURL}/dailyDeletes`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_DAILY_DELETES},
};

export const RES_REPORT_WEEKLY_CANCELS = {
    GET: {
        request: Actions.REQUEST_REPORT_WEEKLY_CANCELS,
        receive: Actions.RECEIVE_REPORT_WEEKLY_CANCELS,
        url: `${baseURL}/weeklyCancels`
    },
    XLS: {
        url: `${baseServiceURL}/weeklyCancelsDeletions.xls`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_WEEKLY_CANCELS},
};

export const RES_REPORT_WEEKLY_DELETES = {
    GET: {
        request: Actions.REQUEST_REPORT_WEEKLY_DELETES,
        receive: Actions.RECEIVE_REPORT_WEEKLY_DELETES,
        url: `${baseURL}/weeklyDeletes`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_WEEKLY_DELETES},
};

export const RES_REPORT_WEEKLY_REGISTRATIONS = {
    GET: {
        request: Actions.REQUEST_REPORT_WEEKLY_REGISTRATIONS,
        receive: Actions.RECEIVE_REPORT_WEEKLY_REGISTRATIONS,
        url: `${baseURL}/weeklyRegistrations`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_WEEKLY_REGISTRATIONS_PDF,
        receive: Actions.RECEIVE_REPORT_WEEKLY_REGISTRATIONS_PDF,
        url: `${baseURL}/weeklyRegistrations.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_WEEKLY_REGISTRATIONS},
};

export const RES_REPORT_WORK_REQUESTED = {
    GET: {
        request: Actions.REQUEST_REPORT_WORK_REQUESTED,
        receive: Actions.RECEIVE_REPORT_WORK_REQUESTED,
        url: `${baseURL}/workRequested`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_WORK_REQUESTED_PDF,
        receive: Actions.RECEIVE_REPORT_WORK_REQUESTED_PDF,
        url: `${baseURL}/workRequested.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_WORK_REQUESTED},
};

export const RES_REPORT_WORK_REQUIRED_DUE = {
    GET: {
        request: Actions.REQUEST_REPORT_WORK_REQUIRED_DUE,
        receive: Actions.RECEIVE_REPORT_WORK_REQUIRED_DUE,
        url: `${baseURL}/workRequiredDue`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_WORK_REQUIRED_DUE_PDF,
        receive: Actions.RECEIVE_REPORT_WORK_REQUIRED_DUE_PDF,
        url: `${baseURL}/workRequiredDue.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_WORK_REQUIRED_DUE},
};

export const RES_REPORT_WORK_RECEIVED = {
    GET: {
        request: Actions.REQUEST_REPORT_WORK_RECEIVED,
        receive: Actions.RECEIVE_REPORT_WORK_RECEIVED,
        url: `${baseURL}/workReceived`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_WORK_RECEIVED_PDF,
        receive: Actions.RECEIVE_REPORT_WORK_RECEIVED_PDF,
        url: `${baseURL}/workReceived.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_WORK_RECEIVED},
};

export const RES_REPORT_WORK_FOR_APPROVAL = {
    GET: {
        request: Actions.REQUEST_REPORT_WORK_FOR_APPROVAL,
        receive: Actions.RECEIVE_REPORT_WORK_FOR_APPROVAL,
        url: `${baseURL}/workForApproval`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_WORK_FOR_APPROVAL_PDF,
        receive: Actions.RECEIVE_REPORT_WORK_FOR_APPROVAL_PDF,
        url: `${baseURL}/workForApproval.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_WORK_FOR_APPROVAL},
};

export const RES_REPORT_UNINVOICED_CHARGES = {
    GET: {
        request: Actions.REQUEST_REPORT_UNINVOICED_CHARGES,
        receive: Actions.RECEIVE_REPORT_UNINVOICED_CHARGES,
        url: `${baseURL}/uninvoicedCharges`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_UNINVOICED_CHARGES_PDF,
        receive: Actions.RECEIVE_REPORT_UNINVOICED_CHARGES_PDF,
        url: `${baseURL}/uninvoicedCharges.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_UNINVOICED_CHARGES_PDF}
};

export const RES_REPORT_INCOMPLETE_CHARGES = {
    GET: {
        request: Actions.REQUEST_REPORT_INCOMPLETE_CHARGES,
        receive: Actions.RECEIVE_REPORT_INCOMPLETE_CHARGES,
        url: `${baseURL}/incompleteCharges`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_INCOMPLETE_CHARGES_PDF,
        receive: Actions.RECEIVE_REPORT_INCOMPLETE_CHARGES_PDF,
        url: `${baseURL}/incompleteCharges.pdf`
    },
};

export const RES_REPORT_WRITE_OFFS = {
    GET: {
        request: Actions.REQUEST_REPORT_WRITE_OFFS,
        receive: Actions.RECEIVE_REPORT_WRITE_OFFS,
        url: `${baseURL}/writeOffs`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_WRITE_OFFS_PDF,
        receive: Actions.RECEIVE_REPORT_WRITE_OFFS_PDF,
        url: `${baseURL}/writeOffs.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_WRITE_OFFS}
};

export const RES_REPORT_MONTHS_CASH = {
    GET: {
        request: Actions.REQUEST_REPORT_MONTHS_CASH,
        receive: Actions.RECEIVE_REPORT_MONTHS_CASH,
        url: `${baseServiceURL}/monthlyCash`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_MONTHS_CASH_PDF,
        receive: Actions.RECEIVE_REPORT_MONTHS_CASH_PDF,
        url: `${baseURL}/monthsCash.pdf`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_WEEKLY_SHARE_XLS,
        receive: Actions.RECEIVE_REPORT_WEEKLY_SHARE_XLS,
        url: `${baseServiceURL}/monthlyCash.xls`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_MONTHS_CASH}
};

export const RES_REPORT_MONTHLY_SHARE = {
    GET: {
        request: Actions.REQUEST_REPORT_MONTHLY_SHARE,
        receive: Actions.RECEIVE_REPORT_MONTHLY_SHARE,
        url: `${baseServiceURL}/monthlyShare`
    },
    XLS: {
        url: `${baseServiceURL}/monthlyShare.xls`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_MONTHLY_SHARE}
};

// pr-392

export const RES_REPORT_WEEKLY_SHARE = {
    GET: {
        request: Actions.REQUEST_REPORT_WEEKLY_SHARE,
        receive: Actions.RECEIVE_REPORT_WEEKLY_SHARE,
        url: `${baseServiceURL}/weeklyShare`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_WEEKLY_SHARE_PDF,
        receive: Actions.RECEIVE_REPORT_WEEKLY_SHARE_PDF,
        url: `${baseServiceURL}/weeklyShare.pdf`
    },
    XLS: {
        url: `${baseServiceURL}/weeklyShare.xls`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_WEEKLY_SHARE}
};

export const RES_REPORT_TRANS_INVOICES = {
    GET_DAYS: {
        request: Actions.REQUEST_REPORT_TRANS_DAYS_INVOICES,
        receive: Actions.RECEIVE_REPORT_TRANS_DAYS_INVOICES,
        url: `${baseURL}/transInvoices`
    },
    GET_MONTHS: {
        request: Actions.REQUEST_REPORT_TRANS_MONTHS_INVOICES,
        receive: Actions.RECEIVE_REPORT_TRANS_MONTHS_INVOICES,
        url: `${baseServiceURL}/transMonthlyInvoices`
    },
};

export const RES_REPORT_TRANS_RECEIPTS = {
    GET_DAYS: {
        request: Actions.REQUEST_REPORT_TRANS_DAYS_RECEIPTS,
        receive: Actions.RECEIVE_REPORT_TRANS_DAYS_RECEIPTS,
        url: `${baseURL}/transReceipts`
    },
    GET_MONTHS: {
        request: Actions.REQUEST_REPORT_TRANS_MONTHS_RECEIPTS,
        receive: Actions.RECEIVE_REPORT_TRANS_MONTHS_RECEIPTS,
        url: `${baseServiceURL}/transMonthlyReceipts`
    },
};

export const RES_REPORT_TRANS_PAYMENTS = {
    GET_DAYS: {
        request: Actions.REQUEST_REPORT_TRANS_DAYS_PAYMENTS,
        receive: Actions.RECEIVE_REPORT_TRANS_DAYS_PAYMENTS,
        url: `${baseURL}/transPayments`
    },
    GET_MONTHS: {
        request: Actions.REQUEST_REPORT_TRANS_MONTHS_PAYMENTS,
        receive: Actions.RECEIVE_REPORT_TRANS_MONTHS_PAYMENTS,
        url: `${baseServiceURL}/transMonthlyPayments`
    },
};

export const RES_REPORT_TRANS_REFUNDS = {
    GET_DAYS: {
        request: Actions.REQUEST_REPORT_TRANS_DAYS_REFUNDS,
        receive: Actions.RECEIVE_REPORT_TRANS_DAYS_REFUNDS,
        url: `${baseURL}/transRefunds`
    },
    GET_MONTHS: {
        request: Actions.REQUEST_REPORT_TRANS_MONTHS_REFUNDS,
        receive: Actions.RECEIVE_REPORT_TRANS_MONTHS_REFUNDS,
        url: `${baseServiceURL}/transMonthlyRefunds`
    },
};

export const RES_REPORT_DAILY_TRANSACTIONS = {
    GET: {
        request: Actions.REQUEST_REPORT_DAILY_TRANSACTIONS,
        receive: Actions.RECEIVE_REPORT_DAILY_TRANSACTIONS,
        url: `${baseURL}/dailyTransactions`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_DAILY_TRANSACTIONS_PDF,
        receive: Actions.RECEIVE_REPORT_DAILY_TRANSACTIONS_PDF,
        url: `${baseURL}/dailyTransactions.pdf`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_DAILY_TRANSACTIONS_XLS,
        receive: Actions.RECEIVE_REPORT_DAILY_TRANSACTIONS_XLS,
        url: `${baseURL}/dailyTransactions.xls`
    },
    CLEAR: {
        action: Actions.CLEAR_REPORT_DAILY_TRANSACTIONS,
    },
};

export const RES_REPORT_MONTHLY_TRANSACTIONS = {
    GET: {
        request: Actions.REQUEST_REPORT_MONTHLY_TRANSACTIONS,
        receive: Actions.RECEIVE_REPORT_MONTHLY_TRANSACTIONS,
        url: `${baseURL}/dailyTransactions`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_MONTHS_TRANSACTIONS_PDF,
        receive: Actions.RECEIVE_REPORT_MONTHS_TRANSACTIONS_PDF,
        url: `${baseURL}/monthlyTransactions.pdf`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_MONTHS_TRANSACTIONS_XLS,
        receive: Actions.RECEIVE_REPORT_MONTHS_TRANSACTIONS_XLS,
        url: `${baseServiceURL}/monthlyTransactions.xls`
    },
    CLEAR: {
        action: Actions.CLEAR_REPORT_MONTHS_TRANSACTIONS,
    },
};

export const RES_REPORT_YEARS_CASH = {
    GET: {
        request: Actions.REQUEST_REPORT_YEARS_CASH,
        receive: Actions.RECEIVE_REPORT_YEARS_CASH,
        url: `${baseURL}/yearsCash`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_YEARS_CASH_PDF,
        receive: Actions.RECEIVE_REPORT_YEARS_CASH_PDF,
        url: `${baseURL}/yearsCash.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_YEARS_CASH}
};

export const RES_REPORT_YEARS_SHARE = {
    GET: {
        request: Actions.REQUEST_REPORT_YEARS_SHARE,
        receive: Actions.RECEIVE_REPORT_YEARS_SHARE,
        url: `${baseURL}/yearsShare`
    },
    PDF: {
        request: Actions.REQUEST_REPORT_YEARS_CASH_PDF,
        receive: Actions.RECEIVE_REPORT_YEARS_CASH_PDF,
        url: `${baseURL}/yearsShare.pdf`
    },
    CLEAR: {action: Actions.CLEAR_REPORT_YEARS_CASH}
};

export const RES_REPORT_TEXTS = {
    GET: {action: Actions.RECEIVE_RT_REPORT_TEXT, url: `${baseURL}/reportText`},
};

export const RES_OVER_DUE_ACCOUNTS = {
    GET_30: {
        request: Actions.REQUEST_OVER_DUE_30,
        receive: Actions.RECEIVE_OVER_DUE_30,
        url: `${baseServiceURL}/overDueAccounts`
    },
    GET_3160: {
        request: Actions.REQUEST_OVER_DUE_3160,
        receive: Actions.RECEIVE_OVER_DUE_3160,
        url: `${baseServiceURL}/overDueAccounts`
    },
    GET_6190: {
        request: Actions.REQUEST_OVER_DUE_6190,
        receive: Actions.RECEIVE_OVER_DUE_6190,
        url: `${baseServiceURL}/overDueAccounts`
    },
    GET_91: {
        request: Actions.REQUEST_OVER_DUE_91,
        receive: Actions.RECEIVE_OVER_DUE_91,
        url: `${baseServiceURL}/overDueAccounts`
    },
    XLS: {
        request: Actions.REQUEST_REPORT_DAILYCASH_XLS,
        receive: Actions.RECEIVE_REPORT_DAILYCASH_XLS,
        url: `${baseServiceURL}/overDueAccounts.xls`
    },
    CLEAR: {action: Actions.CLEAR_OVER_DUE_ACCOUNTS_PDF}
};

export const getReport = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: {}});
    }
};

export const getReportService = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchReportClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: {}});
    }
};

export const getReportServicePDF = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchReportClient().get(`${url}?mcId=${ac.getMcId()}`, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            },
            params: {...params}
        })
            .then(res => {
                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: {}, ...params});
    }
};

export const getReportPDF = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            },
            params: {...params}
        })
            .then(res => {
                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: {}, ...params});
    }
};

export const getReportXSL = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {
            responseType: '',
            params: {...params}
        })
            .then(res => {
                window.URL.createObjectURL(new Blob([res.data]))
            });
        dispatch({type: request, payload: {}, id: params.id});
    }
};

export const getReportText = ({url, action}, params) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: action, payload: res.data, rtType: params.type})
            });
    }
};

export const getResource = ({url, action}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: action, payload: res.data, ...params})
            });
    }
};
