import React from 'react';
import {
    JawLower,
    JawUpper,
    RootFillColor,
    RootUnfilledColor,
    TMApicectomy,
    TMNone,
    TMRetained,
    TMRootCanal, TMRootCanalApectomy
} from "../Model/Constants";
import {addRootExtras, getToothNumber, getTranslate} from "./ChartingUtils";
import {getRandomKey} from "../Utils/general";
import {ToothBaseComponent} from "./ToothBaseComponent";

const UpperRootPoints = [16,4, 32,4, 32,54, 44,58, 44,88, 4,88, 4,58, 16,54, 16,14];

const UpperChippedRootPoints = [16,4, 32,4, 32,54, 44,58, 44,78, 34,88, 4, 88, 4, 58, 16, 54, 16, 14];

const LowerRootPoints = [4, 4, 44, 4, 44, 34, 32, 38, 32, 88, 16, 88, 16, 38, 4, 34, 4, 4];

const LowerChippedRootPoints = [14, 4, 44, 4, 44, 34, 32, 38, 32, 88, 16, 88, 16, 38, 4, 34, 4, 14, 14, 4];

const UpperPostPoints = [16, 4, 32, 4, 32, 54, 44, 58, 44, 68, 4, 68, 4, 58, 16, 54, 16, 14];

const LowerPostPoints = [4, 24, 44, 24, 44, 34, 32, 38, 32, 88, 16, 88, 16, 38, 4, 34, 4, 24];

const CanalPoints = [22, 4, 26, 4, 26, 50, 22, 50, 22, 4];

const UpperRootApiPoints = [16, 34, 32, 34, 32, 54, 44, 58, 44, 88, 4, 88, 4, 58, 16, 54, 16, 34];

const UpperRootApiPostPoints = [16, 34, 32, 34, 32, 54, 44, 58, 44, 68, 4, 68, 4, 58, 16, 54, 16, 34];

const LowerRootApiPoints = [4, 4, 44, 4, 44, 34, 32, 38, 32, 58, 16, 58, 16, 38, 4, 34, 4, 4];

const LowerRootApiPostPoints = [4, 24, 44, 24, 44, 34, 32, 38, 32, 58, 16, 58, 16, 38, 4, 34, 4, 24];

const CanalApiPoints = [22, 4, 26, 4, 26, 20, 22, 20, 22, 4];

const UpperRetainedRootPoints = [16, 4, 32, 4, 32, 54, 44, 58, 4, 58, 16, 54, 16, 14];

const LowerRetainedRootPoints = [44, 34, 32, 38, 32, 88, 16, 88, 16, 38, 4, 34, 44, 34];

export class AdultIncisor extends ToothBaseComponent {

    constructor(props) {
        super();
        this.state = {
            cursor: '',
        };
        this.points = [];
        this.canal = [];
        this.fracturePoints = [];
        this.canalFill = RootUnfilledColor;

        this.rootPoints = [];
        this.rootApiPoints = [];
        this.canalPoints = [];
        this.canalApiPoints = [];
        this.retainedRootPoints = [];
        this.postPoints = [];
        this.rootApiPostPoints = [];

        this.translate = '';
        this.canalTranslate = '';
        this.labelTranslate = '';

        switch (props.data.jaw) {
            case JawUpper:
                if (props.data.chipped)
                    this.setPoints(UpperChippedRootPoints, UpperRootApiPoints, CanalPoints, CanalApiPoints, UpperRetainedRootPoints, UpperPostPoints, UpperRootApiPostPoints);
                else
                    this.setPoints(UpperRootPoints, UpperRootApiPoints, CanalPoints, CanalApiPoints, UpperRetainedRootPoints, UpperPostPoints, UpperRootApiPostPoints);
                break;
            case JawLower:
                if (props.data.chipped)
                    this.setPoints(LowerChippedRootPoints, LowerRootApiPoints, CanalPoints, CanalApiPoints, LowerRetainedRootPoints, LowerPostPoints, LowerRootApiPostPoints);
                else
                    this.setPoints(LowerRootPoints, LowerRootApiPoints, CanalPoints, CanalApiPoints, LowerRetainedRootPoints, LowerPostPoints, LowerRootApiPostPoints);
                break;
            default:
                break;

        }
    }

    setPoints(rootPoints, rootApiPoints, canalPoints, canalApiPoints, retainedRootPoints, postPoints, rootApiPostPoints) {

        this.rootPoints = rootPoints;
        this.rootApiPoints = rootApiPoints;

        this.canalPoints = canalPoints;
        this.canalApiPoints = canalApiPoints;

        this.retainedRootPoints = retainedRootPoints;

        this.postPoints = postPoints;
        this.rootApiPostPoints = rootApiPostPoints;
    };

    addRoot(modifier, post) {

        switch (modifier) {
            case TMRetained :
                this.points = this.retainedRootPoints;
                break;
            case TMNone :
            case TMRootCanal :
                this.points = post ? this.postPoints : this.rootPoints;
                break;
            case TMApicectomy :
                this.points = post ? this.rootApiPostPoints : this.rootApiPoints;
                break;
            case TMRootCanalApectomy :
                this.points = post ? this.rootApiPostPoints : this.rootApiPoints;
                break;
            default:
                break;
        }
    };

    addCanal(modifier) {

        switch (modifier) {
            case TMRetained :
            case TMNone :
            case TMRootCanal :
                this.canal = this.canalPoints;
                break;
            case TMApicectomy :
            case TMRootCanalApectomy :
                this.canal = this.canalApiPoints;
                break;
            default:
                break;
        }
        this.canalFill = modifier === (TMRootCanal || TMRootCanalApectomy) ? RootFillColor : RootUnfilledColor;
    }

    render() {

        const data = this.props.data;

        this.translate = getTranslate(data, 1, 0, 1, 57, false);
        this.labelTranslate = getTranslate(data, 17, 77, 17, 78, false);
        this.canalTranslate = getTranslate(data, 1, 0, 1, 95, true);

        this.addRoot(data.tm, data.post.present || data.postAndCore.present);
        this.addCanal(data.tm);

        const bgColour = this.props.data.coreMaterial === undefined ? RootUnfilledColor : '';

        return (
            <g key={getRandomKey()} onContextMenu={(event) => {

                event.preventDefault();

                if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
                    this.props.data.currentCommand.setTarget(this.props.data);
                }
                this.props.setCurrentItem(this.props.data);
                this.props.cm(event);
            }}
               onMouseEnter={() => this.handleEnter()}
               onMouseLeave={() => this.handleLeave()}

               style={{cursor: this.state.cursor}}

               onClick={() => {
                   if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
                       this.props.onClick(this.props.data);
                   }
               }}
            >
                <polygon
                    points={this.points}
                    style={{fill: bgColour, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={this.translate}
                />
                <polygon
                    points={this.canal}
                    style={{fill: this.canalFill, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={this.canalTranslate}
                />

                {addRootExtras(this.props.data)}

                <text x={0}
                      y={0}
                      fill={'black'}
                      fontSize={10}
                      transform={this.labelTranslate}>

                    {getToothNumber(data)}

                </text>
            </g>
        )
    }
}