import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {RadioButton} from 'primereact/radiobutton';
import {
    calendar,
    checkBox,
    dropDown,
    genders,
    inputCurrencyText,
    inputText
} from "../../../../PatientDynamicItems/OnChangeUtils";
import {
    EDI_ST_Stacked,
    PART2_ACT_DELETE,
    PART2_ACT_RESUB_REPLACE,
    PART2_ACT_SQIND,
    PART2_CLAIM_ACTION
} from "../../Constants";
import _ from "lodash";
import {SelectButton} from "primereact/selectbutton";
import {currencyConverter} from "../../../fixedItemUtils";
import {SU_STATUS_ACTIVE} from "../../../../../Constants";

const showOptionalClaimAction = ({status}, props) => {

    if (status !== EDI_ST_Stacked) {

        const resubmitReplace = props.claimAction.resubmitReplaceOrDelete;
        const deleteIt = !props.claimAction.resubmitReplaceOrDelete;

        return (
            <Panel header={PART2_CLAIM_ACTION}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-6">
                        <RadioButton checked={resubmitReplace}
                                     onChange={(e) => {
                                         props.onChange({
                                             owner: `claimAction.resubmitReplaceOrDelete`,
                                             value: !resubmitReplace
                                         })
                                     }}
                        />
                        <label style={{fontSize: 'small'}}
                               className='p-radiobutton-label'>{PART2_ACT_RESUB_REPLACE}</label>
                    </div>
                    <div className="p-col-6">
                        {checkBox(props, 'SQInd', PART2_ACT_SQIND, false, false)}
                    </div>

                    <div className="p-col-6">
                        <RadioButton checked={deleteIt}
                                     onChange={(e) => {
                                         props.onChange({
                                             owner: `claimAction.resubmitReplaceOrDelete`,
                                             value: !resubmitReplace
                                         })
                                     }}
                        />
                        <label style={{fontSize: 'small'}} className='p-radiobutton-label'>{PART2_ACT_DELETE}</label>
                    </div>
                </div>
            </Panel>
        )
    } else return null;
};

export const Part2Content = (props) => {

    const claimProps = {
        onChange: props.onChange,
        target: 'claim',
        claim: props.claim,
    };

    const nhsDetailsProps = {
        onChange: props.onChange,
        target: 'NHSRegistration',
        NHSRegistration: props.NHSRegistration
    };

    const patientProps = {
        onChange: props.onChange,
        titles: props.titles,
        target: 'patient',
        patient: props.patient
    };

    const actionProps = {
        onChange: props.onChange,
        target: 'claimAction',
        claimAction: props.claimAction,
    };

    const gender = _.find(genders, gender=> gender.label === props.patient.title.genderType);

    const nhsNumberColor = props.NHSRegistration.nhsNumber === "0000000000" ? "red" : "";
    
    return (
        <Panel header='Part 2'>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-3" style={{color: nhsNumberColor}}>
                    <label htmlFor="nhsNumber">NHS Number</label>
                </div>
                <div className="p-col-9">
                    {inputText(nhsDetailsProps, 'nhsNumber', 'NHS Number', false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="title">Title</label>
                </div>
                <div className="p-col-9">
                    {dropDown(patientProps, 'titles', 'title', 'abbreviation', false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="surname">Surname</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'surname', 'Surname', false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="forename">Forename</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'forename', 'Forename', false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='line1'>House number or name</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine1', 'Street', false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='line2'>Street</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine2', 'Street', false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='city'>City</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine3', 'City or Town', false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='county'>County</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine4', 'County', false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='postcode'>Postcode</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine5', 'Postcode', false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="previousSurname">Previous Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(nhsDetailsProps, 'previousSurname', 'Previous Surname', false, false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="gender">Gender</label>
                </div>
                <div className="p-col-9">
                    <SelectButton value={gender.value}
                                  options={genders}
                                  onChange={(e) => {
                                      const gender = _.find(genders, gender=> gender.value === e.value);
                                      if (e.value !== null) // null value indicates no change
                                        props.onChange({owner: `patient.title.genderType`, value: gender.label})
                                  }}
                    />
                </div>
                <div className="p-col-3">
                    <label htmlFor="dob">Date of Birth</label>
                </div>
                <div className="p-col-9">
                    {calendar(patientProps, 'dob')}
                </div>
                <div className="p-col-3">
                    <label htmlFor="dob">Patient Charge</label>
                </div>
                <div className="p-col-9">
                    {inputCurrencyText(claimProps, 'chxPatientCharge', '', false, true)}
                </div>
            </div>
            {/*{showOptionalClaimAction(props.claim, actionProps)}*/}
        </Panel>
    )
};
