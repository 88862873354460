import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    NOTE_Action, NOTE_Complaint,
    NOTE_Info, NOTE_Medical,
    NOTE_TreatmentCode,
    NOTE_Warning,
    PN_Add_NoteType,
    PN_Info
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import * as DefaultData from "../../DefaultData";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {ScrollPanel} from "primereact/scrollpanel";
import {InputText} from "primereact/inputtext";
import {Editor} from "primereact/editor";

export default class AddPatientNote extends Component {

    constructor(props) {
        super(props);

        this.state = {
            patientNote: props.editing ? {...props.item} : _.cloneDeep(DefaultData.PatientNote(ac.getMcId(), props)),
            templateNote: null,

            globalFilter: '',
            flip: false,
        };

        this.editor = null;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        switch (event.owner) {
            case 'patientNote.type' :
                if (event.value === PN_Info.value) {
                    _.set(state, 'patientNote.popUp', false);
                } else {
                    _.set(state, 'patientNote.popUp', true);
                }
                break;
            default:
                break;
        }
        this.setState(state);
    }

    dealWithInsert = (e) => {

        try {
            let patientNote = {...this.state.patientNote};

            const position = this.editor.quill.getSelection().index;
            const delta =this.editor.quill.clipboard.convert(e.data.content);
            this.editor.quill.insertText(position, delta.ops[0].insert)

            patientNote.content = this.editor.quill.getContents().ops[0].insert;

            this.setState({patientNote});
        } catch (error) {
            console.log(error);
        }
    }

    onDoubleClick = (e) => {

        this.editor.quill.focus();
        
        this.setState({flip: !this.state.flip}, () => {
            this.dealWithInsert(e);
        });
    }

    onHide = () => {
        this.props.onHideDialog(this.props.target);
    }

    render() {

        if (this.state.patientNote === null)
            return null;

        const patNote = this.state.patientNote;
        const content = this.state.patientNote.content;
        const noteContent = this.editor === null ? content : this.editor.quill.root.innerHTML;

        const patNoteDefined = !(patNote.subject !== '' && noteContent !== '');
        const readOnly = patNote.type !== NOTE_Action.type && patNote.type !== NOTE_Warning.type && patNote.type !== NOTE_Info.type && patNote.type !== NOTE_TreatmentCode.type && patNote.type !== NOTE_Medical.type && patNote.type !== NOTE_Complaint.type;

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            const patientNote = {...this.state.patientNote};
                            patientNote.content = this.editor.quill.root.innerHTML;
                            this.props.onOkDialog(patientNote)
                        }}
                        disabled={patNoteDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            editing: this.props.editing,
            onChange: this.onChange,
            target: 'patientNote',
            patientNote: this.state.patientNote,
        };

        let templates = _.filter(this.props.templateNotes, template => {
            const pattern = `${template.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'name', 'asc');

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, first: 0});
                       }}
                       placeholder="Search"
                       autoFocus
            />
            <Dropdown options={[]}/>
        </div>;

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <div className="p-grid p-fluid form-group">

                                <div className="p-col-2">
                                    <label>Subject</label>
                                </div>
                                <div className="p-col-10">
                                    {inputText(props, 'subject', 'Title', false, true)}
                                </div>
                                <div className="p-col-2">
                                    <label>Type</label>
                                </div>
                                <div className="p-col-10">
                                    <Dropdown value={patNote.type}
                                              options={PN_Add_NoteType}
                                              disabled={readOnly}
                                              onChange={(e) => {
                                                  this.onChange({owner: 'patientNote.type', value: e.value});
                                              }}
                                              autoWidth={false}
                                    />
                                </div>
                                <div className="p-col-12">
                                    <Editor value={content}
                                            style={{height: '300px'}}
                                            ref={el => this.editor = el}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-6">
                            <ScrollPanel style={{width: '100%', height: '300px'}} className='custom'>

                                <DataTable value={sortedTemplates}
                                           className='p-datatable-gridlines'
                                           header={tableHeader}
                                           selectionMode="single"
                                           selection={this.state.templateNote}
                                           onRowDoubleClick={this.onDoubleClick}
                                           onSelectionChange={
                                               (e) => this.setState({templateNote: e.value})
                                           }
                                >
                                    <Column field="name"/>
                                </DataTable>
                            </ScrollPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
