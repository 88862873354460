import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import moment from "moment";
import {ac} from '../../../../index';

import {Accordion, AccordionTab} from 'primereact/components/accordion/Accordion';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Toast} from 'primereact/toast';
import {setState, stateRequest} from "../../../../actions/stateManagement";
import {getPatientIds, printDocumentItem} from "../Utils";
import EditDocumentInfo from '../dialogs/EditDocumentInfo'
import {
    deleteDocument,
    openDocument,
    previewPatientDocument,
    RES_PATIENT_DOCUMENTS,
    RES_sendPatientDocument,
    sendPatientDocument
} from "../../../../actions/personal";
import {
    DOC_ANY,
    DOC_APPOINTMENT_LETTER,
    DOC_CONSENT,
    DOC_Email,
    DOC_GDPR_CONSENT,
    DOC_IMAGE,
    DOC_ORTHO_REFERRAL,
    DOC_PAYMENT_PLAN,
    DOC_PAYMENT_PLAN_PROPOSAL,
    DOC_PDF,
    DOC_PRESCRIPTION,
    DOC_REFERRAL,
    DOC_SMS,
    DOC_SMS_INCOMING,
    DOC_SMS_OUTGOING,
    DOC_TREATMENT_PLAN,
    DOC_UPLOAD_MSWORD,
    DOC_UPLOAD_SCAN_GIF,
    DOC_UPLOAD_SCAN_JPG,
    DOC_UPLOAD_SCAN_PDF,
    DOC_UPLOAD_SCAN_TIF,
    DOC_UPLOAD_WORD,
    DOC_UPLOAD_XRAY_GIF,
    DOC_UPLOAD_XRAY_JPG,
    DOC_UPLOAD_XRAY_TIF,
    DOC_WORD,
    DOC_XRAY,
    HM_DeletePatientDocument,
    HM_DeletePaymentPlanDocument,
    HM_DeletePresDocument,
    HM_DeleteReferralDocument,
    HM_DeleteTreatmentPlanDocument,
    HM_DeleteXrayScanDocument,
    HM_EditConsentDocument,
    HM_EditEmailSMS,
    HM_EditPatientDocument,
    HM_EditPaymentPlanDocument,
    HM_EditPresDocument,
    HM_EditReferralDocument,
    HM_EditTreatmentPlanDocument,
    HM_EditXrayScanDocument,
    HM_notImplemented,
    HM_PreviewPatientConsent,
    HM_PreviewPatientDocument,
    HM_PreviewPatientPaymentPlan,
    HM_PreviewPatientPrescription,
    HM_PreviewPatientReferral,
    HM_PreviewPatientTreatmentPlan,
    HM_PreviewPatientXray,
    HM_PrintConsentDocument,
    HM_PrintPatientDocument,
    HM_PrintPaymentPlanDocument,
    HM_PrintPresDocument,
    HM_PrintReferralDocument,
    HM_PrintTreatmentPlanDocument,
    UP_DOCUMENT_ADDED,
    UP_EMAIL_DOCUMENT,
    UP_EMAIL_DOCUMENT_FAILED,
    UP_EMAIL_DOCUMENT_PREVIEW
} from "../../../../Constants";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {ShowMessageDialog, ShowQuestionDialog} from "../../Diary/components/EventComponent";

import * as Actions from "../../../../actions";
import {ProgressBar} from "primereact/progressbar";
import {menuButtonTemplate} from "../../fixedItemUtils";
import PatientUploadedPDF from "../PDFs/PatientUploadedPDF";
import {
    buildFlattenedItemsMenu,
    buildPatientConsentItemsMenu,
    buildPatientSMSEmailItemsMenu,
    buildPrescriptionDocumentsItemsMenu,
    buildTPPPDocumentsItemsMenu,
    CM_DOCUMENTS_CONSENT,
    CM_DOCUMENTS_FLATTENED,
    CM_DOCUMENTS_PRES,
    CM_DOCUMENTS_SMS_EMAIL,
    CM_DOCUMENTS_TP,
    docClassificationTemplate,
    docTypeTemplateFlattened,
    emailSMSTemplate
} from "../Constants";
import PreviewPatientDocument from "../dialogs/PreviewPatientDocument";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {ICON_CLOSE} from "../../../../icons";
import {DocumentBaseComponent} from "./DocumentBaseComponent";

class ConnectedDocumentsFlattenedSection extends DocumentBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                activeIndex: 0,
                stateManagementId: props.patientDocumentsId,
                patientId: props.patientId,

                showImage: false,
                flattenedDocumentsSelectValue: null,

                xrayDocuments: [],
                xrayDocumentsSelectValue: null,
                firstImage: 0,
                imageRows: 10,

                patientDocuments: [],
                firstDoc: 0,
                docRows: 5,

                presDocuments: [],
                presDocumentsSelectValue: null,
                firstPres: 0,
                presRows: 5,

                refDocuments: [],
                refDocumentsSelectValue: null,
                firstRef: 0,
                refRows: 5,

                tpDocuments: [],
                tpDocumentsSelectValue: null,
                firstTp: 0,
                tpRows: 5,

                smsEmailDocuments: [],
                smsEmailDocumentsSelectValue: null,
                firstSMS: 0,
                SMSRows: 5,

                consentFormDocuments: [],
                consentFormDocumentsSelectValue: null,
                firstConsent: 0,
                consentRows: 5,

                eventTarget: null,

                contextMenus: [
                    {tag: CM_DOCUMENTS_FLATTENED, menu: null},
                    {tag: CM_DOCUMENTS_PRES, menu: null},
                    {tag: CM_DOCUMENTS_TP, menu: null},
                    {tag: CM_DOCUMENTS_SMS_EMAIL, menu: null},
                    {tag: CM_DOCUMENTS_CONSENT, menu: null},
                ],
            };
        }

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
            [HM_PrintPatientDocument.id]: (menu) => this.onPrintDocument(menu),

            [HM_PreviewPatientDocument.id]: this.onRequestPreviewDocument
        };

        this.emailSendGrowl = null;
    }

    componentDidMount() {

        if (this.props.patientDocumentsLoaded) {
            this.processDocuments();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_DOCUMENT_ADDED:

                            const document = {...this.props.wsmessage.content};

                            // this filters out other patients updates
                            if (parseInt(this.state.patientId, 10) === document.patientId) {

                                switch (document.type) {

                                    case DOC_WORD.name:
                                    case DOC_ANY.name:
                                    case DOC_UPLOAD_MSWORD.name:
                                    case DOC_UPLOAD_WORD.name:
                                    case DOC_PDF.name:
                                    case DOC_APPOINTMENT_LETTER.name:
                                        const patientDocuments = [...this.state.patientDocuments];
                                        patientDocuments.push(document);
                                        this.setState({
                                            patientDocuments,
                                        });
                                        break;
                                    case DOC_PRESCRIPTION.name:
                                        const presDocuments = [...this.state.presDocuments];
                                        presDocuments.push(document);
                                        this.setState({
                                            presDocuments,
                                        });
                                        break;
                                    case DOC_ORTHO_REFERRAL.name:
                                    case DOC_REFERRAL.name:
                                        const refDocuments = [...this.state.refDocuments];
                                        refDocuments.push(document);
                                        this.setState({
                                            refDocuments,
                                        });
                                        break;
                                    case DOC_UPLOAD_XRAY_TIF.name:
                                    case DOC_UPLOAD_XRAY_JPG.name:
                                    case DOC_UPLOAD_XRAY_GIF.name:
                                    case DOC_UPLOAD_SCAN_TIF.name:
                                    case DOC_UPLOAD_SCAN_JPG.name:
                                    case DOC_UPLOAD_SCAN_GIF.name:
                                    case DOC_UPLOAD_SCAN_PDF.name:
                                    case DOC_IMAGE.name:
                                        const xrayDocuments = [...this.state.xrayDocuments];
                                        xrayDocuments.push(document);
                                        this.setState({
                                            xrayDocuments,
                                        });
                                        break;
                                    case DOC_TREATMENT_PLAN.name:
                                    case DOC_PAYMENT_PLAN.name:
                                        const tpDocuments = [...this.state.tpDocuments];
                                        tpDocuments.push(document);
                                        this.setState({
                                            tpDocuments,
                                        });
                                        break;
                                    case DOC_SMS.name:
                                    case DOC_SMS_INCOMING.name:
                                    case DOC_SMS_OUTGOING.name:
                                    case DOC_Email.name:
                                        const smsEmailDocuments = [...this.state.smsEmailDocuments];
                                        smsEmailDocuments.push(document);
                                        this.setState({
                                            smsEmailDocuments,
                                        });
                                        break;
                                    case DOC_CONSENT.name:
                                    case DOC_GDPR_CONSENT.name:
                                        const consentFormDocuments = [...this.state.consentFormDocuments];
                                        consentFormDocuments.push(document);
                                        this.setState({
                                            consentFormDocuments
                                        });
                                        break;
                                    default:
                                        console.log(document.type);
                                        break;
                                }
                                break;
                            }
                            break;

                        case UP_EMAIL_DOCUMENT_PREVIEW:

                            const previewToken = this.props.wsmessage.content[6];

                            if (previewToken === this.state.previewToken) {

                                const previewCreated = this.props.wsmessage.content[4];

                                if (previewCreated) {
                                    const documentDetails = [...this.props.wsmessage.content];

                                    if (Array.isArray(documentDetails[5])) {
                                        documentDetails[5] = documentDetails[5][0];
                                    }
                                    this.setState({
                                        documentDetails,
                                        [HM_PreviewPatientDocument.id]: true,
                                        previewToken
                                    });
                                } else {
                                    if (this.props.wsmessage.content[1] === null) {// then this is a patient document
                                        this.emailSendGrowl.show({
                                            severity: 'warn',
                                            summary: 'No Patient Email Address',
                                            detail: 'This patient doesn\'t have a registered email address'
                                        });
                                    } else {
                                        this.emailSendGrowl.show({
                                            severity: 'warn',
                                            summary: 'No Referrer Email Address',
                                            detail: 'This referrer doesn\'t have a registered email address'
                                        });
                                    }
                                }
                            }
                            break;

                        case UP_EMAIL_DOCUMENT: {

                            const idToken = this.props.wsmessage.content[1];

                            if (idToken === this.state.idToken) {
                                this.emailSendGrowl.show({
                                    severity: 'info',
                                    summary: 'Success',
                                    detail: 'Document Successfully Emailed'
                                });

                                const document = this.props.wsmessage.content[2];

                                const smsEmailDocuments = [...this.state.smsEmailDocuments];
                                smsEmailDocuments.push(document);
                                this.setState({
                                    smsEmailDocuments,
                                });
                            }
                            break;
                        }
                        case UP_EMAIL_DOCUMENT_FAILED: {

                            const idToken = this.props.wsmessage.content[1];

                            if (idToken === this.state.idToken) {
                                this.emailSendGrowl.show({
                                    severity: 'error',
                                    summary: 'Failure',
                                    detail: 'Failed to Email Document'
                                });
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    break;

                case RES_PATIENT_DOCUMENTS.OPEN.action:

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(new Blob([this.props.documentData]), this.props.filename); // for IE browser
                    } else {
                        const url = window.URL.createObjectURL(new Blob([this.props.documentData]));
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = this.props.filename;
                        a.target = '_blank';
                        a.click();
                    }
                    break;
                case RES_PATIENT_DOCUMENTS.GET.receive:
                case RES_PATIENT_DOCUMENTS.DELETE.action:

                    this.processDocuments()
                    break;
                default:
                    break;
            }
        }
    }

    onRequestPreviewDocument = (target) => {

        const previewToken = Math.random().toString(36).slice(2);

        this.setState({eventTarget: target.id, previewToken}, () => {

            switch (target.id) {
                case HM_PreviewPatientXray.id:
                    this.props.previewPatientDocument(this.state.flattenedDocumentsSelectValue, previewToken);
                    break;
                case HM_PreviewPatientDocument.id:
                    this.props.previewPatientDocument(this.state.flattenedDocumentsSelectValue, previewToken);
                    break;
                case HM_PreviewPatientPrescription.id:
                    this.props.previewPatientDocument(this.state.presDocumentsSelectValue, previewToken);
                    break;
                case HM_PreviewPatientReferral.id:
                    this.props.previewPatientDocument(this.state.flattenedDocumentsSelectValue, previewToken);
                    break;
                case HM_PreviewPatientTreatmentPlan.id:
                    this.props.previewPatientDocument(this.state.tpDocumentsSelectValue, previewToken);
                    break;
                case HM_PreviewPatientPaymentPlan.id:
                    this.props.previewPatientDocument(this.state.tpDocumentsSelectValue, previewToken);
                    break;
                case HM_PreviewPatientConsent.id:
                    this.props.previewPatientDocument(this.state.consentFormDocumentsSelectValue, previewToken);
                    break;
                default:
                    break;
            }
        });
    }

    onRequestSendDocument = (details) => {

        const idToken = Math.random().toString(36).slice(2);

        this.setState({[HM_PreviewPatientDocument.id]: false, idToken}, () => {
            this.props.sendPatientDocument(details[5], details[2], idToken)
        });
    }

    onPrintDocument = (menu) => {

        let selection = null;

        switch (menu.target) {
            case HM_PrintPatientDocument.id:
                selection = this.state.flattenedDocumentsSelectValue;
                break;
            case HM_PrintPresDocument.id:
                selection = this.state.presDocumentsSelectValue;
                break;
            case HM_PrintReferralDocument.id:
                selection = this.state.refDocumentsSelectValue;
                break;
            case HM_PrintTreatmentPlanDocument.id:
                selection = this.state.tpDocumentsSelectValue;
                break;
            case HM_PrintPaymentPlanDocument.id:
                selection = this.state.tpDocumentsSelectValue;
                break;
            case HM_PrintConsentDocument.id:
                selection = this.state.consentFormDocumentsSelectValue;
                break;
            default:
                break;
        }

        const options = {
            itemId: selection.docId,
            report: menu.report,
            reportFunction: menu.reportFunction,
            target: menu.target,
            type: selection.type,
            filename: selection.filename,
        };

        printDocumentItem({options}, this.emailSendGrowl);
    }

    onDeleteDocument = () => {

        switch (this.state.eventTarget) {
            case HM_DeletePatientDocument.id:
                this.props.deleteDocument(this.state.patientId, HM_DeletePatientDocument.id, this.state.flattenedDocumentsSelectValue.docId);
                break;
            case HM_DeleteXrayScanDocument.id:
                this.props.deleteDocument(this.state.patientId, HM_DeleteXrayScanDocument.id, this.state.flattenedDocumentsSelectValue.docId);
                break;
            case HM_DeleteReferralDocument.id:
                this.props.deleteDocument(this.state.patientId, HM_DeleteReferralDocument.id, this.state.flattenedDocumentsSelectValue.docId);
                break;
            case HM_DeletePresDocument.id:
                this.props.deleteDocument(this.state.patientId, HM_DeletePresDocument.id, this.state.presDocumentsSelectValue.docId);
                break;
            case HM_DeleteTreatmentPlanDocument.id:
                this.props.deleteDocument(this.state.patientId, HM_DeleteTreatmentPlanDocument.id, this.state.tpDocumentsSelectValue.docId);
                break;
            case HM_DeletePaymentPlanDocument.id:
                this.props.deleteDocument(this.state.patientId, HM_DeletePaymentPlanDocument.id, this.state.tpDocumentsSelectValue.docId);
                break;
            default:
                break;
        }
        this.onHideMenuEvent(this.state.eventTarget);
    }

    onSelectionChange = (e, owner) => {

        this.setState({[owner]: e.value}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
        });
    };

    onDocumentsDoubleClick = (event, owner) => {

        this.setState({[owner]: event.data}, () => {

            this.props.openDocument(event.data);
        });
    };

    onOnlyOfficeOpen = ({filename, visibleName, type, docId}) => {

        switch (type) {
            case DOC_UPLOAD_SCAN_PDF.name:
            case DOC_PDF.name:
                this.props.onPCButtonClick(menuButtonTemplate(this.props, PatientUploadedPDF, docId, 'fas fa-file-pdf', `${visibleName}`, null, {
                    filename,
                    visibleName,
                    type,
                    docId
                }));
                break;
            default:
                const documentData = {
                    chiralServer: ac.getBASERESTURL(),
                    mcid: ac.getMcId(),
                    filename,
                    docname: visibleName,
                    type
                };

                const key = Math.random().toString(36).slice(2);
                const key2 = "1234";

                localStorage.setItem(key2, JSON.stringify(documentData));

                window.open(`https://${ac.getChiralServer()}/documentLoader.html?id=${key}`, '_blank');
                break;
        }
    }

    onImageOpen = () => {

        const {filename, visibleName, type, docId} = this.state.flattenedDocumentsSelectValue;

        if (type === DOC_PDF.name || type === DOC_UPLOAD_SCAN_PDF.name) {
            this.props.onPCButtonClick(menuButtonTemplate(this.props, PatientUploadedPDF, docId, 'fas fa-file-pdf', `${visibleName}`, null, {
                filename,
                visibleName,
                type,
                docId
            }));
        } else {

            const imageData = {
                chiralServer: ac.getBASERESTURL(),
                mcid: ac.getMcId(),
                filename,
                docname: visibleName,
                token: ac.getAuthTokenRaw(),
            };

            const key = Math.random().toString(36).slice(2);
            const key2 = "1234";

            localStorage.setItem(key2, JSON.stringify(imageData));
            const win = window.open(`https://${ac.getChiralServer()}/imageLoader.html?id=${key}`, '_blank');
            win.focus();
        }
    }

    onUpdateDocumentInfo = (targetId, patientDocument) => {

        patientDocument.edited = true;

        let patientDocuments = [];
        let documentGroup = '';

        switch (patientDocument.type) {

            case DOC_XRAY.name:
            case DOC_IMAGE.name:
            case DOC_UPLOAD_SCAN_JPG.name:
            case DOC_UPLOAD_SCAN_GIF.name:
            case DOC_UPLOAD_SCAN_TIF.name:
            case DOC_UPLOAD_SCAN_PDF.name:
            case DOC_UPLOAD_XRAY_JPG.name:
            case DOC_UPLOAD_XRAY_GIF.name:
            case DOC_UPLOAD_XRAY_TIF.name:
                patientDocuments = [...this.state.xrayDocuments];
                documentGroup = 'xrayDocuments'
                break;

            case DOC_PDF.name:
            case DOC_WORD.name:
            case DOC_UPLOAD_WORD.name:
            case DOC_UPLOAD_MSWORD.name:
                patientDocuments = [...this.state.patientDocuments];
                documentGroup = 'patientDocuments'
                break;

            case DOC_PRESCRIPTION.name:
                patientDocuments = [...this.state.presDocuments];
                documentGroup = 'presDocuments'
                break;

            case DOC_REFERRAL.name:
            case DOC_ORTHO_REFERRAL.name:
                patientDocuments = [...this.state.refDocuments];
                documentGroup = 'refDocuments'
                break;

            case DOC_TREATMENT_PLAN.name:
            case DOC_PAYMENT_PLAN.name:
            case DOC_PAYMENT_PLAN_PROPOSAL.name:
                patientDocuments = [...this.state.tpDocuments];
                documentGroup = 'tpDocuments'
                break;

            case DOC_SMS.name:
            case DOC_Email.name:
                patientDocuments = [...this.state.smsEmailDocuments];
                documentGroup = 'smsEmailDocuments'
                break;

            case DOC_CONSENT.name:
            case DOC_GDPR_CONSENT.name:
                patientDocuments = [...this.state.consentFormDocuments];
                documentGroup = 'consentFormDocuments'
                break;
            default:
                return;
        }
        const index = _.findIndex(patientDocuments, document => document.docId === patientDocument.docId);

        patientDocuments[index] = patientDocument;

        this.setState({[documentGroup]: patientDocuments, [targetId]: false}, () => {
            this.props.onChange({owner: targetId, value: null});
            this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
        });
    }

    onPrescriptionsOpen = () => {
        const {filename, visibleName} = this.state.presDocumentsSelectValue;

        const presData = {
            chiralServer: ac.getBASERESTURL(),
            mcid: ac.getMcId(),
            filename,
            docname: visibleName,
            type: DOC_PRESCRIPTION.name
        };

        const key = Math.random().toString(36).slice(2);
        const key2 = "1234";

        localStorage.setItem(key2, JSON.stringify(presData));

        const win = window.open(`https://${ac.getChiralServer()}/documentLoader.html?id=${key}`, '_blank');
        win.focus();
    }

    showDialogs = () => {

        const contents = [];

        if (this.state[HM_PreviewPatientDocument.id]) {
            contents.push(
                <PreviewPatientDocument documentDetails={this.state.documentDetails}
                                        targetId={HM_PreviewPatientDocument.id}
                                        onOkDialog={this.onRequestSendDocument}
                                        onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state[HM_EditXrayScanDocument.id]) {
            contents.push(
                <EditDocumentInfo patientDocument={this.state.flattenedDocumentsSelectValue}
                                  targetId={HM_EditXrayScanDocument.id}
                                  onOkDialog={this.onUpdateDocumentInfo}
                                  onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state[HM_EditPatientDocument.id]) {
            contents.push(
                <EditDocumentInfo patientDocument={this.state.flattenedDocumentsSelectValue}
                                  targetId={HM_EditPatientDocument.id}
                                  onOkDialog={this.onUpdateDocumentInfo}
                                  onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state[HM_EditPresDocument.id]) {
            contents.push(
                <EditDocumentInfo patientDocument={this.state.presDocumentsSelectValue}
                                  targetId={HM_EditPresDocument.id}
                                  onOkDialog={this.onUpdateDocumentInfo}
                                  onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state[HM_EditReferralDocument.id]) {
            contents.push(
                <EditDocumentInfo patientDocument={this.state.flattenedDocumentsSelectValue}
                                  targetId={HM_EditReferralDocument.id}
                                  onOkDialog={this.onUpdateDocumentInfo}
                                  onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state[HM_EditTreatmentPlanDocument.id]) {
            contents.push(
                <EditDocumentInfo patientDocument={this.state.tpDocumentsSelectValue}
                                  targetId={HM_EditTreatmentPlanDocument.id}
                                  onOkDialog={this.onUpdateDocumentInfo}
                                  onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state[HM_EditPaymentPlanDocument.id]) {
            contents.push(
                <EditDocumentInfo patientDocument={this.state.tpDocumentsSelectValue}
                                  targetId={HM_EditPaymentPlanDocument.id}
                                  onOkDialog={this.onUpdateDocumentInfo}
                                  onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state[HM_EditEmailSMS.id]) {
            contents.push(
                <EditDocumentInfo patientDocument={this.state.smsEmailDocumentsSelectValue}
                                  targetId={HM_EditEmailSMS.id}
                                  onOkDialog={this.onUpdateDocumentInfo}
                                  onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state[HM_EditConsentDocument.id]) {
            contents.push(
                <EditDocumentInfo patientDocument={this.state.consentFormDocumentsSelectValue}
                                  targetId={HM_EditConsentDocument.id}
                                  onOkDialog={this.onUpdateDocumentInfo}
                                  onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state.showImage) {

            const image = this.state.flattenedDocumentsSelectValue;

            switch (image.type) {
                case DOC_UPLOAD_XRAY_TIF.name :
                case DOC_UPLOAD_XRAY_JPG.name :
                case DOC_UPLOAD_XRAY_GIF.name :
                case DOC_UPLOAD_SCAN_TIF.name :
                case DOC_UPLOAD_SCAN_JPG.name :
                case DOC_UPLOAD_SCAN_GIF.name :
                case DOC_IMAGE.name :

                    const key = Math.random().toString(36).slice(2);

                    const footer =
                        <div>
                            <Button icon={ICON_CLOSE}
                                    label='Close'
                                    onClick={() => {
                                        this.showImage(image, false);
                                    }}
                            />
                        </div>;

                    contents.push(
                        <Dialog header={<span onDrag={(event) => {
                            // override Dialog style with { top: `calc(50% - ${offsetY}px)`, left: `calc(50% - ${offsetX}px} }
                        }}>{image.title} : {dateTemplate(image.created)}</span>}
                                visible={true}
                                onHide={() => {
                                    this.showImage(image, false);
                                }}
                                modal={false}
                                footer={footer}
                        >
                            <img alt=''
                                 src={`${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${image.filename}/${DOC_IMAGE.name}/${key}`}
                                 style={{height: '75vh'}}
                            />
                        </Dialog>
                    );
                    break;
                default:
                    break;
            }
        }

        contents.push(ShowQuestionDialog(this, HM_DeletePatientDocument, () => {
            this.onDeleteDocument()
        }));
        contents.push(ShowQuestionDialog(this, HM_DeleteXrayScanDocument, () => {
            this.onDeleteDocument()
        }));
        contents.push(ShowQuestionDialog(this, HM_DeletePresDocument, () => {
            this.onDeleteDocument()
        }));
        contents.push(ShowQuestionDialog(this, HM_DeleteReferralDocument, () => {
            this.onDeleteDocument()
        }));
        contents.push(ShowQuestionDialog(this, HM_DeleteTreatmentPlanDocument, () => {
            this.onDeleteDocument()
        }));
        contents.push(ShowQuestionDialog(this, HM_DeletePaymentPlanDocument, () => {
            this.onDeleteDocument()
        }));
        contents.push(ShowMessageDialog(this, HM_notImplemented));

        return contents;
    }

    showContextMenu = (tag, event) => {

        this.state.contextMenus.forEach(menu => menu.menu.hide(event));
        const index = _.findIndex(this.state.contextMenus, menu => menu.tag === tag);
        this.state.contextMenus[index].menu.show(event);
    }

    setMenu = (tag, ref) => {
        const index = _.findIndex(this.state.contextMenus, menu => menu.tag === tag);
        this.state.contextMenus[index].menu = ref;
    }

    showImage(row, newState) {
        this.setState({flattenedDocumentsSelectValue: row, showImage: newState});
    }

    render() {

        if (!this.props.patientDocumentsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const flattenedDocuments = _.orderBy([...this.state.xrayDocuments, ...this.state.patientDocuments, ...this.state.refDocuments], (document) => {
            return document.created === null ? '' : moment(document.created).format('YYYYMMDD');
        }, ['asc']);

        const presDocuments = _.orderBy(this.state.presDocuments, (document) => {
            return document.created === null ? '' : moment(document.created).format('YYYYMMDD');
        }, ['asc']);

        const tpDocuments = _.orderBy(this.state.tpDocuments, (document) => {
            return document.created === null ? '' : moment(document.created).format('YYYYMMDD');
        }, ['asc']);

        const smsEmailDocuments = _.orderBy(this.state.smsEmailDocuments, (document) => {
            return document.created === null ? '' : moment(document.created).format('YYYYMMDD');
        }, ['desc']);

        const consentFormDocuments = _.orderBy(this.state.consentFormDocuments, 'docId', 'asc');

        return (
            <div className='p-col-12 p-lg-12'>

                <Toast ref={(el) => {
                    this.emailSendGrowl = el;
                }}/>

                <ContextMenu style={{width: 280}}
                             model={buildFlattenedItemsMenu(this.toolbarCallbacks, this.onShowMenuEvent, this.state.flattenedDocumentsSelectValue)}
                             ref={el => this.setMenu(CM_DOCUMENTS_FLATTENED, el)}/>
                <ContextMenu style={{width: 250}}
                             model={buildPrescriptionDocumentsItemsMenu(this.toolbarCallbacks, this.onShowMenuEvent)}
                             ref={el => this.setMenu(CM_DOCUMENTS_PRES, el)}/>
                <ContextMenu style={{width: 300}}
                             model={buildTPPPDocumentsItemsMenu(this.toolbarCallbacks, this.onShowMenuEvent, this.state.tpDocumentsSelectValue, false)}
                             ref={el => this.setMenu(CM_DOCUMENTS_TP, el)}/>
                <ContextMenu style={{width: 250}}
                             model={buildPatientSMSEmailItemsMenu(this.toolbarCallbacks, this.onShowMenuEvent)}
                             ref={el => this.setMenu(CM_DOCUMENTS_SMS_EMAIL, el)}/>
                <ContextMenu style={{width: 250}}
                             model={buildPatientConsentItemsMenu(this.toolbarCallbacks, this.onShowMenuEvent, false)}
                             ref={el => this.setMenu(CM_DOCUMENTS_CONSENT, el)}/>

                {this.showDialogs()}

                <Accordion multiple={true} activeIndex={this.state.activeIndex}
                           onTabChange={(event) => {
                               this.setState({activeIndex: event.index}, () => {
                                   this.props.setState(this.state.stateManagementId, {...this.state}, this.props.patientDataId);
                               });
                           }}>
                    <AccordionTab key={0} header='Documents & Images'>
                        <DataTable value={flattenedDocuments}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   paginator={true}
                                   rows={this.state.imageRows}
                                   rowsPerPageOptions={[10, 20, 100]}
                                   onPage={(e) => {
                                       this.onPageFlex(e, 'firstImage', 'imageRows');
                                   }}
                                   first={this.state.firstImage}
                                   onContextMenu={e => this.showContextMenu(CM_DOCUMENTS_FLATTENED, e.originalEvent)}
                                   selectionMode="single"
                                   selection={this.state.flattenedDocumentsSelectValue}
                                   onRowDoubleClick={(event) => {
                                       switch (event.data.type) {
                                           case DOC_UPLOAD_XRAY_TIF.name :
                                           case DOC_UPLOAD_XRAY_JPG.name :
                                           case DOC_UPLOAD_XRAY_GIF.name :
                                           case DOC_UPLOAD_SCAN_TIF.name :
                                           case DOC_UPLOAD_SCAN_JPG.name :
                                           case DOC_UPLOAD_SCAN_GIF.name :
                                           case DOC_IMAGE.name :
                                               this.onImageOpen();
                                               break;
                                           default:
                                               this.onOnlyOfficeOpen(this.state.flattenedDocumentsSelectValue)
                                               break;
                                       }
                                   }}
                                   onSelectionChange={(event) => this.onSelectionChange(event, 'flattenedDocumentsSelectValue')}
                                   contextMenuSelection={this.state.flattenedDocumentsSelectValue}
                                   onContextMenuSelectionChange={(event) => this.onSelectionChange(event, 'flattenedDocumentsSelectValue')}
                        >
                            <Column body={row => docTypeTemplateFlattened(row, ac, (row) => this.showImage(row, true))}
                                    header="" style={{width: '10%'}}/>
                            <Column body={row => docClassificationTemplate(row)} header="Type" style={{width: '5%'}}/>
                            <Column body={row => dateTemplate(row.created)} header="Created On" style={{width: '15%'}}/>
                            <Column field="userName" header="By" style={{width: '10%'}}/>
                            <Column field="visibleName" header="Name" style={{width: '25%'}}/>
                            <Column field="notes" header="Note" style={{width: '35%'}}/>
                        </DataTable>
                    </AccordionTab>
                    <AccordionTab key={2} header='Prescriptions'>
                        <DataTable value={presDocuments}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   paginator={true}
                                   rows={this.state.presRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => {
                                       this.onPageFlex(e, 'firstPres', 'presRows');
                                   }}
                                   first={this.state.firstPres}
                                   onContextMenu={e => this.showContextMenu(CM_DOCUMENTS_PRES, e.originalEvent)}
                                   selectionMode="single"
                                   selection={this.state.presDocumentsSelectValue}
                                   onRowDoubleClick={(event) => {
                                       this.onPrescriptionsOpen()
                                   }}
                                   onSelectionChange={(event) => this.onSelectionChange(event, 'presDocumentsSelectValue')}
                                   contextMenuSelection={this.state.presDocumentsSelectValue}
                                   onContextMenuSelectionChange={(event) => this.onSelectionChange(event, 'presDocumentsSelectValue')}
                        >
                            <Column body={row => docTypeTemplateFlattened(row, ac)} header="" style={{width: '5%'}}/>
                            <Column body={row => dateTemplate(row.created)} header="Created On" style={{width: '15%'}}/>
                            <Column field="userName" header="By" style={{width: '15%'}}/>
                            <Column field="visibleName" header="Name" style={{width: '25%'}}/>
                            <Column field="notes" header="Note" style={{width: '40%'}}/>
                        </DataTable>
                    </AccordionTab>
                    <AccordionTab key={4} header='Treatment & Payment Plans'>
                        <DataTable value={tpDocuments}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   paginator={true}
                                   rows={this.state.tpRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => {
                                       this.onPageFlex(e, 'firstTp', 'tpRows');
                                   }}
                                   first={this.state.firstTp}
                                   onContextMenu={e => this.showContextMenu(CM_DOCUMENTS_TP, e.originalEvent)}
                                   selectionMode="single"
                                   selection={this.state.tpDocumentsSelectValue}
                                   onRowDoubleClick={(event) => {
                                       this.onOnlyOfficeOpen(this.state.tpDocumentsSelectValue)
                                   }}
                                   onSelectionChange={(event) => this.onSelectionChange(event, 'tpDocumentsSelectValue')}
                                   contextMenuSelection={this.state.tpDocumentsSelectValue}
                                   onContextMenuSelectionChange={(event) => this.onSelectionChange(event, 'tpDocumentsSelectValue')}
                        >
                            <Column body={row => docTypeTemplateFlattened(row, ac)} header="" style={{width: '5%'}}/>
                            <Column body={row => dateTemplate(row.created)} header="Created On" style={{width: '15%'}}/>
                            <Column field="userName" header="By" style={{width: '15%'}}/>
                            <Column field="visibleName" header="Name" style={{width: '25%'}}/>
                            <Column field="notes" header="Note" style={{width: '40%'}}/>
                        </DataTable>
                    </AccordionTab>
                    <AccordionTab key={5} header='SMS & Emails'>
                        <DataTable value={smsEmailDocuments}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   paginator={true}
                                   rows={this.state.SMSRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => {
                                       this.onPageFlex(e, 'firstSMS', 'SMSRows');
                                   }}
                                   first={this.state.firstSMS}
                                   onContextMenu={e => this.showContextMenu(CM_DOCUMENTS_SMS_EMAIL, e.originalEvent)}
                                   selectionMode="single"
                                   selection={this.state.smsEmailDocumentsSelectValue}
                                   onRowDoubleClick={(event) => this.onDocumentsDoubleClick(event, 'smsEmailDocumentsSelectValue')}
                                   onSelectionChange={(event) => this.onSelectionChange(event, 'smsEmailDocumentsSelectValue')}
                                   contextMenuSelection={this.state.smsEmailDocumentsSelectValue}
                                   onContextMenuSelectionChange={(event) => this.onSelectionChange(event, 'smsEmailDocumentsSelectValue')}
                        >
                            <Column body={row => docTypeTemplateFlattened(row, ac)} header="" style={{width: '5%'}}/>
                            <Column body={row => dateTemplate(row.created)} header="Created On" style={{width: '15%'}}/>
                            <Column field="userName" header="By" style={{width: '15%'}}/>
                            <Column body={emailSMSTemplate} header="Mobile Number / Email" style={{width: '25%'}}/>
                            <Column field="content" header="Content" style={{width: '40%'}}/>
                        </DataTable>
                    </AccordionTab>
                    <AccordionTab key={6} header='Consent Forms'>
                        <DataTable value={consentFormDocuments}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   paginator={true}
                                   rows={this.state.consentRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => {
                                       this.onPageFlex(e, 'firstConsent', 'consentRows');
                                   }}
                                   first={this.state.firstConsent}
                                   onContextMenu={e => this.showContextMenu(CM_DOCUMENTS_CONSENT, e.originalEvent)}
                                   selectionMode="single"
                                   selection={this.state.consentFormDocumentsSelectValue}
                                   onRowDoubleClick={(event) => this.onDocumentsDoubleClick(event, 'consentFormDocumentsSelectValue')}
                                   onSelectionChange={(event) => this.onSelectionChange(event, 'consentFormDocumentsSelectValue')}
                                   contextMenuSelection={this.state.consentFormDocumentsSelectValue}
                                   onContextMenuSelectionChange={(event) => this.onSelectionChange(event, 'consentFormDocumentsSelectValue')}
                        >
                            <Column body={row => docTypeTemplateFlattened(row, ac)} header="" style={{width: '5%'}}/>
                            <Column body={row => dateTemplate(row.created)} header="Created On" style={{width: '15%'}}/>
                            <Column field="userName" header="By" style={{width: '15%'}}/>
                            <Column field="visibleName" header="Name" style={{width: '25%'}}/>
                            <Column field="notes" header="Note" style={{width: '40%'}}/>
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        patientDataId,

        patientDocumentsId,
        patientDocumentsLoaded,

    } = getPatientIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        patientDataId,

        patientDocumentsLoaded,
        patientDocumentsId,
        patientDocuments: state.patients[patientDocumentsId],

        filename: state.patients.filename,
        documentData: state.patients.documentData,

        classificationsLoaded: state.housekeeping.classificationsLoaded,
        classifications: state.housekeeping.classifications,

        wsmessage: state.websockets.message,

        onPCButtonClick: state.login.onPCButtonClick,

        currentState: state.stateManagement[patientDocumentsId],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        openDocument: (document) => dispatch(openDocument(RES_PATIENT_DOCUMENTS.OPEN, document)),
        deleteDocument: (patientId, delType, id) => dispatch(deleteDocument(RES_PATIENT_DOCUMENTS.DELETE, patientId, delType, id)),

        previewPatientDocument: (document, previewToken) => dispatch(previewPatientDocument(RES_sendPatientDocument.PREVIEW, document, previewToken)),
        sendPatientDocument: (patientDocument, message, idToken) => dispatch(sendPatientDocument(RES_sendPatientDocument.SEND, patientDocument, message, idToken, [])),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const DocumentsFlattenedSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedDocumentsFlattenedSection);

export default DocumentsFlattenedSection;
