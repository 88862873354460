import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from "react-redux";
import {Button} from 'primereact/components/button/Button';
import {CLINICIAN, HM_DIARY_SORT_ORDER, SU_STATUS_ACTIVE} from "../../../Constants";
import {Dialog} from "primereact/dialog";
import {OrderList} from "primereact/orderlist";
import {getAllUsers, getDiarySortOrder, RES_DIARY_SORT_ORDER, setDiarySortOrder} from "../../../actions/users";

import * as Actions from "../../../actions";
import {HELP_DIARY_SORT_ORDER} from "../../../actions/stateManagement";

export class ConnectedDiarySortOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            providers: [],
            providersFiltered: false,
        };
    }

    componentDidMount() {

        this.props.getDiarySortOrder();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {

            switch (this.props.message.type) {

                case Actions.DIARY_SORT_ORDER:
                    this.props.getAllUsersShort();
                    break;

                case Actions.RECEIVE_USER_SEARCH:

                    if (!this.state.providersFiltered) {
                        const providers = _.filter(this.props.usersShort, provider => provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);
                        const order = this.props.sortOrder.split(':');
                        const orderedProviders = [];

                        order.forEach(username => {
                            const match = _.find(providers, provider => provider.username === username);
                            if (match !== undefined) {
                                orderedProviders.push(match);
                            }
                        });
                        this.setState({providers: orderedProviders, providersFiltered: true});
                    }
                    break;
                default:
                    break;
            }
        }
    }

    providerTemplate(provider) {
        return (
            <div className='p-clearfix'>
                {provider.fullName}
            </div>
        )
    }

    onUpdateSortOrder() {

        const newOrderUsername = _.map(this.state.providers, provider => provider.username);

        let newOrder = ':';
        newOrderUsername.forEach(username => newOrder += `${username}:`);

        this.props.setDiarySortOrder(newOrder);
        this.props.onHideDialog(HM_DIARY_SORT_ORDER.id);
    }

    onHelpRequest = () => {

        window.open(HELP_DIARY_SORT_ORDER);
    }

    render() {

        if (!this.props.usersLoaded) {
            return null;
        }

        const footer = (
            <div>
                <Button label='Close'
                        icon='fas fa-check'
                        onClick={() => {
                            this.onUpdateSortOrder();
                        }}/>
                <Button label='Cancel'
                        icon='fa fa-times'
                        onClick={() => {
                            this.props.onHideDialog(HM_DIARY_SORT_ORDER.id);
                        }}/>
            </div>
        );

        return (
            <Dialog style={{width: "50%"}}
                    header={HM_DIARY_SORT_ORDER.header}
                    footer={footer}
                    visible={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_DIARY_SORT_ORDER.id);
                    }}>
                <OrderList className='p-col-12' value={this.state.providers}
                           itemTemplate={this.providerTemplate}
                           header='Providers'
                           onChange={(e) => {
                               this.setState({providers: e.value})
                           }}/>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        sortOrder: state.users.sortOrder,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),

        getDiarySortOrder: () => dispatch(getDiarySortOrder(RES_DIARY_SORT_ORDER.GET)),
        setDiarySortOrder: (newOrder) => dispatch(setDiarySortOrder(RES_DIARY_SORT_ORDER.SET, newOrder)),
    };
};

const DiarySortOrder = connect(mapStateToProps, mapDispatchToProps)(ConnectedDiarySortOrder);

export default DiarySortOrder;

DiarySortOrder.propTypes = {};