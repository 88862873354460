import React from 'react';
import {BaseComponent} from "../../../BaseComponent";
import {
    HM_PRINT_INVOICE,
    HM_SHOW_INVOICE,
    HM_SHOW_INVOICE_APPOINTMENT,
    HM_SHOW_INVOICE_PATIENT_ACCOUNTS
} from "../Constants";
import {TB_PATIENT_DETAILS, TB_RECALCULATE_PATIENT_ACCOUNTS} from "../../PatientDetails/Constants";
import {
    HM_EmailInvoice,
    HM_PrintInvoice,
    HM_ShowInvoice,
    REP_Invoice,
    REPORT_SHOW,
    RT_ACC_RENDERED_30
} from "../../../../Constants";
import {menuButtonTemplate} from "../../fixedItemUtils";
import AccountItemsPDF from "../PDFs/AccountItemsPDF";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {outputCurrency} from "../../../PatientDynamicItems/OnChangeUtils";
import {Column} from "primereact/column";
import {ICON_CALC, ICON_OK} from "../../../../icons";
import RecalculatePatientAccounts from "../../Patients/dialogs/RecalulatePatientAccounts";
import _ from "lodash";
import * as Capabilities from "../../../Capabilities";
import {Button} from "primereact/components/button/Button";
import {printDocumentItem} from "../../PatientDetails/Utils";

export class OverDueAccountsBase extends BaseComponent {

    componentDidUpdate(prevProps, ps, ss) {
    }

    onSelectionChange(selection) {

        this.setState({selectedOverDue: selection.value});
    }

    onShowDialogs = () => {

        if (this.state[TB_RECALCULATE_PATIENT_ACCOUNTS.id]) {

            return (
                <RecalculatePatientAccounts results={this.props.overDues}
                                            group={this.props.accountGroup}
                                            onHideDialog={(target) => this.reloadOverdues(target)}
                />
            );
        } else return null;
    }

    createRecalculateButton = (headerText) => {

        const disallowRecalculation = !this.props.overDues || this.props.overDues.length === 0;

        return (<div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{headerText}</span>
            </div>
            <div className="items-margin d-flex d-align-center">

                {_.includes([...this.props.capabilities, Capabilities.AID_RECALCULATE_PATIENT_ACCOUNTS], Capabilities.AID_RECALCULATE_PATIENT_ACCOUNTS) &&
                <Button tooltipOptions={{position: 'top'}}
                        tooltip={TB_RECALCULATE_PATIENT_ACCOUNTS.text}
                        icon={ICON_CALC}
                        disabled={disallowRecalculation}
                        onClick={() => this.onShowMenuEntry({item: {target: TB_RECALCULATE_PATIENT_ACCOUNTS.id}})}
                />}
            </div>
        </div>);
    }

    paymentPlanTemplate(isPartOfPaymentPlan) {

        if (isPartOfPaymentPlan) {
            return <i className={ICON_OK}/>
        }
    }

    addColumns() {

        let key = Math.floor(Math.random() * 1000);
        const content = [];

        content.push(<Column body={(row) => dateTemplate(row.raisedOn)}
                             key={key++}
                             header='Date Raised'
                             style={{width: '15%'}}/>);
        content.push(<Column body={(row) => this.paymentPlanTemplate(row.partOfPaymentPlan)}
                             key={key++}
                             header='PP'
                             style={{width: '5%'}}/>
        );
        content.push(<Column field='invoiceString'
                             key={key++}
                             header='Invoice'
                             style={{width: '10%'}}/>
        );
        content.push(<Column body={(row) => `${row.title.abbreviation} ${row.patientFirstName} ${row.patientLastName}`}
                             key={key++}
                             header='Patient'
                             style={{width: '20%'}}/>
        );
        content.push(<Column field='telephone'
                             key={key++}
                             header='Telephone'
                             style={{width: '10%'}}/>
        );
        content.push(<Column field='mobile'
                             key={key++}
                             header='Mobile'
                             style={{width: '10%'}}/>
        );
        content.push(<Column body={(row) => outputCurrency(row.total)}
                             key={key}
                             header='Total'
                             style={{width: '10%', textAlign: 'right'}}/>
        );
        content.push(<Column body={(row) => outputCurrency(row.paidToDate)}
                             key={key}
                             header='Paid to Date'
                             style={{width: '10%', textAlign: 'right'}}/>
        );
        content.push(<Column body={(row) => outputCurrency(row.total - row.paidToDate)}
                             key={key}
                             header='Amount Outstanding'
                             style={{width: '10%', textAlign: 'right'}}/>
        );
        return content;
    }

    showInvoice(selection) {

        const options = {
            itemId: selection.id,
            report: REP_Invoice.value,
            reportFunction: REPORT_SHOW,
            number: selection.invoiceString,
            period: RT_ACC_RENDERED_30.ordinal,
            target: HM_ShowInvoice.id,
        };
        this.props.onPCButtonClick(menuButtonTemplate(this.props, AccountItemsPDF, `${options.number}`, 'fas fa-file-pdf', `${options.number}`, null, options));

    };

    printInvoice(selection) {

        const options = {
            itemId: selection.id,
            report: REP_Invoice.value,
            reportFunction: REPORT_SHOW,
            number: selection.invoiceString,
            period: RT_ACC_RENDERED_30.ordinal,
            target: HM_PrintInvoice.id,
        };
        printDocumentItem({options}, this.emailSendGrowl);
    }

    buildMenu() {

        const items = [
            {
                label: HM_SHOW_INVOICE.header,
                icon: HM_SHOW_INVOICE.icon,
                command: () => {
                    this.showInvoice(this.state.selectedOverDue);
                },
                target: HM_SHOW_INVOICE.id
            },
            {
                label: HM_PRINT_INVOICE.header,
                icon: HM_PRINT_INVOICE.icon,
                command: () => {
                    this.printInvoice(this.state.selectedOverDue);
                },
                target: HM_PRINT_INVOICE.id
            },
            {
                label: HM_SHOW_INVOICE_APPOINTMENT.header, icon: HM_SHOW_INVOICE_APPOINTMENT.icon,
                command: () => {
                },
                target: HM_SHOW_INVOICE_APPOINTMENT.id,
                disabled: true
            },
            {
                label: HM_SHOW_INVOICE_PATIENT_ACCOUNTS.header,
                icon: HM_SHOW_INVOICE_PATIENT_ACCOUNTS.icon,
                command: () => {
                    this.props.toolbarCallbacks[TB_PATIENT_DETAILS.id](this.state.selectedOverDue);
                },
                target: HM_SHOW_INVOICE_PATIENT_ACCOUNTS.id
            },
            {
                label: HM_EmailInvoice.label,
                icon: HM_EmailInvoice.icon,
                command: (item) => {
                    this.props.onRequestPreviewAccountItem(item, this.state.selectedOverDue);
                },
                target: HM_EmailInvoice.id,
                report: REP_Invoice.value,
                reportFunction: REPORT_SHOW
            },
        ];
        return items;
    }

    render() {
        return null;
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}
