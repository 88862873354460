import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';
import {ScrollPanel} from 'primereact/scrollpanel';
import _ from "lodash";
import {CLINICIAN, DOC_PRESCRIPTION_TEMPLATE, HM_addPatPrescription, SU_STATUS_ACTIVE} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {
    getResource,
    getResource2,
    RES_HOUSEKEEPING_MEDICATIONS,
    RES_TEMPLATES_PRESCRIPTIONS
} from "../../../../actions/housekeeping";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {getAllUsers} from "../../../../actions/users";
import {ProgressBar} from "primereact/progressbar";
import {InputText} from "primereact/inputtext";

export class ConnectedAddPatPrescription extends Component {

    constructor(props) {
        super(props);

        this.state = {

            details: {
                templateId: null,
                providerId: null,
                medications: [],
                patientId: props.patientId,
                visibleName: '',
                notes: '',
                createdBy: props.loginIdentity.id,
            },
            selectedTemplate: null,
            selectedMedication: [],
            selectedProvider: null,
            firstUsers: 0,
            firstMedications: 0,
            firstTemps: 0,
            rowsUsers: 5,
            rowsMedications: 5,
            rowsTemps: 5,

            globalFilter: '',
        };
    }

    componentDidMount() {

        if (!this.props.prescriptionTemplateNotesLoaded)
            this.props.getTemplates([DOC_PRESCRIPTION_TEMPLATE.name]);

        if (!this.props.medicationsLoaded)
            this.props.getMedications();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.messageType !== prevProps.messageType) {
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onProviderSelection = (event) => {

        const details = {...this.state.details};
        details.providerId = event.value.id;

        this.setState({selectedProvider: event.value, details});
    }

    onMedicationSelection = (event) => {

        console.log(event);

        const details = {...this.state.details};
        details.medications = [...event.value];

        this.setState({selectedMedication: event.value, details});
    }

    onTemplateSelection = (event) => {

        const visibleName = `${event.value.visibleName} - ${dateTemplate(new Date())}`;
        const details = {...this.state.details};
        details.visibleName = visibleName;
        details.templateId = event.value.docId;
        details.template = event.value;

        this.setState({selectedTemplate: event.value, details});
    }

    onPageUsers = (e) => {
        this.setState({firstUsers: e.first, rowsUsers: e.rows})
    }

    onPageMedications = (e) => {
        this.setState({firstMedications: e.first, rowsMedications: e.rows})
    }

    onPageTemps = (e) => {
        this.setState({firstTemps: e.first, rowsTemps: e.rows})
    }

    render() {

        if (!this.props.prescriptionTemplateNotesLoaded || !this.props.medicationsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const docNotDefined = this.state.selectedTemplate === null || this.state.selectedProvider === null || this.state.selectedMedication === null;
        const visibleUsers = this.props.usersShort.filter(user => user.status === SU_STATUS_ACTIVE && user.myRole === CLINICIAN);

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.details);
                        }}
                        disabled={docNotDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_addPatPrescription.id)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'details',
            details: this.state.details,
        };

        const templateHeader = <div className='p-panel-header'>
            <div>Templates</div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           value={this.state.globalFilter}
                           onInput={(e) => this.setState({globalFilter: e.target.value, firstTemps: 0})}
                           placeholder="Search"
                           autoFocus
                />
            </div>
        </div>;

        let templates = _.filter(this.props.prescriptionTemplateNotes, template => {
            const pattern = `${template.visibleName} ${template.notes}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'visibleName', 'asc');

        return (
            <Dialog header={HM_addPatPrescription.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_addPatPrescription.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <DataTable value={visibleUsers}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       header='Providers'
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.rowsUsers}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={this.onPageUsers}
                                       first={this.state.firstUsers}
                                       selection={this.state.selectedProvider}
                                       onSelectionChange={this.onProviderSelection}
                            >

                                <Column field="fullName" header='Name' style={{width: '50%'}}/>
                                <Column field="username" header='Username' style={{width: '50%'}}/>

                            </DataTable>
                        </div>
                        <div className="p-col-6">
                            <DataTable value={this.props.medications}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       header='Medications'
                                       selectionMode="multiple"
                                       paginator={true}
                                       rows={this.state.rowsMedications}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={this.onPageMedications}
                                       first={this.state.firstMedications}
                                       selection={this.state.selectedMedication}
                                       onSelectionChange={this.onMedicationSelection}
                            >

                                <Column field="description" header='Description'/>

                            </DataTable>
                        </div>
                    </div>
                    <DataTable value={sortedTemplates}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small', paddingTop: '5px'}}
                               header={templateHeader}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsTemps}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPageTemps}
                               first={this.state.firstTemps}
                               selection={this.state.selectedTemplate}
                               onSelectionChange={this.onTemplateSelection}
                    >
                        <Column field="visibleName" header='Template Name' style={{width: '50%'}}/>
                        <Column field="notes" header='notes' style={{width: '50%'}}/>

                    </DataTable>
                    <Panel header='Document Details' style={{paddingTop: '5px'}}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-3">
                                <label>Document Name</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'visibleName', 'Document Name ...', false, true)}
                            </div>

                            <div className="p-col-3">
                                <label>Notes</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'notes', 5, 100, false, false)}
                            </div>
                        </div>
                    </Panel>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        prescriptionTemplateNotesLoaded: state.housekeeping.prescriptionTemplateNotesLoaded,
        prescriptionTemplateNotes: state.housekeeping.prescriptionTemplateNotes,

        medicationsLoaded: state.housekeeping.medicationsLoaded,
        medications: state.housekeeping.medications,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        getAllUsersShort: () => dispatch(getAllUsers()),
        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_PRESCRIPTIONS.GET, types)),
        getMedications: () => dispatch(getResource(RES_HOUSEKEEPING_MEDICATIONS.GET, {})),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddPatPrescription = connect(mapStateToProps, MapDispatchToProps)(ConnectedAddPatPrescription);

export default AddPatPrescription;
