import React from 'react';

import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import {connect} from "react-redux";
import moment from "moment";
import {Toast} from 'primereact/components/toast/Toast';
import {Panel} from 'primereact/components/panel/Panel';
import {queryQuestionnaire, updateAppComment, updateEventComment} from './MenuActions';
import {
    addAdHocRule,
    addAppointment,
    addBack,
    addDiaryEvent,
    addDiaryNote,
    getDiaryEventsByClinician,
    getNextPrevEventDate,
    previewAppointmentLetter,
    removeSchedules,
    RES_CLINICIAN_DIARY,
    RES_diaryNote,
    RES_PRACTICE_DIARY,
    RES_SEARCH_EVENTS,
    RES_sendAppointmentLetter,
    sendAppointmentLetter,
} from "../../../actions/diary";
import {convertAppointmentDAOtoEvent} from '../../../reducers/diary'

import {
    ADD,
    ADDBACK,
    DELETE,
    HM_AddAppointment,
    HM_AddDiaryEvent,
    HM_AppointmentComment,
    HM_CancelAppointment,
    HM_DeleteAppointment,
    HM_DeleteDiaryEvent,
    HM_EditDiaryEvent,
    HM_MoveAppointment,
    HM_MoveDiaryEvent,
    HM_notImplemented,
    HM_PreviewAppointmentLetter,
    HM_QUESTIONNAIRE_UNCHANGED,
    HM_ResizeAppointment,
    HM_ResizeDiaryEvent,
    HM_SEND_SMS,
    HM_SendAppointmentLetter,
    HO_STATUS_SHOW_TASK,
    JSON_DATE_FORMAT,
    MOVING_IN_DIARY,
    RT_PAYMENT_COMMENT,
    SM_DIARY_MODE_NORMAL,
    SR_CLINIC, SR_ZONE,
    UP_APP_CONFIRMED,
    UP_EMAIL_DOCUMENT,
    UP_EMAIL_DOCUMENT_FAILED,
    UP_EMAIL_DOCUMENT_PREVIEW,
    UP_NEWTASK,
    UP_TASKUPDATE,
    UPDATE
} from "../../../Constants";
import {ResourceContent} from "./components/DiaryResourceContent";
import {
    HELP_CLINICIAN_DIARY,
    messageBus,
    setState,
    SM_CLINICIAN_DIARY,
    SM_PATIENT_QUES_QUERY,
    stateRequest
} from "../../../actions/stateManagement";
import _ from "lodash";
import * as Actions from "../../../actions";
import {PALETTE_GRP_APP} from "./Constants";
import DiaryErrorBoundary, {getDiaryIds} from "./Utils";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {getResource, RES_HOUSEKEEPING_ADETS} from "../../../actions/housekeeping";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";

import {addToLists, RES_TABLET_LISTS} from "../../../actions/tablet";
import {ProgressBar} from "primereact/progressbar";
import {getDiarySortOrder, getResource as getUserResource, RES_DIARY_SORT_ORDER} from "../../../actions/users";
import {
    issueInvoice2,
    issueReceipt2,
    RES_PATIENT_ACCOUNT,
    RES_PATIENT_DETAILS,
    RES_PATIENT_EMAIL,
    RES_PATIENT_QUESTIONNAIRE,
    RES_PATIENT_SMS,
    RES_RESEND_PATIENT_PORTAL,
    resendPortalReference,
    sendEmail,
    sendSMS
} from "../../../actions/personal";
import DiaryComponent from "./DiaryComponent";
import {closeDiaryTask} from "../../../actions/tasks";
import {getReportText, RES_REPORT_TEXTS} from "../../../actions/reports";
import {TB_PATIENT_HANDOVER} from "../PatientDetails/Constants";
import {DiaryToolbar} from "./components/DiaryToolbar";
import {
    getResource as getLabResource,
    markWorkStatus,
    requestWorkRequired,
    RES_WORK_REQUIRED
} from "../../../actions/findLaboratories";
import {timeTemplate2} from "../../PatientDynamicItems/Utils";
import {DAY_LIST_TEXT_FORMAT, IN_CHAIR, LEFT_CHAIR} from "../Preferences/Constants";
import {RES_getAccountGroups} from "../../../actions/accountGroups";
import {getObjectStore, RES_OBJECT_STORE} from "../../../actions/objectStore";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

class ConnectedClinicianDiary extends DiaryComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                source: {action: RES_CLINICIAN_DIARY.CLEAR},

                stateManagementId: `${SM_CLINICIAN_DIARY.id}_${props.options.clinician.id}`,
                currentDate: new Date(),

                diaryMode: SM_DIARY_MODE_NORMAL,
                handoverTask: null,
                taskMessage: null,

                clinician: props.options.clinician,

                events: [],
                resources: [],
                movingEvents: [],
                recallEvents: [],

                discriminator: Views.DAY,
                previewToken: null,

                selectable: false,

                selectedEvent: {
                    attended: false,
                    confirmed: false,
                },

                movingEvent: null,
                resizingEvent: null,

                selectedResource: null,
                selectedProvider: null,
                selectedAppTypes: [],

                reservationEvent: null,
                newDiaryEvent: null,

                privacyOn: false,
                zoningOn: true,

                barcodePattern: '',
                namePattern: '',

                [HM_notImplemented]: false,

                [HM_AddAppointment.id]: false,
                [HM_DeleteAppointment.id]: false,
                [HM_CancelAppointment.id]: false,

                [HM_AddDiaryEvent.id]: false,
                [HM_DeleteDiaryEvent.id]: false,

                [HM_MoveAppointment.id]: false,
                [HM_MoveDiaryEvent.id]: false,

                [HM_ResizeAppointment.id]: false,
                [HM_ResizeDiaryEvent.id]: false,

                [HM_EditDiaryEvent.id]: false,

                [HM_AppointmentComment.id]: false,

                [HM_SendAppointmentLetter.id]: false,
                [HM_PreviewAppointmentLetter.id]: false,

                [HM_SEND_SMS.id]: false,

                [HM_QUESTIONNAIRE_UNCHANGED.id]: false,

                paletteItem: {id: null, title: '', paletteType: PALETTE_GRP_APP},

                documentDetails: null,

                slotSize: 2,
                tabOptions: [],

                daysActivePopUps: [],

                zoneEvents: [],

                forceRerender: false,
            }
        }
        this.exitState = TAB_PARENT_CHANGE;

        this.tabletGrowl = null;
        this.taskGrowl = null;
        this.eventGrowl = null;

        this.growl = null;
        this.popupsAdded = false;

        this.emailSendGrowl = null;

        // context menu item
        this.selectedTask = null;
        this.selectedTaskState = HO_STATUS_SHOW_TASK;
        this.selectedMove = null;
    };

    componentDidMount() {

        if (!Boolean(this.state.currentState)) {
            this.props.getDiarySortOrder();
            this.props.getObjectStore([DAY_LIST_TEXT_FORMAT]);
        }

        this.buildAllMenus(false);
    }

    componentDidUpdate(prevProps, prevState, snapShot) {

        if (this.state !== prevState) {
        }

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_DETAILS.ERROR:
                    this.tabletGrowl.show({
                        severity: 'error',
                        summary: 'Failure',
                        detail: 'A patient with this name and date of birth already exists',
                        sticky: true,
                    });
                    break;

                case Actions.DIARY_SORT_ORDER:
                    this.props.getDiaryEvents(this.state.currentDate, this.state.currentDate, this.props.loginIdentity.id, this.state.clinician.id);
                    this.props.getDropDowns();
                    this.props.getAccountGroups();
                    this.props.reportText(RT_PAYMENT_COMMENT.name);
                    break;

                case Actions.RECEIVE_CLINICIAN_DIARY_EVENTS:
                case Actions.RECEIVE_ADD_APPOINTMENT: {

                    const zoneEvents = this.computeBackgroundEvents(this.props.selectedWeekIndex, this.props.zoneEvents);

                    this.setState({
                        resources: this.props.diaryResources,
                        events: this.props.diaryEvents,
                        zoneEvents
                    }, () => {
                        this.props.getDiaryActivePopUps(this.state.currentDate);
                    });
                    break;
                }

                case Actions.REQUEST_ACTIVE_POPUPS:
                    this.popupsAdded = false;
                    break;

                case Actions.RECEIVE_ACTIVE_POPUPS:
                    this.setState({daysActivePopUps: this.props.daysActivePopUps}, () => {
                        this.showPopUpNotes();
                    })
                    break;

                case Actions.RECEIVE_DIARY_PALLETE_ENTRIES:
                    this.setState({
                        movingEvents: this.props.movingEvents,
                        recallEvents: this.props.recallEvents
                    }, () => {
                    });
                    break;

                case Actions.RECEIVE_ADD_DIARYEVENT:
                    this.setState({reservationEvent: this.props.newDiaryEvent}, () => {
                    });
                    break;

                case Actions.RECEIVE_NEXT_EVENT:

                    if (this.props.nextDiaryEvent.id !== null) {
                        this.setState({currentDate: this.props.nextDiaryEvent.start}, () => {
                            this.props.getDiaryActivePopUps(this.state.currentDate);
                            this.props.getDiaryEvents(this.state.currentDate, this.state.currentDate, this.props.loginIdentity.id, this.state.clinician === undefined ? null : this.state.clinician.id);
                        })
                        this.createEventGrowl(this.props.nextDiaryEvent);
                    } else {
                        this.createNoMatchEventGrowl();
                    }
                    break;

                case Actions.RECEIVE_PREV_EVENT:

                    if (this.props.prevDiaryEvent.id !== null) {
                        this.setState({currentDate: this.props.prevDiaryEvent.start}, () => {
                            this.props.getDiaryActivePopUps(this.state.currentDate);
                            this.props.getDiaryEvents(this.state.currentDate, this.state.currentDate, this.props.loginIdentity.id, this.state.clinician === undefined ? null : this.state.clinician.id);
                        })
                        this.createEventGrowl(this.props.prevDiaryEvent);
                    } else {
                        this.createNoMatchEventGrowl();
                    }
                    break;

                case RES_PATIENT_QUESTIONNAIRE.QUERY.receive:

                    if (this.props.queryQuestionnaireResultLoaded && this.state.idToken === this.props.queryQuestionnaireResult[0]) {

                        if (this.props.queryQuestionnaireResult[1]) {

                            this.setState({[HM_QUESTIONNAIRE_UNCHANGED.id]: true});
                        } else {
                            this.tabletGrowl.show({
                                severity: 'error',
                                summary: 'Failure',
                                detail: 'There is no questionnaire started for this patient'
                            });
                        }
                    }
                    break;

                case Actions.WSM_APPOINTMENTS:

                    switch (this.props.wsmessage.function) {

                        case ADD:
                            this.setState({
                                resources: this.props.diaryResources,
                                events: this.props.diaryEvents
                            }, () => {
                            });
                            break;

                        case ADDBACK: {
                            const addBackEventDAO = {...this.props.wsmessage.content};
                            const addBackEvent = convertAppointmentDAOtoEvent(addBackEventDAO);

                            // this will remove the palette entry for this appointment
                            const movingEvents = this.state.movingEvents.filter(event => event.id !== parseInt(addBackEvent.id, 10));

                            this.setState({
                                events: [...this.state.events, addBackEvent],
                                movingEvents,
                                selectable: false,
                                paletteItem: {id: null, title: '', paletteType: PALETTE_GRP_APP},
                            }, () => {
                            });
                        }
                            break;

                        case MOVING_IN_DIARY: {
                            const moveEvent = this.props.wsmessage.content;

                            moveEvent.paletteType = PALETTE_GRP_APP;

                            // this will add the palette entry for the appointment
                            const movingEvents = [...this.state.movingEvents, moveEvent];

                            this.setState({
                                events: this.state.events.filter(event => parseInt(event.id, 10) !== moveEvent.id),
                                movingEvents
                            }, () => {
                            });
                        }
                            break;

                        case DELETE:
                            const deleteEventId = this.props.wsmessage.content;

                            this.setState({events: this.state.events.filter(event => parseInt(event.id, 10) !== deleteEventId)}, () => {
                            });
                            break;

                        case UPDATE: {
                            let updateEvent = convertAppointmentDAOtoEvent(this.props.wsmessage.content);

                            const events = [...this.state.events];

                            const updateIndex = _.findIndex(events, event => event.id === updateEvent.id);

                            if (updateIndex !== -1) {
                                events[updateIndex] = updateEvent;
                                this.setState({events}, () => {
                                });
                            }
                            break;
                        }

                        case IN_CHAIR: {
                            const events = [...this.state.events];
                            this.setState({events}, () => {
                            });
                            break;
                        }

                        case LEFT_CHAIR: {
                            const events = [...this.state.events];
                            this.setState({events}, () => {
                            });
                            break;
                        }
                        default:
                            console.log('Unhandled WSM_APP : ' + this.props.wsmessage.function);
                            break;
                    }
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_APP_CONFIRMED:
                            const confirmedEventId = this.props.wsmessage.content;

                            this.setState({
                                events: this.state.events.map(event => parseInt(event.id, 10) !== confirmedEventId ? event : {
                                    ...event,
                                    confirmed: true
                                })
                            }, () => {
                            });
                            break;

                        case UP_EMAIL_DOCUMENT_PREVIEW:

                            const previewToken = this.props.wsmessage.content[6];

                            if (previewToken === this.state.previewToken) {
                                const documentDetails = this.props.wsmessage.content;
                                this.setState({documentDetails, [HM_PreviewAppointmentLetter.id]: true, previewToken});
                            }
                            break;

                        case UP_EMAIL_DOCUMENT: {

                            const previewToken = this.props.wsmessage.content[1];

                            if (previewToken === this.state.previewToken) {
                                this.emailSendGrowl.show({
                                    severity: 'info',
                                    summary: 'Success',
                                    detail: 'Document Successfully Emailed'
                                });
                            }
                            break;
                        }
                        case UP_EMAIL_DOCUMENT_FAILED: {

                            const previewToken = this.props.wsmessage.content[1];

                            if (previewToken === this.state.previewToken) {
                                this.emailSendGrowl.show({
                                    severity: 'error',
                                    summary: 'Failure',
                                    detail: 'Failed to Email Document'
                                });
                            }
                            break;
                        }

                        case UP_NEWTASK: {
                            this.setState({
                                taskMessage: this.props.wsmessage.content,
                                [TB_PATIENT_HANDOVER.id]: true
                            }, () => {
                            });
                            break;
                        }
                        case UP_TASKUPDATE: {
                            this.setState({diaryTasks: this.props.diaryTasks}, () => {
                            });
                            break;
                        }

                        default:

                            this.setState({events: this.props.diaryEvents}, () => {
                            });
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onMultiSelect = (e) => {
        this.setState({selectedAppTypes: e.value});
    }

    onCalNavigate = (date, discriminator, selection) => {

        this.onNavigate(discriminator, date, null, true);
    }

    // slotPropGetter for applying the precomputed styles to each slot
    slotPropGetter = (date, resourceId) => {

        if (!resourceId)
            return {};

        const resource = this.props.diaryResources[0];

        const currentDateTime = moment(date);

        const startTime = currentDateTime.clone();
        startTime.hour(resource.startHour).minute(resource.startMin);

        const endTime = currentDateTime.clone();
        endTime.hour(resource.endHour).minute(resource.endMin);

        const lbStartTime = currentDateTime.clone()
        lbStartTime.hour(resource.lbStartHour).minute(resource.lbstartMin);

        const lbEndTime = currentDateTime.clone();
        lbEndTime.hour(resource.lbEndHour).minute(resource.lbEndMin);

        const outOfHours = currentDateTime.isBefore(startTime) || currentDateTime.isSameOrAfter(endTime);
        const lunchTime = (currentDateTime.isSameOrAfter(lbStartTime) && currentDateTime.isBefore(lbEndTime)) && resource.lbShown;

        if (lunchTime) {
            return {
                key: date,
                className: 'special-day',
                style: {
                    backgroundColor: '#70d0f6',
                },
            }
        } else if (outOfHours) {
            return {
                key: date,
                className: 'special-day',
                style: {
                    backgroundColor: '#ac7575',
                },
            }
        }
    };

    render() {

        if (!this.props.diaryEventsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        this.postEventLoad();

        const clinicianName = ` : ${this.state.clinician.fullNameUsername}`;

        const diffString = moment(this.state.currentDate).isBefore(moment()) ? `${moment(this.state.currentDate).diff(moment(), 'week')}` : `${moment(this.state.currentDate).add(1, 'day').diff(moment(), 'week')}`
        const currentDateString = `${moment(this.state.currentDate).format('D, ddd MMM YYYY')} ${clinicianName} : (${diffString})`;

        let {slotDuration, min, max} = this.props.diaryHeader;

        const minTime = new Date(min);
        const maxTime = new Date(max);

        const zoneEvents = this.state.zoningOn && this.state.currentDate > new Date('2024-10-12') ? this.state.zoneEvents : [];

        return (
            <DiaryErrorBoundary>
                <div>

                    <Toast ref={(el) => this.growl = el} onRemove={this.onRemoveNote}/>
                    <Toast ref={(el) => this.tabletGrowl = el}/>
                    <Toast ref={(el) => this.emailSendGrowl = el}/>
                    <Toast ref={(el) => this.taskGrowl = el}/>
                    <Toast ref={(el) => this.eventGrowl = el}/>

                    <DiaryToolbar parent={this}
                                  helpURL={HELP_CLINICIAN_DIARY}
                                  weekView={false}
                                  resources={this.props.diaryEventTypes}
                                  selections={this.state.selectedAppTypes}
                                  resourceId={this.state.clinician.id}
                    />

                    <Panel header={`Day Diary : ${currentDateString}`} style={{paddingTop: '5px'}}>
                        <div className="p-grid">

                            {this.showDialogs()}
                            {this.showPalette()}

                            {this.attachContextMenus()}

                            <DragAndDropCalendar
                                selectable={this.state.selectable}
                                resizable={true}
                                onEventResize={this.resizeEvent}
                                onEventDrop={this.moveEvent}
                                className={'p-sm-10'}
                                events={this.state.events}
                                backgroundEvents={zoneEvents}
                                localizer={localizer}
                                defaultView={Views.DAY}
                                views={['day']}
                                step={slotDuration}
                                timeslots={this.state.slotSize}
                                min={minTime}
                                max={maxTime}
                                date={this.state.currentDate}
                                onNavigate={this.onCalNavigate}
                                onSelectSlot={(event) => {
                                    if (event.action === 'click' || event.action === 'select') {
                                        this.handleSelect(event);
                                    }
                                }}
                                onSelectEvent={this.onDiaryEventSelect}
                                resources={this.props.diaryResources}
                                resourceIdAccessor="resourceId"
                                resourceColorAccessor="color"
                                resourceTitleAccessor="title"
                                tooltipAccessor={event => {
                                    switch (event.discriminator) {
                                        case SR_CLINIC:
                                            const checkedInTime = event.source.attended && event.source.checkedInTime !== null ? timeTemplate2(event.source.checkedInTime) : '-';
                                            const assignedTo = event.assignedTo ? ` (${event.assignedTo})` : '';

                                            return `(${checkedInTime}) ${event.patientFullname} :${assignedTo}: ${event.title}`;
                                        case SR_ZONE:
                                            return event.title;
                                        default:
                                            return `${event.title} :: ${event.comment}`;
                                    }
                                }}
                                components={{
                                    event: (event) => {
                                        return this.showDiaryEventContent(event);
                                    },
                                    resourceHeader: (header) => {
                                        return <ResourceContent header={header}
                                                                diary={this}
                                                                events={this.props.diaryEvents}
                                                                onShowRightClickHeader={this.onShowRightClickHeader}
                                        />
                                    }
                                }}
                                eventPropGetter={this.getEventColour}
                                slotPropGetter={this.slotPropGetter}
                            />

                        </div>
                    </Panel>
                </div>
            </DiaryErrorBoundary>
        );
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({
                    action: RES_CLINICIAN_DIARY.CLEAR.action,
                    clinicianId: this.state.clinician.id
                });
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    const {

        userId,

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,
        providerColourScheme,
        dayListTextFormat,

        cliniciansHeader,

        clinicianResources,
        unscheduledResources,

        dropDownsLoaded,
        providers,

        cliniciansEventsLoaded,
        cliniciansEvents,

        movingEventsLoaded,
        movingEvents,
        recallEvents,
        diaryTasks,

        titles,

        newDiaryEvent,

        daysActivePopUpsLoaded,
        daysActivePopUps,

        selectedWeekIndex,
    } = getDiaryIds(state, ownProps);

    const accountGroups = state.users.accountGroups;

    const queryQuestionnaireResultLoaded = state.patients[SM_PATIENT_QUES_QUERY.loaded];
    const queryQuestionnaireResult = state.patients[SM_PATIENT_QUES_QUERY.id];

    const practiceDetailsLoaded = Boolean(state.preferences.practiceDetailsLoaded);
    const practiceDetails = practiceDetailsLoaded ? state.preferences.practiceDetails : [];

    return {

        message: state.stateManagement.message,

        userId,

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,
        providerColourScheme,
        dayListTextFormat,

        diaryResources: clinicianResources,
        unscheduledResources,

        dropDownsLoaded,
        providers,
        accountGroups,
        locations: state.dropDowns.locations,

        diaryHeader: cliniciansHeader,

        diaryEventsLoaded: cliniciansEventsLoaded,
        diaryEvents: cliniciansEvents,

        movingEventsLoaded,
        movingEvents,
        recallEvents,
        diaryTasks,

        newDiaryEvent,

        nextDiaryEventLoaded: state.diary.nextDiaryEventLoaded,
        nextDiaryEvent: state.diary.nextDiaryEvent,

        prevDiaryEventLoaded: state.diary.prevDiaryEventLoaded,
        prevDiaryEvent: state.diary.prevDiaryEvent,

        titles,

        reportTexts: state.reports.reportTexts,

        loginIdentity: state.login.user,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        queryQuestionnaireResultLoaded,
        queryQuestionnaireResult,

        practiceDetails,

        daysActivePopUpsLoaded,
        daysActivePopUps,

        selectedWeekIndex,

        zoneEvents: state.housekeeping[`zoneEvents_${ownProps.options.clinician.id}`],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        wsmessage: state.websockets.message,

        currentState: state.stateManagement[`${SM_CLINICIAN_DIARY.id}_${ownProps.options.clinician.id}`],
    }
};

const MapDispatchToProps = dispatch => {

    return {
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getDiaryEvents: (start, end, loginIdentityId, userId) => dispatch(getDiaryEventsByClinician(start, end, loginIdentityId, userId)),
        getDiaryActivePopUps: (date) => dispatch(getResource(RES_PRACTICE_DIARY.POPUPS, {date: moment(date).format(JSON_DATE_FORMAT)})),
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getDiarySortOrder: () => dispatch(getDiarySortOrder(RES_DIARY_SORT_ORDER.GET)),
        getAccountGroups: () => dispatch(getUserResource(RES_getAccountGroups.GET, {})),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        reportText: (type) => dispatch(getReportText(RES_REPORT_TEXTS.GET, {type})),

        addAppointment: (newAppointment, createRecall, referralDate, patient, NHSRegistration) => dispatch(addAppointment(newAppointment, createRecall, referralDate, patient, NHSRegistration, true)),
        addDiaryNote: (note) => dispatch(addDiaryNote(RES_diaryNote.ADD, note)),
        addBack: (appointment) => dispatch(addBack(appointment)),
        addDiaryEvent: (newEvent) => dispatch(addDiaryEvent(newEvent)),
        addAdHocRule: (providerId, start, end, loginId) => dispatch(addAdHocRule(providerId, start, end, loginId)),

        addToLists: (tabletMember) => dispatch(addToLists(RES_TABLET_LISTS.ADD_TO, tabletMember)),

        updateAppComment: (id, summary, comment, typeId) => dispatch(updateAppComment(id, summary, comment, typeId)),
        updateEventComment: (id, comment) => dispatch(updateEventComment(id, comment)),

        unscheduleProvider: (rule, start, end, loginId) => dispatch(removeSchedules(rule, start, end, loginId)),

        previewAppointmentLetter: (patientDocument, appointment, previewToken) => dispatch(previewAppointmentLetter(RES_sendAppointmentLetter.PREVIEW, patientDocument, appointment, previewToken)),
        sendAppointmentLetter: (patientDocument, appointment) => dispatch(sendAppointmentLetter(RES_sendAppointmentLetter.CREATE, patientDocument, appointment)),
        sendSMS: (details) => dispatch(sendSMS(RES_PATIENT_SMS.SEND, details)),
        sendEmail: (sentById, patientId, email, subject, message) => dispatch(sendEmail(RES_PATIENT_EMAIL.SEND, sentById, patientId, email, subject, message)),
        resendPortalReference: (patientId) => dispatch(resendPortalReference(RES_RESEND_PATIENT_PORTAL.SEND, patientId)),

        closeTask: (task, loginIdentityId) => dispatch(closeDiaryTask(task, loginIdentityId)),

        issueReceipt: (receipt, treatmentPlanId, salesInvoice) => dispatch(issueReceipt2(RES_PATIENT_ACCOUNT.ISSUE_RECEIPT2, receipt, treatmentPlanId, salesInvoice)),
        issueInvoice: (invoice, treatmentPlanId) => dispatch(issueInvoice2(RES_PATIENT_ACCOUNT.ISSUE_INVOICE2, invoice, treatmentPlanId)),

        queryQuestionnaire: (params) => dispatch(queryQuestionnaire(params)),

        searchForNextEventDate: (params) => dispatch(getNextPrevEventDate(RES_SEARCH_EVENTS.NEXT, params)),
        searchForPrevEventDate: (params) => dispatch(getNextPrevEventDate(RES_SEARCH_EVENTS.PREV, params)),

        requestWorkRequired: (workRequired) => dispatch(requestWorkRequired(RES_WORK_REQUIRED.REQUEST, workRequired)),
        searchForWorkRequired: (params) => dispatch(getLabResource(RES_WORK_REQUIRED.SEARCH, params)),
        searchForWorkByBarcode: (params) => dispatch(getLabResource(RES_WORK_REQUIRED.SEARCH_BY_BARCODE, params)),
        markWorkReceived: (labEntry) => dispatch(markWorkStatus(RES_WORK_REQUIRED.RECEIVED, labEntry)),

        sendMessage: (message) => dispatch(messageBus(message)),
        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const ClinicianDiary = connect(mapStateToProps, MapDispatchToProps)(ConnectedClinicianDiary);

export default ClinicianDiary;
