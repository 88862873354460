import {Button} from 'primereact/components/button/Button';
import {FileUpload} from 'primereact/components/fileupload/FileUpload';
import {Dropdown} from "primereact/dropdown";

export class MyPaymentsUpload extends FileUpload {

    constructor(props) {
        super(props);
    }

    upload = () => {
        if (this.props.customUpload) {
            if (this.props.fileLimit) {
                this.uploadedFileCount += this.state.files.length;
            }

            if (this.props.uploadHandler) {
                this.props.uploadHandler({
                    files: this.state.files,
                    options: {
                        clear: this.clear,
                        props: this.props
                    }
                })
            }
        }
        else {
            this.setState({msgs:[]});
            let xhr = new XMLHttpRequest();
            let formData = new FormData();

            if (this.props.onBeforeUpload) {
                this.props.onBeforeUpload({
                    'xhr': xhr,
                    'formData': formData
                });
            }

            for (let file of this.state.files) {
                const fileName = {name: file.name, classificationId: file.classificationId};
                formData.append(this.props.name, file, JSON.stringify(fileName));
            }

            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    this.setState({ progress: Math.round((event.loaded * 100) / event.total) }, () => {
                        if (this.props.onProgress) {
                            this.props.onProgress({
                                originalEvent: event,
                                progress: this.state.progress
                            });
                        }
                    });
                }
            });

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    this.setState({ progress: 0 });

                    if (xhr.status >= 200 && xhr.status < 300) {
                        if (this.props.fileLimit) {
                            this.uploadedFileCount += this.state.files.length;
                        }

                        if (this.props.onUpload) {
                            this.props.onUpload({xhr: xhr, files: this.state.files});
                        }
                    }
                    else {
                        if (this.props.onError) {
                            this.props.onError({xhr: xhr, files: this.state.files});
                        }
                    }
                }
            };

            xhr.open('POST', this.props.url, true);

            if(this.props.onBeforeSend) {
                this.props.onBeforeSend({
                    'xhr': xhr,
                    'formData': formData
                });
            }

            xhr.withCredentials = this.props.withCredentials;

            xhr.send(formData);
        }
    }
}

