import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {categoryDropDown, checkBox, spinner, spinnerStep} from './OnChangeUtils';

export const OrthoIntraOral1 = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header='Intra-Oral'>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>Incisor Relationship</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'IncisorRelationship', 'incisorRelationshipACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-9 p-md-9">
                    <label>Overjet</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinnerStep(props, 'overjetVal', -15, 20, 0.5, readOnly, '')}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>Overbite</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'OverbiteCompleteness', 'overbiteACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6"/>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'OverbiteClass', 'overbiteAssACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>Openbite</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'openBiteOn', '', readOnly)}
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'Openbite', 'openBiteACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'openBiteVal', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>Molar Relationship</label>
                </div>
                <div className="p-lg-1 p-md-1" style={{textAlign: 'right'}}>R</div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'MolarRelationshipRight', 'molarRelationshipRightACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'MolarRelationshipLeft', 'molarRelationshipLeftACE', readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">L</div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>Canine Relationship</label>
                </div>
                <div className="p-lg-1 p-md-1" style={{textAlign: 'right'}}>R</div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'CanineRelationshipRight', 'canineRelationshipRightACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'CanineRelationshipLeft', 'canineRelationshipLeftACE', readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">L</div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>Centerlines</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Upper</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'CentreLineUpper', 'upperCentreLinesACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'upperCentreLinesVal', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Lower</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'CentreLineLower', 'lowerCentreLinesACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'lowerCentreLinesVal', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>Crossbites</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'Crossbite', 'crossbitesACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>Displacements</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'Displacement', 'disDisplacementsACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'disDisplacementsVal', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>Crowding</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Upper Labial Seg.</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'CrowdingULS', 'upperLabialSegACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Lower Labial Seg.</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'CrowdingLLS', 'lowerLabialSegACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Upper Buccal Seg.</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'CrowdingUBS', 'upperBuccalSegACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Lower Buccal Seg.</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'CrowdingLBS', 'lowerBuccalSegACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>Spacing</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'spacing', '', readOnly)}
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Midline Diastema</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'midlineDiastema', -10, 99, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Fraenum</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'fraenum', -10, 99, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Generalised</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'generalised', -10, 99, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>
            </div>
        </Panel>
    );
};
