import React from 'react';
import {connect} from "react-redux";
import {Panel} from 'primereact/panel';
import {setState, SM_PATIENT_SEDATION_RECORDS} from "../../../../actions/stateManagement";
import {
    HM_AddPatientSedationRecord,
    HM_DeletePatientSedationRecord,
    HM_EditPatientSedationRecord,
    HM_notImplemented,
    HM_PatientSedationHistory,
    ST_TYPES
} from "../../../../Constants";
import {BaseComponent} from "../../../BaseComponent";

import {ShowMessageDialog, ShowQuestionDialog} from "../../Diary/components/EventComponent";
import {ProgressBar} from "primereact/progressbar";
import _ from "lodash";
import {Button} from "primereact/components/button/Button";
import {UPDATE_TAB} from "../../../../actions";
import AddPatientSedationRecord from "../../AppointmentDetails/Dialogs/AddPatientSedationRecord";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {dateTemplateVeryShort} from "../../../PatientDynamicItems/Utils";
import {deleteSedationRecord, getResource, RES_PATIENT_SEDATION_RECORDS,} from "../../../../actions/personal";
import {getObjectStore, RES_OBJECT_STORE} from "../../../../actions/objectStore";
import {getObjectStoreIds} from "../../Preferences/Utils";
import {SEDATION_ASSISTANT} from "../../Preferences/Constants";
import {getSedationAssistantsShort} from "../../../../actions/users";

const requiredObject = [SEDATION_ASSISTANT];

class ConnectedSedationHistorySection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.sedationRecordsId,

                sedationRecordsLoaded: false,
                sedationRecords: [],

                newRecordId: -100,

                selection: null,
                first: 0,
                rows: 5,
            };

            this.showDialogs = this.showDialogs.bind(this);

            this.toolbarCallbacks = {

                [HM_notImplemented.id]: this.onNotImplemented,
            }
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getSedationAssistantsShort();
            this.props.getObjectStore(requiredObject);
            this.props.getSedationRecords(this.props.patientId, this.props.appointmentId)
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_SEDATION_RECORDS.GET.receive:
                case RES_PATIENT_SEDATION_RECORDS.DELETE.action:

                    this.setState({sedationRecords: this.props.sedationRecords, sedationRecordsLoaded: true});
                    break;

                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);
        this.setState(state);
    }

    onSelectionChange = (e) => {

        this.setState({selection: e.value});
    };

    addNewPatientSedationRecord = (patientSedationRecord) => {

        patientSedationRecord.id = this.state.newRecordId;
        patientSedationRecord.patientId = this.props.treatmentPlan.patient.id;
        patientSedationRecord.administeredById = patientSedationRecord.administeredBy.id
        patientSedationRecord.assistedById = patientSedationRecord.assistedBy.id
        patientSedationRecord.appointmentId = this.props.appointmentId;

        const sedationRecords = [...this.state.sedationRecords];
        sedationRecords.unshift(patientSedationRecord);

        this.setState({
            sedationRecords,
            [HM_AddPatientSedationRecord.id]: false,
            newRecordId: this.state.newRecordId + 1
        }, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
        });
    }

    updatePatientSedationRecord = (updatedPatientSedationRecord) => {

        updatedPatientSedationRecord.edited = true;
        updatedPatientSedationRecord.administeredById = updatedPatientSedationRecord.administeredBy.id
        updatedPatientSedationRecord.assistedById = updatedPatientSedationRecord.assistedBy.id

        const sedationRecords = [...this.state.sedationRecords];

        const index = _.findIndex(sedationRecords, record => record.id === updatedPatientSedationRecord.id);
        sedationRecords[index] = updatedPatientSedationRecord;

        this.setState({sedationRecords, [HM_EditPatientSedationRecord.id]: false}, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
        });
    }

    onDeletePatientSedationRecord = () => {
        this.onHideMenuEntry(HM_DeletePatientSedationRecord.id);
        this.props.deleteSedationRecord(this.props.appointmentId, this.state.selection.id);
    }

    showDialogs() {

        const content = [];

        if (this.state[HM_AddPatientSedationRecord.id]) {
            content.push(
                <AddPatientSedationRecord loginIdentity={this.props.loginIdentity}
                                          patId={this.props.patId}
                                          header={HM_AddPatientSedationRecord.header}
                                          editing={false}
                                          onOkDialog={this.addNewPatientSedationRecord}
                                          onHideDialog={this.onHideMenuEntry}
                                          onChange={this.props.onChange}
                                          usersShort={this.props.sedationAssistants}
                />
            );
        } else if (this.state[HM_EditPatientSedationRecord.id]) {
            content.push(
                <AddPatientSedationRecord loginIdentity={this.props.loginIdentity}
                                          patId={this.props.patId}
                                          header={HM_EditPatientSedationRecord.header}
                                          editing={true}
                                          record={this.state.selection}
                                          onOkDialog={this.updatePatientSedationRecord}
                                          onHideDialog={this.onHideMenuEntry}
                                          onChange={this.props.onChange}
                                          usersShort={this.props.sedationAssistants}
                />
            );
        } else {
            content.push(ShowQuestionDialog(this, HM_DeletePatientSedationRecord, this.onDeletePatientSedationRecord));
            content.push(ShowMessageDialog(this, HM_notImplemented));
        }
        return content;
    }

    buildMenuItems = () => {

        if (this.props.selection === null) {
            return [];
        }
        const items = [];

        items.push({
            label: HM_EditPatientSedationRecord.label,
            icon: HM_EditPatientSedationRecord.icon,
            command: (e) => this.setState({[HM_EditPatientSedationRecord.id]: true}),
        });

        items.push({
            label: HM_DeletePatientSedationRecord.label,
            icon: HM_DeletePatientSedationRecord.icon,
            command: (e) => this.setState({[HM_DeletePatientSedationRecord.id]: true}),
        });
        return items;
    }

    providerTemplate(props, row) {
        return _.find(props.usersShort, userShort => userShort.id === row.administeredById).fullName;
    };

    sedationTypeTemplate = (row) => {

        return _.find(ST_TYPES, type => type.value === row.sedationTechnique).label;
    }

    render() {

        if (!this.state.sedationRecordsLoaded || !this.props.sedationAssistantsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        let cm = null;
        const items = this.buildMenuItems();

        const header = (
            <div className='panel-header-centered-content'><label
                id='panel-header'>{HM_PatientSedationHistory.header}</label>
                <Button tooltip={HM_PatientSedationHistory.message}
                        tooltipOptions={{position: 'left'}}
                        icon={HM_PatientSedationHistory.icon}
                        onClick={(e) => {
                            this.setState({[HM_AddPatientSedationRecord.id]: true})
                        }}>
                </Button>
            </div>
        );

        return (
            <React.Fragment>
                {this.showDialogs()}

                <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

                <DataTable value={this.state.sedationRecords}
                           header={header}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}
                           selection={this.state.selection}
                           onSelectionChange={this.onSelectionChange}
                           contextMenuSelection={this.state.selection}
                           onContextMenuSelectionChange={this.onSelectionChange}
                           onContextMenu={e => cm.show(e.originalEvent)}
                >
                    <Column body={row => dateTemplateVeryShort(row.administeredOn)}
                            header="Administered On"
                            style={{width: '10%'}}/>
                    <Column body={row => this.providerTemplate(this.props, row)}
                            header="Administered By"
                            style={{width: '10%'}}/>
                    <Column body={row => this.sedationTypeTemplate(row)}
                            header="Type"
                            style={{width: '10%'}}/>
                </DataTable>
            </React.Fragment>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const sedationRecordsLoadedId = `${SM_PATIENT_SEDATION_RECORDS.loaded}_${ownProps.appointmentId}`;
    const sedationRecordsId = `${SM_PATIENT_SEDATION_RECORDS.id}_${ownProps.appointmentId}`;

    let sedationRecordsLoaded = state.patients[sedationRecordsLoadedId];
    sedationRecordsLoaded = sedationRecordsLoaded === undefined ? false : sedationRecordsLoaded;

    const patientId = ownProps.treatmentPlan.patient.id;

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {
        message: state.stateManagement.message,

        patientId,
        sedationRecordsLoaded,
        sedationRecordsId,
        sedationRecords: state.patients[sedationRecordsId],

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        sedationAssistantsLoaded: state.users.sedationAssistantsLoaded,
        sedationAssistants: state.users.sedationAssistants,

        currentState: state.stateManagement[sedationRecordsId],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getSedationAssistantsShort: () => dispatch(getSedationAssistantsShort()),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        getSedationRecords: (patientId, appointmentId) => dispatch(getResource(RES_PATIENT_SEDATION_RECORDS.GET, {
            patientId,
            appointmentId
        })),
        deleteSedationRecord: (appointmentId, recordId) => dispatch(deleteSedationRecord(RES_PATIENT_SEDATION_RECORDS.DELETE, appointmentId, recordId)),

        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const SedationHistorySection = connect(MapStateToProps, MapDispatchToProps)(ConnectedSedationHistorySection);

export default SedationHistorySection;

