import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Card} from 'primereact/components/card/Card';

import {
    calendarCompact,
    checkBox,
    CONST_ASK,
    CONST_ASK_LABEL,
    CONST_FEMALE,
    CONST_FEMALE_LABEL, CONST_INDETERMINATE, CONST_INDETERMINATE_LABEL,
    CONST_MALE,
    CONST_MALE_LABEL,
    inputText,
    inputTextArea,
    outputCurrencyText
} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    APP_CREATED,
    HM_AddAppointment,
    HM_AddAppointmentPatient,
    HM_setIndependentReferrer,
    HM_setPatientReferrer,
    HM_setProviderReferrer,
    LOC_STATUS_ACTIVE,
    PAT_STATUS_ACTIVE, PJS_USABLE,
    REF_INDEPENDENT,
    REF_PATIENT,
    REF_PROVIDER
} from "../../../../Constants";
import {ICON_CANCEL, ICON_NEXT, ICON_OK, ICON_PREV,} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getAppointmentPatientsByFirstname, getAppointmentPatientsBySurname} from "../../../../actions/findPatients";
import * as Actions from "../../../../actions";
import {
    getResource as getHKResource,
    getResource,
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_JSTAGES
} from "../../../../actions/housekeeping";
import {ac} from "../../../../index";
import {Name} from "../../../PatientDynamicItems/Name";
import {BaseComponent} from "../../../BaseComponent";
import * as DefaultData from "../../DefaultData";
import {APP_CREATE_STAGE_1, APP_CREATE_STAGE_2} from "../../DefaultData";
import {ProgressBar} from "primereact/progressbar";
import {General} from "../components/General";
import SetIndependentReferrer from "../../PatientDetails/dialogs/SetIndependentReferrer";
import SetProviderReferrer from "../../PatientDetails/dialogs/SetProviderReferrer";
import SetPatientReferrer from "../../PatientDetails/dialogs/SetPatientReferrer";
import {getAllUsers} from "../../../../actions/users";
import {SelectButton} from "primereact/selectbutton";
import {dateTemplateVeryShort} from "../../../PatientDynamicItems/Utils";
import {PatientImage} from "../../../PatientDynamicItems/PatientImage";
import {nhsTemplate} from "../../Patients/Utils";
import {Panel} from "primereact/components/panel/Panel";
import {Address} from "../../../DynamicItems/Address";
import {getDropDowns, RES_getDropDowns} from "../../../../actions/dropDowns";

export class ConnectedAddAppointment extends BaseComponent {

    constructor(props) {
        super(props);

        const provider = _.find(props.providers, provider => provider.id === props.resource.resourceId);

        const targetTypeId = props.zoneSnap ? props.resource.zoneAppointmentTypeId : provider.defaultAppType;
        let providerDefaultAppType = _.find(props.appointmentTypes, type => targetTypeId === type.id);

        providerDefaultAppType = providerDefaultAppType === undefined ? props.appointmentTypes[0] : providerDefaultAppType;

        let location = _.find(this.props.locations, location => provider.locationId && location.id === provider.locationId);
        location = location === undefined ? null : location;

        this.state = {

            appointment: {
                appointmentId: -1000,
                mc: ac.getMcId(),
                bookedBy: {id: this.props.loginIdentity.id},
                bookingDate: new Date(),
                patient: {id: 2},
                title: 'Appointment',
                appointmentWith: {id: props.resource.resourceId},
                shortDescription: '',
                description: '',
                status: APP_CREATED,
                workRequired: false,
                apType: providerDefaultAppType,
                cancellationComment: '',
                claimId: null,
                iotnScore: 0,
                aestheticComponent: 0,
                start: props.resource.start,
                end: props.resource.end,
                location,
            },

            matchedPatients: [],
            selectedPatient: null,
            criteria: true,
            firstMP: 0,
            MPRows: 5,

            patient: _.cloneDeep(DefaultData.PatientData(ac.getMcId())),
            NHSRegistration: _.cloneDeep(DefaultData.PatientNHSDetails),

            settings: {
                createRecall: providerDefaultAppType ? providerDefaultAppType.recallType !== null : false,
                onReferral: false,
                referralDate: null,
                currentBalance: 0.0,
            },

            search: {
                item: '',
            },

            [HM_setIndependentReferrer.id]: false,
            [HM_setProviderReferrer.id]: false,
            [HM_setPatientReferrer.id]: false,

            addPatientStage: APP_CREATE_STAGE_1,
        };

        this.state.patient.registeredBy = this.props.loginIdentity;
        this.state.patient.registeredOn = new Date();
        this.state.patient.status = PAT_STATUS_ACTIVE.value;
    }

    componentDidMount() {

        this.props.getAppointmentDiaryTypes();

        if (document.getElementById('search.item')) {
            document.getElementById('search.item').focus();
        }
        this.props.getAllUsersShort();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_APP_PATIENT_SEARCH:

                    const settings = {...this.state.settings};
                    settings.currentBalance = new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    }).format(0.0);
                    settings.billingGroup = '';

                    this.setState({matchedPatients: this.props.matchedPatients, selectedPatient: null, settings});
                    break;

                case Actions.RECEIVE_HOUSEKEEPING_JSTAGES:

                    const patient = {...this.state.patient};
                    patient.patientJourney = _.cloneDeep(DefaultData.patientJourney(ac.getMcId()));

                    let journeyStages = _.filter(this.props.journeyStages, stage => stage.status === PJS_USABLE);

                    let newPatientStage = _.find(journeyStages, stage => stage.code === 'NPUB' || stage.code === 'NP');

                    patient.patientJourney.stage = newPatientStage === undefined ? journeyStages[0] : newPatientStage;

                    this.setState({patient, journeyStages});
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        let gender = CONST_ASK;

        if (event.owner === 'patient.title') {
            switch (event.value.genderType) {
                case CONST_FEMALE_LABEL:
                    gender = CONST_FEMALE;
                    break;
                case CONST_MALE_LABEL:
                    gender = CONST_MALE;
                    break;
                case CONST_ASK_LABEL:
                    gender = CONST_ASK;
                    break;
                case CONST_INDETERMINATE_LABEL:
                    gender = CONST_INDETERMINATE;
                    break;
                default:
                    break;
            }
            _.set(state, 'patient.gender', gender);
        } else if (event.owner === 'criteria') {
            _.set(state, 'search.item', '');
        } else if (event.owner === 'selectedPatient.alternateRef') {
            _.set(state, 'appointment.alternateRef', event.value);
        }
        this.setState(state);
    }

    onDialogChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        if (event.owner === 'search.item' && event.value.length > 1) {

            if (this.state.criteria) {
                this.props.getPatientsBySurname(event.value);
            } else {
                this.props.getPatientsByForename(event.value);
            }
        }
        this.setState(state);
    }

    onTypeChange = (event) => {

        const appointment = {...this.state.appointment};
        appointment.apType = event.value;

        const patient = {...this.state.patient};
        patient.recall = appointment.apType.recallType !== null;

        const settings = {...this.state.settings};
        settings.createRecall = appointment.apType.recallType !== null;

        this.setState({appointment, patient, settings});
    }

    onOk = () => {
        this.props.onOkDialog(this.state.appointment, this.state.patient, this.state.NHSRegistration, this.state.settings);
    }

    onPatientSelection = (event) => {

        const {id, balance, billingGroup} = event.value;
        const settings = {...this.state.settings};

        this.onDialogChange({owner: 'patient.billingGroup', value: billingGroup});
        settings.currentBalance = new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(balance);

        const appointment = {...this.state.appointment};
        appointment.patient.id = id;

        this.setState({appointment, selectedPatient: event.value, settings});
    }

    setIndependentReferrer = (referrer) => {

        this.setState({[HM_setIndependentReferrer.id]: false}, () => {
            this.onChange({owner: 'patient.referrer', value: referrer});
        });
    }

    setProviderReferrer = (provider) => {

        const referrer = _.cloneDeep(DefaultData.referrerData(ac.getMcId()));
        referrer.id = -1;
        referrer.type = REF_PROVIDER.value;
        referrer.provider = {id: provider.id, firstName: provider.firstName, lastName: provider.lastName};
        referrer.address = null; // this must be null as address info comes from provider
        referrer.occupation = null; // this must be null as this is a provider

        this.setState({[HM_setProviderReferrer.id]: false}, () => {
            this.onChange({owner: 'patient.referrer', value: referrer});
        });
    }

    setPatientReferrer = (patient) => {

        const referrer = _.cloneDeep(DefaultData.referrerData(ac.getMcId()));
        referrer.id = -1;
        referrer.type = REF_PATIENT.value;
        referrer.patient = {id: patient.id, firstName: patient.firstName, lastName: patient.lastName};
        referrer.address = null; // this must be null as address info comes from patient
        referrer.occupation = null; // this must be null as this is a patient

        this.setState({[HM_setPatientReferrer.id]: false}, () => {
            this.onChange({owner: 'patient.referrer', value: referrer});
        });
    }

    showDialogs = () => {

        if (this.state[HM_setIndependentReferrer.id]) {
            return (
                <SetIndependentReferrer onHideDialog={this.onHideMenuEntry}
                                        onOkDialog={this.setIndependentReferrer}
                />
            );
        } else if (this.state[HM_setProviderReferrer.id]) {
            return (
                <SetProviderReferrer onHideDialog={this.onHideMenuEntry}
                                     onOkDialog={this.setProviderReferrer}
                />
            );
        } else if (this.state[HM_setPatientReferrer.id]) {
            return (
                <SetPatientReferrer onHideDialog={this.onHideMenuEntry}
                                    onOkDialog={this.setPatientReferrer}
                />
            );
        }
    }

    onReferrerChange = (event) => {

        let value = event.value;

        if (value === null) { // selection not changed
            value = this.state.patient.referrer === null ? null : this.state.patient.referrer.type;
        }

        if (value === null) return;

        switch (value) {
            case REF_INDEPENDENT.value:
                this.onShowMenuEntry({item: {target: HM_setIndependentReferrer.id}});
                break;
            case REF_PROVIDER.value:
                this.onShowMenuEntry({item: {target: HM_setProviderReferrer.id}});
                break;
            case REF_PATIENT.value:
                this.onShowMenuEntry({item: {target: HM_setPatientReferrer.id}});
                break;
            default:
                break;
        }
    }

    onProviderChange = (event) => {

        let provider = {id: event.value.id};
        this.onChange({owner: 'patient.provider', value: provider});
    }

    insertNHSinfo = (nhsPatient, propsSettings) => {

        if (nhsPatient) {

            const header = <div>NHS : {checkBox(propsSettings, 'onReferral', 'Treatment On Referral', false, false)}</div>

            return (

                <Panel header={header} style={{paddingTop: '5px'}}>
                    <div className="p-grid p-fluid">
                        <div className='p-col-4'>
                            <label>Referral Date</label>
                        </div>
                        <div className='p-col-8'>
                            {calendarCompact(propsSettings, 'referralDate', !this.state.settings.onReferral, false)}
                        </div>
                    </div>
                </Panel>
            )
        } else {
            return null;
        }
    }

    insertAppointmentType = (appointmentType, onTypeChange, appointmentTypes) => {
        return (
            <Dropdown value={appointmentType}
                      options={appointmentTypes}
                      optionLabel='description'
                      onChange={onTypeChange}
                      autoWidth={false}
            />
        )
    }

    patientSelection(footer) {

        const billingGroup = this.state.selectedPatient?.billingGroup ?? '';
        const alternateRef = this.state.selectedPatient?.alternateRef ?? '';

        let nhsPatient = false;

        if (this.state.patient.createNewPatient) {
            nhsPatient = this.state.patient.NHSPatient;
        } else {
            nhsPatient = this.state.selectedPatient ? this.state.selectedPatient.nhsPatient : false;
        }

        // match the providers default appointment type if not return the first in the list
        let appointmentType = _.find(this.props.appointmentTypes, type => type.id === this.state.appointment.apType.id);
        appointmentType = appointmentType === undefined ? this.props.appointmentTypes[0] : appointmentType;

        // match the providers default appointment type if not return the first in the list
        let location = _.find(this.props.locations, location => this.state.appointment.location && location.id === this.state.appointment.location.id);
        location = location === undefined ? null : location;

        let provider = _.find(this.props.usersShort, user => this.state.selectedPatient && this.state.selectedPatient.providerId === user.id);
        provider = provider === undefined ? '-' : provider.fullName;

        const style = this.state.settings.currentBalance && this.state.settings.currentBalance.startsWith('-') ? {color: 'red'} : null;

        const propsSearch = {
            onChange: this.onDialogChange,
            target: 'search',
            search: this.state.search,
        };

        const propsAppointment = {
            onChange: this.onChange,
            target: 'appointment',
            appointment: this.state.appointment,
        };

        const propsSettings = {
            onChange: this.onChange,
            target: 'settings',
            settings: this.state.settings,
        };

        const patientProps = {
            onChange: this.onChange,
            target: 'patient',
            patient: this.state.patient,
        };

        const selectItems = [
            {label: 'Surname', value: true},
            {label: 'Forename', value: false}
        ];

        const activeLocations = _.filter(this.props.locations, location => location.status === LOC_STATUS_ACTIVE.name);

        let key = 1;

        if (!this.state.patient.createNewPatient) {

            const content = [];
            const appNotDefined = this.state.selectedPatient === null || this.state.appointment.apType.id === null || this.state.appointment.shortDescription.trim() === '';

            const footer = (
                <div>
                    <Button label="OK"
                            icon={ICON_OK}
                            onClick={() => {
                                this.onOk()
                            }}
                            disabled={appNotDefined}/>
                    <Button label="Add New Patient"
                            icon={ICON_OK}
                            onClick={() => {
                                const patient = {...this.state.patient};
                                patient.createNewPatient = true;
                                this.setState({patient}, () => {
                                    this.props.getJourneyStages();
                                    this.props.getDropDowns();
                                });
                            }}
                            disabled={this.state.patient.createNewPatient}/>
                    <Button label="Cancel"
                            icon={ICON_CANCEL}
                            onClick={() => {
                                this.props.onHideDialog(HM_AddAppointment.id)
                            }}/>
                </div>
            );

            content.push(
                <div key = {key++} className='p-grid'>
                    <div className="p-col-5">
                        <Panel header='Patient Selection'>
                            <div className="p-col-12 p-fluid">
                                <SelectButton value={this.state.criteria}
                                              options={selectItems}
                                              onChange={(e) =>
                                                  this.onChange({owner: 'criteria', value: e.value})
                                              }
                                              style={{paddingBottom: '10px'}}
                                />
                                {inputText(propsSearch, 'item', '', false, true)}
                            </div>

                            <DataTable header='Patient Match(s)'
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       value={this.state.matchedPatients}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.MPRows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={(e) => {
                                           this.onPageFlex(e, 'firstMP', 'MPRows');
                                       }}
                                       first={this.state.firstMP}
                                       selection={this.state.selectedPatient}
                                       onSelectionChange={this.onPatientSelection}
                            >

                                <Column body={nhsTemplate} style={{width: '20%'}}/>
                                <Column field="fullName" style={{width: '50%'}}/>
                                <Column body={(rowData) => dateTemplateVeryShort(rowData.dateOfBirth)}
                                        style={{width: '30%'}}/>
                            </DataTable>
                            <div className="p-col-12">
                                {checkBox(propsAppointment, 'workRequired', 'Appointment requires external work returned', false, false)}
                            </div>
                        </Panel>
                        {this.insertNHSinfo(nhsPatient, propsSettings)}
                    </div>
                    <div className="p-col-7">
                        <Panel header='Appointment Details'>
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-3">
                                    <label>Summary</label>
                                </div>
                                <div className="p-col-9">
                                    {inputText(propsAppointment, 'shortDescription', '', false, true)}
                                </div>

                                <div className="p-col-3">
                                    <label>Comment</label>
                                </div>
                                <div className="p-col-9">
                                    {inputTextArea(propsAppointment, 'description', 4, -1, false, false)}
                                </div>

                                <div className="p-col-3">
                                    <label>Type</label>
                                </div>
                                <div className="p-col-5">
                                    {this.insertAppointmentType(appointmentType, this.onTypeChange, this.props.appointmentTypes)}
                                </div>
                                <div className="p-col-4">
                                    {checkBox(propsSettings, 'createRecall', 'Create Recall', false, false)}
                                </div>

                                <div className="p-col-3">
                                    <label>Location</label>
                                </div>
                                <div className="p-col-9">
                                    <Dropdown value={location}
                                              options={activeLocations}
                                              optionLabel='name'
                                              onChange={(e) => this.onChange({
                                                  owner: 'appointment.location',
                                                  value: e.value
                                              })}
                                              autoWidth={false}
                                    />
                                </div>

                            </div>
                        </Panel>
                        <Panel header='Patient Info' style={{paddingTop: '5px'}}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-3">
                                            <label>Assigned To</label>
                                        </div>
                                        <div className="p-col-9">
                                            {inputText({provider: {name: provider}, target: 'provider'}, 'name', '', true, true)}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-6">
                                            <label>Current Balance</label>
                                        </div>
                                        <div className="p-col-6">
                                            <p style={style}>{this.state.settings.currentBalance}</p>
                                        </div>
                                        <div className="p-col-6">
                                            <label>Family Group</label>
                                        </div>
                                        <div className="p-col-6">
                                            <InputText type="text"
                                                       disabled={true}
                                                       value={billingGroup ? billingGroup :  ''}/>
                                        </div>
                                        <div className="p-col-6">
                                            <label>Altern.Ref.</label>
                                        </div>
                                        <div className="p-col-6">
                                            <InputText type="text"
                                                       onChange={(e) => this.onChange({
                                                           owner: 'selectedPatient.alternateRef',
                                                           value: e.target.value
                                                       })}
                                                       disabled={false}
                                                       value={alternateRef}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <PatientImage patient={this.state.selectedPatient}/>
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
            )
            return (
                <Dialog style={{width: "50%"}}
                        header={this.props.header}
                        footer={footer}
                        visible={true}
                        modal={true}
                        resizable={true}
                        onHide={() => {
                            this.props.onHideDialog(HM_AddAppointment.id)
                        }}
                >
                    <div className='p-grid'>

                        {content}

                        {this.showDialogs()}

                    </div>
                </Dialog>
            )
        } else {

            if (!this.props.journeyStagesLoaded) {
                return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
            }

            const content = [];

            let footer = '';

            switch (this.state.addPatientStage) {
                case APP_CREATE_STAGE_1: {

                    const mobileNeeded = this.state.patient.mobile === '';
                    const emailNeeded = this.state.patient.email === '';
                    const mobileOrEmailNeeded = mobileNeeded || emailNeeded;

                    const patientDetailsNeeded = this.state.patient.firstName === '' || this.state.patient.lastName === '' || !this.state.patient.title;
                    const providerNeeded = this.state.patient.provider === null;

                    const appNotDefined = mobileOrEmailNeeded || patientDetailsNeeded || providerNeeded;

                    footer = (
                        <div>
                            <Button label="Next"
                                    icon={ICON_NEXT}
                                    onClick={() => {
                                        this.setState({addPatientStage: APP_CREATE_STAGE_2});
                                    }}
                                    disabled={appNotDefined}/>
                            <Button label="Cancel"
                                    icon={ICON_CANCEL}
                                    onClick={() => {
                                        this.props.onHideDialog(HM_AddAppointment.id)
                                    }}/>
                        </div>
                    );

                    content.push(
                        <div className="p-grid p-fluid">
                            <div className="p-col-6">
                                <Name onChange={this.onChange}
                                      target='patient'
                                      onTablet={false}
                                      {...this.state}
                                      {...this.props}
                                />
                            </div>
                            <div className="p-col-6">
                                <General onChange={this.onChange}
                                         patient={this.state.patient}
                                         onReferrerChange={this.onReferrerChange}
                                         providers={this.props.usersShort}
                                         onProviderChange={this.onProviderChange}
                                         journeyStages={this.state.journeyStages}
                                />
                                <div className="p-grid" style={{paddingTop: '5px'}}>
                                    <div className="p-col-3">
                                        <label>Mobile</label>
                                    </div>
                                    <div className="p-col-9">
                                        {inputText(patientProps, 'mobile', '', false, mobileNeeded)}
                                    </div>
                                    <div className="p-col-3">
                                        <label>Email</label>
                                    </div>
                                    <div className="p-col-9">
                                        {inputText(patientProps, 'email', '', false, emailNeeded)}
                                    </div>
                                    <div className="p-col-3">
                                        <label>Altern.Ref.</label>
                                    </div>
                                    <div className="p-col-9">
                                        {inputText(patientProps, 'alternateRef', '', false, false)}
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12">
                                {this.insertNHSinfo(nhsPatient, propsSettings)}
                            </div>
                            <div className="p-col-12">
                                <Address onChange={this.onChange}
                                         target='patient.contactDetails'
                                         header='Main Address'
                                         postCodeMandatory={false}
                                         countries={this.props.countries}
                                         patient={this.state.patient}
                                />
                            </div>
                        </div>
                    )
                    break;
                }
                case APP_CREATE_STAGE_2: {

                    const appNotDefined = this.state.appointment.apType.id === null || this.state.appointment.shortDescription.trim() === '';

                    footer = (
                        <div>
                            <Button label="Prev"
                                    icon={ICON_PREV}
                                    onClick={() => {
                                        this.setState({addPatientStage: APP_CREATE_STAGE_1});
                                    }}/>
                            <Button label="OK"
                                    icon={ICON_OK}
                                    onClick={() => {
                                        this.onOk()
                                    }}
                                    disabled={appNotDefined}/>
                            <Button label="Cancel"
                                    icon={ICON_CANCEL}
                                    onClick={() => {
                                        this.props.onHideDialog(HM_AddAppointment.id)
                                    }}/>
                        </div>
                    );

                    content.push(
                        <div className='p-grid'>
                            <Card title='Appointment Details'>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-3">
                                        <label>Summary</label>
                                    </div>
                                    <div className="p-col-9">
                                        {inputText(propsAppointment, 'shortDescription', '', false, true)}
                                    </div>

                                    <div className="p-col-3">
                                        <label>Comment</label>
                                    </div>
                                    <div className="p-col-9">
                                        {inputTextArea(propsAppointment, 'description', 4, -1, false, false)}
                                    </div>

                                    <div className="p-col-3">
                                        <label>Type</label>
                                    </div>
                                    <div className="p-col-5">
                                        <Dropdown value={appointmentType}
                                                  options={this.props.appointmentTypes}
                                                  optionLabel='description'
                                                  onChange={this.onTypeChange}
                                                  autoWidth={false}
                                        />
                                    </div>
                                    <div className="p-col-4">
                                        {checkBox(propsSettings, 'createRecall', 'Create Recall', false, false)}
                                    </div>

                                    <div className="p-col-3">
                                        <label>Location</label>
                                    </div>
                                    <div className="p-col-9">
                                        <Dropdown value={location}
                                                  options={this.props.locations}
                                                  optionLabel='name'
                                                  onChange={(e) => this.onChange({
                                                      owner: 'appointment.location',
                                                      value: e.value
                                                  })}
                                                  autoWidth={false}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )
                    break;
                }
                default:
                    break;
            }
            return (
                <Dialog style={{width: "50%"}}
                        header={HM_AddAppointmentPatient.header}
                        footer={footer}
                        visible={true}
                        modal={true}
                        resizable={true}
                        onHide={() => {
                            this.props.onHideDialog(HM_AddAppointment.id)
                        }}
                >
                    <div className='p-grid'>

                        {content}

                        {this.showDialogs()}

                    </div>
                </Dialog>
            )
        }
    }

    render() {

        if (this.props.visible === false || !this.props.appointmentDiaryTypesLoaded) {
            return null;
        }

        return this.patientSelection();
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        countries: state.dropDowns.countries,

        loginIdentity: state.login.user,

        appointmentDiaryTypesLoaded: state.housekeeping.appointmentDiaryTypesLoaded,
        appointmentTypes: state.housekeeping.appointmentDiaryTypes.appointmentTypes,

        journeyStagesLoaded: state.housekeeping.journeyStagesLoaded,
        journeyStages: state.housekeeping.journeyStages,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        matchedPatients: state.diary.matchedPatients,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getPatientsBySurname: (urlPattern) => dispatch(getAppointmentPatientsBySurname(urlPattern)),
        getPatientsByForename: (urlPattern) => dispatch(getAppointmentPatientsByFirstname(urlPattern)),

        getAllUsersShort: () => dispatch(getAllUsers()),
        getJourneyStages: () => dispatch(getHKResource(RES_HOUSEKEEPING_JSTAGES.GET, {})),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddAppointment = connect(mapStateToProps, MapDispatchToProps)(ConnectedAddAppointment);

export default AddAppointment;
