import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {ColorPicker} from 'primereact/components/colorpicker/ColorPicker';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import _ from "lodash";
import {dropDownPlain, inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {
    HM_AddJStages,
    HM_EditJStages,
    PJ_Action,
    PJ_DEACT,
    PJ_None,
    PJS_ARCHIVED,
    PJS_USABLE
} from "../../../../Constants";
import {
    ICON_CANCEL,
    ICON_OK,
} from "../../../../icons";
import {patientJourneyData} from "../../DefaultData";

export default class AddJourneyStage extends Component {

    constructor(props) {
        super(props);

        this.state = {

            defaultAction: null,
            defaultRecallType: null,
            recallTypeDisabled: false,
        };

        if (props.editing) {

            this.state.journeyStage = props.item;

            if (this.state.journeyStage.recallType !== undefined && this.state.journeyStage.recallType !== null) {
                this.state.defaultRecallType = _.find(props.recallTypes, recallType => recallType.id === this.state.journeyStage.recallType.id);
            }
        } else {

            this.state.journeyStage = _.cloneDeep(patientJourneyData(ac.getMcId()));

        }
        this.state.defaultAction = _.find(PJ_Action, (action) => action.value === this.state.journeyStage.action);
        this.state.recallTypeDisabled = this.state.journeyStage.action === PJ_None.value || this.state.journeyStage.action === PJ_DEACT.value;

        this.onChange = this.onChange.bind(this);
        this.onActionChange = this.onActionChange.bind(this);
        this.onRecallTypeChange = this.onRecallTypeChange.bind(this);
        this.onColourSelect = this.onColourSelect.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onActionChange(event) {

        const journeyStage = {...this.state.journeyStage};
        journeyStage.action = event.value;
        const recallTypeDisabled = journeyStage.action === PJ_None.value || journeyStage.action === PJ_DEACT.value;

        this.setState({journeyStage, defaultAction: event.value, recallTypeDisabled})
    }

    onRecallTypeChange(event) {

        const journeyStage = {...this.state.journeyStage};
        journeyStage.recallType = event.value;

        this.setState({journeyStage, defaultRecallType: event.value})
    }

    onColourSelect(event) {

        const nc = event.value;

        const newJourneyStage = {...this.state.journeyStage};
        newJourneyStage.red = nc.r;
        newJourneyStage.green = nc.g;
        newJourneyStage.blue = nc.b;

        this.setState({journeyStage: newJourneyStage})
    }

    onHide() {
        const target = this.props.editing ? HM_EditJStages.id : HM_AddJStages.id;
        this.props.onHideDialog(target)
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const journeyStage = this.state.journeyStage;
        const journeyStageNotDefined = journeyStage.code === '' || journeyStage.description === '' || (journeyStage.recall && (!this.state.recallTypeDisabled && this.state.defaultRecallType === null));

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.journeyStage)
                        }}
                        disabled={journeyStageNotDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'journeyStage',
            journeyStage: this.state.journeyStage,
        };

        const actionProps = {
            actions: PJ_Action,
            onChange: this.onActionChange,
            target: 'journeyStage',
            journeyStage: this.state.journeyStage,
        };

        const colorValue = {r: journeyStage.red, g: journeyStage.green, b: journeyStage.blue};

        const archived = this.state.journeyStage.status === PJS_ARCHIVED;

        return (
            <Dialog header={this.props.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={this.props.visible}
                    resizable={true}
                    onHide={this.onHide}>

                <div className="p-grid p-fluid form-group">

                    <div className="p-col-3">
                        <label>Code</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'code', '')}
                    </div>

                    <div className="p-col-3">
                        <label>Description</label>
                    </div>
                    <div className="p-col-9">
                        {inputTextArea(props, 'description', 4, -1, false, false)}
                    </div>

                    <div className="p-col-3">
                        <label>Action</label>
                    </div>
                    <div className="p-col-9">
                        {dropDownPlain(actionProps, 'actions', 'action', 'label', false, false)}
                    </div>

                    <div className="p-col-3">
                        <label>Recall Type</label>
                    </div>
                    <div className="p-col-9">
                        <Dropdown optionLabel='title'
                                  value={this.state.defaultRecallType}
                                  options={this.props.recallTypes}
                                  onChange={this.onRecallTypeChange}
                                  autoWidth={false}
                                  scrollHeight='100px'
                                  disabled={this.state.recallTypeDisabled}
                        />
                    </div>

                    <div className="p-col-3">
                        <label>Color</label>
                    </div>
                    <div className="p-col-3">
                        <ColorPicker format='rgb'
                                     value={colorValue}
                                     onChange={this.onColourSelect}
                        />
                    </div>

                    <div className="p-col-3">
                        <label>Inform Keap</label>
                    </div>
                    <div className="p-col-3">
                        <Checkbox checked={this.state.journeyStage.informKeap}
                                  className='p-checkbox-label'
                                  onChange={(e) => {
                                      this.onChange({owner: 'journeyStage.informKeap', value: e.checked})
                                  }}
                        />
                    </div>

                    <div className="p-col-3">
                        <label>Archived</label>
                    </div>
                    <div className="p-col-9">
                        <Checkbox checked={archived}
                                  className='p-checkbox-label'
                                  onChange={(e) => {
                                      const status = e.checked ? PJS_ARCHIVED : PJS_USABLE;
                                      this.onChange({owner: 'journeyStage.status', value: status})
                                  }}
                        />
                    </div>
                </div>
            </Dialog>
        )
    }
};
