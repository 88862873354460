// login related ids

export const SEL_INP_LOGIN_USERNAME = 'SEL_INP_LOGIN_USERNAME';
export const SEL_INP_LOGIN_PASSWORD = 'SEL_INP_LOGIN_PASSWORD';
export const SEL_INP_LOGIN_PASSWORD_CONF = 'SEL_INP_LOGIN_PASSWORD_CONF';
export const SEL_BTN_LOGIN_BUTTON = 'SEL_BTN_LOGIN_BUTTON';
export const SEL_A_ACTIVE_TOP_MENU = 'SEL_A_ACTIVE_TOP_MENU';
export const SEL_A_LOGOUT_BUTTON = 'SEL_A_LOGOUT_BUTTON';

// find patient related ids

export const SEL_BTN_PATIENT_SEARCH = 'SM_PATIENT_SEARCH';
export const SEL_BTN_PRACTICE_DIARY = 'SM_PRACTICE_DIARY';