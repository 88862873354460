import React, {Component} from 'react';

import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_DIS} from "../../../../actions/stateManagement";
import {getObjectStoreIds} from "../Utils";
import {RES_PREFERENCES} from "../../../../actions/preferences";
import {checkBox, dropDownPlain, spinner} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    ACL_ONCANCEL,
    ACL_ONCHANGEDURATION,
    ACL_ONDELETE,
    ACL_ONMOVE,
    ACTIVATEPOPUPONARRIVAL,
    ACTIVECANCELLATIONLIST,
    APPNAVS,
    APPOINTMENT_COLOUR_SCHEME,
    APPOINTMENT_CONFIRM_ON_REMINDER,
    APPTYPE_SCHEME,
    ARRIVALSOUND,
    DAY_LIST_TEXT_FORMAT,
    GotoAppointment_Treatments,
    GotoPatientView_Summary,
    IncrementalSearch,
    LUNCH_WARNING,
    NHSNUMBER_REQUIRED,
    OpenAppointmentEditorPreference,
    OpenPatientEditorPreference,
    PAT_REALLOC_SUPPRESS,
    PATIENT_TIME_MONITORING,
    PATNAVS,
    PROVIDER_SCHEME,
    SOUND1,
    SOUNDS,
    TIMEINTERVALES_1224
} from "../Constants";
import {getObjectStore, RES_OBJECT_STORE} from "../../../../actions/objectStore";
import {getBoolean, getInteger, getString} from "../../fixedItemUtils";

const requiredObject = [
    OpenPatientEditorPreference,
    OpenAppointmentEditorPreference,
    ACTIVECANCELLATIONLIST,
    ACL_ONDELETE,
    ACL_ONCANCEL,
    ACL_ONMOVE,
    ACL_ONCHANGEDURATION,
    ACTIVATEPOPUPONARRIVAL,
    TIMEINTERVALES_1224,
    LUNCH_WARNING.value,
    APPOINTMENT_COLOUR_SCHEME,
    DAY_LIST_TEXT_FORMAT,
    PAT_REALLOC_SUPPRESS,
    PATIENT_TIME_MONITORING,
    APPOINTMENT_CONFIRM_ON_REMINDER,
    ARRIVALSOUND,
    IncrementalSearch,
    NHSNUMBER_REQUIRED,
];

class ConnectedDiarySection extends Component {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_DIS.id,

                objects: {},
            }
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getObjectStore(requiredObject);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_OBJECT_STORE.GET.receive:

                    const objects = this.props.objects;

                    this.setState({
                        objects: {
                            OpenPatientEditorPreference: getString(objects, OpenPatientEditorPreference, GotoPatientView_Summary),
                            OpenAppointmentEditorPreference: getString(objects, OpenAppointmentEditorPreference, GotoAppointment_Treatments),
                            ACTIVECANCELLATIONLIST: getBoolean(objects, ACTIVECANCELLATIONLIST, false),
                            ACL_ONDELETE: getBoolean(objects, ACL_ONDELETE, true),
                            ACL_ONCANCEL: getBoolean(objects, ACL_ONCANCEL, true),
                            ACL_ONMOVE: getBoolean(objects, ACL_ONMOVE, false),
                            ACL_ONCHANGEDURATION: getBoolean(objects, ACL_ONCHANGEDURATION, false),
                            ACTIVATEPOPUPONARRIVAL: getBoolean(objects, ACTIVATEPOPUPONARRIVAL, false),
                            TIMEINTERVALES_1224: !getBoolean(objects, TIMEINTERVALES_1224, true),
                            LUNCH_WARNING: getBoolean(objects, LUNCH_WARNING, false),
                            APPOINTMENT_COLOUR_SCHEME: getString(objects, APPOINTMENT_COLOUR_SCHEME, APPTYPE_SCHEME),
                            DAY_LIST_TEXT_FORMAT: getBoolean(objects, DAY_LIST_TEXT_FORMAT, false),
                            PAT_REALLOC_SUPPRESS: getBoolean(objects, PAT_REALLOC_SUPPRESS, false),
                            PATIENT_TIME_MONITORING: getBoolean(objects, PATIENT_TIME_MONITORING, false),
                            APPOINTMENT_CONFIRM_ON_REMINDER: getBoolean(objects, APPOINTMENT_CONFIRM_ON_REMINDER, false),
                            ARRIVALSOUND: getString(objects, ARRIVALSOUND, SOUND1),
                            IncrementalSearch: getInteger(objects, IncrementalSearch, 3),
                            NHSNUMBER_REQUIRED: getBoolean(objects, NHSNUMBER_REQUIRED, false),
                        }
                    }, () => {

                    });
                    break;

                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES.SAVE.action,
                    saveState: false,
                    saveObjects: true
                }
            })
        });
    }

    render() {

        if (!this.props.objectsLoaded) {
            return null;
        }

        const objectProps = {
            onChange: this.onChange,
            target: 'objects',
            objects: this.state.objects,
            patNavs: PATNAVS,
            appNavs: APPNAVS,
            sounds: SOUNDS,
        };

        const providerSchemeChecked = this.state.objects.APPOINTMENT_COLOUR_SCHEME === PROVIDER_SCHEME;

        return (
            <div className="p-grid p-fluid" style={{fontSize: 'small'}}>
                <Panel header='Navigation' className="p-col-6" style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-2">
                            <label htmlFor="OpenPatientEditorPreference">Patient</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(objectProps, 'patNavs', 'OpenPatientEditorPreference', 'label', false, false)}
                        </div>
                        <div className="p-col-2">
                            <label htmlFor="OpenPatientEditorPreference">Appointment</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(objectProps, 'appNavs', 'OpenAppointmentEditorPreference', 'label', false, false)}
                        </div>
                    </div>
                </Panel>
                <Panel header='Suppress Patient Re-Allocation' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    {checkBox(objectProps, 'PAT_REALLOC_SUPPRESS', 'Enabled', false, false)}
                </Panel>
                <Panel header='Confirmation on Reminder Sent' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    {checkBox(objectProps, 'APPOINTMENT_CONFIRM_ON_REMINDER', 'Appointment Confirmed', false, false)}
                </Panel>
                <Panel header='Appointment Colour Scheme' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    <Checkbox checked={providerSchemeChecked}
                              disabled={false}
                              className='p-checkbox-label'
                              onChange={(e) => {
                                  this.onChange({
                                      owner: `objects.${APPOINTMENT_COLOUR_SCHEME}`,
                                      value: e.checked ? PROVIDER_SCHEME : APPTYPE_SCHEME
                                  })
                              }}
                    />
                    <label style={{fontSize: 'small'}} className='p-checkbox-label'>Provider Scheme</label>
                </Panel>
                <Panel header='Arrival Time Monitoring' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    {checkBox(objectProps, 'PATIENT_TIME_MONITORING', 'Enabled', false, false)}
                </Panel>
                <Panel header='Diary Hour Labels' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    {checkBox(objectProps, 'TIMEINTERVALES_1224', '24 Hour', false, false)}
                </Panel>
                <Panel header='Cancellation List' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    {checkBox(objectProps, 'ACTIVECANCELLATIONLIST', 'Activate', false, false)}
                </Panel>
                <Panel header='Popup and Sound on Patient Arrival' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-4">
                            {checkBox(objectProps, 'ACTIVATEPOPUPONARRIVAL', 'Activate', false, false)}
                        </div>
                        <div className="p-col-4">
                            <label htmlFor="sounds">Sounds</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(objectProps, 'sounds', 'ARRIVALSOUND', 'label', false, false)}
                        </div>
                    </div>
                </Panel>

                <Panel header='Diary Printout Format' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            {checkBox(objectProps, 'DAY_LIST_TEXT_FORMAT', 'Use Template Format', false, false)}
                        </div>
                    </div>
                </Panel>

                <Panel header='Suppress Lunch Booking Warning' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    {checkBox(objectProps, 'LUNCH_WARNING', 'Suppress', false, false)}
                </Panel>
                <Panel header='NHS Number' className="p-col-3" style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    {checkBox(objectProps, 'NHSNUMBER_REQUIRED', 'Required', false, false)}
                </Panel>
                <Panel header='Incremental Search Limit' className="p-col-3"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-4">
                            <label htmlFor="IncrementalSearch">Character Count</label>
                        </div>
                        <div className="p-col-8">
                            {spinner(objectProps, 'IncrementalSearch', 1, 3, false, false)}
                        </div>
                    </div>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        currentState: state.stateManagement[SM_PREFERENCES_DIS.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const DiarySection = connect(mapStateToProps, mapDispatchToProps)(ConnectedDiarySection);

export default DiarySection;
