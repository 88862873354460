import _ from 'lodash';
import {
    SM_PAYMENT_PLAN,
    SM_PAYMENT_PLAN_HISTORY,
    SM_PAYMENT_PLAN_INSTALLMENTS,
    SM_PaymentPlaning,
    SM_REPORTS_PAYMENT_PLANS_PDF,
} from "../actions/stateManagement";
import * as Actions from "../actions";
import {
    RES_PAYMENT_PLAN_BY_ID,
    RES_PAYMENT_PLAN_HISTORY,
    RES_PAYMENT_PLANNING,
    RES_PP_DUE_INSTALLMENTS
} from "../actions/paymentPlanning";
import {PP_STATUS_ACCEPTED, PP_STATUS_SUSPENDED, PP_STATUS_WRITTEN_OFF} from "../Constants";
import {RES_TREATMENT_PLAN_BY_ID} from "../actions/treatmentPlanning";

const initialState = {

    planInstallmentsLoaded: false,
    planInstallments: [],
};

export const paymentPlanning = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ////////////////////////////////////////////////// Payment Planning

            case RES_PP_DUE_INSTALLMENTS.GET.request:
            case RES_PP_DUE_INSTALLMENTS.CLEAR.action:
                return {
                    ...state,
                    planInstallmentsLoaded: false,
                    planInstallments: [],
                };

            case RES_PP_DUE_INSTALLMENTS.GET.receive:

                return {
                    ...state,
                    planInstallmentsLoaded: true,
                    planInstallments: action.payload,
                };

            case RES_PAYMENT_PLAN_HISTORY.GET.receive:

                return {
                    ...state,
                    [`${SM_PAYMENT_PLAN_HISTORY.loaded}_${action.id}`]: true,
                    [`${SM_PAYMENT_PLAN_HISTORY.id}_${action.id}`]: action.payload,
                };

            case RES_PAYMENT_PLANNING.CLEAR.action: {

                let clearPPState = {...state};

                let plans = _.get(clearPPState, `${SM_PAYMENT_PLAN_HISTORY.id}_${action.smRef}.plans`);

                if (plans !== undefined) {
                    // clear all plan entries based on the loaded history
                    plans.forEach(plan => {
                        delete clearPPState[`${SM_PAYMENT_PLAN.id}_${plan.id}`];
                        delete clearPPState[`${SM_PAYMENT_PLAN.loaded}_${plan.id}`];
                    });
                }
                delete clearPPState[`${SM_PAYMENT_PLAN_HISTORY.loaded}_${action.smRef}`];
                delete clearPPState[`${SM_PAYMENT_PLAN_HISTORY.id}_${action.smRef}`];

                delete clearPPState[`${SM_PaymentPlaning.loaded}_${action.smRef}`];
                delete clearPPState[`${SM_PaymentPlaning.id}_${action.smRef}`];

                return clearPPState;
            }

            case RES_PAYMENT_PLAN_BY_ID.SAVE.action:
            case RES_PAYMENT_PLAN_BY_ID.GET.receive:

                return {
                    ...state,
                    [`${SM_PAYMENT_PLAN.id}_${action.id}`]: action.payload,
                    [`${SM_PAYMENT_PLAN.loaded}_${action.id}`]: true,
                };

            case RES_PAYMENT_PLAN_BY_ID.PDF.receive: {

                const loadedIndex = `${SM_REPORTS_PAYMENT_PLANS_PDF.loaded}_${action.id}.pdf`;
                const index = `${SM_REPORTS_PAYMENT_PLANS_PDF.id}_${action.id}.pdf`;

                return {
                    ...state,
                    [loadedIndex]: true,
                    [index]: action.payload,
                }
            }
            case RES_TREATMENT_PLAN_BY_ID.CLEAR_PDF.action: {

                let clearPPPDFState = {...state};

                const loadedIndex = `${SM_REPORTS_PAYMENT_PLANS_PDF.loaded}_${action.id}.pdf`;
                const index = `${SM_REPORTS_PAYMENT_PLANS_PDF.id}_${action.id}.pdf`;

                delete clearPPPDFState[loadedIndex];
                delete clearPPPDFState[index];

                return clearPPPDFState;
            }

            case RES_PAYMENT_PLAN_BY_ID.INSTALLMENTS.receive:

                return {
                    ...state,
                    [`${SM_PAYMENT_PLAN_INSTALLMENTS.id}_${action.id}`]: action.payload,
                    [`${SM_PAYMENT_PLAN_INSTALLMENTS.loaded}_${action.id}`]: true,
                };

            case RES_PAYMENT_PLAN_BY_ID.DELETE.action:

                let clearPPState = {...state};

                let plans = _.get(clearPPState, `${SM_PAYMENT_PLAN_HISTORY.id}_${action.patientId}`);
                const history = _.filter(plans, plan => plan.id !== action.planId);

                return {
                    ...state,
                    [`${SM_PAYMENT_PLAN_HISTORY.loaded}_${action.patientId}`]: true,
                    [`${SM_PAYMENT_PLAN_HISTORY.id}_${action.patientId}`]: history,
                };

            case RES_PAYMENT_PLANNING.SUSPEND_PAT.action: {

                const paymentPlans = state[`${SM_PAYMENT_PLAN_HISTORY.id}_${action.payload.patientId}`];

                // check if the patient history has been loaded, if not skip
                if (paymentPlans === undefined) return state;

                const index = _.findIndex(paymentPlans, plan => plan.id === action.payload.id);
                paymentPlans[index].status = PP_STATUS_SUSPENDED.name;
                paymentPlans[index] = action.payload;

                return {
                    ...state,
                    [`${SM_PAYMENT_PLAN_HISTORY.id}_${action.payload.patientId}`]: paymentPlans,
                };
            }

            case RES_PAYMENT_PLANNING.RESUME_PAT.action: {

                const paymentPlans = state[`${SM_PAYMENT_PLAN_HISTORY.id}_${action.payload.patientId}`];

                // check if the patient history has been loaded, if not skip
                if (paymentPlans === undefined) return state;

                const index = _.findIndex(paymentPlans, plan => plan.id === action.payload.id);

                paymentPlans[index].status = PP_STATUS_ACCEPTED.name;
                paymentPlans[index] = action.payload

                return {
                    ...state,
                    [`${SM_PAYMENT_PLAN_HISTORY.id}_${action.payload.patientId}`]: paymentPlans,
                };
            }

            case RES_PAYMENT_PLANNING.WRITE_OFF_PAT.action: {

                const paymentPlans = state[`${SM_PAYMENT_PLAN_HISTORY.id}_${action.payload.patientId}`];

                // check if the patient history has been loaded, if not skip
                if (paymentPlans === undefined) return state;
                const index = _.findIndex(paymentPlans, plan => plan.id === action.payload.id);

                paymentPlans[index].status = PP_STATUS_WRITTEN_OFF.name;
                paymentPlans[index] = action.payload;

                return {
                    ...state,
                    [`${SM_PAYMENT_PLAN_HISTORY.id}_${action.payload.patientId}`]: paymentPlans,
                };
            }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};