import {ICON_PLUS} from "../../../icons";

export const MESSAGE_RECIPIENT_USER = "SystemUser";
export const MESSAGE_RECIPIENT_GROUP = "Group";
export const MESSAGE_RECIPIENT_LIST = "List";

export const ME_ADD_TO_GROUP = {
    id: 'ME_ADD_TO_GROUP',
    label: 'Add to Group',
    menuIcon: ICON_PLUS,
};
