import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {categoryDropDown, checkBox, spinner} from './OnChangeUtils';

export const OrthoExtraOral = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header='Extra-Oral'
               style={{marginBottom: '1em'}}>

            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>Skeletal</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Class</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'SkeletalClass', 'skeletalClassACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Severity</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'SkeletalSeverity', 'skeletalSeverityACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>FMPA</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'fmpa', 'fMPAACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>LAFH</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'lafh', 'lAFHACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>Asymmetries</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Chin Point</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'Position', 'assymsChinPointACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'chinPointValue', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>Naso-Labial Angle</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'Angle', 'nasoLabelAngleACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>Lips</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'Competence', 'lipsACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>Lip Line</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'Level', 'lipLineACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>Incisor Show</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'incisorShow', '', readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>At Rest</label>
                </div>
                <div className="p-lg-1 p-md-1 p-col-nopad">
                    {spinner(props, 'atRestValue', -15, 20, readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    <label>&nbsp;mm / Smiling</label>
                </div>
                <div className="p-lg-1 p-md-1 p-col-nopad">
                    {spinner(props, 'smilingValue', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>
            </div>
        </Panel>
    );
};
