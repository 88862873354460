import {ICON_DELETE, ICON_EDIT, ICON_GOTO, ICON_MINUS, ICON_PLUS} from "../../icons";

export const TB_ADMIN_GROUPS = {
    id: 'TB_ADMIN_GROUPS',
    text: 'Practice Groups',
    icon: 'fas fa-users'
};

export const TB_ADMIN_GROUP_SUBSCRIPTIONS = {
    id: 'TB_ADMIN_GROUP_SUBSCRIPTIONS',
    text: 'Group Subscriptions',
    icon: 'fas fa-users'
};

export const TB_ADMIN_SUBSCRIPTION = {
    id: 'TB_ADMIN_SUBSCRIPTION',
    text: 'Subscription Details',
    icon: ICON_EDIT
};

export const TB_ADMIN_SUBSCRIPTION_ACTIVATE = {
    id: 'TB_ADMIN_SUBSCRIPTION_ACTIVATE',
    text: 'Activate Subscription',
    icon: ICON_PLUS
};

export const TB_ADMIN_SUBSCRIPTION_DEACTIVATE = {
    id: 'TB_ADMIN_SUBSCRIPTION_DEACTIVATE',
    text: 'Deactivate Subscription',
    icon: ICON_MINUS
};

export const TB_ADD_GROUP = {
    id: 'TB_ADD_GROUP',
    text: 'Add Group',
    icon: 'fas fa-user-plus'
};

export const TB_ADD_SUBSCRIPTION = {
    id: 'TB_ADD_SUBSCRIPTION',
    text: 'Add Subscription',
    icon: 'fas fa-user-plus'
};

export const ME_LOGOUT_USER = {
    id: 'ME_LOGOUT_USER',
    label: 'Logout ',
    menuIcon: ICON_GOTO,
};

export const HM_LOGOUT_USER = {
    id: 'HM_LOGOUT_USER',
    header: 'User Logout',
    message: 'Are you sure you want to log this user out?',
    icon: ICON_DELETE
};
