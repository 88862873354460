import _ from "lodash";
import {NHSClaim} from "../DefaultData";
import {ac} from "../../../index";
import {
    CurrencyCodes,
    EDI_CF_Form1_closed,
    EDI_CF_Form1_fitted,
    EDI_CF_Form1_recepited,
    EDI_CF_Form2,
    EDI_CR_AbandonedFTR,
    EDI_CR_AbandonedPR,
    EDI_CR_AssessAndFittedSubmit,
    EDI_CR_AssessAndRefuse,
    EDI_CR_AssessAndReview,
    EDI_CR_Complete,
    EDI_CR_Discontinued,
    EDI_CR_Incomplete,
    EDI_CR_None,
    EDI_CR_Regulation11,
    EDI_CR_RepairFittedByOther, EDI_CT_FP17,
    EDI_CT_FP17O,
    EDI_ST_Stacked
} from "../NHSManagaement/Constants";
import {
    _0000,
    _9150,
    _9152,
    _9153,
    _9154,
    _9155,
    _9156,
    _9157,
    _9158,
    _9162,
    _9163,
    _9166,
    Band,
    Band1,
    Band2,
    Band3,
    Codes9000ClinicalDataSet,
    Codes9000OrthodonticDataSet,
    NoBand,
    Urgent
} from "../../../NHSConstants";
import {
    _00,
    _01,
    _02,
    _03,
    _04,
    _05,
    _06,
    _13,
    _14,
    _15,
    _16,
    _17,
    _18,
    _25,
    _26,
    _27,
    _28,
    _31,
    _32,
    _33,
    _34,
    _36,
    _37,
    _71,
    _72,
    _73,
    _74,
    _75,
    _76,
    _77,
    _78,
    _79,
    _80,
    _81,
    _82,
    _83,
    _84,
    ExemptionAndRemissionCodes
} from "../../PatientDynamicItems/NHSDetails";
import {CH_INVOICE, CH_RECEIPTED, CH_SAVED} from "../../../Constants";
import moment from "moment";

const getFormattedDateDiff = (date1, date2) => {
    let b = moment(date1),
        a = moment(date2),
        intervals = ['years', 'months'],
        out = [];

    intervals.forEach(interval => {
        let diff = a.diff(b, interval);
        b.add(diff, interval);
        out.push(diff);
    });
    return out;
};

export const handleIt = (props, plan, appointment, charges, patientNHSDetails, {
    total
}, selectedPerformer) => {

    try {
        // filter the NHS charges
        const NHSCharges = _.filter(charges, charge => charge.item.code9000 && charge.item.code9000 !== _0000.id);

        // if non then no claim so return
        if (NHSCharges.length === 0) return;

        const allCharges = [...plan.unassignedCharges];
        const claimCharges = getClaimCharges(allCharges);
        const completedCharges = getCompletedCharges(allCharges);
        const incompletedCharges = getIncompletedCharges(allCharges);

        const allBand = getBand(plan.urgentTreatment, allCharges);
        const completedBand = getBand(plan.urgentTreatment, completedCharges);

        const years = getFormattedDateDiff(plan.patient.dateOfBirth, new moment())[0]; // 0 - years : 1 - months

        let claim = _.cloneDeep(NHSClaim(ac.getMcId()));
        claim.id = -1;

        claim.treatmentPlan = plan;

        // status is set to stacked the start of the process
        claim.status = EDI_ST_Stacked;
        claim.stackingDate = new Date();
        claim.chxCurrencyCode = CurrencyCodes[0]; //gbp
        claim.chxPatientCharge = 0.0;
        claim.patientNHSDetails = patientNHSDetails;
        claim.completedBand = NoBand.id;
        claim.incompletedBand = NoBand.id;
        claim.clinicalData = [];
        claim.otherServices = [];
        claim.declarationsBCDS_1 = false;
        claim.declarationsBCDS_2 = false;
        claim.declarationsBCDS_64 = false;

        claim.iotnScore = 0;
        claim.iotnCompScore = 0;
        claim.aestheticComponent = 0;
        claim.aestheticComponentComp = 0;
        claim.units = 0.0;

        claim.narrativeDate = new Date();
        claim.narrative = '';

        claim.completionReason = plan.completionReason;

        const claimPerformer = selectedPerformer !== null ? {id: selectedPerformer.id} : {id: plan.patient.provider.id};

        if (appointment.apType.ortho) {

            claim.claimType = EDI_CT_FP17O;

            switch (claim.completionReason) {
                case EDI_CR_AssessAndReview :
                    claim.claimForm = EDI_CF_Form1_closed;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim.trtdatgrpDateOfExamination = appointment.start;
                    claim.trtdatgrpDateOfAcceptance = appointment.start;
                    claim.trtdatgrpDateOfCompletion = appointment.start;
                    claim.iotnCompScore = plan.patient.iotnDhc;
                    claim.aestheticComponentComp = plan.patient.iotnAc;
                    claim = performerDeclaration(claim, false);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim = orthoDataSetTreatmentProvided(claim, allCharges);
                    claim.referralDate = plan.referralDate;
                    claim.units = 1.0;
                    break;
                case EDI_CR_AssessAndRefuse :
                    claim.claimForm = EDI_CF_Form1_closed;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim.trtdatgrpDateOfExamination = appointment.start;
                    claim.trtdatgrpDateOfAcceptance = appointment.start;
                    claim.trtdatgrpDateOfCompletion = appointment.start;
                    claim.iotnCompScore = plan.patient.iotnDhc;
                    claim.aestheticComponentComp = plan.patient.iotnAc;
                    claim = performerDeclaration(claim, false);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim = orthoDataSetTreatmentProvided(claim, allCharges);
                    claim.referralDate = plan.referralDate;
                    claim.units = 1.0;
                    break;
                case EDI_CR_AssessAndFittedSubmit :
                    claim.claimForm = EDI_CF_Form1_fitted;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim = orthoDataSetTreatmentProvided(claim, claimCharges);
                    claim.trtdatgrpDateOfExamination = appointment.start;
                    claim.trtdatgrpDateOfAcceptance = appointment.start;
                    claim.trtdatgrpDateOfCompletion = appointment.start;
                    claim.iotnCompScore = plan.patient.iotnDhc;
                    claim.aestheticComponentComp = plan.patient.iotnAc;
                    claim = performerDeclaration(claim, true);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim.referralDate = plan.referralDate;

                    if (years < 10)
                        claim.units = 4.0;
                    else if (years > 17)
                        claim.units = 23.0;
                    else
                        claim.units = 21.0;
                    break;
                case EDI_CR_AbandonedFTR :
                    claim.claimForm = EDI_CF_Form2;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim = orthoDataSetTreatmentProvided(claim, allCharges);
                    claim.trtdatgrpDateOfExamination = appointment.start;
                    claim.trtdatgrpDateOfAcceptance = appointment.start;
                    claim.trtdatgrpDateOfCompletion = appointment.start;
                    claim.iotnCompScore = plan.patient.iotnDhc;
                    claim.aestheticComponentComp = plan.patient.iotnAc;
                    claim = performerDeclaration(claim, false);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim.parScoreCalculated = plan.parScoreCalculated;
                    claim.units = 0.0;
                    break;
                case EDI_CR_AbandonedPR :
                    claim.claimForm = EDI_CF_Form2;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim = orthoDataSetTreatmentProvided(claim, allCharges);
                    claim.trtdatgrpDateOfExamination = appointment.start;
                    claim.trtdatgrpDateOfAcceptance = appointment.start;
                    claim.trtdatgrpDateOfCompletion = appointment.start;
                    claim.iotnCompScore = plan.patient.iotnDhc;
                    claim.aestheticComponentComp = plan.patient.iotnAc;
                    claim = performerDeclaration(claim, false);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim.parScoreCalculated = plan.parScoreCalculated;
                    claim.units = 0.0;
                    break;
                case EDI_CR_Discontinued :
                    claim.claimForm = EDI_CF_Form2;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim = orthoDataSetTreatmentProvided(claim, allCharges);
                    claim.trtdatgrpDateOfExamination = appointment.start;
                    claim.trtdatgrpDateOfAcceptance = appointment.start;
                    claim.trtdatgrpDateOfCompletion = appointment.start;
                    claim.iotnCompScore = plan.patient.iotnDhc;
                    claim.aestheticComponentComp = plan.patient.iotnAc;
                    claim = performerDeclaration(claim, false);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim.parScoreCalculated = plan.parScoreCalculated;
                    claim.units = 0.0;
                    break;
                case EDI_CR_Incomplete :
                    claim.claimForm = EDI_CF_Form2;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim.trtdatgrpDateOfCompletion = appointment.start;

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim.units = 0.0;
                    break;
                case EDI_CR_Complete :
                    claim.claimForm = EDI_CF_Form2;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim = orthoDataSetTreatmentProvided(claim, allCharges);
                    claim.trtdatgrpDateOfExamination = appointment.start;
                    claim.trtdatgrpDateOfAcceptance = appointment.start;
                    claim.trtdatgrpDateOfCompletion = appointment.start;
                    claim.iotnCompScore = plan.patient.iotnDhc;
                    claim.aestheticComponentComp = plan.patient.iotnAc;
                    claim = performerDeclaration(claim, false);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim.parScoreCalculated = plan.parScoreCalculated;
                    claim.units = 0.0;
                    break;
                case EDI_CR_RepairFittedByOther :
                    claim.claimForm = EDI_CF_Form1_closed;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim.trtdatgrpDateOfExamination = appointment.start;
                    claim.trtdatgrpDateOfAcceptance = appointment.start;
                    claim.trtdatgrpDateOfCompletion = appointment.start;
                    claim = performerDeclaration(claim, false);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim.units = 0.0;
                    break;
                case EDI_CR_Regulation11 :
                    claim.claimForm = EDI_CF_Form1_closed;

                    claim.performer = claimPerformer;
                    claim.patientNHSDetails = patientNHSDetails;
                    claim.chxPatientCharge = total;
                    claim = orthoDataSetTreatmentProvided(claim, allCharges);
                    claim.trtdatgrpDateOfExamination = appointment.start;
                    claim.trtdatgrpDateOfAcceptance = appointment.start;
                    claim.trtdatgrpDateOfCompletion = appointment.start;
                    claim = performerDeclaration(claim, false);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.units = 0.0;
                    break;
                case EDI_CR_None :
                    // this is not a claimable transaction
                    return;
                default:
                    break;
            }
            plan.claimForm = claim.claimForm;
        } else {

            claim.claimType = EDI_CT_FP17;

            claim.claimForm = EDI_CF_Form1_recepited;
            claim.performer = claimPerformer;
            claim.patientNHSDetails = patientNHSDetails;

            if (moment(plan.acceptenceDate).isAfter(moment(appointment.start))) {
                claim.trtdatgrpDateOfAcceptance = appointment.start;
                claim.trtdatgrpDateOfCompletion = appointment.start;
            } else {
                claim.trtdatgrpDateOfAcceptance = plan.acceptenceDate;
                claim.trtdatgrpDateOfCompletion = appointment.start;
            }

            claim.referralDate = plan.referralDate;
            claim.furtherTreatment = plan.furtherTreatment;

            const treatmentCategory = getTreatmentCategory(allCharges);

            claim.completionReason = EDI_CR_Complete;

            switch (treatmentCategory) {
                case _9150.id :
                    claim.treatmentCategory = _9150.id; // Banded category
                    switch (allBand) {
                        case Band1.id :
                            claim.completedBand = Band1.id;
                            claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                            claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                            claim = otherServices(claim, completedBand, allCharges);
                            claim = performerDeclaration(claim);

                            claim = exemptionAndRemisionCodes(claim);
                            claim.chxPatientCharge = total;
                            claim.units = 1.0;
                            break;
                        case Band2.id :
                            claim.completedBand = Band2.id;
                            claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                            claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                            claim = otherServices(claim, completedBand, allCharges);
                            claim = performerDeclaration(claim);

                            claim = exemptionAndRemisionCodes(claim);
                            claim.chxPatientCharge = total;
                            claim.units = 3.0;
                            break;
                        case Band3.id :
                            claim.completedBand = Band3.id;
                            claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                            claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                            claim = otherServices(claim, completedBand, allCharges);
                            claim = performerDeclaration(claim);

                            claim = exemptionAndRemisionCodes(claim);
                            claim.chxPatientCharge = total;
                            claim.units = 12.0;
                            break;
                        case Urgent.id :
                            claim.completedBand = Urgent.id;
                            claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                            claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                            claim = otherServices(claim, completedBand, allCharges);
                            claim = performerDeclaration(claim);

                            claim = exemptionAndRemisionCodes(claim);
                            claim.chxPatientCharge = total;
                            claim.units = 1.2;
                            break;
                        case NoBand.id :
                            claim.completedBand = NoBand.id;
                            claim = otherServices(claim, completedBand, allCharges);
                            claim = performerDeclaration(claim);

                            claim = exemptionAndRemisionCodes(claim);
                            claim.units = 0.0;
                            break;
                        default:
                            break;
                    }
                    break;
                case _9162.id: // Regulation 11
                    claim.treatmentCategory = treatmentCategory; // Treatment category
                    claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                    claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                    claim = performerDeclaration(claim);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim = otherServices(claim, completedBand, allCharges);
                    claim.units = 0.0;
                    break;
                case _9158.id: // Prescription only
                    claim.treatmentCategory = treatmentCategory; // Treatment category
                    claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                    claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                    claim = performerDeclaration(claim);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim = otherServices(claim, completedBand, allCharges);
                    claim.units = 0.75;
                    break;
                case _9154.id: // Denture repairs
                    claim.treatmentCategory = treatmentCategory; // Treatment category
                    claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                    claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                    claim = performerDeclaration(claim);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim = otherServices(claim, completedBand, allCharges);
                    claim.units = 1.0;
                    break;
                case _9157.id: // Bridge repairs
                    claim.treatmentCategory = treatmentCategory; // Treatment category
                    claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                    claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                    claim = performerDeclaration(claim);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim = otherServices(claim, completedBand, allCharges);
                    claim.units = 1.2;
                    break;
                case _9155.id: // Arrest of bleeding
                    claim.treatmentCategory = treatmentCategory; // Treatment category
                    claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                    claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                    claim = performerDeclaration(claim);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim = otherServices(claim, completedBand, allCharges);
                    claim.units = 1.2;
                    break;
                case _9156.id: // Removal of sutures
                    claim.treatmentCategory = treatmentCategory; // Treatment category
                    claim.clinicalData = clinicalDataSetTreatmentProvided(allCharges);
                    claim = incompleteTreatment(claim, incompletedCharges, completedBand);
                    claim = performerDeclaration(claim);

                    claim = exemptionAndRemisionCodes(claim);
                    claim.chxPatientCharge = total;
                    claim = otherServices(claim, completedBand, allCharges);
                    claim.units = 1.0;
                    break;
                default:
                    break;
            }
        }

        let clinicalSubmittable = false;

        for (let i = 0; i < claim.clinicalData.length; i++) {

            const item = claim.clinicalData[i].item;

            if (item.code9000 && item.code9000 !== _0000.id) {
                clinicalSubmittable = true;
                break;
            }
        }

        let otherSubmittable = false;

        for (let i = 0; claim.otherServices.length; i++) {

            const item = claim.otherServices[i].item;

            if (item.code9000 && item.code9000 !== _0000.id) {
                otherSubmittable = true;
                break;
            }
        }

        if (clinicalSubmittable || otherSubmittable || claim.completionReason !== EDI_CR_None) {
            props.stackClaim(claim);
        }
    } catch (error) {
        console.log(error);
    }
}

const exemptionAndRemisionCodes = (claim) => {

    const exemAndRems = [...claim.patientNHSDetails.exemptionsAndRemissions];

    let chxExmpAndRemissionCode = _00;

    for (let i = 0; i < exemAndRems.length; i++) {
        if (exemAndRems[i]) {
            chxExmpAndRemissionCode = mapChxExmpAndRemissionCode(ExemptionAndRemissionCodes[i], claim.patientNHSDetails.exemptionShown[i]);
            break;
        }
    }
    claim.chxExmpAndRemissionCode = chxExmpAndRemissionCode;

    return claim;
}

const getTreatmentCategory = (allCharges) => {

    let treatmentCategory = _9150.id;

    // check for treatment categories
    allCharges.forEach(charge => {

        switch (charge.item.code9000) {
            case _9162.id:
                treatmentCategory = _9162.id;
                break;
            case _9158.id:
                treatmentCategory = _9158.id;
                break;
            case _9154.id:
                treatmentCategory = _9154.id;
                break;
            case _9157.id:
                treatmentCategory = _9157.id;
                break;
            case _9155.id:
                treatmentCategory = _9155.id;
                break;
            case _9156.id:
                treatmentCategory = _9156.id;
                break;
            default:
                break;
        }
    });
    return treatmentCategory;
}

const otherServices = (claim, band, allCharges) => {

    const otherServices = [];

    switch (band) {

        case Band1.id :
            allCharges.forEach(charge => {
                if (_.findIndex([_9163, _9152, _9166], code => code.id === charge.item.code9000) > -1) {
                    otherServices.push(charge);
                }
            });
            break;
        case Band2.id :
        case Band3.id :
        case Urgent.id :
        case NoBand.id :
            allCharges.forEach(charge => {
                if (_.findIndex([_9163, _9152, _9153, _9166], code => code.id === charge.item.code9000) > -1) {
                    otherServices.push(charge);
                }
            });
            break;
        default:
            break;
    }
    claim.otherServices = otherServices;
    return claim;
}

const incompleteTreatment = (claim, incompletedCharges, completedBand) => {

    if (incompletedCharges.length > 0)
        claim.incompletedBand = completedBand;

    return claim;
}

const clinicalDataSetTreatmentProvided = (completedCharges) => {

    const clinicalData = [];

    completedCharges.forEach(charge => {
        if (_.findIndex(Codes9000ClinicalDataSet, code => code.id === charge.item.code9000) > -1) {
            clinicalData.push({id: charge.id, item: charge.item});
        }
    });
    return clinicalData;
}

const getBand = (urgentTreatment, charges) => {

    let chargedBand = NoBand;

    if (urgentTreatment)
        chargedBand = Urgent;
    else {
        // attach the charges
        charges.forEach(charge => {

                const code9000 = _.find(Codes9000ClinicalDataSet, code9000 => code9000.id === charge.item.code9000);

                if (code9000) {
                    const chargeBand = code9000.band;
                    const chargedBandIndex = _.findIndex(Band, band => band.id === chargedBand.id);
                    const chargeBandIndex = _.findIndex(Band, band => band.id === chargeBand.id);

                    if (chargedBandIndex < chargeBandIndex)
                        chargedBand = chargeBand;
                }
        });
    }
    return chargedBand.id;
}

const performerDeclaration = (claim, declaration1) => {

    if (declaration1)
        claim.declarationsBCDS_1 = true;
    else
        claim.declarationsBCDS_2 = true;

    claim.nice = claim.treatmentPlan.nice;
    return claim;
}

const orthoDataSetTreatmentProvided = (claim, orthoCharges) => {

    const clinicalData = [];

    orthoCharges.forEach(charge => {
        if (_.findIndex(Codes9000OrthodonticDataSet, code => code.id === charge.item.code9000) > -1) {
            clinicalData.push(charge);
        }
    });

    claim.clinicalData = clinicalData;
    return claim;
}

const getClaimCharges = (charges) => {

    const results = [];

    charges.forEach(charge => {
        if (charge.status === CH_INVOICE) {
            results.push(charge);
        }
    });
    return results;
}

const getCompletedCharges = (charges) => {

    const results = [];
    charges.forEach(charge => {
        if (charge.status === CH_RECEIPTED || charge.status === CH_INVOICE) {
            results.push(charge);
        }
    });
    return results;
}

const getIncompletedCharges = (charges) => {

    const results = [];
    charges.forEach(charge => {
        if (charge.status === CH_SAVED) {
            results.push(charge);
        }
    });
    return results;
}

const mapChxExmpAndRemissionCode = (exemptionAndRemisionCodes, shown) => {

    if (!shown) {
        switch (exemptionAndRemisionCodes) {
            case _02.id :
                return _01.id;
            case _04.id :
                return _03.id;
            case _05.id :
                return _06.id;
            case _14.id :
                return _13.id;
            case _16.id :
                return _15.id;
            case _18.id :
                return _17.id;
            case _26.id :
                return _25.id;
            case _28.id :
                return _27.id;
            case _32.id :
                return _31.id;
            case _34.id :
                return _33.id;
            case _36.id :
                return _37.id;
            case _71.id :
                return _75.id;
            case _72.id :
                return _76.id;
            case _73.id :
                return _77.id;
            case _74.id :
                return _78.id;
            case _79.id :
                return _80.id;
            case _81.id :
                return _82.id;
            case _84.id :
                return _83.id;
            default:
                break;
        }
    }
    return exemptionAndRemisionCodes;
}
