import * as Actions from '../actions/index';
import _ from 'lodash';

const initialState = {

    results: [],
    searchComplete: false,

    labWorkResults: [],
    labWorkSearchComplete: false,
};

export const findLaboratories = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.REQUEST_LABORATORY_SEARCH:
            case Actions.CLEAR_LABORATORY_SEARCH:

                return {
                    ...state,
                    results: [],
                    searchComplete: false,
                };

            case Actions.RECEIVE_LABORATORY_ADD:

                const {id, firstName, lastName, companyName, type, telephone, email} = action.payload.laboratory;
                const laboratoryResult = {id, firstName, lastName, companyName, type, telephone, email};

                if (state.searchComplete) {

                    const addState = {...state};
                    addState.results.push(laboratoryResult);
                    return addState;
                }
                return state;

            case Actions.RECEIVE_LABORATORY_SEARCH:

                return {
                    ...state,
                    results: action.payload,
                    searchComplete: true,
                };

            case Actions.REQUEST_SEARCH_FOR_WORK_REQUESTED:
            case Actions.REQUEST_SEARCH_FOR_WORK_REQUIRED:
            case Actions.CLEAR_SEARCH_FOR_WORK:

                return {
                    ...state,
                    labWorkResults: [],
                    labWorkSearchComplete: false,
                };

            case Actions.RECEIVE_SEARCH_FOR_WORK_REQUESTED:
            case Actions.RECEIVE_SEARCH_FOR_WORK_REQUIRED:

                return {
                    ...state,
                    labWorkResults: action.payload,
                    labWorkSearchComplete: true,
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};