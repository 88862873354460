import _ from 'lodash';
import * as Actions from '../actions/index';
import {RES_getRecallsDue} from "../actions/recalls";
import {RES_getRemindersDue} from "../actions/reminders";
import {Com1, Com2, Com3, Deactivated, LimitReached} from "../components/FixedItems/Recalls/Constants";

const initialState = {

    // Recalls data

    recallsDueLoaded: false,
    recallsDue: [],

    recallsLoaded: false,
    recalls: [],

    // Reminders data

    remindersDueLoaded: false,
    remindersDue: [],

    remindersLoaded: false,
    reminders: [],

    [`${LimitReached.enum}Loaded`]: false,
    [`${LimitReached.enum}`]: [],

    [`${Com1.enum}Loaded`]: false,
    [`${Com1.enum}`]: [],

    [`${Com2.enum}Loaded`]: false,
    [`${Com2.enum}`]: [],

    [`${Com3.enum}Loaded`]: false,
    [`${Com3.enum}`]: [],

    [`${Deactivated.enum}Loaded`]: false,
    [`${Deactivated.enum}`]: [],
};

export const recallsAndReminders = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Recalls Related

            case Actions.RECEIVE_RECALLS_DUE:

                return {
                    ...state,
                    recallsDueLoaded: true,
                    recallsDue: action.payload,
                };

            case Actions.RECEIVE_RECALLS:

                return {
                    ...state,
                    recallsLoaded: true,
                    recalls: action.payload,
                };

            case Actions.REQUEST_RECALL_MANAGEMENT:

                return {
                    ...state,
                    [`${action.status}Loaded`]: false,
                    [`${action.status}`]: [],
                }

            case Actions.RECEIVE_RECALL_MANAGEMENT:

                return {
                    ...state,
                    [`${action.status}Loaded`]: true,
                    [`${action.status}`]: action.payload,
                }

            case RES_getRecallsDue.CLEAR.action:

                return {
                    ...state,
                    recallsDueLoaded: false,
                    recallsDue: [],
                };

            case Actions.REQUEST_RECALL_SHIFT:

                return {
                    ...state,
                    [`${action.stage}Loaded`]: false,
                    [`${action.stage}`]: [],
                }

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Reminders Related

            case Actions.RECEIVE_REMINDERS_DUE:

                return {
                    ...state,
                    remindersDueLoaded: true,
                    remindersDue: action.payload,
                };

            case Actions.RECEIVE_REMINDERS:

                return {
                    ...state,
                    remindersLoaded: true,
                    reminders: action.payload,
                };

            case RES_getRemindersDue.CLEAR.action:

                return {
                    ...state,
                    remindersDueLoaded: false,
                    remindersDue: [],
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};