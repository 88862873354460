import React from 'react';
import _ from 'lodash';

import {Button} from 'primereact/components/button/Button';
import {TabPanel} from 'primereact/components/tabview/TabView';
import TabErrorBoundary from "../FixedItems/fixedItemUtils";

const AdminPCButtonWrapper = (props) => {

    const Component = props.component;

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onClick;
    const onTabUpdate = props.onTabUpdate;

    const componentId = props.id;
    const defaultTab = props.defaultTab;

    if (props.capabilities === undefined || _.includes(props.capabilities, props.control)) {

        return ([
            <i key={1} className={props.icon} style={props.style}/>,
            <Button key={2} label={props.label} style={{flexGrow: '1', fontSize: '18px'}}
                    id={componentId}
                    onClick={
                        props.onClick.bind(this,
                            {
                                edited: false,
                                content:
                                    <TabPanel key={props.id}
                                              header={props.label}
                                              leftIcon={`${props.tabIcon}`}>
                                        <TabErrorBoundary>
                                            <Component onCloseClick={onCloseClick} id={componentId}
                                                       onAdditionalClick={onAdditionalClick} onTabUpdate={onTabUpdate}
                                                       defaultTab={defaultTab} options={props.options}/>
                                        </TabErrorBoundary>
                                    </TabPanel>,
                                editedContent:
                                    <TabPanel key={props.id}
                                              header={props.label + ' *'} leftIcon={`${props.tabIcon}`}>
                                        <TabErrorBoundary>
                                            <Component onCloseClick={onCloseClick} id={componentId}
                                                       onAdditionalClick={onAdditionalClick} onTabUpdate={onTabUpdate}
                                                       defaultTab={defaultTab}/>
                                        </TabErrorBoundary>
                                    </TabPanel>,
                                defaultTab: defaultTab,
                            }
                        )}
            />
        ])
    } else {
        return ([
            <i key={1} className='blankButton48'/>,
            <Button key={2}
                    label=' '
                    style={{flexGrow: '1', fontSize: '18px'}}
                    id='mainDashboard'
                    disabled="disabled"
            />
        ])
    }
};

export default AdminPCButtonWrapper;
