export default class HashMap {
    constructor() {
        this.map = new Map();
    }

    // Emulates Java HashMap's put() method
    put(key, value) {
        this.map.set(key, value);
    }

    // Emulates Java HashMap's get() method
    get(key) {
        return this.map.get(key);
    }

    // Emulates Java HashMap's size() method
    size() {
        return this.map.size;
    }

    // Emulates Java HashMap's containsKey() method
    containsKey(key) {
        return this.map.has(key);
    }
}
