import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {ColorPicker} from 'primereact/components/colorpicker/ColorPicker';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import _ from "lodash";
import moment from "moment";
import {checkBox, inputText, inputTextArea, spinnerStep} from "../../../PatientDynamicItems/OnChangeUtils";
import {
    HM_AddRecType,
    HM_EditRecType,
    RecallRemType,
    recallRemTypeOrdinal,
    RecallType,
    RT_EMAIL_RECALL,
    RT_SMS_RECALL
} from "../../../../Constants";
import {
    ICON_CANCEL,
    ICON_OK,
} from "../../../../icons";
import {recallTypeData} from "../../DefaultData";

export default class AddRecallType extends Component {

    constructor(props) {
        super();

        this.defaultDocument = {
            id: -1,
            mc: ac.getMcId(),
            visibleName: 'Default',
        };

        this.defaultReportText = {
            id: -1,
            mc: ac.getMcId(),
            name: 'Default',
            spare1: '',
            spare2: '',
            spare3: '',
        };

        let defaultLetterTemplate = null;
        let defaultEmailTemplate = null;
        let defaultSMSTemplate = null;

        let filteredEmailReportTexts = [this.defaultReportText];
        let filteredSMSReportTexts = [this.defaultReportText];

        props.reportTexts.forEach((reportText) => {
            if (reportText.type === RT_EMAIL_RECALL)
                filteredEmailReportTexts.push(reportText);
        });

        props.reportTexts.forEach((reportText) => {
            if (reportText.type === RT_SMS_RECALL)
                filteredSMSReportTexts.push(reportText);
        });

        if (props.editing) {

            this.state = {
                recallType: props.item,
            };
            this.state.recallType.duration = moment(props.item.duration);
        } else {

            this.state = {
                recallType: _.cloneDeep(recallTypeData(ac.getMcId())),
            };
            this.state.recallType.duration = moment().set('hour', 0).set('minute', 0);
        }

        if (this.state.recallType.templateId !== null) {
            defaultLetterTemplate = props.letterTemplates.find(letterTemplate => letterTemplate.docId === this.state.recallType.templateId);
        } else {
            defaultLetterTemplate = this.defaultDocument;
        }
        if (this.state.recallType.emailTemplateId !== null) {
            defaultEmailTemplate = props.reportTexts.find(reportText => reportText.id === this.state.recallType.emailTemplateId);
        } else {
            defaultEmailTemplate = this.defaultReportText;
        }
        if (this.state.recallType.smsTemplateId !== null) {
            defaultSMSTemplate = props.reportTexts.find(reportText => reportText.id === this.state.recallType.smsTemplateId);
        } else {
            defaultSMSTemplate = this.defaultReportText;
        }
        let defaultReminderMethodType = RecallRemType[recallRemTypeOrdinal(this.state.recallType.method)];

        this.state.letterTemplates = [this.defaultDocument, ...props.letterTemplates];
        this.state.emailTemplates = filteredEmailReportTexts;
        this.state.smsTemplates = filteredSMSReportTexts;
        this.state.defaultLetterTemplate = defaultLetterTemplate;
        this.state.defaultEmailTemplate = defaultEmailTemplate;
        this.state.defaultSMSTemplate = defaultSMSTemplate;
        this.state.defaultReminderMethodType = defaultReminderMethodType;
        this.state.filteredEmailReportTexts = filteredEmailReportTexts;
        this.state.filteredSMSReportTexts = filteredSMSReportTexts;

        const duration = moment.duration(this.state.recallType.durationMins, 'minutes');
        this.state.duration = {
            hour: duration.hours(),
            minute: duration.minutes(),
        };

        this.onChange = this.onChange.bind(this);

        this.onReminderMethodChange = this.onReminderMethodChange.bind(this);
        this.onLetterTemplateChange = this.onLetterTemplateChange.bind(this);
        this.onEmailTemplateChange = this.onEmailTemplateChange.bind(this);
        this.onSMSTemplateChange = this.onSMSTemplateChange.bind(this);
        this.onColourSelect = this.onColourSelect.bind(this);
        this.onDurationChange = this.onDurationChange.bind(this);

        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onReminderMethodChange(option) {

        const recallType = {...this.state.recallType};
        recallType.method = option.value.name;

        this.setState({recallType, defaultReminderMethodType: option.value})
    }

    onLetterTemplateChange(option) {

        const recallType = {...this.state.recallType};
        recallType.templateId = option.value.id;

        this.setState({recallType, defaultLetterTemplate: option.value})
    }

    onEmailTemplateChange(option) {

        const recallType = {...this.state.recallType};
        recallType.emailTemplateId = option.value.id;

        this.setState({recallType, defaultEmailTemplate: option.value})
    }

    onSMSTemplateChange(option) {

        const recallType = {...this.state.recallType};
        recallType.smsTemplateId = option.value.id;

        this.setState({recallType, defaultSMSTemplate: option.value})
    }

    onDurationChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        const {hour, minute} = state.duration;

        if (event.owner === 'duration.hour') {
            state.recallType.durationMins = event.value * 60 + minute;
        } else {
            state.recallType.durationMins = hour * 60 + event.value;
        }
        this.setState(state);
    }

    onColourSelect(event) {

        const nc = event.value;

        const newRecallType = {...this.state.recallType};
        newRecallType.red = nc.r;
        newRecallType.green = nc.g;
        newRecallType.blue = nc.b;

        this.setState({recallType: newRecallType})
    }

    onHide() {
        const target = this.props.editing ? HM_EditRecType.id : HM_AddRecType.id;
        this.props.onHideDialog(target)
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const rt = this.state.recallType;
        const rtDefined = rt.title === '' || rt.description === '';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.recallType)
                        }}
                        disabled={rtDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'recallType',
            recallType: this.state.recallType,
        };

        const durationProps = {
            onChange: this.onDurationChange,
            target: 'duration',
            duration: this.state.duration,
        };

        const colorValue = {r: rt.red, g: rt.green, b: rt.blue};

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={this.props.visible}
                    resizable={true}
                    onHide={this.onHide}>

                <div className="p-grid p-fluid">

                    <div className="p-col-3">
                        <label>Code</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'code', '')}
                    </div>

                    <div className="p-col-3">
                        <label>Title</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'title', '')}
                    </div>

                    <div className="p-col-3">
                        <label>Description</label>
                    </div>
                    <div className="p-col-9">
                        {inputTextArea(props, 'description', 4, -1, false, false)}
                    </div>

                    <div className="p-col-3">
                        <label>Narrative</label>
                    </div>
                    <div className="p-col-9">
                        {inputTextArea(props, 'narrative', 4, -1, false, false)}
                    </div>

                    <div className="p-col-3">
                        <label>Letter Template</label>
                    </div>
                    <div className="p-col-9">
                        <Dropdown optionLabel='visibleName'
                                  value={this.state.defaultLetterTemplate}
                                  options={this.state.letterTemplates}
                                  onChange={this.onLetterTemplateChange}
                                  autoWidth={false}
                                  scrollHeight='100px'
                        />
                    </div>

                    <div className="p-col-3">
                        <label>Email Template</label>
                    </div>
                    <div className="p-col-9">
                        <Dropdown optionLabel='name'
                                  value={this.state.defaultEmailTemplate}
                                  options={this.state.filteredEmailReportTexts}
                                  onChange={this.onEmailTemplateChange}
                                  autoWidth={false}
                                  scrollHeight='100px'
                        />
                    </div>

                    <div className="p-col-3">
                        <label>SMS Template</label>
                    </div>
                    <div className="p-col-9">
                        <Dropdown optionLabel='name'
                                  value={this.state.defaultSMSTemplate}
                                  options={this.state.filteredSMSReportTexts}
                                  onChange={this.onSMSTemplateChange}
                                  autoWidth={false}
                                  scrollHeight='100px'
                        />
                    </div>

                    <div className="p-col-3">
                        <label>Color</label>
                    </div>
                    <div className="p-col-9">
                        <ColorPicker format='rgb'
                                     value={colorValue}
                                     onChange={this.onColourSelect}
                        />
                    </div>
                    <div className="p-col-3">
                        <label>Duration</label>
                    </div>
                    <div className="p-col-2">
                        {spinnerStep(durationProps, 'hour', 0, 45, 1)}
                    </div>
                    <div className="p-col-1">
                        <label>Hrs</label>
                    </div>
                    <div className="p-col-2">
                        {spinnerStep(durationProps, 'minute', 0, 45, 15)}
                    </div>
                    <div className="p-col-1">
                        <label>Mins</label>
                    </div>
                    <div className="p-col-3"/>

                    <div className="p-col-3"/>
                    <div className="p-col-3">
                        {checkBox(props, 'archived', 'Archived')}
                    </div>
                    <div className="p-col-5">
                        {checkBox(props, 'keepSeparate', 'Separate Each Recall')}
                    </div>
                    <div className="p-col-1"/>

                    <div className="p-col-3"/>
                    <div className="p-col-2">
                        {spinnerStep(props, 'interval', 0, 30, 1)}
                    </div>
                    <div className="p-col-2">
                        <label>Interval(Months)</label>
                    </div>
                    <div className="p-col-2">
                        {spinnerStep(props, 'limit', 0, 30, 1)}
                    </div>
                    <div className="p-col-2">
                        <label>Max Count</label>
                    </div>
                    <div className="p-col-1"/>

                    <div className="p-col-3">
                        <label>Contact By</label>
                    </div>
                    <div className="p-col-9" style={{marginBottom: '6.5em'}}>
                        <Dropdown optionLabel='text'
                                  value={this.state.defaultReminderMethodType}
                                  options={RecallType}
                                  onChange={this.onReminderMethodChange}
                                  autoWidth={false}
                                  scrollHeight='100px'
                        />
                    </div>
                </div>
            </Dialog>
        )
    }
};
