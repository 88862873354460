import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {ColorPicker} from 'primereact/components/colorpicker/ColorPicker';

import {checkBox, inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    DE_DROP_DOWN_TYPES,
    DE_TYPE_NONE,
    DE_TYPE_RESERVATION,
    DE_TYPES,
    HM_AddDET,
    HM_EditDET, TC_ARCHIVED,
} from "../../../../Constants";
import {
    ICON_CANCEL,
    ICON_OK
} from "../../../../icons";
import {Dropdown} from "primereact/dropdown";
import * as DefaultData from "../../DefaultData";
import {Checkbox} from "primereact/components/checkbox/Checkbox";

export default class AddDiaryEventType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            diaryEventType: props.editing ? {...props.item} : _.cloneDeep(DefaultData.DiaryEventType(ac.getMcId())),
        };

        if (this.state.diaryEventType.type === null) {
            this.state.diaryEventType.type = this.state.diaryEventType.reservationType ? DE_TYPE_RESERVATION : DE_TYPE_NONE;
        }

        this.onChange = this.onChange.bind(this);
        this.onColourSelect = this.onColourSelect.bind(this);

        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onColourSelect(event) {

        const nc = event.value;

        const newDiaryEventType = {...this.state.diaryEventType};
        newDiaryEventType.red = nc.r;
        newDiaryEventType.green = nc.g;
        newDiaryEventType.blue = nc.b;

        this.setState({diaryEventType: newDiaryEventType})
    }

    onHide() {
        const target = this.props.editing ? HM_EditDET.id : HM_AddDET.id;
        this.props.onHideDialog(target);
    }

    showOptionalCode(props) {

        if (props.editing)
            return null;

        const content = [];

        content.push(<div className="p-col-5"><label>Code</label></div>);
        content.push(<div className="p-col-7">{inputText(props, 'code', '')}</div>);

        return content;
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const det = this.state.diaryEventType;
        const detDefined = (det.description !== '' && det.code !== '' && det.title !== '') ? '' : 'disabled';
        const readOnly = det.reservationType;

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.diaryEventType)
                        }}
                        disabled={detDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            editing: this.props.editing,
            onChange: this.onChange,
            target: 'diaryEventType',
            diaryEventType: this.state.diaryEventType,
        };

        const colorValue = {r: det.red, g: det.green, b: det.blue};
        const apTypeDisabled = (this.state.diaryEventType.type.name === DE_TYPE_NONE.name) || (this.state.diaryEventType.type.name === DE_TYPE_RESERVATION.name) || readOnly;
        const appointmentTypes = _.filter(this.props.appointmentTypes, apType => apType.onlineType === this.state.diaryEventType.type.name);
        const appointmentType = _.find(this.props.appointmentTypes, apType => apType.id === this.state.diaryEventType.appointmentTypeId);

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={this.props.visible}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog();
                    }}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid">

                        {this.showOptionalCode(props)}

                        <div className="p-col-3">
                            <label>Title</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'title', '')}
                        </div>

                        <div className="p-col-12">
                            {inputTextArea(props, 'description', 5, -1, false, false)}
                        </div>

                        <div className="p-col-2">
                            <label>Color</label>
                        </div>
                        <div className="p-col-3">
                            <ColorPicker format='rgb'
                                         value={colorValue}
                                         onChange={this.onColourSelect}
                            />
                        </div>

                        <div className="p-col-7">
                            {checkBox(props, 'archived', 'Archived')}
                        </div>

                        <div className="p-col-2">
                            <label>Event Type</label>
                        </div>
                        <div className="p-col-3">
                            <Dropdown key={`types`}
                                      value={this.state.diaryEventType.type}
                                      options={readOnly ? DE_TYPES : DE_DROP_DOWN_TYPES}
                                      optionLabel='text'
                                      onChange={(e) => {
                                          const type = _.find(DE_DROP_DOWN_TYPES, type => type.name === e.value.name);
                                          props.onChange({owner: `diaryEventType.type`, value: type})
                                      }}
                                      disabled={readOnly}
                            />
                        </div>
                        <div className="p-col-2">
                            <label>Appointment Type</label>
                        </div>
                        <div className="p-col-5">
                            <Dropdown key={`types`}
                                      value={appointmentType}
                                      options={appointmentTypes}
                                      optionLabel='description'
                                      onChange={(e) => {
                                          props.onChange({owner: `diaryEventType.appointmentTypeId`, value: e.target.value.id})
                                      }}
                                      disabled={apTypeDisabled}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
