import React, {Component} from 'react';
import {connect} from "react-redux";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';

export class ConnectedDatabaseRestore extends Component {

    constructor(props) {
        super(props);
        this.state = {
            props,
        };
    }

    render() {

        return (
            <div className="card" id="detailPanel">
                <Toolbar right={<Button className="p-button-danger" icon="fa fa-times"
                                        onClick={this.props.onCloseClick.bind(this, {key: this.props.id})}/>}
                />
                <Panel header='Database Restore'>
                    <div className="p-grid p-fluid form-group">
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {}
};

const mapDispatchToProps = dispatch => {
    return {};
};

const DatabaseRestore = connect(mapStateToProps, mapDispatchToProps)(ConnectedDatabaseRestore);

export default DatabaseRestore;
