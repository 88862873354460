import {ACC_LEVEL_1, ACC_ST_POPULATED, ACC_TYPE_SINGLE, NewPassword} from "../../Constants";

export const GroupSubscriptionData = (groupId) => {

    return {
        passwd: NewPassword,
        hash: '',
        salt: '',
        id: null,
        mc: null,
        group: {id: groupId},
        name: '',
        description: '',
        comments: '',
        contact: '',
        telephone: '',
        mobile: '',
        email: '',
        address: {
            id: null,
            mc: null,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {
                "id": 230,
                "mc": 1,
                "countryName": "UNITED KINGDOM",
                "rank": 1
            }
        },
        accountStatus: ACC_ST_POPULATED.name,
        populationDate: new Date(),
        activationDate: null,
        inactivationDate: null,
        blockingDate: null,
        leavingDate: null,
        leftDate: null,
        paymentAmount: 0.0,
        paymentDate: null,
        bankName: '',
        sortCode: '',
        accountNumber: '',
        overdueMonthCount: 0,
        bankAddress: {
            id: null,
            mc: null,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {
                "id": 230,
                "mc": 1,
                "countryName": "UNITED KINGDOM",
                "rank": 1
            }
        },
        level: ACC_LEVEL_1.name,
        maximumActiveUsers: 1,
        actualActiveUsers: 1,
        type: ACC_TYPE_SINGLE.name,
    }
};
