import classNames from "classnames";

export const tabletLayoutClassName = classNames('layout-wrapper', {
    'menu-layout-static': true,
    'menu-layout-overlay': false,
    'layout-menu-overlay-active': false,
    'menu-layout-slim': false,
    'menu-layout-horizontal': false,
    'layout-menu-static-inactive': true,
    'layout-menu-static-active': false
});

export const TAB_PatientConciege = 'PatientDetailsConciege';
export const TAB_PatientDetailsUpdate = 'PatientDetailsUpdate';
export const TAB_MedicalHistoryUpdate = 'MedicalHistoryUpdate';
export const TAB_PatientConsentForms = 'PatientConsentForms';
export const TAB_TreatmentPlanSigns = 'TreatmentPlanSigns';
export const TAB_PaymentPlanSigns = 'PaymentPlanSigns';
export const TAB_Questionnaire = 'Questionnaire';

export const QUESTIONNAIRE_ON_TABLET = 'OnTablet';
export const QUESTIONNAIRE_CURRENT = 'Current';
export const QUESTIONNAIRE_CURRENT_BY_PRACTICE = 'CurrentByPractice';
export const QUESTIONNAIRE_COMPLETE = 'Complete';
export const QUESTIONNAIRE_COMPLETE_BY_PRACTICE = 'CompleteByPractice';
