export const NEW_PATIENTS = {
    id: 0,
    header: 'New Patients'
};
export const REFERRAL_SOURCES = {
    id: 1,
    header: 'Referral Sources'
};
export const ACTIVE_PATIENTS = {
    id: 2,
    header: 'Active Patients'
};
export const TREATMENT_PLANNING = {
    id: 3,
    header: 'Treatment Planning'
};
export const APPOINTMENTS_BOOKED = {
    id: 4,
    header: 'Appointments Booked'
};
export const APPOINTMENTS = {
    id: 5,
    header: 'Appointments'
};
