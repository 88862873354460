import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {Editor} from "primereact/editor";

export const QuillReport = (props) => {

    const reportProps = {
        onChange: () => {
        },
        target: 'report',
        report: props.report,
    };

    return (
        <Panel header='Details'>
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label htmlFor="name">Name</label>
                </div>
                <div className="p-col-12">
                    {inputText(reportProps, 'name', '', props.viewing, false)}
                </div>
                <div className="p-col-12">
                    <label htmlFor='report.comment'>Comment</label>
                </div>
                <div className="p-col-12">
                    {inputText(reportProps, 'comment', '', props.viewing, false)}
                </div>
                <div className="p-col-12">
                    <Editor className="p-fluid p-col-12"
                            value={reportProps.report.text}
                            readOnly={true}
                            style={{height: '340px'}}/>
                </div>
            </div>
        </Panel>
    )
};
