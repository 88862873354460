import * as Actions from './index';
import {ac} from '../index'

const baseURL = '/Utils';

export const RES_getDropDowns = {
    url: `dropDowns`,
    request: Actions.REQUEST_DROPDOWNS,
    receive: Actions.RECEIVE_DROPDOWNS
};

export const getDropDowns = ({url, request, receive}) => {

    return (dispatch) => {

        const mcId = ac.getMcId() === '' ? 1 : ac.getMcId();

        ac.fetchClient().get(`${baseURL}/${url}?mcId=${mcId}`)
            .then(res => {
                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};
