import React, {Component} from 'react';
import * as Sections from "../Constants";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {SM_OVER_DUE_30, SM_OVER_DUE_3160, SM_OVER_DUE_6190, SM_OVER_DUE_91} from "../../../../actions/stateManagement";
import OverDueAccounts30 from "./OverDueAccounts30";
import OverDueAccounts3160 from "./OverDueAccounts3160";
import OverDueAccounts6190 from "./OverDueAccounts6190";
import OverDueAccounts91 from "./OverDueAccounts91";

export default class AccGroupOverdueAccounts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onTabChange = (index) => {
        this.setState({activeIndex: index});
    }

    render() {

        const tabStack = [];

        tabStack.push(
            {
                section: Sections.OVER_DUE_30,
                content: <TabPanel key={Sections.OVER_DUE_30}
                                   header={SM_OVER_DUE_30.label}>
                    <OverDueAccounts30 onChange={this.props.onChange}
                                       toolbarCallbacks={this.props.toolbarCallbacks}
                                       setPeriodTotal={this.props.setPeriodTotal}
                                       onPCButtonClick={this.props.onPCButtonClick}
                                       onCloseClick={this.props.onCloseClick}
                                       onRequestPreviewAccountItem={this.props.onRequestPreviewAccountItem}
                                       accountGroup={this.props.accountGroup}
                    />
                </TabPanel>
            }
        );

        tabStack.push(
            {
                section: Sections.OVER_DUE_3160,
                content: <TabPanel key={Sections.OVER_DUE_3160}
                                   header={SM_OVER_DUE_3160.label}>
                    <OverDueAccounts3160 onChange={this.props.onChange}
                                         toolbarCallbacks={this.props.toolbarCallbacks}
                                         setPeriodTotal={this.props.setPeriodTotal}
                                         onPCButtonClick={this.props.onPCButtonClick}
                                         onCloseClick={this.props.onCloseClick}
                                         onRequestPreviewAccountItem={this.props.onRequestPreviewAccountItem}
                                         accountGroup={this.props.accountGroup}
                    />
                </TabPanel>
            }
        );

        tabStack.push(
            {
                section: Sections.OVER_DUE_6190,
                content: <TabPanel key={Sections.OVER_DUE_6190}
                                   header={SM_OVER_DUE_6190.label}>
                    <OverDueAccounts6190 onChange={this.props.onChange}
                                         toolbarCallbacks={this.props.toolbarCallbacks}
                                         setPeriodTotal={this.props.setPeriodTotal}
                                         onPCButtonClick={this.props.onPCButtonClick}
                                         onCloseClick={this.props.onCloseClick}
                                         onRequestPreviewAccountItem={this.props.onRequestPreviewAccountItem}
                                         accountGroup={this.props.accountGroup}
                    />
                </TabPanel>
            }
        );

        tabStack.push(
            {
                section: Sections.OVER_DUE_91,
                content: <TabPanel key={Sections.OVER_DUE_91}
                                   header={SM_OVER_DUE_91.label}>
                    <OverDueAccounts91 onChange={this.props.onChange}
                                       toolbarCallbacks={this.props.toolbarCallbacks}
                                       setPeriodTotal={this.props.setPeriodTotal}
                                       onPCButtonClick={this.props.onPCButtonClick}
                                       onCloseClick={this.props.onCloseClick}
                                       onRequestPreviewAccountItem={this.props.onRequestPreviewAccountItem}
                                       accountGroup={this.props.accountGroup}
                    />
                </TabPanel>
            }
        );

        return (
            <TabView scrollable={true}
                     activeIndex={this.props.accountGroupData.activeIndex}
                     onTabChange={(e) => {
                         this.props.onTabChange(e.index)
                     }}>

                {tabStack.map(tab => {
                    return tab.content
                })}
            </TabView>
        )

    }
}
