import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import moment from "moment";
import {ac} from '../../index';

import {Accordion, AccordionTab} from 'primereact/components/accordion/Accordion';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Toast} from 'primereact/toast';
import {getPatientIds, printDocumentItem} from "../FixedItems/PatientDetails/Utils";
import {getResource, openDocument, RES_PATIENT_DOCUMENTS} from "../../actions/personal";
import {
    DOC_ANY,
    DOC_APPOINTMENT_LETTER,
    DOC_CONSENT,
    DOC_PAYMENT_PLAN,
    DOC_PDF,
    DOC_SMS,
    DOC_TREATMENT_PLAN,
    DOC_UPLOAD_MSWORD,
    DOC_UPLOAD_WORD,
    DOC_WORD,
    HM_notImplemented,
    HM_PreviewPatientDocument,
    HM_PrintConsentDocument,
    HM_PrintPatientDocument,
    HM_PrintPaymentPlanDocument,
    HM_PrintPresDocument,
    HM_PrintReferralDocument,
    HM_PrintTreatmentPlanDocument,
    UP_DOCUMENT_ADDED
} from "../../Constants";
import {dateTemplate} from "../PatientDynamicItems/Utils";
import {ShowMessageDialog} from "../FixedItems/Diary/components/EventComponent";
import * as Actions from "../../actions";
import {ProgressBar} from "primereact/progressbar";
import {menuButtonTemplate} from "../FixedItems/fixedItemUtils";
import PatientUploadedPDF from "../FixedItems/PatientDetails/PDFs/PatientUploadedPDF";
import {
    buildPatientConsentItemsMenu,
    buildPatientDocumentsItemsMenu,
    buildTPPPDocumentsItemsMenu,
    CM_DOCUMENTS_CONSENT,
    CM_DOCUMENTS_PAT,
    CM_DOCUMENTS_TP,
    docTypeTemplate
} from "../FixedItems/PatientDetails/Constants";
import {Panel} from "primereact/components/panel/Panel";
import {SE_NONE, SM_CLIENT_DOCUMENTS} from "./Constants";
import {Button} from "primereact/components/button/Button";
import {ClientComponent} from "./ClientComponent";

class ConnectedClientDocuments extends ClientComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                activeIndex: 0,
                stateManagementId: props.patientDocumentsId,
                patientId: props.patientId,

                patientDocuments: [],
                patientDocumentsSelectValue: null,
                firstDoc: 0,
                docRows: 5,

                tpDocuments: [],
                tpDocumentsSelectValue: null,
                firstTp: 0,
                tpRows: 5,

                smsEmailDocuments: [],
                smsEmailDocumentsSelectValue: null,
                firstSMS: 0,
                SMSRows: 5,

                consentFormDocuments: [],
                consentFormDocumentsSelectValue: null,
                firstConsent: 0,
                consentRows: 5,

                eventTarget: null,

                contextMenus: [
                    {tag: CM_DOCUMENTS_PAT, menu: null},
                    {tag: CM_DOCUMENTS_TP, menu: null},
                    {tag: CM_DOCUMENTS_CONSENT, menu: null},
                ],
            };
        }
        this.showDialogs = this.showDialogs.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.showContextMenu = this.showContextMenu.bind(this);
        this.setMenu = this.setMenu.bind(this);

        this.onOnlyOfficeOpen = this.onOnlyOfficeOpen.bind(this);
        this.onDocumentsDoubleClick = this.onDocumentsDoubleClick.bind(this);

        this.onPrintDocument = this.onPrintDocument.bind(this);

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
            [HM_PrintPatientDocument.id]: (menu) => this.onPrintDocument(menu),

            [HM_PreviewPatientDocument.id]: this.onRequestPreviewDocument
        };

        this.emailSendGrowl = null;
    }

    componentDidMount() {

        this.props.getDocuments(this.state.patientId);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_DOCUMENT_ADDED:

                            const document = {...this.props.wsmessage.content};

                            // this filters out other patients updates
                            if (parseInt(this.state.patientId, 10) === document.patientId) {

                                switch (document.type) {

                                    case DOC_WORD.name:
                                    case DOC_ANY.name:
                                    case DOC_UPLOAD_MSWORD.name:
                                    case DOC_UPLOAD_WORD.name:
                                    case DOC_PDF.name:
                                    case DOC_APPOINTMENT_LETTER.name:
                                        const patientDocuments = [...this.state.patientDocuments];
                                        patientDocuments.push(document);
                                        this.setState({
                                            patientDocuments,
                                        });
                                        break;
                                    case DOC_TREATMENT_PLAN.name:
                                    case DOC_PAYMENT_PLAN.name:
                                        const tpDocuments = [...this.state.tpDocuments];
                                        tpDocuments.push(document);
                                        this.setState({
                                            tpDocuments,
                                        });
                                        break;
                                    case DOC_SMS.name:
                                        const smsEmailDocuments = [...this.state.smsEmailDocuments];
                                        smsEmailDocuments.push(document);
                                        this.setState({
                                            smsEmailDocuments,
                                        });
                                        break;
                                    case DOC_CONSENT.name:
                                        const consentFormDocuments = [...this.state.consentFormDocuments];
                                        consentFormDocuments.push(document);
                                        this.setState({
                                            consentFormDocuments
                                        });
                                        break;
                                    default:
                                        break;
                                }
                                break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;

                case RES_PATIENT_DOCUMENTS.OPEN.action:

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(new Blob([this.props.documentData]), this.props.filename); // for IE browser
                    } else {
                        const url = window.URL.createObjectURL(new Blob([this.props.documentData]));
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = this.props.filename;
                        a.target = '_blank';
                        a.click();
                    }
                    break;
                case RES_PATIENT_DOCUMENTS.GET.receive:
                case RES_PATIENT_DOCUMENTS.DELETE.action:

                    const patientDocuments = [];
                    const tpDocuments = [];
                    const smsEmailDocuments = [];
                    const consentFormDocuments = [];

                    this.props.patientDocuments.forEach(document => {
                        switch (document.type) {

                            case DOC_WORD.name:
                            case DOC_ANY.name:
                            case DOC_UPLOAD_MSWORD.name:
                            case DOC_UPLOAD_WORD.name:
                            case DOC_PDF.name:
                            case DOC_APPOINTMENT_LETTER.name:
                                patientDocuments.push(document);
                                break;
                            case DOC_TREATMENT_PLAN.name:
                            case DOC_PAYMENT_PLAN.name:
                                tpDocuments.push(document);
                                break;
                            case DOC_SMS.name:
                                smsEmailDocuments.push(document);
                                break;
                            case DOC_CONSENT.name:
                                consentFormDocuments.push(document);
                                break;
                            default:
                                break;
                        }
                    });
                    this.setState({
                        patientDocuments,
                        tpDocuments,
                        smsEmailDocuments,
                        consentFormDocuments
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onPrintDocument(menu) {

        let selection = null;

        switch (menu.target) {
            case HM_PrintPatientDocument.id:
                selection = this.state.patientDocumentsSelectValue;
                break;
            case HM_PrintPresDocument.id:
                selection = this.state.presDocumentsSelectValue;
                break;
            case HM_PrintReferralDocument.id:
                selection = this.state.refDocumentsSelectValue;
                break;
            case HM_PrintTreatmentPlanDocument.id:
                selection = this.state.tpDocumentsSelectValue;
                break;
            case HM_PrintPaymentPlanDocument.id:
                selection = this.state.tpDocumentsSelectValue;
                break;
            case HM_PrintConsentDocument.id:
                selection = this.state.consentFormDocumentsSelectValue;
                break;
            default:
                break;
        }

        const options = {
            itemId: selection.docId,
            report: menu.report,
            reportFunction: menu.reportFunction,
            target: menu.target,
            type: selection.type,
            filename: selection.filename,
        };

        printDocumentItem({options}, this.emailSendGrowl);
    }

    onSelectionChange(event, owner) {

        this.setState({[owner]: event.value});
    };

    onDocumentsDoubleClick(event, owner) {

        this.setState({[owner]: event.data}, () => {

            this.props.openDocument(event.data);
        });
    };

    onOnlyOfficeOpen({filename, visibleName, type, docId}) {

        if (type === DOC_PDF.name) {
            this.props.onPCButtonClick(menuButtonTemplate(this.props, PatientUploadedPDF, docId, 'fas fa-file-pdf', `${visibleName}`, null, {
                filename,
                visibleName,
                type,
                docId
            }));
        } else {
            const documentData = {
                chiralServer: ac.getBASERESTURL(),
                mcid: ac.getMcId(),
                filename,
                docname: visibleName,
                type
            };

            const key = Math.random().toString(36).slice(2);
            const key2 = "1234";

            localStorage.setItem(key2, JSON.stringify(documentData));

            window.open(`https://${ac.getChiralServer()}/documentLoader.html?id=${key}`, '_blank');
        }
    }

    showDialogs() {

        const contents = [];
        contents.push(ShowMessageDialog(this, HM_notImplemented));

        return contents;
    }

    showContextMenu(tag, event) {

        this.state.contextMenus.forEach(menu => menu.menu.hide(event));
        const index = _.findIndex(this.state.contextMenus, menu => menu.tag === tag);
        this.state.contextMenus[index].menu.show(event);
    }

    setMenu(tag, ref) {
        const index = _.findIndex(this.state.contextMenus, menu => menu.tag === tag);
        this.state.contextMenus[index].menu = ref;
    }

    render() {

        if (!this.props.patientDocumentsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

      

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{SM_CLIENT_DOCUMENTS.detail}</label>
            <div className="p-toolbar-group-right">
                <Button label={SM_CLIENT_DOCUMENTS.exitLabel} icon={SM_CLIENT_DOCUMENTS.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSave(SE_NONE)}
                />
            </div>
        </div>;

        const patientDocuments = _.orderBy(this.state.patientDocuments, (document) => {
            return document.created === null ? '' : moment(document.created).format('YYYYMMDD');
        }, ['asc']);

        const smsEmailDocuments = _.orderBy(this.state.smsEmailDocuments, (document) => {
            return document.created === null ? '' : moment(document.created).format('YYYYMMDD');
        }, ['asc']);

        const consentFormDocuments = _.orderBy(this.state.consentFormDocuments, (document) => {
            return document.created === null ? '' : moment(document.created).format('YYYYMMDD');
        }, ['asc']);

        return (
            <div className="p-col-12 p-lg-12">
                <Panel header={header}>
                    <div className='p-col-12 p-lg-12'>

                        <Toast ref={(el) => {
                            this.emailSendGrowl = el;
                        }}/>
                        <ContextMenu style={{width: 250}}
                                     model={buildPatientDocumentsItemsMenu(this.toolbarCallbacks, this.onShowMenuEvent, true)}
                                     ref={el => this.setMenu(CM_DOCUMENTS_PAT, el)}/>
                        <ContextMenu style={{width: 300}}
                                     model={buildTPPPDocumentsItemsMenu(this.toolbarCallbacks, this.onShowMenuEvent, this.state.tpDocumentsSelectValue, true)}
                                     ref={el => this.setMenu(CM_DOCUMENTS_TP, el)}/>
                        <ContextMenu style={{width: 250}}
                                     model={buildPatientConsentItemsMenu(this.toolbarCallbacks, this.onShowMenuEvent, true)}
                                     ref={el => this.setMenu(CM_DOCUMENTS_CONSENT, el)}/>

                        {this.showDialogs()}

                        <Accordion multiple={true} activeIndex={this.state.activeIndex}
                                   onTabChange={(event) => {
                                       this.setState({activeIndex: event.index});
                                   }}>
                            <AccordionTab key={1} header='Documents'>
                                <DataTable value={patientDocuments}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           paginator={true}
                                           rows={this.state.docRows}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => {
                                               this.onPageFlex(e, 'firstDoc', 'docRows');
                                           }}
                                           first={this.state.firstDoc}
                                           onContextMenu={e => this.showContextMenu('cmPatientDocuments', e.originalEvent)}
                                           selectionMode="single"
                                           selection={this.state.patientDocumentsSelectValue}
                                           onRowDoubleClick={(event) => {
                                               this.onOnlyOfficeOpen(this.state.patientDocumentsSelectValue)
                                           }}
                                           onSelectionChange={(event) => this.onSelectionChange(event, 'patientDocumentsSelectValue')}
                                           contextMenuSelection={this.state.patientDocumentsSelectValue}
                                           onContextMenuSelectionChange={(event) => this.onSelectionChange(event, 'patientDocumentsSelectValue')}
                                >
                                    <Column body={docTypeTemplate} header="" style={{width: '5%'}}/>
                                    <Column body={row => dateTemplate(row.created)} header="Created On"
                                            style={{width: '15%'}}/>
                                    <Column field="userName" header="By" style={{width: '15%'}}/>
                                    <Column field="visibleName" header="Name" style={{width: '25%'}}/>
                                    <Column field="notes" header="Note" style={{width: '40%'}}/>
                                </DataTable>
                            </AccordionTab>
                            <AccordionTab key={4} header='Treatment & Payment Plans'>
                                <DataTable value={this.state.tpDocuments}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           paginator={true}
                                           rows={this.state.tpRows}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => {
                                               this.onPageFlex(e, 'firstTp', 'tpRows');
                                           }}
                                           first={this.state.firstTp}
                                           onContextMenu={e => this.showContextMenu('cmTpDocuments', e.originalEvent)}
                                           selectionMode="single"
                                           selection={this.state.tpDocumentsSelectValue}
                                           onRowDoubleClick={() => {
                                               this.onOnlyOfficeOpen(this.state.tpDocumentsSelectValue)
                                           }}
                                           onSelectionChange={(event) => this.onSelectionChange(event, 'tpDocumentsSelectValue')}
                                           contextMenuSelection={this.state.tpDocumentsSelectValue}
                                           onContextMenuSelectionChange={(event) => this.onSelectionChange(event, 'tpDocumentsSelectValue')}
                                >
                                    <Column body={docTypeTemplate} header="" style={{width: '5%'}}/>
                                    <Column body={row => dateTemplate(row.created)} header="Created On"
                                            style={{width: '15%'}}/>
                                    <Column field="userName" header="By" style={{width: '15%'}}/>
                                    <Column field="visibleName" header="Name" style={{width: '25%'}}/>
                                    <Column field="notes" header="Note" style={{width: '40%'}}/>
                                </DataTable>
                            </AccordionTab>
                            <AccordionTab key={5} header='SMS & Emails'>
                                <DataTable value={smsEmailDocuments}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           paginator={true}
                                           rows={this.state.SMSRows}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => {
                                               this.onPageFlex(e, 'firstSMS', 'SMSRows');
                                           }}
                                           first={this.state.firstSMS}
                                           selectionMode="single"
                                           selection={this.state.smsEmailDocumentsSelectValue}
                                           onRowDoubleClick={(event) => this.onDocumentsDoubleClick(event, 'smsEmailDocumentsSelectValue')}
                                           onSelectionChange={(event) => this.onSelectionChange(event, 'smsEmailDocumentsSelectValue')}
                                >
                                    <Column body={docTypeTemplate} header="" style={{width: '5%'}}/>
                                    <Column body={row => dateTemplate(row.created)} header="Created On"
                                            style={{width: '15%'}}/>
                                    <Column field="userName" header="By" style={{width: '15%'}}/>
                                    <Column field="visibleName" header="Name" style={{width: '25%'}}/>
                                    <Column field="notes" header="Note" style={{width: '40%'}}/>
                                </DataTable>
                            </AccordionTab>
                            <AccordionTab key={6} header='Consent Forms'>
                                <DataTable value={consentFormDocuments}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           paginator={true}
                                           rows={this.state.consentRows}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => {
                                               this.onPageFlex(e, 'firstConsent', 'consentRows');
                                           }}
                                           first={this.state.firstConsent}
                                           onContextMenu={e => this.showContextMenu('cmConsentFormDocuments', e.originalEvent)}
                                           selectionMode="single"
                                           selection={this.state.consentFormDocumentsSelectValue}
                                           onRowDoubleClick={(event) => this.onDocumentsDoubleClick(event, 'consentFormDocumentsSelectValue')}
                                           onSelectionChange={(event) => this.onSelectionChange(event, 'consentFormDocumentsSelectValue')}
                                           contextMenuSelection={this.state.consentFormDocumentsSelectValue}
                                           onContextMenuSelectionChange={(event) => this.onSelectionChange(event, 'consentFormDocumentsSelectValue')}
                                >
                                    <Column body={docTypeTemplate} header="" style={{width: '5%'}}/>
                                    <Column body={row => dateTemplate(row.created)} header="Created On"
                                            style={{width: '15%'}}/>
                                    <Column field="userName" header="By" style={{width: '15%'}}/>
                                    <Column field="visibleName" header="Name" style={{width: '25%'}}/>
                                    <Column field="notes" header="Note" style={{width: '40%'}}/>
                                </DataTable>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </Panel>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        patientDataId,

        patientDocumentsId,
        patientDocumentsLoaded,

    } = getPatientIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        patientDataId,

        patientDocumentsLoaded,
        patientDocumentsId,
        patientDocuments: state.patients[patientDocumentsId],

        filename: state.patients.filename,
        documentData: state.patients.documentData,

        wsmessage: state.websockets.message,

        onPCButtonClick: state.login.onPCButtonClick,

        currentState: state.stateManagement[patientDocumentsId],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getDocuments: (patientId) => dispatch(getResource(RES_PATIENT_DOCUMENTS.GET, {patientId})),
        openDocument: (document) => dispatch(openDocument(RES_PATIENT_DOCUMENTS.OPEN, document)),
    }
};

const ClientDocuments = connect(MapStateToProps, MapDispatchToProps)(ConnectedClientDocuments);

export default ClientDocuments;
