import * as Actions from './index';
import {ac} from '../index'
import moment from 'moment';
import {
    WORK_STATUS_APPROVED,
    WORK_STATUS_LAB_NOTIFIED,
    WORK_STATUS_RECEIVED_FROM_LAB, WORK_STATUS_SENT_TO_LAB,
    WORK_TYPE_FOR_APPOINTMENT
} from "../Constants";

export const baseURL = '/Laboratories';

export const RES_LABORATORY_DETAILS = {
    GET: {
        url: `${baseURL}/getLaboratory`,
        request: Actions.REQUEST_LABORATORY_DETAILS,
        receive: Actions.RECEIVE_LABORATORY_DETAILS
    },
    ADD: {
        url: `${baseURL}/addLaboratory`,
        request: Actions.REQUEST_LABORATORY_ADD,
        receive: Actions.RECEIVE_LABORATORY_ADD
    },
    SAVE: {action: Actions.SAVE_LABORATORY_DETAILS, url: `${baseURL}/updateLaboratory`},
    CLEAR: {action: Actions.CLEAR_LABORATORY_DETAILS},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_LABORATORY_DETAILS},
};

export const RES_FIND_LABORATORIES = {
    CLEAR: {action: Actions.CLEAR_LABORATORY_SEARCH}
};

export const RES_LABORATORY_PATIENTS = {
    GET: {
        url: `${baseURL}/getPatients`,
        request: Actions.REQUEST_LABORATORY_PATIENTS,
        receive: Actions.RECEIVE_LABORATORY_PATIENTS
    }
};

export const RES_FOR_APPOINTMENTS = {
    GET: {
        url: `${baseURL}/getForAppointments`,
        request: Actions.REQUEST_FOR_APPOINTMENTS,
        receive: Actions.RECEIVE_FOR_APPOINTMENTS
    }
};

export const RES_WORK_REQUIRED = {
    GET: {
        url: `${baseURL}/getWorkRequired`,
        request: Actions.REQUEST_WORK_REQUIRED,
        receive: Actions.RECEIVE_WORK_REQUIRED
    },
    CLEAR: {action: Actions.CLEAR_SEARCH_FOR_WORK},
    SEARCH_REQUESTED: {
        url: `${baseURL}/searchRequestedByPatient`,
        request: Actions.REQUEST_SEARCH_FOR_WORK_REQUESTED,
        receive: Actions.RECEIVE_SEARCH_FOR_WORK_REQUESTED
    },
    SEARCH_REQUESTED_BY_BARCODE: {
        url: `${baseURL}/searchRequestedByBarcode`,
        request: Actions.REQUEST_SEARCH_FOR_WORK_REQUESTED,
        receive: Actions.RECEIVE_SEARCH_FOR_WORK_REQUESTED
    },
    SEARCH: {
        url: `${baseURL}/searchSentToLabByPatient`,
        request: Actions.REQUEST_SEARCH_FOR_WORK_REQUIRED,
        receive: Actions.RECEIVE_SEARCH_FOR_WORK_REQUIRED
    },
    SEARCH_BY_BARCODE: {
        url: `${baseURL}/searchSentToLabByBarcode`,
        request: Actions.REQUEST_SEARCH_FOR_WORK_REQUIRED,
        receive: Actions.RECEIVE_SEARCH_FOR_WORK_REQUIRED
    },
    GET_FOR_PATIENT: {
        url: `${baseURL}/workRequiredForPatient`,
        request: Actions.REQUEST_WORK_REQUIRED_FOR_PATIENT,
        receive: Actions.RECEIVE_WORK_REQUIRED_FOR_PATIENT
    },
    REQUEST: {
        url: `${baseURL}/requestWorkRequired`,
        request: Actions.REQUEST_WORK_REQUIRED_REQUEST,
        receive: Actions.RECEIVE_WORK_REQUIRED_REQUEST
    },
    UPDATE: {
        url: `${baseURL}/updateWorkRequired`,
        action: Actions.SAVE_WORK_REQUIRED,
    },
    LAB_NOTIFIED: {
        url: `${baseURL}/markWorkReceived`,
        request: Actions.REQUEST_LAB_NOTIFIED,
        receive: Actions.RECEIVE_LAB_NOTIFIED
    },
    LAB_PICK_UP: {
        url: `${baseURL}/markWorkReceived`,
        request: Actions.REQUEST_LAB_PICK_UP,
        receive: Actions.RECEIVE_LAB_PICK_UP
    },
    RECEIVED: {
        url: `${baseURL}/markWorkReceived`,
        request: Actions.REQUEST_MARK_WORK_RECEIVED,
        receive: Actions.RECEIVE_MARK_WORK_RECEIVED
    },
    APPROVED: {
        url: `${baseURL}/markWorkReceived`,
        request: Actions.REQUEST_MARK_WORK_RECEIVED,
        receive: Actions.RECEIVE_MARK_WORK_RECEIVED
    },
};

export const getAllLaboratories = () => {

    const searchURL = `${baseURL}/findShort?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL)
            .then(res => {

                dispatch({type: Actions.RECEIVE_LABORATORY_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_LABORATORY_SEARCH, payload: []});
    }
};

export const getLabPatients = ({url, request, receive}, id) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(res => {

                dispatch({type: receive, payload: res.data, id})
            });
        dispatch({type: request, payload: []});
    }
};

export const getLaboratory = ({url, request, receive}, id) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(res => {
                dispatch({type: receive, payload: res.data, id});
            });
        dispatch({type: request, payload: id});
    }
};

export const getLaboratoriesByName = (urlPattern) => {

    const searchURL = `${baseURL}/findShortByName?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_LABORATORY_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_LABORATORY_SEARCH, payload: []});
    }
};

export const getLaboratoriesByAddress = (urlPattern) => {

    const searchURL = `${baseURL}/findShortByAddress?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_LABORATORY_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_LABORATORY_SEARCH, payload: []});
    }
};

export const addLaboratory = ({url, request, receive}, laboratory) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, laboratory)
            .then((res) => {
                dispatch({type: receive, payload: res.data});
            });
        dispatch({type: request, payload: false});
    }
};

export const requestWorkRequired = ({url, request, receive}, workRequired) => {

    return (dispatch) => {

        const _workRequired = {...workRequired};

        _workRequired.id = -1000;
        _workRequired.returnDate = workRequired.returnDate ? moment(workRequired.returnDate).valueOf() : null;
        _workRequired.requestedOn = workRequired.requestedOn ? moment(workRequired.requestedOn).valueOf() : null;
        _workRequired.sentOn = workRequired.sentOn ? moment(workRequired.sentOn).valueOf() : null;
        _workRequired.receivedOn = workRequired.receivedOn ? moment(workRequired.receivedOn).valueOf() : null;
        _workRequired.placedOnHoldOn = workRequired.placedOnHoldOn ? moment(workRequired.placedOnHoldOn).valueOf() : null;
        _workRequired.cancelledOn = workRequired.cancelledOn ? moment(workRequired.cancelledOn).valueOf() : null;

        _workRequired.status = workRequired.status.name;

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, _workRequired)
            .then((res) => {
                dispatch({type: receive, payload: res.data, id: _workRequired.issuingAppointment.appointmentId});
            });
        dispatch({type: request, payload: false});
    }
};

export const updateWorkRequired = ({url, action}, workRequired) => {

    return (dispatch) => {

        const _workRequired = {...workRequired};

        _workRequired.returnDate = workRequired.returnDate ? moment(workRequired.returnDate).valueOf() : null;

        if (_workRequired.returnType === WORK_TYPE_FOR_APPOINTMENT.value) {
            _workRequired.returnAppointment = {appointmentId: workRequired.returnAppointment.appointmentId};
        } else {
            _workRequired.returnAppointment = null;
        }

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, _workRequired)
            .then((res) => {
                dispatch({type: action, payload: res.data});
            });
    }
};

export const markWorkStatus = ({url, request, receive}, labEntry) => {

    const _labEntry = {...labEntry};

    switch (_labEntry.status) {
        case WORK_STATUS_LAB_NOTIFIED.name:
            _labEntry.labNotifiedOn = _labEntry.labNotifiedOn ? moment(_labEntry.labNotifiedOn).valueOf() : moment(new Date()).valueOf();
            break;
        case WORK_STATUS_SENT_TO_LAB:
            _labEntry.sentOn = _labEntry.sentOn ? moment(_labEntry.sentOn).valueOf() : moment(new Date()).valueOf();
            break;
        case WORK_STATUS_RECEIVED_FROM_LAB.name:
            _labEntry.receivedOn = _labEntry.receivedOn ? moment(_labEntry.receivedOn).valueOf() : moment(new Date()).valueOf();
            break;
        case WORK_STATUS_APPROVED.name:
            _labEntry.approvedOn = _labEntry.approvedOn ? moment(_labEntry.approvedOn).valueOf() : moment(new Date()).valueOf();
            break;
        default:
            break;
    }

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, _labEntry)
            .then((res) => {
                dispatch({type: receive, payload: res.data, _labEntry});
            });
        dispatch({type: request, payload: false});
    }
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request});
    }
};
