import {JAW_TOOTH_COUNT} from "../../Charting/Model/Constants";

const TOOTH_HEIGHT = 150;
export const PERIO_MOUTH_HEIGHT = 2 * TOOTH_HEIGHT + 20;

const TOOTH_SIZE = 50;
const TOOTH_GAP = 5;

export const BLEEDER_SUPPORATER_UPPER_BUCCAL = 39.11;
export const BLEEDER_SUPPORATER_UPPER_LINGUAL = 97.11;
export const BLEEDER_SUPPORATER_LOWER_BUCCAL = 166.11;
export const BLEEDER_SUPPORATER_LOWER_LINGUAL = 224.11;

export const FURCATION_UPPER_BUCCAL = 4.0;
export const FURCATION_UPPER_LINGUAL = 62.0;
export const FURCATION_LOWER_BUCCAL = 131.0;
export const FURCATION_LOWER_LINGUAL = 189.0;

export const PERIO_MOUTH_WIDTH = JAW_TOOTH_COUNT * (TOOTH_SIZE + TOOTH_GAP) + TOOTH_GAP;

export const UPPER = 0;
export const LOWER = 1;
export const UPPER_BUCCAL = 0;
export const UPPER_LINGUAL = 1;
export const LOWER_BUCCAL = 2;
export const LOWER_LINGUAL = 3;

export const UPPER_BUCCAL_MOB = 0;
export const UPPER_BUCCAL_GR = 1;
export const UPPER_BUCCAL_PD = 2;
export const UPPER_LINGUAL_GR = 3;
export const UPPER_LINGUAL_PD = 4;
export const LOWER_BUCCAL_MOB = 5;
export const LOWER_BUCCAL_GR = 6;
export const LOWER_BUCCAL_PD = 7;
export const LOWER_LINGUAL_GR = 8;
export const LOWER_LINGUAL_PD = 9;

export const MOBILITY_TEXT = 'Mobility';
export const MOBILITY_TEXT_SEL = ' ** Mobility **';

export const RECESSION_TEXT = 'Gingival Recession';
export const RECESSION_TEXT_SEL = ' ** Gingival Recession **';

export const PROBING_TEXT = 'Probing Depth';
export const PROBING_TEXT_SEL = ' ** Probing Depth **';

export const TEXTS = [
    {id: UPPER_BUCCAL_MOB, unSel: MOBILITY_TEXT, sel: MOBILITY_TEXT_SEL},
    {id: UPPER_BUCCAL_GR, unSel: RECESSION_TEXT, sel: RECESSION_TEXT_SEL},
    {id: UPPER_BUCCAL_PD, unSel: PROBING_TEXT, sel: PROBING_TEXT_SEL},
    {id: UPPER_LINGUAL_GR, unSel: RECESSION_TEXT, sel: RECESSION_TEXT_SEL},
    {id: UPPER_LINGUAL_PD, unSel: PROBING_TEXT, sel: PROBING_TEXT_SEL},
    {id: LOWER_BUCCAL_MOB, unSel: MOBILITY_TEXT, sel: MOBILITY_TEXT_SEL},
    {id: LOWER_BUCCAL_GR, unSel: RECESSION_TEXT, sel: RECESSION_TEXT_SEL},
    {id: LOWER_BUCCAL_PD, unSel: PROBING_TEXT, sel: PROBING_TEXT_SEL},
    {id: LOWER_LINGUAL_GR, unSel: RECESSION_TEXT, sel: RECESSION_TEXT_SEL},
    {id: LOWER_LINGUAL_PD, unSel: PROBING_TEXT, sel: PROBING_TEXT_SEL},
];

export const PERIO_KEYS = ['numeric', 'f', 'F', 's', 'S', 'b', 'B', 'right', 'left', 'up', 'down', 'pageup', 'pagedown', 'home', 'end'];

export const numberStyle = {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontSize: '4.23333311px',
    lineHeight: '125%',
    fontFamily: 'Courier New',
    InkscapeFontSpecification: 'Courier New',
    letterSpacing: '0px',
    wordSpacing: '0px',
    fill: '#000000',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: '0.3175px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeOpacity: 1
};

export const selectedNumberStyle = {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontSize: '4.23333311px',
    lineHeight: '125%',
    fontFamily: '"Courier New"',
    InkscapeFontSpecification: '"Courier New"',
    letterSpacing: '0px',
    wordSpacing: '0px',
    fill: '#289bff',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: '0.3175px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeOpacity: 1,
    textDecoration: 'underline',
};

export const graphLineStyle = {
    display: 'inline',
    fill: 'none',
    fillRule: 'evenodd',
    stroke: '#000000',
    strokeWidth: '0.3175px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeOpacity: 0.1
};

export const grLineStyle = {
    display: 'inline',
    fill: 'none',
    fillRule: 'evenodd',
    stroke: '#ff0000',
    strokeWidth: '0.5px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeOpacity: 1
};

export const pdLineStyle = {
    display: 'inline',
    fill: 'none',
    fillRule: 'evenodd',
    stroke: '#0000ff',
    strokeWidth: '0.5px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeOpacity: 1
};

export const mouthOutlineStyle = {
    opacity: 1,
    fill: '#fff0f4',
    fillOpacity: 1,
    stroke: '#000000',
    strokeWidth: '0.3175',
    strokeOpacity: '0.25098039'
};

export const furcationMarkerStyle = {
    opacity: 1,
    fill: '#ffaaaa',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: 1,
    strokeOpacity: 1
};

export const bleederMarkerStyle = {
    opacity: 1,
    fill: '#ff1d13',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: 1,
    strokeOpacity: 1
};

export const superatorMarkerStyle = {
    opacity: 1,
    fill: '#c9ff22',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: 1,
    strokeOpacity: 1
};

export const labelTextStyle4 = {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontSize: '7.76111126px',
    fontFamily: 'Arial',
    InkscapeFontSpecification: 'Arial',
    strokeWidth: '0.26458332px'
};

export const labelTextStyle3 = {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontFamily: 'Arial',
    InkscapeFontSpecification: 'Arial',
    strokeWidth: '0.26458332px'
};

export const labelTextStyle3red = {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontFamily: 'Arial',
    InkscapeFontSpecification: 'Arial',
    fill: '#ff0000',
    strokeWidth: '0.26458332px'
};

export const labelTextStyle3blue = {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontFamily: 'Arial',
    InkscapeFontSpecification: 'Arial',
    fill: '#0000ff',
    strokeWidth: '0.26458332px'
};

export const labelTextStyle1 = {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontSize: '4.23333311px',
    lineHeight: '125%',
    fontFamily: '"Courier New"',
    InkscapeFontSpecification: '"Courier New"',
    letterSpacing: '0px',
    wordSpacing: '0px',
    fill: '#000000',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: '0.26458332px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeOpacity: 1
};

export const READ_ONLY_MODE = 0.5;
export const EDIT_MODE = 1;

export const singleFurcationIndexes = [0, 1, 2, 13, 14, 15];
export const doubleFurcationIndexes = [0, 1, 2, 4, 11, 13, 14, 15];

export const furcationLevelNone = 0;

export const furcationLevel0 = 1;
export const furcationStyleLevel0 = {
    display: 'inline',
    opacity: 1,
    fill: '#16ff87',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: 0.3175,
    strokeOpacity: 1
};

export const furcationLevel1 = 2;
export const furcationStyleLevel1 = {
    display: 'inline',
    opacity: 1,
    fill: '#ffac58',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: 0.3175,
    strokeOpacity: 1
};

export const furcationLevel2 = 3;
export const furcationStyleLevel2 = {
    display: 'inline',
    opacity: 1,
    fill: '#ff343f',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: 0.3175,
    strokeOpacity: 1
};

export const furcationStyles = [null, furcationStyleLevel0, furcationStyleLevel1, furcationStyleLevel2];
export const furationValues = [furcationLevel0, furcationLevel1, furcationLevel2, furcationLevelNone];
