import _ from 'lodash';
import {
    SM_APPTreatmentPlan,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_APPOINTMENT_NOTES,
    SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY,
    SM_PATIENT_LAB_HISTORY,
    SM_PAYMENT_PLAN,
    SM_TreatmentPlan,
    SM_TreatmentPlanHistory,
    SM_TreatmentPlaning
} from "../actions/stateManagement";
import {
    RES_PAYMENT_PLAN_BY_TP_ID,
    RES_TREATMENT_PLAN_BY_APP_ID,
    RES_TREATMENT_PLAN_BY_ID,
    RES_TREATMENT_PLAN_HISTORY,
    RES_TREATMENT_PLANNING
} from "../actions/treatmentPlanning";
import * as Actions from "../actions";
import {SM_CLIENT_TREATMENT_PLAN_PDF} from "../components/Client/Constants";
import {UP_DOCUMENT_USE_AS_PHOTO, UP_TREATMENTPLANS_COPY} from "../Constants";

const initialState = {
    paymentPlanAddtionTPId: undefined,
};

export const treatmentPlanning = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            // saving from an appointment, using the plan id as the index, suffixed with _APP
            case RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action: {

                const treatmentPlan = action.payload;

                treatmentPlan.appointments.forEach(appointment => {

                    appointment.checkedInTime = new Date('1970-01-01T' + appointment.checkedInTime + 'Z');
                    appointment.inSurgeryTime = new Date('1970-01-01T' + appointment.inSurgeryTime + 'Z');
                    appointment.leftSurgeryTime = new Date('1970-01-01T' + appointment.leftSurgeryTime + 'Z');
                });

                return {
                    ...state,
                    [`${SM_APPTreatmentPlan.id}_${action.id}`]: treatmentPlan,
                    [`${SM_APPTreatmentPlan.loaded}_${action.id}`]: true,
                }

            }

            // loading from treatment planning, using the plan id as the index
            case RES_TREATMENT_PLAN_BY_ID.SAVE.action: {

                const treatmentPlan = action.payload;

                treatmentPlan.appointments.forEach(appointment => {

                    appointment.checkedInTime = new Date('1970-01-01T' + appointment.checkedInTime + 'Z');
                    appointment.inSurgeryTime = new Date('1970-01-01T' + appointment.inSurgeryTime + 'Z');
                    appointment.leftSurgeryTime = new Date('1970-01-01T' + appointment.leftSurgeryTime + 'Z');
                });

                const newState = {
                    ...state,
                    [`${SM_TreatmentPlan.id}_${action.id}`]: treatmentPlan,
                    [`${SM_TreatmentPlan.loaded}_${action.id}`]: true,
                };
                return newState;
            }

            // loading from an appointment, using the plan id as the index, suffixed with _APP
            case RES_TREATMENT_PLAN_BY_APP_ID.GET.receive: {

                if (action.payload) {

                    const treatmentPlan = action.payload;

                    treatmentPlan.appointments.forEach(appointment => {

                        if (appointment.checkedInTime !== null) {
                            appointment.checkedInTime = new Date('1970-01-01T' + appointment.checkedInTime + 'Z');
                        }
                        if (appointment.inSurgeryTime !== null) {
                            appointment.inSurgeryTime = new Date('1970-01-01T' + appointment.inSurgeryTime + 'Z')
                        }
                        if (appointment.leftSurgeryTime != null) {
                            appointment.leftSurgeryTime = new Date('1970-01-01T' + appointment.leftSurgeryTime + 'Z')
                        }
                    });

                    const newState = {...state};

                    _.set(newState, `${SM_APPTreatmentPlan.id}_${action.id}`, treatmentPlan);
                    _.set(newState, `${SM_APPTreatmentPlan.loaded}_${action.id}`, true);

                    return newState;
                } else
                    return state;
            }

            // loading from treatment planning, using the plan id as the index
            case RES_TREATMENT_PLAN_BY_ID.GET.receive:
            case RES_TREATMENT_PLAN_BY_ID.ADD.action: {

                const treatmentPlan = action.payload;

                treatmentPlan.appointments.forEach(appointment => {

                    appointment.checkedInTime = new Date('1970-01-01T' + appointment.checkedInTime + 'Z');
                    appointment.inSurgeryTime = new Date('1970-01-01T' + appointment.inSurgeryTime + 'Z');
                    appointment.leftSurgeryTime = new Date('1970-01-01T' + appointment.leftSurgeryTime + 'Z');
                });

                const newState = {...state};

                _.set(newState, `${SM_TreatmentPlan.id}_${treatmentPlan.id}`, treatmentPlan);
                _.set(newState, `${SM_TreatmentPlan.loaded}_${treatmentPlan.id}`, true);

                newState.paymentPlanAddtionTPId = treatmentPlan.id;
                return newState;
            }

            // loading from task, using the plan id as the index
            case RES_TREATMENT_PLAN_BY_ID.GET_FOR_TASK.receive: {

                const treatmentPlan = action.payload;
                const taskId = action.taskId;

                const newState = {...state};

                _.set(newState, `${SM_TreatmentPlan.id}_${taskId}`, treatmentPlan);
                _.set(newState, `${SM_TreatmentPlan.loaded}_${taskId}`, true);

                newState.paymentPlanAddtionTPId = treatmentPlan.id;
                return newState;
            }

            case RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action: {

                // this is just for appointment pages
                let clearTPState = {...state};

                delete clearTPState[`${SM_APPTreatmentPlan.id}_${action.id}`];
                delete clearTPState[`${SM_APPTreatmentPlan.loaded}_${action.id}`];
                delete clearTPState[`${SM_APPTreatmentPlan.id}_${action.id}_CH`];
                delete clearTPState[`${SM_APPTreatmentPlan.loaded}_${action.id}_CH`];
                delete clearTPState[`${SM_PATIENT_APPOINTMENT_NOTES.loaded}_${action.id}`];
                delete clearTPState[`${SM_PATIENT_APPOINTMENT_NOTES.id}_${action.id}`];
                delete clearTPState[`${SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY.loaded}_${action.id}`];
                delete clearTPState[`${SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY.id}_${action.id}`];
                delete clearTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.loaded}_${action.id}`];
                delete clearTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.id}_${action.id}`];
                delete clearTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.loaded}_${action.id}`];
                delete clearTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.id}_${action.id}`];
                delete clearTPState[`${SM_PATIENT_LAB_HISTORY.loaded}_${action.id}`];
                delete clearTPState[`${SM_PATIENT_LAB_HISTORY.id}_${action.id}`];

                return clearTPState;
            }

            ////////////////////////////////////////////////// Treatment Planning

            case RES_TREATMENT_PLAN_HISTORY.GET.receive:

                return {
                    ...state,
                    [`${SM_TreatmentPlanHistory.loaded}_${action.id}`]: true,
                    [`${SM_TreatmentPlanHistory.id}_${action.id}`]: action.payload,
                };

            case RES_TREATMENT_PLANNING.CLEAR.action: {

                // this is just for treatment planing pages
                let clearTPState = {...state};

                let plans = _.get(clearTPState, `${SM_TreatmentPlanHistory.id}_${action.smRef}.plans`);

                if (plans !== undefined) {
                    // clear all plan entries based on the loaded history
                    plans.forEach(plan => {
                        delete clearTPState[`${SM_TreatmentPlan.id}_${plan.id}`];
                        delete clearTPState[`${SM_TreatmentPlan.loaded}_${plan.id}`];
                        delete clearTPState[`${SM_TreatmentPlan.id}_${plan.id}_CH`];
                        delete clearTPState[`${SM_TreatmentPlan.loaded}_${plan.id}_CH`];
                    });
                }
                delete clearTPState[`${SM_TreatmentPlanHistory.loaded}_${action.smRef}`];
                delete clearTPState[`${SM_TreatmentPlanHistory.id}_${action.smRef}`];

                delete clearTPState[`${SM_TreatmentPlaning.loaded}_${action.smRef}`];
                delete clearTPState[`${SM_TreatmentPlaning.id}_${action.smRef}`];

                return clearTPState;
            }

            case RES_PAYMENT_PLAN_BY_TP_ID.SAVE.action:
            case RES_PAYMENT_PLAN_BY_TP_ID.GET.receive:

                return {
                    ...state,
                    [`${SM_PAYMENT_PLAN.id}_${action.id}`]: action.payload,
                    [`${SM_PAYMENT_PLAN.loaded}_${action.id}`]: true,
                };

            case RES_TREATMENT_PLAN_BY_ID.DELETE.action:

                let clearTPState = {...state};

                let plans = _.get(clearTPState, `${SM_TreatmentPlanHistory.id}_${action.patientId}.plans`);
                let psr = _.get(clearTPState, `${SM_TreatmentPlanHistory.id}_${action.patientId}.psr`);
                const history = _.filter(plans, plan => plan.id !== action.planId);

                return {
                    ...state,
                    [`${SM_TreatmentPlanHistory.loaded}_${action.patientId}`]: true,
                    [`${SM_TreatmentPlanHistory.id}_${action.patientId}`]: {plans: history, psr},
                };

            case RES_TREATMENT_PLAN_BY_ID.COPY.action: {

                let copyTPState = {...state};

                let plans = _.get(copyTPState, `${SM_TreatmentPlanHistory.id}_${action.patientId}.plans`);
                let psr = _.get(copyTPState, `${SM_TreatmentPlanHistory.id}_${action.patientId}.psr`);
                plans.push(action.payload);

                return {
                    ...state,
                    [`${SM_TreatmentPlanHistory.loaded}_${action.patientId}`]: true,
                    [`${SM_TreatmentPlanHistory.id}_${action.patientId}`]: {plans, psr},
                }
            }

            case RES_TREATMENT_PLAN_BY_ID.PDF.receive: {

                const loadedIndex = `${SM_CLIENT_TREATMENT_PLAN_PDF.loaded}_${action.id}.pdf`;
                const index = `${SM_CLIENT_TREATMENT_PLAN_PDF.id}_${action.id}.pdf`;

                return {
                    ...state,
                    [loadedIndex]: true,
                    [index]: action.payload,
                }
            }
            case RES_TREATMENT_PLAN_BY_ID.CLEAR_PDF.action: {

                let clearTPPDFState = {...state};

                const loadedIndex = `${SM_CLIENT_TREATMENT_PLAN_PDF.loaded}_${action.payload.smRef}.pdf`;
                const index = `${SM_CLIENT_TREATMENT_PLAN_PDF.id}_${action.payload.smRef}.pdf`;

                delete clearTPPDFState[loadedIndex];
                delete clearTPPDFState[index];

                return clearTPPDFState;
            }

            case Actions.WSM_UPDATES:

                switch (action.payload.function) {

                    case UP_DOCUMENT_USE_AS_PHOTO: {
                        const treatmentPlan = {...state[`${SM_APPTreatmentPlan.id}_${action.payload.content[2]}`]};

                        if (treatmentPlan.patient) {
                            treatmentPlan.patient.patientImageFileName = action.payload.content[1];

                            return {
                                ...state,
                                [`${SM_APPTreatmentPlan.id}_${action.payload.content[2]}`]: treatmentPlan,
                            }
                        } else {
                            return state;
                        }
                    }
                    default:
                        return state;
                }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};