import {ToothSurface} from "../Parts/ToothSurface";
import {Inlay} from "../Model/Constants";
import _ from "lodash";
import {_getCommandContextMenuItems} from "../Parts/ChartingUtils";
import {ChartItemBase} from "./ChartItemBase";

export class ComInlay extends ChartItemBase {

    constructor() {
        super();

        this.state = {};

        this.target = null;
        this.mouth = null;
        this.adultTeeth = null;
        this.tooth = null;
        this.material = null;

        this.setTarget = this.setTarget.bind(this);
        this.executeBaseOption = this.executeBaseOption.bind(this);
        this.executeTreatmentOption = this.executeTreatmentOption.bind(this);
        this.getCommandContextMenuItems = this.getCommandContextMenuItems.bind(this);
    };

    toString() {
        return Inlay;
    }

    canExecute(target) {

        try {

            return (target instanceof ToothSurface);
        } catch (e) {
            return false;
        }
    }

    executeBase(mouth, target) {
    }

    executeTreatment(mouth, target) {
    }

    executeBaseOption(mouth, target, material) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, Inlay, true);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;
        entry.material = material;
        entry.surfaces = _.filter(target.surfaces, (surface) => surface.selected);
        entry.surfaceList = _.map(_.filter(target.surfaces, (surface) => surface.selected), item => item.surface.ordinal);

        mouth.props.addChartEntry(entry);

        mouth.clearSelection();
    }

    executeTreatmentOption(mouth, target, material) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, Inlay, false);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;
        entry.material = material;
        entry.surfaces = _.filter(target.surfaces, (surface) => surface.selected);
        entry.surfaceList = _.map(_.filter(target.surfaces, (surface) => surface.selected), item => item.surface.ordinal);

        mouth.props.addTreatmentHandler(entry);

        mouth.clearSelection();
    }

    setTarget(target) {
        this.target = target;
    }

    getCommandContextMenuItems(mouth) {
        return _getCommandContextMenuItems(mouth, this)
    }

    clear() {
    }
}
