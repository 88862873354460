import {
    AdultIncisor,
    AdultMolar,
    AdultPremolar,
    AdultPremolarBicusp,
    BondedBridge,
    BridgeOnImplant,
    ChartNumbering_UK,
    ChartNumbering_US,
    CompleteJaw,
    CONDNone,
    Filling,
    FixedBondedOrthodonticRetainer,
    Inlay,
    JawLower,
    JawUpper,
    Left,
    LLIncisor,
    LLMolar,
    LLPreMolar,
    LRIncisor,
    LRMolar,
    LRPreMolar,
    MatNone,
    Surfaces,
    SurfNone,
    TemporaryFilling,
    ULIncisor,
    ULMolar,
    ULPreMolar,
    ULPreMolarBicusp,
    URIncisor,
    URMolar,
    URPreMolar,
    URPreMolarBicusp
} from "../Model/Constants";
import {CH_INVOICE, CH_RECEIPTED, CH_SAVED, CH_SCHEDULED, INV_PREDUE, INV_SCHEDULE} from "../../../Constants";
import _ from "lodash";
import {Denture} from "../Parts/Denture";

export const toothEquals = (target1, target2) => {

    return target1.pos.perm === target2.pos.perm && target1.jaw === target2.jaw;
};

export const positionIndex = (entry) => {

    return CompleteJaw.find(tooth => entry.position === tooth.perm);
};

export const getPosition = (target) => {
    const match = CompleteJaw.find((position) => position.perm === target);
    return match.perm;
};

export const getNumber = (jaw, position) => {

    // FIX-THIS
    let scheme = ChartNumbering_UK;

    let name = '  ';

    switch (scheme) {
        case ChartNumbering_UK:

            if (isNaN(position)) {
                name = position;
            } else {
                name = _.find(CompleteJaw, tooth => tooth.offset === position).perm;
            }
            return (jaw === JawUpper ? "U" : "L") + name;

        case ChartNumbering_US:

            if (jaw === JawUpper)
                return "" + (position + 1);
            else
                return "" + (32 - position);

        default:

            if (jaw === JawUpper && position < 8)
                return "1" + name.charAt(1);
            else if (jaw === JawUpper)
                return "2" + name.charAt(1);
            else if (position > 7)
                return "3" + name.charAt(1);
            else
                return "4" + name.charAt(1);
    }
};

export const getChartedItemDetails = (entry) => {

    let content = "";

    switch (entry.item.code) {

        case BondedBridge :
        case Denture :
        case BridgeOnImplant :
        case FixedBondedOrthodonticRetainer :
            if (entry.spanEnd > entry.spanStart)
                content += (getNumber(entry.jaw, entry.spanStart) + " - " + getNumber(entry.jaw, entry.spanEnd));
            else
                content += (getNumber(entry.jaw, entry.spanEnd) + " - " + getNumber(entry.jaw, entry.spanStart));
            break;
        case Filling :
        case TemporaryFilling :
        case Inlay:

            content += (getNumber(entry.jaw, getPosition(entry.position)));

            let firstSurface = true;

            entry.surfaces = [];

            try {
                Surfaces.forEach(surface => {
                    entry.surfaceList.forEach(target => {
                        if (surface.ordinal === target) {
                            entry.surfaces.push(surface);
                        }
                    })
                });
            } catch (error) {
            }

            entry.surfaces.forEach((surface) => {

                if (firstSurface) {
                    if (!(surface.type === SurfNone.type))
                        content += (", (" + surface.name);
                    firstSurface = false;
                } else {
                    if (!(surface.type === SurfNone.type))
                        content += (", " + surface.name);
                }
            });

            if (!firstSurface)
                content += (")");
            break;
        default:
            content += (getNumber(entry.jaw, getPosition(entry.position)));
            break;
    }

    content += ((entry.material == null || entry.material.code === MatNone)) ? "" : ", " + entry.material.name;
    content += ((entry.condition == null || entry.condition.code === CONDNone)) ? "" : ", " + entry.condition.name;

    return content;

};

export const getChargeTransactionStatus = (charge) => {

    if (charge.invoice === null) {
        switch (charge.status) {
            case CH_SAVED :
                return "Saved";
            case CH_SCHEDULED :
                return "Scheduled";
            case CH_INVOICE :
                return "Not charged";
            case CH_RECEIPTED :
                return charge.receipt === null ? '-?-' : charge.receipt.receiptString;
            default :
                return "Unknown status";
        }
    } else {

        switch (charge.invoice.status) {
            case INV_SCHEDULE :
                return "Scheduled";
            case INV_PREDUE :
                return "Issuing";
            default :
                return charge.invoice.invoiceString;
        }
    }
};

export const getRandomKey = () => {
    return Math.random() * (1000 - 2000) + 1000;
};

export const addSurface = (data) => {

    let left = false;
    let surfaces = [];

    switch (data.jaw) {
        case JawLower :
            switch (data.type) {
                case AdultMolar : {
                    left = _.includes(Left, data.pos.offset);
                    const molar = left ? LLMolar : LRMolar;
                    molar.forEach((surface) => {
                        surfaces.push({left, surface, selected: false});
                    })
                }
                    break;
                case AdultPremolar : {
                    left = _.includes(Left, data.pos.offset);
                    const preMolar = left ? LLPreMolar : LRPreMolar;
                    preMolar.forEach((surface) => {
                        surfaces.push({left, surface, selected: false});
                    })
                }
                    break;
                case AdultIncisor : {
                    left = _.includes(Left, data.pos.offset);
                    const incisor = left ? LLIncisor : LRIncisor;
                    incisor.forEach((surface) => {
                        surfaces.push({left, surface, selected: false});
                    })
                }
                    break;
                default:
                    break;
            }
            break;
        case JawUpper :
            switch (data.type) {
                case AdultMolar :
                    left = _.includes(Left, data.pos.offset);
                    const molar = left ? ULMolar : URMolar;
                    molar.forEach((surface) => {
                        surfaces.push({left, surface, selected: false});
                    });
                    break;
                case AdultPremolar :
                    left = _.includes(Left, data.pos.offset);
                    const preMolar = left ? ULPreMolar : URPreMolar;
                    preMolar.forEach((surface) => {
                        surfaces.push({left, surface, selected: false});
                    });
                    break;
                case AdultPremolarBicusp :
                    left = _.includes(Left, data.pos.offset);
                    const preMolarBicusp = left ? ULPreMolarBicusp : URPreMolarBicusp;
                    preMolarBicusp.forEach((surface) => {
                        surfaces.push({left, surface, selected: false});
                    });
                    break;
                case AdultIncisor :
                    left = _.includes(Left, data.pos.offset);
                    const incisor = left ? ULIncisor : URIncisor;
                    incisor.forEach((surface) => {
                        surfaces.push({left, surface, selected: false});
                    });
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return surfaces;
};