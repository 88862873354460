import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {categoryDropDown, spinner} from "./OnChangeUtils";

export const IOTN = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header='IOTN'>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>DHC</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'iotnDhc', 0, 100, readOnly)}
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'IOTNDHCSub', 'iotnDhcSubACE', readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;AC</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {spinner(props, 'iotnAc', 0, 100, readOnly)}
                </div>
            </div>
        </Panel>
    );
};
