import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {InputText} from 'primereact/components/inputtext/InputText';
import {SMSBalanceText} from "../FixedItems/PatientDetails/Constants";

export const SMSBalance = (props) => {

    return (
        <Panel header={SMSBalanceText} style={{paddingTop: '5px'}}>
            <div className="p-fluid">
                <InputText value={props.balance} style={{fontSize: 'x-large', textAlign: 'right'}} disabled={true}/>
            </div>
        </Panel>
    )
};
