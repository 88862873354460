import React from 'react';
import {BaseComponent} from "../../../BaseComponent";
import {calendarWithNull, inputText, spinner} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {IOTN} from "../../../PatientDynamicItems/IOTN";

export default class OrthoDetailsSection extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    render() {

        const {
            lastAppointment,
            nextAppointment,
            ftaCount,
            visitCount,
            cancelCount,
            iotnDhc,
            iotnAc,
            preTreatmentParScore,
            postTreatmentParScore,
            patientImageFileName,
            unitsUOA,
            allocatedUOA,
        } = this.props.treatmentPlan.patient;

        const patient = {
            lastAppointment: dateTemplate(lastAppointment),
            nextAppointment: dateTemplate(nextAppointment),
            ftaCount,
            visitCount,
            cancelCount,
            iotnDhc,
            iotnAc,
            preTreatmentParScore,
            postTreatmentParScore,
            patientImageFileName,
            unitsUOA,
            allocatedUOA
        };

        const patientProps = {
            onChange: this.props.onChange,
            target: 'patient',
            patient: patient,
            categories: this.props.categories,
        };

        const {
            referralDate,
            treatmentStartDate,
            treatmentEndDate,
        } = this.props.treatmentPlan;

        const treatmentPlan = {
            referralDate: dateTemplate(referralDate),
            treatmentStartDate,
            treatmentEndDate,
        };

        const treatmentPlanProps = {
            onChange: this.props.onChange,
            target: 'treatmentPlan',
            treatmentPlan: treatmentPlan,
        };

        return (
            <div className="p-grid p-fluid p-col-12">
                <div className="p-grid p-fluid form-group p-col-6">

                    <div className="p-col-2">
                        <label htmlFor="title">Referral Date</label>
                    </div>
                    <div className="p-col-4">
                        {inputText(treatmentPlanProps, 'referralDate', '', true, false)}
                    </div>
                    <div className="p-col-6"/>

                    <div className="p-col-2">
                        <label htmlFor="title">Treatment Start</label>
                    </div>
                    <div className="p-col-4">
                        {calendarWithNull(treatmentPlanProps, 'treatmentStartDate')}
                    </div>

                    <div className="p-col-2">
                        <label htmlFor="title">Treatment End</label>
                    </div>
                    <div className="p-col-4">
                        {calendarWithNull(treatmentPlanProps, 'treatmentEndDate')}
                    </div>

                    <div className="p-col-2">
                        <label htmlFor="title">Last Appointment</label>
                    </div>
                    <div className="p-col-4">
                        {inputText(patientProps, 'lastAppointment', '', true, false)}
                    </div>

                    <div className="p-col-2">
                        <label htmlFor="title">Next Appointment</label>
                    </div>
                    <div className="p-col-4">
                        {inputText(patientProps, 'nextAppointment', '', true, false)}
                    </div>

                    <div className="p-col-2">
                        <label htmlFor="title">FTA Count</label>
                    </div>
                    <div className="p-col-2">
                        {inputText(patientProps, 'ftaCount', '', true, false)}
                    </div>

                    <div className="p-col-2">
                        <label htmlFor="title">Visit Count</label>
                    </div>
                    <div className="p-col-2">
                        {inputText(patientProps, 'visitCount', '', true, false)}
                    </div>

                    <div className="p-col-2">
                        <label htmlFor="title">Cancel Count</label>
                    </div>
                    <div className="p-col-2">
                        {inputText(patientProps, 'cancelCount', '', true, false)}
                    </div>
                    <div className="p-col-12"><br/></div>
                    <div className="p-col-12">&nbsp;</div>
                </div>
                <div className="p-grid p-fluid form-group p-col-6">

                    <div className="p-col-12" id="prePar">
                        <IOTN target='patient'
                              onChange={this.props.onChange}
                              categories={this.props.categories}
                              readOnly={true}
                        />
                    </div>

                    <div className="p-col-2">Pre-Treatment Par</div>
                    <div className="p-col-4" id="prePar">
                        {spinner(patientProps, 'preTreatmentParScore', 0, 100, false)}
                    </div>
                    <div className="p-col-2">Post-Treatment Par</div>
                    <div className="p-col-4" id="prePar">
                        {spinner(patientProps, 'postTreatmentParScore', 0, 100, false)}
                    </div>

                    <div className="p-col-2">
                        <label htmlFor="title">Claimed Units</label>
                    </div>
                    <div className="p-col-2">
                        {inputText(patientProps, 'unitsUOA', '', true, false)}
                    </div>

                    <div className="p-col-2">
                        <label htmlFor="title">Allocated Units</label>
                    </div>
                    <div className="p-col-2">
                        {inputText(patientProps, 'allocatedUOA', '', true, false)}
                    </div>
                    <div className="p-col-12"><br/></div>
                </div>
            </div>
        )
    }
}
