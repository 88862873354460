import _ from 'lodash';
import {
    AdultIncisor,
    AdultMolar, AdultPremolar, AdultPremolarBicusp,
    JawLower, JawUpper, SurfBuccal,
    SurfBuccal1,
    SurfBuccal2, SurfDistal, SurfIncisal, SurfLabial, SurfLingual,
    SurfLingual1,
    SurfLingual2,
    SurfMesial, SurfOcclusal, SurfOcclusal1, SurfOcclusal2, SurfPalatal, SurfPalatal1, SurfPalatal2
} from "../Model/Constants";

const _includes = (surfaces, target) => {
    return _.findIndex(surfaces, (surface) => surface.type === target.type) !== -1;     
};

export const contiguous = (tooth, surface) => {

    let contig = false;
    let left = surface.left;

    const surfaces = [];

    tooth.surfaces.forEach((surface)=>{
        if (surface.selected)
            surfaces.push(surface.surface);
    });

    if (surfaces.length===0)
        return true;

    switch (tooth.jaw) {
        case JawLower:
            switch (tooth.type) {
                case AdultMolar:
                    switch (surface.type) {
                        case SurfLingual1.type:
                            if (left)
                                contig = _includes(surfaces, SurfLingual2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfMesial);
                            else
                                contig = _includes(surfaces, SurfLingual2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfDistal);
                            break;
                        case SurfLingual2.type:
                            if (left)
                                contig = _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfLingual1) || _includes(surfaces, SurfDistal);
                            else
                                contig = _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfLingual1) || _includes(surfaces, SurfMesial);
                            break;
                        case SurfMesial.type:
                            if (left)
                                contig = _includes(surfaces, SurfLingual1) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfBuccal1);
                            else
                                contig = _includes(surfaces, SurfLingual2) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfBuccal2);
                            break;
                        case SurfBuccal1.type:
                            if (left)
                                contig = _includes(surfaces, SurfBuccal2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfMesial);
                            else
                                contig = _includes(surfaces, SurfBuccal2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfDistal);
                            break;
                        case SurfBuccal2.type:
                            if (left)
                                contig = _includes(surfaces, SurfBuccal1) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfDistal);
                            else
                                contig = _includes(surfaces, SurfBuccal1) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfMesial);
                            break;
                        case SurfDistal.type:
                            if (left)
                                contig = _includes(surfaces, SurfLingual2) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfBuccal2);
                            else
                                contig = _includes(surfaces, SurfLingual1) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfBuccal1);
                            break;
                        case SurfOcclusal1.type:
                            if (left)
                                contig = _includes(surfaces, SurfLingual1) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfBuccal1) || _includes(surfaces, SurfMesial);
                            else
                                contig = _includes(surfaces, SurfLingual1) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfBuccal1) || _includes(surfaces, SurfDistal);
                            break;
                        case SurfOcclusal2.type:
                            if (left)
                                contig = _includes(surfaces, SurfLingual2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfBuccal2) || _includes(surfaces, SurfDistal);
                            else
                                contig = _includes(surfaces, SurfLingual2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfBuccal2) || _includes(surfaces, SurfMesial);
                            break;
                        default:
                            break;
                    }
                    break;
                case AdultPremolar:
                    switch (surface.type) {
                        case SurfLingual.type:
                        case SurfBuccal.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfOcclusal) || _includes(surfaces, SurfDistal);
                            break;
                        case SurfMesial.type:
                        case SurfDistal.type:
                            contig = _includes(surfaces, SurfBuccal) || _includes(surfaces, SurfOcclusal) || _includes(surfaces, SurfLingual);
                            break;
                        case SurfOcclusal.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfPalatal) || _includes(surfaces, SurfBuccal);
                            break;
                        default:
                            break;
                    }
                    break;
                case AdultIncisor:
                    switch (surface.type) {
                        case SurfLingual.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfIncisal);
                            break;
                        case SurfLabial.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfIncisal);
                            break;
                        case SurfMesial.type:
                        case SurfDistal.type:
                            contig = _includes(surfaces, SurfLabial) || _includes(surfaces, SurfLingual) || _includes(surfaces, SurfIncisal);
                            break;
                        case SurfIncisal.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfLabial) || _includes(surfaces, SurfLingual);
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            break;
        case JawUpper:
            switch (tooth.type) {
                case AdultMolar:
                    switch (surface.type) {
                        case SurfBuccal1.type:
                            if (left)
                                contig = _includes(surfaces, SurfBuccal2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfMesial);
                            else
                                contig = _includes(surfaces, SurfBuccal2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfDistal);
                            break;
                        case SurfBuccal2.type:
                            if (left)
                                contig = _includes(surfaces, SurfBuccal1) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfDistal);
                            else
                                contig = _includes(surfaces, SurfBuccal1) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfMesial);
                            break;
                        case SurfMesial.type:
                            if (left)
                                contig = _includes(surfaces, SurfBuccal1) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfPalatal1);
                            else
                                contig = _includes(surfaces, SurfBuccal2) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfPalatal2);
                            break;
                        case SurfPalatal2.type:
                            if (left)
                                contig = _includes(surfaces, SurfDistal) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfPalatal1);
                            else
                                contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfPalatal1);
                            break;
                        case SurfPalatal1.type:
                            if (left)
                                contig = _includes(surfaces, SurfPalatal2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfMesial);
                            else
                                contig = _includes(surfaces, SurfPalatal2) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfDistal);
                            break;
                        case SurfDistal.type:
                            if (left)
                                contig = _includes(surfaces, SurfPalatal2) || _includes(surfaces, SurfOcclusal2) || _includes(surfaces, SurfBuccal2);
                            else
                                contig = _includes(surfaces, SurfPalatal1) || _includes(surfaces, SurfOcclusal1) || _includes(surfaces, SurfBuccal1);
                            break;
                        case SurfOcclusal1.type:
                            if (left)
                                contig = _includes(surfaces, SurfBuccal1) || _includes(surfaces, SurfMesial) || _includes(surfaces, SurfPalatal1) || _includes(surfaces, SurfOcclusal2);
                            else
                                contig = _includes(surfaces, SurfBuccal1) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfPalatal1) || _includes(surfaces, SurfOcclusal2);
                            break;
                        case SurfOcclusal2.type:
                            if (left)
                                contig = _includes(surfaces, SurfBuccal2) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfPalatal2) || _includes(surfaces, SurfOcclusal1);
                            else
                                contig = _includes(surfaces, SurfBuccal2) || _includes(surfaces, SurfMesial) || _includes(surfaces, SurfPalatal2) || _includes(surfaces, SurfOcclusal1);
                            break;
                        default:
                            break;
                    }
                    break;
                case AdultPremolar:
                case AdultPremolarBicusp:
                    switch (surface.type) {
                        case SurfBuccal.type:
                        case SurfPalatal.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfOcclusal) || _includes(surfaces, SurfDistal);
                            break;
                        case SurfMesial.type:
                        case SurfDistal.type:
                            contig = _includes(surfaces, SurfBuccal) || _includes(surfaces, SurfOcclusal) || _includes(surfaces, SurfPalatal);
                            break;
                        case SurfOcclusal.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfPalatal) || _includes(surfaces, SurfBuccal);
                            break;
                        default:
                            break;
                    }
                    break;
                case AdultIncisor:
                    switch (surface.type) {
                        case SurfLabial.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfIncisal);
                            break;
                        case SurfPalatal.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfIncisal);
                            break;
                        case SurfMesial.type:
                        case SurfDistal.type:
                            contig = _includes(surfaces, SurfLabial) || _includes(surfaces, SurfPalatal) || _includes(surfaces, SurfIncisal);
                            break;
                        case SurfIncisal.type:
                            contig = _includes(surfaces, SurfMesial) || _includes(surfaces, SurfDistal) || _includes(surfaces, SurfLabial) || _includes(surfaces, SurfPalatal);
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return contig;
};
