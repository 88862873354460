import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

import "core-js/stable";
import "regenerator-runtime/runtime";

import App from './App';
import {HashRouter, Route} from 'react-router-dom'

import {login} from './reducers/login';
import {admin} from './reducers/admin';
import {stateManagement} from './reducers/stateManagement';
import {patientAssessmentCategories} from './reducers/patientAssessmentCategories';
import {orthoAssessmentCategories} from './reducers/orthoAssessmentCategories';
import {endoAssessmentCategories} from './reducers/endoAssessmentCategories';
import {patients} from './reducers/patients';
import {findReferrer} from './reducers/findReferrers';
import {users} from './reducers/users';
import {dropDowns} from './reducers/dropDowns';
import {housekeeping} from './reducers/housekeeping';
import {websockets} from './reducers/websockets';
import {chartResources} from './reducers/chartReources';
import {diary} from './reducers/diary';
import {tasks} from './reducers/tasks';
import {tablet} from './reducers/tablet';
import {recallsAndReminders} from './reducers/recalls';
import {reports} from './reducers/reports';
import {mailShot} from './reducers/mailShot';
import {kpis} from './reducers/kpis';
import {referrers} from './reducers/referrers';
import {laboratories} from './reducers/laboratories';
import {gateway} from './reducers/gateway';
import {treatmentPlanning} from './reducers/treatmentPlanning';
import {preferences} from './reducers/preferences';
import {objectStore} from './reducers/objectStore';
import {nhsManagement} from './reducers/nhsManagement';
import {paymentPlanning} from './reducers/paymentPlanning';
import {findLaboratories} from './reducers/findLaboratories';
import AxiosFetchClient from './actions/fetchClient'

const rootReducer = combineReducers({
    findLaboratories,
    laboratories,
    login,
    gateway,
    admin,
    mailShot,
    pac: patientAssessmentCategories,
    oac: orthoAssessmentCategories,
    eoc: endoAssessmentCategories,
    patients,
    users,
    findReferrer,
    dropDowns,
    housekeeping,
    websockets,
    chartResources,
    diary,
    tasks,
    tablet,
    recallsAndReminders,
    reports,
    kpis,
    treatmentPlanning,
    referrers,
    preferences,
    objectStore,
    nhsManagement,
    paymentPlanning,
    stateManagement,
});

export const ac = new AxiosFetchClient();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <Route path="/" component={App}/>
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);
