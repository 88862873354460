import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {HM_CloseReminder, HM_notImplemented, HM_SendReminder, REM_STATUS} from "../../../../Constants";
import {ICON_CLOSE, ICON_SEND,} from "../../../../icons";
import {colourTemplate} from "../Utils";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";
import {contactReminderMethodTextTemplate} from "../../../Utils";

const defaultProviderTemplate = (item) => {

    const {defaultProvider} = item;
    return `${defaultProvider.title.abbreviation} ${defaultProvider.firstName} ${defaultProvider.lastName}`.trim();
};

export const RemindersSection = (props) => {

    if (!props.remindersLoaded) {
        return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
    }

    const filteredAppTypes = [];
    const {appointmentTypes, patientAppointmentSummary} = props;

    appointmentTypes.forEach((appointmentType) => {

            patientAppointmentSummary.appointmentsHistory.forEach(appointment => {
                if (appointment.apType === appointmentType.id && filteredAppTypes.indexOf(appointmentType) < 0)
                    filteredAppTypes.push(appointmentType);
            })
        }
    );

    const reminderHistory = _.orderBy(props.reminders, (o) => {
        return moment(o.setFor).format('YYYYMMDD');
    }, ['desc']);

    const userTemplate = (props, item, target) => {

        const value = _.get(item, target);
        const user = _.find(props.usersShort, (user) => value === user.id);
        return user === undefined ? '-' : `${user.titleAbbreviation} ${user.firstName} ${user.lastName}`.trim();
    };

    const stateTemplate = ({state}) => {
        return _.find(REM_STATUS, (type) => type.name === state).text;
    };

    let items = [
        {
            label: HM_SendReminder.label,
            icon: ICON_SEND,
            command: () => props.toolbarCallbacks[HM_SendReminder.id](),
            target: HM_SendReminder.id
        },
        {
            label: HM_CloseReminder.label,
            icon: ICON_CLOSE,
            command: () => props.toolbarCallbacks[HM_notImplemented.id](),
            target: HM_CloseReminder.id
        },
    ];
    let cm = null;

    return (
        <div>
            <Panel header='Default Providers'>
                <DataTable value={filteredAppTypes}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                >
                    <Column field="color" body={colourTemplate} style={{width: '5%'}}/>
                    <Column field="description" header="Description" style={{width: '55%'}}/>
                    <Column field="provider" body={defaultProviderTemplate} header="Default Provider"
                            style={{width: '20%'}}/>
                    <Column field="reminderInterval" header="Reminder Period" style={{width: '10%'}}/>
                    <Column body={(row) => contactReminderMethodTextTemplate(row)} header="Reminder Method"
                            style={{width: '10%'}}/>
                </DataTable>
            </Panel>
            <Panel header='Reminders' style={{paddingTop: '5px'}}>

                <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

                <DataTable value={reminderHistory}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           paginator={true}
                           rows={props.reminderRows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={(e) => {
                               props.onPageFlex(e, 'firstReminder', 'reminderRows');
                           }}
                           first={props.firstReminder}
                           selection={props.selectedReminder}
                           onSelectionChange={(e) => props.setValue('selectedReminder', e.value)}
                           contextMenuSelection={props.selectedReminder}
                           onContextMenuSelectionChange={(e) => props.setValue('selectedReminder', e.value)}
                           onContextMenu={e => cm.show(e.originalEvent)}
                >
                    <Column field="method" body={(row) => contactReminderMethodTextTemplate(row)}
                            header="Contact Method"
                            style={{width: '10%'}}/>
                    <Column body={row => dateTemplate(row.setFor)} header="Set For" style={{width: '14%'}}/>
                    <Column body={row => dateTemplate(row.appointmentDate)} header="Appointment Date"
                            style={{width: '14%'}}/>
                    <Column field="provider" body={row => userTemplate(props, row, 'appointmentWith')}
                            header="Clinician" style={{width: '20%'}}/>
                    <Column field="state" body={stateTemplate} header="Status" style={{width: '8%'}}/>
                    <Column field="sentBy" body={row => userTemplate(props, row, 'sentBy')} header="Sent By"
                            style={{width: '20%'}}/>
                    <Column body={row => dateTemplate(row.sentOn)} header="Sent On" style={{width: '14%'}}/>
                </DataTable>
            </Panel>
        </div>
    )
};
