import React from 'react';
import moment from 'moment';

import {
    RecallRemType,
    REM_STATUS_FAILEDTOSEND,
    REM_STATUS_MISSED,
    REM_STATUS_OPEN,
    REM_STATUS_SENT,
    REM_STATUS_SMSNOCREDIT, RRT_REMINDER_SNAILMAIL
} from "../Constants";
import {TabBaseComponent} from "./TabBaseComponent";
import {contactMethodTemplate, contactRecallMethodTemplate} from "./Utils";
import {ICON_PATIENT_FEMALE, ICON_PATIENT_MALE} from "./FixedItems/PatientDetails/Constants";
import _ from "lodash";
import {ICON_GROUP} from "../icons";

export class RecallReminderComponent extends TabBaseComponent {

    patientTemplate({patientTitle, patientFirstName, patientLastName}) {
        return `${patientTitle} ${patientFirstName} ${patientLastName}`.trim();
    }

    patientTreeTemplate(node) {
        return `${node.data.patientTitle} ${node.data.patientFirstName} ${node.data.patientLastName}`.trim();
    }

    userTemplate({userTitle, userFirstName, userLastName}) {
        return `${userTitle} ${userFirstName} ${userLastName}`.trim();
    }

    userTreeTemplate(node) {
        if (!node.data.header)
            return `${node.data.userTitle} ${node.data.userFirstName} ${node.data.userLastName}`.trim();
    }

    statusTemplate({state}) {
        switch (state) {
            case REM_STATUS_OPEN.name :
                return REM_STATUS_OPEN.text;
            case REM_STATUS_SENT.name :
                return REM_STATUS_SENT.text;
            case REM_STATUS_MISSED.name :
                return REM_STATUS_MISSED.text;
            case REM_STATUS_FAILEDTOSEND.name :
                return REM_STATUS_FAILEDTOSEND.text;
            case REM_STATUS_SMSNOCREDIT.name :
                return REM_STATUS_SMSNOCREDIT.text;
            default :
                return '??'
        }
    }

    statusTreeTemplate(node) {

        if (!node.data.header) {
            switch (node.data.state) {
                case REM_STATUS_OPEN.name :
                    return REM_STATUS_OPEN.text;
                case REM_STATUS_SENT.name :
                    return REM_STATUS_SENT.text;
                case REM_STATUS_MISSED.name :
                    return REM_STATUS_MISSED.text;
                case REM_STATUS_FAILEDTOSEND.name :
                    return REM_STATUS_FAILEDTOSEND.text;
                case REM_STATUS_SMSNOCREDIT.name :
                    return REM_STATUS_SMSNOCREDIT.text;
                default :
                    return '??'
            }
        }
    }

    contactRecallMethodTreeTemplate = (node) => {
        if (!node.data.header) {
            return contactRecallMethodTemplate(node.data.recallMethod);
        } else if (node.data.bgEntry) {
            return <div style={{fontSize: '17px'}} className={'workRequired16'}/>
        } else {
            return node.data.female ? <div style={{fontSize: '17px'}} className={ICON_PATIENT_FEMALE}/> : <div style={{fontSize: '17px'}} className={ICON_PATIENT_MALE}/>
        }
    }

    contactReminderMethodTreeTemplate(node) {
        if (!node.data.header) {

            const source = node.data.reminderMethod === undefined ? RRT_REMINDER_SNAILMAIL.name : node.data.reminderMethod;
            const target = _.find(RecallRemType, (type) => type.name === source);

            if (target)
                return contactMethodTemplate(target.ordinal);
            else
                return contactMethodTemplate(RRT_REMINDER_SNAILMAIL.ordinal);

        } else if (node.data.bgEntry) {
            return <div style={{fontSize: '17px'}} className={ICON_GROUP}/>
        } else {
            return node.data.female ? <div style={{fontSize: '17px'}} className={ICON_PATIENT_FEMALE}/> : <div style={{fontSize: '17px'}} className={ICON_PATIENT_MALE}/>
        }
    }

    apTypeTemplate({iconName}) {
        return <img alt='' src={`assets/images/${iconName}`}/>
    }

    apTypeTreeTemplate(node) {
        if (!node.data.header) {
            return this.apTypeTemplate(node.data);
        }
    }

    dateTreeTemplate = (node) => {

        if (!node.data.header) {
            try {
                return node.data.start === null ? '-' : moment(node.data.start).format('dddd, MMMM Do YYYY')
            } catch (ex) {
                return '-';
            }
        }
    };

    dateTreeTemplateShort = (node) => {

        if (!node.data.header) {
            try {
                return node.data.start === null ? '-' : moment(node.data.start).format('Do MMMM YYYY')
            } catch (ex) {
                return '-';
            }
        }
    };

    durationTreeTemplate = (node) => {

        if (!node.data.header) {
            try {
                return node.data.duration === null ? '-' : moment(node.data.duration).format('HH:mm')
            } catch (ex) {
                return '-';
            }
        }
    };

    countTreeTemplate(node) {

        if (!node.data.header) {
            return node.data.recallCount
        }
    }

    limitTreeTemplate(node) {

        if (!node.data.header) {
            return node.data.recallLimit
        }
    }
}