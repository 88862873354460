import React from 'react';
import {setState} from "../../../../actions/stateManagement";

import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';

import {connect} from "react-redux";
import * as Actions from "../../../../actions/index";
import {HM_notImplemented} from "../../../../Constants";
import {ICON_PRINT, ICON_SCREEN} from "../../../../icons";
import {findByProviderShort} from "../../../../actions/findPatients";
import {BaseComponent} from "../../../BaseComponent";
import {showPatientDetailsPage} from "../../PatientDetails/Utils";
import {TB_PATIENT_DETAILS} from "../../PatientDetails/Constants";
import {ShowMessageDialog} from "../../Diary/components/EventComponent";

import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";

export class ConnectedUserPatients extends BaseComponent {

    constructor(props) {
        super();

        this.state = {

            providerPatients: [],
            selectedPatient: null,

            first: 0,
            rows: 5,

            canSave: {status: false, count: 0},
            init: false,
        };

        this.onSelection = this.onSelection.bind(this);
        this.onShowDetails = this.onShowDetails.bind(this);

        this.toolbarCallbacks = {

            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        this.props.findByProviderShort(this.props.userId);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_PATIENTS:
                    this.setState({patients: this.props.providerPatients, providerPatientsLoaded: true});
                    break;
                default:
                    break;
            }
        }
    }

    onSelection(event) {

        this.setState({selectedPatient: event.value});
    }

    onShowDetails({id, firstName, lastName, gender}) {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender});
    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    genderTemplate(rowData) {
        return rowData['gender'] === 1 ? 'Male' : 'Female';
    }

    render() {

      

        if (!this.state.providerPatientsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = [
            {
                label: TB_PATIENT_DETAILS.text, icon: TB_PATIENT_DETAILS.icon, command: (e) => {
                    this.toolbarCallbacks[TB_PATIENT_DETAILS.id](this.state.selectedPatient);
                }
            }
        ];

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>User's Patients</span>
                    </div>
                    <div className="items-margin d-flex d-align-center">
                        <Button style={{'float': 'right'}}
                                icon={ICON_SCREEN}
                                onClick={()=>{this.onShowMenuEntry({item: {target: HM_notImplemented.id}})}}/>
                        <Button style={{'float': 'right'}}
                                icon={ICON_PRINT}
                                onClick={()=>{this.onShowMenuEntry({item: {target: HM_notImplemented.id}})}}/>
                    </div>
                </div>;

        return (
            <Panel headerTemplate={header}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.showDialogs()}

                    <DataTable value={this.state.patients}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5,10,20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedPatient}
                               onSelectionChange={this.onSelection}
                               onRowDoubleClick={e=>this.toolbarCallbacks[TB_PATIENT_DETAILS.id](this.state.selectedPatient)}
                               contextMenuSelection={this.state.selectedPatient}
                               onContextMenuSelectionChange={e => this.setState({selectedPatient: e.value})}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent);
                               }}
                    >
                        <Column field="gender" body={this.genderTemplate} style={{width: '10%'}}/>
                        <Column field="fullName2" header="Full Name" style={{width: '70%'}}/>
                        <Column body={dateTemplate} header="Date of Birth" style={{width: '20%'}}/>
                    </DataTable>
            </Panel>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        providerPatientsLoaded: state.patients.providerPatientsLoaded,
        providerPatients: state.patients.providerPatients,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {

    return {
        findByProviderShort: (userId) => dispatch(findByProviderShort(userId)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const UserPatients = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserPatients);

export default UserPatients;