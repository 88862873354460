import _ from 'lodash';
import {mapBaseChartItems, mapFavChartItems} from "../components/Charting/Palettes/BasePalette";
import {SM_patientChart} from "../actions/stateManagement";
import {RES_CHART, RES_chartResources} from "../actions/ChartResources";
import {RES_TREATMENT_PLAN_BY_APP_ID} from "../actions/treatmentPlanning";

const initialState = {

    message: null,

    // Chart resource data

    resources: {resources: {}},
    resourcesLoaded: false,

};

export const chartResources = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Chart Related

            case RES_chartResources.GET.receive:

                const resources = {
                    chartingItems: action.payload.chartingItems,
                    materials: action.payload.materials,
                    conditions: action.payload.conditions,
                    favourites: action.payload.favourites,
                    nhsCodes: action.payload.nhsCodes,
                };

                const baseCategoryGroups = mapBaseChartItems(action.payload);

                let resourceData = {
                    resources,
                    baseCategoryGroups,
                    favCategoryGroups: mapFavChartItems(action.payload),
                };

                return {
                    ...state,
                    resources: resourceData,
                    resourcesLoaded: true
                };

            case RES_CHART.GET.receive :

                return {
                    ...state,
                    [`${SM_patientChart.id}_${action.id}`]: action.payload,
                    [`${SM_patientChart.loaded}_${action.id}`]: true
                };

            case RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action:

                let deleteChartState = {...state};
                delete deleteChartState[`${SM_patientChart.loaded}_${action.id}`];
                delete deleteChartState[`${SM_patientChart.id}_${action.id}`];

                return deleteChartState;

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};