import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {dropDownArray} from './OnChangeUtils';

const theWorks = (props) => {

    const readOnly = props.readOnly;

    return (
        <div id="tooth-wear-row" className="p-dropdown-auto-width">
            <div>
                {dropDownArray(props, 'twi0', 1, readOnly, false, readOnly, false)}
                <label>8</label>
                {dropDownArray(props, 'twi16', 17, readOnly, false, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi1', 2, readOnly, false, readOnly, false)}
                <label>7</label>
                {dropDownArray(props, 'twi17', 18, readOnly, false, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi2', 3, readOnly, false)}
                <label>6</label>
                {dropDownArray(props, 'twi18', 19, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi3', 4, readOnly, false)}
                <label>5</label>
                {dropDownArray(props, 'twi19', 20, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi4', 5, readOnly, false)}
                <label>4</label>
                {dropDownArray(props, 'twi20', 21, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi5', 6, readOnly, false)}
                <label>3</label>
                {dropDownArray(props, 'twi21', 22, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi6', 7, readOnly, false)}
                <label>2</label>
                {dropDownArray(props, 'twi22', 23, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi7', 8, readOnly, false)}
                <label>1</label>
                {dropDownArray(props, 'twi23', 24, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi8', 9, readOnly, false)}
                <label>1</label>
                {dropDownArray(props, 'twi24', 25, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi9', 10, readOnly, false)}
                <label>2</label>
                {dropDownArray(props, 'twi25', 26, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi10', 11, readOnly, false)}
                <label>3</label>
                {dropDownArray(props, 'twi26', 27, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi11', 12, readOnly, false)}
                <label>4</label>
                {dropDownArray(props, 'twi27', 28, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi12', 13, readOnly, false)}
                <label>5</label>
                {dropDownArray(props, 'twi28', 29, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi13', 14, readOnly, false)}
                <label>6</label>
                {dropDownArray(props, 'twi29', 30, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi14', 15, readOnly, false)}
                <label>7</label>
                {dropDownArray(props, 'twi30', 31, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'twi15', 16, readOnly, false)}
                <label>8</label>
                {dropDownArray(props, 'twi31', 32, readOnly, false)}
            </div>
        </div>
    )
};

export const ToothwearIndex = (props) => {

    const newProps = {...props};

    newProps.name = 'toothWearIndex';

    return (
        <Panel header='Tooth Wear Index' style={{marginBottom: '1em'}}>
            <div>
                {theWorks(newProps)}
            </div>
        </Panel>
    );
};
