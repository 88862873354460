import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {dropDown, inputText} from '../PatientDynamicItems/OnChangeUtils';

export const Name = (props) => {

    return (
        <Panel header='Name'>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-3">
                    <label htmlFor="title">Title</label>
                </div>
                <div className="p-col-9">
                    {dropDown(props, 'titles', 'title', 'abbreviation', true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="firstName">First Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'firstName', 'First Name', false, props.mandatory)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="middleName">Middle Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'middleName', 'Middle Name', false, false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="lastName">Last Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'lastName', 'Last Name', false, props.mandatory)}
                </div>
            </div>
        </Panel>
    )
};
