import React, {Component} from 'react';

import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Panel} from 'primereact/panel';
import {ScrollPanel} from 'primereact/scrollpanel';
import _ from "lodash";
import {DOC_WORD_PATIENT_TEMPLATE, HM_createPatDocument, ME_PatientTemplates,} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getResource2, RES_PATIENT_DOC_TEMPLATE} from "../../../../actions/housekeeping";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {InputText} from "primereact/inputtext";

export class ConnectedCreatePatientDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {

            details: {
                templateId: null,
                patientId: props.patientId,
                visibleName: '',
                notes: '',
                createdBy: props.loginIdentity.id,
            },
            selectedTemplate: null,
            first: 0,
            rows: 5,

            globalFilter: '',
        };

        this.onPage = this.onPage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onTemplateSelection = this.onTemplateSelection.bind(this);
    }

    componentDidMount() {

        this.props.getTemplates([DOC_WORD_PATIENT_TEMPLATE.name]);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.messageType !== prevProps.messageType) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onTemplateSelection(event) {

        const visibleName = `${event.value.visibleName} - ${dateTemplate(new Date())}`;
        const details = {...this.state.details};
        details.visibleName = visibleName;
        details.templateId = event.value.docId;
        details.template = event.value;

        this.setState({selectedTemplate: event.value, details});
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows})
    }

    render() {

        if (!this.props.patientDocTemplatesLoaded) {
            return null;
        }

        const docNotDefined = this.state.selectedTemplate === null;

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.details);
                        }}
                        disabled={docNotDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_createPatDocument.id)
                        }}/>
            </div>
        );

        let patientDocTemplates = _.filter(this.props.patientDocTemplates, template => {
            const pattern = `${template.visibleName} ${template.notes}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const props = {
            onChange: this.onChange,
            target: 'details',
            details: this.state.details,
        };

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{HM_createPatDocument.header}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => {
                               this.setState({globalFilter: e.target.value});
                           }}
                           placeholder="Search"
                           size="50"
                           autoFocus
                />
            </div>
        </div>;

        return (
            <Dialog style={{width: "50%"}}
                    header={header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_createPatDocument.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    <DataTable value={patientDocTemplates}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               header={ME_PatientTemplates.label}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedTemplate}
                               onSelectionChange={this.onTemplateSelection}
                    >

                        <Column field="visibleName" header='Template Name' style={{width: '50%'}}/>
                        <Column field="notes" header='notes' style={{width: '50%'}}/>

                    </DataTable>
                    <Panel header='Document Details' style={{paddingTop: '5px'}}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-3">
                                <label>Document Name</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'visibleName', 'Document Name ...', false, true)}
                            </div>

                            <div className="p-col-3">
                                <label>Notes</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'notes', 5, 100, false, false)}
                            </div>
                        </div>
                    </Panel>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        patientDocTemplatesLoaded: state.housekeeping.patientDocTemplatesLoaded,
        patientDocTemplates: state.housekeeping.patientDocTemplates,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        getTemplates: (types) => dispatch(getResource2(RES_PATIENT_DOC_TEMPLATE.GET, types)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddPatientDocument = connect(mapStateToProps, MapDispatchToProps)(ConnectedCreatePatientDocument);

export default AddPatientDocument;
