import {ComOverErupted} from "../Commands/ComOverErupted";
import {ComFilling} from "../Commands/ComFilling";
import {ComWatch} from "../Commands/ComWatch";
import {ComOnlay} from "../Commands/ComOnlay";
import {ComPartiallyErupted} from "../Commands/ComPartiallyErupted";
import {ComRetainedRoot} from "../Commands/ComRetainedRoot";
import {ComBridgeImplant} from "../Commands/ComBridgeImplant";
import {ComMissingTooth} from "../Commands/ComMissingTooth";
import {ComBondedCrown} from "../Commands/ComBondedCrown";
import {ComVeneer} from "../Commands/ComVeneer";
import {ComCrownOnImplant} from "../Commands/ComCrownOnImplant";
import {ComBridgeBonded} from "../Commands/ComBridgeBonded";
import {ComImplantFixture} from "../Commands/ComImplantFixture";
import {ComPostAndCore} from "../Commands/ComPostAndCore";
import {ComDeciduous} from "../Commands/ComDeciduous";
import {ComImpacted} from "../Commands/ComImpacted";
import {ComUnerupted} from "../Commands/ComUnerupted";
import {ComPost} from "../Commands/ComPost";
import {ComFullCrown} from "../Commands/ComFullCrown";
import {ComDriftedRight} from "../Commands/ComDriftedRight";
import {ComDriftedLeft} from "../Commands/ComDriftedLeft";
import {ComClosedGap} from "../Commands/ComClosedGap";
import {ComJacketCrown} from "../Commands/ComJacketCrown";
import {ComRootCanalTreatment} from "../Commands/ComRootCanalTreatment";
import {ComApicectomy} from "../Commands/ComApicectomy";
import {ComCaries} from "../Commands/ComCaries";
import {ComTempFilling} from "../Commands/ComTempFilling";
import {ComInlay} from "../Commands/ComInlay";
import {ComChipped} from "../Commands/ComChipped";
import {ComFractured} from "../Commands/ComFractured";
import {ComDenture} from "../Commands/ComDenture";
import {ComOrthoFixedRetainer} from "../Commands/ComOrthoFixedRetainer";

export const ChartNumbering_UK = "ChartNumbering_UK";
export const ChartNumbering_US = "ChartNumbering_US";
// export const ChartNumbering_EURO = "ChartNumbering_EURO";

export const JAW_TOOTH_COUNT = 16;

export const TOOTH_HEIGHT = 150;
export const MOUTH_HEIGHT = 2 * TOOTH_HEIGHT + 20;

export const TOOTH_SIZE = 50;
export const TOOTH_GAP = 5;

export const MOUTH_WIDTH = JAW_TOOTH_COUNT * (TOOTH_SIZE + TOOTH_GAP) + TOOTH_GAP;

export const TOOTH_X = TOOTH_SIZE;
export const TOOTH_X_OFFSET = TOOTH_X + TOOTH_GAP;
export const TOOTH_Y = TOOTH_SIZE;

export const TOOTH_LOWER_OFFSET = TOOTH_Y + TOOTH_GAP;

export const UPPER_UNERUPTED_OFFSET = -40;
export const LOWER_UNERUPTED_OFFSET = 40;

export const UPPER_IMPACTED_OFFSET = -40;
export const LOWER_IMPACTED_OFFSET = 40;

export const UPPER_PARTIALLY_ERUPTED_OFFSET = -20;
export const LOWER_PARTIALLY_ERUPTED_OFFSET = 20;

export const UPPER_OVER_ERUPTED_OFFSET = 15;
export const LOWER_OVER_ERUPTED_OFFSET = -15;

// Jaw

export const JawUpper = 'Upper';
export const JawUpperIndex = 0;

export const JawLower = 'Lower';
export const JawLowerIndex = 1;

// Positions

export const R8 = {perm: 'R8', deci: '', offset: 0};
export const R7 = {perm: 'R7', deci: '', offset: 1};
export const R6 = {perm: 'R6', deci: '', offset: 2};
export const R5 = {perm: 'R5', deci: 'RE', offset: 3};
export const R4 = {perm: 'R4', deci: 'RD', offset: 4};
export const R3 = {perm: 'R3', deci: 'RC', offset: 5};
export const R2 = {perm: 'R2', deci: 'RB', offset: 6};
export const R1 = {perm: 'R1', deci: 'RA', offset: 7};
export const L1 = {perm: 'L1', deci: 'LA', offset: 8};
export const L2 = {perm: 'L2', deci: 'LB', offset: 9};
export const L3 = {perm: 'L3', deci: 'LC', offset: 10};
export const L4 = {perm: 'L4', deci: 'LD', offset: 11};
export const L5 = {perm: 'L5', deci: 'LE', offset: 12};
export const L6 = {perm: 'L6', deci: '', offset: 13};
export const L7 = {perm: 'L7', deci: '', offset: 14};
export const L8 = {perm: 'L8', deci: '', offset: 15};

export const CompleteJaw = [R8, R7, R6, R5, R4, R3, R2, R1, L1, L2, L3, L4, L5, L6, L7, L8];


// Left & Right

export const Left = [L1.offset, L2.offset, L3.offset, L4.offset, L5.offset, L6.offset, L7.offset, L8.offset];
export const Right = [R1.offset, R2.offset, R3.offset, R4.offset, R5.offset, R6.offset, R7.offset, R8.offset];

// Teeth

export const AdultMolar = 0;
export const AdultMolarBicus = 1;
export const AdultPremolar = 2;
export const AdultPremolarBicusp = 3;
export const AdultIncisor = 4;
export const ChildMolar = 5;
export const ChildPremolar = 6;
export const ChildIncisor = 7;
export const NonTooth = 8;

// ToothType

export const ToothType = [AdultMolar, AdultMolarBicus, AdultPremolar, AdultPremolarBicusp, AdultIncisor, ChildMolar, ChildPremolar, ChildIncisor, NonTooth];

// Roots

export const MesialRoot = 0;
export const DistalRoot = 1;
export const MesioBuccalRoot = 2;
export const PalatalRoot = 3;
export const DistoBuccalRoot = 4;
export const BuccalRoot = 5;
export const Root = 6;

// RootType

export const RootType = [MesialRoot, DistalRoot, MesioBuccalRoot, PalatalRoot, DistoBuccalRoot, BuccalRoot, Root];

// SurfaceType

export const SurfNone = {ordinal: 0, type: 'SurfNone', rightRank: 0, leftRank: 0, name: ''};
export const SurfIncisal = {ordinal: 1, type: 'SurfIncisal',rightRank: 0, leftRank: 0, name: 'In'};
export const SurfOcclusal = {ordinal: 2, type: 'SurfOcclusal',rightRank: 1, leftRank: 1, name: 'Oc'};
export const SurfOcclusal1 = {ordinal: 3, type: 'SurfOcclusal1',rightRank: 3, leftRank: 3, name: 'Oc1'};
export const SurfOcclusal2 = {ordinal: 4, type: 'SurfOcclusal2',rightRank: 3, leftRank: 3, name: 'Oc2'};
export const SurfMesial = {ordinal: 5, type: 'SurfMesial',rightRank: 2, leftRank: 4, name: 'Me'};
export const SurfDistal = {ordinal: 6, type: 'SurfDistal',rightRank: 4, leftRank: 2, name: 'Di'};
export const SurfBuccal = {ordinal: 7, type: 'SurfBuccal',rightRank: 5, leftRank: 5, name: 'Bu'};
export const SurfBuccal1 = {ordinal: 8, type: 'SurfBuccal1',rightRank: 5, leftRank: 5, name: 'Bu1'};
export const SurfBuccal2 = {ordinal: 9, type: 'SurfBuccal2',rightRank: 5, leftRank: 5, name: 'Bu2'};
export const SurfLabial = {ordinal: 10, type: 'SurfLabial',rightRank: 5, leftRank: 5, name: 'La'};
export const SurfPalatal = {ordinal: 11, type: 'SurfPalatal',rightRank: 5, leftRank: 5, name: 'Pa'};
export const SurfPalatal1 = {ordinal: 12, type: 'SurfPalatal1',rightRank: 5, leftRank: 5, name: 'Pa1'};
export const SurfPalatal2 = {ordinal: 13, type: 'SurfPalatal2',rightRank: 5, leftRank: 5, name: 'Pa2'};
export const SurfLingual = {ordinal: 14, type: 'SurfLingual',rightRank: 5, leftRank: 5, name: 'Li'};
export const SurfLingual1 = {ordinal: 15, type: 'SurfLingual1',rightRank: 5, leftRank: 5, name: 'Li1'};
export const SurfLingual2 = {ordinal: 16, type: 'SurfLingual2',rightRank: 5, leftRank: 5, name: 'Li2'};

// Surfaces

export const Surfaces = [
    SurfNone, SurfIncisal, SurfOcclusal, SurfOcclusal1, SurfOcclusal2, SurfMesial, SurfDistal, SurfBuccal, SurfBuccal1, SurfBuccal2,
    SurfLabial, SurfPalatal, SurfPalatal1, SurfPalatal2, SurfLingual, SurfLingual1, SurfLingual2
];

// Scopes

export const ScopeSurface = {text: 'Surface'};
export const ScopeTooth = {text: 'Tooth'};
export const ScopeRoot = {text: 'Root'};
export const ScopeEntire = {text: 'Entire'};

export const Scope = [ScopeSurface, ScopeTooth, ScopeRoot, ScopeEntire];

// Directions

export const DirLeft = 0;
export const DirRight = 1;
export const DirBackwards = 2;
export const DirForwards = 3;
export const DirClosed = 4;

export const Directions = [DirLeft, DirRight, DirBackwards, DirForwards, DirClosed];

// Tooth Root Modifier

export const TMNone = 0;
export const TMApicectomy = 1;
export const TMRetained = 2;
export const TMRootCanal = 3;
export const TMRootCanalApectomy = 4;

// Colours

export const CariesColor = 'rgb(255,0,0)';
export const SelectedColor = 'rgb(255,255,0)';
export const UnSelectedColor = 'rgb(0xff, 0xff,0xff)';

export const MouthBGColor = 'rgb(255, 240, 245)';
export const MouthBaseBGColor = 'rgb(196, 225, 255)';
export const SelectedBGColor = 'rgb(22,255,135)';

export const RootFillColor = 'rgb(255,0,255)';
export const RootUnfilledColor = 'rgb(255,255,255)';
export const RootApicetomyFillColor = 'rgb(05,0,250)';

export const WatchColor = 'rgb(255,191,255)';
export const DeciduousColor = 'rgb(168,255,211)';
export const ImpactedColor = 'rgb(255,0,0)';

export const ProstheticColour = 'rgb(0xff, 0xff,0x9c)';

// Tooth Surfaces

// Upper
export const URMolar = [SurfBuccal1, SurfBuccal2, SurfMesial, SurfPalatal2, SurfPalatal1, SurfDistal, SurfOcclusal1, SurfOcclusal2];
export const ULMolar = [SurfBuccal1, SurfBuccal2, SurfDistal, SurfPalatal2, SurfPalatal1, SurfMesial, SurfOcclusal1, SurfOcclusal2];

export const URPreMolar = [SurfBuccal, SurfMesial, SurfPalatal, SurfDistal, SurfOcclusal];
export const ULPreMolar = [SurfBuccal, SurfDistal, SurfPalatal, SurfMesial, SurfOcclusal];

export const URPreMolarBicusp = [SurfBuccal, SurfMesial, SurfPalatal, SurfDistal, SurfOcclusal];
export const ULPreMolarBicusp = [SurfBuccal, SurfDistal, SurfPalatal, SurfMesial, SurfOcclusal];

export const URIncisor = [SurfLabial, SurfMesial, SurfPalatal, SurfDistal, SurfIncisal];
export const ULIncisor = [SurfLabial, SurfDistal, SurfPalatal, SurfMesial, SurfIncisal];

// Lower
export const LRMolar = [SurfLingual1, SurfLingual2, SurfMesial, SurfBuccal2, SurfBuccal1, SurfDistal, SurfOcclusal1, SurfOcclusal2];
export const LLMolar = [SurfLingual1, SurfLingual2, SurfDistal, SurfBuccal2, SurfBuccal1, SurfMesial, SurfOcclusal1, SurfOcclusal2];

export const LRPreMolar = [SurfLingual, SurfMesial, SurfBuccal, SurfDistal, SurfOcclusal];
export const LLPreMolar = [SurfLingual, SurfDistal, SurfBuccal, SurfMesial, SurfOcclusal];

export const LRIncisor = [SurfLingual, SurfMesial, SurfLabial, SurfDistal, SurfIncisal];
export const LLIncisor = [SurfLingual, SurfDistal, SurfLabial, SurfMesial, SurfIncisal];

// Child Types

export const PlaceHolderEP = 0;
export const DentureEP = 1;
export const ToothEP = 2;

export const BondedBridgeEP = 10;
export const BondedCrownEP = 11;
export const BridgeOnImplantEP = 12;
export const CantileverBridgeEP = 13;
export const CariesEP = 14;
export const CariesSurfaceEP = 15;
export const ChartingEditPart = 16;
export const ClosedGapEP = 17;
export const CrownOnImplantEP = 18;
export const DriftedLeftEP = 20;
export const DriftedRightEP = 21;
export const ExtractedToothEP = 22;
export const FillingEP = 23;
export const FillingSurfaceEP = 24;
export const FlashEditPart = 25;
export const FlashHelper = 26;
export const FullCrownEP = 27;
export const IFlasher = 28;
export const ImageOnToothEP = 29;
export const ImplantFixtureEP = 30;
export const InlayEP = 31;
export const InlaySurfaceEP = 32;
export const MarylandBridgeEP = 33;
export const MouthEP = 34;
export const MyEditPartFactory = 35;
export const OnlayEP = 36;
export const ToothRootEP = 37;
export const ToothSurfaceEP = 38;
export const ToothTopEP = 39;
export const FixedBondedOrthodonticRetainerEP = 32;

// Charting Primitives

export const CONDNone = "CONDNone";
export const CONDExcellent = "CONDExcellent";
export const CONDGood = "CONDGood";
export const CONDPoor = "CONDPoor";
export const CONDReplace = "CONDReplace";

export const CONDAdequate = "CONDAdequate";
export const CONDIncomplete = "CONDIncomplete";
export const CONDApicalInfection = "CONDApicalInfection";

export const CANALNone = "CANALNone";
export const CANALC = "CANALC";
export const CANALB = "CANALB";
export const CANALMB = "CANALMB";
export const CANALMB1 = "CANALMB1";
export const CANALMB2 = "CANALMB2";
export const CANALML = "CANALML";
export const CANALDB = "CANALDB";
export const CANALP = "CANALP";
export const CANALMP = "CANALMP";
export const CANALDP = "CANALDP";
export const CANALM = "CANALM";
export const CANALD = "CANALD";
export const CANALSMB = "CANALSMB";
export const CANALMBP = "CANALMBP";
export const CANALSDP = "CANALSDP";
export const CANALDBP = "CANALDBP";
export const CANALExtra = "CANALExtra";

export const Apicectomy = "Apicectomy";
export const BondedCrown = "BondedCrown";
export const BondedBridge = "BondedBridge";
export const BondedToMetalBridge = "BondedToMetalBridge";
export const Bridge = "Bridge";
export const BridgeOnImplant = "BridgeOnImplant";
export const CantileverBridge = "CantileverBridge";
export const Caries = "Caries";
export const ClosedGap = "ClosedGap";
export const Crown_3_4 = "Crown_3_4";
export const CrownOnImplant = "CrownOnImplant";
export const DeciduousTooth = "DeciduousTooth";
export const Denture = "Denture";
export const DriftedLeft = "DriftedLeft";
export const DriftedRight = "DriftedRight";
export const Filling = "Filling";
export const FissureSealant = "FissureSealant";
export const FixedBondedOrthodonticRetainer = "FixedBondedOrthodonticRetainer";
export const FracturedTooth = "FracturedTooth";
export const FullCrown = "FullCrown";
export const Impacted = "Impacted";
export const ImplantFixture = "ImplantFixture";
export const Inlay = "Inlay";
export const JacketCrown = "JacketCrown";
export const MarylandBridge = "MarylandBridge";
export const MissingTooth = "MissingTooth";
export const ExtractTooth = "ExtractTooth";
export const NonDisplayableItem = "NonDisplayableItem";
export const Onlay = "Onlay";
export const OverEruptedTooth = "OverEruptedTooth";
export const PartiallyEruptedTooth = "PartiallyEruptedTooth";
export const PinnedRetention = "PinnedRetention";
export const Post = "Post";
export const Post_And_Core = "Post_And_Core";
export const RetainedRoot = "RetainedRoot";
export const RootCanalTreatment = "RootCanalTreatment";
export const SubgingivalDebridement = "SubgingivalDebridement";
export const TemporaryBridge = "TemporaryBridge";
export const TemporaryCrown = "TemporaryCrown";
export const TemporaryFilling = "TemporaryFilling";
export const Veneer = "Veneer";
export const Watch = "Watch";
export const OverErupted = "OverErupted";
export const Unerupted = "Unerupted";
export const PartiallyErupted = "PartiallyErupted";
export const ImageOnTooth = "ImageOnTooth";
export const ChippedTooth = "ChippedTooth";

export const MatNone = "MatNone";
export const MatAcrylic = "MatAcrylic";
export const MatChrome = "MatChrome";
export const MatAmalgam = "MatAmalgam";
export const MatComposite = "MatComposite";
export const MatGlassIonomer = "MatGlassIonomer";
export const MatGold = "MatGold";
export const MatMetal = "MatMetal";
export const MatPorcelain = "MatPorcelain";
export const MatSynthResin = "MatSynthResin";

// Chart Status

export const CAT_Misc = "Miscellaneous";
export const CAT_Filling = "Fillings";
export const CAT_Crowns = "Crowns";
export const CAT_Bridges = "Bridges";
export const CAT_Dentures = "Dentures";
export const CAT_Endodontics = "Endodontics";
export const CAT_Orthodontics = "Orthodontics";

export const Base = 0;
export const Historical = 1;
export const Current = 2;

export const Status = [Base, Historical, Current];

export const basePaletteGroups = [
    {
        name: CAT_Misc,
        selected: true,
        contents: [
            {code: MissingTooth, command: ComMissingTooth},
            {code: Caries, command: ComCaries},
            {code: OverErupted, command: ComOverErupted},
            {code: PartiallyErupted, command: ComPartiallyErupted},
            {code: Unerupted, command: ComUnerupted},
            {code: Impacted, command: ComImpacted},
            {code: DeciduousTooth, command: ComDeciduous},
            {code: Watch, command: ComWatch},
            {code: FracturedTooth, command: ComFractured},
            {code: ChippedTooth, command: ComChipped},
        ]
    },
    {
        name: CAT_Filling,
        selected: false,
        contents: [
            {code: TemporaryFilling, command: ComTempFilling},
            {code: Filling, command: ComFilling},
        ]
    },
    {
        name: CAT_Crowns,
        selected: false,
        contents: [
            {code: BondedCrown, command: ComBondedCrown},
            {code: FullCrown, command: ComFullCrown},
            {code: JacketCrown, command: ComJacketCrown},
            {code: CrownOnImplant, command: ComCrownOnImplant},
            {code: Inlay, command: ComInlay},
            {code: ImplantFixture, command: ComImplantFixture},
            {code: Onlay, command: ComOnlay},
            {code: Post, command: ComPost},
            {code: Post_And_Core, command: ComPostAndCore},
            {code: Veneer, command: ComVeneer},
        ]
    },
    {
        name: CAT_Bridges,
        selected: false,
        contents: [
            {code: BondedBridge, command: ComBridgeBonded},
            {code: BridgeOnImplant, command: ComBridgeImplant},
            {code: MarylandBridge},
            {code: CantileverBridge},
        ]
    },
    {
        name: CAT_Dentures,
        selected: false,
        contents: [
            {code: Denture, command: ComDenture},
        ]
    },
    {
        name: CAT_Endodontics,
        selected: false,
        contents: [
            {code: Apicectomy, command: ComApicectomy},
            {code: RetainedRoot, command: ComRetainedRoot},
            {code: RootCanalTreatment, command: ComRootCanalTreatment},
        ]
    },
    {
        name: CAT_Orthodontics,
        selected: false,
        contents: [
            {code: FixedBondedOrthodonticRetainer, command: ComOrthoFixedRetainer},
            {code: ClosedGap, command: ComClosedGap},
            {code: DriftedLeft, command: ComDriftedLeft},
            {code: DriftedRight, command: ComDriftedRight},
        ]
    },
];
