import moment from 'moment';
import _ from 'lodash';

import {
    ADD,
    ADDBACK,
    ADDBACK_MULTI,
    DELETE,
    DOC_IMAGE,
    fullColorHex,
    fullColorHexColonSep,
    ISO_FRI,
    ISO_MON,
    ISO_SAT,
    ISO_SUN,
    ISO_THU,
    ISO_TUE,
    ISO_WED,
    MOVING_IN_DIARY,
    SR_CLINIC,
    SR_HOLIDAY,
    SR_NOTAVAILABLE,
    TASK_STATUS_ACTIVE,
    UP_ADD_DIARYEVENT,
    UP_DEL_DIARYEVENT,
    UP_DIARYEVENT,
    UP_DOCUMENT_USE_AS_PHOTO,
    UP_NEWTASK,
    UP_TASKUPDATE,
    UPDATE
} from "../Constants";
import * as Actions from "../actions";
import {RECEIVE_APP_PATIENT_SEARCH} from "../actions";
import {PALETTE_GRP_DTSK, PALETTE_GRP_MATM, PALETTE_GRP_RAPS} from "../components/FixedItems/Diary/Constants";
import {IN_CHAIR, LEFT_CHAIR, PROVIDER_SCHEME} from "../components/FixedItems/Preferences/Constants";
import {RES_CLINICIAN_DIARY, RES_PRACTICE_DIARY, RES_PRACTICE_WEEK_DIARY} from "../actions/diary";
import {
    TB_GOTO_APPOINTMENT,
    TB_PLACE_APPOINTMENT,
    TB_SET_CURRENT_DATE
} from "../components/FixedItems/PatientDetails/Constants";
import {ac} from "../index";

const initialState = {

    // Diary data

    currentDate: new Date(),
    typesLoaded: false,

    headerLoaded: false,
    movingEventsLoaded: false,
    resourcesLoaded: false,

    eventsLoaded: false,
    cliniciansEventsLoaded: false,
    weekEventsLoaded: false,

    diaryHeader: [],
    cliniciansHeader: [],
    weekHeader: [],

    diaryResources: [],
    clinicianResources: [],
    weekResources: [],

    events: [],
    cliniciansEvents: [],
    weekEvents: [],

    nextDiaryEventLoaded: false,
    nextDiaryEvent: null,

    prevDiaryEventLoaded: false,
    prevDiaryEvent: null,

    diaryEventTypes: [],
    movingEvents: [],
    diaryTasks: [],

    newDiaryEvent: null,

    matchedPatients: [],

    providerColourScheme: false,

    sortOrder: [],

    daysActivePopUpsLoaded: false,
    daysActivePopUps: [],

    videoAppointmentsLoaded: false,
    videoAppointments: [],
};

const resourceHeld = (data, resource) => {

    let held = false;

    for (let i = 0; i < data.resources.length; i++) {
        if (data.resources[i].resourceId === resource.resourceId) {
            held = true;
            break;
        }
    }
    return held;
};

const holidayHeld = (data, resource) => {

    let held = false;

    for (let i = 0; i < data.holidays.length; i++) {
        if (data.holidays[i].resourceId === resource.resourceId) {
            held = true;
            break;
        }
    }
    return held;
};

const weekHolidayHeld = (data, resource, day) => {

    for (let i = 0; i < data.holidays[day].length; i++) {

        if (data.holidays[day][i] !== undefined && data.holidays[day][i].resourceId === resource.resourceId) {
            return true
        }
    }
    return false;
};

export const findMinMaxHours = (masterConfiguration, start) => {

    let startHour = 0;
    let startMin = 0;
    let endHour = 0;
    let endMin = 0;

    switch (start.weekday()) {
        case ISO_SUN:
            startHour = masterConfiguration.sunStartHour;
            startMin = masterConfiguration.sunStartMin;
            endHour = masterConfiguration.sunTimeHour;
            endMin = masterConfiguration.sunTimeMin;
            break;
        case ISO_MON:
            startHour = masterConfiguration.monStartHour;
            startMin = masterConfiguration.monStartMin;
            endHour = masterConfiguration.monTimeHour;
            endMin = masterConfiguration.monTimeMin;
            break;
        case ISO_TUE:
            startHour = masterConfiguration.tueStartHour;
            startMin = masterConfiguration.tueStartMin;
            endHour = masterConfiguration.tueTimeHour;
            endMin = masterConfiguration.tueTimeMin;
            break;
        case ISO_WED:
            startHour = masterConfiguration.wedStartHour;
            startMin = masterConfiguration.wedStartMin;
            endHour = masterConfiguration.wedTimeHour;
            endMin = masterConfiguration.wedTimeMin;
            break;
        case ISO_THU:
            startHour = masterConfiguration.thuStartHour;
            startMin = masterConfiguration.thuStartMin;
            endHour = masterConfiguration.thuTimeHour;
            endMin = masterConfiguration.thuTimeMin;
            break;
        case ISO_FRI:
            startHour = masterConfiguration.friStartHour;
            startMin = masterConfiguration.friStartMin;
            endHour = masterConfiguration.friTimeHour;
            endMin = masterConfiguration.friTimeMin;
            break;
        case ISO_SAT:
            startHour = masterConfiguration.satStartHour;
            startMin = masterConfiguration.satStartMin;
            endHour = masterConfiguration.satTimeHour;
            endMin = masterConfiguration.satTimeMin;
            break;
        default:
            break;
    }
    const minDateTime = start;
    minDateTime.hour(startHour);
    minDateTime.minute(startMin);
    const minDateTimeString = moment(minDateTime).format();

    const maxDateTime = start;
    maxDateTime.hour(endHour);
    maxDateTime.minute(endMin);
    const maxDateTimeString = moment(maxDateTime).format();

    return {minDateTimeString, maxDateTimeString, startHour, startMin, endHour, endMin};
};

export const diary = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Diary Related

            case Actions.MESSAGE_BUS: {

                switch (action.payload.type) {
                    case TB_GOTO_APPOINTMENT.id:
                        return {
                            ...state,
                            unplacedAppointment: null,
                            currentDate: new Date(action.payload.payload.start),
                        };
                    case TB_PLACE_APPOINTMENT.id:
                        return {
                            ...state,
                            unplacedAppointment: action.payload.payload,
                            currentDate: new Date(),
                        };
                    case TB_SET_CURRENT_DATE.id:
                        return {
                            ...state,
                            unplacedAppointment: null,
                            currentDate: new Date(action.payload.payload),
                        };
                    default:
                        return state;
                }
            }

            case Actions.DIARY_SORT_ORDER:

                return {
                    ...state,
                    sortOrder: action.payload
                };

            case Actions.REQUEST_VIDEO_APPOINTMENTS:

                return {
                    ...state,
                    videoAppointmentsLoaded: false,
                    videoAppointments: [],
                };

            case Actions.RECEIVE_VIDEO_APPOINTMENTS:

                return {
                    ...state,
                    videoAppointmentsLoaded: true,
                    videoAppointments: action.payload,
                };

            case Actions.RECEIVE_DIARY_EVENT_TYPES:

                return {
                    ...state,
                    typesLoaded: true,
                    diaryEventTypes: action.payload,
                };

            case Actions.RECEIVE_DIARY_RESOURCES:

                return {
                    ...state,
                    resourcesLoaded: true,
                    currentDate: action.date,
                    diaryResources: action.payload,
                };

            case RES_PRACTICE_WEEK_DIARY.CLEAR:

                return {
                    ...state,
                    weekEvents: [],
                    weekEventsLoaded: false,
                    weekHeader: {},
                    weekResources: [],
                };

            case Actions.RECEIVE_WEEK_DIARY_EVENTS: {

                const masterConfiguration = action.masterConfiguration;
                const {start} = action.date;

                const orderedResources = [];
                let selectedWeekIndex = 0;

                let data = {
                    resources: [],
                    events: [],
                    holidays: [[], [], [], [], [], [], []],
                };

                const {minDateTimeString, maxDateTimeString} = findMinMaxHours(masterConfiguration, start);

                const {appointments, diaryEvents, rules} = action.payload;

                if (rules.length > 0) {

                    rules.forEach(rule => {

                        const {user} = rule;

                        const resource =
                            {
                                resourceId: user.id,
                                username: user.username,
                                title: `${user.title.abbreviation === null ? '' : user.title.abbreviation} ${user.firstName} ${user.lastName}`.trim(),
                                color: fullColorHex(user.red, user.green, user.blue).toUpperCase(),
                                gender: user.gender,
                                adHoc: false,
                                startHour: 0,
                                startMin: 0,
                                endHour: 0,
                                endMin: 0,
                                lbStartHour: 0,
                                lbstartMin: 0,
                                lbEndHour: 0,
                                lbEndMin: 0,
                                lbShown: false,
                                rule: null,
                            };

                        switch (rule.type) {
                            case SR_CLINIC :

                                // if we have an rule it gives a header more than one rule would give multiple headers.
                                const weekIndex = Math.abs(start.diff(rule.startingOn, 'week') % 4);

                                let currentDate = moment(action.date.start);

                                // iterate of the four week range of the schedule rule
                                for (let week = 0; week < 4; week++) {

                                    // if the requested date / week matches the rule / week act upon it
                                    if (week === weekIndex) {

                                        selectedWeekIndex = weekIndex;

                                        // now iterate through the days of the week
                                        for (let day = 0; day < 7; day++) {

                                            // if the requested date / day matches the rule / day act upon it and the day is active
                                            if (rule.days[week][day]) {
                                                resource.adHoc = rule.adHoc;
                                                resource.startHour = rule.startHour[week][day];
                                                resource.startMin = rule.startMin[week][day];
                                                resource.endHour = rule.endHour[week][day];
                                                resource.endMin = rule.endMin[week][day];
                                                resource.lbStartHour = rule.lbStartHour[week][day];
                                                resource.lbstartMin = rule.lbstartMin[week][day];
                                                resource.lbEndHour = rule.lbEndHour[week][day];
                                                resource.lbEndMin = rule.lbEndMin[week][day];
                                                resource.lbShown = rule.lunchShown[week][day];
                                                resource.rule = rule;

                                                if (!resourceHeld(data, resource)) {
                                                    data.resources.push(resource);
                                                }
                                                currentDate = currentDate.add(1, 'day');
                                            }
                                        }
                                    }
                                }
                                break;
                            case SR_HOLIDAY : {

                                let startingOn = moment(rule.startingOn);
                                startingOn.hour(0);
                                startingOn.minute(0); // this ensure the day is matched, the calendar will deal with the actual start / stop time

                                let endingOn = moment(rule.endingOn);
                                endingOn.hour(23);
                                endingOn.minute(59); // this ensure the day is matched, the calendar will deal with the actual start / stop time

                                let currentDate = moment(action.date.start);

                                resource.rule = rule;

                                for (let day = 0; day < 7; day++) {
                                    if (currentDate.isSameOrAfter(startingOn) && currentDate.isSameOrBefore(endingOn)) {

                                        const start = moment(currentDate);
                                        const end = moment(currentDate);

                                        const day = currentDate.weekday();

                                        if (startingOn.format('L') === endingOn.format('L')) {
                                            start.hour(rule.startHour[0][0]);
                                            start.minute(rule.startMin[0][0]);
                                            end.hour(rule.endHour[0][0]);
                                            end.minute(rule.endMin[0][0]);
                                        } else if (startingOn.format('L') === currentDate.format('L')) {
                                            start.hour(rule.startHour[0][0]);
                                            start.minute(rule.startMin[0][0]);
                                            end.hour(rule.endHour[1][day]);
                                            end.minute(rule.endMin[1][day]);
                                        } else if (endingOn.format('L') === currentDate.format('L')) {
                                            start.hour(rule.startHour[1][day]);
                                            start.minute(rule.startMin[1][day]);
                                            end.hour(rule.endHour[0][0]);
                                            end.minute(rule.endMin[0][0]);
                                        } else {
                                            start.hour(rule.startHour[1][day]);
                                            start.minute(rule.startMin[1][day]);
                                            end.hour(rule.endHour[1][day]);
                                            end.minute(rule.endMin[1][day]);
                                        }

                                        const holidayEvent = {
                                            id: rule.id,
                                            resourceId: resource.resourceId,
                                            userId: resource.resourceId,
                                            title: 'Holiday',
                                            start: start.toDate(),
                                            end: end.toDate(),
                                            comment: 'Holiday',
                                            color: fullColorHexColonSep(action.colours.HOLIDAY_COLOUR),
                                            type: 0,
                                            discriminator: SR_HOLIDAY,
                                            source: rule,
                                        };

                                        if (!weekHolidayHeld(data, resource, day)) {
                                            data.holidays[day].push(resource);
                                            data.events.push(holidayEvent);
                                        }
                                    }
                                    currentDate = currentDate.add(1, 'day');
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    });

                    appointments.forEach(event => {

                        const appointment = convertAppointmentWeekViewDAOtoEvent(action.usersShort, event);
                        data.events.push(appointment);
                    });

                    diaryEvents.forEach(event => {

                        const diaryEvent = convertDiaryEventWeekViewDAOtoEvent(action.usersShort, event);
                        data.events.push(diaryEvent);
                    });

                    // now sort the resources
                    const order = state.sortOrder.split(':');

                    order.forEach(username => {
                        const match = _.find(data.resources, resource => resource.username === username);
                        if (match !== undefined) {
                            orderedResources.push(match);
                        }
                    });
                }

                return {
                    ...state,
                    weekEvents: data.events,
                    weekEventsLoaded: true,
                    weekHeader: {
                        left: "prev,next,today",
                        center: "title",
                        right: "month,agendaWeek,agendaDay",
                        min: minDateTimeString,
                        max: maxDateTimeString,
                        slotDuration: 15
                    },
                    weekResources: orderedResources,
                    providerColourScheme: PROVIDER_SCHEME, //APPOINTMENT_COLOUR_SCHEME,
                    unplacedAppointment: null,
                    selectedWeekIndex,
                };
            }

            case RES_PRACTICE_DIARY.CLEAR:

                return {
                    ...state,
                    events: [],
                    eventsLoaded: false,
                    header: {},
                    resources: [],
                    unscheduledResources: [],
                };

            case Actions.RECEIVE_DIARY_EVENTS: {

                const masterConfiguration = action.masterConfiguration;
                const {start} = action.date;

                const orderedResources = [];
                let selectedWeekIndex = 0;

                let data = {
                    resources: [],
                    unscheduledResources: [],
                    events: [],
                    holidays: [],
                };

                const {minDateTimeString, maxDateTimeString} = findMinMaxHours(masterConfiguration, start);

                action.payload.forEach(({user, appointments, diaryEvents, rules}) => {

                    if (rules.length > 0) {

                        const resource =
                            {
                                resourceId: user.id,
                                username: user.username,
                                title: `${user.title.abbreviation === null ? '' : user.title.abbreviation} ${user.firstName} ${user.lastName}`.trim(),
                                color: fullColorHex(user.red, user.green, user.blue).toUpperCase(),
                                gender: user.gender,
                                adHoc: false,
                                startHour: 0,
                                startMin: 0,
                                endHour: 0,
                                endMin: 0,
                                lbStartHour: 0,
                                lbstartMin: 0,
                                lbEndHour: 0,
                                lbEndMin: 0,
                                lbShown: false,
                                rule: null,
                                status: rules[0].user.status,
                            };

                        rules.forEach(rule => {

                            switch (rule.type) {
                                case SR_CLINIC :

                                    // if we have an rule it gives a header more than one rule would give multiple headers.
                                    const weekIndex = Math.abs(start.diff(rule.startingOn, 'week') % 4);

                                    // iterate of the four week range of the schedule rule
                                    for (let week = 0; week < 4; week++) {

                                        // if the requested date / week matches the rule / week act upon it
                                        if (week === weekIndex) {

                                            selectedWeekIndex = weekIndex;

                                            // now iterate through the days of the week
                                            for (let day = 0; day < 7; day++) {

                                                // if the requested date / day matches the rule / day act upon it and the day is active
                                                if (start.weekday() === day && rule.days[week][day]) {
                                                    resource.adHoc = rule.adHoc;
                                                    resource.startHour = rule.startHour[week][day];
                                                    resource.startMin = rule.startMin[week][day];
                                                    resource.endHour = rule.endHour[week][day];
                                                    resource.endMin = rule.endMin[week][day];
                                                    resource.lbStartHour = rule.lbStartHour[week][day];
                                                    resource.lbstartMin = rule.lbstartMin[week][day];
                                                    resource.lbEndHour = rule.lbEndHour[week][day];
                                                    resource.lbEndMin = rule.lbEndMin[week][day];
                                                    resource.lbShown = rule.lunchShown[week][day];
                                                    resource.rule = rule;

                                                    if (!resourceHeld(data, resource)) {
                                                        data.resources.push(resource);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    break;
                                case SR_HOLIDAY :

                                    let startingOn = moment(rule.startingOn);
                                    startingOn.hour(0);
                                    startingOn.minute(0); // this ensure the day is matched, the calendar will deal with the actual start / stop time

                                    let endingOn = moment(rule.endingOn);
                                    endingOn.hour(23);
                                    endingOn.minute(59); // this ensure the day is matched, the calendar will deal with the actual start / stop time

                                    endingOn.hour(23); //// !!!!!!!!!!!!!!!!!!!!! this is a big fudge as we dont have a time in the ending starting dates!!!!!!!!!!!

                                    const currentDate = action.date.start;

                                    const include = currentDate.isBetween(startingOn, endingOn);

                                    if (include) {

                                        const start = moment(currentDate);
                                        start.hour(rule.startHour[0][0]);
                                        start.minute(rule.startMin[0][0]);

                                        const end = moment(currentDate);
                                        end.hour(rule.endHour[0][0]);
                                        end.minute(rule.endMin[0][0]);

                                        resource.rule = rule;

                                        const holidayEvent = {
                                            id: rule.id,
                                            resourceId: resource.resourceId,
                                            userId: resource.resourceId,
                                            title: 'Holiday',
                                            start: start.toDate(),
                                            end: end.toDate(),
                                            comment: 'Holiday',
                                            color: fullColorHexColonSep(action.colours.HOLIDAY_COLOUR),
                                            type: 0,
                                            discriminator: SR_HOLIDAY,
                                            source: rule,
                                        };
                                        if (!holidayHeld(data, resource)) {
                                            data.holidays.push(resource);
                                            data.events.push(holidayEvent);
                                        }
                                    }
                                    break;
                                default:
                                    break;

                            }
                        });

                        appointments.forEach(event => {

                            const appointment = convertAppointmentDAOtoEvent(event);
                            data.events.push(appointment);
                        });

                        diaryEvents.forEach(event => {

                            const diaryEvent = convertDiaryEventDAOtoEvent(event);
                            data.events.push(diaryEvent);
                        });
                    }
                });

                // build unscheduled provider list
                action.payload.forEach(({user}) => {
                    if (_.findIndex(data.resources, resource => resource.resourceId === user.id) === -1) {
                        data.unscheduledResources.push(user);
                    }
                });

                // now sort the resources
                const order = state.sortOrder.split(':');

                order.forEach(username => {
                    const match = _.find(data.resources, resource => resource.username === username);
                    if (match !== undefined) {
                        orderedResources.push(match);
                    }
                });

                return {
                    ...state,
                    events: data.events,
                    eventsLoaded: true,
                    header: {
                        left: "prev,next,today",
                        center: "title",
                        right: "month,agendaWeek,agendaDay",
                        min: minDateTimeString,
                        max: maxDateTimeString,
                        slotDuration: 15
                    },
                    resources: orderedResources,
                    unscheduledResources: data.unscheduledResources,
                    providerColourScheme: PROVIDER_SCHEME, //APPOINTMENT_COLOUR_SCHEME,
                    unplacedAppointment: null,
                    selectedWeekIndex,
                };
            }

            case RES_PRACTICE_DIARY.POPUPS.request: {

                return {
                    ...state,
                    daysActivePopUpsLoaded: false,
                    daysActivePopUps: [],
                };
            }

            case RES_PRACTICE_DIARY.POPUPS.receive: {

                return {
                    ...state,
                    daysActivePopUpsLoaded: true,
                    daysActivePopUps: action.payload,
                };
            }

            case RES_CLINICIAN_DIARY.CLEAR.action:

                const clinicianState = {...state};

                delete clinicianState[`cliniciansEventsLoaded_${action.payload}`];
                delete clinicianState[`cliniciansEvents_${action.payload}`];
                delete clinicianState[`cliniciansHeader_${action.payload}`];
                delete clinicianState[`clinicianResources_${action.payload}`];
                delete clinicianState[`unscheduledResources_${action.payload}`];
                delete clinicianState[`providerColourScheme_${action.payload}`];
                delete clinicianState[`selectedWeekIndex_${action.payload}`];

                return clinicianState;

            case Actions.REQUEST_CLINICIAN_DIARY_EVENTS:

                return {
                    ...state,
                    selectedClinician: action.clinicianId,
                };

            case Actions.RECEIVE_CLINICIAN_DIARY_EVENTS: {

                const masterConfiguration = action.masterConfiguration;
                const {start} = action.date;

                const orderedResources = [];
                let selectedWeekIndex = 0;

                let clinicianData = {
                    resources: [],
                    unscheduledResources: [],
                    events: [],
                    holidays: [],
                };

                const {minDateTimeString, maxDateTimeString} = findMinMaxHours(masterConfiguration, start);

                action.payload.forEach(({user, appointments, diaryEvents, rules}) => {

                    if (rules.length > 0) {

                        const resource =
                            {
                                resourceId: user.id,
                                username: user.username,
                                title: `${user.title.abbreviation === null ? '' : user.title.abbreviation} ${user.firstName} ${user.lastName}`.trim(),
                                color: fullColorHex(user.red, user.green, user.blue).toUpperCase(),
                                gender: user.gender,
                                adHoc: false,
                                startHour: 0,
                                startMin: 0,
                                endHour: 0,
                                endMin: 0,
                                lbStartHour: 0,
                                lbstartMin: 0,
                                lbEndHour: 0,
                                lbEndMin: 0,
                                lbShown: false,
                                rule: null,
                                status: rules[0].user.status,
                            };

                        rules.forEach(rule => {

                            switch (rule.type) {
                                case SR_CLINIC :

                                    // if we have an rule it gives a header more than one rule would give multiple headers.
                                    const weekIndex = Math.abs(start.diff(rule.startingOn, 'week') % 4);

                                    // iterate of the four week range of the schedule rule
                                    for (let week = 0; week < 4; week++) {

                                        // if the requested date / week matches the rule / week act upon it
                                        if (week === weekIndex) {

                                            selectedWeekIndex = weekIndex;

                                            // now iterate through the days of the week
                                            for (let day = 0; day < 7; day++) {

                                                // if the requested date / day matches the rule / day act upon it and the day is active
                                                if (start.weekday() === day && rule.days[week][day]) {
                                                    resource.adHoc = rule.adHoc;
                                                    resource.startHour = rule.startHour[week][day];
                                                    resource.startMin = rule.startMin[week][day];
                                                    resource.endHour = rule.endHour[week][day];
                                                    resource.endMin = rule.endMin[week][day];
                                                    resource.lbStartHour = rule.lbStartHour[week][day];
                                                    resource.lbstartMin = rule.lbstartMin[week][day];
                                                    resource.lbEndHour = rule.lbEndHour[week][day];
                                                    resource.lbEndMin = rule.lbEndMin[week][day];
                                                    resource.lbShown = rule.lunchShown[week][day];
                                                    resource.rule = rule;

                                                    if (!resourceHeld(clinicianData, resource)) {
                                                        clinicianData.resources.push(resource);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    break;
                                case SR_HOLIDAY :

                                    let startingOn = moment(rule.startingOn);
                                    startingOn.hour(0);
                                    startingOn.minute(0); // this ensure the day is matched, the calendar will deal with the actual start / stop time

                                    let endingOn = moment(rule.endingOn);
                                    endingOn.hour(23);
                                    endingOn.minute(59); // this ensure the day is matched, the calendar will deal with the actual start / stop time

                                    const currentDate = action.date.start;

                                    const include = currentDate.isBetween(startingOn, endingOn);

                                    if (include) {

                                        const start = moment(currentDate);
                                        start.hour(rule.startHour[0][0]);
                                        start.minute(rule.startMin[0][0]);

                                        const end = moment(currentDate);
                                        end.hour(rule.endHour[0][0]);
                                        end.minute(rule.endMin[0][0]);

                                        resource.rule = rule;

                                        const holidayEvent = {
                                            id: rule.id,
                                            resourceId: resource.resourceId,
                                            userId: resource.resourceId,
                                            title: 'Holiday',
                                            start: start.toDate(),
                                            end: end.toDate(),
                                            comment: 'Holiday',
                                            color: fullColorHexColonSep(action.colours.HOLIDAY_COLOUR),
                                            type: 0,
                                            discriminator: SR_HOLIDAY,
                                            source: rule,
                                        };
                                        if (!holidayHeld(clinicianData, resource)) {
                                            clinicianData.holidays.push(resource);
                                            clinicianData.events.push(holidayEvent);
                                        }
                                    }
                                    break;
                                default:
                                    break;

                            }
                        });

                        appointments.forEach(event => {

                            const appointment = convertAppointmentDAOtoEvent(event);
                            clinicianData.events.push(appointment);
                        });

                        diaryEvents.forEach(event => {

                            const diaryEvent = convertDiaryEventDAOtoEvent(event);
                            clinicianData.events.push(diaryEvent);
                        });
                    }
                });

                // now sort the resources
                const order = state.sortOrder.split(':');

                order.forEach(username => {
                    const match = _.find(clinicianData.resources, resource => resource.username === username);
                    if (match !== undefined) {
                        orderedResources.push(match);
                    }
                });

                state[`cliniciansEvents_${action.clinicianId}`] = clinicianData.events;
                state[`cliniciansEventsLoaded_${action.clinicianId}`] = true;
                state[`cliniciansHeader_${action.clinicianId}`] = {
                    left: "prev,next today",
                    center: "title",
                    right: "month,agendaWeek,agendaDay",
                    min: minDateTimeString,
                    max: maxDateTimeString,
                    slotDuration: 15
                };
                state[`clinicianResources_${action.clinicianId}`] = orderedResources;
                state[`unscheduledResources_${action.clinicianId}`] = clinicianData.unscheduledResources;
                state[`providerColourScheme_${action.clinicianId}`] = PROVIDER_SCHEME;
                state[`selectedWeekIndex_${action.clinicianId}`] = selectedWeekIndex;

                return {
                    ...state,
                    unplacedAppointment: null,
                };
            }

            case Actions.RECEIVE_DIARY_PALLETE_ENTRIES:

                const _movingEvents = action.payload !== undefined && action.payload[0].constructor === Array ? action.payload[0] : [];
                const movingEvents = _movingEvents.map(event => {
                    return {...event, paletteType: PALETTE_GRP_MATM}
                });

                const _recallEvents = action.payload !== undefined && action.payload[1].constructor === Array ? action.payload[1] : [];
                const recallEvents = _recallEvents.map(event => {
                    return {...event, paletteType: PALETTE_GRP_RAPS}
                });

                const _diaryTasks = action.payload !== undefined && action.payload[2].constructor === Array ? action.payload[2] : [];
                const diaryTasks = _diaryTasks.map(event => {
                    return {...event, paletteType: PALETTE_GRP_DTSK}
                });

                return {
                    ...state,
                    movingEventsLoaded: true,
                    movingEvents,
                    recallEvents,
                    diaryTasks,
                };

            case Actions.RECEIVE_ADD_DIARYEVENT:

                return {
                    ...state,
                    newDiaryEvent: action.payload,
                };

            case Actions.REQUEST_PREV_EVENT:

                return {
                    ...state,
                    prevDiaryEvent: undefined,
                    prevDiaryEventLoaded: false,
                };

            case Actions.RECEIVE_PREV_EVENT:

                return {
                    ...state,
                    prevDiaryEvent: convertDiaryEventDAOtoEvent(action.payload),
                    prevDiaryEventLoaded: true,
                };

            case Actions.REQUEST_NEXT_EVENT:

                return {
                    ...state,
                    nextDiaryEvent: undefined,
                    nextDiaryEventLoaded: false,
                };

            case Actions.RECEIVE_NEXT_EVENT:

                    return {
                    ...state,
                    nextDiaryEvent: convertDiaryEventDAOtoEvent(action.payload),
                    nextDiaryEventLoaded: true,
                };

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Diary Related

            case Actions.WSM_APPOINTMENTS: {

                switch (action.payload.function) {

                    case MOVING_IN_DIARY: {

                        let dao = action.payload.content;

                        // deal with clinician diary
                        const clinicianId = dao.providerId;
                        const clinicianLoaded = state[`cliniciansEventsLoaded_${clinicianId}`];
                        const cliniciansEvents = state[`cliniciansEvents_${clinicianId}`];

                        if (clinicianLoaded) {
                            const index = _.findIndex(cliniciansEvents, (target) => dao.id === target.id);

                            if (index !== -1) {
                                cliniciansEvents.splice(index, 1);
                            }
                        }

                        // deal with practice diary
                        const events = [...state.events];
                        const index = _.findIndex(events, (target) => dao.id === target.id);

                        // if this is the originating client we will already have the appointment. Otherwise add it in.
                        if (index !== -1) {
                            events.splice(index, 1);
                            return {
                                ...state,
                                eventsLoaded: true,
                                events,
                                [`cliniciansEventsLoaded_${clinicianId}`]: clinicianLoaded,
                                [`cliniciansEvents_${clinicianId}`]: cliniciansEvents,
                            };
                        } else {
                            return state;
                        }
                    }
                    case ADDBACK:
                    case ADD:

                        let dao = action.payload.content;

                        // deal with clinician diary
                        const clinicianId = dao.providerId;
                        const clinicianLoaded = state[`cliniciansEventsLoaded_${clinicianId}`];
                        const cliniciansEvents = state[`cliniciansEvents_${clinicianId}`];

                        if (clinicianLoaded) {
                            const index = _.findIndex(cliniciansEvents, (target) => dao.id === target.id);

                            // if this is the originating client we will already have the appointment. Otherwise add it in.
                            if (index === -1) {

                                const event = convertAppointmentDAOtoEvent(dao);
                                cliniciansEvents.push(event);
                            }
                        }

                        // deal with practice diary
                        const events = [...state.events];
                        const index = _.findIndex(events, (target) => dao.id === target.id);

                        // if this is the originating client we will already have the appointment. Otherwise add it in.
                        if (index === -1) {

                            const event = convertAppointmentDAOtoEvent(dao);
                            events.push(event);
                        }
                        return {
                            ...state,
                            eventsLoaded: true,
                            events,
                            [`cliniciansEventsLoaded_${clinicianId}`]: clinicianLoaded,
                            [`cliniciansEvents_${clinicianId}`]: cliniciansEvents,
                        };

                    case UPDATE: {

                        const events = [...state.events];
                        let dao = action.payload.content;

                        const appointment = convertAppointmentDAOtoEvent(dao);
                        const index = _.findIndex(events, (target) => dao.id === target.id);

                        events[index] = appointment;
                        return {
                            ...state,
                            eventsLoaded: true,
                            events
                        }
                    }

                    case IN_CHAIR: {

                        const events = [...state.events];
                        let dao = action.payload.content;

                        const index = _.findIndex(events, (target) => dao.id === target.id);
                        events[index].source.inSurgeryTime = 1;
                        return {
                            ...state,
                            eventsLoaded: true,
                            events
                        }
                    }

                    case LEFT_CHAIR: {

                        const events = [...state.events];
                        let dao = action.payload.content;

                        const index = _.findIndex(events, (target) => dao.id === target.id);
                        events[index].source.checkedOutTime = 1;
                        return {
                            ...state,
                            eventsLoaded: true,
                            events
                        }
                    }

                    case DELETE: {

                        const events = [...state.events];

                        let dao = action.payload.content;

                        const index = _.findIndex(events, (target) => dao === parseInt(target.id, 10));
                        events.splice(index, 1);
                        return {
                            ...state,
                            eventsLoaded: true,
                            events
                        }
                    }

                    case ADDBACK_MULTI:
                        return {
                            ...state,
                        };

                    default:
                        break;
                }
                break;
            }

            case Actions.WSM_UPDATES: {

                const updateState = {...state};

                switch (action.payload.function) {

                    case UP_ADD_DIARYEVENT: {

                        const event = action.payload.content; // extract diary event
                        const diaryEvent = convertDiaryEventDAOtoEvent(event);
                        updateState.events.push(diaryEvent);

                        const cliniciansEventsLoaded = updateState[`cliniciansEventsLoaded_${event.eventForId}`];

                        if (cliniciansEventsLoaded) {
                            updateState[`cliniciansEvents_${event.eventForId}`].push(diaryEvent);
                        }
                        break;
                    }

                    case UP_DIARYEVENT: {

                        const diaryEvent = convertDiaryEventDAOtoEvent(action.payload.content);
                        const index = _.findIndex(updateState.events, (target) => diaryEvent.id === target.id);
                        updateState.events[index] = diaryEvent;

                        const {content} = action.payload;
                        const targetId = content.eventForId !== undefined ? content.eventForId : content.userId;

                        const cliniciansEventsLoaded = updateState[`cliniciansEventsLoaded_${targetId}`];

                        if (cliniciansEventsLoaded) {
                            const index = _.findIndex(updateState[`cliniciansEvents_${targetId}`], (target) => action.payload.content.id === target.id);
                            updateState[`cliniciansEvents_${targetId}`][index] = diaryEvent;
                        }

                        break;
                    }

                    case UP_DEL_DIARYEVENT: {

                        const index = _.findIndex(updateState.events, (target) => action.payload.content.id === target.id);
                        updateState.events.splice(index, 1);

                        const cliniciansEventsLoaded = updateState[`cliniciansEventsLoaded_${action.payload.content.eventFor.id}`];
                        if (cliniciansEventsLoaded) {
                            const index = _.findIndex(updateState[`cliniciansEvents_${action.payload.content.eventFor.id}`], (target) => action.payload.content.id === target.id);
                            updateState[`cliniciansEvents_${action.payload.content.eventFor.id}`].splice(index, 1);
                        }
                        break;
                    }
                    case UP_NEWTASK:

                        updateState.diaryTasks.push(action.payload.content);
                        break;
                    case UP_TASKUPDATE:

                        const index = _.findIndex(updateState.diaryTasks, (target) => action.payload.content.id === target.id);

                        if (action.payload.content.status !== TASK_STATUS_ACTIVE) {
                            updateState.diaryTasks.splice(index, 1);
                        }
                        break;
                    default:
                        break;

                    case UP_DOCUMENT_USE_AS_PHOTO: {
                        const patientId = parseInt(action.payload.content[0], 10);
                        const patientImageFileName = action.payload.content[1];

                        const events = [...state.events];

                        const index = _.findIndex(events, (target) => patientId === target.patientId);
                        if (index > -1) {
                            events[index].patientImageFileName = `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${patientImageFileName}/${DOC_IMAGE.name}`;
                            events[index].patientThumbFileName = `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${patientImageFileName}`;
                        }
                        return {
                            ...state,
                            events
                        };
                    }
                }

                return {
                    ...updateState,
                }
            }

            case RECEIVE_APP_PATIENT_SEARCH:

                return {
                    ...state,
                    matchedPatients: action.payload
                };

            case Actions.RECEIVE_ADD_APPOINTMENT: {

                const events = [...state.events];
                let event = convertAppointmentDAOtoEvent(action.payload);

                const index = _.findIndex(events, (target) => event.id === target.id);

                // if this is the originating client we will already have the appointment. Otherwise add it in.
                if (index === -1) {
                    events.push(event);
                }

                return {
                    ...state,
                    eventsLoaded: true,
                    events
                };
            }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return {
                    ...state,
                };
        }
    } catch (error) {
        return state;
    }
};

export const convertAppointmentDAOtoEvent = (dao) => {

    return {
        id: dao.id,
        resourceId: dao.providerId,
        userId: dao.providerId,
        patientId: dao.patientId,
        bookedById: dao.bookedById,
        assignedTo: dao.assignedTo,
        title: dao.shortDescription,
        start: moment(dao.start).toDate(),
        end: moment(dao.end).toDate(),
        bookedOn: new Date(dao.bookedOn),
        comment: dao.description,
        patientFullname: `${dao.title} ${dao.patientFirstName} ${dao.patientLastName}`.trim(),
        patientImageFileName: `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${dao.patientImageFileName}/${DOC_IMAGE.name}`,
        patientThumbFileName: `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${dao.patientImageFileName}`,
        firstName: dao.patientFirstName,
        lastName: dao.patientLastName,
        color: fullColorHex(dao.red, dao.green, dao.blue).toUpperCase(),
        gender: dao.gender,
        female: dao.gender === 1,
        type: {id: dao.type},
        attended: dao.attended,
        confirmed: dao.confirmed,
        workRequired: dao.workRequired,
        discriminator: SR_CLINIC,
        source: dao,
    };
};

export const convertAppointmentWeekViewDAOtoEvent = (usersShort, dao) => {

    if (usersShort === null)
        return;

    const user = _.find(usersShort, user => user.username === dao.username);

    if (user === undefined)
        return;

    return {
        id: dao.id,
        resourceId: user.id,
        userId: user.id,
        patientId: dao.patientId,
        start: new Date(dao.start),
        end: new Date(dao.end),
        comment: dao.summary,
        patientFullname: `${dao.abbreviation} ${dao.firstName} ${dao.lastName}`.trim(),
        firstName: dao.firstName,
        lastName: dao.lastName,
        color: fullColorHex(user.red, user.green, user.blue).toUpperCase(),
        type: {id: dao.type},
        discriminator: SR_CLINIC,
        source: dao,
    };
};

const convertDiaryEventDAOtoEvent = (dao) => {

    if (dao.id === null) {
        return {id: null}
    }
    return {
        id: dao.id,
        resourceId: dao.eventForId,
        userId: dao.eventForId,
        title: dao.type.title,
        start: new Date(dao.start),
        end: new Date(dao.end),
        comment: dao.note,
        color: fullColorHex(dao.type.red, dao.type.green, dao.type.blue).toUpperCase(),
        type: dao.type,
        discriminator: SR_NOTAVAILABLE,
        source: dao,
    };
};

const convertDiaryEventWeekViewDAOtoEvent = (usersShort, dao) => {

    if (usersShort === null)
        return;

    const user = _.find(usersShort, user => user.username === dao.username);

    if (user === undefined)
        return;

    return {
        id: dao.id,
        resourceId: user.id,
        userId: user.id,
        title: dao.title,
        start: new Date(dao.start),
        end: new Date(dao.end),
        comment: dao.description,
        color: fullColorHex(dao.red, dao.green, dao.blue).toUpperCase(),
        type: null,
        discriminator: SR_NOTAVAILABLE,
        source: dao,
        display: 'background'
    };
};
