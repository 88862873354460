import React from 'react';
import {connect} from 'react-redux';
import {ac} from "../../../index";

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';

import NewPatientsSection from './Sections/NewPatients';
import AppointmentsSection from "./Sections/Appointments";
import AppointmentsBookedSection from "./Sections/AppointmentsBooked";
import TreatmentPlanningSection from "./Sections/TreatmentPlanning";
import ActivePatientsSection from "./Sections/ActivePatients";
import ReferralSourcesSection from "./Sections/ReferralSources";

import * as Sections from "./Constants";
import {TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_HELP} from "../../../icons";
import {tb_boilerPlateRight} from "../../Utils";
import {HELP_REP_KPIS} from "../../../actions/stateManagement";
import {PDFBaseComponent} from "../../PDFBaseComponent";

class ConnectedKPIs extends PDFBaseComponent {

    constructor(props) {
        super();
        this.state = {
            props,
            mcId: ac.getMcId(),
            lastSelectedSection: 0,
        };

        this.tabStack = [];
        this.index = 0;

        this.onTabChange = this.onTabChange.bind(this);
    }

    onTabChange(index) {

        const tab = this.tabStack.find(tab => tab.index === index);
        this.setState({activeIndex: index, lastSelectedSection: tab.index});
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.NEW_PATIENTS.id, content: <TabPanel key='newPatients'
                                                                      header={Sections.NEW_PATIENTS.header}>
                    <NewPatientsSection onChange={this.onChange}
                                        {...this.props}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.ACTIVE_PATIENTS.id, content: <TabPanel key='activePatients'
                                                                         header={Sections.ACTIVE_PATIENTS.header}>
                    <ActivePatientsSection onChange={this.onChange}
                                           {...this.props}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.APPOINTMENTS_BOOKED.id, content: <TabPanel key='appointmentsBooked'
                                                                             header={Sections.APPOINTMENTS_BOOKED.header}>
                    <AppointmentsBookedSection onChange={this.onChange}
                                               {...this.props}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.APPOINTMENTS.id, content: <TabPanel key='appointments'
                                                                      header={Sections.APPOINTMENTS.header}>
                    <AppointmentsSection onChange={this.onChange}
                                         {...this.props}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.REFERRAL_SOURCES.id, content: <TabPanel key='referralSources'
                                                                          header={Sections.REFERRAL_SOURCES.header}>
                    <ReferralSourcesSection onChange={this.onChange}
                                            {...this.props}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.TREATMENT_PLANNING.id, content: <TabPanel key='treatmentPlanning'
                                                                            header={Sections.TREATMENT_PLANNING.header}>
                    <TreatmentPlanningSection onChange={this.onChange}
                                              {...this.props}
                    />
                </TabPanel>
            });

        let key = 1000;

        return (
            <div id="detailPanel">

                <Toolbar right={<React.Fragment>
                    {tb_boilerPlateRight(() => window.open(HELP_REP_KPIS), ICON_HELP, 'Knowledge Base', key++, true)}
                    {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                </React.Fragment>}
                />

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = state => {
    return {}
};

const MapDispatchToProps = dispatch => {
    return {}
};

const KPIs = connect(MapStateToProps, MapDispatchToProps)(ConnectedKPIs);

export default KPIs;

