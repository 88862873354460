import _ from "lodash";
import * as Actions from "../actions/index";
import {
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_ASSCATS,
    RES_HOUSEKEEPING_BASE_CHART_FAVS,
    RES_HOUSEKEEPING_CCS,
    RES_HOUSEKEEPING_CHART_ITEMS,
    RES_HOUSEKEEPING_CRS,
    RES_HOUSEKEEPING_CSCHED,
    RES_HOUSEKEEPING_DOC_CLASSIFICATIONS,
    RES_HOUSEKEEPING_FAVS,
    RES_HOUSEKEEPING_JSTAGES,
    RES_HOUSEKEEPING_MCS,
    RES_HOUSEKEEPING_MEDICATIONS,
    RES_HOUSEKEEPING_OCCS,
    RES_HOUSEKEEPING_QUES,
    RES_HOUSEKEEPING_RECTYPES,
    RES_HOUSEKEEPING_TCODES,
    RES_HOUSEKEEPING_TITLES,
    RES_HOUSEKEEPING_ZONES,
    RES_PATIENT_DOC_TEMPLATE,
    RES_TEMPLATE_NOTES,
    RES_TEMPLATE_NOTES_FAVS,
    RES_TEMPLATES_APPOINTMENT_LETTER,
    RES_TEMPLATES_CONSENT_FORMS,
    RES_TEMPLATES_PATIENT,
    RES_TEMPLATES_PAYMENT_PLANS,
    RES_TEMPLATES_PRESCRIPTIONS,
    RES_TEMPLATES_REFERRAL,
    RES_TEMPLATES_SYSTEM,
    RES_TEMPLATES_TREATMENT_PLANS
} from "../actions/housekeeping";
import {DOC_SYSTEM_TEMPLATE, UP_RULEADD, UP_RULEREMOVE, UP_RULEUPDATE, UP_TEMPLATE_ADDED} from "../Constants";

const initialState = {

    titlesLoaded: false,
    titles: [],

    treatmentCodesLoaded: false,
    treatmentCodes: [],

    chartingItemsLoaded: false,
    chartingItems: [],

    journeyStagesLoaded: false,
    journeyStages: [],

    classificationsLoaded: false,
    classifications: [],

    recallTypesLoaded: false,
    recallTypes: [],

    treatmentFavouritesLoaded: false,
    treatmentFavourites: [],

    baseChartFavouritesLoaded: false,
    baseChartFavourites: [],

    templateNoteFavouritesLoaded: false,
    templateNoteFavourites: [],

    costCentersLoaded: false,
    costCenters: [],

    appointmentDiaryTypesLoaded: false,
    appointmentDiaryTypes: [],

    medicalConditionsLoaded: false,
    medicalConditions: [],

    medicationsLoaded: false,
    medications: [],

    questionsLoaded: false,
    questions: [],

    cancellationReasonsLoaded: false,
    cancellationReasons: [],

    occupationsLoaded: false,
    occupations: [],

    assCatsLoaded: false,
    assCats: [],

    reportTypesLoaded: false,
    reportTypes: [],

    systemTemplatesLoaded: false,
    systemTemplates: [],

    patientTemplatesLoaded: false,
    patientTemplates: [],

    patientDocTemplatesLoaded: false,
    patientDocTemplates: [],

    templateNotesLoaded: false,
    templateNotes: [],

    tpTemplatesLoaded: false,
    tpTemplates: [],

    ppTemplatesLoaded: false,
    ppTemplates: [],

    alTemplatesLoaded: false,
    alTemplates: [],

    cfTemplatesLoaded: false,
    cfTemplates: [],

    prescriptionTemplateNotesLoaded: false,
    prescriptionTemplateNotes: [],

    schedulesLoaded: false,
    schedules: [],

    consentFormsLoaded: false,
    consentForms: [],

    creditLimit: 0,

    zoneEventsLoaded: false,
    zoneEvents: [
        [
            [], [], [], [], [], [], []
        ],
        [
            [], [], [], [], [], [], []
        ],
        [
            [], [], [], [], [], [], []
        ],
        [
            [], [], [], [], [], [], []
        ]
    ],
};

export const housekeeping = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case RES_HOUSEKEEPING_TITLES.GET.receive:

                return {
                    ...state,
                    titlesLoaded: true,
                    titles: action.payload,
                };

            case RES_HOUSEKEEPING_TITLES.SAVE.action: {

                let newTitles = [...state.titles];
                const index = _.findIndex(state.titles, (title) => {
                    return title.id === action.payload.id
                });

                if (index < 0) {
                    newTitles.unshift(action.payload);
                } else {
                    newTitles[index] = action.payload;
                }

                return {
                    ...state,
                    titles: newTitles,
                };
            }
            case RES_HOUSEKEEPING_TITLES.DELETE.action:

                if (action.deleted) {

                    return {
                        ...state,
                        deleted: true,
                        titles: _.filter(state.titles, (title) => {
                            return title.id !== action.payload
                        }),
                    }
                } else {
                    return {
                        ...state,
                        deleted: false,
                    }
                }

            case RES_HOUSEKEEPING_TCODES.GET.receive:

                return {
                    ...state,
                    treatmentCodesLoaded: true,
                    treatmentCodes: action.payload,
                };

            case RES_HOUSEKEEPING_TCODES.SAVE.action:

                const newTreatmentCodes = {...state.treatmentCodes};

                const returns = action.payload;

                returns.forEach(item => {
                    const index = _.findIndex(newTreatmentCodes.items, (treatmentCode) => {
                        return treatmentCode.id === item.id
                    });

                    if (index < 0) {
                        newTreatmentCodes.items.unshift(item);
                    } else {
                        newTreatmentCodes.items[index] = item;
                    }
                });
                return {
                    ...state,
                    treatmentCodes: newTreatmentCodes,
                };

            case RES_HOUSEKEEPING_FAVS.SAVE.action: {

                const newTreatmentFavourites = [...state.treatmentFavourites];

                const returns = action.payload;

                returns.forEach(item => {
                    const index = _.findIndex(newTreatmentFavourites, (treatmentFavourite) => {
                        return treatmentFavourite.id === item.id
                    });

                    if (index < 0) {
                        newTreatmentFavourites.unshift(item);
                    } else {
                        newTreatmentFavourites[index] = item;
                    }
                });

                return {
                    ...state,
                    treatmentFavourites: newTreatmentFavourites,
                }
            }

            case RES_HOUSEKEEPING_CHART_ITEMS.GET.receive:

                return {
                    ...state,
                    chartingItemsLoaded: true,
                    chartingItems: action.payload,
                };

            case RES_HOUSEKEEPING_BASE_CHART_FAVS.GET.receive:

                return {
                    ...state,
                    baseChartFavouritesLoaded: true,
                    baseChartFavourites: action.payload,
                };

            case RES_HOUSEKEEPING_BASE_CHART_FAVS.SAVE.action: {

                const newFavourites = [...state.baseChartFavourites];

                const returns = action.payload;

                returns.forEach(item => {
                    const index = _.findIndex(newFavourites, (favourite) => {
                        return favourite.id === item.id
                    });

                    if (index < 0) {
                        newFavourites.unshift(item);
                    } else {
                        newFavourites[index] = item;
                    }
                });

                return {
                    ...state,
                    baseChartFavourites: newFavourites,
                }
            }

            case RES_HOUSEKEEPING_BASE_CHART_FAVS.DELETE.action:

                return {
                    ...state,
                    baseChartFavourites: _.filter(state.baseChartFavourites, (baseChartFavourite) => {
                        return baseChartFavourite.id !== action.payload
                    }),
                };

            case RES_HOUSEKEEPING_JSTAGES.SAVE.action: {

                const newJourneyStages = [...state.journeyStages];

                const returns = action.payload;

                returns.forEach(item => {
                    const index = _.findIndex(newJourneyStages, (journeyStage) => {
                        return journeyStage.id === item.id
                    });

                    if (index < 0) {
                        newJourneyStages.unshift(item);
                    } else {
                        newJourneyStages[index] = item;
                    }
                });

                return {
                    ...state,
                    journeyStages: [...newJourneyStages],
                }
            }

            case RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.SAVE.action: {

                const newClassifications = [...state.classifications];

                const returns = action.payload;

                returns.forEach(item => {
                    const index = _.findIndex(newClassifications, (classification) => {
                        return classification.id === item.id
                    });

                    if (index < 0) {
                        newClassifications.unshift(item);
                    } else {
                        newClassifications[index] = item;
                    }
                });

                return {
                    ...state,
                    classification: [...newClassifications],
                }
            }

            case RES_HOUSEKEEPING_RECTYPES.SAVE.action: {

                const appointmentDiaryTypes = {...state.appointmentDiaryTypes};
                const newRecallTypes = [...appointmentDiaryTypes.recallTypes];

                const returns = action.payload;

                returns.forEach(item => {
                    const index = _.findIndex(newRecallTypes, (recallTypes) => {
                        return recallTypes.id === item.id
                    });

                    if (index < 0) {
                        newRecallTypes.unshift(item);
                    } else {
                        newRecallTypes[index] = item;
                    }
                });

                appointmentDiaryTypes.recallTypes = newRecallTypes;

                return {
                    ...state,
                    appointmentDiaryTypes,
                }
            }

            case RES_HOUSEKEEPING_TCODES.DELETE.action:

                const items = _.filter(state.treatmentCodes.items, (treatmentCode) => {
                    return treatmentCode.id !== action.payload
                });

                return {
                    ...state,
                    treatmentCodes: {
                        code9000: state.treatmentCodes.code9000,
                        items,
                    },
                };

            case RES_HOUSEKEEPING_CCS.GET.receive:

                return {
                    ...state,
                    costCentersLoaded: true,
                    costCenters: action.payload,
                };

            case RES_HOUSEKEEPING_CCS.SAVE.action: {

                return {
                    ...state,
                    costCenters: action.payload
                }
            }

            case RES_HOUSEKEEPING_CCS.DELETE.action:

                return {
                    ...state,
                    costCenters: _.filter(state.costCenters, (costCenter) => {
                        return costCenter.id !== action.payload
                    }),
                };

            case RES_HOUSEKEEPING_JSTAGES.GET.receive:

                const journeyStageActions = [];

                action.payload.journeyStageActions.elements.forEach((item) => {

                    let action = JSON.parse(item.type);
                    action['name'] = item.name;
                    journeyStageActions.push(action);
                });

                return {
                    ...state,
                    journeyStagesLoaded: true,
                    journeyStages: action.payload.stages,
                    journeyStageActions,
                };

            case RES_HOUSEKEEPING_JSTAGES.DELETE.action:

                return {
                    ...state,
                    journeyStages: _.filter(state.journeyStages, (journeyStage) => {
                        return journeyStage.id !== action.payload.id
                    }),
                };

            case RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.GET.receive:

                return {
                    ...state,
                    classificationsLoaded: true,
                    classifications: action.payload,
                };

            case RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.DELETE.action:

                return {
                    ...state,
                    classifications: _.filter(state.classifications, (classifications) => {
                        return classifications.id !== action.payload.id
                    }),
                };

            case RES_HOUSEKEEPING_FAVS.GET.receive:

                return {
                    ...state,
                    treatmentFavouritesLoaded: true,
                    treatmentFavourites: action.payload,
                };

            case RES_HOUSEKEEPING_RECTYPES.GET.receive:

                return {
                    ...state,
                    recallTypesLoaded: true,
                    recallTypes: action.payload,
                };

            case RES_HOUSEKEEPING_FAVS.DELETE.action:

                return {
                    ...state,
                    treatmentFavourites: _.filter(state.treatmentFavourites, (treatmentFavourite) => {
                        return treatmentFavourite.id !== action.payload
                    }),
                };

            case RES_HOUSEKEEPING_CSCHED.GET.receive:

                return {
                    ...state,
                    schedulesLoaded: true,
                    schedules: action.payload,
                };

            case RES_HOUSEKEEPING_CSCHED.SAVE.action: {

                const newSchedules = [...state.schedules];
                const index = _.findIndex(newSchedules, (schedule) => {
                    return schedule.id === action.payload.id
                });

                if (index < 0) {
                    newSchedules.unshift(action.payload);
                } else {
                    newSchedules[index] = action.payload;
                }

                return {
                    ...state,
                    schedules: newSchedules,
                }
            }

            case RES_HOUSEKEEPING_ZONES.GET.request:

                return {
                    ...state,
                    zoneEventsLoaded: false,
                    zoneEvents: [
                        [
                            [], [], [], [], [], [], []
                        ],
                        [
                            [], [], [], [], [], [], []
                        ],
                        [
                            [], [], [], [], [], [], []
                        ],
                        [
                            [], [], [], [], [], [], []
                        ]
                    ]
                };

            case RES_HOUSEKEEPING_ZONES.GET.receive:

                // Initialize an empty 4x7 matrix
                const zoneEvents = Array.from({ length: 4 }, () =>
                    Array.from({ length: 7 }, () => [])
                );

                // Iterate over the flat array of events
                action.payload.forEach(event => {
                    const { zoneIndex, zoneDayIndex } = event;

                    // Ensure zoneIndex and zoneDayIndex are within valid ranges
                    if (zoneIndex >= 0 && zoneIndex < 4 && zoneDayIndex >= 0 && zoneDayIndex < 7) {
                        // Add event to the appropriate position in the matrix

                        const currentStart = new Date();
                        const start = new Date(event.start);
                        currentStart.setHours(start.getHours());
                        currentStart.setMinutes(start.getMinutes());

                        const currentEnd = new Date();
                        const end = new Date(event.end);
                        currentEnd.setHours(end.getHours());
                        currentEnd.setMinutes(end.getMinutes());

                        const convertedEvent = {...event, start: currentStart, end: currentEnd};

                        zoneEvents[zoneIndex][zoneDayIndex].push(convertedEvent);
                    }
                });

                return {
                    ...state,
                    zoneEventsLoaded: true,
                    [`zoneEvents_${action.clinicianId}`]: zoneEvents
                }

            case RES_HOUSEKEEPING_ADETS.AT_SAVE.action: {

                const apTypes = [...state.appointmentDiaryTypes.appointmentTypes];

                const returns = action.payload;

                returns.forEach(item => {
                    const index = _.findIndex(apTypes, (apType) => {
                        return apType.id === item.id
                    });

                    if (index < 0) {
                        apTypes.unshift(item);
                    } else {
                        apTypes[index] = item;
                    }
                });

                return {
                    ...state,
                    appointmentDiaryTypes: {
                        appointmentTypes: apTypes,
                        diaryEventTypes: [...state.appointmentDiaryTypes.diaryEventTypes],
                    }
                }
            }
            case RES_HOUSEKEEPING_ADETS.DE_SAVE.action: {

                const deTypes = [...state.appointmentDiaryTypes.appointmentTypes];

                const returns = action.payload;

                returns.forEach(item => {
                    const index = _.findIndex(deTypes, (deType) => {
                        return deType.id === item.id
                    });

                    if (index < 0) {
                        deTypes.unshift(item);
                    } else {
                        deTypes[index] = item;
                    }
                });

                return {
                    ...state,
                    appointmentDiaryTypes: {
                        diaryEventTypes: deTypes,
                        appointmentTypes: [...state.appointmentDiaryTypes.appointmentTypes],
                    }
                }
            }

            case RES_HOUSEKEEPING_ADETS.GET.receive:

                let newAppointmentDiaryTypes = {
                    appointmentTypes: [...action.payload.appointmentTypes],
                    diaryEventTypes: [...action.payload.diaryEventTypes],
                    recallTypes: [...action.payload.recallTypes],
                    recallTemplates: [...action.payload.recallTemplates],
                    reportTexts: [...action.payload.reportTexts],
                    reportTextTypes: [],
                };
                const reportTextTypes = [];

                action.payload.reportTextTypes.elements.forEach((item) => {

                    let reportTextType = JSON.parse(item.type);
                    reportTextType['name'] = item.name;
                    reportTextTypes.push(reportTextType);
                });

                newAppointmentDiaryTypes.reportTextTypes = reportTextTypes;

                return {
                    ...state,
                    appointmentDiaryTypesLoaded: true,
                    appointmentDiaryTypes: newAppointmentDiaryTypes,
                };

            case RES_HOUSEKEEPING_ADETS.AT_DELETE.action: {

                const appointmentDiaryTypes = {...state.appointmentDiaryTypes};

                const appointmentTypes = _.filter(appointmentDiaryTypes.appointmentTypes, (appointmentType) => {
                    return appointmentType.id !== action.payload;
                });
                appointmentDiaryTypes.appointmentTypes = appointmentTypes;

                return {
                    ...state,
                    appointmentDiaryTypes
                };
            }
            case RES_HOUSEKEEPING_ADETS.DE_DELETE.action: {

                const appointmentDiaryTypes = {...state.appointmentDiaryTypes};

                const diaryEventTypes = _.filter(appointmentDiaryTypes.diaryEventTypes, (diaryEventType) => {
                    return diaryEventType.id !== action.payload;
                });
                appointmentDiaryTypes.diaryEventTypes = diaryEventTypes;

                return {
                    ...state,
                    appointmentDiaryTypes
                };
            }

            case RES_HOUSEKEEPING_MCS.GET.receive:

                return {
                    ...state,
                    medicalConditionsLoaded: true,
                    medicalConditions: action.payload,
                };

            case RES_HOUSEKEEPING_MCS.SAVE.action: {

                const newMedicalConditions = [...state.medicalConditions];

                const returns = action.payload;

                returns.forEach(condition => {
                    const index = _.findIndex(newMedicalConditions, (medicalCondition) => {
                        return medicalCondition.id === condition.id
                    });

                    if (index < 0) {
                        newMedicalConditions.unshift(condition);
                    } else {
                        newMedicalConditions[index] = condition;
                    }
                });

                return {
                    ...state,
                    medicalConditions: newMedicalConditions,
                }
            }

            case RES_HOUSEKEEPING_MCS.DELETE.action:

                return {
                    ...state,
                    medicalConditions: _.filter(state.medicalConditions, (medicalCondition) => {
                        return medicalCondition.id !== action.payload
                    }),
                };

            case RES_HOUSEKEEPING_MEDICATIONS.GET.receive:

                return {
                    ...state,
                    medicationsLoaded: true,
                    medications: action.payload,
                };

            case RES_HOUSEKEEPING_MEDICATIONS.SAVE.action: {

                const newMedications = [...state.medications];

                const returns = action.payload;

                returns.forEach(medication => {
                    const index = _.findIndex(newMedications, (medicationItem) => {
                        return medicationItem.id === medication.id
                    });

                    if (index < 0) {
                        newMedications.unshift(medication);
                    } else {
                        newMedications[index] = medication;
                    }
                });

                return {
                    ...state,
                    medications: newMedications,
                }
            }

            case RES_HOUSEKEEPING_MEDICATIONS.DELETE.action:

                return {
                    ...state,
                    medications: _.filter(state.medications, (medication) => {
                        return medication.id !== action.payload
                    }),
                };

            case RES_HOUSEKEEPING_QUES.GET.receive:

                return {
                    ...state,
                    questionsLoaded: true,
                    questions: action.payload,
                };

            case RES_HOUSEKEEPING_QUES.SAVE.action: {

                const newQuestions = [...state.questions];

                const returns = action.payload;

                returns.forEach(question => {
                    const index = _.findIndex(newQuestions, (targetQuestion) => {
                        return targetQuestion.id === question.id
                    });

                    if (index < 0) {
                        newQuestions.unshift(question);
                    } else {
                        newQuestions[index] = question;
                    }
                });

                return {
                    ...state,
                    questions: newQuestions,
                }
            }

            case RES_HOUSEKEEPING_QUES.DELETE.action:

                return {
                    ...state,
                    questions: _.filter(state.questions, (question) => {
                        return question.id !== action.payload
                    }),
                };

            case RES_HOUSEKEEPING_CRS.GET.receive:

                return {
                    ...state,
                    cancellationReasonsLoaded: true,
                    cancellationReasons: action.payload,
                };

            case RES_HOUSEKEEPING_CRS.SAVE.action: {

                const newCancellationReasons = [...state.cancellationReasons];
                const index = _.findIndex(newCancellationReasons, (cancellationReason) => {
                    return cancellationReason.id === action.payload.id
                });

                if (index < 0) {
                    newCancellationReasons.unshift(action.payload);
                } else {
                    newCancellationReasons[index] = action.payload;
                }

                return {
                    ...state,
                    cancellationReasons: newCancellationReasons,
                }
            }

            case RES_HOUSEKEEPING_CRS.DELETE.action:

                return {
                    ...state,
                    cancellationReasons: _.filter(state.cancellationReasons, (cancellationReason) => {
                        return cancellationReason.id !== action.payload
                    }),
                };

            case RES_HOUSEKEEPING_OCCS.GET.receive:

                return {
                    ...state,
                    occupationsLoaded: true,
                    occupations: action.payload,
                };

            case RES_HOUSEKEEPING_OCCS.SAVE.action: {

                const newOccupations = [...state.occupations];
                const index = _.findIndex(newOccupations, (occupation) => {
                    return occupation.id === action.payload.id
                });

                if (index < 0) {
                    newOccupations.unshift(action.payload);
                } else {
                    newOccupations[index] = action.payload;
                }

                return {
                    ...state,
                    occupations: newOccupations,
                }
            }

            case RES_HOUSEKEEPING_OCCS.DELETE.action:

                return {
                    ...state,
                    occupations: _.filter(state.occupations, (occupation) => {
                        return occupation.id !== action.payload
                    }),
                };

            case RES_HOUSEKEEPING_ASSCATS.GET.receive:

                return {
                    ...state,
                    assCatsLoaded: true,
                    assCats: action.payload,
                };

            case RES_HOUSEKEEPING_ASSCATS.SAVE.action: {

                const newAssCats = [...state.assCats];
                const index = _.findIndex(newAssCats, (assCat) => {
                    return assCat.id === action.payload.id
                });

                if (index < 0) {
                    newAssCats.unshift(action.payload);
                } else {
                    newAssCats[index] = action.payload;
                }

                return {
                    ...state,
                    assCats: newAssCats,
                }
            }

            case RES_HOUSEKEEPING_ASSCATS.DELETE.action:

                return state;

            case Actions.RECEIVE_REPORT_TYPES:

                return {
                    ...state,
                    reportTypesLoaded: true,
                    reportTypes: action.payload.reportTypes,
                    docTypes: action.payload.docTypes,
                    patientTemplateTypes: action.payload.patientTemplateTypes,
                };

            case RES_PATIENT_DOC_TEMPLATE.GET.receive:

                return {
                    ...state,
                    patientDocTemplatesLoaded: true,
                    patientDocTemplates: action.payload,
                };

            case RES_TEMPLATES_SYSTEM.GET.receive:

                return {
                    ...state,
                    systemTemplatesLoaded: true,
                    systemTemplates: action.payload,
                };

            case RES_TEMPLATES_SYSTEM.SAVE.action:

                return {
                    ...state,
                    systemTemplateResult: action.payload,
                };

            case RES_TEMPLATES_SYSTEM.COPY.action:

                return {
                    ...state,
                    systemTemplateResult: action.payload,
                };

            case RES_TEMPLATES_PATIENT.GET.receive:

                return {
                    ...state,
                    patientTemplatesLoaded: true,
                    patientTemplates: action.payload,
                };

            case RES_TEMPLATES_PATIENT.SAVE.action:

                const patientTemplates = [...state.patientTemplates];
                patientTemplates.push(action.payload);
                return {
                    ...state,
                    patientTemplates
                };

            case RES_TEMPLATES_PATIENT.COPY.action:

                return {
                    ...state,
                    patientTemplateResult: action.payload,
                };

            case RES_TEMPLATES_SYSTEM.DELETE.action:
            case RES_TEMPLATES_PATIENT.DELETE.action:

                return {
                    ...state,
                    systemTemplates: _.filter(state.systemTemplates, (template) => {
                        return template.docId !== action.payload
                    }),
                    patientTemplates: _.filter(state.patientTemplates, (template) => {
                        return template.docId !== action.payload
                    })
                };

            case RES_TEMPLATE_NOTES.GET.receive:

                if (action.payload.length === 1) {
                    return {
                        ...state,
                        templateNotesLoaded: true,
                        templateNotes: action.payload[0],
                    }
                } else {
                    return {
                        ...state,
                        templateNotesLoaded: true,
                        templateNotes: action.payload[1],
                        defaultPaymentNote: action.payload[0],
                    }
                }

            case RES_TEMPLATE_NOTES.CLEAR.action:

                const templateNoteState = {...state};
                templateNoteState.templateNotes = [];
                templateNoteState.templateNotesLoaded = false;
                templateNoteState.templateNoteFavourites = [];
                templateNoteState.templateNoteFavouritesLoaded = true;

                return templateNoteState;

            case RES_TEMPLATE_NOTES_FAVS.GET.receive:
            case RES_TEMPLATE_NOTES_FAVS.SAVE.action:

                return {
                    ...state,
                    templateNoteFavouritesLoaded: true,
                    templateNoteFavourites: action.payload,
                };

            case RES_TEMPLATE_NOTES_FAVS.DELETE.action:

                return {
                    ...state,
                    templateNoteFavourites: _.filter(state.templateNoteFavourites, (templateNoteFavourite) => {
                        return templateNoteFavourite.id !== action.payload;
                    }),
                };

            case RES_TEMPLATES_TREATMENT_PLANS.GET.receive:

                return {
                    ...state,
                    tpTemplatesLoaded: true,
                    tpTemplates: action.payload,
                };

            case RES_TEMPLATES_PAYMENT_PLANS.GET.receive:

                return {
                    ...state,
                    ppTemplatesLoaded: true,
                    ppTemplates: action.payload,
                };

            case RES_TEMPLATES_APPOINTMENT_LETTER.GET.receive:

                return {
                    ...state,
                    alTemplatesLoaded: true,
                    alTemplates: action.payload,
                };

            case RES_TEMPLATES_CONSENT_FORMS.GET.receive:

                return {
                    ...state,
                    cfTemplatesLoaded: true,
                    cfTemplates: action.payload,
                };

            case RES_TEMPLATES_PRESCRIPTIONS.GET.receive:

                return {
                    ...state,
                    prescriptionTemplateNotesLoaded: true,
                    prescriptionTemplateNotes: action.payload,
                };

            case RES_TEMPLATES_REFERRAL.GET.receive:

                return {
                    ...state,
                    referralTemplatesLoaded: true,
                    referralTemplates: action.payload,
                };

            case Actions.RECEIVE_CREDIT_LIMIT:

                return {
                    ...state,
                    creditLimit: action.payload,
                };

            case Actions.WSM_UPDATES:

                switch (action.payload.function) {

                    case UP_RULEADD :

                        const schedules = [...state.schedules];
                        let schedule = action.payload.content;

                        schedules.push(schedule);

                        return {
                            ...state,
                            schedules
                        };

                    case UP_RULEUPDATE : {

                        const schedules = [...state.schedules];
                        let schedule = action.payload.content;

                        const index = _.findIndex(schedules, (target) => schedule.id === target.id);
                        schedules[index] = schedule;

                        return {
                            ...state,
                            schedules
                        };
                    }

                    case UP_RULEREMOVE : {

                        const schedules = [...state.schedules];
                        let schedule = action.payload.content;

                        // for remove the payload is the removal id.
                        const index = _.findIndex(schedules, (target) => schedule === target.id);
                        schedules.splice(index, 1);

                        return {
                            ...state,
                            schedules
                        };
                    }

                    case UP_TEMPLATE_ADDED : {

                        const patientTemplates = [...state.patientTemplates];
                        const systemTemplates = [...state.systemTemplates];

                        if (action.payload.content.type === DOC_SYSTEM_TEMPLATE.name) {
                            systemTemplates.push(action.payload.content);
                            return {
                                ...state,
                                systemTemplates,
                            };
                        } else {
                            patientTemplates.push(action.payload.content);
                            return {
                                ...state,
                                patientTemplates,
                            };
                        }
                    }

                    default:
                        return state;
                }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
}
