import * as Actions from './index';
import axios from "axios/index";
import {ac} from '../index'
import {RES_PREFERENCES_HOURS} from "./preferences";
import crypto from "crypto";
import jwt from "jsonwebtoken";

// Gateway

export const RES_CHECK_FOR_GATEWAY = {
    CHECK: {
        receive: Actions.RECEIVE_CHECK_FOR_GATEWAY,
        error: Actions.ERROR_CHECK_FOR_GATEWAY,
        url: `http://127.0.0.1:4798/api/v1/Ping`
    },
};

export const getPracticeLoginPageDetails = (mcId) => {

    return (dispatch) => {

        axios.get(`https://${ac.getBASERESTURL()}/practiceLoginPageDetails?mcId=${mcId}`)
            .then(res => {
                dispatch({
                    type: Actions.RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS,
                    payload: {practiceLoginPageDetails: res.data}
                });
            })
            .catch((error) => {
            });
        dispatch({type: Actions.REQUEST_PRACTICE_LOGIN_PAGE_DETAILS, payload: {}});
    }
};

export const getPracticeDetails = (mcId) => {

    return (dispatch) => {

        ac.fetchClient().get(`https://${ac.getBASERESTURL()}/practiceDetails?mcId=${mcId}`)
            .then(res => {
                dispatch({type: Actions.RECEIVE_PRACTICE_DETAILS, payload: {practiceDetails: res.data}});
            })
            .catch((error) => {
            });
        dispatch({type: Actions.REQUEST_PRACTICE_DETAILS, payload: {}});
    }
};

export const getLaboratoryList = () => {

    return (dispatch) => {

        axios.get(`https://${ac.getBASERESTURL()}/laboratoryList`)
            .then(res => {
                dispatch({type: Actions.RECEIVE_LABORATORY_LIST, payload: {laboratoryList: res.data}});
            })
            .catch((error) => {
            });
        dispatch({type: Actions.REQUEST_LABORATORY_LIST, payload: {}});
    }
};

export const getLaboratoryDetails = (mcId) => {

    return (dispatch) => {

        axios.get(`https://${ac.getBASERESTURL()}/laboratoryDetails?mcId=${mcId}`)
            .then(res => {
                dispatch({type: Actions.RECEIVE_LABORATORY_DETAILS, payload: {laboratoryDetails: res.data}});
            })
            .catch((error) => {
            });
        dispatch({type: Actions.REQUEST_LABORATORY_DETAILS, payload: {}});
    }
};

const getLoginUser = (dispatch, mcId, id) => {

    ac.fetchClient().get(`https://${ac.getBASERESTURL()}/User/getUser?mcId=${ac.getMcId()}&id=${id}`)
        .then(res => {
            dispatch({type: Actions.RECEIVE_LOGIN_USER_DETAILS, payload: res.data});
        })
        .catch((error) => {
            console.log(error);
        });

    ac.fetchClient().get(`${RES_PREFERENCES_HOURS.GET.url}/?mcId=${ac.getMcId()}`)
        .then(res => {
            dispatch({type: RES_PREFERENCES_HOURS.GET.receive, payload: res.data});
        })
        .catch((error) => {
            console.log(error);
        });
    dispatch({type: Actions.REQUEST_LOGIN_USER_DETAILS, payload: {}});
    dispatch({type: RES_PREFERENCES_HOURS.GET.request, payload: {}});
};

const getAdminLoginUser = (dispatch, id) => {

    ac.fetchClient().get(`https://${ac.getBASERESTURL()}/Admin/User/getAdminUser?id=${id}`)
        .then(res => {
            dispatch({type: Actions.RECEIVE_LOGIN_ADMIN_USER_DETAILS, payload: res.data});
        })
        .catch((error) => {
            console.log(error);
        });
    dispatch({type: Actions.REQUEST_LOGIN_ADMIN_USER_DETAILS, payload: {}});
};

const getLaboratoryLoginUser = (dispatch, id) => {

    ac.fetchClient().get(`https://${ac.getBASERESTURL()}/Laboratory/User/getLaboratoryUser?id=${id}`)
        .then(res => {
            dispatch({type: Actions.RECEIVE_LOGIN_LAB_USER_DETAILS, payload: res.data});
        })
        .catch((error) => {
            console.log(error);
        });
    dispatch({type: Actions.REQUEST_LOGIN_LAB_USER_DETAILS, payload: {}});
};

const validPassword = (password, salt, storedHash) => {
    const hash = crypto.pbkdf2Sync(password, salt, 1000, 64, `sha512`).toString(`hex`);
    return hash === storedHash;
};

export const doLogin = (loginDetails) => {

    return (dispatch) => {

        dispatch({type: Actions.LOGIN});

        axios.get(`https://${ac.getBASERESTURL()}/authenticate/user?mcId=${loginDetails.mcId}&username=${loginDetails.username}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(res => {

                if (res.data.userValid && validPassword(loginDetails.password, res.data.salt, res.data.hash)) {

                    axios.post(`https://${ac.getBASERESTURL()}/authenticate/user?mcId=${loginDetails.mcId}&username=${loginDetails.username}`, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then(res => {

                            ac.setToken(res.data.token);
                            ac.setMcId(loginDetails.mcId);

                            try {
                                jwt.verify(res.data.token, 'ExampleKey');
                            } catch {
                                console.log(`Unable to verify token`);
                            }
                            dispatch({
                                type: Actions.LOGIN_SUCCESS,
                                payload: {
                                    token: res.data.token,
                                    userId: parseInt(res.data.userId),
                                    practice: loginDetails.mcId
                                }
                            });
                            getLoginUser(dispatch, loginDetails.mcId, res.data.userId);
                        }
                    )
                } else {
                    dispatch({
                        type: Actions.LOGIN_FAILURE,
                        payload: Actions.LOGIN_FAILURE
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
};

export const doAdminLogin = (loginDetails) => {

    return (dispatch) => {

        dispatch({type: Actions.LOGIN});

        axios.get(`https://${ac.getBASERESTURL()}/authenticate/adminUser?username=${loginDetails.username}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(res => {

                if (res.data.userValid && validPassword(loginDetails.password, res.data.salt, res.data.hash)) {

                    axios.post(`https://${ac.getBASERESTURL()}/authenticate/adminUser?username=${loginDetails.username}`, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then(res => {

                            ac.setToken(res.data.token);

                            dispatch({
                                type: Actions.LOGIN_SUCCESS,
                                payload: {
                                    token: res.data.token,
                                    userId: parseInt(res.data.userId),
                                }
                            });
                            getAdminLoginUser(dispatch, res.data.userId);
                        }
                    )
                } else {
                    dispatch({
                        type: Actions.LOGIN_FAILURE,
                        payload: Actions.LOGIN_FAILURE
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
};

export const doLaboratoryLogin = (loginDetails) => {

    return (dispatch) => {

        dispatch({type: Actions.LOGIN});

        axios.get(`https://${ac.getBASERESTURL()}/authenticate/laboratoryUser?username=${loginDetails.username}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(res => {

                if (res.data.userValid && validPassword(loginDetails.password, res.data.salt, res.data.hash)) {

                    axios.post(`https://${ac.getBASERESTURL()}/authenticate/laboratoryUser?username=${loginDetails.username}`, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then(res => {

                            ac.setToken(res.data.token);

                            dispatch({
                                type: Actions.LOGIN_SUCCESS,
                                payload: {
                                    token: res.data.token,
                                    userId: parseInt(res.data.userId),
                                }
                            });
                            getLaboratoryLoginUser(dispatch, res.data.userId);
                        }
                    )
                } else {
                    dispatch({
                        type: Actions.LOGIN_FAILURE,
                        payload: Actions.LOGIN_FAILURE
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
};

export const doClientLogin = (loginDetails) => {

    return (dispatch) => {

        // dispatch({type: Actions.LOGIN});

        axios.get(`https://${ac.getBASERESTURL()}/authenticate/patient?mcId=${loginDetails.mcId}&portalReference=${loginDetails.portalReference}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(res => {

                if (res.data.patientValid && validPassword(loginDetails.password, res.data.salt, res.data.hash)) {

                    axios.post(`https://${ac.getBASERESTURL()}/authenticate/patient?mcId=${loginDetails.mcId}&portalReference=${loginDetails.portalReference}`, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then(res => {

                            ac.setToken(res.data.token);
                            ac.setMcId(loginDetails.mcId);

                            dispatch({
                                type: Actions.LOGIN_SUCCESS_PATIENT,
                                payload: {
                                    token: res.data.token,
                                    patientId: parseInt(res.data.patientId),
                                    practice: loginDetails.mcId
                                }
                            });
                        }
                    )
                } else {
                    dispatch({
                        type: Actions.LOGIN_FAILURE,
                        payload: Actions.LOGIN_FAILURE
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: Actions.LOGIN_FAILURE,
                    payload: error
                });
            })
    }
};

export const doLogout = (action) => {

    return (dispatch) => {

        dispatch({type: action});
    }
};

export const updateTabStack = (state) => {

    return (dispatch) => {

        dispatch({
            type: Actions.UPDATE_TABSTACK,
            payload: state,
        });
    }
};

export const saveStateVectors = (onPCButtonClick, onTabCloseClick, onTabUpdate) => {

    return (dispatch) => {

        dispatch({
            type: Actions.SAVE_STACK_VECTORS,
            payload: {
                onPCButtonClick,
                onTabCloseClick,
                onTabUpdate
            }
        });
    }
};

export const openMessaging = () => {

    return (dispatch) => {

        dispatch({
            type: Actions.OPEN_MESSAGING,
            payload: null,
        });
    }
}
export const checkForGateway = ({url, error, receive}) => {

    return (dispatch) => {

        axios.get(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                dispatch({type: receive, payload: res.data});
            })
            .catch((e) => {
                    dispatch({type: error, payload: false});
                }
            )
    }
}