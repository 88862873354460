import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import 'nanoscroller';
import classNames from 'classnames';

import 'primereact/resources/primereact.min.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'nanoscroller/bin/css/nanoscroller.css';

import Login from './Login'
import LoggedIn from './components/FixedItems/LoggedIn'
import Tablet from './components/Tablet/Tablet'

import './App.scss';

import {
    SM_404_CLOUD_LAB_PAGE_ERROR,
    SM_404_CLOUD_PAGE_ERROR,
    SM_404_PAGE_ERROR,
    SM_500_CLOUD_PAGE_ERROR,
    SM_CLIENT_PW_FORGOT,
    SM_CLOUD_ADMIN_LOGGED_IN,
    SM_CLOUD_ADMIN_LOGIN,
    SM_CLOUD_LAB_LOGIN,
    SM_CLOUD_LOGGED_IN,
    SM_CLOUD_LOGIN,
    SM_CLOUD_PORTAL_LOGGED_IN,
    SM_CLOUD_PORTAL_LOGIN,
    SM_CLOUD_PW_FORGOT,
    SM_TAB_CONCIEGE,
    SM_TAB_FEEDBACK,
    SM_TAB_PATIENT_CONSENT,
    SM_TAB_PATIENT_DETAILS,
    SM_TAB_PATIENT_MED_CON,
    SM_TAB_PATIENT_QUESTIONNAIRE,
    SM_TAB_PATIENT_SIGN_PPS,
    SM_TAB_PATIENT_SIGN_TREATS,
    SM_TABLET_LOGGED_IN
} from "./actions/stateManagement";

import TabPatientDetails from "./components/Tablet/FixedItems/PatientDetails";
import TabPatientMedCons from "./components/Tablet/FixedItems/PatientMedCons";
import TabPatientConsents from "./components/Tablet/FixedItems/PatientConsents";
import TabPatientSignTreats from "./components/Tablet/FixedItems/PatientSignTreats";
import TabPatientSignPPS from "./components/Tablet/FixedItems/PatientSignPPS";
import TabPatientDetailsMain from "./components/Tablet/FixedItems/PatientDetailsMain";
import TabPatientMedConsMain from "./components/Tablet/FixedItems/PatientMedConsMain";
import PatientFeedback from "./components/Tablet/FixedItems/PatientFeedback";
import TabPatientConsentsMain from "./components/Tablet/FixedItems/PatientConsentsMain";
import ClientLoggedIn from "./components/Client/ClientLoggedIn";
import NotFound from "./components/NotFound";
import LaboratoryLogin from "./LaboratoryLogin";
import CloudNotFound from "./components/CloudNotFound";
import TabReturnViaKeypad from "./components/Tablet/FixedItems/TabReturnViaKeypad";
import CloudResetPassword from "./components/FixedItems/CloudResetPassword";
import ClientResetPassword from "./components/Client/ClientResetPassword";
import TabPatientQuestionnaire from "./components/Tablet/FixedItems/PatientQuestionnaires";
import TabPatientQuestionnaireMain from "./components/Tablet/FixedItems/PatientQuestionnaireMain";
import AdminLogin from "./AdminLogin";
import AdminLoggedIn from "./components/AdminApp/AdminLoggedIn";
import CloudLabNotFound from "./components/CloudLabNotFound";
import NotActive from "./components/NotActive";
import {suppressBrowserCM2} from "./components/Utils";
import IdleTimer from "react-idle-timer";
import TabPatientCheckin from "./components/Tablet/FixedItems/PatientCheckin";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    handleOnActive = () => {
    };
    handleOnIdle = () => {
    };
    handleOnAction = () => {
    };

    render() {

        suppressBrowserCM2();

        let layoutClassName = classNames('login-body');

        const {protocol, host, pathname, hash} = window.location;

        return (

            <div className={layoutClassName}>
                <IdleTimer ref={ref => {
                    this.idleTimer = ref
                }}
                           timeout={1000 * 30}
                           onActive={this.handleOnActive}
                           onIdle={this.handleOnIdle}
                           onAction={this.handleOnAction}
                           debounce={250}
                />

                <div className="layout-main">
                    <Switch>

                        {/*Redirect to separate client site.*/}
                        <Route path={SM_CLOUD_PORTAL_LOGIN.route} exact
                               render={() => window.location = `${protocol}//client.${host}${pathname}${hash}`}/>

                        <Route path={SM_CLOUD_LAB_LOGIN.route} exact component={LaboratoryLogin}/>
                        <Route path={SM_CLOUD_ADMIN_LOGIN.route} exact component={AdminLogin}/>
                        <Route path={SM_CLOUD_LOGIN.route} exact component={Login}/>

                        <Route path={SM_CLOUD_ADMIN_LOGGED_IN.route} exact component={AdminLoggedIn}/>

                        <Route path={SM_CLOUD_LOGGED_IN.route} exact component={LoggedIn}/>
                        <Route path={SM_CLOUD_PW_FORGOT.route} component={CloudResetPassword}/>
                        <Route path={SM_CLIENT_PW_FORGOT.route} component={ClientResetPassword}/>

                        <Route path={SM_CLOUD_PORTAL_LOGGED_IN.route} exact component={ClientLoggedIn}/>

                        <Route path={SM_TABLET_LOGGED_IN.route} exact component={Tablet}/>

                        <Route path={SM_TAB_CONCIEGE.route} exact component={TabPatientCheckin}/>

                        <Route path={SM_TAB_PATIENT_DETAILS.route} exact component={TabPatientDetails}/>
                        <Route path={`${SM_TAB_PATIENT_DETAILS.route}/:patientId/:memberId`} exact
                               component={TabPatientDetailsMain}/>
                        <Route path={`${SM_TAB_PATIENT_DETAILS.exitRoute}/:from/:patientId`} exact
                               component={TabReturnViaKeypad}/>

                        <Route path={SM_TAB_PATIENT_MED_CON.route} exact component={TabPatientMedCons}/>
                        <Route path={`${SM_TAB_PATIENT_MED_CON.route}/:patientId/:memberId`} exact
                               component={TabPatientMedConsMain}/>
                        <Route path={`${SM_TAB_PATIENT_MED_CON.exitRoute}/:from/:patientId`} exact
                               component={TabReturnViaKeypad}/>

                        <Route path={SM_TAB_PATIENT_CONSENT.route} exact component={TabPatientConsents}/>
                        <Route path={`${SM_TAB_PATIENT_CONSENT.route}/:patientId/:memberId`} exact
                               component={TabPatientConsentsMain}/>
                        <Route path={`${SM_TAB_PATIENT_CONSENT.exitRoute}/:from/:patientId`} exact
                               component={TabReturnViaKeypad}/>

                        <Route path={SM_TAB_PATIENT_QUESTIONNAIRE.route} exact component={TabPatientQuestionnaire}/>
                        <Route path={`${SM_TAB_PATIENT_QUESTIONNAIRE.route}/:patientId/:memberId`} exact
                               component={TabPatientQuestionnaireMain}/>
                        <Route path={`${SM_TAB_PATIENT_QUESTIONNAIRE.exitRoute}/:from/:patientId`} exact
                               component={TabReturnViaKeypad}/>

                        <Route path={SM_TAB_PATIENT_SIGN_TREATS.route} exact component={TabPatientSignTreats}/>
                        <Route path={`${SM_TAB_PATIENT_SIGN_TREATS.route}/:treatmentPlanId/:memberId`} exact
                               component={TabPatientSignTreats}/>
                        <Route path={`${SM_TAB_PATIENT_SIGN_TREATS.exitRoute}/:from/:patientId`} exact
                               component={TabReturnViaKeypad}/>

                        <Route path={SM_TAB_PATIENT_SIGN_PPS.route} exact component={TabPatientSignPPS}/>
                        <Route path={`${SM_TAB_PATIENT_SIGN_PPS.route}/:paymentPlanId/:memberId`} exact
                               component={TabPatientSignTreats}/>
                        <Route path={`${SM_TAB_PATIENT_SIGN_PPS.exitRoute}/:from/:patientId`} exact
                               component={TabReturnViaKeypad}/>

                        <Route path={SM_TAB_FEEDBACK.route} exact component={PatientFeedback}/>
                        <Route path={`${SM_TAB_FEEDBACK.exitRoute}/:from/:patientId`} exact
                               component={TabReturnViaKeypad}/>

                        <Route path={SM_500_CLOUD_PAGE_ERROR.route} exact component={NotActive}/>

                        <Route path={SM_404_CLOUD_PAGE_ERROR.route} exact component={CloudNotFound}/>
                        <Route path={SM_404_CLOUD_LAB_PAGE_ERROR.route} exact component={CloudLabNotFound}/>
                        <Route path={SM_404_PAGE_ERROR.route} exact component={NotFound}/>

                        <Route path='*' exact component={NotFound}/>
                    </Switch>
                </div>
            </div>);
    }
}

export default App;
