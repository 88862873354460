import React from 'react';
import {
    setState,
    SM_PATIENT_QUES,
    SM_TAB_PATIENT_CONSENT,
    SM_TAB_PATIENT_QUESTIONNAIRE,
    stateRequest
} from "../../../actions/stateManagement";

import _ from "lodash";
import {Questionnaire} from "../../FixedItems/DefaultData";
import {ProgressBar} from "primereact/progressbar";
import {ac} from "../../../index";
import {TAB_Questionnaire, tabletLayoutClassName} from "../Constants";
import {InputTextarea} from "primereact/inputtextarea";
import {getQuestionnaire, RES_PATIENT_QUESTIONNAIRE} from "../../../actions/personal";
import {connect} from "react-redux";
import {Panel} from "primereact/panel";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Button} from "primereact/components/button/Button";
import * as Actions from "../../../actions";
import {HM_COMPLETE_QUES, HM_TABLET_CONFIRM_QUES} from "../../../Constants";
import {ShowMessageDialog, ShowQuestionDialog} from "../../FixedItems/Diary/components/EventComponent";
import {SelectButton} from "primereact/selectbutton";
import {BaseComponent} from "../../BaseComponent";
import {removeFromLists, RES_TABLET_LISTS} from "../../../actions/tablet";

class ConnectedTabPatientQuestionnaireMain extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: this.props.dataId,
                questionnaire: _.cloneDeep(Questionnaire(ac.getMcId())),
                patientId: parseInt(props.match.params.patientId, 10),
                memberId: parseInt(props.match.params.memberId, 10),
                image: null,
                completed: false,
            }
        }

        this.onChange = this.onChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.insertQuestions = this.insertQuestions.bind(this);
    }

    componentDidMount() {

        if (!this.props.questionnaireLoaded) {
            this.props.getQuestionnaire(this.state.patientId);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {


        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_QUESTIONNAIRE:

                    const questionnaire = {...this.props.questionnaire};

                    questionnaire.entries.forEach(entry => {
                        entry.preAppointmentYes = null;
                        entry.uponArrivalYes = null;
                        entry.questionnaire = {id: questionnaire.id};
                    });

                    this.setState({questionnaireLoaded: true, questionnaire}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;

                case Actions.CLEAR_PATIENT_QUESTIONNAIRE:

                    window.location.hash = `${SM_TAB_PATIENT_QUESTIONNAIRE.exitRoute}/${TAB_Questionnaire}/${this.state.patientId}`;
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const questionnaire = {...this.state.questionnaire};
        const entry = _.find(questionnaire.entries, entry => entry.id === event.id);

        _.set(entry, event.owner, event.value);

        if (event.owner === 'preAppointmentYes') {

            let completed = true;

            questionnaire.entries.forEach(entry => {
                if (!entry.question.formHeader)
                    if (entry.preAppointmentYes === null) {
                        completed = false;
                    }
            });
            this.setState({questionnaire, completed});
        } else {
            this.setState({questionnaire});
        }
    }

    onSave = () => {

        if (this.state.completed) {
            this.setState({[HM_TABLET_CONFIRM_QUES.id]: true}, () => {
                this.props.setState(this.state.stateManagementId, {...this.state});
            });
        } else {
            this.setState({[HM_COMPLETE_QUES.id]: true}, () => {
                this.props.setState(this.state.stateManagementId, {...this.state});
            });
        }
    }

    onExit = () => {

        const source = {
            id: this.state.stateManagementId,
            action: Actions.SAVE_PATIENT_QUESTIONNAIRE,
            saveState: true,
            saveObjects: false,
            smRef: this.state.patientId,
        };

        this.props.stateRequest(source);
    }

    onCancel() {

        const tabletMember = {patientId: this.state.patientId, type: TAB_Questionnaire};
        this.props.removeFromLists(tabletMember);

        window.location.hash = `${SM_TAB_PATIENT_CONSENT.exitRoute}/${TAB_Questionnaire}/${this.state.patientId}`;
    }

    insertQuestions = () => {

        const questions = [];
        const questionnaireEntries = _.sortBy(this.state.questionnaire.entries, ['question.questionnaireIndex', 'name'], ['asc', 'asc']);

        const selectItems = [
            {label: 'Yes', value: true},
            {label: 'No', value: false}
        ];

        questions.push(
            <React.Fragment>
                <div className="p-col-6">
                </div>
                <div className="p-col-3">
                    <h4>Pre Appointment Date</h4>
                </div>
                <div className="p-col-3">
                    <h4>Upon Arrival Date</h4>
                </div>
            </React.Fragment>
        );

        questionnaireEntries.forEach((entry) => {

            if (entry.question.formHeader) {
                questions.push(
                    <div style={{backgroundColor: 'rgb(52, 143, 207)', height: '40px'}}
                         key={`header_${entry.question.id}`}
                    >
                        <span style={{textAlign: 'center'}}>{entry.question.formEntry}</span>
                    </div>
                )
            } else {
                questions.push(
                    <React.Fragment>
                        <div className="p-col-6">
                            <InputTextarea value={entry.question.formEntry}
                                           autoResize={true}
                                           rows={4}
                            />
                        </div>
                        <div className="p-col-3">
                            <SelectButton value={entry.preAppointmentYes}
                                          options={selectItems}
                                          onChange={(e) =>
                                              this.onChange({
                                                  id: entry.id,
                                                  owner: 'preAppointmentYes',
                                                  value: e.value
                                              })
                                          }/>
                        </div>
                        <div className="p-col-3">
                            <SelectButton value={entry.uponArrivalYes}
                                          options={selectItems}
                                          disabled={true}
                            />
                        </div>
                    </React.Fragment>
                )
            }
        });
        return questions;
    }

    showDialogs() {

        const content = [];
        if (this.state[HM_COMPLETE_QUES.id]) {
            content.push(ShowMessageDialog(this, HM_COMPLETE_QUES));
        } else if (this.state[HM_TABLET_CONFIRM_QUES.id]) {
            content.push(ShowQuestionDialog(this, HM_TABLET_CONFIRM_QUES, this.onExit));
        }
        return content;
    }

    render() {

        if (!this.state.questionnaireLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='panel-header-centered-content'><label
            id='panel-header'>{SM_TAB_PATIENT_QUESTIONNAIRE.label}</label>
        </div>;

        return (

            <div className={tabletLayoutClassName} id='mainPCC'>

                {this.showDialogs()}

                <div>
                    <div className="p-grid">
                        <div className="p-xl-8 p-xl-offset-2">
                            <div className="card card-w-title card-bg-solid">
                                <span className="logo"><i className='fa mainLogoImg'/></span>
                                <div className="p-grid control-panel-dashboard">

                                    <Toolbar className="p-col-12"
                                             right={<React.Fragment>
                                                 <Button label="Save" icon="pi pi-check"
                                                         className="p-button-success"
                                                         onClick={this.onSave}
                                                 />
                                                 <i className="pi pi-bars p-toolbar-separator"
                                                    style={{marginRight: '.25em'}}/>
                                                 <Button label="Cancel"
                                                         icon="pi pi-times"
                                                         className="p-button-warning"
                                                         onClick={this.onCancel}
                                                 />
                                             </React.Fragment>
                                             }
                                    />

                                    <div className="p-col-12">
                                        <Panel header={header} style={{fontSize: '1.5em'}}>
                                            <div className="p-grid p-fluid">

                                                {this.insertQuestions()}

                                            </div>
                                        </Panel>
                                    </div>

                                    <Toolbar className="p-col-12"
                                             right={<React.Fragment>
                                                 <Button label="Save" icon="pi pi-check"
                                                         className="p-button-success"
                                                         onClick={this.onSave}
                                                 />
                                                 <i className="pi pi-bars p-toolbar-separator"
                                                    style={{marginRight: '.25em'}}/>
                                                 <Button label="Cancel"
                                                         icon="pi pi-times"
                                                         className="p-button-warning"
                                                         onClick={this.onCancel}
                                                 />
                                             </React.Fragment>
                                             }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-mask"/>

                    {/*<AppFooter/>*/}
                    <span className="bg-shape bg-shape-2"/>
                    <span className="bg-shape bg-shape-1"/>
                    <span className="bg-shape bg-shape-3"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const loadedId = `${SM_PATIENT_QUES.loaded}_${ownProps.match.params.patientId}`;
    const dataId = `${SM_PATIENT_QUES.id}_${ownProps.match.params.patientId}`;

    return {

        dataId,
        loaded: state.patients[loadedId],
        questionnaire: state.patients[dataId],

        message: state.stateManagement.message,

        loginIdentity: state.login.user,
        userId: state.login.userId,

        currentState: state.stateManagement[dataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getQuestionnaire: (patientId) => dispatch(getQuestionnaire(RES_PATIENT_QUESTIONNAIRE.GET, {patientId})),
        removeFromLists: (tabletMember) => dispatch(removeFromLists(RES_TABLET_LISTS.REMOVE_FROM, tabletMember)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TabPatientQuestionnaireMain = connect(mapStateToProps, MapDispatchToProps)(ConnectedTabPatientQuestionnaireMain);

export default TabPatientQuestionnaireMain;
