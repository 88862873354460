import React from 'react';
import {connect} from "react-redux";
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {tabletRegistrationsToolBar} from "./Utils";
import {setState, SM_TABLET_REGISTRATIONS, stateRequest} from "../../../actions/stateManagement";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {
    TAB_MedicalHistoryUpdate,
    TAB_PatientConsentForms,
    TAB_PatientDetailsUpdate,
    TAB_PaymentPlanSigns,
    TAB_TreatmentPlanSigns
} from "../../Tablet/Constants";
import {
    RES_TAB_PATIENT_CONSENTS,
    RES_TAB_PATIENT_DETAILS,
    RES_TAB_PATIENT_MED_CONS,
    RES_TAB_PATIENT_PP_SIGNS,
    RES_TAB_PATIENT_TP_SIGNS,
    RES_TABLET_LISTS
} from "../../../actions/tablet";
import TabletRegistration from "./Sections/TabletRegistration";
import {
    HM_notImplemented,
    HM_TABLET_REGISTRATIONS_DOWNLOAD,
    HM_TABLET_REGISTRATIONS_PRINT,
    HM_TABLET_REGISTRATIONS_SHOW,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {TabBaseComponent} from "../../TabBaseComponent";
import {TAB_CHANGE, TAB_EXIT_SAVE} from "../Housekeeping/Constants";
import {TB_PATIENT_DETAILS} from "../PatientDetails/Constants";
import {showPatientDetailsPage} from "../PatientDetails/Utils";

export class ConnectedTabletRegistrations extends TabBaseComponent {

    constructor(props) {
        super(props);
        this.state = {

            canSave: {
                status: false,
                activeIndex: 0,
                source: {
                    id: SM_TABLET_REGISTRATIONS.id,
                    action: RES_TABLET_LISTS.CLEAR.action,
                },
            },
        };
        this.exitState = TAB_CHANGE;

        this.toolbarCallbacks = {
            [TB_SAVE_AND_EXIT.id]: this.exit,
            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [HM_TABLET_REGISTRATIONS_PRINT.id]: this.onPrintReport,
            [HM_TABLET_REGISTRATIONS_SHOW.id]: this.onShowReport,
            [HM_TABLET_REGISTRATIONS_DOWNLOAD.id]: this.onDownload,
        }
    }

    onShowDetails = ({id, firstName, lastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    onShowReport(event) {
    }

    onPrintReport(event) {
    }

    onDownload() {
    }

    exit = () => {
        this.exitState = TAB_EXIT_SAVE;
        this.onSaveExit();
    }

    showDialogs = () => {

        const contents = [];

        contents.push(ShowMessageDialog(this, HM_notImplemented))

        return contents;
    }

    render() {

        this.tabStack = [];

        this.tabStack.push(
            {
                section: TAB_PatientDetailsUpdate,
                content: <TabPanel key={TAB_PatientDetailsUpdate}
                                   header={RES_TAB_PATIENT_DETAILS.GET.label}>
                    <TabletRegistration category={RES_TAB_PATIENT_DETAILS.GET}
                                        toolbarCallbacks={this.toolbarCallbacks}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                section: TAB_MedicalHistoryUpdate,
                content: <TabPanel key={TAB_MedicalHistoryUpdate}
                                   header={RES_TAB_PATIENT_MED_CONS.GET.label}>
                    <TabletRegistration category={RES_TAB_PATIENT_MED_CONS.GET}
                                        toolbarCallbacks={this.toolbarCallbacks}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                section: TAB_PatientConsentForms,
                content: <TabPanel key={TAB_PatientConsentForms}
                                   header={RES_TAB_PATIENT_CONSENTS.GET_CLOUD.label}>
                    <TabletRegistration category={RES_TAB_PATIENT_CONSENTS.GET_CLOUD}
                                        toolbarCallbacks={this.toolbarCallbacks}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                section: TAB_TreatmentPlanSigns,
                content: <TabPanel key={TAB_TreatmentPlanSigns}
                                   header={RES_TAB_PATIENT_TP_SIGNS.GET.label}>
                    <TabletRegistration category={RES_TAB_PATIENT_TP_SIGNS.GET}
                                        toolbarCallbacks={this.toolbarCallbacks}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                section: TAB_PaymentPlanSigns,
                content: <TabPanel key={TAB_PaymentPlanSigns}
                                   header={RES_TAB_PATIENT_PP_SIGNS.GET.label}>
                    <TabletRegistration category={RES_TAB_PATIENT_PP_SIGNS.GET}
                                        toolbarCallbacks={this.toolbarCallbacks}
                    />
                </TabPanel>
            }
        );

        return (
            <div>
                <Toolbar right={
                    tabletRegistrationsToolBar(this.toolbarCallbacks, null, null)
                }
                />

                {this.showDialogs()}

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_TABLET_REGISTRATIONS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const TabletRegistrations = connect(mapStateToProps, mapDispatchToProps)(ConnectedTabletRegistrations);

export default TabletRegistrations;
