
export const TB_REFERRER_ACTIVE = {
    id: 'TB_REFERRER_ACTIVE',
    text: 'Mark as active referrer',
    icon: 'fas fa-check-circle',
};

export const TB_REFERRER_INACTIVE = {
    id: 'TB_REFERRER_INACTIVE',
    text: 'Mark as inactive referrer',
    icon: 'fas fa-times-circle',
};
