import React from "react";
import {CONST_FEMALE} from "./OnChangeUtils";
import {Dialog} from "primereact/dialog";
import {HM_PATIENT_IMAGE} from "../../Constants";
import {ICON_CLOSE} from "../../icons";
import {Button} from "primereact/button";

export const ShowPatientImage = (props) => {

    const {gender, patientImageFileName} = props.selectedEvent;
    const image = gender === CONST_FEMALE ? 'femaleOutline.png' : 'maleOutline.png';

    const key = Math.random().toString(36).slice(2);

    const footer =
        <div>
            <Button icon={ICON_CLOSE}
                    label='Close'
                    onClick={() => {
                        props.onHideDialog();
                    }}
            />
        </div>;

    if (patientImageFileName && !patientImageFileName.endsWith('null/IMAGE')) {
        return (
            <Dialog header={HM_PATIENT_IMAGE.header}
                    visible={true}
                    onHide={() => {
                        props.onHideDialog();
                    }}
                    modal={false}
                    footer={footer}
            >
                <img alt=''
                     src={`${patientImageFileName}/${key}`}
                     style={{height: '75vh'}}
                />
            </Dialog>
        )
    } else {
        return (
            <Dialog header={HM_PATIENT_IMAGE.header}
                    visible={true}
                    onHide={() => {
                        props.onHideDialog();
                    }}
                    modal={false}
                    footer={footer}
            >
                <img alt=''
                     src={`/assets/images/${image}`}
                     style={{height: '75vh'}}
                />
            </Dialog>
        )
    }
}