import React from 'react';
import moment from 'moment';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {connect} from "react-redux";
import {
    setState,
    SM_NHS_MANAGEMENT,
    SM_NHS_MANAGEMENT_SCHEDULED,
    stateRequest
} from "../../../../actions/stateManagement";
import {getNHSManagementIds} from "../Utils";
import {
    getResource,
    RES_getProviderPerformers,
    RES_NHS_MANAGEMENT_CLAIM,
    RES_NHS_MANAGEMENT_CLAIMS,
    RES_NHS_MANAGEMENT_SCHEDULED,
    RES_NHS_MANAGEMENT_STACKED,
    resubmitClaim
} from "../../../../actions/nhsManagement";
import * as Actions from "../../../../actions";
import {
    claimReferenceTemplate, commentTemplate,
    EDI_CLAIMED_TOTAL,
    EDI_SCHEDULED,
    EDI_SCHEDULED_TITLE,
    EDI_SCHEDULED_TOTAL,
    EDI_ST_Scheduled,
    expectedTemplate2,
    formTemplate,
    nameTemplate,
    ppPerformerNumberTemplate,
    processClaimComments,
    unitsTemplate
} from "../Constants";

import ClaimsComponent from "../ClaimsComponent";
import {InputText} from "primereact/inputtext";
import {dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import {JSON_DATE_FORMAT} from "../../../../Constants";
import {ProgressBar} from "primereact/progressbar";
import _ from "lodash";

export class ConnectedScheduledSection extends ClaimsComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                type: EDI_SCHEDULED,

                stateManagementId: SM_NHS_MANAGEMENT_SCHEDULED.id,

                selectedUser: {id: null},
                firstClinicians: 0,
                rowsClinicians: 5,

                claims: [],
                selectedClaim: null,

                claimedTotal: 0,
                scheduledTotal: 0,

                firstClaims: 0,
                rowsClaims: 5,

                pinValid: false,
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.claimDate !== this.props.claimDate) {
            this.setState({claims: []}, () => {
                const date = new moment(this.props.claimDate);
                this.props.getClaims(this.state.selectedUser.id, date.format(JSON_DATE_FORMAT));
            });
        }

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:
                    break;
                case RES_NHS_MANAGEMENT_STACKED.GET.request:
                    this.setState({claims: [], claimsLoaded: false, claimedTotal: 0});
                    break;
                case RES_NHS_MANAGEMENT_CLAIM.SAVE.action:
                case RES_NHS_MANAGEMENT_CLAIMS.RESUBMIT.receive:
                    this.onUserReload();
                    break;
                case RES_NHS_MANAGEMENT_SCHEDULED.GET.receive:

                    let claimedTotal = 0;
                    let scheduledTotal = 0;

                    this.props.claims.forEach((claim) => claimedTotal += claim.units);
                    this.props.claims.forEach((claim) => {
                        scheduledTotal += processClaimComments(claim);
                    });

                    this.setState({claims: this.props.claims, claimedTotal, scheduledTotal}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.props.providerPerformersLoaded || (this.state.selectedUser.id !== null && !this.props.claimsLoaded)) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const sortedClaims = _.sortBy(this.state.claims, ['id'], ['asc']);

        const claimsHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{EDI_SCHEDULED_TITLE}</span>
                <span className='p-panel-title' style={{marginRight: 15}}>{EDI_CLAIMED_TOTAL}</span>
                <InputText value={this.state.claimedTotal}
                           style={{textAlign: 'right'}}
                           disabled={true}
                />
                <span className='p-panel-title' style={{marginRight: 15}}>{EDI_SCHEDULED_TOTAL}</span>
                <InputText value={this.state.scheduledTotal}
                           style={{textAlign: 'right'}}
                           disabled={true}
                />
            </div>
            <div className="items-margin d-flex d-align-center">
            </div>
        </div>;

        return (
            <div>
                <Panel header='Performers' style={{marginBottom: '5px'}}>

                    <ContextMenu style={{width: 250}} model={this.buildUserContextMenu()}
                                 ref={el => this.usersCm = el}/>

                    <DataTable value={this.sortProviderPerformers()}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsClinicians}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstClinicians', 'rowsClinicians')}
                               first={this.state.firstClinicians}
                               selection={this.state.selectedUser}
                               onSelectionChange={this.onUserSelectionChange}
                               contextMenuSelection={this.state.selectedUser}
                               onContextMenuSelectionChange={this.onUserSelectionChange}
                               onContextMenu={e => this.usersCm.show(e.originalEvent)}
                    >

                        <Column header="Name"
                                body={nameTemplate}
                                style={{width: '70%'}}/>
                        <Column header="Performer Number"
                                body={ppPerformerNumberTemplate}
                                style={{textAlign: 'right', width: '30%'}}/>
                    </DataTable>
                </Panel>

                <Panel headerTemplate={claimsHeader}>

                    <ContextMenu style={{width: 250}} model={this.buildClaimContextMenu(EDI_ST_Scheduled)}
                                 ref={el => this.cm = el}/>

                    {this.showDialogs()}

                    <DataTable value={sortedClaims}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsClaims}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstClaims', 'rowsClaims')}
                               first={this.state.firstClaims}
                               selection={this.state.selectedClaim}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedClaim}
                               onContextMenuSelectionChange={this.onSelection}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent);
                               }}
                    >

                        <Column body={(row) => dateTemplateShort(row.submissionDate)}
                                header="Submitted On"
                                style={{width: '10%'}}/>

                        <Column field='count'
                                header="Count"
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column body={(row) => claimReferenceTemplate(row)}
                                header="Claim"
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column body={(row) => formTemplate(row)}
                                header="Form"
                                style={{width: '20%'}}/>

                        <Column body={(row) => unitsTemplate(row)}
                                header="Units"
                                style={{width: '10%', textAlign: 'right'}}/>

                        <Column body={(row) => expectedTemplate2(row)}
                                header="Expected"
                                style={{width: '10%'}}/>

                        <Column field="journeyCode"
                                header="Stage"
                                style={{width: '10%'}}/>

                        <Column body={(row) => nameTemplate(row)}
                                header="Patient"
                                style={{width: '15%'}}/>

                        <Column body={(row) => commentTemplate(row)}
                                header="Comment"
                                style={{width: '20%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        providerPerformersLoaded,
        providerPerformers,

        scheduledLoaded,
        scheduled,
    } = getNHSManagementIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        providerPerformersLoaded,
        providerPerformers,

        claimsLoaded: scheduledLoaded,
        claims: scheduled,

        currentState: state.stateManagement[SM_NHS_MANAGEMENT_SCHEDULED.id],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getProviderPerformers: () => dispatch(getResource(RES_getProviderPerformers.GET, {})),
        getClaims: (ppId, claimDate) => dispatch(getResource(RES_NHS_MANAGEMENT_SCHEDULED.GET, {ppId, claimDate})),
        getClaim: (id) => dispatch(getResource(RES_NHS_MANAGEMENT_SCHEDULED.GET_DET, {id})),

        resubmitClaim: (details) => dispatch(resubmitClaim(RES_NHS_MANAGEMENT_CLAIMS.RESUBMIT, details)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data, SM_NHS_MANAGEMENT.id)),
    }
};

const ScheduledSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedScheduledSection);

export default ScheduledSection;

