import React from 'react';
import {connect} from 'react-redux';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';

import '../../../../images.css';
import {setState} from "../../../../actions/stateManagement";
import {HM_notImplemented} from "../../../../Constants";
import {ICON_DELETE} from "../../../../icons";
import {getLists, removeFromLists, RES_TABLET_LISTS} from "../../../../actions/tablet";
import {Column} from "primereact/components/column/Column";
import {dateTemplate, patientTemplate} from "../../../PatientDynamicItems/Utils";
import {BaseComponent} from "../../../BaseComponent";

import {TB_PATIENT_DETAILS, TB_REMOVE_FROM_TABLET} from "../../PatientDetails/Constants";
import _ from "lodash";
import moment from "moment";
import {CONST_FEMALE} from "../../../PatientDynamicItems/OnChangeUtils";
import * as Actions from "../../../../actions";

class ConnectedTabletRegistration extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                membersLoaded: false,
                members: [],

                selectedMember: null,
                first: 0,
                rows: 5,
            };
        }

        this.toolbarCallbacks = {
            [HM_notImplemented.id]: this.onNotImplemented,
        };
    }

    componentDidMount() {

        if (!this.state.membersLoaded) {
            this.props.getMemberList(this.props.category);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_TAB_PATIENT_DETAILS:
                case Actions.RECEIVE_TAB_PATIENT_MED_CONS:
                case Actions.RECEIVE_TAB_PATIENT_CONSENTS:
                case Actions.RECEIVE_TAB_PATIENT_TP_SIGNS:
                case Actions.RECEIVE_TAB_PATIENT_PP_SIGNS:
                    this.setState({members: this.props.members, membersLoaded: true})
                    break;
                default:
                    break;
            }
        }
    }

    removeFromLists = (memberToRemove) => {

        const members = _.filter(this.state.members, member => member.id !== memberToRemove.id);

        this.setState({members}, () => {
            this.props.removeFromLists(memberToRemove);
        })
    }

    buildItems() {

        if (this.state.selectedMember === null) return [];


        const items = [];

        const selectedMember = {
            id: this.state.selectedMember.patientId,
            firstName: this.state.selectedMember.firstname,
            lastName: this.state.selectedMember.lastname,
            gender: this.state.selectedMember.gender,
            nhsPatient: this.state.selectedMember.nhsPatient,
        };
        const billingGroupId = this.state.selectedMember.groupId;

        const detailsIcon = selectedMember.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;
        const memberToRemove = {
            id: this.state.selectedMember.id,
            patientId: this.state.selectedMember.patientId,
            type: this.props.category.type
        };

        items.push(
            {
                label: TB_PATIENT_DETAILS.text, icon: detailsIcon, command: (e) => {
                    this.props.toolbarCallbacks[TB_PATIENT_DETAILS.id](selectedMember, billingGroupId);
                }
            },
        );
        items.push(
            {
                label: TB_REMOVE_FROM_TABLET.text, icon: ICON_DELETE, command: (e) => {
                    this.removeFromLists(memberToRemove);
                }
            },
        );

        return items;
    }

    orderedMembers = () => {
        return _.orderBy(this.state.members, (member) => {
            return moment(member.addedOn).format('YYYYMMDD');
        }, ['asc']);
    }

    render() {

        if (!this.state.membersLoaded) {
            return null;
        }

        return (

            <Panel header={`${this.props.category.label} Registrations`}>

                <ContextMenu style={{width: 250}} model={this.buildItems()} ref={el => this.cm = el}/>

                <DataTable value={this.orderedMembers()}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}
                           selectionMode="single"
                           selection={this.state.selectedMember}
                           onSelectionChange={(e) => this.setState({selectedMember: e.value})}
                           contextMenuSelection={this.state.selectedMember}
                           onContextMenuSelectionChange={e => this.setState({selectedMember: e.value})}
                           onContextMenu={e => this.cm.show(e.originalEvent)}
                >
                    <Column header="Name"
                            body={(row) => patientTemplate(row)}
                            style={{width: '20%'}}/>
                    <Column header="Added On"
                            body={row => dateTemplate(row.addedOn)}
                            style={{width: '20%'}}/>
                    <Column header="Comments"
                            field="comment"
                            style={{width: '20%'}}/>
                </DataTable>
            </Panel>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const membersLoaded = `${ownProps.category.index}Loaded`;
    const members = `${ownProps.category.index}`;

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        membersLoaded: state.tablet[membersLoaded],
        members: state.tablet[members],

        currentState: state.stateManagement[ownProps.category.type],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getMemberList: (category) => dispatch(getLists(category, false)),
        removeFromLists: (tabletMember) => dispatch(removeFromLists(RES_TABLET_LISTS.REMOVE_FROM, tabletMember)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TabletRegistration = connect(MapStateToProps, MapDispatchToProps)(ConnectedTabletRegistration);

export default TabletRegistration;

