import {SM_SMS_MAIL_SHOT_SEND, SM_SMS_MAIL_SHOT_TEST_SEND} from "../../../actions/stateManagement";
import {
    __CHIRAL_CONTACT_DETAILS__,
    TB_EXIT,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TB_SEND_SMS_MAIL_SHOT,
    TB_SEND_SMS_MAIL_SHOT_TEST
} from "../../../Constants";
import {tb_boilerPlate2, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import React from "react";

export const smsMailShotHistoryToolBar = (callbacks, saveIcon, canSave) => {
    let key = Math.floor(Math.random() * 1000);
    const saveAndExitText = canSave ? TB_SAVE_AND_EXIT.text : TB_EXIT.text;

    return {
        left: tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text, `sms_${key++}`),
        right: tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, saveAndExitText, `sms_${key}`),
    }
};

export const smsMailShotToolBar = (callbacks, saveIcon, canSave) => {
    let key = Math.floor(Math.random() * 1000);
    const saveAndExitText = canSave ? TB_SAVE_AND_EXIT.text : TB_EXIT.text;

    return {
        left: tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text, key++),
        right: [tb_boilerPlateRight2(callbacks[TB_SEND_SMS_MAIL_SHOT_TEST], SM_SMS_MAIL_SHOT_TEST_SEND.icon, SM_SMS_MAIL_SHOT_TEST_SEND.label, `sms_${key++}`, canSave),
            tb_boilerPlateRight2(callbacks[TB_SEND_SMS_MAIL_SHOT], SM_SMS_MAIL_SHOT_SEND.icon, SM_SMS_MAIL_SHOT_SEND.label, `sms_${key++}`, canSave),
            tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, saveAndExitText, `sms_${key}`)]
    }
};

export default class SMSMailShotErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>Something went wrong in the SMS Mail Shot section.</h1>
                    <h2>{__CHIRAL_CONTACT_DETAILS__}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
