import * as Actions from '../actions/index';
import _ from 'lodash';

const initialState = {

    message: {type: ''},

    dropDownsLoaded: false,
    titles: [],
    providers: [],
    countries: [],
    sources: [],
    origins: [],
    genders: [],
    roles: [],
};

export const dropDowns = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// DropDowns Related

            case Actions.REQUEST_DROPDOWNS:
                return {
                    ...state,
                    dropDownsLoaded: false,
                };

            case Actions.RECEIVE_DROPDOWNS:

                return {
                    ...state,
                    dropDownsLoaded: true,
                    titles: action.payload.titles,
                    providers: action.payload.providers,
                    countries: action.payload.countries,
                    sources: action.payload.sources,
                    origins: action.payload.origins,
                    genders: action.payload.genders,
                    roles: action.payload.roles,
                    appointmentTypes: action.payload.appointmentTypes,
                    recalls: action.payload.recalls,
                    specialisms: action.payload.specialisms,
                    locations: action.payload.locations,
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};