import * as React from "react";
import {Component} from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {HM_AddMessageGroup, SU_STATUS_ACTIVE, TABLET} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import {ac} from "../../../../index";
import _ from "lodash";
import {Card} from "primereact/components/card/Card";
import {PickList} from "primereact/components/picklist/PickList";
import {InputText} from "primereact/components/inputtext/InputText";

export default class AddAppointmentType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            group: {
                id: null,
                mc: ac.getMcId(),
                owner: {id: props.loginIdentity.id},
                recipients: [],
                groupName: '',
                globalFilter: '',
                sourceTCs: [],
                targetTCs: [],
            },
            users: _.filter(props.users, user => user.status === SU_STATUS_ACTIVE && user.username !== 'superuser' && user.myRole !== TABLET),
        }
    }

    componentDidMount() {

        this.calculatePickList('');
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onTCSelection = (event) => {

        this.setState({
            sourceTCs: _.sortBy(event.source, ['lastName'], ['asc']),
            targetTCs: _.sortBy(event.target, ['lastName'], ['asc'])
        });
    }

    calculatePickList = (globalFilter) => {

        let users = [];

        this.state.group.recipients.forEach((target) => {
            const index = _.findIndex(this.state.users, (source) => target.id === source.id);
            users.push(this.state.users[index]);
        });

        let sourceTCs = _.filter(this.state.users, user => {
            const pattern = `${user.lastName}`;
            return (pattern.toLowerCase().includes(globalFilter.toLowerCase()) || globalFilter === '') && pattern.trim() !== '' && !users.includes(user);
        });

        sourceTCs = _.sortBy(sourceTCs, ['lastName'], ['asc']);

        let targetTCs = this.state.group.recipients;
        targetTCs = _.sortBy(targetTCs, ['lastName'], ['asc']);

        this.setState({sourceTCs: sourceTCs, targetTCs: targetTCs});
    }

    userTemplate(rowData) {
        return (
            <div className="p-helper-clearfix">
                <div
                    style={{fontSize: 'small'}}>{`${rowData.titleAbbreviation} ${rowData.firstName} ${rowData.lastName}`.trim()}</div>
            </div>
        )
    }

    render() {

        const agDefined = !(this.state.group.groupName && this.state.group.groupName.length > 0);

        const possibleHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <span style={{margin: '4px 4px 0 0'}}>Possible</span>
            <InputText type="search"
                       onInput={(e) => {
                           this.calculatePickList(e.target.value);
                       }}
                       placeholder="Member Search"
                       size="50"
                       autoFocus
            />
        </div>;

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => this.props.onOK(this.state.group)}
                        disabled={agDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => this.props.onHideMenuEntry({item: {target: HM_AddMessageGroup.id}})}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'group',
            group: this.state.group,
        };

        return <Dialog style={{width: "50%"}}
                       onHide={() => this.props.onHideMenuEntry({item: {target: HM_AddMessageGroup.id}})}
                       visible={true}
                       header={`Add Message Group`}
                       footer={footer}
        >

            <div className="p-grid p-fluid">
                <div className="p-col-3">
                    <label>Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'groupName', '', false, true)}
                </div>

                <Card title='Group Members' style={{width: '100%'}}>
                    <PickList className='p-lg-12 p-md-12'
                              sourceHeader={possibleHeader}
                              targetHeader="Actual"
                              itemTemplate={this.userTemplate}
                              showSourceControls={false}
                              showTargetControls={false}
                              source={this.state.sourceTCs}
                              target={this.state.targetTCs}
                              style={{paddingLeft: 0}}
                              onChange={this.onTCSelection}
                    />
                </Card>
            </div>
        </Dialog>
    }
}