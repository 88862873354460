import React from 'react';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';

import {costConverter, textColorConverter, transactionConverter} from "../Utils";
import {
    CH_INVOICE,
    CH_INVOICED,
    CH_RECEIPTED,
    CH_SAVED,
    CH_SCHEDULED,
    TREATMENTS_APP,
    TREATMENTS_TP_LEFT,
    TREATMENTS_TP_RIGHT
} from "../../../../Constants";

export default class Treatment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: props.charge.status === CH_INVOICED || props.charge.status === CH_RECEIPTED || props.charge.status === CH_INVOICE,
        };

        this.onChargeComplete = this.onChargeComplete.bind(this);
    }

    onChargeComplete(checked) {

        let result = checked;
        const charge = {...this.props.charge};

        switch (charge.status) {
            case CH_SCHEDULED:
            case CH_INVOICE :
                if (!checked)
                    charge.status = CH_SAVED;
                break;
            case CH_INVOICED:
            case CH_RECEIPTED:
                result = true;
                break;
            case CH_SAVED:
                if (checked)
                    charge.status = CH_INVOICE;
                break;
            default:
                break;
        }

        this.props.onComplete(charge);
        this.setState({checked: result});
    };

    showCompleteness(readOnly, borderStyle, code, width) {

        return (
            <div style={borderStyle} className={width}>
                <Checkbox checked={this.state.checked}
                          disabled={readOnly}
                          className='p-checkbox-label'
                          onChange={(e) => {
                              this.onChargeComplete(e.checked)
                          }}/>
                {` ${code}`}
            </div>)
    }

    render() {

        const {provided, innerRef, snapshot, charge, assigned, onContextMenu, onSelection, highlighted} = this.props;

        const style = snapshot.isDragging || assigned ? {
            backgroundColor: '#55ff60',
            opacity: '0.76'
        } : highlighted ? {backgroundColor: '#70d0f6'} : {backgroundColor: '#fafafa'};

        const textColor = {color: textColorConverter(this.props.charge)};

        const borderStyle = {borderStyle: 'solid', borderColor: '#ebebeb', borderWidth: '1px', ...style, ...textColor};

        switch (this.props.treatmentDisplay) {

            case TREATMENTS_TP_LEFT :

                return (
                    <div className="p-grid p-fluid form-group p-col-12"
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                         ref={innerRef}
                         onContextMenu={onContextMenu}
                         onClick={onSelection}
                    >
                        <div style={borderStyle} className="p-col-1">{charge.item.code}</div>
                        <div style={borderStyle} className='p-col-6'>{charge.item.description}</div>
                        <div style={borderStyle} className="p-col-2">{charge.details}</div>
                        <div style={{...borderStyle, textAlign: 'right'}} className="p-col-1">{charge.quantity}</div>
                        <div style={{...borderStyle, textAlign: 'right'}}
                             className="p-col-2">{costConverter(charge)}</div>
                    </div>
                );
            case TREATMENTS_TP_RIGHT : {

                return (
                    <div className="p-grid p-fluid form-group p-col-12"
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                         ref={innerRef}
                         onContextMenu={onContextMenu}
                         onClick={onSelection}
                    >
                        {this.showCompleteness(true, borderStyle, charge.item.code, 'p-col-1')}
                        <div style={borderStyle} className='p-col-6'>{charge.item.description}</div>
                        <div style={borderStyle} className="p-col-2">{charge.details}</div>
                        <div style={{...borderStyle, textAlign: 'right'}} className="p-col-1">{charge.quantity}</div>
                        <div style={{...borderStyle, textAlign: 'right'}}
                             className="p-col-2">{costConverter(charge)}</div>
                    </div>
                );
            }
            case TREATMENTS_APP : {

                return (
                    <div className="p-grid p-fluid form-group p-col-12"
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                         ref={innerRef}
                         onContextMenu={onContextMenu}
                         onClick={onSelection}
                    >
                        {this.showCompleteness(false, borderStyle, charge.item.code, 'p-col-1')}
                        <div style={borderStyle} className='p-col-4'>{charge.item.description}</div>
                        <div style={{...borderStyle, textAlign: 'right'}} className="p-col-1">{charge.quantity}</div>
                        <div style={{...borderStyle, textAlign: 'right'}}
                             className="p-col-1">{costConverter(charge)}</div>
                        <div style={borderStyle} className="p-col-1">{transactionConverter(charge)}</div>
                        <div style={borderStyle} className="p-col-4">
                            <div className="content" dangerouslySetInnerHTML={{__html: charge.comment}}></div>
                        </div>
                    </div>
                )
            }
            default :
                return null;
        }
    }
}
