import React, {Component} from 'react';

import {connect} from 'react-redux';

class ConnectedClientResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    render() {
        return <div></div>
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

const ClientResetPassword = connect(mapStateToProps, mapDispatchToProps)(ConnectedClientResetPassword);

export default ClientResetPassword;
