import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import _ from "lodash";
import {HM_AddPatientMH, HM_EditPatientMH} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {RES_PATIENT_MHFS, saveMedicalHistory} from "../../../../actions/personal";
import {MedicalHistoryForm} from "../../DefaultData";
import {ac} from "../../../../index";
import {getResource, RES_HOUSEKEEPING_MCS} from "../../../../actions/housekeeping";
import {getHousekeepingIds} from "../../Housekeeping/Utils";
import {ProgressBar} from "primereact/progressbar";
import {Card} from "primereact/card";
import {InputTextarea} from "primereact/inputtextarea";
import {ScrollPanel} from "primereact/scrollpanel";
import {SelectButton} from "primereact/selectbutton";

class ConnectedAddMedicalHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            medicalHistoryForm: props.edit ? _.cloneDeep(props.form) : _.cloneDeep(MedicalHistoryForm(ac.getMcId())),
        };

        this.state.medicalHistoryForm.patient = {id: props.patientId};

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
        this.insertConditions = this.insertConditions.bind(this);
    }

    componentDidMount() {

        if (this.props.edit) {

            const medicalConditions = [];
            this.state.medicalHistoryForm.entries.forEach(entry => {

                const condition = {...entry.condition};
                condition.yes = entry.yes;
                condition.comment = entry.comment;
                condition.entryId = entry.id;
                medicalConditions.push(condition);
            });

            this.setState({medicalConditions: [..._.sortBy(medicalConditions, ['medicalHistoryFormIndex', 'name'], ['asc', 'asc'])], medicalConditionsLoaded: true})
        } else {
            if (!this.props.medicalConditionsLoaded) {
                this.props.getMedicalConditions();
            } else {

                const formConditions = this.props.medicalConditions.filter((condition => {
                    return condition.includeInMedicalistoryForm;

                }));

                this.setState({medicalConditions: [..._.sortBy(formConditions, ['medicalHistoryFormIndex', 'name'], ['asc', 'asc'])]})
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_MCS.GET.receive:

                    const formConditions = this.props.medicalConditions.filter((condition => {
                        return condition.includeInMedicalistoryForm;

                    }));
                    this.setState({medicalConditions: _.sortBy(formConditions, ['medicalHistoryFormIndex', 'name'], ['asc', 'asc']), medicalConditionsLoaded: true})
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        const conditionIndex = _.findIndex(state.medicalConditions, condition => condition.id === event.id);
        _.set(state.medicalConditions[conditionIndex], event.owner, event.value);

        this.setState(state);
    }

    onSave = () => {

        const medicalHistoryForm = {...this.state.medicalHistoryForm};

        medicalHistoryForm.entries = [];
        let fakeId = -1;

        this.state.medicalConditions.forEach(condition => {
            const entryCondition = {...condition};
            const id = this.props.edit ? condition.entryId : fakeId--;
            delete entryCondition.comment;
            delete entryCondition.yes;
            delete entryCondition.entryId;
            const entry = {id, mc: medicalHistoryForm.mc, form: null, condition: entryCondition, comment: condition.comment, yes: condition.yes};
            medicalHistoryForm.entries.push(entry);
        })

        this.props.saveMedicalHistory({form: medicalHistoryForm, image: null, memberId: null});
        this.onHide();
    }

    onHide() {
        this.props.onHideDialog(this.props.edit ? HM_EditPatientMH.id : HM_AddPatientMH.id);
    }

    insertConditions() {

        let key = 1001;

        const conditions = [];

        const selectItems = [
            {label: 'Yes', value: true},
            {label: 'No', value: false}
        ];

        if (this.state.medicalConditions) {
            this.state.medicalConditions.forEach(condition => {

                if (condition.formHeader) {
                    conditions.push(
                        <div key={key++}
                             style={{
                                 backgroundColor: 'rgb(151,188,215)',
                                 marginTop: '5px',
                                 marginBottom: '5px',
                                 paddingTop: '5px',
                                 paddingBottom: '5px',
                                 display: 'flex', flexDirection: 'column'
                             }}>
                            <span key={key++} style={{textAlign: 'center'}}>{condition.formEntry}</span>
                        </div>
                    )
                } else {
                    conditions.push(
                        <Card key={key++} header={condition.formEntry}>
                            <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>
                                <div className="p-col-2">
                                    <SelectButton value={condition.yes}
                                                  options={selectItems}
                                                  onChange={(e) =>
                                                      this.onChange({id: condition.id, owner: 'yes', value: e.value})
                                                  }/>
                                </div>
                                <div className="p-col-10">
                                    <InputTextarea value={condition.comment}
                                                   autoResize={true}
                                                   rows={3}
                                                   onChange={(e) => {
                                                       this.onChange({
                                                           id: condition.id,
                                                           owner: 'comment',
                                                           value: e.target.value
                                                       })
                                                   }}
                                    />
                                </div>
                            </div>
                        </Card>
                    )
                }
            });
        }
        return conditions;
    }

    render() {

        if (!this.state.medicalConditionsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.onSave()
                        }}
                />
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        return (
            <Dialog style={{width: "50%"}}
                    header={HM_AddPatientMH.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    onHide={this.onHide}>

                <ScrollPanel style={{width: '100%', height: '250px'}} className='custom'>
                    {this.insertConditions()}
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        medicalConditionsLoaded,
        medicalConditions,
    } = getHousekeepingIds(state, ownProps);

    return {

        medicalConditionsLoaded,
        medicalConditions,

        message: state.stateManagement.message,

        loginIdentity: state.login.user,
        userId: state.login.userId,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getMedicalConditions: () => dispatch(getResource(RES_HOUSEKEEPING_MCS.GET, {})),
        saveMedicalHistory: (medicalHistoryForm) => dispatch(saveMedicalHistory(RES_PATIENT_MHFS.SAVE, medicalHistoryForm)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddMedicalHistory = connect(mapStateToProps, MapDispatchToProps)(ConnectedAddMedicalHistory);

export default AddMedicalHistory;
