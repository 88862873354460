import * as Actions from '../actions/index';
import _ from 'lodash';

const initialState = {

    results: [],
    searchComplete: false,
};

export const findReferrer = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.REQUEST_REFERRER_SEARCH:
            case Actions.CLEAR_REFERRER_SEARCH:

                return {
                    ...state,
                    results: [],
                    searchComplete: false,
                };

            case Actions.RECEIVE_REFERRER_ADD:

                const {id, firstName, lastName, companyName, type, telephone, email} = action.payload.referrer;
                const referrerResult = {id, firstName, lastName, companyName, type, telephone, email};

                if (state.searchComplete) {

                    const addState = {...state};
                    addState.results.push(referrerResult);
                    return addState;
                }
                return state;

            case Actions.RECEIVE_REFERRER_SEARCH:

                return {
                    ...state,
                    results: action.payload,
                    searchComplete: true,
                };

            case Actions.REQUEST_REFERRER_DOWNLOAD:

                return {
                    ...state,
                    xlsBuffer: null,
                };

            case Actions.RECEIVE_REFERRER_DOWNLOAD:

                return {
                    ...state,
                    xlsBuffer: action.payload,
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};