import React from 'react';
import {JawUpper} from "../Model/Constants";
import {getTranslate} from "./ChartingUtils";
import {
    AdultLowerCrownOnImplant,
    AdultUpperCrownOnImplant,
    LowerOnlayPoints1,
    UpperOnlayPoints1
} from "../Model/Points";
import {getRandomKey} from "../Utils/general";
import {ToothBaseComponent} from "./ToothBaseComponent";

export class CrownOnImplant extends ToothBaseComponent {

    constructor(props) {
        super(props);

        this.state = {};
    };

    handleLeave() {
        this.setState({cursor: ''})
    }

    render() {

        const mat = this.props.data.material;

        let topPoints = [];
        let rootPoints = [];

        let image = '';

        if (this.props.data.jaw === JawUpper) {
            topPoints = UpperOnlayPoints1;
            rootPoints = AdultUpperCrownOnImplant;
            image = 'implantFixtureU.png';
        } else {
            topPoints = LowerOnlayPoints1;
            rootPoints = AdultLowerCrownOnImplant;
            image = 'implantFixtureL.png';
        }

        const rgb = `rgb(${mat.red},${mat.green},${mat.blue})`;
        const key = `${this.props.data.pos.offset}-${this.props.data.jaw}`;

        return (
            <g key={getRandomKey()} onContextMenu={(event) => {

                event.preventDefault();

                if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
                    this.props.data.currentCommand.setTarget(this.props.data);
                }
                this.props.mouth.setCurrentItem(this.props.data);
                this.props.cm(event);
            }}
               onMouseEnter={() => this.handleEnter()}
               onMouseLeave={() => this.handleLeave()}

               style={{cursor: this.state.cursor}}

               onClick={() => {
                   if (this.props.data.currentCommand != null && this.props.data.currentCommand.canExecute(this))
                       this.props.onClick(this.props.data);
               }}
            >
                <image alt='' key={key+'i'} x='0' y="0" width="20" height="70" href={`/assets/images/${image}`}
                       transform={getTranslate(this.props.data, 15, -5, 15, 85)}
                />
                <polygon key={key+'t'}
                         points={topPoints}
                         style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                         transform={getTranslate(this.props.data, 1, 100, 1, 0)}
                />
                <polygon key={key+'r'}
                         points={rootPoints}
                         style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                         transform={getTranslate(this.props.data, 1, 0, 1, 57)}
                />
            </g>
        )
    }
}
