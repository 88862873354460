import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import _ from "lodash";
import {SR_HOLIDAY, TB_AddHoliday, TB_EditHoliday} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import * as DefaultData from "../../DefaultData";
import {calendar, spinner, spinnerStep} from "../../../PatientDynamicItems/OnChangeUtils";

export default class AddScheduleRule extends Component {

    constructor(props) {
        super(props);

        if (props.editing) {
            this.state = {
                rule: props.rule,
            }
        } else {
            this.state = {
                rule: _.cloneDeep(DefaultData.ScheduleRule(ac.getMcId(), props.practiceDetails)),
            }
        }

        if (!props.editing) {
            this.state.rule.user = props.createdFor;
            this.state.rule.createdOn = new Date();
            this.state.rule.startingOn = new Date();
            this.state.rule.endingOn = new Date();
            this.state.rule.type = SR_HOLIDAY;
        } else {
            this.state.rule.startingOn = new Date(props.rule.startingOn);
            this.state.rule.endingOn = new Date(props.rule.endingOn);
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    render() {

        const ruleDefined = this.state.rule !== null ? '' : 'disabled';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onSaveRuleDialog(this.state.rule)
                        }}
                        disabled={ruleDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            const target = this.props.editing ? TB_EditHoliday.id : TB_AddHoliday.id;
                            this.props.onHideDialog(target)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'rule',
            rule: this.state.rule,
        };

        const spinnerWidth = 1;

        return (
            <Dialog style={{width: "40%"}}
                    header={this.props.header}
                    footer={footer}
                    modal={true}
                    visible={true}
                    resizable={true}
                    onHide={() => {
                        const target = this.props.editing ? TB_EditHoliday.id : TB_AddHoliday.id;
                        this.props.onHideDialog(target)
                    }}>

                <React.Fragment>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <div style={{margin: '4px'}}>
                            <label>Starting On</label>
                        </div>
                        <div style={{margin: '4px'}}>
                            {calendar(props, 'startingOn', false, false)}
                        </div>
                        <div style={{margin: '4px'}}>
                            <label>Ending On</label>
                        </div>
                        <div style={{margin: '4px'}}>
                            {calendar(props, 'endingOn', false, false)}
                        </div>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <div style={{margin: '4px'}}>
                            <label>Leaving Time</label>
                        </div>
                        <div style={{margin: '4px'}}>
                            {spinner(props, `startHour[0][0]`, 1, 23, false, false, spinnerWidth)}
                        </div>
                        <div style={{margin: '4px'}}>
                            <label>H</label>
                        </div>
                        <div style={{margin: '4px'}}>
                            {spinnerStep(props, `startMin[0][0]`, 0, 59, 15, false, false, spinnerWidth)}
                        </div>
                        <div style={{margin: '4px'}}>
                            <label>M&nbsp;&nbsp;&nbsp;&nbsp;Returning Time</label>
                        </div>
                        <div style={{margin: '4px'}}>
                            {spinner(props, `endHour[0][0]`, 1, 23, false, false, spinnerWidth)}
                        </div>
                        <div style={{margin: '4px'}}>
                            <label>H</label>
                        </div>
                        <div style={{margin: '4px'}}>
                            {spinnerStep(props, `endMin[0][0]`, 0, 59, 15, false, false, spinnerWidth)}
                        </div>
                        <div style={{margin: '4px'}}>
                            <label>M</label>
                        </div>
                    </div>
                </React.Fragment>
            </Dialog>
        )
    }
};
