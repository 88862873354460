import React from 'react';
import _ from 'lodash';

import {Column} from "primereact/column";
import {RadioButton} from "primereact/radiobutton";
import {DataTable} from "primereact/datatable";
import {Panel} from 'primereact/panel';
import {calendar, checkBox, dropDown, inputTextArea, spinner} from "../../../../PatientDynamicItems/OnChangeUtils";
import {
    EDI_CR_AssessAndFittedSubmit,
    EDI_CR_AssessAndRefuse,
    EDI_CR_AssessAndReview,
    PART5_ASS_APP_FIT,
    PART5_ASS_REF,
    PART5_ASS_REV,
    PART5_DATE_APP_FIT,
    PART5_DATE_OF_ACT,
    PART5_DATE_OF_ASS,
    PART5_DATE_OF_REF,
    PART5_DATE_UNAVAILABLE,
    PART5_DECLARATION_1,
    PART5_DECLARATION_2,
    PART6_AEST_COMP,
    PART6_IOTN
} from "../../Constants";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Button} from "primereact/components/button/Button";
import {ICON_COPY} from "../../../../../icons";
import {getDateDiffRaw} from "../../../../Utils";
import moment from "moment";

const onNoteSelection = (props, row) => {

    let observation = _.get(props.claimProps, 'claim.observation');

    if (observation.trim() === '')
        observation += `${row.data.content}`;
    else
        observation += ` ${row.data.content}`;

    props.claimProps.onChange({owner: `${props.claimProps.target}.observation`, value: observation});
};

const copyAssessmentDate = (props, index) => {

    const claim = {...props.claim};

    if (index === 1) {
        claim.trtdatgrpDateOfAcceptance = claim.trtdatgrpDateOfExamination;
    } else {
        claim.trtdatgrpDateOfCompletion = claim.trtdatgrpDateOfExamination;
    }
    props.onChange({owner: props.target, value: claim});
}

export const Part5Content = (props) => {

    const AssessAndReview = props.claimProps.claim.completionReason === EDI_CR_AssessAndReview;
    const AssessAndRefuse = props.claimProps.claim.completionReason === EDI_CR_AssessAndRefuse;
    const AssessAndFittedSubmit = props.claimProps.claim.completionReason === EDI_CR_AssessAndFittedSubmit;

    const showReferralDate = props.claimProps.claim.referralDate !== null;
    const showAssessmentDate = props.claimProps.claim.referralDate !== null;
    const showAcceptanceDate = props.claimProps.claim.referralDate !== null;
    const showFittingDate = props.claimProps.claim.referralDate !== null;

    const age = getDateDiffRaw(props.patient.dob, props.claimProps.claim.referralDate);
    const commApprovalRequired = (age[0] >= 18) && moment(props.claimProps.claim.referralDate).isAfter(new moment('01-04-2019', 'MM-DD-YYYY'))

    let templates = _.filter(props.templateNotes, template => {
        const pattern = `${template.name}`;
        return (pattern.toLowerCase().includes(props.globalFilter.toLowerCase()) || props.globalFilter === '') && pattern.trim() !== ''
    });
    const sortedTemplates = _.orderBy(templates, 'name', 'asc');

    const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
        <InputText type="search"
                   onInput={(e) => {
                       props.onChange({owner: 'globalFilter', value: e.target.value});
                   }}
                   placeholder="Search"
                   size="50"
                   autoFocus
        />
        <Dropdown options={[]}/>
    </div>;

    const iotnColor = props.claimProps.claim.iotnScore === 0 ? 'red' : '';

    function addOptionalCommApproval(commApprovalRequired, claimProps) {

        if (commApprovalRequired) {
            return <>
                <div className="p-col-3">
                    {checkBox(props.claimProps, 'commApproval', '', !commApprovalRequired, false)}
                </div>
                <div className="p-col-9">
                    <label>Commissioning Approval</label>
                </div>
            </>
        }
    }

    return (
        <div className="p-grid p-fluid" style={{fontSize: 'small'}}>
            <div className="p-lg-7 p-md-7">
                <Panel header='Part 5'>
                    <div className="p-col-12">
                        <RadioButton checked={AssessAndReview}
                                     onChange={(e) => {
                                         props.onChange({
                                             owner: `claim.completionReason`,
                                             value: EDI_CR_AssessAndReview
                                         })
                                     }}
                        />
                        <label style={{fontSize: 'small'}} className='p-radiobutton-label'>{PART5_ASS_REV}</label>
                    </div>
                    <div className="p-col-12">
                        <RadioButton checked={AssessAndRefuse}
                                     onChange={(e) => {
                                         props.onChange({
                                             owner: `claim.completionReason`,
                                             value: EDI_CR_AssessAndRefuse
                                         })
                                     }}
                        />
                        <label style={{fontSize: 'small'}} className='p-radiobutton-label'>{PART5_ASS_REF}</label>
                    </div>
                    <div className="p-col-12">
                        <RadioButton checked={AssessAndFittedSubmit}
                                     onChange={(e) => {
                                         props.onChange({
                                             owner: `claim.completionReason`,
                                             value: EDI_CR_AssessAndFittedSubmit
                                         })
                                     }}
                        />
                        <label style={{fontSize: 'small'}}
                               className='p-radiobutton-label'>{PART5_ASS_APP_FIT}</label>
                    </div>
                </Panel>
                <Panel header=' '>
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <label>{`${PART5_DATE_OF_REF} `}</label>
                        </div>
                        <div className="p-col-6">
                            {showReferralDate ? calendar(props.claimProps, 'referralDate', false, false) : 'Unavailable'}
                        </div>

                        <div className="p-col-5">
                            <label>{`${PART5_DATE_OF_ASS} `}</label>
                        </div>
                        <div className="p-col-1"/>
                        <div className="p-col-6">
                            {showAssessmentDate ? calendar(props.claimProps, 'trtdatgrpDateOfExamination', false, false) : PART5_DATE_UNAVAILABLE}
                        </div>

                        <div className="p-col-5">
                            <label>{`${PART5_DATE_OF_ACT} `}</label>
                        </div>
                        <div className="p-col-1">
                            <Button icon={ICON_COPY}
                                    tooltip='Copy Assessment Date'
                                    tooltipOptions={{position: 'left'}}
                                    onClick={() => copyAssessmentDate(props.claimProps, 1)}
                            />
                        </div>
                        <div className="p-col-6">
                            {showAcceptanceDate ? calendar(props.claimProps, 'trtdatgrpDateOfAcceptance', false, false) : PART5_DATE_UNAVAILABLE}
                        </div>

                        <div className="p-col-5">
                            <label>{`${PART5_DATE_APP_FIT} `}</label>
                        </div>
                        <div className="p-col-1">
                            <Button icon={ICON_COPY}
                                    tooltip='Copy Assessment Date'
                                    tooltipOptions={{position: 'left'}}
                                    onClick={() => copyAssessmentDate(props.claimProps, 2)}
                            />
                        </div>
                        <div className="p-col-6">
                            {showFittingDate ? calendar(props.claimProps, 'trtdatgrpDateOfCompletion', false, false) : PART5_DATE_UNAVAILABLE}
                        </div>

                        <div className="p-col-3">
                            <label style={{color: iotnColor}}>{`${PART6_IOTN} `}</label>
                        </div>
                        <div className="p-col-3">
                            {spinner(props.claimProps, 'iotnScore', 0, 5, false, false)}
                        </div>
                        <div className="p-col-3">
                            <label>{`${PART6_AEST_COMP} `}</label>
                        </div>
                        <div className="p-col-3">
                            {spinner(props.claimProps, 'aestheticComponent', 0, 10, false, false)}
                        </div>

                        <div className="p-col-3">
                            {checkBox(props.claimProps, 'declarationsBCDS_1', '', false, false)}
                        </div>
                        <div className="p-col-9">
                            {inputTextArea({
                                target: 'dec1',
                                dec1: {dec1: PART5_DECLARATION_1}
                            }, 'dec1', 2, -1, true, false)}
                        </div>
                        <div className="p-col-3">
                            {checkBox(props.claimProps, 'declarationsBCDS_2', '', false, false)}
                        </div>
                        <div className="p-col-9">
                            {inputTextArea({
                                target: 'dec2',
                                dec2: {dec2: PART5_DECLARATION_2}
                            }, 'dec2', 2, -1, true, false)}
                        </div>

                        <div className="p-col-3">
                            <label>Provider</label>
                        </div>
                        <div className="p-col-9">
                            {dropDown(props.claimProps, 'providers', 'performer', 'fullName', false, false)}
                        </div>

                        {addOptionalCommApproval(commApprovalRequired, props.claimProps)}

                        <div className="p-col-12">
                            <label>Observations</label>
                        </div>
                        <div className="p-col-12">
                            {inputTextArea(props.claimProps, 'observation', 4, -1, false, false)}
                        </div>
                        <div className="p-col-12">
                            <label>Comments</label>
                        </div>
                        <div className="p-col-12">
                            {inputTextArea(props.claimProps, 'narrative', 4, -1, false, false)}
                        </div>
                    </div>
                </Panel>
            </div>
            <div className="p-lg-5 p-md-5">

                <DataTable header={tableHeader}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           value={sortedTemplates}
                           selectionMode="single"
                           paginator={true}
                           rows={props.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={(e) => props.onPage(e)}
                           first={props.first}
                           onRowDoubleClick={row => onNoteSelection(props, row)}
                >
                    <Column field="name"/>
                </DataTable>
            </div>
        </div>
    )
};
