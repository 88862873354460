import React from 'react';
import moment from "moment";
import {connect} from "react-redux";
import _ from "lodash";

import {Button} from 'primereact/components/button/Button';
import {Calendar} from 'primereact/components/calendar/Calendar';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TreeTable} from 'primereact/components/treetable/TreeTable';
import {
    HM_notImplemented,
    JSON_DATE_FORMAT,
    REM_STATUS_CLOSED,
    RR_selectedItem,
    RRT_REMINDER_EMAIL,
    RRT_REMINDER_HOMEPHONE,
    RRT_REMINDER_MOBILE,
    RRT_REMINDER_OTHERPHONE,
    RRT_REMINDER_SMS,
    RRT_REMINDER_SNAILMAIL,
    RRT_REMINDER_WORKPHONE,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_HELP,} from "../../../icons";
import {HELP_REMINDERS_DUE, setState, SM_REMINDERS_DUE, stateRequest} from "../../../actions/stateManagement";
import {
    closeReminder,
    getResource,
    HM_PatientsReminders,
    HM_ReminderClose,
    HM_ReminderCloseJG,
    HM_ReminderSend,
    HM_ReminderSendJG,
    HM_RemindersGJActionSendElectronic,
    HM_RemindersIndividualActionSendElectronic,
    RES_getRemindersDue,
    RES_PatientReminder,
    sendReminder,
    updatePatientReminderDetails
} from "../../../actions/reminders";
import {getAllUsers} from "../../../actions/users";
import * as Actions from "../../../actions/index";
import {ShowMessageDialog, ShowQuestionDialog} from "../Diary/components/EventComponent";
import {RecallReminderPatientDetails} from "../../PatientDynamicItems/RecallReminderPatientDetails";
import {calcAllReminders} from "./calcAllReminders";
import {dateTemplate} from "../../PatientDynamicItems/Utils";
import {
    anyEmailsOrSMSReminders,
    anyJGEmailsOrSMSReminders,
    getIndividualEmailsOrSMSReminders,
    showPatientDetailsPage
} from "../PatientDetails/Utils";
import {RecallReminderComponent} from "../../RecallReminderComponent";
import {SMSBalance} from "../../PatientDynamicItems/SMSBalance";
import {getSMSCreditLimit, RES_PATIENT_SMS} from "../../../actions/personal";
import {ProgressBar} from "primereact/progressbar";
import {CONST_FEMALE, CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import RemindersDueErrorBoundary from "./Utils";
import {contactReminderMethodTextTemplate, tb_boilerPlate, tb_boilerPlateRight} from "../../Utils";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {DIARY_BACK_DAY, DIARY_FOR_DAY} from "../Diary/Constants";
import {getPreferenceIds} from "../Preferences/Utils";

export class ConnectedRemindersDue extends RecallReminderComponent {

    constructor(props) {
        super(props);

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;

            const {nonGroupResult, groupResult} = calcAllReminders(this.state.remindersDue);

            this.state.individualReminders = nonGroupResult;
            this.state.groupJointReminders = groupResult;
        } else {
            this.state = {

                stateManagementId: SM_REMINDERS_DUE.id,
                canSave: {status: false, activeIndex: 0, source: RES_getRemindersDue.CLEAR},

                selectedDate: new Date(),
                offset: 0,

                individualReminders: [],
                groupJointReminders: [],

                selectedIndividualReminder: null,
                selectedGroupJointReminderKey: null,

                selectedReminder: RR_selectedItem,

                firstIndividual: 0,
                individualRows: 20,

                firstGroup: 0,
                groupRows: 5,

                [HM_notImplemented.id]: false,
                [HM_ReminderClose.id]: false,
                [HM_ReminderCloseJG.id]: false,
                [HM_ReminderSend.id]: false,
                [HM_ReminderSendJG.id]: false,
                [HM_RemindersIndividualActionSendElectronic.id]: false,
                [HM_RemindersGJActionSendElectronic.id]: false,
            }
        }
        this.state.selectedReminder.isAppointmentDateShown = true;

        this.cm1 = null;
        this.cm2 = null;
        this.closeItem = null;
        this.sendItem = null;

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onShowDetails = this.onShowDetails.bind(this);

        this.onChangeLive = this.onChangeLive.bind(this);
        this.onChangeMethodLive = this.onChangeMethodLive.bind(this);

        this.closeReminder = this.closeReminder.bind(this);
        this.sendReminder = this.sendReminder.bind(this);

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    fetchTheReminders(forward, offset) {

        if (forward && offset < 10)
            offset++;
        else if (!forward && offset > 0)
            offset--;

        const reminderPeriod = this.props.practiceDetails.reminderLookAhead;
        const date = new moment();
        date.add(reminderPeriod + offset, 'days');

        this.setState({selectedDate: date, offset}, () => {
            this.props.getReminders({date: moment(date).format(JSON_DATE_FORMAT)});
            this.props.setState(SM_REMINDERS_DUE.id, {...this.state});
        });
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getSMSCreditLimit();
            this.props.getAllUsersShort();
            this.fetchTheReminders(true, -1);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_REMINDERS_DUE:

                    const {nonGroupResult, groupResult} = calcAllReminders(this.props.remindersDue);

                    this.setState({
                        remindersDue: this.props.remindersDue,
                        individualReminders: nonGroupResult,
                        groupJointReminders: groupResult
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;
                case Actions.REMINDER_CLOSE:
                default:
                    break;
            }
        }
    }

    onChangeLive(reminderOrdinal, recallOrdinal, value) {

        const selectedReminder = {...this.state.selectedReminder};
        selectedReminder.summaryData = false;

        switch (reminderOrdinal) {
            case RRT_REMINDER_HOMEPHONE.ordinal:
                selectedReminder.homeTelephone = value;
                break;
            case RRT_REMINDER_WORKPHONE.ordinal:
                selectedReminder.workTelephone = value;
                break;
            case RRT_REMINDER_OTHERPHONE.ordinal:
                selectedReminder.otherTelephone = value;
                break;
            case RRT_REMINDER_MOBILE.ordinal:
                selectedReminder.mobile = value;
                break;
            case RRT_REMINDER_EMAIL.ordinal:
                selectedReminder.email = value;
                break;
            default:
                break;
        }
        const index = _.findIndex(this.state.remindersDue, reminder => reminder.id === selectedReminder.id);
        const remindersDue = [...this.state.remindersDue];
        remindersDue[index] = selectedReminder;

        const {nonGroupResult, groupResult} = calcAllReminders(remindersDue);

        this.setState({
            remindersDue,
            individualReminders: nonGroupResult,
            groupJointReminders: groupResult,
            selectedReminder
        }, () => {
            this.props.updatePatientReminderDetails(this.state.selectedReminder);
            this.props.setState(this.state.stateManagementId, this.state);
        });
    };

    onChangeMethodLive(reminderOrdinal, recallOrdinal) {

        const remindersDue = [...this.state.remindersDue];
        const selectedReminder = {...this.state.selectedReminder};
        selectedReminder.summaryData = false;

        switch (reminderOrdinal) {
            case RRT_REMINDER_HOMEPHONE.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_HOMEPHONE.name;
                break;
            case RRT_REMINDER_WORKPHONE.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_WORKPHONE.name;
                break;
            case RRT_REMINDER_OTHERPHONE.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_OTHERPHONE.name;
                break;
            case RRT_REMINDER_MOBILE.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_MOBILE.name;
                break;
            case RRT_REMINDER_SMS.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_SMS.name;
                break;
            case RRT_REMINDER_EMAIL.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_EMAIL.name;
                break;
            case RRT_REMINDER_SNAILMAIL.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_SNAILMAIL.name;
                break;
            default:
                break;
        }
        const index = _.findIndex(remindersDue, reminder => {
            return reminder.id === selectedReminder.id;
        });
        remindersDue[index] = selectedReminder;

        const {nonGroupResult, groupResult} = calcAllReminders(remindersDue);

        this.setState({
            remindersDue,
            individualReminders: nonGroupResult,
            groupJointReminders: groupResult,
            selectedReminder: this.state.selectedReminder.header ? null : selectedReminder,
        }, () => {
            this.props.updatePatientReminderDetails(this.state.selectedReminder);
            this.props.setState(this.state.stateManagementId, this.state);
        });
    };

    onCloseClick() {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: this.state.stateManagementId});
    }

    onDateChange(forward) {

        this.setState({firstIndividual: 0, firstGroup: 0}, () => {
            this.fetchTheReminders(forward, this.state.offset);
        })
    }

    closeReminder() {

        const remindersDue = _.filter(this.state.remindersDue, reminder => this.closeItem.id !== reminder.id);

        const details = {
            reminderId: this.closeItem.id,
            userId: this.props.loginIdentity.id,
            state: REM_STATUS_CLOSED.name,
        };

        const {nonGroupResult, groupResult} = calcAllReminders(remindersDue);

        this.setState({
            remindersDue,
            individualReminders: nonGroupResult,
            groupJointReminders: groupResult,
            [HM_ReminderClose.id]: false
        }, () => {
            this.props.setState(this.state.stateManagementId, this.state);
            this.props.closeReminder(details);
        });
    }

    sendIndividualReminders = () => {

        const {emailSendList, smsSendList} = getIndividualEmailsOrSMSReminders(this.state.individualReminders);

        if (emailSendList.length > 0) this.props.sendEmailReminder(emailSendList);
        if (smsSendList.length > 0) this.props.sendSMSReminder(smsSendList);

        let remainingReminders = _.differenceBy(this.state.remindersDue, _.map([...emailSendList, ...smsSendList], remId => {
            return {'id': remId}
        }), 'id');

        const {nonGroupResult} = calcAllReminders(remainingReminders);

        this.setState({
            remindersDue: remainingReminders,
            individualReminders: nonGroupResult,
            [HM_RemindersIndividualActionSendElectronic.id]: false
        }, () => {
            this.props.setState(this.state.stateManagementId, this.state);
        });
    }

    sendGroupJointReminders = () => {

        let remainingReminders = [...this.state.remindersDue];

        this.state.groupJointReminders.forEach(item => {

            if (item.data.bgEntry) {

                const emailSendGroupList = [];
                const smsSendGroupList = [];

                item.children.forEach(child => {
                    if (child.data.reminderMethod === RRT_REMINDER_EMAIL.name) {
                        emailSendGroupList.push(child.data.id)
                    } else if (child.data.reminderMethod === RRT_REMINDER_SMS.name) {
                        smsSendGroupList.push(child.data.id)
                    }
                    remainingReminders = _.remove(remainingReminders, reminder => reminder.id === child.data.id);
                })

                if (emailSendGroupList.length > 0) {
                    this.props.sendGroupEmailReminder(emailSendGroupList);
                }
                if (smsSendGroupList.length > 0) {
                    this.props.sendGroupSMSReminder(smsSendGroupList);
                }
            } else {

                const emailSendJointList = [];
                const smsSendJointList = [];

                item.children.forEach(child => {
                    if (child.data.reminderMethod === RRT_REMINDER_EMAIL.name) {
                        emailSendJointList.push(child.data.id)
                    } else if (child.data.reminderMethod === RRT_REMINDER_SMS.name) {
                        smsSendJointList.push(child.data.id)
                    }
                    remainingReminders = _.remove(remainingReminders, reminder => reminder.id === child.data.id);
                })
                if (emailSendJointList.length > 0) {
                    this.props.sendJointEmailReminder(emailSendJointList);
                }
                if (smsSendJointList.length > 0) {
                    this.props.sendJointSMSReminder(smsSendJointList);
                }
            }
        });

        const {groupResult} = calcAllReminders(remainingReminders);

        this.setState({
            remindersDue: remainingReminders,
            groupJointReminders: groupResult,
            [HM_RemindersGJActionSendElectronic.id]: false
        }, () => {
            this.props.setState(this.state.stateManagementId, this.state);
        });
    }

    sendReminder() {

        let remainingReminders = [];
        let smsSendList = [];
        let emailSendList = [];

        if (this.sendItem.id === undefined) { // undefined as joint / groups return a key not the object

            if (isNaN(this.sendItem)) { // if item is a string it is a group / joint key

                remainingReminders = [...this.state.remindersDue];

                this.state.groupJointReminders.forEach(group => {

                    if (group.key === this.sendItem) {

                        group.children.forEach(child => {
                            remainingReminders = _.filter(remainingReminders, reminder => child.data.id !== reminder.id);

                            switch (child.data.reminderMethod) {
                                case RRT_REMINDER_EMAIL.name :
                                    emailSendList.push(child.data.id);
                                    break;
                                case RRT_REMINDER_SMS.name :
                                    smsSendList.push(child.data.id);
                                    break;
                                default:
                            }
                        });
                    }
                });
            } else { // otherwise it is a reminder id.

                remainingReminders = _.filter(this.state.remindersDue, reminder => this.sendItem !== reminder.id);

                const sendReminder = _.find(this.state.remindersDue, reminder => reminder.id === this.sendItem);

                switch (sendReminder.reminderMethod) {
                    case RRT_REMINDER_EMAIL.name :
                        emailSendList.push(sendReminder.id);
                        break;
                    case RRT_REMINDER_SMS.name :
                        smsSendList.push(sendReminder.id);
                        break;
                    default:
                }
            }
        } else {
            remainingReminders = _.filter(this.state.remindersDue, reminder => this.sendItem.id !== reminder.id);

            switch (this.sendItem.reminderMethod) {
                case RRT_REMINDER_EMAIL.name :
                    emailSendList.push(this.sendItem.id);
                    break;
                case RRT_REMINDER_SMS.name :
                    smsSendList.push(this.sendItem.id);
                    break;
                default:
            }
        }

        const {nonGroupResult, groupResult} = calcAllReminders(remainingReminders);

        this.setState({
            remindersDue: remainingReminders,
            individualReminders: nonGroupResult,
            groupJointReminders: groupResult,
            [HM_ReminderSend.id]: false
        }, () => {

            if (smsSendList.length > 0)
                this.props.sendSMSReminder(smsSendList);

            if (emailSendList.length > 0)
                this.props.sendEmailReminder(emailSendList);

            this.props.setState(this.state.stateManagementId, this.state);
        });
    }

    showDialogs() {

        if (this.state[HM_ReminderClose.id]) {
            return ShowQuestionDialog(this, HM_ReminderClose, this.closeReminder)
        } else if (this.state[HM_ReminderSend.id]) {
            return ShowQuestionDialog(this, HM_ReminderSend, this.sendReminder)
        } else if (this.state[HM_RemindersIndividualActionSendElectronic.id]) {
            return ShowQuestionDialog(this, HM_RemindersIndividualActionSendElectronic, this.sendIndividualReminders)
        } else if (this.state[HM_RemindersGJActionSendElectronic.id]) {
            return ShowQuestionDialog(this, HM_RemindersGJActionSendElectronic, this.sendGroupJointReminders)
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onShowDetails({id, firstName, lastName, gender, nhsPatient}, groupId) {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    buildMenu(individual) {

        let items = [];

        const {selectedReminder} = this.state;

        items.push({
            label: HM_PatientsReminders.label,
            icon: HM_PatientsReminders.icon,
            command: () => {
                const {
                    patientId,
                    patientFirstName,
                    patientLastName,
                    bg1Id,
                    female,
                    nhsPatient
                } = selectedReminder;

                const details = {
                    id: patientId,
                    firstName: patientFirstName,
                    lastName: patientLastName,
                    gender: female ? CONST_FEMALE : CONST_MALE,
                    nhsPatient,
                };
                this.onShowDetails(details, bg1Id);
            }
        });

        if (selectedReminder.reminderMethod === RRT_REMINDER_EMAIL.name || selectedReminder.reminderMethod === RRT_REMINDER_SMS.name) {
            items.push({
                label: HM_ReminderSend.label,
                icon: HM_ReminderSend.icon,
                command: () => {
                    this.sendItem = selectedReminder;
                    this.onShowMenuEntry({item: {target: HM_ReminderSend.id}})
                },
            })
        }

        items.push({
            label: HM_ReminderClose.label,
            icon: HM_ReminderClose.icon,
            command: () => {
                this.closeItem = selectedReminder;
                this.onShowMenuEntry({item: {target: HM_ReminderClose.id}})
            },
        });

        return items;
    }

    render() {

        if (!this.props.usersLoaded || !this.props.remindersLoadedDue || !this.props.creditLimitLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const electronicIndividuals = anyEmailsOrSMSReminders(this.state.individualReminders);

        const headerRemindersIndividual = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title'
                      style={{marginRight: 15}}>{HM_RemindersIndividualActionSendElectronic.header}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button tooltipOptions={{position: 'right'}}
                        icon={HM_RemindersIndividualActionSendElectronic.icon}
                        label={HM_RemindersIndividualActionSendElectronic.label}
                        onClick={() => {
                            this.onShowMenuEntry({item: {target: HM_RemindersIndividualActionSendElectronic.id}});
                        }}
                        disabled={!electronicIndividuals}
                />
            </div>
        </div>;

        const electronicGroupJoints = anyJGEmailsOrSMSReminders(this.state.groupJointReminders);

        const headerRemindersGroupJoint = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title'
                      style={{marginRight: 15}}>{HM_RemindersGJActionSendElectronic.header}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button tooltipOptions={{position: 'right'}}
                        icon={HM_RemindersGJActionSendElectronic.icon}
                        label={HM_RemindersGJActionSendElectronic.label}
                        onClick={() => {
                            this.onShowMenuEntry({item: {target: HM_RemindersGJActionSendElectronic.id}});
                        }}
                        disabled={!electronicGroupJoints}
                />
            </div>
        </div>

        const individualReminders = _.orderBy(this.state.individualReminders, (ir) => {
            return moment(ir.appointmentDate).format('YYYYMMDD')
        }, ['asc']);

        const individualItems = this.buildMenu(true);
        const jointGroupItems = this.buildMenu(false);

        let key = 1000;

        return (
            <RemindersDueErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar left={
                        <React.Fragment>
                            {tb_boilerPlate((e) => this.onDateChange(false), DIARY_BACK_DAY.icon, DIARY_BACK_DAY.tooltip, key++)}
                            {tb_boilerPlate((e) => this.onDateChange(true), DIARY_FOR_DAY.icon, DIARY_FOR_DAY.tooltip, key++)}
                            <Calendar value={this.state.selectedDate.toDate()}
                                      dateFormat="D d M yy"
                                      monthNavigator={true}
                                      yearNavigator={true}
                                      yearRange="2019:2030"
                                      disabled={true}
                                      readOnlyInput={true}
                            />
                        </React.Fragment>}
                             right={<React.Fragment>
                                 {tb_boilerPlateRight((e) => window.open(HELP_REMINDERS_DUE), ICON_HELP, 'Knowledge Base', key++, true)}
                                 {tb_boilerPlateRight(this.onCloseClick, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                             </React.Fragment>}
                    />

                    <div className="p-grid" style={{fontSize: 'small'}}>
                        <div className='p-col-9'>

                            <Panel headerTemplate={headerRemindersIndividual}
                                   style={{paddingTop: '5px'}}
                            >

                                <ContextMenu style={{width: 250}} model={individualItems} ref={el => this.cm1 = el}/>

                                <DataTable value={individualReminders}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           paginator={true}
                                           rows={this.state.individualRows}
                                           first={this.state.firstIndividual}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => {
                                               this.setState({selectedReminder: RR_selectedItem});
                                               this.onPageFlex(e, 'firstIndividual', 'individualRows');
                                           }}
                                           selectionMode="single"
                                           selection={this.state.selectedIndividualReminder}
                                           onSelectionChange={(e) => {
                                               this.setState({
                                                   selectedIndividualReminder: e.value,
                                                   selectedReminder: e.value
                                               }, () => {
                                                   e.value.isAppointmentDateShown = true;
                                               })
                                           }}
                                           contextMenuSelection={this.state.selectedIndividualReminder}
                                           onContextMenuSelectionChange={e => {
                                               this.setState({
                                                   selectedIndividualReminder: e.value,
                                                   selectedReminder: e.value
                                               })
                                           }}
                                           onContextMenu={e => {
                                               this.cm2.hide(e.originalEvent);
                                               this.cm1.show(e.originalEvent);
                                           }}
                                >
                                    <Column field=''
                                            header=""
                                            style={{width: '2%'}}/>
                                    <Column body={row => contactReminderMethodTextTemplate(row)}
                                            header=""
                                            style={{width: '4%'}}/>
                                    <Column body={row => this.apTypeTemplate(row)}
                                            header=""
                                            style={{width: '4%'}}/>
                                    <Column body={row => dateTemplate(row.appointmentDate)}
                                            header="Appointment Date"
                                            style={{width: '20%'}}/>
                                    <Column body={row => this.patientTemplate(row)}
                                            header="Patient"
                                            style={{width: '30%'}}/>
                                    <Column body={row => this.userTemplate(row)}
                                            header="Clinician"
                                            style={{width: '30%'}}/>
                                    <Column body={row => this.statusTemplate(row)}
                                            header="Status"
                                            style={{width: '10%'}}/>
                                </DataTable>
                            </Panel>

                            <Panel headerTemplate={headerRemindersGroupJoint} style={{paddingTop: '5px'}}>

                                <ContextMenu style={{width: 250}} model={jointGroupItems} ref={el => this.cm2 = el}/>

                                <TreeTable value={this.state.groupJointReminders}
                                           paginator={true}
                                           rows={this.state.groupRows}
                                           first={this.state.firstGroup}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => {
                                               this.setState({selectedReminder: RR_selectedItem});
                                               this.onPageFlex(e, 'firstGroup', 'groupRows');
                                           }}
                                           selectionMode="single"
                                           selectionKeys={this.state.selectedGroupJointReminderKey}
                                           onSelectionChange={(e) => {
                                               let target = null;
                                               _.find(this.state.groupJointReminders, reminder => {
                                                   target = _.find(reminder.children, child => {
                                                       if (isNaN(e.value)) {
                                                           return child.key == e.value.substring(1, e.value.length);
                                                       } else {
                                                           return child.key === e.value;
                                                       }
                                                   });
                                                   return target;
                                               });
                                               this.setState({
                                                   selectedGroupJointReminderKey: e.value,
                                                   selectedReminder: target ? target.data : RR_selectedItem
                                               })
                                           }}
                                           style={{fontSize: 'small'}}
                                           contextMenuSelectionKey={this.state.selectedGroupJointReminderKey}
                                           onContextMenuSelectionChange={e => {
                                               let target = null;
                                               _.find(this.state.groupJointReminders, reminder => {
                                                   target = _.find(reminder.children, child => {
                                                       if (isNaN(e.value)) {
                                                           return child.key == e.value.substring(1, e.value.length);
                                                       } else {
                                                           return child.key === e.value;
                                                       }
                                                   });
                                                   return target;
                                               });
                                               this.setState({
                                                   selectedGroupJointReminderKey: e.value,
                                                   selectedReminder: target ? target.data : RR_selectedItem
                                               })
                                           }}
                                           onContextMenu={e => {
                                               this.cm1.hide(e.originalEvent);
                                               this.cm2.show(e.originalEvent);
                                           }}
                                           className='p-treetable-gridlines'
                                >
                                    <Column field=''
                                            header=""
                                            style={{width: '2%'}} expander/>
                                    <Column body={row => this.contactReminderMethodTreeTemplate(row)}
                                            header=""
                                            style={{width: '4%'}}/>
                                    <Column body={row => this.apTypeTreeTemplate(row)}
                                            header=""
                                            style={{width: '4%'}}/>
                                    <Column body={row => this.dateTreeTemplate(row)}
                                            header="Appointment Date"
                                            style={{width: '20%'}}/>
                                    <Column body={row => this.patientTreeTemplate(row)}
                                            header="Patient"
                                            style={{width: '30%'}}/>
                                    <Column body={row => this.userTreeTemplate(row)}
                                            header="Clinician"
                                            style={{width: '30%'}}/>
                                    <Column body={row => this.statusTreeTemplate(row)}
                                            header="Status"
                                            style={{width: '10%'}}/>
                                </TreeTable>
                            </Panel>
                        </div>
                        <div className='p-col-3'>
                            <RecallReminderPatientDetails selectedItem={this.state.selectedReminder}
                                                          contactMethod={this.state.selectedReminder.reminderMethod}
                                                          onChange={this.onChangeLive}
                                                          onChangeMethod={this.onChangeMethodLive}
                            />
                            <SMSBalance balance={this.props.creditLimit}/>
                        </div>
                    </div>
                </div>
            </RemindersDueErrorBoundary>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT_SAVE :
            case TAB_EXIT :
                this.props.stateRequest(this.state.canSave.source);
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        practiceDetailsLoaded,
        practiceDetails,
    } = getPreferenceIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        practiceDetailsLoaded,
        practiceDetails,

        remindersLoadedDue: state.recallsAndReminders.remindersDueLoaded,
        remindersDue: state.recallsAndReminders.remindersDueLoaded === false ? [] : state.recallsAndReminders.remindersDue,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        creditLimitLoaded: state.patients.creditLimitLoaded,
        creditLimit: state.patients.creditLimit,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        stateManagementId: SM_REMINDERS_DUE.id,
        currentState: state.stateManagement[SM_REMINDERS_DUE.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getReminders: (params) => dispatch(getResource(RES_getRemindersDue.GET, params)),
        getSMSCreditLimit: () => dispatch(getSMSCreditLimit(RES_PATIENT_SMS.CREDIT_LIMIT)),

        closeReminder: (details) => dispatch(closeReminder(RES_getRemindersDue.CLOSE, details)),

        sendSMSReminder: (sendList) => dispatch(sendReminder(RES_getRemindersDue.SEND_SMS, sendList)),
        sendEmailReminder: (sendList) => dispatch(sendReminder(RES_getRemindersDue.SEND_EMAIL, sendList)),

        sendJointSMSReminder: (sendList) => dispatch(sendReminder(RES_getRemindersDue.SEND_SMS_JOINT, sendList)),
        sendJointEmailReminder: (sendList) => dispatch(sendReminder(RES_getRemindersDue.SEND_EMAIL_JOINT, sendList)),

        sendGroupSMSReminder: (sendList) => dispatch(sendReminder(RES_getRemindersDue.SEND_SMS_GROUP, sendList)),
        sendGroupEmailReminder: (sendList) => dispatch(sendReminder(RES_getRemindersDue.SEND_EMAIL_GROUP, sendList)),

        updatePatientReminderDetails: (selectedReminder) => dispatch(updatePatientReminderDetails(RES_PatientReminder.UPDATE, selectedReminder)),

        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const RemindersDue = connect(mapStateToProps, mapDispatchToProps)(ConnectedRemindersDue);

export default RemindersDue;
