import React from 'react';
import _ from "lodash";
import {ac} from '../../../../index'

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {ICON_CAMERA, ICON_CANCEL} from "../../../../icons";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {BaseComponent} from "../../../BaseComponent";
import {connect} from "react-redux";
import {RES_scanPatientImage, scanImage} from "../../../../actions/gateway";

export class ConnectedScanPatientImage extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            scanEnabled: true,
            notes: '',
            selectedSource: null,
            firstSource: 0,
            sourceRows: 5,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {
                case RES_scanPatientImage.SCAN.block:
                    this.setState({scanEnabled: false});
                    break;
                case RES_scanPatientImage.SCAN.action:
                    this.setState({scanEnabled: true});
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        if (event.owner === 'selectedSource') {
            this.setState(state);
        }
    }

    onDoubleClick = (e) => {

        this.setState({selectedSource: e.data}, () => {
            this.onScanImage();
        });
    }

    onScanImage = () => {

        if (!this.state.scanEnabled) return;

        const params = {
            mcId: ac.getMcId(),
            groupId: ac.groupId,
            scanner: this.state.selectedSource.name,
            patientId: this.props.patientId,
            visibleName: 'Test Scanned image'
        };
        this.props.scanImage(params);
        this.onHide();
    }

    onHide = () => {
        this.props.onHideDialog(this.props.dialogId);
    }

    render() {

        const footer = (
            <div>
                <Button label="Scan"
                        icon={ICON_CAMERA}
                        disabled={!(this.state.scanEnabled && this.state.selectedSource)}
                        onClick={this.onScanImage}/>
                <Button label="Close"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        let scannerList = [];

        if (this.props.gatewayConfiguration.scannerList) {
            const keys = Object.getOwnPropertyNames(this.props.gatewayConfiguration.scannerList.scanners);

            keys.forEach(key => {
                const {scanners} = this.props.gatewayConfiguration.scannerList;
                const selected = scanners[key];
                const obj = {name: key, selected}
                scannerList.push(obj);
            })
        }

        return (
            <Dialog header={this.props.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>
                <div className="p-grid p-fluid">

                    <DataTable value={scannerList}
                               className='p-datatable-gridlines'
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.sourceRows}
                               rowsPerPageOptions={[5]}
                               onPage={(e) => this.onPageFlex(e, 'firstSource', 'sourceRows')}
                               first={this.state.firstSource}
                               selection={this.state.selectedSource}
                               onSelectionChange={(e) => this.onChange({owner: 'selectedSource', value: (e.value)})}
                               onRowDoubleClick={this.onDoubleClick}
                               style={{fontSize: 'small'}}
                    >

                        <Column field="name" header='Scanner' style={{width: '50%'}}/>

                    </DataTable>
                </div>
            </Dialog>
        )
    }
};

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        scanImage: (params) => dispatch(scanImage(RES_scanPatientImage.SCAN, params)),
    }
};

const ScanPatientImage = connect(mapStateToProps, MapDispatchToProps)(ConnectedScanPatientImage);

export default ScanPatientImage;
