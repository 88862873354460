import axios from 'axios';

import {HTTP_RESPONSE_Unauthorized} from "../Constants";

//  valid groupIds
const GROUP_0 = "Rm78frVJFedA67yA";
const GROUP_1 = "fYHe4FPLm44mXEhs";
const GROUP_2 = "65pKSSnrbKqqdhUQ";
const GROUP_3 = "Z3C8dWJYSWGK79qe";
const GROUP_4 = "Z6raXfPB72RFKyQb";
const GROUP_5 = "avA6VJqXTjxcC35U";
const GROUP_6 = "Xb36SpsK6jQu7mqy";
const GROUP_7 = "qQCaY6Xu9FQFFRUh";
const GROUP_8 = "z3rG2baJGMRJAQyw";
export const GROUP_9 = "YjU4gAhCyhnZK8ap";

export const ADMIN = 'inOAcOAWaIEZuQb';
export const LABORATORY = 'in9AcBAWaWEZuQ2';

export const VALID_GROUPS = [ADMIN, GROUP_0, GROUP_1, GROUP_2, GROUP_3, GROUP_4, GROUP_5, GROUP_6, GROUP_7, GROUP_8, GROUP_9];

const PORT_REPORT_ROOT_REST = 'ChiralReportRest';
const PORT_UPLOAD_ROOT_REST = 'ChiralUploadRest';
const PORT_WEBROOT_REST = 'ChiralRest';
const PORT_WEBROOT_WS = 'ChiralWS';

const DEVELOPMENT = process.env.REACT_APP_STAGE === 'dev';

export const RTCConfiguration = {
    iceServers: [
        {
            urls: 'stun:stun.l.google.com:19302',
        }
    ]
};

class AxiosFetchClient {

    constructor() {

        this.token = null;
        this.groupId = null;
        this.mcId = null;

        this.pc = null;

        this.clientView = false;

        this.groupId = window.location.pathname.split('/')[1];
        this.mcId = window.location.pathname.split('/')[2];

        this.chiralServer = DEVELOPMENT ? 'chiralhome.hopto.org' : 'chiralcloud.com';
        this.onlyofficeServer = DEVELOPMENT ? `chiralhome.hopto.org` : `onlyoffice.chiralcloud.com`;

        this.UUID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        this.BASEURL = `https://${this.chiralServer}/`;
        this.BASERESTURL = `${this.chiralServer}/${PORT_WEBROOT_REST}/${this.groupId}`;
        this.BASEREPORTRESTURL = `${this.chiralServer}/${PORT_REPORT_ROOT_REST}/${this.groupId}`;
        this.BASEUPLOADRESTURL = `${this.chiralServer}/${PORT_UPLOAD_ROOT_REST}/${this.groupId}`;
        this.BASEWSURL = `wss://${this.chiralServer}/${PORT_WEBROOT_WS}/${this.groupId}/${this.mcId}/${this.UUID}`;

        this.DOCUMENT_SERVER_API = `http://${this.onlyofficeServer}/web-apps/apps/api/documents/api.js`;
        this.IMAGE_SERVER_API = `https://${this.BASERESTURL}/Patients`;

        this.logoutURL = `https://${this.chiralServer}/${this.groupId}/${this.mcId}`;
        this.clientLogoutURL = `https://${this.chiralServer}/${this.groupId}/${this.mcId}/#/Client`;

        this.defaultOptions = {

            baseURL: `https://${this.BASERESTURL}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        this.defaultReportServiceOptions = {

            baseURL: `https://${this.BASEREPORTRESTURL}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        this.defaultUploadServiceOptions = {

            baseURL: `https://${this.BASEUPLOADRESTURL}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        this.axioInstance = axios.create(this.defaultOptions);
        this.axioReportInstance = axios.create(this.defaultReportServiceOptions);
        this.axioUploadInstance = axios.create(this.defaultUploadServiceOptions);
    }

    fetchClient = (callback) => {

        // Set the AUTH token for any request
        this.axioInstance.interceptors.request.use(config => {
            config.headers.Authorization = `Bearer ${this.token}`;
            config.headers.UUID = this.UUID;
            return config;
        });

        this.axioInstance.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response !== undefined && error.response.status === HTTP_RESPONSE_Unauthorized) {

                if (this.clientView) {
                    window.location = this.clientLogoutURL;
                } else {
                    window.location = this.logoutURL;
                }
            } else {
                return error;
            }
        });
        return this.axioInstance;
    };

    fetchReportClient = (callback) => {

        // Set the AUTH token for any request
        this.axioReportInstance.interceptors.request.use(config => {
            config.headers.Authorization = `Bearer ${this.token}`;
            config.headers.UUID = this.UUID;
            return config;
        });

        this.axioReportInstance.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response !== undefined && error.response.status === HTTP_RESPONSE_Unauthorized) {

                if (this.clientView) {
                    window.location = this.clientLogoutURL;
                } else {
                    window.location = this.logoutURL;
                }
            } else {
                return error;
            }
        });
        return this.axioReportInstance;
    };

    fetchUploadClient = (callback) => {

        // Set the AUTH token for any request
        this.axioUploadInstance.interceptors.request.use(config => {
            config.headers.Authorization = `Bearer ${this.token}`;
            config.headers.UUID = this.UUID;
            return config;
        });

        this.axioUploadInstance.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response !== undefined && error.response.status === HTTP_RESPONSE_Unauthorized) {

                if (this.clientView) {
                    window.location = this.clientLogoutURL;
                } else {
                    window.location = this.logoutURL;
                }
            } else {
                return error;
            }
        });
        return this.axioUploadInstance;
    };

    getBASEURL = () => {
        return this.BASEURL;
    }

    getBASERESTURL = () => {
        return this.BASERESTURL;
    }

    getBASEREPORTRESTURL = () => {
        return this.BASEREPORTRESTURL;
    }

    getBASEUPLOADRESTURL = () => {
        return this.BASEUPLOADRESTURL;
    }

    getBASEWSURL = () => {
        return this.BASEWSURL;
    }

    getIMAGE_SERVER_API = () => {
        return this.IMAGE_SERVER_API;
    }

    getDOCUMENT_SERVER_API = () => {
        return this.DOCUMENT_SERVER_API;
    }

    getChiralServer = () => {
        return this.chiralServer;
    }

    setGroupId = (location) => {
        const urlParts = location.pathname.split('/');
        this.groupId = urlParts[1];
    };

    getGroupId = () => {
        return this.groupId;
    };

    setMcId = (mcId) => {
        this.mcId = parseInt(mcId, 10);
    };

    getMcId = () => {
        return this.mcId;
    };

    getUUID = () => {
        return this.UUID;
    };

    setToken = (token) => {
        this.token = token;
    };

    getAuthToken() {
        return `Bearer ${this.token}`;
    };

    getAuthTokenRaw() {
        return this.token;
    };

    getRTCPeerConnection() {
        return this.pc;
    };

    setRTCPeerConnection(pc) {
        this.pc = pc;
    };

    setClientView() {
        this.clientView = true;
    }

    getClientLogoutURL = () => {
        return this.clientLogoutURL;
    }

    getLogoutURL = () => {
        return this.logoutURL;
    }
}

export default AxiosFetchClient;
