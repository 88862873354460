import React from 'react';
import _ from 'lodash';
import {AdultRetainerBar1, AdultRetainerBar2} from "../Model/Points";
import {getTranslate} from "./ChartingUtils";
import {TOOTH_GAP, TOOTH_SIZE} from "../Model/Constants";
import {getRandomKey} from "../Utils/general";
import {ToothBaseComponent} from "./ToothBaseComponent";

export class FixedBondedOrthoRetainer extends ToothBaseComponent {

    constructor(props) {
        super(props);

        this.state = {};
    };

    render() {

        const width = ((this.props.data.end - this.props.data.start + 1) * (TOOTH_SIZE + TOOTH_GAP)) - (3 * TOOTH_GAP);
        const rgb = `rgb(${this.props.data.material.red},${this.props.data.material.green},${this.props.data.material.blue})`;

        const barPoints1 = _.cloneDeep(AdultRetainerBar1);
        const barPoints2 = _.cloneDeep(AdultRetainerBar2);

        const data = {
            jaw: this.props.data.jaw,
            unerupted: false,
            impacted: false,
            partiallyErupted: false,
            overErupted: false,
            pos: {offset: this.props.data.start}
        };

        barPoints1[4] = width;
        barPoints1[6] = width;
        barPoints2[4] = width;
        barPoints2[6] = width;

        return (
            <g key={getRandomKey()} onContextMenu={(event) => {

                event.preventDefault();

                if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
                    this.props.data.currentCommand.setTarget(this.props.data);
                }
                this.props.mouth.setCurrentItem(this.props.data);
                this.props.cm(event);
            }}
               onMouseEnter={() => this.handleEnter()}
               onMouseLeave={() => this.handleLeave()}

               style={{cursor: this.state.cursor}}

               onClick={() => {
                   if (this.props.data.currentCommand != null && this.props.data.currentCommand.canExecute(this))
                       this.props.onClick(this.props.data);
               }}
            >
                <polygon
                    points={barPoints1}
                    style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={getTranslate(data, TOOTH_GAP, 65, TOOTH_GAP, 73)}
                />
                <polygon
                    points={barPoints2}
                    style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={getTranslate(data, TOOTH_GAP, 144, TOOTH_GAP, 1)}
                />
            </g>
        )
    }
}
