import React from 'react';
import {connect} from 'react-redux';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';

import '../../../../images.css';
import {setState, SM_WORK_RECEIVED_FOR_APPROVAL, SM_WORK_RECEIVED_REPORT} from "../../../../actions/stateManagement";
import {
    HM_REP_LAB_WORK_APPROVAL,
    HM_VIEW_WORK_REQUIRED,
    HM_WORK_REQUIRED_APPROVED, HM_WORK_REQUIRED_CANCELLED,
    JSON_DATE_FORMAT,
    UP_WORK_REQUIRED_STATUS,
    WORK_STATUS_APPROVED,
    WORK_STATUS_RECEIVED_FROM_LAB
} from "../../../../Constants";
import {getReport, getReportPDF, RES_REPORT_WORK_FOR_APPROVAL} from "../../../../actions/reports";
import {Column} from "primereact/components/column/Column";
import {byDateTemplate, forAppointmentTemplate, statusTemplate} from "../../../Utils";
import {dateTemplateVeryShort} from "../../../PatientDynamicItems/Utils";
import _ from "lodash";
import moment from "moment";
import {markWorkStatus, RES_WORK_REQUIRED} from "../../../../actions/findLaboratories";
import * as Actions from "../../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_PRINT} from "../../../../actions";
import {WorkReceivedBase} from "./WorkReceivedBase";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {TB_PATIENT_DETAILS} from "../../PatientDetails/Constants";

class ConnectedWorkReceivedForApproval extends WorkReceivedBase {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                selectedLabEntry: null,
                firstLabEntry: 0,
                labEntryRows: 5,
            };
        }

        this.toolbarCallbacks = {
            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [HM_VIEW_WORK_REQUIRED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_VIEW_WORK_REQUIRED.id}});
                });
            },
            [HM_WORK_REQUIRED_APPROVED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_APPROVED.id}});
                });
            },
            [HM_WORK_REQUIRED_CANCELLED.id]: () => {
                this.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_CANCELLED.id}});
            },
        }
    }

    componentDidMount() {

        if (!this.props.labWorkApprovalLoaded) {
            this.props.getReport(this.state.currentDate);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.currentDate !== this.props.currentDate) {
            this.props.getReport(this.props.currentDate);
        }

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_REPORT_WORK_FOR_APPROVAL.GET.receive:
                    this.setState({
                        labWorkApproval: this.props.labWorkApproval,
                        labWorkApprovalLoaded: true
                    }, () => {
                        this.props.setState(SM_WORK_RECEIVED_FOR_APPROVAL.id, this.state, SM_WORK_RECEIVED_REPORT.id);
                    });
                    break;
                case RES_REPORT_WORK_FOR_APPROVAL.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_WORK_RECEIVED_FOR_APPROVAL.id, this.state, SM_WORK_RECEIVED_REPORT.id);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_WORK_REQUIRED_STATUS: {
                            // is this for us.
                            if ((this.props.wsmessage.content.status === WORK_STATUS_RECEIVED_FROM_LAB.name && this.props.wsmessage.content.requiresApproval) || this.props.wsmessage.content.status === WORK_STATUS_APPROVED.name) {
                                const labWorkApproval = [...this.state.labWorkApproval];

                                // if we already have the item then it has been approved so update, otherwise insert
                                const index = _.findIndex(labWorkApproval, item => item.id === this.props.wsmessage.content.id);
                                if (index>=0) {
                                    labWorkApproval[index] = this.props.wsmessage.content;
                                } else {
                                    labWorkApproval.unshift(this.props.wsmessage.content);
                                }
                                this.setState({
                                    labWorkApproval,
                                    labWorkApprovalLoaded: true
                                }, () => {
                                    this.props.setState(SM_WORK_RECEIVED_FOR_APPROVAL.id, this.state);
                                });
                                break;
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.state.labWorkApprovalLoaded) {
            return null;
        }

        const sortedLabHistory = _.orderBy(this.state.labWorkApproval, 'returnDate', 'asc');

        this.buildMenu();

        return (

            <Panel header={HM_REP_LAB_WORK_APPROVAL.header} style={{paddingTop: '5px'}}>

                {this.showDialogs()}

                <ContextMenu model={this.items}
                             style={{width: '250px'}}
                             ref={el => this.cm = el}/>

                <DataTable value={sortedLabHistory}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.labEntryRows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={(e) => {
                               this.onPageFlex(e, 'firstLabEntry', 'labEntryRows');
                           }}
                           first={this.state.firstLabEntry}
                           selection={this.state.selectedLabEntry}
                           onSelectionChange={(e) => {
                               this.setValue('selectedLabEntry', e.value)
                           }}
                           contextMenuSelection={this.state.selectedLabEntry}
                           onContextMenuSelectionChange={(e) => this.setValue('selectedLabEntry', e.value)}
                           onContextMenu={e => this.cm.show(e.originalEvent)}
                >
                    <Column header="" body={(rowData) => statusTemplate(rowData)}
                            style={{width: '2%'}}/>
                    <Column header="Requested"
                            body={(rowData) => dateTemplateVeryShort(rowData.requestedOn)}
                            style={{width: '8%', textAlign: 'right'}}/>
                    <Column header="Laboratory" body={(rowData) => rowData.laboratoryName}
                            style={{width: '20%'}}/>
                    <Column header="Patient" body={(rowData) => rowData.patientName}
                            style={{width: '21%'}}/>
                    <Column header="Provider" body={(rowData) => rowData.providerName}
                            style={{width: '7%'}}/>
                    <Column header="By Date" body={byDateTemplate}
                            style={{width: '7%', textAlign: 'right'}}/>
                    <Column header="For Appointment" body={forAppointmentTemplate}
                            style={{width: '8%', textAlign: 'right'}}/>
                    <Column header="Approved"
                            body={(rowData) => dateTemplateVeryShort(rowData.approvedOn)}
                            style={{width: '8%', textAlign: 'right'}}/>
                    <Column header="By" body={(rowData) => rowData.approvedBy} style={{width: '7%'}}/>
                </DataTable>
            </Panel>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        wsmessage: state.websockets.message,

        loginIdentity: state.login.user,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        labWorkApprovalLoaded: state.reports.labWorkApprovalLoaded,
        labWorkApproval: state.reports.labWorkApproval,

        currentState: state.stateManagement[SM_WORK_RECEIVED_FOR_APPROVAL.id],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getReport: (date) => dispatch(getReport(RES_REPORT_WORK_FOR_APPROVAL.GET, {
            date: moment(date).format(JSON_DATE_FORMAT)
        })),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_WORK_FOR_APPROVAL.PDF, params)),

        markWorkApproved: (labEntry) => dispatch(markWorkStatus(RES_WORK_REQUIRED.APPROVED, labEntry)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const WorkReceivedForApproval = connect(MapStateToProps, MapDispatchToProps)(ConnectedWorkReceivedForApproval);

export default WorkReceivedForApproval;

