import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {ICON_CANCEL} from "../../../../icons";
import {TB_RECALCULATE_PATIENT_ACCOUNTS} from "../../PatientDetails/Constants";
import {recalculateAccount, RES_PATIENT_ACCOUNT} from "../../../../actions/personal";
import {connect} from "react-redux";
import * as Actions from "../../../../actions";

class ConnectedRecalculatePatientAccounts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 0,
        };
    }

    componentDidMount() {

        const details = {
            patientId: this.props.results[0].id,
            accountGroupId: this.props.group.id,
        };
        this.props.recalculateAccount(details);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_ACCOUNT_RECALC:

                    this.setState({currentIndex: this.state.currentIndex + 1}, () => {

                        if (this.state.currentIndex <= this.props.results.length-1) {
                            const details = {
                                patientId: this.props.results[this.state.currentIndex].id,
                                accountGroupId: this.props.group.id,
                            };
                            this.props.recalculateAccount(details);
                        } else {
                            this.onHide();
                        }
                    })
                    break;
                default:
                    break;
            }
        }
    }

    onHide() {
        this.props.onHideDialog(TB_RECALCULATE_PATIENT_ACCOUNTS.id);
    }

    render() {

        const footer = (
            <div>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.props.onCancel}/>
            </div>
        );

        const fullName = this.state.currentIndex <= this.props.results.length-1 ? this.props.results[this.state.currentIndex].fullName : '';

        return (
            <Dialog header={TB_RECALCULATE_PATIENT_ACCOUNTS.text}
                    style={{width: '25%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => this.props.onHideDialog(TB_RECALCULATE_PATIENT_ACCOUNTS.id)}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">

                        <div className="p-col-5">
                            <label>Recalculating</label>
                        </div>
                        <div className="p-col-7">
                            <label>{fullName}</label>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const MapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,
    };
};

const MapDispatchToProps = dispatch => {

    return {
        recalculateAccount: (details) => dispatch(recalculateAccount(RES_PATIENT_ACCOUNT.RECALC, details)),
    }
}

const RecalculatePatientAccounts = connect(MapStateToProps, MapDispatchToProps)(ConnectedRecalculatePatientAccounts);

export default RecalculatePatientAccounts;
