import React, {Component} from 'react';
import _ from "lodash";

import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {getChartedItemDetails} from "../../../Charting/Utils/general";
import {
    baseTreatmentIconTemplate,
    baseTreatmentTextTemplate,
    dateTemplateShort
} from "../../../PatientDynamicItems/Utils";

import {ICON_DELETE, ICON_EDIT} from "../../../../icons";

export default class BaseChartEntries extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataTableSelectValue: null,
        };

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    componentDidMount() {
    }

    onSelectionChanged(e) {

        this.setState({dataTableSelectValue: e.value});
    }

    render() {

        const deleteDisabled = this.state.dataTableSelectValue === null || (this.state.dataTableSelectValue && !this.state.dataTableSelectValue.baseEntry);

        const items = [
            {
                label: 'Edit Item', icon: ICON_EDIT, command: (e) => {
                }
            },
            {
                label: 'Delete Item', icon: ICON_DELETE, command: (e) => {

                    const item = {id: this.state.dataTableSelectValue.id, jaw: this.state.dataTableSelectValue.jaw, pos: {perm: this.state.dataTableSelectValue.position}}
                    this.props.removeChartingEntry(item);
                },
                disabled: deleteDisabled,
            },
        ];

      

        let baseEntries = this.props.entries.length === 0 ? [] : [...this.props.entries];
        baseEntries = _.filter(baseEntries, item => item.baseEntry || item.complete);
        baseEntries.forEach((entry) => entry['details'] = getChartedItemDetails(entry));

        const sortedBaseEntries = _.sortBy(baseEntries, 'id', 'asc');

        return (

            <div>
                <ContextMenu model={items} ref={
                    el => this.cm = el
                }/>

                <DataTable value={sortedBaseEntries}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           onContextMenu={e=>this.cm.show(e.originalEvent)}
                           paginator={true}
                           rows={5}
                           rowsPerPageOptions={[5,10,20]}
                           selection={this.state.dataTableSelectValue}
                           onSelectionChange={this.onSelectionChanged}
                           onContextMenuSelectionChange={this.onSelectionChanged}
                >
                    <Column body={row => baseTreatmentIconTemplate(this.props.treatmentPlan, row)} sortable={false} style={{width: '5%'}}/>
                    <Column body={row => dateTemplateShort(row.dateAdded)} header="Date" sortable={false} style={{width: '10%'}}/>
                    <Column body={row => baseTreatmentTextTemplate(this.props.treatmentPlan, row)} header="Entry" sortable={false} style={{width: '35%'}}/>
                    <Column field="details" header="Details" sortable={false} style={{width: '15%'}}/>
                    <Column field="addedBy.username" header="By" sortable={true} style={{width: '10%'}}/>
                    <Column field="comment" header="Comment" sortable={true} style={{width: '25%'}}/>
                </DataTable>
            </div>
        )
    }
}