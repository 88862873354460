import {ac} from '../index'
import {RES_PREFERENCES} from "./preferences";
import {savePreferenceObjects} from "./Utils/preferences";

export const REQUEST_LOGIN_OBJECT_STORE = 'REQUEST_LOGIN_OBJECT_STORE';
export const RECEIVE_LOGIN_OBJECT_STORE = 'RECEIVE_LOGIN_OBJECT_STORE';

export const REQUEST_OBJECT_STORE = 'REQUEST_OBJECT_STORE';
export const RECEIVE_OBJECT_STORE = 'RECEIVE_OBJECT_STORE';

export const RES_OBJECT_STORE = {
    GET_LOGIN: {request: REQUEST_LOGIN_OBJECT_STORE, receive: RECEIVE_LOGIN_OBJECT_STORE, url: '/Utils/objectStore'},
    GET: {request: REQUEST_OBJECT_STORE, receive: RECEIVE_OBJECT_STORE, url: '/Utils/objectStore'},
    SET: {request: 'REQUEST_SET_OBJECT_STORE', receive: 'REQUEST_SET_OBJECT_STORE', url: '/Utils/objectStore'},
};

export const getObjectStore = ({url, request, receive}, objectList) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {objectList: JSON.stringify(objectList)}})
            .then(res => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: true});
    }
};

export const setObjectStore = (source) => {

        return (dispatch, getState) => {

            const sm = getState().stateManagement;

            // use this to pre-process any of the object data
            switch (source.action) {

                case RES_PREFERENCES.SAVE.action:

                    savePreferenceObjects(dispatch, sm);
                    break;

                default:
                    break;
            }
        }
    }
;
