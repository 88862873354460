import React from "react";
import {SELECTED_ITEM_COLOUR} from "../Constants";
import {COLOR_ChiralPalette} from "../../../Client/Constants";

export class PaletteDiaryEventComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            diaryEvent: this.props.diaryEvent,
            selectedItem: this.props.selectedItem,
        }
        this.myRef = React.createRef();
        this.onClickHandler = this.onClickHandler.bind(this);

        this.paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};
    }

    onClickHandler = () => {
        this.props.onItemSelected(this.state.diaryEvent);
    }

    componentDidMount() {

        try {
            this.myRef.current.addEventListener('click', this.onClickHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedItem !== prevProps.selectedItem) {
            this.setState({selectedItem: this.props.selectedItem})
        }
    }

    render() {

        const {diaryEvent} = this.state;

        let index = 0;

        let backgroundColor = diaryEvent.id === this.state.selectedItem ? SELECTED_ITEM_COLOUR : null;

        return (
            <div key={index++}
                 id={diaryEvent.id}
                 style={{fontWeight: 'bold', ...this.paddingStyle, backgroundColor}}
                 ref={this.myRef}
                 className='diary-palette-component'
            >
                <span className='fas fa-calendar-plus'
                      style={{fontSize: '20px', color: COLOR_ChiralPalette, marginRight: '5px', marginTop: '5px'}}/>
                {diaryEvent.title}</div>
        )
    }

    componentWillUnmount() {

        try {
            this.myRef.current.removeEventListener('click', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
