import React, {Component} from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_PDS} from "../../../../actions/stateManagement";
import {getObjectStoreIds, getPreferenceIds} from "../Utils";
import {getResource, RES_PREFERENCES_PDS} from "../../../../actions/preferences";
import {checkBox, dropDown, inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import {getDropDowns, RES_getDropDowns} from "../../../../actions/dropDowns";
import _ from "lodash";
import {FLATTEN_PATIENT_DOCUMENTS, KEAP_INTEGRATION, SHOW_MEDICAL_HISTORY} from "../Constants";
import {getObjectStore, RES_OBJECT_STORE} from "../../../../actions/objectStore";
import {getBoolean} from "../../fixedItemUtils";

const requiredObject = [
    KEAP_INTEGRATION,
    FLATTEN_PATIENT_DOCUMENTS,
    SHOW_MEDICAL_HISTORY
];

class ConnectedPracticeDetailsSection extends Component {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_PDS.id,
                practiceDetails: null,

                objects: {},
            }
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getDropDowns();
            this.props.getPracticeDetails();
            this.props.getObjectStore(requiredObject);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_OBJECT_STORE.GET.receive:

                    const objects = this.props.objects;

                    this.setState({
                        objects: {
                            KEAP_INTEGRATION: getBoolean(objects, KEAP_INTEGRATION, false),
                            FLATTEN_PATIENT_DOCUMENTS: getBoolean(objects, FLATTEN_PATIENT_DOCUMENTS, false),
                            SHOW_MEDICAL_HISTORY: getBoolean(objects, SHOW_MEDICAL_HISTORY, false),
                        }
                    })
                    break;

                case RES_PREFERENCES_PDS.GET.receive:

                    this.setState({practiceDetails: this.props.practiceDetails}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES_PDS.SAVE.action,
                    saveState: true,
                    saveObjects: true
                }
            })
        });
    }

    render() {

        if (!this.props.practiceDetailsLoaded || !this.props.dropDownsLoaded) {
            return null;
        }

        const props = {
            onChange: this.onChange,
            target: 'practiceDetails',
            practiceDetails: this.state.practiceDetails,
            countries: this.props.countries,
        };

        const objectProps = {
            onChange: this.onChange,
            target: 'objects',
            objects: this.state.objects,
        }

        return (
            <div className="p-grid p-fluid">
                <Panel header='Practice Details' className="p-col-4">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <label htmlFor="practiceName">Name</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'practiceName', 'Practice Name', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="addressLine1">Address</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'addressLine1', '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="addressLine2"/>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'addressLine2', '', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="addressLine3"/>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'addressLine3', '', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="county">County</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'county', '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="mainCountry">Country</label>
                        </div>
                        <div className="p-col-9">
                            {dropDown(props, 'countries', 'country', 'countryName', true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="postcode">Post Code</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'postcode', '', false, true)}
                        </div>

                        <div className="p-col-12"/>

                        <div className="p-col-3">
                            <label htmlFor="telephone">Tel.</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'telephone', '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="fax">Fax.</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'fax', '', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="email">Email</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'email', '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="web">Web Site</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'web', '', false, false)}
                        </div>
                    </div>
                </Panel>
                <Panel header='Portal Details' className="p-col-4"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <label htmlFor="patientDetails">Patient Details</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'patientDetails', 'Show Patient Details', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="nhsDetails">NHS Details</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'nhsDetails', 'Show Patient NHS Details', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="imageUpload">Image Upload</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'imageUpload', 'Show Patient Images', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="medicalHistory">Medical History</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'medicalHistory', 'Show Medical History', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="consentForms">Consent Form</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'consentForms', 'Show Consent Forms', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="questionnaire">Questionnaire</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'questionnaire', 'Show Questionnaire', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="appointmentHistory">Appointment History</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'appointmentHistory', 'Show Appointment History', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="documentHistory">Document History</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'documentHistory', 'Show Document History', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="prescriptionHistory">Prescription History</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'prescriptionHistory', 'Show Prescription History', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="treatmentPlans">Treatment Plans</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'treatmentPlans', 'Show Treatment Plans', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="paymentPlans">Payment Plans</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'paymentPlans', 'Show Payment Plans', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="onlineBooking">Online Booking</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'onlineBooking', 'Show Online Booking', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="videoConferencing">Video Conferencing</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'videoConferencing', 'Show Video Conferencing', false, false)}
                        </div>
                    </div>
                </Panel>
                <div className="p-grid p-fluid p-col-4">
                    <Panel header='Tablet Details' className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">
                                <label htmlFor="tabConciege">Patient Checkin</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabConciege', 'Show Checkin', false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabPatientDetails">Patient Details</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabPatientDetails', 'Show Patient Details', false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabMedicalHistory">Medical History</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabMedicalHistory', 'Show Medical History', false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabConsentForms">Consent Form</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabConsentForms', 'Show Consent Forms', false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabQuestionnaire">Questionnaire</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabQuestionnaire', 'Show Questionnaire', false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabTreatmentPlans">Treatment Plans</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabTreatmentPlans', 'Show Treatment Plans', false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabPaymentPlans">Payment Plans</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabPaymentPlans', 'Show Payment Plans', false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header='Keap Integration' className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3"/>
                            <div className="p-col-9">
                                {checkBox(objectProps, KEAP_INTEGRATION, 'Enabled', false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="subscriptionId">Subscription Id</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'keapSubscriptionId', 'Subscription Id', !this.state.objects.KEAP_INTEGRATION, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header='Patient Document Layout' className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">Layout</div>
                            <div className="p-col-9">
                                {checkBox(objectProps, FLATTEN_PATIENT_DOCUMENTS, 'Flatten', false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header='Show Medical History in Appointment' className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">History</div>
                            <div className="p-col-9">
                                {checkBox(objectProps, SHOW_MEDICAL_HISTORY, 'Show', false, false)}
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        dropDownsLoaded,
        practiceDetailsLoaded,
        practiceDetails,
    } = getPreferenceIds(state, ownProps);

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        dropDownsLoaded,
        countries: state.dropDowns.countries,

        practiceDetailsLoaded,
        practiceDetails,

        currentState: state.stateManagement[SM_PREFERENCES_PDS.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getPracticeDetails: () => dispatch(getResource(RES_PREFERENCES_PDS.GET, {})),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const PracticeDetailsSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedPracticeDetailsSection);

export default PracticeDetailsSection;
