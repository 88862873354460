import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {categoryDropDown, checkBox, inputText, spinner} from './OnChangeUtils';

export const PatAssMedHistory = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header='Medical History - Social History'>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>Complains Of</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'patientsComplaint', 'patientsComplaint', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>HPC</label>
                </div>
                <div className="p-lg-8 p-md-8">
                    {inputText(props, 'hpc', '', readOnly, false)}
                </div>

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>Medical History Update</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {inputText(props, 'mhu', '', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label/>
                </div>
                <div className="p-lg-1 p-md-1">
                    {checkBox(props, 'noChange', 'No Change', readOnly, false)}
                </div>
                <div className="p-lg-7 p-md-7">
                    {checkBox(props, 'updated', 'Updated', readOnly, false)}
                </div>

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>Smoker</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'smoker', 'smoker', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>Per Day</label>
                </div>
                <div className="p-lg-1 p-md-1">
                    {spinner(props, 'cigsPerDay', 0, 200, readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {checkBox(props, 'exSmoker', 'Ex Smoker', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>How Long Ago (Years)</label>
                </div>
                <div className="p-lg-1 p-md-1">
                    {spinner(props, 'howLongAgo', 0, 99, readOnly, false)}
                </div>
                <div className="p-lg-2 p-md-2">
                    <label>&nbsp;</label>
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>&nbsp;</label>
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>SCAG</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'SCAG', 'scag', readOnly, false)}
                </div>
                <div className="p-lg-6 p-md-6">
                    <label>&nbsp;</label>
                </div>

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>Alcohol</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'Alcohol', 'alcohol', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>Units per Week</label>
                </div>
                <div className="p-lg-1 p-md-1">
                    {spinner(props, 'unitsPerWeek', 0, 99, readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>ARAG</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'ARAG', 'arag', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>MCA</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'MCA', 'mca', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label/>
                </div>

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>Muscles of Mastication</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'musclesOfMastication', 'musclesOfMastication', readOnly, false)}
                </div>
                <div className="p-lg-9 p-md-9">
                    <label/>
                </div>

                {/**/}
                <div className="p-lg-1 p-md-1">
                    <label>Speech</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'speech', 'speech', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>Stressometer</label>
                </div>
                <div className="p-lg-1 p-md-1">
                    {spinner(props, 'stressometer', 0, 99, readOnly, false)}
                </div>
                <div className="p-lg-7 p-md-7">
                    <label/>
                </div>
            </div>
        </Panel>
    );
};
