import React from 'react';
import _ from 'lodash';

import {Panel} from 'primereact/components/panel/Panel';

import {ToothDFProgTickbox, ToothTickbox} from './ToothTickbox';
import {CPITN} from './CPITN';

import {categoryDropDown, checkBox} from './OnChangeUtils';
import {SelectButton} from "primereact/components/selectbutton/SelectButton";

export const CHART_TYPE_PRIMARY = 'Primary';

export const CHART_TYPE_MIXED = 'Mixed';

export const CHART_TYPE_PERMANENT = 'Permanent';

export const CHART_TYPE_DFPROG = 'DoubtfulPrognosis';

export const CHART_TYPES = [CHART_TYPE_PRIMARY, CHART_TYPE_MIXED, CHART_TYPE_PERMANENT];

export const OrthoIntraOral2 = (props) => {

    const readOnly = props.readOnly;
    const chartType = props.selectedAssessment ? props.selectedAssessment.chartType : CHART_TYPE_PRIMARY;

    const dpProps = _.cloneDeep(props);

    if (dpProps.selectedAssessment) {
        dpProps.selectedAssessment.chartType = CHART_TYPE_DFPROG
    }

    return (
        <Panel header='Intra-Oral'>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>Oral Hygiene</label>
                </div>
                <div className="p-lg-9 p-md-9">
                    {categoryDropDown(props, 'OrthoOralHygiene', 'oralHygieneACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-2 p-md-2">
                    <label>Teeth Missing</label>
                </div>
                <div className="p-lg-4 p-md-4">
                    <SelectButton options={CHART_TYPES}
                                  value={chartType}
                                  onChange={(event) => props.onChange({owner: "selectedAssessment.chartType", value: event.value})}
                                  multiple={false}
                                  disabled={readOnly}
                    />
                </div>
                <div className="card p-lg-6 p-md-6">
                    <ToothTickbox teethMissing={`${props.target}.teethMissing`}
                                  teethMixedMissing={`${props.target}.teethMixedMissing`}
                                  readOnly={readOnly}
                                  {...props}
                    />
                </div>

                <div className="p-lg-3 p-md-3">
                    <label>Doubtful Prognosis</label>
                </div>
                <div className="p-lg-9 p-md-9">
                    <ToothDFProgTickbox doubtfulPrognosis={`${props.target}.doubtfulPrognosis`}
                                  teethMixedMissing={null}
                                  readOnly={readOnly}
                        {...props}
                    />
                </div>

                <div className="p-lg-3 p-md-3">
                    <label>CPITN</label>
                </div>
                <div className="p-lg-5 p-md-5">
                    {checkBox(props, 'cpitn', '', readOnly)}
                </div>
                <div className="p-lg-4 p-md-4">
                    <CPITN readOnly={readOnly}
                           {...props}
                    />
                </div>
            </div>
        </Panel>
    );
};
