import React from 'react';
import _ from "lodash";
import {ac} from '../../../../index'

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {ICON_CAMERA, ICON_CANCEL, ICON_XRAY} from "../../../../icons";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {BaseComponent} from "../../../BaseComponent";
import {connect} from "react-redux";
import {RES_scanPatientImage, scanImage} from "../../../../actions/gateway";
import * as Actions from "../../../../actions";
import {getResource, RES_PATIENT_XRAY_RECORDS} from "../../../../actions/personal";

export class ConnectedConnectXRaySoftware extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            scanEnabled: true,
            notes: '',
            selectedSource: null,
            firstSource: 0,
            sourceRows: 5,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {
                case RES_scanPatientImage.SCAN.block:
                    this.setState({scanEnabled: false});
                    break;
                case RES_scanPatientImage.SCAN.action:
                    this.setState({scanEnabled: true});
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        if (event.owner === 'selectedSource') {
            this.setState(state);
        }
    }

    onDoubleClick = (e) => {

        this.setState({selectedSource: e.data}, () => {
            this.onScanImage();
        });
    }

    onScanImage = () => {

        if (!this.state.scanEnabled) return;

        this.onHide();
    }

    onHide = () => {
        this.props.onHideDialog(this.props.dialogId);
    }

    render() {

        const footer = (
            <div>
                <Button label="Connect"
                        icon={ICON_XRAY}
                        disabled={!(this.state.scanEnabled && this.state.selectedSource)}
                        onClick={this.onScanImage}/>
                <Button label="Close"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        return (
            <Dialog header={this.props.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>
                <div className="p-grid p-fluid">

                    <DataTable value={this.props.gatewayConfiguration.provider}
                               className='p-datatable-gridlines'
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.sourceRows}
                               rowsPerPageOptions={[5]}
                               onPage={(e) => this.onPageFlex(e, 'firstSource', 'sourceRows')}
                               first={this.state.firstSource}
                               selection={this.state.selectedSource}
                               onSelectionChange={(e) => this.onChange({owner: 'selectedSource', value: (e.value)})}
                               onRowDoubleClick={this.onDoubleClick}
                               style={{fontSize: 'small'}}
                    >

                        <Column field="company" header='Provider' style={{width: '50%'}}/>
                        <Column field="product" header='XRay Software' style={{width: '50%'}}/>

                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, myProps) => {

    const xrayRefLoadedId = `${Actions.RECEIVE_PATIENT_XRAY_REF}_LOADED_${myProps.patientId}`;
    const xrayRefId = `${Actions.RECEIVE_PATIENT_XRAY_REF}_${myProps.patientId}`;

    return {
        message: state.stateManagement.message,

        xrayRefLoaded: state.patients[xrayRefLoadedId],
        xrayRef: state.patients[xrayRefId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getXRAYRef: (patientId) => dispatch(getResource(RES_PATIENT_XRAY_RECORDS.GET_REF, {patientId})),
    }
};

const ConnectXRaySoftware = connect(mapStateToProps, MapDispatchToProps)(ConnectedConnectXRaySoftware);

export default ConnectXRaySoftware;
