import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getAllUsers, getUsersBySurname, RES_FIND_USERS, setUserStatus} from "../../../actions/users";
import * as Actions from "../../../actions";
import {
    HM_notImplemented,
    SU_STATUS_ACTIVE,
    SU_STATUS_INACTIVE,
    SU_STATUS_LOCKED,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_HELP, ICON_PLUS,} from "../../../icons";
import {BaseComponent} from "../../BaseComponent";
import {HELP_FIND_USER, setState, SM_USER_SEARCH, stateRequest} from "../../../actions/stateManagement";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {TB_USER_ACTIVE, TB_USER_ADD, TB_USER_DETAILS, TB_USER_INACTIVE, TB_USER_LOCKED} from "../UserDetails/Constants";
import {onShowUserDetailsPage, showAddUserPage} from "../UserDetails/Utils";
import {checkBox, CONST_FEMALE} from "../../PatientDynamicItems/OnChangeUtils";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {tb_boilerPlateRight} from "../../Utils";

export class ConnectedFindUsers extends BaseComponent {

    constructor(props) {
        super();

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                canSave: {status: false, activeIndex: 0, source: RES_FIND_USERS.CLEAR},

                searchCriteria: Actions.SEARCH_SURNAME,
                searchString: '',
                placeHolder: 'Surname',
                dataTableValue: [],
                dataTableSelectValue: null,

                checkboxes: {
                    includeInactives: false,
                },

                results: [],

                first: 0,
                rows: 5,
            }
        }

        this.toolbarCallbacks = {

            [TB_USER_ADD.id]: this.onAddUser,
            [TB_USER_DETAILS.id]: this.onShowDetails,
            [TB_SAVE_AND_EXIT.id]: this.onCloseClick,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.save) {
            this.props.setState(SM_USER_SEARCH.id, {...this.state});
            this.save = false;
        }

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:
                    this.setState({results: this.props.results, first: 0});
                    this.save = true;
                    break;
                default:
                    break;
            }
        }
    }

    onCriteriaChange = (e) => {

        if (e.value !== 'all') {
            this.setState({searchCriteria: Actions.SEARCH_SURNAME, searchString: ''});
            this.props.getUsersBySurname(`&pattern=${''}`);
        } else {
            this.setState({searchCriteria: Actions.SEARCH_ALL});
            this.props.getAllUsers();
        }
        this.save = true;
    }

    onGeneralValueChange(e) {
        const searchString = e.target.value;
        const urlPattern = `&pattern=${searchString}`;

        if (searchString.length > 2) {
            this.setState({searchCriteria: Actions.SEARCH_SURNAME, searchString});
            this.props.getUsersBySurname(urlPattern);
        } else {
            this.setState({searchString, searchCriteria: Actions.SEARCH_SURNAME});
        }
        this.save = true;
    }

    optionalInputText() {

        if (this.state.searchCriteria.value !== Actions.SEARCH_ALL.value) {

            let placeHolder = this.state.placeHolder ? this.state.placeHolder : Actions.SEARCH_SURNAME.placeHolder;
            let searchString = this.state.searchString ? this.state.searchString : '';

            return (
                <InputText id='searchString' type="text" placeholder={placeHolder}
                           onChange={this.onGeneralValueChange.bind(this)}
                           value={searchString}
                           autoFocus
                />
            )
        }
    }

    onShowDetails = ({id, firstName, lastName, gender}) => {

        onShowUserDetailsPage({props: this.props, id, firstName, lastName, gender});
    }

    onAddUser = () => {

        showAddUserPage(this.props);
    }

    onCloseClick = () => {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: SM_USER_SEARCH.id});
    }

    onSelectionChanged = (e) => {
        this.setState({dataTableSelectValue: e.value}, () => {
            this.save = true;
        });
    }

    onDoubleClick = (e) => {

        this.setState({dataTableSelectValue: e.data}, () => {
            this.onShowDetails(this.state.dataTableSelectValue);
            this.save = true;
        });
    }

    showDialogs() {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    buildMenuItems = () => {

        const items = [];

        if (this.state.dataTableSelectValue !== null) {

            const detailsIcon = this.state.dataTableSelectValue.gender === CONST_FEMALE ? TB_USER_DETAILS.femaleIcon : TB_USER_DETAILS.maleIcon;

            items.push({
                label: TB_USER_DETAILS.text, icon: detailsIcon, command: (e) => {
                    this.toolbarCallbacks[TB_USER_DETAILS.id](this.state.dataTableSelectValue);
                }
            });

            switch (this.state.dataTableSelectValue.status) {
                case SU_STATUS_INACTIVE:

                    items.push({
                        label: TB_USER_ACTIVE.text, icon: TB_USER_ACTIVE.icon, command: (e) => {
                            this.props.setUserStatus(this.state.dataTableSelectValue.id, SU_STATUS_ACTIVE);
                            this.onChange({owner: 'dataTableSelectValue.status', value: SU_STATUS_ACTIVE})
                        }
                    })

                    items.push({
                        label: TB_USER_LOCKED.text, icon: TB_USER_LOCKED.icon, command: (e) => {
                            this.props.setUserStatus(this.state.dataTableSelectValue.id, SU_STATUS_LOCKED);
                            this.onChange({owner: 'dataTableSelectValue.status', value: SU_STATUS_LOCKED})
                        }
                    });
                    break;
                case SU_STATUS_ACTIVE:

                    items.push({
                        label: TB_USER_INACTIVE.text, icon: TB_USER_INACTIVE.icon, command: (e) => {
                            this.props.setUserStatus(this.state.dataTableSelectValue.id, SU_STATUS_INACTIVE);
                            this.onChange({owner: 'dataTableSelectValue.status', value: SU_STATUS_INACTIVE})
                        }
                    });

                    items.push({
                        label: TB_USER_LOCKED.text, icon: TB_USER_LOCKED.icon, command: (e) => {
                            this.props.setUserStatus(this.state.dataTableSelectValue.id, SU_STATUS_LOCKED);
                            this.onChange({owner: 'dataTableSelectValue.status', value: SU_STATUS_LOCKED})
                        }
                    });
                    break;
                case SU_STATUS_LOCKED:

                    items.push({
                        label: TB_USER_ACTIVE.text, icon: TB_USER_ACTIVE.icon, command: (e) => {
                            this.props.setUserStatus(this.state.dataTableSelectValue.id, SU_STATUS_ACTIVE);
                            this.onChange({owner: 'dataTableSelectValue.status', value: SU_STATUS_ACTIVE})
                        }
                    })

                    items.push({
                        label: TB_USER_INACTIVE.text, icon: TB_USER_INACTIVE.icon, command: (e) => {
                            this.props.setUserStatus(this.state.dataTableSelectValue.id, SU_STATUS_INACTIVE);
                            this.onChange({owner: 'dataTableSelectValue.status', value: SU_STATUS_INACTIVE})
                        }
                    });
            }
        }
        return items;
    }

    render() {

        const searchCriteria = [
            Actions.SEARCH_SURNAME,
            Actions.SEARCH_ALL,
        ];

        const items = this.buildMenuItems();

        const props = {
            onChange: this.onChange,
            target: 'checkboxes',
            checkboxes: this.state.checkboxes,
        };

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>User Search</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={TB_USER_ADD.text}
                        icon={ICON_PLUS}
                        onClick={this.onAddUser}/>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Dropdown id="provider" value={this.state.searchCriteria.value}
                          options={searchCriteria}
                          onChange={this.onCriteriaChange}
                          autoWidth={false}
                />
                {this.optionalInputText()}
                {checkBox(props, 'includeInactives', 'Include Inactives', false, false)}
            </div>
        </div>;

        const visibleResults = this.state.results.filter(user => this.state.checkboxes.includeInactives ? user.username !== 'superuser' && user.username !== 'TED_superuser' : user.status !== SU_STATUS_INACTIVE && user.username !== 'superuser' && user.username !== 'TED_superuser');

        return (
            <div id="detailPanel">

                <Toolbar right={
                    <React.Fragment>
                        {tb_boilerPlateRight((e) => window.open(HELP_FIND_USER), ICON_HELP, 'Knowledge Base', 1, true)}
                        {tb_boilerPlateRight(this.onCloseClick, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 2)}
                    </React.Fragment>
                }
                />

                <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: '220px'}} model={items} ref={el => this.cm = el}/>

                    <DataTable value={visibleResults}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.dataTableSelectValue}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.dataTableSelectValue}
                               onContextMenuSelectionChange={e => this.setState({dataTableSelectValue: e.value})}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                    >
                        <Column field="titleAbbreviation" header="Title" sortable={true} style={{width: '10%'}}/>
                        <Column field="firstName" header="First Name" sortable={true} style={{width: '35%'}}/>
                        <Column field="lastName" header="Last Name" sortable={true} style={{width: '35%'}}/>
                        <Column field="status" header="Status" sortable={true} style={{width: '10%'}}/>
                        <Column field="myRole" header="Role" sortable={true} style={{width: '10%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT_SAVE :
            case TAB_EXIT :
                this.props.stateRequest(this.state.canSave.source);
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        results: state.users.results,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_USER_SEARCH.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsers: () => dispatch(getAllUsers()),
        getUsersBySurname: (urlPattern) => dispatch(getUsersBySurname(urlPattern)),
        setUserStatus: (id, status) => dispatch(setUserStatus(id, status)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const
    FindUsers = connect(mapStateToProps, mapDispatchToProps)(ConnectedFindUsers);

export default FindUsers;
