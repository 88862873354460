import React from 'react';
import _ from 'lodash';
import {BaseComponent} from "../../BaseComponent";
import {ac} from "../../../index";
import {ICON_BAN, ICON_DELETE} from "../../../icons";

export class TemplateComponent extends BaseComponent {

    onAddTemplate = (template) => {

        const newTemplate = {
            visibleName: template.visibleName,
            notes: template.notes,
            createBy: this.props.loginIdentity,
            report: template.templateType.report,
            type: template.templateType.type,
        };
        this.props.saveTemplate(newTemplate);
    }

    onEditTemplate = (template) => {
        this.props.updateTemplate(template);
    }

    onCopyTemplate(template) {

        const copyTemplate = {
            visibleName: template.visibleName,
            notes: template.notes,
            createbyId: this.props.loginIdentity.id,
            sourceId: template.docId,
        };
        this.props.copyTemplate(copyTemplate);
    }

    onSave(close) {
        this.props.setState(this.state.stateManagementId, null);
        this.onExit();
    }

    onExit = () => {
        this.props.setState(this.state.stateManagementId, null);
        this.props.onCloseClick({key: this.props.id});
    }

    onSelection = (e) => {

        this.setState({template: e.value}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
        });
        this.save = true;
    }

    deletableTemplate(rowData) {

        return rowData['deletable'] ? '' : <i alt='' className={ICON_BAN}/>;
    }

    reportTemplate = (rowData) => {
        const reportType = _.find(this.props.reportTypes, report => report.value === rowData.report);
        return reportType.label;
    }

    onOnlyOfficeOpen = () => {
        const {filename, visibleName} = this.state.template;
        const templateData = {
            chiralServer: ac.getBASERESTURL(),
            mcid: ac.getMcId(),
            filename,
            docname: visibleName,
        };

        const key = Math.random().toString(36).slice(2);

        let key2 = "1234";
        localStorage.setItem(key2, JSON.stringify(templateData));

        window.open(`https://${ac.getChiralServer()}/templateLoader.html?id=${key}`, '_blank');
    }

    onDeleteTemplate = (target) => {

        this.props.deleteTemplate(this.state.template.docId);

        const templates = [...this.state.templates];
        const index = _.findIndex(templates, image => image.docId === this.state.template.docId);
        templates.splice(index, 1);

        this.setState({
            templates,
            template: null,
            [target]: false,
        });
    }
}
