import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Button} from 'primereact/components/button/Button';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    HM_AccountGroupSelector,
    HM_ConfirmCreatePayments,
    HM_notImplemented,
    HM_PatientPaymentUpload,
    HM_PaymentFilePassword,
    HM_PaymentUploadFile,
    HTTP_RESPONSE_NotAcceptable,
    HTTP_RESPONSE_Unauthorized,
    TB_CLEAR_PAYMENTS,
    TB_CREATE_PAYMENTS,
    TB_PAYMENTS_CLEAR_ALL,
    TB_PAYMENTS_PASSWORD,
    TB_PAYMENTS_SET_ALL,
    TB_SAVE_AND_EXIT,
    UP_PAYMENT
} from "../../../Constants";
import {ICON_HELP, ICON_PLUS,} from "../../../icons";
import {connect} from "react-redux";
import {HELP_PATIENT_PAYMENT_UPLOADS, setState, SM_UploadPayments} from "../../../actions/stateManagement";
import {ShowMessageDialog, ShowQuestionDialog} from "../Diary/components/EventComponent";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import {BaseComponent} from "../../BaseComponent";
import UploadPaymentXLS from "./dialogs/UploadPaymentsXLS";
import {Toast} from "primereact/components/toast/Toast";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {Checkbox} from "primereact/checkbox";
import _ from "lodash";
import {currencyTemplate} from "../fixedItemUtils";
import {InputNumber} from "primereact/inputnumber";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import * as Actions from "../../../actions";
import {makePayment, RES_PATIENT_ACCOUNT, RES_PATIENT_PAYMENTS, updatePassword} from "../../../actions/personal";
import * as DefaultData from "../DefaultData";
import {ac} from "../../../index";
import {PAY_TYPE_EFT} from "../PatientDetails/Constants";
import AccountGroupSelector from "../Reports/dialogs/AccountGroupSelector";
import {getPatientById} from "../../../actions/findPatients";
import moment from "moment";
import {UpdatePaymentPassword} from "./dialogs/UpdatePaymentPassword";

export class ConnectedPatientPaymentUpload extends BaseComponent {

    constructor(props) {
        super();

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_UploadPayments.id,
                selectedAccountGroup: null,
                payments: [],
                payment: null,
                results: [],
                canSave: {status: false, activeIndex: 0},
                showSaveDialog: false,

                sheet: {password: ''},

                first: 0,
                rows: 5,

                globalFilter: '',
                [HM_AccountGroupSelector.id]: true,
                [HM_PaymentUploadFile.id]: false,
                [HM_ConfirmCreatePayments.id]: false,
            }
        }
        this.uploadGrowl = null;

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_PATIENT_PAYMENTS_SAVE: {
                    const payments = _.filter(this.state.payments, payment => !payment.approved);
                    this.setState({payments, canSave: {status: false}})
                    break;
                }
                case Actions.RECEIVE_PATIENT_SEARCH: {

                    const payment = {...this.state.payment};

                    if (this.props.results.length === 1) {
                        payment.firstName = this.props.results[0].firstName;
                        payment.lastName = this.props.results[0].lastName;
                        payment.patientId = this.props.results[0].id;
                        payment.approved = true;
                    } else {
                        payment.firstName = "";
                        payment.lastName = "";
                        payment.approved = false;
                        payment.patientId = 0;
                    }

                    const payments = [...this.state.payments];
                    const index = _.findIndex(payments, item => item.index === payment.index);
                    payments[index] = payment;

                    this.setState({payments, payment}, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    this.save = true;
                    break;
                }

                case Actions.WSM_UPDATES:
                    switch (this.props.wsmessage.function) {
                        case UP_PAYMENT:
                            const {patientId, amount, date} = this.props.wsmessage.content;
                            const date1 = moment(date).format('YYYY-MM-DD');
                            const payments = _.filter(this.state.payments, payment => {
                                const date2 = moment(payment.date).format('YYYY-MM-DD');
                                return payment.patientId !== patientId || payment.amount !== amount || date1 !== date2;
                            });
                            this.setState({payments}, () => {
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);
        this.setState(state);
    }

    onUpload = ({xhr, files}) => {

        const payments = _.map(JSON.parse(xhr.response), (payment, index) => {
            return {...payment, index, approved: payment.patientId !== 0}
        });

        const status = _.size(_.filter(payments, payment => payment.approved)) > 0;

        this.setState({[HM_PaymentUploadFile.id]: false, payments, canSave: {status}}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
        })
    }

    onSelection = (e) => {
        this.setState({payment: e.value});
    }

    tickboxTemplate = (row) => {

        if (row.patientId === 0)
            return null;

        return (<Checkbox onChange={e => {
                if (row.patientId === 0)
                    return;
                const payments = [...this.state.payments];
                const index = _.findIndex(payments, payment => payment.index === row.index);
                payments[index] = {...payments[index], approved: e.checked};
                const status = _.size(_.filter(payments, payment => payment.approved)) > 0;
                this.setState({payments, canSave: {status}});
            }}
                          checked={row.approved}
            />
        )
    }

    onSetAccountGroup = (accountGroup) => {

        this.setState({
            selectedAccountGroup: accountGroup,
            [HM_AccountGroupSelector.id]: false,
            [HM_PaymentUploadFile.id]: true
        });
    }

    onError = (result) => {

        switch (result.xhr.status) {
            case HTTP_RESPONSE_Unauthorized:
                this.uploadGrowl.show({
                    severity: 'error',
                    summary: 'Authentication Failure',
                    detail: 'Incorrect spreadsheet password',
                    sticky: true,
                });
                break;
            case HTTP_RESPONSE_NotAcceptable:
                this.uploadGrowl.show({
                    severity: 'error',
                    summary: 'Incorrect File Type',
                    detail: 'Only files of type (.xls, .xlsx) are acceptable',
                    sticky: true,
                });
                break;
            default:
                this.uploadGrowl.show({
                    severity: 'error',
                    summary: 'Upload Failure',
                    detail: 'Payment file upload failed'
                });
                break;
        }
        this.onHideMenuEntry(HM_PaymentUploadFile.id);
    };

    showDialogs = () => {

        if (this.state[HM_PaymentUploadFile.id]) {

            return (
                <UploadPaymentXLS
                    key={HM_PaymentUploadFile.id}
                    header={HM_PaymentUploadFile.header}
                    onFileUploaded={this.onUpload}
                    onFileUploadError={this.onError}
                    onHideDialog={this.onHideMenuEntry}
                />)
        } else if (this.state[HM_AccountGroupSelector.id]) {
            return (
                <AccountGroupSelector key={HM_AccountGroupSelector.id}
                                      onOkDialog={this.onSetAccountGroup}
                />
            )
        } else if (this.state[HM_PaymentFilePassword.id]) {
            return (
                <UpdatePaymentPassword key={HM_PaymentFilePassword.id}
                                       target='sheet'
                                       onChange={this.onChange}
                                       onHideDialog={this.onHideMenuEntry}
                                       onOKDialog={this.onSavePassword}
                />
            )
        } else if (this.state[HM_ConfirmCreatePayments.id]) {
            return ShowQuestionDialog(this, HM_ConfirmCreatePayments, this.onSaveConfirm)
        } else {
            return ShowMessageDialog(this, HM_notImplemented);
        }
    }

    onExit = () => {

        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: SM_UploadPayments.id});
    }

    onSave = () => {
        this.setState({[HM_ConfirmCreatePayments.id]: true});
    }

    onUpdatePassword = () => {
        this.setState({[HM_PaymentFilePassword.id]: true});
    }

    onSavePassword = (password) => {
        this.setState({[HM_PaymentFilePassword.id]: false}, () => {
            this.props.updatePassword(this.state.sheet.password)
        });
    }

    onSaveConfirm = () => {

        const validPayments = _.filter(this.state.payments, payment => payment.approved);

        validPayments.forEach(paymentData => {

            const newPayment = _.cloneDeep(DefaultData.paymentData(ac.getMcId()));

            newPayment.placementOrder = paymentData.date;
            newPayment.date = newPayment.placementOrder
            newPayment.amount = paymentData.amount;
            newPayment.patient = {id: paymentData.patientId};
            newPayment.paymentType = PAY_TYPE_EFT.value;
            newPayment.narrative = `${paymentData.split} :: ${paymentData.payee}`;
            newPayment.takenBy = {id: this.props.loginIdentity.id}
            newPayment.accountGroup = {id: this.state.selectedAccountGroup.id};

            this.props.makePayment(newPayment);
        })
        this.setState({[HM_ConfirmCreatePayments.id]: false, canSave: {status: false}}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        })
    }

    onSetApprovals = (state) => {
        const payments = _.map(this.state.payments, payment => ({
            ...payment,
            approved: payment.paymentId !== 0 ? state : false
        }));
        const status = _.size(_.filter(payments, payment => payment.approved)) > 0;
        this.setState({payments, canSave: {status}}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        })
    }

    onClearPayments = () => {
        this.setState({payments: [], payment: null, canSave: {status: false}}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        })
    }

    onEditorSubmit = (row) => {
    }

    numberEditor = (options) => {

        return (
            <InputNumber
                mode="decimal"
                useGrouping={false}
                value={options.rowData[options.field]}
                onValueChange={(e) => {
                    const payments = [...this.state.payments];
                    const index = _.findIndex(payments, payment => payment.index === options.rowData.index);
                    const newValue = e.value && !isNaN(e.value) ? e.value : 0;
                    payments[index] = {...payments[index], patientId: newValue, approved: newValue !== 0};
                    const status = _.size(_.filter(payments, payment => payment.approved)) > 0;

                    this.setState({payments, canSave: {status}}, () => {

                        if (newValue > 0)
                            this.props.getPatientById(newValue);
                    });
                }}
            />
        );
    }

    render() {

        const items = [];

        const saveIcon = this.state.canSave.status ? TB_CREATE_PAYMENTS.icon : TB_CREATE_PAYMENTS.iconDisabled;

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{HM_PatientPaymentUpload.header}</span>
                <Button tooltip={HM_PatientPaymentUpload.message}
                        tooltipOptions={{position: 'right'}}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_PaymentUploadFile.id}})
                        }}/>
            </div>
        </div>;

        const sortedPayments = _.sortBy(this.state.payments, ['date', 'lastName', 'firstName'], ['asc', 'asc', 'asc']);

        let key = 2000;

        return (
            <div key={key++}>

                <Toast ref={(el) => {
                    this.uploadGrowl = el;
                }}/>

                {this.showDialogs()}

                <Toolbar left={
                    <React.Fragment>
                        {tb_boilerPlate2(this.onSave, saveIcon, !this.state.canSave.status, TB_CREATE_PAYMENTS.text, key++)}
                        <i key='sep1' className='p-toolbar-group-left fa separator24'/>
                        {tb_boilerPlate2(() => this.onSetApprovals(false), TB_PAYMENTS_CLEAR_ALL.icon, false, TB_PAYMENTS_CLEAR_ALL.text, key++)}
                        {tb_boilerPlate2(() => this.onSetApprovals(true), TB_PAYMENTS_SET_ALL.icon, false, TB_PAYMENTS_SET_ALL.text, key++)}
                        <i key='sep2' className='p-toolbar-group-left fa separator24'/>
                        {tb_boilerPlate2(this.onClearPayments, TB_CLEAR_PAYMENTS.icon, false, TB_CLEAR_PAYMENTS.text, key++)}
                    </React.Fragment>
                }
                         right={
                             <React.Fragment>
                                 {tb_boilerPlate2(this.onUpdatePassword, TB_PAYMENTS_PASSWORD.icon, false, TB_PAYMENTS_PASSWORD.text, key++)}
                                 <i key='sep3' className='p-toolbar-group-right fa separator24'/>
                                 {tb_boilerPlateRight((e) => window.open(HELP_PATIENT_PAYMENT_UPLOADS), ICON_HELP, 'Knowledge Base', key++, true)}
                                 {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                             </React.Fragment>
                         }
                />

                <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>
                    <div className="p-col-12">
                        <Panel headerTemplate={header}>

                            <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                            <DataTable value={sortedPayments}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       selection={this.state.payment}
                                       onRowDoubleClick={this.onSelection}
                                       contextMenuSelection={this.state.payment}
                                       onContextMenuSelectionChange={e => this.setState({template: e.value})}
                                       onContextMenu={e => this.cm.show(e.originalEvent)}
                                       paginator={true}
                                       rows={this.state.rows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onSelectionChange={this.onSelection}
                                       first={this.state.first}
                                       onPage={this.onPage}
                            >
                                <Column body={this.tickboxTemplate}
                                        header="Approved"
                                        style={{width: '10%'}}/>
                                <Column body={row => dateTemplateShort(row.date)}
                                        header="Date"
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="patientId"
                                        editor={this.numberEditor}
                                        onEditorSubmit={e => this.onEditorSubmit(e)}
                                        header="Patient ID"
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field='payee'
                                        header="Payee Name"
                                        style={{width: '30%'}}/>
                                <Column
                                    body={(row) => row.patientId === 0 ? '' : `${row.lastName}, ${row.firstName}`.trim()}
                                    header="Patient Name"
                                    style={{width: '30%'}}/>
                                <Column body={row => currencyTemplate(row, 'amount')}
                                        header="Amount"
                                        style={{width: '10%', textAlign: 'right'}}/>
                            </DataTable>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({action: RES_PATIENT_PAYMENTS.CLEAR.action, id: SM_UploadPayments.id});
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        results: state.patients.results,

        loginIdentity: state.login.user,

        capabilities: state.login.capabilities,

        currentState: state.stateManagement[SM_UploadPayments.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        makePayment: (payment) => dispatch(makePayment(RES_PATIENT_ACCOUNT.MAKE_PAYMENT, payment)),
        getPatientById: (id) => dispatch(getPatientById(id)),
        updatePassword: (password) => dispatch(updatePassword(password)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const PatientPaymentUpload = connect(mapStateToProps, mapDispatchToProps)(ConnectedPatientPaymentUpload);

export default PatientPaymentUpload;
