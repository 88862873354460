import _ from 'lodash';
import {RES_OBJECT_STORE} from "../actions/objectStore";
import * as Actions from "../actions";
import {RES_PREFERENCES} from "../actions/preferences";
import {SM_PREFERENCES_DCS, SM_PREFERENCES_DIS} from "../actions/stateManagement";

const initialState = {

    objectRequestLoaded: false,
};

export const objectStore = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case RES_OBJECT_STORE.GET_LOGIN.request: {

                const newState = {...state};

                _.set(newState, 'loginObjectRequestLoaded', false);
                return newState;
            }

            case RES_OBJECT_STORE.GET.request: {

                const newState = {...state};

                _.set(newState, 'objectRequestLoaded', false);
                return newState;
            }
            case RES_PREFERENCES.SAVE.action:

                switch (action.saveType) {
                    case SM_PREFERENCES_DIS.id:

                        const {APPOINTMENT_COLOUR_SCHEME} = action.payload;
                        return {
                            ...state,
                            APPOINTMENT_COLOUR_SCHEME,
                        };
                    case SM_PREFERENCES_DCS.id:

                        const {HOLIDAY_COLOUR, OUTOFHOURS_COLOUR, LUNCH_COLOUR, RESERVED_COLOUR} = action.payload;
                        return {
                            ...state,
                            HOLIDAY_COLOUR,
                            OUTOFHOURS_COLOUR,
                            LUNCH_COLOUR,
                            RESERVED_COLOUR
                        };
                    default:
                        return state;
                }

            case RES_OBJECT_STORE.GET_LOGIN.receive: {

                const newState = {...state};

                for (let key in action.payload) {
                    _.set(newState, key, action.payload[key]);
                }
                _.set(newState, 'loginObjectRequestLoaded', true);
                return newState;
            }

            case RES_OBJECT_STORE.GET.receive: {

                const newState = {...state};

                for (let key in action.payload) {
                    _.set(newState, key, action.payload[key]);
                }
                _.set(newState, 'objectRequestLoaded', true);
                return newState;
            }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default:
                return state;
        }
    } catch (error) {
        return state;
    }
}
