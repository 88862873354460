import React from "react";
import {COLOR_ChiralPalette} from "../../../Client/Constants";

export class PaletteBaseComponent extends React.Component {

    constructor(props) {
        super(props);

        this.myRef = React.createRef();
        this.onClickHandler = this.onClickHandler.bind(this);

        this.paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};
    }

    onClickHandler = () => {
        this.props.onItemSelected(null);
    }

    componentDidMount() {

        try {
            this.myRef.current.addEventListener('click', this.onClickHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    render() {

        let key = Math.floor(Math.random() * 1000);

        return (
            <div className='p-sm-2' style={{display: 'flex', flexFlow: 'column'}} key={`pbc_${key}`}>
                <div className='diary-palette-component' style={{backgroundColor: '#f5f5f5', borderBottom: '1px solid grey'}}>

                    <span className='fas fa-palette'
                          style={{fontSize: '20px', color: COLOR_ChiralPalette, marginRight: '5px', marginTop: '5px'}}/>
                    Diary Actions
                </div>
                <div className="diaryPaletteEntry"
                     style={{fontWeight: 'bold'}}
                     ref={this.myRef}
                >
                    <span className='fas fa-mouse-pointer'
                          style={{fontSize: '20px', color: COLOR_ChiralPalette, marginRight: '5px', marginTop: '5px'}}/>
                    Deselect
                </div>
                {this.props.buildPalette(this.props)}
            </div>
        )
    }

    componentWillUnmount() {

        try {
            this.myRef.current.removeEventListener('click', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
