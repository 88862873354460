import _ from 'lodash';
import * as Actions from '../actions/index';

const initialState = {

    gatewayConfigurationLoaded: false,
    gatewayConfiguration: [],
};

export const gateway = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.GET_GATEWAY:

                return {
                    ...state,
                    gatewayConfigurationLoaded: true,
                    gatewayConfiguration: action.payload,
                };
          default :
                return state;
        }
    } catch (error) {
        return state;
    }
};