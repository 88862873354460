import React from 'react';
import {PDFBaseComponent} from "../../../PDFBaseComponent";
import {getReportPDF} from "../../../../actions/reports";
import {setState, SM_PATIENT_NOTE_HISTORY_PDF} from "../../../../actions/stateManagement";
import {RES_PATIENT_NOTES} from "../../../../actions/personal";
import {connect} from "react-redux";
import {ProgressBar} from "primereact/progressbar";
import {TextPatientNotes} from "../../../../Messages";

class ConnectedNoteHistoryPDF extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                pdfType: SM_PATIENT_NOTE_HISTORY_PDF.id,
                numPages: null,
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_NOTES.PDF_ALL.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(this.props.noteHistoryIndex, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.state.PDFLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (
            <div className="p-col-12 p-lg-12">

                {this.createPDFToolBar(TextPatientNotes, SM_PATIENT_NOTE_HISTORY_PDF.filename)}
                {this.createPDFContainer(SM_PATIENT_NOTE_HISTORY_PDF.label)}
            </div>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_PATIENT_NOTES.CLEAR);
    }
}

const mapStateToProps = (state, ownProps) => {

    const noteHistoryLoaded = `${SM_PATIENT_NOTE_HISTORY_PDF.id}_${ownProps.details.id}Loaded`;
    const noteHistoryIndex = `${SM_PATIENT_NOTE_HISTORY_PDF.id}_${ownProps.details.id}`;

    return {
        message: state.stateManagement.message,

        PDFLoaded: state.reports[noteHistoryLoaded],
        pdfBuffer: state.reports[noteHistoryIndex],

        noteHistoryIndex,

        currentState: state.stateManagement[noteHistoryIndex],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReportPDF(RES_PATIENT_NOTES.PDF_ALL, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const NoteHistoryPDF = connect(mapStateToProps, mapDispatchToProps)(ConnectedNoteHistoryPDF);

export default NoteHistoryPDF;
