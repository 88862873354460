import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";

export const Report = (props) => {

    const reportProps = {
        onChange: () => {
        },
        target: 'report',
        report: props.report,
    };

    return (
        <Panel header='Details'>
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label htmlFor="name">Name</label>
                </div>
                <div className="p-col-12">
                    {inputText(reportProps, 'name', '', props.viewing, false)}
                </div>
                <div className="p-col-12">
                    <label htmlFor='report.comment'>Comment</label>
                </div>
                <div className="p-col-12">
                    {inputText(reportProps, 'comment', '', props.viewing, false)}
                </div>
                <div className="p-col-12">
                    {inputTextArea(reportProps, 'text', 20, 30, props.viewing, false)}
                </div>
            </div>
        </Panel>
    )
};
