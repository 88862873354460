
export const handleError = (error, source) => {

    if (error.response) {
        console.log(`${source} :: ${error.message}`);
        console.log(`${source} :: ${error.response.status}`);
    } else if (error.request) {
        console.log(`${source} :: ${error.message}`);
        console.log(`${source} :: ${error.request}`);
    } else {
        console.log(`${source} :: ${error.message}`);
    }
}