import React from 'react';
import {connect} from 'react-redux';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {RES_SMS_SHOT, sendSMSMailShot, sendSMSMailShotTest} from "../../../actions/mailShots";
import {TB_SAVE, TB_SAVE_AND_EXIT, TB_SEND_SMS_MAIL_SHOT, TB_SEND_SMS_MAIL_SHOT_TEST} from "../../../Constants";
import {ICON_CLOSE, ICON_OK, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED,} from "../../../icons";
import {TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import SMSMailShotHistory from "./History";
import SMSMailShot from "./SMSMailShot";
import {SMS_HISTORY} from "../EmailShots/Constants";
import {setState, SM_SMS_MAIL_SHOT, SM_SMS_MAIL_SHOTS, stateRequest} from "../../../actions/stateManagement";
import SMSMailShotErrorBoundary, {smsMailShotHistoryToolBar, smsMailShotToolBar} from "./Utils";
import {MailShotsBase} from "../Utilities/MailShotsBase";

class ConnectedEmailMailShots extends MailShotsBase {

    constructor(props) {
        super(props);

        const initialSource = {
            id: SM_SMS_MAIL_SHOTS.id,
            action: RES_SMS_SHOT.CLEAR.action,
            saveState: false,
            saveObjects: false,
        };

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_SMS_MAIL_SHOTS.id,

                selectedShots: [],
                selectedMS: null,

                canSave: {status: false, activeIndex: 0, source: initialSource},
                showSaveDialog: false,
            }
        }

        this.tabStack = [];
        this.index = 0;

        this.exitState = TAB_PARENT_CHANGE;

        this.source = {
            id: this.state.stateManagementId,
            action: RES_SMS_SHOT.SAVE.action,
            saveState: true,
            saveObjects: false,
        };

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onCloseTab,

            [TB_SEND_SMS_MAIL_SHOT]: this.onSendMailShot,
            [TB_SEND_SMS_MAIL_SHOT_TEST]: this.onSendMailShotTest,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message.type !== prevProps.message.type) {

            switch (this.props.message.type) {
                case RES_SMS_SHOT.SAVE.action:

                    this.exitState = TAB_PARENT_CHANGE;

                    const newState = {...this.state};
                    newState.canSave.status = false;
                    newState.showSaveDialog = false;

                    this.setState(newState, () => {
                        this.props.onTabUpdate({key: SM_SMS_MAIL_SHOTS.id}, false);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onCloseTab = () => {

        if (this.state.canSave.status) {
            this.onCloseRequest(RES_SMS_SHOT.SAVE_CLEAR.action)
        } else {
            this.onExit(RES_SMS_SHOT.CLEAR.action)
        }
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        const footer = <div>
            <Button label="Yes" icon={ICON_OK} onClick={() => this.onClose()}/>
            <Button label="No" icon={ICON_CLOSE} onClick={() => this.onExit(RES_SMS_SHOT.CLEAR.action)}/>
        </div>;

        this.tabStack.push(
            {
                index: this.index++,
                section: SMS_HISTORY, content:
                    <TabPanel key='smsHistory'
                              header="Mail Shots">

                        <SMSMailShotHistory onChange={this.onChange}
                                            onAddSMSTab={this.onAddMSTab}
                                            onCloseClick={this.props.onCloseClick}
                                            onTabUpdate={this.props.onTabUpdate}
                                            onAdditionalClick={this.props.onPCButtonClick}
                        />
                    </TabPanel>
            });

        this.state.selectedShots.forEach(shot => {
            this.tabStack.push(
                {
                    index: this.index++,
                    key: this.index++,
                    section: `${SM_SMS_MAIL_SHOT.id}_${shot.id}`, content:
                        <TabPanel key={`sms_${this.index++}`}
                                  header={shot.title}>

                            <SMSMailShot key={`sms_${this.index++}`}
                                         shot={shot}
                                         smsShotId={shot.id}
                                         editing={shot.editing}
                                         onChange={this.onChange}
                                         onCloseClick={this.props.onCloseClick}
                                         onTabUpdate={this.props.onTabUpdate}
                                         onAdditionalClick={this.props.onPCButtonClick}
                                         parentId={SM_SMS_MAIL_SHOTS.id}
                            />
                        </TabPanel>
                });
        });
        const canSave = this.state.canSave.status;
        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const toolbar = this.state.canSave.activeIndex === 0 ? smsMailShotHistoryToolBar(this.toolbarCallbacks, saveIcon, canSave) : smsMailShotToolBar(this.toolbarCallbacks, saveIcon, canSave);

        return (
            <SMSMailShotErrorBoundary>
                <div id="detailPanel">

                    {this.showDialogs()}

                    <Toolbar {...toolbar}/>

                    <Dialog header="Save Resource"
                            footer={footer}
                            visible={this.state.showSaveDialog}
                            width="350px"
                            modal={true}
                            minY={70}
                            onHide={() => {
                                this.onChange({owner: "showSaveDialog", value: false});
                            }}>
                        The form has been modified, save these changes?
                    </Dialog>

                    <TabView scrollable={true}
                             style={{paddingTop: '5px'}}
                             activeIndex={this.state.canSave.activeIndex}
                             onTabChange={(e) => {
                                 this.onTabChange(e.index)
                             }}>

                        {this.tabStack.map(tab => {
                            return tab.content
                        })}

                    </TabView>
                </div>
            </SMSMailShotErrorBoundary>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        currentState: state.stateManagement[SM_SMS_MAIL_SHOTS.id],
    }
};

const MapDispatchToProps = dispatch => {

    return {
        sendMailShot: (params) => dispatch(sendSMSMailShot(RES_SMS_SHOT.SEND, params)),
        sendMailShotTest: (params) => dispatch(sendSMSMailShotTest(RES_SMS_SHOT.SEND, params)),
        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const SMSMailShots = connect(MapStateToProps, MapDispatchToProps)(ConnectedEmailMailShots);

export default SMSMailShots;

