import * as Actions from './index';
import {ac} from '../index'

export const baseURL = '/Referrers';
const baseServiceURL = '/api/v1/reports';

export const RES_REFERRER_DETAILS = {
    GET: {
        url: `${baseURL}/getReferrer`,
        request: Actions.REQUEST_REFERRER_DETAILS,
        receive: Actions.RECEIVE_REFERRER_DETAILS
    },
    ADD: {
        url: `${baseURL}/addReferrer`,
        request: Actions.REQUEST_REFERRER_ADD,
        receive: Actions.RECEIVE_REFERRER_ADD
    },
    SAVE: {action: Actions.SAVE_REFERRER_DETAILS, url: `${baseURL}/updateReferrer`},
    CLEAR: {action: Actions.CLEAR_REFERRER_DETAILS},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_REFERRER_DETAILS},
    DOWNLOAD: {
        url: `${baseServiceURL}/referrers.xls`,
        request: Actions.REQUEST_REFERRER_DOWNLOAD,
        receive: Actions.RECEIVE_REFERRER_DOWNLOAD
    },
};

export const RES_FIND_REFERRERS = {
    CLEAR: {action: Actions.CLEAR_REFERRER_SEARCH}
};

export const RES_REFERRER_PATIENTS = {
    GET: {
        url: `${baseURL}/getPatients`,
        request: Actions.REQUEST_REFERRER_PATIENTS,
        receive: Actions.RECEIVE_REFERRER_PATIENTS
    }
};

export const getAllReferrers = (includeInactives) => {

    const searchURL = `${baseURL}/findShort?mcId=${ac.getMcId()}&includeInactives=${includeInactives}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL)
            .then(res => {

                dispatch({type: Actions.RECEIVE_REFERRER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_REFERRER_SEARCH, payload: []});
    }
};

export const getRefPatients = ({url, request, receive}, id) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(res => {

                dispatch({type: receive, payload: res.data, id})
            });
        dispatch({type: request, payload: []});
    }
};

export const getReferrer = ({url, request, receive}, id) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(res => {
                dispatch({type: receive, payload: res.data, id});
            });
        dispatch({type: request, payload: id});
    }
};

export const getReferrersBySurname = (urlPattern) => {

    const searchURL = `${baseURL}/findShort?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_REFERRER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_REFERRER_SEARCH, payload: []});
    }
};

export const getReferrersByFirstname = (urlPattern) => {

    const searchURL = `${baseURL}/findShortByFirstname?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_REFERRER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_REFERRER_SEARCH, payload: []});
    }
};

export const getReferrersByCompany = (urlPattern) => {

    const searchURL = `${baseURL}/findShortByOrganisation?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_REFERRER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_REFERRER_SEARCH, payload: []});
    }
};

export const getReferrersByJobDescription = (urlPattern) => {

    const searchURL = `${baseURL}/findShortByJobDescription?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_REFERRER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_REFERRER_SEARCH, payload: []});
    }
};

export const getReferrersByAddress = (urlPattern) => {

    const searchURL = `${baseURL}/findShortByAddress?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_REFERRER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_REFERRER_SEARCH, payload: []});
    }
};

export const addReferrer = ({url, request, receive}, referrer) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, referrer)
            .then((res) => {
                dispatch({type: receive, payload: res.data});
            });
        dispatch({type: request, payload: false});
    }
};

export const setReferrerStatus = (id, status) => {

    return (dispatch) => {
        ac.fetchClient().post(`${baseURL}/setStatus?mcId=${ac.getMcId()}&id=${id}&status=${status}`)
            .then((response) => {
                dispatch({type: Actions.COMPLETED_SET_REFEERRER_STATUS, payload: true})
            });
        dispatch({type: Actions.REQUEST_SET_REFEERRER_STATUS, payload: false});
    }
};
