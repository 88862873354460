import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';

import {AssessmentHistory} from '../../../PatientDynamicItems/AssessmentHistory';
import {OrthoExtraOral} from '../../../PatientDynamicItems/OrthoExtraOral';
import {OrthoIntraOral1} from '../../../PatientDynamicItems/OrthoIntraOral1';
import {OrthoIntraOral2} from '../../../PatientDynamicItems/OrthoIntraOral2';
import {IOTN} from '../../../PatientDynamicItems/IOTN';
import {DevStage} from '../../../PatientDynamicItems/DevStage';
import {OtherInvestigations} from '../../../PatientDynamicItems/OtherInvestigations';
import {getResource as getCatResource, RES_ORTHO_CATEGORIES} from "../../../../actions/categories";
import {connect} from "react-redux";
import {UPDATE_TAB} from "../../../../actions";
import {
    deleteItem,
    getResource2,
    RES_ORTHO_ASSESSMENTS,
    RES_ORTHO_ASSESSMENTS_APPOINTMENT
} from "../../../../actions/personal";
import {
    HM_AddOrthoAssessment,
    HM_DeleteAssessment,
    HM_EditAssessment,
    HM_notImplemented,
    HM_ORTHO_ASSESSMENT_PRINT,
    HM_ORTHO_ASSESSMENT_SHOW
} from "../../../../Constants";
import {ShowMessageDialog, ShowQuestionDialog} from "../../Diary/components/EventComponent";
import {BaseComponent} from "../../../BaseComponent";
import _ from "lodash";
import * as DefaultData from "../../DefaultData";
import {ac} from "../../../../index";
import {setState} from "../../../../actions/stateManagement";
import {ProgressBar} from "primereact/progressbar";

class ConnectedOrthoAssessmentSection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.requesterRef,

                requesterLoadedRef: props.requesterLoadedRef,
                requesterRef: props.requesterRef,

                assessmentHistory: [],
                fakeIndex: -1000,

                readOnly: true,

                [HM_notImplemented.id]: false,
                [HM_EditAssessment.id]: false,
                [HM_DeleteAssessment.id]: false,
            }
        }

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
            [HM_EditAssessment.id]: this.onEditAssessment,
            [HM_DeleteAssessment.id]: this.onDeleteAssessment,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getOrthoCategories();
            this.props.getOrthoHistory(this.props.requester, this.props.requesterLoadedRef, this.props.requesterRef, {patientId: this.props.patientId});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_ORTHO_ASSESSMENTS_APPOINTMENT.GET.receive:
                case RES_ORTHO_ASSESSMENTS.GET.receive:
                    this.setState({assessmentHistory: this.props.patientOrthoHistory});
                    break;
                case RES_ORTHO_ASSESSMENTS_APPOINTMENT.SAVE.action:
                case RES_ORTHO_ASSESSMENTS.SAVE.action:
                    break;
                default:
                    break;
            }
        }
    }

    onEditAssessment = (e) => {
        this.setState({readOnly: false});
    };

    onSelectionChanged = (assessment) => {
        this.setState({selectedAssessment: assessment, readOnly: true}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
        })
    }

    onAssessmentChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        state.selectedAssessment.edited = true;

        this.setState(state, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
        })
    }

    onAddAssessment = () => {

        const newAssessment = DefaultData.orthoAssessment(ac.getMcId(), this.props.categories);
        newAssessment.id = this.state.fakeIndex;
        newAssessment.patient = {id: this.props.patientId};
        newAssessment.assessedBy = this.props.loginIdentity;

        const assessments = [...this.state.assessmentHistory];
        assessments.unshift(newAssessment);

        this.setState({
            assessmentHistory: assessments,
            selectedAssessment: newAssessment,
            fakeIndex: this.state.fakeIndex + 1
        }, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
        });
    };

    onDeleteAssessment = () => {

        const details = {oaId: this.state.selectedAssessment.id};
        const assessmentHistory = _.filter(this.state.assessmentHistory, assessment => assessment.id !== details.oaId);

        this.setState({assessmentHistory, selectedAssessment: undefined, [HM_DeleteAssessment.id]: false}, () => {
            this.props.deleteAssessment(details);
        });
    }

    showDialogs() {

        const contents = [];

        contents.push(ShowMessageDialog(this, HM_notImplemented));
        contents.push(ShowQuestionDialog(this, HM_DeleteAssessment, this.onDeleteAssessment));

        return contents
    }

    render() {

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>Orthodontic Assessment</span>
                    </div>
                    <div className="items-margin d-flex d-align-center">
                        <Button icon={HM_ORTHO_ASSESSMENT_SHOW.icon}
                                tooltip={HM_ORTHO_ASSESSMENT_SHOW.label}
                                tooltipOptions={{position: 'top'}}
                                onClick={(e) => {
                                    this.toolbarCallbacks[HM_notImplemented.id]();
                                }}
                        />
                        <Button icon={HM_ORTHO_ASSESSMENT_PRINT.icon}
                                tooltip={HM_ORTHO_ASSESSMENT_PRINT.label}
                                tooltipOptions={{position: 'top'}}
                                onClick={(e) => {
                                    this.toolbarCallbacks[HM_notImplemented.id]();
                                }}
                        />
                    </div>
                </div>;

        if (!this.props.categoriesLoaded || !this.props.patientOrthoHistoryLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const {readOnly} = this.state;

        return (

            <Panel headerTemplate={header}>

                {this.showDialogs()}

                <div className="p-grid"
                     style={{fontSize: 'small'}}>

                    <div className="p-lg-12 p-md-12 p-col-nopad">
                        <AssessmentHistory onSelectionChanged={this.onSelectionChanged}
                                           toolbarCallbacks={this.toolbarCallbacks}
                                           assessment={this.state.selectedAssessment}
                                           list={this.state.assessmentHistory}
                                           assessmentType={HM_AddOrthoAssessment}
                                           onAddAssessment={this.onAddAssessment}
                                           onDeleteAssessment={() => this.onShowMenuEntry({item: {target: HM_DeleteAssessment.id}})}
                        />
                    </div>
                    <div className="p-lg-6 p-md-6">
                        <OrthoExtraOral target='selectedAssessment'
                                        onChange={this.onAssessmentChange}
                                        selectedAssessment={this.state.selectedAssessment}
                                        categories={this.props.categories}
                                        readOnly={readOnly}
                        />
                        <OrthoIntraOral2 target='selectedAssessment'
                                         onChange={this.onAssessmentChange}
                                         selectedAssessment={this.state.selectedAssessment}
                                         categories={this.props.categories}
                                         readOnly={readOnly}
                        />
                    </div>
                    <div className="p-lg-6 p-md-6 p-col-nopad">
                        <OrthoIntraOral1 target='selectedAssessment'
                                         onChange={this.onAssessmentChange}
                                         selectedAssessment={this.state.selectedAssessment}
                                         categories={this.props.categories}
                                         readOnly={readOnly}
                        />
                    </div>
                    <div className="p-lg-12 p-col-nopad">
                        <IOTN target='selectedAssessment'
                              onChange={this.onAssessmentChange}
                              selectedAssessment={this.state.selectedAssessment}
                              categories={this.props.categories}
                              readOnly={readOnly}
                        />
                    </div>
                    <div className="p-lg-12 p-col-nopad">
                        <DevStage target='selectedAssessment'
                                  onChange={this.onAssessmentChange}
                                  selectedAssessment={this.state.selectedAssessment}
                                  categories={this.props.categories}
                                  readOnly={readOnly}
                        />
                    </div>
                </div>
                <OtherInvestigations target='selectedAssessment'
                                     onChange={this.onAssessmentChange}
                                     selectedAssessment={this.state.selectedAssessment}
                                     categories={this.props.categories}
                                     readOnly={readOnly}
                />
            </Panel>
        );
    }
}

const MapStateToProps = (state, ownProps) => {

    const {requesterLoadedRef, requesterRef} = ownProps;

    const patientOrthoHistoryLoaded = state.patients[requesterLoadedRef] === undefined ? false : state.patients[requesterLoadedRef];
    const patientOrthoHistory = patientOrthoHistoryLoaded ? state.patients[requesterRef] : [];

    return {

        message: state.stateManagement.message,

        categoriesLoaded: state.oac.categoriesLoaded,
        categories: state.oac.categories.categories,

        patientOrthoHistoryLoaded,
        patientOrthoHistory,

        loginIdentity: state.login.user,

        currentState: state.stateManagement[requesterRef],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getOrthoCategories: () => dispatch(getCatResource(RES_ORTHO_CATEGORIES.GET, {})),
        getOrthoHistory: (getRequest, requesterLoadedRef, requesterRef, patientId) => dispatch(getResource2(getRequest, requesterLoadedRef, requesterRef, patientId)),
        deleteAssessment: (details) => dispatch(deleteItem(RES_ORTHO_ASSESSMENTS.DELETE, details)),

        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const OrthoAssessmentSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedOrthoAssessmentSection);

export default OrthoAssessmentSection;
