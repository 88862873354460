import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from 'primereact/components/inputtext/InputText';

import {
    CC_TYPES,
    DC_TYPES,
    PAY_TYPE_CC,
    PD_STATE_RECEIPT_AMOUNT,
    PD_STATE_RECEIPT_CD_CARD,
    PD_STATE_RECEIPT_NARRATIVE,
    TB_RECEIPT_CD_CARD
} from "../../../PatientDetails/Constants";
import {dropDownPlain, inputText} from "../../../../PatientDynamicItems/OnChangeUtils";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";

function optionalAmountInfo(props) {

    if (!props.changeType) {

        const content = [];

        content.push(
            <div className="p-col-3">
                <label>Amount (£)</label>
            </div>
        );
        content.push(
            <div className="p-col-9">
                <InputText type="text"
                           disabled={false}
                           style={{fontSize: 'x-large', textAlign: 'right'}}
                           value={props.payment.amount}
                           keyfilter='pnum'
                           onChange={(e) => {
                               props.onChange({owner: 'payment.amount', value: e.target.value});
                           }}
                />
            </div>
        );
        return content;
    }
}

export const PaymentCDCard = (props) => {

    const payProps = {
        onChange: props.onChange,
        cardTypesOptions: props.payment.paymentType === PAY_TYPE_CC.value ? CC_TYPES : DC_TYPES,
        target: 'payment',
        payment: props.payment,
    };

    const target = props.payment.paymentType === PAY_TYPE_CC.value ? 'ccType' : 'dcType';

    const footer =
        <div>
            <Button label='Back'
                    icon="fa fa-chevron-left"
                    onClick={() => {
                        props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_AMOUNT});
                    }}/>
            <Button label='Next'
                    icon="fa fa-chevron-right"
                    onClick={() => {
                        props.onChange([{
                            owner: 'previousState',
                            value: PD_STATE_RECEIPT_CD_CARD
                        }, {owner: 'dialogState', value: PD_STATE_RECEIPT_NARRATIVE}]);
                    }}/>
            <Button label='Finish'
                    icon={ICON_OK}
                    disabled={!props.finishEnabled}
                    onClick={() => {
                        props.onFinish(props.payment);
                    }}/>
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.identity.id)}/>
        </div>;

    return (
        <Dialog style={{width: "50%"}}
                header={TB_RECEIPT_CD_CARD.header}
                footer={footer}
                visible={true}
                modal={true}
                resizable={false}
                onHide={e => props.onHideDialog(props.identity.id)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <label>{TB_RECEIPT_CD_CARD.text}</label>
                </div>

                {optionalAmountInfo(props)}

                <div className="p-col-3">
                    <label>Type</label>
                </div>
                <div className="p-col-9">
                    {dropDownPlain(payProps, 'cardTypesOptions', target, 'label', false, false)}
                </div>

                <div className="p-col-3">
                    <label>Transaction No.</label>
                </div>
                <div className="p-col-9">
                    {inputText(payProps, 'transactionNumber', '', false, false)}
                </div>
            </div>
        </Dialog>
    )
};