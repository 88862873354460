import React from "react";
import {
    bleederMarkerStyle,
    furcationLevelNone,
    furcationStyles,
    selectedNumberStyle,
    superatorMarkerStyle,
    UPPER
} from '../Model/Constants';

const textStyle = {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontSize: '6.3499999px',
    lineHeight: '125%',
    fontFamily: '"Courier New"',
    InkscapeFontSpecification: '"Courier New"',
    letterSpacing: '0px',
    wordSpacing: '0px',
    fill: '#000000',
    fillOpacity: 1,
    stroke: 'none',
    strokeWidth: '0.26458332px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeOpacity: 1
};

const toothStyle = {
    fill: '#fffff5',
    fillOpacity: 1,
    fillRule: 'evenodd',
    stroke: '#000000',
    strokeWidth: '0.26458332px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeOpacity: 0.10
};

export const mobility = (numberStyle, y, chart, level, showSelectedColumn, inputIndex) => {

    const content = [];
    let x = 12.644 + 5;

    const control = chart.mobility[level];

    for (let toothIndex = 0; toothIndex < 16; toothIndex++) {

        const style = toothIndex === inputIndex && showSelectedColumn ? selectedNumberStyle : numberStyle;

        content.push(
            <text id={`gingRec${toothIndex}`} y={y} x={x} style={style}>
                <tspan style={{strokeWidth: '0.3175px'}} y={y} x={x}
                       id="tspan7690-9-9-1">{control[toothIndex]}
                </tspan>
            </text>
        );
        x += 15;
    }
    return content;
};

export const gingivalRecession = (numberStyle, y, chart, level, showSelectedColumn, inputIndex, subInputIndex) => {

    const content = [];
    let x = 12.644;

    const control = chart.recession[level];

    for (let toothIndex = 0; toothIndex < 16; toothIndex++) {
        for (let subIndex = 0; subIndex < 3; subIndex++) {

            const style = toothIndex === inputIndex && subIndex === subInputIndex && showSelectedColumn ? selectedNumberStyle : numberStyle;

            content.push(
                <text id={`gingRec${x}`} y={y} x={x} style={style}>
                    <tspan style={{strokeWidth: '0.3175px'}} y={y} x={x}
                           id="tspan7690-9-9-1">{control[toothIndex][subIndex]}
                    </tspan>
                </text>
            );
            x += 5;
        }
    }
    return content;
};

export const gingivalRecessionLine = (lineStyle, level, control) => {

    let x = 12.644;
    let y = level === UPPER ? 43.7 : 36.5;

    let points = `${x}, ${y}`;
    for (let toothIndex = 0; toothIndex < 16; toothIndex++) {
        for (let subIndex = 0; subIndex < 3; subIndex++) {
            points += `, ${x},${level === UPPER ? y - control[toothIndex][subIndex] * 1.7 : control[toothIndex][subIndex] * 1.7 + y}`;
            x += 5;
        }
    }
    points += `, ${x - 2},${y}`;
    return <polyline points={points}
                     style={lineStyle}/>;
};

export const probingDepth = (numberStyle, y, chart, level, showSelectedColumn, inputIndex, subInputIndex) => {

    const content = [];
    let x = 12.644;

    const control = chart.pockets[level];

    for (let toothIndex = 0; toothIndex < 16; toothIndex++) {
        for (let subIndex = 0; subIndex < 3; subIndex++) {

            const style = toothIndex === inputIndex && subIndex === subInputIndex && showSelectedColumn ? selectedNumberStyle : numberStyle;

            content.push(
                <text id={`gingRec${x}`} y={y} x={x} style={style}>
                    <tspan style={{strokeWidth: '0.3175px'}} y={y} x={x}
                           id="tspan7690-9-9-1">{control[toothIndex][subIndex]}
                    </tspan>
                </text>
            );
            x += 5;
        }
    }
    return content;
};

export const probeDepthLine = (lineStyle, level, recession, pocket) => {

    let x = 12.644;
    let y = level === UPPER ? 43.7 : 36.5;

    let points = `${x}, ${y}`;
    for (let toothIndex = 0; toothIndex < 16; toothIndex++) {
        for (let subIndex = 0; subIndex < 3; subIndex++) {
            const _y = level === UPPER ? y - (recession[toothIndex][subIndex] + pocket[toothIndex][subIndex]) * 1.7 : (recession[toothIndex][subIndex] + pocket[toothIndex][subIndex]) * 1.7 + y;
            points += `, ${x},${_y}`;
            x += 5;
        }
    }
    points += `, ${x - 2},${y}`;
    return <polyline points={points}
                     style={lineStyle}/>;
};

const molarUpper = (coords) => {

    return (
        <path id="path-2-ur8"
              d={`m ${coords[0]},${coords[1]} h 2.758116 v 14.484411 h 1.379057 V ${coords[1]} h 2.702952 v 14.484411 h 1.323895 V ${coords[1]} h 2.813279 v 13.665041 l 1.379058,0.81937 v 9.251004 H ${coords[0] - 1.38} v -9.251004 l 1.37906,-0.77972 z`}
              style={toothStyle}/>
    )
};

const incisorUpper = (coords) => {

    return (
        <path id="path-2-ur1"
              d={`m ${coords[0] - 1.38},${coords[1]} v -9.237114 l 4.1033,-1.3089 V ${coords[2]} h 5.5148 v 13.184581 l 4.11732,1.3463 v 9.202454 z`}
              style={toothStyle}/>
    )
};

const preMolarUpper = (coords) => {

    return (
        <path id="path-2-ur1"
              d={`m ${coords[0] - 1.38},${coords[1]} v -9.237114 l 4.1033,-1.3089 V ${coords[2]} h 5.5148 v 13.184581 l 4.11732,1.3463 v 9.202454 z`}
              style={toothStyle}/>
    )
};
const canineUpper = (coords) => {

    return (
        <path id="path-2-ur4"
              d={`m ${coords[0] - 1.38},${coords[1]} v -9.224224 l 1.35937,-0.71019 V ${coords[2]} h 4.119306 v 14.520591 h 2.741859 V ${coords[2]} h 4.116743 v 13.775941 l 1.398138,0.76422 v 9.195054 z`}
              style={toothStyle}/>
    )
};

const incisorLower = (coords) => {

    return (
        <path id="path-1-ll4"
              d={`m ${coords[0] - (2 * 1.38)},${coords[1]} v 9.23712 l -4.10329,1.3089 v 13.18938 h -5.51482 v -13.18458 l -4.1173,-1.34631 v -9.20245 z`}
              style={toothStyle}/>
    )
};

const canineLower = (coords) => {

    return (
        <path id="path-1-ll4"
              d={`m ${coords[0] - (2 * 1.38)},${coords[1]} v 9.23712 l -4.10329,1.3089 v 13.18938 h -5.51482 v -13.18458 l -4.1173,-1.34631 v -9.20245 z`}
              style={toothStyle}/>
    )
};

const preMolarLower = (coords) => {

    return (
        <path id="path-1-ll5"
              d={`m ${coords[0] - (2 * 1.38)},${coords[1]} v 9.23712 l -4.10329,1.3089 v 13.18938 h -5.51482 v -13.18458 l -4.1173,-1.34631 v -9.20245 z`}
              style={toothStyle}/>
    )
};

const molarLower = (coords) => {

    return (
        <path id="path-1-ll8"
              d={`m ${coords[0] - 1.38},${coords[1]} v 9.22422 l 1.35936,0.71019 v 13.76048 h 4.11931 v -14.52059 h 2.74186 v 14.54016 h 4.11675 v -13.77594 l 1.39814,-0.76422 v -9.19506 z`}
              style={toothStyle}/>
    )
};

const teethVectors = [
    [[13.51, 15.13], [28.51, 15.13], [43.51, 15.13], [58.51, 38.87, 15.13], [73.51, 38.87, 15.13], [88.51, 38.87, 15.13], [103.51, 38.87, 15.13], [118.51, 38.87, 15.13], [133.51, 38.87, 15.13], [148.51, 38.87, 15.13], [163.51, 38.87, 15.13], [178.51, 38.87, 15.13], [193.51, 38.87, 15.13], [208.51, 15.13], [223.51, 15.13], [238.51, 15.13]],
    [[13.51, 73.22], [28.51, 73.22], [43.51, 73.22], [58.51, 96.96, 73.22], [73.51, 96.96, 73.22], [88.51, 96.96, 73.22], [103.51, 96.96, 73.22], [118.51, 96.96, 73.22], [133.51, 96.96, 73.22], [148.51, 96.96, 73.22], [163.51, 96.96, 73.22], [178.51, 96.96, 73.22], [193.51, 96.96, 73.22], [208.51, 73.22], [223.51, 73.22], [238.51, 73.22]],
    [[13.51, 141.96], [28.51, 141.96], [43.51, 141.96], [73.51, 141.96], [88.51, 141.96], [103.51, 141.96], [118.51, 141.96], [133.51, 141.96], [148.51, 141.96], [163.51, 141.96], [178.51, 141.96], [193.51, 141.96], [208.51, 141.96], [208.51, 141.96], [223.51, 141.96], [238.51, 141.96]],
    [[13.51, 199.55], [28.51, 199.55], [43.51, 199.55], [73.51, 199.55], [88.51, 199.55], [103.51, 199.55], [118.51, 199.55], [133.51, 199.55], [148.51, 199.55], [163.51, 199.55], [178.51, 199.55], [193.51, 199.55], [208.51, 199.55], [208.51, 199.55], [223.51, 199.55], [238.51, 199.55]],
];

export const teethShapes = () => {

    const content = [];

    for (let index = 0; index < 2; index++) {
        content.push(molarUpper(teethVectors[index][0])); // UR8
        content.push(molarUpper(teethVectors[index][1])); // UR7
        content.push(molarUpper(teethVectors[index][2])); // UR6
        content.push(preMolarUpper(teethVectors[index][3])); // UR5
        content.push(canineUpper(teethVectors[index][4])); // UR4
        content.push(incisorUpper(teethVectors[index][5])); // UR3
        content.push(incisorUpper(teethVectors[index][6])); // UR2
        content.push(incisorUpper(teethVectors[index][7])); // UR1
        content.push(incisorUpper(teethVectors[index][8])); // LL1
        content.push(incisorUpper(teethVectors[index][9])); // LL2
        content.push(incisorUpper(teethVectors[index][10])); // LL3
        content.push(canineUpper(teethVectors[index][11])); // LL4
        content.push(preMolarUpper(teethVectors[index][12])); // LL5
        content.push(molarUpper(teethVectors[index][13])); // LL6
        content.push(molarUpper(teethVectors[index][14])); // LL7
        content.push(molarUpper(teethVectors[index][15])); // LL8
    }

    for (let index = 2; index < 4; index++) {
        content.push(molarLower(teethVectors[index][0])); // LR8
        content.push(molarLower(teethVectors[index][1])); // LR7
        content.push(molarLower(teethVectors[index][2])); // LR6
        content.push(preMolarLower(teethVectors[index][3])); // LR5
        content.push(canineLower(teethVectors[index][4])); // LR4
        content.push(incisorLower(teethVectors[index][5])); // LR3
        content.push(incisorLower(teethVectors[index][6])); // LR2
        content.push(incisorLower(teethVectors[index][7])); // LR1
        content.push(incisorLower(teethVectors[index][8])); // LL1
        content.push(incisorLower(teethVectors[index][9])); // LL2
        content.push(incisorLower(teethVectors[index][10])); // LL3
        content.push(canineLower(teethVectors[index][11])); // LL4
        content.push(preMolarLower(teethVectors[index][12])); // LL5
        content.push(molarLower(teethVectors[index][13])); // LL6
        content.push(molarLower(teethVectors[index][14])); // LL7
        content.push(molarLower(teethVectors[index][15])); // LL8
    }
    return content;
};

export const teethNumbering = () => {

    const content = [];

    const _y = [34.92, 93.02, 148.35, 206.55];
    const numbers = ['R8', 'R7', 'R6', 'R5', 'R4', 'R3', 'R2', 'R1', 'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7', 'L8'];
    let x = 15.33;

    for (let index = 0; index < 16; index++) {
        content.push(
            <text id="text4203" y={_y[0]} x={x} style={textStyle}>
                <tspan style={{fontSize: '4.23333311px', strokeWidth: '0.26458332px'}} y={_y[0]}
                       x={x} id="tspan4201">{`U${numbers[index]}`}
                </tspan>
            </text>
        );
        content.push(
            <text id="text4203" y={_y[1]} x={x} style={textStyle}>
                <tspan style={{fontSize: '4.23333311px', strokeWidth: '0.26458332px'}} y={_y[1]}
                       x={x} id="tspan4201">{`U${numbers[index]}`}
                </tspan>
            </text>
        );
        content.push(
            <text id="text4203" y={_y[2]} x={x} style={textStyle}>
                <tspan style={{fontSize: '4.23333311px', strokeWidth: '0.26458332px'}} y={_y[2]}
                       x={x} id="tspan4201">{`L${numbers[index]}`}
                </tspan>
            </text>
        );
        content.push(
            <text id="text4203" y={_y[3]} x={x} style={textStyle}>
                <tspan style={{fontSize: '4.23333311px', strokeWidth: '0.26458332px'}} y={_y[3]}
                       x={x} id="tspan4201">{`L${numbers[index]}`}
                </tspan>
            </text>
        );
        x += 15;
    }
    return content;
};

export const graphGridUpper = (graphLineStyle, y, y1) => {

    const content = [];

    let x1 = 26.51;
    let _y1 = y1;

    for (let index = 0; index < 15; index++) { // tooth separating vertical lines
        content.push(
            <path id={`graphGrid2${_y1}`} d={`m ${x1},${_y1} c 0,-54.0422137 0,-54.0422137 0,-54.0422137`}
                  style={graphLineStyle}/>
        );
        x1 += 15;
    }

    let x = 10.2;
    let _y = y;

    for (let index = 0; index < 5; index++) { // horizontal lines separating the number rows
        content.push(
            <path style={graphLineStyle} d={`m ${x},${_y} h 242.4252 v 0 0`} id={`graphGrid${_y}`}/>
        );
        _y += 3.4;
    }

    _y += 2.4;

    for (let index = 0; index < 11; index++) { // horizontal lines acting as marker grid for recession and pocket depth lines
        content.push(
            <path style={graphLineStyle} d={`m ${x},${_y} h 242.4252 v 0 0`} id={`graphGrid${_y}`}/>
        );
        _y += 1.7;
    }
    return content;
};

export const graphGridLower = (graphLineStyle, y, y1) => {

    const content = [];

    let x1 = 26.51;
    let _y1 = y1;

    for (let index = 0; index < 15; index++) { // tooth separating vertical lines
        content.push(
            <path id={`graphGrid2${_y1}`} d={`m ${x1},${_y1} c 0,-54.0422137 0,-54.0422137 0,-54.0422137`}
                  style={graphLineStyle}/>
        );
        x1 += 15;
    }

    let x = 10.2;
    let _y = y;

    for (let index = 0; index < 5; index++) { // horizontal lines separating the number rows
        content.push(
            <path style={graphLineStyle} d={`m ${x},${_y} h 242.4252 v 0 0`} id={`graphGrid${_y}`}/>
        );
        _y += 3.4;
    }

    _y += 12.5;

    for (let index = 0; index < 11; index++) { // horizontal lines acting as marker grid for recession and pocket depth lines
        content.push(
            <path style={graphLineStyle} d={`m ${x},${_y} h 242.4252 v 0 0`} id={`graphGrid${_y}`}/>
        );
        _y += 1.7;
    }
    return content;
};

export const block1 = (graphLineStyle) => {

    const content = [];
    let y = 17.2;
    let x = 16.476;

    for (let index = 0; index < 47; index++) { // vertical lines searating the number columns
        content.push(
            <path id={`block1_${index}`} d={`M ${x},${y} V 7.2`} style={graphLineStyle}/>
        );
        x += 5;
    }
    return content;
};

export const doubleFurcationMarker = (lineStyle, y) => {

    const offsets = [33.59, 68.11, 84.17, 115.48, 131.54, 163.66, 226.28, 257.59, 557.07, 588.38, 651.00, 654.42, 683.12, 698.38, 730.50, 745.39, 776.33];

    const content = [];
    let _y = y + 10;

    for (let index = 0; index <= 16; index++) {

        if (index === 0 || index === 16) {
            content.push(
                <path style={lineStyle}
                      d={`m ${offsets[index]},${y} v -4.41587 h 8.831739 8.831739 v 4.41587 4.41587 h -8.831739 -8.831739 z`}
                      id={`path11815`} transform="scale(0.3175)"/>
            );
        } else {
            content.push(
                <path style={lineStyle}
                      d={`m ${offsets[index]},${y} v -4.41587 h 7.225969 7.225968 v 4.41587 4.41587 h -7.225968 -7.225969 z`}
                      id={`path11815`} transform="scale(0.3175)"/>
            );
        }
        if (index === 0 || index === 16) {
            content.push(
                <path style={lineStyle}
                      d={`m ${offsets[index]},${_y} v -4.41587 h 8.831739 8.831739 v 4.41587 4.41587 h -8.831739 -8.831739 z`}
                      id={`path11817`} transform="scale(0.3175)"/>
            );
        } else {
            content.push(
                <path style={lineStyle}
                      d={`m ${offsets[index]},${_y} v -4.41587 h 7.225969 7.225968 v 4.41587 4.41587 h -7.225968 -7.225969 z`}
                      id={`path11817`} transform="scale(0.3175)"/>
            );
        }
    }
    return content;
};

export const doubleFurcation = (lineStyle, y, chart, level) => {

    const content = [];
    let furcationx = 15.705239;

    for (let index = 0; index < 16; index++) {

        const furcationValueLeft = chart.furcation[level][index][0]; // always index 0 as this is a double furcation, 1 if single
        let furcationStyleLeft = null;

        if (furcationValueLeft !== furcationLevelNone) {
            furcationStyleLeft = furcationStyles[furcationValueLeft];

            content.push(
                <path d={`m ${furcationx}, ${y} -1.766234,3.0592 -1.766234,-3.0592 z`}
                      id="furc0"
                      style={furcationStyleLeft}/>
            );
        }
        furcationx += 10;

        const furcationValueRight = chart.furcation[level][index][2]; // always index 2 as this is a double furcation, 1 if single
        let furcationStyleRight = null;

        if (furcationValueRight !== furcationLevelNone) {
            furcationStyleRight = furcationStyles[furcationValueRight];

            content.push(
                <path d={`m ${furcationx}, ${y} -1.766234,3.0592 -1.766234,-3.0592 z`}
                      id="furc0"
                      style={furcationStyleRight}/>
            );
        }
        furcationx += 5;
    }
    return content;
};

export const singleFurcationMarker = (lineStyle, y) => {

    const offsets = [16.65, 63.85, 111.05, 630.25, 677.45, 724.65];

    const content = [];
    let _y = y + 10;

    for (let index = 0; index <= 5; index++) {

        content.push(
            <path style={lineStyle}
                  d={`m ${offsets[index] + 36},${y} v -4.41587 h 7.225969 7.225968 v 4.41587 4.41587 h -7.225968 -7.225969 z`}
                  id={`path11815`} transform="scale(0.3175)"/>
        );
        content.push(
            <path style={lineStyle}
                  d={`m ${offsets[index] + 36},${_y} v -4.41587 h 7.225969 7.225968 v 4.41587 4.41587 h -7.225968 -7.225969 z`}
                  id={`path11817`} transform="scale(0.3175)"/>
        );
    }
    return content;
};

export const singleFurcation = (lineStyle, y, chart, level) => {

    const content = [];
    let furcationx = 20.705239;

    for (let index = 0; index < 16; index++) {

        const furcationValue = chart.furcation[level][index][1]; // always index 1 as this is a single furcation, 0 - 2 if double
        let furcationStyle = null;

        if (furcationValue !== furcationLevelNone) {
            furcationStyle = furcationStyles[furcationValue];

            content.push(
                <path d={`m ${furcationx}, ${y} -1.766234,3.0592 -1.766234,-3.0592 z`}
                      id="furc0"
                      style={furcationStyle}/>
            );
        }
        furcationx += 15;
    }
    return content;
};

export const bleederSuppurators = (y, chart, level) => {

    const content = [];

    let bleedersx = 13.216123 + 0.5;
    let suppuratorsx = 10.549973 + 0.5;

    for (let index = 0; index < 16; index++) {
        for (let subIndex = 0; subIndex < 3; subIndex++) {

            if (chart.bleeders[level][index][subIndex] !== 0) {
                content.push(
                    <path
                        style={bleederMarkerStyle}
                        d={`m ${bleedersx},${y - 20} v -1.53215 h 1.547374 1.547374 v 1.53215 1.53215 h -1.547374 -1.547374 z`}
                        id="path16231-0-9"/>
                );
            }
            if (chart.suppurators[level][index][subIndex] !== 0) {
                content.push(
                    <path
                        style={superatorMarkerStyle}
                        d={`m ${suppuratorsx},${y - 20} v -1.53215 h 1.547374 1.547374 v 1.53215 1.53215 h -1.547374 -1.547374 z`}
                        id="path16231-0"/>
                );
            }
            bleedersx += 5;
            suppuratorsx += 5;
        }
    }
    return content;
};

const quadStyle = {
    fill: '#ffffff',
    fillOpacity: 1,
    fillRule: 'evenodd',
    stroke: '#000000',
    strokeWidth: '0.26531196px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeOpacity: 0.2
};

const COL_BLEED = '#FF0000';
const COL_SUPER = '#c9ff22';
const COL_TOOTH = '#FFFFF5';

const quadTooth = (x, y, control, upperOrLower, index, bleeding, callback) => {

    const _quadStyle0 = {...quadStyle};
    const _quadStyle1 = {...quadStyle};
    const _quadStyle2 = {...quadStyle};
    const _quadStyle3 = {...quadStyle};

    _quadStyle0.fill = control[upperOrLower][index][0] ? (bleeding ? COL_BLEED : COL_SUPER) : COL_TOOTH;
    _quadStyle1.fill = control[upperOrLower][index][1] ? (bleeding ? COL_BLEED : COL_SUPER) : COL_TOOTH;
    _quadStyle2.fill = control[upperOrLower][index][2] ? (bleeding ? COL_BLEED : COL_SUPER) : COL_TOOTH;
    _quadStyle3.fill = control[upperOrLower][index][3] ? (bleeding ? COL_BLEED : COL_SUPER) : COL_TOOTH;

    return (
        <g style={{fill: '#fffff5', fillOpacity: 1, strokeWidth: '1.00275385'}} id="g1144-2-3"
           transform={`matrix(0.99436865,0,0,1.0001474,${x},${y})`}
        >
            <path id="path1062-7" d="M 0.31758245,229.55369 H 13.677701 l -6.7067798,6.66485 z" style={_quadStyle0}
                  onClick={(event) => {
                      callback(bleeding, upperOrLower, index, 0);
                  }}
            />
            <path id="path1062-4-61" d="M 13.624593,229.55369 V 242.9138 L 6.9597437,236.20702 Z" style={_quadStyle1}
                  onClick={(event) => {
                      callback(bleeding, upperOrLower, index, 1);
                  }}/>
            <path id="path1062-0-7-2" d="M 13.601905,242.86035 H 0.2417864 l 6.7067798,-6.66485 z" style={_quadStyle2}
                  onClick={(event) => {
                      callback(bleeding, upperOrLower, index, 2);
                  }}/>
            <path id="path1062-4-6-9-74" d="m 0.2948944,242.86035 v -13.36011 l 6.6648493,6.70678 z" style={_quadStyle3}
                  onClick={(event) => {
                      callback(bleeding, upperOrLower, index, 3);
                  }}
            />
        </g>
    )
};

export const quadTeeth = (control, upperOrLower, bleeding, callback) => {

    const y = bleeding ? (upperOrLower === 0 ? 8.73 : 23.73) : (upperOrLower === 0 ? 40.73 : 55.73);

    const content = [];
    let x = 12.08;

    for (let index = 0; index < 16; index++) {
        content.push(quadTooth(x, y, control, upperOrLower, index, bleeding, callback));
        x += 15;
    }
    return content;
};

