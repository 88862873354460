import React from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {HM_ClaimSchedDetails} from "../../../../Constants";
import {ICON_OK} from "../../../../icons";
import {Panel} from "primereact/panel";
import {EDI_RemovalNarrativeNotSub, EDI_ScheduleDetails1, EDI_ScheduleDetails2} from "../Constants";

export const ClaimScheduleDetails = (props) => {

    const footer = (
        <div>
            <Button label="OK"
                    icon={ICON_OK}
                    onClick={() => {
                        props.onHideDialog(HM_ClaimSchedDetails.id)
                    }}/>
        </div>
    );

    const header = props.selectedClaim.claimReference === undefined || props.selectedClaim.claimReference === null? EDI_RemovalNarrativeNotSub : `${EDI_ScheduleDetails2} ${props.selectedClaim.claimReference}`;

    let details = "";

    props.selectedClaim.spComments.forEach(comment => details+=(comment+'\r\n'));

    return (
        <Dialog header={EDI_ScheduleDetails1}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(HM_ClaimSchedDetails.id)
                }}>
            <Panel header={header}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        {inputTextArea({target: 'comments', comments: {comments: details}}, 'comments', 5, -1, true, false)}
                    </div>
                </div>
            </Panel>
        </Dialog>
    )
};
