import React from 'react';
import moment from 'moment';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {HM_AddPerioChart, HM_DeletePerioChart, HM_EditPerioChart} from "../../../Constants";


const dateTemplate = (chartedOn) => {

    return moment(chartedOn).format('ddd, DD MMM YYYY');
};

const byTemplate = (chartedBy) => {

    return `${chartedBy.title.abbreviation} ${chartedBy.firstName} ${chartedBy.lastName}`.trim();
};

export const PerioHistory = (props) => {

    let cm = null;

    const items = [
        {
            label: HM_EditPerioChart.header,
            icon: HM_EditPerioChart.icon,
            command: (e) => props.toolbarCallbacks[HM_EditPerioChart.id](e)
        },
        {label: HM_DeletePerioChart.header, icon: HM_DeletePerioChart.icon, command: (e) => props.onDeleteAssessment()},
    ];

    const header = <div className='panel-header-centered-content'><label id='panel-header'>Charting History</label>
        <Button tooltipOptions={{position: 'right'}}
                tooltip={HM_AddPerioChart.header}
                icon={HM_AddPerioChart.icon}
                onClick={props.onAddAssessment}>
        </Button>
    </div>;

    return (
        <Panel header={header} id='assessmentHistory' className="p-panel-content-wrapper-expanded">

            <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

            <DataTable selectionMode="single"
                       className='p-datatable-gridlines'
                       style={{fontSize: 'small'}}
                       value={props.list}
                       paginator={true}
                       rows={5}
                       rowsPerPageOptions={[5, 10, 20]}
                       onSelectionChange={(e) => props.onSelectionChanged(e.value)}
                       selection={props.chart}
                       contextMenuSelection={props.chart}
                       onContextMenuSelectionChange={e => props.onSelectionChanged(e.value)}
                       onContextMenu={e => {
                           cm.show(e.originalEvent);
                       }}
            >
                <Column body={(row) => dateTemplate(row.chartedOn)} header="Date" style={{width: '40%'}}/>
                <Column body={(row) => byTemplate(row.chartedBy)} header="By" style={{width: '60%'}}/>
            </DataTable>
        </Panel>
    )
};
