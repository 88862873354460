import * as Actions from "./index";
import {ac} from '../index'
import {ICON_EDIT} from "../icons";

const baseURL = 'Reminders';

export const RES_getRemindersDue = {
    GET: {
        url: `${baseURL}/remindersDue`,
        request: Actions.REQUEST_REMINDERS_DUE,
        receive: Actions.RECEIVE_REMINDERS_DUE
    },
    CLEAR: {action: Actions.CLEAR_REMINDERS_DUE},
    SEND_EMAIL: {
        url: `${baseURL}/sendEmail`,
        action: Actions.SEND_REMINDERS_DUE
    },
    SEND_EMAIL_GROUP: {
        url: `${baseURL}/sendEmailGroup`,
        action: Actions.SEND_REMINDERS_DUE
    },
    SEND_EMAIL_JOINT: {
        url: `${baseURL}/sendEmailJoint`,
        action: Actions.SEND_REMINDERS_DUE
    },
    SEND_SMS: {
        url: `${baseURL}/sendSMS`,
        action: Actions.SEND_REMINDERS_DUE
    },
    SEND_SMS_GROUP: {
        url: `${baseURL}/sendSMSGroup`,
        action: Actions.SEND_REMINDERS_DUE
    },
    SEND_SMS_JOINT: {
        url: `${baseURL}/sendSMSJoint`,
        action: Actions.SEND_REMINDERS_DUE
    },
    SEND_APPOINTMENT_SMS: {
        url: `${baseURL}/sendSMSAppointment`,
        action: Actions.SEND_REMINDERS_DUE
    },
    SEND_APPOINTMENT_EMAIL: {
        url: `${baseURL}/sendEmailAppointment`,
        action: Actions.SEND_REMINDERS_DUE
    },
    CREDIT_LIMIT: {
        url: '/Housekeeping/checkCreditLimit',
        request: Actions.REQUEST_CREDIT_LIMIT,
        receive: Actions.RECEIVE_CREDIT_LIMIT
    },
    CLOSE: {
        url: `${baseURL}/reminder`,
        action: Actions.REMINDER_CLOSE,
    }
};

export const RES_PatientReminder = {
    UPDATE: {action: Actions.PATIENT_REMINDER_DETAILS, url: `${baseURL}/updatePatientReminderDetails`,}
};

export const RES_getReminders = {
    GET: {url: `${baseURL}/reminders`, request: Actions.REQUEST_REMINDERS, receive: Actions.RECEIVE_REMINDERS}
};

export const HM_RemindersIndividual = {
    id: 'RemindersIndividual',
    label: '',
    header: 'Individual Reminders',
    message: '',
    icon: ''
};
export const HM_RemindersGroupJoint = {
    id: 'RemindersGroupJoint',
    label: '',
    header: 'Group / Joint Reminders',
    message: '',
    icon: ''
};

export const HM_PatientsReminders = {
    id: 'PatientReminders',
    label: "Show Patient's Reminders",
    header: '',
    message: '',
    icon: 'fas fa-info'
};

export const HM_ReminderSend = {
    id: 'HM_ReminderSend',
    label: 'Send This Reminder',
    header: 'Send Reminder',
    message: 'Are you sure you want to send this reminder?',
    icon: 'fas fa-mail-bulk'
};

export const HM_ReminderClose = {
    id: 'ReminderClose',
    label: 'Close This Reminder',
    header: 'Close Reminder',
    message: 'Are you sure you want to close this reminder?',
    icon: 'far fa-times-circle'
};

export const HM_ReminderSendJG = {
    id: 'HM_ReminderSendJG',
    label: 'Send This Reminder',
    header: 'Send Reminder',
    message: 'Are you sure you want to send this reminder?',
    icon: 'fas fa-mail-bulk'
};

export const HM_ReminderCloseJG = {
    id: 'ReminderCloseJG',
    label: 'Close This Reminder',
    header: 'Close Reminder',
    message: 'Are you sure you want to close this reminder?',
    icon: 'far fa-times-circle'
};

export const HM_RemindersIndividualActionSendElectronic = {
    id: 'HM_RemindersIndividualActionSendElectronic',
    label: 'Send Email / SMS Reminder(s)',
    header: 'Send Individual Reminder',
    message: 'Are you sure you want to send these individual reminders?',
    icon: 'fas fa-mail-bulk'
};

export const HM_RemindersGJActionSendElectronic = {
    id: 'HM_RemindersGJActionSendElectronic',
    label: 'Send Email / SMS Reminder(s)',
    header: 'Send Group / Joint Reminder(s)',
    message: 'Are you sure you want to send these group / joint reminders?',
    icon: 'fas fa-mail-bulk'
};

export const HM_ReminderCommunicationType = {
    id: 'HM_ReminderCommunicationType',
    label: 'Set the Communication Type',
    header: 'Communication Type',
    message: 'Are you sure you want to set this reminder communication type?',
    icon: ICON_EDIT
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params,});
    }
};

export const closeReminder = ({url, action}, params) => {

    return (dispatch) => {

        ac.fetchClient().delete(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: action, payload: res.data, ...params})
            });
    }
};

export const sendReminder = ({url, action}, sendList) => {

    return (dispatch) => {

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, sendList)
            .then(res => {

                dispatch({type: action, payload: res.data, sendList})
            });
    }
};

export const updatePatientReminderDetails = ({url, action}, dao) => {

    const {patientId, appointmentId, homeTelephone, workTelephone, otherTelephone, mobile, email, reminderMethod} = dao;

    const details = {
        patientId, appointmentId, homeTelephone, workTelephone, otherTelephone, mobile, email, reminderMethod,
    };

    const summaryData = dao.summaryData;

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...details}})
            .then(res => {

                dispatch({type: action, payload: res.data, details, summaryData})
            });
    }
};
