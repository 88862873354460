import React, {Component} from 'react';
import * as TC from "../Model/Constants";
import {AdultIncisor} from "./AdultIncisor";
import {AdultMolar} from "./AdultMolar";
import {AdultPreMolar} from "./AdultPreMolar";
import {AdultPreMolarBicusp} from "./AdultPreMolarBicusp";
import {getRandomKey} from "../Utils/general";

export class ToothRoot extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {

        switch(this.props.data.type) {
            case TC.AdultIncisor:
                return <AdultIncisor cm={this.props.cm}
                                     key={getRandomKey()}
                                     data={this.props.data}
                                     onClick={this.props.onClick}
                                     setCurrentItem={this.props.setCurrentItem}
                />;
            case TC.AdultMolar:
                return <AdultMolar cm={this.props.cm}
                                   key={getRandomKey()}
                                   data={this.props.data}
                                   onClick={this.props.onClick}
                                   setCurrentItem={this.props.setCurrentItem}
                />;
            case TC.AdultPremolar:
                return <AdultPreMolar cm={this.props.cm}
                                      key={getRandomKey()}
                                      data={this.props.data}
                                      onClick={this.props.onClick}
                                      setCurrentItem={this.props.setCurrentItem}
                />;
            case TC.AdultPremolarBicusp:
                return <AdultPreMolarBicusp cm={this.props.cm}
                                            key={getRandomKey()}
                                            data={this.props.data}
                                            onClick={this.props.onClick}
                                            setCurrentItem={this.props.setCurrentItem}
                />;
            default:
                return null;
        }
    }
}
