import React from 'react';
import {BaseComponent} from "../../../BaseComponent";
import {showPaymentPlanHistoryPage} from "../../PatientDetails/Utils";
import _ from "lodash";
import moment from "moment";

import {
    TB_PATIENT_PPLANS,
    TB_PATIENT_PPLANS_RESUME,
    TB_PATIENT_PPLANS_SUSPEND,
    TB_PATIENT_PPLANS_WRITEOFF
} from "../../PatientDetails/Constants";
import {SEC_PP_ACTIVE, SEC_PP_FINISHED, SEC_PP_SUSPENDED, SEC_PP_WRITTEN_OFF} from "../Constants";
import {ShowMessageDialog} from "../../Diary/components/EventComponent";
import {
    HM_notImplemented,
    HM_RESUME_PAYMENT_PLAN,
    HM_SUSPEND_PAYMENT_PLAN,
    HM_WRITE_OFF_PAYMENT_PLAN
} from "../../../../Constants";
import ChangePlanStatus from "../../PaymentPlanning/dialogs/ChangePlanStatus";

export const PPCOLS = ["Patient Name", "First Instalment", "Plan Total", "Deposit", "No. Of Instalments", "No. Un-Issued", "Un-Issued Balance", "Suspended Until", "Write Off Date", "Narrative"];

export class PaymentPlansBase extends BaseComponent {

    constructor(props) {

        super(props);

        this.showDialogs = this.showDialogs.bind(this);
        this.onHideMenuEntry = this.onHideMenuEntry.bind(this);
        this.onShowDetails = this.onShowDetails.bind(this);

        this.onSuspend = this.onSuspend.bind(this);
        this.suspend = this.suspend.bind(this);

        this.onResume = this.onResume.bind(this);
        this.resume = this.resume.bind(this);

        this.onWriteOff = this.onWriteOff.bind(this);
        this.writeOff = this.writeOff.bind(this);

        this.menuItems = [];
    }

    buildItems() {

        switch (this.state.type) {
            case SEC_PP_ACTIVE:
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS.text, icon: TB_PATIENT_PPLANS.icon, command: (e) => {
                            this.toolbarCallbacks[TB_PATIENT_PPLANS.id](this.props, this.state.selectedPlan);
                        }
                    },
                );
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS_SUSPEND.text, icon: TB_PATIENT_PPLANS_SUSPEND.icon, command: (e) => {
                            this.toolbarCallbacks[TB_PATIENT_PPLANS_SUSPEND.id](this.props, this.state.selectedPlan);
                        }
                    },
                );
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS_WRITEOFF.text, icon: TB_PATIENT_PPLANS_WRITEOFF.icon, command: (e) => {
                            this.toolbarCallbacks[TB_PATIENT_PPLANS_WRITEOFF.id](this.props, this.state.selectedPlan);
                        }
                    },
                )
                break;
            case SEC_PP_FINISHED:
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS.text, icon: TB_PATIENT_PPLANS.icon, command: (e) => {
                            this.toolbarCallbacks[TB_PATIENT_PPLANS.id](this.props, this.state.selectedPlan);
                        }
                    },
                );
                break;
            case SEC_PP_SUSPENDED:
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS.text, icon: TB_PATIENT_PPLANS.icon, command: (e) => {
                            this.toolbarCallbacks[TB_PATIENT_PPLANS.id](this.props, this.state.selectedPlan);
                        }
                    },
                );
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS_RESUME.text, icon: TB_PATIENT_PPLANS_RESUME.icon, command: (e) => {
                            this.toolbarCallbacks[TB_PATIENT_PPLANS_RESUME.id](this.props, this.state.selectedPlan);
                        }
                    },
                );
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS_WRITEOFF.text, icon: TB_PATIENT_PPLANS_WRITEOFF.icon, command: (e) => {
                            this.toolbarCallbacks[TB_PATIENT_PPLANS_WRITEOFF.id](this.props, this.state.selectedPlan);
                        }
                    },
                )
                break;
            case SEC_PP_WRITTEN_OFF:
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS.text, icon: TB_PATIENT_PPLANS.icon, command: (e) => {
                            this.toolbarCallbacks[TB_PATIENT_PPLANS.id](this.props, this.state.selectedPlan);
                        }
                    },
                )
                break;
            default:
                break;
        }
    }

    orderedPlans() {
        return _.orderBy(this.props.paymentPlans, (pp) => {
            return moment(pp.firstInstallment).format('YYYYMMDD');
        }, ['asc']);
    }

    onShowDetails(props, {patientId, patientName}) {

        const parts = patientName.split(' ');
        showPaymentPlanHistoryPage({
                props,
                id: patientId,
                firstName: parts[0],
                lastName: parts[1]
            }
        );
    }

    showDialogs() {

        if (this.state[HM_SUSPEND_PAYMENT_PLAN.id]) {

            return <ChangePlanStatus paymentPlan={this.state.selectedPlan}
                                     loginIdentity={this.props.loginIdentity}
                                     onOkDialog={this.suspend}
                                     onHideDialog={this.onHideMenuEntry}
                                     target={HM_SUSPEND_PAYMENT_PLAN}
            />
        } else if (this.state[HM_RESUME_PAYMENT_PLAN.id]) {

            return <ChangePlanStatus paymentPlan={this.state.selectedPlan}
                                     loginIdentity={this.props.loginIdentity}
                                     onOkDialog={this.resume}
                                     onHideDialog={this.onHideMenuEntry}
                                     target={HM_RESUME_PAYMENT_PLAN}
            />
        } else if (this.state[HM_WRITE_OFF_PAYMENT_PLAN.id]) {

            return <ChangePlanStatus paymentPlan={this.state.selectedPlan}
                                     loginIdentity={this.props.loginIdentity}
                                     onOkDialog={this.writeOff}
                                     onHideDialog={this.onHideMenuEntry}
                                     target={HM_WRITE_OFF_PAYMENT_PLAN}
            />
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onSuspend() {
        this.onShowMenuEntry({item: {target: HM_SUSPEND_PAYMENT_PLAN.id}});
    }

    suspend(plan) {

        this.onHideMenuEntry(HM_SUSPEND_PAYMENT_PLAN.id)
        this.props.suspend(plan);
    }

    onResume() {
        this.onShowMenuEntry({item: {target: HM_RESUME_PAYMENT_PLAN.id}});
    }

    resume(plan) {

        this.onHideMenuEntry(HM_RESUME_PAYMENT_PLAN.id)
        this.props.resume(plan);
    }

    onWriteOff() {
        this.onShowMenuEntry({item: {target: HM_WRITE_OFF_PAYMENT_PLAN.id}});
    }

    writeOff(plan) {

        this.onHideMenuEntry(HM_WRITE_OFF_PAYMENT_PLAN.id)
        this.props.writeOff(plan);
    }
}
