import React from 'react';
import _ from 'lodash';

import {Dropdown} from 'primereact/components/dropdown/Dropdown';

import {checkBox, checkBoxNHSKPI, dropDownPlain, inputText, spinner} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {_9501, _9502, _9504, _9505, _9506, _9507, _9509, _9511, _9512, _9513, _9515} from "../../../../NHSConstants";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {Button} from "primereact/components/button/Button";
import {HM_TreatmentPlanNote, PJ_STATUS_Usable} from "../../../../Constants";
import {Editor} from "primereact/editor";

const insertName = ({nhspatient}) => {

    if (nhspatient) {
        return (
            <React.Fragment>
                <div className="p-col-2">
                    <label htmlFor="patient.firstName">Patient</label>
                </div>
                <div className="p-col-1">
                    <i className="fa nhsLogo16"/>
                </div>
            </React.Fragment>
        )
    } else {
        return (
            <div className="p-col-3">
                <label htmlFor="patient.firstName">Patient</label>
            </div>
        )
    }
}

const insertRightHandSide = (patient, generalProps) => {

    let tabIndex = 0;

    const header = (
        <Button tooltip={HM_TreatmentPlanNote.header}
                tooltipOptions={{position: 'top'}}
                icon={HM_TreatmentPlanNote.icon}
                style={{
                    padding: '1.0em 1.0em',
                    marginRight: '10px',
                    width: '20px',
                    height: '30px',
                    background: '#1a91cf',
                    display: 'flex'
                }}
                onClick={(e) => {
                    generalProps.onShowMenuEntry({item: {target: HM_TreatmentPlanNote.id}})
                }}>
        </Button>
    );

    if (patient.nhspatient) {
        return (

            <TabView className="p-fluid p-col-6"
                     scrollable={true}
                     activeIndex={generalProps.activeIndex}
                     onTabChange={(e) => {
                         generalProps.onTabChange(e.index)
                     }}>
                <TabPanel header="General Notes"
                          id='generalNotes'
                          key={tabIndex += 1}
                >
                    <Editor className="p-fluid p-col-12"
                            value={generalProps.treatmentPlan.content}
                            readOnly={true}
                            style={{height: '200px'}}
                            headerTemplate={header}/>
                </TabPanel>
                <TabPanel header="NHS KPIs"
                          id='nhsKPIs'
                          key={tabIndex += 1}
                >
                    <div className="p-grid p-fluid form-group p-col-12">

                        {/*Basic Perio Exam*/}
                        <div className="p-col-4">
                            <label htmlFor="_9504">{_9504.text}</label>
                        </div>
                        <div className="p-col-2" id="_9504">
                            {checkBoxNHSKPI(generalProps, _9504, false, false)}
                        </div>
                        {/*Continuity of Care*/}
                        <div className="p-col-4">
                            <label htmlFor="_9515">{_9515.text}</label>
                        </div>
                        <div className="p-col-2" id="_9515">
                            {checkBoxNHSKPI(generalProps, _9515, false, false)}
                        </div>

                        {/*Dental Care Assessment*/}
                        <div className="p-col-4">
                            <label htmlFor="_9506">{_9506.text}</label>
                        </div>
                        <div className="p-col-2" id="_9506">
                            {checkBoxNHSKPI(generalProps, _9506, false, false)}
                        </div>
                        {/*Health Prom, smoking cessation*/}
                        <div className="p-col-4">
                            <label htmlFor="_9512">{_9512.text}</label>
                        </div>
                        <div className="p-col-2" id="_9512">
                            {checkBoxNHSKPI(generalProps, _9512, false, false)}
                        </div>

                        {/*Health Prom, smoking status*/}
                        <div className="p-col-4">
                            <label htmlFor="_9511">{_9511.text}</label>
                        </div>
                        <div className="p-col-2" id="_9511">
                            {checkBoxNHSKPI(generalProps, _9511, false, false)}
                        </div>
                        {/*New Patient*/}
                        <div className="p-col-4">
                            <label htmlFor="_9502">{_9502.text}</label>
                        </div>
                        <div className="p-col-2" id="_9502">
                            {checkBoxNHSKPI(generalProps, _9502, false, false)}
                        </div>

                        {/*Soft Tissue Assess*/}
                        <div className="p-col-4">
                            <label htmlFor="_9509">{_9509.text}</label>
                        </div>
                        <div className="p-col-2" id="_9509">
                            {checkBoxNHSKPI(generalProps, _9509, false, false)}
                        </div>
                        {/*Tooth Decay Assess*/}
                        <div className="p-col-4">
                            <label htmlFor="_9507">{_9507.text}</label>
                        </div>
                        <div className="p-col-2" id="_9507">
                            {checkBoxNHSKPI(generalProps, _9507, false, false)}
                        </div>

                        {/*Toothbrush advice*/}
                        <div className="p-col-4">
                            <label htmlFor="_9513">{_9513.text}</label>
                        </div>
                        <div className="p-col-2" id="_9513">
                            {checkBoxNHSKPI(generalProps, _9513, false, false)}
                        </div>
                        {/*Urgent Appointments*/}
                        <div className="p-col-4">
                            <label htmlFor="_9501">{_9501.text}</label>
                        </div>
                        <div className="p-col-2" id="_9501">
                            {checkBoxNHSKPI(generalProps, _9501, false, false)}
                        </div>

                        {/*Visible Plaque*/}
                        <div className="p-col-4">
                            <label htmlFor="_9505">{_9505.text}</label>
                        </div>
                        <div className="p-col-2" id="_9505">
                            {checkBoxNHSKPI(generalProps, _9505, false, false)}
                        </div>
                        <div className="p-col-6"/>

                        <div className="p-col-2">
                            <label htmlFor="nice">NICE Guidance (No of Months)</label>
                        </div>
                        <div className="p-col-2" id="_9504">
                            {spinner(generalProps, 'nice', 0, 24, false, false)}
                        </div>
                        <div className="p-col-2" id="_9505">
                            {checkBox(generalProps, 'bestPracticePrevention', '', false, false)}
                        </div>
                        <div className="p-col-6">
                            <label>Best Practice prevention according to Delivering Better Oral Health
                                offered</label>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        )
    } else {
        return (
            <TabView className="p-fluid p-col-6"
                     scrollable={true}
                     style={{paddingTop: '5px'}}
                     activeIndex={generalProps.activeIndex}
                     onTabChange={(e) => {
                         generalProps.onTabChange(e.index)
                     }}>
                <TabPanel header="General Notes"
                          id='generalNotes'
                          key={tabIndex += 1}
                >
                    <Editor className="p-fluid p-col-12"
                            value={generalProps.treatmentPlan.content}
                            readOnly={true}
                            style={{height: '200px'}}
                            headerTemplate={header}/>
                </TabPanel>
            </TabView>
        )
    }
}

export const General = (props) => {

    const {
        firstName,
        lastName,
        title,
        dateOfBirth,
        patientJourney,
        knownAs,
        alternateRef,
        email,
        mobile,
    } = props.treatmentPlan.patient;
    const patientName = knownAs.trim() === '' ? `${title.abbreviation} ${firstName} ${lastName}`.trim() : `${title.abbreviation} ${firstName} ${lastName} (${knownAs})`.trim();
    const dob = dateTemplate(dateOfBirth);

    const pjChangedOn = dateTemplate(patientJourney.changedOn);

    const patient = {name: patientName, dob, pjChangedOn, alternateRef, email, mobile};

    const stage = _.find(props.journeyStages, stage => stage.id === patientJourney.stage.id);

    const sortedStages = _.sortBy(_.filter(props.journeyStages, item => item.status === PJ_STATUS_Usable ), 'description');

    const generalProps = {
        statuses: props.statuses,
        onChange: props.onTreatmentPlanDetailsChange,
        target: 'treatmentPlan',
        treatmentPlan: props.treatmentPlan,
        activeIndex: props.activeIndex,
        onTabChange: props.onGeneralTabChange,
        onShowMenuEntry: props.onShowMenuEntry
    };

    const patientProps = {
        onChange: props.onTreatmentPlanDetailsChange,
        target: 'patient',
        patient: patient,
    };

    const nhsProps = {
        onChange: props.onTreatmentPlanDetailsChange,
        target: 'NHSRegistration',
        NHSRegistration: props.NHSRegistration,
    };

    return (
        <div className="p-grid p-fluid p-col-12">
            <div className="p-grid p-fluid form-group p-col-6">

                {/**/}

                {insertName(props.treatmentPlan.patient)}

                <div className="p-col-4">
                    {inputText(patientProps, 'name', '', true, true)}
                </div>
                <div className="p-col-1">
                    <label>DOB</label>
                </div>
                <div className="p-col-4">
                    {inputText(patientProps, 'dob', '', true, true)}
                </div>

                {/**/}

                <div className="p-col-3">
                    <label htmlFor="mobile">Mobile</label>
                </div>
                <div className="p-col-4">
                    {inputText(patientProps, 'mobile', '', true, false)}
                </div>
                <div className="p-col-1">
                    <label htmlFor="email">Email</label>
                </div>
                <div className="p-col-4">
                    {inputText(patientProps, 'email', '', true, false)}
                </div>

                {/**/}

                <div className="p-col-3">
                    <label htmlFor="title">Title</label>
                </div>
                <div className="p-col-9">
                    {inputText(generalProps, 'title', 'Title', false, true)}
                </div>

                {/**/}

                <div className="p-col-3">
                    <label htmlFor="status">Status</label>
                </div>
                <div className="p-col-4">
                    {dropDownPlain(generalProps, 'statuses', 'status', 'label', !props.planning, false)}
                </div>
                <div className="p-col-1">
                    <label>Alt.Ref.</label>
                </div>
                <div className="p-col-4">
                    {inputText(patientProps, 'alternateRef', '', false, false)}
                </div>

                {/**/}

                <div className="p-col-3">
                    <label>Patient Journey</label>
                </div>
                <div className="p-col-5">
                    <Dropdown value={stage}
                              options={sortedStages}
                              optionLabel='description'
                              onChange={(e) => {
                                  props.onTreatmentPlanDetailsChange({
                                      owner: `treatmentPlan.patient.patientJourney.stage`,
                                      value: e.value
                                  })
                              }}
                              filter={true}
                              filterPlaceholder='Patient Journey'
                              filterBy='description'
                    />

                </div>
                <div className="p-col-4">
                    {inputText(patientProps, 'pjChangedOn', '', true, true)}
                </div>

                {/**/}
            </div>
            {insertRightHandSide(props.treatmentPlan.patient, generalProps, nhsProps)}
        </div>
    )
};
