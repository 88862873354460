import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_NHS} from "../../../../actions/stateManagement";
import {deleteContract, getResource, RES_PREFERENCES_NHS} from "../../../../actions/preferences";
import _ from "lodash";
import {BaseComponent} from "../../../BaseComponent";
import {getPreferenceIds} from "../Utils";
import AddNHSContract from "../Dialogs/AddNHSContract";

import {ICON_DELETE, ICON_EDIT, ICON_PLUS} from "../../../../icons";

class ConnectedNHSRegistrationSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_NHS.id,

                contractsLoaded: false,
                contracts: [],
                first: 0,
                rows: 5,

                selectedContract: null,
                showAddContract: false,
                showEditContract: false,
                showDeleteContract: false,
            }
        }

        this.onSelectionChange = this.onSelectionChange.bind(this);

        this.addContract = this.addContract.bind(this);
        this.updateContract = this.updateContract.bind(this);

        this.onShowDialogs = this.onShowDialogs.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getAllNHSContracts();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PREFERENCES_NHS.GET.receive:

                    this.setState({contracts: this.props.contracts, contractsLoaded: true}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;

                case RES_PREFERENCES_NHS.SAVE.action:
                    break;

                default:
                    break;
            }
        }
    }

    onSelectionChange(selection) {

        this.setState({selectedContract: selection.value});
    }

    addContract(_newContract) {

        const newContract = {..._newContract};
        newContract.location = _.padStart(newContract.location, 6, '0');

        const newContracts = [...this.state.contracts];
        newContracts.unshift(newContract);

        this.setState({contracts: newContracts, showAddContract: false}, () => {

            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES_NHS.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            });

            this.props.setState(this.state.stateManagementId, {...this.state, ...{contracts: newContracts}});
        })
    }

    updateContract(_editedContract) {

        const editedContract = {..._editedContract};
        editedContract.location = _.padStart(editedContract.location, 6, '0');

        const editedContracts = [...this.state.contracts];

        const index = _.findIndex(editedContracts, (contract) => {
            return contract.id === editedContract.id;
        });

        editedContracts[index] = editedContract;

        this.setState({contracts: editedContracts, showEditContract: false}, () => {

            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES_NHS.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            });

            this.props.setState(this.state.stateManagementId, {...this.state, ...{contracts: editedContracts}});
        });
    }

    onShowDialogs() {

        if (this.state.showAddContract) {

            return (
                <AddNHSContract header='Create New Contract'
                                visible={true}
                                onHideDialog={this.onHideMenuEntry}
                                onOkDialog={this.addContract}
                                editing={false}
                />
            )
        } else if (this.state.showEditContract) {

            return (
                <AddNHSContract header='Edit Contract'
                                visible={true}
                                onHideDialog={this.onHideMenuEntry}
                                onOkDialog={this.updateContract}
                                editing={true}
                                item={this.state.selectedContract}
                />
            )
        } else if (this.state.showDeleteContract) {

            const footer = <div>
                <Button label="Yes" icon="fa fa-check" onClick={() => {
                    this.onDeleteContract();
                }}/>
                <Button label="No" icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry('showDeleteContract')
                }}/>
            </div>;

            return (

                <Dialog header="Delete Contract"
                        visible={this.state.showDeleteContract}
                        width="350px"
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({showDeleteContract: false})
                        }}>
                    Are you sure you want to delete this contract?
                </Dialog>
            )
        } else
            return null;
    }

    onDeleteContract() {

        this.props.deleteContract(this.state.selectedContract.id);

        this.setState({
            contracts: [],
            selectedContract: null,
            showDeleteContract: false,
        });
    }

    render() {

        if (!this.state.contractsLoaded) {
            return null;
        }


        const items = [
            {label: 'Edit Contract', icon: ICON_EDIT, command: this.onShowMenuEntry, target: 'showEditContract'},
            {label: 'Delete Contract', icon: ICON_DELETE, command: this.onShowMenuEntry, target: 'showDeleteContract'},
        ];

        const header = <div className='panel-header-centered-content'><label id='panel-header'>NHS Contracts</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip='Add Contract'
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: 'showAddContract'}})
                    }}>
            </Button>
        </div>;

        return (
            <div>
                <Panel header={header} style={{fontSize: 'small'}}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={this.state.contracts}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode='single'
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedContract}
                               onSelectionChange={this.onSelectionChange}
                               contextMenuSelection={this.state.selectedContract}
                               onContextMenuSelectionChange={e => this.setState({selectedContract: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field='description'
                                header='Name'
                                style={{width: '25%'}}/>
                        <Column field='siteNumber'
                                header='Site'
                                style={{width: '25%'}}/>
                        <Column field='location'
                                header='Location'
                                style={{width: '25%'}}/>
                        <Column field='contractNumber'
                                header='Contract No.'
                                style={{width: '25%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        contractsLoaded,
        contracts,
    } = getPreferenceIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        contractsLoaded,
        contracts,

        currentState: state.stateManagement[SM_PREFERENCES_NHS.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllNHSContracts: () => dispatch(getResource(RES_PREFERENCES_NHS.GET)),
        deleteContract: (id) => dispatch(deleteContract(id)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const NHSRegistrationSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedNHSRegistrationSection);

export default NHSRegistrationSection;
