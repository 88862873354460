import React from 'react';
import {connect} from "react-redux";
import {
    HELP_REP_PATIENT_JOURNEY,
    setState,
    SM_REPORTS_PATIENT_JOURNEY,
    SM_REPORTS_PATIENT_JOURNEY_MAIN,
    SM_REPORTS_PATIENT_JOURNEY_PDF,
    SM_REPORTS_PATIENT_JOURNEY_SS,
    stateRequest
} from "../../../actions/stateManagement";
import * as Sections from "./Constants";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import PatientJourneyMain from "./Sections/PatientJourney";
import PatientJourneySnapShot from "./Sections/PatientJourneySnapShot";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {RES_REPORT_PAT_JOURNEYS} from "../../../actions/reports";
import {TAB_CHANGE, TAB_EXIT} from "../Housekeeping/Constants";
import {TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_HELP} from "../../../icons";
import {TabBaseComponent} from "../../TabBaseComponent";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlateRight} from "../../Utils";

export class ConnectedPatientJourney extends TabBaseComponent {

    constructor(props) {
        super(props);
        this.state = {

            stateManagementId: SM_REPORTS_PATIENT_JOURNEY.id,

            canSave: {
                status: false,
                activeIndex: 0,
                source: {
                    id: SM_REPORTS_PATIENT_JOURNEY.id,
                    action: RES_REPORT_PAT_JOURNEYS.CLEAR.action,
                },
                activePlanTotal: 0.0,
                suspendedPlanTotal: 0.0,
                writtenOffPlanTotal: 0.0,
                finishedPlanTotal: 0.0,
            },
        };

        this.exitState = TAB_CHANGE;

        this.toolbarCallbacks = {
            [TB_SAVE_AND_EXIT.id]: this.exit,
        }
    }

    onExit = (key) => {

        this.exitState = TAB_EXIT;
        this.props.onCloseClick(key);
    }

    render() {

        this.tabStack = [];

        this.tabStack.push(
            {
                section: Sections.SEC_PJ_MAIN,
                content: <TabPanel key={Sections.SEC_PJ_MAIN}
                                   leftIcon={SM_REPORTS_PATIENT_JOURNEY.tabIcon}
                                   header={SM_REPORTS_PATIENT_JOURNEY.label}>
                    <ReportErrorBoundary>
                        <PatientJourneyMain onChange={this.onChange}
                                            toolbarCallbacks={this.toolbarCallbacks}
                                            onPCButtonClick={this.props.onPCButtonClick}
                                            onSectionCloseClick={this.onExit}
                                            id={SM_REPORTS_PATIENT_JOURNEY_MAIN.id}
                        />
                    </ReportErrorBoundary>
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                section: Sections.SEC_PJ_SNAP_SHOT,
                content: <TabPanel key={Sections.SEC_PJ_SNAP_SHOT}
                                   leftIcon={SM_REPORTS_PATIENT_JOURNEY_SS.tabIcon}
                                   header={SM_REPORTS_PATIENT_JOURNEY_SS.label}>
                    <ReportErrorBoundary>
                        <PatientJourneySnapShot onChange={this.onChange}
                                                toolbarCallbacks={this.toolbarCallbacks}
                                                onPCButtonClick={this.props.onPCButtonClick}
                                                onSectionCloseClick={this.onExit}
                        />
                    </ReportErrorBoundary>
                </TabPanel>
            }
        );

        let key = 1000;

        return (
            <div id="detailPanel">

                <Toolbar
                        right={<React.Fragment>
                            {tb_boilerPlateRight(() => window.open(HELP_REP_PATIENT_JOURNEY), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(() => this.onExit({key: this.state.stateManagementId}), TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                />

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_REPORTS_PATIENT_JOURNEY.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const PatientJourney = connect(mapStateToProps, mapDispatchToProps)(ConnectedPatientJourney);

export default PatientJourney;
