import React from 'react';
import {connect} from 'react-redux';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Dialog} from 'primereact/components/dialog/Dialog';

import * as Sections from "./Constants";

import * as Capabilities from '../../../components/Capabilities';
import AccountingSection from "./Sections/AccountingSection";
import ChartingSystemsSection from "./Sections/ChartingSystemsSection";
import DiarySection from "./Sections/DiarySection";
import DiaryColourSection from "./Sections/DiaryColourSection";
import EmailSection from "./Sections/EmailSection";
import PracticeDetailsSection from "./Sections/PracticeDetailsSection";
import PracticeHoursSection from "./Sections/PracticeHoursSection";
import NHSRegistrationSection from "./Sections/NHSRegistrationSection";
import RecallsRemindersSection from "./Sections/RecallsRemindersSection";
import EsendexSection from "./Sections/EsendexSection";
import {
    HELP_PREF_INDEXS,
    setState,
    SM_PREFERENCES,
    SM_PREFERENCES_ACS,
    SM_PREFERENCES_CRT,
    SM_PREFERENCES_DCS,
    SM_PREFERENCES_DIS,
    SM_PREFERENCES_EMAIL,
    SM_PREFERENCES_ESENDEX,
    SM_PREFERENCES_HOURS,
    SM_PREFERENCES_LOCATIONS,
    SM_PREFERENCES_NHS,
    SM_PREFERENCES_PDS,
    SM_PREFERENCES_RAR,
    SM_PREFERENCES_REPORTS, SM_PREFERENCES_XRAY_GATEWAY,
    stateRequest
} from "../../../actions/stateManagement";
import {TAB_EXIT, TAB_EXIT_SAVE} from "../Housekeeping/Constants";
import {TabBaseComponent} from "../../TabBaseComponent";
import {RES_PREFERENCES} from "../../../actions/preferences";
import PreferencesErrorBoundary, {getPreferenceIds} from "./Utils";
import {setObjectStore} from "../../../actions/objectStore";
import _ from "lodash";
import * as Actions from "../../../actions";
import {UPDATE_TAB} from "../../../actions";
import {HM_notImplemented, TB_SAVE, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_SAVE_DISABLED, ICON_SAVE_ENABLED} from "../../../icons";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import ReportsSection from "./Sections/ReportsSection";
import LocationsSection from "./Sections/LocationsSection";
import XRayGatewaySection from "./Sections/XRayGatewaySection";

class ConnectedPreferences extends TabBaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES.id,

                canSave: {status: false, activeIndex: 0, source: {action: RES_PREFERENCES.CLEAR.action}},
                showSaveDialog: false,
            }
        }

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onClose,

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidUpdate(prevProps, prevState, snapShot) {

        if (this.props !== prevProps) {

            switch (this.props.message.type) {

                case RES_PREFERENCES.SAVE.action:

                    const canSave = {...this.state.canSave};
                    canSave.status = false;

                    this.setState({canSave}, () => {
                        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onClose() {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true}, () => {
                this.props.setState(this.state.stateManagementId, this.state);
            })
        } else {
            this.onExit(false);
        }
    }

    onExit(save) {

        const source = {
            id: this.state.stateManagementId,
            action: save ? Actions.SAVE_CLEAR_PREFERENCES : Actions.CLEAR_PREFERENCES,
            saveState: true,
            saveObjects: true,
        };

        this.exitState = save ? TAB_EXIT_SAVE : TAB_EXIT;

        this.setState({showSaveDialog: false, canSave: {status: true, source}}, () => {
            this.props.onTabCloseClick({key: this.state.stateManagementId});
        });
    }

    onChange(event) {

        const source = {
            id: this.state.stateManagementId,
            action: RES_PREFERENCES.SAVE.action,
            saveState: true,
            saveObjects: true,
        };

        const newState = {...this.state};

        if (event === null) {
            this.setState({canSave: {status: true, source, activeIndex: newState.canSave.activeIndex}}, () => {
                this.props.setState(this.state.stateManagementId, this.state);
            });
        } else if (event.owner === UPDATE_TAB) {
            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});
            this.setState(newState, () => {
                this.props.onTabUpdate({key: SM_PREFERENCES.id}, true);
            });
        } else {
            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        }
    }

    onHelpRequest = () => {

        window.open(HELP_PREF_INDEXS[this.state.canSave.activeIndex]);
    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        const footer = <div>
            <Button label="Yes" icon="fa fa-check" onClick={() => {
                this.onExit(this.state.canSave.status);
            }}/>
            <Button label="No"
                    icon="fas fa-times"
                    onClick={() => this.onCancel(RES_PREFERENCES.CLEAR.action)}
            />
        </div>;

        this.filterSection(Capabilities.AID_PREF_ACCPREFIXES,
            {
                section: Sections.ACCOUNTING,
                content: <TabPanel key={Sections.ACCOUNTING}
                                   header={SM_PREFERENCES_ACS.label}>
                    <PreferencesErrorBoundary>
                        <AccountingSection onChange={this.onChange}
                                           {...this.props}
                        />
                    </PreferencesErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_PREF_CHARTING,
            {
                section: Sections.CHARTING_SYSTEMS,
                content: <TabPanel key={Sections.CHARTING_SYSTEMS}
                                   header={SM_PREFERENCES_CRT.label}>
                    <PreferencesErrorBoundary>
                        <ChartingSystemsSection onChange={this.onChange}
                                                {...this.props}
                        />
                    </PreferencesErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_PREF_DIARY,
            {
                section: Sections.DIARY,
                content: <TabPanel key={Sections.DIARY}
                                   header={SM_PREFERENCES_DIS.label}>
                    <DiarySection onChange={this.onChange}
                                  {...this.props}
                    />
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_ALL,
            {
                section: Sections.DIARY_COLOUR_SELECTION,
                content: <TabPanel key={Sections.DIARY_COLOUR_SELECTION}
                                   header={SM_PREFERENCES_DCS.label}>
                    <PreferencesErrorBoundary>
                        <DiaryColourSection onChange={this.onChange}
                                            {...this.props}
                        />
                    </PreferencesErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_PREF_EMAIL,
            {
                section: Sections.EMAIL_ACCOUNT_SETTINGS,
                content: <TabPanel key={Sections.EMAIL_ACCOUNT_SETTINGS}
                                   header={SM_PREFERENCES_EMAIL.label}>
                    <PreferencesErrorBoundary>
                        <EmailSection onChange={this.onChange}
                                      {...this.props}
                        />
                    </PreferencesErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_PREF_ESENDEX,
            {
                section: Sections.ESENDEX_SETTINGS,
                content: <TabPanel key={Sections.ESENDEX_SETTINGS}
                                   header={SM_PREFERENCES_ESENDEX.label}>
                    <EsendexSection onChange={this.onChange}
                                    {...this.props}
                    />
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_PREF_PRACTICE_DETAILS,
            {
                section: Sections.PRACTICE_DETAILS,
                content: <TabPanel key={Sections.PRACTICE_DETAILS}
                                   header={SM_PREFERENCES_PDS.label}>
                    <PreferencesErrorBoundary>
                        <PracticeDetailsSection onChange={this.onChange}
                                                {...this.props}
                        />
                    </PreferencesErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_PREF_PRACTICE_DETAILS,
            {
                section: Sections.PRACTICE_OPENING_LOCATIONS,
                content: <TabPanel key={Sections.PRACTICE_OPENING_LOCATIONS}
                                   header={SM_PREFERENCES_LOCATIONS.label}>
                    <LocationsSection onChange={this.onChange}
                                      {...this.props}
                    />
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_PREF_PRACTICE_HOURS,
            {
                section: Sections.PRACTICE_OPENING_HOURS,
                content: <TabPanel key={Sections.PRACTICE_OPENING_HOURS}
                                   header={SM_PREFERENCES_HOURS.label}>
                    <PracticeHoursSection onChange={this.onChange}
                                          {...this.props}
                    />
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_UDAMANAGER,
            {
                section: Sections.NHS_REGISTRATION,
                content: <TabPanel key={Sections.NHS_REGISTRATION}
                                   header={SM_PREFERENCES_NHS.label}>
                    <PreferencesErrorBoundary>
                        <NHSRegistrationSection onChange={this.onChange}
                                                {...this.props}
                        />
                    </PreferencesErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_PREF_RECALLS_REMINDERS,
            {
                section: Sections.RECALLS_REMINDERS,
                content: <TabPanel key={Sections.RECALLS_REMINDERS}
                                   header={SM_PREFERENCES_RAR.label}>
                    <PreferencesErrorBoundary>
                        <RecallsRemindersSection onChange={this.onChange}
                                                 {...this.props}
                        />
                    </PreferencesErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_ALL,
            {
                section: Sections.RECALLS_REPORTS,
                content: <TabPanel key={Sections.RECALLS_REPORTS}
                                   header={SM_PREFERENCES_REPORTS.label}>
                    <PreferencesErrorBoundary>
                        <ReportsSection onChange={this.onChange}
                                        {...this.props}
                        />
                    </PreferencesErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_ALL,
            {
                section: Sections.XRAY_GATEWAY,
                content: <TabPanel key={Sections.XRAY_GATEWAY}
                                   header={SM_PREFERENCES_XRAY_GATEWAY.label}>
                    <PreferencesErrorBoundary>
                        <XRayGatewaySection onChange={this.onChange}
                                        {...this.props}
                        />
                    </PreferencesErrorBoundary>
                </TabPanel>
            });

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;
        const saveText = this.state.canSave.status ? 'Save & Exit' : 'Exit';

        return (
            <div id="detailPanel">

                <Dialog header="Save Resource"
                        footer={footer}
                        visible={this.state.showSaveDialog}
                        modal={true}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>
                    The form has been modified, save these changes?
                </Dialog>

                <Toolbar left={
                    <Button tooltipOptions={{position: 'top'}}
                            tooltip='Save'
                            icon={saveIcon}
                            disabled={!this.state.canSave.status}
                            onClick={this.onSaveNoDialog}>
                    </Button>
                }
                         right={
                             <React.Fragment>
                                 <Button icon='far fa-question-circle'
                                         tooltipOptions={{position: 'top'}}
                                         tooltip='Knowledge Base' onClick={(e) => {
                                     this.onHelpRequest();
                                 }}/>
                                 <Button className="p-button-danger"
                                         tooltipOptions={{position: 'top'}}
                                         tooltip={saveText}
                                         icon="fa fa-times"
                                         onClick={() => {
                                             this.exitState = TAB_EXIT_SAVE;
                                             this.onSaveExit()
                                         }}/>
                             </React.Fragment>
                         }
                />

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        capabilities
    } = getPreferenceIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        capabilities,

        currentState: state.stateManagement[SM_PREFERENCES.id],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        setObjectStore: (source) => dispatch(setObjectStore(source)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const Preferences = connect(MapStateToProps, MapDispatchToProps)(ConnectedPreferences);

export default Preferences;

