import React from 'react';
import {PDFBaseComponent} from "../../../PDFBaseComponent";
import {setState, SM_PATIENT_ACCOUNT_HISTORY_PDF} from "../../../../actions/stateManagement";
import {ProgressBar} from "primereact/progressbar";
import {TextPatientAccount} from "../../../../Messages";
import {getReportPDF} from "../../../../actions/reports";
import {connect} from "react-redux";
import {RES_PATIENT_ACCOUNT_HISTORY} from "../../../../actions/personal";

class ConnectedAccountHistoryPDF extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                pdfType: SM_PATIENT_ACCOUNT_HISTORY_PDF.id,
                numPages: null,
                pageNumber: 1,
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_ACCOUNT_HISTORY.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_PATIENT_ACCOUNT_HISTORY_PDF.id, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.state.PDFLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (
            <div className="p-col-12 p-lg-12">

                {this.createPDFToolBar(TextPatientAccount, SM_PATIENT_ACCOUNT_HISTORY_PDF.filename)}
                {this.createPDFContainer(SM_PATIENT_ACCOUNT_HISTORY_PDF.label)}
            </div>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_PATIENT_ACCOUNT_HISTORY.CLEAR);
    }
}

const mapStateToProps = (state, ownProps) => {

    const accountHistoryLoadedId = `${SM_PATIENT_ACCOUNT_HISTORY_PDF.loaded}_${ownProps.details.patientId}`;
    const accountHistoryId = `${SM_PATIENT_ACCOUNT_HISTORY_PDF.id}_${ownProps.details.patientId}`;

    return {
        message: state.stateManagement.message,

        PDFLoaded: state.reports[accountHistoryLoadedId],
        pdfBuffer: state.reports[accountHistoryId],

        currentState: state.stateManagement[SM_PATIENT_ACCOUNT_HISTORY_PDF.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReportPDF(RES_PATIENT_ACCOUNT_HISTORY.PDF, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const AccountHistoryPDF = connect(mapStateToProps, mapDispatchToProps)(ConnectedAccountHistoryPDF);

export default AccountHistoryPDF;
