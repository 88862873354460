import * as TC from "../Model/Constants";
import {getCondition, getItem, getMaterial} from "../Parts/ChartingUtils";
import {ac} from "../../../index";

let entryIndex = -100;

export class ChartItemBase {

    createNewEntry(mouth, itemCode, baseEntry) {

        const resources = mouth.props.parent.props.resources;
        const item = getItem(resources.chartingItems, itemCode);
        item.scope = 'Surface';

        let entry = {
            id: entryIndex--,
            mc: ac.getMcId(),
            addedBy: mouth.props.loginIdentity,
            adult: mouth.props.charting.adultChart,
            attachments: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            viables: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            canals: [],
            chart: null,
            comment: '',
            condition: getCondition(resources.conditions, TC.CONDNone),
            dateAdded: new Date(),
            jaw: TC.JawUpper,
            material: getMaterial(resources.materials, TC.MatNone),
            position: TC.L1.perm,
            spanStart: -1,
            spanEnd: -1,
            surfaces: [],
            type: TC.NonTooth,
            baseEntry: baseEntry,
            complete: baseEntry, // if this a base charted item then it is automatically marked as complete.
            linkedDocument: null,
            item,
        };

        return entry;
    }

    clear() {
        this.started = false;
        this.start = null;
        this.end = null;
        this.selection = [];
        this.jaw = null;
    }
}