import React from 'react';

import {RootFillColor, RootUnfilledColor, TMApicectomy, TMNone, TMRetained, TMRootCanal} from "../Model/Constants";
import {addRootExtras, getToothNumber, getTranslate} from "./ChartingUtils";
import {getRandomKey} from "../Utils/general";
import {ToothBaseComponent} from "./ToothBaseComponent";

const RootPoints = [8, 4, 22, 4, 22, 56, 26, 56, 26, 4, 34, 4, 40, 4, 40, 56, 44, 58, 44, 88, 4, 88, 4, 58, 8, 56, 8, 4];

const ChippedRootPoints = [8, 4, 22, 4, 22, 56, 26, 56, 26, 4, 34, 4, 40, 4, 40, 56, 44, 58, 44, 78, 34, 88, 4, 88, 4, 58, 8, 56, 8, 4];

const PostPoints = [8, 4, 22, 4, 22, 56, 26, 56, 26, 4, 34, 4, 40, 4, 40, 56, 44, 58, 44, 68, 4, 68, 4, 58, 8, 56, 8, 4];

const RootApiPoints = [8, 34, 22, 34, 22, 56, 26, 56, 26, 34, 34, 34, 40, 34, 40, 56, 44, 58, 44, 88, 4, 88, 4, 58, 8, 56, 8, 34];

const RootApiPostPoints = [8, 34, 22, 34, 22, 56, 26, 56, 26, 34, 34, 34, 40, 34, 40, 56, 44, 58, 44, 68, 4, 68, 4, 58, 8, 56, 8, 34];

const CanalPoints = [22, 4, 26, 4, 26, 50, 22, 50, 22, 4];

const CanalApiPoints = [22, 4, 26, 4, 26, 20, 22, 20, 22, 4];

const RetainedRootPoints = [8, 4, 22, 4, 22, 56, 26, 56, 26, 4, 34, 4, 40, 4, 40, 56, 44, 58, 4, 58, 8, 56, 8, 4];

export class AdultPreMolarBicusp extends ToothBaseComponent {

    constructor(props) {
        super();
        this.state = {
            cursor: '',
        };
        this.points = [];
        this.canalPoints1 = [];
        this.canalPoints2 = [];
        this.fracturePoints = [];
        this.canalFill = RootUnfilledColor;

        this.rootPoints = [];
        this.rootApiPoints = [];
        this.canalPoints = [];
        this.canalApiPoints = [];
        this.retainedRootPoints = [];
        this.postPoints = [];
        this.rootApiPostPoints = [];

        this.translate = '';
        this.labelTranslate = '';
        this.canalTranslate1 = '';
        this.canalTranslate2 = '';

        if (props.data.chipped)
            this.setPoints(ChippedRootPoints, RootApiPoints, CanalPoints, CanalApiPoints, RetainedRootPoints, PostPoints, RootApiPostPoints);
        else
            this.setPoints(RootPoints, RootApiPoints, CanalPoints, CanalApiPoints, RetainedRootPoints, PostPoints, RootApiPostPoints);
    }

    setPoints(rootPoints, rootApiPoints, canalPoints, canalApiPoints, retainedRootPoints, postPoints, rootApiPostPoints) {

        this.rootPoints = rootPoints;
        this.rootApiPoints = rootApiPoints;

        this.canalPoints = canalPoints;
        this.canalApiPoints = canalApiPoints;

        this.retainedRootPoints = retainedRootPoints;

        this.postPoints = postPoints;
        this.rootApiPostPoints = rootApiPostPoints;
    };

    addRoot(modifier, post) {

        switch (modifier) {
            case TMRetained :
                this.points = this.retainedRootPoints;
                break;
            case TMNone :
            case TMRootCanal :
                this.points = post ? this.postPoints : this.rootPoints;
                break;
            case TMApicectomy :
                this.points = post ? this.rootApiPostPoints : this.rootApiPoints;
                break;
            default:
                break;
        }
    };

    addCanal(modifier) {

        switch (modifier) {
            case TMRetained :
            case TMNone :
            case TMRootCanal :
                this.canalPoints1 = this.canalPoints;
                this.canalPoints2 = this.canalPoints;
                break;
            case TMApicectomy :
                this.canalPoints1 = this.canalApiPoints;
                this.canalPoints2 = this.canalApiPoints;
                break;
            default:
                break;
        }
        this.canalFill = modifier === TMRootCanal ? RootFillColor : RootUnfilledColor;
    }

    render() {

        const data = this.props.data;

        this.translate = getTranslate(data, 1, 0, 0, 0, false);
        this.labelTranslate = getTranslate(data, 15, 78, 0, 0, false);
        this.canalTranslate1 = getTranslate(data, -8, 0, 0, 0, true);
        this.canalTranslate2 = getTranslate(data, 10, 0, 0, 0, true);

        this.addRoot(data.tm, data.post.present || data.postAndCore.present);
        this.addCanal(data.tm);

        const bgColour = this.props.data.coreMaterial === undefined ? RootUnfilledColor : '';

        return (
            <g key={getRandomKey()} onContextMenu={(event) => {

                event.preventDefault();

                if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
                    this.props.data.currentCommand.setTarget(this.props.data);
                }
                this.props.setCurrentItem(this.props.data);
                this.props.cm(event);
            }}
               onMouseEnter={() => this.handleEnter()}
               onMouseLeave={() => this.handleLeave()}

               style={{cursor: this.state.cursor}}

               onClick={() => {
                   if (this.props.data.currentCommand != null && this.props.data.currentCommand.canExecute(this))
                       this.props.onClick(this.props.data);
               }}
            >
                <polygon
                    points={this.points}
                    style={{fill: bgColour, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={this.translate}
                />
                <polygon
                    points={this.canalPoints1}
                    style={{fill: this.canalFill, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={this.canalTranslate1}
                />
                <polygon
                    points={this.canalPoints2}
                    style={{fill: this.canalFill, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={this.canalTranslate2}
                />
                <polygon
                    points={this.fracturePoints}
                    style={{fill: bgColour, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={this.canalTranslate2}
                />

                {addRootExtras(this.props.data)}

                <text x={0} y={0} fill={'black'} fontSize={10}
                      transform={this.labelTranslate}>{getToothNumber(data)}</text>
            </g>
        )
    }
}
