import React from 'react';
import _ from "lodash";

import moment from 'moment';

import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Password} from 'primereact/components/password/Password';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';
import {RadioButton} from 'primereact/components/radiobutton/RadioButton';
import {SelectButton} from 'primereact/components/selectbutton/SelectButton';
import {ToggleButton} from 'primereact/components/togglebutton/ToggleButton';
import {Calendar} from 'primereact/components/calendar/Calendar';
import {Codes9000KPIDataSet} from "../../NHSConstants";
import {InputNumber} from "primereact/inputnumber";
import {NewPassword} from "../../Constants";

export const CONST_FEMALE = 0;
export const CONST_FEMALE_LABEL = 'Female';
export const CONST_MALE = 1;
export const CONST_MALE_LABEL = 'Male';
export const CONST_INDETERMINATE = 2;
export const CONST_INDETERMINATE_LABEL = 'Neutral';
export const CONST_ASK = 3;
export const CONST_ASK_LABEL = 'Ask';

export const MINIMUM_TELEPHONE_NUMBER_UK = 11;
export const MINIMUM_MOBILE_NUMBER_UK = 11;

export const MINIMUM_TELEPHONE_NUMBER = MINIMUM_TELEPHONE_NUMBER_UK;
export const MINIMUM_MOBILE_NUMBER = MINIMUM_MOBILE_NUMBER_UK;

export const genders = [
    {label: CONST_FEMALE_LABEL, value: CONST_FEMALE},
    {label: CONST_MALE_LABEL, value: CONST_MALE},
    {label: CONST_INDETERMINATE_LABEL, value: CONST_INDETERMINATE},
    {label: CONST_ASK_LABEL, value: CONST_ASK},
];

export const categoryDropDown = (props, catName, assName, readOnly) => {

    const valueId = _.get(props, `${props.target}.${assName}.id`);
    let value = '';
    let categories = _.get(props, 'categories');
    let options = [];

    categories = categories === undefined ? [{category: ''}] : categories;

    let categoryIndex = null;

    try {
        categoryIndex = _.findIndex(categories, (cat) => catName === cat.category);
    } catch (error) {
        console.log(error);
    }
    if (categoryIndex !== null || categoryIndex > -1) {
        let category = categories[categoryIndex];

        category = category === undefined ? [] : category;

        options = _.orderBy(category.elements, 'rank', 'asc');

        options = options === undefined ? [] : options;

        if (valueId !== undefined && options.length > 0) {

            const optionIndex = _.findIndex(options, (item) => valueId === item.id);

            value = optionIndex < 0 ? {label: '', value: null} : options[optionIndex];
        } else
            value = {label: '', value: null};
    }

    return (
        <Dropdown value={value}
                  disabled={readOnly}
                  options={options}
                  optionLabel="name"
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${assName}`, value: e.value})
                  }}
                  autoWidth={false}
        />
    );
};

export const dropDown = (props, options, name, label, mandatory, readOnly) => {

    const value = _.get(props, `${props.target}.${name}`);

    const optionsList = _.get(props, options);

    const _value = value ? _.find(optionsList, option => option.id === value.id) : undefined;

    const className = (!_value && mandatory) ? 'p-invalid' : '';

    return (
        <Dropdown value={_value}
                  className={className}
                  optionLabel={label}
                  options={optionsList}
                  disabled={readOnly}
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${name}`, value: e.value})
                  }}
        />
    )
};

export const dropDownId = (props, options, name, label, mandatory, readOnly) => {

    const value = _.get(props, `${props.target}.${name}.id`);

    const optionsList = _.get(props, options);

    const className = (!value && mandatory) ? 'p-invalid' : '';

    return (
        <Dropdown className={className}
                  value={value}
                  options={optionsList}
                  optionLabel={label}
                  optionValue='id'
                  onChange={e => {
                      props.onChange({
                          owner: `${props.target}.${name}`,
                          value: _.find(optionsList, option => option.id === e.value)
                      })
                  }}
                  disabled={readOnly}
        />
    )
};

export const dropDownWithOrdinal = (key, props, options, name, label, mandatory, readOnly) => {

    const valueId = _.get(props, `${props.target}.${name}.value`);
    let value = '';

    if (valueId) {
        value = _.get(props, `[${options}]`)[_.findIndex(_.get(props, `[${options}]`), (item) => valueId === item.value)];
    }

    if (!valueId || valueId === '' || value === '')
        value = {value: ''};

    const className = (value === '' && mandatory) ? 'p-invalid' : '';

    return (
        <Dropdown value={value.value}
                  className={className}
                  optionLabel={label}
                  options={_.get(props, `[${options}]`)}
                  autoWidth={false}
                  disabled={readOnly}
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${name}.value`, value: e.value})
                  }}
        />
    )
};

export const dropDownPlainWithElements = (props, options, name, readOnly, mandatory) => {

    const value = _.get(props, `${props.target}.${name}`);

    return (
        <Dropdown value={value}
                  options={_.get(props, `[${options}].elements`)}
                  disabled={readOnly}
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${name}`, value: e.value})
                  }}
                  autoWidth={false}
        />
    )
};

export const dropDownPlain = (props, options, name, label, readOnly, mandatory) => {

    let value = _.get(props, `${props.target}.${name}`);

    const optionsList = _.get(props, options);

    const className = (value === '' && mandatory) ? 'p-invalid' : '';

    return (
        <Dropdown className={className}
                  value={value}
                  options={optionsList}
                  optionLabel={label}
                  disabled={readOnly}
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${name}`, value: e.value})
                  }}
                  autoWidth={false}
        />
    )
};

export const dropDownArray = (props, name, tabIndex, readOnly) => {

    let assessment = _.get(props, props.target);
    let assessmentValue;
    let options = [];
    try {
        options = _.find(props.categories, (target) => target.category === props.name).elements;
    } catch (error) {
        console.log(error);
    }
    let label = 'name';

    if (assessment === undefined || assessment === null || Boolean(assessment.notLoaded || options.length === 0)) {
        assessmentValue = {label: '', value: null};
    } else {
        assessmentValue = _.get(assessment, name);
    }

    return (
        <Dropdown value={assessmentValue}
                  options={options}
                  optionLabel={label}
                  optionValue={'id'}
                  disabled={readOnly}
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${name}`, value: e.value})
                  }}
                  tabIndex={tabIndex}
        />
    );
};

export const checkBox = (props, name, label, readOnly, mandatory, width) => {

    const checked = _.get(props, `${props.target}.${name}`);
    const contents = [];
    const style = Boolean(width) ? {width: `${width}px`} : null;

    let key = Math.floor(Math.random() * 1000);

    contents.push(
        <Checkbox checked={checked}
                  disabled={readOnly}
                  className='p-checkbox-label'
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${name}`, value: e.checked})
                  }}
                  key={key++}
        />
    );
    if (label)
        contents.push(<label style={style}
                             className='p-checkbox-label'
                             key={key}
                             htmlFor={name}>{label}
        </label>);
    return contents;
};

export const checkBoxNHSKPI = (props, code9000, readOnly, mandatory, width) => {

    const kpis = _.get(props, `${props.target}.kpis`);
    const index = _.findIndex(Codes9000KPIDataSet, code => code.id === code9000.id);

    const checked = kpis[index];

    const contents = [];

    let key = Math.floor(Math.random() * 1000);

    contents.push(
        <Checkbox checked={checked}
                  disabled={readOnly}
                  className='p-checkbox-label'
                  onChange={(e) => {
                      kpis[index] = e.checked;
                      props.onChange({owner: `${props.target}.kpis`, value: kpis})
                  }}
                  key={key}
        />
    );
    return contents;
};

export const checkBoxFixed = (checked, name, label) => {

    const contents = [];

    let key = Math.floor(Math.random() * 1000);

    contents.push(
        <Checkbox checked={checked}
                  disabled={true}
                  className='p-checkbox-label'
                  key={key++}
        />
    );
    if (label)
        contents.push(
            <label className='p-checkbox-label'
                   htmlFor={name}
                   key={key}>
                {label}
            </label>);

    return contents;
};

export const radioButton = (props, name, label) => {

    const checked = _.get(props, `${props.target}.${name}`);

    return (
        <div>
            <RadioButton checked={checked}
                         onChange={(e) => {
                             props.onChange({owner: `${props.target}.${name}`, value: e.checked})
                         }}
            />
            {label ? <label style={{fontSize: 'small'}} className='p-radiobutton-label'
                            htmlFor={name}>{label}</label> : null}
        </div>
    );
};

export const radioButtonNHS = (props, name, value, label) => {

    const checked = _.get(props, name) === value;

    return (
        <div>
            <RadioButton checked={checked}
                         onChange={(e) => {
                             props.onChange({owner: name, value: value})
                         }}
            />
            {label ? <label style={{fontSize: 'small'}} className='p-radiobutton-label'
                            htmlFor={name}>{label}</label> : null}
        </div>
    );
};

export const spinner = (props, name, min, max, readOnly, mandatory, width) => {

    return (
        <InputNumber value={_.get(props, `${props.target}.${name}`)}
                     min={min}
                     max={max}
                     disabled={readOnly}
                     size={width}
                     showButtons
                     onChange={(e) => {
                         props.onChange({owner: `${props.target}.${name}`, value: e.value})
                     }}
        />
    );
};

export const spinnerStep = (props, name, min, max, step, readOnly, mandatory, width, tooltip) => {

    return (<InputNumber value={_.get(props, `${props.target}.${name}`)}
                         min={min}
                         max={max}
                         disabled={readOnly}
                         size={width}
                         tooltipOptions={{position: 'right'}}
                         tooltip={tooltip}
                         showButtons
                         onChange={(e) => {
                             props.onChange({owner: `${props.target}.${name}`, value: e.value});
                         }}
                         step={step}
        />
    );
};

export const tooth = (props, position, index) => {
    return (
        <Checkbox disabled={props.readOnly}
                  onChange={(e) => {
                      props.onChange({owner: `${props.arrayType}[${position}][${index}]`, value: e.checked})
                  }}
                  checked={_.get(props, `${props.arrayType}[${position}][${index}]`)}
        />
    )
};

export const numberText = (props, name, placeholder, readOnly, mandatory) => {

    let value = _.get(props, `${props.target}.${name}`);
    value = (value === null || value === undefined) ? '' : value;
    const className = (value.toString().trim() === '' && mandatory) ? 'p-invalid' : '';

    return (
        <InputText type="text"
                   style={{textAlign: 'right'}}
                   className={className}
                   disabled={readOnly}
                   placeholder={placeholder}
                   onChange={(e) => {
                       props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
                   }}
                   value={value}
        />
    );
};

export const inputText = (props, name, placeholder, readOnly, mandatory) => {

    let value = _.get(props, `${props.target}.${name}`);
    value = (value === null || value === undefined) ? '' : value;
    const className = (value.toString().trim() === '' && mandatory) ? 'p-invalid' : '';

    return (
        <InputText type="text"
                   className={className}
                   disabled={readOnly}
                   placeholder={placeholder}
                   onChange={(e) => {
                       props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
                   }}
                   value={value}
        />
    )
};

export const inputTextSized = (props, name, placeholder, readOnly, mandatory, requiredLength) => {

    let value = _.get(props, `${props.target}.${name}`).replace(/\s+/g, '');
    value = (value === null || value === undefined) ? '' : value;

    const className = ((value.toString().trim() === '' || value.length < requiredLength) && mandatory) ? 'p-invalid' : '';
    const sizedValue = value.length <= requiredLength ? value : value.substring(0, requiredLength);
    return (
        <InputText type="text"
                   className={className}
                   disabled={readOnly}
                   placeholder={placeholder}
                   onChange={(e) => {
                       props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
                   }}
                   value={sizedValue}
        />
    )
};

export const inputTextNHS = (props, name, placeholder, readOnly, mandatory) => {

    let value = _.get(props, `${props.target}.${name}`);
    value = (value === null || value === undefined) ? '' : value;
    const className = mandatory ? 'p-invalid' : '';

    return (
        <InputText type="text"
                   className={className}
                   disabled={readOnly}
                   placeholder={placeholder}
                   onChange={(e) => {
                       props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
                   }}
                   value={value}
        />
    );
};

export const inputDiscountText = (props, name, placeholder, readOnly, mandatory) => {

    let value = _.get(props, `${props.target}.${name}`);
    value = (value === null || value === undefined) ? '' : value;
    const className = (value.toString().trim() === '' && mandatory) ? 'p-invalid' : '';

    return (
        <InputText type="text"
                   className={className}
                   disabled={readOnly}
                   placeholder={placeholder}
                   onChange={(e) => {
                       props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
                   }}
                   value={value}
                   style={{textAlign: 'right'}}
        />
    );
};

export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const inputTextEmail = (props, name, placeholder, readOnly, mandatory) => {

    let value = _.get(props, `${props.target}.${name}`);
    value = (value === null || value === undefined) ? '' : value;
    const className = ((value.toString().trim() === '' && mandatory) || !validateEmail(value)) ? 'p-invalid' : '';

    return (
        <InputText type="text"
                   keyfilter="email"
                   className={className}
                   disabled={readOnly}
                   placeholder={placeholder}
                   onChange={(e) => {
                       props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
                   }}
                   value={value}
        />
    );
};

export const inputCurrencyText = (props, name, placeholder, readOnly, mandatory) => {

    const value = _.get(props, `${props.target}.${name}`);

    const className = value === undefined ? '' : (value.toString().trim() === '' && mandatory) ? 'p-invalid' : '';

    return (
        <InputText type="text"
                   style={{textAlign: 'right'}}
                   className={className}
                   disabled={readOnly}
                   placeholder={placeholder}
                   keyfilter='money'
                   onChange={(e) => {
                       props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
                   }}
                   value={value}
        />
    );
};

export const inputNumberText = (props, name, placeholder, readOnly, mandatory) => {

    const value = _.get(props, `${props.target}.${name}`);

    const className = value === undefined ? '' : (value.toString().trim() === '' && mandatory) ? 'p-invalid' : '';

    return (
        <InputText type="text"
                   style={{textAlign: 'right'}}
                   className={className}
                   disabled={readOnly}
                   placeholder={placeholder}
                   keyfilter='num'
                   onChange={(e) => {
                       props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
                   }}
                   value={value}
        />
    );
};

export const outputCurrency = (value) => {

    return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(value);
};

export const outputCurrencyText = (value) => {

    const formattedValue = new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(value);

    return (
        <InputText type="text"
                   style={{textAlign: 'right'}}
                   disabled={true}
                   value={formattedValue}
        />
    );
};

export const outputText = (value) => {

    return (
        <InputText type="text"
                   style={{textAlign: 'right'}}
                   disabled={true}
                   value={value}
        />
    );
};

export const outputPropsCurrencyText = (props, name) => {

    const value = _.get(props, `${props.target}.${name}`);
    const formattedValue = new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(value);

    return (
        <InputText type="text"
                   style={{textAlign: 'right'}}
                   disabled={true}
                   value={formattedValue}
        />
    );
};

export const outputPropsCurrencyBigText = (props, name) => {

    const value = _.get(props, `${props.target}.${name}`);
    const formattedValue = new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(value);

    return (
        <InputText type="text"
                   style={{textAlign: 'right', fontsize: 'medium', fontweight: 'bold'}}
                   disabled={true}
                   value={formattedValue}
        />
    );
};

export const password = (props, name, feedback, readOnly, mandatory) => {

    const value = _.get(props, `${props.target}.${name}`);
    const className = value === undefined ? '' : ((value.toString().trim() === '' || value.toString().trim() === NewPassword) && mandatory) ? 'p-invalid' : '';
    const pFeedback = feedback === undefined ? true : feedback;
    const pReadOnly = readOnly === undefined ? false : readOnly;

    return (
        <Password className={className}
                  disabled={pReadOnly}
                  feedback={pFeedback}
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
                  }}
                  value={value}
        />
    );
};

export const inputTextArea = (props, name, rows, cols, readOnly, mandatory) => {

    const _value = _.get(props, `${props.target}.${name}`);
    const value = _value ? _value : '';
    const className = (value.toString().trim() === '' && mandatory) ? 'p-invalid' : '';

    return (
        <InputTextarea onChange={(e) => {
            props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
        }}
                       className={className}
                       autoResize={true}
                       disabled={readOnly === undefined ? false : readOnly}
                       rows={rows}
                       cols={cols}
                       value={value}
                       style={{textAlign: 'left'}}
        />
    );
};

export const inputTextAreaKeyed = (key, props, name, rows, cols, readOnly, style) => {

    const taStyle = style === undefined ? {textAlign: 'left'} : style;
    const _value = _.get(props, `${props.target}.${name}`);
    const value = _value === undefined ? '' : _value;

    return (
        <InputTextarea onChange={(e) => {
            props.onChange({owner: `${props.target}.${name}`, value: e.target.value})
        }}
                       autoResize={true}
                       disabled={readOnly === undefined ? false : readOnly}
                       rows={rows}
                       cols={cols}
                       value={value}
                       style={taStyle}
        />
    );
};

export const selectGendersButton = (props, name) => {

    const value = _.get(props, `${props.target}.${name}`);
    const className = value === CONST_ASK ? 'p-invalid p-buttonset-4' : 'p-buttonset-4';

    return (
        <SelectButton className={className}
                      value={value}
                      options={genders}
                      onChange={(e) => {
                          if (e.value !== null) // null indicates no change
                              props.onChange({owner: `${props.target}.${name}`, value: e.value})
                      }}
        />
    );
};

export const selectButton = (props, name) => {

    const value = _.get(props, `${props.target}.${name}`);

    return (
        <SelectButton value={value}
                      options={props.options}
                      onChange={(e) => {
                          props.onChange({owner: `${props.target}.${name}`, value: e.value})
                      }}
        />
    );
};

export const toggleButton = (props, name, onLabel, offLabel, onIcon, offIcon, right) => {

    const checked = _.get(props, `${name}`);
    const toolTip = checked ? offLabel : onLabel;

    let style = {color: 'white'};

    if (right)
        style = {...style, alignItems: 'float-right'};

    return (
        <ToggleButton checked={checked}
                      onLabel={onLabel}
                      offLabel={offLabel}
                      onIcon={onIcon}
                      offIcon={offIcon}
                      tooltipOptions={{position: 'right'}}
                      tooltip={toolTip}
                      onChange={() =>
                          props.onSelectionChange({owner: name, value: !_.get(props, name)})
                      }
                      style={style}
        />
    );
};

export const calendar = (props, name, readOnly, mandatory) => {

    let date = _.get(props, `${props.target}.${name}`);

    let validDate = moment(date).toDate();

    if (!moment(date).isValid()) {
        return '-';
    } else {

        const className = (validDate && mandatory) ? 'p-invalid' : '';

        const dateFormat = props.onTablet ? 'dd/mm/yy' : 'DD, d, MM, yy';


        return (
            <Calendar value={validDate}
                      className={className}
                      disabled={readOnly}
                      monthNavigator={true}
                      showIcon={true}
                      yearNavigator={true}
                      yearRange="1900:2030"
                      dateFormat={dateFormat}
                      onChange={(e) => {
                          props.onChange({owner: `${props.target}.${name}`, value: e.value})
                      }}
                      readOnlyInput={true}
            />
        );
    }
}

export const calendarWithNull = (props, name, readOnly, mandatory) => {

    let date = _.get(props, `${props.target}.${name}`);

    let validDate = date ? moment(date).toDate() : null

    const className = (validDate && mandatory) ? 'p-invalid' : '';

    const dateFormat = props.onTablet ? 'dd/mm/yy' : 'DD, d, MM, yy';

    return (
        <Calendar value={validDate}
                  className={className}
                  disabled={readOnly}
                  monthNavigator={true}
                  showIcon={true}
                  yearNavigator={true}
                  yearRange="1900:2030"
                  dateFormat={dateFormat}
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${name}`, value: e.value})
                  }}
                  readOnlyInput={true}
        />
    );
}

export const calendarCompact = (props, name, readOnly, mandatory) => {

    let date = _.get(props, `${props.target}.${name}`);
    const validDate = date === null ? new Date() : moment(date).toDate();

    const className = (validDate && mandatory) ? 'p-invalid' : '';

    return (
        <Calendar value={validDate}
                  className={className}
                  disabled={readOnly}
                  monthNavigator={true}
                  showIcon={true}
                  yearNavigator={true}
                  yearRange="1900:2030"
                  dateFormat='dd/mm/yy'
                  onChange={(e) => {
                      props.onChange({owner: `${props.target}.${name}`, value: e.value})
                  }}
                  readOnlyInput={true}
        />
    );
};