import * as Actions from "./index";
import {ac} from "../index";
import {ADMIN_GROUP_SUBSCRIPTIONS_STATUS} from "./index";

const baseURL = '/Admin'

export const RES_getCountryCodes = {
    GET: {
        request: Actions.REQUEST_COUNTRY_CODES,
        receive: Actions.RECEIVE_COUNTRY_CODES,
        url: `${baseURL}/Utils/countryCodes`,
    }
};

export const RES_ADMIN_GROUPS = {
    GET: {
        request: Actions.REQUEST_ADMIN_GROUPS,
        receive: Actions.RECEIVE_ADMIN_GROUPS,
        url: `${baseURL}/Group/adminGroups`

    },
    CLEAR: {action: Actions.CLEAR_ADMIN_GROUPS}
};

export const RES_ADMIN_GROUP_SUBSCRIPTIONS = {
    GET: {
        request: Actions.REQUEST_ADMIN_GROUP_SUBSCRIPTIONS,
        receive: Actions.RECEIVE_ADMIN_GROUP_SUBSCRIPTIONS,
        url: `${baseURL}/Subscription/adminSubscriptions`

    },
    SET_STATUS: {
        action: Actions.ADMIN_GROUP_SUBSCRIPTIONS_STATUS,
        url: `${baseURL}/Subscription/status`
    },
    CLEAR: {action: Actions.CLEAR_ADMIN_GROUP_SUBSCRIPTIONS}
};

export const RES_ADMIN_GROUP_SUBSCRIPTION = {
    ACTIVE_LOGINS: {
        request: Actions.REQUEST_SUBSCRIPTION_ACTIVE_LOGINS,
        receive: Actions.RECEIVE_SUBSCRIPTION_ACTIVE_LOGINS,
        url: `${baseURL}/Subscription/activeLogins`

    },
    ADD: {
        action: Actions.ADD_ADMIN_GROUP_SUBSCRIPTION,
        url: `${baseURL}/Subscription/createSubscriptionData`
    },
    CLEAR: {action: Actions.CLEAR_SUBSCRIPTION_ACTIVE_LOGINS},
    LOGOUT: {action: Actions.CLEAR_SUBSCRIPTION_ACTIVE_LOGINS}
};

export const RES_FIND_SUBSCRIPTIONS = {
    CLEAR: {action: Actions.CLEAR_SUBSCRIPTION_SEARCH}
};

export const RES_SUBSCRIPTION_DETAILS = {
    PASS: {
        request: Actions.REQUEST_SUBSCRIPTION_DETAILS,
        receive: Actions.RECEIVE_SUBSCRIPTION_DETAILS
    },
    SAVE: {action: Actions.SAVE_SUBSCRIPTION_DETAILS, url: `${baseURL}/Subscription/updateSubscription`},
    CLEAR: {action: Actions.CLEAR_SUBSCRIPTION_DETAILS},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_SUBSCRIPTION_DETAILS},
    SEND_EMAIL: {action: Actions.SAVE_SUBSCRIPTION_DETAILS, url: `${baseURL}/Subscription/emailDetails`}
};

export const passSubscription = ({url, request, receive}, subscriptionData, params) => {

    return (dispatch) => dispatch({type: receive, payload: subscriptionData, ...params})
};

export const logUserOut = ({url, action}, params) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}&id=${params.id}`)
            .then(res => {

                dispatch({type: action, payload: res.data})
            });
    }
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params});
    }
};

export const setResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().post(`${url}`, params)
            .then(res => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const adminSubscriptionAdd = ({url, action}, subscription) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?id=${subscription.group.id}`, subscription)
            .then(res => {

                dispatch({type: action, payload: res.data})
            });
    }
};

export const emailSubscriptionDetails = ({url, action}, params) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?id=${params.id}&email=${params.email}`)
            .then(res => {

                dispatch({type: action, payload: res.data})
            });
    }
};
