import React from 'react';

import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';

import {setState, stateRequest} from "../../../actions/stateManagement";
import {connect} from "react-redux";
import _ from "lodash";
import {
    copyTreatmentPlan,
    deleteTreatmentPlan,
    getResource,
    RES_TREATMENT_PLAN_BY_ID,
    RES_TREATMENT_PLAN_HISTORY
} from "../../../actions/treatmentPlanning";
import {HM_AddPassword, HM_notImplemented, TP_ACCEPTED, TP_PROPOSED} from "../../../Constants";
import {ICON_DELETE, ICON_EDIT, ICON_PLUS} from "../../../icons";
import {
    TB_ADD_TREATMENT_PLAN,
    TB_COPY_TREATMENT_PLAN,
    TB_COPY_TREATMENT_PLAN_NAME,
    TB_DELETE_TREATMENT_PLAN,
    TB_EDIT_TREATMENT_PLAN
} from "../PatientDetails/Constants";
import {ShowMessageDialog, ShowQuestionDialog} from "../Diary/components/EventComponent";
import {getTreatmentPlanIds} from "./Utils";
import {dateTemplate} from "../../PatientDynamicItems/Utils";
import {treatmentPlanData} from "../DefaultData";
import {ac} from "../../../index";
import {TabBaseComponent} from "../../TabBaseComponent";

import {DELETE_TREATMENT_PLAN} from "../../../actions";
import {checkBox, inputText} from "../../PatientDynamicItems/OnChangeUtils";
import {Dialog} from "primereact/components/dialog/Dialog";

export class ConnectedTreatmentPlanHistory extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.treatmentPlanHistoryId,
                first: 0,
                rows: 5,
                canSave: {status: false},
                treatmentPlanHistoryLoaded: false,
                selectedTreatmentPlan: null,

                showAddPlan: false,
                copiedPlan: {newTitle: ''},

                fakeTreatmentPlanId: -100,

                checkboxes: {
                    includeAppointments: false,
                },
            }
        }

        this.toolbarCallbacks = {

            [TB_ADD_TREATMENT_PLAN.id]: this.onAddTreatmentPlan,
            [TB_EDIT_TREATMENT_PLAN.id]: this.onRowSelection,
            [TB_COPY_TREATMENT_PLAN.id]: this.onCopySelection,
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getTPHistory(this.props.patientId);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_TREATMENT_PLAN_HISTORY.GET.receive:
                case DELETE_TREATMENT_PLAN:

                    this.setState({
                        treatmentPlanHistory: this.props.treatmentPlanHistory,
                        treatmentPlanHistoryLoaded: this.props.treatmentPlanHistoryLoaded,
                        copiedPlan: {newTitle: ''},
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;

                case RES_TREATMENT_PLAN_BY_ID.ADD.action:

                    this.props.getTPHistory(this.props.patientId);
                    break;

                default:
                    break;
            }
        }
    }

    onCopySelection = (e) => {
        this.setState({[TB_COPY_TREATMENT_PLAN.id]: true});
    }

    copySelection = () => {
        this.setState({[TB_COPY_TREATMENT_PLAN.id]: false, [TB_COPY_TREATMENT_PLAN_NAME.id]: true});
    }

    copyComplete = (text) => {

        const params = {
            patientId: this.props.patientId,
            planId: this.state.selectedTreatmentPlan.id,
            newTitle: this.state.copiedPlan.newTitle
        };
        this.setState({[TB_COPY_TREATMENT_PLAN_NAME.id]: false}, () => {
            this.props.copyTreatmentPlan(params);
        });
    }

    onRowSelection = (e) => {
        this.props.onAddTPTab(this.state.selectedTreatmentPlan);
    }

    onSelection = (e) => {
        this.setState({selectedTreatmentPlan: e.value});
    }

    onDeletePlan = () => {

        this.setState({[TB_DELETE_TREATMENT_PLAN.id]: false}, () => {
            this.props.onDeleteTPTab(this.state.selectedTreatmentPlan);
            this.props.deleteTreatmentPlan(this.props.patientId, this.state.selectedTreatmentPlan.id);
        });
    }

    showDialogs = () => {

        if (this.state[TB_DELETE_TREATMENT_PLAN.id]) {
            return ShowQuestionDialog(this, TB_DELETE_TREATMENT_PLAN, this.onDeletePlan)
        } else if (this.state[TB_COPY_TREATMENT_PLAN.id]) {
            return ShowQuestionDialog(this, TB_COPY_TREATMENT_PLAN, this.copySelection)
        } else if (this.state[TB_COPY_TREATMENT_PLAN_NAME.id]) {

            const nameProps = {
                target: 'copiedPlan',
                copiedPlan: {newTitle: this.state.copiedPlan.newTitle},
                onChange: this.onChange,
            }

            const footer = <div>
                <Button label="OK"
                        icon="fa fa-check"
                        onClick={() => {
                            this.setState({[HM_AddPassword.id]: false}, () => {
                                this.copyComplete()
                            });
                        }}
                        disabled={this.state.copiedPlan.newTitle.length === 0}
                />
                <Button label="Cancel" icon="fas fa-times" onClick={() => {
                    this.setState({[TB_COPY_TREATMENT_PLAN_NAME.id]: false});
                }
                }/>
            </div>;

            return (
                <Dialog header={TB_COPY_TREATMENT_PLAN_NAME.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        style={{width: "25%"}}
                        footer={footer}
                        onHide={() => {
                            this.setState({[TB_COPY_TREATMENT_PLAN_NAME.id]: false})
                        }}
                >

                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            {inputText(nameProps, 'newTitle', 'Plan Name', false, true)}
                        </div>
                    </div>
                </Dialog>
            )
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onAddTreatmentPlan = () => {

        const treatmentPlan = _.cloneDeep(treatmentPlanData(ac.getMcId()));

        treatmentPlan.id = this.state.fakeTreatmentPlanId;
        treatmentPlan.patient = {id: this.props.patientId};
        treatmentPlan.title = 'New Treatment Plan';
        treatmentPlan.description = '';
        treatmentPlan.status = TP_PROPOSED.name;
        treatmentPlan.createdBy = {id: this.props.loginIdentity.id};

        const source = {
            id: this.state.stateManagementId,
            action: RES_TREATMENT_PLAN_BY_ID.ADD.action,
            saveState: true,
            saveObjects: false,
        };

        this.setState({fakeTreatmentPlanId: this.state.fakeTreatmentPlanId + 1}, () => {
            this.props.stateRequest(source, treatmentPlan);
        })
    }

    buildItems = () => {

        const items = [];

        if (this.state.selectedTreatmentPlan === null) return [];

        items.push(
            {
                key: TB_EDIT_TREATMENT_PLAN.id,
                label: TB_EDIT_TREATMENT_PLAN.text,
                icon: ICON_EDIT,
                command: (e) => {
                    this.toolbarCallbacks[TB_EDIT_TREATMENT_PLAN.id](this.state.selectedTreatmentPlan);
                },
            });

        if (this.state.selectedTreatmentPlan.status !== TP_ACCEPTED.name) {

            items.push(
                {
                    key: TB_DELETE_TREATMENT_PLAN.id,
                    label: TB_DELETE_TREATMENT_PLAN.text,
                    icon: ICON_DELETE,
                    command: (e) => {
                        this.onShowMenuEntry({item: {target: TB_DELETE_TREATMENT_PLAN.id}})
                    }
                }
            )
        }

        items.push(
            {
                key: TB_COPY_TREATMENT_PLAN.id,
                label: TB_COPY_TREATMENT_PLAN.text,
                icon: TB_COPY_TREATMENT_PLAN.icon,
                command: (e) => {
                    this.toolbarCallbacks[TB_COPY_TREATMENT_PLAN.id](this.state.selectedTreatmentPlan);
                },
            });
        return items;
    }

    render() {

        if (!this.state.treatmentPlanHistoryLoaded) {
            return null;
        }

        const props = {
            onChange: this.onChange,
            target: 'checkboxes',
            checkboxes: this.state.checkboxes,
        };

        const panelHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>Treatment Plans</span>
                <Button tooltip={TB_ADD_TREATMENT_PLAN.text}
                        tooltipOptions={{position: 'right'}}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.toolbarCallbacks[TB_ADD_TREATMENT_PLAN.id]();
                        }}>
                </Button>
            </div>
            <div className="items-margin d-flex d-align-center">
                {checkBox(props, 'includeAppointments', 'Include Appointments', false, false)}
            </div>
        </div>;

        const items = this.buildItems();

        const filteredTreatmentPlans = _.filter(this.state.treatmentPlanHistory.plans, plan => this.state.checkboxes.includeAppointments || plan.title !== 'Standard Course of Treatment');
        const sortedTreatmentPlans = _.orderBy(filteredTreatmentPlans, 'id', 'desc');

        return (
            <Panel headerTemplate={panelHeader}>

                {this.showDialogs()}

                <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                <DataTable value={sortedTreatmentPlans}
                           className='p-datatable-gridlines'
                           editable={true}
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}
                           selectionMode="single"
                           selection={this.state.selectedTreatmentPlan}
                           onRowDoubleClick={this.onRowSelection}
                           onSelectionChange={this.onSelection}
                           contextMenuSelection={this.state.selectedTreatmentPlan}
                           onContextMenuSelectionChange={e => this.setState({selectedTreatmentPlan: e.value})}
                           onContextMenu={e => this.cm.show(e.originalEvent)}
                >
                    <Column body={row => dateTemplate(row.created)} header="Created On"
                            style={{width: '15%', textAlign: 'right'}}/>
                    <Column field="title" header="Title" style={{width: '25%'}}/>
                    <Column field="description" header="Description" style={{width: '50%'}}/>
                    <Column body={row => row.status === TP_ACCEPTED.name ? 'Accepted' : 'Proposed'}
                            field="status" header="Status" style={{width: '10%', textAlign: 'right'}}/>
                </DataTable>
            </Panel>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        treatmentPlanHistoryLoaded,
        treatmentPlanHistoryId,
        treatmentPlanHistory,
    } = getTreatmentPlanIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        treatmentPlanHistoryLoaded,
        treatmentPlanHistoryId,
        treatmentPlanHistory,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[treatmentPlanHistoryId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTPHistory: (patientId) => dispatch(getResource(RES_TREATMENT_PLAN_HISTORY.GET, patientId, {})),
        deleteTreatmentPlan: (patientId, planId) => dispatch(deleteTreatmentPlan(RES_TREATMENT_PLAN_BY_ID.DELETE, patientId, planId)),
        copyTreatmentPlan: (params) => dispatch(copyTreatmentPlan(RES_TREATMENT_PLAN_BY_ID.COPY, params)),

        stateRequest: (source, treatmentPlan) => dispatch(stateRequest(source, treatmentPlan)),

        setState: (id, data, parent) => dispatch(setState(id, data, parent)),
    }
};

const TreatmentPlanHistory = connect(mapStateToProps, mapDispatchToProps)(ConnectedTreatmentPlanHistory);

export default TreatmentPlanHistory;