import React from 'react';

import {TabPanel} from 'primereact/components/tabview/TabView';

import {__CHIRAL_CONTACT_DETAILS__, TB_SAVE, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_HELP, ICON_LAB} from "../../../icons";
import {
    HELP_LABORATORY_INDEXS,
    SM_LABORATORY_ADD,
    SM_LABORATORY_DATA, SM_LABORATORY_DETAILS_DATA,
    SM_LABORATORY_PATIENT_DATA
} from "../../../actions/stateManagement";

import Laboratory from '../LaboratoryDetails/Laboratory';
import AddLaboratory from '../Laboratories/AddLaboratory';
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";

let addLaboratoryTabIndex = 0;

export const getAddLaboratoryTabIndex = () => {

    return addLaboratoryTabIndex++;
};

export const getLaboratoryIds = (state, ownProps) => {

    const laboratoryDataLoadedId = `${SM_LABORATORY_DATA.loaded}_${ownProps.laboratoryId}`;
    const laboratoryDataId = `${SM_LABORATORY_DATA.id}_${ownProps.laboratoryId}`;

    let laboratoryDataLoaded = state.laboratories[laboratoryDataLoadedId];
    laboratoryDataLoaded = laboratoryDataLoaded === undefined ? false : laboratoryDataLoaded;

    const laboratoryDetailsLoadedId = `${SM_LABORATORY_DETAILS_DATA.loaded}_${ownProps.laboratoryId}`;
    const laboratoryDetailsId = `${SM_LABORATORY_DETAILS_DATA.id}_${ownProps.laboratoryId}`;

    let laboratoryDetailsLoaded = state.laboratories[laboratoryDetailsLoadedId];
    laboratoryDetailsLoaded = laboratoryDetailsLoaded === undefined ? false : laboratoryDetailsLoaded;

    const laboratoryPatientsLoadedId = `${SM_LABORATORY_PATIENT_DATA.loaded}_${ownProps.laboratoryId}`;
    const laboratoryPatientsId = `${SM_LABORATORY_PATIENT_DATA.id}_${ownProps.laboratoryId}`;

    let laboratoryPatientsLoaded = state.laboratories[laboratoryPatientsLoadedId];
    laboratoryPatientsLoaded = laboratoryPatientsLoaded === undefined ? false : laboratoryPatientsLoaded;

    return {
        laboratoryDataId,
        laboratoryDataLoaded,
        laboratoryDetailsId,
        laboratoryDetailsLoaded,
        laboratoryPatientsId,
        laboratoryPatientsLoaded,
    }
};

export const laboratoryDetailsToolBar = (callbacks, saveIcon, canSave, activeIndex) => {

    return {
        left:
            <React.Fragment>
                {tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text)}
            </React.Fragment>,

        right: <React.Fragment>
            {tb_boilerPlateRight((e) => window.open(HELP_LABORATORY_INDEXS[activeIndex]), ICON_HELP, 'Knowledge Base', 1, true)}
            {tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text)}
        </React.Fragment>
    }
};

export const onShowLaboratoryDetailsPage = (props, row) => {

    const onCloseClick = props.onCloseClick;

    const onAdditionalClick = props.onPCButtonClick;

    const onTabUpdate = props.onTabUpdate;

    const {id, name} = row;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_LABORATORY_DATA.id}_${id}`} header={name}
                          leftIcon={ICON_LAB}>
                    <Laboratory onCloseClick={onCloseClick}
                              onTabUpdate={onTabUpdate}
                              laboratoryId={id.toString()}
                              onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_LABORATORY_DATA.id}_${id}`} header={`${name} *`}
                          leftIcon={ICON_LAB}>
                    <Laboratory onCloseClick={onCloseClick}
                              onTabUpdate={onTabUpdate}
                              laboratoryId={id.toString()}
                              onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )
};

export const showAddLaboratoryPage = (props) => {

    const onCloseClick = props.onCloseClick;

    const onAdditionalClick = props.onPCButtonClick;

    const onTabUpdate = props.onTabUpdate;

    const keyId = `${SM_LABORATORY_ADD.id}_${addLaboratoryTabIndex++}`;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={keyId}
                          header={SM_LABORATORY_ADD.label}
                          leftIcon={`fa ${SM_LABORATORY_ADD.tabIcon}`}>
                    <AddLaboratory onCloseClick={onCloseClick}
                                   onTabUpdate={onTabUpdate}
                                   id={keyId}
                                   onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={keyId}
                          header={`${SM_LABORATORY_ADD.label} *`}
                          leftIcon={`fa ${SM_LABORATORY_ADD.tabIcon}`}>
                    <AddLaboratory onCloseClick={onCloseClick}
                                   onTabUpdate={onTabUpdate}
                                   id={keyId}
                                   onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )

};

export default class LaboratoryErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>Something went wrong in the Laboratory section.</h1>
                    <h2>{__CHIRAL_CONTACT_DETAILS__}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
