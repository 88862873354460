import * as Actions from './index';
import {ac} from '../index'
import moment from 'moment'
import {JSON_DATE_FORMAT} from "../Constants";
import {CLEAR_PAYMENT_PLAN_PDF_BY_ID, ISSUE_PP_DUE_INVOICE} from "./index";

const baseURL = '/PaymentPlanning';

export const RES_PAYMENT_PLANNING = {
    SAVE: {action: Actions.SAVE_PAYMENT_PLAN},
    CLEAR: {action: Actions.CLEAR_PAYMENT_PLAN},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_PAYMENT_PLAN},
    ISSUE: {request: Actions.REQUEST_PP_ISSUE_INVOICES, receive: Actions.RECEIVE_PP_ISSUE_INVOICES, url: `${baseURL}/issuePaymentPlanInvoices`},
    SUSPEND: {action: Actions.SUSPEND_PAYMENT_PLAN, url: `${baseURL}/suspend`},
    RESUME: {action: Actions.RESUME_PAYMENT_PLAN, url: `${baseURL}/resume`},
    WRITE_OFF: {action: Actions.WRITE_OFF_PAYMENT_PLAN, url: `${baseURL}/writeOff`},
    SUSPEND_PAT: {action: Actions.SUSPEND_PAYMENT_PLAN_PAT, url: `${baseURL}/suspend`},
    RESUME_PAT: {action: Actions.RESUME_PAYMENT_PLAN_PAT, url: `${baseURL}/resume`},
    WRITE_OFF_PAT: {action: Actions.WRITE_OFF_PAYMENT_PLAN_PAT, url: `${baseURL}/writeOff`},
};

export const RES_PAYMENT_PLAN_HISTORY = {
    GET: {url: `${baseURL}/history`, request: Actions.REQUEST_PP_HISTORY, receive: Actions.RECEIVE_PP_HISTORY},
    CLEAR: {action: Actions.CLEAR_PP_HISTORY},
};

export const RES_PAYMENT_PLAN_BY_ID = {
    GET: {
        url: `${baseURL}/paymentPlanById`,
        request: Actions.REQUEST_PAYMENT_PLAN_BY_TP_ID,
        receive: Actions.RECEIVE_PAYMENT_PLAN_BY_TP_ID
    },
    INSTALLMENTS: {
        url: `${baseURL}/paymentPlanInstallments`,
        request: Actions.REQUEST_PAYMENT_PLAN_INSTALLMENTS,
        receive: Actions.RECEIVE_PAYMENT_PLAN_INSTALLMENTS
    },
    CLEAR: {action: Actions.CLEAR_PAYMENT_PLAN},
    CLEAR_PDF: {action: Actions.CLEAR_PAYMENT_PLAN_PDF},
    ADD: {action: Actions.ADD_PAYMENT_PLAN, url: `${baseURL}/addPaymentPlan`},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_PAYMENT_PLAN},
    SAVE: {action: Actions.SAVE_PAYMENT_PLAN, url: `${baseURL}/savePaymentPlan`},
    DELETE: {action: Actions.DELETE_PAYMENT_PLAN, url: `${baseURL}/paymentPlan`},
    PDF: {
        url: `${baseURL}/PDFs/show`,
        request: Actions.REQUEST_PAYMENT_PLAN_PDF,
        receive: Actions.RECEIVE_PAYMENT_PLAN_PDF
    },
};

export const RES_PP_DUE_INSTALLMENTS = {
    GET: {url: `${baseURL}/installmentsDue`, request: Actions.REQUEST_PP_DUE_INSTALLMENTS, receive: Actions.RECEIVE_PP_DUE_INSTALLMENTS},
    ISSUE: {url: `${baseURL}/issuePaymentPlanInvoice`, request: Actions.REQUEST_PP_ISSUE_INVOICE, receive: Actions.RECEIVE_PP_ISSUE_INVOICE},
    CLEAR: {action: Actions.CLEAR_PP_DUE_INSTALLMENTS},
};

export const getResource = ({url, request, receive}, id) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(res => {

                dispatch({type: receive, id: id, payload: res.data})
            });
        dispatch({type: request, id: id, payload: false});
    }
};

export const issuePaymentPlanInvoices = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}&token=${params.token}&issuedById=${params.issuedById}`)
            .then((res) => {
                dispatch({type: receive, payload: res.data});
            });
        dispatch({type: request, payload: true});
    }
};

export const issueInstalmentInvoice = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}&installmentId=${params.installmentId}&issuedById=${params.issuedById}`)
            .then((res) => {
                dispatch({type: receive, payload: res.data});
            });
        dispatch({type: request, payload: true});
    }
};

export const deletePaymentPlan = ({url, action}, patientId, planId) => {

    return (dispatch) => {
        ac.fetchClient().delete(`${url}/?mcId=${ac.getMcId()}&id=${planId}`)
            .then((res) => {
                dispatch({type: action, payload: res.data, patientId, planId});
            });
    }
};

export const changeStatus = ({url, action}, plan) => {

    const params = {
        id: plan.id,
        changedById: plan.changedById,
        narrative: plan.narrative,
        suspendUntil: moment(plan.suspendUntil).format(JSON_DATE_FORMAT),
    };

    return (dispatch) => {
        ac.fetchClient().get(`${url}/?mcId=${ac.getMcId()}`, {params: {...params}})
            .then((res) => {
                dispatch({type: action, payload: plan});
            });
    }
};
