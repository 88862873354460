import {ICON_SEND} from "../../../icons"

export const LimitReached = {
    id: 0,
    header: 'Limit Reached',
    enum: 'LimitReached',
    nextEnum: 'Com1',
    text: 'Send Communication 1',
    icon: ICON_SEND,
    hide: false,
};

export const Com1 = {
    id: 1,
    header: 'Communication 1 Sent',
    enum: 'Com1',
    nextEnum: 'Com2',
    text: 'Send Communication 2',
    icon: ICON_SEND,
    hide: false,
};

export const Com2 = {
    id: 2,
    header: 'Communication 2 Sent',
    enum: 'Com2',
    nextEnum: 'Com3',
    text: 'Send Communication 3',
    icon: ICON_SEND,
    hide: false,
};

export const Com3 = {
    id: 3,
    header: 'Communication 3 Sent',
    enum: 'Com3',
    nextEnum: 'Deactivated',
    text: 'Send Account Deactivation',
    icon: ICON_SEND,
    hide: false,
};

export const Deactivated = {
    id: 4,
    header: 'Deactivated Recalls',
    enum: 'Deactivated',
    nextEnum: '',
    text: '',
    icon: '',
    hide: true,
};
