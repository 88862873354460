import {TAB_CHANGE, TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE, TAB_SAVE} from "../Housekeeping/Constants";
import {Component} from "react";
import _ from "lodash";

export class MailShotsBase extends Component {

    constructor(props) {
        super(props);
    }

    onSendMailShot = () => {

        this.props.sendMailShot({msId: this.state.selectedMS.id});
    }

    onSendMailShotTest = () => {

        this.props.sendMailShotTest({msId: this.state.selectedMS.id, to: this.props.loginIdentity.id});
    }

    onAddMSTab = (shot) => {

        const index = _.findIndex(this.state.selectedShots, (target) => target.id === shot.id);

        const selectedShots = [...this.state.selectedShots];

        if (index === -1) {
            selectedShots.push(shot);

            this.setState({selectedShots, canSave: {...this.state.canSave, activeIndex: selectedShots.length}});
        } else {
            this.setState({selectedShots, canSave: {...this.state.canSave, activeIndex: index + 1}});
        }
    }

    showDialogs() {
        return null;
    }

    onChange = (event) => {

        const newState = {...this.state};

        if (event === null) {
            newState.canSave.status = true;
            newState.canSave.source = this.source;

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            })
        } else if (event.owner === 'selectedMS') {

            _.set(newState, event.owner, event.value);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else if (event.owner === 'canSave.status') { // this will be propagated up from the sub tabs

            _.set(newState, event.owner, event.value);
            newState.canSave.status = true;
            newState.canSave.source = this.source;

            const index = _.findIndex(this.state.selectedShots, (target) => target.id === this.state.selectedMS.id);

            const shot = {...newState.selectedShots[index]};
            shot.save = event.value;
            newState.selectedMS = shot;

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        } else {

            _.set(newState, event.owner, event.value);
            newState.canSave.status = true;
            newState.canSave.source = this.source;

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        }
    }

    onTabChange = (index) => {

        this.exitState = TAB_CHANGE;
        const {canSave} = this.state;

        canSave.activeIndex = index;

        this.setState({canSave}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });
    }

    onCloseRequest = (action) => {
        const newState = {...this.state};
        this.exitState = TAB_EXIT_SAVE;

        newState.showSaveDialog = true;
        newState.canSave.source.action = action;

        this.setState(newState, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
        });
    }

    onClose = () => {
        this.props.onTabCloseClick({key: this.state.stateManagementId});
    }

    onExit = (action) => {
        const newState = {...this.state};
        this.exitState = TAB_EXIT;

        newState.showSaveDialog = false;
        newState.canSave.source.action = action;

        this.setState(newState, () => {
            this.props.onTabCloseClick({key: this.state.stateManagementId});
        });
    }

    onSaveNoDialog = () => {

        this.exitState = TAB_PARENT_CHANGE;
        const {canSave} = this.state;

        if (canSave.source.saveState) {
            this.props.stateRequest(canSave.source);
        }

        if (canSave.source.saveObjects) {
            this.props.setObjectStore(canSave.source);
        }
    }

    componentWillUnmount() {

        if (this.state.canSave.source) {

            switch (this.exitState) {
                case TAB_EXIT:

                    this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    this.props.stateRequest(this.state.canSave.source);
                    this.props.setState(this.state.stateManagementId, null);
                    break;

                case TAB_EXIT_SAVE:

                    this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    this.props.stateRequest(this.state.canSave.source);
                    this.props.setState(this.state.stateManagementId, null);
                    break;

                case TAB_CHANGE:
                case TAB_PARENT_CHANGE:

                    this.props.setState(this.state.stateManagementId, {...this.state});
                    break;

                default:
                    break;
            }
        }
    }
}
