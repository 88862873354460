import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from "primereact/inputtext";
import {Panel} from 'primereact/components/panel/Panel';
import {ProgressBar} from "primereact/progressbar";
import {ScrollPanel} from 'primereact/scrollpanel';
import _ from "lodash";
import {DOC_ORTHO_REFERRER_TEMPLATE, DOC_REFERRAL_TEMPLATE, HM_addReferrerDocument} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getResource2, RES_TEMPLATES_REFERRAL} from "../../../../actions/housekeeping";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate, referrerTemplate} from "../../../PatientDynamicItems/Utils";
import {getAllReferrers} from "../../../../actions/findReferrers";
import * as Actions from "../../../../actions";

export class ConnectedAddReferrerDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {

            details: {
                templateId: null,
                referrerId: null,
                patientId: props.patientId,
                visibleName: '',
                notes: '',
                createdBy: props.loginIdentity.id,
            },
            globalFilter: '',

            selectedTemplate: null,
            firstReferrers: 0,
            firstTemps: 0,
            rowsReferrers: 5,
            rowsTemps: 5,
        };

        this.onChange = this.onChange.bind(this);
        this.onPageReferrers = this.onPageReferrers.bind(this);
        this.onPageTemps = this.onPageTemps.bind(this);
        this.onReferrerSelection = this.onReferrerSelection.bind(this);
        this.onTemplateSelection = this.onTemplateSelection.bind(this);
    }

    componentDidMount() {

        this.props.getAllReferrers();
        this.props.getTemplates([DOC_REFERRAL_TEMPLATE.name, DOC_ORTHO_REFERRER_TEMPLATE.name]);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message.type !== prevProps.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_REFERRER_SEARCH:

                    const selectedReferrerIndex = _.findIndex(this.props.referrers, referrer => {
                        if (this.props.patientData === undefined)
                            return false;
                        return this.props.patientData.referrer === null ? false : referrer.id === this.props.patientData.referrer.id;
                    });

                    if (selectedReferrerIndex > -1) {
                        const selectedReferrer = this.props.referrers[selectedReferrerIndex];
                        const details = {...this.state.details};
                        details.referrerId = selectedReferrer.id;
                        this.setState({selectedReferrer, details});
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onReferrerSelection(event) {

        const details = {...this.state.details};
        details.referrerId = event.value.id;

        this.setState({selectedReferrer: event.value, details});
    }

    onTemplateSelection(event) {

        const visibleName = `${event.value.visibleName} - ${dateTemplate(new Date())}`;
        const details = {...this.state.details};
        details.visibleName = visibleName;
        details.templateId = event.value.docId;
        details.template = event.value;

        this.setState({selectedTemplate: event.value, details});
    }

    onPageReferrers(e) {
        this.setState({firstReferrers: e.first, rowsReferrers: e.rows})
    }

    onPageTemps(e) {
        this.setState({firstTemps: e.first, rowsTemps: e.rows})
    }

    findPatientsReferrer = (referrers) => {

        const selectedReferrerIndex = _.findIndex(referrers, referrer => {
            if (this.props.patientData === undefined)
                return false;
            return this.props.patientData.referrer === null ? false : referrer.id === this.props.patientData.referrer.id;
        });

        if (selectedReferrerIndex > -1) {
            return selectedReferrerIndex - 1;
        } else {
            return null;
        }
    }

    render() {

        if (!this.props.referralTemplatesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        let referrers = _.filter(this.props.referrers, referrer => {
            const pattern = `${referrer.firstName} ${referrer.lastName} ${referrer.companyName}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const sortedReferrers = _.orderBy(referrers, ['lastName', 'firstName', 'companyName'], ['asc', 'asc', 'asc']);

        const selectedReferrerIndex = this.findPatientsReferrer(sortedReferrers);

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{HM_addReferrerDocument.header}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => {
                               this.setState({globalFilter: e.target.value, firstReferrers: 0});
                           }}
                           placeholder="Search"
                           size="50"
                           autoFocus
                />
            </div>
        </div>;

        const docNotDefined = this.state.selectedTemplate === null || this.state.selectedReferrer === null;

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.details);
                        }}
                        disabled={docNotDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_addReferrerDocument.id)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'details',
            details: this.state.details,
        };

        return (
            <Dialog header={header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_addReferrerDocument.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    <DataTable value={sortedReferrers}
                               className='p-datatable-gridlines'
                               header='Referrers'
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsReferrers}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPageReferrers}
                               first={selectedReferrerIndex}
                               selection={this.state.selectedReferrer}
                               onSelectionChange={this.onReferrerSelection}
                               style={{fontSize: 'small'}}
                    >

                        <Column body={(row) => referrerTemplate(row)} header='Name' style={{width: '50%'}}/>
                        <Column field="workTelephone" header='Telephone' style={{width: '50%'}}/>

                    </DataTable>
                    <DataTable value={this.props.referralTemplates}
                               className='p-datatable-gridlines'
                               header='Templates'
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsTemps}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPageTemps}
                               first={this.state.firstTemps}
                               selection={this.state.selectedTemplate}
                               onSelectionChange={this.onTemplateSelection}
                               style={{fontSize: 'small', paddingTop: '5px'}}
                    >

                        <Column field="visibleName" header='Template Name' style={{width: '50%'}}/>
                        <Column field="notes" header='notes' style={{width: '50%'}}/>

                    </DataTable>
                    <Panel header='Document Details' style={{paddingTop: '5px'}}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-3">
                                <label>Document Name</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'visibleName', 'Document Name ...', false, true)}
                            </div>

                            <div className="p-col-3">
                                <label>Notes</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'notes', 5, 100, false, false)}
                            </div>
                        </div>
                    </Panel>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        referrersLoaded: state.findReferrer.searchComplete,
        referrers: state.findReferrer.results,

        referralTemplatesLoaded: state.housekeeping.referralTemplatesLoaded,
        referralTemplates: state.housekeeping.referralTemplates,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        getAllReferrers: () => dispatch(getAllReferrers()),
        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_REFERRAL.GET, types)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddReferrerDocument = connect(mapStateToProps, MapDispatchToProps)(ConnectedAddReferrerDocument);

export default AddReferrerDocument;
