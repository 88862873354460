import React from 'react';
import _ from "lodash";
import {connect} from "react-redux";

import {Column} from "primereact/column";
import {ContextMenu} from "primereact/contextmenu";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {
    CLINICIAN,
    JSON_DATE_FORMAT,
    SU_STATUS_ACTIVE,
    TB_SAVE_AND_EXIT,
    UTILISATION_REPORT_INTERVALS,
    UTILISATION_REPORT_WEEKLY
} from "../../../Constants";
import {ICON_EXCEL, ICON_HELP,} from "../../../icons";
import {CONST_FEMALE} from "../../PatientDynamicItems/OnChangeUtils";
import * as Actions from "../../../actions";
import {dateTemplateMonthYear, dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {TB_PATIENT_DETAILS} from "../PatientDetails/Constants";
import {ProgressBar} from "primereact/progressbar";
import {HELP_REP_TREATMENT_CODES, setState} from "../../../actions/stateManagement";
import {ac} from "../../../index";
import {getResource as getCHResource, RES_chartResources} from "../../../actions/ChartResources";
import {TreatmentCodeUsageHeadings} from "./Constants";
import {getAllUsers} from "../../../actions/users";
import {getReportService, RES_REPORT_TREATMENT_CODE_USAGE} from "../../../actions/reports";
import moment from "moment";
import {Calendar} from "primereact/calendar";
import ReportErrorBoundary from "./Utils";
import {
    tb_boilerPlate,
    tb_boilerPlateRight,
    tb_boilerPlateRight2,
    tb_boilerPlateRightText,
    tb_dropDownLeft
} from "../../Utils";
import {DIARY_BACK_MONTH, DIARY_FOR_MONTH, DIARY_FOR_WEEK} from "../Diary/Constants";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {getResource, RES_HOUSEKEEPING_TCODES} from "../../../actions/housekeeping";
import {getHousekeepingIds} from "../Housekeeping/Utils";
import {converter} from "../fixedItemUtils";

export class ConnectedTreatmentCodeSummary extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {
                currentDate: moment(new Date()).clone().startOf('isoWeek').toDate(),

                treatmentCodeUsage: [],
                codeTotal: 0,
                patientTotal: 0,
                chargeTotal: 0.0,

                favourites: [],
                providers: [],

                selections: {
                    favourite: {items: []},
                    code: {id: -1},
                    provider: {id: -1},
                    treatmentCodeUsage: {id: null, gender: CONST_FEMALE},
                },

                first: 0,
                rows: 5,
                interval: UTILISATION_REPORT_WEEKLY
            }
        }

        this.toolbarCallbacks = {

            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
        }
    }

    componentDidMount() {

        this.props.getAllUsersShort();
        this.props.getChartResources();

        if (!this.props.treatmentCodesLoaded) {
            this.props.getHousekeepingTCodes();
            this.props.getReport({
                currentItem: this.state.selections.code.id,
                currentProvider: this.state.selections.provider.id,
                lower: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                upper: moment(this.state.currentDate).clone().endOf('isoWeek').format(JSON_DATE_FORMAT),
            });
            this.props.setState(this.props.id, this.state);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_CHART_RESOURCES:

                    const Any = {
                        "id": -1,
                        "mc": 1,
                        "description": "Any",
                        "code": "Any",
                        "importReference": null,
                        "cost": 0,
                        "costToPractice": 0,
                        "scope": "",
                        "triggerForRecall": false,
                        "cancellationCharge": false,
                        "discountCharge": false,
                        "triggerForTreatmentStart": false,
                        "triggerForTreatmentEnd": false,
                        "strings": null,
                        "status": "USABLE",
                        "chartingItem": null
                    };

                    let allCodes = [Any, ..._.map(this.props.treatmentCodes.items, item => ({...item, index: 0}))];

                    const All = {
                        id: -1,
                        mc: ac.getMcId(),
                        description: 'All',
                        items: allCodes,
                        default: true,
                    };

                    const favourites = [All, ..._.map(this.props.resources.favourites, favourite => ({
                        ...favourite,
                        index: 0
                    }))];

                    const providers = _.filter(this.props.usersShort, provider => provider.status === SU_STATUS_ACTIVE && provider.username !== 'superuser' && provider.username !== 'TED_superuser' && provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);

                    const AllProviders = {
                        id: -1,
                        mc: ac.getMcId(),
                        fullName: 'All',
                        status: SU_STATUS_ACTIVE,
                        myRole: CLINICIAN,
                        default: true,
                    };

                    const allProviders = [AllProviders, ..._.map(providers, provider => ({...provider, index: 0}))];

                    const selections = {...this.state.selections};
                    selections.favourite = _.find(favourites, favourite => favourite.default);
                    selections.provider = _.find(allProviders, provider => provider.default);

                    this.setState({
                        providers: allProviders,
                        favourites,
                        selections,
                    });
                    break;

                case Actions.RECEIVE_REPORT_TREATMENT_CODE_USAGE:

                    let codeTotal = 0;
                    let chargeTotal = 0.0;
                    this.props.treatmentCodeUsage.forEach(code => {
                        codeTotal += code.quantity;
                        chargeTotal += (code.quantity * code.chargedPrice);
                    });

                    const patientTotal = _.size(_.countBy(this.props.treatmentCodeUsage, (usage) => {
                        return usage.patientId
                    }));

                    this.setState({
                        treatmentCodeUsage: this.props.treatmentCodeUsage,
                        codeTotal,
                        patientTotal,
                        chargeTotal
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = () => {

        const {patientId, firstName, lastName, gender, nhsPatient, groupId} = this.state.selections.treatmentCodeUsage;
        showPatientDetailsPage({props: this.props, id: patientId, firstName, lastName, gender, nhsPatient, groupId});
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {
            case 'selections.favourite' :
                state.selections.favourite = event.value;
                break;
            case 'selections.code' :
                state.selections.code = event.value;
                break;
            case 'selections.provider' :
                state.selections.provider = event.value;
                break;
            case 'selections.treatmentCodeUsage' :
                state.selections.treatmentCodeUsage = event.value;
                return;
            case 'interval.value' :
                _.set(state, 'interval', _.find(UTILISATION_REPORT_INTERVALS, type => type.value === event.value));

                if (state.interval.unit === 'M') {
                    state.currentDate = moment(new Date()).startOf('month').toDate();
                } else {
                    state.currentDate = moment(new Date()).startOf('isoWeek').toDate();
                }
                break;
            case 'currentDate' :
                if (state.interval.unit === 'M') {
                    state.currentDate = moment(event.value).startOf('month').toDate();
                } else {
                    state.currentDate = event.value;
                }
                break;
            case 'back' :
                state.currentDate = moment(state.currentDate).add(-1, state.interval.unit).toDate();
                break;
            case 'forward' :
                state.currentDate = moment(state.currentDate).add(1, state.interval.unit).toDate();
                break;
            default:
                break;
        }

        state.first = 0;
        state.selections.treatmentCodeUsage = {id: null, gender: CONST_FEMALE};

        const upper = state.interval.unit === 'M' ? moment(state.currentDate).endOf('month') : moment(state.currentDate).endOf('week');

        this.setState(state, () => {
            this.props.getReport({
                currentItem: this.state.selections.code.id,
                currentProvider: this.state.selections.provider.id,
                lower: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                upper: upper.format(JSON_DATE_FORMAT),
            });
            this.props.setState(this.props.id, this.state);
        });
    }

    patientTemplate(row) {
        return `${row.firstName} ${row.lastName}`.trim();
    }

    providerTemplate(row) {
        return `${row.suFirstName} ${row.suLastName}`.trim();
    };

    itemTemplate(row) {

        const item = _.filter(this.props.treatmentCodes.items, code => code.id === row.itemId);

        if (item.length > 0) {
            return `${item[0].description}`;
        } else {
            return '-'
        }
    };

    priceTemplate(row) {
        try {
            return `${converter.format(row.quantity * row.chargedPrice)}`.trim();
        } catch (error) {
            console.log(error);
        }
    };

    buildMenu = () => {

        if (this.state.selections.patientJourney === null)
            return [];

        const detailsIcon = this.state.selections.treatmentCodeUsage.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;

        const items = [
            {
                label: TB_PATIENT_DETAILS.text, icon: detailsIcon, command: (e) => {
                    const {
                        patientId,
                        firstName,
                        lastName,
                        gender,
                        nhsPatient
                    } = this.state.selections.treatmentCodeUsage;
                    const patientDetails = {id: patientId, firstName, lastName, gender, nhsPatient};
                    this.toolbarCallbacks[TB_PATIENT_DETAILS.id](patientDetails);
                }
            }];
        return items;
    }

    showDialogs = () => {

        const contents = [];
        return contents;
    }

    render() {

        if (!this.props.resourcesLoaded || !this.props.usersLoaded || !this.props.treatmentCodesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const sortedFavourites = _.orderBy(this.state.favourites, [['index', 'asc'], ['description', 'asc']]);

        const favourite = sortedFavourites === null ? undefined : _.find(sortedFavourites, favourite => (favourite.id === this.state.selections.favourite.id));

        const sortedProviders = _.orderBy(this.state.providers, [['index', 'asc'], ['lastName', 'asc', 'firstName', 'asc']]);

        const provider = sortedProviders === null ? undefined : _.find(sortedProviders, provider => (provider.id === this.state.selections.provider.id));

        const sortedItems = _.orderBy(this.state.selections.favourite.items, [['index', 'asc'], ['description', 'asc']]);

        const item = sortedItems === null ? undefined : _.find(sortedItems, item => (item.id === this.state.selections.code.id));

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title'
                      style={{marginRight: 15}}>Treatment Code Usage : {`(${dateTemplateMonthYear(this.state.currentDate)})`}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <label id='panel-header'>{` Favourites `}</label>
                <Dropdown id="pj1"
                          placeHolder='Favourites'
                          value={favourite}
                          options={sortedFavourites}
                          optionLabel={`description`}
                          onChange={e => {
                              this.onChange({owner: `selections.favourite`, value: e.value})
                          }}
                />
                <label id='panel-header'>{` Treatment Code `}</label>
                <Dropdown id="pj1"
                          placeHolder='Treatment Code'
                          value={item}
                          options={sortedItems}
                          optionLabel={`description`}
                          onChange={e => {
                              this.onChange({owner: `selections.code`, value: e.value})
                          }}
                />
                <label id='panel-header'>{` Clinician `}</label>
                <Dropdown id='pj2'
                          placeHolder='Clinician'
                          value={provider}
                          options={sortedProviders}
                          optionLabel={`fullName`}
                          onChange={e => {
                              this.onChange({owner: `selections.provider`, value: e.value})
                          }}
                />
            </div>
        </div>;

        const sortedTreatmentCodeUsages = _.orderBy(this.state.treatmentCodeUsage, [(o) => {
            return moment(o.chargedOn).format('YYYY-MM-DDTHH:mm');
        }, 'lastName'], ['asc', 'asc']);

        let key = 1000;

        const intervalText = this.state.interval.text;

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate(() => this.onChange({owner: 'back'}), DIARY_BACK_MONTH.icon, `Back a ${intervalText}`, key++)}
                            {tb_boilerPlate(() => this.onChange({owner: 'forward'}), DIARY_FOR_MONTH.icon, `Forward a ${intervalText}`, key++)}
                            {tb_dropDownLeft((e) => this.onChange(e), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, UTILISATION_REPORT_INTERVALS, this.state.interval, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat={this.state.interval.unit === 'M' ? "mm/yy" : "dd/mm/yy"}
                                      disabledDays={[0, 2, 3, 4, 5, 6]}
                                      onChange={(e) => this.onChange({owner: 'currentDate', value: e.value})}
                                      view={this.state.interval.unit === 'M' ? "month" : undefined}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRightText(key++, `Total ${converter.format(this.state.chargeTotal)}`, true)}
                            {tb_boilerPlateRightText(key++, `No of Patients ${this.state.patientTotal}`, true)}
                            {tb_boilerPlateRightText(key++, `Code Count ${this.state.codeTotal}`, true)}
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download  ${intervalText} Excel File`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_TREATMENT_CODES), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                        <ContextMenu style={{width: '210px'}} model={this.buildMenu()} ref={el => this.cm = el}/>

                        <DataTable value={sortedTreatmentCodeUsages}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selections.treatmentCodeUsage}
                                   onSelectionChange={e => this.onChange({
                                       owner: `selections.treatmentCodeUsage`,
                                       value: e.value
                                   })}
                                   contextMenuSelection={this.state.selections.patient}
                                   onContextMenuSelectionChange={e => this.onChange({
                                       owner: `selections.treatmentCodeUsage`,
                                       value: e.value
                                   })}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent)
                                   }}
                        >

                            <Column field="quantity"
                                    header={TreatmentCodeUsageHeadings[0]}
                                    style={{width: '5%', textAlign: 'right'}}/>
                            <Column body={row => this.priceTemplate(row)}
                                    header={TreatmentCodeUsageHeadings[5]}
                                    style={{width: '10%', textAlign: 'right'}}/>
                            <Column body={row => dateTemplateShort(row.chargedOn)}
                                    header={TreatmentCodeUsageHeadings[1]}
                                    style={{width: '10%'}}/>
                            <Column body={row => this.itemTemplate(row)}
                                    header={TreatmentCodeUsageHeadings[2]}
                                    style={{width: '35%'}}/>
                            <Column body={row => this.providerTemplate(row)}
                                    header={TreatmentCodeUsageHeadings[3]}
                                    style={{width: '20%'}}/>
                            <Column body={row => this.patientTemplate(row)}
                                    header={TreatmentCodeUsageHeadings[4]}
                                    style={{width: '20%'}}/>
                        </DataTable>

                        {this.showDialogs()}
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        resourcesLoaded,
        resources,
        treatmentCodesLoaded,
        treatmentCodes,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        treatmentCodeUsageLoaded: state.reports.treatmentCodeUsageLoaded,
        treatmentCodeUsage: state.reports.treatmentCodeUsage,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {

        getAllUsersShort: () => dispatch(getAllUsers()),
        getChartResources: () => dispatch(getCHResource(RES_chartResources.GET)),
        getHousekeepingTCodes: () => dispatch(getResource(RES_HOUSEKEEPING_TCODES.GET, {})),
        getReport: (params) => dispatch(getReportService(RES_REPORT_TREATMENT_CODE_USAGE.GET, params)),

        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const TreatmentCodeSummary = connect(mapStateToProps, mapDispatchToProps)(ConnectedTreatmentCodeSummary);

export default TreatmentCodeSummary;
