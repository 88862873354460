import {
    DOC_Email,
    DOC_IMAGE,
    DOC_ORTHO_REFERRAL,
    DOC_PDF,
    DOC_REFERRAL,
    DOC_SMS_INCOMING,
    DOC_SMS_OUTGOING,
    DOC_TREATMENT_PLAN,
    DOC_TYPES,
    DOC_UPLOAD_MSWORD,
    DOC_UPLOAD_SCAN_GIF,
    DOC_UPLOAD_SCAN_JPG,
    DOC_UPLOAD_SCAN_PDF,
    DOC_UPLOAD_SCAN_TIF,
    DOC_UPLOAD_WORD,
    DOC_UPLOAD_XRAY_GIF,
    DOC_UPLOAD_XRAY_JPG,
    DOC_UPLOAD_XRAY_TIF,
    DOC_WORD,
    DOC_XRAY,
    HM_DeleteConsentDocument,
    HM_DeleteEmailSMS,
    HM_DeletePatientDocument,
    HM_DeletePaymentPlanDocument,
    HM_DeletePresDocument,
    HM_DeleteReferralDocument,
    HM_DeleteTreatmentPlanDocument,
    HM_DeleteXrayScanDocument,
    HM_EditConsentDocument,
    HM_EditEmailSMS,
    HM_EditPatientDocument,
    HM_EditPaymentPlanDocument,
    HM_EditPresDocument,
    HM_EditReferralDocument,
    HM_EditTreatmentPlanDocument,
    HM_EditXrayScanDocument,
    HM_EmailConsentDocument,
    HM_EmailPatientDocument,
    HM_EmailPaymentPlanDocument,
    HM_EmailPresDocument,
    HM_EmailReferralDocument,
    HM_EmailTreatmentPlanDocument,
    HM_EmailXrayScanDocument,
    HM_notImplemented,
    HM_PreviewPatientConsent,
    HM_PreviewPatientDocument,
    HM_PreviewPatientPaymentPlan,
    HM_PreviewPatientPrescription,
    HM_PreviewPatientReferral,
    HM_PreviewPatientTreatmentPlan,
    HM_PreviewPatientXray,
    HM_PrintConsentDocument,
    HM_PrintPatientDocument,
    HM_PrintPaymentPlanDocument,
    HM_PrintPresDocument,
    HM_PrintReferralDocument,
    HM_PrintTreatmentPlanDocument,
    REP_ConsentForm,
    REP_OrthoRefLetter,
    REP_PaymentPlan,
    REP_Prescription,
    REP_TreatmentPlan,
    REPORT_SHOW
} from "../../../Constants";
import React from "react";
import _ from "lodash";
import * as icons from "../../../icons";
import {ICON_COPY, ICON_EMAIL, ICON_EXCEL} from "../../../icons";
import {Editor} from "primereact/components/editor/Editor";

export const PAT_SUMMARY = 0;
export const PAT_TREATMENT = 1;
export const PAT_ACCOUNT = 2;
export const PAT_RECALLS = 3;
export const PAT_REMINDERS = 4;
export const PAT_PERSONAL = 5;
export const PAT_BILLING_GROUP = 6;
export const PAT_NOTES = 7;
export const PAT_MEDHISTORY = 8;
export const PAT_DOCUMENTS = 9;
export const PAT_IMAGES = 10;
export const PAT_ORTHO = 11;
export const PAT_PATASS = 12;
export const PAT_NHS_REG = 13;
export const PAT_NHS_CLAIMS = 14;
export const PAT_PERIO = 15;

export const ICON_PATIENT_MALE = 'fas fa-male';
export const ICON_PATIENT_FEMALE = 'fas fa-female';

export const ICON_PATIENT_MALE_24 = 'fa patientMale24';
export const ICON_PATIENT_FEMALE_24 = 'fa patientFemale24';

// mail shot related

export const TB_EDIT_MAILSHOT = {
    id: 'TB_EDIT_MAILSHOT',
    text: 'Edit Mail Shot',
    icon: 'fas fa-edit',
};

export const TB_DELETE_MAILSHOT = {
    id: 'TB_DELETE_MAILSHOT',
    text: 'Delete Mail Shot',
    icon: 'fa fa-times',
    header: 'Delete Mail Shot',
    message: 'Are you sure you want to delete this mail shot',
};

export const TB_MAILSHOT_RECIPIENTS = {
    id: 'TB_MAILSHOT_RECIPIENTS',
    text: 'Run Selection',
    icon: 'fas fa-check-square'
};

export const TB_MAILSHOT_REMOVE_RECIPIENT = {
    id: 'TB_MAILSHOT_REMOVE_RECIPIENTS',
    text: 'Remove Patient',
    icon: 'fa fa-times'
};

// patient related

export const TB_PATIENT_DETAILS = {
    id: 'TB_PATIENT_DETAILS',
    text: 'Patient Details',
    maleIcon: ICON_PATIENT_MALE,
    femaleIcon: ICON_PATIENT_FEMALE
};

export const TB_ADD_PATIENT = {id: 'TB_ADD_PATIENT', text: 'Add Patient', icon: 'fas fa-user-plus'};

export const TB_RECALCULATE_PATIENT_ACCOUNTS = {
    id: 'TB_RECALCULATE_PATIENT_ACCOUNTS',
    text: 'Recalculate patient accounts',
    icon: 'fas fa-user-plus'
};

export const TB_ADD_MESS_GROUP = {
    id: 'SM_ADD_MESS_GROUP',
    label: 'Recipients',
    text: 'Add Message Group',
    icon: 'fas fa-user-friends'
};

export const TB_CLICK_TO_CALL = {id: 'TB_CLICK_TO_CALL', text: 'Click to Call this patient', icon: 'fa telephone24'};
export const TB_CLICK_TO_TASK = {id: 'TB_CLICK_TO_TASK', text: 'Create patient task', icon: 'fas fa-thumbtack'};
export const TB_CLICK_TO_SMS = {id: 'TB_CLICK_TO_SMS', text: 'Send this patient an SMS', icon: 'fas fa-sms'};
export const TB_CLICK_TO_EMAIL = {id: 'TB_CLICK_TO_EMAIL', text: 'Send this patient an Email', icon: ICON_EMAIL};

export const TB_PATIENT_RECALL = {id: 'TB_PATIENT_RECALL', text: 'Create patient recall', icon: 'fas fa-user-clock'};
export const TB_PATIENT_LATE_ARRIVER = {
    id: 'TB_PATIENT_LATE_ARRIVER',
    text: 'Mark Late Arriver',
    text2: 'Clear Late Arriver',
    icon: 'far fa-clock',
    icon2: 'fas fa-clock'
};
export const TB_PATIENT_WRITE_OFF_ACCOUNT = {
    id: 'TB_PATIENT_WRITE_OFF_ACCOUNT',
    text: 'Write Off Account',
    text2: 'Reactivate Account',
    icon: 'fas fa-times-circle',
    icon2: 'fas fa-check-circle',
};
export const TB_PATIENT_LATE_PAYER = {
    id: 'TB_PATIENT_LATE_PAYER',
    text: 'Mark Late Payer',
    text2: 'Clear Late Payer',
    icon: 'fas fa-coins',
    icon2: 'fas fa-exclamation-triangle'
};

export const TB_SHOW_TREATMENT_ADD = {id: 'TB_SHOW_TREATMENT_ADD', text: '', icon: ''};
export const TB_TREATMENT_ADD = {id: 'TB_TREATMENT_ADD', text: '', icon: ''};
export const TB_TREATMENT_EDIT = {id: 'TB_TREATMENT_EDIT', text: '', icon: ''};
export const TB_SHOW_TREATMENT_EDIT = {id: 'TB_SHOW_TREATMENT_EDIT', text: '', icon: ''};
export const TB_HIDE_TREATMENT_ADD_EDIT = {id: 'TB_HIDE_TREATMENT_ADD_EDIT', text: '', icon: ''};
export const TB_TREATMENT_DELETE = {id: 'TB_TREATMENT_DELETE', text: '', icon: ''};
export const TB_TREATMENT_COMPLETE = {id: 'TB_TREATMENT_COMPLETE', text: '', icon: ''};

export const TB_USING_BUTTON = {id: 'TB_USING_BUTTON', text: '', icon: ''};

export const TB_EDIT_APPOINTMENT_TITLE = {
    id: 'TB_EDIT_APPOINTMENT_TITLE',
    text: 'Edit appointment title',
    icon: 'fas fa-edit'
};
export const TB_DELETE_APPOINTMENT = {
    id: 'TB_DELETE_APPOINTMENT',
    text: 'Delete this treatment plan appointment',
    icon: 'fa fa-times'
};
export const TB_GOTO_APPOINTMENT = {
    id: 'TB_GOTO_APPOINTMENT',
    text: 'Goto this treatment plan appointment in the diary',
    icon: 'fas fa-sign-out-alt'
};
export const TB_WEEK_GOTO_DAY_DIARY = {
    id: 'TB_WEEK_GOTO_DAY_DIARY',
    text: 'Goto this day in the practice diary',
    icon: 'fas fa-sign-out-alt'
};
export const TB_SET_CURRENT_DATE = {
    id: 'TB_SET_CURRENT_DATE',
};

export const TB_PLACE_APPOINTMENT = {
    id: 'TB_PLACE_APPOINTMENT',
    text: 'Place appointment in diary',
    icon: 'fas fa-sign-in-alt'
};
export const TB_APPOINTMENT_NOT_SPECIFIED = {
    id: 'TB_APPOINTMENT_NOT_SPECIFIED',
    text: 'The appointment is not fully specified',
    icon: 'far fa-square'
};
export const TB_ON_EDIT_APPOINTMENT = {id: 'TB_ON_EDIT_APPOINTMENT_TITLE', text: '', icon: ''};
export const TB_PATIENT_APPOINTMENT = {
    id: 'TB_PATIENT_APPOINTMENT',
    text: 'Create a patient appointment',
    icon: 'fas fa-calendar-plus'
};

export const TB_PATIENT_SALES = {
    id: 'TB_PATIENT_SALES',
    text: 'Patient Sales, Credit and Cash Sales',
    icon: 'fas fa-cash-register',
    icon16: 'fas fa-cash-register'
};

export const TB_PATIENT_SALES_NHS = {
    id: 'TB_PATIENT_SALES_NHS',
    text: 'Commit Appointment',
    icon: 'fas fa-cash-register',
    icon16: 'fas fa-cash-register'
};

export const TB_PATIENT_SALES_NHS_PART2 = {
    id: 'TB_PATIENT_SALES_NHS_PART2',
    text: 'Commit Appointment',
    icon: 'fa invoice24',
    icon16: 'fa invoice16'
};

export const TB_PERFORMER_SELECTOR = {
    id: 'TB_PERFORMER_SELECTOR',
    text: 'Performer',
    icon: '',
    icon16: ''
};

export const TB_PATIENT_SALES_2 = {
    id: 'TB_PATIENT_SALES_2',
    text: 'Patient Sales, Credit and Cash Sales',
    icon: 'fa invoice24'
};

export const TB_TREATMENT_SALES = {
    id: 'TB_TREATMENT_SALES',
    text: 'Sales',
    icon: 'fa invoice24'
};

export const TB_MAKE_PAYMENT = {
    id: 'TB_MAKE_PAYMENT',
    text: 'Make a payment',
    icon: 'fa invoice24'
};

export const TB_RECALC_ACCOUNT = {
    id: 'TB_RECALC_ACCOUNT',
    text: 'Recalculate account',
    icon: 'fa refresh24',
    header: 'Recalculate account',
    message: 'Are you sure you want to recalculate this patient account',
};

export const TB_ISSUE_CREDIT_NOTE = {
    id: 'TB_ISSUE_CREDIT_NOTE',
    text: 'Issue a credit note',
    icon: 'fa invoice24',
    header: 'Issue a credit note',
    message: 'Are you sure you want to issue a credit note against this item',
};

export const TB_ISSUE_REFUND = {
    id: 'TB_ISSUE_REFUND',
    text: 'Issue a refund',
    icon: 'fa invoice24',
    fullyRefundedId: 'TB_ISSUE_REFUND_FULL',
    fullyRefundedText: 'This payment has been fully refunded already',
    zeroRefundedId: 'TB_ISSUE_REFUND_ZERO',
    zeroRefundedText: 'There is nothing to refund',
};

export const TB_MAKE_ADJUSTMENT = {
    id: 'TB_MAKE_ADJUSTMENT',
    text: 'Make an adjustment',
    icon: 'fa invoice24'
};

export const TB_CHANGE_PAY_TYPE = {
    id: 'TB_CHANGE_PAY_TYPE',
    header: 'Change payment type',
    text: 'Change payment type',
    icon: 'fas fa-exchange-alt'
};

export const TB_RECEIPT_PAYMENT = {
    id: 'TB_RECEIPT_PAYMENT',
    header: 'Make a Receipt Payment',
    text: 'Payment',
    icon: 'fa invoice24'
};

export const TB_RECEIPT_PAYMENT_NHS = {
    id: 'TB_RECEIPT_PAYMENT_NHS',
    header: 'Make an NHS Receipt Payment',
    text: 'Payment',
    icon: 'fa nhsLogo16'
};

export const TB_RECEIPT_CHEQUE = {
    id: 'TB_RECEIPT_CHEQUE',
    header: 'Make a Cheque Payment',
    text: 'Cheque Payment',
    icon: 'fa invoice24'
};

export const TB_RECEIPT_CD_CARD = {
    id: 'TB_RECEIPT_CD_CARD',
    header: 'Make a Card Payment',
    text: 'Card Payment',
    icon: 'fa invoice24'
};
export const TB_RECEIPT_NARRATIVE = {
    id: 'TB_RECEIPT_NARRATIVE',
    header: 'Payment Narrative',
    text: 'Narrative',
    icon: 'fa invoice24'
};

export const TB_SHOW_ACCOUNT_STATEMENT = {
    id: 'TB_SHOW_ACCOUNT_STATEMENT',
    text: 'Show Patient Account ',
    icon: 'fa show24',
};

export const TB_PRINT_ACCOUNT_STATEMENT = {
    id: 'TB_PRINT_ACCOUNT_STATEMENT',
    text: 'Print Patient Account ',
    icon: 'fa print24',
};

export const TB_PRINT_ACCOUNT_STATEMENT_PDF = {
    id: 'TB_PRINT_ACCOUNT_STATEMENT',
    text: 'Patient Account PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
};

export const TB_SHOW_PATIENT_NOTE = {
    id: 'TB_SHOW_PATIENT_NOTE',
    text: 'Patient Note History ',
    icon: 'fa show24',
};

export const TB_PRINT_PATIENT_NOTE = {
    id: 'TB_PRINT_PATIENT_NOTE',
    text: 'Patient Note History ',
    icon: 'fa print24',
    tabIcon: 'fa print24',
};

export const TB_PRINT_PATIENT_NOTE_PDF = {
    id: 'TB_PRINT_PATIENT_NOTE_PDF',
    text: 'Patient Note History PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
};

export const TB_SHOW_FULL_ACCOUNT_HISTORY = 'Full Account History  ';
export const TB_SHOW_CURRENT_ACCOUNT_HISTORY = 'Current Account History  ';

export const TB_PATIENT_NOTE = {id: 'TB_PATIENT_NOTE', text: 'Create a patient note', icon: 'fas fa-sticky-note'};

export const TB_PATIENT_DOC_UPLOAD = {
    id: 'TB_PATIENT_DOC_UPLOAD',
    text: 'Upload Patient Document',
    icon: 'fas fa-file-upload'
};
export const TB_PATIENT_IMG_UPLOAD = {
    id: 'TB_PATIENT_IMG_UPLOAD',
    text: 'Upload Patient Image / XRay / PDF',
    icon: 'far fa-image'
};

export const TB_PATIENT_IMG_SCAN = {
    id: 'TB_PATIENT_IMG_SCAN',
    text: 'Scan Patient Image',
    icon: 'fas fa-scanner-image'
};

export const TB_CONNECT_TO_XRAY = {
    id: 'TB_CONNECT_TO_XRAY',
    text: 'Connect To X-Ray Software',
    icon: 'fas fa-x-ray',
    tabIcon: 'fas fa-x-ray',
};

export const TB_PATIENT_DOC_CREATE = {
    id: 'TB_PATIENT_DOC_CREATE',
    text: 'Create new patient document',
    icon: 'fas fa-file-medical'
};

export const TB_PATIENT_DOC_REFERRER = {
    id: 'TB_PATIENT_DOC_REFERRER',
    text: 'Create Referrer / Patient Document',
    icon: 'fas fa-file-prescription'
};
export const TB_PATIENT_DOC_PRESCRIPTION = {
    id: 'TB_PATIENT_DOC_PRESCRIPTION',
    text: 'Create Patient Prescription',
    icon: 'fas fa-prescription-bottle-alt'
};

export const TB_PATIENT_TPLANS = {
    id: 'TB_PATIENT_TPLANS',
    text: 'Patient Treatment Plans',
    icon: 'fas fa-clipboard-list'
};

export const TB_PATIENT_PPLANS = {
    id: 'TB_PATIENT_PPLANS',
    text: 'Patient Payment Plans',
    icon: 'fas fa-list-ol',
};

export const TB_ISSUE_INSTALLMENT_INVOICE = {
    id: 'TB_ISSUE_INSTALLMENT_INVOICE',
    text: 'Issue Instalment Invoice',
    icon: 'fas fa-share-square',
};

export const TB_SCANNER = {
    id: 'TB_SCANNER',
    text: 'Document Scan',
    icon: 'scanner7',
};

export const TB_PATIENT_PPLANS_SUSPEND = {
    id: 'TB_PATIENT_PPLANS_SUSPEND',
    text: 'Suspend Payment Plan',
    icon: 'far fa-stop-circle'
};

export const TB_PATIENT_PPLANS_RESUME = {
    id: 'TB_PATIENT_PPLANS_RESUME',
    text: 'Resume Payment Plan',
    icon: 'far fa-play-circle'
};

export const TB_PATIENT_PPLANS_WRITEOFF = {
    id: 'TB_PATIENT_PPLANS_WRITEOFF',
    text: 'Write Off Payment Plan',
    icon: 'fas fa-trash-alt'
};

export const TB_ADD_APPOINTMENT = {
    id: 'TB_ADD_APPOINTMENT',
    text: 'Add new Appointment',
    icon: 'fas fa-calendar-plus'
};

export const TB_ADD_LAB_WORK = {
    id: 'TB_ADD_LAB_WORK',
    text: 'Add new lab work request',
    icon: 'fas fa-wrench'
};

export const TB_MESSAGE_TP_TASK = {
    id: 'TB_MESSAGE_TP_TASK',
    text: 'Handover from ',
};

export const TB_IMAGE_EDIT_ITEM = {id: 'TB_IMAGE_EDIT_ITEM', text: 'Edit selected item', icon: 'pi pi-pencil'};
export const TB_IMAGE_EDIT_IMAGE = {id: 'TB_IMAGE_EDIT_IMAGE', text: 'Edit selected image', icon: 'far fa-image'};
export const TB_IMAGE_SELECT_IMAGE = {id: 'TB_IMAGE_SELECT_IMAGE', text: 'Select image', icon: 'fa fa-plus'};
export const TB_IMAGE_DESELECT_IMAGE = {id: 'TB_IMAGE_DESELECT_IMAGE', text: 'Deselect image', icon: 'fa fa-minus'};

export const TB_IMAGE_SEND_TO_PATIENT = {
    id: 'TB_IMAGE_SEND_TO_PATIENT',
    text: 'Send image to patient',
    icon: ICON_EMAIL
};
export const TB_IMAGE_SEND_TO_REFERRER = {
    id: 'TB_IMAGE_SEND_TO_REFERRER',
    text: 'Send to referrer as document attachment',
    icon: ICON_EMAIL
};

export const TB_IMAGE_SELECT_SEND_TO_PATIENT = {
    id: 'TB_IMAGE_SELECT_SEND_TO_PATIENT',
    text: 'Send selected image(s) to patient',
    icon: ICON_EMAIL
};
export const TB_IMAGE_SELECT_SEND_TO_REFERRER = {
    id: 'TB_IMAGE_SELECT_SEND_TO_REFERRER',
    text: 'Send to referrer as document attachment',
    icon: ICON_EMAIL
};

export const TB_ADD_TREATMENT_PLAN = {id: 'TB_ADD_TREATMENT_PLAN', text: 'Add Plan', icon: 'fa patientEdit24'};
export const TB_EDIT_TREATMENT_PLAN = {id: 'TB_EDIT_TREATMENT_PLAN', text: 'Edit Plan', icon: 'fa editTreatmentPlan24'};

export const TB_COPY_TREATMENT_PLAN = {
    id: 'TB_COPY_TREATMENT_PLAN',
    text: 'Copy Plan',
    icon: ICON_COPY,
    header: 'Copy Treatment Plan',
    message: 'Are sure you want to copy this treatment plan',
};

export const TB_COPY_TREATMENT_PLAN_NAME = {
    id: 'TB_COPY_TREATMENT_PLAN_NAME',
    text: 'New Treatment Plan Name',
    icon: ICON_COPY,
    header: 'New Treatment Plan Name',
    message: 'What is the new treatment plan title',
};

export const TB_DELETE_TREATMENT_PLAN = {
    id: 'TB_DELETE_TREATMENT_PLAN',
    text: 'Delete Plan',
    icon: 'fa deleteTreatmentPlan24',
    header: 'Delete Treatment Plan',
    message: 'Are sure you want to delete this treatment plan',
};

export const TB_APPOINTMENTS_OPEN = {
    id: 'TB_APPOINTMENTS_OPEN',
    text: 'Open Appointments',
    icon: 'fas fa-angle-double-down'
};

export const TB_APPOINTMENTS_CLOSE = {
    id: 'TB_APPOINTMENTS_CLOSE',
    text: 'Close Appointments',
    icon: 'fas fa-angle-double-up'
};

export const TB_PATIENT_HANDOVER = {
    id: 'TB_PATIENT_HANDOVER',
    text: 'Hand patient over to reception',
    icon: 'fas fa-share'
};

// treatment history related

export const HM_TreatmentShowAppointment = {
    id: 'HM_TreatmentShowAppointment',
    header: 'Show Appointment',
    message: '',
    icon: 'fas fa-sign-out-alt'
};

export const HM_TreatmentShowInvoice = {
    id: 'HM_TreatmentShowInvoice',
    header: 'Show Invoice',
    message: '',
    icon: 'fas fa-pound-sign'
};

export const HM_TreatmentPrintInvoice = {
    id: 'HM_TreatmentPrintInvoice',
    header: 'Print Invoice',
    message: '',
    icon: 'fas fa-print'
};

export const HM_TreatmentShowReceipt = {
    id: 'HM_TreatmentShowReceipt',
    header: 'Show Receipt',
    message: '',
    icon: 'fas fa-pound-sign'
};

export const HM_TreatmentPrintReceipt = {
    id: 'HM_TreatmentPrintReceipt',
    header: 'Print Receipt',
    message: '',
    icon: 'fas fa-print'
};

export const HM_TreatmentEdit = {
    id: 'HM_TreatmentEdit',
    header: 'Edit Treatment',
    message: '',
    icon: 'fas fa-edit'
};

export const HM_TreatmentDelete = {
    id: 'HM_TreatmentDelete',
    header: 'Delete Treatment',
    message: '',
    icon: 'fa fa-times'
};

// payment plan related

export const TB_PAYMENT_PLAN_PROPOSAL = {
    id: 'TB_PAYMENT_PLAN_PROPOSAL',
    text: 'Patient payment plan proposal',
    icon: 'fa paymentPlanProposal24'
};
export const TB_CREATE_PAYMENT_PLAN = {
    id: 'TB_CREATE_PAYMENT_PLAN',
    text: 'Create a patient payment plan',
    icon: 'fas fa-file-powerpoint'
};
export const TB_CREATE_TREATMENT_PLAN_DOC = {
    id: 'TB_CREATE_TREATMENT_PLAN_DOC',
    text: 'Create a treatment plan document',
    icon: 'fas fa-file-medical'
};
export const TB_CREATE_PAYMENT_PLAN_DOC = {
    id: 'TB_CREATE_PAYMENT_PLAN_DOC',
    text: 'Create a payment plan document',
    icon: 'fas fa-file-medical'
};
export const TB_ADD_PAYMENT_PLAN = {
    id: 'TB_ADD_PAYMENT_PLAN',
    text: 'Add a patient payment plan',
    header: 'Select A Patient Treatment plan',
    icon: 'fa paymentPlan24'
};
export const TB_EDIT_PAYMENT_PLAN = {
    id: 'TB_EDIT_PAYMENT_PLAN',
    text: 'Edit plan',
    icon: 'fas fa-edit',
};
export const TB_VIEW_PAYMENT_PLAN = {
    id: 'TB_VIEW_PAYMENT_PLAN',
    text: 'View plan',
    icon: 'far far-eye',
};
export const TB_DELETE_PAYMENT_PLAN = {
    id: 'TB_DELETE_PAYMENT_PLAN',
    text: 'Delete plan',
    icon: 'fa fa-times',
    header: 'Delete Payment plan',
    message: 'Are you sure you want to delete this payment plan',
};
export const TB_TREATMENT_PLAN_PRINT = {
    id: 'TB_TREATMENT_PLAN_PRINT',
    text: 'Print treatment plan',
    icon: 'fas fa-print'
};
export const TB_PAYMENT_PLAN_PRINT = {
    id: 'TB_PAYMENT_PLAN_PRINT',
    text: 'Print payment plan',
    icon: 'fas fa-print'
};

export const TB_TREATMENT_PLAN_VIEW = {
    id: 'TB_TREATMENT_PLAN_VIEW',
    text: 'View Treatment Plan',
    icon: 'fas fa-desktop'
};

export const TB_TREATMENT_PLAN_VIEW_PDF = {
    id: 'TB_TREATMENT_PLAN_VIEW_PDF',
    text: 'Treatment Plan PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
};

export const TB_PAYMENT_PLAN_VIEW = {
    id: 'TB_PAYMENT_PLAN_VIEW',
    text: 'View Payment Plan',
    icon: 'fas fa-desktop'
};

export const TB_PAYMENT_PLAN_UNISSUED = {
    id: 'TB_PAYMENT_PLAN_UNISSUED',
    text: 'Payment Plan Unissued',
    icon: 'fas fa-desktop'
};

export const TB_PAYMENT_PLAN_OUTSTANDING = {
    id: 'TB_PAYMENT_PLAN_OUTSTANDING',
    text: 'Payment Plan Outstanding',
    icon: 'fas fa-desktop'
};

export const TB_PAYMENT_PLAN_VIEW_PDF = {
    id: 'TB_PAYMENT_PLAN_VIEW_PDF',
    text: 'Payment Plan PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
};

// referrer related

export const TB_DOWNLOAD_REFERRERS = {
    id: 'TB_DOWNLOAD_REFERRERS',
    text: 'Download referrers list',
    icon: ICON_EXCEL
};

// tablet related

export const TB_REMOVE_FROM_TABLET = {
    id: 'TB_REMOVE_FROM_TABLET',
    text: 'Remove from tablet',
    maleIcon: ICON_PATIENT_MALE_24,
    femaleIcon: ICON_PATIENT_FEMALE_24
};

// payment states

export const PAY_STATE_AVAILABLE = 0;
export const PAY_STATE_ALLOCATED = 1;
export const PAY_STATE_VOIDED = 2;

// payment dialog states

export const PD_STATE_PAYMENT_OPTIONS = 0;
export const PD_STATE_RECEIPT_AMOUNT = 1;
export const PD_STATE_RECEIPT_CHEQUE = 2;
export const PD_STATE_RECEIPT_CD_CARD = 3;
export const PD_STATE_RECEIPT_NARRATIVE = 4;

export const PAY_TYPE_CASH = {value: 'CASH', label: 'Cash', abbrev: 'CS', ordinal: 0};
export const PAY_TYPE_CHEQUE = {value: 'CHEQUE', label: 'Cheque', abbrev: 'CQ', ordinal: 1};
export const PAY_TYPE_CC = {value: 'CC', label: 'Credit Card', abbrev: 'CC', ordinal: 2};
export const PAY_TYPE_BACS = {value: 'BACS', label: 'BACS', abbrev: 'BS', ordinal: 3};
export const PAY_TYPE_EFT = {value: 'EFT', label: 'EFT', abbrev: 'ET', ordinal: 4};
export const PAY_TYPE_DD = {value: 'DD', label: 'Direct Debit', abbrev: 'DD', ordinal: 5};
export const PAY_TYPE_SO = {value: 'SO', label: 'Standing Order', abbrev: 'SO', ordinal: 6};
export const PAY_TYPE_DC = {value: 'DC', label: 'Debit Card', abbrev: 'DC', ordinal: 7};
export const PAY_TYPE_APP = {value: 'APP', label: 'Apple Pay', abbrev: 'APP', ordinal: 8};
export const PAY_TYPE_ANP = {value: 'ANP', label: 'Android Pay', abbrev: 'ANP', ordinal: 9};

export const PAY_TYPES = [PAY_TYPE_CASH, PAY_TYPE_CHEQUE, PAY_TYPE_CC, PAY_TYPE_BACS, PAY_TYPE_EFT, PAY_TYPE_DD, PAY_TYPE_SO, PAY_TYPE_DC, PAY_TYPE_APP, PAY_TYPE_ANP];

// credit card types
export const CC_TYPE_VI = {value: '0', label: 'Visa'};
export const CC_TYPE_MC = {value: '1', label: 'Master Card'};
export const CC_TYPE_AE = {value: '2', label: 'American Express'};
export const CC_TYPE_DI = {value: '3', label: 'Diners'};
export const CC_TYPE_JC = {value: '4', label: 'JCB'};
export const CC_TYPE_COT = {value: '5', label: 'Other'};

export const DC_TYPE_VD = {value: '20', label: 'Visa Debit'};
export const DC_TYPE_MA = {value: '21', label: 'Maestro'};
export const DC_TYPE_VE = {value: '22', label: 'Visa Electron'};
export const DC_TYPE_DOT = {value: '23', label: 'Other'};

export const CC_TYPES = [CC_TYPE_VI, CC_TYPE_MC, CC_TYPE_AE, CC_TYPE_DI, CC_TYPE_JC, CC_TYPE_COT];
export const DC_TYPES = [DC_TYPE_VD, DC_TYPE_MA, DC_TYPE_VE, DC_TYPE_DOT];

export const HM_SingleDiscountCode = {id: 'HM_SingleDiscountCode', header: '', message: 'Single Discount Code'};

export const SMSBalanceText = 'SMS Balance';

//

export const TB_SELECT_ALL_RECALLS = {
    id: 'TB_SELECT_ALL_RECALLS',
    text: 'Select All',
    icon: icons.ICON_PLUS,
};

export const TB_DESELECT_ALL_RECALLS = {
    id: 'TB_DESELECT_ALL_RECALLS',
    text: 'Deselect All',
    icon: icons.ICON_MINUS,
};

export const TB_DOWNLOAD_RECALLS = {
    id: 'TB_DOWNLOAD_RECALLS',
    text: 'Download Recalls',
    icon: icons.ICON_EXCEL,
};

//

export const HM_PatShowAppointment = {
    id: 'HM_PatShowAppointment',
    label: 'Goto Appointment',
    header: '',
    message: '',
    icon: 'far fa-calendar-alt'
};

export const HM_PatAppointmentSummary = {
    id: 'HM_PatAppointmentSummary',
    label: 'Appointment Summary',
    header: '',
    message: '',
    icon: 'fas fa-info'
};

export const emailSMSTemplate = (row) => {

    switch (row.type) {
        case DOC_SMS_OUTGOING.name:
        case DOC_SMS_INCOMING.name:
            return row.visibleName;
        default:
            return row.email;
    }
}

export const docTypeTemplate = (row) => {

    const icon = _.find(DOC_TYPES, doc => doc.name === row.type).icon;
    return <i className={icon} style={{fontSize: 25}}/>;
};

export const docEmailTypeTemplate = (row) => {

    if (row.type === DOC_Email.name) {
        const header = (
            <span className="ql-formats">
            <button style={{width: "100%"}} className='ql-header' aria-label='subject'>{row.visibleName}</button>
        </span>
        );

        return <Editor value={row.content}
                       readOnly={true}
                       headerTemplate={header}
        />
    } else {
        return row.content;
    }
};

export const docClassificationTemplate = (row) => {

    return row.classification ? row.classification.code : '-';
};

export const docTypeTemplateFlattened = (row, ac, showImage) => {

    const icon = _.find(DOC_TYPES, doc => doc.name === row.type).icon;

    const key = Math.random().toString(36).slice(2);

    switch (row.type) {
        case DOC_UPLOAD_XRAY_TIF.name:
        case DOC_UPLOAD_XRAY_JPG.name:
        case DOC_UPLOAD_XRAY_GIF.name:
        case DOC_UPLOAD_SCAN_TIF.name:
        case DOC_UPLOAD_SCAN_JPG.name:
        case DOC_UPLOAD_SCAN_GIF.name:
        case DOC_IMAGE.name:
            return <img alt='' src={`${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${row.filename}/${key}`}
                        style={{width: '100px'}}
                        onClick={() => {
                            showImage(row, true);
                        }}
            />
        default:
            return <i className={icon} style={{fontSize: 25}}/>;
    }
};

export const CM_DOCUMENTS_XRAY = 'cmXrayDocuments';
export const CM_DOCUMENTS_PAT = 'cmPatientDocuments';
export const CM_DOCUMENTS_PRES = 'cmPresDocuments';
export const CM_DOCUMENTS_REF = 'cmRefDocuments';
export const CM_DOCUMENTS_TP = 'cmTpDocuments';
export const CM_DOCUMENTS_SMS_EMAIL = 'cmSmsEmailDocuments';
export const CM_DOCUMENTS_CONSENT = 'cmConsentFormDocuments';
export const CM_DOCUMENTS_FLATTENED = 'cmFlattenedDocuments';

export const buildPatientSMSEmailItemsMenu = (toolbarCallbacks, onShowMenuEvent) => {

    return ([
        {
            label: HM_DeleteEmailSMS.header,
            icon: HM_DeleteEmailSMS.icon,
            command: onShowMenuEvent,
            target: HM_notImplemented.id
        },
        {
            label: HM_EditEmailSMS.header,
            icon: HM_EditEmailSMS.icon,
            command: onShowMenuEvent,
            target: HM_EditEmailSMS.id
        },
    ])
};

export const buildPatientConsentItemsMenu = (toolbarCallbacks, onShowMenuEvent, portalView) => {

    if (portalView) {
        return ([
            {
                label: HM_PrintConsentDocument.header,
                icon: HM_PrintConsentDocument.icon,
                command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                    {
                        report: REP_ConsentForm.value,
                        reportFunction: REPORT_SHOW,
                        target: HM_PrintConsentDocument.id
                    }
                ),
            },
        ])
    } else {
        return ([
            {
                label: HM_DeleteConsentDocument.header,
                icon: HM_DeleteConsentDocument.icon,
                command: onShowMenuEvent,
                target: HM_notImplemented.id
            },
            {
                label: HM_EditConsentDocument.header,
                icon: HM_EditConsentDocument.icon,
                command: onShowMenuEvent,
                target: HM_EditConsentDocument.id
            },
            {
                label: HM_PrintConsentDocument.header,
                icon: HM_PrintConsentDocument.icon,
                command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                    {
                        report: REP_ConsentForm.value,
                        reportFunction: REPORT_SHOW,
                        target: HM_PrintConsentDocument.id
                    }
                ),
            },
            {
                label: HM_EmailConsentDocument.header,
                icon: HM_EmailConsentDocument.icon,
                command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientConsent),
            },
        ])
    }
};

export const buildPatientXrayScanDocumentsMenu = (toolbarCallbacks, onShowMenuEvent) => {

    return ([
        {
            label: HM_DeleteXrayScanDocument.header,
            icon: HM_DeleteXrayScanDocument.icon,
            command: onShowMenuEvent,
            target: HM_DeleteXrayScanDocument.id
        },
        {
            label: HM_EditXrayScanDocument.header,
            icon: HM_EditXrayScanDocument.icon,
            command: onShowMenuEvent,
            target: HM_EditXrayScanDocument.id
        },
        {
            label: HM_EmailXrayScanDocument.header,
            icon: HM_EmailXrayScanDocument.icon,
            command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientXray),
        },
    ])
};

export const buildPatientDocumentsItemsMenu = (toolbarCallbacks, onShowMenuEvent, portalView) => {

    if (portalView) {
        // {
        //     label: HM_PrintPatientDocument.header,
        //     icon: HM_PrintPatientDocument.icon,
        //     command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
        //         {
        //             report: REP_StandardPatient.value,
        //             reportFunction: REPORT_SHOW,
        //             target: HM_PrintPatientDocument.id
        //         }
        //     ),
        // },
    } else {
        return ([
            {
                label: HM_DeletePatientDocument.header,
                icon: HM_DeletePatientDocument.icon,
                command: onShowMenuEvent,
                target: HM_DeletePatientDocument.id
            },
            {
                label: HM_EditPatientDocument.header,
                icon: HM_EditPatientDocument.icon,
                command: onShowMenuEvent,
                target: HM_EditPatientDocument.id
            },
            // {
            //     label: HM_PrintPatientDocument.header,
            //     icon: HM_PrintPatientDocument.icon,
            //     command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
            //         {
            //             report: REP_StandardPatient.value,
            //             reportFunction: REPORT_SHOW,
            //             target: HM_PrintPatientDocument.id
            //         }
            //     ),
            // },
            {
                label: HM_EmailPatientDocument.header,
                icon: HM_EmailPatientDocument.icon,
                command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientDocument),
            },
        ])
    }
};

export const buildPrescriptionDocumentsItemsMenu = (toolbarCallbacks, onShowMenuEvent) => {
    return ([
        {
            label: HM_DeletePresDocument.header,
            icon: HM_DeletePresDocument.icon,
            command: onShowMenuEvent,
            target: HM_DeletePresDocument.id
        },
        {
            label: HM_EditPresDocument.header,
            icon: HM_EditPresDocument.icon,
            command: onShowMenuEvent,
            target: HM_EditPresDocument.id
        },
        {
            label: HM_PrintPresDocument.header,
            icon: HM_PrintPresDocument.icon,
            command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                {
                    report: REP_Prescription.value,
                    reportFunction: REPORT_SHOW,
                    target: HM_PrintPresDocument.id
                }
            ),
        },
        {
            label: HM_EmailPresDocument.header,
            icon: HM_EmailPresDocument.icon,
            command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientPrescription),
        },
    ])
};

export const buildReferralDocumentsItemsMenu = (toolbarCallbacks, onShowMenuEvent) => {
    return ([
        {
            label: HM_DeleteReferralDocument.header,
            icon: HM_DeleteReferralDocument.icon,
            command: onShowMenuEvent,
            target: HM_DeleteReferralDocument.id
        },
        {
            label: HM_EditReferralDocument.header,
            icon: HM_EditReferralDocument.icon,
            command: onShowMenuEvent,
            target: HM_EditReferralDocument.id
        },
        {
            label: HM_PrintReferralDocument.header,
            icon: HM_PrintReferralDocument.icon,
            command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                {
                    report: REP_OrthoRefLetter.value,
                    reportFunction: REPORT_SHOW,
                    target: HM_PrintReferralDocument.id
                }
            ),
        },
        {
            label: HM_EmailReferralDocument.header,
            icon: HM_EmailReferralDocument.icon,
            command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientReferral),
        },
    ])
};

export const buildFlattenedItemsMenu = (toolbarCallbacks, onShowMenuEvent, selectedItem) => {


    if (selectedItem === null)
        return null;

    switch (selectedItem.type) {

        case DOC_XRAY.name:
        case DOC_IMAGE.name:
        case DOC_UPLOAD_SCAN_JPG.name:
        case DOC_UPLOAD_SCAN_GIF.name:
        case DOC_UPLOAD_SCAN_TIF.name:
        case DOC_UPLOAD_SCAN_PDF.name:
        case DOC_UPLOAD_XRAY_JPG.name:
        case DOC_UPLOAD_XRAY_GIF.name:
        case DOC_UPLOAD_XRAY_TIF.name:
            return ([
                {
                    label: HM_DeleteXrayScanDocument.header,
                    icon: HM_DeleteXrayScanDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_DeleteXrayScanDocument.id
                },
                {
                    label: HM_EditXrayScanDocument.header,
                    icon: HM_EditXrayScanDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_EditXrayScanDocument.id
                },
                {
                    label: HM_EmailXrayScanDocument.header,
                    icon: HM_EmailXrayScanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientXray),
                },
            ])

        case DOC_WORD.name:
        case DOC_PDF.name:
        case DOC_UPLOAD_WORD.name:
        case DOC_UPLOAD_MSWORD.name:
            return ([
                {
                    label: HM_DeletePatientDocument.header,
                    icon: HM_DeletePatientDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_DeletePatientDocument.id
                },
                {
                    label: HM_EditPatientDocument.header,
                    icon: HM_EditPatientDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_EditPatientDocument.id
                },
                // {
                //     label: HM_PrintPatientDocument.header,
                //     icon: HM_PrintPatientDocument.icon,
                //     command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                //         {
                //             report: REP_StandardPatient.value,
                //             reportFunction: REPORT_SHOW,
                //             target: HM_PrintPatientDocument.id
                //         }
                //     ),
                // },
                {
                    label: HM_EmailPatientDocument.header,
                    icon: HM_EmailPatientDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientDocument),
                },
            ])

        case DOC_REFERRAL.name:
        case DOC_ORTHO_REFERRAL.name:
            return ([
                {
                    label: HM_DeleteReferralDocument.header,
                    icon: HM_DeleteReferralDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_DeleteReferralDocument.id
                },
                {
                    label: HM_EditReferralDocument.header,
                    icon: HM_EditReferralDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_EditReferralDocument.id
                },
                {
                    label: HM_PrintReferralDocument.header,
                    icon: HM_PrintReferralDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                        {
                            report: REP_OrthoRefLetter.value,
                            reportFunction: REPORT_SHOW,
                            target: HM_PrintReferralDocument.id
                        }
                    ),
                },
                {
                    label: HM_EmailReferralDocument.header,
                    icon: HM_EmailReferralDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientReferral),
                },
            ])

        default:
            break;
    }
};

export const buildTPPPDocumentsItemsMenu = (toolbarCallbacks, onShowMenuEvent, selectedItem, portalView) => {

    if (selectedItem === null)
        return null;

    if (selectedItem.type === DOC_TREATMENT_PLAN.name) {

        if (portalView) {
            return ([
                {
                    label: HM_PrintTreatmentPlanDocument.header,
                    icon: HM_PrintTreatmentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                        {
                            report: REP_TreatmentPlan.value,
                            reportFunction: REPORT_SHOW,
                            target: HM_PrintTreatmentPlanDocument.id
                        }
                    ),
                },
            ])
        } else {
            return ([
                {
                    label: HM_DeleteTreatmentPlanDocument.header,
                    icon: HM_DeleteTreatmentPlanDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_DeleteTreatmentPlanDocument.id
                },
                {
                    label: HM_EditTreatmentPlanDocument.header,
                    icon: HM_EditTreatmentPlanDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_EditTreatmentPlanDocument.id
                },
                {
                    label: HM_PrintTreatmentPlanDocument.header,
                    icon: HM_PrintTreatmentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                        {
                            report: REP_TreatmentPlan.value,
                            reportFunction: REPORT_SHOW,
                            target: HM_PrintTreatmentPlanDocument.id
                        }
                    ),
                },
                {
                    label: HM_EmailTreatmentPlanDocument.header,
                    icon: HM_EmailTreatmentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientTreatmentPlan),
                },
            ])
        }
    } else {

        if (portalView) {
            return ([
                {
                    label: HM_PrintPaymentPlanDocument.header,
                    icon: HM_PrintPaymentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                        {
                            report: REP_PaymentPlan.value,
                            reportFunction: REPORT_SHOW,
                            target: HM_PrintPaymentPlanDocument.id
                        }
                    ),
                },
            ])
        } else {
            return ([
                {
                    label: HM_DeletePaymentPlanDocument.header,
                    icon: HM_DeletePaymentPlanDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_DeletePaymentPlanDocument.id
                },
                {
                    label: HM_EditPaymentPlanDocument.header,
                    icon: HM_EditPaymentPlanDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_EditPaymentPlanDocument.id,

                },
                {
                    label: HM_PrintPaymentPlanDocument.header,
                    icon: HM_PrintPaymentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                        {
                            report: REP_PaymentPlan.value,
                            reportFunction: REPORT_SHOW,
                            target: HM_PrintPaymentPlanDocument.id
                        }
                    ),
                },
                {
                    label: HM_EmailPaymentPlanDocument.header,
                    icon: HM_EmailPaymentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientPaymentPlan),
                },
            ])
        }
    }
};
