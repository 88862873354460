import React from 'react';

import {TabPanel} from 'primereact/components/tabview/TabView';

import {
    __CHIRAL_CONTACT_DETAILS__,
    REF_INDEPENDENT,
    REF_PATIENT,
    REF_PROVIDER,
    TB_SAVE,
    TB_SAVE_AND_EXIT
} from "../../../Constants";

import {
    ICON_HELP,
} from "../../../icons";
import {
    HELP_REFERRER_INDEXS,
    SM_PATIENT_DATA,
    SM_REFERRER_ADD,
    SM_REFERRER_DATA,
    SM_REFERRER_PATIENT_DATA,
    SM_USER_DATA
} from "../../../actions/stateManagement";

import Referrer from '../ReferrerDetails/Referrer';
import AddReferrer from '../Referrers/AddReferrer';
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import {CONST_FEMALE} from "../../PatientDynamicItems/OnChangeUtils";

let addReferrerTabIndex = 0;

export const getAddReferrerTabIndex = () => {

    return addReferrerTabIndex++;
};

export const getReferrerIds = (state, ownProps) => {

    const referrerDataLoadedId = `${SM_REFERRER_DATA.loaded}_${ownProps.referrerId}`;
    const referrerDataId = `${SM_REFERRER_DATA.id}_${ownProps.referrerId}`;

    let referrerLoaded = state.referrers[referrerDataLoadedId];
    referrerLoaded = referrerLoaded === undefined ? false : referrerLoaded;

    const referrerPatientsLoadedId = `${SM_REFERRER_PATIENT_DATA.loaded}_${ownProps.referrerId}`;
    const referrerPatientsId = `${SM_REFERRER_PATIENT_DATA.id}_${ownProps.referrerId}`;

    let referrerPatientsLoaded = state.referrers[referrerPatientsLoadedId];
    referrerPatientsLoaded = referrerPatientsLoaded === undefined ? false : referrerPatientsLoaded;

    return {
        referrerDataId,
        referrerLoaded,
        referrerPatientsId,
        referrerPatientsLoaded,
    }
};

export const referrerDetailsToolBar = (callbacks, saveIcon, canSave, activeIndex) => {

    return {
        left:
            <React.Fragment>
                {tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text)}
            </React.Fragment>,
        right:
            <React.Fragment>
                {tb_boilerPlateRight((e) => window.open(HELP_REFERRER_INDEXS[activeIndex]), ICON_HELP, 'Knowledge Base', 1, true)}
                {tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text)}
            </React.Fragment>
    }
};

export const onShowReferrerDetailsPage = (props, row) => {

    const onCloseClick = props.onCloseClick;

    const onAdditionalClick = props.onPCButtonClick;

    const onTabUpdate = props.onTabUpdate;

    const {id, type, firstName, lastName, gender, companyName} = row;

    let header = '';
    let leftIcon = '';

    switch (type) {
        case REF_INDEPENDENT.value:
            header = companyName === null || companyName.trim() === '' ? `${firstName} ${lastName}`.trim() : companyName.trim();
            leftIcon = SM_REFERRER_DATA.tabIcon;
            break;
        case REF_PATIENT.value:
            header = `${firstName} ${lastName}`.trim();
            leftIcon = parseInt(gender, 10) === CONST_FEMALE ? SM_PATIENT_DATA.femaleTabIcon : SM_PATIENT_DATA.maleTabIcon;
            break;
        case REF_PROVIDER.value:
            header = `${firstName} ${lastName}`.trim();
            leftIcon = parseInt(gender, 10) === CONST_FEMALE ? SM_USER_DATA.femaleTabIcon : SM_USER_DATA.maleTabIcon;
            break;
        default:
            leftIcon = '';
    }

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_REFERRER_DATA.id}_${id}`} header={header} leftIcon={leftIcon}>
                    <Referrer onCloseClick={onCloseClick}
                              onTabUpdate={onTabUpdate}
                              referrerId={id.toString()}
                              onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_REFERRER_DATA.id}_${id}`} header={`${firstName} ${lastName} *`} leftIcon={leftIcon}>
                    <Referrer onCloseClick={onCloseClick}
                              onTabUpdate={onTabUpdate}
                              referrerId={id.toString()}
                              onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )
};

export const showAddReferrerPage = (props) => {

    const onCloseClick = props.onCloseClick;

    const onAdditionalClick = props.onPCButtonClick;

    const onTabUpdate = props.onTabUpdate;

    const keyId = `${SM_REFERRER_ADD.id}_${addReferrerTabIndex++}`;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={keyId}
                          header={SM_REFERRER_ADD.label}
                          leftIcon={`fa ${SM_REFERRER_ADD.tabIcon}`}>
                    <AddReferrer onCloseClick={onCloseClick}
                                 onTabUpdate={onTabUpdate}
                                 id={keyId}
                                 onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={keyId}
                          header={`${SM_REFERRER_ADD.label} *`}
                          leftIcon={`fa ${SM_REFERRER_ADD.tabIcon}`}>
                    <AddReferrer onCloseClick={onCloseClick}
                                 onTabUpdate={onTabUpdate}
                                 id={keyId}
                                 onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )

};

export default class ReferrerErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>Something went wrong in the Referrer section.</h1>
                    <h2>{__CHIRAL_CONTACT_DETAILS__}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
