import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReport, getReportPDF, RES_REPORT_WEEKLY_FTA} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {Calendar} from "primereact/components/calendar/Calendar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {dateTemplateShort, durationTemplate, startTemplate} from "../../PatientDynamicItems/Utils";
import {HM_REP_WEEKLY_FTA, JSON_DATE_FORMAT, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PRINT, ICON_SCREEN,} from "../../../icons";
import {
    HELP_REP_WEEKS_FTAS,
    setState,
    SM_REPORTS_WEEKLY_FAILS,
    SM_REPORTS_WEEKLY_FAILS_PDF
} from "../../../actions/stateManagement";
import {TabPanel} from "primereact/components/tabview/TabView";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {TextWeeksFTAs} from "../../../Messages";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_WEEK, DIARY_FOR_WEEK} from "../Diary/Constants";
import {ICON_PATIENT_FEMALE, ICON_PATIENT_MALE} from "../PatientDetails/Constants";
import {CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import WeeklyFailsPDF from "./PDFs/WeeklyFailsPDF";

const FTA_COLS = ["App. Date", "Patient", "Time", "Duration", "With", "Last Reminder", "Sent Using", "Status"];

export class ConnectedWeeklyFails extends PDFBaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),
            selectedItem: null,
            rows: 5,
            first: 0,
        };
        this.cm = null;

        this.onShowDetails = this.onShowDetails.bind(this);
    }

    componentDidMount() {

        if (!this.props.failedToAttendByWeekLoaded) {
            this.props.getReport(this.state.currentDate);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_WEEKLY_FTA.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_WEEKLY_FAILS_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails(groupId) {

        const patient = this.state.selectedItem;
        showPatientDetailsPage({
            props: this.props,
            id: patient.patientId,
            firstName: patient.patientFirstName,
            lastName: patient.patientLastName,
            gender: patient.gender,
            nhsPatient: patient.nhsPatient,
            groupId
        });
    }

    onDateChange = (e) => {
        this.setState({currentDate: e.value, failedToAttendByWeekLoaded: false}, () => {
            this.props.getReport(this.state.currentDate);
        })
    }

    onDateChange2 = (forward) => {

        let date = moment(this.state.currentDate).add(forward ? 1 : -1, 'weeks');
        date = date.toDate();

        this.setState({
            currentDate: date,
            failedToAttendByWeekLoaded: false,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.getReport(date);
            this.props.setState(SM_REPORTS_WEEKLY_FAILS, this.state);
        })
    }

    onShowReport = (event) => {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const daysDate = this.state.currentDate;
        const formattedDate = moment(daysDate).format(JSON_DATE_FORMAT);

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={SM_REPORTS_WEEKLY_FAILS_PDF.id} header={SM_REPORTS_WEEKLY_FAILS_PDF.label}
                              leftIcon={SM_REPORTS_WEEKLY_FAILS_PDF.tabIcon}>
                        <WeeklyFailsPDF onCloseClick={onCloseClick}
                                        onTabUpdate={onTabUpdate}
                                        date={formattedDate}
                                        id={SM_REPORTS_WEEKLY_FAILS_PDF.id}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_WEEKLY_FAILS_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    onDownload = () => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_REPORTS_WEEKLY_FAILS_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    render() {

        if (!this.props.failedToAttendByWeekLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const genderIcon = this.state.selectedItem && this.state.selectedItem.gender === CONST_MALE ? ICON_PATIENT_MALE : ICON_PATIENT_FEMALE;

        const items = [
            {label: 'Show this patients summary', icon: genderIcon, command: this.onShowDetails},
        ];

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate((e) => this.onDateChange2(false), DIARY_BACK_WEEK.icon, DIARY_BACK_WEEK.tooltip, key++)}
                            {tb_boilerPlate((e) => this.onDateChange2(true), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      disabledDays={[0, 2, 3, 4, 5, 6]}
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight2(this.onShowReport, ICON_SCREEN, 'Show ' + TextWeeksFTAs, key++, false)}
                            {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextWeeksFTAs, key++, false)}
                            {tb_boilerPlateRight2(this.onDownload, ICON_DOWNLOAD, `Download ${TextWeeksFTAs} Report`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_WEEKS_FTAS), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel header={HM_REP_WEEKLY_FTA.header} style={{paddingTop: '5px'}}>

                        <ContextMenu model={items}
                                     style={{width: '250px'}}
                                     ref={el => this.cm = el}/>

                        <DataTable value={this.props.failedToAttendByWeek}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selectedItem}
                                   onSelectionChange={(e) => {
                                       this.setState({selectedItem: e.value});
                                   }}
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e => this.setState({selectedItem: e.value})}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent);
                                   }}
                        >
                            <Column header={FTA_COLS[0]}
                                    body={(row) => dateTemplateShort(row.start)}
                                    style={{width: '10%'}}/>
                            <Column field="patientFullName"
                                    header={FTA_COLS[1]}
                                    style={{width: '25%'}}/>
                            <Column header={FTA_COLS[2]}
                                    body={(row) => startTemplate(row.start)}
                                    style={{width: '10%'}}/>
                            <Column header={FTA_COLS[3]}
                                    body={(row) => durationTemplate(row.start, row.end)}
                                    style={{width: '10%'}}/>
                            <Column field="username"
                                    header={FTA_COLS[4]}
                                    style={{width: '10%'}}/>
                            <Column header={FTA_COLS[5]}
                                    body={(row) => dateTemplateShort(row.lastReminder)}
                                    style={{width: '10%'}}/>
                            <Column field="type"
                                    header={FTA_COLS[6]}
                                    style={{width: '15%'}}/>
                            <Column field="remState"
                                    header={FTA_COLS[7]}
                                    style={{width: '10%'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        failedToAttendByWeekLoaded: state.reports.failedToAttendByWeekLoaded,
        failedToAttendByWeek: state.reports.failedToAttendByWeek,

        PDFLoaded: state.reports.failedToAttendByWeekPDFLoaded,
        pdfBuffer: state.reports.failedToAttendByWeekPDF,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (date) => dispatch(getReport(RES_REPORT_WEEKLY_FTA.GET, {date: moment(date).format(JSON_DATE_FORMAT)})),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_WEEKLY_FTA.PDF, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const WeeklyFails = connect(mapStateToProps, mapDispatchToProps)(ConnectedWeeklyFails);

export default WeeklyFails;
