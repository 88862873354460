import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {HM_PatientSelector} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getAppointmentPatientsBySurname} from "../../../../actions/findPatients";
import {nhsTemplate} from "../../Patients/Utils";
import {dateTemplateVeryShort} from "../../../PatientDynamicItems/Utils";
import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import * as Actions from "../../../../actions";

export class ConnectedTransferSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            matchedPatients: [],
            selectedPatient: null,

            firstMP: 0,
            MPRows: 5,

            globalFilter: '',
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message.type !== prevProps.message.type) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_APP_PATIENT_SEARCH:

                    this.setState({matchedPatients: this.props.matchedPatients, selectedPatient: null});
                    break;
                default:
                    break;
            }
        }
    }

    onDialogChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        if (event.owner === 'search.item' && event.value.length > 1) {
            this.props.getPatientsBySurname(event.value);
        }
        this.setState(state);
    }

    onPatientSelection = (event) => {

        this.setState({selectedPatient: event.value});
    }

    onPageFlex = (e, target, rows) => {
        if (rows !== undefined)
            this.setState({[target]: e.first, [rows]: e.rows});
        else
            this.setState({[target]: e.first});
        this.save = true;
    }

    render() {

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.selectedPatient);
                        }}
                        disabled={this.state.selectedPatient === null}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.props.onHide}/>
            </div>
        );

        const propsSearch = {
            onChange: this.onDialogChange,
            target: 'search',
            search: this.state.search,
        };

        return (
            <Dialog header={HM_PatientSelector.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.props.onHide}
            >
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        {inputText(propsSearch, 'item', '', false, true)}
                    </div>

                    <DataTable header='Patient Match(s)'
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               value={this.state.matchedPatients}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.MPRows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => {
                                   this.onPageFlex(e, 'firstMP', 'MPRows');
                               }}
                               first={this.state.firstMP}
                               selection={this.state.selectedPatient}
                               onSelectionChange={this.onPatientSelection}
                    >

                        <Column body={nhsTemplate} style={{width: '20%'}}/>
                        <Column field="fullName" style={{width: '50%'}}/>
                        <Column body={(rowData) => dateTemplateVeryShort(rowData.dateOfBirth)}
                                style={{width: '30%'}}/>
                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        matchedPatients: state.diary.matchedPatients,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getPatientsBySurname: (urlPattern) => dispatch(getAppointmentPatientsBySurname(urlPattern)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TransferSelector = connect(mapStateToProps, MapDispatchToProps)(ConnectedTransferSelector);

export default TransferSelector;
