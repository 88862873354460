import React from 'react';
import {ac} from '../../../index';

import {connect} from "react-redux";
import moment from "moment";
import {messageBus, setState, SM_APPOINTMENT, stateRequest} from "../../../actions/stateManagement";
import {
    clearTreatmentPlan,
    createTreatmentPlanDocument,
    getResource as getTPResource,
    RES_TREATMENT_PLAN_BY_ID
} from "../../../actions/treatmentPlanning";
import {getTreatmentPlanIds, TP_UNASSIGNED_CHARGE} from "./Utils";
import CreateTreatmentPlanDocument from "./dialogs/CreateTreatmentPlanDocument";
import {tpStatusDropDownList} from "../fixedItemUtils";
import _ from "lodash";

import {General} from "./Sections/General";
import {Accordion, AccordionTab} from 'primereact/components/accordion/Accordion';
import {Button} from 'primereact/components/button/Button';

import {MOUTH_HEIGHT, MOUTH_WIDTH, MouthBGColor} from "../../Charting/Model/Constants";
import {TreatmentPalette} from "../../Charting/Palettes/BasePalette";
import Mouth from "../../Charting/Mouth";
import {ChartingComponent} from "../ChartingComponent";
import {getChart, getResource as getCHResource, RES_CHART, RES_chartResources} from "../../../actions/ChartResources";
import * as Actions from "../../../actions";
import {PALETTE_CHANGE} from "../../../actions";
import TreatmentsAppointments from "./Sections/TreatmentsAppointments";
import {
    getResource as getHKResource,
    getResource,
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_JSTAGES,
    RES_HOUSEKEEPING_TCODES,
    RES_TEMPLATE_NOTES
} from "../../../actions/housekeeping";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import {getResource as getUserResource} from "../../../actions/users";
import {TREATMENT_PLANNING} from "../KPIs/Constants";
import {
    TB_HIDE_TREATMENT_ADD_EDIT,
    TB_PLACE_APPOINTMENT,
    TB_SHOW_TREATMENT_ADD,
    TB_SHOW_TREATMENT_EDIT,
} from "../PatientDetails/Constants";
import * as DefaultData from "../DefaultData";
import {
    ADDBACK,
    APP_CREATED,
    APP_MOVING,
    DOC_TREATMENT_PLAN,
    HM_AddFollowOnRecall,
    HM_CREATE_TREATMENT_PLAN_DOC,
    HM_notImplemented,
    HM_SaveBeforeAction,
    MOVING_IN_DIARY,
    PAT_STATUS_INACTIVE,
    PJ_DEACT,
    PJ_None,
    UP_DOCUMENT_ADDED
} from "../../../Constants";
import AddRecall from "../../DynamicItems/AddRecall";
import {addRecall, RES_placeRecall} from "../../../actions/recalls";
import {getSalesTPTotal} from "../AppointmentDetails/Utils";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {TAB_EXIT} from "../Housekeeping/Constants";
import {RES_getAccountGroups} from "../../../actions/accountGroups";

class ConnectedTreatmentPlan extends ChartingComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {

                fromTreatmentPlan: true,

                stateManagementId: props.treatmentPlanId,
                canSave: {status: false},

                treatmentPlan: null,
                resourcesLoaded: false,
                treatmentPlanLoaded: false,
                chartLoaded: false,
                charting: [],
                baseView: false,
                selectedCategory: null,
                selectedItem: {label: 'Select'},
                currentCommand: null,
                showAddTreatment: false,
                showEditTreatment: false,
                showCode: false,

                selectedAppointmentId: null,

                activeIndex: 0,
                dummyAppointmentId: -1000,
            };

            if (props.plan.id < 0) {
                this.state.treatmentPlan = props.plan;
                this.state.treatmentPlanLoaded = true;
            }
        }

        this.callbacks = {

            [TB_SHOW_TREATMENT_ADD.id]: (charted, entry, appointmentId, target) => this.onShowAddEditTreatment(target, charted, entry, appointmentId),
            [TB_SHOW_TREATMENT_EDIT.id]: (charted, entry, appointmentId, target) => this.onShowAddEditTreatment(target, charted, entry, appointmentId),

            [TB_HIDE_TREATMENT_ADD_EDIT.id]: this.onHideAddEditTreatment,

            [HM_notImplemented.id]: this.onNotImplemented,
        }
        this.exitState = TAB_EXIT;
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getTemplateNotes();
            this.props.getAccountGroups();
            this.props.getAppointmentDiaryTypes();
            this.props.getDropDowns();
            this.props.getHousekeepingTCodes();

            this.props.getTreatmentPlanById(this.props.plan.id);
            this.props.getJourneyStages();
            this.props.getChartResources();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_CHART_RESOURCES:

                    this.setState({
                        resourcesLoaded: this.props.resourcesLoaded,
                        resources: this.props.resources
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                    });
                    break;

                case RES_TREATMENT_PLAN_BY_ID.SAVE.action:

                    const treatmentPlan = {...this.props.treatmentPlan};
                    treatmentPlan.edited = false;

                    this.setState({
                        canSave: {status: false},
                        treatmentPlanLoaded: this.props.treatmentPlanLoaded,
                        treatmentPlan: this.props.treatmentPlan
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                        this.props.onTabUpdate({key: `${SM_APPOINTMENT.id}_${this.props.appointmentId}`}, false);
                    });
                    break;

                case RES_TREATMENT_PLAN_BY_ID.GET.receive:

                    this.props.getChart(this.props.treatmentPlan.patient.id);

                    this.setState({
                        treatmentPlanLoaded: this.props.treatmentPlanLoaded,
                        treatmentPlan: this.props.treatmentPlan
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

                        let salesTotal = this.state.treatmentPlan ? getSalesTPTotal(this.state.treatmentPlan) : 0.0;

                        this.props.onCostChange(salesTotal);
                    });
                    break;

                case Actions.RECEIVE_CHART:

                    // check if this chart was just created. If so charted by is login id
                    if (this.props.charting.chartedBy === null)
                        this.props.charting.chartedBy = {id: this.props.loginIdentity.id};

                    this.setState({chartLoaded: this.props.chartLoaded, charting: this.props.charting}, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                    });
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_DOCUMENT_ADDED:

                            const document = {...this.props.wsmessage.content};

                            // this filters out other patients updates
                            if (this.state.treatmentPlan.patient.id === document.patientId) {

                                switch (document.type) {

                                    case DOC_TREATMENT_PLAN.name:

                                        const {filename, visibleName, type} = document;

                                        const documentData = {
                                            chiralServer: ac.getBASERESTURL(),
                                            mcid: ac.getMcId(),
                                            filename,
                                            docname: visibleName,
                                            type
                                        };

                                        this.setState({documentData}, () => {

                                            const key = Math.random().toString(36).slice(2);
                                            const key2 = "1234";

                                            localStorage.setItem(key2, JSON.stringify(documentData));

                                            window.open(`https://${ac.getChiralServer()}/documentLoader.html?id=${key}`, '_blank');
                                        });
                                        break;

                                    default:
                                        break;
                                }
                            }
                            break;
                        default:
                            break;
                    }
                    break;

                case Actions.WSM_APPOINTMENTS:

                    switch (this.props.wsmessage.function) {

                        case MOVING_IN_DIARY: {
                            const {treatmentPlanId, id, start, end} = this.props.wsmessage.content;

                            if (treatmentPlanId === this.state.treatmentPlan.id) {

                                const appointmentIndex = _.findIndex(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === id);

                                const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);

                                treatmentPlan.appointments[appointmentIndex].status = APP_MOVING;
                                treatmentPlan.appointments[appointmentIndex].start = null;
                                treatmentPlan.appointments[appointmentIndex].end = null;

                                this.setState({treatmentPlan}, () => {
                                    this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                });
                            }
                            break;
                        }

                        case ADDBACK: {
                            const {treatmentPlanId, id, start, end} = this.props.wsmessage.content;

                            if (treatmentPlanId === this.state.treatmentPlan.id) {

                                const appointmentIndex = _.findIndex(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === id);

                                const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);

                                treatmentPlan.appointments[appointmentIndex].status = APP_CREATED;
                                treatmentPlan.appointments[appointmentIndex].start = start;
                                treatmentPlan.appointments[appointmentIndex].end = end;

                                this.setState({treatmentPlan}, () => {
                                    this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                });
                            }
                            break;
                        }
                        default:
                            break;
                    }
            }
        }
    }

    addRecall = (newRecall) => {

        this.onHideMenuEntry(HM_AddFollowOnRecall.id);
        this.props.placeRecall(newRecall);
    }

    onChange = (event) => {

        const newState = {...this.state};

        if (event === null) {
            _.set(newState, 'treatmentPlan.edited', true);
            _.set(newState, 'canSave', {status: true});
            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else if (event.owner === PALETTE_CHANGE) { // throw this event away
            return;
        } else if (event.owner === HM_AddFollowOnRecall.tpOwner) { // do we need to check the journey code change

            if (event.value.id !== newState.treatmentPlan.patient.patientJourney.stage.id) {

                _.set(newState, 'canSave.status', true);

                newState.treatmentPlan.patient.patientJourney.stage = event.value;
                newState.treatmentPlan.patient.patientJourney.changedOn = new Date();

                switch (event.value.action) {
                    case PJ_None.value:
                        break;
                    case PJ_DEACT.value:
                        newState.treatmentPlan.patient.state = PAT_STATUS_INACTIVE.name;
                        break;
                    default:
                        /* As this is a treatment plan we should not be creating recalls which require user and appointment to be specified */
                        // const newRecall = _.cloneDeep(recallData(ac.getMcId()));
                        // newRecall.patient = {id: this.state.treatmentPlan.patient.id};
                        // newRecall.placedOn = new Date();
                        // newRecall.placedBy = {id: this.props.loginIdentity.id};
                        // newRecall.recallType = {id: event.value.recallType.id};
                        // newRecall.provider = {id: appointment.appointmentWith.id};
                        //
                        // const pjStage = _.find(PJ_Action, action => action.value === event.value.action);
                        //
                        // const interval = pjStage.months;
                        // const placedFor = new moment();
                        // placedFor.add(interval, 'months');
                        // newRecall.placedFor = placedFor.toDate();
                        // newRecall.comment = event.value.description;
                        //
                        // newState.newRecall = newRecall;
                        // newState[HM_AddFollowOnRecall.id] = true;
                        break;
                }

                this.setState(newState, () => {
                    this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                });
            }

        } else {

            switch (event.owner) {

                case 'treatmentPlan.title' :
                    _.set(newState, 'treatmentPlan.edited', true);
                    _.set(newState, event.owner, event.value);
                    _.set(newState, 'canSave', {status: true});

                    this.setState(newState, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                        this.props.onTabUpdate({key: this.props.parentId}, true);
                        this.props.updateTabTitle({
                            id: this.state.treatmentPlan.id,
                            title: this.state.treatmentPlan.title
                        });
                    });
                    break;
                default:
                    _.set(newState, 'treatmentPlan.edited', true);
                    _.set(newState, event.owner, event.value);
                    _.set(newState, 'canSave', {status: true});

                    this.setState(newState, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                        this.props.onTabUpdate({key: this.props.parentId}, true);
                    });
            }
        }

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_TREATMENT_PLAN_BY_ID.SAVE.action
            },
            sourceTpId: newState.treatmentPlan.id,
        });
    }

    handleItemSelected = (item) => {

        const command = item.command === undefined ? null : new item.command();
        this.setState({selectedItem: item, currentCommand: command}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            this.props.onChange({owner: PALETTE_CHANGE, value: null});
        });
    }

    handleItemDeselected = () => {
        this.state.currentCommand.clear();
        this.setState({selectedItem: {label: 'Select'}, currentCommand: null}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            this.props.onChange({owner: PALETTE_CHANGE, value: null});
        });
    }

    addChartingEntry = (entry) => {

        const charting = {...this.state.charting};

        charting.chart.entries.push(entry);

        this.setState({charting});
    }

    onAddAppointment = () => {

        const canSave = {...this.state.canSave};
        canSave.status = true;

        const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);

        const appointmentWith = treatmentPlan.patient.provider;

        const tpAppointment = _.cloneDeep(DefaultData.appointmentData(ac.getMcId()));
        tpAppointment.appointmentId = this.state.dummyAppointmentId++;
        tpAppointment.patient = treatmentPlan.patient;
        tpAppointment.bookedBy = {id: this.props.loginIdentity.id};
        tpAppointment.index = treatmentPlan.appointments.length;
        tpAppointment.treatmentPlan = treatmentPlan;
        tpAppointment.appointmentWith = appointmentWith;

        tpAppointment.apType = appointmentWith.defaultAppointmentType ? appointmentWith.defaultAppointmentType : this.props.appointmentTypes[0];

        tpAppointment.start = moment([0, 0, 1, 0, 0, 0]).toDate();
        const durationEnd = moment([0, 0, 1, 0, 0, 0]);
        durationEnd.minute(tpAppointment.apType.durationMins);
        tpAppointment.end = durationEnd.toDate();

        treatmentPlan.appointments.push(tpAppointment);
        treatmentPlan.edited = true;

        this.setState({treatmentPlan, dummyAppointmentId: this.state.dummyAppointmentId + 1, canSave}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_TREATMENT_PLAN_BY_ID.SAVE.action
                },
                sourceTpId: treatmentPlan.id,
            });
        });
    };

    onDeleteAppointment = (delAppointment) => {

        const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);
        treatmentPlan.appointments = treatmentPlan.appointments.filter(appointment => appointment.appointmentId !== delAppointment.appointmentId);
        treatmentPlan.edited = true;

        this.setState({treatmentPlan}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_TREATMENT_PLAN_BY_ID.SAVE.action
                },
                sourceTpId: treatmentPlan.id,
            });
        });
    }

    onDragEnd = (event) => {

        // is this an assignment
        if (event.draggableId.startsWith(TP_UNASSIGNED_CHARGE)) {

            const chargeId = parseInt(event.draggableId.split('_')[1], 10);
            const appointmentId = parseInt(event.destination.droppableId.split('_')[1], 10);

            const charge = _.find(this.state.treatmentPlan.unassignedCharges, charge => charge.id === chargeId);
            const destinationAppointment = _.find(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === appointmentId);

            // assign the charge to the appointment
            charge.appointment = {appointmentId: destinationAppointment.appointmentId};

            if (!Boolean(destinationAppointment.charges))
                destinationAppointment.charges = [];

            destinationAppointment.charges.push(charge);

            const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);
            treatmentPlan.edited = true;

            treatmentPlan.appointments = treatmentPlan.appointments.map(appointment => appointment.appointmentId === appointmentId ? destinationAppointment : appointment);

            this.setState({treatmentPlan}, () => {
                this.props.setState(this.state.stateManagementId, {...this.state});
            });
        } else {

            // first check if we are un-assigning the charge
            if (event.destination.droppableId === 'treatments') {
                const chargeId = parseInt(event.draggableId.split('_')[1], 10);
                const appointmentId = parseInt(event.source.droppableId.split('_')[1], 10);

                const charge = _.find(this.state.treatmentPlan.unassignedCharges, charge => charge.id === chargeId);

                // un-assign the charge to the appointment
                charge.appointment = null;

                const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);
                treatmentPlan.edited = true;

                let sourceAppointmentIndex = _.findIndex(treatmentPlan.appointments, appointment => appointment.appointmentId === appointmentId);
                treatmentPlan.appointments[sourceAppointmentIndex].charges = treatmentPlan.appointments[sourceAppointmentIndex].charges.filter(charge => charge.id !== chargeId);

                this.setState({treatmentPlan}, () => {
                    this.props.setState(this.state.stateManagementId, {...this.state});
                });
            } else { // otherwise this is between appointments

                const chargeId = parseInt(event.draggableId.split('_')[1], 10);
                const charge = _.find(this.state.treatmentPlan.unassignedCharges, charge => charge.id === chargeId);

                const sourceAppointmentId = parseInt(event.source.droppableId.split('_')[1], 10);
                const destinationAppointmentId = parseInt(event.destination.droppableId.split('_')[1], 10);

                let sourceAppointment = _.find(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === sourceAppointmentId);
                sourceAppointment.charges = sourceAppointment.charges.filter(charge => charge.id !== chargeId);

                const destinationAppointment = _.find(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === destinationAppointmentId);

                // assign the charge to the appointment
                charge.appointment = {appointmentId: destinationAppointment.appointmentId};

                destinationAppointment.charges.push(charge);

                const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);
                treatmentPlan.edited = true;

                treatmentPlan.appointments = treatmentPlan.appointments.map(appointment => {
                        if (appointment.appointmentId === destinationAppointmentId)
                            return destinationAppointment;
                        else if (appointment.appointmentId === sourceAppointmentId)
                            return sourceAppointment;
                        else
                            return appointment;
                    }
                );

                this.setState({treatmentPlan}, () => {
                    this.props.setState(this.state.stateManagementId, {...this.state});
                });
            }
        }

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_TREATMENT_PLAN_BY_ID.SAVE.action
            },
            sourceTpId: this.state.treatmentPlan.id,
        });
    }

    onCreateTreatmentPlanDocument = (details) => {

        this.props.createTreatmentPlanDocument(details);
        this.props.onHideDialog(HM_CREATE_TREATMENT_PLAN_DOC.id);
    }

    showDialogs = () => {

        const contents = [];

        if (this.props.showDialog !== null && this.props.showDialog.id === HM_CREATE_TREATMENT_PLAN_DOC.id) {
            contents.push(
                <CreateTreatmentPlanDocument key={HM_CREATE_TREATMENT_PLAN_DOC.id}
                                             treatmentPlan={this.state.treatmentPlan}
                                             onCreateDialog={this.onCreateTreatmentPlanDocument}
                                             onHideDialog={this.props.onHideDialog}
                />
            )
        } else if (this.state[HM_AddFollowOnRecall.id]) {
            contents.push(
                <AddRecall newRecall={this.state.newRecall}
                           loginIdentity={this.props.loginIdentity}
                           onHideDialog={this.onHideMenuEntry}
                           recallTypes={this.props.recallTypes}
                           onOkDialog={this.addRecall}
                           showProviders={false}
                />
            );
        } else {
            contents.push(ShowMessageDialog(this, HM_SaveBeforeAction, () => {
                this.setState({[HM_SaveBeforeAction.id]: false})
            }));
        }
        return contents;
    }

    treatmentHandler = (entry) => {
        this.callbacks[TB_SHOW_TREATMENT_ADD.id](true, entry, null, TB_SHOW_TREATMENT_ADD.id);
    };

    onPlaceAppointment = (appointment) => {

        if (this.state.canSave.status) {
            this.setState({[HM_SaveBeforeAction.id]: true});
        } else {
            this.props.sendMessage({type: TB_PLACE_APPOINTMENT.id, payload: appointment})
        }
    }

    render() {

        if (!this.props.resourcesLoaded || !this.state.treatmentPlanLoaded || !this.state.chartLoaded) {
            return null;
        }
        this.mapThePalette();

        const tpStatuses = tpStatusDropDownList();
        const header = 'Treatment Charting';

        let selectedCategory = this.state.selectedCategory === null ? this.props.favCategoryGroups[0] : this.state.selectedCategory;
        selectedCategory = selectedCategory === undefined ? {contents: []} : selectedCategory;

        const categories = this.props.favCategoryGroups;
        const content = [];

        const toggleCodeText = this.state.showCode ? 'Show Full Description' : 'Show Code';
        const toggleCodeIcon = this.state.showCode ? 'fas fa-expand-alt' : 'fas fa-compress-alt';

        content.push(this.showDialogs());

        content.push(
            <Accordion key='ac_0' multiple={true} activeIndex={this.state.activeIndex} onTabChange={(e) => {
                this.setState({activeIndex: e.index})
            }}>
                <AccordionTab key='act_0' header='General'>
                    <General treatmentPlan={this.state.treatmentPlan}
                             statuses={tpStatuses}
                             onTreatmentPlanDetailsChange={this.onChange}
                             journeyStages={this.props.journeyStages}
                             planning={true}
                    />
                </AccordionTab>
                <AccordionTab key='act_1' header={header} style={{background: '#ffffff', paddingTop: 0}}>
                    <div style={{paddingBottom: '5px', display: 'flex', flexFlow: 'row nowrap'}}>
                        <Button className="p-button-secondary"
                                label={this.state.selectedItem.label}
                                style={{backgroundColor: MouthBGColor}}
                        />
                        <Button id='toggleShowCode'
                                tooltip={toggleCodeText}
                                className="p-button-secondary"
                                icon={toggleCodeIcon}
                                onClick={this.codeToggle}
                                style={{backgroundColor: MouthBGColor}}
                        />
                        {this.showCategoryButtons(this.onChange, categories, MouthBGColor, this.state.showCode, selectedCategory)}
                    </div>
                    <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
                        <TreatmentPalette resources={this.props.resources}
                                          category={selectedCategory}
                                          selectedItem={this.state.selectedItem}
                                          onItemSelected={this.handleItemSelected}
                                          onItemDeselected={this.handleItemDeselected}
                                          onlyCode={this.state.showCode}
                        />
                        <Mouth width={MOUTH_WIDTH}
                               height={MOUTH_HEIGHT}
                               baseView={this.state.baseView}
                               resources={this.props.resources}
                               charting={this.state.charting}
                               chart={this.state.charting.chart}
                               selectedItem={this.state.selectedItem}
                               currentCommand={this.state.currentCommand}
                               parent={this}
                               loginIdentity={this.props.loginIdentity}
                               addTreatmentHandler={this.treatmentHandler}
                               addChartEntry={this.addChartingEntry}
                               removeChartingEntry={this.removeChartingEntry}
                               resetTooth={this.resetTooth}
                               onChange={this.onChange}
                        />
                    </div>
                </AccordionTab>
            </Accordion>,
            <TreatmentsAppointments key='act_tp'
                                    treatmentDisplay={TREATMENT_PLANNING}
                                    treatmentPlan={this.state.treatmentPlan}
                                    providers={this.props.providers}
                                    appointmentTypes={this.props.appointmentTypes}
                                    accountGroups={this.props.accountGroups}
                                    templateNotes={this.props.templateNotes}
                                    onComplete={this.onTreatmentComplete}
                                    onChange={this.onChange}
                                    onUpdateAppointment={this.onUpdateAppointment}
                                    favourites={this.props.resources.favourites}
                                    treatmentCodes={this.props.treatmentCodes}
                                    onAddTreatment={this.onAddTreatment}
                                    onEditTreatment={this.onEditTreatment}
                                    onDeleteTreatment={this.onDeleteTreatment}
                                    addFromCharting={this.state.addFromCharting}
                                    showAddTreatment={this.state.showAddTreatment}
                                    showEditTreatment={this.state.showEditTreatment}
                                    onShowAddEditTreatment={this.callbacks[[TB_SHOW_TREATMENT_ADD.id]]}
                                    onHideAddEditTreatment={this.callbacks[TB_HIDE_TREATMENT_ADD_EDIT.id]}
                                    currentSelectedItem={this.state.selectedItem}
                                    currentChartedEntry={this.state.currentChartedEntry}
                                    onDragEnd={this.onDragEnd}
                                    onAddAppointment={this.onAddAppointment}
                                    onDeleteAppointment={this.onDeleteAppointment}
                                    baseView={this.state.baseView}
                                    charting={this.state.charting}
                                    onHideMenuEntry={this.onHideMenuEntry}
                                    onPlaceAppointment={this.onPlaceAppointment}
                                    sendMessage={this.props.sendMessage}

            />
        );
        return content;
    }
}

const MapStateToProps = (state, ownProps) => {

    const {

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        treatmentPlanLoaded,
        treatmentPlanId,
        treatmentPlan,

        baseCategoryGroups,
        favCategoryGroups,

        chartLoaded,
        charting,

        journeyStagesLoaded,
        journeyStages,
    } = getTreatmentPlanIds(state, ownProps);

    return {

        message: state.stateManagement.message,
        messageId: state.stateManagement.messageId,
        wsmessage: state.websockets.message,

        loginIdentity: state.login.user,

        resourcesLoaded,
        resources,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        providers: state.dropDowns.providers,

        treatmentCodesLoaded,
        treatmentCodes,

        appointmentDiaryTypesLoaded: state.housekeeping.appointmentDiaryTypesLoaded,
        appointmentTypes: state.housekeeping.appointmentDiaryTypes.appointmentTypes,

        accountGroupsLoaded: state.users.accountGroupsLoaded,
        accountGroups: state.users.accountGroups,

        templateNotesLoaded: Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false,
        templateNotes: state.housekeeping.templateNotes,

        treatmentPlanLoaded,
        treatmentPlanId,
        treatmentPlan,

        baseCategoryGroups,
        favCategoryGroups,

        chartLoaded,
        charting,

        journeyStagesLoaded,
        journeyStages,

        currentState: state.stateManagement[treatmentPlanId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getTemplateNotes: () => dispatch(getResource(RES_TEMPLATE_NOTES.GET, {})),
        getAccountGroups: () => dispatch(getUserResource(RES_getAccountGroups.GET)),
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getChartResources: () => dispatch(getCHResource(RES_chartResources.GET)),
        getHousekeepingTCodes: () => dispatch(getResource(RES_HOUSEKEEPING_TCODES.GET, {})),
        getTreatmentPlanById: (treatmentPlanId) => dispatch(getTPResource(RES_TREATMENT_PLAN_BY_ID.GET, treatmentPlanId, null)),
        getChart: (id) => dispatch(getChart(RES_CHART.GET_BY_PATIENT_ID, id)),
        getJourneyStages: () => dispatch(getHKResource(RES_HOUSEKEEPING_JSTAGES.GET, {})),

        createTreatmentPlanDocument: (details) => dispatch(createTreatmentPlanDocument(details)),
        clearTreatmentPlan: (id) => dispatch(clearTreatmentPlan(id)),
        placeRecall: (newRecall) => dispatch(addRecall(RES_placeRecall.ADD, newRecall)),

        sendMessage: (message) => dispatch(messageBus(message)),

        setState: (id, data, parent) => dispatch(setState(id, data, parent)),
        stateRequest: (source, patientId, selectedTPs) => dispatch(stateRequest(source, patientId, selectedTPs)),
    }
};

const TreatmentPlan = connect(MapStateToProps, MapDispatchToProps)(ConnectedTreatmentPlan);

export default TreatmentPlan;
