import React from 'react';
import {ComMissingTooth} from "../Commands/ComMissingTooth";
import {ComFilling} from "../Commands/ComFilling";
import {ComWatch} from "../Commands/ComWatch";

import {
    Apicectomy,
    BondedBridge,
    BondedCrown,
    BridgeOnImplant,
    CantileverBridge,
    Caries,
    ChippedTooth,
    ClosedGap,
    CrownOnImplant,
    DeciduousTooth,
    Denture,
    DriftedLeft,
    DriftedRight,
    ExtractTooth,
    Filling,
    FixedBondedOrthodonticRetainer,
    FracturedTooth,
    FullCrown,
    Impacted,
    ImplantFixture,
    Inlay,
    JacketCrown,
    MarylandBridge,
    MissingTooth,
    Onlay,
    OverErupted,
    PartiallyErupted,
    Post,
    Post_And_Core,
    RetainedRoot,
    RootCanalTreatment, TemporaryCrown,
    TemporaryFilling,
    Unerupted,
    Veneer,
    Watch
} from "../Model/Constants";

import _ from "lodash";
import {ComDeciduous} from "../Commands/ComDeciduous";
import {ComImpacted} from "../Commands/ComImpacted";
import {ComPartiallyErupted} from "../Commands/ComPartiallyErupted";
import {ComUnerupted} from "../Commands/ComUnerupted";
import {ComOverErupted} from "../Commands/ComOverErupted";
import {ComClosedGap} from "../Commands/ComClosedGap";
import {ComDriftedLeft} from "../Commands/ComDriftedLeft";
import {ComDriftedRight} from "../Commands/ComDriftedRight";
import {ComVeneer} from "../Commands/ComVeneer";
import {ComPost} from "../Commands/ComPost";
import {ComBondedCrown} from "../Commands/ComBondedCrown";
import {ComJacketCrown} from "../Commands/ComJacketCrown";
import {ComFullCrown} from "../Commands/ComFullCrown";
import {ComOnlay} from "../Commands/ComOnlay";
import {ComCrownOnImplant} from "../Commands/ComCrownOnImplant";
import {ComImplantFixture} from "../Commands/ComImplantFixture";
import {ComPostAndCore} from "../Commands/ComPostAndCore";
import {ComRetainedRoot} from "../Commands/ComRetainedRoot";
import {ComBridgeImplant} from "../Commands/ComBridgeImplant";
import {ComExtractTooth} from "../Commands/ComExtractTooth";
import {ComRootCanalTreatment} from "../Commands/ComRootCanalTreatment";
import {ComApicectomy} from "../Commands/ComApicectomy";
import {ComCaries} from "../Commands/ComCaries";
import {ComTempFilling} from "../Commands/ComTempFilling";
import {ComInlay} from "../Commands/ComInlay";
import {ComChipped} from "../Commands/ComChipped";
import {ComFractured} from "../Commands/ComFractured";
import {ComDenture} from "../Commands/ComDenture";
import {ComBridgeBonded} from "../Commands/ComBridgeBonded";
import {ComOrthoFixedRetainer} from "../Commands/ComOrthoFixedRetainer";
import {COLOR_ChiralPalette} from "../../Client/Constants";
import {CI_STATUS_USABLE} from "../../../Constants";

const SELECTED_ITEM = '#CFE3FA';

const paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};
const iconStyle = {...paddingStyle, color: COLOR_ChiralPalette, fontSize: '20px', marginBottom: '3px'};

const chartContextMenuBaseItems = (mouth) => {

    const content = [];

    content.push(
        {
            label: 'Image On Tooth', icon: 'fa fa-image', command: (e) => {
            }
        },
        {separator: true},
        {
            label: 'Tooth History', icon: 'fas fa-history', command: (e) => {
            }
        },
    );

    if (mouth.props.baseView) {
        content.push(
                {
                    label: 'Reset Tooth', icon: 'fas fa-sync-alt', command: (e) => {
                        mouth.props.resetTooth(mouth.currentItem);
                    }
                }
        );
    }
    return content;
}

export const mapBaseChartItems = (resources) => {

    let groups = [];

    resources.bcfavourites.forEach((category) => {

        let entry = {
            name: category.description,
            code: category.code,
            selected: false,
            contents: [],
        };

        let contents = [];

        category.items.forEach((item) => {

            const command = findCommandFromCode(item.code);

            if (command !== null) {
                contents.push({
                    code: item.code,
                    command,
                    chartItem: item,
                    label: item.shortDescription,
                })
            }
        });

        if (contents.length > 0) {
            entry.contents = contents;
            groups.push(entry);
        }
    });
    return groups;
};

export const mapFavChartItems = (resources) => {

    let groups = [];

    resources.favourites.forEach((category) => {

        let entry = {
            name: category.description,
            code: category.code,
            selected: false,
            contents: [],
        };

        let contents = [];

        category.items.forEach((item) => {

            if (item.needsGraphic && item.chartingItem) {

                const command = findCommandFromCode(item.chartingItem.code);

                if (command !== null) {
                    contents.push({
                        code: item.code,
                        command,
                        chartItem: findItemFromCode(resources.chartingItems, item.chartingItem),
                        label: item.description,
                        chargeableItem: item,
                    })
                }
            }
        });
        if (contents.length > 0) {
            entry.contents = contents;
            groups.push(entry);
        }
    });
    return groups;
};

const findItemFromCode = (items, code) => {

    return _.find(items, item => item.code === code);
};

const findCommandFromCode = (code) => {

    switch (code) {
        case Apicectomy :
            return ComApicectomy;
        case MissingTooth :
            return ComMissingTooth;
        case Caries :
            return ComCaries;
        case ChippedTooth :
            return ComChipped;
        case Denture :
            return ComDenture;
        case OverErupted :
            return ComOverErupted;
        case ExtractTooth :
            return ComExtractTooth;
        case PartiallyErupted :
            return ComPartiallyErupted;
        case Unerupted :
            return ComUnerupted;
        case Impacted :
            return ComImpacted;
        case DeciduousTooth :
            return ComDeciduous;
        case Watch :
            return ComWatch;
        case FracturedTooth :
            return ComFractured;
        case TemporaryCrown :
            return ComFullCrown;
        case TemporaryFilling :
            return ComTempFilling;
        case Filling :
            return ComFilling;
        case FixedBondedOrthodonticRetainer :
            return ComOrthoFixedRetainer;
        case BondedBridge :
            return ComBridgeBonded;
        case BondedCrown :
            return ComBondedCrown;
        case FullCrown :
            return ComFullCrown;
        case JacketCrown :
            return ComJacketCrown;
        case CrownOnImplant :
            return ComCrownOnImplant;
        case Inlay :
            return ComInlay;
        case ImplantFixture :
            return ComImplantFixture;
        case Onlay :
            return ComOnlay;
        case Post :
            return ComPost;
        case Post_And_Core :
            return ComPostAndCore;
        case Veneer :
            return ComVeneer;
        case BridgeOnImplant :
            return ComBridgeImplant;
        case MarylandBridge :
            return null;
        case CantileverBridge :
            return null;
        case RetainedRoot :
            return ComRetainedRoot;
        case RootCanalTreatment :
            return ComRootCanalTreatment;
        case ClosedGap :
            return ComClosedGap;
        case DriftedLeft :
            return ComDriftedLeft;
        case DriftedRight :
            return ComDriftedRight;
        default :
            return null;
    }
};

const GetItems = (props, paddingStyle) => {

    const contents = _.sortBy(props.category.contents, 'label', 'asc');

    const content = contents.map((categoryItem, index) => {

        let backgroundColor = null;
        if (props.selectedItem === undefined ? false : props.selectedItem.code === categoryItem.code) {
            backgroundColor = SELECTED_ITEM;
        }

        return (
            <div key={index} className="diaryPaletteEntry"
                 style={{fontWeight: 'bold', ...paddingStyle, backgroundColor}}>
                <div onClick={(item) => {
                    props.onItemSelected(categoryItem);
                }}>{categoryItem.chartItem.shortDescription}</div>
            </div>
        )
    })
    return content;
}

export const BasePalette = (props) => {

    return (
        <div style={{display: 'flex', flexFlow: 'column nowrap', paddingRight: 20, width: '200px'}}>
            <div style={{backgroundColor: '#f5f5f5', borderBottom: '1px solid grey'}}>
                <i style={iconStyle}
                   className='fas fa-palette'
                />Charting Items
            </div>
            <div className="diaryPaletteEntry"
                 style={{fontWeight: 'bold'}}
                 onClick={(item) => {
                     props.onItemDeselected();
                 }}
            >
                <i style={iconStyle}
                   className='fas fa-mouse-pointer'
                />Deselect
            </div>

            <div style={{backgroundColor: '#f5f5f5', borderTop: '1px solid grey', borderBottom: '1px dotted grey'}}>
                <i style={iconStyle}
                   className='fas fa-folder-open'
                />{props.category.name}
            </div>

            {GetItems(props, paddingStyle)}
        </div>
    )
};

export const TreatmentPalette = (props) => {

    let categoryItems = props.category ? _.filter(props.category.contents, item => item.chargeableItem.status === CI_STATUS_USABLE) : [];
    categoryItems = _.sortBy(categoryItems, 'label', 'asc');

    const label = props.category ? props.onlyCode ? props.category.code : props.category.name : '';

    return (
        <div style={{display: 'flex', flexFlow: 'column nowrap', paddingRight: 20, width: '200px'}}>
            <div style={{backgroundColor: '#f5f5f5', borderBottom: '1px solid grey'}}>
                <i style={iconStyle}
                   className='fas fa-palette'
                />Treatment Items
            </div>
            <div className="diaryPaletteEntry"
                 style={{fontWeight: 'bold'}}
                 onClick={() => {
                     props.onItemDeselected();
                 }}
            >
                <i style={iconStyle}
                   className='fas fa-mouse-pointer'
                />Deselect
            </div>

            <div style={{backgroundColor: '#f5f5f5', borderTop: '1px solid grey', borderBottom: '1px dotted grey'}}>
                <i style={iconStyle}
                   className='fas fa-folder-open'
                />{label}
            </div>

            {categoryItems.map((categoryItem, index) => {

                let backgroundColor = null;
                if (props.selectedItem === undefined ? false : props.selectedItem.code === categoryItem.code) {
                    backgroundColor = SELECTED_ITEM;
                }

                const label = props.onlyCode ? categoryItem.code : categoryItem.label;

                return (
                    <div key={index} className="diaryPaletteEntry"
                         style={{fontWeight: 'bold', ...paddingStyle, backgroundColor}}>
                        <div onClick={() => {
                            props.onItemSelected(categoryItem);
                        }}>{label}</div>
                    </div>
                )
            })}
        </div>
    )
};

export const getChartContextMenuItems = (mouth) => {

    if (mouth.props.currentCommand === null)
        return chartContextMenuBaseItems(mouth);

    let commandItems = mouth.props.currentCommand.getCommandContextMenuItems(mouth);

    return [...commandItems, ...chartContextMenuBaseItems(mouth)];
};
