import React from 'react';
import {Dialog} from "primereact/components/dialog/Dialog";
import {HM_WORK_REQUIRED_RECEIVED, SU_STATUS_ACTIVE,} from "../../../../Constants";
import {ICON_BARCODE, ICON_CANCEL, ICON_OK, ICON_SEARCH} from "../../../../icons";
import {Button} from "primereact/components/button/Button";
import {Calendar} from "primereact/components/calendar/Calendar";
import {Dropdown} from "primereact/dropdown";
import _ from "lodash";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {InputText} from "primereact/inputtext";
import {inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";

const createLeftHandSide = (props) => {

    const header = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
        <i className={ICON_SEARCH} style={{margin: '4px 4px 0 0'}}/>
        <InputText type="search"
                   value={props.patterns.namePattern}
                   onInput={(e) => {
                       props.searchForWorkRequired({pattern: e.target.value});
                   }}
                   placeholder="Search"
                   size="50"
                   autoFocus
        />
        <i className={ICON_BARCODE} style={{margin: '4px 4px 0px 4px'}}/>
        <InputText value={props.patterns.barcodePattern}
                   onInput={(e) => {
                       props.searchForWorkByBarcode({pattern: e.target.value});
                   }} placeholder="Barcode" size="50"/>
    </div>;

    const selection = props.labEntry ? _.find(props.labWorkResults, entry => entry.id === props.labEntry.id) : null;

    return (
        <div className="p-col-6">
            <div className="p-grid p-fluid form-group p-col-12">
                <ScrollPanel style={{
                    width: '100%',
                    height: '405px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'gray'
                }} className='custom'>

                    <DataTable value={props.labWorkResults}
                               className='p-datatable-gridlines'
                               header={header}
                               selectionMode="single"
                               selection={selection}
                               onSelectionChange={(e) => {
                                   props.onChange({owner: `selectedLabEntry`, value: e.value})
                               }}
                               style={{fontSize: 'small'}}
                    >

                        <Column header='Laboratory' field="laboratoryName" style={{width: '50%'}}/>
                        <Column header='Patient' field="patientName" style={{width: '50%'}}/>

                    </DataTable>
                </ScrollPanel>
            </div>
        </div>
    )
}

const createRightHandSide = (props) => {

    const labEntry = props.labEntry ? props.labEntry : {receivedOn: new Date(), receivedById: null, returnNote: ''};

    const receivedOn = labEntry.receivedOn ? new Date(labEntry.receivedOn) : new Date();
    const users = _.filter(props.users, user => user.status === SU_STATUS_ACTIVE && user.username !== 'superuser' && user.username !== 'TED_superuser');

    const receivedById = labEntry.receivedById ? labEntry.receivedById : props.loginIdentity.id;
    const receiver = _.find(users, user => receivedById === user.id);

    const _props = {
        onChange: props.onChange,
        target: 'labEntry',
        labEntry
    };

    return (
        <div className="p-col-6">
            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-2">
                    <label>Date</label>
                </div>
                <div className="p-col-4">
                    <Calendar value={receivedOn}
                              dateFormat="dd/mm/yy"
                              onChange={(e) => props.onChange({
                                  owner: 'labEntry.receivedOn',
                                  value: e.value
                              })}
                              disabled={!props.labEntry}
                              readOnlyInput={true}/>
                </div>
                <div className="p-col-1">
                    <label>By</label>
                </div>
                <div className="p-col-5">
                    <Dropdown key='receivedBy'
                              value={receiver}
                              options={users}
                              optionLabel='fullName'
                              onChange={(e) => {
                                  props.onChange({owner: `labEntry.receivedById`, value: e.value.id})
                              }}
                              disabled={!props.labEntry}
                    />
                </div>
                <div className="p-col-6">
                    <label>Return Note</label>
                </div>
                <div className="p-col-12">
                    {inputTextArea(_props, 'returnNote', 19, 100, !props.labEntry, false)}
                </div>
            </div>
        </div>
    )
}

export const DiaryReceiveWorkRequired = (props) => {

    const labEntry = props.labEntry ? {...props.labEntry} : null;

    const footer = (
        <div>
            <Button label="OK"
                    icon={ICON_OK}
                    onClick={() => {
                        props.markWorkReceived(labEntry);
                    }}
                    disabled={labEntry === null || labEntry.receivedById === null}
            />
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(props.target.id)
                    }}/>
        </div>
    );

    return (
        <Dialog header={HM_WORK_REQUIRED_RECEIVED.header}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(props.target.id)
                }}
        >
            <div className="p-grid p-fluid p-col-12">
                {createLeftHandSide(props)}
                {createRightHandSide(props)}
            </div>
        </Dialog>
    )
}

