import React, {Component} from 'react';
import {connect} from "react-redux";
import Websocket from "react-websocket";
import {ac} from "../index";
import {handleAppointmentMessage, handleUpdateMessage} from "../actions/websockets";

class ConnectedClientUpdatesWebsocketHandler extends Component {

    constructor(props) {
        super(props);

        this.sdpws = null;
    }

    onError = (error) => {
        throw new Error(error)
    }

    onOpenUpdatesTopic = () => {
    }

    onOpenAppointmentTopic() {
    }

    onCloseUpdatesTopic = () => {
    }

    onCloseAppointmentTopic() {
    }

    componentDidMount() {
    }

    render() {
        try {

            return (
                <div>
                    <Websocket url={ac.getBASEWSURL() + '/UpdatesTopic'}
                               onOpen={this.onOpenUpdatesTopic}
                               onError={this.onError}
                               onMessage={this.props.handleUpdateMessage.bind(this)}
                               onClose={this.onCloseUpdatesTopic}
                    />
                    <Websocket url={ac.getBASEWSURL() + '/AppointmentTopic'}
                               onOpen={this.onOpenAppointmentTopic}
                               onError={this.onError}
                               onMessage={this.props.handleAppointmentMessage.bind(this)}
                               onClose={this.onCloseAppointmentTopic}
                    />
                </div>
            );
        } catch (error) {
            return <h1>{`Websocket connect error : ${error}`}</h1>;
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleUpdateMessage: (data) => dispatch(handleUpdateMessage(data)),
        handleAppointmentMessage: (data) => dispatch(handleAppointmentMessage(data)),
    };
};

const ClientUpdatesWebsocketHandler = connect(null, mapDispatchToProps)(ConnectedClientUpdatesWebsocketHandler);

export default ClientUpdatesWebsocketHandler;
