import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Card} from 'primereact/components/card/Card';
import {PickList} from 'primereact/components/picklist/PickList';
import {Button} from 'primereact/components/button/Button';

import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {HM_AddBCFav, HM_EditBCFav} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {InputText} from "primereact/components/inputtext/InputText";

export default class AddBaseChartFavourite extends Component {

    constructor(props) {
        super(props);

        if (props.editing) {
            this.state = {
                baseChartingFavourite: props.item,
                globalFilter: '',
                sourceTCs: [],
                targetTCs: [],
            }
        } else {
            this.state = {
                baseChartingFavourite: {
                    id: null,
                    mc: ac.getMcId(),
                    description: "",
                    code: "",
                    items: [],
                    usedBy: [],
                },
                globalFilter: '',
                sourceTCs: [],
                targetTCs: [],
            }
        }

        this.onChange = this.onChange.bind(this);
        this.onTCSelection = this.onTCSelection.bind(this);
    }

    componentDidMount() {

        this.calculatePickList('');
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    calculatePickList = (globalFilter) => {

        let codes = [];

        this.state.baseChartingFavourite.items.forEach((targetCode) => {
            const index = _.findIndex(this.props.chartingItems, (sourceCode) => targetCode.id === sourceCode.id);
            codes.push(this.props.chartingItems[index]);
        });

        let sourceTCs = _.filter(this.props.chartingItems, code => {
            const pattern = `${code.longDescription}`;
            return (pattern.toLowerCase().includes(globalFilter.toLowerCase()) || globalFilter === '') && pattern.trim() !== '' && !codes.includes(code);
        });

        sourceTCs = _.sortBy(sourceTCs, ['longDescription'], ['asc']);

        let targetTCs = this.state.baseChartingFavourite.items;
        targetTCs = _.sortBy(targetTCs, ['longDescription'], ['asc']);

        this.setState({sourceTCs: sourceTCs, targetTCs: targetTCs});
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    descriptionTemplate({longDescription}) {
        return (
            <div className="p-helper-clearfix">
                <div style={{fontSize: 'small'}}>{longDescription}</div>
            </div>
        )
    }

    onTCSelection(event) {

        const baseChartingFavourite = {...this.state.baseChartingFavourite};
        baseChartingFavourite.items = event.target;

        this.setState({
            baseChartingFavourite,
            sourceTCs: _.sortBy(event.source, ['longDescription'], ['asc']),
            targetTCs: _.sortBy(event.target, ['longDescription'], ['asc'])
        });
    }

    render() {

        const tfDefined = (this.state.baseChartingFavourite.description !== '' && this.state.targetTCs.length > 0) ? '' : 'disabled';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.baseChartingFavourite)
                        }}
                        disabled={tfDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            const target = this.props.editing ? HM_EditBCFav.id : HM_AddBCFav.id;
                            this.props.onHideDialog(target)
                        }}/>
            </div>
        );

        const possibleHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <span style={{margin: '4px 4px 0 0'}}>Possible</span>
            <InputText type="search"
                       onInput={(e) => {
                           this.calculatePickList(e.target.value);
                       }}
                       placeholder="Code Search"
                       size="50"
                       autoFocus
            />
        </div>;

        const props = {
            onChange: this.onChange,
            target: 'baseChartingFavourite',
            baseChartingFavourite: this.state.baseChartingFavourite,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={this.props.visible}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(this.props.editing ? HM_EditBCFav.id : HM_AddBCFav.id)
                    }}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-5">
                            <label>Description</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'description', '', false, true)}
                        </div>
                        <div className="p-col-5">
                            <label>Code</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'code', '', false, true)}
                        </div>

                        <Card title='Base Chart Favourite Members' style={{width: '100%'}}>
                            <PickList className='p-lg-12 p-md-12'
                                      sourceHeader={possibleHeader}
                                      targetHeader="Actual"
                                      itemTemplate={this.descriptionTemplate}
                                      showSourceControls={false}
                                      showTargetControls={false}
                                      source={this.state.sourceTCs}
                                      target={this.state.targetTCs}
                                      style={{paddingLeft: 0}}
                                      onChange={this.onTCSelection}
                            />
                        </Card>
                    </div>
                </div>
            </Dialog>
        )
    }
};
