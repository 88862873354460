import * as Actions from './index';
import {REQUEST_ALL_USERS_AND_CAPABILITIES, REQUEST_CAPABILITIES} from './index';
import {ac} from '../index'
import axios from "axios";

export const baseURL = '/User';

export const RES_getAllCapabilities = {
    GET: {url: `${baseURL}/getCapabilities`, request: REQUEST_CAPABILITIES, receive: Actions.RECEIVE_CAPABILITIES},
};

export const RES_getUsersAndCapabilities = {
    GET: {url: `${baseURL}/getUsersAndCapabilities`, request: REQUEST_ALL_USERS_AND_CAPABILITIES, receive: Actions.RECEIVE_ALL_USERS_AND_CAPABILITIES},
    SAVE_USERS: {url: `${baseURL}/userCapabilities`, action: Actions.SAVE_USERS_CAPABILITIES},
    SAVE_ROLES: {url: `${baseURL}/roleCapabilities`, action: Actions.SAVE_ROLES_CAPABILITIES},
    CLEAR: {action: Actions.CLEAR_ROLES_CAPABILITIES},
};

export const RES_USER_DETAILS = {

    GET: {url: `${baseURL}/getUser`, request: Actions.REQUEST_USER_DETAILS, receive: Actions.RECEIVE_USER_DETAILS},
    NHS_SAVE: {action: Actions.UPDATE_USER_NHS_DETAILS},
    CLEAR: {action: Actions.CLEAR_USER_DETAILS},
};

export const RES_FIND_USERS = {
    CLEAR: {action: Actions.CLEAR_USER_SEARCH}
};

export const RES_DIARY_SORT_ORDER = {
    GET: {action: Actions.DIARY_SORT_ORDER, url: `${baseURL}/diarySortOrder`},
    SET: {action: Actions.DIARY_SORT_ORDER, url: `${baseURL}/diarySortOrder`}
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params});
    }
};

export const getAllUsersWizard = (mcId) => {

    return (dispatch) => {

        axios.get(`https://${ac.getBASERESTURL()}/User/findShortUA?mcId=${mcId}`)
            .then(res => {
                dispatch({type: Actions.RECEIVE_USER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_USER_SEARCH, payload: []});
    }
};

export const getAllUsers = () => {

    const searchURL = `${baseURL}/findShort?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL)
            .then(res => {

                dispatch({type: Actions.RECEIVE_USER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_USER_SEARCH, payload: []});
    }
};

export const getSedationAssistantsShort = () => {

    const searchURL = `${baseURL}/findSedationAssistantsShort?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL)
            .then(res => {

                dispatch({type: Actions.RECEIVE_SEDATION_ASSISTANTS, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_SEDATION_ASSISTANTS, payload: []});
    }
};

export const getMessengerRecipients = () => {

    const URL = `${baseURL}/getRecipients?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(URL)
            .then(res => {

                dispatch({type: Actions.RECEIVE_MESSENGER_RECIPIENTS, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_MESSENGER_RECIPIENTS, payload: []});
    }
};

export const getPeformers = () => {

    const searchURL = `${baseURL}/findShort?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL)
            .then(res => {

                dispatch({type: Actions.RECEIVE_USER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_USER_SEARCH, payload: []});
    }
};

export const getUsersBySurname = (urlPattern) => {

    const searchURL = `${baseURL}/findShort?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_USER_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_USER_SEARCH, payload: []});
    }
};

export const addUser = (user, specialisms) => {

    return (dispatch) => {

        const userData = {...user, specialisms};

        ac.fetchClient().post(`${baseURL}/addUser?mcId=` + ac.getMcId(), userData)
            .then(res => {

                dispatch({type: Actions.RECEIVE_USER_ADD, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_USER_ADD, payload: {}});
    }
};

export const getUser = (loaded, id) => {

    if (loaded === undefined) return {type: ''};

    return (dispatch) => {

        if (!loaded) {
            ac.fetchClient().get(`${baseURL}/getUser?mcId=${ac.getMcId()}&id=${id}`)
                .then(res => {
                    dispatch({type: Actions.RECEIVE_USER_DETAILS, payload: res.data, loaded});
                });
            dispatch({type: Actions.REQUEST_USER_DETAILS, payload: id});
        } else {
            dispatch({type: Actions.RECEIVE_USER_DETAILS, payload: id, loaded});
        }
    }
};

export const saveUserDetails = (data) => {

    return (dispatch) => {
        ac.fetchClient().post(`${baseURL}/userDetails`, data)
            .then((response) => {

                dispatch({type: Actions.COMPLETED_SAVE_USER_DETAILS, payload: true})
            });
        dispatch({type: Actions.REQUEST_SAVE_USER_DETAILS, payload: false});
    }
};

export const clearUserDetails = (details) => {

    return (dispatch) => {
        dispatch({type: Actions.CLEAR_USER_DETAILS, payload: details.id});
    }
};

export const setUserStatus = (id, status) => {

    return (dispatch) => {
        ac.fetchClient().post(`${baseURL}/setStatus?mcId=${ac.getMcId()}&id=${id}&status=${status}`)
            .then((response) => {
                dispatch({type: Actions.COMPLETED_SET_USER_STATUS, payload: true})
            });
        dispatch({type: Actions.REQUEST_SET_USER_STATUS, payload: false});
    }
};

export const updateUserNHSDetails = (details) => {

    return (dispatch) => {
        dispatch({type: Actions.UPDATE_USER_NHS_DETAILS, payload: details});
    }
};

export const getDiarySortOrder = ({url, action}) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`)
            .then(res => {
                dispatch({type: action, payload: res.data})
            });
    }
};

export const setDiarySortOrder = ({url, action}, newOrder) => {

    return (dispatch) => {

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}&newOrder=${newOrder}`)
            .then(res => {
                dispatch({type: action, payload: res.data})
            });
    }
};
