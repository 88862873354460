import React, {Component} from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";

export default class CopyTemplate extends Component {

    constructor(props) {
        super();

        this.state = {
            template: props.template,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    render() {

        const copyOK = this.state.template.visibleName.trim() === '' ? 'disabled' : '';
        const origin = this.props.origin;

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={()=>{this.props.onSaveDialog(this.state.template)}}
                        disabled={copyOK}
                />
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(origin);
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'template',
            template: this.state.template,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header="Document Template Details"
                    footer={footer}
                    visible={this.props.visible}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(origin);
                    }}>
                <label>Copy the selected template</label>
                <div id="detailPanel">
                    <Panel header='Document Template Copy'>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-4">
                                <label>Template Name</label>
                            </div>
                            <div className="p-col-8">
                                {inputText(props, 'visibleName', '')}
                            </div>
                            <div className="p-col-4">
                                <label>Notes</label>
                            </div>
                            <div className="p-col-8">
                                {inputTextArea(props, 'notes', 5, 30, false, false)}
                            </div>
                        </div>
                    </Panel>
                </div>
            </Dialog>
        )
    }
};
