import * as Actions from '../actions/index';
import _ from 'lodash';
import {SM_ACTIVE_LOGINS, SM_ADMIN_GROUP_SUBSCRIPTIONS, SM_SUBSCRIPTION_DATA} from "../actions/stateManagement";

const initialState = {

    groupsLoaded: false,
    groups: [],

};

export const admin = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.REQUEST_ADMIN_GROUPS:
                return ({
                    ...state,
                    groupsLoaded: false,
                    groups: []
                })

            case Actions.RECEIVE_ADMIN_GROUPS:
                return ({
                    ...state,
                    groupsLoaded: true,
                    groups: action.payload,
                })

            case Actions.REQUEST_ADMIN_GROUP_SUBSCRIPTIONS: {

                const subscriptionsLoadedId = `${SM_ADMIN_GROUP_SUBSCRIPTIONS.id}_Loaded_${action.id}`;
                const subscriptionsDataId = `${SM_ADMIN_GROUP_SUBSCRIPTIONS.id}_${action.id}`;

                return ({
                    ...state,
                    [subscriptionsLoadedId]: false,
                    [subscriptionsDataId]: [],
                })
            }
            case Actions.RECEIVE_ADMIN_GROUP_SUBSCRIPTIONS: {

                const subscriptionsLoadedId = `${SM_ADMIN_GROUP_SUBSCRIPTIONS.id}_Loaded_${action.id}`;
                const subscriptionsDataId = `${SM_ADMIN_GROUP_SUBSCRIPTIONS.id}_${action.id}`;

                return ({
                    ...state,
                    [subscriptionsLoadedId]: true,
                    [subscriptionsDataId]: action.payload,
                })
            }

            case Actions.RECEIVE_SUBSCRIPTION_DETAILS: {

                const subscriptionLoadedId = `${SM_SUBSCRIPTION_DATA.loaded}_${action.id}`;
                const subscriptionDataId = `${SM_SUBSCRIPTION_DATA.id}_${action.id}`;

                return ({
                    ...state,
                    [subscriptionLoadedId]: true,
                    [subscriptionDataId]: action.payload,
                })
            }

            case Actions.REQUEST_SUBSCRIPTION_SEARCH:
                return ({
                    ...state,
                    resultsLoaded: false,
                    results: []
                })

            case Actions.ADD_ADMIN_GROUP_SUBSCRIPTION:
                return ({
                    ...state,
                    result: action.payload
                })

            case Actions.RECEIVE_SUBSCRIPTION_SEARCH:
                return ({
                    ...state,
                    resultsLoaded: true,
                    results: action.payload,
                })

            case Actions.RECEIVE_COUNTRY_CODES:
                return ({
                    ...state,
                    countryCodesLoaded: true,
                    countryCodes: action.payload,
                })

            case Actions.RECEIVE_SUBSCRIPTION_ACTIVE_LOGINS:

                const activeLoginsLoadedId = `${SM_ACTIVE_LOGINS.loaded}_${action.subscriptionId}`;
                const activeLoginsDataId = `${SM_ACTIVE_LOGINS.id}_${action.subscriptionId}`;

                return ({
                    ...state,
                    [activeLoginsLoadedId]: true,
                    [activeLoginsDataId]: action.payload,
                })

            default :
                return state;
        }
    } catch (error) {
        console.log(error);
    }
}
