import * as Actions from './index';
import {ac} from '../index'

const baseURL = 'charting';

export const RES_chartResources = {
    GET: {
        url: `${baseURL}/chartResources`,
        request: Actions.REQUEST_CHART_RESOURCES,
        receive: Actions.RECEIVE_CHART_RESOURCES
    }
};

export const RES_CHART = {
    GET: {url: `${baseURL}/chartByAppointmentId`, request: Actions.REQUEST_CHART, receive: Actions.RECEIVE_CHART},
    GET_BY_PATIENT_ID: {
        url: `${baseURL}/chartByPatientId`,
        request: Actions.REQUEST_CHART,
        receive: Actions.RECEIVE_CHART
    },
    SAVE: {url: `${baseURL}/update`, action: Actions.SAVE_CHART},
    CLEAR: {action: Actions.CLEAR_CHART},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_CHART}
};

export const getResource = ({url, request, receive}) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`)
            .then(res => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request});
    }
};

export const getChart = ({url, request, receive}, id) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(res => {
                dispatch({type: receive, id, payload: {...res.data}});
            })
            .catch((error) => {
                console.log(error);
            });
        dispatch({type: request});
    }
};
