import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {InputNumber} from 'primereact/inputnumber';

import {dropDownArray} from './OnChangeUtils';

export const BEWE = (props) => {

    const readOnly = props.readOnly;

    const newProps = {...props};

    const beweResult = 0;

    newProps.name = 'BEWEScore';

    if (!Boolean(newProps.categories.BEWEScore))
        newProps.categories.push(
            {
                category: "BEWEScore",
                elements: [
                    {id: '_Empty', name: '-'},
                    {id: '_0', name: '0'},
                    {id: '_1', name: '1'},
                    {id: '_2', name: '2'},
                    {id: '_3', name: '3'},
                ],
            }
        );

    return (
        <Panel header='BEWE'>

            <div className="p-grid p-fluid form-group">

                <div className="p-col-2 p-md-4 p-lg-4">
                    <div id="cpitn-row" className="p-dropdown-auto-width">
                        <div>
                            {dropDownArray(newProps, 'beweScore1', 1, readOnly, false)}
                            {dropDownArray(newProps, 'beweScore4', 4, readOnly, false)}
                        </div>
                        <div>
                            {dropDownArray(newProps, 'beweScore2', 2, readOnly, false)}
                            {dropDownArray(newProps, 'beweScore5', 5, readOnly, false)}
                        </div>
                        <div>
                            {dropDownArray(newProps, 'beweScore3', 3, readOnly, false)}
                            {dropDownArray(newProps, 'beweScore6', 6, readOnly, false)}
                        </div>
                    </div>
                </div>
                <div className="p-lg-12 p-md-12">
                    <label/>
                </div>

                {/**/}

                <div className="p-lg-2 p-md-2">
                    <label>BEWE Result</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    <InputNumber value={beweResult}
                                 showButtons
                                 disabled={true}/>

                </div>
                <div className="p-lg-8 p-md-8">
                    <label/>
                </div>

                {/**/}

                <div className="p-lg-12 p-md-12">
                    <label/>
                </div>

                {/**/}

                <div className="p-lg-2 p-md-2">
                    <label>None</label>
                </div>
                <div className="p-lg-4 p-md-4">
                    <label>Less than or equal to 2</label>
                </div>
                <div className="p-lg-4 p-md-4">
                    <label>Repeat at 3-year intervals</label>
                </div>
                <div className="p-lg-2 p-md-2"><label/></div>

                {/**/}

                <div className="p-lg-2 p-md-2">
                    <label>Low</label>
                </div>
                <div className="p-lg-4 p-md-4">
                    <label>Between 3 and 8</label>
                </div>
                <div className="p-lg-4 p-md-4">
                    <label>Repeat at 2-year intervals</label>
                </div>
                <div className="p-lg-2 p-md-2"><label/></div>

                {/**/}

                <div className="p-lg-2 p-md-2">
                    <label>Medium</label>
                </div>
                <div className="p-lg-4 p-md-4">
                    <label>Between 9 and 13</label>
                </div>
                <div className="p-lg-4 p-md-4">
                    <label>Repeat at 6-12 month intervals</label>
                </div>
                <div className="p-lg-2 p-md-2"><label/></div>

                {/**/}

                <div className="p-lg-2 p-md-2">
                    <label>High</label>
                </div>
                <div className="p-lg-4 p-md-4">
                    <label>14 and over</label>
                </div>
                <div className="p-lg-4 p-md-4">
                    <label>Repeat at 6-12 month intervals</label>
                </div>
                <div className="p-lg-2 p-md-2"><label/></div>
            </div>
        </Panel>
    );
};
