import * as Actions from './index';
import {ac} from '../index'


const baseURL = '/Categories';

export const RES_PAT_CATEGORIES = {
    GET: {request: Actions.REQUEST_PAT_CATEGORIES, receive: Actions.RECEIVE_PAT_CATEGORIES, url: `patientCategories`},
};

export const RES_ORTHO_CATEGORIES = {
    GET: {request: Actions.REQUEST_ORTHO_CATEGORIES, receive: Actions.RECEIVE_ORTHO_CATEGORIES, url: `orthoCategories`}
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

            ac.fetchClient().get(`${baseURL}/${url}?mcId=${ac.getMcId()}`, {params: {...params}})
                .then(res => {

                    dispatch({type: receive, payload: res.data, ...params})
                });
            dispatch({type: request, payload: false, ...params, });
    }
};
