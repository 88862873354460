import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {ColorPicker} from 'primereact/components/colorpicker/ColorPicker';
import _ from "lodash";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {
    DOCLASS_ARCHIVED,
    DOCLASS_USABLE,
    HM_AddDocClassification,
    HM_EditDocClassification
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import {DocumentClassificationData} from "../../DefaultData";

export default class AddDocumentClassification extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        if (props.editing) {

            this.state.classification = props.item;
        } else {

            this.state.classification = _.cloneDeep(DocumentClassificationData(ac.getMcId()));

        }

        this.onChange = this.onChange.bind(this);
        this.onColourSelect = this.onColourSelect.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onColourSelect(event) {

        const nc = event.value;

        const newClassification = {...this.state.classification};
        newClassification.red = nc.r;
        newClassification.green = nc.g;
        newClassification.blue = nc.b;

        this.setState({classification: newClassification})
    }

    onHide() {
        const target = this.props.editing ? HM_EditDocClassification.id : HM_AddDocClassification.id;
        this.props.onHideDialog(target)
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const classification = this.state.classification;
        const classificationNotDefined = classification.code === '' || classification.description === '';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.classification)
                        }}
                        disabled={classificationNotDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'classification',
            classification: this.state.classification,
        };

        const colorValue = {r: classification.red, g: classification.green, b: classification.blue};

        const archived = this.state.classification.status === DOCLASS_ARCHIVED;

        return (
            <Dialog header={this.props.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={this.props.visible}
                    resizable={true}
                    onHide={this.onHide}>

                <div className="p-grid p-fluid form-group">

                    <div className="p-col-3">
                        <label>Code</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'code', '')}
                    </div>

                    <div className="p-col-3">
                        <label>Description</label>
                    </div>
                    <div className="p-col-9">
                        {inputTextArea(props, 'description', 4, -1, false, false)}
                    </div>

                    <div className="p-col-3">
                        <label>Color</label>
                    </div>
                    <div className="p-col-3">
                        <ColorPicker format='rgb'
                                     value={colorValue}
                                     onChange={this.onColourSelect}
                        />
                    </div>

                    <div className="p-col-3">
                        <label>Archived</label>
                    </div>
                    <div className="p-col-3">
                        <Checkbox checked={archived}
                                  className='p-checkbox-label'
                                  onChange={(e) => {
                                      const status = e.checked ? DOCLASS_ARCHIVED : DOCLASS_USABLE;
                                      this.onChange({owner: 'classification.status', value: status})
                                  }}
                        />
                    </div>
                </div>
            </Dialog>
        )
    }
};
