import React from 'react';

import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'

import {connect} from "react-redux";
import moment from "moment";
import {Toast} from 'primereact/components/toast/Toast';
import {Panel} from 'primereact/components/panel/Panel';
import {updateAppComment, updateEventComment} from './MenuActions';
import {
    addAdHocRule,
    addAppointment,
    addBack,
    addDiaryEvent,
    getDiaryEventsByClinician,
    previewAppointmentLetter,
    removeSchedules,
    RES_CLINICIAN_WEEK_DIARY,
    RES_PRACTICE_DIARY,
    RES_sendAppointmentLetter,
    sendAppointmentLetter,
} from "../../../actions/diary";
import {convertAppointmentDAOtoEvent} from '../../../reducers/diary'
import {
    ADD,
    ADDBACK,
    DELETE,
    HM_AddAppointment,
    HM_AddDiaryEvent,
    HM_AppointmentComment,
    HM_CancelAppointment,
    HM_DeleteAppointment,
    HM_DeleteDiaryEvent,
    HM_EditDiaryEvent,
    HM_MoveAppointment,
    HM_MoveDiaryEvent,
    HM_notImplemented,
    HM_PreviewAppointmentLetter,
    HM_ResizeAppointment,
    HM_ResizeDiaryEvent,
    HM_SEND_SMS,
    HM_SendAppointmentLetter,
    JSON_DATE_FORMAT,
    MOVING_IN_DIARY,
    UP_APP_CONFIRMED,
    UP_EMAIL_DOCUMENT_PREVIEW,
    UPDATE
} from "../../../Constants";
import {ResourceContent} from "./components/DiaryResourceContent";
import {
    HELP_PRACTICE_DIARY,
    messageBus,
    setState,
    SM_PRACTICE_DIARY,
    stateRequest
} from "../../../actions/stateManagement";
import _ from "lodash";
import * as Actions from "../../../actions";
import {PALETTE_GRP_APP} from "./Constants";
import DiaryErrorBoundary, {getDiaryIds} from "./Utils";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import {getResource, RES_HOUSEKEEPING_ADETS} from "../../../actions/housekeeping";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";

import {addToLists, RES_TABLET_LISTS} from "../../../actions/tablet";
import {ProgressBar} from "primereact/progressbar";
import {getDiarySortOrder, RES_DIARY_SORT_ORDER} from "../../../actions/users";
import DiaryComponent from "./DiaryComponent";
import {RES_RESEND_PATIENT_PORTAL, resendPortalReference} from "../../../actions/personal";
import {DiaryToolbar} from "./components/DiaryToolbar";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

class ConnectedClinicianWeekDiary extends DiaryComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                source: {action: RES_CLINICIAN_WEEK_DIARY.CLEAR},

                stateManagementId: SM_PRACTICE_DIARY.id,
                currentDate: new Date(),

                clinician: props.options.clinician,

                events: [],
                resources: [],
                movingEvents: [],
                recallEvents: [],

                discriminator: Views.WEEK,
                previewToken: null,

                selectable: false,

                selectedEvent: {
                    attended: false,
                    confirmed: false,
                },
                movingEvent: null,
                resizingEvent: null,

                selectedResource: null,
                selectedProvider: null,

                reservationEvent: null,
                newDiaryEvent: null,

                privacyOn: false,

                [HM_notImplemented]: false,

                [HM_AddAppointment.id]: false,
                [HM_DeleteAppointment.id]: false,
                [HM_CancelAppointment.id]: false,

                [HM_AddDiaryEvent.id]: false,
                [HM_DeleteDiaryEvent.id]: false,

                [HM_MoveAppointment.id]: false,
                [HM_MoveDiaryEvent.id]: false,

                [HM_ResizeAppointment.id]: false,
                [HM_ResizeDiaryEvent.id]: false,

                [HM_EditDiaryEvent.id]: false,

                [HM_AppointmentComment.id]: false,

                [HM_SendAppointmentLetter.id]: false,
                [HM_PreviewAppointmentLetter.id]: false,

                [HM_SEND_SMS.id]: false,

                paletteItem: {id: null, title: ''},

                documentDetails: null,
            }
        }
        this.exitState = TAB_PARENT_CHANGE;
        this.tabletGrowl = null;

        this.onShowRightClick = this.onShowRightClick.bind(this);
        this.onShowRightClickHeader = this.onShowRightClickHeader.bind(this);
        this.onShowRightClickProviders = this.onShowRightClickProviders.bind(this);

        this.onEventDblClick = this.onEventDblClick.bind(this);
        this.calcTabAndId = this.calcTabAndId.bind(this);
        this.resizeEvent = this.resizeEvent.bind(this);
        this.moveEvent = this.moveEvent.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.showDiaryEventContent = this.showDiaryEventContent.bind(this);
        this.getEventColour = this.getEventColour.bind(this);

        this.onEventSelected = this.onEventSelected.bind(this);
        this.onEventDeselected = this.onEventDeselected.bind(this);
        this.customSlotPropGetter = this.customSlotPropGetter.bind(this);

        this.removeReservation = this.removeReservation.bind(this);

        this.showDialogs = this.showDialogs.bind(this);
        this.onCancelAppointmentAdd = this.onCancelAppointmentAdd.bind(this);
        this.onAddAppointment = this.onAddAppointment.bind(this);
        this.onDeleteAppointment = this.onDeleteAppointment.bind(this);
        this.onCancelAppointment = this.onCancelAppointment.bind(this);
        this.onUpdateAppointmentComment = this.onUpdateAppointmentComment.bind(this);

        this.onAddDiaryEvent = this.onAddDiaryEvent.bind(this);
        this.onDeleteEvent = this.onDeleteEvent.bind(this);

        this.onMoveAppointment = this.onMoveAppointment.bind(this);
        this.onMoveDiaryEvent = this.onMoveDiaryEvent.bind(this);

        this.onResizeAppointment = this.onResizeAppointment.bind(this);
        this.onResizeDiaryEvent = this.onResizeDiaryEvent.bind(this);

        this.handleMoveEvent = this.handleMoveEvent.bind(this);

        this.onAddToTablet = this.onAddToTablet.bind(this);
        this.onAddConsentsToTablet = this.onAddConsentsToTablet.bind(this);

        this.onRequestPreviewAppointmentLetter = this.onRequestPreviewAppointmentLetter.bind(this);
        this.onSendAppointmentLetter = this.onSendAppointmentLetter.bind(this);

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onExit = this.onExit.bind(this);
    };

    componentDidMount() {

        if (!Boolean(this.state.currentState)) {
            this.props.getDiarySortOrder();
            this.props.getAppointmentDiaryTypes();
            this.props.getDropDowns();
            this.props.getDiaryEvents(this.state.currentDate, this.state.currentDate, this.props.loginIdentity.id, this.state.clinician.id);
        }
        this.buildAllMenus();
    }

    componentDidUpdate(prevProps, prevState, snapShot) {

        if (this.state !== prevState) {
        }

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_DIARY_EVENTS:
                    this.setState({resources: this.props.diaryResources, events: this.props.diaryEvents}, () => {
                        this.props.getDiaryActivePopUps(this.state.currentDate);
                        this.postEventLoad();
                    });
                    break;

                case Actions.RECEIVE_DIARY_PALLETE_ENTRIES:
                    this.setState({
                        movingEvents: this.props.movingEvents,
                        recallEvents: this.props.recallEvents
                    }, () => {
                        this.postEventLoad();
                    });
                    break;

                case Actions.RECEIVE_ADD_DIARYEVENT:
                    this.setState({reservationEvent: this.props.newDiaryEvent}, () => {
                        this.postEventLoad();
                    });
                    break;

                case Actions.WSM_APPOINTMENTS:

                    switch (this.props.wsmessage.function) {

                        case ADD:
                            this.setState({
                                resources: this.props.diaryResources,
                                events: this.props.diaryEvents
                            }, () => {
                                this.postEventLoad()
                            });
                            break;

                        case ADDBACK: {
                            const addBackEventDAO = {...this.props.wsmessage.content};
                            const addBackEvent = convertAppointmentDAOtoEvent(addBackEventDAO);

                            // this will remove the palette entry for this appointment
                            const movingEvents = this.state.movingEvents.filter(event => event.id !== parseInt(addBackEvent.id, 10));

                            this.setState({
                                events: [...this.state.events, addBackEvent],
                                movingEvents,
                                selectable: false,
                                paletteItem: null
                            }, () => {
                                this.postEventLoad();
                            });
                        }
                            break;

                        case MOVING_IN_DIARY: {
                            const moveEvent = this.props.wsmessage.content;

                            moveEvent.paletteType = PALETTE_GRP_APP;

                            // this will add the palette entry for the appointment
                            const movingEvents = [...this.state.movingEvents, moveEvent];

                            this.setState({
                                events: this.state.events.filter(event => parseInt(event.id, 10) !== moveEvent.id),
                                movingEvents
                            }, () => {
                                this.postEventLoad();
                            });
                        }
                            break;

                        case DELETE:
                            const deleteEventId = this.props.wsmessage.content;

                            this.setState({events: this.state.events.filter(event => parseInt(event.id, 10) !== deleteEventId)}, () => {
                                this.postEventLoad();
                            });
                            break;

                        case UPDATE:
                            let updateEvent = convertAppointmentDAOtoEvent(this.props.wsmessage.content);

                            const events = [...this.state.events];

                            const updateIndex = _.findIndex(events, event => event.id === updateEvent.id);

                            if (updateIndex !== -1) {
                                events[updateIndex] = updateEvent;
                                this.setState({events}, () => {
                                    this.postEventLoad();
                                });
                            }
                            break;

                        default:
                            console.log('Unhandled WSM_APP : ' + this.props.wsmessage.function);
                            break;
                    }
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_APP_CONFIRMED:
                            const confirmedEventId = this.props.wsmessage.content;

                            this.setState({
                                events: this.state.events.map(event => parseInt(event.id, 10) !== confirmedEventId ? event : {
                                    ...event,
                                    confirmed: true
                                })
                            }, () => {
                                this.postEventLoad();
                            });
                            break;

                        case UP_EMAIL_DOCUMENT_PREVIEW:

                            const previewToken = this.props.wsmessage.content[6];

                            if (previewToken === this.state.previewToken) {
                                const documentDetails = this.props.wsmessage.content;
                                this.setState({documentDetails, [HM_PreviewAppointmentLetter.id]: true, previewToken});
                            }
                            break;

                        default:

                            this.setState({events: this.props.diaryEvents}, () => {
                                this.postEventLoad();
                            });
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.props.eventsLoaded || !this.props.appointmentDiaryTypesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }


        const currentDateString = moment(this.state.currentDate).format('D, ddd MMM YYYY');

        let {slotDuration, min, max} = this.props.diaryHeader;

        const minTime = new Date(min);
        const maxTime = new Date(max);

        this.postEventLoad();

        return (
            <DiaryErrorBoundary>
                <div>

                    <Toast ref={(el) => this.tabletGrowl = el}/>

                    <DiaryToolbar parent={this}
                                  helpURL={HELP_PRACTICE_DIARY}
                                  weekView={false}
                    />

                    <Panel header={`Day Diary : ${currentDateString}`} style={{paddingTop: '5px'}}>
                        <div className="p-grid">

                            {this.showDialogs()}

                            {this.attachContextMenus()}

                            <DragAndDropCalendar
                                selectable={this.state.selectable}
                                resizable={true}
                                draggableAccessor={event => true}
                                onEventResize={this.resizeEvent}
                                onEventDrop={this.moveEvent}
                                className={'p-sm-10'}
                                events={this.state.events}
                                localizer={localizer}
                                defaultView={Views.WEEK}
                                views={['week']}
                                step={parseInt(slotDuration, 10)}
                                timeslots={2}
                                min={minTime}
                                max={maxTime}
                                date={this.state.currentDate}
                                onNavigate={this.onNavigate}
                                onSelectSlot={this.handleSelect}
                                onSelectEvent={this.onDiaryEventSelect}
                                resources={this.props.diaryResources}
                                resourceIdAccessor="resourceId"
                                resourceColorAccessor="color"
                                resourceTitleAccessor="title"
                                tooltipAccessor={event => `${event.title}`}
                                components={{
                                    event: (event) => {
                                        return this.showDiaryEventContent(event);
                                    },
                                    resourceHeader: (header) => {
                                        return <ResourceContent header={header}
                                                                diary={this}
                                                                events={this.props.diaryEvents}
                                                                onShowRightClickHeader={this.onShowRightClickHeader}
                                        />
                                    }
                                }}
                                eventPropGetter={this.getEventColour}
                                slotPropGetter={this.customSlotPropGetter}
                            />

                        </div>
                    </Panel>
                </div>
            </DiaryErrorBoundary>
        );
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({action: RES_PRACTICE_DIARY.CLEAR.action});
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    const {

        userId,

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,
        providerColourScheme,

        headerLoaded,
        diaryHeader,

        resourcesLoaded,
        diaryResources,
        unscheduledResources,

        dropDownsLoaded,
        providers,

        eventsLoaded,
        diaryEvents,

        movingEventsLoaded,
        movingEvents,
        recallEvents,
        diaryTasks,

        titles,

        newDiaryEvent,
    } = getDiaryIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        userId,

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,
        providerColourScheme,

        resourcesLoaded,
        diaryResources,
        unscheduledResources,

        dropDownsLoaded,
        providers,

        headerLoaded,
        diaryHeader,

        eventsLoaded,
        diaryEvents,

        movingEventsLoaded,
        movingEvents,
        recallEvents,
        diaryTasks,

        newDiaryEvent,

        titles,

        loginIdentity: state.login.user,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        wsmessage: state.websockets.message,

        currentState: state.stateManagement[SM_PRACTICE_DIARY.id],
    }
};

const MapDispatchToProps = dispatch => {

    return {
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET)),
        getDiaryEvents: (start, end, loginIdentityId, userId) => dispatch(getDiaryEventsByClinician(start, end, loginIdentityId, userId)),
        getDiaryActivePopUps: (date) => dispatch(getResource(RES_PRACTICE_DIARY.POPUPS, {date: moment(date).format(JSON_DATE_FORMAT)})),
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getDiarySortOrder: () => dispatch(getDiarySortOrder(RES_DIARY_SORT_ORDER.GET)),

        addAppointment: (newAppointment, createRecall, referralDate, patient) => dispatch(addAppointment(newAppointment, createRecall, referralDate, patient)),
        addBack: (appointment) => dispatch(addBack(appointment)),
        addDiaryEvent: (newEvent) => dispatch(addDiaryEvent(newEvent)),
        addAdHocRule: (providerId, start, end, loginId) => dispatch(addAdHocRule(providerId, start, end, loginId)),
        addToLists: (tabletMember) => dispatch(addToLists(RES_TABLET_LISTS.ADD_TO, tabletMember)),

        updateAppComment: (id, summary, comment) => dispatch(updateAppComment(id, summary, comment)),
        updateEventComment: (id, comment) => dispatch(updateEventComment(id, comment)),

        unscheduleProvider: (rule, start, end, loginId) => dispatch(removeSchedules(rule, start, end, loginId)),

        previewAppointmentLetter: (patientDocument, appointment) => dispatch(previewAppointmentLetter(RES_sendAppointmentLetter.PREVIEW, patientDocument, appointment)),
        sendAppointmentLetter: (patientDocument, appointment) => dispatch(sendAppointmentLetter(RES_sendAppointmentLetter.CREATE, patientDocument, appointment)),
        resendPortalReference: (patientId) => dispatch(resendPortalReference(RES_RESEND_PATIENT_PORTAL.SEND, patientId)),

        sendMessage: (message) => dispatch(messageBus(message)),
        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const ClinicianWeekDiary = connect(mapStateToProps, MapDispatchToProps)(ConnectedClinicianWeekDiary);

export default ClinicianWeekDiary;
