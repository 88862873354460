import * as Actions from './index';
import {ac} from '../index'

export const getAllSubscriptions = () => {

    const searchURL = `/Admin/Subscription/findShortAll`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL)
            .then(res => {

                dispatch({type: Actions.RECEIVE_SUBSCRIPTION_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_SUBSCRIPTION_SEARCH, payload: []});
    }
};

export const getSubscriptionsByName = (urlPattern) => {

    const searchURL = `/Admin/Subscription/findShortByName`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_SUBSCRIPTION_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_SUBSCRIPTION_SEARCH, payload: []});
    }
};

export const getSubscriptionsByContact = (urlPattern) => {

    const searchURL = `/Admin/Subscription/findShortByContact`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_SUBSCRIPTION_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_SUBSCRIPTION_SEARCH, payload: []});
    }
};

export const getSubscriptionsByEmail = (urlPattern) => {

    const searchURL = `/Admin/Subscription/findShortByEmail`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_SUBSCRIPTION_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_SUBSCRIPTION_SEARCH, payload: []});
    }
};

export const getSubscriptionsByPostcode = (urlPattern) => {

    const searchURL = `/Admin/Subscription/findShortByPostcode`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_SUBSCRIPTION_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_SUBSCRIPTION_SEARCH, payload: []});
    }
};

export const getSubscriptionsByTelnumber = (urlPattern) => {

    const searchURL = `/Admin/Subscription/findShortByTelnumber`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_SUBSCRIPTION_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_SUBSCRIPTION_SEARCH, payload: []});
    }
};
