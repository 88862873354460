import React from 'react';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_REPORTS} from "../../../../actions/stateManagement";
import {BaseComponent} from "../../../BaseComponent";

import {Report} from "../components/Report";
import EditReportText from "../Dialogs/EditReportText";
import {getResource, RES_PREFERENCES, RES_PREFERENCES_REPORTS} from "../../../../actions/preferences";
import * as Actions from "../../../../actions";
import _ from "lodash";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {HM_PREFERENCES_REPORT} from "../Constants";
import {ICON_EDIT} from "../../../../icons";
import {InputText} from "primereact/components/inputtext/InputText";
import {ReportTextEmailType} from "../../../../Constants";
import EditReportQuill from "../Dialogs/EditReportQuill";
import {QuillReport} from "../components/QuillReport";

class ConnectedReportsSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_REPORTS.id,

                reportTextsLoaded: false,
                reportTexts: [],
                selectedReport: {content: ''},

                first: 0,
                rows: 5,

                globalFilter: '',

                emailType: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getReportTexts();
        }
    }

    componentDidUpdate(prevProps, prevState, snapShot) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_PREFERENCES_REPORTS:

                    const sorted = _.sortBy(this.props.reportTexts, ['name'], ['asc']);
                    this.setState({reportTextsLoaded: true, reportTexts: sorted}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;

                default:
                    break;
            }
        }
    }

    onSelectionChange = (e) => {

        const emailType = _.findIndex(ReportTextEmailType, type => e.value.type.ordinal === type.ordinal) > -1;

        this.setState({selectedReport: e.value, emailType});
    }

    updateReport = (updatedReportText) => {

        const reportTexts = [...this.state.reportTexts];
        const index = _.findIndex(reportTexts, reportText => reportText.id === updatedReportText.id);
        reportTexts[index] = updatedReportText;

        this.setState({reportTexts, [HM_PREFERENCES_REPORT.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES.SAVE.action,
                    saveState: true,
                    saveObjects: true
                }
            });
        });
    }

    showReport = () => {

        if (this.state.emailType) {
            return (
                <QuillReport report={this.state.selectedReport}
                        viewing={true}
                />
            )
        } else {
            return (
                <Report report={this.state.selectedReport}
                        viewing={true}
                />
            )
        }
    }

    onShowDialogs = () => {

        if (this.state[HM_PREFERENCES_REPORT.id]) {

            if (this.state.emailType) {
                return (
                    <EditReportQuill
                        onHideDialog={this.onHideMenuEntry}
                        onOkDialog={this.updateReport}
                        reportText={this.state.selectedReport}
                    />
                )
            } else {
                return (
                    <EditReportText
                        onHideDialog={this.onHideMenuEntry}
                        onOkDialog={this.updateReport}
                        reportText={this.state.selectedReport}
                    />
                )
            }
        }
    }

    render() {

        if (!this.state.reportTextsLoaded) {
            return null;
        }

        const items = [
            {
                label: HM_PREFERENCES_REPORT.label,
                icon: ICON_EDIT,
                command: this.onShowMenuEntry,
                target: HM_PREFERENCES_REPORT.id
            },
        ];

        let cm = null;

        let reportTexts = _.filter(this.state.reportTexts, reportText => reportText.name.toLowerCase().includes(this.state.globalFilter.toLowerCase()));

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{SM_PREFERENCES_REPORTS.label}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => this.setState({globalFilter: e.target.value, first: 0})}
                           placeholder="Report Search"
                           size="50"
                           style={{'float': 'right'}}
                           autoFocus
                />
            </div>
        </div>;

        return (
            <div className="p-grid" style={{fontSize: 'small'}}>

                {this.onShowDialogs()}

                <div className="p-lg-6 p-md-12 ">
                    <Panel headerTemplate={header}>

                        <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

                        <DataTable value={reportTexts}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selectedReport}
                                   onSelectionChange={this.onSelectionChange}
                                   onContextMenuSelectionChange={this.onSelectionChange}
                                   onContextMenu={e => cm.show(e.originalEvent)}
                        >
                            <Column header='Name'
                                    field='name'
                                    style={{width: '100%'}}/>
                        </DataTable>
                    </Panel>
                </div>
                <div className="p-lg-6 p-md-12">
                    {this.showReport()}
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        reportTextsLoaded: state.preferences.reportTextsLoaded,
        reportTexts: state.preferences.reportTexts,

        currentState: state.stateManagement[SM_PREFERENCES_REPORTS.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getReportTexts: () => dispatch(getResource(RES_PREFERENCES_REPORTS.GET, {})),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const ReportsSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedReportsSection);

export default ReportsSection;
