import {React, Component} from "react";
import {InlineWidget} from "react-calendly";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Panel} from "primereact/components/panel/Panel";
import {Button} from "primereact/components/button/Button";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {HM_BOOK_TRAINING} from "../../../../Constants";

export default class BookTraining extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    render() {

        const footer = (
            <div>
                <Button label="Close"
                        icon={ICON_OK}
                        onClick={this.props.onHideDialog}
            />
            </div>
        );

        return (
            <Dialog style={{width: "50%"}}
                    header={HM_BOOK_TRAINING.header}
                    footer={footer}
                    visible={true}
                    resizable={true}
                    onHide={this.props.onHideDialog}>
                    <InlineWidget url="https://calendly.com/dominic-102/chiral-cloud-online-training"
                                  styles={{
                                      height: '680px'
                                  }}
                    />
            </Dialog>
        )
    }
}