import React from 'react';

import _ from 'lodash';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {InputText} from 'primereact/components/inputtext/InputText';
import {deleteHskItem, getResource, RES_HOUSEKEEPING_TCODES} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_TCODES} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import AddTreatmentCode from "../dialogs/AddTreatmentCode";
import {getResource as getChartResource, RES_chartResources} from "../../../../actions/ChartResources";
import {CI_STATUS_USABLE, HM_AddTCode, HM_DeleteTCode, HM_EditTCode, TC_USABLE} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";

import {checkBox} from "../../../PatientDynamicItems/OnChangeUtils";

export class ConnectedTreatmentCodesSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {

                stateManagementId: SM_HOUSEKEEPING_TCODES.id,

                treatmentCodes: [],
                fakeIndex: -1000,
                first: 0,
                rows: 5,

                selectedTreatmentCode: {},
                [HM_AddTCode.id]: false,
                [HM_EditTCode.id]: false,
                [HM_DeleteTCode.id]: false,

                checkboxes: {
                    includeArchived: false,
                },
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getHousekeepingTCodes();
            this.props.getChartResources(false);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type || prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_TCODES.GET.receive:
                case RES_HOUSEKEEPING_TCODES.SAVE.action:
                case RES_HOUSEKEEPING_TCODES.DELETE.action:
                    this.setState({treatmentCodes: this.props.treatmentCodes.items}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onSelection = (e) => {
        this.setState({selectedTreatmentCode: e.value});
    }

    chartingItemTemplate = (rowData) => {

        const treatmentCode = rowData;

        if (treatmentCode.chartingItem === undefined || treatmentCode.chartingItem === null)
            return '';

        const index = _.findIndex(this.props.resources.chartingItems, (chartingItem) => {
            return chartingItem.id === treatmentCode.chartingItem.id
        });
        return index < 0 ? '' : this.props.resources.chartingItems[index].shortDescription;
    }

    materialTemplate = (rowData) => {

        const treatmentCode = rowData;

        if (treatmentCode.defaultMaterial === null) {
            return '';
        }

        if (treatmentCode.defaultMaterial === undefined) {
            return '';
        }

        const index = _.findIndex(this.props.resources.materials, (material) => {
            return material.id === treatmentCode.defaultMaterial.id
        });
        return index < 0 ? '' : this.props.resources.materials[index].name;
    }

    recallTriggerTemplate(rowData) {

        const treatmentCode = rowData;

        return treatmentCode.triggerForRecall ? 'Yes' : 'No';
    }

    discountTemplate(rowData) {

        const treatmentCode = rowData;

        return treatmentCode.discountCharge ? 'Yes' : 'No';
    }

    costTemplate(rowData) {

        const treatmentCode = rowData;

        if (treatmentCode.discountCharge) {
            return `%${treatmentCode.cost}`
        } else {
            const value = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP'
            }).format(treatmentCode.cost);
            return value;
        }
    }

    statusTemplate(rowData) {

        const treatmentCode = rowData;

        return treatmentCode.status === TC_USABLE ? 'Usable' : 'Archived';
    }

    code9000Template = ({code9000}) => {

        if (code9000 !== undefined) {
            code9000 = _.find(this.props.resources.nhsCodes, (item) => code9000 === item.value);
        }
        return code9000 === undefined ? '' : code9000.label;
    }

    addTreatment = (newTreatmentCode, editing) => {

        this.onHideMenuEntry(HM_AddTCode.id);
        newTreatmentCode.id = this.state.fakeIndex;
        newTreatmentCode.newItem = true;
        this.updateTreatment(newTreatmentCode, editing);
    }

    updateTreatment = (editedTreatmentCode, editing) => {

        editedTreatmentCode.edited = true;

        const editedTreatmentCodes = [...this.state.treatmentCodes];

        const index = _.findIndex(editedTreatmentCodes, (treatmentCode) => {
            return treatmentCode.id === editedTreatmentCode.id;
        });

        if (Boolean(editedTreatmentCode.newItem)) {
            editedTreatmentCode.newItem = false;
            editedTreatmentCodes.unshift(editedTreatmentCode);
        } else {
            editedTreatmentCodes[index] = editedTreatmentCode;
        }

        const dialogToHide = editing ? HM_EditTCode.id : HM_AddTCode.id;

        this.setState({treatmentCodes: editedTreatmentCodes, [dialogToHide]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_TCODES.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    showDialogs = () => {

        if (this.state[HM_AddTCode.id]) {
            return (
                <AddTreatmentCode
                    editing={false}
                    header={HM_AddTCode.header}
                    onHideDialog={this.onHideMenuEntry}
                    resources={this.props.resources}
                    onOkDialog={this.addTreatment}
                />
            )
        } else if (this.state[HM_EditTCode.id]) {
            return (
                <AddTreatmentCode
                    editing={true}
                    header={HM_EditTCode.header}
                    onHideDialog={this.onHideMenuEntry}
                    resources={this.props.resources}
                    onOkDialog={this.updateTreatment}
                    treatmentCode={this.state.selectedTreatmentCode}
                />
            )
        } else if (this.state[HM_DeleteTCode.id]) {
            const footer = <div>
                <Button label="Yes" icon="fa fa-check" onClick={() => {
                    this.onDeleteTC();
                }}/>
                <Button label="No" icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteTCode.id)
                }}/>
            </div>;

            return (

                <Dialog header={HM_DeleteTCode.header}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteTCode.id]: false})
                        }}>
                    {HM_DeleteTCode.message}
                </Dialog>
            )
        }
    }

    onDeleteTC = () => {

        this.props.deleteTreatmentCode(this.state.selectedTreatmentCode.id);

        this.setState({[HM_DeleteTCode.id]: false});
    }

    render() {

        if (!this.props.resourcesLoaded || !this.props.treatmentCodesLoaded) {
            return null;
        }

        const props = {
            onChange: this.onChange,
            target: 'checkboxes',
            checkboxes: this.state.checkboxes,
        };

        const items = [
            {
                label: HM_EditTCode.header,
                icon: HM_EditTCode.icon,
                command: this.onShowMenuEntry,
                target: HM_EditTCode.id
            },
            {
                label: HM_DeleteTCode.header,
                icon: HM_DeleteTCode.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteTCode.id
            },
        ];

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{ marginRight: 15 }}>Treatment Codes</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={HM_AddTCode.header}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_AddTCode.id}})
                        }}
                />
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => this.setState({globalFilter: e.target.value})}
                           placeholder="Code Search"
                           autoFocus
                />
                {checkBox(props, 'includeArchived', 'Include Archived', false, false)}
            </div>
        </div>;

        let treatmentCodes = _.filter(this.state.treatmentCodes, code => code.status === CI_STATUS_USABLE || this.state.checkboxes.includeArchived);
        treatmentCodes = _.sortBy(treatmentCodes, ['description'], ['asc']);

        return (
            <div>
                <Panel headerTemplate={header}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.showDialogs()}

                    <DataTable value={treatmentCodes}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               resizableColumns={true}
                               columnResizeMode="fit"
                               editable={true}
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selectionMode="single"
                               selection={this.state.selectedTreatmentCode}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedTreatmentCode}
                               onContextMenuSelectionChange={e => this.setState({selectedTreatmentCode: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                               globalFilter={this.state.globalFilter}
                    >
                        <Column field="color"
                                header=""
                                body={this.colourTemplateSplit}
                                style={{width: '5%'}}/>
                        <Column field="description"
                                header="Description"
                                sortable={true}
                                style={{width: '50%'}}/>
                        <Column field="code"
                                header="Code"
                                sortable={true}
                                style={{width: '15%'}}/>
                        <Column field="chartingItem"
                                header="Chart Item"
                                body={this.chartingItemTemplate}
                                style={{width: '10%'}}/>
                        <Column field="defaultMaterial"
                                header="Material"
                                body={this.materialTemplate}
                                style={{width: '15%'}}/>
                        <Column field="triggerForRecall"
                                header="Recall Trigger"
                                body={this.recallTriggerTemplate}
                                style={{textAlign: 'right', width: '15%'}}/>
                        <Column field="discountCharge"
                                header="Discount"
                                body={this.discountTemplate}
                                style={{textAlign: 'right', width: '15%'}}/>
                        <Column field="code9000"
                                header="NHS Code"
                                body={this.code9000Template}
                                style={{width: '15%'}}/>
                        <Column field="cost"
                                header="Unit Cost/% Discount"
                                body={this.costTemplate}
                                style={{textAlign: 'right', width: '15%'}}/>
                        <Column field="status"
                                header="Status"
                                body={this.statusTemplate}
                                sortable={true}
                                style={{textAlign: 'right', width: '15%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        resourcesLoaded,
        resources,
        treatmentCodesLoaded,
        treatmentCodes,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        newTreatmentCode: state.housekeeping.newTreatmentCode,

        currentState: state.stateManagement[SM_HOUSEKEEPING_TCODES.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getChartResources: (loaded) => dispatch(getChartResource(RES_chartResources.GET)),
        getHousekeepingTCodes: () => dispatch(getResource(RES_HOUSEKEEPING_TCODES.GET, {})),
        deleteTreatmentCode: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_TCODES.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const TreatmentCodesSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedTreatmentCodesSection);

export default TreatmentCodesSection;
