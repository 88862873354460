import React from 'react';
import _ from 'lodash'
import {CHART_TYPE_DFPROG, CHART_TYPE_MIXED, CHART_TYPE_PERMANENT, CHART_TYPE_PRIMARY} from "./OrthoIntraOral2";

const onClick = (props, emptyTooth, tooth, chartType, showingMixed) => {

    let teeth = null;
    let emptyTeeth = null;
    let offset = 16;
    let owner = props.owner;

    switch (chartType) {
        case CHART_TYPE_PRIMARY:
            if (showingMixed) {
                teeth = [...props.primaryTeeth];
                emptyTeeth = [...props.emptyPrimaryTeeth];
                owner = 'selectedAssessment.teethMixedMissing';
            } else {
                teeth = [...props.teeth];
                emptyTeeth = [...props.emptyTeeth];
            }
            offset = 10;
            break;
        case CHART_TYPE_PERMANENT:
            if (showingMixed) {
                teeth = [...props.permanentTeeth];
                emptyTeeth = [...props.emptyPermanentTeeth];
                owner = 'selectedAssessment.teethMissing';
            } else {
                teeth = [...props.teeth];
                emptyTeeth = [...props.emptyTeeth];
            }
            break;
        case CHART_TYPE_DFPROG:
            if (showingMixed) {
                teeth = [...props.doubtfulPrognosis];
                emptyTeeth = [...props.emptyPermanentTeeth];
                owner = 'selectedAssessment.doubtfulPrognosis';
            } else {
                teeth = [...props.teeth];
                emptyTeeth = [...props.emptyTeeth];
            }
            break;
        default:
            break;
    }

    const upper = emptyTooth.upper;
    const index = _.findIndex(emptyTeeth[upper ? 0 : 1], target => target.id === emptyTooth.id);
    const selectedTooth = Math.abs(index - offset) - 1;

    if (upper)
        teeth[0][selectedTooth] = !teeth[0][selectedTooth];
    else
        teeth[1][selectedTooth] = !teeth[1][selectedTooth];

    props.onChange({owner, value: teeth});
};

const showMissing = (props, emptyTooth, tooth, x, y) => {

    const pres = tooth.pres !== undefined ? tooth.pres : tooth;

    if (pres) {
        return (
            <g key={x} fill="none" stroke="#000" onClick={event => onClick(props, emptyTooth, tooth)}>
                <path d={`M ${x} ${y} L ${x + 20} ${y + 20}`}/>
                <path d={`M ${x} ${y + 20} L ${x + 20} ${y}`}/>
            </g>
        )
    } else
        return null;
};

const showTeeth = (props, chartType, emptyTooth, tooth, index, x, y, xText, yText, showingMixed) => {

    let offset = chartType === CHART_TYPE_PRIMARY ? 3 * 23 : 0;

    try {
        return (
            <g key={index}>
                <text
                    fontSize={16}
                    fontWeight={400}
                    letterSpacing={0}
                    wordSpacing={0}
                    fill="#4d4d4d"
                    fontFamily="Arial"
                    transform="translate(4.983 -990.615)"
                >
                    <tspan
                        x={xText + (index * 23) + offset}
                        y={yText}
                        style={{
                            InkscapeFontSpecification: 'Arial',
                        }}
                    >
                        {emptyTooth.text}
                    </tspan>
                </text>
                <path fill="#fff" stroke="#000" d={`M${x + (index * 23) + offset} ${y}h20v20h-20z`}
                      onClick={event => onClick(props, emptyTooth, tooth, chartType, showingMixed)}/>
                {showMissing(props, emptyTooth, tooth, (x + (index * 23) + offset), y)}
            </g>
        )
    } catch (error) {
        console.log(error);
    }
};

export const TeethTickBox = (props) => {

    const x = 3.90;
    const yUpper = 19.673;
    const yLower = 44.82;

    const xText = 4.174;
    const yTextUpper = 1007.906;
    const yTextLower = 1072.32;

    switch (props.chartType) {
        case CHART_TYPE_PRIMARY:

            return (
                <svg width={370} height={84.506}>

                    {
                        props.teeth[0].slice(0).reverse().map((tooth, index) => {
                            return showTeeth(props, props.chartType, props.emptyTeeth[0][index], tooth, index, x, yUpper, xText, yTextUpper, false);
                        })
                    }
                    {
                        props.teeth[1].slice(0).reverse().map((tooth, index) => {
                            return showTeeth(props, props.chartType, props.emptyTeeth[1][index], tooth, index, x, yLower, xText, yTextLower, false);
                        })
                    }
                </svg>);
        case CHART_TYPE_PERMANENT:
        case CHART_TYPE_DFPROG:
            return (
                <svg width={370} height={84.506}>

                    {
                        props.teeth[0].slice(0).reverse().map((tooth, index) => {
                            return showTeeth(props, props.chartType, props.emptyTeeth[0][index], tooth, index, x, yUpper, xText, yTextUpper, false);
                        })
                    }
                    {
                        props.teeth[1].slice(0).reverse().map((tooth, index) => {
                            return showTeeth(props, props.chartType, props.emptyTeeth[1][index], tooth, index, x, yLower, xText, yTextLower, false);
                        })
                    }
                </svg>);
        case CHART_TYPE_MIXED:

            const priYUpper = 19.673;
            const priYLower = 44.82;

            const priTextUpper = 1007.906;
            const priTextLower = 1072.32;

            const spacing = 42;

            return (
                <svg width={370} height={84.506 * 2}>

                    {
                        props.primaryTeeth[0].slice(0).reverse().map((tooth, index) => {
                            return showTeeth(props, CHART_TYPE_PRIMARY, props.emptyPrimaryTeeth[0][index], tooth, index, x, priYUpper, xText, priTextUpper, true);
                        })
                    }

                    {
                        props.permanentTeeth[0].slice(0).reverse().map((tooth, index) => {
                            return showTeeth(props, CHART_TYPE_PERMANENT, props.emptyPermanentTeeth[0][index], tooth, index, x, yUpper + spacing, xText, yTextUpper + spacing, true);
                        })
                    }

                    {
                        props.permanentTeeth[1].slice(0).reverse().map((tooth, index) => {
                            return showTeeth(props, CHART_TYPE_PERMANENT, props.emptyPermanentTeeth[1][index], tooth, index, x, priYLower + spacing, xText, priTextLower + spacing, true);
                        })
                    }
                    {
                        props.primaryTeeth[1].slice(0).reverse().map((tooth, index) => {
                            return showTeeth(props, CHART_TYPE_PRIMARY, props.emptyPrimaryTeeth[1][index], tooth, index, x, yLower + (spacing * 2), xText, yTextLower + (spacing * 2), true);
                        })
                    }
                </svg>);
        default:
            break;
    }
}