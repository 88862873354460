import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Card} from 'primereact/components/card/Card';
import {PickList} from 'primereact/components/picklist/PickList';
import {Button} from 'primereact/components/button/Button';

import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {HM_AddCC, HM_EditCC} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";

export default class AddCostCenter extends Component {

    constructor(props) {
        super(props);

        if (props.editing) {
            this.state = {
                costCenter: props.item,

                sourceTCs: [],
                targetTCs: [],
            }
        } else {
            this.state = {
                costCenter: {
                    id: null,
                    mc: ac.getMcId(),
                    description: "",
                    code: "",
                    items: [],
                },
                sourceTCs: [],
                targetTCs: [],
            }
        }
        this.calculatePickList = this.calculatePickList.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onTCSelection = this.onTCSelection.bind(this);
    }

    componentDidMount() {

        this.calculatePickList();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    calculatePickList() {

        let codes = [];

        this.state.costCenter.items.forEach((targetCode) => {
            const index = _.findIndex(this.props.treatmentCodes, (sourceCode) => targetCode.id === sourceCode.id);
            codes.push(this.props.treatmentCodes[index]);
        });

        let sourceTCs = this.props.treatmentCodes.filter((code) => !codes.includes(code));
        let targetTCs = this.state.costCenter.items;

        this.setState({sourceTCs: sourceTCs, targetTCs: targetTCs, pickListLoaded: false});
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    ccTemplate(costCenters) {
        return (
            <div className="p-helper-clearfix">
                <div style={{fontSize: 'small'}}>{costCenters.description}</div>
            </div>
        )
    }

    onTCSelection(event) {

        const costCenter = {...this.state.costCenter};
        costCenter.items = event.target;

        this.setState({costCenter, sourceTCs: _.sortBy(event.source, ['description'], ['asc']), targetTCs: _.sortBy(event.target, ['description'], ['asc'])});
    }

    render() {

        const ccDefined = this.state.costCenter.description !== '' ? '' : 'disabled';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.costCenter, this.props.editing)
                        }}
                        disabled={ccDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            const target = this.props.editing ? HM_EditCC.id : HM_AddCC.id;
                            this.props.onHideDialog(target)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'costCenter',
            costCenter: this.state.costCenter,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={this.props.visible}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog('showAddCC')
                    }}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-5">
                            <label>Description</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'description', '', false, true)}
                        </div>
                        <div className="p-col-5">
                            <label>Code</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'code', '', false, true)}
                        </div>

                        <Card title='Cost Center Members' style={{width: '100%'}}>
                            <PickList className='p-lg-12 p-md-12'
                                      sourceHeader="Possible"
                                      targetHeader="Actual"
                                      itemTemplate={this.ccTemplate}
                                      showSourceControls={false}
                                      showTargetControls={false}
                                      source={this.state.sourceTCs}
                                      target={this.state.targetTCs}
                                      style={{paddingLeft: 0}}
                                      onChange={this.onTCSelection}
                            />
                        </Card>
                    </div>
                </div>
            </Dialog>
        )
    }
};
