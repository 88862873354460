import React from 'react';

import {HM_NHS_SUPPORTING_DETAILS} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";

import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Panel} from "primereact/panel";
import {ScrollPanel} from "primereact/scrollpanel";
import {
    NHS_SUPPD_18_IN_FULL,
    NHS_SUPPD_BABY_12,
    NHS_SUPPD_BABY_BORN_DUE,
    NHS_SUPPD_CARD_CERT_NO,
    NHS_SUPPD_CERT_NO,
    NHS_SUPPD_CERTIFICATE,
    NHS_SUPPD_COLL_UNI,
    NHS_SUPPD_DOB,
    NHS_SUPPD_FIRST_DAY,
    NHS_SUPPD_HC2,
    NHS_SUPPD_HC3,
    NHS_SUPPD_IN_SUPP_ETC,
    NHS_SUPPD_INC_REL,
    NHS_SUPPD_JOB_SEEK,
    NHS_SUPPD_MAT_CERT,
    NHS_SUPPD_NAME_REC,
    NHS_SUPPD_NI_NO,
    NHS_SUPPD_PENT_CREDIT,
    NHS_SUPPD_PREGNANT,
    NHS_SUPPD_RECEIVING,
    NHS_SUPPD_REDUCED,
    NHS_SUPPD_TAX_CREDIT
} from "../../NHSManagaement/Constants";
import {calendarCompact, checkBoxFixed, inputCurrencyText, inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import {_01, _03, _05, _13, _15, _17, _25, _31, _33, _83} from "../../../PatientDynamicItems/NHSDetails";

export const NHSSupportingDetails = (props) => {

    const footer = (
        <div>
            <Button label="OK"
                    icon={ICON_OK}
                    onClick={() => {
                        props.onOkDialog()
                    }}/>
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(HM_NHS_SUPPORTING_DETAILS.id);
                    }}/>
        </div>
    );

    const supportProps = {
        onChange: props.onChange,
        target: 'NHSRegistration',
        NHSRegistration: props.NHSRegistration
    };

    const SUPPD_18_IN_FULL = props.NHSRegistration.exemptionsAndRemissions[_01.ordinal];
    const SUPPD_PREGNANT = props.NHSRegistration.exemptionsAndRemissions[_03.ordinal];
    const SUPPD_BABY_12 = props.NHSRegistration.exemptionsAndRemissions[_05.ordinal];
    const SUPPD_IN_SUPP_ETC = props.NHSRegistration.exemptionsAndRemissions[_17.ordinal];
    const SUPPD_JOB_SEEK = props.NHSRegistration.exemptionsAndRemissions[_25.ordinal];
    const SUPPD_INC_REL = props.NHSRegistration.exemptionsAndRemissions[_83.ordinal];
    const SUPPD_PENT_CREDIT = props.NHSRegistration.exemptionsAndRemissions[_33.ordinal];
    const SUPPD_HC2 = props.NHSRegistration.exemptionsAndRemissions[_13.ordinal];
    const SUPPD_TAX_CREDIT = props.NHSRegistration.exemptionsAndRemissions[_31.ordinal];
    const SUPPD_HC3 = props.NHSRegistration.exemptionsAndRemissions[_15.ordinal];

    return (

        <Dialog header={HM_NHS_SUPPORTING_DETAILS.header}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(HM_NHS_SUPPORTING_DETAILS.id)
                }}
        >
            <ScrollPanel style={{width: '100%', height: '600px'}} className='custom'>
                <Panel header={NHS_SUPPD_FIRST_DAY}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-6">
                            {checkBoxFixed(SUPPD_18_IN_FULL, '_0', NHS_SUPPD_18_IN_FULL)}
                        </div>
                        <div className="p-col-6">
                            {inputText(supportProps, 'collegeOrUniversity', NHS_SUPPD_COLL_UNI, !SUPPD_18_IN_FULL, false)}
                        </div>

                        <div className="p-col-6">
                            {checkBoxFixed(SUPPD_PREGNANT, '_1', NHS_SUPPD_PREGNANT)}
                        </div>
                        <div className="p-col-6">
                            {inputText(supportProps, 'marternityExemptionNo', NHS_SUPPD_MAT_CERT, !SUPPD_PREGNANT, false)}
                        </div>

                        <div className="p-col-8">
                            {checkBoxFixed(SUPPD_BABY_12, '_2', NHS_SUPPD_BABY_12)}
                        </div>
                        <div className="p-col-2">
                            <label>{NHS_SUPPD_BABY_BORN_DUE}</label>
                        </div>
                        <div className="p-col-2">
                            {SUPPD_BABY_12 ? calendarCompact(supportProps, 'babyDueOrBorn') : <label>-</label>}
                        </div>
                    </div>
                </Panel>
                <Panel header={NHS_SUPPD_RECEIVING} style={{paddingTop: '5px'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-8">
                            {checkBoxFixed(SUPPD_IN_SUPP_ETC, '_3', NHS_SUPPD_IN_SUPP_ETC)}
                        </div>
                        <div className="p-col-4">
                            {inputText(supportProps, 'nameOfPersonReceivingBenefit', NHS_SUPPD_NAME_REC, !SUPPD_IN_SUPP_ETC, false)}
                        </div>

                        <div className="p-col-8">
                            {checkBoxFixed(SUPPD_JOB_SEEK, '_4', NHS_SUPPD_JOB_SEEK)}
                        </div>
                        <div className="p-col-2">
                            <label>{NHS_SUPPD_DOB}</label>
                        </div>
                        <div className="p-col-2">
                            {SUPPD_JOB_SEEK ? calendarCompact(supportProps, 'dateOfBirth') : <label>-</label>}
                        </div>

                        <div className="p-col-9">
                            {checkBoxFixed(SUPPD_INC_REL, '_5', NHS_SUPPD_INC_REL)}
                        </div>
                        <div className="p-col-3">
                            {inputText(supportProps, 'NINo', NHS_SUPPD_NI_NO, !SUPPD_INC_REL, false)}
                        </div>

                        <div className="p-col-12">
                            {checkBoxFixed(SUPPD_PENT_CREDIT, '_6', NHS_SUPPD_PENT_CREDIT)}
                        </div>
                    </div>
                </Panel>
                <Panel header={NHS_SUPPD_CERTIFICATE} style={{paddingTop: '5px'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-6">
                            {checkBoxFixed(SUPPD_HC2, '_7', NHS_SUPPD_HC2)}
                        </div>
                        <div className="p-col-6">
                            {inputText(supportProps, 'hc2CertificateNo', NHS_SUPPD_CERT_NO, !SUPPD_HC2, false)}
                        </div>

                        <div className="p-col-6">
                            {checkBoxFixed(SUPPD_TAX_CREDIT, '_8', NHS_SUPPD_TAX_CREDIT)}
                        </div>
                        <div className="p-col-6">
                            {inputText(supportProps, 'taxCreditNo', NHS_SUPPD_CARD_CERT_NO, !SUPPD_TAX_CREDIT, false)}
                        </div>
                    </div>
                </Panel>
                <Panel header={NHS_SUPPD_REDUCED} style={{paddingTop: '5px'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-11">
                            {checkBoxFixed(SUPPD_HC3, '_9', NHS_SUPPD_HC3)}
                        </div>
                        <div className="p-col-1">
                            {inputCurrencyText(supportProps, 'hc3PaymentLimit', '£', !SUPPD_HC3, true)}
                        </div>
                        <div className="p-col-8"/>
                        <div className="p-col-4">
                            {inputText(supportProps, 'hc3CertificateNo', NHS_SUPPD_CERT_NO, !SUPPD_HC3, false)}
                        </div>
                    </div>
                </Panel>
            </ScrollPanel>
        </Dialog>
    )
};