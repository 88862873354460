import React from 'react';
import {BaseComponent} from "../../../BaseComponent";
import {
    PAY_TYPE_CC,
    PAY_TYPE_CHEQUE,
    PAY_TYPE_DC,
    PD_STATE_PAYMENT_OPTIONS,
    PD_STATE_RECEIPT_AMOUNT,
    PD_STATE_RECEIPT_CD_CARD,
    PD_STATE_RECEIPT_CHEQUE,
    PD_STATE_RECEIPT_NARRATIVE
} from "../../PatientDetails/Constants";
import _ from "lodash";
import {PaymentOptions} from "./Pages/PaymentOptions";
import {ReceiptAmount} from "./Pages/ReceiptAmount";
import {ReceiptCheque} from "./Pages/ReceiptCheque";
import {ac} from "../../../../index";
import {receiptData} from "../../DefaultData";
import {ReceiptCDCard} from "./Pages/ReceiptCDCard";
import {ReceiptNarrative} from "./Pages/ReceiptNarrative";
import {REC_ISSUED} from "../../../../Constants";

export default class PatientSales extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            dialogState: PD_STATE_PAYMENT_OPTIONS,
            minPayable: 0.0,
            receipt: _.cloneDeep(receiptData(ac.getMcId())),
            previousState: PD_STATE_RECEIPT_AMOUNT,
            printControl: {printOnComplete: false},
        };

        this.state.receipt.total = props.salesTotal;
        this.state.receipt.amountReceived = props.balance;
        this.state.receipt.receiptee = {id: props.patient.id};
        this.state.receipt.comment = props.defaultPaymentNote.text;
        this.state.receipt.status = REC_ISSUED;
        this.state.receipt.raisedBy = {id: props.loginIdentity.id};
        this.state.receipt.raisedOn = new Date();
        this.state.receipt.appointment = {appointmentId: props.appointmentId};
        this.state.receipt.accountGroup = _.find(props.accountGroups, group => group.id === props.accountGroup.id);

        if (!this.state.receipt.accountGroup) {
            this.state.receipt.accountGroup = _.find(props.accountGroups, group => props.accountGroup.defaultAG);
        }
    }

    checkForCompletness = () => {

        let finishEnabled = false;
        // based on the selected payment type we will determine the wizard completeness
        switch (this.state.receipt.paymentType) {
            case PAY_TYPE_CHEQUE.value:
                finishEnabled = this.state.receipt.raisedBy.id !==null && this.state.receipt.sortCode.length===8;
                break;
            case PAY_TYPE_CC.value:
            case PAY_TYPE_DC.value:
                finishEnabled = this.state.receipt.raisedBy.id !==null;
                break;
            default:
                finishEnabled = this.state.receipt.raisedBy.id !==null;
                break;
        }
        return finishEnabled;
    }

    onChange = (event) => {

        const state = {...this.state};

        if (event.constructor === Array) {

            event.forEach(item => {
                _.set(state, item.owner, item.value);
            })
        } else {

            switch (event.owner) {

                case 'receipt.raisedBy' :
                    _.set(state, event.owner, {id: event.value.id});
                    break;
                default:
                    _.set(state, event.owner, event.value);
                    break;
            }
        }
        this.setState({...state});
    }

    render() {

        const finishEnabled = this.checkForCompletness();

        switch (this.state.dialogState) {

            case PD_STATE_PAYMENT_OPTIONS :
                return (
                    <PaymentOptions onChange={this.onChange}
                                    onFinish={this.props.onFinishInvoice}
                                    onHideDialog={this.props.onHideDialog}
                                    salesTotal={this.props.salesTotal}
                                    balance={this.props.balance}
                                    ownerId={this.props.ownerId}
                                    accountGroup={this.props.accountGroup}
                    />
                );

            case PD_STATE_RECEIPT_AMOUNT:

                return (
                    <ReceiptAmount receipt={this.state.receipt}
                                   onChange={this.onChange}
                                   onFinish={this.props.onFinishReceipt}
                                   onHideDialog={this.props.onHideDialog}
                                   salesTotal={this.props.salesTotal}
                                   providers={this.props.providers}
                                   accountGroups={this.props.accountGroups}
                                   finishEnabled={finishEnabled}
                                   ownerId={this.props.ownerId}
                    />
                );

            case PD_STATE_RECEIPT_CHEQUE:

                return (
                    <ReceiptCheque receipt={this.state.receipt}
                                   onChange={this.onChange}
                                   onFinish={this.props.onFinishReceipt}
                                   onHideDialog={this.props.onHideDialog}
                                   salesTotal={this.props.salesTotal}
                                   finishEnabled={finishEnabled}
                                   ownerId={this.props.ownerId}
                    />
                );

            case PD_STATE_RECEIPT_CD_CARD:

                return (
                    <ReceiptCDCard receipt={this.state.receipt}
                                   onChange={this.onChange}
                                   onFinish={this.props.onFinishReceipt}
                                   onHideDialog={this.props.onHideDialog}
                                   salesTotal={this.props.salesTotal}
                                   finishEnabled={finishEnabled}
                                   ownerId={this.props.ownerId}
                    />
                );

            case PD_STATE_RECEIPT_NARRATIVE:

                return (
                    <ReceiptNarrative receipt={this.state.receipt}
                                      onChange={this.onChange}
                                      onFinish={this.props.onFinishReceipt}
                                      onHideDialog={this.props.onHideDialog}
                                      previousState={this.state.previousState}
                                      finishEnabled={finishEnabled}
                                      ownerId={this.props.ownerId}
                    />
                );

            default:
                break;
        }
    }
}
