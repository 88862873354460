import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import _ from "lodash";
import {inputText, inputTextArea} from "../../PatientDynamicItems/OnChangeUtils";
import {HM_AddBillingGroup, HM_EditBillingGroup} from "../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../icons";

export default class AddBillingGroup extends Component {

    constructor(props) {
        super(props);

        // add the fullname for the textInput component
        const {firstName, lastName, title} = props.principle;

        this.newBillingGroup = {
            id: null,
            description: '',
            comments: '',
            principlePayeeId: props.principle.id,
            principleName: `${title.abbreviation} ${firstName} ${lastName}`,
            groupees: [],
            accountBalanceTotal: 0.0,
            pendingBalanceTotal: 0.0,
        };

        this.state = {
            billingGroup: props.editing ? {...props.item} : this.newBillingGroup,
        };

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onHide() {
        const target = this.props.editing ? HM_EditBillingGroup.id : HM_AddBillingGroup.id;
        this.props.onHideDialog(target);
    }

    render() {

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.billingGroup)
                        }}
                />
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            editing: this.props.editing,
            onChange: this.onChange,
            target: 'billingGroup',
            billingGroup: this.state.billingGroup,
        };

        return (
            <Dialog header={this.props.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">

                        <div className="p-col-5">
                            <label>Principle</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'principleName', '', true, false)}
                        </div>

                        <div className="p-col-5">
                            <label>Description</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'description', '')}
                        </div>

                        <div className="p-col-5">
                            <label>Comments</label>
                        </div>
                        <div className="p-col-7">
                            {inputTextArea(props, 'comments', 5, -1, false, false)}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
