import React from 'react';
import {connect} from "react-redux";

import * as Actions from '../../../actions/index';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Toast} from 'primereact/components/toast/Toast';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {setState, SM_SUBSCRIPTION_SEARCH, stateRequest} from "../../../actions/stateManagement";
import {BaseComponent} from "../../BaseComponent";
import _ from "lodash";

import {passSubscription, RES_FIND_SUBSCRIPTIONS, RES_SUBSCRIPTION_DETAILS} from "../../../actions/admin";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../../FixedItems/Housekeeping/Constants";
import {TB_ADD_SUBSCRIPTION, TB_ADMIN_SUBSCRIPTION} from "../Constants";
import {showSubscriptionPage} from "../Utils";
import {
    getAllSubscriptions,
    getSubscriptionsByContact,
    getSubscriptionsByEmail,
    getSubscriptionsByName,
    getSubscriptionsByPostcode,
    getSubscriptionsByTelnumber
} from "../../../actions/findSubscriptions";
import {HM_notImplemented, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_PLUS} from "../../../icons";
import {dateTemplate} from "../../PatientDynamicItems/Utils";
import {ac} from "../../../index";

export class ConnectedFindSubscriptions extends BaseComponent {

    constructor(props) {
        super(props);

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                canSave: {status: false, activeIndex: 0, source: RES_FIND_SUBSCRIPTIONS.CLEAR},

                searchCriteria: Actions.SEARCH_NAME,
                searchString: '',
                subscription: null,

                dataTableSelectValue: null,

                results: [],

                first: 0,
                rows: 5,
            };
        }
        this.searchInput = React.createRef();

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
            [TB_SAVE_AND_EXIT.id]: this.onCloseClick,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_SUBSCRIPTION_DETAILS.PASS.receive:

                    const {id, name} = this.state.dataTableSelectValue;
                    showSubscriptionPage({
                        props: this.props,
                        edit: true,
                        id,
                        name,
                        icon: TB_ADMIN_SUBSCRIPTION.icon,
                        subscription: this.state.dataTableSelectValue
                    });
                    break;

                case Actions.RECEIVE_SUBSCRIPTION_SEARCH:

                    this.setState({results: this.props.results, first: 0});
                    this.save = true;
                    break;
                default:
                    break;
            }
        }
    }

    onCriteriaChange = (e, clear) => {

        const searchString = clear ? '' : this.state.searchString;

        switch (e.value) {
            case Actions.SEARCH_NAME.value:
                this.setState({searchCriteria: Actions.SEARCH_NAME, searchString, first: 0});
                break;
            case Actions.SEARCH_CONTACT.value:
                this.setState({searchCriteria: Actions.SEARCH_CONTACT, searchString, first: 0});
                break;
            case Actions.SEARCH_EMAIL.value:
                this.setState({searchCriteria: Actions.SEARCH_EMAIL, searchString, first: 0});
                break;
            case Actions.SEARCH_POSTCODE.value:
                this.setState({searchCriteria: Actions.SEARCH_POSTCODE, searchString, first: 0});
                break;
            case Actions.SEARCH_MOBILE.value:
                this.setState({searchCriteria: Actions.SEARCH_MOBILE, searchString, first: 0});
                break;
            case Actions.SEARCH_ALL.value:
                this.setState({searchCriteria: Actions.SEARCH_ALL, first: 0}, () => {
                    this.props.getAllSubscriptions();
                });
                break;
            default:
                break;
        }
        this.save = true;
    }

    onGeneralValueChange = (e) => {

        const searchString = e.target.value;

        if (this.state.searchCriteria.value === Actions.SEARCH_ALL)
            return;

        switch (this.state.searchCriteria.value) {
            case Actions.SEARCH_NAME.value:
                this.setState({searchString, first: 0}, () => {
                    const urlPattern = `?pattern=${searchString}`;
                    this.props.getSubscriptionsByName(urlPattern);
                });
                break;
            case Actions.SEARCH_CONTACT.value:
                this.setState({searchString, first: 0}, () => {
                    const urlPattern = `?pattern=${searchString}`;
                    this.props.getSubscriptionsByContact(urlPattern);
                });
                break;
            case Actions.SEARCH_EMAIL.value:
                this.setState({searchString, first: 0}, () => {
                    const urlPattern = `?pattern=${searchString}`;
                    this.props.getSubscriptionsByEmail(urlPattern);
                });
                break;
            case Actions.SEARCH_POSTCODE.value:
                this.setState({searchString, first: 0}, () => {
                    const urlPattern = `?pattern=${searchString}`;
                    this.props.getSubscriptionsByPostcode(urlPattern);
                });
                break;
            case Actions.SEARCH_MOBILE.value:
                this.setState({searchString, first: 0}, () => {
                    const urlPattern = `?pattern=${searchString}`;
                    this.props.getSubscriptionsByTelnumber(urlPattern);
                });
                break;
            default:
                break;
        }
        this.save = true;
    }

    optionalInputText() {

        switch (this.state.searchCriteria.value) {

            case Actions.SEARCH_NAME.value:
            case Actions.SEARCH_CONTACT.value:
            case Actions.SEARCH_EMAIL.value:
            case Actions.SEARCH_POSTCODE.value:
            case Actions.SEARCH_MOBILE.value:

                let searchString = this.state.searchString ? this.state.searchString : '';

                return (
                    <div key='2' className="p-col-3"
                    >
                        <InputText id='searchString' type="text" placeholder={this.state.searchCriteria.placeHolder}
                                   onChange={this.onGeneralValueChange.bind(this)}
                                   value={searchString}
                        />
                    </div>
                );
            default:
                break;
        }
    }

    onShowDetails = ({id, name}) => {
    }

    onAddSubscription = () => {

        // showAddPatientPage(this.props);
    }

    onCloseClick = () => {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: SM_SUBSCRIPTION_SEARCH.id});
    }

    onSelectionChanged = (e) => {

        this.setState({dataTableSelectValue: e.value}, () => {
            this.save = true;
        });
    }

    onDoubleClick = (e) => {

        this.setState({dataTableSelectValue: e.data}, () => {
            this.save = true;
        });
    }

    showDialogs() {
        return null;
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        if (this.state.searchCriteria.value === Actions.SEARCH_ALL.value)
            this.setState(state, () => {
                this.onCriteriaChange(this.state.searchCriteria, false)
            });
        else
            this.onGeneralValueChange({target: {value: this.state.searchString}})
    }

    buildMenu = () => {

        if (this.state.dataTableSelectValue === null) return [];

        return [
            {
                label: TB_ADMIN_SUBSCRIPTION.text, icon: TB_ADMIN_SUBSCRIPTION.icon, command: (e) => {
                    const {id} = this.state.dataTableSelectValue;
                    this.props.passSubscription({subscription: this.state.dataTableSelectValue, id});
                }
            },
        ];
    }

    urlTemplate = (row) => {
        const url = `${ac.getBASEURL()}${row.group.groupId}/${row.mc}/#`;
        return <label onClick={() => {
            window.open(url, '_blank');
        }}>{row.name}</label>
    }

    render() {

        const searchCriteria = [
            Actions.SEARCH_NAME,
            Actions.SEARCH_CONTACT,
            Actions.SEARCH_EMAIL,
            Actions.SEARCH_POSTCODE,
            Actions.SEARCH_MOBILE,
            Actions.SEARCH_ALL,
        ];

        const items = this.buildMenu();

        const header = <div className='panel-header-centered-content'><label id='panel-header'>Subscription Search</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={TB_ADD_SUBSCRIPTION.text}
                    icon={ICON_PLUS}
                    onClick={this.onAddSubscription}>
            </Button>
        </div>;

        const resultsHeader = `Matching Subscriptions (${this.state.results.length})`;



        return (
            <div id="detailPanel">

                <Toast ref={(el) => this.tabletGrowl = el}/>

                <Toolbar right={
                    <Button tooltipOptions={{position: 'top'}}
                            tooltip='Exit'
                            className="p-button-danger" icon="fa fa-times"
                            onClick={this.onCloseClick}/>
                }/>

                <Panel header={header}>

                    {this.showDialogs()}

                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <Dropdown id="provider" value={this.state.searchCriteria.value}
                                      options={searchCriteria}
                                      onChange={e => this.onCriteriaChange(e, true)}
                                      autoWidth={false}
                            />
                        </div>

                        {this.optionalInputText()}
                    </div>

                    <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                    <DataTable value={this.state.results}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               header={resultsHeader}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.dataTableSelectValue}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.dataTableSelectValue}
                               onContextMenuSelectionChange={this.onSelectionChanged}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                    >
                        <Column field='name' header="Name" style={{width: '20%'}}/>
                        <Column field="description" header="Description" style={{width: '20%'}}/>
                        <Column field="contact" header="Contact" style={{width: '20%'}}/>
                        <Column body={(row) => dateTemplate(row.activationDate)} header="Activated On"
                                style={{width: '20%'}}/>
                        <Column body={(row) => this.urlTemplate(row)} header="URL"
                                style={{width: '20%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT_SAVE :
            case TAB_EXIT :
                this.props.stateRequest(this.state.canSave.source);
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        results: state.admin.results,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        currentState: state.stateManagement[SM_SUBSCRIPTION_SEARCH.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllSubscriptions: () => dispatch(getAllSubscriptions()),
        getSubscriptionsByName: (urlPattern) => dispatch(getSubscriptionsByName(urlPattern)),
        getSubscriptionsByContact: (urlPattern) => dispatch(getSubscriptionsByContact(urlPattern)),
        getSubscriptionsByEmail: (urlPattern) => dispatch(getSubscriptionsByEmail(urlPattern)),
        getSubscriptionsByPostcode: (urlPattern) => dispatch(getSubscriptionsByPostcode(urlPattern)),
        getSubscriptionsByTelnumber: (urlPattern) => dispatch(getSubscriptionsByTelnumber(urlPattern)),
        passSubscription: (params) => dispatch(passSubscription(RES_SUBSCRIPTION_DETAILS.PASS, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const FindSubscriptions = connect(mapStateToProps, mapDispatchToProps)(ConnectedFindSubscriptions);

export default FindSubscriptions;
