import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import _ from "lodash";
import {
    NOTE_Action,
    NOTE_COMPLAINT,
    NOTE_Complaint,
    NOTE_FINANCIAL,
    NOTE_Info,
    NOTE_MEDICAL,
    NOTE_Medical,
    NOTE_PATIENT,
    NOTE_TREATMENT,
    NOTE_Treatment,
    NOTE_TreatmentCode,
    NOTE_Warning
} from "../../Constants";
import {ScrollPanel} from "primereact/scrollpanel";
import moment from "moment";
import {Editor} from "primereact/components/editor/Editor";
import {Button} from "primereact/components/button/Button";
import {ICON_COPY} from "../../icons";

let references = [];

const insertNotes = (sortedDisplayNotes, selection) => {

    const notes = [];

    const selectedIndex = selection ? _.findIndex(sortedDisplayNotes, note => note.id === selection.id) : null;

    sortedDisplayNotes.forEach((note, index) => {

        const editorStyle = {backgroundColor: selectedIndex === index ? '#C7DCE7' : null};

        let content = note.content ? note.content : '';

        if (!(content.startsWith('<p>') && content.endsWith('</p>'))) {
            content = `<p>${content}</p>`
        }

        const header = (
            <>
                <Button icon={ICON_COPY}
                        style={{
                            padding: '1.0em 1.0em',
                            marginRight: '10px',
                            width: '20px',
                            height: '30px',
                            background: '#1a91cf',
                            display: 'flex'
                        }}
                        onClick={() => navigator.clipboard.writeText(references[index].quill.getText())}
                />
                <span className="ql-formats" style={editorStyle}>
                    <button style={{width: "100%", ...editorStyle}} className='ql-header'
                            aria-label='subject'>{note.subject}</button>
                </span>
            </>
        );

        notes.push(
            <div key={`note_${index}`} className="p-col-12" style={{paddingTop: index === 0 ? '5px' : null}}>
                <Editor style={editorStyle}
                        value={content}
                        readOnly={true}
                        headerTemplate={header}
                        ref={(el) => references[index] = el}
                />
            </div>
        )
    });

    return notes;
}

export const NoteExpanded = (props) => {

    const displayNotes = _.filter(props.patientNotes, note => {

        switch (note.type) {
            case NOTE_TreatmentCode.type:
            case NOTE_Treatment.type:
            case NOTE_Medical.type:
                return _.findIndex(props.filters, filter => (filter.type === NOTE_MEDICAL.type)) !== -1;
            case NOTE_Info.type:
            case NOTE_Action.type:
            case NOTE_Warning.type:
                return _.findIndex(props.filters, filter => filter.type === NOTE_PATIENT.type) !== -1;
            case NOTE_Complaint.type:
                return _.findIndex(props.filters, filter => filter.type === NOTE_COMPLAINT.type) !== -1;
            default:
                return _.findIndex(props.filters, filter => filter.type === NOTE_FINANCIAL.type) !== -1;
        }
    });

    const sortedDisplayNotes = _.orderBy(displayNotes, [(note) => {
        return moment(note.createdOn).format('YYYYMMDD');
    }, 'id'], ['desc', 'desc']);

    // if (props.selection) {
    //     const selectedIndex = _.findIndex(sortedDisplayNotes, note => note.id === props.selection.id);
    //
    //     if (references[selectedIndex]) {
    //
    //         references.forEach(reference => {
    //             if (reference.quill) {
    //                 reference.quill.enable(true)
    //             }
    //         });
    //         if (references[selectedIndex].quill) {
    //             references[selectedIndex].quill.setSelection(0, 0);
    //         }
    //         references.forEach(reference => {
    //             if (reference.quill) {
    //                 reference.quill.enable(false);
    //             }
    //         });
    //     } else {
    //         console.log(`selectedIndex :: ${selectedIndex} => ${props.selection ? props.selection.id : 'no selection'}`);
    //
    //         references.forEach(reference => {
    //             if (reference.quill) {
    //                 reference.quill.enable(true)
    //             }
    //         });
    //         if (references[selectedIndex].quill) {
    //             references[0].quill.setSelection(0, 0);
    //         }
    //         references.forEach(reference => {
    //             if (reference.quill) {
    //                 reference.quill.enable(false)
    //             }
    //         });
    //     }
    // }
    return (
        <Panel header="Note(s)">
            <div className="p-grid p-fluid">
                <ScrollPanel style={{width: '100%', height: '542px'}} className='custom'>

                    {insertNotes(sortedDisplayNotes, props.selection)}

                </ScrollPanel>
            </div>
        </Panel>
    )
};
