import {AdultPreMolar} from "../Parts/AdultPreMolar";
import {AdultIncisor} from "../Parts/AdultIncisor";
import {AdultMolar} from "../Parts/AdultMolar";
import {AdultPreMolarBicusp} from "../Parts/AdultPreMolarBicusp";
import {BondedCrown, FullCrown, JacketCrown, Post} from "../Model/Constants";
import {ChartItemBase} from "./ChartItemBase";

export class ComPost extends ChartItemBase {

    constructor() {
        super();

        this.state = {};
        this.target = null;
        this.mouth = null;
        this.adultTeeth = null;
        this.tooth = null;

        this.setTarget = this.setTarget.bind(this);
        this.executeTreatment = this.executeTreatment.bind(this);
    };

    toString() {
        return Post;
    }

    canExecute(target) {

        try {
            if ((target instanceof AdultIncisor) ||
                (target instanceof AdultMolar) ||
                (target instanceof AdultPreMolar) ||
                (target instanceof AdultPreMolarBicusp)) {

                target.props.data.charting.forEach((entry) => {
                    if (entry.code === BondedCrown || entry.code === FullCrown || entry.code === JacketCrown)
                        return false
                })
            }
            return (target instanceof AdultIncisor) ||
                (target instanceof AdultMolar) ||
                (target instanceof AdultPreMolar) ||
                (target instanceof AdultPreMolarBicusp);
        } catch (e) {
            return false;
        }
    }

    executeBase(mouth, target) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, Post, true);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;

        mouth.props.addChartEntry(entry);
    }

    executeTreatment(mouth, target) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, Post, false);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;

        mouth.props.addTreatmentHandler(entry);
    }

    setTarget(target) {
        this.target = target;
    }

    getCommandContextMenuItems() {
        return [];
    }

    clear() {
    }
}
