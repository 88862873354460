import React from 'react';
import {connect} from 'react-redux';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import * as Sections from "./Constants";
import {HM_NHS_REG_INCOMPLETE} from "./Constants";
import Personal from "./Sections/Personal";
import AppointmentHistory from "./Sections/AppointmentHistory";
import UserPatients from "./Sections/UserPatients";
import UDAManagement from "./Sections/UDAManagement";
import {getUserIds, userDetailsToolBar} from "./Utils";
import {BaseComponent} from "../../BaseComponent";
import {HM_notImplemented} from "../../../Constants";
import {ICON_SAVE_DISABLED, ICON_SAVE_ENABLED} from "../../../icons";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {setState} from "../../../actions/stateManagement";
import {clearUserDetails, saveUserDetails} from "../../../actions/users";

class ConnectedUser extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else
            this.state = {
                stateManagementId: props.userDataId,

                canSave: {status: false, activeIndex: 0},
                showSaveDialog: false,

                [HM_NHS_REG_INCOMPLETE.id]: false,
                [HM_notImplemented.id]: false,
            };

        this.tabStack = [];
        this.index = 0;

        this.onTabChange = this.onTabChange.bind(this);
        this.onUserChange = this.onUserChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onExit = this.onExit.bind(this);

        this.onSaveUserDetails = this.onSaveUserDetails.bind(this);
        this.onSaveUserDetailsNoClose = this.onSaveUserDetailsNoClose.bind(this);

        this.toolbarCallbacks = {

            TB_SAVE: this.onSaveUserDetailsNoClose,
            TB_SAVE_AND_EXIT: this.onClose,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {
        }
    }

    onTabChange(index) {

        this.setState({canSave: {status: false, activeIndex: index}});
    }

    onUserChange() {

        this.setState({canSave: {status: true, activeIndex: this.state.canSave.activeIndex}});
        this.props.onTabUpdate({key: this.props.userDataId}, true);
    }

    checkUDAManagement() {

        return this.props.userData.underUDAManagement &&
            (this.props.userData.performerNumber.length !== 6 || this.props.userData.providerPIN.length !== 6)
    }

    onClose() {

        if (this.state.canSave.status) {

            if (this.checkUDAManagement())
                return;

            this.setState({showSaveDialog: true}, () => {
                this.props.setState(this.props.userDataId, null);
            });
        } else {
            this.onExit();
        }
    }

    onExit() {

        this.setState({showSaveDialog: false}, () => {
            this.props.clearUserDetails(this.props.userData);
            this.props.onTabUpdate({key: this.props.userDataId}, false);
            this.props.onCloseClick({key: this.props.userDataId});
        });
    }

    onSaveUserDetails() {

        if (this.state.canSave.status) {

            if (this.checkUDAManagement()) {
                this.setState({});
                return;
            }
            this.props.saveUserDetails(this.props.userData);
        }
        this.onExit();
    }

    onSaveUserDetailsNoClose() {

        if (this.state.canSave.status) {

            if (this.checkUDAManagement())
                return;

            this.setState({canSave: {status: false, activeIndex: this.state.activeIndex}}, () => {
                this.props.saveUserDetails(this.props.userData);
                this.props.onTabUpdate({key: this.props.userDataId}, false);
            });
        }
    }

    showDialogs() {
        const content = [];
        content.push(ShowMessageDialog(this, HM_NHS_REG_INCOMPLETE));
        content.push(ShowMessageDialog(this, HM_notImplemented));
        return content;
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.PERSONAL, content: <TabPanel key='personal'
                                                               header="Personal">
                    <Personal onUserChange={this.onUserChange}
                              {...this.props}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.APPOINTMENT_HISTORY, content: <TabPanel key='apHistory'
                                                                          header="Appointment History">
                    <AppointmentHistory {...this.props}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.USER_PATIENTS, content: <TabPanel key='userPatients'
                                                                    header="User Patients">
                    <UserPatients {...this.props}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.UDA_MANAGEMENT, content: <TabPanel key='udaManagment'
                                                                     header="UDA Management">
                    <UDAManagement onUserChange={this.onUserChange}
                                   {...this.props}
                    />
                </TabPanel>
            });

        const {status, activeIndex} = this.state.canSave;

        const saveIcon = status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const footer = <div>
            <Button label="Yes" icon="fa fa-check" onClick={() => {
                this.onSaveUserDetails();
            }}/>
            <Button label="No" icon="fas fa-times" onClick={this.onExit}/>
        </div>;

        return (
            <div id="detailPanel">

                <Dialog header="Save Resource"
                        visible={this.state.showSaveDialog}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>
                    The form has been modified, save these changes?
                </Dialog>

                <Toolbar {...userDetailsToolBar(this.toolbarCallbacks, saveIcon, status, activeIndex)}/>

                <TabView scrollable={true}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {userDataId} = getUserIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        userDataId,
        userData: state.users[userDataId],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[userDataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        saveUserDetails: (data) => dispatch(saveUserDetails(data)),
        clearUserDetails: (data) => dispatch(clearUserDetails(data)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const User = connect(MapStateToProps, MapDispatchToProps)(ConnectedUser);

export default User;

