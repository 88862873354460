import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import _ from "lodash";
import {checkBox, dropDownPlain, inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {HM_AddMedCon, HM_EditMedCon, MEDCON_SEVERITY} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {medicalConditionData} from "../../DefaultData";

export default class AddMedicalCondition extends Component {

    constructor(props) {
        super(props);

        if (props.editing) {

            this.state = {

                medCondition: props.item,
            };
        } else {

            this.state = {

                medCondition: _.cloneDeep(medicalConditionData(ac.getMcId())),
            };
        }
    }

    componentDidMount() {
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onHide = () => {
        const target = this.props.editing ? HM_EditMedCon.id : HM_AddMedCon.id;
        this.props.onHideDialog(target)
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const medCondition = this.state.medCondition;
        const medConditionDefined = medCondition.name === '';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.medCondition)
                        }}
                        disabled={medConditionDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'medCondition',
            medCondition: this.state.medCondition,
        };

        const severityProps = {
            levels: MEDCON_SEVERITY,
            onChange: this.onChange,
            target: 'medCondition',
            medCondition: this.state.medCondition,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div className="p-grid p-fluid form-group">
                    <div className="p-col-3">
                        <label>Name</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'name', '')}
                    </div>

                    <div className="p-col-3">
                        <label>Severity</label>
                    </div>
                    <div className="p-col-9">
                        {dropDownPlain(severityProps, 'levels', 'level', 'label', false, false)}
                    </div>

                    <div className="p-col-3">
                        <label>Description</label>
                    </div>
                    <div className="p-col-6"/>
                    <div className="p-col-3">
                        {checkBox(props, 'formHeader', 'Form Header', false, false)}
                    </div>

                    <div className="p-col-3"/>
                    <div className="p-col-9">
                        {inputTextArea(props, 'description', 4, -1, false, false)}
                    </div>

                    <div className="p-col-3">
                        <label>Action (if required)</label>
                    </div>
                    <div className="p-col-9">
                        {inputTextArea(props, 'action', 4, -1, false, false)}
                    </div>

                    <div className="p-col-3">
                        <label>Form Text</label>
                    </div>
                    <div className="p-col-9">
                        {inputTextArea(props, 'formEntry', 4, -1, false, false)}
                    </div>
                </div>
            </Dialog>
        )
    }
};
