import Housekeeping from "./components/FixedItems/Housekeeping/Housekeeping";
import * as Capabilities from "./components/Capabilities";
import UserCapabilities from "./components/FixedItems/Utilities/UserCapabilities";
import PatientTemplates from "./components/FixedItems/Utilities/PatientTemplates";
import SystemTemplates from "./components/FixedItems/Utilities/SystemTemplates";
import DatabaseHistory from "./components/FixedItems/Database/DatabaseHistory";
import Database from "./components/FixedItems/Database/Database";
import DatabaseRestore from "./components/FixedItems/Database/DatabaseRestore";
import _ from 'lodash';
import KPIs from "./components/FixedItems/KPIs/KPIs";
import {
    SM_ADD_PATIENT,
    SM_ADD_USER,
    SM_EMAIL_MAIL_SHOTS,
    SM_HOUSEKEEPING,
    SM_SMS_MAIL_SHOTS,
    SM_TEMPLATE_NOTES,
    SM_UploadPayments
} from "./actions/stateManagement";
import {
    TextBiannualXrayReport,
    TextMonthlySedReport,
    TextMonthlyXrayReport,
    TextOutstandingTasks,
    TextPatientPaymentPlans,
    TextQuarterlyXrayReport,
    TextWeeksSedReport,
    TextWeeksXrayReport,
    TextYearlyXrayReport
} from "./Messages";
import {
    CM_APPOINTMENT_PAT_ARRIVED,
    CM_APPOINTMENT_SEND_EMAIL,
    CM_APPOINTMENT_SEND_SMS,
    ICON_DIARY_SEARCH_NEXT,
    ICON_DIARY_SEARCH_PREV
} from "./components/FixedItems/Diary/Constants";
import ImportPatientCSV from "./components/FixedItems/Utilities/ImportPatientCSV";
import EmailMailShots from "./components/FixedItems/EmailShots/EmailMailShots";
import SMSMailShots from "./components/FixedItems/SMSShots/SMSMailShots";

import * as icons from "./icons"
import {ICON_DELETE, ICON_EMAIL, ICON_HAND, ICON_MOBILE, ICON_XRAY} from "./icons"
import AddUser from "./components/FixedItems/Users/AddUser";
import AddPatient from "./components/FixedItems/Patients/AddPatient";
import TemplateNotesHandler from "./components/FixedItems/TemplateNotes/TemplateNotes";
import PatientPaymentUpload from "./components/FixedItems/Utilities/PatientPaymentUpload";

export const __CHIRAL_SYSTEMS_LTD_NHS_APPROVAL_NUMBER__ = '135';

export const __CHIRAL_CONTACT_DETAILS__ = 'Please contact Chiral System Ltd via support@chiralsystems.com';

export const __CHIRAL_SUPPORT_NUMBER__ = '+44 (0) 207 099 9126';

export const HTTP_RESPONSE_Unauthorized = 401;
export const HTTP_RESPONSE_NotAcceptable = 406;

export const ACC_ST_POPULATED = {name: 'Populated', text: 'Populated'};
export const ACC_ST_ACTIVE = {name: 'Active', text: 'Activated'};
export const ACC_ST_INACTIVE = {name: 'Inactive', text: 'Inactivated'};
export const ACC_ST_BLOCKED = {name: 'Blocked', text: 'Blocked'};
export const ACC_ST_LEAVING = {name: 'Leaving', text: 'Leaving'};
export const ACC_ST_LEFT = {name: 'Left', text: 'Left'};

export const ACC_STATUS = [ACC_ST_POPULATED, ACC_ST_ACTIVE, ACC_ST_INACTIVE, ACC_ST_BLOCKED, ACC_ST_LEAVING, ACC_ST_LEFT];

export const ACC_TYPE_SINGLE = {name: 'SolePractice', text: 'Sole Practice'};
export const ACC_TYPE_GROUP = {name: 'GroupPractice', text: 'Group Practice'};

export const ACC_TYPES = [ACC_TYPE_SINGLE, ACC_TYPE_GROUP];

export const ACC_LEVEL_1 = {name: 'LevelOne', text: 'Level 1', min: 1, max: 5, amount: 150};
export const ACC_LEVEL_2 = {name: 'LevelTwo', text: 'Level 2', min: 6, max: 10, amount: 225.0};
export const ACC_LEVEL_3 = {name: 'LevelThree', text: 'Level 3', min: 11, max: 1000, amount: 300.0};

export const ACC_LEVELS = [ACC_LEVEL_1, ACC_LEVEL_2, ACC_LEVEL_3];

export const MS_EMAIL = 'Email';
export const MS_SMS = 'SMS';

// Date related

export const ISO_SUN = 0;
export const ISO_MON = 1;
export const ISO_TUE = 2;
export const ISO_WED = 3;
export const ISO_THU = 4;
export const ISO_FRI = 5;
export const ISO_SAT = 6;

export const DATATABLE_SORT_DESC = -1;
export const DATATABLE_SORT_ASC = 1;

// Mail Shot related

export const Null = {value: 'Null', label: '-', ordinal: 0};
export const DateOfBirthLessThan = {value: 'DateOfBirthLessThan', label: 'DOB Before', ordinal: 1};
export const DateOfBirthBetween = {value: 'DateOfBirthBetween', label: 'DOB Between', ordinal: 2};
export const DateOfBirthGreaterThan = {value: 'DateOfBirthGreaterThan', label: 'DOB After', ordinal: 3};
export const NextAppointmentBefore = {value: 'NextAppointmentBefore', label: 'Next Appointment Before', ordinal: 4};
export const NextAppointmentBetween = {value: 'NextAppointmentBetween', label: 'Next Appointment Between', ordinal: 5};
export const NextAppointmentAfter = {value: 'NextAppointmentAfter', label: 'Next Appointment After', ordinal: 6};
export const RegisteredBefore = {value: 'RegisteredBefore', label: 'Registered Before', ordinal: 7};
export const RegisteredBetween = {value: 'RegisteredBetween', label: 'Registered Between', ordinal: 8};
export const RegisteredAfter = {value: 'RegisteredAfter', label: 'Registered After', ordinal: 9};
export const Male = {value: 'Male', label: 'Male', ordinal: 10};
export const Female = {value: 'Female', label: 'Female', ordinal: 11};
export const WithProvider = {value: 'WithProvider', label: 'Provider', ordinal: 12};
export const PatientJourneyCode = {value: 'PatientJourneyCode', label: 'Journey Code (JC)', ordinal: 13};
export const PatientJourneyBetween = {value: 'PatientJourneyBetween', label: 'JC Changed Between', ordinal: 14};
export const PatientStatus = {value: 'Status', label: 'Patient Status', ordinal: 15};

export const MSOperators = [
    Null, DateOfBirthLessThan, DateOfBirthBetween, DateOfBirthGreaterThan,
    NextAppointmentBefore, NextAppointmentBetween, NextAppointmentAfter,
    RegisteredBefore, RegisteredBetween, RegisteredAfter,
    Male, Female, WithProvider,
    PatientJourneyCode, PatientJourneyBetween, PatientStatus
]

export const TB_SEND_EMAIL_MAIL_SHOT = 'TB_SEND_EMAIL_MAIL_SHOT';
export const TB_SEND_SMS_MAIL_SHOT = 'TB_SEND_SMS_MAIL_SHOT';

export const TB_SEND_EMAIL_MAIL_SHOT_TEST = 'TB_SEND_EMAIL_MAIL_SHOT_TEST';
export const TB_SEND_SMS_MAIL_SHOT_TEST = 'TB_SEND_SMS_MAIL_SHOT_TEST';

// Treatment Code related

export const CI_STATUS_USABLE = 'USABLE';
export const CI_STATUS_ARCHIVED = 'ARCHIVED';

// icon related

export const SideBarButtonStyle = {color: "#1a92d0", fontSize: "24px", width: 28};
export const DashBoardButtonStyle = {color: "#70d0f6", fontSize: "48px", marginRight: "20px", width: 48};

// message related constants

export const ALL = "ALL";

export const FUNCTION = "FUNCTION";

export const ADD = "ADD";
export const ADDBACK = "ADDBACK";
export const ADDBACK_MULTI = "ADDBACK_MULTI";

export const PREVIEW = "PREVIEW";
export const SEND_GROUP = "SEND_GROUP";
export const SEND_JOINT = "SEND_JOINT";
export const SEND = "SEND";

export const ADD_INVOICE = "ADD_INVOICE";
export const ADD_PAYMENT = "ADD_PAYMENT";

export const EMAIL_ACCOUNT_ITEM = "EMAIL_ACCOUNT_ITEM";

export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

export const OPEN = "OPEN";
export const OPEN_LATEST = "OPEN_LATEST";
export const OPEN_ID = "OPEN_ID";
export const OPEN_TP = "OPEN_TP";
export const OPEN_CHART_APPOINTMENT = "OPEN_CHART_APPOINTMENT";

export const REMOVED_TPA = "REMOVED_TPA";
export const REMOVED_TPA_TREATMENT = "REMOVED_TPA_TREATMENT";

export const PLACED_IN_DIARY = "PLACED_IN_DIARY";
export const MOVING_IN_DIARY = "MOVING_IN_DIARY";
export const REMOVED_FROM_DIARY = "REMOVED_FROM_DIARY";
export const RECALL_IN_DIARY = "RECALL_IN_DIARY";

export const MOVING_IN_DIARY_MULTI = "MOVING_IN_DIARY_MULTI";

export const RESERVE = "RESERVE";
export const REMOVE_RESERVE = "REMOVE_RESERVE";

export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";

export const APP_DRAG_DELTA = "APP_DRAG_DELTA";
export const APP_RESIZE_DELTA = "APP_RESIZE_DELTA";

export const EVENT_DRAG_DELTA = "EVENT_DRAG_DELTA";
export const EVENT_RESIZE_DELTA = "EVENT_RESIZE_DELTA";

// MHF related

export const HM_COMPLETE_MHF = {
    id: 'HM_COMPLETE_MHF',
    header: 'Complete Medical History Form',
    message: 'Please answer all the questions either Yes / No',
    icon: ''
};

// Questionnaire related

export const HM_COMPLETE_QUES = {
    id: 'HM_COMPLETE_QUES',
    header: 'Complete Questionnaire',
    message: 'Please answer all the questions either Yes / No',
    icon: ''
};

// diary related

export const HM_DIARY_SORT_ORDER = {
    id: 'HM_DIARY_SORT_ORDER',
    header: 'Diary Sort Order',
    message: 'Diary Sort Order',
    icon: '',
    menuIcon: icons.ICON_SORT_ORDER,
};

export const HM_SHOW_WITH = {
    id: 'HM_SHOW_WITH',
    label: 'Show With',
    message: 'Show With',
    icon: icons.ICON_GROUP,
    menuIcon: icons.ICON_GROUP,
    tabIcon: icons.ICON_GROUP,
};

export const SM_DIARY_MODE_NORMAL = 'SM_DIARY_MODE_NORMAL';
export const SM_DIARY_MODE_TASK_TP = 'SM_DIARY_MODE_TASK_TP';
export const SM_DIARY_MODE_PLACE_TPA_APP = 'SM_DIARY_MODE_PLACE_TPA_APP';

export const AP_TYPE_NONE = {name: 'None', text: '-', ordinal: 0};
export const AP_TYPE_ONLINE_BOOKING = {name: 'OnlineBooking', text: 'Online Booking', ordinal: 1};
export const AP_TYPE_VIDEO_CONF = {name: 'VideoConference', text: 'Video Conference', ordinal: 2};
export const AP_DROP_DOWN_TYPES = [AP_TYPE_NONE, AP_TYPE_ONLINE_BOOKING, AP_TYPE_VIDEO_CONF];

// laboratory status

export const LAB_STATUS_INACTIVE = 'INACTIVE';
export const LAB_STATUS_ACTIVE = 'ACTIVE';

// event statuses
export const DE_STATUS_ACTIVE = {name: 'Active', text: 'Active', ordinal: 0};
export const DE_STATUS_ONLINE_BOOKED = {name: 'OnlineBooked', text: 'Booked Online', ordinal: 1};
export const DE_STATUS_ONLINE_VCBooked = {name: 'OnlineBooked', text: 'Video Conference', ordinal: 2};
export const DE_STATUS_CLOSED = {name: 'Closed', text: 'Closed', ordinal: 3};

// event types
export const DE_TYPE_NONE = {name: 'None', text: '-', ordinal: 0, iconName: ''};
export const DE_TYPE_RESERVATION = {name: 'Reservation', text: 'Booking Reservation', ordinal: 1, iconName: ''};
export const DE_TYPE_ONLINE_BOOKING = {
    name: 'OnlineBooking',
    text: 'Online Booking',
    ordinal: 2,
    iconName: 'fa onlineBooking16'
};
export const DE_TYPE_VIDEO_CONF = {
    name: 'VideoConference',
    text: 'Video Conference',
    ordinal: 3,
    iconName: 'fa webCam16'
};

export const DE_TYPES = [DE_TYPE_NONE, DE_TYPE_RESERVATION, DE_TYPE_ONLINE_BOOKING, DE_TYPE_VIDEO_CONF];
export const DE_DROP_DOWN_TYPES = [DE_TYPE_NONE, DE_TYPE_ONLINE_BOOKING, DE_TYPE_VIDEO_CONF];

// patient related

export const PAT_CREATE_STATUS_USERNAME_EXISTS = 'USERNAME_EXISTS';
export const PAT_CREATE_STATUS_BLOCK_REGISTRATION = 'BLOCK_REGISTRATION';
export const PAT_CREATE_STATUS_ALREADY_REGISTERED = 'ALREADY_REGISTERED';
export const PAT_CREATE_STATUS_UNSUBMITTED = 'UNSUBMITTED';
export const PAT_CREATE_STATUS_MATCHED_BOTH = 'MATCHED_BOTH';
export const PAT_CREATE_STATUS_MATCHED_EMAIL = 'MATCHED_EMAIL';
export const PAT_CREATE_STATUS_MATCHED_MOBILE = 'MATCHED_MOBILE';
export const PAT_CREATE_STATUS_MATCHED_NEITHER = 'MATCHED_NEITHER';
export const PAT_CREATE_STATUS_UNMATCHED = 'UNMATCHED';
export const PAT_CREATE_STATUS_AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION';
export const PAT_CREATE_STATUS_CONFIRMED = 'CONFIRMED';
export const PAT_CREATE_STATUS_UNCONFIRMED = 'UNCONFIRMED';
export const PAT_CREATE_STATUS_TRIES_EXCEEDED = 'TRIES_EXCEEDED';
export const PAT_CREATE_STATUS_NEW_ERROR = 'NEW_ERROR';
export const PAT_CREATE_STATUS_REG_MATCHED_BOTH = 'REG_MATCHED_BOTH';
export const PAT_CREATE_STATUS_REG_MATCHED_EMAIL = 'REG_MATCHED_EMAIL';

export const PAT_STATUS_INACTIVE = {value: 'INACTIVE', label: 'Inactive'};
export const PAT_STATUS_ACTIVE = {value: 'ACTIVE', label: 'Active'};
export const PAT_STATUS_BILLSONLY = {value: 'BILLSONLY', label: 'Bills Only'};
export const PAT_STATUS_CASUALPATIENT = {value: 'CASUALPATIENT', label: 'Casual'};
export const PAT_STATUS_DECEASED = {value: 'DECEASED', label: 'Deceased'};
export const PAT_STATUS_HOLD = {value: 'HOLD', label: 'Hold'};
export const PAT_STATUS_INTERIM = {value: 'INTERIM', label: 'Interim'};
export const PAT_STATUS_RECALLSONLY = {value: 'RECALLSONLY', label: 'Recalls Only'};
export const PAT_STATUS_WRITTENOFF = {value: 'WRITTENOFF', label: 'Written Off'};
export const PAT_STATUS_SLOWPAYER = {value: 'SLOWPAYER', label: 'Slow Payer'};
export const PAT_STATUS_NOCOMMUNICATION = {value: 'NOCOMMUNICATION', label: 'Non Communication'};

export const PAT_STATUSES = [PAT_STATUS_INACTIVE, PAT_STATUS_ACTIVE, PAT_STATUS_BILLSONLY, PAT_STATUS_CASUALPATIENT, PAT_STATUS_DECEASED, PAT_STATUS_HOLD, PAT_STATUS_INTERIM, PAT_STATUS_RECALLSONLY, PAT_STATUS_WRITTENOFF, PAT_STATUS_SLOWPAYER, PAT_STATUS_NOCOMMUNICATION];

export const ADD_PATIENT_OK = 'OK';
export const ADD_PATIENT_ALREADY_EXISTS = 'ALREADY_EXISTS';
export const ADD_PATIENT_ERROR = 'ERROR';
export const ADD_PATIENT_NONUNIQUE = 'NONUNIQUE';
export const ADD_PATIENT_PENDING = 'PENDING';

export const ADD_PATIENT_RESULT = [ADD_PATIENT_OK, ADD_PATIENT_ALREADY_EXISTS, ADD_PATIENT_ERROR, ADD_PATIENT_NONUNIQUE];

export const HM_AddPassword = {
    id: 'HM_AddPassword',
    header: 'Patient Password',
    message: 'Set the patient password'
};

export const HM_AddNote = {
    id: 'HM_AddNote',
    header: 'Add Patient Note',
    message: 'Add Note',
    label: 'Add Note',
    icon: icons.ICON_PLUS
};

export const HM_EditNote = {
    id: 'HM_EditNote',
    header: 'Edit Patient Note',
    message: 'Edit Note',
    label: 'Edit Note',
    icon: icons.ICON_EDIT
};

export const HM_TreatmentPlanNote = {
    id: 'HM_TreatmentPlanNote',
    header: 'Edit Treatment Plan Note',
    message: 'Edit Note',
    label: 'Edit Note',
    icon: icons.ICON_EDIT
};

export const HM_DeleteNote = {
    id: 'HM_DeleteNote',
    header: 'Delete Patient Note',
    message: 'Are you sure you want to delete this note?',
    label: 'Delete Note',
    icon: icons.ICON_DELETE
};

export const HM_AddPatientXRAYRecord = {
    id: 'HM_AddPatientXRAYRecord',
    header: 'Add Patient XRAY Record',
    message: 'Add Patient XRAY Record',
    label: 'Add Patient XRAY Record',
    icon: icons.ICON_PLUS
};

export const HM_EditPatientXRAYRecord = {
    id: 'HM_EditPatientXRAYRecord',
    header: 'Edit XRAY Record',
    message: 'Edit XRAY Record',
    label: 'Edit XRAY Record',
    icon: icons.ICON_EDIT
};

export const HM_DeletePatientXRAYRecord = {
    id: 'HM_DeletePatientXRAYRecord',
    header: 'Delete XRAY Record',
    message: 'Are you sure you want to delete this record?',
    label: 'Delete XRAY Record',
    icon: icons.ICON_DELETE
};

export const HM_AddPatientSedationRecord = {
    id: 'HM_AddPatientSedationRecord',
    header: 'Add Patient Sedation Record',
    message: 'Add Patient Sedation Record',
    label: 'Add Patient Sedation Record',
    icon: icons.ICON_PLUS
};

export const HM_EditPatientSedationRecord = {
    id: 'HM_EditPatientSedationRecord',
    header: 'Edit Sedation Record',
    message: 'Edit Sedation Record',
    label: 'Edit Sedation Record',
    icon: icons.ICON_EDIT
};

export const HM_DeletePatientSedationRecord = {
    id: 'HM_DeletePatientSedationRecord',
    header: 'Delete Sedation Record',
    message: 'Are you sure you want to delete this record?',
    label: 'Delete Sedation Record',
    icon: icons.ICON_DELETE
};

export const HM_WriteOffAccount = {
    id: 'WriteOffAccount',
    header: 'Write Off Account',
    message: 'Write Off Account',
    label: 'Write Off Account',
    icon: icons.ICON_DELETE
};

export const HM_CommunicationType = {
    id: 'CommunicationType',
    header: 'Recall & Reminder Communication Type',
    message: 'Set patient recall and reminder communication type',
    label: 'Recall & Reminder Communication Type',
    icon: icons.ICON_EDIT
};

// Client App tool bar related

export const HM_HELP_PATIENT_PORTAL = {
    id: 'HM_HELP_PATIENT_PORTAL',
    header: 'Help',
    message: '',
    icon: 'far fa-question-circle'
};

export const HM_ABOUT_PATIENT_PORTAL = {
    id: 'HM_ABOUT_PATIENT_PORTAL',
    header: 'About Patient Portal',
    message: '',
    icon: icons.ICON_USERS,
};

// App tool bar related

export const HM_MESSAGING = {
    id: 'HM_MESSAGING',
    header: 'Messaging',
    message: '',
    icon: 'far fa-comments'
};

export const HM_HELP_CHIRAL = {
    id: 'HM_HELP_CHIRAL',
    header: 'Help',
    message: '',
    icon: 'far fa-question-circle'
};

export const HM_HELP_CHIRAL_CLIENT = {
    id: 'HM_HELP_CHIRAL_CLIENT',
    header: 'Patient Help',
    message: '',
    icon: 'far fa-question-circle'
};

export const HM_ABOUT_CHIRAL = {
    id: 'HM_ABOUT_CHIRAL',
    header: 'About Chiral Cloud',
    message: '',
    icon: icons.ICON_USERS
};

export const HM_VERSION_MISMATCH = {
    id: 'HM_VERSION_MISMATCH',
    header: 'Chiral Cloud Update',
    message: 'An update is available. Press OK to install',
    icon: icons.ICON_PREFS
};

export const HM_ABOUT_CHIRAL_CLIENT = {
    id: 'HM_ABOUT_CHIRAL_CLIENT',
    header: 'About Chiral Patient Portal',
    message: 'www.chiralsystems.com : support@chiralsystems.com',
    icon: icons.ICON_USERS
};

export const HM_COMPLETE_GDPR = {
    id: 'HM_COMPLETE_GDPR',
    header: 'Complete GDPR consent',
    message: 'Please Complete the GDPR consent form before continuing',
    icon: icons.ICON_USERS
};

export const HM_COMPLETE_OTHERS = {
    id: 'HM_COMPLETE_OTHERS',
    header: 'Complete Requested Actions',
    message: 'Please Complete the requested forms before continuing',
    icon: icons.ICON_USERS
};

export const HM_LOGOUT = {
    id: 'HM_LOGOUT',
    header: 'Logout',
    message: '',
    icon: icons.ICON_GOTO
};

export const HM_FORCE_LOGOUT = {
    id: 'HM_FORCE_LOGOUT',
    header: 'User Account Multiple Login',
    message: 'This login session has been logged out due to the user\'s account being used else where',
    icon: icons.ICON_GOTO
};

export const HM_RELEASE_NOTE = {
    id: 'HM_RELEASE_NOTE',
    header: 'Release Note',
    message: '',
};

// patient related

export const HM_InvoiceIssued = {
    id: 'HM_InvoiceIssued',
    header: 'Invoice Issued',
    message: 'Invoice successfully issued'
};

export const HM_ReceiptIssued = {
    id: 'HM_ReceiptIssued',
    header: 'Receipt Issued',
    message: 'Receipt successfully issued'
};

export const HM_ClaimStacked = {
    id: 'HM_ClaimStacked',
    header: 'Claim Stacked',
    message: 'Claim successfully stacked'
};

export const HM_uploadPatDocument = {
    id: 'HM_uploadPatDocument',
    header: 'Upload Patient Document',
    message: 'Select and upload a patient document'
};

export const HM_uploadPatImage = {
    id: 'HM_uploadPatImage',
    header: 'Upload Patient Image / XRay / PDF',
    message: 'Select and upload a patient image / xray / PDF'
};

export const HM_imageScan = {
    id: 'HM_imageScan',
    header: 'Scan Patient Image',
    message: 'Scan Patient Image'
};

export const HM_imageEditor = {
    id: 'HM_imageEditor',
    header: 'Edit Patient Image',
    message: 'Edit Patient Image'
};

export const HM_XrayConnect = {
    id: 'XrayConnect',
    header: 'Connect to X-Ray Software',
    message: 'Connect to X-Ray Software'
};

export const HM_createPatDocument = {
    id: 'HM_createPatDocument',
    header: 'Create Patient Document',
    message: 'Create a patient document'
};

export const HM_addReferrerDocument = {
    id: 'HM_addReferrerDocument',
    header: 'Referrer / Patient Document',
    message: 'Create a new referrer / patient document'
};

export const HM_selectReferrerDocument = {
    id: 'HM_selectReferrerDocument',
    header: 'Select Referrer Document',
    message: 'Select referrer document to send with attachment(s)'
};

export const HM_addPatAppointment = {
    id: 'HM_addPatAppointment',
    header: 'Patient Appointment',
    message: 'Create a patient appointment'
};

export const HM_addPatMedCondition = {
    id: 'HM_addPatMedCondition',
    header: 'Add Patient Medical Condition',
    message: 'Add Patient Medical Condition',
    icon: icons.ICON_PLUS
};

export const HM_editPatMedCondition = {
    id: 'HM_editPatMedCondition',
    header: 'Edit Condition',
    message: 'Edit Patient Medical Condition',
    icon: icons.ICON_PLUS
};

export const HM_deletePatMedCondition = {
    id: 'HM_deletePatMedCondition',
    header: 'Delete Condition',
    message: 'Are you sure you want to delete this patient medical condition?',
    icon: icons.ICON_DELETE
};

export const HM_printPatMedCondition = {
    id: 'HM_printPatMedCondition',
    label: 'Print Condition',
    message: 'Print Patient Medical Condition',
    icon: icons.ICON_PRINT
};

export const HM_addPatPrescription = {
    id: 'HM_addPatPrescription',
    header: 'Patient Prescription',
    message: 'Create a new patient prescription'
};

export const HM_DeletePatientDocument = {
    id: 'HM_DeletePatientDocument',
    header: 'Delete Patient Document',
    message: 'Are you sure you want to delete this document?',
    icon: icons.ICON_DELETE,
};

export const HM_EditPatientDocument = {
    id: 'HM_EditPatientDocument',
    header: 'Edit Document Information',
    message: 'Edit Patient Document',
    icon: icons.ICON_EDIT,
};

export const HM_PrintPatientDocument = {
    id: 'HM_PrintPatientDocument',
    header: 'Print Patient Document',
    message: 'Print Patient Document',
    icon: icons.ICON_PRINT,
};

export const HM_EmailPatientDocument = {
    id: 'HM_EmailPatientDocument',
    header: 'Email Patient Document',
    message: 'Email Patient Document',
    icon: icons.ICON_EMAIL,
};

export const HM_DeleteXrayScanDocument = {
    id: 'HM_DeleteXrayScanDocument',
    header: 'Delete Xray / Scan',
    message: 'Are you sure you want to delete this xray / scan?',
    icon: icons.ICON_DELETE,
};

export const HM_EditXrayScanDocument = {
    id: 'HM_EditXrayScanDocument',
    header: 'Edit Xray / Scan',
    message: 'Edit Patient Xray / Scan',
    icon: icons.ICON_EDIT,
};

export const HM_RenameXrayScanDocument = {
    id: 'HM_RenameXrayScanDocument',
    header: 'Rename Xray / Scan',
    message: 'Rename Patient Xray / Scan',
    icon: icons.ICON_RENAME,
};

export const HM_PrintXrayScanDocument = {
    id: 'HM_PrintXrayScanDocument',
    header: 'Print Xray / Scan',
    message: 'Print Patient Xray / Scan',
    icon: icons.ICON_PRINT,
};

export const HM_EmailXrayScanDocument = {
    id: 'HM_EmailXrayScanDocument',
    header: 'Email Xray / Scan',
    message: 'Email Patient Xray / Scan',
    icon: icons.ICON_EMAIL,
};

export const HM_DeletePresDocument = {
    id: 'HM_DeletePresDocument',
    header: 'Delete Prescription',
    message: 'Are you sure you want to delete this prescription?',
    icon: icons.ICON_DELETE,
};

export const HM_EditPresDocument = {
    id: 'HM_EditPresDocument',
    header: 'Edit Prescription',
    message: 'Edit Patient Prescription',
    icon: icons.ICON_EDIT,
};

export const HM_PrintPresDocument = {
    id: 'HM_PrintPresDocument',
    header: 'Print Prescription',
    message: 'Print Patient Prescription',
    icon: icons.ICON_PRINT,
};

export const HM_EmailPresDocument = {
    id: 'HM_EmailPresDocument',
    header: 'Email Prescription',
    message: 'Email Patient Prescription',
    icon: icons.ICON_EMAIL,
};

export const HM_DeleteReferralDocument = {
    id: 'HM_DeleteReferralDocument',
    header: 'Delete Referral Document',
    message: 'Are you sure you want to delete this document?',
    icon: icons.ICON_DELETE,
};

export const HM_EditReferralDocument = {
    id: 'HM_EditReferralDocument',
    header: 'Edit Referral Document',
    message: 'Edit Patient Referral Document',
    icon: icons.ICON_EDIT,
};

export const HM_PrintReferralDocument = {
    id: 'HM_PrintReferralDocument',
    header: 'Print Referral Document',
    message: 'Print Patient Referral Document',
    icon: icons.ICON_PRINT,
};

export const HM_EmailReferralDocument = {
    id: 'HM_EmailReferralDocument',
    header: 'Email Referral Document',
    message: 'Email Patient Referral Document',
    icon: icons.ICON_EMAIL,
};

export const HM_DeleteTreatmentPlanDocument = {
    id: 'HM_DeleteTreatmentPlanDocument',
    header: 'Delete Treatment Plan Document',
    message: 'Are you sure you want to delete this document?',
    icon: icons.ICON_DELETE,
};

export const HM_EditTreatmentPlanDocument = {
    id: 'HM_EditTreatmentPlanDocument',
    header: 'Edit Treatment Plan Document',
    message: 'Edit Patient Treatment Plan Document',
    icon: icons.ICON_EDIT,
};

export const HM_PrintTreatmentPlanDocument = {
    id: 'HM_PrintTreatmentPlanDocument',
    header: 'Print Treatment Plan Document',
    message: 'Print Patient Treatment Plan Document',
    icon: icons.ICON_PRINT,
};

export const HM_EmailTreatmentPlanDocument = {
    id: 'HM_EmailTreatmentPlanDocument',
    header: 'Email Treatment Plan Document',
    message: 'Email Patient Treatment Plan Document',
    icon: icons.ICON_EMAIL,
};

export const HM_DeletePaymentPlanDocument = {
    id: 'HM_DeletePaymentPlanDocument',
    header: 'Delete Payment Plan Document',
    message: 'Are you sure you want to delete this document?',
    icon: icons.ICON_DELETE,
};

export const HM_DeleteConsentDocument = {
    id: 'HM_DeleteConsentDocument',
    header: 'Delete Consent Document',
    message: 'Are you sure you want to delete this document?',
    icon: icons.ICON_DELETE,
};

export const HM_EditConsentDocument = {
    id: 'HM_EditConsentDocument',
    header: 'Edit Consent Document',
    message: 'Edit Consent Document',
    icon: icons.ICON_EDIT,
};

export const HM_PrintConsentDocument = {
    id: 'HM_PrintConsentDocument',
    header: 'Print Consent Document',
    message: 'Print Consent Document',
    icon: icons.ICON_PRINT,
};

export const HM_EmailConsentDocument = {
    id: 'HM_EmailConsentDocument',
    header: 'Email Consent Document',
    message: 'Email Consent Document',
    icon: icons.ICON_EMAIL,
};

export const HM_DeleteEmailSMS = {
    id: 'HM_DeleteEmailSMS',
    header: 'Delete SMS Email',
    message: 'Are you sure you want to delete this item?',
    icon: icons.ICON_DELETE,
};

export const HM_EditEmailSMS = {
    id: 'HM_EditEmailSMS',
    header: 'Edit SMS / Email',
    message: 'Edit SMS / Email',
    icon: icons.ICON_EDIT,
};

export const HM_EditPaymentPlan = {
    id: 'HM_EditPaymentPlan',
    label: 'Edit Payment Plan',
    header: '',
    message: '',
    icon: icons.ICON_EDIT,
};

export const HM_GotoTreatmentPlan = {
    id: 'HM_GotoPaymentPlan',
    label: 'Show Treatment Plan',
    header: '',
    message: '',
    icon: icons.ICON_GOTO,
};

export const HM_GotoPaymentPlan = {
    id: 'HM_GotoPaymentPlan',
    label: 'Show Payment Plan',
    header: '',
    message: '',
    icon: icons.ICON_GOTO,
};

export const HM_ShowPaymentPlanDocument = {
    id: 'HM_ShowPaymentPlanDocument',
    label: 'Show Payment Plan',
    header: '',
    message: '',
    icon: icons.ICON_SHOW
};

export const HM_EditPaymentPlanDocument = {
    id: 'HM_PrintPaymentPlanDocument',
    header: 'Edit Payment Plan',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_PrintPaymentPlanDocument = {
    id: 'HM_PrintPaymentPlanDocument',
    header: 'Print Payment Plan',
    message: '',
    icon: icons.ICON_PRINT
};

export const HM_EmailPaymentPlanDocument = {
    id: 'HM_EmailPaymentPlanDocument',
    header: 'Email Payment Plan Document',
    message: 'Email Payment Plan Document',
    icon: icons.ICON_EMAIL,
};

export const HM_PatientImages = {
    id: 'HM_PatientImages',
    header: 'Patient Images',
    message: 'Patient Images'
};

export const HM_PatientPerio = {
    id: 'HM_PatientPerio',
    header: 'Chart',
    message: 'Periodontal Chart'
};

export const HM_IMAGES_UPLOAD = {
    id: 'HM_IMAGES_UPLOAD',
    header: 'Upload Image(s)',
    message: 'Upload Image(s)'
};

export const HM_IMAGES_SELECT_ALL = {
    id: 'HM_IMAGES_SELECT_ALL',
    header: 'Select All',
    message: 'Select All'
};

export const HM_IMAGES_DESELECT_ALL = {
    id: 'HM_IMAGES_DESELECT_ALL',
    header: 'Deselect All',
    message: 'Deselect All'
};

export const HM_DOWNLOAD_SELECTED = {
    id: 'HM_DOWNLOAD_SELECTED',
    header: 'Download selected images',
    message: 'Download selected images'
};

export const HM_TRANSFER_SELECTED = {
    id: 'HM_TRANSFER_SELECTED',
    header: 'Transfer selected images(s)',
    message: 'Transfer selected images(s)'
};

export const HM_DELETE_SELECTED_IMAGE = {
    id: 'HM_DELETE_SELECTED_IMAGE',
    header: 'Delete Image',
    label: 'Delete this image',
    message: 'Are you sure you want to delete this image',
    icon: 'fa fa-times'
};

export const HM_DELETE_SELECTED_IMAGES = {
    id: 'HM_DELETE_SELECTED_IMAGES',
    header: 'Delete Image(s)',
    label: 'Delete selected images',
    message: 'Are you sure you want to delete this image(s)',
    icon: 'fa fa-times'
};

export const HM_PatientXrayHistory = {
    id: 'HM_PatientXrayHistory',
    header: 'Patient Xray History',
    message: 'Add New Patient Xray Record',
    icon: icons.ICON_PLUS
};

export const HM_PatientSedationHistory = {
    id: 'HM_PatientSedationHistory',
    header: 'Patient Sedation History',
    message: 'Add New Patient Sedation Record',
    icon: icons.ICON_PLUS
};

export const HM_PatientBillingGroup = {
    id: 'HM_PatientBillingGroup',
    header: 'Family Group',
    message: 'Family Group'
};

export const HM_BG_REMOVE_MEMBER = {
    id: 'HM_BG_REMOVE_MEMBER',
    header: 'Remove Member',
    message: 'Remove Member'
};

export const HM_BG_SHOW_MEMBER = {
    id: 'HM_BG_SHOW_MEMBER',
    header: 'Show this members account',
    message: 'Show this members account'
};

export const HM_setIndependentReferrer = {
    id: 'HM_setIndependentReferrer',
    header: 'Independent Referrer',
    message: 'Set Independent Referrer'
};

export const HM_setPatientReferrer = {
    id: 'HM_setPatientReferrer',
    header: 'Patient Referrer',
    message: 'Set Patient Referrer'
};

export const HM_setProviderReferrer = {
    id: 'HM_setProviderReferrer',
    header: 'Provider Referrer',
    message: 'Set Provider Referrer'
};

export const HM_AddFollowOnRecall = {
    id: 'HM_AddFollowOnRecall',
    id2: 'HM_AddFollowOnRecall2',
    header: 'Add Recall Follow On',
    message: 'Add a patient recall follow on',
    tpOwner: 'treatmentPlan.patient.patientJourney.stage',
    ppOwner: 'patientData.patientJourney.stage',
    icon: icons.ICON_PLUS,
};

export const HM_SaveBeforeAction = {
    id: 'HM_SaveBeforeAction',
    header: 'Save Before Action',
    message: 'Please save before continuing',
    icon: icons.ICON_PLUS,
};

// billing group related

export const BG_PRINCIPLE = 'Family Principle';
export const BG_GROUP_MEMBERS = 'Family Members';
export const BG_ACCOUNT_BALANCE = 'Account Balance';

export const HM_EditBillingGroup = {
    id: 'HM_EditBillingGroup',
    header: 'Edit Family Group',
    message: '',
    icon: 'fa fa-pencil'
};

export const HM_AddBillingGroup = {
    id: 'HM_AddBillingGroup',
    header: 'Add Family Group',
    message: '',
    icon: icons.ICON_PLUS
};

// system user related

export const SU_STATUS_INACTIVE = 'INACTIVE';
export const SU_STATUS_ACTIVE = 'ACTIVE';
export const SU_STATUS_LOCKED = 'LOCKED';

export const SU_AT_PERCENTAGE = 'PERCENTAGE';
export const SU_AT_FIXEDFEE = 'FIXEDFEE';

export const SU_ADD_RESULT_OK = 'OK';
export const SU_ADD_RESULT_ALREADY_EXISTS = 'ALREADY_EXISTS';
export const SU_ADD_RESULT_DUPLICATE_USERNAME = 'DUPLICATE_USERNAME';
export const SU_ADD_RESULT_ERROR = 'ERROR';

export const SU_ADD_RESULT = [SU_ADD_RESULT_OK, SU_ADD_RESULT_ALREADY_EXISTS, SU_ADD_RESULT_DUPLICATE_USERNAME, SU_ADD_RESULT_ERROR];

// patient note types

export const PN_Info = {label: 'Informational', value: 'Info'};
export const PN_Warning = {label: 'Warning', value: 'Warning'};
export const PN_Action = {label: 'Action', value: 'Action'};
export const PN_Medical = {label: 'Medical (Dental)', value: 'Medical'};
export const PN_Treatment = {label: '-', value: 'Treatment'};
export const PN_Invoice = {label: '-', value: 'Invoice'};
export const PN_Receipt = {label: '-', value: 'Receipt'};
export const PN_Payment = {label: '-', value: 'Payment'};
export const PN_Refund = {label: '-', value: 'Refund'};
export const PN_AAdjustment = {label: '-', value: 'AAdjustment'};
export const PN_TreatmentCode = {label: '-', value: 'TreatmentCode'};
export const PN_Complaint = {label: 'Complaint', value: 'Complaint'};

export const PN_Add_NoteType = [PN_Medical, PN_Info, PN_Warning, PN_Action, PN_Complaint];

export const PN_NEVER_SHOW = {label: "Close popup and never show again", icon: 'fas fa-trash-alt'};
export const PN_CLOSE_POPUP = {label: "Close popup", icon: ICON_DELETE};
export const PN_CLOSE_ALL_POPUP = {label: "Close all popups", icon: 'far fa-clone'};

export const PN_NHS_NUMBER_MISSING = {
    label: "NHS Number Missing",
    icon: ICON_HAND,
    value: 'This patient does not have an NHS number recorded'
};

export const PN_NEGATIVE_BALANCE = {
    label: "Account Balance",
    icon: ICON_HAND,
};

// menu related constants
// Patients

export const ME_ADD_PATIENT = {
    id: SM_ADD_PATIENT.id,
    label: SM_ADD_PATIENT.label,
    impClass: AddPatient,
    menuIcon: SM_ADD_PATIENT.icon,
    tabIcon: SM_ADD_PATIENT.tabIcon,
    text: SM_ADD_PATIENT.label,
    prefSection: null,
    controlledBy: Capabilities.AID_AddPatient,
};

export const ME_MAIL_SHOTS = {
    id: 'ME_MAIL_SHOTS',
    label: 'Mail Shots',
    menuIcon: icons.ICON_MAIL_SHOTS,
    tabIcon: icons.ICON_MAIL_SHOTS,
};

export const ME_EMAIL_MAIL_SHOTS = {
    id: SM_EMAIL_MAIL_SHOTS.id,
    label: 'Email',
    impClass: EmailMailShots,
    menuIcon: icons.ICON_EMAIL,
    tabIcon: icons.ICON_EMAIL,
    text: 'Email',
    prefSection: null,
    controlledBy: null,
};

export const ME_SMS_MAIL_SHOTS = {
    id: SM_SMS_MAIL_SHOTS.id,
    label: 'SMS',
    impClass: SMSMailShots,
    menuIcon: icons.ICON_SMS,
    tabIcon: icons.ICON_SMS,
    text: 'SMS',
    prefSection: null,
    controlledBy: null,
};

// Admin

export const ME_GROUPS = {
    id: 'SM_GROUPS',
    label: 'Practice Groups',
    menuIcon: icons.ICON_GROUP,
    tabIcon: icons.ICON_GROUP,
};

export const ME_SUBSCRIPTIONS = {
    id: 'SM_SUBSCRIPTIONS',
    label: 'Group Subscriptions',
    menuIcon: icons.ICON_USERS,
    tabIcon: icons.ICON_USERS,
};

// Preferences

export const HM_Preferences_QB_IMPORT = {
    id: 'HM_Preferences_QB_IMPORT',
    header: 'Quick Books Payment Import',
    message: 'Quick Books Payment Import',
    icon: ''
};

// Utilities

export const ME_Utilities = {
    id: 'SM_UTILITIES',
    label: 'Utilities',
    menuIcon: icons.ICON_UTILITIES,
    tabIcon: icons.ICON_UTILITIES,
};

export const ME_Preferences = {
    id: 'SM_PREFERENCES',
    label: 'Preferences',
    menuIcon: icons.ICON_PREFS,
    tabIcon: icons.ICON_PREFS,
};

export const ME_Housekeeping = {
    id: SM_HOUSEKEEPING.id,
    label: 'Housekeeping',
    impClass: Housekeeping,
    menuIcon: icons.ICON_HOUSE_KEEPING,
    tabIcon: icons.ICON_HOUSE_KEEPING,
    text: 'House Keeping',
    prefSection: null,
    controlledBy: Capabilities.AID_HouseKeeping,
};

export const ME_UserCapabilities = {
    id: 'userCapabilities',
    label: 'User Capabilities',
    impClass: UserCapabilities,
    menuIcon: icons.ICON_CAPS,
    tabIcon: icons.ICON_CAPS,
    text: 'User Capabilities',
    prefSection: null,
    controlledBy: Capabilities.AID_AlterActivites,
};

export const ME_ImportPatientCSV = {
    id: 'ME_ImportPatientCSV',
    label: 'Import Patient CSV',
    impClass: ImportPatientCSV,
    menuIcon: icons.ICON_IMPORT,
    tabIcon: icons.ICON_IMPORT,
    text: 'Import Patient CSV',
    prefSection: null,
    controlledBy: null,
};

export const ME_PatientTemplates = {
    id: 'patDocTemplates',
    label: 'Patient Templates',
    impClass: PatientTemplates,
    menuIcon: icons.ICON_TEMPLATE,
    tabIcon: icons.ICON_TEMPLATE,
    text: 'Patient Document Templates',
    prefSection: null,
    controlledBy: null,
};

export const ME_SystemTemplates = {
    id: 'sysDocTemplates',
    label: 'System Templates',
    impClass: SystemTemplates,
    menuIcon: icons.ICON_TEMPLATE,
    tabIcon: icons.ICON_TEMPLATE,
    text: 'System Document Templates',
    prefSection: null,
    controlledBy: Capabilities.AID_EditSystemTemplates,
};

export const ME_TemplateNotes = {
    id: SM_TEMPLATE_NOTES.id,
    label: 'Template Notes',
    impClass: TemplateNotesHandler,
    menuIcon: icons.ICON_NOTE,
    tabIcon: icons.ICON_NOTE,
    text: 'Template Notes',
    prefSection: null,
    controlledBy: Capabilities.AID_EditTemplateNotes,
};

export const ME_UploadPayments = {
    id: SM_UploadPayments.id,
    label: 'Upload Payments',
    impClass: PatientPaymentUpload,
    menuIcon: icons.ICON_UPLOAD,
    tabIcon: icons.ICON_UPLOAD,
    text: 'Upload Patient Payments',
    prefSection: null,
    controlledBy: Capabilities.AID_UploadPayments,
};

// Database Backup

export const ME_DBBackup = {
    id: 'databaseBackup',
    label: 'Backup',
    impClass: Database,
    menuIcon: 'databaseBackup24',
    tabIcon: 'databaseBackup16',
    text: 'Database Backup',
    prefSection: null,
    controlledBy: Capabilities.AID_DBBackup,
};

export const ME_DBRestore = {
    id: 'databaseRestore',
    label: 'Restore',
    impClass: DatabaseRestore,
    menuIcon: 'databaseRestore24',
    tabIcon: 'databaseRestore16',
    text: 'Database Restore',
    prefSection: null,
    controlledBy: Capabilities.AID_DBBackup,
};

export const ME_DBHistory = {
    id: 'databaseHistory',
    label: 'History',
    impClass: DatabaseHistory,
    menuIcon: 'databaseHistory24',
    tabIcon: 'databaseHistory16',
    text: 'Database History',
    prefSection: null,
    controlledBy: Capabilities.AID_DBBackup,
};

// KPI's

export const ME_KPIS = {
    id: 'kpis',
    label: 'KPIs',
    impClass: KPIs,
    menuIcon: 'kpis24',
    tabIcon: 'kpis16',
    text: 'KPIs',
    prefSection: null,
    controlledBy: null,
};

// User Roles

export const ADMINISTRATOR = 'ADMINISTRATOR';
export const CLINICIAN = 'CLINICIAN';
export const NURSE = 'NURSE';
export const RECEPTIONIST = 'RECEPTIONIST';
export const CHIRALADMIN = 'CHIRALADMIN';
export const TABLET = 'TABLET';

export const ROLES = [ADMINISTRATOR, CLINICIAN, NURSE, RECEPTIONIST, CHIRALADMIN, TABLET];

export const rolesOrdinal = (role) => {
    ROLES.indexOf(role)
};

export const HM_UserAddPassword = {
    id: 'HM_UserAddPassword',
    header: 'User Password (Min 6 characters)',
    message: 'Set the user password'
};

export const HM_UserSave = {
    id: 'HM_UserSave',
    header: 'User Creation (Min 6 characters)',
    message: 'The form has been modified, save these changes?'
};

export const HM_UserSaveOK = {
    id: 'HM_UserSaveOK',
    header: 'User Creation',
    message: 'User Successfully Created'
};

export const HM_UserSaveNOK = {
    id: 'HM_UserSaveNOK',
    header: 'User Creation',
    message: 'User Creation Failed'
};

// account types

export const ACC_TYPE_INVOICE = 'ACC_TYPE_INVOICE';
export const ACC_TYPE_RECEIPT = 'ACC_TYPE_RECEIPT';
export const ACC_RECEIPT = 'Receipt';
export const ACC_TYPE_PAYMENT = 'ACC_TYPE_PAYMENT';
export const ACC_PAYMENT = 'Payment';
export const ACC_TYPE_CREDIT_NOTE = 'ACC_TYPE_CREDIT_NOTE';
export const ACC_TYPE_REFUND = 'ACC_TYPE_REFUND';
export const ACC_REFUND = 'Refund';
export const ACC_TYPE_ADJUSTMENT = 'ACC_TYPE_ADJUSTMENT';

// charge statuses

export const CH_UNUSED1 = 'CH_UNUSED1';
export const CH_INVOICE = 'CH_INVOICE';
export const CH_INVOICED = 'CH_INVOICED';
export const CH_NHSCOMPLETED = 'CH_NHSCOMPLETED';
export const CH_UNUSED3 = 'CH_UNUSED3';
export const CH_SAVED = 'CH_SAVED';
export const CH_SCHEDULED = 'CH_SCHEDULED';
export const CH_RECEIPTED = 'CH_RECEIPTED';

export const CHARGE_STATUS = [
    CH_UNUSED1, CH_INVOICE, CH_INVOICED, CH_NHSCOMPLETED, CH_UNUSED3, CH_SAVED, CH_SCHEDULED, CH_RECEIPTED
];

export const chargeStatusOrdinal = (status) => {
    CHARGE_STATUS.indexOf(status)
};

// invoice statuses

export const INV_DUE = 'INV_DUE';
export const INV_PREDUE = 'INV_PREDUE';
export const INV_SCHEDULE = 'INV_SCHEDULE';
export const INV_ONGOING = 'INV_ONGOING';
export const INV_CLOSED = 'INV_CLOSED';
export const INV_WRITTEN_OFF = 'INV_WRITTEN_OFF';
export const INV_CANCELLED = 'INV_CANCELLED';
export const INV_VOIDED = 'INV_VOIDED';
export const INV_PLANNED = 'INV_PLANNED';

export const INVOICE_STATUS = [INV_DUE, INV_PREDUE, INV_SCHEDULE, INV_ONGOING, INV_CLOSED, INV_WRITTEN_OFF, INV_CANCELLED, INV_VOIDED, INV_PLANNED];

export const invoiceStatusOrdinal = (status) => {
    INVOICE_STATUS.indexOf(status)
};

// receipt statuses

export const REC_ISSUED = 'ISSUED';
export const REC_AVAILABLE = 'AVAILABLE';
export const REC_ALLOCATED = 'ALLOCATED';
export const REC_VOIDED = 'VOIDED';

// Appointment treatment display

export const TREATMENTS_TP_LEFT = 'TREATMENTS_TP_LEFT';
export const TREATMENTS_TP_RIGHT = 'TREATMENTS_TP_RIGHT';
export const TREATMENTS_APP = 'TREATMENTS_APP';

// treatment plan statuses

export const TP_PROPOSED = {name: 'PROPOSED', text: 'Proposed', ord: 0};
export const TP_ACCEPTED = {name: 'ACCEPTED', text: 'Accepted', ord: 1};
export const TP_REJECTED = {name: 'REJECTED', text: 'Rejected', ord: 2};
export const TP_CLOSED = {name: 'CLOSED', text: 'Closed', ord: 3};

export const TP_STATUS = [TP_PROPOSED, TP_ACCEPTED, TP_REJECTED, TP_CLOSED];

export const tpStatusOrdinal = status => TP_STATUS.find(entry => status === entry.name).ord;
export const tpStatusText = status => TP_STATUS.find(entry => status === entry.name).text;

// patient account types

export const PAT_ACC_TYPE_CURRENT = {value: 'CURRENT', label: 'Current'};
export const PAT_ACC_TYPE_FULL = {value: 'FULL', label: 'Full'};
export const PAT_ACC_TYPE_DATE_RANGE = {value: 'DATERANGE', label: 'Date Range'};
export const PAT_ACC_TYPE_SELECTION = {value: 'SELECTION', label: 'Selection'};

export const PAT_ACC_TYPES = [PAT_ACC_TYPE_CURRENT, PAT_ACC_TYPE_FULL, PAT_ACC_TYPE_DATE_RANGE];

// patient journey codes

export const PJ_STATUS_Usable = 'Useable';

// patient journey stage actions

export const PJ_None = {value: 'None', label: 'No Action', months: 0, ord: 0, recall: false};
export const PJ_RET3 = {value: 'RET3', label: 'Retention Check 3 Months', months: 3, ord: 1, recall: true};
export const PJ_RET6 = {value: 'RET6', label: 'Retention Check 6 Months', months: 6, ord: 2, recall: true};
export const PJ_RET9 = {value: 'RET9', label: 'Retention Check 9 Months', months: 9, ord: 3, recall: true};
export const PJ_RET12 = {value: 'RET12', label: 'Retention Check 12 Months', months: 12, ord: 4, recall: true};
export const PJ_RV3 = {value: 'RV3', label: 'Review in 3 Months', months: 3, ord: 5, recall: true};
export const PJ_RV6 = {value: 'RV6', label: 'Review in 6 Months', months: 6, ord: 6, recall: true};
export const PJ_RV9 = {value: 'RV9', label: 'Review in 9 Months', months: 9, ord: 7, recall: true};
export const PJ_RV12 = {value: 'RV12', label: 'Review in 12 Months', months: 12, ord: 8, recall: true};
export const PJ_RV24 = {value: 'RV24', label: 'Review in 24 Months', months: 24, ord: 9, recall: true};
export const PJ_RV36 = {value: 'RV36', label: 'Review in 36 Months', months: 36, ord: 10, recall: true};
export const PJ_RV48 = {value: 'RV48', label: 'Review in 48 Months', months: 48, ord: 11, recall: true};
export const PJ_RET4 = {value: 'RET4', label: 'Retention Check 4 Months', months: 4, ord: 12, recall: true};
export const PJ_DEACT = {value: 'DEACT', label: 'Deactivate Patient', months: 0, ord: 13, recall: true};
export const PJ_Recall = {value: 'Recall', label: 'Set Recall', months: 0, ord: 14, recall: true};
export const PJ_InformKeap = {value: 'InformKeap', label: 'Set Recall', months: 0, ord: 15, recall: false};
export const PJ_RV18 = {value: 'RV18', label: 'Review in 18 Months', months: 19, ord: 16, recall: true};
export const PJ_RV30 = {value: 'RV30', label: 'Review in 30 Months', months: 30, ord: 17, recall: true};
export const PJ_RET24 = {value: 'RET24', label: 'Retention Check 24 Months', months: 24, ord: 18, recall: true};
export const PJ_RET36 = {value: 'RET36', label: 'Retention Check 36 Months', months: 36, ord: 19, recall: true};
export const PJ_RET48 = {value: 'RET48', label: 'Retention Check 48 Months', months: 48, ord: 20, recall: true};

export const PJ_Action = [PJ_None, PJ_RET3, PJ_RET6, PJ_RET9, PJ_RET12, PJ_RET24, PJ_RET36, PJ_RET48, PJ_RV3, PJ_RV6, PJ_RV9, PJ_RV12, PJ_RV18, PJ_RV24, PJ_RV30, PJ_RV36, PJ_RV48, PJ_RET4, PJ_DEACT, PJ_Recall];

export const pjActionOrdinal = action => PJ_Action.find(entry => action === entry.name).ord;
export const pjActionText = action => PJ_Action.find(entry => action === entry.name).text;
export const pjActionMonths = action => PJ_Action.find(entry => action === entry.name).months;

// Template / Document Types

export const DOC_WORD = {name: 'WORD', text: 'Standard Patient Letter', icon: 'far fa-file-word'};
export const DOC_IMAGE = {name: 'IMAGE', text: '', icon: 'fa fa-image'};
export const DOC_XRAY = {name: 'XRAY', text: '', icon: ICON_XRAY};
export const DOC_SYSTEM_TEMPLATE = {name: 'SYSTEM_TEMPLATE', text: '', icon: 'fa fa-circle'};
export const DOC_UPLOAD_MSWORD = {name: 'UPLOAD_MSWORD', text: '', icon: 'far fa-file-word'};
export const DOC_UPLOAD_WORD = {name: 'UPLOAD_WORD', text: '', icon: 'far fa-file-word'};
export const DOC_UPLOAD_XRAY_TIF = {name: 'UPLOAD_XRAY_TIF', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_XRAY_JPG = {name: 'UPLOAD_XRAY_JPG', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_XRAY_GIF = {name: 'UPLOAD_XRAY_GIF', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_SCAN_TIF = {name: 'UPLOAD_SCAN_TIF', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_SCAN_JPG = {name: 'UPLOAD_SCAN_JPG', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_SCAN_GIF = {name: 'UPLOAD_SCAN_GIF', text: '', icon: 'fa fa-image'};
export const DOC_REFERRAL_TEMPLATE = {
    name: 'REFERRAL_TEMPLATE',
    text: 'Standard Referrer / Patient Template',
    icon: 'fa fa-circle'
};
export const DOC_PRESCRIPTION_TEMPLATE = {
    name: 'PRESCRIPTION_TEMPLATE',
    text: 'Standard Prescription Template',
    icon: 'fa fa-circle'
};
export const DOC_REFERRAL = {name: 'REFERRAL', text: 'Standard Referrer / Patient', icon: 'fa fa-circle'};
export const DOC_PRESCRIPTION = {name: 'PRESCRIPTION', text: 'Standard Prescription', icon: 'fas fa-prescription'};
export const DOC_PDF = {name: 'PDF', text: '', icon: 'far fa-file-pdf'};
export const DOC_TREATMENT_PLAN = {name: 'TREATMENT_PLAN', text: 'Standard Treatment Plan', icon: 'fa fa-circle'};
export const DOC_TREATMENT_PLAN_TEMPLATE = {
    name: 'TREATMENT_PLAN_TEMPLATE',
    text: 'Standard Treatment Plan Template',
    icon: 'fa fa-circle'
};
export const DOC_WORD_PATIENT_TEMPLATE = {
    name: 'WORD_PATIENT_TEMPLATE',
    text: 'Standard Patient Letter Template',
    icon: 'fa fa-circle'
};
export const DOC_EmailAccountItem = {
    name: 'EmailAccountItem',
    text: 'Patient Account Item Email',
    icon: 'fa fa-circle'
};
export const DOC_Email = {name: 'Email', text: 'Patient Email', icon: 'fas fa-at'};
export const DOC_CONTACTLIST_TEMPLATE = {
    name: 'CONTACTLIST_TEMPLATE',
    text: 'Standard Contact Template',
    icon: 'fa fa-circle'
};
export const DOC_CONTACTLIST = {name: 'CONTACTLIST', text: 'Standard Contact', icon: 'fa fa-circle'};
export const DOC_ANY = {name: 'ANY', text: '', icon: 'fa fa-circle'};
export const DOC_UPLOAD_SCAN_PDF = {name: 'UPLOAD_SCAN_PDF', text: '', icon: 'far fa-file-pdf'};
export const DOC_ORTHO_REFERRER_TEMPLATE = {
    name: 'ORTHO_REFERRER_TEMPLATE',
    text: 'Standard Ortho Referrer / Patient Template',
    icon: 'far fa-file-word'
};
export const DOC_ORTHO_REFERRAL = {
    name: 'ORTHO_REFERRAL',
    text: 'Standard Ortho Referrer / Patient',
    icon: 'far fa-file-word'
};
export const DOC_SMS = {name: 'SMS', text: 'Adhoc SMS', icon: 'fa fa-circle'};
export const DOC_SIGNATURE = {name: 'SIGNATURE', text: 'Signature', icon: 'fa fa-circle'};
export const DOC_PAYMENT_PLAN = {name: 'PAYMENT_PLAN', text: 'Standard Payment Plan', icon: 'fa fa-circle'};
export const DOC_PAYMENT_PLAN_TEMPLATE = {
    name: 'PAYMENT_PLAN_TEMPLATE',
    text: 'Standard Payment Plan Template',
    icon: 'fa fa-circle'
};
export const DOC_PAYMENT_PLAN_PROPOSAL = {
    name: 'PAYMENT_PLAN_PROPOSAL',
    text: 'Standard Payment Plan Proposal',
    icon: 'fa fa-circle'
};
export const DOC_PAYMENT_PLAN_PROPOSAL_TEMPLATE = {
    name: 'PAYMENT_PLAN_PROPOSAL_TEMPLATE',
    text: 'Standard Payment Plan Proposal Template',
    icon: 'fa fa-circle'
};
export const DOC_APPOINTMENT_LETTER = {
    name: 'APPOINTMENT_LETTER',
    text: 'Standard Appointment Letter',
    icon: 'fa fa-circle'
};
export const DOC_APPOINTMENT_LETTER_TEMPLATE = {
    name: 'APPOINTMENT_LETTER_TEMPLATE',
    text: 'Standard Appointment Letter template',
    icon: 'fa fa-circle'
};
export const DOC_SMS_OUTGOING = {name: 'SMS_OUTGOING', text: 'SMS Outgoing', icon: 'fas fa-sign-out'};
export const DOC_SMS_INCOMING = {name: 'SMS_INCOMING', text: 'SMS Incoming', icon: 'fas fa-sign-in'};
export const DOC_CONSENT = {name: 'CONSENT', text: 'Patient Consent Form', icon: 'fa fa-circle'};
export const DOC_CONSENT_TEMPLATE = {
    name: 'CONSENT_TEMPLATE',
    text: 'Patient Consent Form Template',
    icon: 'fa fa-circle'
};
export const DOC_GDPR_CONSENT = {name: 'GDPR_CONSENT', text: 'Patient GDPR Consent Form', icon: 'fa fa-circle'};
export const DOC_GDPR_CONSENT_TEMPLATE = {
    name: 'GDPR_CONSENT_TEMPLATE',
    text: 'Patient GDPR Consent Form Template',
    icon: 'fa fa-circle'
};

export const DOC_TYPES = [
    DOC_WORD, DOC_IMAGE, DOC_XRAY, DOC_SYSTEM_TEMPLATE, DOC_UPLOAD_MSWORD, DOC_UPLOAD_WORD, DOC_UPLOAD_XRAY_TIF, DOC_UPLOAD_XRAY_JPG,
    DOC_UPLOAD_XRAY_GIF, DOC_UPLOAD_SCAN_TIF, DOC_UPLOAD_SCAN_JPG, DOC_UPLOAD_SCAN_GIF, DOC_REFERRAL_TEMPLATE, DOC_PRESCRIPTION_TEMPLATE,
    DOC_REFERRAL, DOC_PRESCRIPTION, DOC_PDF, DOC_TREATMENT_PLAN, DOC_TREATMENT_PLAN_TEMPLATE, DOC_WORD_PATIENT_TEMPLATE,
    DOC_EmailAccountItem, DOC_Email, DOC_CONTACTLIST_TEMPLATE, DOC_CONTACTLIST, DOC_ANY, DOC_UPLOAD_SCAN_PDF, DOC_ORTHO_REFERRER_TEMPLATE,
    DOC_ORTHO_REFERRAL, DOC_SMS, DOC_SIGNATURE, DOC_CONSENT, DOC_PAYMENT_PLAN, DOC_PAYMENT_PLAN_TEMPLATE,
    DOC_PAYMENT_PLAN_PROPOSAL, DOC_PAYMENT_PLAN_PROPOSAL_TEMPLATE,
    DOC_APPOINTMENT_LETTER, DOC_APPOINTMENT_LETTER_TEMPLATE,
    DOC_SMS_OUTGOING, DOC_SMS_INCOMING, DOC_CONSENT, DOC_CONSENT_TEMPLATE, DOC_GDPR_CONSENT, DOC_GDPR_CONSENT_TEMPLATE
];

export const documentOrdinal = type => _.findIndex(DOC_TYPES, (entry => type === entry.name));

// consent form related

export const OUTCOME_ConsentGiven = 'ConsentGiven';
export const OUTCOME_ConsentGivenText = 'Patient Gives Consent';
export const OUTCOME_ConsentNotGiven = 'ConsentNotGiven';
export const OUTCOME_ConsentNotGivenText = 'Patient Does Not Give Consent';

// Report types

export const REP_AccRendered = {value: 'AccRendered', label: "Accounts Rendered"};
export const REP_BackupHistory = {value: 'BackupHistory', label: "Backup History"};
export const REP_BGOverdueList = {value: 'BGOverdueList', label: "Family Group Overdue List"};
export const REP_CreditNote = {value: 'CreditNote', label: "Credit Note"};
export const REP_DayList = {value: 'DayList', label: "Day List"};
export const REP_DaysInvoicesReceipts = {value: 'DaysInvoicesReceipts', label: "Days Transactions"};
export const REP_Invoice = {value: 'Invoice', label: "Invoice"};
export const REP_InvoiceDueList = {value: 'InvoiceDueList', label: "Invoices Due"};
export const REP_IssuableInvoices = {value: 'IssuableInvoices', label: "Issuable Invoices"};
export const REP_NegativePatientAccount = {value: 'NegativePatientAccount', label: "Negative Patient Accounts"};
export const REP_NonAttendence1 = {value: 'NonAttendence1', label: "Non Attendence 1"};
export const REP_OverdueList = {value: 'OverdueList', label: "Overdue List"};
export const REP_PatientAccount = {value: 'PatientAccount', label: "Patient Account"};
export const REP_PatientAdjustment = {value: 'PatientAdjustment', label: "Account Adjustement"};
export const REP_PatientAppointmentHistory = {value: 'PatientAppointmentHistory', label: "Patient Appointment History"};
export const REP_PatientTreatmentHistory = {value: 'PatientTreatmentHistory', label: "Patient Treatment History"};
export const REP_Payment = {value: 'Payment', label: "Payment"};
export const REP_PositivePatientAccount = {value: 'PositivePatientAccount', label: "Patient Accounts in Credit"};
export const REP_Recalls = {value: 'Recalls', label: "Recall"};
export const REP_RecallsDue = {value: 'RecallsDue', label: "Recalls Due"};
export const REP_Reminder = {value: 'Reminder', label: "Reminder"};
export const REP_RemindersDue = {value: 'RemindersDue', label: "Reminders Due"};
export const REP_RevenueShare = {value: 'RevenueShare', label: "Years Revenue Share"};
export const REP_ScheduledCharges = {value: 'ScheduledCharges', label: "Scheduled Charges"};
export const REP_StandardPatient = {value: 'StandardPatient', label: "Standard Patient"};
export const REP_TreatmentHistory = {value: 'TreatmentHistory', label: "Treatment History"};
export const REP_TreatmentPlan = {value: 'TreatmentPlan', label: "Treatment Plan"};
export const REP_UninvoicedTreatments = {value: 'UninvoicedTreatments', label: "Uninvoiced Treatments"};
export const REP_WorkRequired1 = {value: 'WorkRequired1', label: "WorkRequired1"};
export const REP_WorkRequired2 = {value: 'WorkRequired2', label: "WorkRequired2"};
export const REP_YearlyRevenue = {value: 'YearlyRevenue', label: "Years Revenue"};
export const REP_NonAttendence2 = {value: 'NonAttendence2', label: "Non Attendence 2"};
export const REP_WeeksInvoicesReceipts = {value: 'WeeksInvoicesReceipts', label: "Weeks Transactions"};
export const REP_Prescription = {value: 'Prescription', label: "Standard Prescription"};
export const REP_NonAttendence3 = {value: 'NonAttendence3', label: "Non Attendence 3"};
export const REP_CostCentre1 = {value: 'CostCentre1', label: "Cost Centre 1"};
export const REP_StandardRefrrerPatient = {value: 'StandardRefrrerPatient', label: "Standard Referrer / Patient"};
export const REP_NALetter1 = {value: 'NALetter1', label: "Non Attendence Letter 1"};
export const REP_NALetter2 = {value: 'NALetter2', label: "Non Attendence Letter 2"};
export const REP_NALetter3 = {value: 'NALetter3', label: "Non Attendence Letter 3"};
export const REP_DaysCash = {value: 'DaysCash', label: "Days Cash"};
export const REP_PersonalDetails = {value: 'PersonalDetails', label: "Personal Details"};
export const REP_DaysCanccelations = {value: 'DaysCanccelations', label: "Days Cancellation"};
export const REP_WeeksCanccelations = {value: 'WeeksCanccelations', label: "Weeks Cancellations"};
export const REP_PatientNote = {value: 'PatientNote', label: "Patient Note"};
export const REP_PatientNoteHistory = {value: 'PatientNoteHistory', label: "Patient Note History"};
export const REP_MedicalCondition = {value: 'MedicalCondition', label: "Patient Medical Condition"};
export const REP_MedicalConditionHistory = {
    value: 'MedicalConditionHistory',
    label: "Patient Medical Condition History"
};
export const REP_ImageView = {value: 'ImageView', label: "Image Viewer"};
export const REP_FutureAppointments = {value: 'FutureAppointments', label: "Patient Future Appointment Summary"};
export const REP_Receipt = {value: 'Receipt', label: "Receipt"};
export const REP_GroupRecalls = {value: 'GroupRecalls', label: "Group Recall"};
export const REP_JointRecalls = {value: 'JointRecalls', label: "Joint Recall"};
export const REP_FailedToAttend = {value: 'FailedToAttend', label: "Failed To Attend"};
export const REP_ContactList = {value: 'ContactList', label: "Contact List"};
export const REP_ContactListDetails = {value: 'ContactListDetails', label: "Contact List Details"};
export const REP_PatientChart = {value: 'PatientChart', label: "Patient Chart"};
export const REP_PatientPerioChart = {value: 'PatientPerioChart', label: "Patient Perio Chart"};
export const REP_RecallsSent = {value: 'RecallsSent', label: "Recalls Sent"};
export const REP_RecallsSentToday = {value: 'RecallsSentToday', label: "Recalls Sent Today"};
export const REP_RecallSuccesses = {value: 'RecallSuccesses', label: "Recall Successes"};
export const REP_IncompletedTreatments = {value: 'IncompletedTreatments', label: "Incompleted Treatments"};
export const REP_MonthlyRevenue = {value: 'MonthlyRevenue', label: "Months Revenue"};
export const REP_BGAccRendered = {value: 'BGAccRendered', label: "Family Group Accounts Rendered"};
export const REP_MonthlyCash = {value: 'MonthlyCash', label: "Months Cash"};
export const REP_YearlyCash = {value: 'YearlyCash', label: "Years Cash"};
export const REP_VoidedTransactions = {value: 'VoidedTransactions', label: "Voided Transactions"};
export const REP_TreatmentCodes = {value: 'TreatmentCodes', label: "Treatment Codes"};
export const REP_Refund = {value: 'Refund', label: "Refund"};
export const REP_AccountWriteOffs = {value: 'AccountWriteOffs', label: "Account Write Offs"};
export const REP_NewPatients = {value: 'NewPatients', label: "New Patients"};
export const REP_PotHygiene = {value: 'PotHygiene', label: "Potential Hygiene Patients"};
export const REP_ReferredPatients = {value: 'ReferredPatients', label: "Referrers Patients"};
export const REP_DayList2 = {value: 'DayList2', label: "Performer Day List"};
export const REP_OrthoPaymentPlan4 = {value: 'OrthoPaymentPlan4', label: "Orthodontic Payment Plan"};
export const REP_OrthoRefLetter = {value: 'OrthoRefLetter', label: "Orthodontic Referrer Letter"};
export const REP_PerformerUDAs = {value: 'PerformerUDAs', label: "Performer UDA/UOAs"};
export const REP_PerformerStacked = {value: 'PerformerStacked', label: "Performer Stacked Claims"};
export const REP_PatientJourney = {value: 'PatientJourney', label: "Patient Journey"};
export const REP_PerformerSubmitted = {value: 'PerformerSubmitted', label: "Performer Submitted Claims"};
export const REP_PerformerRejected = {value: 'PerformerRejected', label: "Performer Rejected Claims"};
export const REP_PerformerRemoved = {value: 'PerformerRemoved', label: "Performer Removed Claims"};
export const REP_PerformerScheduled = {value: 'PerformerScheduled', label: "Performer Scheduled Claims"};
export const REP_PatientJourneySS = {value: 'PatientJourneySS', label: "Patient Journey Snapshot"};
export const REP_ConsentForm = {value: 'ConsentForm', label: "Patient Consent Form"};
export const REP_KPITreatmentPlans = {value: 'KPITreatmentPlans', label: "Treatment Plans"};
export const REP_KPIReferrealSource = {value: 'KPIReferrealSource', label: "Referral Sources"};
export const REP_ActivePatients = {value: 'ActivePatients', label: "Active Patients"};
export const REP_TreatmentCodeUsage = {value: 'TreatmentCodeUsage', label: "Treatment Code Usage"};
export const REP_PaymentPlan = {value: 'PaymentPlan', label: "Payment Plan"};
export const REP_PatientAssessment = {value: 'PatientAssessment', label: "Patient Assessment"};
export const REP_RecallManagement = {value: 'RecallManagement', label: "Recall Management"};
export const REP_ActivePaymentPlans = {value: 'ActivePaymentPlans', label: "Active Payment Plans"};
export const REP_Referrers = {value: 'Referrers', label: "Referrers"};
export const REP_PaymentPlanProposal = {value: 'PaymentPlanProposal', label: "Payment Plan Proposal"};
export const REP_StandardAppointmentLetter = {value: 'StandardAppointmentLetter', label: "Standard Appointment Letter"};

export const REPORTS = [
    REP_AccRendered,
    REP_BackupHistory,
    REP_BGOverdueList,
    REP_CreditNote,
    REP_DayList,
    REP_DaysInvoicesReceipts,
    REP_Invoice,
    REP_InvoiceDueList,
    REP_IssuableInvoices,
    REP_NegativePatientAccount,
    REP_NonAttendence1,
    REP_OverdueList,
    REP_PatientAccount,
    REP_PatientAdjustment,
    REP_PatientAppointmentHistory,
    REP_PatientTreatmentHistory,
    REP_Payment,
    REP_PositivePatientAccount,
    REP_Recalls,
    REP_RecallsDue,
    REP_Reminder,
    REP_RemindersDue,
    REP_RevenueShare,
    REP_ScheduledCharges,
    REP_StandardPatient,
    REP_TreatmentHistory,
    REP_TreatmentPlan,
    REP_UninvoicedTreatments,
    REP_WorkRequired1,
    REP_WorkRequired2,
    REP_YearlyRevenue,
    REP_NonAttendence2,
    REP_WeeksInvoicesReceipts,
    REP_Prescription,
    REP_NonAttendence3,
    REP_CostCentre1,
    REP_StandardRefrrerPatient,
    REP_NALetter1,
    REP_NALetter2,
    REP_NALetter3,
    REP_DaysCash,
    REP_PersonalDetails,
    REP_DaysCanccelations,
    REP_WeeksCanccelations,
    REP_PatientNote,
    REP_PatientNoteHistory,
    REP_MedicalCondition,
    REP_MedicalConditionHistory,
    REP_ImageView,
    REP_FutureAppointments,
    REP_Receipt,
    REP_GroupRecalls,
    REP_JointRecalls,
    REP_FailedToAttend,
    REP_ContactList,
    REP_ContactListDetails,
    REP_PatientChart,
    REP_PatientPerioChart,
    REP_RecallsSent,
    REP_RecallsSentToday,
    REP_RecallSuccesses,
    REP_IncompletedTreatments,
    REP_MonthlyRevenue,
    REP_BGAccRendered,
    REP_MonthlyCash,
    REP_YearlyCash,
    REP_VoidedTransactions,
    REP_TreatmentCodes,
    REP_Refund,
    REP_AccountWriteOffs,
    REP_NewPatients,
    REP_PotHygiene,
    REP_ReferredPatients,
    REP_DayList2,
    REP_OrthoPaymentPlan4,
    REP_OrthoRefLetter,
    REP_PerformerUDAs,
    REP_PerformerStacked,
    REP_PatientJourney,
    REP_PerformerSubmitted,
    REP_PerformerRejected,
    REP_PerformerRemoved,
    REP_PerformerScheduled,
    REP_PatientJourneySS,
    REP_ConsentForm,
    REP_KPITreatmentPlans,
    REP_KPIReferrealSource,
    REP_ActivePatients,
    REP_TreatmentCodeUsage,
    REP_PaymentPlan,
    REP_PatientAssessment,
    REP_RecallManagement,
    REP_ActivePaymentPlans,
    REP_Referrers,
    REP_PaymentPlanProposal,
    REP_StandardAppointmentLetter,
];

export const HM_REP_WEEKLY_FTA = {
    id: 'HM_REP_WEEKLY_FTA',
    label: 'Failed to Attends',
    header: 'Weekly Failed to Attends',
    message: 'weekly failed to attends',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_XRAY_REPORT = {
    id: 'HM_REP_WEEKLY_XRAY_REPORT',
    label: 'XRay Records',
    header: 'XRay Records',
    message: 'XRay records',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_XRAY_SUMMARY = {
    id: 'HM_REP_WEEKLY_XRAY_SUMMARY',
    label: 'XRay Summary',
    header: 'Weekly XRay Summary',
    headerTotalByRating: "Totals by Rating",
    headerTotalByType: "Totals by Type",
    headerBW: "Bitewing",
    headerPA: "Periapical",
    headerOC: "Occlusal",
    headerPAN: "Panoramic",
    headerCM: "Cephalometric",
    headerCBCT: "CBCT",
    message: 'weekly XRay summary',
    icon: icons.ICON_PIE_CHART
};

export const HM_REP_WEEKLY_SEDATION_REPORT = {
    id: 'HM_REP_WEEKLY_SEDATION_REPORT',
    label: 'Sedation Records',
    header: 'Sedation Records',
    message: 'Sedation records',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_SEDATION_SUMMARY = {
    id: 'HM_REP_WEEKLY_SEDATION_SUMMARY',
    label: 'Sedation Summary',
    header: 'Weekly Sedation Summary',
    headerTotalByInvolvedAs: "Totals Involved As",
    headerTotalByASA: "Totals by ASA",
    headerTotalByTechnique: "Totals by Technique",
    headerTotalByScoring: "Totals by Scoring",
    headerTotalByOC: "Totals by Operating Conditions",
    headerTotalByRecovery: "Totals by Recovery",
    headerTotalByFeedback: "Totals by Feedback",
    message: 'weekly sedation summary',
    icon: icons.ICON_PIE_CHART
};

export const HM_REP_VOIDS = {
    id: 'HM_REP_VOIDS',
    label: 'Void Transactions',
    header: 'Voided Transactions',
    message: 'voided transactions',
    icon: icons.ICON_EDIT
};

export const HM_REP_IN_CREDIT = {
    id: 'HM_REP_IN_CREDIT',
    label: 'Accounts In Credit',
    header: 'Accounts In Credit',
    message: 'Accounts In Credit',
    icon: icons.ICON_EDIT
};

export const HM_REP_DAILY_CANCELS = {
    id: 'HM_REP_DAILY_CANCELS',
    label: 'Daily Cancellations',
    header: 'Daily Appointment Cancellations',
    message: 'daily appointment cancellations',
    icon: icons.ICON_EDIT
};

export const HM_REP_DAILY_DELETES = {
    id: 'HM_REP_DAILY_DELETES',
    label: 'Daily Deletions',
    header: 'Daily Appointment Deletions',
    message: 'daily appointment deletions',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_CANCELS = {
    id: 'HM_REP_WEEKLY_CANCELS',
    label: 'Weekly Cancellations',
    header: 'Weekly Appointment Cancellations',
    message: 'weekly appointment cancellations',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_REGISTRATIONS = {
    id: 'HM_REP_WEEKLY_REGISTRATIONS',
    label: 'Weekly Online Registrations',
    header: 'Weekly Online Registrations',
    message: 'weekly Online Registrations',
    icon: icons.ICON_EDIT
};

export const HM_REP_LAB_WORK_REQUESTED = {
    id: 'HM_REP_LAB_WORK_REQUESTED',
    label: 'Lab Work Raised',
    header: 'Laboratory Work Raised',
    message: 'laboratory work raised',
    icon: icons.ICON_LAB
};

export const HM_REP_LAB_WORK_REQUIRED = {
    id: 'HM_REP_LAB_WORK_REQUIRED',
    label: 'Lab Work Due',
    header: 'Laboratory Work Due',
    message: 'laboratory work',
    icon: icons.ICON_PICKUP
};

export const HM_REP_LAB_WORK_RECEIVED = {
    id: 'HM_REP_LAB_WORK_RECEIVED',
    label: 'Lab Work Received',
    header: 'Laboratory Work Received',
    message: 'laboratory work received',
    icon: icons.ICON_GIFT
};

export const HM_REP_LAB_WORK_APPROVAL = {
    id: 'HM_REP_LAB_WORK_APPROVAL',
    label: 'Lab Work Approval',
    header: 'Laboratory Work For Approval',
    message: 'laboratory work for approval',
    icon: icons.ICON_CERTIFICATE
};

export const HM_REP_WEEKLY_DELETES = {
    id: 'HM_REP_WEEKLY_DELETES',
    label: 'Weekly Deletions',
    header: 'Weekly Appointment Deletions',
    message: 'weekly appointment deletions',
    icon: icons.ICON_EDIT
};

export const HM_REP_UNINVOICED_CHARGES = {
    id: 'HM_REP_UNINVOICED_CHARGES',
    label: 'Un-invoiced Charges',
    header: 'Un-invoiced Charges',
    message: 'un-invoiced Charges',
    icon: icons.ICON_EDIT
};

export const HM_REP_INCOMPLETE_CHARGES = {
    id: 'HM_REP_INCOMPLETE_CHARGES',
    label: 'Incomplete Charges',
    header: 'Incomplete Charges',
    message: 'incomplete Charges',
    icon: icons.ICON_EDIT
};

export const HM_REP_WRITE_OFFS = {
    id: 'HM_REP_WRITE_OFFS',
    label: 'Account Write Offs',
    header: 'Account Write Offs For : ',
    message: 'account write offs',
    icon: icons.ICON_EDIT
};

export const HM_REP_OVERDUE_ACCOUNTS_SHOW = {
    id: 'HM_REP_OVERDUE_ACCOUNTS_SHOW',
    label: 'Show Over Due Accounts',
    icon: icons.ICON_SCREEN
};

export const HM_REP_OVERDUE_ACCOUNTS_EXCEL = {
    id: 'HM_REP_OVERDUE_ACCOUNTS_EXCEL',
    label: 'Download Over Due Accounts Excel',
    icon: icons.ICON_EXCEL
};

export const HM_REP_OVERDUE_ACCOUNTS_PRINT = {
    id: 'HM_REP_OVERDUE_ACCOUNTS_PRINT',
    label: 'Print Over Due Accounts',
    icon: icons.ICON_PRINT
};

export const HM_REP_PAYMENT_PLANS_SHOW = {
    id: 'HM_REP_PAYMENT_PLANS_SHOW',
    label: 'Show Payment Plans',
    icon: icons.ICON_SCREEN
};

export const HM_REP_PAYMENT_PLANS_PRINT = {
    id: 'HM_REP_PAYMENT_PLANS_PRINT',
    label: 'Print Payment Plans',
    icon: icons.ICON_PRINT
};

export const HM_REP_PAYMENT_PLANS_DOWNLOAD = {
    id: 'HM_REP_PAYMENT_PLANS_DOWNLOAD',
    label: 'Download Payment Plans',
    icon: icons.ICON_DOWNLOAD,
};

export const HM_REP_DAILY_CASH = {
    id: 'HM_REP_DAILY_CASH',
    label: 'Daily Cash',
    header: 'Daily Cash',
    headerRecPay: 'Receipts and Payments',
    headerRecPayTotals: 'Receipt and Payment Totals By Type',
    headerGrandTotals: 'Grand Totals',
    headerRefund: 'Refunds',
    message: 'daily cash',
    icon: icons.ICON_EDIT
};

export const HM_REP_DAILY_TRANSACTIONS = {
    id: 'HM_REP_DAILY_TRANSACTIONS',
    label: 'Daily Transactions',
    header: 'Daily Transactions',
    headerInvoice: 'Invoices',
    headerRecPay: 'Receipts and Payments',
    headerRecPayTotals: 'Receipt and Payment Totals By Type',
    headerRefund: 'Refunds',
    headerGrandTotals: 'Grand Totals',
    message: 'daily transactions',
    icon: icons.ICON_EDIT
};

export const HM_REP_MONTHLY_TRANSACTIONS = {
    id: 'HM_REP_MONTHLY_TRANSACTIONS',
    label: 'Monthly Transactions',
    header: 'Monthly Transactions',
    headerInvoice: 'Invoices',
    headerRecPay: 'Receipts and Payments',
    headerRecPayTotals: 'Receipt and Payment Totals By Type',
    headerRefund: 'Refunds',
    headerGrandTotals: 'Grand Totals',
    message: 'monthly transactions',
    icon: icons.ICON_EDIT
};

export const HM_REP_PATIENT_JOURNEY_STAGE = {
    id: 'HM_REP_PATIENT_JOURNEY_STAGE',
    label: 'Patient Journey Stage',
    header: 'Patient Journey Stage',
    message: 'Patient Journey Stage',
    icon: icons.ICON_EDIT
};

// Account Group related

export const HM_AccountGroupSave = {
    id: 'HM_AccountGroupSave',
    header: 'Account Group Creation',
    message: 'The account group(s) have been modified, save these changes?'
};

export const HM_AccountGroupSelector = {
    id: 'HM_AccountGroupSelector',
    header: 'Account Groups',
    message: ''
};

export const AG_STATUS_ACTIVE = {name: 'Active', text: 'Active', ord: 0};
export const AG_STATUS_SUSPENDED = {name: 'Suspended', text: 'Suspended', ord: 1};

export const AG_STATUS = [AG_STATUS_SUSPENDED, AG_STATUS_ACTIVE];

// Tablet related

export const HM_TABLET_REGISTRATIONS_SHOW = {
    id: 'HM_TABLET_REGISTRATIONS_SHOW',
    label: 'Show Tablet Registrations',
    icon: icons.ICON_SCREEN
};

export const HM_TABLET_REGISTRATIONS_PRINT = {
    id: 'HM_REP_PAYMENT_PLANS_PRINT',
    label: 'Print Tablet Registrations',
    icon: icons.ICON_PRINT
};

export const HM_TABLET_REGISTRATIONS_DOWNLOAD = {
    id: 'HM_REP_PAYMENT_PLANS_DOWNLOAD',
    label: 'Download Tablet Registrations',
    icon: icons.ICON_DOWNLOAD,
};

export const HM_TABLET_CONFIRM_CONSENT = {
    id: 'HM_TABLET_CONFIRM_CONSENT',
    header: 'Confirm Consent',
    message: 'Are you sure you want to save these consent form(s)',
    icon: icons.ICON_OK,
};

export const HM_TABLET_CONFIRM_PD = {
    id: 'HM_TABLET_CONFIRM_PD',
    header: 'Confirm Personal Details',
    message: 'Are you sure you want to save your personal details form',
    icon: icons.ICON_OK,
};

export const HM_TABLET_CONFIRM_MHF = {
    id: 'HM_TABLET_CONFIRM_MHF',
    header: 'Confirm Medical History',
    message: 'Are you sure you want to save this medical history form',
    icon: icons.ICON_OK,
};

export const HM_TABLET_NO_CHANGE_MHF = {
    id: 'HM_TABLET_NO_CHANGE_MHF',
    header: 'Confirm Medical History',
    message: 'Are you sure there is no change in the medical history form',
    icon: icons.ICON_OK,
};

export const HM_TABLET_CONFIRM_QUES = {
    id: 'HM_TABLET_CONFIRM_QUES',
    header: 'Confirm Questionnaire',
    message: 'Are you sure you want to save this questionnaire',
    icon: icons.ICON_OK,
};

export const HM_PORTAL_CONFIRM_GDPR_CONSENT = {
    id: 'HM_PORTAL_CONFIRM_GDPR_CONSENT',
    header: 'Confirm GDPR Consent',
    message: 'Are you sure you want to give consent to GDPR',
    icon: icons.ICON_OK,
};

//

export const reportOrdinal = report => _.findIndex(REPORTS, (entry => report === entry.value));

export const TC_USABLE = 'USABLE';
export const TC_ARCHIVED = 'ARCHIVED';

export const TC_STATUS = [TC_USABLE, TC_ARCHIVED];

export const RC_USABLE = 'USABLE';
export const RC_ARCHIVED = 'ARCHIVED';

export const REM_STATUS_OPEN = {name: 'OPEN', text: "Unsent"};
export const REM_STATUS_SENT = {name: 'SENT', text: "Sent"};
export const REM_STATUS_MISSED = {name: 'MISSED', text: "Missed"};
export const REM_STATUS_FAILEDTOSEND = {name: 'FAILEDTOSEND', text: "Failed"};
export const REM_STATUS_NOT_ELECT = {name: 'FAILEDNOTELECT', text: "Not Electronic"};
export const REM_STATUS_APPMOVING = {name: 'APPMOVING', text: "Moving"};
export const REM_STATUS_SMSNOCREDIT = {name: 'SMSNOCREDIT', text: "No SMS Credit"};
export const REM_STATUS_CLOSED = {name: 'CLOSED', text: "Closed"};

export const REM_STATUS = [REM_STATUS_OPEN, REM_STATUS_SENT, REM_STATUS_MISSED, REM_STATUS_FAILEDTOSEND, REM_STATUS_APPMOVING, REM_STATUS_SENT, REM_STATUS_CLOSED];

//    Set<Recall.State>set = EnumSet.of(State.OPEN, State.FOLLOWEDUP, State.FAILEDTOSEND, State.EXCEEDED, State.SENT);
export const REC_STATUS_FOLLOWEDUP = {name: 'FOLLOWEDUP', text: "Followed up"};
export const REC_STATUS_OPEN = {name: 'OPEN', text: "Unsent"};
export const REC_STATUS_SENT = {name: 'SENT', text: "Sent"};
export const REC_STATUS_FAILEDTOSEND = {name: 'FAILEDTOSEND', text: "Failed"};
export const REC_STATUS_EXCEEDED = {name: 'EXCEEDED', text: "Limit reached"};
export const REC_STATUS_APP_MOVING = {name: 'APP_MOVING', text: "App. Moving"};
export const REC_STATUS_APP_PLACED = {name: 'APP_PLACED', text: "App. Placed"};
export const REC_STATUS_APP_CANCELED = {name: 'APP_CANCELED', text: "App. Cancelled"};
export const REC_STATUS_CLOSED = {name: 'CLOSED', text: "closed"};
export const REC_STATUS_RESET = {name: 'RESET', text: "Reset"};

export const REC_STATUS = [REC_STATUS_FOLLOWEDUP, REC_STATUS_OPEN, REC_STATUS_SENT, REC_STATUS_FAILEDTOSEND, REC_STATUS_EXCEEDED, REC_STATUS_APP_CANCELED, REC_STATUS_APP_MOVING, REC_STATUS_APP_PLACED, REC_STATUS_CLOSED, REC_STATUS_RESET];

export const PAT_REC_CURRENT_SET = [REC_STATUS_OPEN, REC_STATUS_FOLLOWEDUP, REC_STATUS_FAILEDTOSEND, REC_STATUS_EXCEEDED, REC_STATUS_SENT];

export const RRT_REMINDER_HOMEPHONE = {name: 'REMINDER_HOMEPHONE', text: 'Home Phone', icon: 'phoneIcon16', ordinal: 0};
export const RRT_REMINDER_WORKPHONE = {name: 'REMINDER_WORKPHONE', text: 'Work Phone', icon: 'phoneIcon16', ordinal: 1};
export const RRT_REMINDER_OTHERPHONE = {
    name: 'REMINDER_OTHERPHONE',
    text: 'Other Phone',
    icon: 'phoneIcon16',
    ordinal: 2
};
export const RRT_REMINDER_MOBILE = {name: 'REMINDER_MOBILE', text: 'Mobile Phone', icon: ICON_MOBILE, ordinal: 3};
export const RRT_REMINDER_EMAIL = {name: 'REMINDER_EMAIL', text: 'Email', icon: icons.ICON_EMAIL, ordinal: 4};
export const RRT_REMINDER_SMS = {name: 'REMINDER_SMS', text: 'SMS', icon: icons.ICON_SMS, ordinal: 5};
export const RRT_REMINDER_SNAILMAIL = {name: 'REMINDER_SNAILMAIL', text: 'Letter', icon: icons.ICON_SMAIL, ordinal: 6};

export const RRT_RECALL_EMAIL = {name: 'RECALL_EMAIL', text: 'Email', icon: icons.ICON_EMAIL, ordinal: 7};
export const RRT_RECALL_SMS = {name: 'RECALL_SMS', text: 'SMS', icon: icons.ICON_SMS, ordinal: 8};
export const RRT_RECALL_SNAILMAIL = {name: 'RECALL_SNAILMAIL', text: 'Letter', icon: icons.ICON_SMAIL, ordinal: 9};
export const RRT_RECALL_HOMEPHONE = {name: 'RECALL_HOMEPHONE', text: 'Home Phone', icon: icons.ICON_PHONE, ordinal: 10};
export const RRT_RECALL_WORKPHONE = {name: 'RECALL_WORKPHONE', text: 'Work Phone', icon: icons.ICON_PHONE, ordinal: 11};
export const RRT_RECALL_OTHERPHONE = {
    name: 'RECALL_OTHERPHONE',
    text: 'Other Phone',
    icon: icons.ICON_PHONE,
    ordinal: 12
};
export const RRT_RECALL_MOBILE = {name: 'RECALL_MOBILE', text: 'Mobile Phone', icon: icons.ICON_MOBILE, ordinal: 13};
export const RRT_None = {name: 'None', text: 'None', icon: '', ordinal: 14};

export const RecallRemType = [RRT_REMINDER_HOMEPHONE, RRT_REMINDER_WORKPHONE, RRT_REMINDER_OTHERPHONE, RRT_REMINDER_MOBILE, RRT_REMINDER_EMAIL,
    RRT_REMINDER_SMS, RRT_REMINDER_SNAILMAIL, RRT_RECALL_EMAIL, RRT_RECALL_SMS, RRT_RECALL_SNAILMAIL, RRT_RECALL_HOMEPHONE, RRT_RECALL_WORKPHONE,
    RRT_RECALL_OTHERPHONE, RRT_RECALL_MOBILE, RRT_None];

export const RemType = [RRT_REMINDER_HOMEPHONE, RRT_REMINDER_WORKPHONE, RRT_REMINDER_OTHERPHONE, RRT_REMINDER_MOBILE, RRT_REMINDER_EMAIL,
    RRT_REMINDER_SMS, RRT_REMINDER_SNAILMAIL, RRT_None];

export const RecallType = [RRT_RECALL_EMAIL, RRT_RECALL_SMS, RRT_RECALL_SNAILMAIL, RRT_RECALL_HOMEPHONE, RRT_RECALL_WORKPHONE,
    RRT_RECALL_OTHERPHONE, RRT_RECALL_MOBILE, RRT_None];

export const recallRemTypeOrdinal = recallRemType => _.findIndex(RecallRemType, (entry => recallRemType === entry.name));
export const recallRemTypeText = recallRemType => RecallRemType[_.findIndex(RecallRemType, (entry => recallRemType === entry.name))].text;

export const RT_EMAIL_REMINDER = {name: 'RT_EMAIL_REMINDER', text: 'Default Individual Email Reminder', ordinal: 0};
export const RT_DEF_EMAIL_RECALL = {name: 'RT_DEF_EMAIL_RECALL', text: 'Default Email Recall', ordinal: 1};
export const RT_SMS_REMINDER = {name: 'RT_SMS_REMINDER', text: 'Default Individual SMS Reminder', ordinal: 2};
export const RT_DEF_SMS_RECALL = {name: 'RT_DEF_SMS_RECALL', text: 'Default SMS Recall', ordinal: 3};
export const RT_PRINT_REMINDER = {name: 'RT_PRINT_REMINDER', text: 'Default Print Reminder', ordinal: 4};
export const RT_DEF_PRINT_RECALL = {name: 'RT_DEF_PRINT_RECALL', text: 'Default Print Recall', ordinal: 5};
export const RT_ACC_RENDERED_30 = {name: 'RT_ACC_RENDERED_30', text: 'Account Rendered 30 Days', ordinal: 6};
export const RT_ACC_RENDERED_3160 = {name: 'RT_ACC_RENDERED_3160', text: 'Account Rendered 31-60 Days', ordinal: 7};
export const RT_ACC_RENDERED_6190 = {name: 'RT_ACC_RENDERED_6190', text: 'Account Rendered 61-90 Days', ordinal: 8};
export const RT_ACC_RENDERED_91 = {
    name: 'RT_ACC_RENDERED_91',
    text: 'Account Rendered Greater Than 90 Days',
    ordinal: 9
};
export const RT_CHANGEOFADDRESS = {name: 'RT_CHANGEOFADDRESS', text: 'Change Of Address', ordinal: 10};
export const RT_PAYMENT_COMMENT = {name: 'RT_PAYMENT_COMMENT', text: 'Payment Comment', ordinal: 11};
export const RT_ACC_RENDERED_ALL = {name: 'RT_ACC_RENDERED_ALL', text: 'Account Rendered', ordinal: 12};
export const RT_DEF_EMAIL_GROUP_RECALL = {
    name: 'RT_DEF_EMAIL_GROUP_RECALL',
    text: 'Default Group Email Recall',
    ordinal: 13
};
export const RT_DEF_SMS_GROUP_RECALL = {name: 'RT_DEF_SMS_GROUP_RECALL', text: 'Default Group SMS Recall', ordinal: 14};
export const RT_DEF_EMAIL_JOINT_RECALL = {
    name: 'RT_DEF_EMAIL_JOINT_RECALL',
    text: 'Default Joint Email Recall',
    ordinal: 15
};
export const RT_DEF_SMS_JOINT_RECALL = {name: 'RT_DEF_SMS_JOINT_RECALL', text: 'Default Joint SMS Recall', ordinal: 16};
export const RT_EMAIL_GROUP_REMINDER = {
    name: 'RT_EMAIL_GROUP_REMINDER',
    text: 'Default Group Email Reminder',
    ordinal: 17
};
export const RT_SMS_GROUP_REMINDER = {name: 'RT_SMS_GROUP_REMINDER', text: 'Default Group SMS Reminder', ordinal: 18};
export const RT_EMAIL_JOINT_REMINDER = {
    name: 'RT_EMAIL_JOINT_REMINDER',
    text: 'Default Joint Email Reminder',
    ordinal: 19
};
export const RT_SMS_JOINT_REMINDER = {name: 'RT_SMS_JOINT_REMINDER', text: 'Default Joint SMS Reminder', ordinal: 20};
export const RT_EMAIL_ACCOUNT_ITEM = {name: 'RT_EMAIL_ACCOUNT_ITEM', text: 'Email Account Item', ordinal: 21};
export const RT_EMAIL_RECALL = {name: 'RT_EMAIL_RECALL', text: 'Email Recall', ordinal: 22};
export const RT_SMS_RECALL = {name: 'RT_SMS_RECALL', text: 'SMS Recall', ordinal: 23};
export const RT_PRINT_RECALL = {name: 'RT_PRINT_RECALL', text: 'Print Recall', ordinal: 24};
export const RT_EMAIL_PATIENT_CONSENT_FORM = {
    name: 'RT_PATIENT_CONSENT_FORM',
    text: 'Email Patient Consent',
    ordinal: 25
};
export const RT_EMAIL_ORTHOREF = {name: 'RT_EMAIL_ORTHOREF', text: 'Ortho Referrer', ordinal: 26};
export const RT_EMAIL_PAT_DOC = {name: 'RT_EMAIL_PAT_DOC', text: 'Patient Document', ordinal: 27};
export const RT_EMAIL_APPOINT_DOC = {name: 'RT_EMAIL_APPOINT_DOC', text: 'Appointment Document', ordinal: 28};
export const RT_EMAIL_REGISTRATION = {
    name: 'RT_EMAIL_REGISTRATION',
    text: 'Email Registration Confirmation Code',
    ordinal: 29
};
export const RT_SMS_REGISTRATION = {
    name: 'RT_SMS_REGISTRATION',
    text: 'SMS Registration Confirmation Code',
    ordinal: 30
};
export const RT_PATIENT_CONSENT_FORM = {name: 'RT_PATIENT_CONSENT_FORM', text: 'Patient Consent', ordinal: 31};
export const RT_EMAIL_APP_CONFIRMATION = {
    name: 'RT_EMAIL_APP_CONFIRMATION',
    text: 'Email Appointment Confirmation',
    ordinal: 32
};
export const RT_SMS_APP_CONFIRMATION = {
    name: 'RT_SMS_APP_CONFIRMATION',
    text: 'SMS Appointment Confirmation',
    ordinal: 33
};
export const RT_EMAIL_APP_DEL_CONFIRMATION = {
    name: 'RT_EMAIL_APP_DEL_CONFIRMATION',
    text: 'Email Appointment Delete Confirmation',
    ordinal: 34
};
export const RT_SMS_APP_DEL_CONFIRMATION = {
    name: 'RT_SMS_APP_DEL_CONFIRMATION',
    text: 'SMS Appointment Delete Confirmation',
    ordinal: 35
};
export const RT_EMAIL_APP_MOVE_CONFIRMATION = {
    name: 'RT_EMAIL_APP_MOVE_CONFIRMATION',
    text: 'Email Appointment Move Confirmation',
    ordinal: 36
};
export const RT_SMS_APP_MOVE_CONFIRMATION = {
    name: 'RT_SMS_APP_MOVE_CONFIRMATION',
    text: 'SMS Appointment Move Confirmation',
    ordinal: 37
};
export const RT_EMAIL_RESEND_REFERENCE = {
    name: 'RT_EMAIL_RESEND_REFERENCE',
    text: 'Email Appointment Resend',
    ordinal: 38
};
export const RT_SMS_RESEND_REFERENCE = {
    name: 'RT_SMS_RESEND_REFERENCE',
    text: 'SMS Appointment Resend',
    ordinal: 39
};
export const RT_EMAIL_SEND = {
    name: 'RT_EMAIL_SEND',
    text: 'Send Email',
    ordinal: 40
};
export const RT_RECALL_COM1 = {
    name: 'RT_RECALL_COM1',
    text: 'Recall Communication 1',
    ordinal: 41
};
export const RT_RECALL_COM2 = {
    name: 'RT_RECALL_COM2',
    text: 'Recall Communication 2',
    ordinal: 42
};
export const RT_RECALL_COM3 = {
    name: 'RT_RECALL_COM3',
    text: 'Recall Communication 3',
    ordinal: 43
};
export const RT_RECALL_DEACTIVATE = {
    name: 'RT_RECALL_DEACTIVATE',
    text: 'Recall Deactivate',
    ordinal: 44
};

export const ReportTextType = [RT_EMAIL_REMINDER,
    RT_DEF_EMAIL_RECALL,
    RT_SMS_REMINDER,
    RT_DEF_SMS_RECALL,
    RT_PRINT_REMINDER,
    RT_DEF_PRINT_RECALL,
    RT_ACC_RENDERED_30,
    RT_ACC_RENDERED_3160,
    RT_ACC_RENDERED_6190,
    RT_ACC_RENDERED_91,
    RT_CHANGEOFADDRESS,
    RT_PAYMENT_COMMENT,
    RT_ACC_RENDERED_ALL,
    RT_DEF_EMAIL_GROUP_RECALL,
    RT_DEF_SMS_GROUP_RECALL,
    RT_DEF_EMAIL_JOINT_RECALL,
    RT_DEF_SMS_JOINT_RECALL,
    RT_EMAIL_GROUP_REMINDER,
    RT_SMS_GROUP_REMINDER,
    RT_EMAIL_JOINT_REMINDER,
    RT_SMS_JOINT_REMINDER,
    RT_EMAIL_ACCOUNT_ITEM,
    RT_EMAIL_RECALL,
    RT_SMS_RECALL,
    RT_PRINT_RECALL,
    RT_EMAIL_PATIENT_CONSENT_FORM,
    RT_EMAIL_ORTHOREF,
    RT_EMAIL_PAT_DOC,
    RT_EMAIL_APPOINT_DOC,
    RT_PATIENT_CONSENT_FORM,
];

export const ReportTextEmailType = [RT_EMAIL_REMINDER,
    RT_DEF_EMAIL_RECALL,
    RT_DEF_EMAIL_GROUP_RECALL,
    RT_DEF_EMAIL_JOINT_RECALL,
    RT_EMAIL_GROUP_REMINDER,
    RT_EMAIL_JOINT_REMINDER,
    RT_EMAIL_ACCOUNT_ITEM,
    RT_EMAIL_RECALL,
    RT_EMAIL_PATIENT_CONSENT_FORM,
    RT_EMAIL_ORTHOREF,
    RT_EMAIL_PAT_DOC,
    RT_EMAIL_APPOINT_DOC,
    RT_EMAIL_APP_CONFIRMATION,
    RT_EMAIL_APP_DEL_CONFIRMATION,
    RT_EMAIL_APP_MOVE_CONFIRMATION,
    RT_EMAIL_RESEND_REFERENCE,
    RT_EMAIL_SEND,
    RT_RECALL_COM1,
    RT_RECALL_COM2,
    RT_RECALL_COM3,
    RT_RECALL_DEACTIVATE,
];

export const RR_selectedItem = {
    contactMethod: null,
    appointmentType: null,
    appointmentDate: null,
    patientFirstName: '',
    patientLastName: '',
    status: null,
    homeTelephone: '',
    workTelephone: '',
    otherTelephone: '',
    mobile: '',
    email: '',
};

export const HM_REPORT_ACT_PPS_PRINT = {
    id: 'HM_REPORT_ACT_PPS_PRINT',
    label: `Print ${TextPatientPaymentPlans}`,
    icon: icons.PRINT24
};

export const HM_REPORT_ACT_PPS_SHOW = {
    id: 'HM_REPORT_ACT_PPS_SHOW',
    label: `Show ${TextPatientPaymentPlans}`,
    icon: icons.SHOW24
};

// Patient journey stage

export const PJS_USABLE = 'Useable';
export const PJS_ARCHIVED = 'Archived';

export const PJS_None = 'None';

export const PJS_SORT_Patient = 'Patient';
export const PJS_SORT_DateOfStatusChange = 'DateOfStatusChange';
export const PJS_SORT_Clinician = 'Clinician';
export const PJS_SORT_DateOfBirth = 'DateOfBirth';

export const PJS_SORTS = [PJS_SORT_Patient, PJS_SORT_DateOfStatusChange, PJS_SORT_Clinician, PJS_SORT_DateOfBirth];

// Document classifications

export const DOCLASS_USABLE = 'Useable';
export const DOCLASS_ARCHIVED = 'Archived';

// Medical condition severity

export const MEDCON_INFORM = {label: 'Information', value: 0};
export const MEDCON_WARNING = {label: 'Warning', value: 1};
export const MEDCON_HAZARD = {label: 'Hazard', value: 2};

export const MEDCON_SEVERITY = [MEDCON_INFORM, MEDCON_WARNING, MEDCON_HAZARD];

// Appointment related

export const APP_CREATED = 'CREATED';
export const APP_SUSUPENDED = 'SUSUPENDED';
export const APP_MOVING = 'MOVING';
export const APP_TPUNPLACED = 'TPUNPLACED';
export const APP_CANCELLED = 'CANCELLED';
export const APP_RECALLAPPOINTMENT = 'RECALLAPPOINTMENT';
export const APP_CANCELLED_FOLLOWUP = 'CANCELLED_FOLLOWUP';
export const APP_MOING_MULTI = 'MOING_MULTI';
export const APP_DELETE = 'DELETE';

export const APP_Status = [APP_CREATED, APP_SUSUPENDED, APP_MOVING, APP_TPUNPLACED, APP_CANCELLED, APP_RECALLAPPOINTMENT, APP_CANCELLED_FOLLOWUP, APP_MOING_MULTI];

// Date formats

export const JSON_DATE_FORMAT_FIRST = '01/DD/YYYY';
export const JSON_DATE_FORMAT = 'MM/DD/YYYY';
export const JSON_DATE_TIME_FORMAT = 'yyyy-MM-dd\'T\' HH:mm:ss';

// Cancellation Reason related

export const SystemReason = 'SystemReason';
export const UserReason = 'UserReason';

export const SystemReasonIcon = 'fas fa-clinic-medical';
export const SystemReasonText = 'Practice Reason';
export const SystemReasonText2 = 'Practice';

export const UserReasonIcon = 'fas fa-user-friends';
export const UserReasonText = 'Patient Reason';
export const UserReasonText2 = 'Patient';

export const cancellationReasonTypes = [{value: SystemReason, label: SystemReasonText}, {
    value: UserReason,
    label: UserReasonText
}];

// Diary related

export const HM_AddAppointment = {
    id: 'HM_AddAppointment',
    header: 'Select the patient for this appointment',
    message: ''
};

export const HM_UserLocked = {
    id: 'HM_UserLocked',
    header: 'Locked Clinician',
    message: 'The selected clinician is locked'
};

export const HM_AddAppointmentPatient = {
    id: 'HM_AddAppointment',
    header: 'Create the patient for this appointment',
    message: ''
};

export const HM_AddAppointmentNote = {
    id: 'HM_AddAppointmentNOte',
    header: 'Add Appointment Note',
    message: 'Create an Appointment note'
};

export const HM_AddDiaryNote = {
    id: 'HM_AddDiaryNote',
    header: 'Add Diary Note',
    message: 'Create a diary note',
    icon: 'fas fa-sticky-note',
};

export const HM_DeleteDiaryNote = {
    id: 'HM_DeleteDiaryNote',
    header: 'Delete Diary Note',
    message: 'Are you sure you want to delete this Diary Note',
};

export const HM_ClinicianSelector = {
    id: 'HM_ClinicianSelector',
    header: 'Clinicians',
    message: ''
};

export const HM_PatientSelector = {
    id: 'HM_PatientSelector',
    header: 'Patients',
    message: ''
};

export const HM_ProviderPerformers = {
    id: 'HM_ProviderPerformers',
    header: 'Performers',
    message: ''
};

export const HM_ClinicianWeekSelector = {
    id: 'HM_ClinicianWeekSelector',
    header: 'Clinicians Week',
    message: ''
};

export const HM_AddDiaryEvent = {id: 'HM_AddDiaryEvent', header: 'Add Diary Event', message: ''};
export const HM_EditDiaryEvent = {id: 'HM_EditDiaryEvent', header: 'Edit Diary Event', message: ''};

export const HM_DeleteAppointment = {
    id: 'HM_DeleteAppointment',
    header: 'Delete Appointment',
    message: 'Are you sure you want to delete this appointment?'
};

export const HM_CannotDeleteAppointment = {
    id: 'HM_CannotDeleteAppointment',
    header: 'Delete Appointment',
    message: 'The appointment is in the diary so cannot be deleted from here'
};

export const HM_CancelAppointment = {
    id: 'HM_CancelAppointment',
    header: 'Cancel Appointment',
    message: 'Are you sure you want to cancel this appointment?'
};

export const HM_DeleteAppointmentUnassignFirst = {
    id: 'DeleteAppointmentUnassignFirst',
    header: 'Delete Appointment',
    message: 'You must un-assign any treatments for this appointment first'
};
export const HM_DeleteDiaryEvent = {
    id: 'HM_DeleteDiaryEvent',
    header: 'Delete Diary Event',
    message: 'Are you sure you want to delete this diary event?'
};

export const HM_MoveAppointment = {
    id: 'HM_MoveAppointment',
    header: 'Move Appointment?',
    firstLabel: 'Move',
    secondLabel: 'Move and Notify',
    message: 'Are you sure you want to move this appointment?'
};

export const HM_MoveAppointmentToPalette = {
    id: 'HM_MoveAppointmentToPalette',
    header: 'Move Appointment?',
    firstLabel: 'Move',
    secondLabel: 'Move and Notify',
    message: 'Are you sure you want to move this appointment?'
};

export const HM_MoveDiaryEvent = {
    id: 'HM_MoveDiaryEvent',
    header: 'Move Diary Event',
    message: 'Are you sure you want to move this diary event?'
};

export const HM_ResizeAppointment = {
    id: 'HM_ResizeAppointment',
    header: 'Resize Appointment?',
    message: 'Are you sure you want to resize this appointment?'
};
export const HM_ResizeDiaryEvent = {
    id: 'HM_ResizeDiaryEvent',
    header: 'Resize Diary Event',
    message: 'Are you sure you want to resize this diary event?'
};

export const HM_AppointmentComment = {
    id: 'AppointmentComment',
    header: 'Appointment Comment',
    message: 'Edit the existing appointment comments'
};

export const HM_PRINT_MED_HISTORY = {
    id: 'PRINT_MED_HISTORY',
    header: 'Print Medical History',
    message: 'Print current medical history Form'
};

export const HM_PRINT_NOTE = {
    id: 'PRINT_NOTE',
    header: 'Print Patient Note',
    message: 'Print patient note'
};

export const HM_PRINT_QUESTIONNAIRE = {
    id: 'PRINT_QUESTIONNAIRE',
    header: 'Print Questionnaire',
    message: 'Print current medical history Form'
};

export const HM_notImplemented = {
    id: 'HM_notImplemented',
    header: 'Not Implemented',
    message: 'This feature is not yet implemented'
};

export const HM_ZERO_RECEIPT_ISSUE = {
    id: 'HM_ZERO_RECEIPT_ISSUE',
    header: 'Receipt Issue (£0.0)',
    message: 'A £0.0 value receipt has been issued'
};

export const HM_PaymentPlanChargeCodeNotSpecified = {
    id: 'HM_PaymentPlanChargeCodeNotSpecified',
    header: 'Payment Plan',
    message: 'The payment plan installment charge code must be set before accepting payment plan'
};

export const HM_PaymentPlanInstallmentsDue = {
    id: 'HM_PaymentPlanInstallmentsDue',
    header: 'Payment Plan Instalments Due',
};

export const HM_EditAssessment = {
    id: 'HM_EditAssessment',
    header: 'Edit Assessment',
    message: 'Edit Selected Assessment',
    icon: icons.ICON_EDIT,
};

export const HM_EDIT_WORK_REQUIRED = {
    id: 'HM_EDIT_WORK_REQUIRED',
    header: 'Edit Work Request',
    message: 'Edit Selected Work Request',
    icon: icons.ICON_EDIT,
};

export const HM_VIEW_WORK_REQUIRED = {
    id: 'HM_VIEW_WORK_REQUIRED',
    header: 'View Work Request',
    message: 'View Selected Work Request',
    icon: icons.ICON_SCREEN,
};

export const HM_WORK_REQUESTED_REPORT = {
    id: 'HM_WORK_REQUESTED_REPORT',
    header: 'Lab Work Raised',
    message: 'Lab Work Raised',
    icon: icons.ICON_LINE_CHART,
};

export const HM_WORK_REQUIRED_REPORT = {
    id: 'HM_WORK_REQUIRED_REPORT',
    header: 'Lab Work Due',
    message: 'Lab Work Due',
    icon: icons.ICON_LINE_CHART,
};

export const HM_WORK_RECEIVED_REPORT = {
    id: 'HM_WORK_RECEIVED_REPORT',
    header: 'Lab Work Received',
    message: 'Lab Work Received',
    icon: icons.ICON_LINE_CHART,
};

export const HM_WORK_NOTIFY_LAB = {
    id: 'HM_WORK_NOTIFY_LAB',
    header: 'Notify Lab',
    message: 'Notify Lab',
    icon: icons.ICON_PHONE,
};

export const HM_WORK_LAB_NOTIFIED = {
    id: 'HM_WORK_LAB_NOTIFIED',
    header: 'Lab Notified',
    message: 'Lab Notified',
    icon: icons.ICON_PICKUP,
};

export const HM_WORK_REQUIRED_PICKUP = {
    id: 'HM_WORK_REQUIRED_PICKUP',
    header: 'Lab Work Pickup',
    message: 'Lab Work Pickup',
    icon: icons.ICON_PICKUP,
};

export const HM_WORK_REQUESTED = {
    id: 'HM_WORK_REQUESTED',
    header: 'Lab Work For Pickup',
    message: 'Lab Work for pickup',
    icon: icons.ICON_LAB,
};

export const HM_WORK_REQUIRED_RECEIVED = {
    id: 'HM_WORK_REQUIRED_RECEIVED',
    header: 'Lab Work Returned',
    message: 'Mark Lab Work Returned',
    icon: icons.ICON_GIFT,
};

export const HM_WORK_REQUIRED_APPROVED = {
    id: 'HM_WORK_REQUIRED_APPROVED',
    header: 'Lab Work Approval',
    message: 'Mark Lab Work Approved',
    icon: icons.ICON_CERTIFICATE,
};

export const HM_WORK_REQUIRED_CANCELLED = {
    id: 'HM_WORK_REQUIRED_CANCELLED',
    header: 'Cancel Lab Work',
    message: 'Cancel lab work',
    icon: icons.ICON_DELETE,
};

export const HM_EditPerioChart = {
    id: 'HM_EditPerioChart',
    header: 'Edit Perio Chart',
    message: 'Edit Selected Perio Chart',
    icon: icons.ICON_EDIT,
};

export const HM_DeleteAssessment = {
    id: 'HM_DeleteAssessment',
    header: 'Delete Assessment',
    message: 'Are you sure you want to delete this assessment',
    icon: icons.ICON_DELETE,
};

export const HM_DeletePerioChart = {
    id: 'HM_DeletePerioChart',
    header: 'Delete Chart',
    message: 'Are you sure you want to delete this Periodontal chart',
    icon: icons.ICON_DELETE,
};

export const HM_UnscheduleProvider = {
    id: 'HM_UnscheduleProvider',
    header: 'Unschedule Provider',
    message: '',
    icon: 'fas fa-thumbtack'
};

export const HM_PrintProviderDayList = {
    id: 'HM_PrintProviderDayList',
    header: 'Print Day List',
    message: '',
    icon: icons.ICON_PRINT
};

export const HM_SearchNextEventType = {
    id: 'HM_SearchNextEventType',
    header: 'Next Event Type',
    message: '',
    icon: ICON_DIARY_SEARCH_NEXT
};

export const HM_SearchPrevEventType = {
    id: 'HM_SearchPrevEventType',
    header: 'Previous Event Type',
    message: '',
    icon: ICON_DIARY_SEARCH_PREV
};

export const HM_ConfirmReAssignSlot = {
    id: 'HM_ConfirmReAssignSlot',
    header: 'Confirm Reassign Provider Slot',
    message: 'Are you sure you want to reassign appointments in this provider slot',
    icon: icons.ICON_MOVE
};

export const HM_ReAssignSlot = {
    id: 'HM_ReAssignSlot',
    header: 'Reassign Provider Slot',
    message: 'Reassign appointments in this provider slot',
    icon: icons.ICON_MOVE
};

export const HM_PrintDayView = {
    id: 'HM_PrintDayView',
    header: 'Practice Day View',
    message: 'Print day view',
    icon: icons.ICON_PRINT
};

export const HM_PrintWeekView = {
    id: 'HM_PrintWeekView',
    header: 'Practice Week View',
    message: 'Print Week view',
    icon: icons.ICON_PRINT
};

export const HM_SendAppointmentLetter = {
    id: 'HM_SendAppointmentLetter',
    header: 'Patient Appointment Letter',
    message: 'Create a new patient appointment letter',
    icon: icons.ICON_EMAIL
};

export const HM_PreviewAppointmentLetter = {
    id: 'HM_PreviewAppointmentLetter',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: icons.ICON_SHOW
};

export const HM_QUESTIONNAIRE_UNCHANGED = {
    id: 'HM_QUESTIONNAIRE_UNCHANGED',
    header: 'Complete Questionnaire',
    message: 'Have any of the patients answers changed since filling in the questionnaire',
    icon: icons.ICON_SHOW
};

export const HM_QUESTIONNAIRE_UNCHANGED_PRINT = {
    id: 'HM_QUESTIONNAIRE_UNCHANGED_PRINT',
    header: 'Print Questionnaire',
    message: 'Do you want to print the unchanged questionnaire',
    icon: icons.ICON_SHOW
};

export const HM_SendPatientDocument = {
    id: 'HM_SendPatientDocument',
    header: 'Patient Document',
    message: 'Send a patient document',
    icon: icons.ICON_EMAIL
};

export const HM_PreviewPatientDocument = {
    id: 'HM_PreviewPatientDocument',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: icons.ICON_SHOW
};

export const HM_PreviewAccountItem = {
    id: 'HM_PreviewAccountItem',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: icons.ICON_SHOW
};

export const HM_SendPatientXray = {
    id: 'HM_SendPatientXray',
    header: 'Patient XRay Document',
    message: 'Send a patient XRay document',
    icon: icons.ICON_EMAIL
};

export const HM_PreviewPatientXray = {
    id: 'HM_PreviewPatientXray',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: icons.ICON_SHOW
};

export const HM_SendPatientPrescription = {
    id: 'HM_SendPatientPrescription',
    header: 'Patient Prescription',
    message: 'Send a patient prescription',
    icon: icons.ICON_EMAIL
};

export const HM_PreviewPatientPrescription = {
    id: 'HM_PreviewPatientPrescription',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: icons.ICON_SHOW
};

export const HM_SendPatientReferral = {
    id: 'HM_SendPatientReferral',
    header: 'Patient Referrer Document',
    message: 'Send a patient referrer document',
    icon: icons.ICON_EMAIL
};

export const HM_PreviewPatientReferral = {
    id: 'HM_PreviewPatientReferral',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: icons.ICON_SHOW
};

export const HM_SendPatientTreatmentPlan = {
    id: 'HM_SendPatientTreatmentPlan',
    header: 'Patient Treatment Plan',
    message: 'Send a patient treatment plan',
    icon: icons.ICON_EMAIL
};

export const HM_PreviewPatientTreatmentPlan = {
    id: 'HM_PreviewPatientTreatmentPlan',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: icons.ICON_SHOW
};

export const HM_SendPatientPaymentPlan = {
    id: 'HM_SendPatientPaymentPlan',
    header: 'Patient Payment Plan',
    message: 'Send a patient payment plan',
    icon: icons.ICON_EMAIL
};

export const HM_PatientPaymentUpload = {
    id: 'HM_PatientPaymentUpload',
    header: 'Patient Payment Upload',
    message: 'Choose payment upload file',
    icon: icons.ICON_UPLOAD
};

export const HM_PaymentUploadFile = {
    id: 'HM_PaymentUploadFile',
    header: 'Payment Upload File',
    message: 'Choose payment upload file',
    icon: icons.ICON_UPLOAD
};

export const HM_PaymentFilePassword = {
    id: 'HM_PaymentFilePassword',
    header: 'Update Password',
    message: 'Update the spreadsheet password',
    icon: icons.ICON_LOCK
};

export const HM_ConfirmCreatePayments = {
    id: 'HM_ConfirmCreatePayments',
    header: 'Create Patient Payments',
    message: 'Are you sure you want to create these patient payments',
    icon: icons.ICON_CONFIRM
};

export const HM_PreviewPatientPaymentPlan = {
    id: 'HM_PreviewPatientPaymentPlan',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: icons.ICON_SHOW
};

export const HM_SendPatientConsent = {
    id: 'HM_SendPatientConsent',
    header: 'Patient Consent Document',
    message: 'Send a patient consent document',
    icon: icons.ICON_EMAIL
};

export const HM_PreviewPatientConsent = {
    id: 'HM_PreviewPatientConsent',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: icons.ICON_SHOW
};

export const HM_SEND_SMS = {
    id: 'HM_SEND_SMS',
    header: CM_APPOINTMENT_SEND_SMS,
    message: 'Send SMS to Patient',
    icon: 'fa sms16'
};

export const HM_SMS_CREDIT_LIMIT = {
    id: 'HM_SMS_CREDIT_LIMIT',
    header: 'SMS Balance',
    message: 'SMS Balance',
    icon: 'fa sms16'
};

export const HM_SEND_EMAIL = {
    id: 'HM_SEND_EMAIL',
    header: CM_APPOINTMENT_SEND_EMAIL,
    message: 'Send Email to Patient',
    icon: icons.ICON_EMAIL
};

export const HM_SEND_TASK_EMAIL = {
    id: 'HM_SEND_TASK_EMAIL',
    header: CM_APPOINTMENT_SEND_EMAIL,
    message: 'Send Task Email to Practice',
    icon: icons.ICON_EMAIL
};

export const HM_BOOK_TRAINING = {
    id: 'HM_BOOK_TRAINING',
    header: 'Book Training',
    message: 'Book Training',
    icon: 'fa fa-graduation-cap'
};

export const HM_SEND_SMS_NO_MOBILE_NUMBER = {
    id: 'HM_SEND_SMS_NO_MOBILE_NUMBER',
    header: 'No Mobile Number',
    message: 'Patient doesn\'t have a mobile number',
    icon: 'fa sms16'
};

export const HM_SEND_EMAIL_NO_EMAIL = {
    id: 'HM_SEND_EMAIL_NO_EMAIL',
    header: 'No Email Address',
    message: 'Patient doesn\'t have an email address',
    icon: icons.ICON_EMAIL
};

export const HM_ISSUE_PAYMENT_PLAN_INVOICES = {
    id: 'HM_ISSUE_PAYMENT_PLAN_INVOICES',
    label: 'Payment Plan Invoice Issuing',
    header: 'Payment Planning',
    message: 'Are you sure you want to issue the payment plan installment invoices?',
    icon: icons.ICON_DELETE,
};

export const HM_RESEND_PORTAL_REFERENCE = {
    id: 'HM_RESEND_PORTAL_REFERENCE',
    label: 'Resend Portal Ref',
    header: 'Resend Portal Ref',
    message: 'Are you sure you want to resend this patients portal reference?',
    icon: icons.ICON_RESEND,
};

// System & patient template related

export const HM_DeleteTemplateSystem = {
    id: 'DeleteTemplateSystem',
    label: 'Delete Template',
    header: 'Delete System Template',
    message: 'Are you sure you want to delete this system template?',
    icon: icons.ICON_DELETE,
};

export const HM_DeleteTemplatePatient = {
    id: 'DeleteTemplatePatient',
    label: 'Delete Template',
    header: 'Delete Patient Template',
    message: 'Are you sure you want to delete this patient template?',
    icon: icons.ICON_DELETE,
};

export const HM_CreateTemplateSystem = {
    id: 'CreateTemplateSystem',
    label: 'Add Template',
    header: 'Create System Document Template',
    message: 'Are you sure you want to create a patient template?'
};

export const HM_CreateTemplatePatient = {
    id: 'CreateTemplatePatient',
    label: 'Add Template',
    header: 'Create Patient Document Template',
    message: 'Are you sure you want to create a patient template?'
};

export const HM_EditTemplateSystem = {
    id: 'EditTemplateSystem',
    label: 'Edit Template',
    header: 'Edit System Document Template',
    message: 'Are you sure you want to edit this system template?',
    icon: icons.ICON_EDIT,
};

export const HM_EditTemplatePatient = {
    id: 'EditTemplatePatient',
    label: 'Edit Template',
    header: 'Edit Patient Document Template',
    message: 'Are you sure you want to edit this patient template?',
    icon: icons.ICON_EDIT,
};

export const HM_CopyTemplateSystem = {
    id: 'CopyTemplateSystem',
    label: 'Copy Template',
    header: 'Copy System Document Template',
    message: 'Are you sure you want to copy this system template?',
    icon: icons.ICON_COPY,
};

export const HM_CopyTemplatePatient = {
    id: 'CopyTemplatePatient',
    label: 'Copy Template',
    header: 'Copy Patient Document Template',
    message: 'Are you sure you want to copy this patient template?',
    icon: icons.ICON_COPY,
};

// Ortho and Patient assessment related

export const HM_SavePatientAssessment = {
    id: 'SavePatientAssessment',
    header: 'Save Patient Assessment',
    message: 'Are you sure you want to save this assessment?'
};

export const HM_AddPatientAssessment = {
    id: 'AddPatientAssessment',
    header: 'Add Patient Assessment',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_SaveOrthoAssessment = {
    id: 'SaveOrthoAssessment',
    header: 'Save Ortho Assessment',
    message: 'Are you sure you want to save this assessment?'
};

export const HM_AddOrthoAssessment = {
    id: 'AddOrthoAssessment',
    header: 'Add Ortho Assessment',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_AddPerioChart = {
    id: 'AddPerioChart',
    header: 'Add Periodontal Chart',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_PATIENT_SALES = {
    id: 'HM_PATIENT_SALES',
    header: 'Patient Sales, Credit and Cash Sales',
    message: 'Patient sales, issue a patient invoice or patient receipt',
    icon: 'fa invoice24'
};

export const HM_REMOVE_SALE_ITEM = {
    id: 'HM_REMOVE_SALE_ITEM',
    label: 'Remove sales item',
    icon: 'fa fa-minus'
};

// Treatment Plan / Appointment related

export const HM_AddTreatment = {
    id: 'AddTreatment',
    label: 'Add Treatment',
    header: 'Add New Treatment',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditTreatment = {
    id: 'EditTreatment',
    label: 'Edit Treatment',
    header: 'Edit Treatment',
    message: '',
    icon: 'fa fa-edit'
};
export const HM_DeleteTreatment = {
    id: 'DeleteTreatment',
    header: 'Delete Treatment',
    message: 'Are you sure you want to delete this treatment?',
    icon: 'fas fa-times'
};

export const HM_UnAssignTreatment = {
    id: 'UnAssignTreatment',
    header: 'Delete Treatment',
    message: 'Un-assign this treatment before deleting'
};

export const HM_CreateTPDocument = {
    id: 'HM_CreateTPDocument',
    header: 'Patient Treatment Plan',
    message: 'Select the treatment plan template to use for creation'
};

// Housekeeping related

export const HM_AddTitle = {
    id: 'AddTitle',
    label: 'Add Title',
    header: 'Create New Title',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditTitle = {
    id: 'EditTitle',
    label: 'Edit Title',
    header: 'Edit Title',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteTitle = {
    id: 'DeleteTitle',
    label: 'Delete Title',
    header: 'Delete Title',
    message: 'Are you sure you want to delete this title?',
    icon: icons.ICON_DELETE
};

export const HM_AddTCode = {
    id: 'AddCode',
    label: 'Add Treatment Code',
    header: 'Create New Treatment Code',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditTCode = {
    id: 'EditCode',
    label: 'Edit Treatment Code',
    header: 'Edit Treatment Code',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeleteTCode = {
    id: 'DeleteCode',
    label: 'Delete Treatment Code',
    header: 'Delete Treatment Code',
    message: 'Are you sure you want to delete this treatment code?',
    icon: icons.ICON_DELETE
};

export const HM_AddCC = {
    id: 'AddCC',
    label: 'Add Cost Center',
    header: 'Create New Cost Center',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditCC = {
    id: 'EditCC',
    label: 'Edit Cost Center',
    header: 'Edit Cost Center',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteCC = {
    id: 'DeleteCC',
    label: 'Delete Cost Center',
    header: 'Delete Cost Center',
    message: 'Are you sure you want to delete this cost center?',
    icon: icons.ICON_DELETE
};

export const HM_AddJStages = {
    id: 'AddJStages',
    label: 'Add Journey Stage',
    header: 'Create New Journey Stage',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditJStages = {
    id: 'EditJStages',
    label: 'Edit Journey Stage',
    header: 'Edit Journey Stage',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteJStages = {
    id: 'DeleteJStages',
    label: 'Delete Journey Stage',
    header: 'Delete Journey Stage',
    message: 'Are you sure you want to delete this journey stage?',
    icon: icons.ICON_DELETE
};

export const HM_AddDocClassification = {
    id: 'AddDocClassification',
    label: 'Add Document Classification',
    header: 'Create New Document Classification',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditDocClassification = {
    id: 'EditDocClassification',
    label: 'Edit Document Classification',
    header: 'Edit Document Classification',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteDocClassification = {
    id: 'DeleteDocClassification',
    label: 'Delete Document Classification',
    header: 'Delete Document Classification',
    message: 'Are you sure you want to delete this classification?',
    icon: icons.ICON_DELETE
};

export const HM_AddMedication = {
    id: 'AddMedication',
    label: 'Add Medication',
    header: 'Create New Medication',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditMedication = {
    id: 'EditMedication',
    label: 'Edit Medication',
    header: 'Edit Medication',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteMedication = {
    id: 'DeleteMedication',
    label: 'Delete Medication',
    header: 'Delete Medication',
    message: 'Are you sure you want to delete this medication?',
    icon: icons.ICON_DELETE
};

export const HM_AddFav = {
    id: 'AddFav',
    label: 'Add Treatment Favourite',
    header: 'Create New Treatment Favourite',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditFav = {
    id: 'EditFav',
    label: 'Edit Treatment Favourite',
    header: 'Edit Treatment Favourite',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteFav = {
    id: 'DeleteFav',
    label: 'Delete Treatment Favourite',
    header: 'Delete Treatment Favourite',
    message: 'Are you sure you want to delete this treatment favourite?',
    icon: icons.ICON_DELETE
};

export const HM_AddBCFav = {
    id: 'AddBCFav',
    label: 'Add Base Chart Favourite',
    header: 'Create New Base Chart Favourite',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditBCFav = {
    id: 'EditFav',
    label: 'Edit Base Chart Favourite',
    header: 'Edit Base Chart Favourite',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteBCFav = {
    id: 'DeleteBCFav',
    label: 'Delete Base Chart Favourite',
    header: 'Delete Base Chart Favourite',
    message: 'Are you sure you want to delete this base chart favourite?',
    icon: icons.ICON_DELETE
};

export const HM_AddADT = {
    id: 'AddADT',
    label: 'Add Appointment Type',
    header: 'Create New Appointment Type',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditADT = {
    id: 'EditADT',
    label: 'Edit Appointment Type',
    header: 'Edit Appointment Type',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeleteADT = {
    id: 'DeleteADT',
    label: 'Delete Appointment Type',
    header: 'Delete Appointment Type',
    message: 'Are you sure you want to delete this appointment type?',
    icon: icons.ICON_DELETE
};

export const HM_AddDET = {
    id: 'AddDET',
    label: 'Add Diary Event Type',
    header: 'Create New Diary Event Type',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditDET = {
    id: 'EditDET',
    label: 'Edit Diary Event Type',
    header: 'Edit Diary Event Type',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeleteDET = {
    id: 'DeleteDET',
    label: 'Delete Diary Event Type',
    header: 'Delete Diary Event Type',
    message: 'Are you sure you want to delete this event type?',
    icon: icons.ICON_DELETE
};

export const HM_AddRecType = {
    id: 'AddRecType',
    label: 'Add Recall Type',
    header: 'Create New Recall Type',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditRecType = {
    id: 'EditRecType',
    label: 'Edit Recall Type',
    header: 'Edit Recall Type',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeleteRecType = {
    id: 'DeleteRecType',
    label: 'Delete Recall Type',
    header: 'Delete Recall Type',
    message: 'Are you sure you want to delete this recall type?',
    icon: icons.ICON_DELETE
};

export const HM_AddTemplateNote = {
    id: 'AddTemplateNote',
    label: 'Add Template Note',
    header: 'Add Template Note',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditTemplateNote = {
    id: 'EditTemplateNote',
    label: 'Edit Template Note',
    header: 'Edit Template Note',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_CopyTemplateNote = {
    id: 'CopyTemplateNote',
    label: 'Copy Template Note',
    header: 'Copy Template Note',
    message: '',
    icon: icons.ICON_COPY
};

export const HM_DeleteTemplateNote = {
    id: 'DeleteTemplateNote',
    label: 'Delete Template Note',
    header: 'Delete Template Note',
    message: 'Are you sure you want to delete this template note?',
    icon: icons.ICON_DELETE
};

export const HM_PrintRecall = {
    id: 'PrintRecall',
    label: 'Print Recall',
    header: 'Print Recall',
    message: '',
    icon: icons.ICON_PRINT
};

export const HM_SendRecall = {
    id: 'SendRecall',
    label: 'Send Email / SMS Recall',
    header: 'Send Email / SMS Recall',
    message: '',
    icon: icons.ICON_SEND
};

export const HM_EditRecall = {
    id: 'EditRecall',
    label: 'Edit Recall',
    header: 'Edit Recall',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_CloseRecall = {
    id: 'CloseRecall',
    label: 'Close Recall',
    header: 'Close Recall',
    message: '',
    icon: icons.ICON_CLOSE
};

export const HM_SendReminder = {
    id: 'SendRecall',
    label: 'Send Email / SMS Reminder',
    header: 'Send Email / SMS Reminder',
    message: '',
    icon: icons.ICON_SEND
};

export const HM_CloseReminder = {
    id: 'HM_CloseReminder',
    label: 'Close Reminder',
    header: 'Close Reminder',
    message: '',
    icon: icons.ICON_CLOSE
};

export const HM_AddPatientMedConHistory = {
    id: 'HM_AddPatientMedConHistory',
    label: 'Add Patient Medical History',
    header: 'Add Patient Medical History',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_AddMedCon = {
    id: 'HM_AddMedCon',
    label: 'Add Medical Condition',
    header: 'Create New Medical Condition',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditMedCon = {
    id: 'HM_EditMedCon',
    label: 'Edit Medical Condition',
    header: 'Edit Medical Condition',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeleteMedCon = {
    id: 'HM_DeleteMedCon',
    label: 'Delete Medical Condition',
    header: 'Delete Medical Condition',
    message: 'Are you sure you want to delete this medical condition?',
    icon: icons.ICON_DELETE
};

export const HM_AddQuestion = {
    id: 'HM_AddQuestion',
    label: 'Add Question',
    header: 'Create Question',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditQuestion = {
    id: 'HM_EditQuestion',
    label: 'Edit Question',
    header: 'Edit Question',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeleteQuestion = {
    id: 'HM_DeleteQuestion',
    label: 'Delete Question',
    header: 'Delete Question',
    message: 'Are you sure you want to delete this question?',
    icon: icons.ICON_DELETE
};

export const HM_AddCRS = {
    id: 'AddCRS',
    label: 'Add Cancellation Reason',
    header: 'Create Cancellation Reason',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditCRS = {
    id: 'EditCRS',
    label: 'Edit Cancellation Reason',
    header: 'Edit Cancellation Reason',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeleteCRS = {
    id: 'DeleteCRS',
    label: 'Delete Cancellation Reason',
    header: 'Delete Cancellation Reason',
    message: 'Are you sure you want to delete this cancellation reason?',
    icon: icons.ICON_DELETE
};

export const HM_AddOCCS = {
    id: 'AddOCCS',
    label: 'Add Occupation',
    header: 'Create Occupation',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditOCCS = {
    id: 'EditOCCS',
    label: 'Edit Occupation',
    header: 'Edit Occupation',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeleteOCCS = {
    id: 'DeleteOCCS',
    label: 'Delete Occupation',
    header: 'Delete Occupation',
    message: 'Are you sure you want to delete this occupation?',
    icon: icons.ICON_DELETE
};

export const HM_AddPCF = {
    id: 'AddPCF',
    label: 'Add Consent Form',
    header: 'Add Consent Form',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditPCF = {
    id: 'EditPCF',
    label: 'Edit Consent Form',
    header: 'Edit Consent Form',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeletePCF = {
    id: 'DeletePCF',
    label: 'Delete Consent Form',
    header: 'Delete Consent Form',
    message: 'Are you sure you want to delete this consent form?',
    icon: icons.ICON_DELETE
};

export const HM_EditAssCats = {
    id: 'EditAssCats',
    label: 'Edit Category',
    header: 'Edit Category',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_AddAssCatItem = {
    id: 'AddAssCatItem',
    label: 'Add Category Item',
    header: 'Create Category Item',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditAssCatItem = {
    id: 'EditAssCatItem',
    label: 'Edit Category Item',
    header: 'Edit Category Item',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteAssCatItem = {
    id: 'DeleteAssCatItem',
    label: 'Delete Category Item',
    header: 'Delete Category Item',
    message: 'Are you sure you want to delete this category item?',
    icon: icons.ICON_DELETE
};

// Messenger related

export const HM_AddMessageGroup = {
    id: 'HM_AddMessageGroup',
    label: 'Add Message Group',
    header: 'Message Group',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_AddPatientMH = {
    id: 'AddPatientMH',
    label: 'Add Medical History',
    header: 'Add Medical History',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditPatientMH = {
    id: 'EditPatientMH',
    label: 'Edit Medical History',
    header: 'Edit Medical History',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeletePatientMH = {
    id: 'DeletePatientMH',
    label: 'Delete Medical History',
    header: 'Delete Medical History',
    message: 'Are you sure you want to delete this patient medical history?',
    icon: icons.ICON_DELETE
};
export const HM_ShowPatientMH = {
    id: 'ShowPatientMH',
    label: 'Show Medical History',
    header: 'Show Medical History',
    message: '',
    icon: icons.ICON_SHOW
};
export const HM_PrintPatientMH = {
    id: 'PrintPatientMH',
    label: 'Print Medical History',
    header: 'Print Medical History',
    message: '',
    icon: icons.ICON_PRINT
};
export const HM_PrintPatientNote = {
    id: 'PrintPatientNote',
    label: 'Print Note',
    header: 'Print Note',
    message: '',
    icon: icons.ICON_PRINT
};

// Questionnaire related

export const HM_AddPatientQuestionnaire = {
    id: 'AddPatientQuestionnaire',
    label: 'Add Questionnaire',
    header: 'Add Questionnaire',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditPatientQuestionnaire = {
    id: 'EditPatientQuestionnaire',
    label: 'Edit Questionnaire',
    header: 'Edit Questionnaire',
    message: '',
    icon: icons.ICON_EDIT
};
export const HM_DeletePatientQuestionnaire = {
    id: 'DeletePatientQuestionnaire',
    label: 'Remove Questionnaire',
    header: 'Remove Questionnaire',
    message: '',
    icon: icons.ICON_DELETE
};
export const HM_ShowPatientQuestionnaire = {
    id: 'ShowPatientQuestionnaire',
    label: 'Show Questionnaire',
    header: 'Show Questionnaire',
    message: '',
    icon: icons.ICON_SHOW
};
export const HM_PrintPatientQuestionnaire = {
    id: 'PrintPatientQuestionnaire',
    label: 'Print Questionnaire',
    header: 'Print Questionnaire',
    message: '',
    icon: icons.ICON_PRINT
};

// user related

export const HM_AccountGroups = {
    id: 'HM_AccountGroups',
    header: 'Account Group Management',
    groups: 'Account Groups',
    details: 'Group details',
    members: 'Group Members',
    possible: 'Possible account group members',
    actual: 'Actual account group members',
};

export const HM_AddAccountGroup = {
    id: 'HM_AddAccountGroup',
    label: 'Add Account Group',
    header: 'Create New Account Group',
    message: '',
    icon: icons.ICON_PLUS
};

export const HM_EditAccountGroup = {
    id: 'HM_EditAccountGroup',
    label: 'Edit Account Group',
    header: 'Edit Account Group',
    message: '',
    icon: icons.ICON_EDIT
};

export const ME_ADD_USER = {
    id: SM_ADD_USER.id,
    label: SM_ADD_USER.label,
    impClass: AddUser,
    menuIcon: SM_ADD_USER.icon,
    tabIcon: SM_ADD_USER.tabIcon,
    text: SM_ADD_USER.label,
    prefSection: null,
    controlledBy: Capabilities.AID_AddUser,
};

// Account Section Related

export const REPORT_SHOW = 'SHOW';
export const REPORT_PRINT = 'PRINT';

export const HM_ShowAccountItem = {id: 'ShowAccountItem', label: '', header: '', message: '', icon: icons.ICON_SHOW};
export const HM_PrintAccountItem = {id: 'PrintAccountItem', label: '', header: '', message: '', icon: icons.ICON_PRINT};

export const HM_VoidItem = {id: 'VoidItem', label: '', header: '', message: '', icon: ''};

export const HM_ShowInvoice = {
    id: 'ShowInvoice',
    label: 'Show Invoice',
    header: '',
    message: '',
    icon: icons.ICON_SHOW
};
export const HM_PrintInvoice = {
    id: 'PrintInvoice',
    label: 'Print Invoice',
    header: '',
    message: '',
    icon: icons.ICON_PRINT
};
export const HM_EmailInvoice = {
    id: 'EmailInvoice',
    label: 'Email Invoice',
    header: '',
    message: '',
    icon: icons.ICON_EMAIL
};
export const HM_VoidInvoice = {
    id: 'VoidInvoice',
    label: 'Void Invoice',
    header: '',
    message: '',
    icon: icons.ICON_CLOSE
};

export const HM_ShowReceipt = {
    id: 'ShowReceipt',
    label: 'Show Receipt',
    header: '',
    message: '',
    icon: icons.ICON_SHOW
};
export const HM_PrintReceipt = {
    id: 'PrintReceipt',
    label: 'Print Receipt',
    header: '',
    message: '',
    icon: icons.ICON_PRINT
};
export const HM_EmailReceipt = {
    id: 'EmailReceipt',
    label: 'Email Receipt',
    header: '',
    message: '',
    icon: icons.ICON_EMAIL
};
export const HM_VoidReceipt = {
    id: 'VoidReceipt',
    label: 'Void Receipt',
    header: '',
    message: '',
    icon: icons.ICON_CLOSE
};

export const HM_ShowPayment = {
    id: 'ShowPayment',
    label: 'Show Payment',
    header: '',
    message: '',
    icon: icons.ICON_SHOW
};
export const HM_PrintPayment = {
    id: 'PrintPayment',
    label: 'Print Payment',
    header: '',
    message: '',
    icon: icons.ICON_PRINT
};
export const HM_EmailPayment = {
    id: 'EmailPayment',
    label: 'Email Payment',
    header: '',
    message: '',
    icon: icons.ICON_EMAIL
};

export const HM_VoidPayment = {
    id: 'VoidPayment',
    label: 'Void Payment',
    header: '',
    message: '',
    icon: icons.ICON_CLOSE
};

export const HM_ShowCreditNote = {
    id: 'ShowCreditNote',
    label: 'Show Credit Note',
    header: '',
    message: '',
    icon: icons.ICON_SHOW
};
export const HM_PrintCreditNote = {
    id: 'PrintCreditNote',
    label: 'Print Credit Note',
    header: '',
    message: '',
    icon: icons.ICON_PRINT
};
export const HM_EmailCreditNote = {
    id: 'EmailCreditNote',
    label: 'Email Credit Note',
    header: '',
    message: '',
    icon: icons.ICON_EMAIL
};
export const HM_VoidCreditNote = {
    id: 'VoidCreditNote',
    label: 'Void Credit Note',
    header: '',
    message: 'Are you sure you want to void this credit note',
    icon: icons.ICON_CLOSE
};

export const HM_EmailAccountItem = {
    id: 'HM_EmailAccountItem',
    label: 'Email Account Item',
    header: '',
    message: '',
    icon: icons.ICON_EMAIL
};

export const HM_ShowRefund = {id: 'ShowRefund', label: 'Show Refund', header: '', message: '', icon: icons.ICON_SHOW};
export const HM_PrintRefund = {
    id: 'PrintRefund',
    label: 'Print Refund',
    header: '',
    message: '',
    icon: icons.ICON_PRINT
};
export const HM_EmailRefund = {
    id: 'EmailRefund',
    label: 'Email Refund',
    header: '',
    message: '',
    icon: icons.ICON_EMAIL
};
export const HM_VoidRefund = {
    id: 'VoidRefund',
    label: 'Void Refund',
    header: '',
    message: 'Are you sure you want to void this refund',
    icon: icons.ICON_CLOSE
};

export const HM_ShowAdjustment = {
    id: 'ShowAdjustment',
    label: 'Show Adjustment',
    header: '',
    message: '',
    icon: icons.ICON_SHOW
};
export const HM_PrintAdjustment = {
    id: 'PrintAdjustment',
    label: 'Print Adjustment',
    header: '',
    message: '',
    icon: icons.ICON_PRINT
};
export const HM_EmailAdjustment = {id: 'EmailAdjustment', label: 'Email Adjustment', header: '', message: '', icon: ''};
export const HM_VoidAdjustment = {
    id: 'VoidAdjustment',
    label: 'Void Adjustment',
    header: '',
    message: 'Are you sure you want to void this adjustment',
    icon: icons.ICON_CLOSE
};

// Gender related

export const GenderItems = [
    {label: 'Male', value: 'Male'},
    {label: 'Female', value: 'Female'},
    {label: 'Ask', value: 'Ask'},
];

// Toolbar related

export const TB_SAVE = {id: 'TB_SAVE', text: 'Save', icon: ''};
export const TB_EXIT = {id: 'TB_EXIT', text: 'Exit', icon: 'fa fa-times'};
export const TB_SAVE_AND_EXIT = {id: 'TB_SAVE_AND_EXIT', text: 'Save & Exit', icon: 'fa fa-times'};
export const TB_SCROLL_TO_END = {id: 'TB_SCROLL_T_END', text: 'Scroll to End', icon: 'fas fa-arrow-down'};

export const TB_CREATE_PAYMENTS = {
    id: 'TB_CREATE_PAYMENTS',
    text: 'Create Patient Payments',
    icon: icons.ICON_SAVE_ENABLED,
    iconDisabled: icons.ICON_SAVE_DISABLED
};
export const TB_PAYMENTS_CLEAR_ALL = {id: 'TB_PAYMENTS_CLEAR_ALL', text: 'Clear All Approvals', icon: icons.ICON_MINUS};
export const TB_PAYMENTS_SET_ALL = {id: 'TB_CREATE_PAYMENTS', text: 'Set All Approvals', icon: icons.ICON_PLUS};
export const TB_PAYMENTS_PASSWORD = {
    id: 'TB_PAYMENTS_PASSWORD',
    text: 'Update spreadsheet password',
    icon: icons.ICON_LOCK
};
export const TB_CLEAR_PAYMENTS = {id: 'TB_CREATE_PAYMENTS', text: 'Create Patient Payments', icon: icons.ICON_RESET};

// Update targets

export const UP_PATIENT = "PATIENT";
export const UP_PATIENTNOTES = "PATIENTNOTES";
export const UP_PATIENTCONDITIONS = "PATIENTCONDITIONS";
export const UP_PATIENTBALANCE = "PATIENTBALANCE";
export const UP_PATIENTPENDINGBALANCE = "UP_PATIENTPENDINGBALANCE";

export const UP_APPOINTMENT = "APPOINTMENT";
export const UP_USER = "USER";
export const UP_PAYMENT = "PAYMENT";
export const UP_BILLING = "BILLING";
export const UP_CNISSUE = "CNISSUE";
export const UP_REFUND_ISSUE = "REFUND_ISSUE";
export const UP_ACCADJUSTMENT = "ACCADJUSTMENT";
export const UP_TREATMENT = "TREATMENT";
export const UP_TITLES = "TITLES";
export const UP_OCCUPATIONS = "OCCUPATIONS";
export const UP_ADD_DIARYEVENT = "ADD_DIARYEVENT";
export const UP_DEL_DIARYEVENT = "DEL_DIARYEVENT";
export const UP_DIARYEVENT_ALREADY_BOOKED = "DIARYEVENT_ALREADY_BOOKED";
export const UP_ADD_DIARYEVENTTYPE = "ADD_DIARYEVENTTYPE";
export const UP_DEL_DIARYEVENTTYPE = "DEL_DIARYEVENTTYPE";
export const UP_DIARYEVENT = "UP_DIARYEVENT";
export const UP_DIARYEVENTTYPE = "UP_DIARYEVENTTYPE";
export const UP_COUNTRY_CODES = "COUNTRY_CODES";
export const UP_SPECIALISIMS = "SPECIALISIMS";
export const UP_RECALL = "RECALL";
export const UP_RECALL_FOLLOWUP = "RECALL_FOLLOWUP";
export const UP_RECALL_CLOSE = "RECALL_CLOSE";
export const UP_REMRECALL = "REMRECALL";
export const UP_MASTER_CONFIGURATION = "MASTER_CONFIGURATION";
export const UP_APPOINTMENTNOTES = "APPOINTMENTNOTES";
export const UP_DIAYDAYNOTES = "DIAYDAYNOTES";
export const UP_TEMPLATENOTES = "TEMPLATENOTES";
export const UP_APPTYPE = "UP_APPTYPE";
export const UP_APP_CONFIRMED = "APP_CONFIRMED";

export const UP_APP_LINKED = "APP_LINKED";
export const UP_APP_UNLINKED = "APP_UNLINKED";
export const UP_TREATMENTPLANS = "TREATMENTPLANS";
export const UP_TREATMENTPLANS_COPY = "TREATMENTPLANS_COPY";
export const UP_CANCELLATIONREASON = "CANCELLATIONREASON";
export const UP_ACCOUNTGROUPS = "ACCOUNTGROUPS";
export const UP_ADD_CANCELLATIONINTEREST = "ADD_CANCELLATIONINTEREST";
export const UP_DEL_CANCELLATIONINTEREST = "DEL_CANCELLATIONINTEREST";
export const UP_ADD_PATIENTACCESS = "ADD_PATIENTACCESS";
export const UP_CLR_PATIENTACCESS = "CLR_PATIENTACCESS";
export const UP_DIARY_SORT_ORDER = "UP_DIARY_SORT_ORDER";
export const UP_RECALLTYPE = "UP_RECALLTYPE";
export const UP_WORK_REQUIRED = "UP_WORK_REQUIRED";
export const UP_WORK_REQUIRED_STATUS = "UP_WORK_REQUIRED_STATUS";

export const UP_ADD_PATIENTJOUNEYSTAGE = "UP_ADD_PATIENTJOUNEYSTAGE";
export const UP_DEL_PATIENTJOUNEYSTAGE = "UP_DEL_PATIENTJOUNEYSTAGE";
export const UP_PATIENTJOUNEYSTAGE = "UP_PATIENTJOUNEYSTAGE";

export const UP_ADD_PRACTICEUDATRAN = "UP_ADD_PRACTICEUDATRAN";
export const UP_DEL_PRACTICEUDATRAN = "UP_DEL_PRACTICEUDATRAN";
export const UP_PRACTICEUDATRAN = "UP_PRACTICEUDATRAN";

export const UP_ADD_MEDICALCONDITIONS = "UP_ADD_MEDICALCONDITIONS";
export const UP_UPD_MEDICALCONDITIONS = "UP_UPD_MEDICALCONDITIONS";
export const UP_DEL_MEDICALCONDITIONS = "UP_DEL_MEDICALCONDITIONS";

export const UP_ADD_PATMEDICALCOND = "UP_ADD_PATMEDICALCOND";
export const UP_UPD_PATMEDICALCOND = "UP_UPD_PATMEDICALCOND";
export const UP_DEL_PATMEDICALCOND = "UP_DEL_PATMEDICALCOND";

export const UP_ADD_MEDHISTFORM = "UP_ADD_MEDHISTFORM";
export const UP_UPD_MEDHISTFORM = "UP_UPD_MEDHISTFORM";
export const UP_DEL_MEDHISTFORM = "UP_DEL_MEDHISTFORM";

export const UP_NEWTASK = "UP_NEWTASK";
export const UP_TASKUPDATE = "UP_TASKUPDATE";
export const UP_TASKDEL = "UP_TASKDEL";

export const UP_RULEADD = "RULEADD";
export const UP_RULEUPDATE = "RULEUPDATE";
export const UP_RULEREMOVE = "RULEREMOVE";

export const ADD_TREATMENTCODE = "ADD_TREATMENTCODE";
export const UP_TREATMENTCODE = "UP_TREATMENTCODE";

export const UP_INVOICESCHEDULE = "INVOICESCHEDULE";
export const UP_INVOICEPREISSUE = "INVOICEPREISSUE";
export const UP_INVOICEISSUE = "INVOICEISSUE";
export const UP_INVOICECLOSED = "INVOICECLOSED";
export const UP_INVOICEREOPEN = "UP_INVOICEREOPEN";

export const UP_PP_INVOICES_TOTAL = "UP_PP_INVOICES_TOTAL";

export const UP_EDI_CLAIMS_TOTAL = "UP_EDI_CLAIMS_TOTAL";
export const UP_EDI_ERRORS_TOTAL = "UP_EDI_ERRORS_TOTAL";
export const UP_EDI_RESUBMIT = "UP_EDI_RESUBMIT";
export const UP_CLAIM_STACKED = "UP_CLAIM_STACKED";

export const UP_SMS_RECEIVED = "SMSRECEIVED";

export const UP_REMINDERSENT = "REMINDERSENT";
export const UP_REMINDERNOTSENT = "REMINDERNOTSENT";
export const UP_REMINDERCLOSED = "REMINDERCLOSED";

export const UP_RECALLSENT = "RECALLSENT";
export const UP_RECALLNOTSENT = "RECALLNOTSENT";

export const UP_DOCUMENT_ADDED = "DOCUMENT_ADDED";
export const SM_DROPPED_ITEM = "SM_DROPPED_ITEM";
export const UP_DOCUMENT_TRANSFERED = "DOCUMENT_TRANSFERED";
export const UP_DOCUMENT_USE_AS_PHOTO = "UP_DOCUMENT_USE_AS_PHOTO";

export const UP_TEMPLATE_ADDED = "TEMPLATE_ADDED";

export const UP_PATIENT_ARRIVED = "UP_PATIENT_ARRIVED";

export const UP_RECEIPTISSUED = "UP_RECEIPTISSUED";

export const UP_EMAIL_ACCOUNT_ITEM_PREVIEW = "UP_EMAIL_ACCOUNT_ITEM_PREVIEW";
export const UP_EMAIL_ACCOUNT_ITEM = "UP_EMAIL_ACCOUNT_ITEM";
export const UP_EMAIL_ACCOUNT_ITEM_FAILED = "UP_EMAIL_ACCOUNT_ITEM_FAILED";

export const UP_EMAIL_DOCUMENT_PREVIEW = "UP_EMAIL_DOCUMENT_PREVIEW";
export const UP_EMAIL_DOCUMENT = "UP_EMAIL_DOCUMENT";
export const UP_EMAIL_DOCUMENT_FAILED = "UP_EMAIL_DOCUMENT_FAILED";

export const UP_CONTACTLIST = "UP_CONTACTLIST";
export const UP_CONTACTLISTMEMBERSHIP = "UP_CONTACTLISTMEMBERSHIP";

// void related values 
export const UP_INVOICEVOIDED = "INVOICEVOIDED";
export const UP_CREDITNOTEVOIDED = "CREDITNOTEVOIDED";
export const UP_PAYMENTVOIDED = "PAYMENTVOIDED";
export const UP_ADJUSTMENTVOIDED = "ADJUSTMENTVOIDED";
export const UP_RECEIPTVOIDED = "RECEIPTVOIDED";
export const UP_REFUNDVOIDED = "REFUNDVOIDED";

export const UP_PAYMENTPAYTYPE = "PAYMENTPAYTYPE";
export const UP_RECEIPTPAYTYPE = "RECEIPTPAYTYPE";

// sms related values
export const UP_SMSCREDITEXCEEDED = "SMSCREDITEXCEEDED";

// ping related values
export const UP_PING = "PING";

// restart related values
export const UP_RESTART_REQUIRED = "RESTART_REQUIRED";

// messenger related values
export const UP_MESSENGER_NEW = "UP_MESSENGER_NEW";
export const UP_MESSENGER_REPLY = "UP_MESSENGER_REPLY";
export const UP_MESSENGER_ADDFOLDER = "UP_MESSENGER_ADDFOLDER";

// Billing Group related values

export const UP_BG_UPDATE = "BG_UPDATE";
export const UP_BG_REMOVE = "BG_REMOVE";

// schedule rules related

export const SR_CLINIC = 0;
export const SR_HOLIDAY = 1;
export const SR_NOTAVAILABLE = 2;
export const SR_LUNCH = 3;
export const SR_RESERVED = 4;
export const SR_SURGERY = 5;
export const SR_ZONE = 6;

export const TB_AddRule = {id: 'TB_addRule', text: 'Add Rule', icon: icons.ICON_PLUS};
export const TB_EditRule = {id: 'TB_editRule', text: 'Edit Rule', icon: icons.ICON_EDIT};
export const HM_DeleteRule = {
    id: 'DeleteRule',
    header: 'Delete Rule',
    message: 'Are you sure you want to delete this rule?',
    icon: icons.ICON_DELETE
};

export const TB_AddHoliday = {id: 'addHoliday', text: 'Add Holiday', icon: icons.ICON_PLUS};
export const TB_EditHoliday = {id: 'editHoliday', text: 'Edit Holiday', icon: icons.ICON_EDIT};
export const HM_DeleteHoliday = {
    id: 'DeleteHoliday',
    header: 'Delete Holiday',
    message: 'Are you sure you want to delete this holiday?',
    icon: icons.ICON_DELETE
};

// Laboratory related

export const LAB_ADD_RESULT_OK = 'OK';
export const LAB_ADD_RESULT_FAIL = 'FAIL';
export const LAB_ADD_RESULT_EXISTS = 'EXITS';
export const LAB_ADD_RESULT_IN_USE = 'IN_USE';

export const HM_LaboratorySave = {
    id: 'HM_LaboratorySave',
    header: 'Laboratory Creation',
    message: 'The form has been modified, save these changes?'
};

export const HM_LaboratorySaveOK = {
    id: 'HM_LaboratorySaveOK',
    header: 'Laboratory Creation',
    message: 'Laboratory Successfully Created'
};

export const HM_LaboratorySaveNOK = {
    id: 'HM_LaboratorySaveNOK',
    header: 'Laboratory Creation',
    message: 'Laboratory Creation Failed'
};

export const HM_ADD_WORK_REQUIRED = {
    id: 'HM_ADD_WORK_REQUIRED',
    header: 'Laboratory Work Required',
    message: 'Create a new lab work request',
    icon: icons.ICON_LAB,
};

// referrer related

export const REF_STATUS_INACTIVE = 'INACTIVE';
export const REF_STATUS_ACTIVE = 'ACTIVE';

export const REF_INDEPENDENT = {label: 'Independent', value: 'INDEPENDENT'};
export const REF_PATIENT = {label: 'Patient', value: 'PATIENT'};
export const REF_PROVIDER = {label: 'Provider', value: 'PROVIDER'};
export const REF_UNKNOWN = {label: 'Unknown', value: 'UNKNOWN'};

export const REFERRER_TYPES = [REF_INDEPENDENT, REF_PATIENT, REF_PROVIDER];

export const REF_ADD_RESULT_OK = 'OK';
export const REF_ADD_RESULT_FAIL = 'FAIL';
export const REF_ADD_RESULT_EXISTS = 'EXITS';
export const REF_ADD_RESULT_IN_USE = 'IN_USE';

export const REF_ADD_RESULT = [REF_ADD_RESULT_OK, REF_ADD_RESULT_FAIL, REF_ADD_RESULT_EXISTS, REF_ADD_RESULT_IN_USE];

export const HM_ReferrerSave = {
    id: 'HM_ReferrerSave',
    header: 'Referrer Creation',
    message: 'The form has been modified, save these changes?'
};

export const HM_ReferrerSaveOK = {
    id: 'HM_ReferrerSaveOK',
    header: 'Referrer Creation',
    message: 'Referrer Successfully Created'
};

export const HM_ReferrerSaveNOK = {
    id: 'HM_ReferrerSaveNOK',
    header: 'Referrer Creation',
    message: 'Referrer Creation Failed'
};

export const HM_EMAIL_IMAGE_QR = {
    id: 'HM_EMAIL_IMAGE_QR',
    label: 'Patient Image Email QR Code',
    header: 'Patient Image Email QR Code',
    message: '',
    icon: '',
};

export const HM_PATIENT_IMAGE = {
    id: 'HM_PATIENT_IMAGE',
    label: 'Use as Patient Photograph',
    header: 'Patient Photograph',
    message: 'Are you sure you want to use this image as the patient photograph?',
    icon: icons.ICON_CAMERA,
};

// Country related

export const CC_UK = 230;

// NHS Management related

export const HM_ClaimsHistory = {
    id: 'ClaimsHistory',
    label: 'Show Claims History',
    header: 'Show Claims History',
    message: '',
    icon: icons.ICON_INFO
};

export const HM_EditClaim = {
    id: 'EditClaim',
    label: 'Edit Claim',
    header: 'Edit Claim',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_CancelClaim = {
    id: 'CancelClaim',
    label: 'Mark as Cancelled',
    header: 'Mark as Cancelled',
    message: '',
    icon: icons.ICON_CANCEL,
};

export const HM_SubmitClaim = {
    id: 'SubmitClaim',
    label: 'Submit Claim',
    header: 'Submit Claim',
    message: '',
    icon: 'fas fa-share-square'
};

export const HM_SubmitClaims = {
    id: 'SubmitClaims',
    label: 'Submit Claim(s)',
    header: 'Submit Claim(s)',
    message: '',
    icon: 'fas fa-share-square'
};

export const HM_ResubmitClaim = {
    id: 'ResubmitClaim',
    label: 'Resubmit Claim',
    header: 'Resubmit Claim',
    message: '',
    icon: 'fas fa-share-square'
};

export const HM_PerformerPinValidation = {
    id: 'HM_PerformerPinValidation',
    label: 'Performer Pin Validation',
    header: 'Performer Pin Validation',
    message: 'The supplied performer PIN does not match that held by the system',
    icon: icons.ICON_PLUS
};

export const HM_ReassignPerformer = {
    id: 'HM_ReassignPerformer',
    label: 'Reassign Performer',
    header: 'Reassign Performer',
    message: '',
    icon: icons.ICON_TRANSFER
};

export const HM_ClaimSearchEdit = {
    id: 'HM_ClaimSearchEdit',
    label: 'Claim Search',
    header: 'Claim Search',
    message: 'Search for NHS Claim',
    icon: icons.ICON_SEARCH
};

export const HM_ClaimNotFound = {
    id: 'HM_ClaimNotFound',
    label: 'Claim Not Found',
    header: 'Claim Not Found',
    message: 'NHS Claim not found with supplied reference',
    icon: icons.ICON_HAND
};

export const HM_DeleteClaim = {
    id: 'DeleteClaim',
    label: 'Delete Claim',
    header: 'Delete Claim',
    message: '',
    icon: icons.ICON_DELETE,
};

export const HM_MarkAsDuplicateClaim = {
    id: 'MarkAsDuplicateClaim',
    label: 'Mark As Duplicate',
    header: 'Mark As Duplicate',
    message: '',
    icon: icons.ICON_COPY,
};
export const HM_ClaimSchedDetails = {
    id: 'ClaimSchedDetails',
    label: 'Claim Schedule Details',
    header: 'Claim Schedule Details',
    message: '',
    icon: icons.ICON_INFO
};

export const HM_EDI_EDIT_PART2 = {
    id: 'HM_EDI_EDIT_PART2',
    label: 'Patient Information (Part 2)',
    message: '',
    icon: ''
};

export const HM_EDI_EDIT_EXPS_REMS = {
    id: 'HM_EDI_EDIT_EXPS_REMS',
    label: 'Exemptions and Remissions (Part 3)',
    message: '',
    icon: ''
};

export const HM_EDI_EDIT_PART5 = {
    id: 'HM_EDI_EDIT_PART5',
    label: 'Orthodontic Assessment and Treatment Start (part 5)',
    message: '',
    icon: ''
};

export const HM_EDI_EDIT_PART6 = {
    id: 'HM_EDI_EDIT_PART6',
    label: 'Orthodontic Completion (Part 6)',
    message: '',
    icon: ''
};

export const HM_EDI_EDIT_PARTS7_9 = {
    id: 'HM_EDI_EDIT_PARTS7_9',
    label: 'Nice Guidance & Declaration (Part 7 & 9)',
    message: '',
    icon: ''
};

export const HM_NHS_SUPPORTING_DETAILS = {
    id: 'HM_NHS_SUPPORTING_DETAILS',
    header: 'Claim for free or reduced cost NHS Dental Services',
    message: 'Supporting Details'
};

// Medical history from related

export const MHF_ST_Current = 'Current';
export const MHF_ST_CurrentExpired = 'CurrentExpired';
export const MHF_ST_Expired = 'Expired';
export const MHF_ST_OnTablet = 'OnTablet';
export const MHF_ST_First = 'First';

// Medical Condition related

export const MC_LEVEL_INFORM = {name: 'INFORM', text: "Information"};
export const MC_LEVEL_WARNING = {name: 'WARNING', text: "Warning"};
export const MC_LEVEL_HAZARD = {name: 'HAZARD', text: "Hazard"};

export const MC_LEVELS = [MC_LEVEL_INFORM, MC_LEVEL_WARNING, MC_LEVEL_HAZARD];

const rgbToHex = (rgb) => {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
        hex = "0" + hex;
    }
    return hex;
};

export const fullColorHex = (r, g, b) => {
    const red = rgbToHex(r);
    const green = rgbToHex(g);
    const blue = rgbToHex(b);
    return `#${red}${green}${blue}`;
};

export const fullColorHexColonSep = (colours) => {

    const parts = colours.split(':');
    const red = rgbToHex(parts[0]);
    const green = rgbToHex(parts[1]);
    const blue = rgbToHex(parts[2]);
    return `#${red}${green}${blue}`;
};

// note types

export const NOTE_Info = {name: 'Info', type: 'Info'};
export const NOTE_Warning = {name: 'Warning', type: 'Warning'};
export const NOTE_Action = {name: 'Action', type: 'Action'};
export const NOTE_Treatment = {name: 'Treatment', type: 'Treatment'};
export const NOTE_Invoice = {name: 'Invoice', type: 'Invoice'};
export const NOTE_Receipt = {name: 'Receipt', type: 'Receipt'};
export const NOTE_Payment = {name: 'Payment', type: 'Payment'};
export const NOTE_Refund = {name: 'Refund', type: 'Refund'};
export const NOTE_AAdjustment = {name: 'AAdjustment', type: 'AAdjustment'};
export const NOTE_TreatmentCode = {name: 'TreatmentCode', type: 'TreatmentCode'};
export const NOTE_Complaint = {name: 'Complaint', type: 'Complaint'};
export const NOTE_NextAction = {name: 'NextAction', type: 'NextAction'};
export const NOTE_Medical = {name: 'Medical', type: 'Medical'};

export const NOTE_PATIENT = {
    name: 'Patient',
    type: 'NOTE_PATIENT',
    include: 'Include patient notes',
    exclude: 'Exclude patient notes'
};
export const NOTE_TREATMENT = {
    name: 'Treatment',
    type: 'NOTE_TREATMENT',
    include: 'Include treatment notes',
    exclude: 'Exclude treatment notes'
};
export const NOTE_MEDICAL = {
    name: 'Medical',
    type: 'NOTE_MEDICAL',
    include: 'Include medical notes',
    exclude: 'Exclude medical notes'
};
export const NOTE_FINANCIAL = {
    name: 'Financial',
    type: 'NOTE_FINANCIAL',
    include: 'Include financial notes',
    exclude: 'Exclude financial notes'
};
export const NOTE_COMPLAINT = {
    name: 'Complaint',
    type: 'NOTE_COMPLAINT',
    include: 'Include complaints',
    exclude: 'Exclude complaints'
};

export const NOTE_FILTER_TYPES = [NOTE_PATIENT, NOTE_MEDICAL, NOTE_FINANCIAL, NOTE_COMPLAINT]

export const NOTE_FULL = {
    name: 'Full',
    type: 'NOTE_FULL',
};

export const NOTE_DATE_RANGE = {
    name: 'Date Range',
    type: 'NOTE_DATE_RANGE',
};

export const NOTE_FILTER_DETAILS = [NOTE_FULL, NOTE_DATE_RANGE]

// time periods

export const INV_OUT_30 = 'INV_OUT_30';
export const INV_OUT_31_60 = 'INV_OUT_31_60';
export const INV_OUT_61_90 = 'INV_OUT_61_90';
export const INV_OUT_91 = 'INV_OUT_91';
export const INV_OUT_30PLUS = 'INV_OUT_30PLUS';
export const INV_BG_OUT_30 = 'INV_BG_OUT_30';
export const INV_BG_OUT_31_60 = 'INV_BG_OUT_31_60';
export const INV_BG_OUT_61_90 = 'INV_BG_OUT_61_90';
export const INV_BG_OUT_91 = 'INV_BG_OUT_91';
export const INV_OUT_ALL = 'INV_OUT_ALL';
export const INV_BG_OUT_ALL = 'INV_BG_OUT_ALL';

export const TimePeriods = [INV_OUT_30, INV_OUT_31_60, INV_OUT_61_90, INV_OUT_91, INV_OUT_30PLUS, INV_BG_OUT_30, INV_BG_OUT_31_60, INV_BG_OUT_61_90, INV_BG_OUT_91, INV_OUT_ALL, INV_BG_OUT_ALL];

// payment plan related

export const PP_STATUS_SAVED = {name: 'Saved', text: 'Saved', ord: 0};
export const PP_STATUS_ACCEPTED = {name: 'Accepted', text: 'Accepted', ord: 1};
export const PP_STATUS_STATED = {name: 'Started', text: 'Started', ord: 2};
export const PP_STATUS_FINISHED = {name: 'Finished', text: 'Finished', ord: 3};
export const PP_STATUS_SUSPENDED = {name: 'Suspended', text: 'Suspended', ord: 4};
export const PP_STATUS_WRITTEN_OFF = {name: 'WrittenOff', text: 'Written Off', ord: 5};
export const PP_STATUS_DELETED = {name: 'Deleted', text: 'Deleted', ord: 6};
export const PP_STATUS_PROPOSED = {name: 'Proposed', text: 'Proposed', ord: 7};

export const PP_STATUS = [PP_STATUS_SAVED, PP_STATUS_ACCEPTED, PP_STATUS_STATED, PP_STATUS_FINISHED, PP_STATUS_SUSPENDED, PP_STATUS_WRITTEN_OFF, PP_STATUS_DELETED, PP_STATUS_PROPOSED];
export const PP_STATUS_VISIBLE = [PP_STATUS_PROPOSED, PP_STATUS_ACCEPTED, PP_STATUS_STATED, PP_STATUS_FINISHED, PP_STATUS_SUSPENDED, PP_STATUS_WRITTEN_OFF];

export const PP_PRECENT0 = {value: 'percent0', label: '      None', percentage: 0};
export const PP_PRECENT5 = {value: 'percent5', label: ' 5 percent', percentage: 5};
export const PP_PRECENT10 = {value: 'percent10', label: '10 percent', percentage: 10};
export const PP_PRECENT15 = {value: 'percent15', label: '15 percent', percentage: 15};
export const PP_PRECENT20 = {value: 'percent20', label: '20 percent', percentage: 20};
export const PP_PRECENT25 = {value: 'percent25', label: '25 percent', percentage: 25};
export const PP_PRECENT30 = {value: 'percent30', label: '30 percent', percentage: 30};
export const PP_PRECENT35 = {value: 'percent35', label: '35 percent', percentage: 35};
export const PP_PRECENT40 = {value: 'percent40', label: '40 percent', percentage: 40};
export const PP_PRECENT50 = {value: 'percent50', label: '50 percent', percentage: 50};
export const PP_PRECENT45 = {value: 'percent45', label: '45 percent', percentage: 45};
export const PP_PRECENT_AM = {value: 'percent_AM', label: '    Amount', percentage: 0};

export const PP_PRECENT = [PP_PRECENT0, PP_PRECENT_AM, PP_PRECENT5, PP_PRECENT10, PP_PRECENT15, PP_PRECENT20, PP_PRECENT25, PP_PRECENT30, PP_PRECENT35, PP_PRECENT40, PP_PRECENT45, PP_PRECENT50];

export const PP_INTERVAL_WEEKLY = {value: 'Weekly', label: 'Weekly'};
export const PP_INTERVAL_MONTHLY = {value: 'Monthly', label: 'Monthly'};
export const PP_INTERVAL_QUARTERLY = {value: 'Quarterly', label: 'Quarterly'};
export const PP_INTERVAL_MONTHS2 = {value: 'Months2', label: 'Every 2 Months'};
export const PP_INTERVAL_MONTHS4 = {value: 'Months4', label: 'Every 4 Months'};
export const PP_INTERVAL_MONTHS6 = {value: 'Months6', label: 'Every 6 Months'};

export const PP_INTERVAL = [PP_INTERVAL_WEEKLY, PP_INTERVAL_MONTHLY, PP_INTERVAL_QUARTERLY, PP_INTERVAL_MONTHS2, PP_INTERVAL_MONTHS4, PP_INTERVAL_MONTHS6];

export const PP_SET_TYPE_OPTION1_DC = {value: 'Option1_DC', label: 'Option One Debit Card'};
export const PP_SET_TYPE_OPTION1_CC = {value: 'Option1_CC', label: 'Option One Credit Card'};
export const PP_SET_TYPE_OPTION2_DC = {value: 'Option2_DC', label: 'Option Two Debit Card'};
export const PP_SET_TYPE_OPTION2_CC = {value: 'Option2_CC', label: 'Option Two Credit Card'};
export const PP_SET_TYPE_DC = {value: 'Option_DC', label: 'Debit Card'};
export const PP_SET_TYPE_CC = {value: 'Option_CC', label: 'Credit Card'};
export const PP_SET_TYPE_SO = {value: 'Option_SO', label: 'Standing Order'};
export const PP_SET_TYPE_OPTION_CHEQUE = {value: 'Option_Cheque', label: 'Cheque'};
export const PP_SET_TYPE_OPTION_CASH = {value: 'Option_Cash', label: 'Cash'};

export const PP_SET_TYPE = [PP_SET_TYPE_OPTION1_DC, PP_SET_TYPE_OPTION1_CC, PP_SET_TYPE_OPTION2_DC, PP_SET_TYPE_OPTION2_CC, PP_SET_TYPE_DC, PP_SET_TYPE_CC, PP_SET_TYPE_SO, PP_SET_TYPE_OPTION_CHEQUE, PP_SET_TYPE_OPTION_CASH];

export const HM_CREATE_PAYMENT_PLAN = {
    id: 'HM_CREATE_PAYMENT_PLAN',
    label: 'Payment Plan',
    header: 'Payment Plan',
    message: 'Create a payment plan for this treatment plan'
};

export const HM_EDIT_PAYMENT_PLAN = {
    id: 'HM_EDIT_PAYMENT_PLAN',
    label: 'Payment Plan',
    header: 'Payment Plan',
    message: 'Edit payment plan for this treatment plan'
};

export const HM_SUSPEND_PAYMENT_PLAN = {
    id: 'HM_SUSPEND_PAYMENT_PLAN',
    label: 'Suspend Payment Plan',
    header: 'Suspend Payment Plan',
    message: 'Are you sure you want to suspend this payment plan?'
};

export const HM_RESUME_PAYMENT_PLAN = {
    id: 'HM_RESUME_PAYMENT_PLAN',
    label: 'Resume Payment Plan',
    header: 'Resume Payment Plan',
    message: 'Are you sure you want to resume this payment plan?'
};

export const HM_WRITE_OFF_PAYMENT_PLAN = {
    id: 'HM_WRITE_OFF_PAYMENT_PLAN',
    label: 'Write Off Payment Plan',
    header: 'Write Off Payment Plan',
    message: 'Are you sure you want to write off this payment plan?'
};

export const HM_CREATE_TREATMENT_PLAN_DOC = {
    id: 'HM_CREATE_TREATMENT_PLAN_DOC',
    label: 'Payment Document',
    header: 'Patient Treatment Plan',
    message: 'Select the treatment plan template to use for creation'
};

export const HM_TREATMENT_PLAN_HANDOVER = {
    id: 'HM_TREATMENT_PLAN_HANDOVER',
    label: 'Treatment Plan Handover',
    header: 'Treatment Plan Handover',
    message: 'Hand treatment plan over to reception'
};

// payment plan related

export const HM_CREATE_PAYMENT_PLAN_DOC = {
    id: 'HM_CREATE_PAYMENT_PLAN_DOC',
    label: 'Payment Document',
    header: 'Patient Payment Plan',
    message: 'Select the payment plan template to use for creation'
};

// perio chart related

export const HM_PERIO_CHART_PRINT = {
    id: 'HM_PERIO_CHART_PRINT',
    label: 'Print Periodontal Chart',
    icon: icons.PRINT24
};

export const HM_PERIO_CHART_SHOW = {
    id: 'HM_PERIO_CHART_SHOW',
    label: 'Show Periodontal Chart',
    icon: icons.SHOW24
};

// ortho assessment related

export const HM_ORTHO_ASSESSMENT_PRINT = {
    id: 'HM_ORTHO_ASSESSMENT_PRINT',
    label: 'Print Ortho Assessment',
    icon: icons.PRINT24
};

export const HM_ORTHO_ASSESSMENT_SHOW = {
    id: 'HM_ORTHO_ASSESSMENT_SHOW',
    label: 'Show Ortho Assessment',
    icon: icons.SHOW24
};

// patient assessment related

export const HM_PATIENT_ASSESSMENT_PRINT = {
    id: 'HM_PATIENT_ASSESSMENT_PRINT',
    label: 'Print Patient Assessment',
    icon: icons.PRINT24
};

export const HM_PATIENT_ASSESSMENT_SHOW = {
    id: 'HM_PATIENT_ASSESSMENT_SHOW',
    label: 'Show Patient Assessment',
    icon: icons.SHOW24
};

// task related

export const HO_ACTION_NO_ACTION = {name: 'NoAction', text: 'No Action'};
export const HO_ACTION_CREATE_NEXT_APP = {name: 'CreateNextAppointment', text: 'Create Next Appointment'};
export const HO_ACTION_CREATE_NEXT_APP_AND_CHARGE = {
    name: 'CreateNextAppointmentAndCharge',
    text: 'Charge & Create Next Appointment'
};
export const HO_ACTION_CHARGE = {name: 'Charge', text: 'Charge'};
export const HO_ACTION_BOOK_MEETING = {name: 'BookMeeting', text: 'Book Meeting'};
export const HO_ACTION_USER_DEFINED = {name: 'UserDefined', text: 'User Defined'};
export const HO_ACTION_BOOK_TP_APP = {name: 'BookTreatmentPlanAppointments', text: 'Book Treatment Plan Appointments'};
export const HO_ACTION_BOOK_TP_APPS_AND_CHARGE = {
    name: 'BookTreatmentPlanAppointmentsAndCharge',
    text: 'Charge & Book Treatment Plan Appointments'
};

export const HO_ACTIONS = [HO_ACTION_NO_ACTION, HO_ACTION_CREATE_NEXT_APP, HO_ACTION_CREATE_NEXT_APP_AND_CHARGE, HO_ACTION_CHARGE, HO_ACTION_BOOK_MEETING, HO_ACTION_USER_DEFINED, HO_ACTION_BOOK_TP_APP, HO_ACTION_BOOK_TP_APPS_AND_CHARGE];

export const HO_STATUS_SHOW_TASK = 'HO_STATUS_SHOW_TASK';
export const HO_STATUS_SHOW_PAYMENT = 'HO_STATUS_SHOW_PAYMENT';
export const HO_STATUS_SHOW_PLACE_APPS = 'HO_STATUS_SHOW_PLACE_APPS';

export const TASK_PRIORITY_LOW = 'Low';
export const TASK_PRIORITY_MEDIUM = 'Medium';
export const TASK_PRIORITY_HIGH = 'High';

export const TASK_STATUS_PREPARING = 'Preparing';
export const TASK_STATUS_ACTIVE = 'Active';
export const TASK_STATUS_COMPLETE = 'Complete';
export const TASK_STATUS_SUSPENDED = 'Suspended';
export const TASK_STATUS_POSTPONED = 'Postponed';
export const TASK_STATUS_CANCELLED = 'Cancelled';

export const HM_ActionDiaryTask = {
    id: 'HM_ActionDiaryTask',
    header: 'Reception Action Task',
    message: '',
    icon: icons.ICON_OK
};

export const HM_CONFIRM_ADD_TO_TABLET = {
    id: 'HM_CONFIRM_ADD_TO_TABLET',
    header: CM_APPOINTMENT_PAT_ARRIVED,
    message: '',
    icon: icons.ICON_OK,
    icon1: 'far fa-check-square',
    icon2: 'far fa-square',
};

export const HM_DeleteDiaryTask = {
    id: 'HM_DeleteDiaryTask',
    header: 'Close Task',
    message: 'Are you sure you want to close this diary task?',
    icon: 'far fa-times-circle'
};

export const HM_DeletePaletteMove = {
    id: 'HM_DeletePaletteMove',
    header: 'Delete appointment from Move',
    message: 'Are you sure you want to delete this appointment from move',
    icon: icons.ICON_DELETE
};

export const HM_DeleteAllPaletteMove = {
    id: 'HM_DeleteAllPaletteMove',
    header: 'Delete all appointment(s) from Move',
    message: 'Are you sure you want to delete all the appointment(s) from move',
    icon: icons.ICON_DELETE
};

export const HM_IncorrectProvider = {
    id: 'HM_IncorrectProvider',
    header: 'Place Treatment Plan Appointment',
    message: 'This appointment is planned with : ',
};

export const HM_TASK_PRINT = {
    id: 'HM_TASK_PRINT',
    label: `Print ${TextOutstandingTasks}`,
    icon: icons.PRINT24
};

export const HM_TASK_SHOW = {
    id: 'HM_TASK_SHOW',
    label: `Show ${TextOutstandingTasks}`,
    icon: icons.SHOW24
};

// PCC related

export const HM_CLOSE_TAB = {
    id: 'HM_CLOSE_TAB',
    label: `Close`,
    icon: icons.ICON_CLOSE
};

export const HM_CLOSE_OTHER_TABS = {
    id: 'HM_CLOSE_OTHER_TABS',
    label: `Close Others`,
    icon: icons.ICON_CLOSE
};

export const HM_CLOSE_ALL_TABS = {
    id: 'HM_CLOSE_ALL_TABS',
    label: `Close All`,
    icon: icons.ICON_CLOSE
};

export const HM_ADD_PATIENT_OK = {
    id: 'HM_ADD_PATIENT_OK',
    header: 'Add Patient',
    message: 'This patient was successfully added'
};

export const HM_ADD_PATIENT_ALREADY_EXISTS = {
    id: 'HM_ADD_PATIENT_ALREADY_EXISTS',
    header: 'Add Patient',
    message: 'This patient already exists'
};

export const HM_ADD_PATIENT_ERROR = {
    id: 'HM_ADD_PATIENT_ERROR',
    header: 'Add Patient',
    message: 'An error occurred whilst adding the patient'
};

// portal related

export const HM_PORTAL_BOOK_APPOINTMENT = {
    id: 'HM_PORTAL_BOOK_APPOINTMENT',
    header: 'Book Appointment',
    message: 'Are you sure you want to book this appointment',
    icon: icons.ICON_OK,
};

export const HM_PORTAL_BOOK_APPOINTMENT_NOTE = {
    id: 'HM_PORTAL_BOOK_APPOINTMENT_NOTE',
    header: 'Appointment Booking Note',
    message: 'Appointment Booking Note',
    icon: icons.ICON_OK,
};

export const HM_PORTAL_BOOK_VIDEO_CALL = {
    id: 'HM_PORTAL_BOOK_VIDEO_CALL',
    header: 'Book Video Call',
    message: 'Are you sure you want to book this video call',
    icon: icons.ICON_OK,
};

export const HM_PORTAL_BOOK_VIDEO_CALL_NOTE = {
    id: 'HM_PORTAL_BOOK_VIDEO_CALL_NOTE',
    header: 'Video Call Booking Note',
    message: 'Video Call Booking Note',
    icon: icons.ICON_OK,
};

// Patient XRAY Record related

export const XT_BITE_WING = {value: 'BiteWing', label: 'BiteWing'};
export const XT_PERIAPICLE = {value: 'Periapical', label: 'Periapical'};
export const XT_OCCLUSAL = {value: 'Occlusal', label: 'Occlusal'};
export const XT_PANORAMIC = {value: 'Panoramic', label: 'Panoramic'};
export const XT_CEPHALOMETRIC = {value: 'Cephalometric', label: 'Cephalometric'};
export const XT_CBCT = {value: 'CBCT', label: 'CBCT'};

export const XRR_TYPES = [XT_BITE_WING, XT_PERIAPICLE, XT_OCCLUSAL, XT_PANORAMIC, XT_CEPHALOMETRIC, XT_CBCT];

export const XR_EXCELLENT = {value: 'Excellent', label: 'Excellent'};
export const XR_ACCEPTABLE = {value: 'Acceptable', label: 'Acceptable'};
export const XR_UNACCEPTABLE = {value: 'Unacceptable', label: 'Unacceptable'};

export const XRR_RATINGS = [XR_EXCELLENT, XR_ACCEPTABLE, XR_UNACCEPTABLE];

// Patient Sedation Record Related

export const IA_O = {value: 'IA_O', label: 'Observer'};
export const IA_A = {value: "IA_A", label: "An Assistant"};
export const IA_S = {value: "IA_S", label: "The person providing the Sedation"};
export const IA_D = {value: "IA_D", label: "The person carrying out the Dental Treatment"};

export const IA_TYPES = [IA_O, IA_A, IA_S, IA_D];

export const ASA_I = {value: "ASA_I", label: "Normal healthy patients"};
export const ASA_II = {value: "ASA_II", label: "Patients with mild systemic disease"};
export const ASA_III = {
    value: "ASA_III",
    label: "Patients with noticeable systemic disease that is limiting but not incapacitating"
};
export const ASA_IV = {
    value: "ASA_IV",
    label: "Patient with an incapacitating disease that is a constant threat to life"
};
export const ASA_V = {value: "ASA_V", label: "Patient not expected to live more than 24 hours"};

export const ASA_TYPES = [ASA_I, ASA_II, ASA_III, ASA_IV, ASA_V];

export const ST_IV = {value: "ST_IV", label: "Intravenous Sedation"};
export const ST_RA = {value: "ST_RA", label: "Inhalation Sedation"};
export const ST_O = {value: "ST_O", label: "Oral Sedation"};
export const ST_TM = {value: "ST_TM", label: "Transmucosal"};

export const ST_TYPES = [ST_IV, ST_RA, ST_O, ST_TM];

export const SS_1 = {value: "SS_1", label: "Anxious, restless or both"};
export const SS_2 = {value: "SS_2", label: "Cooperative, orientated and tranquil"};
export const SS_3 = {value: "SS_3", label: "Responding to commands"};
export const SS_4 = {value: "SS_4", label: "Brisk response to stimulus"};
export const SS_5 = {value: "SS_5", label: "Sluggish response to stimulus"};
export const SS_6 = {value: "SS_6", label: "No response to stimulus"};

export const SS_TYPES = [SS_1, SS_2, SS_3, SS_4, SS_5, SS_6];

export const OCON_1 = {
    value: "OCON_1",
    label: "Good",
    text: "Patient fully cooperative with optimum degree of sedation"
};
export const OCON_2 = {
    value: "OCON_2",
    label: "Fair",
    text: "Minimal interference from patient due to over/under sedation"
};
export const OCON_3 = {value: "OCON_3", label: "Poor", text: "Operating difficult due to over/under sedation"};
export const OCON_4 = {value: "OCON_4", label: "Impossible", text: "Action taken (e.g. GA)"};

export const OCON_TYPES = [OCON_1, OCON_2, OCON_3, OCON_4];

export const Rec_Normal = {value: "Rec_Normal", label: "Normal", text: "Within the timescale expected"};
export const Rec_Rapid = {value: "Rec_Rapid", label: "Rapid", text: "Sooner than normal – action taken"};
export const Rec_Prolonged = {value: "Rec_Prolonged", label: "Prolonged", text: "Longer than normal – action taken"};

export const Rec_TYPES = [Rec_Normal, Rec_Rapid, Rec_Prolonged];

export const FB_0 = {value: "FB_0", label: "Happy to repeat if needed and would recommend to others"};
export const FB_1 = {value: "FB_1", label: "Neutral about the outcome"};
export const FB_2 = {value: "FB_2", label: "Negative patient feedback, label: prefer not to have again"};

export const FB_TYPES = [FB_0, FB_1, FB_2];

// Location related

export const LOC_STATUS_INACTIVE = {name: 'INACTIVE', text: 'Inactive'};
export const LOC_STATUS_ACTIVE = {name: 'ACTIVE', text: 'Active'};
export const LOC_STATUS = [LOC_STATUS_INACTIVE, LOC_STATUS_ACTIVE];

export const LOC_COLOCATED = {name: 'COLOCATED', text: 'On-Site'};
export const LOC_REMOTE = {name: 'REMOTE', text: 'Off-Site'};
export const LOC_TYPES = [LOC_COLOCATED, LOC_REMOTE];

// Lab work related

export const WORK_TYPE_BY_DATE = {value: 'ByDate', label: 'Date'};
export const WORK_TYPE_FOR_APPOINTMENT = {value: 'ForAppointment', label: 'Appointment'};
export const WORK_TYPES = [WORK_TYPE_BY_DATE, WORK_TYPE_FOR_APPOINTMENT];

export const WORK_STATUS_REQUESTED = {name: 'Requested', text: '-', icon: null};
export const WORK_STATUS_LAB_NOTIFIED = {name: 'LabNotified', text: 'Lab Notified', icon: icons.ICON_PHONE};
export const WORK_STATUS_SENT_TO_LAB = {name: 'SentToLab', text: 'Sent To Lab', icon: icons.ICON_PICKUP};
export const WORK_STATUS_RECEIVED_FROM_LAB = {name: 'ReceivedFromLab', text: 'Received', icon: icons.ICON_GIFT};
export const WORK_STATUS_APPROVED = {name: 'Approved', text: 'Approved', icon: icons.ICON_CERTIFICATE};
export const WORK_STATUS_HOLD = {name: 'OnHold', text: 'On Hold', icon: null};
export const WORK_STATUS_RETURNED = {name: 'ReturnedToLab', text: 'Returned To Lab', icon: null};
export const WORK_STATUS_CANCELLED = {name: 'Cancelled', text: 'Cancelled', icon: null};

export const XR_REPORT_WEEKLY = {value: 'Weekly', label: 'Weekly', text: TextWeeksXrayReport};
export const XR_REPORT_MONTHLY = {value: 'Monthly', label: 'Monthly', text: TextMonthlyXrayReport};
export const XR_REPORT_QUARTERLY = {value: 'Quarterly', label: 'Quarterly', text: TextQuarterlyXrayReport};
export const XR_REPORT_BIANNUAL = {value: 'Biannual', label: 'Biannual', text: TextBiannualXrayReport};
export const XR_REPORT_YEARLY = {value: 'Yearly', label: 'Yearly', text: TextYearlyXrayReport};

export const XR_REPORT_INTERVALS = [XR_REPORT_WEEKLY, XR_REPORT_MONTHLY, XR_REPORT_QUARTERLY, XR_REPORT_BIANNUAL, XR_REPORT_YEARLY];

export const SED_REPORT_WEEKLY = {value: 'Weekly', label: 'Weekly', text: TextWeeksSedReport, unit: 'week'};
export const SED_REPORT_MONTHLY = {value: 'Monthly', label: 'Monthly', text: TextMonthlySedReport, unit: 'month'};

export const SED_REPORT_INTERVALS = [SED_REPORT_WEEKLY, SED_REPORT_MONTHLY];

export const UTILISATION_REPORT_WEEKLY = {value: 'Weekly', label: 'Weekly', text: 'week', unit: 'W'};
export const UTILISATION_REPORT_MONTHLY = {value: 'Monthly', label: 'Monthly', text: 'month', unit: 'M'};

export const UTILISATION_REPORT_INTERVALS = [UTILISATION_REPORT_WEEKLY, UTILISATION_REPORT_MONTHLY];

// admin related

export const NewPassword = 'NewPassword';

export const ADMIN_SUB_ADD_RESULT_OK = 'OK';
export const ADMIN_SUB_ADD_RESULT_ALREADY_EXISTS = 'ALREADY_EXISTS';
export const ADMIN_SUB_ADD_RESULT_DUPLICATE_NAME = 'DUPLICATE_SUBSCRIPTION';
export const ADMIN_SUB_ADD_RESULT_ERROR = 'ERROR';

export const TB_SEND_SUB_EMAIL = {
    id: 'TB_SEND_SUB_EMAIL',
    text: 'Send Login Details',
    icon: ICON_EMAIL
};
