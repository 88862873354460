import React from 'react';
import {connect} from "react-redux";
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {TabBaseComponent} from "../../TabBaseComponent";
import {
    setState,
    SM_OVER_DUE_ACCOUNTS,
    SM_PATIENT_ACCOUNT_HISTORY,
    stateRequest
} from "../../../actions/stateManagement";
import ReportErrorBoundary, {accountsOutstandingToolBar} from "./Utils";
import {TB_PATIENT_DETAILS} from "../PatientDetails/Constants";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {
    HM_PreviewAccountItem,
    HM_REP_OVERDUE_ACCOUNTS_EXCEL,
    REPORT_SHOW,
    TB_SAVE_AND_EXIT,
    UP_EMAIL_ACCOUNT_ITEM,
    UP_EMAIL_ACCOUNT_ITEM_FAILED,
    UP_EMAIL_ACCOUNT_ITEM_PREVIEW
} from "../../../Constants";
import {ac} from "../../../index";
import {previewAccountItem, RES_sendAccountItem, sendAccountItem} from "../../../actions/personal";
import * as Actions from "../../../actions";
import {PDF_REQUEST_NONE} from "../../../actions";
import PreviewPatientDocument from "../PatientDetails/dialogs/PreviewPatientDocument";
import {Toast} from "primereact/toast";
import {TAB_EXIT_SAVE} from "../Housekeeping/Constants";
import moment from "moment";
import {getResource} from "../../../actions/users";
import {ProgressBar} from "primereact/progressbar";
import AccGroupOverdueAccounts from './Sections/AccGroupOverdueAccounts';
import {RES_getAccountGroups} from "../../../actions/accountGroups";
import {RES_OVER_DUE_ACCOUNTS} from "../../../actions/reports";

export class ConnectedOutstandingAccounts extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {

                stateManagementId: SM_OVER_DUE_ACCOUNTS.id,

                accountGroupsLoaded: false, props,

                canSave: {status: false, activeIndex: 0, source: null},
                totalOutStanding: 0.0,

                selectedAccountItem: null,

                accountGroupData: [],

                pdfRequest: PDF_REQUEST_NONE,
            }
        }

        this.emailSendGrowl = null;

        this.toolbarCallbacks = {
            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [HM_REP_OVERDUE_ACCOUNTS_EXCEL.id]: this.onDownload,
            [TB_SAVE_AND_EXIT.id]: this.exit,
        };
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getAccountGroups();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_ACCOUNTS_GROUPS: {

                    const accountGroupData = [];

                    this.props.accountGroups.forEach(group => {
                        accountGroupData.push({
                            agId: group.id,
                            agName: group.groupName,
                            activeIndex: 0,
                            pdfBuffer: null,
                            PDFLoaded: false,
                        });
                    });
                    this.setState({accountGroupData, accountGroupsLoaded: true}, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;
                }
                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_EMAIL_ACCOUNT_ITEM_PREVIEW:

                            const previewToken = this.props.wsmessage.content[5];

                            if (previewToken === this.state.previewToken) {

                                const previewCreated = this.props.wsmessage.content[2];

                                if (previewCreated) {
                                    const accountItemDetails = this.props.wsmessage.content;
                                    this.setState({
                                        accountItemDetails,
                                        [HM_PreviewAccountItem.id]: true,
                                        previewToken
                                    });
                                } else {
                                    if (this.props.wsmessage.content[2] === null) {// then this is a patient document
                                        this.emailSendGrowl.show({
                                            severity: 'warn',
                                            summary: 'No Patient Email Address',
                                            detail: 'This patient doesn\'t have a registered email address'
                                        });
                                    } else {
                                        this.emailSendGrowl.show({
                                            severity: 'warn',
                                            summary: 'No Referrer Email Address',
                                            detail: 'This referrer doesn\'t have a registered email address'
                                        });
                                    }
                                }
                            }
                            break;

                        case UP_EMAIL_ACCOUNT_ITEM: {

                            const idToken = this.props.wsmessage.content[1];

                            if (idToken === this.state.idToken) {
                                this.emailSendGrowl.show({
                                    severity: 'info',
                                    summary: 'Success',
                                    detail: 'Document Successfully Emailed'
                                });
                            }
                            break;
                        }
                        case UP_EMAIL_ACCOUNT_ITEM_FAILED: {

                            const idToken = this.props.wsmessage.content[1];

                            if (idToken === this.state.idToken) {
                                this.emailSendGrowl.show({
                                    severity: 'error',
                                    summary: 'Failure',
                                    detail: 'Failed to Email Document'
                                });
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onRequestPreviewAccountItem = (target, selectedAccountItem) => {

        const previewToken = Math.random().toString(36).slice(2);

        this.setState({eventTarget: target.item.id, selectedAccountItem, previewToken}, () => {

            const details = {
                report: target.item.report,
                function: target.item.reportFunction,
                itemId: selectedAccountItem.id,
                emailAddress: selectedAccountItem.email,
            };
            this.props.previewAccountItem(details, previewToken);
        });
    }

    onRequestSendAccountItem = (updatedDetails) => {

        const idToken = Math.random().toString(36).slice(2);

        const details = {
            report: updatedDetails[3],
            function: REPORT_SHOW,
            itemId: this.state.selectedAccountItem.id,
            emailAddress: this.state.selectedAccountItem.email,
        };

        this.setState({[HM_PreviewAccountItem.id]: false, idToken}, () => {
            this.props.sendAccountItem(details, updatedDetails[2], idToken);
        });
    }

    onShowDetails = ({patientId, patientFirstName, patientLastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({
            props: this.props,
            id: patientId,
            firstName: patientFirstName,
            lastName: patientLastName,
            gender,
            nhsPatient,
            groupId
        });
    }

    setPeriodTotal = (totalOutStanding) => {
        this.setState({totalOutStanding});
    }

    onDownload = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_OVER_DUE_ACCOUNTS.XLS.url}?mcId=${ac.getMcId()}&id=${this.state.accountGroupData[this.state.canSave.activeIndex].agId}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `oa${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                    a.click();
                });
            });
    }

    exit = () => {
        this.exitState = TAB_EXIT_SAVE;
        this.onSaveExit();
    }

    onClose = () => {
        if (this.state.canSave.status) {
            this.props.setState(this.props.id, null);
            this.setState({showSaveDialog: true});
        } else {
            this.onExit();
        }
    }

    onExit = () => {
        this.props.onCloseClick({key: this.props.id});
    }

    onCloseClick = () => {
        this.props.onCloseClick({key: this.props.id});
    }

    showDialogs() {

        const contents = [];

        if (this.state[HM_PreviewAccountItem.id]) {
            contents.push(
                <PreviewPatientDocument documentDetails={this.state.accountItemDetails}
                                        targetId={HM_PreviewAccountItem.id}
                                        onOkDialog={this.onRequestSendAccountItem}
                                        onHideDialog={this.onHideMenuEntry}
                />
            )
        }
        return contents;
    }

    onSubTabChange = (agIndex, index) => {

        const accountGroupData = [...this.state.accountGroupData];
        accountGroupData[agIndex].activeIndex = index;

        this.setState({accountGroupData}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
        });
    }

    render() {

        if (!this.state.accountGroupsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        this.tabStack = [];

        this.props.accountGroups.forEach((group, agIndex) => {
            this.tabStack.push(
                {
                    index: this.index++,
                    section: `${SM_PATIENT_ACCOUNT_HISTORY}_${group.id}`,
                    id: `${SM_PATIENT_ACCOUNT_HISTORY}_${group.id}`,
                    helpUrl: SM_PATIENT_ACCOUNT_HISTORY.helpUrl,
                    content: <TabPanel key={`${SM_PATIENT_ACCOUNT_HISTORY}_${group.id}`}
                                       header={group.groupName}>
                        <AccGroupOverdueAccounts accountGroup={group}
                                                 accountGroupData={this.state.accountGroupData[agIndex]}
                                                 onTabChange={(index) => this.onSubTabChange(agIndex, index)}
                                                 toolbarCallbacks={this.toolbarCallbacks}
                                                 setPeriodTotal={this.setPeriodTotal}
                                                 onPCButtonClick={this.props.onPCButtonClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onRequestPreviewAccountItem={this.onRequestPreviewAccountItem}
                        />
                    </TabPanel>
                })
        });

        return (
            <ReportErrorBoundary>
                <div id="detailPanel">
                    <Toolbar right={accountsOutstandingToolBar(this.toolbarCallbacks, this.state.totalOutStanding)}/>

                    <Toast ref={(el) => {
                        this.emailSendGrowl = el;
                    }}/>

                    {this.showDialogs()}

                    <TabView scrollable={true}
                             style={{paddingTop: '5px'}}
                             activeIndex={this.state.canSave.activeIndex}
                             onTabChange={(e) => {
                                 this.onTabChange(e.index)
                             }}>

                        {this.tabStack.map(tab => {
                            return tab.content
                        })}

                    </TabView>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_OVER_DUE_ACCOUNTS.CLEAR);
    }
}

const mapStateToProps = (state) => {

    const currentState = state.stateManagement[SM_OVER_DUE_ACCOUNTS.id];
    const _state = currentState ? currentState.data : null;
    const agId = currentState ? _state.accountGroupData[_state.canSave.activeIndex].agId : 0;

    const loadedIndex = `${SM_OVER_DUE_ACCOUNTS.loaded}_${agId}`;
    const index = `${SM_OVER_DUE_ACCOUNTS.id}_${agId}`;

    return {
        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        accountGroupsLoaded: state.users.accountGroupsLoaded,
        accountGroups: state.users.accountGroups,

        PDFLoaded: state.reports[loadedIndex],
        pdfBuffer: state.reports[index],

        currentState,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAccountGroups: () => dispatch(getResource(RES_getAccountGroups.GET, null)),

        previewAccountItem: (details, previewToken) => dispatch(previewAccountItem(RES_sendAccountItem.PREVIEW, details, previewToken)),
        sendAccountItem: (details, message, idToken) => dispatch(sendAccountItem(RES_sendAccountItem.SEND, details, message, idToken)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const OutstandingAccounts = connect(mapStateToProps, mapDispatchToProps)(ConnectedOutstandingAccounts);

export default OutstandingAccounts;
