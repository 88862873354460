import {
    SM_APPOINTMENT,
    SM_APPOINTMENT_POPUPS,
    SM_APPTreatmentPlan,
    SM_NHSClaimHistory,
    SM_NHSRegistration,
    SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY,
    SM_PATIENT_DATA,
    SM_PATIENT_LAB_HISTORY, SM_PATIENT_MCONS, SM_PATIENT_MHFS,
    SM_patientChart
} from "../../actions/stateManagement";
import * as TC from "./Model/Constants";
import {addSurface} from "./Utils/general";

export const getChartingIds = (state, ownProps) => {

    if (ownProps.selectedSection === null) {
        return null
    }

    const patId = `_${ownProps.selectedSection.patientId}`;

    // ids
    const treatmentPlanLoadedId = Boolean(ownProps.selectedSection) ? `${SM_APPTreatmentPlan.loaded}_${ownProps.selectedSection.id}` : null;
    const treatmentPlanId = Boolean(ownProps.selectedSection) ? `${SM_APPTreatmentPlan.id}_${ownProps.selectedSection.id}` : null;

    const appointmentsActivePopUpsLoadedId = `${SM_APPOINTMENT_POPUPS.loaded}_${ownProps.appointmentId}`;
    const appointmentsActivePopUpsId = `${SM_APPOINTMENT_POPUPS.id}_${ownProps.appointmentId}`;

    const patientDataLoadedId = `${SM_PATIENT_DATA.loaded}${patId}`;
    const patientDataId = `${SM_PATIENT_DATA.id}${patId}`;

    let patientDataLoaded = state.patients[patientDataLoadedId];
    patientDataLoaded = patientDataLoaded === undefined ? false : patientDataLoaded;

    const patientTreatmentHistoryLoadedId = `${SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY.loaded}_${ownProps.selectedSection.patientId}`;
    const patientTreatmentHistoryId = `${SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY.id}_${ownProps.selectedSection.patientId}`;

    const patientLabHistoryLoadedId = `${SM_PATIENT_LAB_HISTORY.loaded}${patId}`;
    const patientLabHistoryId = `${SM_PATIENT_LAB_HISTORY.id}${patId}`;

    const chartLoadedId = `${SM_patientChart.loaded}_${ownProps.appointmentId}`;
    const chartId = `${SM_patientChart.id}_${ownProps.appointmentId}`;

    // data
    const treatmentPlanLoaded = state.treatmentPlanning[treatmentPlanLoadedId] === undefined ? false : state.treatmentPlanning[treatmentPlanLoadedId];
    const treatmentPlan = state.treatmentPlanning[treatmentPlanId];

    const appointmentsActivePopUpsLoaded = state.patients[appointmentsActivePopUpsLoadedId] === undefined ? false : state.patients[appointmentsActivePopUpsLoadedId];
    const appointmentsActivePopUps = state.patients[appointmentsActivePopUpsId];

    const patientTreatmentHistoryLoaded = state.patients[patientTreatmentHistoryLoadedId] === undefined ? false : state.patients[patientTreatmentHistoryLoadedId];
    const patientTreatmentHistory = state.patients[patientTreatmentHistoryId];

    const patientLabHistoryLoaded = state.laboratories[patientLabHistoryLoadedId] === undefined ? false : state.laboratories[patientLabHistoryLoadedId];
    const patientLabHistory = state.laboratories[patientLabHistoryId];

    const resourcesLoaded = state.chartResources.resourcesLoaded;
    const resources = resourcesLoaded ? state.chartResources.resources.resources : null;

    const chartLoaded = state.chartResources[chartLoadedId] === undefined ? false : state.chartResources[chartLoadedId];
    const charting = state.chartResources[chartId];

    const smAppointmentId = `${SM_APPOINTMENT.id}_${ownProps.appointmentId}`;

    const accountGroupsLoaded = state.users.accountGroupsLoaded;

    const templateNotesLoaded = Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false;
    const templateNotes = templateNotesLoaded ? state.housekeeping.templateNotes : [];
    const defaultPaymentNote = state.housekeeping.defaultPaymentNote;

    const dropDownsLoaded = state.dropDowns.dropDownsLoaded;

    const appointmentDiaryTypesLoaded = state.housekeeping.appointmentDiaryTypesLoaded;
    const appointmentTypes = state.housekeeping.appointmentDiaryTypes.appointmentTypes;

    const journeyStagesLoaded = state.housekeeping.journeyStagesLoaded;
    const journeyStages = state.housekeeping.journeyStages;

    const baseCategoryGroups = state.chartResources.resources.baseCategoryGroups;
    const favCategoryGroups = state.chartResources.resources.favCategoryGroups;

    const treatmentCodesLoaded = Boolean(state.housekeeping.treatmentCodesLoaded);
    const treatmentCodes = treatmentCodesLoaded ? state.housekeeping.treatmentCodes : [];

    const NHSClaimHistoryLoadedId = `${SM_NHSClaimHistory.loaded}_${ownProps.selectedSection.patientId}`;
    const NHSClaimHistoryId = `${SM_NHSClaimHistory.id}_${ownProps.selectedSection.patientId}`;

    let NHSClaimHistoryLoaded = state.patients[NHSClaimHistoryLoadedId];
    NHSClaimHistoryLoaded = NHSClaimHistoryLoaded === undefined ? false : NHSClaimHistoryLoaded;

    const NHSRegistrationLoadedId = `${SM_NHSRegistration.loaded}${patId}`;
    const NHSRegistrationId = `${SM_NHSRegistration.id}${patId}`;

    let NHSRegistrationLoaded = state.patients[NHSRegistrationLoadedId];
    NHSRegistrationLoaded = NHSRegistrationLoaded === undefined ? false : NHSRegistrationLoaded;

    const patientMHFSLoadedId = `${SM_PATIENT_MHFS.loaded}${patId}`;
    const patientMHFSId = `${SM_PATIENT_MHFS.id}${patId}`;

    const patientMCONSLoadedId = `${SM_PATIENT_MCONS.loaded}${patId}`;
    const patientMCONSId = `${SM_PATIENT_MCONS.id}${patId}`;

    let patientMHFSLoaded = state.patients[patientMHFSLoadedId];
    patientMHFSLoaded = patientMHFSLoaded === undefined ? false : patientMHFSLoaded;

    let patientMCONSLoaded = state.patients[patientMCONSLoadedId];
    patientMCONSLoaded = patientMCONSLoaded === undefined ? false : patientMCONSLoaded;

    return {

        treatmentPlanLoaded,
        treatmentPlan,

        patientTreatmentHistoryLoaded,
        patientTreatmentHistory,

        patientLabHistoryLoaded,
        patientLabHistory,

        appointmentsActivePopUpsLoaded,
        appointmentsActivePopUps,

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        chartLoaded,
        charting,

        smAppointmentId,

        accountGroupsLoaded,

        templateNotesLoaded,
        templateNotes,
        defaultPaymentNote,

        dropDownsLoaded,

        appointmentDiaryTypesLoaded,
        appointmentTypes,

        journeyStagesLoaded,
        journeyStages,

        NHSRegistrationId,
        NHSRegistrationLoaded,

        NHSClaimHistoryId,
        NHSClaimHistoryLoaded,

        patientDataId,
        patientDataLoaded,

        baseCategoryGroups,
        favCategoryGroups,

        patientMHFSLoaded,
        patientMHFSId,

        patientMCONSLoaded,
        patientMCONSId,
    }
};

export const replaceTooth = (adultPlaceHolders, adultTeeth, childPlaceHolders, childTeeth, jaw, toothType, position, adult) => {

    if (adult) {
        adultPlaceHolders.push({
            ctype: TC.PlaceHolderEP,
            adult: true,
            jaw: jaw,
            type: toothType,
            pos: position,
            present: true,
            charting: [],
            drifted: null,
            selected: false,
            partOfSpan: false,
        });

        const data = {
            ctype: TC.ToothEP,
            adult: true,
            jaw: jaw,
            type: toothType,
            pos: position,
            present: true,
            charting: [],
            surfaces: null,
            unerupted: false,
            partiallyErupted: false,
            overErupted: false,
            impacted: false,
            deciduous: false,
            fractured: false,
            chipped: false,
            watch: false,
            tm: TC.TMNone,
            post: {present: false, material: {}},
            postAndCore: {present: false, material: {}},
            bondedCrown: {present: false, material: {}},
            fullCrown: {present: false, material: {}},
            jacketCrown: {present: false, material: {}},
            veneer: {present: false, material: {}},
            onlay: {present: false, material: {}},
            dontShowInChart: false,
            selected: false,
        };
        data.surfaces = addSurface(data);
        adultTeeth.push(data);
    } else {
        childPlaceHolders.push({
            ctype: TC.PlaceHolderEP,
            adult: false,
            jaw: jaw,
            type: toothType,
            pos: position,
            present: true,
            charting: [],
            drifted: null,
            selected: false,
            partOfSpan: false,
        });

        const data = {
            ctype: TC.ToothEP,
            adult: false,
            jaw: jaw,
            type: toothType,
            pos: position,
            present: true,
            charting: [],
            surfaces: null,
            unerupted: false,
            partiallyErupted: false,
            overErupted: false,
            impacted: false,
            deciduous: false,
            fractured: false,
            chipped: false,
            watch: false,
            tm: TC.TMNone,
            post: {present: false, material: {}},
            postAndCore: {present: false, material: {}},
            bondedCrown: {present: false, material: {}},
            fullCrown: {present: false, material: {}},
            jacketCrown: {present: false, material: {}},
            veneer: {present: false, material: {}},
            onlay: {present: false, material: {}},
            dontShowInChart: false,
            selected: false,
        };
        data.surfaces = addSurface(data);
        this.state.childTeeth.push(data);
    }
}