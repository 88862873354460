import React from 'react';
import _ from "lodash";

import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {HM_notImplemented} from "../../../../Constants";
import {ICON_PRINT, ICON_SCREEN,} from "../../../../icons";
import {ProgressBar} from "primereact/progressbar";
import {Button} from "primereact/components/button/Button";
import {TextLabHistory} from "../../../../Messages";
import {dateTemplateVeryShort} from "../../../PatientDynamicItems/Utils";
import {buildWRMenu, byDateTemplate, forAppointmentTemplate, statusTemplate} from "../../../Utils";

const showDialogs = (props) => {

    if (props.editing) {

        return null;
    }
};

export const LabHistorySection = (props) => {

    if (!props.patientLabHistoryLoaded) {
        return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
    }

    const {patientLabHistory, labEntryRows, firstLabEntry, selectedLabEntry} = props;

    const sortedLabHistory = _.orderBy(patientLabHistory, 'returnDate', 'asc');

    const items = buildWRMenu(props);

    let cm = null;

    const barHeader = <div className='p-panel-header'>
        <div className="items-margin d-flex d-align-center">
            <span className='p-panel-title' style={{marginRight: 15}}>Laboratory History</span>
        </div>
        <div className="items-margin d-flex d-align-center">
            <Button icon={ICON_SCREEN}
                    tooltip={'Show ' + TextLabHistory}
                    tooltipOptions={{position: 'top'}}
                    onClick={(e) => {
                        props.toolbarCallbacks[HM_notImplemented.id]();
                    }}/>
            <Button icon={ICON_PRINT}
                    tooltip={'Print ' + TextLabHistory}
                    tooltipOptions={{position: 'top'}}
                    onClick={(e) => {
                        props.toolbarCallbacks[HM_notImplemented.id]();
                    }}/>
        </div>
    </div>;

    return (
        <Panel headerTemplate={barHeader}>

            {showDialogs(props)}

            <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

            <DataTable value={sortedLabHistory}
                       className='p-datatable-gridlines'
                       style={{fontSize: 'small'}}
                       selectionMode="single"
                       paginator={true}
                       rows={labEntryRows}
                       rowsPerPageOptions={[5, 10, 20]}
                       onPage={(e) => {
                           props.onPageFlex(e, 'firstLabEntry', 'labEntryRows');
                       }}
                       first={firstLabEntry}
                       selection={selectedLabEntry}
                       onSelectionChange={(e) => props.setValue('selectedLabEntry', e.value)}
                       contextMenuSelection={selectedLabEntry}
                       onContextMenuSelectionChange={(e) => props.setValue('selectedLabEntry', e.value)}
                       onContextMenu={e => cm.show(e.originalEvent)}
            >
                <Column header="" body={(rowData) => statusTemplate(rowData)} style={{width: '2%'}}/>
                <Column header="Requested" body={(rowData) => dateTemplateVeryShort(rowData.requestedOn)}
                        style={{width: '8%', textAlign: 'right'}}/>
                <Column header="Laboratory" body={(rowData) => rowData.laboratoryName} style={{width: '13%'}}/>
                <Column header="Notes" body={(rowData) => rowData.note} style={{width: '13%'}}/>
                <Column header="Provider" body={(rowData) => rowData.providerName} style={{width: '7%'}}/>
                <Column header="By Date" body={byDateTemplate} style={{width: '7%', textAlign: 'right'}}/>
                <Column header="Appointment" body={forAppointmentTemplate}
                        style={{width: '8%', textAlign: 'right'}}/>
                <Column header="Received" body={(rowData) => dateTemplateVeryShort(rowData.receivedOn)}
                        style={{width: '8%', textAlign: 'right'}}/>
                <Column header="By" body={(rowData) => rowData.receivedBy} style={{width: '7%'}}/>
                <Column header="Approved" body={(rowData) => dateTemplateVeryShort(rowData.approvedOn)}
                        style={{width: '8%', textAlign: 'right'}}/>
                <Column header="By" body={(rowData) => rowData.approvedBy} style={{width: '7%'}}/>
            </DataTable>
        </Panel>
    )
};
