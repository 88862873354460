import classNames from "classnames";

export const clientLayoutClassName = classNames('layout-wrapper', {
    'menu-layout-static': true,
    'menu-layout-overlay': false,
    'layout-menu-overlay-active': false,
    'menu-layout-slim': false,
    'menu-layout-horizontal': false,
    'layout-menu-static-inactive': true,
    'layout-menu-static-active': false
});
export const PAYMENT_PLAN_HISTORY = 0;
export const PAYMENT_PLAN = 0;

export const SE_NONE = 'SE_NONE';

export const SE_ADD_PERSONAL_DETAILS = 'SE_ADD_PERSONAL_DETAILS';
export const SE_ADD_REFERRER_DETAILS = 'SE_ADD_REFERRER_DETAILS';

export const COLOR_ChiralBorder = '#5181CA';
export const COLOR_ChiralPalette = '#1a92d0';

export const SE_APPOINTMENT_HISTORY = 'SE_APPOINTMENT_HISTORY';
export const SE_BOOK_APPOINTMENT = 'SE_BOOK_APPOINTMENT';
export const SE_ADD_MEDICAL_HISTORY = 'SE_ADD_MEDICAL_HISTORY';
export const SE_ADD_QUESTIONNAIRE = 'SE_ADD_QUESTIONNAIRE';
export const SE_UPLOAD_XRAY_IMAGE = 'SE_UPLOAD_XRAY_IMAGE';
export const SE_SIGN_CONSENT = 'SE_SIGN_CONSENT';
export const SE_SIGN_GDPR_CONSENT = 'SE_SIGN_GDPR_CONSENT';
export const SE_SIGN_GDPR_CONSENT_RETRY = 'SE_SIGN_GDPR_CONSENT_RETRY';
export const SE_PAYMENT_PLANNING = 'SE_PAYMENT_PLANNING';
export const SE_PAYMENT_PLANNING_CONSENT = 'SE_PAYMENT_PLANNING_CONSENT';
export const SE_TREATMENT_PLANNING = 'SE_TREATMENT_PLANNING';
export const SE_TREATMENT_PLANNING_CONSENT = 'SE_TREATMENT_PLANNING_CONSENT';
export const SE_PRESCRIPTION_HISTORY = 'SE_PRESCRIPTION_HISTORY';
export const SE_DOCUMENTS = 'SE_DOCUMENTS';

export const SE_BOOK_CHAT = 'SE_BOOK_CHAT';
export const SE_CHAT = 'SE_CHAT';

export const SE_ONLINE_BOOKED = 'OnlineBooked';
export const SE_ONLINE_VC_BOOKED = 'OnlineVCBooked';

export const SE_BOOK_VIDEO_CALL = 'SE_BOOK_VIDEO_CALL';
export const SE_VIDEO_CALL = 'SE_VIDEO_CALL';

export const VIDEO_OWNER_PRACTICE = 'VIDEO_OWNER_PRACTICE';
export const VIDEO_OWNER_PATIENT = 'VIDEO_OWNER_PATIENT';

export const PATIENT_CONNECTED = 'PATIENT_CONNECTED';
export const PATIENT_CONNECTED_RESULT = 'PATIENT_CONNECTED_RESULT';

export const PATIENT_ANSWER = 'PATIENT_ANSWER';
export const PATIENT_CANDIDATE = 'PATIENT_CANDIDATE';

export const PATIENT_DISCONNECTED = 'PATIENT_DISCONNECTED';
export const PATIENT_DISCONNECTED_RESULT = 'PATIENT_DISCONNECTED_RESULT';

export const PRACTICE_CONNECTED = 'PRACTICE_CONNECTED';
export const PRACTICE_CONNECTED_RESULT = 'PRACTICE_CONNECTED_RESULT';

export const PRACTICE_OFFER = 'PRACTICE_OFFER';
export const PRACTICE_CANDIDATE = 'PRACTICE_CANDIDATE';

export const PRACTICE_DISCONNECTED = 'PRACTICE_DISCONNECTED';
export const PRACTICE_DISCONNECTED_RESULT = 'PRACTICE_DISCONNECTED_RESULT';

export const VIDEO_ERROR = 'ERROR';
export const VIDEO_OK = 'OK';

export const WS_SDP_CLOSED = 'WS_SDP_CLOSED';
export const WS_SDP_OPEN = 'WS_SDP_OPEN';

// cal status

export const CS_ON_HOOK = 'CS_ON_HOOK';

//

const ICON_SAVE = 'pi pi-check';
const ICON_BACK = 'far fa-caret-square-up';

const ICON_CALL = 'fas fa-phone';
const ICON_SHARE = 'far fa-share-square';

const LABEL_SAVE = 'Save';
const LABEL_BACK = 'Return';

export const SM_CLIENT_PATIENT_DETAILS = {
    label: 'My Details',
    icon: 'far fa-user-circle',
};

export const SM_CLIENT_PATIENT_DETAIL = {
    loaded: 'SM_TAB_PATIENT_DETAILS_LOADED',
    id: 'SM_CLIENT_PATIENT_DETAILS',
    label: 'Personal Information',
    detail: 'Patient Details',
    icon: 'far fa-question-circle',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE
};

export const SM_CLIENT_PATIENT_DETAILS_SIG = {
    label: 'Patient Signature',
};

export const SM_CLIENT_BOOK_APPOINTMENT = {
    loaded: 'SM_CLIENT_BOOK_APPOINTMENT_LOADED',
    id: 'SM_CLIENT_BOOK_APPOINTMENT',
    label: 'Book Appointment',
    detail: 'Book an appointment',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_APPOINTMENTS = {
    label: 'My Appointments',
    icon: 'far fa-calendar-alt',
};

export const SM_CLIENT_APPOINTMENT_HISTORY = {
    loaded: 'SM_CLIENT_APPOINTMENT_HISTORY_LOADED',
    id: 'SM_CLIENT_APPOINTMENT_HISTORY',
    label: 'My History',
    detail: 'Appointment History',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_DOCUMENTS = {
    loaded: 'SM_CLIENT_DOCUMENTS_LOADED',
    id: 'SM_CLIENT_DOCUMENTS_HISTORY',
    label: 'My Documents',
    detail: 'Here are your documents',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_BOOK_VIDEO_CALL = {
    loaded: 'SM_CLIENT_BOOK_VIDEO_CALL_LOADED',
    id: 'SM_CLIENT_BOOK_VIDEO_CALL',
    label: 'Book Video Call',
    detail: 'Book a video call',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_VIDEO_CALL = {
    loaded: 'SM_CLIENT_VIDEO_CALL_LOADED',
    id: 'SM_CLIENT_VIDEO_CALL',
    label: 'Video Call',
    detail: 'Join a video call',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
    callIcon: ICON_CALL,
};

export const SM_CLIENT_VIDEO_CALL_SHARE_SCREEN = {
    loaded: 'SM_CLIENT_VIDEO_CALL_SHARE_SCREEN_LOADED',
    id: 'SM_CLIENT_VIDEO_CALL_SHARE_SCREEN',
    label: 'Share Screen',
    detail: 'Share a part or all of your screen',
    icon: ICON_SHARE,
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_UPLOAD_IMAGE = {
    loaded: 'SM_CLIENT_UPLOAD_IMAGE_LOADED',
    id: 'SM_CLIENT_UPLOAD_IMAGE',
    label: 'Upload XRay / Images',
    detail: 'Upload XRay / Images',
    icon: 'far fa-image',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_PRACTICE_CHAT = {
    loaded: 'SM_CLIENT_PRACTICE_CHAT_LOADED',
    id: 'SM_CLIENT_PRACTICE_CHAT',
    label: 'Chat with Practice',
    detail: 'Chat with Practice',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_PATIENT_MED_CON = {
    loaded: 'SM_CLIENT_PATIENT_MED_CON_LOADED',
    id: 'SM_CLIENT_PATIENT_MED_CON',
    label: 'Medical History',
    detail: 'Medical History',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_SAVE,
    exitLabelSM: '',
    exitIcon: ICON_SAVE,
};

export const SM_CLIENT_PATIENT_QUESTIONNAIRE = {
    loaded: 'SM_CLIENT_PATIENT_QUESTIONNAIRE_LOADED',
    id: 'SM_CLIENT_PATIENT_QUESTIONNAIRE',
    label: 'Questionnaire',
    detail: 'Questionnaire',
    o2Saturation: 'Enter Patient Sp02 (mm Hg)',
    temperature: 'Enter Patient Temperature oC',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE,
};

export const SM_CLIENT_PATIENT_MED_CON_SIG = {
    label: 'Patient Signature',
};

export const SM_CLIENT_PATIENT_QUESTIONNAIRE_SIG = {
    label: 'Patient Signature',
};

export const SM_CLIENT_TREATMENT_PLANS = {
    label: 'My Treatment Plans',
    icon: 'fas fa-list-ol',
}

export const SM_CLIENT_TREATMENT_PLAN_PDF = {
    loaded: 'SM_CLIENT_TREATMENT_PLAN_PDF_LOADED',
    id: 'SM_CLIENT_TREATMENT_PLAN_PDF',
    label: 'Treatment Plan',
    icon: 'fas fa-list-ol',
    filename: 'TreatmentPlan'
}

export const SM_CLIENT_TREATMENT_PLANNING = {
    loaded: 'SM_CLIENT_TREATMENT_PLANNING_LOADED',
    id: 'SM_CLIENT_TREATMENT_PLANNING',
    label: 'My History',
    detail: 'My Treatment Planning',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_TREATMENT_PLANNING_CONSENT = {
    label: 'My Consent',
    detail: 'Treatment Planning Consent',
    icon: 'far fa-check-circle',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE,
};

export const SM_CLIENT_PAYMENT_PLANS = {
    label: 'My Payment Plans',
    icon: 'fas fa-list-ol',
}

export const SM_CLIENT_PAYMENT_PLANNING = {
    loaded: 'SM_CLIENT_PAYMENT_PLANNING_LOADED',
    id: 'SM_CLIENT_PAYMENT_PLANNING',
    label: 'My History',
    detail: 'My Payment Planning',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_PAYMENT_PLANNING_CONSENT = {
    label: 'My Consent',
    detail: 'Payment Planning Consent',
    icon: 'far fa-check-circle',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE,
};

export const SM_CLIENT_CONSENT_FORMS = {
    label: 'Consent Forms',
    detail: 'Consent Forms',
    icon: 'far fa-check-circle',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE,
};

export const SM_CLIENT_CONSENT_FORMS_EMPTY = {
    label: 'You don\'t currently have any consent forms to sign',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_PRESCRIPTIONS = {
    label: 'My Prescriptions',
    icon: 'far fa-list-alt',
};

export const SM_CLIENT_PRESCRIPTION_HISTORY = {
    loaded: 'SM_CLIENT_PRESCRIPTION_HISTORY_LOADED',
    id: 'SM_CLIENT_PRESCRIPTION_HISTORY',
    label: 'My History',
    detail: 'My Prescription History',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_REFERRER_DETAILS = {
    loaded: 'SM_CLIENT_REFERRER_DETAILS_LOADED',
    id: 'SM_CLIENT_REFERRER_DETAILS',
    label: 'Referrer Details',
    detail: 'Referrer Details',
    icon: 'far fa-question-circle',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE,
};

export const RS_LOGIN = 'RS_LOGIN';
export const RS_LOGIN_DIRECT = 'RS_LOGIN_DIRECT';
export const RS_LOGIN_FAILURE = 'RS_LOGIN_FAILURE';
export const RS_START = 'RS_START';
export const RS_EXISTING_DETAILS = 'RS_EXISTING_DETAILS';
export const RS_EXISTING_DETAILS_RESEND = 'RS_EXISTING_DETAILS_RESEND';
export const RS_NEW_DETAILS = 'RS_NEW_DETAILS';
export const RS_NEW_DETAILS2 = 'RS_NEW_DETAILS2';
export const RS_AVAILABILITY = 'RS_AVAILABILITY';
export const RS_MATCH_RESULT = 'RS_MATCH_RESULT';
export const RS_CODE_ENTRY = 'RS_CODE_ENTRY';
export const RS_FORGOT_PASSWORD = 'RS_FORGOT_PASSWORD';
