import React from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {ac} from "../../../index";

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Calendar} from 'primereact/components/calendar/Calendar';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    ACC_PAYMENT,
    ACC_RECEIPT,
    ACC_REFUND,
    HM_AccountGroupSelector,
    HM_REP_DAILY_CASH,
    HM_ShowPayment,
    HM_ShowReceipt,
    HM_ShowRefund,
    JSON_DATE_FORMAT,
    REP_Payment,
    REP_Receipt,
    REP_Refund,
    REPORT_SHOW,
    RT_PAYMENT_COMMENT,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_EXCEL, ICON_GOTO, ICON_HELP, ICON_PRINT, ICON_SHOW,} from "../../../icons";
import {TextDaysCash, TextDaysCashExcel} from "../../../Messages";
import {
    HELP_REP_DAYS_CASH,
    setState,
    SM_PATIENT_ACCOUNT_ITEM_PDF,
    SM_patientDataShort,
    SM_REPORTS_DAILY_CASH,
    SM_REPORTS_DAILY_CASH_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import {
    getReport,
    getReportPDF,
    getReportText,
    RES_REPORT_ACC_ITEMS,
    RES_REPORT_DAILYCASH,
    RES_REPORT_TEXTS
} from "../../../actions/reports";

import {Panel} from "primereact/panel";
import {ProgressBar} from "primereact/progressbar";
import {currencyTemplate, menuButtonTemplate2} from "../fixedItemUtils";
import AccountItemsPDF from "./PDFs/AccountItemsPDF";
import {TB_CHANGE_PAY_TYPE} from "../PatientDetails/Constants";
import {dateTemplateShort, dateTemplateVeryShort} from "../../PatientDynamicItems/Utils";
import _ from "lodash";
import * as Actions from "../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import AccountGroupSelector from "./dialogs/AccountGroupSelector";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {getResource as getPatResource, RES_PATIENT_DETAILS_SHORT} from "../../../actions/personal";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_DAY, DIARY_FOR_DAY} from "../Diary/Constants";

const PAY_COLS = ["Receipt", "Payment Type", "Reference", "Patient", "Provider", "Total"];
const REFUND_COLS = ["Refund", "Against", "Patient", "Total"];
const TOTAL_COLS = ["Type", "Total"];
const GRAND_TOTAL_COLS = ["Receipt Total", "Refund Total", "GrandTotal"];

export class ConnectedDailyCashBook extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                currentDate: new Date(),

                dailyCashLoaded: false,

                selectedItem: null,
                selectedRefund: null,
                selectedAccountGroup: null,

                firstItem: 0,
                itemRows: 5,

                firstRefund: 0,
                refundRows: 5,

                pdfRequest: PDF_REQUEST_NONE,

                [TB_CHANGE_PAY_TYPE.id]: false,
                [HM_AccountGroupSelector.id]: true,
            }
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_DAILYCASH.GET.receive:
                    this.setState({dailyCashLoaded: true}, () => {
                        this.props.setState(SM_REPORTS_DAILY_CASH.id, this.state);
                    });
                    break;
                case RES_REPORT_DAILYCASH.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_DAILY_CASH_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                case RES_REPORT_ACC_ITEMS.PAY.receive:
                case RES_REPORT_ACC_ITEMS.RCT.receive:
                case RES_REPORT_ACC_ITEMS.REF.receive:
                    this.setState({
                        pdfBuffer: this.props.itemPDFBuffer,
                        PDFLoaded: true
                    }, () => {
                        super.onPrintReport(`${this.state.selectedNumber} : ${dateTemplateShort(this.state.currentDate)}`);
                    })
                    break;
                case Actions.RECEIVE_PATIENT_DETAILS_SHORT:
                    const {
                        id,
                        firstName,
                        lastName,
                        gender,
                        nhsPatient,
                        billingGroup,
                        mobile
                    } = this.props.patientDataShort;
                    showPatientDetailsPage({
                        props: this.props,
                        id,
                        firstName,
                        lastName,
                        gender,
                        nhsPatient,
                        groupId: billingGroup,
                        mobile
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowAccountItem = (selection) => {

        const options = {
            itemId: this.state.selectedId,
            report: selection.item.report,
            reportFunction: selection.item.reportFunction,
            number: this.state.selectedNumber,
            period: undefined,
            target: selection.item.target,
        };

        const props = {...this.props, options};
        this.props.onPCButtonClick(menuButtonTemplate2(props, AccountItemsPDF, `${options.number}`, 'fas fa-file-pdf', `${options.number}`, null, options));
    }

    refundReference = (row) => {
        if (row.paymentString !== null)
            return row.paymentString;
        else
            return row.receiptString;
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(SM_REPORTS_DAILY_CASH.id, this.state);
        });
    }

    onDateChange = (e) => {

        this.props.getReport({id: this.state.selectedAccountGroup.id, date: moment(e.value).format(JSON_DATE_FORMAT)});
        this.setState({currentDate: e.value, pdfBuffer: null, PDFLoaded: false}, () => {
            this.props.setState(SM_REPORTS_DAILY_CASH.id, this.state);
        });
    }

    onDateChange2 = (forward) => {

        let date = this.state.currentDate;

        date = moment(date).add(forward ? 1 : -1, 'days');
        date = date.toDate();

        this.props.getReport({id: this.state.selectedAccountGroup.id, date: moment(date).format(JSON_DATE_FORMAT)});
        this.setState({currentDate: date, pdfBuffer: null, PDFLoaded: false}, () => {
            this.props.setState(SM_REPORTS_DAILY_CASH.id, this.state);
        });
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_DAILY_CASH_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                const params = {
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    id: this.state.selectedAccountGroup.id
                };
                this.props.getReportPDF(params);
            })
        }
    }

    onDownload = () => {

        const url = `https://${ac.getBASERESTURL()}${RES_REPORT_DAILYCASH.XLS.url}?mcId=${ac.getMcId()}&id=${this.state.selectedAccountGroup.id}&date=${moment(this.state.currentDate).format(JSON_DATE_FORMAT)}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `dc${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                    a.click();
                });
            });
    }

    onShowPrintPayment = () => {

        const options = {
            id: this.state.selectedItem.id,
            report: ACC_PAYMENT,
            reportFunction: REPORT_SHOW,
        };

        this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
            this.props.getItemPDF(RES_REPORT_ACC_ITEMS.PAY, options);
        })
    }

    onShowPrintReceipt = () => {

        const options = {
            id: this.state.selectedItem.id,
            report: ACC_RECEIPT,
            reportFunction: REPORT_SHOW,
        };

        this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
            this.props.getItemPDF(RES_REPORT_ACC_ITEMS.RCT, options);
        })
    }

    onShowPrintRefund = () => {

        const options = {
            id: this.state.selectedRefund.id,
            report: ACC_REFUND,
            reportFunction: REPORT_SHOW,
        };

        this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
            this.props.getItemPDF(RES_REPORT_ACC_ITEMS.REF, options);
        })
    }

    onSetAccountGroup = (accountGroup) => {

        this.setState({selectedAccountGroup: accountGroup, [HM_AccountGroupSelector.id]: false}, () => {
            this.props.getReport({
                id: this.state.selectedAccountGroup.id,
                date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
            });
            this.props.reportText(RT_PAYMENT_COMMENT.name);
            this.props.setState(this.state.stateManagementId, this.state);
        });
    }

    buildContextMenu = () => {

        if (this.state.selectedItem === null) {
            return [];
        } else if (this.state.selectedItem.entryType === ACC_PAYMENT) {
            return [
                {
                    label: 'Show Payment',
                    icon: ICON_SHOW,
                    command: this.onShowAccountItem,
                    target: HM_ShowPayment.id,
                    report: REP_Payment.value,
                    reportFunction: REPORT_SHOW
                },
                {label: 'Print Payment', icon: ICON_PRINT, command: this.onShowPrintPayment, print: true},
                {
                    label: 'Show this patients account', icon: ICON_GOTO, command: (e) => {
                        this.props.getPatientDetails({patientId: this.state.selectedItem.patientId});
                    }
                },
            ]
        } else if (this.state.selectedItem.entryType === ACC_RECEIPT) {
            return [
                {
                    label: 'Show Receipt',
                    icon: ICON_SHOW,
                    command: this.onShowAccountItem,
                    target: HM_ShowReceipt.id,
                    report: REP_Receipt.value,
                    reportFunction: REPORT_SHOW
                },
                {label: 'Print Receipt', icon: ICON_PRINT, command: this.onShowPrintReceipt, print: true},
                {
                    label: 'Show this patients account', icon: ICON_GOTO, command: (e) => {
                        this.props.getPatientDetails({patientId: this.state.selectedItem.patientId});
                    }
                },
            ]
        }
    }

    showDialogs = () => {

        const contents = [];

        if (this.state[HM_AccountGroupSelector.id]) {
            contents.push(
                <AccountGroupSelector key={HM_AccountGroupSelector.id}
                                      onOkDialog={this.onSetAccountGroup}
                />
            )
        }
        return contents;
    }

    render() {

        if (this.state.selectedAccountGroup !== null && !this.props.dailyCashLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const accountGroupName = this.state.selectedAccountGroup === null ? '-' : this.state.selectedAccountGroup.groupName;

        const gtHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{HM_REP_DAILY_CASH.headerGrandTotals}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                Daily Cash
                : {`(${dateTemplateVeryShort(this.state.currentDate)}) - ${accountGroupName}`}
            </div>
        </div>;

        const itemsRefunds = [
            {
                label: 'Show Refund',
                icon: ICON_SHOW,
                command: this.onShowAccountItem,
                target: HM_ShowRefund.id,
                report: REP_Refund.value,
                reportFunction: REPORT_SHOW
            },
            {label: 'Print Refund', icon: ICON_PRINT, command: this.onShowPrintRefund, print: true},
        ];

        const filterItems = _.filter(this.props.items, item => item.amount !== 0);

        const sortedFilterItems = _.orderBy(filterItems, ['number'], ['asc']);

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate((e) => this.onDateChange2(false), DIARY_BACK_DAY.icon, DIARY_BACK_DAY.tooltip, key++)}
                            {tb_boilerPlate((e) => this.onDateChange2(true), DIARY_FOR_DAY.icon, DIARY_FOR_DAY.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextDaysCash, key++, false)}
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${TextDaysCashExcel}`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_DAYS_CASH), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    {/*Grand Totals*/}

                    <Panel headerTemplate={gtHeader} style={{marginTop: '5px'}}>

                        <DataTable value={this.props.grandTotals}
                                   className='p-datatable-gridlines'
                                   style={{marginTop: '5px', fontSize: 'small'}}
                        >
                            <Column body={row => currencyTemplate(row, 'receiptTotal')}
                                    header={GRAND_TOTAL_COLS[0]}
                                    style={{width: '33%', textAlign: 'right'}}/>
                            <Column body={row => currencyTemplate(row, 'refundTotal')}
                                    header={GRAND_TOTAL_COLS[1]}
                                    style={{width: '33%', textAlign: 'right'}}/>
                            <Column body={row => currencyTemplate(row, 'grandTotal')}
                                    header={GRAND_TOTAL_COLS[2]}
                                    style={{width: '33%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>

                    {/*Receipts*/}

                    <ContextMenu model={this.buildContextMenu()}
                                 style={{width: '18em'}}
                                 ref={el => this.cmReceipts = el}/>

                    <Panel header={HM_REP_DAILY_CASH.headerRecPay} style={{marginTop: '5px'}}>

                        <DataTable value={sortedFilterItems}
                                   className='p-datatable-gridlines'
                                   selectionMode="single"
                                   selection={this.state.selectedItem}
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e => {
                                       this.setState({
                                           selectedItem: e.value,
                                           selectedId: e.value.id,
                                           selectedNumber: e.value.number
                                       }, () => {
                                           this.props.setState(SM_REPORTS_DAILY_CASH.id, this.state);
                                       })
                                   }}
                                   onContextMenu={e => {
                                       this.cmReceipts.show(e.originalEvent);
                                       this.cmRefunds.hide(e.originalEvent)
                                   }}
                                   paginator={true}
                                   rows={this.state.itemRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'firstItem', 'itemRows')}
                                   first={this.state.firstItem}
                                   onSelectionChange={(e) => {
                                       this.setState({
                                           selectedItem: e.value,
                                           selectedId: e.value.id,
                                           selectedNumber: e.value.number
                                       }, () => {
                                           this.props.setState(SM_REPORTS_DAILY_CASH.id, this.state);
                                       })
                                   }}
                                   style={{fontSize: 'small'}}
                        >
                            <Column field="number"
                                    header={PAY_COLS[0]}
                                    style={{width: '20%'}}/>
                            <Column field="formattedType"
                                    header={PAY_COLS[1]}
                                    style={{width: '10%'}}/>
                            <Column field="reference"
                                    header={PAY_COLS[2]}
                                    style={{width: '20%'}}/>
                            <Column field="patientName"
                                    header={PAY_COLS[3]}
                                    style={{width: '30%'}}/>
                            <Column field="username"
                                    header={PAY_COLS[4]}
                                    style={{width: '10%'}}/>
                            <Column body={(row) => currencyTemplate(row, 'formattedTotal')}
                                    header={PAY_COLS[5]}
                                    style={{width: '10%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                    {/*Refunds*/}

                    <ContextMenu model={itemsRefunds} ref={el => this.cmRefunds = el}/>

                    <Panel header={HM_REP_DAILY_CASH.headerRefund} style={{marginTop: '5px'}}>

                        <DataTable value={this.props.refunds}
                                   className='p-datatable-gridlines'
                                   selectionMode="single"
                                   selection={this.state.selectedRefund}
                                   contextMenuSelection={this.state.selectedRefund}
                                   onContextMenuSelectionChange={e => {
                                       this.setState({
                                           selectedRefund: e.value,
                                           selectedId: e.value.id,
                                           selectedNumber: e.value.number
                                       }, () => {
                                           this.props.setState(SM_REPORTS_DAILY_CASH.id, this.state);
                                       })
                                   }}
                                   onContextMenu={e => {
                                       this.cmRefunds.show(e.originalEvent);
                                       this.cmReceipts.hide(e.originalEvent)
                                   }}
                                   paginator={true}
                                   rows={this.state.refundRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'firstRefund', 'refundRows')}
                                   first={this.state.firstRefund}
                                   onSelectionChange={(e) => {
                                       this.setState({
                                           selectedRefund: e.value,
                                           selectedId: e.value.id,
                                           selectedNumber: e.value.number
                                       }, () => {
                                           this.props.setState(SM_REPORTS_DAILY_CASH.id, this.state);
                                       })
                                   }}
                                   style={{marginTop: '5px', fontSize: 'small'}}
                        >
                            <Column field="refundNumber"
                                    header={REFUND_COLS[0]}
                                    style={{width: '20%'}}/>
                            <Column header={REFUND_COLS[1]}
                                    body={this.refundReference}
                                    style={{width: '20%'}}/>
                            <Column field="patientName"
                                    header={REFUND_COLS[2]}
                                    style={{width: '50%'}}/>
                            <Column body={(row) => currencyTemplate(row, 'formattedAmount')}
                                    header={REFUND_COLS[3]}
                                    style={{width: '10%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                    {/*Receipt Totals*/}

                    <Panel header={HM_REP_DAILY_CASH.headerRecPayTotals} style={{marginTop: '5px'}}>

                        <DataTable value={this.props.cashTotals}
                                   className='p-datatable-gridlines'
                                   style={{marginTop: '5px', fontSize: 'small'}}
                        >
                            <Column field="type"
                                    header={TOTAL_COLS[0]}
                                    style={{width: '60%'}}/>
                            <Column body={(row) => currencyTemplate(row, 'total')}
                                    header={TOTAL_COLS[1]}
                                    style={{width: '40%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_DAILYCASH.CLEAR);
    }
}

const
    mapStateToProps = (state) => {

        const currentState = state.stateManagement[SM_REPORTS_DAILY_CASH.id];
        const patientDataLoadedId = currentState && currentState.data.selectedItem ? `${SM_patientDataShort.loaded}_${currentState.data.selectedItem.patientId}` : null;
        const patientDataLoaded = patientDataLoadedId ? state.patients[patientDataLoadedId] : false;
        const patientDataId = currentState && currentState.data.selectedItem ? `${SM_patientDataShort.id}_${currentState.data.selectedItem.patientId}` : null;

        const loadedIndex = currentState && currentState.data.selectedId ? `${SM_PATIENT_ACCOUNT_ITEM_PDF.loaded}_${currentState.data.selectedId}` : null;
        const index = currentState && currentState.data.selectedId ? `${SM_PATIENT_ACCOUNT_ITEM_PDF.id}_${currentState.data.selectedId}` : null;

        return {
            message: state.stateManagement.message,

            dailyCashLoaded: state.reports.dailyCashLoaded,

            cashTotals: state.reports.dailyCash.cashTotals,
            grandTotals: state.reports.dailyCash.grandTotals,
            items: state.reports.dailyCash.items,
            payments: state.reports.dailyCash.payments,
            receipts: state.reports.dailyCash.receipts,
            refunds: state.reports.dailyCash.refunds,
            totals: state.reports.dailyCash.totals,

            receiptTotalTotal: state.reports.dailyCash.receiptTotalTotal,
            refundTotal: state.reports.dailyCash.refundTotal,

            PDFLoaded: state.reports.dailyCashPDFLoaded,
            pdfBuffer: state.reports.dailyCashPDF,

            itemPDFLoaded: state.reports[loadedIndex],
            itemPDFBuffer: state.reports[index],

            patientDataShortLoaded: patientDataLoaded,
            patientDataShort: state.patients[patientDataId],

            onPCButtonClick: state.login.onPCButtonClick,
            onTabCloseClick: state.login.onTabCloseClick,
            onTabUpdate: state.login.onTabUpdate,

            reportTexts: state.reports.reportTexts,

            currentState,
        }
    };

const
    mapDispatchToProps = dispatch => {
        return {
            getReport: (params) => dispatch(getReport(RES_REPORT_DAILYCASH.GET, params)),
            getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_DAILYCASH.PDF, params)),
            getItemPDF: (item, params) => dispatch(getReportPDF(item, params)),
            getPatientDetails: (patientId) => dispatch(getPatResource(RES_PATIENT_DETAILS_SHORT.GET, patientId)),

            reportText: (type) => dispatch(getReportText(RES_REPORT_TEXTS.GET, {type})),

            stateRequest: (source) => dispatch(stateRequest(source)),
            setState: (id, data) => dispatch(setState(id, data)),
        };
    };

const
    DailyCashBook = connect(mapStateToProps, mapDispatchToProps)(ConnectedDailyCashBook);

export default DailyCashBook;
