import * as Actions from "./index";
import {ac} from '../index'

const baseURL = '/KPIs';

export const RES_KPI_DATA_SETS = {

    NEW_PATIENTS: {
        id: 'newPatientsKPI',
        url: `${baseURL}/newPatients`,
        request: Actions.REQUEST_KPI_NEW_PATIENTS,
        receive: Actions.RECEIVE_KPI_NEW_PATIENTS,
        name: 'New Patients',
    },
    NEW_PATIENTS_DD: {
        id: 'newPatientsDD',
        url: `${baseURL}/newPatientsDD`,
        request: Actions.REQUEST_KPI_NEW_PATIENTS_DD,
        receive: Actions.RECEIVE_KPI_NEW_PATIENTS_DD,
    },
    ACTIVE_PATIENTS: {
        id: 'activePatientsKPI',
        url: `${baseURL}/activePatients`,
        request: Actions.REQUEST_KPI_ACTIVE_PATIENTS,
        receive: Actions.RECEIVE_KPI_ACTIVE_PATIENTS,
        name: 'Active Patients'
    },
    ACTIVE_PATIENTS_DD: {
        id: 'activePatientsDD',
        url: `${baseURL}/activePatientsDD`,
        request: Actions.REQUEST_KPI_ACTIVE_PATIENTS_DD,
        receive: Actions.RECEIVE_KPI_ACTIVE_PATIENTS_DD,
    },
    APPOINTMENTS: {
        id: 'appointmentsKPI',
        url: `${baseURL}/appointments`,
        request: Actions.REQUEST_KPI_APPOINTMENTS,
        receive: Actions.RECEIVE_KPI_APPOINTMENTS,
        name: 'Appointments'
    },
    APPOINTMENTS_DD: {
        id: 'appointmentsDD',
        url: `${baseURL}/appointmentsDD`,
        request: Actions.REQUEST_KPI_APPOINTMENTS_DD,
        receive: Actions.RECEIVE_KPI_APPOINTMENTS_DD,
    },
    APPOINTMENTS_BOOKED: {
        id: 'appointmentsBookedKPI',
        url: `${baseURL}/appointmentsBooked`,
        request: Actions.REQUEST_KPI_APPOINTMENTS_BOOKED,
        receive: Actions.RECEIVE_KPI_APPOINTMENTS_BOOKED,
        name: 'Appointments Booked'
    },
    APPOINTMENTS_BOOKED_DD: {
        id: 'appointmentsBookedDD',
        url: `${baseURL}/appointmentsBookedDD`,
        request: Actions.REQUEST_KPI_APPOINTMENTS_BOOKED_DD,
        receive: Actions.RECEIVE_KPI_APPOINTMENTS_BOOKED_DD,
    },
    TREATMENT_PLANS: {
        id: 'treatmentPlansKPI',
        url: `${baseURL}/treatmentPlans`,
        request: Actions.REQUEST_KPI_TREATMENT_PLANS,
        receive: Actions.RECEIVE_KPI_TREATMENT_PLANS,
        name: 'Treatment Plans'
    },
    TREATMENT_PLANS_DD: {
        id: 'treatmentPlansDD',
        url: `${baseURL}/treatmentPlansDD`,
        request: Actions.REQUEST_KPI_TREATMENT_PLANS_DD,
        receive: Actions.RECEIVE_KPI_TREATMENT_PLANS_DD,
    },
    REFERRALS: {
        id: 'referralsKPI',
        url: `${baseURL}/referrals`,
        request: Actions.REQUEST_KPI_REFERRALS,
        receive: Actions.RECEIVE_KPI_REFERRALS,
        name: 'Referral Sources'
    },
    REFERRALS_DD: {
        id: 'referralsDD',
        url: `${baseURL}/referralsDD`,
        request: Actions.REQUEST_KPI_REFERRALS_DD,
        receive: Actions.RECEIVE_KPI_REFERRALS_DD,
    },
};

export const getDataSets = ({url, request, receive}) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`)
            .then(res => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: {}});
    }
};

export const getDDData = ({url, request, receive}, collIndex) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {collIndex}})
            .then(res => {

                dispatch({type: receive, payload: res.data, collIndex})
            });
        dispatch({type: request, payload: {}});
    }
};
