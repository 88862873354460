import React from 'react';
import {
    setState,
    SM_TAB_PATIENT_CONSENT,
    SM_TAB_PATIENT_CONSENT_SIG,
    SM_TAB_PATIENT_CONSENT_SIG_SAVE,
    SM_TAB_PATIENT_CONSENTS, SM_TAB_PATIENT_QUESTIONNAIRE
} from "../../../actions/stateManagement";

import _ from "lodash";
import {TAB_PatientConsentForms, tabletLayoutClassName} from "../Constants";
import {connect} from "react-redux";
import {Panel} from "primereact/panel";
import * as Actions from "../../../actions";
import {
    getLists,
    getTabletPDF,
    removeFromListsById,
    RES_TAB_PATIENT_CONSENTS,
    RES_TABLET_LISTS,
    saveConsent
} from "../../../actions/tablet";
import {
    DOC_CONSENT_TEMPLATE,
    HM_TABLET_CONFIRM_CONSENT,
    OUTCOME_ConsentGiven,
    OUTCOME_ConsentGivenText,
    OUTCOME_ConsentNotGiven,
    OUTCOME_ConsentNotGivenText
} from "../../../Constants";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import {getResource2, RES_TEMPLATES_CONSENT_FORMS} from "../../../actions/housekeeping";
import {ProgressBar} from "primereact/progressbar";
import {PDFConsentSection} from "../Utils";
import {Checkbox} from "primereact/checkbox";
import SignatureCanvas from "react-signature-canvas";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import * as DefaultData from "../../FixedItems/DefaultData";
import {ac} from "../../../index";
import {ShowQuestionDialog} from "../../FixedItems/Diary/components/EventComponent";

class ConnectedTabPatientConsentsMain extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                templateIndex: -1,
                consentFormsLoaded: false,
                consentForms: [],
                consentFormsPdfImage: [],
                consentFormLoaded: false,
                consentForm: null,
                member: null,
                memberId: parseInt(props.match.params.memberId, 10),
                patientId: parseInt(props.match.params.patientId, 10),
                saveCount: 0,

                [HM_TABLET_CONFIRM_CONSENT.id]: false,
                holdForLoadingMembers: true,
            }
        }

        this.state.member = _.find(props.members, (member) => member.id === this.state.memberId);

        this.sigCanvases = [];
    }

    componentDidMount() {

        this.props.getTemplates([DOC_CONSENT_TEMPLATE.name]);

        if (this.state.member) {
            this.setState({templateIndex: this.state.templateIndex + 1}, () => {
                const templateId = this.state.member.consentFormIds[this.state.templateIndex];
                this.props.getConsentForms({id: this.state.memberId, templateId});
            })
        } else {
            this.props.getMemberList();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case RES_TEMPLATES_CONSENT_FORMS.GET.receive:
                    break;

                case Actions.RECEIVE_TAB_PATIENT_CONSENT_FORM_PDF:

                    const consentForms = [...this.state.consentForms];
                    const consentFormsPdfImage = [...this.state.consentFormsPdfImage];

                    const consentForm = _.cloneDeep(DefaultData.ConsentForm(ac.getMcId()));

                    consentForm.patient = {id: this.state.member.patientId};
                    consentForm.templateId = this.state.member.consentFormIds[this.state.templateIndex];
                    consentForm.outcome = OUTCOME_ConsentNotGiven;

                    consentForms.push(consentForm)
                    consentFormsPdfImage.push({
                        pdf: this.props.consentForm,
                        image: null,
                    })

                    const consentFormsLoaded = this.state.templateIndex === this.state.member.consentFormIds.length - 1;

                    if (!consentFormsLoaded) {
                        this.setState({
                            templateIndex: this.state.templateIndex + 1,
                            consentFormsLoaded: false,
                            consentForms,
                            consentFormsPdfImage,
                        }, () => {
                            const templateId = this.state.member.consentFormIds[this.state.templateIndex];
                            this.props.getConsentForms({id: this.state.memberId, templateId});
                        })
                    } else {
                        this.setState({consentFormsLoaded: true, consentForms, consentFormsPdfImage});
                    }
                    break;

                case Actions.RECEIVE_SAVE_TAB_PATIENT_CONSENTS:

                    const saveCount = this.state.saveCount + 1;

                    if (saveCount === this.state.consentForms.length) {
                        this.props.removeFromListById(this.state.memberId);

                        window.location.hash = `${SM_TAB_PATIENT_CONSENT.exitRoute}/${TAB_PatientConsentForms}/${this.state.member.patientId}`
                    } else {
                        this.setState({saveCount}, () => {

                            this.props.saveConsent({
                                form: this.state.consentForms[saveCount],
                                image: this.state.consentFormsPdfImage[saveCount].image,
                                memberId: this.state.memberId
                            });
                        });
                    }
                    break;

                case RES_TAB_PATIENT_CONSENTS.GET_TABLET.receive:

                    this.state.member = _.find(this.props.members, (member) => member.id === this.state.memberId);

                    this.setState({templateIndex: this.state.templateIndex + 1, holdForLoadingMembers: false}, () => {
                        const templateId = this.state.member.consentFormIds[this.state.templateIndex];
                        this.props.getConsentForms({id: this.state.memberId, templateId});
                    })
                    break;

                default:
                    break;
            }
        }
    }

    onGive = (event) => {

        const state = {...this.state};
        const consentForms = state.consentForms;

        consentForms[event.index].outcome = event.value ? OUTCOME_ConsentGiven : OUTCOME_ConsentNotGiven;
        this.setState(state);
    }

    onDeny = (event) => {

        const state = {...this.state};
        const consentForms = state.consentForms;

        consentForms[event.index].outcome = event.value ? OUTCOME_ConsentNotGiven : OUTCOME_ConsentGiven;
        this.setState(state);
    }

    onExit = () => {

        this.setState({[HM_TABLET_CONFIRM_CONSENT.id]: false, saveCount: 0}, () => {

            this.props.saveConsent({
                form: this.state.consentForms[0],
                image: this.state.consentFormsPdfImage[0].image,
                memberId: this.state.memberId
            });
        });
    }

    onSave = (index) => {

        const image = this.sigCanvases[index].toDataURL('image/png');
        this.sigCanvases[index].off();

        const state = {...this.state};
        const consentFormsPdfImage = state.consentFormsPdfImage;

        consentFormsPdfImage[index].image = image;

        const foundNull = _.findIndex(consentFormsPdfImage, form => form.image === null);

        if (foundNull === -1) {

            this.setState({[HM_TABLET_CONFIRM_CONSENT.id]: true});
        } else {
            this.setState(state);
        }
    }

    onClear = (index) => {

        this.sigCanvases[index].clear();
        this.sigCanvases[index].on();

        const state = {...this.state};
        const consentFormsPdfImage = state.consentFormsPdfImage;

        consentFormsPdfImage[index].image = null;
        this.setState(state);
    }

    onCancel = () => {

        this.props.removeFromListById(this.state.memberId);

        window.location.hash = `${SM_TAB_PATIENT_CONSENT.exitRoute}/${TAB_PatientConsentForms}/${this.state.patientId}`;
    }

    onSetSigRef = (index, ref) => {

        this.sigCanvases[index] = ref
    }

    showDialogs = () => {

        return (
            ShowQuestionDialog(this, HM_TABLET_CONFIRM_CONSENT, this.onExit)
        )
    }

    insertReportTexts = () => {

        const forms = [];

        this.state.consentForms.forEach((form, index) => {

            const consentForm = this.state.consentForms[index];
            const consentFormPdfImage = this.state.consentFormsPdfImage[index];

            const pdf = consentFormPdfImage.pdf;

            const sigHeader = <div className='p-panel-header'>
                <div className="items-margin d-flex d-align-center">
                    <span className='p-panel-title' style={{marginRight: 15}}>{SM_TAB_PATIENT_CONSENT_SIG.label}</span>
                </div>
                <div className="items-margin d-flex d-align-center">
                    <Button label="Clear" icon="pi pi-plus"
                            onClick={() => this.onClear(index)}/>
                </div>
            </div>;

            const isGiven = consentForm.outcome === OUTCOME_ConsentGiven;
            const isSaved = consentFormPdfImage.image !== null;

            forms.push(
                <React.Fragment>
                    <div className="p-col-12"
                         key={`key__${index}`}
                    >

                        <PDFConsentSection cfTemplates={this.props.cfTemplates}
                                           consentForm={consentForm}
                                           index={index}
                                           numPages={this.state.numPages}
                                           pdf={pdf}
                                           onClear={this.onClear}
                                           onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                                           onLoadError={this.onLoadError}
                        />

                        <Panel header={SM_TAB_PATIENT_CONSENTS.label}
                               className="p-col-12"
                               style={{paddingTop: '5px', paddingLeft: 0, paddingRight: 0}}
                        >
                            <Checkbox className="p-col-6"
                                      checked={isGiven}
                                      onChange={(e) => {
                                          this.onGive({
                                              index,
                                              value: e.checked,
                                          })
                                      }}
                                      inputId={`ConsentGiven${index}`}
                            />
                            <label className="p-col-6 p-checkbox-label"
                                   htmlFor={`ConsentGiven${index}`}
                            >{OUTCOME_ConsentGivenText}
                            </label>

                            <hr/>

                            <Checkbox className="p-col-6"
                                      checked={!isGiven}
                                      onChange={(e) => {
                                          this.onDeny({
                                              index,
                                              value: e.checked,
                                          })
                                      }}
                                      inputId={`ConsentNotGiven${index}`}
                            />
                            <label className="p-col-6 p-checkbox-label"
                                   htmlFor={`ConsentNotGiven${index}`}
                            >
                                {OUTCOME_ConsentNotGivenText}
                            </label>

                            <hr/>
                        </Panel>

                        <Panel className="p-col-12"
                               style={{paddingTop: '5px', paddingLeft: 0, paddingRight: 0}}
                               headerTemplate={sigHeader}>
                            <SignatureCanvas penColor='darkBlue'
                                             ref={(ref) => {
                                                 this.onSetSigRef(index, ref);
                                             }}
                                             canvasProps={{
                                                 width: 500,
                                                 height: 200,
                                                 className: 'sigCanvas'
                                             }}/>
                        </Panel>
                    </div>

                    <Toolbar className="p-col-12"
                             right={<React.Fragment>
                                 <Button label={SM_TAB_PATIENT_CONSENT_SIG_SAVE.label} icon="pi pi-check"
                                         className="p-button-success"
                                         onClick={() => this.onSave(index)}
                                         disabled={isSaved}
                                 />
                                 <i className="pi pi-bars p-toolbar-separator"/>
                                 <Button label="Cancel"
                                         icon="pi pi-times"
                                         className="p-button-warning"
                                         onClick={this.onCancel}
                                 />
                             </React.Fragment>
                             }
                    />
                </React.Fragment>
            )
        });
        return forms;
    }

    render() {
        
        if (!this.state.consentFormsLoaded || !this.props.cfTemplatesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{ marginRight: 15 }}>{SM_TAB_PATIENT_CONSENT.label}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button label="Cancel"
                        icon="pi pi-times"
                        className="p-button-warning"
                        onClick={this.onCancel}
                />
            </div>
        </div>;

        return (

            <div className={tabletLayoutClassName} id='mainPCC'>

                {this.showDialogs()}

                <div>
                    <div className="p-grid">
                        <div className="p-xl-8 p-xl-offset-2">
                            <div className="card card-w-title card-bg-solid">
                                <span className="logo"><i className='fa mainLogoImg'/></span>
                                <div className="p-grid control-panel-dashboard">

                                    <div className="p-col-12">
                                        <Panel headerTemplate={header} style={{fontSize: '1.5em'}}>
                                            <div className="p-grid p-fluid">

                                                {this.insertReportTexts()}

                                            </div>
                                        </Panel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-mask"/>

                    {/*<AppFooter/>*/}
                    <span className="bg-shape bg-shape-2"/>
                    <span className="bg-shape bg-shape-1"/>
                    <span className="bg-shape bg-shape-3"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const loadedId = `${SM_TAB_PATIENT_CONSENT.loaded}_${ownProps.match.params.memberId}`;
    const dataId = `${SM_TAB_PATIENT_CONSENT.id}_${ownProps.match.params.memberId}`;

    return {

        cfTemplatesLoaded: state.housekeeping.cfTemplatesLoaded,
        cfTemplates: state.housekeeping.cfTemplates,

        members: state.tablet.patientConsentMembers,

        consentFormLoaded: state.tablet[loadedId],
        consentForm: state.tablet[dataId],

        message: state.stateManagement.message,

        loginIdentity: state.login.user,
        userId: state.login.userId,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_CONSENT_FORMS.GET, types)),
        getConsentForms: (params) => dispatch(getTabletPDF(RES_TAB_PATIENT_CONSENTS.TABLET, params)),
        saveConsent: (params) => dispatch(saveConsent(RES_TAB_PATIENT_CONSENTS.SAVE, params)),
        removeFromListById: (memberId) => dispatch(removeFromListsById(RES_TABLET_LISTS.REMOVE_FROM_BY_ID, memberId)),
        getMemberList: () => dispatch(getLists(RES_TAB_PATIENT_CONSENTS.GET_TABLET, true)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TabPatientConsentsMain = connect(mapStateToProps, MapDispatchToProps)(ConnectedTabPatientConsentsMain);

export default TabPatientConsentsMain;
