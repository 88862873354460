import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReport, RES_REPORT_WEEKLY_XRAY} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {Calendar} from "primereact/components/calendar/Calendar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {
    HM_REP_WEEKLY_XRAY_REPORT,
    JSON_DATE_FORMAT,
    TB_SAVE_AND_EXIT,
    XR_REPORT_BIANNUAL,
    XR_REPORT_INTERVALS,
    XR_REPORT_MONTHLY,
    XR_REPORT_QUARTERLY,
    XR_REPORT_WEEKLY,
    XR_REPORT_YEARLY
} from "../../../Constants";
import {ICON_EXCEL, ICON_HELP, ICON_PIE_CHART,} from "../../../icons";
import {HELP_REP_WEEKS_XRAY_REPORT, setState, SM_XRAY_REPORT,} from "../../../actions/stateManagement";
import ReportErrorBoundary from "./Utils";
import {
    tb_boilerPlate,
    tb_boilerPlateRight,
    tb_boilerPlateRight2,
    tb_boilerPlateRightLabel,
    tb_dropDownLeft
} from "../../Utils";
import {DIARY_BACK_WEEK, DIARY_FOR_WEEK} from "../Diary/Constants";
import _ from "lodash";
import {ac} from "../../../index";
import {TabBaseComponent} from "../../TabBaseComponent";

const XRAY_COLS = ["Taken On", "Taken By", "Patient", "Type", "Rating", "Repeats", "Nature", "Cause"];

export class ConnectedXrayReport extends TabBaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),
            selectedItem: null,
            rows: 5,
            first: 0,
            interval: XR_REPORT_QUARTERLY,
            refetchData: true,
        };
        this.cm = null;
    }

    componentDidMount() {

        if (!this.props.xrayReportsByWeekLoaded) {
            this.props.getReport({
                interval: this.state.interval.value,
                date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
            });
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_WEEKLY_XRAY.GET_REPORT.receive:
                    this.setState({
                        xrayReportsByWeek: this.props.xrayReportsByWeek,
                        xrayReportsByWeekLoaded: true
                    }, () => {
                        this.props.setState(SM_XRAY_REPORT.id, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {
            case 'interval.value' :
                _.set(state, 'interval', _.find(XR_REPORT_INTERVALS, type => type.value === event.value));
                state.xrayReportsByWeekLoaded = false;

                this.setState(state, () => {
                    this.props.getReport({
                        interval: this.state.interval.value,
                        date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
                    });
                })
                break;
            default:
                _.set(state, event.owner, event.value);
                this.setState(state);
                break;
        }
    }

    onShowDetails = ({id, firstName, lastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    onDateChange = (e) => {
        this.setState({
            currentDate: e.value,
            xrayReportsByWeekLoaded: false,
        }, () => {
            this.props.getReport({
                interval: this.state.interval.value,
                date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
            });
        })
    }

    onDateChange2 = (forward) => {

        let date;

        switch (this.state.interval.value) {
            case XR_REPORT_WEEKLY.value:
                date = moment(this.state.currentDate).add(forward ? 1 : -1, 'weeks');
                break;
            case XR_REPORT_MONTHLY.value:
                date = moment(this.state.currentDate).add(forward ? 1 : -1, 'months');
                break;
            case XR_REPORT_QUARTERLY.value:
                date = moment(this.state.currentDate).add(forward ? 3 : -3, 'months');
                break;
            case XR_REPORT_BIANNUAL.value:
                date = moment(this.state.currentDate).add(forward ? 6 : -6, 'months');
                break;
            case XR_REPORT_YEARLY.value:
                date = moment(this.state.currentDate).add(forward ? 1 : -1, 'years');
                break;
            default:
                date = moment(this.state.currentDate);
                break;
        }
        date = date.toDate();

        this.props.getReport({interval: this.state.interval.value, date: moment(date).format(JSON_DATE_FORMAT)});

        this.setState({
            currentDate: date,
            xrayReportsByWeekLoaded: false,
        }, () => {
            this.props.setState(SM_XRAY_REPORT, this.state);
        })
    }

    onDownload = () => {

        const url = `https://${ac.getBASERESTURL()}${RES_REPORT_WEEKLY_XRAY.XLS.url}?mcId=${ac.getMcId()}&interval=${this.state.interval.value}&date=${moment(this.state.currentDate).format(JSON_DATE_FORMAT)}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `xrays${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                    a.click();
                });
            });
    }

    render() {

        if (!this.props.xrayReportsByWeekLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = [
            {label: 'Show this patients summary', icon: '', command: this.onShowDetails},
        ];

        let key = 1000;

        const {totalBW, totalPA, totalOC, totalPAN, totalCM, totalCBCT} = this.props.xrayReportsByWeekSummary;

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title'
                      style={{marginRight: 15}}>{`${this.state.interval.label} - ${HM_REP_WEEKLY_XRAY_REPORT.header}`}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                {tb_boilerPlateRightLabel(ICON_PIE_CHART, `BW: ${totalBW}`, `Bitewing: ${totalBW}`, key++, false)}
                {tb_boilerPlateRightLabel(ICON_PIE_CHART, `PA: ${totalPA}`, `Periapicle: ${totalPA}`, key++, false)}
                {tb_boilerPlateRightLabel(ICON_PIE_CHART, `OC: ${totalOC}`, `Occlusal: ${totalOC}`, key++, false)}
                {tb_boilerPlateRightLabel(ICON_PIE_CHART, `PAN: ${totalPAN}`, `Panoramic: ${totalPAN}`, key++, false)}
                {tb_boilerPlateRightLabel(ICON_PIE_CHART, `CM: ${totalCM}`, `Celpometric: ${totalCM}`, key++, false)}
                {tb_boilerPlateRightLabel(ICON_PIE_CHART, `CBCT: ${totalCBCT}`, `CBCT: ${totalCBCT}`, key++, false)}
            </div>
        </div>;

        const intervalText = this.state.interval.text;

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate((e) => this.onDateChange2(false), DIARY_BACK_WEEK.icon, `Back a ${intervalText}`, key++)}
                            {tb_boilerPlate((e) => this.onDateChange2(true), DIARY_FOR_WEEK.icon, `Forward a ${intervalText}`, key++)}
                            {tb_dropDownLeft((e) => this.onChange(e), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, XR_REPORT_INTERVALS, this.state.interval, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      disabledDays={[0, 2, 3, 4, 5, 6]}
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>

                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${intervalText} Excel File`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_WEEKS_XRAY_REPORT), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                        <ContextMenu model={items}
                                     style={{width: '250px'}}
                                     ref={el => this.cm = el}/>

                        <DataTable value={this.props.xrayReportsByWeek}
                                   className='p-datatable-gridlines'
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selectedItem}
                                   onSelectionChange={(e) => {
                                       this.setState({selectedItem: e.value});
                                   }}
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e => this.setState({selectedItem: e.value})}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent);
                                   }}
                        >
                            <Column header={XRAY_COLS[0]}
                                    body={(row) => dateTemplateShort(row.takenOn)}
                                    style={{width: '10%'}}/>
                            <Column field="takenByName"
                                    header={XRAY_COLS[1]}
                                    style={{width: '15%'}}/>
                            <Column field="patientName"
                                    header={XRAY_COLS[2]}
                                    style={{width: '20%'}}/>
                            <Column field="type"
                                    style={{width: '10%'}}/>
                            <Column field="rating"
                                    header={XRAY_COLS[4]}
                                    style={{width: '10%'}}/>
                            <Column field="noOfRepeats"
                                    header={XRAY_COLS[5]}
                                    style={{width: '5%'}}/>
                            <Column field="natureOfDeficiency"
                                    header={XRAY_COLS[6]}
                                    style={{width: '15%'}}/>
                            <Column field="causeOfDeficiency"
                                    header={XRAY_COLS[7]}
                                    style={{width: '15%'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_WEEKLY_XRAY.CLEAR_REPORT);
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        xrayReportsByWeekLoaded: state.reports.xrayReportsByWeekLoaded,
        xrayReportsByWeek: state.reports.xrayReportsByWeekRecords,
        xrayReportsByWeekSummary: state.reports.xrayReportsByWeekSummary,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReport(RES_REPORT_WEEKLY_XRAY.GET_REPORT, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const XrayReport = connect(mapStateToProps, mapDispatchToProps)(ConnectedXrayReport);

export default XrayReport;
