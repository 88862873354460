import * as Actions from './index';
import {ac} from '../index'

export const getAllPatients = (includeInactives) => {

    const searchURL = `/Patients/findShort?mcId=${ac.getMcId()}&includeInactives=${includeInactives}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getPatientsBySurname = (urlPattern) => {

    const searchURL = `/Patients/findShort?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getPatientById = (id) => {

    const URL = `/Patients/getShortById?mcId=${ac.getMcId()}&patId=${id}`;

    return (dispatch) => {
        ac.fetchClient().get(URL)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getPatientsByFirstname = (urlPattern) => {

    const searchURL = `/Patients/findShortByFirstname?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getPatientsByAltRef = (urlPattern) => {

    const searchURL = `/Patients/findShortByAlternateRef?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getPatientsByEmail = (urlPattern) => {

    const searchURL = `/Patients/findShortByEmail?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getPatientsByPostcode = (urlPattern) => {

    const searchURL = `/Patients/findShortByPostcode?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getPatientsByTelnumber = (urlPattern) => {

    const searchURL = `/Patients/findShortByTelnumber?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + urlPattern)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getPatientsByDOB = (dob) => {

    const searchURL = `/Patients/findShortByDOB?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + dob)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getPatientsByProvider = (providerId) => {

    const searchURL = `/Patients/findShortByProvider?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + providerId)
            .then(res => {

                dispatch({type: Actions.RECEIVE_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_PATIENT_SEARCH, payload: []});
    }
};

export const getAppointmentPatientsBySurname = (urlPattern) => {

    const searchURL = `/Patients/findShort?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + `&pattern=${urlPattern}`)
            .then(res => {

                dispatch({type: Actions.RECEIVE_APP_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_APP_PATIENT_SEARCH, payload: []});
    }
};

export const getAppointmentPatientsByFirstname = (urlPattern) => {

    const searchURL = `/Patients/findShortByFirstname?mcId=${ac.getMcId()}`;

    return (dispatch) => {
        ac.fetchClient().get(searchURL + `&pattern=${urlPattern}`)
            .then(res => {

                dispatch({type: Actions.RECEIVE_APP_PATIENT_SEARCH, payload: res.data})
            });
        dispatch({type: Actions.REQUEST_APP_PATIENT_SEARCH, payload: []});
    }
};

export const findByProviderShort = (id) => {

    return (dispatch) => {

        ac.fetchClient().get(`/Patients/findByProviderShort?mcId=${ac.getMcId()}&id=${id}`)
            .then(res => {
                dispatch({type: Actions.RECEIVE_USER_PATIENTS, payload: res.data});
            });
        dispatch({type: Actions.REQUEST_USER_PATIENTS, payload: {}});
    }
};
