import React from 'react';

import _ from "lodash";

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    CH_INVOICE,
    CH_INVOICED,
    CH_RECEIPTED,
    CH_SAVED,
    CH_SCHEDULED,
    HM_EditTreatment,
    HM_notImplemented,
    INV_PREDUE,
    INV_SCHEDULE,
} from "../../../../Constants";
import {ICON_CAPS, ICON_PRINT, ICON_SCREEN,} from "../../../../icons";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";
import {
    HM_TreatmentDelete,
    HM_TreatmentEdit,
    HM_TreatmentPrintInvoice,
    HM_TreatmentPrintReceipt,
    HM_TreatmentShowAppointment,
    HM_TreatmentShowInvoice,
    HM_TreatmentShowReceipt,
    TB_TREATMENT_SALES
} from "../Constants";
import EditTreatment from "../dialogs/EditTreatment";
import {Checkbox} from "primereact/checkbox";
import {outputCurrency} from "../../../PatientDynamicItems/OnChangeUtils";
import {TextTreatmentHistory} from "../../../../Messages";

const feeTemplate = (row) => {

    if (row.discountCharge) {
        return `%${row.chargedPrice}`;
    } else {
        return outputCurrency(row.quantity * row.chargedPrice);
    }
};

const updateTreatment = (props, updatedTreatment) => {

    const treatments = _.get(props, `${props.target}`);
    const index = _.findIndex(treatments, treatment => treatment.id === updatedTreatment.id);

    treatments[index] = updatedTreatment;
    props.onChange({owner: 'patientTreatmentHistory', value: treatments});
};

const completeTemplate = (props, row) => {

    if ([CH_INVOICED, CH_RECEIPTED].indexOf(row.status) > -1) {
        return <Checkbox onChange={() => {
        }} checked={true}/>
    } else {
        return <Checkbox onChange={(e) => {
            const treatment = {...row};
            treatment.status = e.checked ? CH_INVOICE : CH_SAVED;
            treatment.edited = true;
            updateTreatment(props, treatment);
        }} checked={row.status === CH_INVOICE}/>
    }
};

const transactionTemplate = (row) => {

    if (row.invStatus === null) {
        switch (row.status) {
            case CH_SAVED :
                return "Saved";
            case CH_SCHEDULED :
                return "Scheduled";
            case CH_INVOICE :
                return "Not charged";
            case CH_INVOICED :
                return row.invoiceString;
            case CH_RECEIPTED :
                return row.receiptString;
            default :
                return "Unknown status";
        }
    } else {

        switch (row.invStatus) {
            case INV_SCHEDULE :
                return "Scheduled";
            case INV_PREDUE :
                return "Issuing";
            default :
                return row.invoiceString;
        }
    }
};

const showDialogs = (props) => {

    if (props.editing) {

        return (
            <EditTreatment header={HM_EditTreatment.header}
                           onHideDialog={(e) => {
                               props.toolbarCallbacks[HM_EditTreatment.id](false);
                           }}
                           onOkDialog={(treatment) => {
                               props.onEditTreatment(treatment);
                           }}
                           treatmentCodes={[]}
                           favourites={[]}
                           item={props.selectedTreatment}
            />
        )
    }
};

export const TreatmentHistorySection = (props) => {

    if (!props.patientTreatmentHistoryLoaded) {
        return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
    }

    let cm = null;

    let items = [];

    const treatment = props.selectedTreatment;

    if (Boolean(treatment)) {

        const invoiceAttached = treatment.invoiceId !== null;
        const receiptAttached = treatment.receiptId !== null;

        items.push({
            label: HM_TreatmentShowAppointment.header,
            icon: HM_TreatmentShowAppointment.icon,
            command: () => props.toolbarCallbacks[HM_notImplemented.id]()
        });
        items.push({separator: true});

        if (invoiceAttached) {
            items.push({
                label: HM_TreatmentShowInvoice.header,
                icon: HM_TreatmentShowInvoice.icon,
                command: () => props.toolbarCallbacks[HM_notImplemented.id]()
            });
            items.push({
                label: HM_TreatmentPrintInvoice.header,
                icon: HM_TreatmentPrintInvoice.icon,
                command: () => props.toolbarCallbacks[HM_notImplemented.id]()
            });
        } else if (receiptAttached) {
            items.push({
                label: HM_TreatmentShowReceipt.header,
                icon: HM_TreatmentShowReceipt.icon,
                command: () => props.toolbarCallbacks[HM_notImplemented.id]()
            });
            items.push({
                label: HM_TreatmentPrintReceipt.header,
                icon: HM_TreatmentPrintReceipt.icon,
                command: () => props.toolbarCallbacks[HM_notImplemented.id]()
            });
        } else {
            items.push({
                label: HM_TreatmentEdit.header,
                icon: HM_TreatmentEdit.icon,
                command: () => props.toolbarCallbacks[HM_EditTreatment.id](true)
            });
            items.push({
                label: HM_TreatmentDelete.header,
                icon: HM_TreatmentDelete.icon,
                command: () => props.toolbarCallbacks[HM_notImplemented.id]()
            });
        }
        items.push({separator: true});

        if (treatment.isPartOfPlan) {
            items.push({
                label: 'Show Treatment Plan',
                icon: '',
                command: () => props.toolbarCallbacks[HM_notImplemented.id]()
            });
            items.push({separator: true});
        }
        items.push({
            label: 'Sales',
            icon: 'fas fa-file-invoice',
            command: () => props.toolbarCallbacks[TB_TREATMENT_SALES.id]()
        });
    } else {

        items = [
            {
                label: 'Sales',
                icon: 'fas fa-file-invoice',
                command: () => props.toolbarCallbacks[HM_notImplemented.id]()
            },
        ];
    }

    const historyText = props.fullTreatmentHistory ? 'Full Treatment History  ' : 'Current Treatment History  ';
    const historyTooltip = props.fullTreatmentHistory ? 'Show current treatment history' : 'Show full treatment history';

    const treatmentHistory = _.orderBy(_.get(props, `${props.target}`), 'id', 'asc');

    const visibleTreatmentHistory = props.fullTreatmentHistory ? treatmentHistory : _.filter(treatmentHistory, item => [CH_SAVED, CH_INVOICE].includes(item.status));

    const noSales = _.filter(visibleTreatmentHistory, item => [CH_SAVED, CH_INVOICE].includes(item.status)).length === 0;

    const barHeader = <div className='p-panel-header'>
        <div className="items-margin d-flex d-align-center">
            <span className='p-panel-title' style={{marginRight: 15}}>{historyText}</span>
            <Button label={TB_TREATMENT_SALES.text}
                    disabled={noSales}
                    onClick={() => {
                        props.toolbarCallbacks[TB_TREATMENT_SALES.id]();
                    }}
            />
        </div>
        <div className="items-margin d-flex d-align-center">
            <Button icon={ICON_CAPS}
                    tooltip={historyTooltip}
                    tooltipOptions={{position: 'right'}}
                    onClick={() => {
                        props.setValue('fullTreatmentHistory', !props.fullTreatmentHistory)
                    }}/>
            <Button icon={ICON_SCREEN}
                    tooltip={'Show ' + TextTreatmentHistory}
                    tooltipOptions={{position: 'top'}}
                    onClick={(e) => {
                        props.toolbarCallbacks[HM_notImplemented.id]();
                    }}/>
            <Button icon={ICON_PRINT}
                    tooltip={'Print ' + TextTreatmentHistory}
                    tooltipOptions={{position: 'top'}}
                    onClick={(e) => {
                        props.toolbarCallbacks[HM_notImplemented.id]();
                    }}/>
        </div>
    </div>;

    return (
        <Panel headerTemplate={barHeader}>

            {showDialogs(props)}

            <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

            <DataTable value={visibleTreatmentHistory}
                       className='p-datatable-gridlines'
                       style={{fontSize: 'small'}}
                       selectionMode="single"
                       paginator={true}
                       rows={props.treatmentRows}
                       rowsPerPageOptions={[5, 10, 20]}
                       onPage={(e) => {
                           props.onPageFlex(e, 'firstTreatment', 'treatmentRows');
                       }}
                       first={props.firstTreatment}
                       selection={props.selectedTreatment}
                       onSelectionChange={(e) => props.setValue('selectedTreatment', e.value)}
                       contextMenuSelection={props.selectedTreatment}
                       onContextMenuSelectionChange={(e) => props.setValue('selectedTreatment', e.value)}
                       onContextMenu={e => cm.show(e.originalEvent)}
            >
                <Column body={(rowData) => completeTemplate(props, rowData)} style={{width: '5%'}}/>
                <Column body={row => dateTemplate(row.dateAdded)} header="Date"
                        style={{width: '15%', textAlign: 'right'}}/>
                <Column field="quantity" header="Qty" style={{width: '5%', textAlign: 'right'}}/>
                <Column field="code" header="Code" style={{width: '10%'}}/>
                <Column field="description" header="Description" style={{width: '25%'}}/>
                <Column field="site" header="Site" style={{width: '5%'}}/>
                <Column field="fee" body={feeTemplate} header="Fee" style={{width: '10%', textAlign: 'right'}}/>
                <Column field="transaction" body={transactionTemplate} header="Transaction"
                        style={{width: '15%', textAlign: 'right'}}/>
                <Column field="username" header="Provider" style={{width: '10%'}}/>
            </DataTable>
        </Panel>
    )
};
