import _ from "lodash";
import {ac} from "../../index";
import {RES_getAccountGroups} from "../accountGroups";
import {SM_ACCOUNT_GROUPS} from "../stateManagement";

export const saveAccountGroups = (dispatch, sm, source) => {

    if (sm[SM_ACCOUNT_GROUPS.id] !== undefined) {

        const groups = sm[SM_ACCOUNT_GROUPS.id].data.accountGroups;

        const updatedNewGroups = _.filter(groups, group => group.id < 0 || group.edited);

        updatedNewGroups.forEach(group => {

            if (Boolean(group.edited)) {

                let updatedGroup = {...group};

                updatedGroup.groupPrinciple = {id: updatedGroup.groupPrinciple.id};
                updatedGroup.edited = false;
                updatedGroup.id = group.id < 0 ? -1 : group.id;

                const members = [];

                _.map(group.members, member => members.push({id: member.id}));

                updatedGroup.members = members;

                ac.fetchClient().post(`${RES_getAccountGroups.SAVE.url}?mcId=${ac.getMcId()}`, updatedGroup)
                    .then(() => {});
            }
        });
    }
};
