import React from 'react';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_OVER_DUE_91, SM_OVER_DUE_ACCOUNTS} from "../../../../actions/stateManagement";

import {getReportService, RES_OVER_DUE_ACCOUNTS} from "../../../../actions/reports";
import {OverDueAccountsBase} from "./OverDueAccountsBase";
import {INV_OUT_91} from "../../../../Constants";
import {calculateOutstandingAmount} from "../Utils";
import {TB_RECALCULATE_PATIENT_ACCOUNTS} from "../../PatientDetails/Constants";

class ConnectedOverDueAccounts91 extends OverDueAccountsBase {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.stateManagementId,

                overDuesLoaded: false,
                overDues: [],
                selectedOverDue: null,
                first: 0,
                rows: 5,

                [TB_RECALCULATE_PATIENT_ACCOUNTS.id]: false,
            }
        }

        this.onSelectionChange = this.onSelectionChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentDate) {
            this.props.getOverDues({code: INV_OUT_91, id: this.props.accountGroup.id});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_OVER_DUE_ACCOUNTS.GET_91.receive:

                    this.setState({overDues: this.props.overDues, overDuesLoaded: true}, () => {
                        this.props.setPeriodTotal(calculateOutstandingAmount(this.props.overDues));
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;

                default:
                    break;
            }
        }
    }

    reloadOverdues = () => {

        this.setState({[TB_RECALCULATE_PATIENT_ACCOUNTS.id]: false}, () => {
            this.props.getOverDues({code: INV_OUT_91, id: this.props.accountGroup.id});
        });
    }

    render() {

        const items = this.buildMenu();

        return (
            <div>
                <Panel headerTemplate={this.createRecalculateButton(SM_OVER_DUE_91.header)}>

                    <ContextMenu style={{width: 280}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={this.state.overDues}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode='single'
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedOverDue}
                               onSelectionChange={this.onSelectionChange}
                               contextMenuSelection={this.state.selectedOverDue}
                               onContextMenuSelectionChange={e => this.setState({selectedOverDue: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >
                        {this.addColumns()}
                    </DataTable>
                </Panel>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {

    const dataLoadedId = `${SM_OVER_DUE_91.loaded}_${ownProps.accountGroup.id}`;
    const dataId = `${SM_OVER_DUE_91.id}_${ownProps.accountGroup.id}`;

    return {

        message: state.stateManagement.message,

        overDuesLoaded: state.reports[dataLoadedId],
        overDues: state.reports[dataId],

        capabilities: state.login.capabilities,

        stateManagementId: dataId,
        currentState: state.stateManagement[dataId],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOverDues: (timePeriod) => dispatch(getReportService(RES_OVER_DUE_ACCOUNTS.GET_91, timePeriod)),

        setState: (id, data) => dispatch(setState(id, data, SM_OVER_DUE_ACCOUNTS.id)), // last parameter is optional parent id
    };
};

const OverDueAccounts91 = connect(mapStateToProps, mapDispatchToProps)(ConnectedOverDueAccounts91);

export default OverDueAccounts91;
