import _ from 'lodash';

import {
    AG_STATUS_ACTIVE,
    APP_TPUNPLACED,
    ASA_I,
    CC_UK,
    CH_SAVED,
    DE_TYPE_NONE, DOCLASS_USABLE,
    FB_0,
    IA_S,
    INV_DUE,
    INV_OUT_30,
    LAB_STATUS_ACTIVE,
    LOC_COLOCATED,
    LOC_STATUS_INACTIVE,
    MHF_ST_Current,
    MS_EMAIL,
    NOTE_Info,
    Null, OCON_1,
    OUTCOME_ConsentNotGiven,
    PAT_STATUS_ACTIVE,
    PJ_None,
    PJS_USABLE,
    PN_Info,
    PP_INTERVAL_MONTHLY,
    PP_PRECENT0,
    PP_SET_TYPE_OPTION1_CC,
    PP_STATUS_PROPOSED,
    Rec_Normal,
    REC_STATUS_OPEN,
    REF_INDEPENDENT,
    RRT_RECALL_SNAILMAIL,
    RT_PATIENT_CONSENT_FORM,
    SR_CLINIC,
    SS_1,
    ST_RA,
    SU_AT_FIXEDFEE,
    SU_STATUS_INACTIVE,
    SystemReason,
    TP_PROPOSED,
    WORK_STATUS_REQUESTED,
    WORK_TYPE_BY_DATE,
    XR_EXCELLENT,
    XT_PANORAMIC,
} from "../../Constants";
import {PAY_STATE_AVAILABLE, PAY_TYPE_CASH} from "./PatientDetails/Constants";
import {Current} from "../Charting/Model/Constants";
import {ac} from "../../index";
import {_00, ExemptionAndRemissionCodes} from "../PatientDynamicItems/NHSDetails";
import {_0000, Codes9000KPIDataSet, NoBand} from "../../NHSConstants";
import {CurrencyCodes, EDI_CF_None, EDI_CR_None, EDI_CT_FP17, EDI_ST_Stacked} from "./NHSManagaement/Constants";
import {CHART_TYPE_PRIMARY} from "../PatientDynamicItems/OrthoIntraOral2";
import {CONST_ASK} from "../PatientDynamicItems/OnChangeUtils";

export const APP_CREATE_STAGE_1 = 'APP_CREATE_STAGE_1';
export const APP_CREATE_STAGE_2 = 'APP_CREATE_STAGE_2';

export const PatientData = (mcId) => {

    return {
        id: null,
        mc: mcId,

        provider: null,
        registeredBy: null,
        registeredOn: null,
        title: null,
        firstName: '',
        middleName: '',
        lastName: '',
        gender: CONST_ASK,
        status: PAT_STATUS_ACTIVE.value,
        dateOfBirth: new Date(),
        addressedAs: '',
        salutation: '',
        knownAs: '',
        contactDetails: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {
                "id": 230,
                "mc": 1,
                "countryName": "UNITED KINGDOM",
                "rank": 1
            }
        },
        alternContactDetails: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {
                "id": 230,
                "mc": 1,
                "countryName": "UNITED KINGDOM",
                "rank": 1
            }
        },
        homeTelephone: '',
        workTelephone: '',
        otherTelephone: '',
        fax: '',
        mobile: '',
        email: '',
        homeTelephoneComment: '',
        workTelephoneComment: '',
        otherTelephoneComment: '',
        mobileComment: '',
        emailComment: '',
        referrer: null,
        otherSource: '',
        appointments: [],
        reminders: [],
        charges: [],
        treatmentPlans: [],
        generalNotes: [],
        accountBalance: 0.0,
        pendingBalance: 0.0,
        lastBillSent: null,
        lastUpdated: '',
        conditions: [],
        billingGroup: null,
        groupPayee: null,
        hasConditions: false,
        hasNotes: false,
        lateArriver: false,
        latePayer: false,
        medicalHistoryFormNeeded: false,
        alternateRef: '',
        studyModelBox: '',
        sendToPrinciple: false,
        importReference: '',
        occupation: null,
        xrayConnectionReference: '',
        useAlternativeAddress: false,
        discountCharge: null,
        lastTransaction: null,
        pricingPlanMembership: null,
        patientJourney: null,
        adultCharting: false,
        claimId: null,
        NHSPatient: false,
        iotnDhc: 0,
        iotnAc: 0,
        blockPortalLogin: false,
        portalLoginRegistered: false,
        portalLoginGDPRRequired: true,
    }
};

export const NextOfKin = (mcId) => {

    return {
        id: null,
        mc: null,
        patient: null,
        title: null,
        firstName: '',
        lastName: '',
        mobile: '',
        relationship: '',
        contactDetails: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {id: CC_UK} // UK
        },
    }
}

export const Address = () => {

    return {
        id: null,
        mc: null,
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        county: '',
        postcode: '',
        country: {id: CC_UK} // UK
    }
}

export const PatientNHSDetails = (mcId) => {
    const patientNHSDetails = {
        id: -1,
        mc: mcId,
        patient: null,
        nhsNumber: '',
        previousSurname: '',
        ethnicGroup: {code: 99},
        exemptionsAndRemissions: [],
        exemptionShown: [],
        collegeOrUniversity: '',
        marternityExemptionNo: '',
        nameOfPersonReceivingBenefit: '',
        NINo: '',
        hc2CertificateNo: '',
        hc3CertificateNo: '',
        taxCreditNo: '',
        hc3PaymentLimit: 0.0,
        babyDueOrBorn: null,
        dateOfBirth: null,
    };
    ExemptionAndRemissionCodes.forEach(code => {
        patientNHSDetails.exemptionsAndRemissions.push(false);
        patientNHSDetails.exemptionShown.push(false);
    })
    return patientNHSDetails;
};

export const userData = (mcId) => {

    return {
        id: null,
        mc: mcId,

        registeredOn: new Date(),

        username: '',
        password: '',

        title: null,

        gender: 2,
        status: SU_STATUS_INACTIVE,
        myRole: {id: null},

        firstName: '',
        middleName: '',
        lastName: '',

        dateOfBirth: new Date(),

        contactDetails: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {id: CC_UK} // UK
        },

        homeTelephone: '',
        workTelephone: '',
        fax: '',
        mobile: '',
        email: '',

        enableSMS: false,
        ownsPatients: false,

        red: 179,
        green: 194,
        blue: 130,

        recallTemplateId: null,
        groupRecallTemplateId: null,

        diaryTimeIncrement: 0,

        importReference: '',

        associationType: SU_AT_FIXEDFEE,
        percentageOrFee: 0.0,

        gdcNumber: '',
        defaultAppointmentType: null,

        shareDiary: false,
        onlinePreference: false,

        // NHS Related info
        providerNumber: '',
        performerNumber: '',
        providerPIN: '',

        underUDAManagement: false,
        performerIsProvider: false,

        specialisms: [],
        treatmentFavorite: null,
    }
};

export const AccountGroupData = (mcId) => {
    
    return {
        id: null,
        mc: mcId,

        groupName: '',
        members: [],
        groupPrinciple: null,
        payableTo: '',
        paymentPeriod: 0,
        bankName: '',
        sortCode: '',
        accountNumber: '',
        invoiceTemplateId: null,
        accRenderedTemplateId: null,
        cnTemplateId: null,
        rcpTemplateId: null,
        paymentTemplateId: null,
        accountTemplateId: null,
        defaultAG: false,
        status: AG_STATUS_ACTIVE.name,
        payments: [],
        red: 255,
        green: 255,
        blue: 255,
    }
}

export const housekeepingData = {
    titles: [],
    treatmentCodes: [],
    costCenters: [],
    treatmentFavourites: [],
    occupations: [],
    occupations_save: false,
    diaryTypes: [],
    diaryTypes_save: false,
    recallTypes: [],
    recallTypes_save: false,
    patientJourneys: [],
    patientJourneys_save: false,
    assCatForms: [],
    assCatForms_save: false,
};

export const appointmentData = (mcId) => {

    return {
        appointmentId: null,
        mc: mcId,
        bookedBy: null,
        updatedBy: null,
        bookingDate: null,
        patient: null,
        appointmentWith: null,
        charges: [],
        shortDescription: '',
        description: '',
        status: APP_TPUNPLACED,
        startdt: null,
        enddt: null,
        created: new Date(),
        lastUpdated: null,
        confirmed: false,
        attended: false,
        invoiced: false,
        workRequired: false,
        recallProcessed: false,
        recallSet: false,
        firstAppointment: false,
        partOfPlan: true,
        confirmedOn: null,
        confirmedBy: null,
        apType: null,
        linkedTo: null,
        fowardTo: null,
        backwardTo: null,
        reminder: null,
        cancellationReason: null,
        cancellationCharge: null,
        labWorkList: [],
        title: 'Appointment',
        lastModified: null,
        index: 0,
        accepted: false,
        placed: false,
        minimumInterval: 0,
        maximumInterval: 0,
        treatmentPlan: null,
        cancellationComment: '',
        importReference: '',
        claimId: null,
        iotnScore: 0,
        aestheticComponent: 0,
        checkedInTime: null,
        inSurgeryTime: null,
        leftSurgeryTime: null
    }
};

export const referrerData = (mcId) => {

    return {

        id: null,
        mc: mcId,
        registeredOn: new Date(),
        title: {id: null},
        firstName: '',
        middleName: '',
        lastName: '',
        homeTelephone: '',
        workTelephone: '',
        fax: '',
        mobile: '',
        email: '',
        companyName: '',
        importReference: '',
        importReference2: null,
        address: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {id: CC_UK}
        },
        patients: [],
        comments: '',
        provider: null,
        patient: null,
        type: REF_INDEPENDENT.value,
        occupation: null,
    }
};

export const ScheduleRule = (mcId, practiceDetails) => {

    const {openSun, openMon, openTue, openWed, openThu, openFri, openSat} = practiceDetails;

    const {sunStartHour, sunStartMin, sunTimeHour, sunTimeMin} = practiceDetails;
    const {monStartHour, monStartMin, monTimeHour, monTimeMin} = practiceDetails;
    const {tueStartHour, tueStartMin, tueTimeHour, tueTimeMin} = practiceDetails;
    const {wedStartHour, wedStartMin, wedTimeHour, wedTimeMin} = practiceDetails;
    const {thuStartHour, thuStartMin, thuTimeHour, thuTimeMin} = practiceDetails;
    const {friStartHour, friStartMin, friTimeHour, friTimeMin} = practiceDetails;
    const {satStartHour, satStartMin, satTimeHour, satTimeMin} = practiceDetails;


    return {
        id: -1,
        mc: mcId,

        user: {id: null},
        surgery: {id: null},
        startingOn: null,
        endingOn: null,

        openStart: false,
        openEnd: false,
        adHoc: false,

        days: [
            [openSun, openMon, openTue, openWed, openThu, openFri, openSat],
            [openSun, openMon, openTue, openWed, openThu, openFri, openSat],
            [openSun, openMon, openTue, openWed, openThu, openFri, openSat],
            [openSun, openMon, openTue, openWed, openThu, openFri, openSat],
        ],

        share: [
            [false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false],
        ],

        startHour: [
            [sunStartHour, monStartHour, tueStartHour, wedStartHour, thuStartHour, friStartHour, satStartHour],
            [sunStartHour, monStartHour, tueStartHour, wedStartHour, thuStartHour, friStartHour, satStartHour],
            [sunStartHour, monStartHour, tueStartHour, wedStartHour, thuStartHour, friStartHour, satStartHour],
            [sunStartHour, monStartHour, tueStartHour, wedStartHour, thuStartHour, friStartHour, satStartHour],
        ],
        startMin: [
            [sunStartMin, monStartMin, tueStartMin, wedStartMin, thuStartMin, friStartMin, satStartMin],
            [sunStartMin, monStartMin, tueStartMin, wedStartMin, thuStartMin, friStartMin, satStartMin],
            [sunStartMin, monStartMin, tueStartMin, wedStartMin, thuStartMin, friStartMin, satStartMin],
            [sunStartMin, monStartMin, tueStartMin, wedStartMin, thuStartMin, friStartMin, satStartMin],
        ],
        endHour: [
            [sunTimeHour, monTimeHour, tueTimeHour, wedTimeHour, thuTimeHour, friTimeHour, satTimeHour],
            [sunTimeHour, monTimeHour, tueTimeHour, wedTimeHour, thuTimeHour, friTimeHour, satTimeHour],
            [sunTimeHour, monTimeHour, tueTimeHour, wedTimeHour, thuTimeHour, friTimeHour, satTimeHour],
            [sunTimeHour, monTimeHour, tueTimeHour, wedTimeHour, thuTimeHour, friTimeHour, satTimeHour],
        ],
        endMin: [
            [sunTimeMin, monTimeMin, tueTimeMin, wedTimeMin, thuTimeMin, friTimeMin, satTimeMin],
            [sunTimeMin, monTimeMin, tueTimeMin, wedTimeMin, thuTimeMin, friTimeMin, satTimeMin],
            [sunTimeMin, monTimeMin, tueTimeMin, wedTimeMin, thuTimeMin, friTimeMin, satTimeMin],
            [sunTimeMin, monTimeMin, tueTimeMin, wedTimeMin, thuTimeMin, friTimeMin, satTimeMin],
        ],

        lbStartHour: [
            [13, 13, 13, 13, 13, 13, 13],
            [13, 13, 13, 13, 13, 13, 13],
            [13, 13, 13, 13, 13, 13, 13],
            [13, 13, 13, 13, 13, 13, 13],
        ],
        lbstartMin: [
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
        ],
        lbEndHour: [
            [14, 14, 14, 14, 14, 14, 14],
            [14, 14, 14, 14, 14, 14, 14],
            [14, 14, 14, 14, 14, 14, 14],
            [14, 14, 14, 14, 14, 14, 14],
        ],
        lbEndMin: [
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
        ],

        lunchShown: [
            [false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false],
        ],

        createdOn: null,

        type: SR_CLINIC
    }
};

const findDefaultCategory = (categories, target) => {

    let elements = [];
    let defaultCategory = undefined;

    try {
        elements = _.find(categories, item => item.category === target).elements;
        defaultCategory = _.find(elements, element => element.defaultCategory);
    } catch (error) {
        return false;
    }
    return Boolean(defaultCategory) ? defaultCategory : elements[0];
};

export const orthoAssessment = (mcId, categories) => {

    return {
        id: null,
        mc: mcId,
        assessmentDate: new Date(),
        patient: null,
        assessedBy: null,
        patientsComplaint: "",
        skeletalClassACE: findDefaultCategory(categories, 'SkeletalClass'),
        skeletalSeverityACE: findDefaultCategory(categories, 'SkeletalSeverity'),
        fMPAACE: findDefaultCategory(categories, 'fmpa'),
        lAFHACE: findDefaultCategory(categories, 'lafh'),
        assymsChinPointACE: findDefaultCategory(categories, 'Position'),
        nasoLabelAngleACE: findDefaultCategory(categories, 'Angle'),
        lipsACE: findDefaultCategory(categories, 'Competence'),
        lipLineACE: findDefaultCategory(categories, 'Level'),
        incisorShow: false,
        incisorShowAtRest: 0,
        incisorShowSmiling: 0,
        nonTMJNadVal: null,
        oralHygieneACE: findDefaultCategory(categories, 'OrthoOralHygiene'),
        teethMissing: [
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        ],
        doubtfulPrognosis: [
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        ],
        teethMixedMissing: [
            [false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false]
        ],
        cpitn: false,
        bpeScore1: "_0",
        bpeScore2: "_0",
        bpeScore3: "_0",
        bpeScore4: "_0",
        bpeScore5: "_0",
        bpeScore6: "_0",
        otherComment: "",
        incisorRelationshipACE: findDefaultCategory(categories, 'IncisorRelationship'),
        incisorRelationshipVal: 0,
        overjetVal: 0,
        overbiteACE: findDefaultCategory(categories, 'OverbiteCompleteness'),
        overbiteAssACE: findDefaultCategory(categories, 'OverbiteClass'),
        overbiteComplete: false,
        overbiteTraumatic: false,
        openBiteOn: false,
        openBiteACE: findDefaultCategory(categories, 'Openbite'),
        openBiteVal: 0,
        molarRelationshipLeftACE: findDefaultCategory(categories, 'MolarRelationshipLeft'),
        molarRelationshipRightACE: findDefaultCategory(categories, 'MolarRelationshipRight'),
        canineRelationshipLeftACE: findDefaultCategory(categories, 'CanineRelationshipLeft'),
        canineRelationshipRightACE: findDefaultCategory(categories, 'CanineRelationshipRight'),
        upperCentreLinesACE: findDefaultCategory(categories, 'CentreLineUpper'),
        upperCentreLinesVal: 0,
        lowerCentreLinesVal: 0,
        lowerCentreLinesACE: findDefaultCategory(categories, 'CentreLineLower'),
        crossbitesACE: findDefaultCategory(categories, 'Crossbite'),
        crossbitesLocation: [
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]],
        disDisplacementsACE: findDefaultCategory(categories, 'Displacement'),
        disDisplacementsVal: 0,
        upperLabialSegACE: findDefaultCategory(categories, 'CrowdingULS'),
        lowerLabialSegACE: findDefaultCategory(categories, 'CrowdingLLS'),
        upperBuccalSegACE: findDefaultCategory(categories, 'CrowdingUBS'),
        lowerBuccalSegACE: findDefaultCategory(categories, 'CrowdingLBS'),
        devStageACE: findDefaultCategory(categories, 'DevelopmentalStage'),
        iotnDhc: 0,
        iotnAc: 0,
        iotnDhcSubACE: findDefaultCategory(categories, 'IOTNDHCSub'),
        preACE: false,
        assymsChinPointVal: 0,
        tmjnad: false,
        tmjotherComment: "",
        siropg: false,
        siropgVal: "",
        sirceph: false,
        sircephVal: "",
        sirvitalityTesting: false,
        sirvitalityTestingVal: "",
        sirphotos: false,
        sirphotosVal: "",
        sirstudyModels: false,
        sirstudyModelsVal: "",
        midlineDiastema: 0,
        generalised: 0,
        chartType: CHART_TYPE_PRIMARY,
    };
};

export const patientAssessment = (mcId, categories) => {

    return {
        id: null,
        mc: mcId,
        assessmentDate: new Date(),
        patient: null,
        assessedBy: null,
        patientsComplaint: findDefaultCategory(categories, 'patientsComplaint'),
        smoker: findDefaultCategory(categories, 'smoker'),
        musclesOfMastication: findDefaultCategory(categories, 'musclesOfMastication'),
        speech: findDefaultCategory(categories, 'speech'),
        softTissueExam: findDefaultCategory(categories, 'softTissueExam'),
        xraysTaken: findDefaultCategory(categories, 'xraysTaken'),
        justification: findDefaultCategory(categories, 'justification'),
        actionTaken: findDefaultCategory(categories, 'actionTaken'),
        bpeRXIndicated: findDefaultCategory(categories, 'bpeRXIndicated'),
        cariesRisk: findDefaultCategory(categories, 'cariesRisk'),
        perioRisk: findDefaultCategory(categories, 'perioRisk'),
        hygienistTreatmentRequired: findDefaultCategory(categories, 'hygienistTreatmentRequired'),
        flosses: findDefaultCategory(categories, 'flosses'),
        occlusion: findDefaultCategory(categories, 'occlusion'),
        guidance: findDefaultCategory(categories, 'guidance'),
        abnormality: findDefaultCategory(categories, 'abnormality'),
        prosthesisWorn: findDefaultCategory(categories, 'prosthesisWorn'),
        toothWearIndex: findDefaultCategory(categories, 'toothWearIndex'),
        baseLine: findDefaultCategory(categories, 'baseLine'),
        mobility: findDefaultCategory(categories, 'Mobility'),
        mca: findDefaultCategory(categories, 'MCA'),
        alcohol: findDefaultCategory(categories, 'Alcohol'),
        lips: findDefaultCategory(categories, 'lips'),
        symetry: findDefaultCategory(categories, 'Symetry'),
        lymphNodes: findDefaultCategory(categories, 'lymphNodes'),
        teethMissing: [
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        ],
        twi0: findDefaultCategory(categories, 'toothWearIndex'),
        twi1: findDefaultCategory(categories, 'toothWearIndex'),
        twi2: findDefaultCategory(categories, 'toothWearIndex'),
        twi3: findDefaultCategory(categories, 'toothWearIndex'),
        twi4: findDefaultCategory(categories, 'toothWearIndex'),
        twi5: findDefaultCategory(categories, 'toothWearIndex'),
        twi6: findDefaultCategory(categories, 'toothWearIndex'),
        twi7: findDefaultCategory(categories, 'toothWearIndex'),
        twi8: findDefaultCategory(categories, 'toothWearIndex'),
        twi9: findDefaultCategory(categories, 'toothWearIndex'),
        twi10: findDefaultCategory(categories, 'toothWearIndex'),
        twi11: findDefaultCategory(categories, 'toothWearIndex'),
        twi12: findDefaultCategory(categories, 'toothWearIndex'),
        twi13: findDefaultCategory(categories, 'toothWearIndex'),
        twi14: findDefaultCategory(categories, 'toothWearIndex'),
        twi15: findDefaultCategory(categories, 'toothWearIndex'),
        twi16: findDefaultCategory(categories, 'toothWearIndex'),
        twi17: findDefaultCategory(categories, 'toothWearIndex'),
        twi18: findDefaultCategory(categories, 'toothWearIndex'),
        twi19: findDefaultCategory(categories, 'toothWearIndex'),
        twi20: findDefaultCategory(categories, 'toothWearIndex'),
        twi21: findDefaultCategory(categories, 'toothWearIndex'),
        twi22: findDefaultCategory(categories, 'toothWearIndex'),
        twi23: findDefaultCategory(categories, 'toothWearIndex'),
        twi24: findDefaultCategory(categories, 'toothWearIndex'),
        twi25: findDefaultCategory(categories, 'toothWearIndex'),
        twi26: findDefaultCategory(categories, 'toothWearIndex'),
        twi27: findDefaultCategory(categories, 'toothWearIndex'),
        twi28: findDefaultCategory(categories, 'toothWearIndex'),
        twi29: findDefaultCategory(categories, 'toothWearIndex'),
        twi30: findDefaultCategory(categories, 'toothWearIndex'),
        twi31: findDefaultCategory(categories, 'toothWearIndex'),
        mob0: findDefaultCategory(categories, 'Mobility'),
        mob1: findDefaultCategory(categories, 'Mobility'),
        mob2: findDefaultCategory(categories, 'Mobility'),
        mob3: findDefaultCategory(categories, 'Mobility'),
        mob4: findDefaultCategory(categories, 'Mobility'),
        mob5: findDefaultCategory(categories, 'Mobility'),
        mob6: findDefaultCategory(categories, 'Mobility'),
        mob7: findDefaultCategory(categories, 'Mobility'),
        mob8: findDefaultCategory(categories, 'Mobility'),
        mob9: findDefaultCategory(categories, 'Mobility'),
        mob10: findDefaultCategory(categories, 'Mobility'),
        mob11: findDefaultCategory(categories, 'Mobility'),
        mob12: findDefaultCategory(categories, 'Mobility'),
        mob13: findDefaultCategory(categories, 'Mobility'),
        mob14: findDefaultCategory(categories, 'Mobility'),
        mob15: findDefaultCategory(categories, 'Mobility'),
        mob16: findDefaultCategory(categories, 'Mobility'),
        mob17: findDefaultCategory(categories, 'Mobility'),
        mob18: findDefaultCategory(categories, 'Mobility'),
        mob19: findDefaultCategory(categories, 'Mobility'),
        mob20: findDefaultCategory(categories, 'Mobility'),
        mob21: findDefaultCategory(categories, 'Mobility'),
        mob22: findDefaultCategory(categories, 'Mobility'),
        mob23: findDefaultCategory(categories, 'Mobility'),
        mob24: findDefaultCategory(categories, 'Mobility'),
        mob25: findDefaultCategory(categories, 'Mobility'),
        mob26: findDefaultCategory(categories, 'Mobility'),
        mob27: findDefaultCategory(categories, 'Mobility'),
        mob28: findDefaultCategory(categories, 'Mobility'),
        mob29: findDefaultCategory(categories, 'Mobility'),
        mob30: findDefaultCategory(categories, 'Mobility'),
        mob31: findDefaultCategory(categories, 'Mobility'),
        labialMucosa: findDefaultCategory(categories, 'LabialMucosa'),
        bucalMucosa: findDefaultCategory(categories, 'BucalMucosa'),
        gingivae: findDefaultCategory(categories, 'Gingivae'),
        floorOfMouth: findDefaultCategory(categories, 'FloorOfMouth'),
        tongue: findDefaultCategory(categories, 'Tongue'),
        palate: findDefaultCategory(categories, 'Palate'),
        backOfThroat: findDefaultCategory(categories, 'BackOfThroat'),
        bpeScore1: "_0",
        bpeScore2: "_0",
        bpeScore3: "_0",
        bpeScore4: "_0",
        bpeScore5: "_0",
        bpeScore6: "_0",
        beweScore1: "_0",
        beweScore2: "_0",
        beweScore3: "_0",
        beweScore4: "_0",
        beweScore5: "_0",
        beweScore6: "_0",
        otherComment: '',
        xrayReport: "",
        noChange: false,
        updated: false,
        exSmoker: false,
        cigsPerDay: 0,
        howLongAgo: 0,
        unitsPerWeek: 0,
        stressometer: 0,
        xRQuality: 0,
        diagnosis: "",
        treatmentplan: "",
        oralHygiene: findDefaultCategory(categories, 'OralHygiene'),
        ohigiven: findDefaultCategory(categories, 'OHIGiven'),
        hpc: "",
        mhu: "",
        scag: findDefaultCategory(categories, 'SCAG'),
        arag: findDefaultCategory(categories, 'ARAG'),
        tmj: findDefaultCategory(categories, 'TMJ'),
    }
};

export const chargeData = (mcId) => {

    return {
        id: -1,
        mc: mcId,
        item: null,
        chargedPrice: 0.0,
        quantity: 1,
        chargedBy: null,
        appointment: null,
        chargedTo: null,
        site: '',
        status: CH_SAVED,
        dateAdded: new Date(),
        comment: '',
        invoiceComment: '',
        invoice: null,
        accountGroup: null,
        receipt: null,
        planIndex: 0,
        appointmentIndex: 0,
        treatmentPlan: null,
        chartEntry: null,
        clinicalDataId: null,
        otherServiceId: null,
    }
};

export const treatmentPlanData = (mcId) => {

    const plan = {
        id: null,
        mc: mcId,
        title: 'Standard Course of Treatment',
        description: '',
        patient: null,
        provider: null,
        createdBy: null,
        created: new Date(),
        lastModified: null,
        acceptenceDate: null,
        status: TP_PROPOSED.name,
        appointmentsSorted: false,
        unassignedCharges: [],
        appointments: [],
        nice: 0,
        bestPracticePrevention: false,
        referralForAMS: false,
        urgentTreatment: false,
        furtherTreatment: false,
        parScoreCalculated: false,
        kpis: [],
        claimForm: null,
        completionReason: null,
        referralDate: null,
        lastTreatmentDate: null,
        content: '',
    };
    Codes9000KPIDataSet.forEach(code => plan.kpis.push(false));
    return plan;
};

export const mailShotData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        type: MS_EMAIL,
        created: null,
        sentOn: null,
        createdBy: null,
        title: '',
        description: '',
        comments: '',
        content: '',
        recipients: [],
        operators: [Null],
        dateOfBirthOne: null,
        dateOfBirthTwo: null,
        nextAppointmentOne: null,
        nextAppointmentTwo: null,
        registeredOne: null,
        registeredTwo: null,
        patientJourneyOne: null,
        patientJourneyTwo: null,
        male: false,
        withProviderId: null,
        patientJourneyCodeId: null,
        patientJourneyCodeIds: [],
    }
}

export const paymentPlanData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        status: PP_STATUS_PROPOSED.name,
        numberOfinstallments: 1,
        preProcedurePercentage: PP_PRECENT0.value,
        instalInterval: PP_INTERVAL_MONTHLY.value,
        settlementType: PP_SET_TYPE_OPTION1_CC.value,
        debitCardDiscount: 0.0,
        creditCardDiscount: 0.0,
        planTotal: 0.0,
        deposit: 0.0,
        preProcedurePercentageAmount: 0.0,
        installmentAmount: 0,
        finalInstallmentAmount: 0.0,
        totalPayment: 0.0,
        patient: null,
        arrangedBy: null,
        agreementDate: new Date(),
        depositDate: new Date(),
        firstInstallment: new Date(),
        lastInstallment: new Date(),
        notes: '',
        treatmentPlan: null,
        chargeableItem: null,
        numberOfCopies: 1, // Transient
        accountGroup: null,
    }
};

export const receiptData = (mcId) => {

    return {
        id: -1,
        mc: mcId,
        placementOrder: new Date(),
        charges: [],
        raisedOn: new Date(),
        receiptee: {id: null},
        raisedBy: {id: null},
        appointment: null,
        total: 0.0,
        allocatedToDate: 0.0,
        refundedToDate: 0.0,
        amountReceived: 0.0,
        comment: '',
        receiptString: '',
        status: 0,
        paymentType: PAY_TYPE_CASH.value,
        ccType: null,
        dcType: null,
        chequeNumber: '',
        sortCode: '00-00-00',
        transactionNumber: '',
        voider: null,
        voidedOn: null,
        voidNarrative: '',
        accountGroup: null
    }
};

export const paymentData = (mcId) => {

    return {
        id: -1,
        itemNumber: null,
        mc: mcId,
        placementOrder: new Date(),
        amount: 0.0,
        allocatedToDate: 0.0,
        refundedToDate: 0.0,
        status: PAY_STATE_AVAILABLE,
        paymentString: '',
        patient: null,
        paymentType: PAY_TYPE_CASH.value,
        ccType: null,
        dcType: null,
        narrative: '',
        chequeNumber: '',
        sortCode: '00-00-00',
        transactionNumber: '',
        date: new Date(),
        takenBy: {id: null},
        paidBy: null,
        adjustments: [],
        voider: null,
        voidedOn: null,
        voidNarrative: '',
        accountGroup: null,
        invoiceId: null,
    }
};

export const changePaymentTypeData = (mcId) => {


return {
    id: -1,
    paymentType: PAY_TYPE_CASH.value,
    ccType: null,
    dcType: null,
    comment: '',
    chequeNumber: '',
    sortCode: '00-00-00',
    transactionNumber: '',
    date: new Date(),
    takenBy: {id: null},
}
};
export const patientJourney = (mcId) => {

    return {
        id: null,
        mc: mcId,
        patient: null,
        stage: null,
        narrative: '',
        changedOn: new Date(),
    }
};

export const patientJourneyData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        code: '',
        description: '',
        status: PJS_USABLE,
        ranking: -1,
        red: 255,
        green: 245,
        blue: 255,
        action: PJ_None.value,
        recallType: null,
    }
};

export const DocumentClassificationData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        code: '',
        description: '',
        status: DOCLASS_USABLE,
        red: 255,
        green: 255,
        blue: 255,
    }
};

export const medicalConditionData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        name: '',
        description: '',
        action: '',
        formEntry: '',
        level: 0,
        importReference: '',
        includeInMedicalistoryForm: false,
        medicalHistoryFormIndex: 0,
    }
};

export const medicationData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        code: '',
        name: '',
        description: '',
    }
};

export const questionData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        name: '',
        description: '',
        formEntry: '',
        formHeader: false,
        includeInQuestionnaire: false,
        questionnaireIndex: 0,
    }
};

export const AssCatData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        name: '',
        text: '',
        category: null,
        rank: -10,
        red: 255,
        green: 255,
        blue: 255,
        defaultCategory: false,
    }
};

export const MedicalHistoryForm = (mcId) => {

    return {
        id: null,
        mc: mcId,
        createdOn: new Date(),
        expiresOn: new Date(),
        patient: null,
        entries: [],
        extras: '',
        signatureId: null,
        state: MHF_ST_Current,
    }
};

export const Questionnaire = (mcId) => {

    return {
        id: null,
        mc: mcId,
        preAppointmentDate: new Date(),
        uponArrivalDate: new Date(),
        patient: null,
        entries: [],
        signatureId: null,
        state: MHF_ST_Current,
        o2Saturation: 0.0,
        temparature: 0.0,
    }
};

export const ConsentForm = (mcId) => {

    return {
        id: null,
        mc: mcId,
        patient: null,
        appointment: null,
        treatmentPlan: null,
        paymentPlan: null,
        consentDate: new Date(),
        outcome: OUTCOME_ConsentNotGiven,
        signatureDocument: null,
        consentDocument: null,
        templateId: null,
        saved: false,
    }
};

export const recallData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        placedBy: null,
        statusChangedBy: null,
        comment: '',
        patient: null,
        appointment: null,
        placedOn: new Date(),
        placedFor: new Date(),
        sentOn: new Date(),
        statusChanged: null,
        recallType: null,
        state: REC_STATUS_OPEN.name,
        closingState: null,
        provider: null,
    };
};

export const recallTypeData = (mcId) => {

    return {
        id: null,
        mc: mcId,
        code: '',
        title: '',
        description: '',
        narrative: '',
        red: 255,
        green: 255,
        blue: 255,
        interval: 3,
        durationMins: 0,
        limit: 4,
        archived: false,
        keepSeparate: false,
        method: RRT_RECALL_SNAILMAIL.name,
        templateId: null,
        emailTemplateId: null,
        smsTemplateId: null,
    };
};

export const invoiceData = (mcId) => {
    return {
        id: null,
        mc: mcId,
        placementOrder: new Date(),
        credits: [],
        charges: [],
        raisedOn: new Date(),
        invoicer: null,
        invoicee: null,
        raisedBy: null,
        appointment: null,
        total: 0.0,
        paidToDate: 0.0,
        comment: '',
        invoiceString: 'INV',
        status: INV_DUE,
        accountGroup: null,
        periodPrintRequired: true,
        partOfPaymentPlan: false,
        periodPrint: INV_OUT_30,
        voider: null,
        voidedOn: null,
        narrative: '',
        importReference: '',
        paid: false,

    };
};

export const PatientDocumentDAO = (mcId) => {

    return {
        docId: null,
        patientId: null,
        itemId: null,
        filename: null,
        thumbnailFilename: null,
        visibleName: null,
        notes: null,
        defaultPrinter: null,
        userName: null,
        created: new Date(),
        type: 0,
        imageAssessment: null,
        report: null,
        deletable: false,
        selected: false,
        hasConditions: false,
    }
};

export const TabletMember = (mcId) => {

    return {
        id: null,
        mc: mcId,
        member: null,
        addedOn: new Date(),
        addedBy: null,
        comment: '',
        type: '',
        consentFormIds: [],
        treatmentPlan: null,
        paymentPlan: null,
        templateId: null,
    }
};

export const AppointmentCancelDAO = (mcId) => {

    return {
        id: null,
        mcId: mcId,
        reasonId: null,
        chargeId: null,
        cancelledbyId: null,
        comment: "",
        type: SystemReason,
    }
};

export const PatientNote = (mcId, props) => {

    return {
        id: null,
        mc: mcId,
        created: new Date(),
        createdBy: {id: props.loginIdentity.id},
        patient: {id: props.patId},
        subject: '',
        content: '',
        appointment: null,
        importReference: '',
        type: PN_Info.value,
        popUp: false,
        active: true,
        closeableByAnyone: false,
        diaryNote: false,
        closedBy: null,
        closedOn: null,
        diaryDate: null,
    }
};

export const PatientXRAYRecord = (mcId) => {

    return {
        id: null,
        mc: mcId,
        patient: null,
        type: XT_PANORAMIC.value,
        rating: XR_EXCELLENT.value,
        takenBy: null,
        takenOn: new Date(),
        natureOfDeficiency: '',
        causeOfDeficiency: '',
        noOfRepeats: 0,
    }
};

export const PatientSedationRecord = (mcId) => {

    return {
        id: null,
        mc: mcId,
        patientId: null,
        administeredById: null,
        assistedById: null,
        appointmentId: null,
        administeredOn: new Date(),
        involvedAs: IA_S.value,
        asa: ASA_I.value,
        sedationTechnique: ST_RA.value,
        sedationScoring: SS_1.value,
        operatingConditions: OCON_1.value,
        recovery: Rec_Normal.value,
        feedback: FB_0.value,
        o2Percentage: 0.0,
        duration: 0,
        hours: 0,
        mins: 0,
    }
};

export const Refund = (mcId) => {
    return {
        id: null,
        mc: mcId,
        itemNumber: null,
        placementOrder: new Date(),
        dateRaised: new Date(),
        raisedBy: null,
        refundedTo: null,
        refundAmount: 0.0,
        comment: '',
        refundString: 'REF',
        payment: null,
        receipt: null,
        voided: false,
        voider: null,
        voidedOn: new Date(),
        voidNarrative: '',
    };
};

export const GeneralNote = (mcId) => {
    return {
        id: null,
        mc: mcId,
        created: null,
        createdBy: null,
        subject: '',
        content: '',
        patient: null,
        appointment: null,
        importReference: '',
        noteType: NOTE_Info.type,
        popUp: false,
        active: false,
        closeableByAnyOne: false,
        diaryNote: false,
        closedBy: null,
        closedOn: null,
        diaryDate: null,
    };
};

export const ReportText = (mcId) => {
    return {
        id: null,
        mc: mcId,
        created: null,
        createdBy: null,
        type: {ordinal: RT_PATIENT_CONSENT_FORM.ordinal},
        name: '',
        comment: '',
        spare1: '',
        spare2: '',
        spare3: '',
        text: '',
    };
};

export const CreditNote = (mcId) => {
    return {
        id: null,
        mc: mcId,
        itemNumber: null,
        placementOrder: new Date(),
        dateRaised: new Date(),
        raisedBy: null,
        creditedTo: null,
        creditAmount: 0.0,
        comment: '',
        creditString: 'CN',
        invoice: null,
        voided: false,
        voider: null,
        voidedOn: new Date(),
        voidNarrative: '',
    };
};

export const Task = (mcId) => {
    return {
        id: null,
        mc: mcId,
        priority: '',
        status: '',
        action: '',
        Category: null,
        repeating: false,
        hasStartTime: false,
        hasDuration: false,
        sendEmail: false,
        hasStartDate: false,
        hasEndDate: false,
        createdOn: new Date(),
        endDate: null,
        statusChangedDate: null,
        createdBy: null,
        statusChangedBy: null,
        participants: [],
        title: '',
        description: '',
        narrative: '',
        spare1: '',
        spare2: '',
        startdt: null,
        enddt: null,
        red: 0,
        green: 0,
        blue: 0,
        repCount: 0,
        actualCount: 0,
        initiatingAppointment: null,
        daysHence: 0,
        treatmentPlan: null,
        patient: null,
        appointmentType: null,
        salesTotal: 0.0,
        consentForms: [],
    };
};

export const DiaryEventType = (mcId) => {

    return {
        id: null,
        mc: ac.getMcId(),
        code: '',
        title: '',
        description: '',
        reservationType: false,
        type: DE_TYPE_NONE,
        red: 255,
        green: 255,
        blue: 255,
        archived: false,
    };
};

export const PerioChart = (mcId) => {
    return {
        id: null,
        mc: mcId,
        comments: '',
        patient: null,
        chartedBy: null,
        chartedOn: new Date(),
        status: Current,
        mobility: [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ],
        recession: [
            [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ],
        ],
        pockets: [
            [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ],
        ],
        furcation: [
            [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ],
        ],

        implants: [
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
        ],
        missing: [
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
        ],

        bleeding: [
            [
                [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false],
                [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false],
            ], [
                [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false],
                [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false],
            ]
        ],
        plaque: [
            [
                [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false],
                [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false],
            ], [
                [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false],
                [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false], [false, false, false, false],
            ]
        ],

        bleeders: [
            [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ],
        ],
        suppurators: [
            [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ], [
                [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0],
            ],
        ],
    }
};

export const NHSClaim = (mcId) => {

    return {
        id: null,
        mc: ac.getMcId(),
        claimType: EDI_CT_FP17,
        claimForm: EDI_CF_None,
	    status: EDI_ST_Stacked,
        treatmentPlan: null,
	    performer: null,
	    patientNHSDetails: null,
	    claimReferenceNumber: null,
        icSeqNo: null,
        contractNumber: null,
        retransmissionCount: 1,
        messageNo: 0,
        disabilityFee: false,
        replacementSubmission: false,
	    declarationsBCDS_1: false,
        declarationsBCDS_2: false,
        declarationsBCDS_64: false,
        completionReason: EDI_CR_None,
	    stackingDate: new Date(),
        submissionDate: new Date(),
        referralDate: new Date(),
	    removalDate: new Date(),
	    removalNarrative: '',
        iotnScore: 1,
        iotnCompScore: 0,
	    aestheticComponent: 0,
        aestheticComponentComp: 0,
	    trtdatgrpDateOfAcceptance: new Date(),
        trtdatgrpDateOfCompletion: new Date(),
        trtdatgrpDateOfExamination: new Date(),
        chxPatientCharge: 0.0,
	    chxCurrencyCode: CurrencyCodes[0], //gbp
        chxExmpAndRemissionCode: _00,
	    chxSupportingDetails: '', // this can be a free text item or a 6 digit date, depends on chx code
        observation: '',
        responseCodes:[0,0,0,0,0,0,0,0,0,0,0,0],
        nice: 0,
        completedBand: NoBand.id,
        incompletedBand: NoBand.id,
        treatmentCategory: _0000.id,
        clinicalData: [],
        otherServices: [],
        parScoreCalculated: false,
        furtherTreatment: false,
        preTreatmentParScore: 0,
        postTreatmentParScore: 0,
	    units: 0.0,
        narrativeDate: new Date(),
        narrative: '',
        SQInd: false,
        commApproval: false,
        lastEditDate: null,
    };
};

export const LocationData = (mcId) => {

    return {
        id: null,
        mc: mcId,

        status: LOC_STATUS_INACTIVE,
        type: LOC_COLOCATED,

        registeredOn: null,
        name: '',

        shortAddress: '',
        addressAsPractice: true,

        address: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {id: CC_UK} // UK
        },
        telephone: '',
        mobile: '',
        email: '',

        note: '',
    }
};

export const LaboratoryData = (mcId) => {

    return {

        id: null,
        mc: mcId,
        status: LAB_STATUS_ACTIVE,
        registeredOn: new Date(),
        name: '',
        mainContact: '',
        telephone: '',
        workTelephone: '',
        fax: '',
        mobile: '',
        email: '',
        workPlaced: [],
        balance: 0.0,
        note: '',
        contactDetails: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {id: CC_UK}
        },
    }
};

export const WorkRequired = (mcId, props) => {

    return {
        id: null,
        mc: mcId,

        patient: {id: props.patientId},
        issuingAppointment: {appointmentId: props.appointmentId},
        returnAppointment: null,
        returnDate: new Date(),

        requestedBy: {id: props.loginIdentity.id},
        requestedOn: new Date(),

        sentOn: new Date(),

        labNotifiedBy: null,
        labNotifiedOn: null,

        receivedBy: null,
        receivedOn: null,

        approvedBy: null,
        approvedOn: null,

        placedOnHoldBy: null,
        placedOnHoldOn: null,

        cancelledBy: null,
        cancelledOn: null,

        status: WORK_STATUS_REQUESTED,
        returnType: WORK_TYPE_BY_DATE.value,
        note: '',
        laboratory: null,
        charge: null,

        requiresApproval: true,
    }
};

export const ChartData = {
    labels: ['Excellent', 'Acceptable', 'Unacceptable'],
    datasets: [
        {
            backgroundColor: [
                "#5ee226",
                "#f89040",
                "#f1141b"
            ],
            backgroundColorType: [
                "#badeab",
                "#c19c7f",
                "#ba7274",
                "#75b890",
                "#6481b3"
            ],
            hoverBackgroundColor: [
                "#5ee226",
                "#f89040",
                "#f1141b"
            ]
        }
    ]
}

const ChartDataSetColours = [
    {
        backgroundColor: [
            "#badeab",
            "#c19c7f",
            "#ba7274",
            "#75b890",
            "#198cdb",
            "#6481b3"
        ],
        backgroundColorType: [
            "#badeab",
            "#c19c7f",
            "#ba7274",
            "#75b890",
            "#198cdb",
            "#6481b3"
        ],
        hoverBackgroundColor: [
            "#badeab",
            "#c19c7f",
            "#ba7274",
            "#75b890",
            "#198cdb",
            "#6481b3"
        ]
    }
]

export const ChartDataSedationIA = {
    labels: ['Observer', 'Assistant', 'Provider', 'Dentist'],
    datasets: ChartDataSetColours
}

export const ChartDataSedationASA = {
    labels: ['Normal healthy', 'Mild systemic disease', 'Noticeable systemic disease', 'Incapacitating disease', 'Not expected to live more than 24 hours'],
    datasets: ChartDataSetColours
}

export const ChartDataSedationST = {
    labels: ['Intravenous Sedation', 'Inhalation Sedation', 'Oral Sedation', 'Transmucosal'],
    datasets: ChartDataSetColours
}

export const ChartDataSedationSS = {
    labels: ['Anxious, restless or both', 'Cooperative, orientated and tranquil', 'Responding to commands', 'Brisk response to stimulus', 'Sluggish response to stimulus', 'No response to stimulus'],
    datasets: ChartDataSetColours
}

export const ChartDataSedationOCON = {
    labels: ['Patient fully cooperative with optimum degree of sedation', 'Minimal interference from patient due to over/under sedation', 'Operating difficult due to over/under sedation', 'Action taken (e.g. GA)'],
    datasets: ChartDataSetColours
}

export const ChartDataSedationRec = {
    labels: ['Within the timescale expected', 'Sooner than normal & action taken', 'Longer than normal & action taken'],
    datasets: ChartDataSetColours
}

export const ChartDataSedationFB = {
    labels: ['Happy to repeat if needed and would recommend to others', 'Neutral about the outcome', 'Negative patient feedback, prefer not to have again'],
    datasets: ChartDataSetColours
}
