export const CreateContactListFromReport = 'Create a contact list from this report';
export const ShowPatientAccount = "ShowPatientAccount";
export const PrintPatientAccount = "PrintPatientAccount";
export const TextPatientAccount = "the patient account";

export const ShowChart = "ShowChart";
export const PrintChart = "PrintChart";
export const TextChart = "the patient chart";

export const ShowPerioChart = "ShowPerioChart";
export const PrintPerioChart = "PrintPerioChart";
export const TextPerioChart = "the patient perio chart";

export const EmailAccountItem = "EmailAccountItem";
export const TextAccountItem = "Email the patient account item";
export const TextAccountItem2 = "patient account item";
export const IconAccountItem = "icons/email24.png";

export const ShowNegativePatientAccount = "ShowNegativePatientAccount";
export const PrintNegativePatientAccount = "PrintNegativePatientAccount";
export const TextNegativePatientAccount = "the outstanding patient account list";
export const TextNegativePatientAccountExcel = "the outstanding patient account list Excel file";

export const ShowPositivePatientAccount = "ShowPositivePatientAccount";
export const PrintPositivePatientAccount = "PrintPositivePatientAccount";
export const TextPositivePatientAccount = "the patient accounts in credit list";

export const ShowRevenueShare = "ShowRevenueShare";
export const PrintRevenueShare = "PrintRevenueShare";
export const TextRevenueShare = "the account group revenue shares";

export const ShowWeeksRevenueShare = "ShowWeeksRevenueShare";
export const PrintWeeksRevenueShare = "PrintWeeksRevenueShare";
export const TextWeeksRevenueShare = "the account groups weekly revenue shares";
export const TextWeeksRevenueShareExcel = "the account groups weekly revenue shares Excel file";

export const ShowMonthsRevenueShare = "ShowMonthsRevenueShare";
export const PrintMonthsRevenueShare = "PrintMonthsRevenueShare";
export const TextMonthsRevenueShare = "the account groups monthly revenue shares";
export const TextMonthsRevenueShareExcel = "the account groups monthly revenue shares Excel file";

export const ShowMonthsCashShare = "ShowMonthsCashShare";
export const PrintMonthsCashShare = "PrintMonthsCashShare";
export const TextMonthsCashShare = "the account groups monthly cash";
export const TextMonthsCashExcel = "the account groups monthly cash Excel file";

export const ShowYearsCashShare = "ShowYearsCashShare";
export const PrintYearsCashShare = "PrintYearsCashShare";
export const TextYearsCashShare = "the account groups yearly cash";
export const TextYearsCashShareExcel = "the account groups yearly cash file";

export const ShowCostCenter = "ShowCostCenter";
export const PrintCostCenter = "PrintCostCenter";
export const TextCostCenter = "the cost centres by account group";

export const ShowVoidedTrans = "ShowVoidedTrans";
export const PrintVoidedTrans = "PrintVoidedTrans";
export const TextVoidedTrans = "the account groups voided transactions";

export const ShowWriteOffs = "ShowWriteOffs";
export const PrintWriteOffs = "PrintWriteOffs";
export const TextWriteOffs = "the written off accounts";

export const ShowYearlyRevenue = "ShowYearlyRevenue";
export const PrintYearlyRevenue = "PrintYearlyRevenue";
export const TextYearlyRevenue = "the account group yearly revenue";

export const ShowDaysCash = "ShowDaysCash";
export const PrintDaysCash = "PrintDaysCash";
export const TextDaysCash = "the days cash";
export const TextDaysCashExcel = "the days cash Excel file";

export const ShowPaymentPlans = "ShowPaymentPlans";
export const PrintPaymentPlans = "PrintPaymentPlans";
export const TextPaymentPlans = "payment plans";

export const ShowInstallments = "ShowInstallments";
export const PrintInstallments = "PrintInstallments";
export const TextInstallments = "instalments";

export const ShowAccountsInCredit = "ShowAccountsInCredit";
export const PrintAccountsInCredit = "PrintAccountsInCredit";
export const TextAccountsInCredit = "accounts in credit";

export const ShowUninvoicedCharges = "ShowUninvoicedCharges";
export const PrintUninvoicedCharges = "PrintUninvoicedCharges";
export const TextUninvoicedCharges = "uninvoiced charges";

export const ShowIncompleteCharges = "ShowIncompleteCharges";
export const PrintIncompleteCharges = "PrintIncompleteCharges";
export const TextIncompleteCharges = "incomplete charges";

export const ShowDaysTransactions = "ShowDaysTransactions";
export const PrintDaysTransactions = "PrintDaysTransactions";
export const TextDaysTransactions = "the days transactions";
export const TextDaysTransactionsExcel = "the days transactions Excel file";

export const ShowDaysInvoicesReceipts = "ShowDaysInvoicesReceipts";
export const PrintDaysInvoicesReceipts = "PrintDaysInvoicesReceipts";
export const RePrintDaysInvoices = "RePrintDaysInvoices";

export const CreateDaysInvoicesReceipts = "CreateDaysInvoicesReceipts";
export const IconCreateDaysInvoicesReceipts = "icons/Save.png";

export const TextRePrintDaysInvoices = "Reprint the days invoices";
export const TextDaysInvoicesReceipts = "Save the days transactions";
export const IconReprintForm = "icons/printerRefresh.png";

export const ShowWeeksInvoicesReceipts = "ShowWeeksInvoicesReceipts";
export const PrintWeeksInvoicesReceipts = "PrintWeeksInvoicesReceipts";
export const TextWeeksInvoicesReceipts = "the weeks transactions";

export const ShowInvoicesDueList = "ShowInvoicesDueList";
export const PrintInvoicesDueList = "PrintInvoicesDueList";
export const TextInvoicesDueList = "the due invoice list";

export const ShowOverDueList = "ShowOverDueList";
export const PrintOverDueList = "PrintOverDueList";
export const TextOverDueList = "the overdue invoice list";

export const ShowBGOverDueList = "ShowBGOverDueList";
export const PrintBGOverDueList = "PrintBGOverDueList";
export const TextBGOverDueList = "the overdue biiling group invoice list";

export const RefreshUninvoicedTreatments = "RefreshUninvoicedTreatments";
export const ShowUninvoicedTreatments = "ShowUninvoicedTreatments";
export const PrintUninvoicedTreatments = "PrintUninvoicedTreatments";
export const TextUninvoicedTreatments = "the uninvoiced treatment list";
export const TextRefreshUninvoicedTreatments = "Refresh the uninvoiced treatment list";
export const IconRefreshForm = "icons/formRefresh2.png";

export const ShowIncompleteTreatments = "ShowIncompleteTreatments";
export const PrintIncompleteTreatments = "PrintIncompleteTreatments";
export const TextIncompleteTreatments = "the incomplete treatment list";

export const ShowTreatmentHistory = "ShowTreatmentHistory";
export const PrintTreatmentHistory = "PrintTreatmentHistory";
export const TextTreatmentHistory = "the patient treatment history";

export const ShowLabHistory = "ShowLabHistory";
export const PrintLabHistory = "PrintLabHistory";
export const TextLabHistory = "the laboratory history";

export const ShowLabRequestHistory = "ShowLabRequestHistory";
export const PrintLabRequestHistory = "PrintLabRequestHistory";
export const TextLabRequestHistory = "the laboratory request history";

export const ShowScheduledInvoices = "ShowScheduledInvoices";
export const PrintScheduledInvoices = "PrintScheduledInvoices";
export const TextScheduledInvoices = "the scheduled invoice list";

export const ShowIssuableInvoices = "ShowIssuableInvoices";
export const PrintIssuableInvoices = "PrintIssuableInvoices";
export const TextIssuableInvoices = "the issuable invoice list";

export const ShowAppointmentHistory = "ShowAppointmentHistory";
export const PrintAppointmentHistory = "PrintAppointmentHistory";
export const TextAppointmentHistory = "the patient appointment history";

export const ShowRecallsDue = "ShowRecallsDue";
export const PrintRecallsDue = "PrintRecallsDue";
export const TextRecallsDue = "the recalls due list";

export const ShowRecallsManagement = "ShowRecallsManagement";
export const PrintRecallsManagement = "PrintRecallsManagement";
export const TextRecallsManagement = "the recall management";

export const PrintRecallsSentToday = "PrintRecallsSentToday";
export const TextRecallsSentToday = "the recalls sent today";
export const ExtraIconRecallsSentToday = "icons/contactListAdd24.png";

export const ShowRemindersDue = "ShowRemindersDue";
export const PrintRemindersDue = "PrintRemindersDue";
export const TextRemindersDue = "the reminders due list";

export const ShowBackupHistory = "ShowBackupHistory";
export const PrintBackupHistory = "PrintBackupHistory";
export const TextBackupHistory = "the database backup history";

export const ShowDaysCancellations = "ShowDaysCancellations";
export const PrintDaysCancellations = "PrintDaysCancellations";
export const TextDaysCancellations = "the days cancellation list";

export const ShowWeeksCancellations = "ShowWeeksCancellations";
export const PrintWeeksCancellations = "PrintWeeksCancellations";
export const TextWeeksCancellations = "the weeks cancellation list";

export const ShowWeeksRegistrations = "ShowWeeksRegistrations";
export const PrintWeeksRegistrations = "PrintWeeksRegistrations";
export const TextWeeksRegistrations = "the weeks registration list";

export const ShowWeeksFTAs = "ShowWeeksFTAs";
export const PrintWeeksFTAs = "PrintWeeksFTAs";
export const TextWeeksFTAs = "the weeks failed to attend list";

export const ShowWeeksXraySummary = "ShowWeeksXraySummary";
export const PrintWeeksXraySummary = "PrintWeeksXraySummary";
export const TextWeeksXraySummary = "the weeks xray summary";

export const ShowWeeksXrayReport = "ShowWeeksXrayReport";
export const PrintWeeksXrayReport = "PrintWeeksXrayReport";

export const TextWeeksXrayReport = "the weeks xray records";
export const TextMonthlyXrayReport = "the months xray records";
export const TextQuarterlyXrayReport = "the quarterly xray records";
export const TextBiannualXrayReport = "the biannual xray records";
export const TextYearlyXrayReport = "the yearly xray records";
export const TextWeeksSedationSummary = "the weeks sedation summary";

export const TextWeeksSedReport = "the weeks sedation records";
export const TextMonthlySedReport = "the months sedation records";

export const ShowTreatmentPlan = "ShowTreatmentPlan";
export const PrintTreatmentPlan = "PrintTreatmentPlan";
export const TextTreatmentPlan = "the treatment plan";
export const ExtraTextTreatmentPlanID = "CreateNewPatientTPDocument";
export const ExtraTextTreatmentPlan = "Create a patient document";
export const ExtraIconTreatmentPlan = "icons/treatmentPlanDoc24.png";

export const ShowNonAttendences = "ShowNonAttendences";
export const PrintNonAttendences = "PrintNonAttendences";
export const TextNonAttendences = "the non attendences";
export const RefreshNonAttendences = "RefreshNonAttendences";
export const TextRefreshNonAttendences = "Refresh the non attendences";

export const ShowPersonalDetails = "ShowPersonalDetails";
export const PrintPersonalDetails = "PrintPersonalDetails";
export const TextPersonalDetails = "the patient personal details";

export const ShowPatientNote = "ShowPatientNote";
export const PrintPatientNote = "PrintPatientNote";
export const TextPatientNote = "the patient note";

export const ShowPatientNotes = "ShowPatientNotes";
export const PrintPatientNotes = "PrintPatientNotes";
export const TextPatientNotes = "the patient note(s)";

export const ShowPatientMedCon = "ShowPatientMedCon";
export const PrintPatientMedCon = "PrintPatientMedCon";
export const TextPatientMedCon = "the patient medical condition";

export const ShowContactListDetails = "ShowContactListDetails";
export const PrintContactListDetails = "PrintContactListDetails";
export const TextContactListDetails = "the contact list details";
export const AddReportAsContactList = "AddReportAsContactList";
export const TextReportAsContactList = "Create a contact list from this report";
export const ExtraIconReportAsContactList = "icons/contactListAdd24.png";

export const ShowAssociateStatement = "ShowAssociateStatement";
export const PrintAssociateStatement = "PrintAssociateStatement";
export const TextAssociateStatement = "the associate statement";

export const ShowNoteHistory = "ShowNoteHistory";
export const PrintNoteHistory = "PrintNoteHistory";
export const TextNoteHistory = "the patient note history";

export const ShowNHSManagement = "ShowNHSManagement";
export const PrintNHSManagement = "PrintNHSManagement";
export const TextNHSManagement = "the performers claim history";

export const ShowMedconHistory = "ShowMedconHistory";
export const TextShowMedconHistory = "the patient medical history";
export const ExtraShowIconMedconHistory = "icons/eye.png";

export const PrintMedconHistory = "PrintMedconHistory";
export const TextPrintMedconHistory = "the patient medical history";
export const ExtraPrintIconMedconHistory = "icons/printer.png";

export const ToggleBase = "ToggleBase";
export const TextToggleBase = "Toggle the patient base / treatment charting";
export const IconToggleBase = "icons/formRefresh2.png";

export const ToggleBaseAndTreatments = "ToggleBaseAndTreatments";
export const TextToggleBaseAndTreatments = "Toggle the patient base / current charting";
export const IconToggleBaseAndTreatments = "icons/formRefresh2.png";

export const ShowTreatmentCodes = "ShowTreatmentCodes";
export const PrintTreatmentCodes = "PrintTreatmentCodes";
export const TextTreatmentCodes = "the active treatment codes";

export const ShowPatientJourneyStages = "ShowPatientJourneyStages";
export const PrintPatientJourneyStages = "PrintPatientJourneyStages";
export const TextPatientJourneyStages = "the active patient journey stages";

export const ShowPatientPaymentPlans = "ShowPatientPaymentPlans";
export const PrintPatientPaymentPlans = "PrintPatientPaymentPlans";
export const TextPatientPaymentPlans = "the active patient payment plans";

export const ShowReferredPatients = "ShowReferredPatients";
export const PrintReferredPatients = "PrintReferredPatients";
export const TextReferredPatients = "this referrers patients";

export const ShowUserPatients = "ShowUserPatients";
export const PrintUserPatients = "PrintUserPatients";
export const TextUserPatients = "this user's patients";

export const EmailAccountPreview = "EmailAccountPreview";
export const EmailAccount = "EmailAccount";
export const TextEmailAccount = "Email the patient account";
export const IconEmailAccount = "icons/email24.png";

export const ShowPatientJourney = "ShowPatientJourney";
export const PrintPatientJourney = "PrintPatientJourney";
export const TextPatientJourney = "the patient journey status";
export const TextPatientJourneyExcel = "the patient journey Excel file";

export const ShowPatientJourneySnapShot = "ShowPatientJourneySnapShot";
export const PrintPatientJourneySnapShot = "PrintPatientJourneySnapShot";
export const TextPatientJourneySnapShot = "the patient journey status snap shot";

export const ShowRecalls = "ShowRecalls";
export const PrintRecalls = "PrintRecalls";
export const TextRecalls = "the recalls";
export const TextForRecallContactList = "Create a contact list from the selected recalls";
export const RecallsContactList = "RecallsContactList";

export const ShowPerformerUDAs = "ShowPerformerUDAs";
export const PrintPerformerUDAs = "PrintPerformerUDAs";
export const TextPerformerUDAs = "the performers UDAs";

export const ShowPerformerStacked = "ShowPerformerStacked";
export const PrintPerformerStacked = "PrintPerformerStacked";
export const TextPerformerStacked = "the performers stacked claims";

export const ShowPerformerRejected = "ShowPerformerRejected";
export const PrintPerformerRejected = "PrintPerformerRejected";
export const TextPerformerRejected = "the performers rejected claims";

export const ShowPerformerRemoved = "ShowPerformerRemoved";
export const PrintPerformerRemoved = "PrintPerformerRemoved";
export const TextPerformerRemoved = "the performers removed claims";

export const ShowPerformerSubmitted = "ShowPerformerSubmitted";
export const PrintPerformerSubmitted = "PrintPerformerSubmitted";
export const TextPerformerSubmitted = "the performers submitted claims";

export const ShowPerformerScheduled = "ShowPerformerScheduled";
export const PrintPerformerScheduled = "PrintPerformerScheduled";
export const TextPerformerScheduled = "the performers scheduled claims";

export const ShowOutstandingTasks = "ShowOutstandingTasks";
export const PrintOutstandingTasks = "PrintOutstandingTasks";
export const TextOutstandingTasks = "the outstanding tasks";

export const ShowTreatmentCodesRep = "ShowTreatmentCodesRep";
export const PrintTreatmentCodesRep = "PrintTreatmentCodesRep";
export const TextTreatmentCodesRep = "the patient treatment code usage";
export const TextTreatmentCodesXLS = "the patient treatment code usage";

export const ShowCop9Report = "ShowCop9Report";
export const PrintCop9Report = "PrintCop9Report";
export const TextCop9Report = "the COP9 report";
export const TextCop9XLS = "the COP9 Excel file";

export const ShowSMSCommunications = "ShowSMSCommunications";
export const PrintSMSCommunications = "PrintSMSCommunications";
export const TextSMSCommunications = "the patient SMS communications";

export const TextDownloadPatientDocument = "patient document";
