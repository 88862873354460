import React from 'react';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {Panel} from 'primereact/components/panel/Panel';

import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {BaseComponent} from "../../../BaseComponent";
import {TB_UNDER_UDA_MANAGEMENT, UDA_REGISTRATION_DETAILS} from "../../NHSManagaement/Constants";
import {inputText, password} from "../../../PatientDynamicItems/OnChangeUtils";
import {getResource, RES_PREFERENCES_NHS} from "../../../../actions/preferences";
import {getUserIds} from "../Utils";
import _ from "lodash";
import {updateUserNHSDetails} from "../../../../actions/users";
import {ProgressBar} from "primereact/progressbar";

export class ConnectedUDAManagement extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {

            contractsLoaded: false,
            contracts: [],

            details: {
                userDataId: props.userDataId,
                underUDAManagement: props.userData.underUDAManagement,
                contractId: props.userData.contractId,
                performerNumber: props.userData.performerNumber,
                providerPIN: props.userData.providerPIN,
            }
        };

        this.onChange = this.onChange.bind(this);
        this.dropDownContract = this.dropDownContract.bind(this);
        this.onUnderUDAManagement = this.onUnderUDAManagement.bind(this);
    }

    componentDidMount() {
        if (!Boolean(this.props.currentState)) {

            if (this.props.contractsLoaded) {
                this.setState({contracts: [...this.props.contracts], contractsLoaded: true})
            }
            this.props.getAllNHSContracts();
        } else {
            this.setState({contracts: [...this.props.contracts], contractsLoaded: true})
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_PREFERENCES_NHS.GET.receive:

                    this.setState({contracts: [...this.props.contracts], contractsLoaded: true})
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        switch (event.owner) {
            case 'details.performerNumber' :
                if (event.value.length > 6) return;
                break;
            case 'details.providerPIN' :
                if (event.value.length > 6) return;
                break;
            default :
                break;
        }

        const state = {...this.state};

        _.set(state, event.owner, event.value);
        _.set(state, 'canSave.status', true);

        this.setState(state, () => {
            this.props.onUserChange(true);
            this.props.saveNHSRegDetails(this.state.details);
        });
    }

    dropDownContract() {

        let value = null;

        if (this.state.details.contractId !== null) {
            value = _.find(this.props.contracts, (contract) => contract.id === this.state.details.contractId);
        } else
            value = null;

        const className = value === '' ? 'p-invalid' : '';

        return (
            <Dropdown className={className}
                      value={value}
                      options={this.props.contracts}
                      optionLabel={'description'}
                      disabled={!this.state.details.underUDAManagement}
                      onChange={(e) => {
                          this.onChange({owner: `details.contractId`, value: e.value.id});
                      }}
                      autoWidth={false}
            />
        )
    }

    onUnderUDAManagement(e) {

        this.onChange({owner: 'details.underUDAManagement', value: e.checked})
    }

    render() {

        if (!this.state.contractsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const props = {
            onChange: this.onChange,
            contracts: this.state.contracts,
            target: 'details',
            details: this.state.details,
        };

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{UDA_REGISTRATION_DETAILS}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Checkbox tooltipOptions={{position: 'top'}}
                          tooltip={TB_UNDER_UDA_MANAGEMENT.text}
                          onChange={this.onUnderUDAManagement}
                          checked={this.state.details.underUDAManagement}/>
                <label>{`${TB_UNDER_UDA_MANAGEMENT.text}`}</label>
            </div>
        </div>;

        const disabled = !this.state.details.underUDAManagement;

        return (
            <Panel headerTemplate={header} id='udaRegistration'>
                <div className='p-grid p-fluid form-group'>
                    <div className='p-col-2'>
                        <label htmlFor='contract'>Contract</label>
                    </div>
                    <div className='p-col-10'>
                        {this.dropDownContract(props, 'contracts', 'contractId', 'description', disabled, !disabled)}
                    </div>

                    <div className='p-col-2'>
                        <label htmlFor='ppNumber'>Performer Number</label>
                    </div>
                    <div className='p-col-10'>
                        {inputText(props, 'performerNumber', 'Number', disabled, !disabled)}
                    </div>

                    <div className='p-col-2'>
                        <label htmlFor='ppPIN'>Performer PIN</label>
                    </div>
                    <div className='p-col-10'>
                        {password(props, 'providerPIN', false, disabled, disabled)}
                    </div>
                </div>
            </Panel>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        userLoaded, userDataId,
    } = getUserIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        contractsLoaded: state.preferences.contractsLoaded,
        contracts: state.preferences.contractsLoaded ? state.preferences.contracts : [],

        userLoaded,
        userDataId,
        userData: state.users[userDataId],

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllNHSContracts: () => dispatch(getResource(RES_PREFERENCES_NHS.GET, {})),
        saveNHSRegDetails: (details) => dispatch(updateUserNHSDetails(details)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const UDAManagement = connect(mapStateToProps, mapDispatchToProps)(ConnectedUDAManagement);

export default UDAManagement;