import React, {Component} from 'react';
import {
    DirClosed,
    DirLeft,
    DirRight,
    Left,
    MouthBaseBGColor,
    MouthBGColor,
    SelectedColor,
    TOOTH_HEIGHT,
    TOOTH_SIZE
} from "./Model/Constants";
import {addPlaceHolderExtras, getTranslate} from "./Parts/ChartingUtils";
import _ from "lodash";

export class PlaceHolder extends Component {

    constructor(props) {
        super();
        this.state = {
            cursor: '',
            currentCommand: null,
        };
        this.translate = '';
        this.labelTranslate = '';

        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
        this.showAttibutes = this.showAttibutes.bind(this);
    };

    handleEnter() {

        if (this.props.data.currentCommand) {
            if (this.props.data.currentCommand.canExecute(this)) {
                this.setState({cursor: 'url(/src/images/arrowPlus16.gif) 1 1, pointer'})
            }
        }
    }

    handleLeave() {
        this.setState({cursor: ''})
    }

    showAttibutes() {

        let left = _.includes(Left, this.props.data.pos.offset);

        switch (this.props.data.drifted) {
            case DirClosed:

                this.driftedTranslate = getTranslate(this.props.data, 10, 68, 10, 67);

                return (
                    <img alt='' x="0" y="0" width="30" height="18" href="/assets/images/closedGap.png"
                         transform={this.driftedTranslate}
                    />
                );
            case DirLeft:

                this.driftedTranslate = getTranslate(this.props.data, 16, 86, 16, 43);

                if (left)
                    return (
                        <img alt='' x="0" y="0" width="18" height="18" href="/src/images/driftedLeft16.png"
                             transform={this.driftedTranslate}
                        />
                    );
                else
                    return (
                        <img alt='' x="0" y="0" width="18" height="18" href="/src/images/driftedRight16.png"
                             transform={this.driftedTranslate}
                        />
                    );
            case DirRight :

                this.driftedTranslate = getTranslate(this.props.data, 16, 86, 16, 43);

                if (left)
                    return (
                        <img alt='' x="0" y="0" width="18" height="18" href="/src/images/driftedRight16.png"
                             transform={this.driftedTranslate}
                        />
                    );
                else
                    return (
                        <img alt='' x="0" y="0" width="18" height="18" href="/src/images/driftedLeft16.png"
                             transform={this.driftedTranslate}
                        />
                    );
            default:
                return null;
        }
    }

    render() {

        const data = this.props.data;

        this.translate = getTranslate(data, 0, 0, 0, 0);
        const bgColour = data.selected ? SelectedColor : data.baseView ? MouthBaseBGColor : MouthBGColor;

        return (
            <g onContextMenu={(event) => {

                event.preventDefault();

                if (this.props.data.currentCommand) {
                    this.props.data.currentCommand.setTarget(this.props.data);
                }
                this.props.mouth.setCurrentItem(this.props.data);
                this.props.cm(event);
            }}
               onMouseEnter={(event) => this.handleEnter()}
               onMouseLeave={(event) => this.handleLeave()}

               style={{cursor: this.state.cursor}}

               onClick={(event) => {
                   if (this.props.data.currentCommand != null && this.props.data.currentCommand.canExecute(this))
                       this.props.onClick(this.props.data);
               }}
            >
                <rect
                    x={0}
                    y={0}
                    width={TOOTH_SIZE}
                    height={TOOTH_HEIGHT}
                    style={{fill: bgColour, strokeWidth: 1, stroke: 'white', opacity: 1}}
                    transform={this.translate}
                />
                {this.showAttibutes()}
                {addPlaceHolderExtras(this.props.data)}
            </g>
        )
    }
}
