import React, {Component} from 'react';
import {
    TAB_MedicalHistoryUpdate,
    TAB_PatientConsentForms,
    TAB_PatientDetailsUpdate,
    TAB_Questionnaire,
    tabletLayoutClassName
} from "../Constants";

import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {connect} from "react-redux";
import {
    SM_TAB_PATIENT_CONSENT,
    SM_TAB_PATIENT_DETAILS,
    SM_TAB_PATIENT_MED_CON,
    SM_TAB_PATIENT_QUESTIONNAIRE,
    SM_TABLET_LOGGED_IN
} from "../../../actions/stateManagement";
import {getResource} from "../../../actions/personal";
import {RES_TABLET_LISTS} from "../../../actions/tablet";
import * as Actions from "../../../actions";
import {ProgressBar} from "primereact/progressbar";

class ConnectedTabReturnViaKeypad extends Component {

    constructor(props) {

        super(props);

        this.state = {
            showLock: false,
            from: props.match.params.from,
            patientId: props.match.params.patientId,
            keyCombination: '',
            possibleOtherFormsLoaded: false,
        }
    }

    componentDidMount() {

        this.props.getPossibleOtherForms({patientId: this.state.patientId});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_TAB_POSSIBLES:

                    if (this.props.possibleOtherForms.length === 0) {
                        this.setState({possibleOtherFormsLoaded: true, showLock: true})
                    } else {

                        const tabletMember = this.props.possibleOtherForms[0];

                        let route = '';

                        switch (tabletMember.type) {
                            case TAB_PatientDetailsUpdate:
                                route = SM_TAB_PATIENT_DETAILS.route;
                                break;
                            case TAB_MedicalHistoryUpdate:
                                route = SM_TAB_PATIENT_MED_CON.route
                                break;
                            case TAB_PatientConsentForms:
                                route = SM_TAB_PATIENT_CONSENT.route
                                break;
                            case TAB_Questionnaire:
                                route = SM_TAB_PATIENT_QUESTIONNAIRE.route
                                break;
                            default:
                                break;
                        }
                        window.location.hash = `${route}/${tabletMember.patientId}/${tabletMember.id}`;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onClick = (number) => {

        this.setState({keyCombination: `${this.state.keyCombination}${number}`});
    }

    onClick2 = (enter) => {

        if (enter) {
            if (this.state.keyCombination === this.props.keyCombination) {
                window.location.hash = SM_TABLET_LOGGED_IN.route;
            } else {
                this.setState({keyCombination: ``});
            }
        } else {
            this.setState({keyCombination: ``});
        }
    }

    render() {

        if (!this.state.possibleOtherFormsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = `Please Return Tablet to a Member of Reception Staff`;

        const canEnter = this.state.keyCombination.length === this.props.keyCombination.length;
        const enterClassName = canEnter ? "p-button-success" : null;

        return (

            <div className={tabletLayoutClassName} id='mainPCC'>

                <div>
                    <div className="p-grid p-fluid">
                        <span className="logo"><i className='fa mainLogoImg'/></span>

                        <div className="p-col-12"
                             style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
                            <div/>
                            <Panel header={header}
                                   style={{textAlign: 'center'}}
                            >
                                <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
                                    <Button label={`1`} style={{fontSize: 40}} onClick={() => this.onClick(1)}/>
                                    <Button label={`2`} style={{fontSize: 40}} onClick={() => this.onClick(2)}/>
                                    <Button label={`3`} style={{fontSize: 40}} onClick={() => this.onClick(3)}/>
                                </div>
                                <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
                                    <Button label={`4`} style={{fontSize: 40}} onClick={() => this.onClick(4)}/>
                                    <Button label={`5`} style={{fontSize: 40}} onClick={() => this.onClick(5)}/>
                                    <Button label={`6`} style={{fontSize: 40}} onClick={() => this.onClick(6)}/>
                                </div>
                                <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
                                    <Button label={`7`} style={{fontSize: 40}} onClick={() => this.onClick(7)}/>
                                    <Button label={`8`} style={{fontSize: 40}} onClick={() => this.onClick(8)}/>
                                    <Button label={`9`} style={{fontSize: 40}} onClick={() => this.onClick(9)}/>
                                </div>
                                <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-around'}}>
                                    <Button label={`0`} style={{fontSize: 40}} onClick={() => this.onClick(0)}/>
                                </div>
                                <line/>
                                <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-around'}}>
                                    <Button label={`Enter`} style={{fontSize: 40}} className={enterClassName}
                                            onClick={() => this.onClick2(true)} disabled={!canEnter}/>
                                    <Button label={`Clear`} style={{fontSize: 40}}
                                            onClick={() => this.onClick2(false)}/>
                                </div>
                            </Panel>
                            <div/>
                        </div>
                    </div>

                    <div className="layout-mask"/>

                    {/*<AppFooter/>*/}
                    <span className="bg-shape bg-shape-2"/>
                    <span className="bg-shape bg-shape-1"/>
                    <span className="bg-shape bg-shape-3"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        keyCombination: `1239`,
        possibleOtherFormsLoaded: state.tablet.possibleOtherFormsLoaded,
        possibleOtherForms: state.tablet.possibleOtherForms,

    }
};

const MapDispatchToProps = dispatch => {
    return {
        getPossibleOtherForms: (params) => dispatch(getResource(RES_TABLET_LISTS.POSSIBLES, params)),
    }
}

const TabReturnViaKeypad = connect(mapStateToProps, MapDispatchToProps)(ConnectedTabReturnViaKeypad);

export default TabReturnViaKeypad;
