import {tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {
    HM_TABLET_REGISTRATIONS_DOWNLOAD,
    HM_TABLET_REGISTRATIONS_PRINT,
    HM_TABLET_REGISTRATIONS_SHOW,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import React from "react";
import _ from "lodash";
import * as DefaultData from "../DefaultData";
import {ac} from "../../../index";
import {SM_TAB_PATIENT_CONSENT} from "../../../actions/stateManagement";

export const tabletRegistrationsToolBar = (callbacks) => {

    let key = Math.floor(Math.random() * 1000);

    return [
        tb_boilerPlateRight2(callbacks[HM_TABLET_REGISTRATIONS_SHOW.id], HM_TABLET_REGISTRATIONS_SHOW.icon, HM_TABLET_REGISTRATIONS_SHOW.label, key++, false),
        tb_boilerPlateRight2(callbacks[HM_TABLET_REGISTRATIONS_PRINT.id], HM_TABLET_REGISTRATIONS_PRINT.icon, HM_TABLET_REGISTRATIONS_PRINT.label, key++, false),
        tb_boilerPlateRight(callbacks[HM_TABLET_REGISTRATIONS_DOWNLOAD.id], HM_TABLET_REGISTRATIONS_DOWNLOAD.icon, HM_TABLET_REGISTRATIONS_DOWNLOAD.label, key++, true),
        tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++),
    ];
};

export const nhsTemplate = (rowData) => {
    return rowData['nhsPatient'] ? <i className='fa nhsLogo16'/> : null;
}

export const onAddConsentsToTablet = (props, patientId, consents) => {

    const tabletMember = _.cloneDeep(DefaultData.TabletMember(ac.getMcId()));

    tabletMember.member = {id: patientId};
    tabletMember.addedBy = {id: props.loginIdentity.id};
    tabletMember.type = SM_TAB_PATIENT_CONSENT.type;

    consents.forEach(consent => {
        if (consent.selected) {
            tabletMember.consentFormIds.push(consent.id);
        }
    });
    this.props.addToLists(tabletMember);
}
