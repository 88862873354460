import _ from "lodash";
import {ac} from "../../index";
import {
    RES_PREFERENCES,
    RES_PREFERENCES_DCS,
    RES_PREFERENCES_HOURS, RES_PREFERENCES_LOCATIONS,
    RES_PREFERENCES_NHS,
    RES_PREFERENCES_PDS, RES_PREFERENCES_RAR,
    RES_PREFERENCES_REPORTS
} from "../preferences";
import {
    SM_PREFERENCES_ACS,
    SM_PREFERENCES_CRT,
    SM_PREFERENCES_DCS,
    SM_PREFERENCES_DIS,
    SM_PREFERENCES_EMAIL,
    SM_PREFERENCES_ESENDEX,
    SM_PREFERENCES_HOURS, SM_PREFERENCES_LOCATIONS,
    SM_PREFERENCES_NHS,
    SM_PREFERENCES_PDS, SM_PREFERENCES_RAR,
    SM_PREFERENCES_REPORTS
} from "../stateManagement";
import {RES_OBJECT_STORE} from "../objectStore";

export const savePreferences = (dispatch, sm) => {

    const practiceDetails = sm[SM_PREFERENCES_PDS.id] === undefined ? null : sm[SM_PREFERENCES_PDS.id].data.practiceDetails;
    const recallsAndReminders = sm[SM_PREFERENCES_RAR.id] === undefined ? null : sm[SM_PREFERENCES_RAR.id].data.practiceDetails;

    if (practiceDetails !== null || recallsAndReminders !== null) {

        let _practiceDetails = null;

        if (practiceDetails && recallsAndReminders) {
            _practiceDetails = {
                ...practiceDetails,
                confirmationByEmail: recallsAndReminders.confirmationByEmail,
                confirmationBySMS: recallsAndReminders.confirmationBySMS,
                confirmationDeleteEmail: recallsAndReminders.confirmationDeleteEmail,
                confirmationDeleteSMS: recallsAndReminders.confirmationDeleteSMS,
                confirmationRescheduleEmail: recallsAndReminders.confirmationRescheduleEmail,
                confirmationRescheduleSMS: recallsAndReminders.confirmationRescheduleSMS,
                lateArriverAdjustment: recallsAndReminders.lateArriverAdjustment,
                recallFollowupPeriod: recallsAndReminders.recallFollowupPeriod,
                chargeableItemRecallPeriod: recallsAndReminders.chargeableItemRecallPeriod,
            }
        } else if (practiceDetails) {
            _practiceDetails = {...practiceDetails}
        } else {
            _practiceDetails = {...recallsAndReminders}
        }
        // as the practice details URL is the same for these preference saves we just use the PDS url
        ac.fetchClient().post(`${RES_PREFERENCES_PDS.SAVE.url}?mcId=${ac.getMcId()}`, _practiceDetails)
            .then(res => {

                dispatch({type: RES_PREFERENCES_PDS.SAVE.action, payload: res.data});
                dispatch({type: RES_PREFERENCES_RAR.SAVE.action, payload: res.data});
                dispatch({type: RES_PREFERENCES.SAVE.action, payload: null}) // needed to clear save icon and button
            });

    }

    const practiceEmail = sm[SM_PREFERENCES_EMAIL.id] === undefined ? null : sm[SM_PREFERENCES_EMAIL.id].data.practiceDetails;

    if (practiceEmail !== null) {
        // as the practice details URL is the same for these preference saves we just use the PDS url
        ac.fetchClient().post(`${RES_PREFERENCES_PDS.SAVE.url}?mcId=${ac.getMcId()}`, practiceEmail)
            .then(res => {

                dispatch({type: RES_PREFERENCES_PDS.SAVE.action, payload: res.data});
                dispatch({type: RES_PREFERENCES.SAVE.action, payload: null}) // needed to clear save icon and button
            });

    }

    const practiceHours = sm[SM_PREFERENCES_HOURS.id] === undefined ? null : sm[SM_PREFERENCES_HOURS.id].data.practiceDetails;

    if (practiceHours !== null) {
        // as the practice details URL is the same for these preference saves we just use the PDS url
        ac.fetchClient().post(`${RES_PREFERENCES_HOURS.SAVE.url}?mcId=${ac.getMcId()}`, practiceHours)
            .then(res => {

                dispatch({type: RES_PREFERENCES_HOURS.SAVE.action, payload: res.data});
                dispatch({type: RES_PREFERENCES.SAVE.action, payload: null}) // needed to clear save icon and button
            });

    }

    const saveData = sm[SM_PREFERENCES_DCS.id] === undefined ? null : sm[SM_PREFERENCES_DCS.id].data;

    if (saveData !== null) {
        saveData.usersShort.forEach(user => {

            if (Boolean(user.edited) && !Boolean(user.special)) {

                ac.fetchClient().post(`${RES_PREFERENCES_DCS.SAVE.url}?mcId=${ac.getMcId()}`, user)
                    .then(res => {

                        dispatch({type: RES_PREFERENCES_DCS.SAVE.action, payload: res.data});
                        dispatch({type: RES_PREFERENCES.SAVE.action, payload: null}) // needed to clear save icon and button
                    });
            }
        });
    }

    const contracts = sm[SM_PREFERENCES_NHS.id] === undefined ? null : sm[SM_PREFERENCES_NHS.id].data.contracts;

    if (contracts !== null) {
        const updatedContracts = [];

        contracts.forEach(contract => {

            if (Boolean(contract.edited)) {
                updatedContracts.push(contract);
            }
        });

        ac.fetchClient().post(`${RES_PREFERENCES_NHS.SAVE.url}?mcId=${ac.getMcId()}`, updatedContracts)
            .then(res => {

                dispatch({type: RES_PREFERENCES_NHS.SAVE.action, payload: res.data});
                dispatch({type: RES_PREFERENCES.SAVE.action, payload: null}) // needed to clear save icon and button
            });
    }

    const locations = sm[SM_PREFERENCES_LOCATIONS.id] === undefined ? null : sm[SM_PREFERENCES_LOCATIONS.id].data.locations;

    if (locations !== null) {
        const updatedLocations = [];

        locations.forEach(location => {

            if (Boolean(location.edited) || location.id<0) {

                const editedLocation = {...location};

                editedLocation.id = editedLocation.id <0 ? null : editedLocation.id;
                editedLocation.status = editedLocation.status.name;
                editedLocation.type = editedLocation.type.name;

                updatedLocations.push(editedLocation);
            }
        });

        ac.fetchClient().post(`${RES_PREFERENCES_LOCATIONS.SAVE.url}?mcId=${ac.getMcId()}`, updatedLocations)
            .then(res => {

                dispatch({type: RES_PREFERENCES_LOCATIONS.SAVE.action, payload: res.data});
                dispatch({type: RES_PREFERENCES.SAVE.action, payload: null}) // needed to clear save icon and button
            });
    }

    const reportTexts = sm[SM_PREFERENCES_REPORTS.id] === undefined ? null : sm[SM_PREFERENCES_REPORTS.id].data.reportTexts;

    if (reportTexts !== null) {

        const edits = _.filter(reportTexts, reportText => reportText.edited);
        let count = 1;

        edits.forEach(edit => {

            let _edit = {...edit};
            // _edit.createdBy = {id: edit.createdBy.id};
            _edit.type = edit.type.ordinal;

            // as the practice details URL is the same for these preference saves we just use the PDS url
            ac.fetchClient().post(`${RES_PREFERENCES_REPORTS.SAVE.url}?mcId=${ac.getMcId()}`, _edit)
                .then(res => {
                    if (count === edits.length) {

                        dispatch({type: RES_PREFERENCES_REPORTS.SAVE.action, payload: res.data});
                        dispatch({type: RES_PREFERENCES.SAVE.action, payload: null}) // needed to clear save icon and button
                    } else {
                        count++;
                    }
                });
        });
    }

};

export const savePreferenceObjects = (dispatch, sm) => {

    const acs = sm[SM_PREFERENCES_ACS.id] === undefined ? null : sm[SM_PREFERENCES_ACS.id].data.objects;

    if (acs !== null) {

        ac.fetchClient().post(`${RES_OBJECT_STORE.SET.url}?mcId=${ac.getMcId()}`, acs)
            .then(() => {

                dispatch({type: RES_PREFERENCES.SAVE.action, payload: acs, saveType: SM_PREFERENCES_ACS.id}) // needed to clear save icon and button
            });
    }

    const crt = sm[SM_PREFERENCES_CRT.id] === undefined ? null : sm[SM_PREFERENCES_CRT.id].data.objects;

    if (crt !== null) {

        ac.fetchClient().post(`${RES_OBJECT_STORE.SET.url}?mcId=${ac.getMcId()}`, crt)
            .then(() => {

                dispatch({type: RES_PREFERENCES.SAVE.action, payload: crt, saveType: SM_PREFERENCES_CRT.id}) // needed to clear save icon and button
            });
    }

    const dcs = sm[SM_PREFERENCES_DCS.id] === undefined ? null : sm[SM_PREFERENCES_DCS.id].data.objects;

    if (dcs !== null) {
        const processedData = {};

        for (let key in dcs) {
            const data = dcs[key];
            processedData[data.value] = `${data.red}:${data.green}:${data.blue}`;
        }
        ac.fetchClient().post(`${RES_OBJECT_STORE.SET.url}?mcId=${ac.getMcId()}`, processedData)
            .then(() => {

                dispatch({type: RES_PREFERENCES.SAVE.action, payload: dcs, saveType: SM_PREFERENCES_DCS.id}) // needed to clear save icon and button
            });
    }

    const ds = sm[SM_PREFERENCES_DIS.id] === undefined ? null : sm[SM_PREFERENCES_DIS.id].data.objects;

    if (ds !== null) {

        ac.fetchClient().post(`${RES_OBJECT_STORE.SET.url}?mcId=${ac.getMcId()}`, ds)
            .then(() => {

                dispatch({type: RES_PREFERENCES.SAVE.action, payload: ds, saveType: SM_PREFERENCES_DIS.id}) // needed to clear save icon and button
            });
    }

    const esendex = sm[SM_PREFERENCES_ESENDEX.id] === undefined ? null : sm[SM_PREFERENCES_ESENDEX.id].data.objects;

    if (esendex !== null) {

        ac.fetchClient().post(`${RES_OBJECT_STORE.SET.url}?mcId=${ac.getMcId()}`, esendex)
            .then(() => {

                dispatch({type: RES_PREFERENCES.SAVE.action, payload: esendex, saveType: SM_PREFERENCES_ESENDEX.id}) // needed to clear save icon and button
            });
    }

    const keap = sm[SM_PREFERENCES_PDS.id] === undefined ? null : sm[SM_PREFERENCES_PDS.id].data.objects;

    if (keap !== null) {

        ac.fetchClient().post(`${RES_OBJECT_STORE.SET.url}?mcId=${ac.getMcId()}`, keap)
            .then(() => {

                dispatch({type: RES_PREFERENCES.SAVE.action, payload: keap, saveType: SM_PREFERENCES_PDS.id}) // needed to clear save icon and button
            });
    }
}