import React from 'react';
import moment from 'moment';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {connect} from "react-redux";
import {
    setState,
    SM_NHS_MANAGEMENT,
    SM_NHS_MANAGEMENT_REMOVED,
    stateRequest
} from "../../../../actions/stateManagement";
import {getNHSManagementIds} from "../Utils";
import {
    getResource,
    RES_getProviderPerformers,
    RES_NHS_MANAGEMENT_CLAIM,
    RES_NHS_MANAGEMENT_REMOVED,
    RES_NHS_MANAGEMENT_STACKED
} from "../../../../actions/nhsManagement";
import * as Actions from "../../../../actions";
import {
    claimReferenceTemplate,
    EDI_REMOVED,
    EDI_REMOVED_TITLE,
    EDI_ST_Removed,
    formTemplate,
    nameTemplate,
    ppPerformerNumberTemplate
} from "../Constants";

import ClaimsComponent from "../ClaimsComponent";
import {JSON_DATE_FORMAT} from "../../../../Constants";
import {dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";
import _ from "lodash";

export class ConnectedRemovedSection extends ClaimsComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                type: EDI_REMOVED,

                stateManagementId: SM_NHS_MANAGEMENT_REMOVED.id,

                selectedUser: {id: null},
                firstClinicians: 0,
                rowsClinicians: 5,

                claims: [],
                selectedClaim: null,

                firstClaims: 0,
                rowsClaims: 5,

                pinValid: false,
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.claimDate !== this.props.claimDate) {
            this.setState({claims: []}, () => {
                const date = new moment(this.props.claimDate);
                this.props.getClaims(this.state.selectedUser.id, date.format(JSON_DATE_FORMAT));
            });
        }

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:
                    break;
                case RES_NHS_MANAGEMENT_STACKED.GET.request:
                    this.setState({claims: [], claimsLoaded: false, claimedTotal: 0});
                    break;
                case RES_NHS_MANAGEMENT_CLAIM.SAVE.action:
                    this.onUserReload();
                    break;
                case RES_NHS_MANAGEMENT_REMOVED.GET.receive:
                case RES_NHS_MANAGEMENT_CLAIM.DELETE.action:
                    this.setState({claims: this.props.claims}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.props.providerPerformersLoaded || (this.state.selectedUser.id !== null && !this.props.claimsLoaded)) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const sortedClaims = _.sortBy(this.state.claims, ['id'], ['asc']);

        return (
            <div>
                <Panel header='Performers' style={{marginBottom: '5px'}}>

                    <ContextMenu style={{width: 250}} model={this.buildUserContextMenu()}
                                 ref={el => this.usersCm = el}/>

                    <DataTable value={this.sortProviderPerformers()}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsClinicians}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstClinicians', 'rowsClinicians')}
                               first={this.state.firstClinicians}
                               selection={this.state.selectedUser}
                               onSelectionChange={this.onUserSelectionChange}
                               contextMenuSelection={this.state.selectedUser}
                               onContextMenuSelectionChange={this.onUserSelectionChange}
                               onContextMenu={e => this.usersCm.show(e.originalEvent)}
                    >

                        <Column header="Name"
                                body={nameTemplate}
                                style={{width: '70%'}}/>
                        <Column header="Performer Number"
                                body={ppPerformerNumberTemplate}
                                style={{textAlign: 'right', width: '30%'}}/>
                    </DataTable>
                </Panel>

                <Panel header={EDI_REMOVED_TITLE}>

                    <ContextMenu style={{width: 250}} model={this.buildClaimContextMenu(EDI_ST_Removed)}
                                 ref={el => this.cm = el}/>

                    {this.showDialogs()}

                    <DataTable value={sortedClaims}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsClaims}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstClaims', 'rowsClaims')}
                               first={this.state.firstClaims}
                               selection={this.state.selectedClaim}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedClaim}
                               onContextMenuSelectionChange={this.onSelection}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent);
                               }}
                    >

                        <Column body={(row) => dateTemplateShort(row.removalDate)}
                                header="Removed On"
                                style={{width: '10%'}}/>

                        <Column field='count'
                                header="Count"
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column body={(row) => claimReferenceTemplate(row)}
                                header="Claim"
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column body={(row) => formTemplate(row)}
                                header="Form"
                                style={{width: '20%'}}/>

                        <Column field='removalNarrative'
                                header="Removal Narrative"
                                style={{width: '30%'}}/>

                        <Column field="units"
                                header="Units"
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column field="status"
                                header="Status"
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column body={(row) => nameTemplate(row)}
                                header="Patient"
                                style={{width: '10%'}}/>

                        <Column field="narrative"
                                header="Comment"
                                style={{width: '10%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        providerPerformersLoaded,
        providerPerformers,

        removedLoaded,
        removed,
    } = getNHSManagementIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        providerPerformersLoaded,
        providerPerformers,

        claimsLoaded: removedLoaded,
        claims: removed,

        currentState: state.stateManagement[SM_NHS_MANAGEMENT_REMOVED.id],
    };
};

const MapDispatchToProps = (dispatch, ownProps) => {

    return {
        getProviderPerformers: () => dispatch(getResource(RES_getProviderPerformers.GET)),
        getClaims: (ppId, claimDate) => dispatch(getResource(RES_NHS_MANAGEMENT_REMOVED.GET, {ppId, claimDate})),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data, SM_NHS_MANAGEMENT.id)),
    }
};

const RemovedSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedRemovedSection);

export default RemovedSection;

