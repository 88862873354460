import * as Actions from "./index";
import {ac} from "../index";

const baseURL = '/Housekeeping';
const baseServiceURL = '/api/v1/reports';

export const RES_HOUSEKEEPING = {
    CLEAR: {action: Actions.CLEAR_HOUSEKEEPING},
    SAVE: {action: Actions.SAVE_HOUSEKEEPING},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_HOUSEKEEPING},
};

export const RES_HOUSEKEEPING_TITLES = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_TITLES,
        receive: Actions.RECEIVE_HOUSEKEEPING_TITLES,
        url: `${baseURL}/titles`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_TITLES, url: `${baseURL}/saveTitle`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_TITLES, url: `${baseURL}/deleteTitle`},
};

export const RES_HOUSEKEEPING_TCODES = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_TCODES,
        receive: Actions.RECEIVE_HOUSEKEEPING_TCODES,
        url: `${baseURL}/treatmentCodes`
    },
    GET_USEABLE: {
        request: Actions.REQUEST_HOUSEKEEPING_TCODES,
        receive: Actions.RECEIVE_HOUSEKEEPING_TCODES,
        url: `${baseURL}/useableTreatmentCodes`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_TCODES, url: `${baseURL}/saveTreatmentCode`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_TCODES, url: `${baseURL}/deleteTreatmentCode`},
};

export const RES_HOUSEKEEPING_CHART_ITEMS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_CHART_ITEMS,
        receive: Actions.RECEIVE_HOUSEKEEPING_CHART_ITEMS,
        url: `${baseURL}/chartingItems`
    },
};

export const RES_HOUSEKEEPING_CCS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_CCS,
        receive: Actions.RECEIVE_HOUSEKEEPING_CCS,
        url: `${baseURL}/costCenters`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_CCS, url: `${baseURL}/saveCostCenter`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_CCS, url: `${baseURL}/deleteCostCenter`},
};

export const RES_HOUSEKEEPING_JSTAGES = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_JSTAGES,
        receive: Actions.RECEIVE_HOUSEKEEPING_JSTAGES,
        url: `${baseURL}/patientJourneys`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_JSTAGES, url: `${baseURL}/savePatientJourney`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_JSTAGES, url: `${baseURL}/deletePatientJourney`},
    INFORM_KEAP: {action: Actions.JSTAGES_INFORM_KEAP, url: `${baseURL}/informKeap`},
};

export const RES_HOUSEKEEPING_DOC_CLASSIFICATIONS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_DOC_CLASSIFICATION,
        receive: Actions.RECEIVE_HOUSEKEEPING_DOC_CLASSIFICATION,
        url: `${baseURL}/documentClassifications`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_DOC_CLASSIFICATION, url: `${baseURL}/saveDocumentClassifications`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_DOC_CLASSIFICATION, url: `${baseURL}/deleteDocumentClassifications`},
};

export const RES_HOUSEKEEPING_MEDICATIONS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_MEDICATIONS,
        receive: Actions.RECEIVE_HOUSEKEEPING_MEDICATIONS,
        url: `${baseURL}/medications`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_MEDICATIONS, url: `${baseURL}/saveMedications`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_MEDICATIONS, url: `${baseURL}/deleteMedication`},
};

export const RES_HOUSEKEEPING_FAVS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_FAVS,
        receive: Actions.RECEIVE_HOUSEKEEPING_FAVS,
        url: `${baseURL}/treatmentFavs`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_FAVS, url: `${baseURL}/saveTreatmentFav`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_FAVS, url: `${baseURL}/deleteTreatmentFav`},
};

export const RES_HOUSEKEEPING_BASE_CHART_FAVS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_BASE_CHART_FAVS,
        receive: Actions.RECEIVE_HOUSEKEEPING_BASE_CHART_FAVS,
        url: `${baseURL}/baseChartFavs`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_BASE_CHART_FAVS, url: `${baseURL}/saveBaseChartFav`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_BASE_CHART_FAVS, url: `${baseURL}/deleteBaseChartFav`},
};

export const RES_HOUSEKEEPING_CSCHED = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_CSCHED,
        receive: Actions.RECEIVE_HOUSEKEEPING_CSCHED,
        url: `${baseURL}/schedules`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_CSCHED, url: `${baseURL}/saveSchedule`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_CSCHED, url: `${baseURL}/deleteSchedule`},
    REMOVE: {action: Actions.REMOVE_HOUSEKEEPING_CSCHED, url: `${baseURL}/removeSchedules`},
    ADDHOC: {action: Actions.REMOVE_HOUSEKEEPING_CSCHED, url: `${baseURL}/adHoc`},
};

export const RES_HOUSEKEEPING_ZONES = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_ZONES,
        receive: Actions.RECEIVE_HOUSEKEEPING_ZONES,
        url: `${baseURL}/zones`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_ZONES, url: `${baseURL}/saveZones`},
};

export const RES_HOUSEKEEPING_ADETS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_ADETS,
        receive: Actions.RECEIVE_HOUSEKEEPING_ADETS,
        url: `${baseURL}/appointmentDiaryTypes`
    },
    AT_SAVE: {action: Actions.SAVE_HOUSEKEEPING_AT_ADETS, url: `${baseURL}/saveAppointmentDiaryType`},
    DE_SAVE: {action: Actions.SAVE_HOUSEKEEPING_DE_ADETS, url: `${baseURL}/saveDiaryEventType`},
    AT_DELETE: {action: Actions.DELETE_HOUSEKEEPING_AT_ADETS, url: `${baseURL}/deleteAppointmentDiaryType`},
    DE_DELETE: {action: Actions.DELETE_HOUSEKEEPING_DE_ADETS, url: `${baseURL}/deleteDiaryEventType`},
};

export const RES_HOUSEKEEPING_RECTYPES = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_RECTYPES,
        receive: Actions.RECEIVE_HOUSEKEEPING_RECTYPES,
        url: `${baseURL}/recallTypes`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_RECTYPES, url: `${baseURL}/saveRecallType`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_RECTYPES, url: `${baseURL}/deleteRecallType`},
};

export const RES_HOUSEKEEPING_MCS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_MCS,
        receive: Actions.RECEIVE_HOUSEKEEPING_MCS,
        url: `${baseURL}/medicalConditions`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_MCS, url: `${baseURL}/saveMedicalConditions`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_MCS, url: `${baseURL}/deleteMedicalConditions`},
};

export const RES_HOUSEKEEPING_QUES = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_QUES,
        receive: Actions.RECEIVE_HOUSEKEEPING_QUES,
        url: `${baseURL}/questions`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_QUES, url: `${baseURL}/saveQuestions`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_QUES, url: `${baseURL}/deleteQuestions`},
};

export const RES_HOUSEKEEPING_CRS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_CRS,
        receive: Actions.RECEIVE_HOUSEKEEPING_CRS,
        url: `${baseURL}/cancellationReasonsAndCharges`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_CRS, url: `${baseURL}/saveCancellationReasons`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_CRS, url: `${baseURL}/deleteCancellationReason`},
    SAVE_CCS: {action: Actions.SAVE_HOUSEKEEPING_CCC, url: `${baseURL}/saveCancellationCharges`},
};

export const RES_HOUSEKEEPING_OCCS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_OCCS,
        receive: Actions.RECEIVE_HOUSEKEEPING_OCCS,
        url: `${baseURL}/occupations`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_OCCS, url: `${baseURL}/saveOccupations`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_OCCS, url: `${baseURL}/deleteOccupations`},
};

export const RES_HOUSEKEEPING_ASSCATS = {
    GET: {
        request: Actions.REQUEST_HOUSEKEEPING_ASSCATS,
        receive: Actions.RECEIVE_HOUSEKEEPING_ASSCATS,
        url: `${baseURL}/patientAssessmentCategories`
    },
    SAVE: {action: Actions.SAVE_HOUSEKEEPING_ASSCATS, url: `${baseURL}/savePatientAssessmentCategories`},
    DELETE: {action: Actions.DELETE_HOUSEKEEPING_ASSCATS, url: `${baseURL}/deletePatientAssessmentCategories`},
};

export const RES_REPORT_TYPES = {
    GET: {request: Actions.REQUEST_REPORT_TYPES, receive: Actions.RECEIVE_REPORT_TYPES, url: `${baseURL}/reportTypes`},
    SAVE: {action: Actions.SAVE_REPORT_TYPES, url: `${baseURL}/saveReportTypes`},
    DELETE: {action: Actions.DELETE_REPORT_TYPES, url: `${baseURL}/deleteReportTypes`},
};

export const RES_TEMPLATES_SYSTEM = {
    GET: {
        request: Actions.REQUEST_TEMPLATES_SYSTEM,
        receive: Actions.RECEIVE_TEMPLATES_SYSTEM,
        url: `${baseURL}/systemTemplates`
    },
    SAVE: {action: Actions.SAVE_TEMPLATES_SYSTEM, url: `${baseURL}/saveSystemTemplate`},
    UPDATE: {action: Actions.SAVE_TEMPLATES_SYSTEM, url: `${baseURL}/updateTemplate`},
    COPY: {action: Actions.COPY_TEMPLATES_SYSTEM, url: `${baseURL}/copyTemplate`},
    DELETE: {action: Actions.DELETE_TEMPLATES_SYSTEM, url: `${baseURL}/deleteSystemTemplate`},
};

export const RES_TEMPLATES_PATIENT = {
    GET: {
        request: Actions.REQUEST_TEMPLATES_PATIENT,
        receive: Actions.RECEIVE_TEMPLATES_PATIENT,
        url: `${baseURL}/patientTemplates`
    },
    SAVE: {action: Actions.SAVE_TEMPLATES_PATIENT, url: `${baseURL}/savePatientTemplate`},
    UPDATE: {action: Actions.SAVE_TEMPLATES_PATIENT, url: `${baseURL}/updateTemplate`},
    COPY: {action: Actions.COPY_TEMPLATES_PATIENT, url: `${baseURL}/copyTemplate`},
    DELETE: {action: Actions.DELETE_TEMPLATES_PATIENT, url: `${baseURL}/deletePatientTemplate`},
};

export const RES_PATIENT_DOC_TEMPLATE = {
    GET: {
        request: Actions.REQUEST_PATIENT_DOC_TEMPLATES,
        receive: Actions.RECEIVE_PATIENT_DOC_TEMPLATES,
        url: `${baseURL}/patientsByType`
    },
};

export const RES_TEMPLATES_PRESCRIPTIONS = {
    GET: {
        request: Actions.REQUEST_TEMPLATES_PRESCRIPTIONS,
        receive: Actions.RECEIVE_TEMPLATES_PRESCRIPTIONS,
        url: `${baseURL}/patientsByType`
    },
};

export const RES_TEMPLATES_REFERRAL = {
    GET: {
        request: Actions.REQUEST_TEMPLATES_REFERRAL,
        receive: Actions.RECEIVE_TEMPLATES_REFERRAL,
        url: `${baseURL}/patientsByType`
    },
};

export const RES_TEMPLATES_APPOINTMENT_LETTER = {
    GET: {
        request: Actions.REQUEST_TEMPLATES_APPOINTMENT_LETTER,
        receive: Actions.RECEIVE_TEMPLATES_APPOINTMENT_LETTER,
        url: `${baseURL}/patientsByType`
    },
    SAVE: {action: Actions.SAVE_TEMPLATES_PATIENT, url: `${baseURL}/savePatientTemplate`},
    DELETE: {action: Actions.DELETE_TEMPLATES_PATIENT, url: `${baseURL}/deletePatientTemplate`},
};

export const RES_TEMPLATES_CONSENT_FORMS = {
    GET: {
        request: Actions.REQUEST_TEMPLATES_CONSENT_FORMS,
        receive: Actions.RECEIVE_TEMPLATES_CONSENT_FORMS,
        url: `${baseURL}/patientsByType`
    },
    SAVE: {action: Actions.SAVE_TEMPLATES_PATIENT, url: `${baseURL}/savePatientTemplate`},
    DELETE: {action: Actions.DELETE_TEMPLATES_PATIENT, url: `${baseURL}/deletePatientTemplate`},
};

export const RES_CREATE_PRESCRIPTION_DOCUMENT = {
    CREATE: {
        action: Actions.CREATE_PRESCRIPTIONS_DOCUMENT,
        url: `${baseURL}/createPrescriptionDocument`,
    },
};

export const RES_TEMPLATES_TREATMENT_PLANS = {
    GET: {
        request: Actions.REQUEST_TEMPLATES_TREATMENT_PLANS,
        receive: Actions.RECEIVE_TEMPLATES_TREATMENT_PLANS,
        url: `${baseURL}/patientsByType`
    },
    SAVE: {action: Actions.SAVE_TEMPLATES_PATIENT, url: `${baseURL}/savePatientTemplate`},
    DELETE: {action: Actions.DELETE_TEMPLATES_PATIENT, url: `${baseURL}/deletePatientTemplate`},
};

export const RES_CREATE_TP_DOCUMENT = {
    CREATE: {
        action: Actions.CREATE_TP_DOCUMENT,
        url: `${baseURL}/createTPDocument`,
    },
};

export const RES_CREATE_PP_DOCUMENT = {
    CREATE: {
        action: Actions.CREATE_PP_DOCUMENT,
        url: `${baseURL}/createPPDocument`,
    },
};

export const RES_TEMPLATE_NOTES = {
    GET: {
        request: Actions.REQUEST_TEMPLATE_NOTES,
        receive: Actions.RECEIVE_TEMPLATE_NOTES,
        url: `${baseURL}/templateNotes`
    },
    SAVE: {action: Actions.SAVE_TEMPLATE_NOTES, url: `${baseURL}/saveTemplateNote`},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_TEMPLATE_NOTES, url: `${baseURL}/saveTemplateNote`},
    CLEAR: {action: Actions.CLEAR_TEMPLATE_NOTES},
    DELETE: {action: Actions.DELETE_TEMPLATE_NOTES, url: `${baseURL}/deleteTemplateNote`},
};

export const RES_TEMPLATE_NOTES_FAVS = {
    GET: {
        request: Actions.REQUEST_TEMPLATE_NOTE_FAVS,
        receive: Actions.RECEIVE_TEMPLATE_NOTE_FAVS,
        url: `${baseURL}/templateNoteFavs`
    },
    SAVE: {action: Actions.SAVE_TEMPLATE_NOTE_FAVS, url: `${baseURL}/saveTemplateNoteFav`},
    DELETE: {action: Actions.DELETE_TEMPLATE_NOTE_FAVS, url: `${baseURL}/deleteTemplateNoteFav`},
};

export const RES_TEMPLATES_PAYMENT_PLANS = {
    GET: {
        request: Actions.REQUEST_TEMPLATES_PAYMENT_PLANS,
        receive: Actions.RECEIVE_TEMPLATES_PAYMENT_PLANS,
        url: `${baseURL}/patientsByType`
    },
    SAVE: {action: Actions.SAVE_TEMPLATES_PATIENT, url: `${baseURL}/savePatientTemplate`},
    DELETE: {action: Actions.DELETE_TEMPLATES_PATIENT, url: `${baseURL}/deletePatientTemplate`},
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {
                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false});
    }
};

export const getResource2 = ({url, request, receive}, types) => {

    const jTypes = JSON.stringify(types);
    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {jTypes}})
            .then(res => {
                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

///////////////////////////////////////////////////// SAVES /////////////////////////////////////////////////////

/// House keepings Saves ///

export const saveTitle = (title) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_TITLES.SAVE.url}?mcId=${ac.getMcId()}`, title)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_TITLES.SAVE.action, payload: res.data})
            });
    }
};

export const saveTreatmentCode = (treatmentCode) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_TCODES.SAVE.url}?mcId=${ac.getMcId()}`, treatmentCode)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_TCODES.SAVE.action, payload: res.data})
            });
    }
};

export const saveCostCenter = (costCenter) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_CCS.SAVE.url}?mcId=${ac.getMcId()}`, costCenter)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_CCS.SAVE.action, payload: res.data})
            });
    }
};

export const saveTreatmentFavourite = (treatmentFavourite) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_FAVS.SAVE.url}?mcId=${ac.getMcId()}`, treatmentFavourite)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_FAVS.SAVE.action, payload: res.data})
            });
    }
};

export const saveScheduleRule = (rule) => {

    return (dispatch) => {

        const saveRule = {...rule};

        saveRule.surgery = null;
        saveRule.user = {id: saveRule.user.id, mc: saveRule.user.mc};

        ac.fetchClient().post(`${RES_HOUSEKEEPING_CSCHED.SAVE.url}?mcId=${ac.getMcId()}`, saveRule)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_CSCHED.SAVE.action, payload: res.data})
            });
    }
};

export const saveDiaryType = (diaryType) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_ADETS.SAVE.url}?mcId=${ac.getMcId()}`, diaryType)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_ADETS.SAVE.action, payload: res.data})
            });
    }
};

export const saveRecallType = (recallType) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_RECTYPES.SAVE.url}?mcId=${ac.getMcId()}`, recallType)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_RECTYPES.SAVE.action, payload: res.data})
            });
    }
};

export const saveJStage = (jstage) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_JSTAGES.SAVE.url}?mcId=${ac.getMcId()}`, jstage)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_JSTAGES.SAVE.action, payload: res.data})
            });
    }
};

export const saveMedicalCondition = (medcon) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_MCS.SAVE.url}?mcId=${ac.getMcId()}`, medcon)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_MCS.SAVE.action, payload: res.data})
            });
    }
};

export const saveOccupation = (occupation) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_OCCS.SAVE.url}?mcId=${ac.getMcId()}`, occupation)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_OCCS.SAVE.action, payload: res.data})
            });
    }
};

export const saveAssCat = (assCat) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_ASSCATS.SAVE.url}?mcId=${ac.getMcId()}`, assCat)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_ASSCATS.SAVE.action, payload: res.data})
            });
    }
};

/// Utilities Saves ///

export const saveSystemTemplate = (newTemplate) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_TEMPLATES_SYSTEM.SAVE.url}?mcId=${ac.getMcId()}`, newTemplate)
            .then(res => {

                dispatch({type: RES_TEMPLATES_SYSTEM.SAVE.action, payload: res.data})
            });
    }
};

export const updateSystemTemplate = (updateTemplate) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_TEMPLATES_SYSTEM.UPDATE.url}?mcId=${ac.getMcId()}`, updateTemplate)
            .then(res => {
            });
    }
};

export const savePatientTemplate = (newTemplate) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_TEMPLATES_PATIENT.SAVE.url}?mcId=${ac.getMcId()}`, newTemplate)
            .then(res => {

                dispatch({type: RES_TEMPLATES_PATIENT.SAVE.action, payload: res.data})
            });
    }
};

export const updatePatientTemplate = (updateTemplate) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_TEMPLATES_PATIENT.UPDATE.url}?mcId=${ac.getMcId()}`, updateTemplate)
            .then(res => {
            });
    }
};

export const copyTemplate = (copyTemplate) => {

    return (dispatch) => {
        ac.fetchClient().get(`${RES_TEMPLATES_PATIENT.COPY.url}?mcId=${ac.getMcId()}`, {params: {...copyTemplate}})
            .then(res => {
                // return will be via WS
            });
    }
};

export const saveTemplateNote = (newTemplateNote) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_TEMPLATE_NOTES.SAVE.url}?mcId=${ac.getMcId()}`, newTemplateNote)
            .then(res => {

                dispatch({type: RES_TEMPLATE_NOTES.SAVE.action, payload: res.data})
            });
    }
};

///////////////////////////////////////////////////// DELETES /////////////////////////////////////////////////////

export const deleteHskItem = ({url, action}, id) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(res => {

                dispatch({type: action, payload: id, result: res.data})
            });
    }
};

export const deleteScheduleRule = (rule) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_HOUSEKEEPING_CSCHED.DELETE.url}?mcId=${ac.getMcId()}`, rule)
            .then(res => {

                dispatch({type: RES_HOUSEKEEPING_CSCHED.DELETE.action, payload: rule.id, deleted: res.data})
            });
    }
};

export const informKeap = ({url, action}, details) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${details.id}&stageId=${details.stageId}`)
            .then(res => {

                dispatch({type: action, payload: true, result: res.data})
            });
    }
};
