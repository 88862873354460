import React from "react";
import _ from "lodash";
import {ICON_PATIENT_FEMALE} from "../../PatientDetails/Constants";
import {ICON_USER_LOCKED} from "../../UserDetails/Constants";
import {SU_STATUS_LOCKED} from "../../../../Constants";

export class ResourceContent extends React.Component {

    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }

    rightCLickHandler = (e) => {
        this.props.onShowRightClickHeader(e, this.props.diary, this.props.header);
    }

    componentDidMount() {

        try {
            this.myRef.current.parentNode.addEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    render() {

        let index = 0;
        const eventCount = _.filter(this.props.events, event => (event.resourceId === this.props.header.resource.resourceId) && (event.discriminator === 0)).length;

        let titleDetails = '';

        if (this.props.events) {
            titleDetails = ` ${this.props.header.resource.adHoc ? '*' : ''}(${eventCount}) ${this.props.header.resource.title}`
        } else {
            titleDetails = ` ${this.props.header.resource.adHoc ? '*' : ''}${this.props.header.resource.title}`
        }

        const className = this.props.header.resource.status === SU_STATUS_LOCKED ? ICON_USER_LOCKED : '';

        return (
            <div id={`${this.props.header.resource.resourceId + index}:${this.props.header.resource.adHoc}`}
                 ref={this.myRef}
                 style={{backgroundColor: this.props.header.resource.color}}
                 className={className}
            >
                {titleDetails}
            </div>
        )
    }

    componentWillUnmount() {

        try {
            this.myRef.current.parentNode.removeEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
