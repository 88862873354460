import React, {Component} from 'react';
import {connect} from "react-redux";

import FindPatients from "./FixedItems/Patients/FindPatients";
import PracticeDiary from "./FixedItems/Diary/PracticeDiary";
import AddPatient from "./FixedItems/Patients/AddPatient";
import FindUsers from "./FixedItems/Users/FindUsers";
import FindReferrers from "./FixedItems/Referrers/FindReferrers";
import AddUser from "./FixedItems/Users/AddUser";
import Housekeeping from "./FixedItems/Housekeeping/Housekeeping";
import {Button} from "primereact/components/button/Button";

import PCButtonWrapper from './FixedItems/PCButtonWrapper';

import * as Capabilities from './Capabilities';
import RecallsDue from "./FixedItems/Recalls/RecallsDue";
import RemindersDue from "./FixedItems/Reminders/RemindersDue";
import ClinicianDiary from "./FixedItems/Diary/ClinicianDiary";
import {
    SM_ADD_PATIENT,
    SM_ADD_USER,
    SM_CLINICIAN_DIARY,
    SM_CLINICIAN_WEEK_DIARY,
    SM_HOUSEKEEPING,
    SM_LABORATORY_SEARCH,
    SM_PATIENT_SEARCH,
    SM_PRACTICE_DIARY,
    SM_PRACTICE_WEEK_DIARY,
    SM_RECALLS_DUE,
    SM_REFERRER_SEARCH,
    SM_REMINDERS_DUE,
    SM_USER_SEARCH
} from "../actions/stateManagement";
import {getAddPatientTabIndex} from "./FixedItems/PatientDetails/Utils";
import PracticeWeekDiary from "./FixedItems/Diary/PracticeWeekDiary";
import {TabPanel} from "primereact/components/tabview/TabView";
import {DashBoardButtonStyle, HM_ClinicianSelector, HM_ClinicianWeekSelector} from "../Constants";
import ClinicianSelector from "./FixedItems/Diary/dialogs/ClinicianSelector";
import {MESSAGE_BUS} from "../actions";
import ClinicianWeekDiary from "./FixedItems/Diary/ClinicianWeekDiary";
import {getAddUserTabIndex} from "./FixedItems/UserDetails/Utils";
import FindLaboratory from "./FixedItems/Laboratories/FindLaboratory";

class ConnectedDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            [HM_ClinicianSelector.id]: false,
        };

        this.showDialogs = this.showDialogs.bind(this);
        this.showClinicianDiaryButton = this.showClinicianDiaryButton.bind(this);

        this.onSetClinician = this.onSetClinician.bind(this);
        this.onSetClinicianWeek = this.onSetClinicianWeek.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case MESSAGE_BUS:

                    const {type} = this.props.message.payload;

                    switch (type) {
                        case SM_CLINICIAN_DIARY.id:
                            this.setState({[HM_ClinicianSelector.id]: true});
                            break;
                        case SM_CLINICIAN_WEEK_DIARY.id:
                            this.setState({[HM_ClinicianWeekSelector.id]: true});
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    showClinicianDiaryButton() {

        const componentId = SM_CLINICIAN_DIARY.id;

        return ([
            <i key={1} className={SM_CLINICIAN_DIARY.bigIcon} style={DashBoardButtonStyle}/>,
            <Button key={2} label={SM_CLINICIAN_DIARY.label} style={{flexGrow: '1', fontSize: '18px'}}
                    id={componentId}
                    onClick={() => {
                        this.setState({[HM_ClinicianSelector.id]: true})
                    }}
            />
        ]);
    }

    onSetClinician(clinician) {

        const componentId = `${SM_CLINICIAN_DIARY.id}_${clinician.id}`;

        const Component = ClinicianDiary;

        const onCloseClick = this.props.onCloseClick;
        const onAdditionalClick = this.props.onClick;
        const onTabUpdate = this.props.onTabUpdate;

        this.props.onClick({
                edited: false,
                content:
                    <TabPanel key={`${componentId}`}
                              header={`${SM_CLINICIAN_DIARY.label} (${clinician.username})`}
                              leftIcon={`fa ${SM_CLINICIAN_DIARY.tabIcon}`}>
                        <Component onCloseClick={onCloseClick} id={componentId}
                                   onAdditionalClick={onAdditionalClick} onTabUpdate={onTabUpdate}
                                   defaultTab={undefined} options={{clinician}}/>
                    </TabPanel>,
                editedContent:
                    <TabPanel key={`${componentId}`}
                              header={`${SM_CLINICIAN_DIARY.label} (${clinician.username}) *`}
                              leftIcon={`fa ${SM_CLINICIAN_DIARY.tabIcon}`}>
                        <Component onCloseClick={onCloseClick} id={componentId}
                                   onAdditionalClick={onAdditionalClick} onTabUpdate={onTabUpdate}
                                   defaultTab={undefined} options={{clinician}}/>
                    </TabPanel>,
                defaultTab: undefined,
            }
        );
        this.setState({[HM_ClinicianSelector.id]: false})
    }

    onSetClinicianWeek(clinician) {

        const componentId = `${SM_CLINICIAN_WEEK_DIARY.id}_${clinician.id}`;

        const Component = ClinicianWeekDiary;

        const onCloseClick = this.props.onCloseClick;
        const onAdditionalClick = this.props.onClick;
        const onTabUpdate = this.props.onTabUpdate;

        this.props.onClick({
                edited: false,
                content:
                    <TabPanel key={`${componentId}`}
                              header={`${SM_CLINICIAN_WEEK_DIARY.label} (${clinician.username})`}
                              leftIcon={`fa ${SM_CLINICIAN_WEEK_DIARY.tabIcon}`}>
                        <Component onCloseClick={onCloseClick} id={componentId}
                                   onAdditionalClick={onAdditionalClick} onTabUpdate={onTabUpdate}
                                   defaultTab={undefined} options={{clinician}}/>
                    </TabPanel>,
                editedContent:
                    <TabPanel key={`${componentId}`}
                              header={`${SM_CLINICIAN_WEEK_DIARY.label} (${clinician.username}) *`}
                              leftIcon={`fa ${SM_CLINICIAN_WEEK_DIARY.tabIcon}`}>
                        <Component onCloseClick={onCloseClick} id={componentId}
                                   onAdditionalClick={onAdditionalClick} onTabUpdate={onTabUpdate}
                                   defaultTab={undefined} options={{clinician}}/>
                    </TabPanel>,
                defaultTab: undefined,
            }
        );
        this.setState({[HM_ClinicianWeekSelector.id]: false})
    }

    showDialogs() {

        const contents = [];

        if (this.state[HM_ClinicianSelector.id]) {
            contents.push(
                <ClinicianSelector key={HM_ClinicianSelector.id}
                                   onOkDialog={this.onSetClinician}
                                   onHide={(target) => {this.setState({[target]: false})}}
                />
            )
        } else if (this.state[HM_ClinicianWeekSelector.id]) {
            contents.push(
                <ClinicianSelector key={HM_ClinicianWeekSelector.id}
                                   onOkDialog={this.onSetClinician}
                                   onHide={(target) => {this.setState({[target]: false})}}
                />
            )
        }
        return contents;
    }

    render() {

      

        return (
            <div className="p-grid">

                {this.showDialogs()}

                <div className="p-xl-8 p-xl-offset-2">
                    <div className="card card-w-title card-bg-solid">
                        <span className="logo"><i className='fa mainLogoImg'/></span>
                        <div className="p-grid control-panel-dashboard">

                            {/*Row 1*/}

                            <div className="p-md-4">
                                <PCButtonWrapper component={FindPatients}
                                                 label={SM_PATIENT_SEARCH.label}
                                                 id={SM_PATIENT_SEARCH.id}
                                                 icon={SM_PATIENT_SEARCH.bigIcon}
                                                 tabIcon={SM_PATIENT_SEARCH.tabIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>
                            <div className="p-md-4">
                                <PCButtonWrapper component={PracticeDiary}
                                                 label={SM_PRACTICE_DIARY.label}
                                                 id={SM_PRACTICE_DIARY.id}
                                                 icon={SM_PRACTICE_DIARY.bigIcon}
                                                 tabIcon={SM_PRACTICE_DIARY.tabIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 options={{defaultDate: new Date()}}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>
                            <div className="p-md-4">
                                <PCButtonWrapper component={AddPatient}
                                                 label={SM_ADD_PATIENT.label}
                                                 id={getAddPatientTabIndex()}
                                                 icon={SM_ADD_PATIENT.bigIcon}
                                                 tabIcon={SM_ADD_PATIENT.tabIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 capabilities={this.props.capabilities}
                                                 control={Capabilities.AID_AddPatient}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>

                            {/*Row 2*/}

                            <div className="p-md-4">
                                <PCButtonWrapper component={FindUsers}
                                                 label={SM_USER_SEARCH.label}
                                                 id={SM_USER_SEARCH.id}
                                                 icon={SM_USER_SEARCH.bigIcon}
                                                 tabIcon={SM_USER_SEARCH.tabIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>
                            <div className="p-md-4">

                                {this.showClinicianDiaryButton()}

                            </div>
                            <div className="p-md-4">
                                <PCButtonWrapper component={AddUser}
                                                 label={SM_ADD_USER.label}
                                                 id={getAddUserTabIndex()}
                                                 icon={SM_ADD_USER.bigIcon}
                                                 tabIcon={SM_ADD_USER.tabIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 capabilities={this.props.capabilities}
                                                 control={Capabilities.AID_AddUser}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>

                            {/*Row 3*/}

                            <div className="p-md-4">
                                <PCButtonWrapper component={FindReferrers}
                                                 label={SM_REFERRER_SEARCH.label}
                                                 id={SM_REFERRER_SEARCH.id}
                                                 tabIcon={SM_REFERRER_SEARCH.tabIcon}
                                                 icon={SM_REFERRER_SEARCH.bigIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>
                            <div className="p-md-4">
                                <PCButtonWrapper component={PracticeWeekDiary}
                                                 label={SM_PRACTICE_WEEK_DIARY.label}
                                                 id={SM_PRACTICE_WEEK_DIARY.id}
                                                 icon={SM_PRACTICE_WEEK_DIARY.bigIcon}
                                                 tabIcon={SM_PRACTICE_WEEK_DIARY.tabIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>
                            <div className="p-md-4">
                                <PCButtonWrapper component={Housekeeping}
                                                 label={SM_HOUSEKEEPING.label}
                                                 id={SM_HOUSEKEEPING.id}
                                                 icon={SM_HOUSEKEEPING.bigIcon}
                                                 tabIcon={SM_HOUSEKEEPING.tabIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 capabilities={this.props.capabilities}
                                                 control={Capabilities.AID_HouseKeeping}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>

                            {/*Row 4*/}

                            <div className="p-md-4">
                                <PCButtonWrapper component={FindLaboratory}
                                                 label={SM_LABORATORY_SEARCH.label}
                                                 id={SM_LABORATORY_SEARCH.id}
                                                 tabIcon={SM_LABORATORY_SEARCH.tabIcon}
                                                 icon={SM_LABORATORY_SEARCH.bigIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>
                            <div className="p-md-4">
                                <PCButtonWrapper component={RemindersDue}
                                                 label={SM_REMINDERS_DUE.label}
                                                 id={SM_REMINDERS_DUE.id}
                                                 icon={SM_REMINDERS_DUE.bigIcon}
                                                 tabIcon={SM_REMINDERS_DUE.tabIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>
                            <div className="p-md-4">
                                <PCButtonWrapper component={RecallsDue}
                                                 label={SM_RECALLS_DUE.label}
                                                 id={SM_RECALLS_DUE.id}
                                                 icon={SM_RECALLS_DUE.bigIcon}
                                                 tabIcon={SM_RECALLS_DUE.tabIcon}
                                                 onClick={this.props.onClick}
                                                 onCloseClick={this.props.onCloseClick}
                                                 onTabUpdate={this.props.onTabUpdate}
                                                 style={DashBoardButtonStyle}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,
        capabilities: state.login.capabilities,
    }
};

const Dashboard = connect(mapStateToProps, null)(ConnectedDashboard);

export default Dashboard;