import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {calendar, dropDown, inputText, inputTextArea} from '../../../PatientDynamicItems/OnChangeUtils';

export const Organisation = (props) => {

    return (
        <Panel header='Organisation Details'>
            <div className="p-grid p-fluid form-group">

                <div className="p-col-3">
                    <label htmlFor="dob">Date Registered</label>
                </div>
                <div className="p-col-9">
                    {calendar(props, 'registeredOn', true, false)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="firstName">Organisation Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'companyName', 'Organisation', false, props.mandatory)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="title">Job Description</label>
                </div>
                <div className="p-col-9">
                    {dropDown(props, 'occupations', 'occupation', 'description', false, false)}
                </div>

                <div className="p-col-12">
                    <label htmlFor="comments">Comments</label>
                </div>

                <div className="p-col-12">
                    {inputTextArea(props, 'comments', 4, -1, false, false)}
                </div>
            </div>
        </Panel>
    )
};
