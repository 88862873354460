import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {Fieldset} from 'primereact/components/fieldset/Fieldset';

import {CPITN} from './CPITN';

import {categoryDropDown, inputTextArea, spinner,} from './OnChangeUtils';

export const PatAssIntraOral = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header='Intra Oral'>
            <div className="p-grid">
                <div className="p-col-8 p-md-8 p-lg-8 p-nopad">
                    <div className="p-col-12">
                        <div className="p-grid p-fluid form-group">

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Soft Tissue Exam</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'softTissueExam', 'softTissueExam', readOnly, false)}
                            </div>
                            <div className="p-col-9 p-md-6 p-md-6">
                                <label/>
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>X Rays Taken</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'xraysTaken', 'xraysTaken', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Justification</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'justification', 'justification', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>X Quality</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {spinner(props, 'xRQuality', 0, 100, readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Action Taken</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'actionTaken', 'actionTaken', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>BPE/CPITN</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                <CPITN readOnly={readOnly}
                                       {...props}/>
                            </div>

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>BPE RX Indicated</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'bpeRXIndicated', 'bpeRXIndicated', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Caries Risk</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'cariesRisk', 'cariesRisk', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Perio Risk</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'perioRisk', 'perioRisk', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Oral Hygiene</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'OralHygiene', 'oralHygiene', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>OHI Given</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'OHIGiven', 'ohigiven', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Flosses Regularly?</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'flosses', 'flosses', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Occlusion</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'occlusion', 'occlusion', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Guidance</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'guidance', 'guidance', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Abnormality</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'abnormality', 'abnormality', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Prosthesis Worn</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'prosthesisWorn', 'prosthesisWorn', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Labial Mucosa</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'LabialMucosa', 'labialMucosa', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Bucal Mucosa</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'BucalMucosa', 'bucalMucosa', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Floor of the Mouth</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'FloorOfMouth', 'floorOfMouth', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Tongue</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'Tongue', 'tongue', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Gingivae</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'Gingivae', 'gingivae', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Palate</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'Palate', 'palate', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>Back of the Throat</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'BackOfThroat', 'backOfThroat', readOnly, false)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-4 p-md-4 p-lg-4 p-fluid">
                    <div className="p-col-12 p-fluid">
                        <div className="p-grid p-fluid">

                            <Fieldset legend='X Ray Report' className='p-fluid p-col-12'>
                                {inputTextArea(props, 'xrayReport', 5, 100, readOnly, false)}
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-col-12 p-fluid">
                        <div className="p-grid p-fluid">
                            <Fieldset legend='Diagnosis' className='p-fluid p-col-12'>
                                {inputTextArea(props, 'diagnosis', 5, 100, readOnly, false)}
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-col-12 p-fluid">
                        <div className="p-grid p-fluid">
                            <Fieldset legend='Treatment Plan' className='p-fluid p-col-12'>
                                {inputTextArea(props, 'treatmentplan', 5, 100, readOnly, false)}
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-col-12 p-fluid">
                        <div className="p-grid p-fluid">
                            <Fieldset legend='Description of Abnormality' className='p-fluid p-col-12'>
                                {inputTextArea(props, 'descOfAbnormality', 5, 100, readOnly, false)}
                            </Fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    );
};
