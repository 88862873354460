import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {HM_VoidItem} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";

export default class VoidItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            voided: {
                narrative: '',
            }
        };

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onHide() {
        this.props.onHideDialog(HM_VoidItem.id);
    }

    render() {

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onVoidItem(this.state.voided.narrative);
                        }}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'voided',
            voided: this.state.voided,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">

                        <div className="p-col-12">
                            <label>Void Narrative</label>
                        </div>
                        <div className="p-col-12">
                            {inputTextArea(props, 'narrative', 5, -1, false, false)}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
