import {RRT_RECALL_EMAIL, RRT_RECALL_SMS} from "../../../Constants";

export const calcAllRecalls = (selectedProvider, recalls) => {

    const nonGroupResult = [];
    const groupResult = [];

    const nonBillingGroupCollation = new Map();
    const billingGroupCollation = new Map();

    const nonBillingGrouped = [];
    const billingGrouped = [];

    let electronic = false;
    let electronicGroup = false;
    let printable = false;
    let printableGroup = false;

    recalls.forEach(recall => {

        if (recall.bg1Id === null && recall.bg2Id === null) {
            nonBillingGrouped.push(recall);
        } else {
            if (recall.sendToPrinciple && !recall.keepSeparate)
                billingGrouped.push(recall);
            else
                nonBillingGrouped.push(recall);
        }
    });

    // non billing group entries
    nonBillingGrouped.forEach(recall => {

        const {patientId} = recall;

        if (!nonBillingGroupCollation.has(patientId)) {
            nonBillingGroupCollation.set(patientId, [recall]);
        } else {
            const content = nonBillingGroupCollation.get(patientId);
            content.push(recall);
            nonBillingGroupCollation.set(patientId, content);
        }
    });

    // we now create a tree of patient reminders, this is to match the tree viewer
    nonBillingGroupCollation.forEach((values) => {

        let isElectronic = false;
        let isPrintable = false;

        const principle = values[0].providerId;

        const matched = matchProvider(selectedProvider, principle);

        if (values.length === 1) {
            electronic = isElectronic || electronic;
            printable = isPrintable || printable;
            nonGroupResult.push(values[0])
        } else {
            const {patientTitle, patientFirstName, patientLastName} = values[0];
            const group = {
                key: `_${values[0].id}`,
                data: {header: true, bgEntry: false, patientTitle, patientFirstName, patientLastName},
                children: []
            };

            for (let index = 0; index < values.length; index++) {

                if (values[index].recallMethod === RRT_RECALL_EMAIL || values[index].recallMethod === RRT_RECALL_SMS)
                    isElectronic = true;
                else
                    isPrintable = true;

                group.children.push({key: values[index].id, data: {header: false, ...values[index]}, children: []});
            }
            electronic = isElectronic || electronic;
            printable = isPrintable || printable;
            groupResult.push(group);
        }
    });

    // billing group entries
    billingGrouped.forEach(recall => {

        const billingGroupId = recall.bg2Id !== null ? recall.bg2Id : recall.bg1Id;

        if (!billingGroupCollation.has(billingGroupId)) {
            billingGroupCollation.set(billingGroupId, [recall]);
        } else {
            const content = billingGroupCollation.get(billingGroupId);
            content.push(recall);
            billingGroupCollation.set(billingGroupId, content);
        }
    });

    billingGroupCollation.forEach((values, billingGroupId) => {

        let isElectronic = false;
        let isPrintable = false;

        const principle = billingGroupCollation.get(billingGroupId)[0];

        const matched = matchProvider(selectedProvider, principle);

        if (values.length === 1) {
            nonGroupResult.push(values[0])
        } else {
            const {patientTitle, patientFirstName, patientLastName} = values[0];
            const group = {
                key: `_${principle}`,
                data: {header: true, bgEntry: false, patientTitle, patientFirstName, patientLastName},
                children: []
            };

            for (let index = 0; index < values.length; index++) {

                if (values[index].recallMethod === RRT_RECALL_EMAIL || values[index].recallMethod === RRT_RECALL_SMS)
                    isElectronic = true;
                else
                    isPrintable = true;

                group.children.push({key: values[index].id, data: {header: false, ...values[index]}, children: []});
            }

            electronicGroup = isElectronic || electronicGroup;
            printableGroup = isPrintable || printableGroup;

            groupResult.push(group);
        }
    });

    return {nonGroupResult, groupResult};
};

const matchProvider = (selectedProvider, principle) => {

    if (!selectedProvider || !principle) {
        return false;
    } else {
        return selectedProvider === principle;
    }
}