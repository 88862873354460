import React from 'react';
import moment from 'moment';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {HM_DeleteAssessment, HM_EditAssessment} from "../../Constants";

const dateTemplate = (row) => {

    return moment(row.assessmentDate).format('ddd, DD MMM YYYY');
};

const byTemplate = (row) => {

    const by = row.assessedBy;
    return `${by.title.abbreviation} ${by.firstName} ${by.lastName}`.trim();
};

export const AssessmentHistory = (props) => {

    let cm = null;

    const items = [
        {
            label: HM_EditAssessment.header,
            icon: HM_EditAssessment.icon,
            command: (e) => props.toolbarCallbacks[HM_EditAssessment.id](e)
        },
        {
            label: HM_DeleteAssessment.header,
            icon: HM_DeleteAssessment.icon,
            command: (e) => props.toolbarCallbacks[HM_DeleteAssessment.id](e)
        },
    ];

    const header = <div className='panel-header-centered-content'><label id='panel-header'>Assessment History</label>
        <Button tooltipOptions={{position: 'right'}}
                tooltip={props.assessmentType.header}
                icon={props.assessmentType.icon}
                onClick={props.onAddAssessment}>
        </Button>
    </div>;

    return (
        <Panel header={header} id='assessmentHistory'>

            <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

            <DataTable selectionMode="single"
                       className='p-datatable-gridlines'
                       style={{fontSize: 'small'}}
                       value={props.list}
                       paginator={true}
                       rows={5}
                       rowsPerPageOptions={[5, 10, 20]}
                       onSelectionChange={(e) => props.onSelectionChanged(e.value)}
                       selection={props.assessment}
                       contextMenuSelection={props.assessment}
                       onContextMenuSelectionChange={e => props.onSelectionChanged(e.value)}
                       onContextMenu={e => {
                           cm.show(e.originalEvent);
                       }}
            >
                <Column field="assessmentDate" body={dateTemplate} header="Date" style={{width: '40%'}}/>
                <Column field="assessedBy" body={byTemplate} header="By" style={{width: '60%'}}/>
            </DataTable>
        </Panel>
    )
};
