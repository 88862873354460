import {BaseComponent} from "../../../BaseComponent";
import _ from "lodash";
import {
    DOC_ANY,
    DOC_APPOINTMENT_LETTER, DOC_CONSENT, DOC_Email, DOC_GDPR_CONSENT, DOC_IMAGE, DOC_ORTHO_REFERRAL, DOC_PAYMENT_PLAN,
    DOC_PDF, DOC_PRESCRIPTION, DOC_REFERRAL, DOC_SMS, DOC_SMS_INCOMING, DOC_SMS_OUTGOING, DOC_TREATMENT_PLAN,
    DOC_UPLOAD_MSWORD, DOC_UPLOAD_SCAN_GIF, DOC_UPLOAD_SCAN_JPG, DOC_UPLOAD_SCAN_PDF, DOC_UPLOAD_SCAN_TIF,
    DOC_UPLOAD_WORD, DOC_UPLOAD_XRAY_GIF, DOC_UPLOAD_XRAY_JPG, DOC_UPLOAD_XRAY_TIF,
    DOC_WORD
} from "../../../../Constants";

export class DocumentBaseComponent extends BaseComponent {

    constructor(props) {
        super(props);
    }

    processDocuments = () => {

        const patientDocuments = [];
        const presDocuments = [];
        const refDocuments = [];
        const xrayDocuments = [];
        const tpDocuments = [];
        const smsEmailDocuments = [];
        const consentFormDocuments = [];

        let documentClassification;

        this.props.patientDocuments.forEach(document => {

            documentClassification = _.find(this.props.classifications, classification => classification.id === document.classificationId);
            document.classification = documentClassification;

            switch (document.type) {

                case DOC_WORD.name:
                case DOC_ANY.name:
                case DOC_UPLOAD_MSWORD.name:
                case DOC_UPLOAD_WORD.name:
                case DOC_PDF.name:
                case DOC_APPOINTMENT_LETTER.name:
                    patientDocuments.push(document);
                    break;
                case DOC_PRESCRIPTION.name:
                    presDocuments.push(document);
                    break;
                case DOC_REFERRAL.name:
                case DOC_ORTHO_REFERRAL.name:
                    refDocuments.push(document);
                    break;
                case DOC_UPLOAD_XRAY_TIF.name:
                case DOC_UPLOAD_XRAY_JPG.name:
                case DOC_UPLOAD_XRAY_GIF.name:
                case DOC_UPLOAD_SCAN_TIF.name:
                case DOC_UPLOAD_SCAN_JPG.name:
                case DOC_UPLOAD_SCAN_GIF.name:
                case DOC_UPLOAD_SCAN_PDF.name:
                case DOC_IMAGE.name:
                    xrayDocuments.push(document);
                    break;
                case DOC_TREATMENT_PLAN.name:
                case DOC_PAYMENT_PLAN.name:
                    tpDocuments.push(document);
                    break;
                case DOC_SMS.name:
                case DOC_SMS_INCOMING.name:
                case DOC_SMS_OUTGOING.name:
                case DOC_Email.name:
                    smsEmailDocuments.push(document);
                    break;
                case DOC_CONSENT.name:
                case DOC_GDPR_CONSENT.name:
                    consentFormDocuments.push(document);
                    break;
                default:
                    break;
            }

            this.setState({
                patientDocuments,
                presDocuments,
                refDocuments,
                xrayDocuments,
                tpDocuments,
                smsEmailDocuments,
                consentFormDocuments
            }, () => {
                this.props.setState(this.state.stateManagementId, {...this.state}, this.props.patientDataId);
            });
        });
    }
}
