import React from 'react';
import {connect} from "react-redux";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {
    HM_AccountGroupSelector,
    JSON_DATE_FORMAT,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {
    ICON_DOWNLOAD,
    ICON_HELP,
    ICON_PRINT,
    ICON_SCREEN,
} from "../../../icons";
import {getReport, getReportPDF, RES_REPORT_YEARS_CASH} from "../../../actions/reports";
import moment from "moment";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {ProgressBar} from "primereact/progressbar";

import {dateTemplateMonthYear, dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {Calendar} from "primereact/calendar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import * as Actions from "../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {PAY_TYPES} from "../PatientDetails/Constants";
import AccountGroupSelector from "./dialogs/AccountGroupSelector";
import {outputCurrency} from "../../PatientDynamicItems/OnChangeUtils";
import {TabPanel} from "primereact/components/tabview/TabView";
import {
    HELP_REP_YEARS_CASH,
    setState,
    SM_REPORTS_YEARS_CASH,
    SM_REPORTS_YEARS_CASH_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import {TextYearsCashShare} from "../../../Messages";
import YearsCashBookPDF from "./PDFs/YearsCashBookPDF";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_MONTH, DIARY_FOR_MONTH} from "../Diary/Constants";

export class ConnectedYearlyCash extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: SM_REPORTS_YEARS_CASH.id,

                yearsCashLoaded: false,
                year: [],

                currentDate: new Date(),
                selectedAccountGroup: null,

                selectedItem: null,

                pdfRequest: PDF_REQUEST_NONE,

                [HM_AccountGroupSelector.id]: true,
            }
        }
        this.onShowReport = this.onShowReport.bind(this);
        this.onPrintReport = this.onPrintReport.bind(this);
        this.onDownload = this.onDownload.bind(this);

        this.showDialogs = this.showDialogs.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSetAccountGroup = this.onSetAccountGroup.bind(this);
        this.insertColumns = this.insertColumns.bind(this);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_REPORT_YEARS_CASH:

                    const yearsCashHeadings = ['Month', '', '', '', '', '', '', '', '', '', '', 'Refund', 'Subtotal'];

                    PAY_TYPES.forEach((type) => {
                        yearsCashHeadings[type.ordinal + 1] = type.label;
                    })

                    const yearsCash = [];
                    this.props.yearsCash.forEach((_row, index) => {

                        let subTotal = 0.0;

                        _row.forEach(col => subTotal += col);

                        const row = [index, ..._row, subTotal];
                        yearsCash.push(row)
                    });

                    this.setState({yearsCash, yearsCashLoaded: true, yearsCashHeadings}, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;
                case RES_REPORT_YEARS_CASH.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_YEARS_CASH_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {
            case 'currentDate' :
                state.currentDate = event.value;
                break;
            case 'back' :
                state.currentDate = moment(state.currentDate).add(-1, 'Y').toDate()
                break;
            case 'forward' :
                state.currentDate = moment(state.currentDate).add(1, 'Y').toDate()
                break;
            case 'selectedItem' :
                state.selectedItem = event.value;
                this.setState(state);
                return;
            default:
                break;
        }

        state.first = 0;
        state.selectedItem = null;
        state.yearsCashLoaded = false;

        this.setState({...state, pdfBuffer: null, PDFLoaded: false}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
            this.props.getReport({
                date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                id: this.state.selectedAccountGroup.id
            });
        });
    }

    onShowDetails({id, firstName, lastName, gender, nhsPatient}, groupId) {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    onShowReport(event) {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const daysDate = this.state.currentDate;
        const formattedDate = moment(daysDate).format(JSON_DATE_FORMAT);

        const agId = this.state.selectedAccountGroup.id;

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={`${SM_REPORTS_YEARS_CASH_PDF.id}_${agId}`} header={SM_REPORTS_YEARS_CASH_PDF.label}
                              leftIcon={SM_REPORTS_YEARS_CASH_PDF.tabIcon}>
                        <YearsCashBookPDF onCloseClick={onCloseClick}
                                          onTabUpdate={onTabUpdate}
                                          date={formattedDate}
                                          selectedAccountGroup={this.state.selectedAccountGroup}
                                          id={`${SM_REPORTS_YEARS_CASH_PDF.id}_${agId}`}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport(event) {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_YEARS_CASH.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {

                const params = {
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    id: this.state.selectedAccountGroup.id
                };
                this.props.getReportPDF(params);
            })
        }
    }

    onDownload() {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_REPORTS_YEARS_CASH.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {

                const params = {
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    id: this.state.selectedAccountGroup.id
                };
                this.props.getReportPDF(params);
            })
        }
    }

    onSetAccountGroup(accountGroup) {

        this.setState({selectedAccountGroup: accountGroup, [HM_AccountGroupSelector.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
            this.props.getReport({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT), id: accountGroup.id});
        });
    }

    rowClassName(rowData) {

        return {'p-highlight': rowData[0] > 31}
    }

    rowTemplate(rowData, column) {

        const columnIndex = parseInt(column.columnKey, 10);

        if (columnIndex === 0) {
            if (column.rowIndex === column.value.length - 1) {
                return <span>Subtotal</span>
            } else {
                const month = moment(`1900-${rowData[columnIndex] + 1}-01`).format("MMMM");
                return <span>{month}</span>
            }
        } else {
            return <span>{outputCurrency(rowData[columnIndex])}</span>
        }
    }

    insertColumns() {

        const headings = [];

        if (this.state.selectedAccountGroup !== null) {

            this.state.yearsCashHeadings.forEach((heading, index) => {

                headings.push(
                    <Column header={heading}
                            body={this.rowTemplate}
                            style={{width: '10%', textAlign: 'right'}}
                            columnKey={`${index}`}
                            key={index}
                    />
                )
            })
        }
        return headings;
    }

    showDialogs() {

        const contents = [];

        if (this.state[HM_AccountGroupSelector.id]) {
            contents.push(
                <AccountGroupSelector key={HM_AccountGroupSelector.id}
                                      onOkDialog={this.onSetAccountGroup}
                />
            )
        }
        return contents;
    }

    render() {

        if (this.state.selectedAccountGroup !== null && !this.state.yearsCashLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }


        const accountGroupName = this.state.selectedAccountGroup === null ? '-' : this.state.selectedAccountGroup.groupName;

        const header = <div className='panel-header-centered-content'><label id='panel-header'>Years Cash
            : {`(${dateTemplateMonthYear(this.state.currentDate)}) - ${accountGroupName}`}</label></div>;

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar
                            left={<React.Fragment>
                                {tb_boilerPlate(() => this.onChange({owner: 'back'}), DIARY_BACK_MONTH.icon, DIARY_BACK_MONTH.tooltip, key++)}
                                {tb_boilerPlate(() => this.onChange({owner: 'forward'}), DIARY_FOR_MONTH.icon, DIARY_FOR_MONTH.tooltip, key++)}
                                <Calendar value={this.state.currentDate}
                                          dateFormat="yy"
                                          view='year'
                                          onChange={(e) => this.onChange({owner: 'currentDate', value: e.value})}
                                          readOnlyInput={true}/>
                            </React.Fragment>}
                            right={<React.Fragment>
                                {tb_boilerPlateRight2(this.onShowReport, ICON_SCREEN, 'Show ' + TextYearsCashShare, key++, false)}
                                {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextYearsCashShare, key++, false)}
                                {tb_boilerPlateRight2(this.onDownload, ICON_DOWNLOAD, `Download ${TextYearsCashShare} Report`, key++, true)}
                                {tb_boilerPlateRight(() => window.open(HELP_REP_YEARS_CASH), ICON_HELP, 'Knowledge Base', key++, true)}
                                {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                            </React.Fragment>}
                    />

                    <Panel header={header} style={{paddingTop: '5px'}}>
                        <DataTable value={this.state.yearsCash}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   selection={this.state.selectedItem}
                                   onSelectionChange={e =>
                                       this.onChange({owner: `selectedItem`, value: e.value})
                                   }
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e =>
                                       this.onChange({owner: `selectedItem`, value: e.value})
                                   }
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent)
                                   }}
                                   rowClassName={this.rowClassName}
                        >
                            {this.insertColumns()}
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_YEARS_CASH.CLEAR);
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        yearsCashLoaded: state.reports.yearsCashLoaded,
        yearsCash: state.reports.yearsCash,

        PDFLoaded: state.reports.yearsCashPDFLoaded,
        pdfBuffer: state.reports.yearsCashPDF,

        currentState: state.stateManagement[SM_REPORTS_YEARS_CASH.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReport(RES_REPORT_YEARS_CASH.GET, params)),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_YEARS_CASH.PDF, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const YearlyCash = connect(mapStateToProps, mapDispatchToProps)(ConnectedYearlyCash);

export default YearlyCash;
