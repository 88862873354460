import {SM_WORK_REQUESTED_REPORT} from "../../actions/stateManagement";
import _ from "lodash";

export const updateWorkRequired = (state, action, sendMessage) => {

    if (!state[SM_WORK_REQUESTED_REPORT.id]) {
        return sendMessage(state, action);
    }
    const data = {...state[SM_WORK_REQUESTED_REPORT.id].data};
    const index = _.findIndex(data.labWorkRequested, item => item.id === action.payload.id);

    if (index >= 0) {
        return sendMessage(state, action);
    } else {
        data.labWorkRequested.unshift(action.payload.content);
        data.labWorkRequestedLoaded = true;
        return sendMessage({
            ...state,
            data,
        })
    }
}