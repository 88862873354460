import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {BaseComponent} from "../../../BaseComponent";
import {HM_CancelAppointment, RC_USABLE} from "../../../../Constants";
import _ from "lodash";
import * as DefaultData from "../../DefaultData";
import {ac} from "../../../../index";
import {getResource, RES_HOUSEKEEPING_CRS, RES_HOUSEKEEPING_TCODES} from "../../../../actions/housekeeping";

import {crTypeTemplate, getHousekeepingIds} from "../../Housekeeping/Utils";
import {ProgressBar} from "primereact/progressbar";

export class ConnectedCancellation extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            cancellation: _.cloneDeep(DefaultData.AppointmentCancelDAO(ac.getMcId())),
            reasons: [],
            charges: [],
            charge: null,
            cancellationReason: null,

            first: 0,
            rows: 5,
        };

        this.state.cancellation.id = props.item.id;
        this.state.cancellation.cancelledbyId = props.loginId;

        this.onTCChange = this.onTCChange.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.onCRSelection = this.onCRSelection.bind(this);
    };

    componentDidMount() {

        this.props.getHousekeepingTCodes();
        this.props.getCancellationReasonsAndCharges();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_TCODES.GET.receive:
                    break;

                case RES_HOUSEKEEPING_CRS.GET.receive:
                    this.setState({cancellationReasons: this.props.cancellationReasons});
                    break;
                default:
                    break;
            }
        }
    }

    onTCChange(event) {

        const cancellation = {...this.state.cancellation};
        cancellation.chargeId = event.value.id;
        this.setState({cancellation, charge: event.value})
    }

    onCommentChange(event) {

        const cancellation = {...this.state.cancellation};
        cancellation.comment = event.target.value;
        this.setState({cancellation})
    }

    onCRSelection(event) {

        const cancellation = {...this.state.cancellation};
        cancellation.reasonId = event.value.id;
        this.setState({cancellation, cancellationReason: event.value})
    }

    render() {

        if (!this.props.cancellationReasonsLoaded || !this.props.treatmentCodesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = _.filter(this.props.treatmentCodes.items, item => item.cancellationCharge);
        const crItems = _.filter(this.props.cancellationReasons, crItem => crItem.status === RC_USABLE);

        const footer = (
            <div>
                <Button label="OK"
                        disabled={this.state.cancellationReason === null}
                        onClick={() => {
                            this.props.onOkDialog(this.state.cancellation)
                        }}/>
                <Button label="Cancel"
                        onClick={() => {
                            this.props.onHideDialog(HM_CancelAppointment.id)
                        }}/>
            </div>
        );

        return (
            <div>
                <Dialog style={{width: "50%"}}
                        header="Cancel Appointment"
                        footer={footer}
                        visible={true}
                        modal={true}
                        onHide={(e) => this.props.showCancellationDialog(false)}>

                    <Panel className="card p-grid p-fluid form-group">

                        <DataTable value={crItems}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.cancellationReason}
                                   onSelectionChange={this.onCRSelection}
                        >

                            <Column field="reason"
                                    header="Reason"
                                    style={{width: '75%'}}/>
                            <Column body={crTypeTemplate}
                                    header="Type"
                                    style={{width: '25%'}}/>
                        </DataTable>
                        <InputTextarea rows={5} cols={30} value={this.state.cancellation.comment}
                                       onChange={this.onCommentChange}/>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Dropdown value={this.state.charge}
                                          optionLabel="description"
                                          options={items}
                                          onChange={this.onTCChange}
                                          placeholder="Select a Cancellation Charge"
                                          scrollHeight="200px"
                                          style={{width: '100%'}}
                                />
                            </div>
                        </div>
                    </Panel>
                </Dialog>
            </div>
        )
    };
}

const mapStateToProps = (state, ownProps) => {

    const {
        cancellationReasonsLoaded,
        cancellationReasons,
        treatmentCodesLoaded,
        treatmentCodes,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        treatmentCodesLoaded,
        treatmentCodes,

        cancellationReasonsLoaded,
        cancellationReasons,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getHousekeepingTCodes: () => dispatch(getResource(RES_HOUSEKEEPING_TCODES.GET, {})),
        getCancellationReasonsAndCharges: () => dispatch(getResource(RES_HOUSEKEEPING_CRS.GET, {})),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const Cancellation = connect(mapStateToProps, mapDispatchToProps)(ConnectedCancellation);

export default Cancellation;

