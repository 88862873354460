import React from 'react';
import {connect} from "react-redux";
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {HM_notImplemented, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_HELP} from "../../../icons";
import ReportErrorBoundary from "../Reports/Utils";
import {tb_boilerPlateRight} from "../../Utils";
import {HELP_REP_RECALL_MGMT, setState, SM_REPORTS_RECALL_MANAGEMENT} from "../../../actions/stateManagement";
import {TabBaseComponent} from "../../TabBaseComponent";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {RES_RecallManagement} from "../../../actions/recalls";
import RecallStageSection from "./Sections/RecallStage";

import * as Sections from "./Constants";
import {getAllUsers} from "../../../actions/users";

export class ConnectedRecallManagement extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_REPORTS_RECALL_MANAGEMENT.id,

                canSave: {status: false, activeIndex: 0, source: {action: RES_RecallManagement.CLEAR.action}},
                showSaveDialog: false,
            }
        }

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {
        if (!Boolean(this.props.currentState)) {
            this.props.getAllUsersShort();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message.type !== prevProps.message.type) {

            switch (this.props.message.type) {

                case RES_RecallManagement.GET.receive:
                    break;
                default:
                    break;
            }
        }
    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    render() {

        let key = 1000;

        this.tabStack = [];
        this.index = 0;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.LimitReached, content: <TabPanel key={Sections.LimitReached.id}
                                                                   header={Sections.LimitReached.header}>
                    <RecallStageSection stage={Sections.LimitReached}
                                        usersShort={this.props.usersShort}
                                        setState={this.props.setState}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.Com1, content: <TabPanel key={Sections.Com1.id}
                                                           header={Sections.Com1.header}>
                    <RecallStageSection stage={Sections.Com1}
                                        usersShort={this.props.usersShort}
                                        setState={this.props.setState}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.Com2, content: <TabPanel key={Sections.Com2.id}
                                                           header={Sections.Com2.header}>
                    <RecallStageSection stage={Sections.Com2}
                                        usersShort={this.props.usersShort}
                                        setState={this.props.setState}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.Com3, content: <TabPanel key={Sections.Com3.id}
                                                           header={Sections.Com3.header}>
                    <RecallStageSection stage={Sections.Com3}
                                        usersShort={this.props.usersShort}
                                        setState={this.props.setState}
                    />
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.Deactivated, content: <TabPanel key={Sections.Deactivated.id}
                                                                  header={Sections.Deactivated.header}>
                    <RecallStageSection stage={Sections.Deactivated}
                                        usersShort={this.props.usersShort}
                                        setState={this.props.setState}
                    />
                </TabPanel>
            });

        return (

            <ReportErrorBoundary>
                <div id="detailPanel">

                    {this.showDialogs()}

                    <Toolbar
                        right={<React.Fragment>
                            {tb_boilerPlateRight(() => window.open(HELP_REP_RECALL_MGMT), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <TabView scrollable={true}
                             style={{paddingTop: '5px'}}
                             activeIndex={this.state.canSave.activeIndex}
                             onTabChange={(e) => {
                                 this.onTabChange(e.index)
                             }}>

                        {this.tabStack.map(tab => {
                            return tab.content
                        })}

                    </TabView>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        currentState: state.stateManagement[SM_REPORTS_RECALL_MANAGEMENT.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const RecallManagement = connect(mapStateToProps, mapDispatchToProps)(ConnectedRecallManagement);

export default RecallManagement;

RecallManagement.propTypes = {};