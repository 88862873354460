import React from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import moment from 'moment';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';

import {ProgressBar} from "primereact/progressbar";
import {Calendar} from "primereact/components/calendar/Calendar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {
    HM_AccountGroupSelector,
    HM_REP_DAILY_TRANSACTIONS,
    HM_ShowInvoice,
    JSON_DATE_FORMAT,
    REP_Invoice,
    REPORT_SHOW,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_EXCEL, ICON_GOTO, ICON_HELP, ICON_PRINT, ICON_SHOW,} from "../../../icons";
import {
    getReport,
    getReportPDF,
    RES_REPORT_DAILY_TRANSACTIONS,
    RES_REPORT_TRANS_INVOICES,
    RES_REPORT_TRANS_PAYMENTS,
    RES_REPORT_TRANS_RECEIPTS,
    RES_REPORT_TRANS_REFUNDS
} from "../../../actions/reports";
import {
    HELP_REP_DAILY_TRANS,
    setState,
    SM_patientDataShort,
    SM_REPORTS_DAILY_TRANSACTIONS,
    SM_REPORTS_DAILY_TRANSACTIONS_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import {dateTemplateShort, dateTemplateVeryShort} from "../../PatientDynamicItems/Utils";
import * as Actions from "../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import AccountGroupSelector from "./dialogs/AccountGroupSelector";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import printJS from "print-js";
import {ac} from "../../../index";
import {currencyTemplate} from "../fixedItemUtils";
import {getResource as getPatResource, RES_PATIENT_DETAILS_SHORT} from "../../../actions/personal";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_DAY, DIARY_FOR_DAY} from "../Diary/Constants";
import {TextDaysTransactionsExcel} from "../../../Messages";

const INV_COLS = ["Invoice", "Patient", "Provider", "Code", "S.Total", "Total"];
const REC_PAY_COLS = ["Pay / Receipt", "Payment Type", "Patient", "Provider", "Code", "S.Total", "Total"];
const REF_COLS = ["Refund", "Patient", "Amount"];
const TOTAL_COLS = ["Type", "Total"];
const GRAND_TOTAL_COLS = ["Invoice Total", "Receipt Total", "Payment Total", "Refund Total"];

export class ConnectedDailyTransactions extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                currentDate: new Date(),
                selectedAccountGroup: null,

                paymentReceipts: [],

                selectedItemInvoice: null,
                selectedItemReceipt: null,
                selectedItemPayment: null,
                selectedItemRefund: null,
                selectedPatientId: null,

                invoiceRows: 5,
                firstInvoice: 0,

                receiptRows: 5,
                firstReceipt: 0,

                paymentRows: 5,
                firstPayment: 0,

                refundRows: 5,
                firstRefund: 0,

                pdfRequest: PDF_REQUEST_NONE,

                [HM_AccountGroupSelector.id]: true,
            };
        }
        this.cmInvoices = null;
        this.cmPayRecs = null;
        this.cmRefund = null;
        this.showStripe = false;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_TRANS_PAYMENTS.GET_DAYS.receive: {

                    const paymentReceipts = _.concat(this.state.paymentReceipts, this.props.paymentTransactions);
                    this.setState({paymentReceipts});
                    break;
                }
                case RES_REPORT_TRANS_RECEIPTS.GET_DAYS.receive: {

                    const paymentReceipts = _.concat(this.state.paymentReceipts, this.props.receiptTransactions);
                    this.setState({paymentReceipts});
                    break;
                }
                case RES_REPORT_DAILY_TRANSACTIONS.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_DAILY_TRANSACTIONS_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                case Actions.RECEIVE_PATIENT_DETAILS_SHORT:
                    const {
                        id,
                        firstName,
                        lastName,
                        gender,
                        nhsPatient,
                        billingGroup,
                        mobile
                    } = this.props.patientDataShort;
                    showPatientDetailsPage({
                        props: this.props,
                        id,
                        firstName,
                        lastName,
                        gender,
                        nhsPatient,
                        groupId: billingGroup,
                        mobile
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = ({id, firstName, lastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    getReportData = () => {

        const params = {
            id: this.state.selectedAccountGroup.id,
            date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
        };

        this.props.getInvoicesReport(params);
        this.props.getReceiptsReport(params);
        this.props.getPaymentsReport(params);
        this.props.getRefundsReport(params);

        this.props.setState(SM_REPORTS_DAILY_TRANSACTIONS.id, this.state);
    }

    onDateChange = (e) => {
        this.setState({paymentReceipts: [], currentDate: e.value, pdfBuffer: null, PDFLoaded: false}, () => {
            this.getReportData();
        })
    }

    onDateChange2(forward) {

        let currentDate = this.state.currentDate;

        currentDate = moment(currentDate).add(forward ? 1 : -1, 'days');
        currentDate = currentDate.toDate();

        this.setState({paymentReceipts: [], currentDate, pdfBuffer: null, PDFLoaded: false}, () => {
            this.getReportData();
        });
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        switch (event.owner) {
            case 'selectedItemInvoice' :
            case 'selectedItemReceipt' :
            case 'selectedItemRefund' :
                _.set(state, 'selectedPatientId', event.value.patientId);
                break;
            default:
                break;
        }
        this.setState(state, () => {
            this.props.setState(SM_REPORTS_DAILY_TRANSACTIONS.id, this.state);
        });
    }

    onPrintReport(event) {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_DAILY_TRANSACTIONS.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                const params = {
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    id: this.state.selectedAccountGroup.id
                };
                this.props.getReportPDF(params);
            })
        }
    }

    onDownload = () => {

        const url = `https://${ac.getBASERESTURL()}${RES_REPORT_DAILY_TRANSACTIONS.XLS.url}?mcId=${ac.getMcId()}&id=${this.state.selectedAccountGroup.id}&date=${moment(this.state.currentDate).format(JSON_DATE_FORMAT)}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `dt${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                    a.click();
                });
            });
    }

    onSetAccountGroup = (accountGroup) => {

        this.setState({selectedAccountGroup: accountGroup, [HM_AccountGroupSelector.id]: false}, () => {

            this.getReportData()
        });
    }

    templateNumberRow(row) {
        return row.showHeader ? row.number : '';
    }

    templatePatientNameRow(row) {
        return row.showHeader ? row.patientName : '';
    }

    templateUsernameRow(row) {
        return row.payment ? '-' : row.username;
    }

    templateCodeRow(row) {
        return row.showHeader ? '' : row.description;
    }

    templateChargedRow(row) {
        return row.showHeader ? '' : row.decoratedChargePrice;
    }

    templateTotalRow(row) {

        if (row.payment) {
            return currencyTemplate(row, 'amount');
        } else {
            return row.showHeader ? currencyTemplate(row, 'total') : '';
        }
    }

    stripeClassName = (row) => {

        if (row.patientName !== undefined) {
            this.showStripe = !this.showStripe;
        }
        return {'p-stripe': this.showStripe};
    }

    onShowAccount = (e) => {

    }

    onShowPrintInvoice = (e) => {

        printJS({
            printable: `https://${ac.getBASERESTURL()}/Reports/accountingItem.pdf?mcId=${ac.getMcId()}&report=${REP_Invoice.value}&reportFunction=${REPORT_SHOW}&id=${this.state.selectedItemInvoice.id}`,
            type: 'pdf',
            showModal: 'true',
            documentTitle: `${this.state.selectedItemInvoice.number}`
        });
    }

    buildInvoiceContextMenu = () => {

        if (this.state.selectedItemInvoice === null) {
            return [];
        } else {
            return [
                {
                    label: 'Show Invoice',
                    icon: ICON_SHOW,
                    command: this.onShowAccountItem,
                    target: HM_ShowInvoice.id,
                    report: REP_Invoice.value,
                    reportFunction: REPORT_SHOW
                },
                {label: 'Print Invoice', icon: ICON_PRINT, command: this.onShowPrintInvoice, print: true},
                {
                    label: 'Show this patients account', icon: ICON_GOTO, command: (e) => {
                        this.props.getPatientDetails({patientId: this.state.selectedItemInvoice.patientId});
                    }
                },
            ]
        }
    }

    buildPayRecContextMenu = () => {

        if (this.state.selectedItemReceipt === null) {
            return [];
        } else {

            return [
                {
                    label: 'Show this patients account', icon: ICON_GOTO, command: (e) => {
                        this.props.getPatientDetails({patientId: this.state.selectedItemReceipt.patientId});
                    }
                },
            ]
        }
    }

    buildRefundContextMenu = () => {

        if (this.state.selectedItemRefund === null) {
            return [];
        } else {

            return [
                {
                    label: 'Show this patients account', icon: ICON_GOTO, command: (e) => {
                        this.props.getPatientDetails({patientId: this.state.selectedItemRefund.patientId});
                    }
                },
            ]
        }
    }

    showDialogs() {

        const contents = [];

        if (this.state[HM_AccountGroupSelector.id]) {
            contents.push(
                <AccountGroupSelector key={HM_AccountGroupSelector.id}
                                      onOkDialog={this.onSetAccountGroup}
                />
            )
        }
        return contents;
    }

    calcTypeTotals = () => {

        const totals = [];

        this.state.paymentReceipts.forEach(item => {

            if (item.showHeader) {

                const index = _.findIndex(totals, total => total.formattedType === item.formattedType);

                if (index < 0) {
                    totals.push({formattedType: item.formattedType, total: item.payment ? item.amount : item.total});
                } else {
                    totals[index].total = totals[index].total += (item.payment ? item.amount : item.total);
                }
            }
        });
        return totals;
    }

    render() {

        if (this.state.selectedAccountGroup !== null && (!this.props.invoiceTransactionsLoaded || !this.props.receiptTransactionsLoaded || !this.props.paymentTransactionsLoaded || !this.props.refundTransactionsLoaded)) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const sortedInvoices = _.orderBy(this.props.invoiceTransactions, ['number'], ['asc']);

        const sortedPaymentReceipts = _.orderBy(this.state.paymentReceipts, ['number'], ['asc']);

        const accountGroupName = this.state.selectedAccountGroup === null ? '-' : this.state.selectedAccountGroup.groupName;

        const gtHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title'
                      style={{marginRight: 15}}>{HM_REP_DAILY_TRANSACTIONS.headerGrandTotals}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                Daily Transactions
                : {`(${dateTemplateVeryShort(this.state.currentDate)}) - ${accountGroupName}`}
            </div>
        </div>;

        const typeTotals = this.calcTypeTotals();

        let key = 1000;

        const grandTotals = [{
            invoiceTotal: this.props.invoiceTransactionsTotal,
            receiptTotal: this.props.receiptTransactionsTotal,
            paymentTotal: this.props.paymentTransactionsTotal,
            refundTotal: this.props.refundTransactionsTotal,
        }];

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <ContextMenu model={this.buildInvoiceContextMenu()}
                                 style={{width: '18em'}}
                                 ref={el => this.cmInvoices = el}/>

                    <ContextMenu model={this.buildPayRecContextMenu()}
                                 style={{width: '18em'}}
                                 ref={el => this.cmPayRecs = el}/>

                    <ContextMenu model={this.buildRefundContextMenu()}
                                 style={{width: '18em'}}
                                 ref={el => this.cmRefund = el}/>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate((e) => this.onDateChange2(false), DIARY_BACK_DAY.icon, DIARY_BACK_DAY.tooltip, key++)}
                            {tb_boilerPlate((e) => this.onDateChange2(true), DIARY_FOR_DAY.icon, DIARY_FOR_DAY.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + HM_REP_DAILY_TRANSACTIONS.message, key++, false)}
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${TextDaysTransactionsExcel} Report`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_DAILY_TRANS), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    {/*Grand Totals*/}

                    <Panel headerTemplate={gtHeader} style={{marginTop: '5px'}}>

                        <DataTable value={grandTotals}
                                   className='p-datatable-gridlines'
                                   style={{marginTop: '5px', fontSize: 'small'}}
                        >
                            <Column body={row => currencyTemplate(row, 'invoiceTotal')}
                                    header={GRAND_TOTAL_COLS[0]}
                                    style={{width: '33%', textAlign: 'right'}}/>
                            <Column body={row => currencyTemplate(row, 'receiptTotal')}
                                    header={GRAND_TOTAL_COLS[1]}
                                    style={{width: '33%', textAlign: 'right'}}/>
                            <Column body={row => currencyTemplate(row, 'paymentTotal')}
                                    header={GRAND_TOTAL_COLS[2]}
                                    style={{width: '33%', textAlign: 'right'}}/>
                            <Column body={row => currencyTemplate(row, 'refundTotal')}
                                    header={GRAND_TOTAL_COLS[3]}
                                    style={{width: '33%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>

                    <Panel header={HM_REP_DAILY_TRANSACTIONS.headerInvoice} style={{marginTop: '5px'}}>

                        <DataTable value={sortedInvoices}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.invoiceRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'firstInvoice', 'invoiceRows')}
                                   first={this.state.firstInvoice}
                                   selection={this.state.selectedItemInvoice}
                                   onSelectionChange={(e) => {
                                       this.onChange({owner: 'selectedItemInvoice', value: e.value});
                                   }}
                                   contextMenuSelection={this.state.selectedItemInvoice}
                                   onContextMenuSelectionChange={(e) => {
                                       this.onChange({owner: 'selectedItemInvoice', value: e.value});
                                   }}
                                   onContextMenu={e => {
                                       this.cmPayRecs.hide(e.originalEvent);
                                       this.cmRefund.hide(e.originalEvent);
                                       this.cmInvoices.show(e.originalEvent);
                                   }}
                                   rowClassName={this.stripeClassName}
                        >
                            <Column body={(row) => this.templateNumberRow(row)}
                                    header={INV_COLS[0]}
                                    style={{width: '15%'}}/>
                            <Column body={(row) => this.templatePatientNameRow(row)}
                                    header={INV_COLS[1]}
                                    style={{width: '40%'}}/>
                            <Column body={(row) => this.templateUsernameRow(row)}
                                    header={INV_COLS[2]}
                                    style={{width: '10%'}}/>
                            <Column body={(row) => this.templateCodeRow(row)}
                                    header={INV_COLS[3]}
                                    style={{width: '25%'}}/>
                            <Column body={(row) => this.templateTotalRow(row)}
                                    header={INV_COLS[5]}
                                    style={{width: '10%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>

                    <Panel header={HM_REP_DAILY_TRANSACTIONS.headerRecPay} style={{marginTop: '5px'}}>

                        <DataTable value={sortedPaymentReceipts}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.receiptRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'firstReceipt', 'receiptRows')}
                                   first={this.state.firstReceipt}
                                   selection={this.state.selectedItemReceipt}
                                   onSelectionChange={(e) => {
                                       this.onChange({owner: 'selectedItemReceipt', value: e.value});
                                   }}
                                   contextMenuSelection={this.state.selectedItemReceipt}
                                   onContextMenuSelectionChange={(e) => {
                                       this.onChange({owner: 'selectedItemReceipt', value: e.value});
                                   }}
                                   onContextMenu={e => {
                                       this.cmInvoices.hide(e.originalEvent);
                                       this.cmRefund.hide(e.originalEvent);
                                       this.cmPayRecs.show(e.originalEvent);
                                   }}
                                   rowClassName={this.stripeClassName}
                        >
                            <Column body={(row) => this.templateNumberRow(row)}
                                    header={REC_PAY_COLS[0]}
                                    style={{width: '15%'}}/>
                            <Column field="formattedType"
                                    header={REC_PAY_COLS[1]}
                                    style={{width: '10%'}}/>
                            <Column body={(row) => this.templatePatientNameRow(row)}
                                    header={REC_PAY_COLS[2]}
                                    style={{width: '30%'}}/>
                            <Column field="username"
                                    header={REC_PAY_COLS[3]}
                                    style={{width: '10%'}}/>
                            <Column body={(row) => this.templateCodeRow(row)}
                                    header={REC_PAY_COLS[4]}
                                    style={{width: '25%'}}/>
                            <Column body={(row) => this.templateTotalRow(row)}
                                    header={REC_PAY_COLS[6]}
                                    style={{width: '10%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>

                    <Panel header={`${HM_REP_DAILY_TRANSACTIONS.headerRecPay} Totals (By Type)`}
                           style={{marginTop: '5px'}}>

                        {/*Receipt Totals*/}

                        <DataTable value={typeTotals}
                                   className='p-datatable-gridlines'
                                   style={{marginTop: '5px', fontSize: 'small'}}
                        >
                            <Column field="formattedType"
                                    header={TOTAL_COLS[0]}
                                    style={{width: '60%'}}/>
                            <Column body={(row) => currencyTemplate(row, 'total')}
                                    header={TOTAL_COLS[1]}
                                    style={{width: '40%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>

                    <Panel header={HM_REP_DAILY_TRANSACTIONS.headerRefund} style={{marginTop: '5px'}}>

                        <DataTable value={this.props.refundTransactions}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.refundRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'firstRefund', 'refundRows')}
                                   first={this.state.firstRefund}
                                   selection={this.state.selectedItemRefund}
                                   onSelectionChange={(e) => {
                                       this.onChange({owner: 'selectedItemRefund', value: e.value});
                                   }}
                                   contextMenuSelection={this.state.selectedItemRefund}
                                   onContextMenuSelectionChange={(e) => {
                                       this.onChange({owner: 'selectedItemRefund', value: e.value});
                                   }}
                                   onContextMenu={e => {
                                       this.cmInvoices.hide(e.originalEvent);
                                       this.cmPayRecs.hide(e.originalEvent);
                                       this.cmRefund.show(e.originalEvent);
                                   }}
                                   rowClassName={this.stripeClassName}
                        >
                            <Column field='number'
                                    header={REF_COLS[0]}
                                    style={{width: '15%'}}/>
                            <Column field="patientName"
                                    header={REF_COLS[1]}
                                    style={{width: '65%'}}/>
                            <Column body={(row) => currencyTemplate(row, 'amount')}
                                    header={REF_COLS[2]}
                                    style={{width: '20%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_DAILY_TRANSACTIONS.CLEAR);
    }
}

const mapStateToProps = (state) => {

    const currentState = state.stateManagement[SM_REPORTS_DAILY_TRANSACTIONS.id];
    const patientDataLoadedId = currentState && currentState.data.selectedPatientId ? `${SM_patientDataShort.loaded}_${currentState.data.selectedPatientId}` : null;
    const patientDataLoaded = patientDataLoadedId ? state.patients[patientDataLoadedId] : false;
    const patientDataId = currentState && currentState.data.selectedPatientId ? `${SM_patientDataShort.id}_${currentState.data.selectedPatientId}` : null;

    return {
        message: state.stateManagement.message,

        invoiceTransactionsLoaded: state.reports.invoiceTransactionsDailyLoaded,
        invoiceTransactions: state.reports.invoiceTransactionsDaily,
        invoiceTransactionsTotal: state.reports.invoiceTransactionsDailyTotal,

        receiptTransactionsLoaded: state.reports.receiptTransactionsDailyLoaded,
        receiptTransactions: state.reports.receiptTransactionsDaily,
        receiptTransactionsTotal: state.reports.receiptTransactionsDailyTotal,

        paymentTransactionsLoaded: state.reports.paymentTransactionsDailyLoaded,
        paymentTransactions: state.reports.paymentTransactionsDaily,
        paymentTransactionsTotal: state.reports.paymentTransactionsDailyTotal,

        refundTransactionsLoaded: state.reports.refundTransactionsDailyLoaded,
        refundTransactions: state.reports.refundTransactionsDaily,
        refundTransactionsTotal: state.reports.refundTransactionsDailyTotal,

        PDFLoaded: state.reports.dailyTransactionsPDFLoaded,
        pdfBuffer: state.reports.dailyTransactionsPDF,

        patientDataShortLoaded: patientDataLoaded,
        patientDataShort: state.patients[patientDataId],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState,
    }
};

const mapDispatchToProps = dispatch => {
    return {

        getInvoicesReport: (params) => dispatch(getReport(RES_REPORT_TRANS_INVOICES.GET_DAYS, params)),
        getReceiptsReport: (params) => dispatch(getReport(RES_REPORT_TRANS_RECEIPTS.GET_DAYS, params)),
        getPaymentsReport: (params) => dispatch(getReport(RES_REPORT_TRANS_PAYMENTS.GET_DAYS, params)),
        getRefundsReport: (params) => dispatch(getReport(RES_REPORT_TRANS_REFUNDS.GET_DAYS, params)),
        getPatientDetails: (patientId) => dispatch(getPatResource(RES_PATIENT_DETAILS_SHORT.GET, patientId)),

        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_DAILY_TRANSACTIONS.PDF, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const DailyTransactions = connect(mapStateToProps, mapDispatchToProps)(ConnectedDailyTransactions);

export default DailyTransactions;
