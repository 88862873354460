import React, {Component} from 'react';

import * as TC from '../Model/Constants';

import {Filling} from "./Filling";

import {ToothSurface} from "./ToothSurface";
import {getRandomKey} from "../Utils/general";
import {Caries} from "./Caries";
import {TempFilling} from "./TempFilling";
import {Inlay} from "./Inlay";

export class AdultTop extends Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.onClick = this.onClick.bind(this);
        this.onSurfaceClick = this.onSurfaceClick.bind(this);
    }

    onClick(data) {
        this.props.onClick(data, this);
    }

    onSurfaceClick(tooth, surface) {

        if (tooth.currentCommand !== null) {
            this.props.mouth.setSelectedTooth(tooth, surface);
        }
    }

    render() {

        return (
            <g key={getRandomKey()}>
                {this.props.data.surfaces.map((surface, index) => {
                    return <ToothSurface cm={this.props.cm}
                                         key={getRandomKey()}
                                         mouth={this.props.mouth}
                                         tooth={this.props.data}
                                         data={surface}
                                         parent={this.props.parent}
                                         onClick={this.onClick}
                                         onSurfaceClick={this.onSurfaceClick}
                                         cursor={this.props.cursor}
                    />
                })}
                {this.props.data.charting.map(chartingItem => {

                    switch (chartingItem.item.code) {
                        case TC.Caries:
                            return <Caries cm={this.props.cm}
                                           key={getRandomKey()}
                                           tooth={this.props.data}
                                           data={chartingItem}
                                           parent={this.props.parent}
                                           onClick={this.onClick}
                            />;
                        case TC.Filling:
                            return <Filling cm={this.props.cm}
                                            key={getRandomKey()}
                                            tooth={this.props.data}
                                            data={chartingItem}
                                            parent={this.props.parent}
                                            onClick={this.onClick}
                            />;
                        case TC.TemporaryFilling:
                            return <TempFilling cm={this.props.cm}
                                                key={getRandomKey()}
                                                tooth={this.props.data}
                                                data={chartingItem}
                                                parent={this.props.parent}
                                                onClick={this.onClick}
                            />;
                        case TC.Inlay:
                            return <Inlay cm={this.props.cm}
                                                key={getRandomKey()}
                                                tooth={this.props.data}
                                                data={chartingItem}
                                                parent={this.props.parent}
                                                onClick={this.onClick}
                            />;
                        default :
                            return null;
                    }
                })}
            </g>
        )
    }
}
