import React from 'react';

import {Checkbox} from 'primereact/components/checkbox/Checkbox';

const teethIndexes = [8, 7, 6, 5, 4, 3, 2, 1, 1, 2, 3, 4, 5, 6, 7, 8];

const label = (props, index, label) => {
    if (props.span[index].active) {
        return <label style={{color: 'white', backgroundColor: '#ffc5d4'}}>{label}</label>
    } else {
        return <label style={{color: 'black', backgroundColor: 'white'}}>{label}</label>
    }
};

const tooth = (props, index) => {
    if (props.span[index].active) {
        return (

            <Checkbox onChange={(e) => {
                props.onChange(index, e.checked)
            }}
                      checked={props.span[index].state}
                      style={{backgroundColor: '#ffc5d4'}}
            />
        )
    } else {
        return (
            <Checkbox onChange={(e) => {
                props.onChange(index, e.checked)
            }}
                      checked={false}
                      disabled
            />
        )
    }
};

const toothRow = (props) => {

    const rows = [];

    for (let index = 0; index < 16; index++) {
        rows.push(
            <div key={index}
                 style={{marginLeft: '4px', marginRight: '4px'}}
            >
                {label(props, index, teethIndexes[index])}
                {tooth(props, index)}
            </div>
        )
    }
    return rows;
};

const theWorks = (props) => {

    return (
        <div id="tooth-row">
            {toothRow(props)}
        </div>
    )
};

export const BridgeToothSelector = (props) => {

    return (
        <div>
            {theWorks(props)}
        </div>
    );
};
