import React from 'react';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from "primereact/panel";
import {shorterDateTemplate} from "../../../PatientDynamicItems/Utils";
import {
    claimReferenceTemplate,
    EDI_ST_Error,
    EDI_ST_ICError,
    expectedTemplate2,
    formTemplate,
    unitsTemplate
} from "../../NHSManagaement/Constants";
import _ from "lodash";
import moment from "moment";
import {ProgressBar} from "primereact/progressbar";
import ClaimsComponent from "../../NHSManagaement/ClaimsComponent";
import {getNHSManagementIds} from "../../NHSManagaement/Utils";
import {SM_NHS_MANAGEMENT_STACKED} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {
    getResource,
    markClaimAsCancelled,
    RES_getProviderPerformers,
    RES_NHS_MANAGEMENT_CLAIM,
    RES_NHS_MANAGEMENT_CLAIMS,
    resubmitClaim,
    saveClaim
} from "../../../../actions/nhsManagement";

class ConnectedNHSClaimsHistory extends ClaimsComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedClaim: null,

            allowSubmit: false,
        }
        this.cm = null;
    }

    componentDidMount() {

        if (!this.props.providerPerformersLoaded) {
            this.props.getProviderPerformers();
        }
    }

    providerTemplate = (props, row) => {

        switch (row.status) {
            case EDI_ST_ICError:
                return row.rejectionReason;
            case EDI_ST_Error:
                return row.responseCodes;
            default:
                const user = _.find(props.users, user => user.id === row.providerId);
                return user === undefined ? '-' : user.fullName;
        }
    };

    onSelection = (e) => {

        const selectedUser = _.find(this.props.providerPerformers, provider => provider.id === e.value.providerId);
        this.setState({selectedUser, selectedClaim: e.value});
    }

    render() {

        if (!this.props.NHSClaimHistoryLoaded && !this.props.providerPerformersLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const NHSClaimHistory = _.orderBy(this.props.NHSClaimHistory, (claim) => {
            return claim.submissionDate === null ? '' : moment(claim.submissionDate).format('YYYYMMDD');
        }, ['asc']);

        const headings = ["Submitted On", "Claim", "Form", "Units", "Expected", "Stage", "Count", "Status", "Provider/Message"];

        const status = this.state.selectedClaim ? this.state.selectedClaim.status : null;

        return (
            <Panel header='NHS Claims' id='nhsClaims'>

                {this.showDialogs()}

                <DataTable value={NHSClaimHistory}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           selection={this.state.selectedClaim}
                           onSelectionChange={this.onSelection}
                           contextMenuSelection={this.state.selectedClaim}
                           onContextMenuSelectionChange={this.onSelection}
                           onContextMenu={e => {
                               this.cm.show(e.originalEvent);
                           }}
                >
                    <Column body={(row) => shorterDateTemplate(row.submissionDate)} header={headings[0]}
                            style={{width: '10%'}}/>
                    <Column field='status' header={headings[7]} style={{width: '5%'}}/>
                    <Column field='count' header={headings[6]} style={{width: '5%', textAlign: 'right'}}/>
                    <Column body={(row) => claimReferenceTemplate(row)} header={headings[1]}
                            style={{width: '5%', textAlign: 'right'}}/>
                    <Column body={(row) => formTemplate(row)} header={headings[2]} style={{width: '15%'}}/>
                    <Column body={(row) => unitsTemplate(row, true)} header={headings[3]}
                            style={{width: '5%', textAlign: 'right'}}/>
                    <Column body={(row) => expectedTemplate2(row, false)} header={headings[4]}
                            style={{width: '5%', textAlign: 'right'}}/>
                    <Column field='journeyCode' header={headings[5]} style={{width: '10%'}}/>
                    <Column body={(row) => this.providerTemplate(this.props, row)} field='' header={headings[8]}
                            style={{width: '20%'}}/>
                </DataTable>
            </Panel>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        providerPerformersLoaded,
        providerPerformers,
    } = getNHSManagementIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        providerPerformersLoaded,
        providerPerformers,

        currentState: state.stateManagement[SM_NHS_MANAGEMENT_STACKED.id],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getProviderPerformers: () => dispatch(getResource(RES_getProviderPerformers.GET, {})),

        saveClaim: (details) => dispatch(saveClaim(RES_NHS_MANAGEMENT_CLAIM.SAVE, details)),
        resubmitClaim: (details) => dispatch(resubmitClaim(RES_NHS_MANAGEMENT_CLAIMS.RESUBMIT, details)),
        markClaimAsCancelled: (details) => dispatch(markClaimAsCancelled(RES_NHS_MANAGEMENT_CLAIM.CANCEL, details)),
    }
};

const NHSClaimsHistory = connect(MapStateToProps, MapDispatchToProps)(ConnectedNHSClaimsHistory);

export default NHSClaimsHistory;
