import React from 'react';
import {Dialog} from "primereact/components/dialog/Dialog";
import {
    HM_WORK_LAB_NOTIFIED,
    HM_WORK_NOTIFY_LAB,
    HM_WORK_REQUIRED_APPROVED, HM_WORK_REQUIRED_PICKUP,
    HM_WORK_REQUIRED_RECEIVED,
    SU_STATUS_ACTIVE,
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import {Button} from "primereact/components/button/Button";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {Calendar} from "primereact/components/calendar/Calendar";
import {Dropdown} from "primereact/dropdown";
import _ from "lodash";

const bySection = (props, byHeader, by, users, byIdOwner) => {

    return (
        <React.Fragment>
            <div className="p-fluid p-col-2">
                <label>{byHeader}</label>
            </div>
            <div className="p-fluid p-col-4">
                <Dropdown key='receivedBy'
                          value={by}
                          options={users}
                          optionLabel='fullName'
                          onChange={(e) => {
                              props.onChange({owner: byIdOwner, value: e.value.id})
                          }}
                />
            </div>
        </React.Fragment>
    )
}

const includeBarCode = (_props) => {

    return (
        <React.Fragment>
            <div className="p-fluid p-col-2">
                <label>Barcode</label>
            </div>
            <div className="p-fluid p-col-4">
                {inputText(_props, 'barcode', 'Barcode', false, false)}
            </div>
        </React.Fragment>
    )
}

export const ReceiveWorkRequired = (props) => {

    const labEntry = {...props.labEntry};

    let okDisabled;

    switch (props.target.id) {
        case HM_WORK_NOTIFY_LAB.id:
            okDisabled = labEntry.labNotifiedById === null;
            labEntry.labNotifiedOn = props.labEntry.labNotifiedOn ? props.labEntry.labNotifiedOn : new Date();
            break;
        case HM_WORK_REQUIRED_PICKUP.id:
            okDisabled = false;
            labEntry.sentOn = props.labEntry.sentOn ? props.labEntry.sentOn : new Date();
            break;
        case HM_WORK_REQUIRED_RECEIVED.id:
            okDisabled = labEntry.receivedById === null;
            labEntry.receivedOn = props.labEntry.receivedOn ? props.labEntry.receivedOn : new Date();
            break;
        case HM_WORK_REQUIRED_APPROVED.id:
            okDisabled = labEntry.approvedById === null;
            labEntry.approvedOn = props.labEntry.approvedOn ? props.labEntry.approvedOn : new Date();
            break;
        default:
            okDisabled = true;
            break;
    }

    const footer = (
        <div>
            <Button label="OK"
                    icon={ICON_OK}
                    onClick={() => {
                        props.markWork(labEntry);
                    }}
                    disabled={okDisabled}
            />
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(props.target.id)
                    }}/>
        </div>
    );

    const _props = {
        onChange: props.onChange,
        target: 'labEntry',
        labEntry: props.labEntry,
    };

    const users = _.filter(props.users, user => user.status === SU_STATUS_ACTIVE && user.username !== 'superuser' && user.username !== 'TED_superuser');

    const sentOn = props.labEntry.sentOn ? new Date(props.labEntry.sentOn) : new Date();

    const receivedOn = props.labEntry.receivedOn ? new Date(props.labEntry.receivedOn) : new Date();
    const receivedById = props.labEntry.receivedById ? props.labEntry.receivedById : props.loginIdentity.id;
    const receiver = _.find(users, user => receivedById === user.id);

    const approvedOn = props.labEntry.approvedOn ? new Date(props.labEntry.approvedOn) : new Date();
    const approvedById = props.labEntry.approvedById ? props.labEntry.approvedById : props.loginIdentity.id;
    const approver = _.find(users, user => approvedById === user.id);

    const labNotifiedOn = props.labEntry.labNotifiedOn ? new Date(props.labEntry.labNotifiedOn) : new Date();
    const labNotifiedById = props.labEntry.labNotifiedById ? props.labEntry.labNotifiedById : props.loginIdentity.id;
    const notifier = _.find(users, user => labNotifiedById === user.id);

    let header = '';
    let dateOwner = '';
    let date = null;
    let byIdOwner = '';
    let by = null;
    let dateHeader = ''
    let byHeader = ''

    switch (props.target.id) {
        case HM_WORK_NOTIFY_LAB.id:
            header = HM_WORK_NOTIFY_LAB.header;
            dateOwner = 'labEntry.labNotifiedOn';
            byIdOwner = `labEntry.labNotifiedById`;
            date = labNotifiedOn;
            by = notifier;
            dateHeader = 'Notified On';
            byHeader = 'Notified By';
            break;
        case HM_WORK_REQUIRED_PICKUP.id:
            header = HM_WORK_REQUIRED_PICKUP.header;
            dateOwner = 'labEntry.sentOn';
            date = sentOn;
            dateHeader = 'Picked Up On';
            break;
        case HM_WORK_REQUIRED_RECEIVED.id:
            header = HM_WORK_REQUIRED_RECEIVED.header;
            dateOwner = 'labEntry.receivedOn';
            byIdOwner = `labEntry.receivedById`;
            date = receivedOn;
            by = receiver;
            dateHeader = 'Return Date';
            byHeader = 'Received By';
            break;
        case HM_WORK_REQUIRED_APPROVED.id:
            header = HM_WORK_REQUIRED_APPROVED.header;
            dateOwner = 'labEntry.approvedOn';
            byIdOwner = `labEntry.approvedById`;
            date = approvedOn;
            by = approver;
            dateHeader = 'Approval Date';
            byHeader = 'Approved By';
            break;
        default:
            header = '';
    }

    return (
        <Dialog header={header}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(props.target.id)
                }}
        >
            <div className="p-grid p-fluid">
                <div className="p-fluid p-col-2">
                    <label>{dateHeader}</label>
                </div>
                <div className="p-fluid p-col-4">
                    <Calendar value={date}
                              dateFormat="dd/mm/yy"
                              onChange={(e) => props.onChange({
                                  owner: dateOwner,
                                  value: e.value
                              })}
                              readOnlyInput={true}/>
                </div>

                {props.target.id !== HM_WORK_REQUIRED_PICKUP.id && bySection(props, byHeader, by, users, byIdOwner)}
                {props.target.id === HM_WORK_REQUIRED_PICKUP.id && <div className="p-fluid p-col-6"/>}
                {props.target.id === HM_WORK_NOTIFY_LAB.id && includeBarCode(_props)}

                <div className="p-fluid p-col-3">
                    <label>Note</label>
                </div>

                <div className="p-fluid p-col-12">
                    {inputTextArea(_props, 'returnNote', 5, 100, false, false)}
                </div>
            </div>
        </Dialog>
    )
}

