import * as Actions from "../../../actions";
import {ac} from "../../../index";
import {RES_PATIENT_QUESTIONNAIRE} from "../../../actions/personal";

const baseURL = '/Diary/MenuActions';

export const RES_AppointmentActions = {
    MOVE: {url: `${baseURL}/MoveAppointment`},
    DELETE: {url: `${baseURL}/DeleteAppointment`},
    DELETE_APP: {url: `${baseURL}/DeleteAppointment`},
    DELETE_EVT: {url: `${baseURL}/DeleteEvent`},
    ATTEND: {url: `${baseURL}/Attended`},
    CONFIRM: {url: `${baseURL}/Confirmed`},
    CANCEL: {url: `${baseURL}/CancelAppointment`},
    WORK_REQ: {url: `${baseURL}/workRequired`},
    UPD_COM_APP: {url: `${baseURL}/updateAppComment`},
    UPD_COM_EVT: {url: `${baseURL}/updateEventComment`},
};

export const doMoveAppointmentToPalette = (selectedEvent, notifyUser) => {

    const moveAppointmentURL = `${RES_AppointmentActions.MOVE.url}?mcId=${ac.getMcId()}&appId=${selectedEvent.id}&movedById=${selectedEvent.userId}&notifyUser=${notifyUser}`;

    ac.fetchClient().post(moveAppointmentURL)
        .then(res => {
        })
};

export const doDeleteAppointment = (selectedEvent) => {

    const deleteAppointmentURL = `${RES_AppointmentActions.DELETE_APP.url}?mcId=${ac.getMcId()}&appId=${selectedEvent.id}&deletedById=${selectedEvent.userId}`;

    ac.fetchClient().post(deleteAppointmentURL)
        .then(res => {
        })
};

export const doCancelAppointment = (reason) => {

    ac.fetchClient().post(`${RES_AppointmentActions.CANCEL.url}`, reason)
        .then(res => {
        })
};

export const doDeleteEvent = (selectedEvent) => {

    const deleteEventURL = `${RES_AppointmentActions.DELETE_EVT.url}?mcId=${ac.getMcId()}&eventId=${selectedEvent.id}&deletedById=${selectedEvent.userId}`;

    ac.fetchClient().post(deleteEventURL)
        .then(res => {
        })
};

export const doAttendance = ({id, attended}) => {

    ac.fetchClient().get(`${RES_AppointmentActions.ATTEND.url}`, {params : {mcId: ac.getMcId(), attended, id}})
        .then(res => {
        })
};

export const doConfirmed = ({id}, userId) => {

    ac.fetchClient().get(`${RES_AppointmentActions.CONFIRM.url}`, {params : {mcId: ac.getMcId(), id, userId}})
        .then(res => {
        })
};

export const doWorkRequired = ({id, workRequired}) => {

    ac.fetchClient().post(`${RES_AppointmentActions.WORK_REQ.url}`, null, {params: {mcId: ac.getMcId(), id, workRequired: !workRequired}})
        .then(res => {
        })
};

export const updateAppComment = (id, summary, comment, typeId) => {

    return (dispatch) => {

        ac.fetchClient().post(`${RES_AppointmentActions.UPD_COM_APP.url}`, null, {params: {mcId: ac.getMcId(), id, summary, comment, typeId}})
            .then(res => {
                dispatch({type: Actions.RECEIVE_UPDATE_APP_COMMENT, payload: res.data})
            });
    }
};

export const updateEventComment = (id, comment) => {

    return (dispatch) => {

        ac.fetchClient().post(`${RES_AppointmentActions.UPD_COM_EVT.url}`, null, {params: {mcId: ac.getMcId(), id, comment}})
            .then(res => {
                dispatch({type: Actions.RECEIVE_UPDATE_APP_COMMENT, payload: res.data})
            });
    }
};

export const queryQuestionnaire = (params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${RES_PATIENT_QUESTIONNAIRE.QUERY.url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {
                dispatch({type: RES_PATIENT_QUESTIONNAIRE.QUERY.receive, payload: res.data})
            });
        dispatch({type: RES_PATIENT_QUESTIONNAIRE.QUERY.request, payload: null})
    }
};
