import _ from 'lodash';
import * as Actions from "../actions";
import {SM_EMAIL_MAIL_SHOT, SM_EMAIL_RECIPIENTS, SM_SMS_MAIL_SHOT, SM_SMS_RECIPIENTS} from "../actions/stateManagement";
import {MSOperators} from "../Constants";

const initialState = {

    emsShotsLoaded: false,
    emsShots: [],

    smsShotsLoaded: false,
    smsShots: [],
};

export const mailShot = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            // Email Related

            case Actions.REQUEST_EMS_HISTORY:
                return ({
                    ...state,
                    emsShotsLoaded: false,
                    emsShots: []
                })

            case Actions.RECEIVE_EMS_HISTORY:
                return ({
                    ...state,
                    emsShotsLoaded: true,
                    emsShots: action.payload,
                })

            case Actions.REQUEST_EMS: {

                const emsShotLoadedId = `${SM_EMAIL_MAIL_SHOT.loaded}_${action.mspId}`;
                const emsShotDataId = `${SM_EMAIL_MAIL_SHOT.id}_${action.mspId}`;

                return ({
                    ...state,
                    [emsShotLoadedId]: false,
                    [emsShotDataId]: null
                })
            }
            case Actions.RECEIVE_EMS: {

                const emsShot = {...action.payload};
                emsShot.operators = _.map(emsShot.operators, (emsOpData) =>
                    _.find(MSOperators, msOpData => msOpData.ordinal === emsOpData).value
                );

                emsShot.dateOfBirthOne = new Date(emsShot.dateOfBirthOne);
                emsShot.dateOfBirthTwo = new Date(emsShot.dateOfBirthOne);

                emsShot.nextAppointmentOne = new Date(emsShot.nextAppointmentOne);
                emsShot.nextAppointmentTwo = new Date(emsShot.nextAppointmentTwo);

                emsShot.registeredOne = new Date(emsShot.registeredOne);
                emsShot.registeredTwo = new Date(emsShot.registeredTwo);

                emsShot.patientJourneyOne = new Date(emsShot.patientJourneyOne);
                emsShot.patientJourneyTwo = new Date(emsShot.patientJourneyTwo);

                const emsShotLoadedId = `${SM_EMAIL_MAIL_SHOT.loaded}_${action.mspId}`;
                const emsShotDataId = `${SM_EMAIL_MAIL_SHOT.id}_${action.mspId}`;

                return ({
                    ...state,
                    [emsShotLoadedId]: true,
                    [emsShotDataId]: emsShot,
                })
            }

            case Actions.REQUEST_EMS_RECIPIENTS: {

                const {id} = action.emsShot;
                const recipientsLoadedId = `${SM_EMAIL_RECIPIENTS.loaded}_${id}`;
                const recipientsDataId = `${SM_EMAIL_RECIPIENTS.id}_${id}`;

                return ({
                    ...state,
                    [recipientsLoadedId]: false,
                    [recipientsDataId]: null
                })
            }
            case Actions.RECEIVE_EMS_RECIPIENTS: {

                const {id} = action.emsShot;
                const recipientsLoadedId = `${SM_EMAIL_RECIPIENTS.loaded}_${id}`;
                const recipientsDataId = `${SM_EMAIL_RECIPIENTS.id}_${id}`;

                return ({
                    ...state,
                    [recipientsLoadedId]: true,
                    [recipientsDataId]: action.payload
                })
            }

            case Actions.CLEAR_EMS:

                const emailShotState = {...state};

                emailShotState.emsShots.forEach((emsShot) => {
                    delete emailShotState[`${SM_EMAIL_MAIL_SHOT.id}_${emsShot.id}`];
                    delete emailShotState[`${SM_EMAIL_MAIL_SHOT.loaded}_${emsShot.id}`];
                })
                emailShotState.emsShotsLoaded = false;
                emailShotState.emsShots = [];
                return emailShotState;

            // SMS Related

            case Actions.REQUEST_SMS_HISTORY:
                return ({
                    ...state,
                    smsShotsLoaded: false,
                    smsShots: []
                })

            case Actions.RECEIVE_SMS_HISTORY:
                return ({
                    ...state,
                    smsShotsLoaded: true,
                    smsShots: action.payload,
                })

            case Actions.REQUEST_SMS: {

                const smsShotLoadedId = `${SM_SMS_MAIL_SHOT.loaded}_${action.mspId}`;
                const smsShotDataId = `${SM_SMS_MAIL_SHOT.id}_${action.mspId}`;

                return ({
                    ...state,
                    [smsShotLoadedId]: false,
                    [smsShotDataId]: null
                })
            }
            case Actions.RECEIVE_SMS: {

                const smsShot = {...action.payload};
                smsShot.operators = _.map(smsShot.operators, (smsOpData) =>
                    _.find(MSOperators, msOpData => msOpData.ordinal === smsOpData).value
                );

                smsShot.dateOfBirthOne = new Date(smsShot.dateOfBirthOne);
                smsShot.dateOfBirthTwo = new Date(smsShot.dateOfBirthOne);

                smsShot.nextAppointmentOne = new Date(smsShot.nextAppointmentOne);
                smsShot.nextAppointmentTwo = new Date(smsShot.nextAppointmentTwo);

                smsShot.registeredOne = new Date(smsShot.registeredOne);
                smsShot.registeredTwo = new Date(smsShot.registeredTwo);

                smsShot.patientJourneyOne = new Date(smsShot.patientJourneyOne);
                smsShot.patientJourneyTwo = new Date(smsShot.patientJourneyTwo);

                const smsShotLoadedId = `${SM_SMS_MAIL_SHOT.loaded}_${action.mspId}`;
                const smsShotDataId = `${SM_SMS_MAIL_SHOT.id}_${action.mspId}`;

                return ({
                    ...state,
                    [smsShotLoadedId]: true,
                    [smsShotDataId]: smsShot,
                })
            }

            case Actions.REQUEST_SMS_RECIPIENTS: {

                const {id} = action.smsShot;
                const recipientsLoadedId = `${SM_SMS_RECIPIENTS.loaded}_${id}`;
                const recipientsDataId = `${SM_SMS_RECIPIENTS.id}_${id}`;

                return ({
                    ...state,
                    [recipientsLoadedId]: false,
                    [recipientsDataId]: null
                })
            }
            case Actions.RECEIVE_SMS_RECIPIENTS: {

                const {id} = action.smsShot;
                const recipientsLoadedId = `${SM_SMS_RECIPIENTS.loaded}_${id}`;
                const recipientsDataId = `${SM_SMS_RECIPIENTS.id}_${id}`;

                return ({
                    ...state,
                    [recipientsLoadedId]: true,
                    [recipientsDataId]: action.payload
                })
            }

            case Actions.CLEAR_SMS:

                const smsShotState = {...state};

                smsShotState.emsShots.forEach((emsShot) => {
                    delete smsShotState[`${SM_SMS_MAIL_SHOT.id}_${emsShot.id}`];
                    delete smsShotState[`${SM_SMS_MAIL_SHOT.loaded}_${emsShot.id}`];
                })
                smsShotState.smsShotsLoaded = false;
                smsShotState.smsShots = [];
                return smsShotState;
            default :
                return state;
        }
    } catch (error) {
        console.log(error);
        return state;
    }
}