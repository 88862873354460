import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {DragDropContext} from 'react-beautiful-dnd'

import {Accordion, AccordionTab} from 'primereact/components/accordion/Accordion';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import Mouth from "./Mouth";
import MouthErrorBoundary from '../FixedItems/AppointmentDetails/ErrorBoundaries/MouthErrorBoundary'
import {BasePalette, TreatmentPalette} from "./Palettes/BasePalette"
import {MOUTH_HEIGHT, MOUTH_WIDTH, MouthBaseBGColor, MouthBGColor} from "./Model/Constants";
import BaseChartEntries from "../FixedItems/AppointmentDetails/Sections/BaseChartEntries";
import {ChartingComponent} from "../FixedItems/ChartingComponent";
import AppointmentErrorBoundary, {addAppointments} from "../FixedItems/AppointmentDetails/Utils";
import {General} from "../FixedItems/TreatmentPlanning/Sections/General";
import AppointmentComment from '../FixedItems/Diary/dialogs/AppointmentComment';
import {
    TB_DELETE_APPOINTMENT,
    TB_EDIT_APPOINTMENT_TITLE,
    TB_GOTO_APPOINTMENT,
    TB_HIDE_TREATMENT_ADD_EDIT,
    TB_ON_EDIT_APPOINTMENT,
    TB_PATIENT_SALES,
    TB_PATIENT_SALES_NHS,
    TB_PLACE_APPOINTMENT,
    TB_SHOW_TREATMENT_ADD,
    TB_SHOW_TREATMENT_EDIT,
    TB_TREATMENT_ADD,
    TB_TREATMENT_COMPLETE,
    TB_TREATMENT_DELETE,
    TB_TREATMENT_EDIT,
    TB_USING_BUTTON
} from "../FixedItems/PatientDetails/Constants";
import {
    APP_CREATED,
    HM_AppointmentComment,
    HM_notImplemented,
    HM_TreatmentPlanNote,
    TREATMENTS_APP
} from "../../Constants";
import {messageBus, setState} from "../../actions/stateManagement";
import {tpStatusDropDownList} from "../FixedItems/fixedItemUtils";
import {ShowMessageDialog} from "../FixedItems/Diary/components/EventComponent";
import moment from "moment";
import {APPOINTMENTS} from "../FixedItems/KPIs/Constants";
import {PALETTE_CHANGE, TOGGLE_BASE_TREATMENT} from "../../actions";
import XrayHistorySection from "../FixedItems/PatientDetails/Sections/XrayHistory";
import {COLOR_ChiralPalette} from "../Client/Constants";
import SedationHistorySection from "../FixedItems/PatientDetails/Sections/SedationHistory";
import OrthoDetailsSection from "../FixedItems/PatientDetails/Sections/OrthoDetails";
import TreatmentPlanNote from "../FixedItems/AppointmentDetails/Dialogs/TreatmentPlanNote";
import {PatientImage} from "../PatientDynamicItems/PatientImage";

class ConnectedCharting extends ChartingComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
            this.usingTBButtons = false;
        } else {
            this.state = {
                stateManagementId: props.stateManagementId,
                baseView: false,
                selectedCategory: null,
                selectedItem: {label: 'Select'},
                currentCommand: null,
                loadedTime: null,
                canSave: {status: false, activeIndex: 0},
                activeIndex: [0],
                generalActiveIndex: 0,
                appActiveIndex: [0],
                showAll: false,
                showCode: false,

                [HM_AppointmentComment.id]: false,
            }
        }
        this.usingTBButtons = false;
        this.appointmentEnd = null;

        this.onChange = props.onChange;

        this.callbacks = {
            [TB_USING_BUTTON.id]: this.onUpdateTBButton,
            [TB_EDIT_APPOINTMENT_TITLE.id]: this.onEditAppointmentTitle,
            [TB_ON_EDIT_APPOINTMENT.id]: props.onUpdateAppointment, // *********************************** this has been changed ******************************************//
            [TB_PLACE_APPOINTMENT.id]: this.props.onPlaceAppointment,

            [TB_SHOW_TREATMENT_ADD.id]: (charted, entry, appointmentId, target) => props.onShowAddEditTreatment(target, charted, entry, appointmentId),
            [TB_SHOW_TREATMENT_EDIT.id]: (charted, entry, appointmentId, target) => props.onShowAddEditTreatment(target, charted, entry, appointmentId),

            [TB_TREATMENT_ADD.id]: props.onAddTreatment,
            [TB_TREATMENT_DELETE.id]: props.onDeleteTreatment,
            [TB_TREATMENT_COMPLETE.id]: props.onComplete,
            [TB_TREATMENT_EDIT.id]: props.onEditTreatment,

            [TB_HIDE_TREATMENT_ADD_EDIT.id]: props.onHideAddEditTreatment,

            [TB_PATIENT_SALES.id]: () => {
                props.toolbarCallbacks[TB_PATIENT_SALES.id](true, this.onUpdateTBButton);
            },
            [TB_PATIENT_SALES_NHS.id]: () => {
                props.toolbarCallbacks[TB_PATIENT_SALES_NHS.id](true, this.onUpdateTBButton);
            },
            [TB_DELETE_APPOINTMENT.id]: props.toolbarCallbacks[TB_DELETE_APPOINTMENT.id],
            [TB_GOTO_APPOINTMENT.id]: this.onGotoAppointment,
        }
    };

    componentDidMount() {

        const appointments = _.filter(this.props.treatmentPlan.appointments, appointment => appointment.status === APP_CREATED);

        const sortedAppointments = _.orderBy(appointments, 'start', (o) => {
            return moment(o.start).format('YYYYMMDD');
        }, ['asc']);

        const selectedAppointment = _.findIndex(sortedAppointments, appointment => appointment.appointmentId === this.props.appointmentId);

        this.setState({appActiveIndex: [selectedAppointment]});
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.appActiveIndex !== this.props.appActiveIndex) {
            this.setState({
                appActiveIndex: this.props.appActiveIndex,
            }, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            })
        }
        if (prevProps.charting !== this.props.charting) {
            this.forceUpdate();
        }
        if (prevProps.treatmentPlan !== this.props.treatmentPlan) {
            this.forceUpdate();
        }
    }

    onGotoAppointment = (appointment) => {

        this.props.sendMessage({type: TB_GOTO_APPOINTMENT.id, payload: appointment})
    }

    onEditAppointmentTitle = (appointment) => {
        this.onShowMenuEntry({item: {target: HM_AppointmentComment.id}});
        this.appointment = appointment;
    }

    onUpdateTBButton = (newState) => {

        this.usingTBButtons = newState;
    }

    handleToggling = () => {

        const baseViewState = !this.state.baseView;

        let baseCategoryGroups = [...this.props.baseCategoryGroups];
        let favCategoryGroups = [...this.props.favCategoryGroups];

        // clear the old selection
        baseCategoryGroups.forEach((category) => {
            category.selected = false;
        });
        favCategoryGroups.forEach((category) => {
            category.selected = false;
        });

        let defaultCategory = {name: '', contents: []};

        if (baseViewState) {
            defaultCategory = baseCategoryGroups.length > 0 ? baseCategoryGroups[0] : defaultCategory;
            defaultCategory.selected = true;
        } else {
            defaultCategory = favCategoryGroups.length > 0 ? favCategoryGroups[0] : defaultCategory;
            defaultCategory.selected = true;
        }
        this.setState({
            baseCategoryGroups,
            favCategoryGroups,
            baseView: baseViewState,
            selectedItem: {label: 'Select'},
            selectedCategory: defaultCategory,
            currentCommand: null,
        }, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
        });
        this.props.onChange({owner: TOGGLE_BASE_TREATMENT, value: null});
    }

    showPalette(selectedCategory) {

        if (this.state.baseView) {

            return (
                <BasePalette resources={this.props.resources}
                             category={this.state.selectedCategory}
                             selectedItem={this.state.selectedItem}
                             onItemSelected={this.handleItemSelected}
                             onItemDeselected={this.handleItemDeselected}
                             showCode={this.state.showCode}
                />
            )
        } else {
            return (
                <TreatmentPalette resources={this.props.resources}
                                  category={selectedCategory}
                                  selectedItem={this.state.selectedItem}
                                  onItemSelected={this.handleItemSelected}
                                  onItemDeselected={this.handleItemDeselected}
                                  onlyCode={this.state.showCode}
                />
            )
        }
    }

    showOptionalOrthoSection = () => {

        const orthoApps = _.findIndex(this.props.treatmentPlan.appointments, appointment => appointment.apType.ortho);

        if (orthoApps !== -1) {
            return (
                    <OrthoDetailsSection treatmentPlan={this.props.treatmentPlan}
                                         categories={this.props.categories}
                                         onChange={this.onChange}
                    />
            );
        }
    }

    showChartingSection = () => {

        let header = '';

        let selectedCategory;
        let sortedCategories;

        if (this.state.baseView) {
            header = 'Patient Charting (Base)';
            sortedCategories = _.sortBy(this.props.baseCategoryGroups, 'name', 'asc');
        } else {
            header = 'Patient Charting (Full)';
            sortedCategories = _.sortBy(this.props.favCategoryGroups, 'name', 'asc');
        }

        selectedCategory = this.state.selectedCategory ? this.state.selectedCategory : sortedCategories[0];

        const bgColour = this.state.baseView ? MouthBaseBGColor : MouthBGColor;

        const toggleCodeText = this.state.showCode ? 'Show Full Description' : 'Show Code';
        const toggleCodeIcon = this.state.showCode ? 'fas fa-expand-alt' : 'fas fa-compress-alt';

        return (
            <Panel headerTemplate={(options) => this.template(options, header)}
                   toggleable
                   collapsed={true}
                   style={{paddingBottom: '2px'}}>
                <div style={{paddingBottom: '5px', display: 'flex', flexFlow: 'row nowrap'}}>
                    <Button id='toggleBaseTreatmentCharting'
                            tooltip='Toggle Base / Treatment charting'
                            tooltipOptions={{position: 'top'}}
                            className="p-button-secondary"
                            icon="fa fa-sync-alt"
                            onClick={this.handleToggling}
                            style={{backgroundColor: bgColour, color: 'black', marginRight: '2px'}}
                    />
                    {!this.state.baseView &&
                        <Button id='toggleShowCode'
                                tooltip={toggleCodeText}
                                tooltipOptions={{position: 'top'}}
                                className="p-button-secondary"
                                icon={toggleCodeIcon}
                                onClick={this.codeToggle}
                                style={{backgroundColor: bgColour, color: 'black', marginRight: '2px'}}
                        />
                    }
                    <Button id='paletteSelectedItem'
                            className="p-button-secondary"
                            label={this.state.selectedItem.label}
                            style={{backgroundColor: COLOR_ChiralPalette, color: 'black', marginRight: '2px'}}
                    />

                    {this.showCategoryButtons(this.props.onChange, sortedCategories, bgColour, this.state.showCode, selectedCategory)}

                </div>
                <div style={{display: 'flex', flexFlow: 'row nowrap'}}>

                    {this.showPalette(selectedCategory)}

                    <MouthErrorBoundary>
                        <Mouth width={MOUTH_WIDTH}
                               height={MOUTH_HEIGHT}
                               baseView={this.state.baseView}
                               resources={this.props.resources}
                               charting={this.props.charting}
                               selectedItem={this.state.selectedItem}
                               currentCommand={this.state.currentCommand}
                               parent={this}
                               loginIdentity={this.props.loginIdentity}
                               addTreatmentHandler={(entry) => this.callbacks[TB_SHOW_TREATMENT_ADD.id](true, entry, this.props.selectedAppointmentId, TB_SHOW_TREATMENT_ADD.id)}
                               addChartEntry={this.props.addChartingEntry}
                               removeChartingEntry={this.props.removeChartingEntry}
                               resetTooth={this.props.resetTooth}
                               onChange={this.props.onChange}
                        />
                    </MouthErrorBoundary>
                </div>
            </Panel>
        )
    }

    showBaseOrTreatmentDetails = (baseView, treatmentPlan, showAddTreatment, showEditTreatment, addFromCharting, currentSelectedItem, currentChartedEntry, payable) => {

        if (baseView) {

            return (
                <Panel header='Base Charting Entry(s)'
                       style={{marginTop: '5px'}}
                >
                    <BaseChartEntries entries={this.props.charting.entries}
                                      treatmentPlan={treatmentPlan}
                                      selectedItem={this.state.selectedItem}
                                      removeChartingEntry={this.props.removeChartingEntry}
                    />
                </Panel>
            )
        } else {

            const {
                providers,
                appointmentTypes,
                accountGroups,
                templateNotes,
                onChange,
                treatmentCodes,
                resources
            } = this.props;

            const selectedAppointmentId = this.props.selectedAppointmentId ? this.props.selectedAppointmentId : this.props.appointmentId;

            return (
                <DragDropContext onDragEnd={this.onDragEnd}>

                    <Accordion multiple={true} activeIndex={this.state.appActiveIndex}
                               onTabChange={(e) => {
                                   if (!this.usingTBButtons)
                                       this.setState({appActiveIndex: e.index});
                               }}
                    >
                        {addAppointments({
                                treatmentPlan,
                                providers,
                                appointmentTypes,
                                accountGroups,
                                templateNotes,
                                onChange,
                                favourites: resources.favourites,
                                treatmentCodes,
                                appointmentId: this.props.appointmentId,
                                treatmentDisplay: APPOINTMENTS,
                                selectedAppointmentId
                            }
                            , this.callbacks, null, true, true, TREATMENTS_APP, showAddTreatment, showEditTreatment, addFromCharting, currentSelectedItem, currentChartedEntry, payable)}
                    </Accordion>
                    <div style={{float: "left", clear: "both"}}
                         ref={(el) => {
                             this.appointmentEnd = el;
                         }}>
                    </div>
                </DragDropContext>
            )
        }
    }

    handleItemSelected = (item) => {

        const command = item.command === undefined ? null : new item.command();

        this.setState({selectedItem: item, currentCommand: command}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            this.props.onChange({owner: PALETTE_CHANGE, value: null});
        });
    }

    handleItemDeselected = () => {

        if (this.state.currentCommand) {
            this.state.currentCommand.clear();

            this.setState({selectedItem: {label: 'Select'}, currentCommand: null}, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onChange({owner: PALETTE_CHANGE, value: null});
            });
        }
    }

    onOkDialog = (content) => {
        this.props.onChange({owner: 'treatmentPlan.content', value: content});
        this.onHideMenuEntry(HM_TreatmentPlanNote.id)
    }

    showDialogs() {

        if (this.state[HM_AppointmentComment.id]) {

            const type = _.find(this.props.appointmentTypes, type => type.id === this.appointment.apType.id);
            const appointment = {
                ...this.appointment,
                type,
                types: this.props.appointmentTypes,
            };

            return (
                <AppointmentComment text={HM_AppointmentComment}
                                    onHideDialog={() => {
                                        this.onUpdateTBButton(false);
                                        this.onHideMenuEntry(HM_AppointmentComment.id)
                                    }}
                                    onOkDialog={(e) => {
                                        this.props.onUpdateAppointment(e);
                                        this.onUpdateTBButton(false);
                                        this.onHideMenuEntry(HM_AppointmentComment.id)
                                    }}
                                    event={null}
                                    appointment={appointment}
                />
            )
        } else if (this.state[HM_TreatmentPlanNote.id]) {
            return (
                <TreatmentPlanNote content={this.props.treatmentPlan.content}
                                   onHideDialog={this.onHideMenuEntry}
                                   onOkDialog={this.onOkDialog}
                                   onChange={this.onChange}
                                   templateNotes={this.props.templateNotes}
                />
            );
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onDragEnd = (event) => {

        if (event.destination) {
            const chargeId = parseInt(event.draggableId.split('_')[1], 10);
            const charge = _.find(this.props.treatmentPlan.unassignedCharges, charge => charge.id === chargeId);

            const sourceAppointmentId = parseInt(event.source.droppableId.split('_')[1], 10);
            const destinationAppointmentId = parseInt(event.destination.droppableId.split('_')[1], 10);

            let sourceAppointment = _.find(this.props.treatmentPlan.appointments, appointment => appointment.appointmentId === sourceAppointmentId);
            sourceAppointment.charges = sourceAppointment.charges.filter(charge => charge.id !== chargeId);

            const destinationAppointment = _.find(this.props.treatmentPlan.appointments, appointment => appointment.appointmentId === destinationAppointmentId);
            charge.appointment = {appointmentId: destinationAppointment.appointmentId};
            destinationAppointment.charges.push(charge);

            const treatmentPlan = {...this.props.treatmentPlan};

            treatmentPlan.appointments = treatmentPlan.appointments.map(appointment => {
                    if (appointment.appointmentId === destinationAppointmentId)
                        return destinationAppointment;
                    else if (appointment.appointmentId === sourceAppointmentId)
                        return sourceAppointment;
                    else
                        return appointment;
                }
            );
            this.props.onChange({owner: 'treatmentPlan', value: treatmentPlan});
        }
    }

    onTreatmentPlanDetailsChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        switch (event.owner) {

            case 'treatmentPlan.title':
            case 'treatmentPlan.content':
            case 'patient.alternateRef':
                _.set(state, event.owner, event.value.substring(0, 255));
                this.setState(state, () => {
                    this.props.onChange(event);
                });
                break;
            case 'showAll' :
                this.setState(state);
                break;
            default:
                this.setState(state, () => {
                    this.props.onChange(event);
                });
                break;
        }
    }

    findCurrentAppointmentInPlan = (appointmentId) => {

        return _.find(this.props.treatmentPlan.appointments, appointment => appointment.appointmentId === appointmentId);
    }

    onGeneralTabChange = (index) => {
        this.setState({generalActiveIndex: index}, () => {
        })
    }

    template = (options, header) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-right';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                </button>
                <span className={titleClassName}>
                    {header}
                </span>
            </div>
        )
    }

    render() {

        this.mapThePalette();

        const tpStatuses = tpStatusDropDownList();

        const {firstName, lastName, knownAs} = this.props.treatmentPlan.patient;
        const generalHeader = `${this.props.treatmentPlan.patient.NHSPatient ? `General (NHS)` : `General`} - ${firstName} ${lastName} (${knownAs})`;

        return (
            <AppointmentErrorBoundary>
                <div className="tp-appointment-list">

                    {this.showDialogs()}

                    <div className="p-grid">
                        <div className="p-lg-2 p-md-2">
                            <PatientImage patient={this.props.treatmentPlan.patient}/>
                        </div>
                        <div className="p-lg-10 p-md-10">

                            <Panel headerTemplate={(options) => this.template(options, generalHeader)}
                                   toggleable
                                   collapsed={true}
                                   style={{paddingBottom: '2px'}}>
                                <General treatmentPlan={this.props.treatmentPlan}
                                         NHSRegistration={this.props.NHSRegistration}
                                         statuses={tpStatuses}
                                         onTreatmentPlanDetailsChange={this.props.onChange}
                                         journeyStages={this.props.journeyStages}
                                         providers={this.props.providers}
                                         showAll={this.state.showAll}
                                         planning={false}
                                         callbacks={this.callbacks}
                                         activeIndex={this.state.generalActiveIndex}
                                         onGeneralTabChange={this.onGeneralTabChange}
                                         onShowMenuEntry={this.onShowMenuEntry}
                                />
                            </Panel>
                            <Panel headerTemplate={(options) => this.template(options, 'Orthodontic Details')}
                                   toggleable
                                   collapsed={true}
                                   style={{paddingBottom: '2px'}}>
                                {this.showOptionalOrthoSection()}
                            </Panel>
                            <Panel headerTemplate={(options) => this.template(options, 'XRay & Sedation History')}
                                   toggleable
                                   collapsed={true}
                                   style={{paddingBottom: '2px'}}>
                                <div style={{display: 'flex'}}>
                                    <div className="items-margin d-flex d-align-top"
                                         style={{paddingLeft: '2px'}}>
                                        <XrayHistorySection
                                            onChange={this.props.onChange}
                                            usersShort={this.props.usersShort}
                                            treatmentPlan={this.props.treatmentPlan}
                                            appointmentId={this.props.appointmentId}
                                        />
                                    </div>
                                    <div className="items-margin d-flex d-align-top"
                                         style={{paddingLeft: '2px'}}>
                                        <SedationHistorySection
                                            onChange={this.props.onChange}
                                            usersShort={this.props.usersShort}
                                            treatmentPlan={this.props.treatmentPlan}
                                            appointmentId={this.props.appointmentId}
                                        />
                                    </div>
                                </div>
                            </Panel>
                            {this.showChartingSection()}
                        </div>
                    </div>
                    <Accordion key={0} multiple={true} activeIndex={this.state.activeIndex} onTabChange={(e) => {
                        this.setState({activeIndex: e.index}, () => {
                            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                        });
                    }}>

                    </Accordion>
                    {
                        this.showBaseOrTreatmentDetails(this.state.baseView,
                            this.props.treatmentPlan,
                            this.props.showAddTreatment,
                            this.props.showEditTreatment,
                            this.props.addFromCharting,
                            this.state.selectedItem,
                            this.props.currentChartedEntry,
                            this.props.payable
                        )}
                </div>
            </AppointmentErrorBoundary>
        )
    };
}

const MapStateToProps = (state, ownProps) => {

        const stateManagementId = `${ownProps.smAppointmentId}_CH`;
        const accountGroups = state.users.accountGroups;
        const providers = state.dropDowns.providers;
        const appointmentTypes = state.housekeeping.appointmentDiaryTypes.appointmentTypes;
        const treatmentCodes = state.housekeeping.treatmentCodes;
        const journeyStages = state.housekeeping.journeyStages;

        return {

            categoriesLoaded: state.oac.categoriesLoaded,
            categories: state.oac.categories.categories,

            loginIdentity: state.login.user,

            accountGroups,
            providers,
            appointmentTypes,
            treatmentCodes,
            journeyStages,

            stateManagementId,
            currentState: state.stateManagement[stateManagementId],
        }
    }
;

const MapDispatchToProps = dispatch => {
        return {

            sendMessage: (message) => dispatch(messageBus(message)),
            setState: (id, data) => dispatch(setState(id, data)),
        }
    }
;

const Charting = connect(MapStateToProps, MapDispatchToProps)(ConnectedCharting);

export default Charting;

