import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {RES_REPORT_WORK_RECEIVED,} from "../../../actions/reports";
import {Calendar} from "primereact/components/calendar/Calendar";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {HM_REP_LAB_WORK_RECEIVED, JSON_DATE_FORMAT, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PRINT, ICON_SCREEN,} from "../../../icons";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {
    HELP_WORK_RECEIVED,
    setState,
    SM_WORK_RECEIVED_FOR_APPROVAL,
    SM_WORK_RECEIVED_RECEIVED,
    SM_WORK_RECEIVED_REPORT,
    SM_WORK_RECEIVED_REPORT_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight} from "../../Utils";
import {DIARY_BACK_WEEK, DIARY_FOR_WEEK} from "../Diary/Constants";
import WorkRequiredPDF from "./PDFs/WorkRequiredPDF";
import {TextLabHistory} from "../../../Messages";
import {TabBaseComponent} from "../../TabBaseComponent";
import * as Sections from "./Constants";
import WorkReceived from "./Sections/WorkReceived";
import WorkReceivedForApproval from "./Sections/WorkReceivedForApproval";
import {getAllUsers} from "../../../actions/users";

export class ConnectedWorkReceived extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                currentDate: moment().add(7, 'days').toDate(),

                stateManagementId: SM_WORK_RECEIVED_REPORT.id,

                canSave: {
                    status: false,
                    activeIndex: 0,
                    source: {
                        id: SM_WORK_RECEIVED_REPORT.id,
                        action: RES_REPORT_WORK_RECEIVED.CLEAR.action,
                    },
                },
                labWorkReceivedLoaded: false,
                labWorkReceived: [],

                labEntryRows: 5,
                firstLabEntry: 0,
                selectedLabEntry: null,

                pdfRequest: PDF_REQUEST_NONE,
            }
        }

        this.onShowReport = this.onShowReport.bind(this);
        this.onPrintReport = this.onPrintReport.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                default:
                    break;
            }
        }
    }

    onDateChange = (e) => {

        this.setState({
            currentDate: e.value,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.setState(SM_WORK_RECEIVED_REPORT, this.state);
        })
    }

    onDateChange2 = (forward) => {

        let date = moment(this.state.currentDate).add(forward ? 1 : -1, 'weeks');
        date = date.toDate();

        this.setState({
            currentDate: date,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.setState(SM_WORK_RECEIVED_REPORT, this.state);
        })
    }

    onShowReport(event) {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const daysDate = this.state.currentDate;
        const formattedDate = moment(daysDate).format(JSON_DATE_FORMAT);

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={SM_WORK_RECEIVED_REPORT_PDF.id} header={SM_WORK_RECEIVED_REPORT_PDF.label}
                              leftIcon={SM_WORK_RECEIVED_REPORT_PDF.tabIcon}>
                        <WorkRequiredPDF onCloseClick={onCloseClick}
                                         onTabUpdate={onTabUpdate}
                                         date={formattedDate}
                                         id={SM_WORK_RECEIVED_REPORT_PDF.id}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport(event) {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_WORK_RECEIVED_REPORT_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    onDownload() {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_WORK_RECEIVED_REPORT_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    render() {

        this.tabStack = [];

        this.tabStack.push(
            {
                key: Sections.SEC_WR_RECEIVED,
                section: Sections.SEC_WR_RECEIVED,
                content: <TabPanel key={Sections.SEC_WR_RECEIVED}
                                   header={SM_WORK_RECEIVED_RECEIVED.label}>
                    <WorkReceived toolbarCallbacks={this.toolbarCallbacks}
                                  onPCButtonClick={this.props.onPCButtonClick}
                                  onCloseClick={this.props.onCloseClick}
                                  currentDate={this.state.currentDate}
                                  usersShort={this.props.usersShort}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                key: Sections.SEC_WR_RECEIVED_FOR_APPROVAL,
                section: Sections.SEC_WR_RECEIVED_FOR_APPROVAL,
                content: <TabPanel key={Sections.SEC_WR_RECEIVED_FOR_APPROVAL}
                                   header={SM_WORK_RECEIVED_FOR_APPROVAL.label}>
                    <WorkReceivedForApproval toolbarCallbacks={this.toolbarCallbacks}
                                             onPCButtonClick={this.props.onPCButtonClick}
                                             onCloseClick={this.props.onCloseClick}
                                             currentDate={this.state.currentDate}
                                             usersShort={this.props.usersShort}
                    />
                </TabPanel>
            }
        );

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div id="detailPanel">

                    <Toolbar
                            left={<React.Fragment>
                                {tb_boilerPlate(() => this.onDateChange2(false), DIARY_BACK_WEEK.icon, DIARY_BACK_WEEK.tooltip, key++)}
                                {tb_boilerPlate(() => this.onDateChange2(true), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, key++)}
                                <Calendar value={this.state.currentDate}
                                          dateFormat="dd/mm/yy"
                                          onChange={this.onDateChange}
                                          readOnlyInput={true}
                                          icon='fas fa-calendar-day'/>
                            </React.Fragment>}
                            right={<React.Fragment>
                                {tb_boilerPlateRight(this.onShowReport, ICON_SCREEN, 'Show ' + HM_REP_LAB_WORK_RECEIVED.message, key++, false)}
                                {tb_boilerPlateRight(this.onPrintReport, ICON_PRINT, 'Print ' + HM_REP_LAB_WORK_RECEIVED.message, key++, false)}
                                {tb_boilerPlateRight(this.onDownload, ICON_DOWNLOAD, `Download ${TextLabHistory} Report`, key++, false)}
                                {tb_boilerPlateRight(() => window.open(HELP_WORK_RECEIVED), ICON_HELP, 'Knowledge Base', key++, true)}
                                {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                            </React.Fragment>}
                    />

                    <TabView scrollable={true}
                             style={{paddingTop: '5px'}}
                             activeIndex={this.state.canSave.activeIndex}
                             onTabChange={(e) => {
                                 this.onTabChange(e.index)
                             }}>

                        {this.tabStack.map(tab => {
                            return tab.content
                        })}

                    </TabView>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_WORK_RECEIVED.CLEAR);
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        PDFLoaded: state.reports.labWorkReceivedPDFLoaded,
        pdfBuffer: state.reports.labWorkReceivedPDF,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_WORK_RECEIVED_REPORT.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const WorkReceivedReport = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkReceived);

export default WorkReceivedReport;
