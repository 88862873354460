import React from 'react';
import {BaseComponent} from "../../../BaseComponent";
import {
    PAY_TYPE_CC,
    PAY_TYPE_CHEQUE,
    PAY_TYPE_DC,
    PD_STATE_RECEIPT_AMOUNT,
    PD_STATE_RECEIPT_CD_CARD,
    PD_STATE_RECEIPT_CHEQUE,
    PD_STATE_RECEIPT_NARRATIVE,
    TB_CHANGE_PAY_TYPE
} from "../Constants";
import _ from "lodash";
import moment from "moment";
import {PaymentAmount} from "./Pages/PaymentAmount";
import {PaymentCheque} from "./Pages/PaymentCheque";
import {PaymentCDCard} from "./Pages/PaymentCDCard";
import {PaymentNarrative} from "./Pages/PaymentNarrative";
import {ACC_TYPE_PAYMENT, JSON_DATE_FORMAT} from "../../../../Constants";
import {connect} from "react-redux";
import {getResource, RES_ACCOUNT_ITEM} from "../../../../actions/personal";
import {ProgressBar} from "primereact/progressbar";

class ConnectedChangePaymentType extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            accountItemLoaded: false,
            dialogState: PD_STATE_RECEIPT_AMOUNT,
            changeItem: {},
            previousState: PD_STATE_RECEIPT_AMOUNT,
            printControl: {printOnComplete: false},
        };

        this.checkForCompletness = this.checkForCompletness.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangePaymentType = this.onChangePaymentType.bind(this);
    }

    componentDidMount() {

        if (this.props.selectedItem.type === ACC_TYPE_PAYMENT) {
            this.props.getPayment({id: this.props.selectedItem.id});
        } else {
            this.props.getReceipt({id: this.props.selectedItem.id});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_ACCOUNT_ITEM.GET_RECEIPT.receive: {
                    const changeItem = {
                        takenBy: {id: this.props.receipt.raisedBy.id},
                        date: this.props.receipt.raisedOn,
                        narrative: this.props.receipt.comment,
                        id: this.props.receipt.id,
                        paymentType: this.props.receipt.paymentType,
                        ccType: this.props.receipt.ccType,
                        dcType: this.props.receipt.dcType,
                        chequeNumber: this.props.receipt.chequeNumber,
                        sortCode: this.props.receipt.sortCode,
                        transactionNumber: this.props.receipt.transactionNumber,
                        isPayment: false,
                    };
                    this.setState({changeItem, accountItemLoaded: true});
                    break;
                }
                case RES_ACCOUNT_ITEM.GET_PAYMENT.receive: {
                    const changeItem = {
                        takenBy: {id: this.props.payment.takenBy.id},
                        date: this.props.payment.date,
                        narrative: this.props.payment.narrative,
                        id: this.props.payment.id,
                        paymentType: this.props.payment.paymentType,
                        ccType: this.props.payment.ccType,
                        dcType: this.props.payment.dcType,
                        chequeNumber: this.props.payment.chequeNumber,
                        sortCode: this.props.payment.sortCode,
                        transactionNumber: this.props.payment.transactionNumber,
                        isPayment: true,
                    };
                    this.setState({changeItem, accountItemLoaded: true});
                    break;
                }
                default:
                    break;
            }
        }
    }

    checkForCompletness() {

        let finishEnabled = false;
        // based on the selected payment type we will determine the wizard completeness
        switch (this.state.changeItem.paymentType) {
            case PAY_TYPE_CHEQUE.value:
                finishEnabled = this.state.changeItem.takenBy.id !== null && this.state.changeItem.sortCode.length === 8;
                break;
            case PAY_TYPE_CC.value:
            case PAY_TYPE_DC.value:
                finishEnabled = this.state.changeItem.takenBy.id !== null;
                break;
            default:
                finishEnabled = this.state.changeItem.takenBy.id !== null;
                break;
        }
        return finishEnabled;
    }

    onChange(event) {

        const state = {...this.state};

        if (event.constructor === Array) {

            event.forEach(item => {
                _.set(state, item.owner, item.value);
            })
        } else if (event.owner === 'payment.paymentType') {
            _.set(state, 'changeItem.paymentType', event.value);
        } else if (event.owner === 'receipt.paymentType') {
            _.set(state, 'changeItem.paymentType', event.value);
        } else if (event.owner === 'payment.sortCode') {
            _.set(state, 'changeItem.sortCode', event.value);
        } else if (event.owner === 'receipt.sortCode') {
            _.set(state, 'changeItem.sortCode', event.value);
        } else if (event.owner === 'payment.chequeNumber') {
            _.set(state, 'changeItem.chequeNumber', event.value);
        } else if (event.owner === 'receipt.chequeNumber') {
            _.set(state, 'changeItem.chequeNumber', event.value);
        } else if (event.owner === 'payment.transactionNumber') {
            _.set(state, 'changeItem.transactionNumber', event.value);
        } else if (event.owner === 'receipt.transactionNumber') {
            _.set(state, 'changeItem.transactionNumber', event.value);
        } else if (event.owner === 'payment.ccType') {
            _.set(state, 'changeItem.ccType', event.value);
        } else if (event.owner === 'receipt.date') {
            _.set(state, 'changeItem.date', event.value);
        } else if (event.owner === 'payment.date') {
            _.set(state, 'changeItem.date', event.value);
        } else if (event.owner === 'receipt.takenBy') {
            _.set(state, 'changeItem.takenBy.id', event.value.id);
        } else if (event.owner === 'payment.takenBy') {
            _.set(state, 'changeItem.takenBy.id', event.value.id);
        } else if (event.owner === 'receipt.ccType') {
            _.set(state, 'changeItem.ccType', event.value);
        } else if (event.owner === 'payment.dcType') {
            _.set(state, 'changeItem.dcType', event.value);
        } else if (event.owner === 'receipt.dcType') {
            _.set(state, 'changeItem.dcType', event.value);
        } else {
            _.set(state, event.owner, event.value);
        }

        this.setState(state);
    }

    onChangePaymentType() {

        const details = {
            isPayment: this.state.changeItem.isPayment,
            id: this.state.changeItem.id,
            paymentType: this.state.changeItem.paymentType,
            narrative: this.state.changeItem.narrative,
            ccType: this.state.changeItem.ccType,
            chequeNumber: this.state.changeItem.chequeNumber,
            sortCode: this.state.changeItem.sortCode,
            transactionNumber: this.state.changeItem.transactionNumber,
            date: moment(this.state.changeItem.date).format(JSON_DATE_FORMAT),
            takenById: this.state.changeItem.takenBy.id,
        };
        this.props.onChangePaymentType(details);
    }

    render() {

        if (!this.state.accountItemLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const finishEnabled = this.checkForCompletness();

        switch (this.state.dialogState) {

            case PD_STATE_RECEIPT_AMOUNT:

                return (
                    <PaymentAmount payment={this.state.changeItem}
                                   identity={TB_CHANGE_PAY_TYPE}
                                   changeType={true}
                                   onChange={this.onChange}
                                   onFinish={this.onChangePaymentType}
                                   onHideDialog={this.props.onHideDialog}
                                   providers={this.props.providers}
                                   accountGroups={this.props.accountGroups}
                                   printControl={this.state.printControl}
                                   finishEnabled={finishEnabled}
                    />
                );

            case PD_STATE_RECEIPT_CHEQUE:

                return (
                    <PaymentCheque payment={this.state.changeItem}
                                   identity={TB_CHANGE_PAY_TYPE}
                                   changeType={true}
                                   onChange={this.onChange}
                                   onFinish={this.onChangePaymentType}
                                   onHideDialog={this.props.onHideDialog}
                                   finishEnabled={finishEnabled}
                    />
                );

            case PD_STATE_RECEIPT_CD_CARD:

                return (
                    <PaymentCDCard payment={this.state.changeItem}
                                   identity={TB_CHANGE_PAY_TYPE}
                                   changeType={true}
                                   onChange={this.onChange}
                                   onFinish={this.onChangePaymentType}
                                   onHideDialog={this.props.onHideDialog}
                                   finishEnabled={finishEnabled}
                    />
                );

            case PD_STATE_RECEIPT_NARRATIVE:

                return (
                    <PaymentNarrative payment={this.state.changeItem}
                                      identity={TB_CHANGE_PAY_TYPE}
                                      changeType={true}
                                      onChange={this.onChange}
                                      onFinish={this.onChangePaymentType}
                                      onHideDialog={this.props.onHideDialog}
                                      previousState={this.state.previousState}
                                      finishEnabled={finishEnabled}
                    />
                );

            default:
                break;
        }
    }
}

const
    MapStateToProps = (state) => {
        return {

            message: state.stateManagement.message,
            receipt: state.patients.receipt,
            payment: state.patients.payment,
        }
    };

const
    MapDispatchToProps = dispatch => {
        return {
            getPayment: (params) => dispatch(getResource(RES_ACCOUNT_ITEM.GET_PAYMENT, params)),
            getReceipt: (params) => dispatch(getResource(RES_ACCOUNT_ITEM.GET_RECEIPT, params)),
        }
    };

const
    ChangePaymentType = connect(MapStateToProps, MapDispatchToProps)(ConnectedChangePaymentType);

export default ChangePaymentType;

