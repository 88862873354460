import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';

import {TB_RECEIPT_NARRATIVE} from "../../../PatientDetails/Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";

export const ReceiptNarrative = (props) => {

    const footer =
        <div>
            <Button label='Back'
                    icon="fa fa-chevron-left"
                    disabled={false}
                    onClick={() => {
                        props.onChange({owner: 'dialogState', value: props.previousState});
                    }}/>
            <Button label='Next'
                    icon="fa fa-chevron-right"
                    disabled={true}
                    onClick={() => {
                    }}/>
            <Button label='Finish'
                    icon={ICON_OK}
                    disabled={!props.finishEnabled}
                    onClick={() => {
                        props.onFinish(props.receipt);
                    }}/>
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.ownerId)}/>
        </div>;

    return (
        <Dialog style={{width: "50%"}}
                header={TB_RECEIPT_NARRATIVE.header}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={e => props.onHideDialog(props.ownerId)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <InputTextarea type="text"
                                   disabled={false}
                                   rows={10}
                                   cols={150}
                                   autoResize={true}
                                   value={props.receipt.comment}
                                   onChange={(e) => {
                                       props.onChange({owner: 'receipt.comment', value: e.target.value})
                                   }}
                    />
                </div>
            </div>
        </Dialog>
    )

};