import React, {Component} from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_CRT} from "../../../../actions/stateManagement";
import {getObjectStoreIds} from "../Utils";
import {RES_PREFERENCES} from "../../../../actions/preferences";
import {checkBox, dropDownPlain} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    ChartingEnabled,
    ChartingScheme,
    DEFAULTSCHEME,
    IncompleteVisualEnabled,
    PerioChartingEnabled,
    SCHEMECATS,
} from "../Constants";
import {getObjectStore, RES_OBJECT_STORE} from "../../../../actions/objectStore";
import {getBoolean, getString} from "../../fixedItemUtils";

const requiredObject = [ChartingEnabled, PerioChartingEnabled, IncompleteVisualEnabled, ChartingScheme];

class ConnectedChartingSystemsSection extends Component {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_CRT.id,

                objectsLoaded: false,
                objects: {},
            }
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getObjectStore(requiredObject);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_OBJECT_STORE.GET.receive:

                    const objects = this.props.objects;

                    this.setState({
                        objects: {
                            ChartingEnabled: getBoolean(objects, ChartingEnabled, true),
                            PerioChartingEnabled: getBoolean(objects, PerioChartingEnabled, false),
                            IncompleteVisualEnabled: getBoolean(objects, IncompleteVisualEnabled, false),
                            ChartingScheme: getString(objects, ChartingScheme, DEFAULTSCHEME),
                        },
                        objectsLoaded: true,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;

                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES.SAVE.action,
                    saveState: false,
                    saveObjects: true
                }
            })
        });
    }

    render() {

        if (!this.state.objectsLoaded) {
            return null;
        }

        const objectProps = {
            onChange: this.onChange,
            target: 'objects',
            objects: this.state.objects,
            schemes: SCHEMECATS,
        };

        return (
            <div className="p-grid p-fluid" style={{fontSize: 'small'}}>
                <Panel header='Charting Systems - Enabled' className="p-col-6"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-4">
                            {checkBox(objectProps, 'ChartingEnabled', 'Base Charting', false, false)}
                        </div>
                        <div className="p-col-4">
                            {checkBox(objectProps, 'PerioChartingEnabled', 'Perio Charting', false, false)}
                        </div>
                        <div className="p-col-4">
                            {checkBox(objectProps, 'IncompleteVisualEnabled', 'Incomplete Visualisation', false, false)}
                        </div>
                    </div>
                </Panel>
                <Panel header='Tooth Numbering Scheme' className="p-col-6"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <label htmlFor="ChartingScheme">Schemes</label>
                        </div>
                        <div className="p-col-9">
                            {dropDownPlain(objectProps, 'schemes', 'ChartingScheme', 'label', false, false)}
                        </div>
                    </div>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        currentState: state.stateManagement[SM_PREFERENCES_CRT.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const ChartingSystemsSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedChartingSystemsSection);

export default ChartingSystemsSection;
