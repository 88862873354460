
export const LowerVeneerPoints = [6,6, 42,6, 42,32, 36,34, 12,34, 6,32 ];
export const UpperVeneerPoints = [42,60, 42,86, 6,86, 6,60, 12,58, 36,58 ];

export const LowerVeneerChippedPoints = [15,6, 42,6, 42,32, 36,34, 12,34, 6,32, 6,20];
export const UpperVeneerChippedPoints = [42,60, 42,77, 33,86, 6,86, 6,60, 12,58, 36,58 ];

export const PostPoints = [0,0, 6,0, 6,20, 0,20 ];

export const UpperPostAndCorePoints = [0,10, 8,10, 8,0, 14,0, 14,10, 22,10, 21,20, 1,20 ];
export const LowerPostAndCorePoints = [1,0, 20,0, 21,10, 14,10, 14,20, 8,20, 8,10, 0,10 ];

export const UpperJacketPoints = [4,24, 44,24, 44,4, 34,4, 33,14, 14,14, 13,4, 4,4 ];
export const LowerJacketPoints = [4,4, 44,4, 44,24, 34,24, 33,14, 14,14, 13, 24, 4,24 ];

export const UpperCrownPoints1 = [4,4, 44,4, 44,44, 4,44, 4,4 ];
export const LowerCrownPoints1 = [4,4, 44,4, 44,44, 4,44, 4,4 ];

export const UpperCrownPoints2 = [4,4, 44,4, 44,34, 4,34, 4,4 ];
export const LowerCrownPoints2 = [4,4, 44,4, 44,34, 4,34, 4,4 ];

export const UpperOnlayPoints1 = [4,4, 44,4, 44,44, 4,44, 4,4 ];
export const LowerOnlayPoints1 = [4,4, 44,4, 44,44, 4,44, 4,4 ];

export const UpperOnlayPoints2 = [4,4, 44,4, 44,14, 4,14, 4,4 ];
export const LowerOnlayPoints2 = [4,4, 44,4, 44,14, 4,14, 4,4 ];

export const AdultUpperCrownOnImplant = [6,56, 42,56, 44,58, 44,88, 4,88, 4,58, 6,56 ];
export const AdultLowerCrownOnImplant = [4,4, 44,4, 44,34, 40,36, 9,36, 4,34, 4,4 ];

export const AdultBridgeBar = [
    0,0,  //0, 1 > top left
    0,10, //2, 3 > bottom left
    0,10, //4, 5 > bottom right
    0,0,  //6, 7 > top right
    0,0   //8, 9 > top left >> 4, 6 are the bar width
];

export const AdultDentureBar = [
    0,0,  //0, 1 > top left
    0,10, //2, 3 > bottom left
    0,10, //4, 5 > bottom right
    0,0,  //6, 7 > top right
    0,0   //8, 9 > top left >> 4, 6 are the bar width
];

export const AdultRetainerBar1 = [
    0,0,  //0, 1 > top left
    0,10, //2, 3 > bottom left
    0,10, //4, 5 > bottom right
    0,0,  //6, 7 > top right
    0,0   //8, 9 > top left >> 4, 6 are the bar width
];

export const AdultRetainerBar2 = [
    0,0,  //0, 1 > top left
    0,3,  //2, 3 > bottom left
    0,3,  //4, 5 > bottom right
    0,0,  //6, 7 > top right
    0,0   //8, 9 > top left >> 4, 6 are the bar width
];
