import React from 'react';
import {SM_TAB_PATIENT_CONSENT, SM_TAB_PATIENT_CONSENT_SIG} from "../../actions/stateManagement";
import _ from "lodash";
import {clientLayoutClassName, SE_NONE, SM_CLIENT_CONSENT_FORMS_EMPTY} from "./Constants";
import {connect} from "react-redux";
import {Panel} from "primereact/panel";
import * as Actions from "../../actions";
import {
    getList,
    getPortalPDF,
    removeFromLists,
    RES_TAB_PATIENT_CONSENTS,
    RES_TABLET_LISTS,
    saveConsent
} from "../../actions/tablet";
import {
    DOC_CONSENT_TEMPLATE,
    HM_TABLET_CONFIRM_CONSENT,
    OUTCOME_ConsentGiven,
    OUTCOME_ConsentNotGiven
} from "../../Constants";
import {PDFBaseComponent} from "../PDFBaseComponent";
import {getResource2, RES_TEMPLATES_CONSENT_FORMS} from "../../actions/housekeeping";
import {ProgressBar} from "primereact/progressbar";
import {PDFConsentSection} from "../Tablet/Utils";
import {Checkbox} from "primereact/checkbox";
import SignatureCanvas from "react-signature-canvas";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import * as DefaultData from "../FixedItems/DefaultData";
import {ac} from "../../index";
import {ShowQuestionDialog} from "../FixedItems/Diary/components/EventComponent";
import {TAB_PatientConsentForms} from "../Tablet/Constants";

class ConnectedClientConsents extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                templateIndex: -1,
                consentFormsLoaded: false,
                consentForms: [],
                consentFormsPdfImage: [],
                consentFormLoaded: false,
                consentForm: null,
                memberLoaded: false,
                member: null,
                saveCount: 0,

                [HM_TABLET_CONFIRM_CONSENT.id]: false,
            }
        }

        this.sigCanvases = [];
    }

    componentDidMount() {

        this.props.getTemplates([DOC_CONSENT_TEMPLATE.name]);
        this.props.getMember(this.props.patientId);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case RES_TEMPLATES_CONSENT_FORMS.GET.receive:
                    break;

                case RES_TAB_PATIENT_CONSENTS.GET_PORTAL.receive:

                    const member = this.props.member;

                    if (member === null) {
                        this.setState({member: null, memberLoaded: true, consentForms: [], consentFormsLoaded: true});
                    } else {
                        this.setState({
                            member,
                            memberLoaded: true,
                            templateIndex: this.state.templateIndex + 1,
                        }, () => {
                            const templateId = this.state.member.consentFormIds[this.state.templateIndex];
                            this.props.getConsentForms({
                                id: this.state.member.id,
                                templateId,
                                patientId: this.state.member.patientId
                            });
                        })
                    }
                    break;

                case Actions.RECEIVE_TAB_PATIENT_CONSENT_FORM_PDF:

                    const consentForms = [...this.state.consentForms];
                    const consentFormsPdfImage = [...this.state.consentFormsPdfImage];

                    const consentForm = _.cloneDeep(DefaultData.ConsentForm(ac.getMcId()));

                    consentForm.patient = {id: this.state.member.patientId};
                    consentForm.templateId = this.state.member.consentFormIds[this.state.templateIndex];
                    consentForm.outcome = OUTCOME_ConsentNotGiven;

                    consentForms.push(consentForm)
                    consentFormsPdfImage.push({
                        pdf: this.props.consentForm,
                        image: null,
                    })

                    const consentFormsLoaded = this.state.templateIndex === this.state.member.consentFormIds.length - 1;

                    if (!consentFormsLoaded) {
                        this.setState({
                            templateIndex: this.state.templateIndex + 1,
                            consentFormsLoaded: false,
                            consentForms,
                            consentFormsPdfImage,
                        }, () => {
                            const templateId = this.state.member.consentFormIds[this.state.templateIndex];
                            this.props.getConsentForms({
                                id: this.state.member.id,
                                templateId,
                                patientId: this.state.member.patientId
                            });
                        })
                    } else {
                        this.setState({consentFormsLoaded: true, consentForms, consentFormsPdfImage});
                    }
                    break;

                case Actions.RECEIVE_SAVE_TAB_PATIENT_CONSENTS:

                    const saveCount = this.state.saveCount + 1;

                    if (saveCount === this.state.consentForms.length) {
                        const tabletMember = {patientId: this.state.member.patientId, type: TAB_PatientConsentForms};
                        this.props.removeFromLists(tabletMember);
                        this.props.onCommandChange(SE_NONE)
                    } else {
                        this.setState({saveCount}, () => {
                            this.props.saveConsent({
                                form: this.state.consentForms[saveCount],
                                image: this.state.consentFormsPdfImage[saveCount].image,
                                memberId: this.props.member.id,
                            });
                        });
                    }
                    break;

                default:
                    break;
            }
        }
    }

    onGive = (event) => {

        const state = {...this.state};
        const consentForms = state.consentForms;

        consentForms[event.index].outcome = event.value ? OUTCOME_ConsentGiven : OUTCOME_ConsentNotGiven;
        this.setState(state);
    }

    onDeny = (event) => {

        const state = {...this.state};
        const consentForms = state.consentForms;

        consentForms[event.index].outcome = event.value ? OUTCOME_ConsentNotGiven : OUTCOME_ConsentGiven;
        this.setState(state);
    }

    onExit = () => {

        this.setState({saveCount: 0}, () => {
            this.props.saveConsent({
                form: this.state.consentForms[0],
                image: this.state.consentFormsPdfImage[0].image,
                memberId: this.props.member.id,
            });
        });
    }

    onSave = (index) => {

        const image = this.sigCanvases[index].toDataURL('image/png');
        this.sigCanvases[index].off();

        const state = {...this.state};
        const consentFormsPdfImage = state.consentFormsPdfImage;

        consentFormsPdfImage[index].image = image;

        const foundNull = _.findIndex(consentFormsPdfImage, form => form.image === null);

        if (foundNull === -1) {

            this.setState({[HM_TABLET_CONFIRM_CONSENT.id]: true});
        } else {
            this.setState(state);
        }
    }

    onClear = (index) => {

        this.sigCanvases[index].clear();
        this.sigCanvases[index].on();

        const state = {...this.state};
        const consentFormsPdfImage = state.consentFormsPdfImage;

        consentFormsPdfImage[index].image = null;
        this.setState(state);
    }

    onCancel = () => {

        this.props.onCommandChange({owner: 'selectedAction', value: SE_NONE})
    }

    onSetSigRef = (index, ref) => {

        this.sigCanvases[index] = ref
    }

    showDialogs = () => {

        return (
            ShowQuestionDialog(this, HM_TABLET_CONFIRM_CONSENT, this.onExit)
        )
    }

    insertReportTexts = () => {

        const forms = [];

        this.state.consentForms.forEach((form, index) => {

            const header = <div className='panel-header-centered-content'><label id='panel-header'>{form.name}</label></div>;

            const consentForm = this.state.consentForms[index];
            const consentFormPdfImage = this.state.consentFormsPdfImage[index];

            const pdf = consentFormPdfImage.pdf;

            const sigHeader =
                <div>
                    <label id='panel-header'>{SM_TAB_PATIENT_CONSENT_SIG.label}</label>
                    <div className="p-toolbar-group-right">
                        <Button label="Clear" icon="pi pi-plus"
                                style={{marginLeft: '0.25em', marginBottom: '0.6em'}}
                                onClick={() => this.onClear(index)}/>
                    </div>
                </div>;

            const isGiven = consentForm.outcome === OUTCOME_ConsentGiven;
            const isSaved = consentFormPdfImage.image !== null;

            forms.push(
                <div className="p-col-12"
                     key={`key__${index}`}
                >
                    <Panel header={header} style={{fontSize: '1.5em', padding: '10px'}}>

                        <PDFConsentSection cfTemplates={this.props.cfTemplates}
                                           consentForm={consentForm}
                                           index={index}
                                           numPages={this.state.numPages}
                                           pdf={pdf}
                                           onClear={this.onClear}
                                           onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                                           onLoadError={this.onLoadError}
                        />

                        <Panel header='Patients Consent'
                               className="p-col-12"
                               style={{paddingTop: '5px'}}
                        >
                            <Checkbox className="p-col-6"
                                      checked={isGiven}
                                      onChange={(e) => {
                                          this.onGive({
                                              index,
                                              value: e.checked,
                                          })
                                      }}
                                      inputId={`ConsentGiven${index}`}
                            />
                            <label className="p-col-6 p-checkbox-label"
                                   htmlFor={`ConsentGiven${index}`}
                            >{`Patient Gives Consent`}
                            </label>
                            <Checkbox className="p-col-6"
                                      checked={!isGiven}
                                      onChange={(e) => {
                                          this.onDeny({
                                              index,
                                              value: e.checked,
                                          })
                                      }}
                                      inputId={`ConsentNotGiven${index}`}
                            />
                            <label className="p-col-6 p-checkbox-label"
                                   htmlFor={`ConsentNotGiven${index}`}
                            >
                                {`Patient Does Not Give Consent`}
                            </label>
                        </Panel>

                        <Panel className="p-col-12"
                               style={{paddingTop: '5px'}}
                               header={sigHeader}>
                            <SignatureCanvas penColor='darkBlue'
                                             ref={(ref) => {
                                                 this.onSetSigRef(index, ref);
                                             }}
                                             canvasProps={{
                                                 width: 500,
                                                 height: 200,
                                                 className: 'sigCanvas'
                                             }}/>
                        </Panel>

                        <Toolbar left={
                            <React.Fragment>
                                <Button label="Save This Consent Signature" icon="pi pi-check"
                                        className="p-button-success"
                                        onClick={() => this.onSave(index)}
                                        disabled={isSaved}
                                />
                                <i className="pi pi-bars p-toolbar-separator"
                                   style={{marginRight: '.25em'}}/>
                                <Button label="Cancel Consent Session"
                                        icon="pi pi-times"
                                        className="p-button-warning"
                                        onClick={this.onCancel}
                                />
                            </React.Fragment>
                        }
                        />

                    </Panel>
                </div>
            )

        });
        return forms;
    }

    render() {

      

        if (!this.state.consentFormsLoaded || !this.state.memberLoaded || !this.props.cfTemplatesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        if (this.state.consentForms.length > 0) {

            return (

                <div className={clientLayoutClassName} id='mainPCC'>

                    {this.showDialogs()}

                    <div>
                        <div className="p-grid">

                            {this.insertReportTexts()}

                        </div>
                    </div>
                </div>
            )
        } else {

            const header = <div className='panel-header-centered-content'><label id='panel-header'>{SM_CLIENT_CONSENT_FORMS_EMPTY.label}</label>
                <div className="p-toolbar-group-right">
                    <Button label={SM_CLIENT_CONSENT_FORMS_EMPTY.exitLabel}
                            icon={SM_CLIENT_CONSENT_FORMS_EMPTY.exitIcon}
                            className="p-button-success"
                            onClick={() => {
                                this.props.onCommandChange(SE_NONE)
                            }}
                    />
                </div>
            </div>;

            return (
                <div className="p-col-12">
                    <Panel header={header}>
                    </Panel>
                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    const loadedId = `${SM_TAB_PATIENT_CONSENT.loaded}_${ownProps.patientId}`;
    const dataId = `${SM_TAB_PATIENT_CONSENT.id}_${ownProps.patientId}`;

    return {

        cfTemplatesLoaded: state.housekeeping.cfTemplatesLoaded,
        cfTemplates: state.housekeeping.cfTemplates,

        memberLoaded: state.tablet.patientConsentMembersLoaded,
        member: state.tablet.patientConsentMembers,

        consentFormLoaded: state.tablet[loadedId],
        consentForm: state.tablet[dataId],

        message: state.stateManagement.message,

        loginIdentity: state.login.user,
        userId: state.login.userId,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_CONSENT_FORMS.GET, types)),
        getMember: (patientId) => dispatch(getList(RES_TAB_PATIENT_CONSENTS.GET_PORTAL, patientId)),
        getConsentForms: (params) => dispatch(getPortalPDF(RES_TAB_PATIENT_CONSENTS.PORTAL, params)),
        saveConsent: (params) => dispatch(saveConsent(RES_TAB_PATIENT_CONSENTS.SAVE, params)),
        removeFromLists: (tabletMember) => dispatch(removeFromLists(RES_TABLET_LISTS.REMOVE_FROM, tabletMember)),
    }
};

const ClientConsents = connect(mapStateToProps, MapDispatchToProps)(ConnectedClientConsents);

export default ClientConsents;
