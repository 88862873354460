import React from 'react';
import _ from "lodash";

import {Button} from 'primereact/components/button/Button';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {calendar, checkBox, spinner, spinnerStep} from "../../../PatientDynamicItems/OnChangeUtils";
import {BaseComponent} from "../../../BaseComponent";
import {SR_CLINIC, SR_RESERVED} from "../../../../Constants";
import {ICON_COPY} from "../../../../icons";

export class ScheduleRule extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {

            activeIndex: 0,

            weeks: [
                {checked: false},
                {checked: false},
                {checked: false},
                {checked: false},
            ],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.rule.id !== this.props.rule.id) {
            this.setState({activeIndex: 0});
        }
    }

    onRuleTabChange = (index) => {

        if (this.props.rule.id !== null) {
            this.setState({activeIndex: index});
            this.props.onTabChange(index);
        }
    }

    showDayRow(week, day, controlName, readOnly) {

        const props = {
            onChange: this.props.onChange,
            target: 'rule',
            rule: this.props.rule,
        };

        const stepValue = this.props.timeDiv;
        const enable = readOnly;
        const lbEnable = enable;

        const spinnerWidth = 2;

        return (
            <div key={`sr_w_${week}_${day}`}
                 style={{display: 'flex', alignItems: 'center', paddingBottom: '5px'}}
            >
                <Checkbox checked={this.props.rule === null ? false : _.get(this.props.rule, `days[${week}][${day}]`)}
                          onChange={(e) => {
                              console.log(`onChange => ${e.checked} :: rule.days[${week}][${day}]`);
                              this.props.onChange({
                              owner: `rule.days[${week}][${day}]`,
                              value: e.checked
                          })}}
                          disabled={enable}
                />
                <label key={`${controlName}_label`}
                       className='p-checkbox-label'
                       style={{width: '60px'}}
                       htmlFor={controlName}>{controlName}</label>

                <div><label style={{margin: '4px'}}>From</label></div>
                <div>{spinner(props, `startHour[${week}][${day}]`, 1, 23, enable, false, spinnerWidth)}</div>
                <div><label style={{margin: '4px'}}>H</label></div>
                <div>{spinnerStep(props, `startMin[${week}][${day}]`, 0, 59, stepValue, enable, false, spinnerWidth)}</div>
                <div><label style={{margin: '4px'}}>M</label></div>
                <div>{spinner(props, `endHour[${week}][${day}]`, 1, 23, enable, false, spinnerWidth)}</div>
                <div><label style={{margin: '4px'}}>H</label></div>
                <div>{spinnerStep(props, `endMin[${week}][${day}]`, 0, 59, stepValue, enable, false, spinnerWidth)}</div>
                <div><label style={{margin: '4px'}}>M</label></div>
                <div style={{paddingLeft: '40px'}}>{checkBox(props, `lunchShown[${week}][${day}]`, '', enable, false, spinnerWidth)}</div>
                <div><label style={{margin: '4px'}}>Lunch</label></div>
                <div style={{paddingLeft: '40px'}}>{spinner(props, `lbStartHour[${week}][${day}]`, 1, 23, lbEnable, false, spinnerWidth)}</div>
                <div><label style={{margin: '4px'}}>H</label></div>
                <div>{spinnerStep(props, `lbstartMin[${week}][${day}]`, 0, 59, stepValue, lbEnable, false, spinnerWidth)}</div>
                <div><label style={{margin: '4px'}}>M</label></div>
                <div>{spinner(props, `lbEndHour[${week}][${day}]`, 1, 23, lbEnable, false, spinnerWidth)}</div>
                <div><label style={{margin: '4px'}}>H</label></div>
                <div>{spinnerStep(props, `lbEndMin[${week}][${day}]`, 0, 59, stepValue, lbEnable, false, spinnerWidth)}</div>
                <div><label style={{margin: '4px'}}>M</label></div>
            </div>
        );
    };

    addDay(week) {

        const contents = [];
        contents.push(this.showDayRow(week, 0, 'Sun', this.props.readOnly));
        contents.push(this.showDayRow(week, 1, 'Mon', this.props.readOnly));
        contents.push(this.showDayRow(week, 2, 'Tue', this.props.readOnly));
        contents.push(this.showDayRow(week, 3, 'Wed', this.props.readOnly));
        contents.push(this.showDayRow(week, 4, 'Thu', this.props.readOnly));
        contents.push(this.showDayRow(week, 5, 'Fri', this.props.readOnly));
        contents.push(this.showDayRow(week, 6, 'Sat', this.props.readOnly));
        return contents;

    };

    addWeeks() {

        const contents = [];

        for (let week = 0; week < 4; week++) {

            contents.push(
                <TabPanel key={`week_${week}`}
                          header={`Week ${week + 1}: `}>
                    {this.addDay(week)}
                </TabPanel>
            )
        }
        return contents;
    }

    reservationCheckBox(props, name, label, readOnly, mandatory) {

        const checked = _.get(props, `${props.target}.${name}`) === SR_RESERVED;

        const contents = [];

        contents.push(
            <Checkbox key={name}
                      checked={checked}
                      disabled={readOnly}
                      className='p-checkbox-label'
                      onChange={(e) => {
                          props.onChange({owner: `${props.target}.${name}`, value: e.checked ? SR_RESERVED : SR_CLINIC})
                      }}
            />
        );
        if (label)
            contents.push(<label key={`${name}_label`} style={{fontSize: 'small'}} className='p-checkbox-label'
                                 htmlFor={name}>{label}</label>);
        return contents;
    }

    addStartingOn(props, readOnly) {

        if (Boolean(props.rule) && props.rule.openStart)
            return null;
        else
            return (
                calendar(props, 'startingOn', readOnly)
            );
    }

    addEndingOn(props, readOnly) {

        if (Boolean(props.rule) && props.rule.openEnd)
            return null;
        else
            return (
                calendar(props, 'endingOn', readOnly)
            );
    }

    addCopyPreviousWeek(showCopyPreviousWeek) {

        const disabled = this.state.activeIndex === 0;
        const week = this.state.activeIndex;

        if (showCopyPreviousWeek) {

            const contents = [];

            contents.push(<div key='copyWeek_1' className="p-grid p-fluid form-group p-col-1"/>);
            contents.push(
                <div key='copyWeek_2'
                     className="p-grid p-fluid form-group p-col-1"
                     style={{paddingLeft: '10px', float: 'right'}}>
                    <Button tooltipOptions={{position: 'top'}}
                            tooltip='Copy previous week'
                            icon={ICON_COPY}
                            disabled={disabled}
                            onClick={() => this.props.copyPreviousWeek(week)}
                    />
                </div>);
            return contents;
        } else
            return <div className="p-grid p-fluid form-group p-col-2"/>
    }

    addOptions(showCopyPreviousWeek) {

        const props = {
            onChange: this.props.onChange,
            target: 'rule',
            rule: this.props.rule,
        };

        return (
            <div className="p-grid form-group p-col-12">
                <div className='p-grid p-col-12'>
                    <div className="p-col-2">
                        {this.reservationCheckBox(props, 'type', 'Reservation', this.props.readOnly, false)}
                    </div>
                    <div className='p-col-8'/>
                    <div className='p-col-2' style={{float: 'right'}}>
                        {this.addCopyPreviousWeek(showCopyPreviousWeek)}
                    </div>
                </div>
                <div className='p-grid p-col-12'>
                    <div className="p-col-2">
                        {checkBox(props, 'openStart', 'Open Start', this.props.readOnly, false)}
                    </div>
                    <div className="p-col-4">
                        {this.addStartingOn(props, this.props.readOnly)}
                    </div>
                    <div className="p-col-2">
                        {checkBox(props, 'openEnd', 'Open End', this.props.readOnly, false)}
                    </div>
                    <div className="p-col-4">
                        {this.addEndingOn(props, this.props.readOnly)}
                    </div>
                </div>
            </div>
        )
    }

    render() {

        let key = Math.floor(Math.random() * 1000);;

        return (
            <div className="p-grid p-col-12" style={{paddingLeft: '40px'}}>
                {this.addOptions(!this.props.readOnly)}
                <TabView key={`sr_key_${key++}`}
                         scrollable={true}
                         activeIndex={this.state.activeIndex}
                         onTabChange={(e) => {
                             this.onRuleTabChange(e.index)
                         }}>
                    {this.addWeeks()}
                </TabView>
            </div>
        )
    }
}