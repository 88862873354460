import * as Actions from '../actions/index';
import _ from 'lodash';
import {RES_KPI_DATA_SETS} from "../actions/kpis";

const initialState = {

    newPatientsLoaded: false,
    newPatients: [],
    appointmentsLoaded: false,
    appointments: [],
    appointmentsBookedLoaded: false,
    appointmentsBooked: [],
    treatmentPlansLoaded: false,
    treatmentPlans: [],
    referralsLoaded: false,
    referrals: [],
};

export const kpis = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.REQUEST_KPI_NEW_PATIENTS: {

                return {
                    ...state,
                    newPatientsLoaded: false,
                };
            }
            case Actions.RECEIVE_KPI_NEW_PATIENTS: {

                return {
                    ...state,
                    newPatientsLoaded: true,
                    newPatients: action.payload,
                };
            }
            case Actions.REQUEST_KPI_NEW_PATIENTS_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.NEW_PATIENTS_DD.id}Loaded_${collIndex}`]: false,
                };
            }
            case Actions.RECEIVE_KPI_NEW_PATIENTS_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.NEW_PATIENTS_DD.id}Loaded_${collIndex}`]: true,
                    [`${RES_KPI_DATA_SETS.NEW_PATIENTS_DD.id}_${collIndex}`]: action.payload,
                };
            }

            case Actions.REQUEST_KPI_ACTIVE_PATIENTS: {

                return {
                    ...state,
                    activePatientsLoaded: false,
                };
            }
            case Actions.RECEIVE_KPI_ACTIVE_PATIENTS: {

                return {
                    ...state,
                    activePatientsLoaded: true,
                    activePatients: action.payload,
                };
            }
            case Actions.REQUEST_KPI_ACTIVE_PATIENTS_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.ACTIVE_PATIENTS_DD.id}Loaded_${collIndex}`]: false,
                };
            }
            case Actions.RECEIVE_KPI_ACTIVE_PATIENTS_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.ACTIVE_PATIENTS_DD.id}Loaded_${collIndex}`]: true,
                    [`${RES_KPI_DATA_SETS.ACTIVE_PATIENTS_DD.id}_${collIndex}`]: action.payload,
                };
            }
            case Actions.REQUEST_KPI_APPOINTMENTS:

                return {
                    ...state,
                    appointmentsLoaded: false,
                };

            case Actions.RECEIVE_KPI_APPOINTMENTS:

                return {
                    ...state,
                    appointmentsLoaded: true,
                    appointments: action.payload,
                };

            case Actions.REQUEST_KPI_APPOINTMENTS_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.APPOINTMENTS_DD.id}Loaded_${collIndex}`]: false,
                };
            }
            case Actions.RECEIVE_KPI_APPOINTMENTS_DD: {

                const collIndex = action.collIndex;

                const appointments = action.payload.map(appointment => {
                    return {...appointment, start: new Date(appointment.start)}
                });

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.APPOINTMENTS_DD.id}Loaded_${collIndex}`]: true,
                    [`${RES_KPI_DATA_SETS.APPOINTMENTS_DD.id}_${collIndex}`]: appointments,
                };
            }
            case Actions.REQUEST_KPI_APPOINTMENTS_BOOKED:

                return {
                    ...state,
                    appointmentsBookedLoaded: false,
                };

            case Actions.RECEIVE_KPI_APPOINTMENTS_BOOKED:

                return {
                    ...state,
                    appointmentsBookedLoaded: true,
                    appointmentsBooked: action.payload,
                };

            case Actions.REQUEST_KPI_APPOINTMENTS_BOOKED_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.APPOINTMENTS_BOOKED_DD.id}Loaded_${collIndex}`]: false,
                };
            }
            case Actions.RECEIVE_KPI_APPOINTMENTS_BOOKED_DD: {

                const collIndex = action.collIndex;

                const appointments = action.payload.map(appointment => {
                    return {...appointment, start: new Date(appointment.start)}
                });

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.APPOINTMENTS_BOOKED_DD.id}Loaded_${collIndex}`]: true,
                    [`${RES_KPI_DATA_SETS.APPOINTMENTS_BOOKED_DD.id}_${collIndex}`]: appointments,
                };
            }
            case Actions.REQUEST_KPI_TREATMENT_PLANS:

                return {
                    ...state,
                    treatmentPlansLoaded: false,
                };

            case Actions.RECEIVE_KPI_TREATMENT_PLANS:

                return {
                    ...state,
                    treatmentPlansLoaded: true,
                    treatmentPlans: action.payload,
                };

            case Actions.REQUEST_KPI_TREATMENT_PLANS_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.TREATMENT_PLANS_DD.id}Loaded_${collIndex}`]: false,
                };
            }
            case Actions.RECEIVE_KPI_TREATMENT_PLANS_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.TREATMENT_PLANS_DD.id}Loaded_${collIndex}`]: true,
                    [`${RES_KPI_DATA_SETS.TREATMENT_PLANS_DD.id}_${collIndex}`]: action.payload,
                };
            }
            case Actions.REQUEST_KPI_REFERRALS:

                return {
                    ...state,
                    referralsLoaded: false,
                };

            case Actions.RECEIVE_KPI_REFERRALS:

                return {
                    ...state,
                    referralsLoaded: true,
                    referrals: action.payload,
                };

            case Actions.REQUEST_KPI_REFERRALS_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.REFERRALS_DD.id}Loaded_${collIndex}`]: false,
                };
            }
            case Actions.RECEIVE_KPI_REFERRALS_DD: {

                const collIndex = action.collIndex;

                return {
                    ...state,
                    [`${RES_KPI_DATA_SETS.REFERRALS_DD.id}Loaded_${collIndex}`]: true,
                    [`${RES_KPI_DATA_SETS.REFERRALS_DD.id}_${collIndex}`]: action.payload,
                };
            }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};