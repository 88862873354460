import * as Actions from './index';
import {
    CLEAR_CLINICIAN_DIARY,
    CLEAR_CLINICIAN_WEEK_DIARY,
    CLEAR_PRACTICE_DIARY,
    CLEAR_PRACTICE_WEEK_DIARY
} from './index';
import {ac} from '../index'
import moment from 'moment';
import _ from 'lodash';
import {ADD_PATIENT_OK, JSON_DATE_FORMAT} from "../Constants";
import {RES_HOUSEKEEPING_CSCHED} from "./housekeeping";
import {RES_PATIENT_DETAILS} from "./personal";
import * as DefaultData from "../components/FixedItems/DefaultData";

const baseURL = '/Appointments';
const eventsBaseURL = '/DiaryEvents';

export const RES_SEARCH_EVENTS = {
    NEXT: {
        url: `${eventsBaseURL}/getNextEventDate`,
        request: Actions.REQUEST_NEXT_EVENT,
        receive: Actions.RECEIVE_NEXT_EVENT
    },
    PREV: {
        url: `${eventsBaseURL}/getPrevEventDate`,
        request: Actions.REQUEST_PREV_EVENT,
        receive: Actions.RECEIVE_PREV_EVENT
    },
};

export const RES_PRACTICE_DIARY = {
    CLEAR: {action: CLEAR_PRACTICE_DIARY},
    POPUPS: {
        request: Actions.REQUEST_ACTIVE_POPUPS,
        receive: Actions.RECEIVE_ACTIVE_POPUPS,
        url: `${baseURL}/daysActivePopUps`
    },
    REASSIGN: {
        request: Actions.REQUEST_REASSIGN_APPOINTMENTS,
        receive: Actions.RECEIVE_REASSIGN_APPOINTMENTS,
        url: `${baseURL}/reassignAppointments`
    }
};

export const RES_PRACTICE_WEEK_DIARY = {
    CLEAR: {action: CLEAR_PRACTICE_WEEK_DIARY}
};

export const RES_CLINICIAN_DIARY = {
    CLEAR: {action: CLEAR_CLINICIAN_DIARY},
    REASSIGN: {
        request: Actions.REQUEST_REASSIGN_APPOINTMENTS,
        receive: Actions.RECEIVE_REASSIGN_APPOINTMENTS,
        url: `${baseURL}/reassignAppointments`
    }
};

export const RES_CLINICIAN_WEEK_DIARY = {
    CLEAR: {action: CLEAR_CLINICIAN_WEEK_DIARY}
};

export const RES_diaryEvents = {
    GET: {
        request: Actions.REQUEST_DIARY_EVENTS,
        receive: Actions.RECEIVE_DIARY_EVENTS,
        url: `${baseURL}/byDate?request=events`
    },
    GET_PORTAL: {
        request: Actions.REQUEST_DIARY_EVENTS_PORTAL,
        receive: Actions.RECEIVE_DIARY_EVENTS_PORTAL,
        url: `${eventsBaseURL}/byWeek?request=appointments`
    },
    GET_PORTAL_UA: {
        request: Actions.REQUEST_DIARY_EVENTS_PORTAL_UA,
        receive: Actions.RECEIVE_DIARY_EVENTS_PORTAL_UA,
        url: `${eventsBaseURL}/byDateUA?request=appointments`
    },
    GET_PORTAL_VC: {
        request: Actions.REQUEST_DIARY_EVENTS_PORTAL_VC,
        receive: Actions.RECEIVE_DIARY_EVENTS_PORTAL_VC,
        url: `${eventsBaseURL}/byWeek?request=videoCalls`
    },
    MOVING: {
        request: Actions.REQUEST_DIARY_PALLETE_ENTRIES,
        receive: Actions.RECEIVE_DIARY_PALLETE_ENTRIES,
        url: `${baseURL}/moving`
    },
    ADD: {receive: Actions.RECEIVE_ADD_DIARYEVENT, url: `${eventsBaseURL}/addDiaryEvent`},
    PRINT_DAY_LIST: {action: Actions.DIARY_PRINT_DAY_LIST, url: `${eventsBaseURL}/printDayList`},
    PORTAL_BOOKING: {
        request: Actions.REQUEST_ADD_PORTAL_BOOKING,
        receive: Actions.RECEIVE_ADD_PORTAL_BOOKING,
        url: `${baseURL}/portalBooking`
    },
    PORTAL_VIDEO_BOOKING: {
        request: Actions.REQUEST_ADD_PORTAL_VIDEO_BOOKING,
        receive: Actions.RECEIVE_ADD_PORTAL_VIDEO_BOOKING,
        url: `${baseURL}/portalBooking`
    }
};

export const RES_clinicianDiaryEvents = {
    GET: {
        request: Actions.REQUEST_CLINICIAN_DIARY_EVENTS,
        receive: Actions.RECEIVE_CLINICIAN_DIARY_EVENTS,
        url: `${baseURL}/byJustDateClinician?request=events`
    },
    ADD: {receive: Actions.RECEIVE_ADD_DIARYEVENT, url: `${eventsBaseURL}/addDiaryEvent`},
};

export const RES_weekDiaryEvents = {
    GET: {
        request: Actions.REQUEST_WEEK_DIARY_EVENTS,
        receive: Actions.RECEIVE_WEEK_DIARY_EVENTS,
        url: `${baseURL}/byWeek?request=events`
    },
};

export const RES_weekProviderDiaryEvents = {
    GET: {
        request: Actions.REQUEST_WEEK_PROVIDER_DIARY_EVENTS,
        receive: Actions.RECEIVE_WEEK_PROVIDER_DIARY_EVENTS,
        url: `${baseURL}/byWeekProvider?request=events`
    },
};

export const RES_addAppointment = {
    ADD: {receive: Actions.RECEIVE_ADD_APPOINTMENT, url: `${baseURL}/addAppointment`},
    ADD_BACK: {receive: Actions.RECEIVE_ADDBACK_APPOINTMENT, url: `${baseURL}/addBack`},
};

export const RES_addAppointmentNote = {
    ADD: {action: Actions.ADD_APPOINTMENT_NOTE, url: `${baseURL}/appointmentNote`},
};

export const RES_diaryNote = {
    ADD: {action: Actions.ADD_DIARY_NOTE, url: `${baseURL}/diaryNote`},
    REMOVE: {action: Actions.DELETE_DIARY_NOTE, url: `${baseURL}/setInactive`},
};

export const RES_sendAppointmentLetter = {
    PREVIEW: {action: Actions.PREVIEW_APPOINTMENT_LETTER, url: `/Patients/previewAppointmentDocument`},
    CREATE: {action: Actions.CREATE_APPOINTMENT_LETTER, url: `/Patients/createAppointmentDocument`},
};

export const RES_APPOINTMENT_CHECK_IN_OUT = {
    CHECK_IN: {action: Actions.APPOINTMENT_CHECK_IN, url: `${baseURL}/inChair`},
    CHECK_OUT: {action: Actions.APPOINTMENT_CHECK_OUT, url: `${baseURL}/leftChair`}
};

export const RES_ONLINE_BOOKINGS = {
    GET: {
        request: Actions.REQUEST_ONLINE_BOOKINGS,
        receive: Actions.RECEIVE_ONLINE_BOOKINGS,
        url: `${baseURL}/onlineBookings`
    },
    CLEAR: {action: Actions.CLEAR_ONLINE_BOOKINGS}
};

export const RES_VIDEO_APPOINTMENTS = {
    GET: {
        request: Actions.REQUEST_VIDEO_APPOINTMENTS,
        receive: Actions.RECEIVE_VIDEO_APPOINTMENTS,
        url: `${baseURL}/videoAppointments`
    },
    CLEAR: {action: Actions.CLEAR_VIDEO_APPOINTMENTS}
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${baseURL}/${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request});
    }
};

export const getNextPrevEventDate = ({url, request, receive}, params) => {

    const date = moment(params.currentDate).format(JSON_DATE_FORMAT)
    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${params.resourceId}&currentDate=${date}&events=${params.selectedAppTypes.join(',')}`)
            .then(res => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request});
    }
}

export const getDiaryEvents = (start, end, userId) => {

    return (dispatch, getState) => {

        start = moment(start, JSON_DATE_FORMAT);
        end = moment(end, JSON_DATE_FORMAT);

        if (!start.isValid() || !end.isValid()) throw new Error('Invalid date format');

        ac.fetchClient().get(`${RES_diaryEvents.GET.url}&mcId=${ac.getMcId()}&start=${start.format(JSON_DATE_FORMAT)}&end=${end.format(JSON_DATE_FORMAT)}`)
            .then(res => {

                const {preferences, objectStore} = getState();
                const masterConfiguration = preferences.practiceDetails;
                const {HOLIDAY_COLOUR, OUTOFHOURS_COLOUR, LUNCH_COLOUR, RESERVED_COLOUR} = objectStore;

                dispatch({
                    type: RES_diaryEvents.GET.receive,
                    date: {start, end},
                    payload: res.data,
                    masterConfiguration,
                    colours: {HOLIDAY_COLOUR, OUTOFHOURS_COLOUR, LUNCH_COLOUR, RESERVED_COLOUR}
                })
            })

        dispatch({type: RES_diaryEvents.GET.request, payload: {}});

        ac.fetchClient().get(`${RES_diaryEvents.MOVING.url}?mcId=${ac.getMcId()}&id=${userId}`)
            .then(res => {
                dispatch({type: RES_diaryEvents.MOVING.receive, payload: res.data})
            });
        dispatch({type: RES_diaryEvents.MOVING.request, payload: {}});
    }
};

export const getDiaryEventsByClinician = (start, end, loginIdentityId, userId) => {

    return (dispatch, getState) => {

        start = moment(start, JSON_DATE_FORMAT);
        end = moment(end, JSON_DATE_FORMAT);

        if (!start.isValid() || !end.isValid()) throw new Error('Invalid date format');

        ac.fetchClient().get(`${RES_clinicianDiaryEvents.GET.url}&mcId=${ac.getMcId()}&start=${start.format(JSON_DATE_FORMAT)}&end=${end.format(JSON_DATE_FORMAT)}&providerId=${userId}`)
            .then(res => {

                const {preferences, objectStore} = getState();
                const masterConfiguration = preferences.practiceDetails;
                const {HOLIDAY_COLOUR, OUTOFHOURS_COLOUR, LUNCH_COLOUR, RESERVED_COLOUR} = objectStore;

                dispatch({
                    type: RES_clinicianDiaryEvents.GET.receive,
                    date: {start, end},
                    payload: res.data,
                    masterConfiguration,
                    colours: {HOLIDAY_COLOUR, OUTOFHOURS_COLOUR, LUNCH_COLOUR, RESERVED_COLOUR},
                    clinicianId: userId
                })
            });

        dispatch({type: RES_clinicianDiaryEvents.GET.request, payload: {}, clinicianId: userId});

        ac.fetchClient().get(`${RES_diaryEvents.MOVING.url}?mcId=${ac.getMcId()}&id=${loginIdentityId}`)
            .then(res => {
                dispatch({type: RES_diaryEvents.MOVING.receive, payload: res.data})
            });
        dispatch({type: RES_diaryEvents.MOVING.request, payload: {}});
    }
};

export const getWeekDiaryEvents = (start, end, usersShort) => {

    return (dispatch, getState) => {

        start = moment(moment(start).startOf('week'), JSON_DATE_FORMAT);
        end = moment(moment(end).startOf('week'), JSON_DATE_FORMAT);

        if (!start.isValid() || !end.isValid()) throw new Error('Invalid date format');

        ac.fetchClient().get(`${RES_weekDiaryEvents.GET.url}&mcId=${ac.getMcId()}&start=${start.format(JSON_DATE_FORMAT)}&end=${end.format(JSON_DATE_FORMAT)}`)
            .then(res => {

                const {preferences, objectStore} = getState();
                const masterConfiguration = preferences.practiceDetails;
                const {HOLIDAY_COLOUR, OUTOFHOURS_COLOUR, LUNCH_COLOUR, RESERVED_COLOUR} = objectStore;

                dispatch({
                    type: RES_weekDiaryEvents.GET.receive,
                    date: {start, end},
                    payload: res.data,
                    masterConfiguration,
                    colours: {HOLIDAY_COLOUR, OUTOFHOURS_COLOUR, LUNCH_COLOUR, RESERVED_COLOUR},
                    usersShort,
                })
            })
        ;
        dispatch({type: RES_weekDiaryEvents.GET.request, payload: {}});
    }
};

export const getPortalDiaryEvents = ({url, request, receive}, start, end) => {

    return (dispatch) => {

        start = moment(start, JSON_DATE_FORMAT);
        end = moment(end, JSON_DATE_FORMAT);

        if (!start.isValid() || !end.isValid()) throw new Error('Invalid date format');

        ac.fetchClient().get(`${url}&mcId=${ac.getMcId()}&start=${start.format(JSON_DATE_FORMAT)}&end=${end.format(JSON_DATE_FORMAT)}`)
            .then(res => {

                dispatch({
                    type: receive,
                    date: {start, end},
                    payload: res.data,
                })
            });
        dispatch({type: request, payload: {}});
    }
};

export const addAppointment = (newAppointment, createRecall, referralDate, patient, _NHSRegistration, alternateRef, clinicianDiaryAppointment) => {

    return (dispatch) => {

        const appointment = {...newAppointment};

        appointment.appointmentWith = appointment.appointmentWith.id === appointment.bookedBy.id ? appointment.appointmentWith.id : {id: appointment.appointmentWith.id};
        appointment.location = appointment.location ? {id: appointment.location.id} : null;

        if (patient.createNewPatient) {

            let NHSRegistration = null;

            if (patient && patient.NHSPatient) {
                NHSRegistration = _.cloneDeep(DefaultData.PatientNHSDetails(ac.getMcId()));
            }
            const data = {patient, NHSRegistration};

            ac.fetchClient().post(`${RES_PATIENT_DETAILS.ADD.url}/?mcId=${ac.getMcId()}`, data)
                .then((patientRes) => {

                    const {patient, result} = patientRes.data;
                    const data = {appointment, createRecall, referralDate};

                    if (result === ADD_PATIENT_OK) {

                        patient.patientJourney.stage.action = null;
                        appointment.patient = patient;

                        ac.fetchClient().post(`${RES_addAppointment.ADD.url}/?mcId=${ac.getMcId()}`, data)
                            .then(appointmentRes => {
                                dispatch({
                                    type: RES_addAppointment.ADD.receive,
                                    payload: appointmentRes.data,
                                    clinicianDiaryAppointment
                                })
                            })
                    } else {
                        dispatch({type: RES_PATIENT_DETAILS.ERROR, payload: result});
                    }
                })
        } else {

            const data = {appointment, createRecall, referralDate, alternateRef};

            ac.fetchClient().post(`${RES_addAppointment.ADD.url}/?mcId: ${ac.getMcId()}`, data)
                .then(res => {
                    dispatch({type: RES_addAppointment.ADD.receive, payload: res.data, clinicianDiaryAppointment})
                })
        }
    }
};

export const addBack = (appointment) => {

    return (dispatch) => {

        ac.fetchClient().post(`${RES_addAppointment.ADD_BACK.url}?mcId=${ac.getMcId()}`, appointment)
            .then(res => {
                dispatch({type: RES_addAppointment.ADD_BACK.receive, payload: res.data})
            });
    }
};

export const addDiaryEvent = (newEvent) => {

    return (dispatch) => {

        ac.fetchClient().post(`${RES_diaryEvents.ADD.url}?mcId=${ac.getMcId()}`, newEvent)
            .then(res => {
                dispatch({type: RES_diaryEvents.ADD.receive, payload: res.data})
            });
    }
};

export const reassignSlot = ({url, request, receive}, date, sourceId, targetId) => {

    return (dispatch) => {

        date = moment(date, JSON_DATE_FORMAT);

        const params = {date: date.format(JSON_DATE_FORMAT), source: sourceId, target: targetId};

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params})
            .then(res => {
            });
    }
};

export const addAdHocRule = (providerId, start, end, userId) => {

    return (dispatch) => {

        start = moment(start, JSON_DATE_FORMAT);

        const params = {providerId, dayDate: start.format(JSON_DATE_FORMAT)};

        ac.fetchClient().get(`${RES_HOUSEKEEPING_CSCHED.ADDHOC.url}?mcId=${ac.getMcId()}`, {params})
            .then(res => {
                dispatch(getDiaryEvents(start, end, userId))
            });
    }
};

export const removeSchedules = (rule, start, end, removedById) => {

    const removals = {rules: [rule], removedById};

    return (dispatch) => {

        ac.fetchClient().post(`${RES_HOUSEKEEPING_CSCHED.REMOVE.url}?mcId=${ac.getMcId()}`, removals)
            .then(res => {
                dispatch(getDiaryEvents(start, end, removedById))
            });
    }
};

export const previewAppointmentLetter = ({url, action}, patientDocument, appointment, previewToken) => {

    return (dispatch) => {

        const params = {
            jsonPatientDocumentDAO: patientDocument,
            appointmentId: appointment.id,
            previewToken,
        };

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, params)
            .then(res => {
                // nothing is dispatched as a popup will occur via WS
            });
    }
};

export const sendAppointmentLetter = ({url, action}, patientDocument, appointment) => {

    return (dispatch) => {

        const params = {
            jsonPatientDocumentDAO: patientDocument,
            jsonAppointmentDAO: appointment,
        };

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, params)
            .then(res => {
                // nothing is dispatched as a popup will occur via WS
            });
    }
};

export const addDiaryNote = ({url, action}, note) => {

    return (dispatch) => {

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, note)
            .then(res => {
                // nothing is dispatched as a popup will occur via WS
            });
    }
};

export const onlineBooking = ({url, action}, details) => {

    return (dispatch) => {

        const _details = {...details};

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, _details)
            .then(res => {
                // nothing is dispatched as a popup will occur via WS
            });
    }
};

export const checkInOut = ({url, action}, details) => {

    return (dispatch) => {

        ac.fetchClient().put(`${url}?mcId=${ac.getMcId()}&appointmentId=${details.appointmentId}`)
            .then(res => {
                dispatch({type: action, payload: res.data})
            });
    }
};

export const setInactive = ({url, action}, params) => {

    return (dispatch) => {

        ac.fetchClient().delete(`${url}?mcId=${ac.getMcId()}&noteId=${params.noteId}&closedById=${params.closedById}`)
            .then(res => {
                dispatch({type: action, payload: res.data})
            });
    }
};

export const printDayList = ({url, action}, details) => {

    return (dispatch) => {

        const params = {params: {...details}};

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, params)
            .then(res => {
                dispatch({type: action, payload: res.data})
            });
    }
};
