import React from 'react';
import {ac} from '../../../index';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Panel} from 'primereact/components/panel/Panel';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {PickList} from 'primereact/components/picklist/PickList';
import {Password} from 'primereact/password';

import {Address} from '../../DynamicItems/Address';
import {Contact} from '../../DynamicItems/Contact';
import {Name} from '../../UserDynamicItems/Name';

import _ from "lodash";
import * as DefaultData from "../DefaultData";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import {connect} from "react-redux";
import {HELP_ADD_USER, setState, SM_ADD_USER, stateRequest} from "../../../actions/stateManagement";
import * as Actions from "../../../actions";
import {
    CONST_ASK,
    CONST_ASK_LABEL,
    CONST_FEMALE,
    CONST_FEMALE_LABEL, CONST_INDETERMINATE, CONST_INDETERMINATE_LABEL,
    CONST_MALE,
    CONST_MALE_LABEL,
    dropDown,
    radioButton
} from "../../PatientDynamicItems/OnChangeUtils";
import {addUser} from "../../../actions/users";
import {
    CHIRALADMIN,
    HM_UserAddPassword,
    HM_UserSave,
    HM_UserSaveNOK,
    HM_UserSaveOK,
    RECEPTIONIST,
    SU_ADD_RESULT_ALREADY_EXISTS,
    SU_ADD_RESULT_DUPLICATE_USERNAME,
    SU_ADD_RESULT_ERROR,
    SU_ADD_RESULT_OK,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_HELP, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED,} from "../../../icons";
import {getResource, RES_HOUSEKEEPING_ADETS, RES_HOUSEKEEPING_FAVS} from "../../../actions/housekeeping";
import {BaseComponent} from "../../BaseComponent";
import crypto from "crypto";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import {ColorPicker} from "primereact/components/colorpicker/ColorPicker";

class ConnectedAddUser extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: this.props.id,
                user: _.cloneDeep(DefaultData.userData(ac.getMcId())),
                canSave: {status: false, count: 0},
                init: false,

                [HM_UserAddPassword.id]: false,
                [HM_UserSave.id]: false,
                [HM_UserSaveOK.id]: false,
                [HM_UserSaveNOK.id]: false,

                showErrorMessage: '',

                spSource: [],
                spTarget: [],
            };
        }
        this.exitState = TAB_PARENT_CHANGE;

        this.onChange = this.onChange.bind(this);
        this.onAddUserPassword = this.onAddUserPassword.bind(this);
        this.onAddUserCreate = this.onAddUserCreate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onExit = this.onExit.bind(this);
        this.onShowDialogs = this.onShowDialogs.bind(this);
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getDropDowns();
            this.props.getAppointmentDiaryTypes();
            this.props.getTreatmentFavs();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_DROPDOWNS: {

                    const newState = {...this.state};
                    _.set(newState, 'spSource', this.props.specialisms);

                    // set the first role type as the default.
                    const receptionRoles = _.filter(this.props.roles, role => role.type === RECEPTIONIST);
                    newState.user.myRole = receptionRoles[0];

                    this.setState(newState);
                }
                    break;

                case Actions.RECEIVE_HOUSEKEEPING_ADETS: {
                    const user = {...this.state.user};
                    // set the first appointment type as the default.
                    user.defaultAppointmentType = this.props.appointmentTypes[0];
                    this.setState({user});
                }
                    break;

                case Actions.RECEIVE_HOUSEKEEPING_FAVS:
                    break;

                case Actions.RECEIVE_USER_ADD:

                    switch (this.props.result) {
                        case SU_ADD_RESULT_OK:
                            this.setState({[HM_UserAddPassword.id]: false, [HM_UserSaveOK.id]: true});
                            break;
                        case SU_ADD_RESULT_DUPLICATE_USERNAME:
                            this.setState({
                                [HM_UserAddPassword.id]: false,
                                [HM_UserSaveNOK.id]: true,
                                showErrorMessage: 'Duplicate user name'
                            });
                            break;
                        case SU_ADD_RESULT_ALREADY_EXISTS:
                            this.setState({
                                [HM_UserAddPassword.id]: false,
                                [HM_UserSaveNOK.id]: true,
                                showErrorMessage: 'User name already exists'
                            });
                            break;
                        case SU_ADD_RESULT_ERROR:
                            this.setState({
                                [HM_UserAddPassword.id]: false,
                                [HM_UserSaveNOK.id]: true,
                                showErrorMessage: 'An error occurred during the user creation process'
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    setPassword(password) {

        // Creating a unique salt for a particular user
        const salt = crypto.randomBytes(16).toString('hex');

        // Hashing user's salt and password with 1000 iterations, 64 length and sha512 digest
        const hash = crypto.pbkdf2Sync(password, salt, 1000, 64, `sha512`).toString(`hex`);
        return {hash, salt};
    };

    onShowDialogs() {

        if (this.state[HM_UserAddPassword.id]) {

            const footer = <div>
                <Button label="OK" icon="fa fa-check" onClick={() => {
                    this.onAddUserCreate();
                }}/>
                <Button label="Cancel" icon="fas fa-times" onClick={() => {
                    this.setState({[HM_UserAddPassword.id]: false});
                }
                }/>
            </div>;

            return (
                <Dialog header={HM_UserSave.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_UserSave.id]: false})
                        }}
                >

                    <div className="p-grid p-fluid">
                        <div className="p-col-3">
                            <label>Password</label>
                        </div>
                        <div className="p-col-9">
                            <Password feedback={false}
                                      onChange={(e) => {
                                          const {hash, salt} = this.setPassword(e.target.value);
                                          const user = {...this.state.user};
                                          user.salt = salt;
                                          user.password = hash;
                                          this.setState({user})
                                      }}/>
                        </div>
                    </div>
                </Dialog>
            )
        } else if (this.state[HM_UserSaveOK.id]) {

            const footer = <Button label="OK" icon="fas fa-times" onClick={() => {
                this.onExit();
            }}/>;

            return (
                <Dialog header={HM_UserSaveOK.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_UserSaveOK.id]: false})
                        }}
                >
                    {HM_UserSaveOK.message}
                </Dialog>
            )
        } else if (this.state[HM_UserSaveNOK.id]) {

            const footer = <Button label="OK" icon="fas fa-times" onClick={() => {
                this.setState({[HM_UserSaveNOK.id]: false})
            }}/>;

            return (
                <Dialog header={HM_UserSaveNOK.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_UserSaveNOK.id]: false})
                        }}
                >
                    {this.state.showErrorMessage}
                </Dialog>
            )
        }
        return null;
    }

    onAddUserPassword(close) {

        if (this.state.canSave.status) {
            this.setState({[HM_UserAddPassword.id]: true});
        }
    }

    onAddUserCreate() {

        const specialisms = this.state.user.specialisms;
        const newUser = {...this.state.user};

        newUser.specialisms = null;

        this.props.addUser(newUser, specialisms);
    }

    onClose() {

        this.onExit();
    }

    onExit() {
        this.exitState = TAB_EXIT;
        this.props.onTabUpdate({key: this.props.id}, false);
        this.props.onCloseClick({key: this.props.id});
    }

    onChange(event) {

        const state = {...this.state};

        if (event.owner.endsWith('postcode')) {
            _.set(state, event.owner, event.value.toUpperCase());
        } else if (event.owner === 'colour') {

            _.set(state, 'user.red', event.value.r);
            _.set(state, 'user.green', event.value.g);
            _.set(state, 'user.blue', event.value.b);

        } else {
            _.set(state, event.owner, event.value);
        }

        if (state.user.title !== null && event.owner === 'user.title') {

            switch (state.user.title.genderType) {
                case CONST_FEMALE_LABEL:
                    state.user.gender = CONST_FEMALE;
                    break;
                case CONST_MALE_LABEL:
                    state.user.gender = CONST_MALE;
                    break;
                case CONST_ASK_LABEL:
                    state.user.gender = CONST_ASK;
                    break;
                case CONST_INDETERMINATE_LABEL:
                    state.user.gender = CONST_INDETERMINATE;
                    break;
                default:
                    break;
            }
        }

        _.set(state, 'canSave.status', true);

        this.setState(state, () => {
            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            this.props.setState(SM_ADD_USER.id, this.state);
        });
    }

    spTemplate(specialism) {
        return (
            <div className="p-helper-clearfix">
                <div style={{fontSize: 'small'}}>{specialism.description}</div>
            </div>
        );
    }
    ;

    render() {

        if (!this.props.dropDownsLoaded || !this.props.appointmentDiaryTypesLoaded) {
            return null;
        }

        const {firstName, lastName, username, specialisms, title} = this.state.user;

        const canSave = this.state.canSave.status && specialisms.length > 0
            && firstName.trim() !== '' && lastName.trim() !== ''
            && username.trim() !== ''
            && title !== null;

        const specialityClassName = specialisms.length === 0 ? 'p-invalid' : '';

        const props = {
            onChange: this.onChange,
            roles: _.filter(this.props.roles, role => role.type !== CHIRALADMIN),
            appointmentTypes: this.props.appointmentTypes,
            target: 'user',
            user: this.state.user,
        };

        let key = Math.floor(Math.random() * 1000);

        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const colorValue = {r: this.state.user.red, g: this.state.user.green, b: this.state.user.blue};

        return (

            <div id="detailPanel">

                {this.onShowDialogs()}

                <Toolbar
                    left={tb_boilerPlate2((e) => this.onAddUserPassword(false), saveIcon, !canSave, 'Add new user', 1)}
                    right={<React.Fragment>
                        {tb_boilerPlateRight((e) => window.open(HELP_ADD_USER), ICON_HELP, 'Knowledge Base', 1, true)}
                        {tb_boilerPlateRight(this.onClose, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 2)}
                    </React.Fragment>}
                />

                <div className="p-grid p-fluid p-col-12">
                    <div className="p-col-6">
                        <Name onChange={this.onChange}
                              style={{marginBottom: '5px'}}
                              target='user'
                              showPasswordButton={false}
                              {...this.state}
                              {...this.props}
                        />
                        <div style={{paddingTop: '5px'}}>
                            <Address onChange={this.onChange}
                                     target='user.contactDetails'
                                     header='Main Address'
                                     {...this.state}
                                     {...this.props}
                            />
                        </div>
                    </div>
                    <div className="p-lg-6 p-md-6">
                        <Contact onChange={this.onChange}
                                 target='user'
                                 includeHomePhone={true}
                                 includeWorkPhone={true}
                                 includeOtherPhone={false}
                                 {...this.state}
                                 {...this.props}
                        />
                        <Panel header='Miscellaneous' style={{marginTop: '5px'}}>
                            <div className="p-grid form-group">

                                <div className="p-col-2">
                                    <label htmlFor="title">Role</label>
                                </div>
                                <div className="p-col-4">
                                    {dropDown(props, 'roles', 'myRole', 'description', true)}
                                </div>

                                <div className="p-col-2">
                                    <label htmlFor="title">Appointment Type</label>
                                </div>
                                <div className="p-col-4">
                                    {dropDown(props, 'appointmentTypes', 'defaultAppointmentType', 'description', false)}
                                </div>

                                <div className="p-col-2">
                                    <label htmlFor="title">Recall Template</label>
                                </div>
                                <div className="p-col-4">
                                    <Dropdown value={this.state.user.recallTemplateId}
                                              options={this.props.recallTemplates}
                                              optionLabel='description'
                                              onChange={(event) => {
                                                  this.onChange({
                                                      owner: 'user.recallTemplateId',
                                                      value: event.value
                                                  })
                                              }}
                                              autoWidth={true}
                                    />
                                </div>

                                <div className="p-col-2">
                                    <label htmlFor="title">Favourite List</label>
                                </div>
                                <div className="p-col-4">
                                    <Dropdown key={key++}
                                              value={this.state.user.treatmentFavorite}
                                              options={this.props.treatmentFavourites}
                                              optionLabel='description'
                                              onChange={(event) => {
                                                  this.onChange({
                                                      owner: 'user.treatmentFavorite',
                                                      value: event.value
                                                  })
                                              }}
                                              autoWidth={true}
                                    />
                                </div>

                                <div className="p-col-2">
                                    {radioButton(props, 'ownsPatients', 'Owns Patients')}
                                </div>
                                <div className="p-col-4">
                                    <ColorPicker format='rgb'
                                                 value={colorValue}
                                                 onChange={(event) => {
                                                     this.onChange({
                                                         owner: 'colour',
                                                         value: event.value
                                                     })
                                                 }}
                                                 disabled={false}
                                    />
                                </div>
                                <div className="p-col-2">
                                    <label htmlFor="title">Default Location</label>
                                </div>
                                <div className="p-col-4">
                                    <Dropdown key={key++}
                                              value={this.state.user.location}
                                              options={this.props.locations}
                                              optionLabel='name'
                                              onChange={(event) => {
                                                  this.onChange({
                                                      owner: 'user.location',
                                                      value: event.value
                                                  })
                                              }}
                                              autoWidth={true}
                                    />
                                </div>
                            </div>
                        </Panel>
                        <Panel header='Speciality' style={{marginTop: '5px'}}>
                            <PickList sourceHeader="Possible"
                                      style={{width: '100%', backgroundColor: 'white'}}
                                      className={specialityClassName}
                                      targetHeader="Actual"
                                      itemTemplate={this.spTemplate}
                                      showSourceControls={false}
                                      showTargetControls={false}
                                      source={this.state.spSource}
                                      target={this.state.spTarget}
                                      onChange={(event) => {
                                          let specialisms = [];

                                          event.target.forEach((specialism) => {
                                              specialisms.push({id: specialism.id})
                                          });
                                          this.onChange({owner: 'user.specialisms', value: specialisms});

                                          this.setState({
                                              spSource: event.source,
                                              spTarget: event.target
                                          })
                                      }}
                            />
                        </Panel>
                    </div>
                </div>
            </div>);
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({action: Actions.CLEAR_USER_ADD, id: this.props.id});
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getTreatmentFavs: () => dispatch(getResource(RES_HOUSEKEEPING_FAVS.GET, {})),
        addUser: (user, specialisms) => dispatch(addUser(user, specialisms)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        genders: state.dropDowns.genders,
        specialisms: state.dropDowns.specialisms,
        roles: state.dropDowns.roles,

        appointmentDiaryTypesLoaded: state.housekeeping.appointmentDiaryTypesLoaded,
        appointmentTypes: state.housekeeping.appointmentDiaryTypes.appointmentTypes,

        recallTemplates: [],

        treatmentFavouritesLoaded: state.housekeeping.treatmentFavouritesLoaded,
        treatmentFavourites: state.housekeeping.treatmentFavourites,

        result: state.users.result,

        currentState: state.stateManagement[ownProps.id],
    }
};

const AddUser = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddUser);

export default AddUser;
