import React from 'react';
import {PDFBaseComponent} from "../../../PDFBaseComponent";
import {getReportPDF} from "../../../../actions/reports";
import {setState, stateRequest} from "../../../../actions/stateManagement";
import {RES_TREATMENT_PLAN_BY_ID} from "../../../../actions/treatmentPlanning";
import {SM_CLIENT_TREATMENT_PLAN_PDF} from "../../../Client/Constants";
import {ProgressBar} from "primereact/progressbar";
import {TextTreatmentPlan} from "../../../../Messages";
import {connect} from "react-redux";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../../Housekeeping/Constants";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Button} from "primereact/components/button/Button";
import {ICON_DOWNLOAD} from "../../../../icons";

class ConnectedTreatmentPlanPDF extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: this.props.stateManagementId,
                pdfType: SM_CLIENT_TREATMENT_PLAN_PDF.id,
                numPages: null,
            }
        }
        this.onExit = this.onExit.bind(this);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_TREATMENT_PLAN_BY_ID.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onExit() {

        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: this.props.id});
    }

    createPDFToolBar(tooltip, filename) {

        return (
            <Toolbar
                left={
                    <Button tooltip={`Download ${tooltip} Report`}
                            tooltipOptions={{position: 'top'}}
                            onClick={() => this.onDownload(filename)}
                            icon={ICON_DOWNLOAD}/>
                }
                right={
                    <Button className="p-button-danger"
                            icon="fa fa-times"
                            onClick={this.onExit}/>
                }
            />
        )
    }

    render() {

        if (!this.state.PDFLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (
            <div className="p-col-12 p-lg-12">

                {this.createPDFToolBar(TextTreatmentPlan, SM_CLIENT_TREATMENT_PLAN_PDF.filename)}
                {this.createPDFContainer(SM_CLIENT_TREATMENT_PLAN_PDF.label)}
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:

                const source = {
                    id: this.state.stateManagementId,
                    action: RES_TREATMENT_PLAN_BY_ID.CLEAR_PDF.action,
                    saveState: false,
                    saveObjects: false,
                    smRef: this.props.planId,
                };

                this.props.stateRequest(source);
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    const loadedIndex = `${SM_CLIENT_TREATMENT_PLAN_PDF.loaded}_${ownProps.planId}.pdf`;
    const index = `${SM_CLIENT_TREATMENT_PLAN_PDF.id}_${ownProps.planId}.pdf`;

    return {
        message: state.stateManagement.message,

        PDFLoaded: state.treatmentPlanning[loadedIndex],
        pdfBuffer: state.treatmentPlanning[index],

        stateManagementId: index,
        currentState: state.stateManagement[index],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReportPDF(RES_TREATMENT_PLAN_BY_ID.PDF, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const TreatmentPlanPDF = connect(mapStateToProps, mapDispatchToProps)(ConnectedTreatmentPlanPDF);

export default TreatmentPlanPDF;
