import {AdultPreMolar} from "../Parts/AdultPreMolar";
import {AdultIncisor} from "../Parts/AdultIncisor";
import {AdultMolar} from "../Parts/AdultMolar";
import {AdultPreMolarBicusp} from "../Parts/AdultPreMolarBicusp";
import {_getCommandContextMenuItems} from "../Parts/ChartingUtils";
import {Onlay} from "../Model/Constants";
import {ChartItemBase} from "./ChartItemBase";

export class ComOnlay extends ChartItemBase {

    constructor() {
        super();

        this.state = {};
        this.target = null;
        this.mouth = null;
        this.adultTeeth = null;
        this.tooth = null;

        this.setTarget = this.setTarget.bind(this);
        this.executeTreatment = this.executeTreatment.bind(this);
        this.getCommandContextMenuItems = this.getCommandContextMenuItems.bind(this);
    };

    toString() {
        return Onlay;
    }

    canExecute(target) {

        try {

            return (target instanceof AdultIncisor) ||
                (target instanceof AdultMolar) ||
                (target instanceof AdultPreMolar) ||
                (target instanceof AdultPreMolarBicusp);
        } catch (e) {
            return false;
        }
    }

    executeBase(mouth, target) {
    }

    executeTreatment(mouth, target) {
    }

    executeBaseOption(mouth, target, material) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, Onlay, true);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;
        entry.material = material;

        mouth.props.addChartEntry(entry);
    }

    executeTreatmentOption(mouth, target, material) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, Onlay, false);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;
        entry.material = material;

        mouth.props.addTreatmentHandler(entry);
    }

    setTarget(target) {
        this.target = target;
    }

    getCommandContextMenuItems(mouth) {
        return _getCommandContextMenuItems(mouth, this)
    }

    clear() {
    }
}
