import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import {ac} from "../../../index";

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';

import {Address} from '../../DynamicItems/Address';
import {Contact} from '../../DynamicItems/Contact';
import {Name} from '../ReferrerDetails/Constants';
import * as DefaultData from "../DefaultData";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import * as Actions from "../../../actions";
import {addReferrer, RES_REFERRER_DETAILS} from "../../../actions/findReferrers";
import {Organisation} from "../ReferrerDetails/Sections/Organisation";
import {getResource as getHKResource, RES_HOUSEKEEPING_OCCS} from "../../../actions/housekeeping";
import {HELP_ADD_REFERRER, setState, stateRequest} from "../../../actions/stateManagement";
import {
    HM_ReferrerSave,
    HM_ReferrerSaveNOK,
    HM_ReferrerSaveOK,
    REF_ADD_RESULT_EXISTS,
    REF_ADD_RESULT_FAIL,
    REF_ADD_RESULT_IN_USE,
    REF_ADD_RESULT_OK,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {
    ICON_HELP,
    ICON_SAVE_DISABLED,
    ICON_SAVE_ENABLED,
} from "../../../icons";
import {ProgressBar} from "primereact/progressbar";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";

class ConnectedAddReferrer extends Component {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                referrer: _.cloneDeep(DefaultData.referrerData(ac.getMcId())),
                canSave: {status: false, activeIndex: 0, source: Actions.CLEAR_REFERRER_ADD},

                orgMandatory: true,
                nameMandatory: true,

                [HM_ReferrerSave.id]: false,
                [HM_ReferrerSaveOK.id]: false,
                [HM_ReferrerSaveNOK.id]: false,
                showErrorMessage: '',
            }
        }
        this.exitState = TAB_PARENT_CHANGE;

        this.onChange = this.onChange.bind(this);
        this.onAddReferrerSave = this.onAddReferrerSave.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onExit = this.onExit.bind(this);
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getDropDowns();
            this.props.getOccupations();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_DROPDOWNS:
                    break;

                case Actions.RECEIVE_REFERRER_ADD:

                    switch (this.props.result) {
                        case REF_ADD_RESULT_OK:
                            this.setState({[HM_ReferrerSaveOK.id]: true});
                            break;
                        case REF_ADD_RESULT_IN_USE:
                            this.setState({[HM_ReferrerSaveNOK.id]: true, showErrorMessage: 'Duplicate referrer'});
                            break;
                        case REF_ADD_RESULT_EXISTS:
                            this.setState({
                                [HM_ReferrerSaveNOK.id]: true,
                                showErrorMessage: 'Referrer name already exists'
                            });
                            break;
                        case REF_ADD_RESULT_FAIL:
                            this.setState({
                                [HM_ReferrerSaveNOK.id]: true,
                                showErrorMessage: 'An error occurred during the referrer creation process'
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onShowDialogs() {

        if (this.state[HM_ReferrerSave.id]) {

            const footer = <div>
                <Button label="Yes" icon="fa fa-check" onClick={() => {
                    this.onAddReferrerSave(true)
                }}/>
                <Button label="No" icon="fas fa-times" onClick={this.onExit}/>
            </div>;

            return (
                <Dialog header={HM_ReferrerSave.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_ReferrerSave.id]: false})
                        }}
                >

                    <div className="p-grid p-fluid">
                        <label>Confirm Referrer Creation</label>
                    </div>
                </Dialog>
            )
        } else if (this.state[HM_ReferrerSaveOK.id]) {

            const footer = <Button label="OK" icon="fas fa-times" onClick={() => {
                this.onExit();
            }}/>;

            return (
                <Dialog header={HM_ReferrerSaveOK.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_ReferrerSaveOK.id]: false})
                        }}
                >
                    {HM_ReferrerSaveOK.message}
                </Dialog>
            )
        } else if (this.state[HM_ReferrerSaveNOK.id]) {

            const footer = <Button label="OK" icon="fas fa-times" onClick={() => {
                this.setState({[HM_ReferrerSaveNOK.id]: false})
            }}/>;

            return (
                <Dialog header={HM_ReferrerSaveNOK.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_ReferrerSaveNOK.id]: false})
                        }}
                >
                    {this.state.showErrorMessage}
                </Dialog>
            )
        }
        return null;
    }

    onAddReferrerSave(close) {

        this.props.addReferrer(this.state.referrer);
    }

    onClose() {
        if (this.state.canSave.status) {
            this.setState({[HM_ReferrerSave.id]: true});
            this.props.setState(this.props.id, null);
        } else {
            this.onExit();
        }
    }

    onExit() {
        this.exitState = TAB_EXIT;
        this.props.onTabUpdate({key: this.props.id}, false);
        this.props.onCloseClick({key: this.props.id});
    }

    onChange(event) {
        const state = {...this.state};

        if (event.owner.endsWith('postcode')) {
            _.set(state, event.owner, event.value.toUpperCase());
        } else {
            _.set(state, event.owner, event.value);
        }

        const {workTelephone, title, firstName, lastName, address, companyName} = this.state.referrer;

        const canSave = workTelephone.trim() !== '' && ((title.id !== null && firstName.trim() !== '' && lastName.trim() !== '') || (companyName.trim() !== '')) && address.postcode.trim() !== '';
        const orgMandatory = companyName.trim() === '' && (title.id !== null && firstName.trim() === '' && lastName.trim() === '');
        const nameMandatory = (title.id !== null && firstName.trim() === '' || lastName.trim() === '') && companyName.trim() === '';

        _.set(state, 'canSave.status', canSave);
        _.set(state, 'orgMandatory', orgMandatory);
        _.set(state, 'nameMandatory', nameMandatory);

        this.setState(state, () => {
            this.props.onTabUpdate({key: this.props.id}, canSave);
            this.props.setState(this.props.id, this.state);
        });
    }

    render() {

        if (!this.props.dropDownsLoaded || !this.props.occupationsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const canSave = this.state.canSave.status;

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        return (

            <div id="detailPanel">

                {this.onShowDialogs()}

                <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate2((e) => this.onAddReferrerSave(false), saveIcon, !canSave, 'Add new referrer', 1)}
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight((e) => window.open(HELP_ADD_REFERRER), ICON_HELP, 'Knowledge Base', 1, true)}
                            {tb_boilerPlateRight(this.onClose, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 2)}
                        </React.Fragment>}
                />

                <div className="p-grid" style={{paddingTop: '5px'}}>
                    <div className="p-col-6">
                        <Organisation onChange={this.onChange}
                                      target='referrer'
                                      referrer={this.state.referrer}
                                      occupations={this.props.occupations}
                                      mandatory={this.state.orgMandatory}
                        />
                    </div>
                    <div className="p-lg-6 p-md-6">
                        <Contact onChange={this.onChange}
                                 target='referrer'
                                 referrer={this.state.referrer}
                                 includeWorkPhone={true}
                                 workPhoneMandatory={true}
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-lg-6 p-md-12">
                        <Name onChange={this.onChange}
                              target='referrer'
                              referrer={this.state.referrer}
                              includeOptionalInfo={false}
                              titles={this.props.titles}
                              mandatory={this.state.nameMandatory}
                        />
                    </div>
                    <div className="p-lg-6 p-md-12">
                        <Address onChange={this.onChange}
                                 target='referrer.address'
                                 referrer={this.state.referrer}
                                 header='Main Address'
                                 postCodeMandatory={true}
                                 countries={this.props.countries}
                        />
                    </div>
                </div>
            </div>);
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({action: Actions.CLEAR_REFERRER_ADD, id: this.props.id});
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        specialisms: state.dropDowns.specialisms,

        occupationsLoaded: state.housekeeping.occupationsLoaded,
        occupations: state.housekeeping.occupations,

        result: state.referrers.result,
        referrer: state.referrers.referrer,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getOccupations: () => dispatch(getHKResource(RES_HOUSEKEEPING_OCCS.GET, {})),
        addReferrer: (referrer) => dispatch(addReferrer(RES_REFERRER_DETAILS.ADD, referrer)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddReferrer = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddReferrer);

export default AddReferrer;
