import React from 'react';

import {dropDownArray} from './OnChangeUtils';

const theWorks = (props) => {

    const readOnly = props.readOnly;

    const newProps = {...props};

    newProps.name = 'BPEScore';

    if (!Boolean(newProps.categories.BEWEScore)) {
        newProps.categories.push(
            {
                category: "BPEScore",
                elements: [
                    {id: '_Empty', name: '-'},
                    {id: '_0', name: '0'},
                    {id: '_1', name: '1'},
                    {id: '_2', name: '2'},
                    {id: '_3', name: '3'},
                    {id: '_3furc', name: '3*'},
                    {id: '_4', name: '4'},
                    {id: '_4furc', name: '4*'}],
            }
        );
    }
    return (
        <div id="cpitn-row" className="p-dropdown-auto-width">
            <div>
                {dropDownArray(newProps, 'bpeScore1', 1, readOnly, false)}
                {dropDownArray(newProps, 'bpeScore4', 4, readOnly, false)}
            </div>
            <div>
                {dropDownArray(newProps, 'bpeScore2', 2, readOnly, false)}
                {dropDownArray(newProps, 'bpeScore5', 5, readOnly, false)}
            </div>
            <div>
                {dropDownArray(newProps, 'bpeScore3', 3, readOnly, false)}
                {dropDownArray(newProps, 'bpeScore6', 6, readOnly, false)}
            </div>
        </div>
    )
};

export const CPITN = (props) => {

    return (
        <div>
            {theWorks(props)}
        </div>
    );
};
