import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from 'primereact/components/inputtext/InputText';

import {
    PAY_TYPE_CC,
    PAY_TYPE_CHEQUE,
    PAY_TYPE_DC,
    PAY_TYPES,
    PD_STATE_RECEIPT_CD_CARD,
    PD_STATE_RECEIPT_CHEQUE,
    PD_STATE_RECEIPT_NARRATIVE,
    TB_PATIENT_SALES_NHS,
    TB_RECEIPT_PAYMENT,
    TB_RECEIPT_PAYMENT_NHS
} from "../../../PatientDetails/Constants";
import {calendar, dropDown, dropDownPlain, outputCurrency} from "../../../../PatientDynamicItems/OnChangeUtils";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import _ from "lodash";

export const ReceiptAmount = (props) => {

    const payProps = {
        onChange: props.onChange,
        paymentTypesOptions: PAY_TYPES,
        providers: props.providers,
        accountGroups: props.accountGroups,
        target: 'receipt',
        receipt: props.receipt,
    };

    const footer =
        <div>
            <Button label='Back'
                    icon="fa fa-chevron-left"
                    disabled={true}
                    onClick={() => {
                    }}/>
            <Button label='Next'
                    icon="fa fa-chevron-right"
                    disabled={props.receipt.paymentType === null}
                    onClick={() => {
                        switch (props.receipt.paymentType) {
                            case PAY_TYPE_CHEQUE.value:
                                props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_CHEQUE});
                                break;
                            case PAY_TYPE_CC.value:
                            case PAY_TYPE_DC.value:
                                props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_CD_CARD});
                                break;
                            default:
                                props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_NARRATIVE});
                                break;
                        }
                    }}/>
            <Button label='Issue Receipt'
                    icon={ICON_OK}
                    disabled={!props.finishEnabled}
                    onClick={() => {
                        props.onFinish(props.receipt, false);
                    }}/>
            <Button label='Issue Print Receipt'
                    icon={ICON_OK}
                    disabled={!props.finishEnabled}
                    onClick={() => {
                        props.onFinish(props.receipt, true);
                    }}/>
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.ownerId)}/>
        </div>;

    const header = props.ownerId === TB_PATIENT_SALES_NHS.id ? TB_RECEIPT_PAYMENT_NHS.header : TB_RECEIPT_PAYMENT.header;

    const optionsList = _.get(props, 'providers');
    const value = _.find(optionsList, provider => provider.id === _.get(props, `receipt.raisedBy`).id);
    const className = (!value && false) ? 'p-invalid' : '';

    return (
        <Dialog style={{width: "50%"}}
                header={header}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={e => props.onHideDialog(props.ownerId)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <label>{TB_RECEIPT_PAYMENT.text}</label>
                </div>

                <div className="p-col-3">
                    <label>Amount (£)</label>
                </div>
                <div className="p-col-9">
                    <InputText type="text"
                               disabled={false}
                               style={{fontSize: 'x-large', textAlign: 'right'}}
                               value={outputCurrency(props.salesTotal)}
                               onChange={(e) => {
                               }}
                    />
                </div>
                <div className="p-col-3">
                    <label>Type</label>
                </div>
                <div className="p-col-9">
                    {dropDownPlain(payProps, 'paymentTypesOptions', 'paymentType', 'label', false, false)}
                </div>
                <div className="p-col-3">
                    <label>Date Taken</label>
                </div>
                <div className="p-col-9">
                    {calendar(payProps, 'raisedOn', false, false)}
                </div>
                <div className="p-col-3">
                    <label>Taken By</label>
                </div>
                <div className="p-col-9">
                    <Dropdown value={value}
                              className={className}
                              optionLabel={'fullName'}
                              options={optionsList}
                              disabled={false}
                              onChange={(e) => {
                                  props.onChange({owner: `receipt.raisedBy`, value: e.value})
                              }}
                    />
                </div>
                <div className="p-col-3">
                    <label>Allocated To (Account Group)</label>
                </div>
                <div className="p-col-9">
                    {dropDown(payProps, 'accountGroups', 'accountGroup', 'groupName', false, false)}
                </div>
            </div>
        </Dialog>
    )
};