import {AdultPreMolar} from "../Parts/AdultPreMolar";
import {AdultIncisor} from "../Parts/AdultIncisor";
import {AdultMolar} from "../Parts/AdultMolar";
import {AdultPreMolarBicusp} from "../Parts/AdultPreMolarBicusp";
import * as TC from "../Model/Constants";
import {ChippedTooth} from "../Model/Constants";
import {ChartItemBase} from "./ChartItemBase";

export class ComChipped extends ChartItemBase {

    constructor() {
        super();

        this.state = {};
        this.target = null;

        this.setTarget = this.setTarget.bind(this);
        this.executeTreatment = this.executeTreatment.bind(this);
    };

    toString() {
        return ChippedTooth;
    }

    canExecute(target) {

        try {

            return (target instanceof AdultIncisor) ||
                (target instanceof AdultMolar) ||
                (target instanceof AdultPreMolar) ||
                (target instanceof AdultPreMolarBicusp)
        } catch (e) {
            return false;
        }
    }

    executeBase(mouth, target) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, TC.ChippedTooth, true);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;

        mouth.props.addChartEntry(entry);
    }

    executeTreatment(mouth, target) {

        // set the chart entry
        let entry = this.createNewEntry(mouth, TC.ChippedTooth, false);
        entry.position = target.pos.perm;
        entry.jaw = target.jaw;

        mouth.props.addTreatmentHandler(entry);
    }

    setTarget(target) {
        this.target = target;
    }

    getCommandContextMenuItems() {
        return [];
    }

    clear() {
    }
}
