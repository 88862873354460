import _ from 'lodash';
import * as Actions from '../actions/index';

const initialState = {

    outstandingTasksLoaded: false,
    outstandingTasks: [],

    taskLoaded: false,
    task: null,
};

export const tasks = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.RECEIVE_TASKS_OUTSTANDING:

                return {
                    ...state,
                    outstandingTasksLoaded: true,
                    outstandingTasks: action.payload,
                };

            case Actions.REQUEST_TASK_DIARY:

                return {
                    ...state,
                    taskLoaded: false,
                    task: null,
                };

            case Actions.RECEIVE_TASK_DIARY:

                return {
                    ...state,
                    taskLoaded: true,
                    task: action.payload,
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};