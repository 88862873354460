import React from 'react';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {Panel} from 'primereact/components/panel/Panel';
import {PickList} from 'primereact/components/picklist/PickList';

import {Address} from '../../../DynamicItems/Address';
import {Contact} from '../../../DynamicItems/Contact';
import {Name} from '../../../UserDynamicItems/Name';
import {setState} from "../../../../actions/stateManagement";
import {getDropDowns, RES_getDropDowns} from "../../../../actions/dropDowns";
import {connect} from "react-redux";
import _ from "lodash";
import {getResource as getUserResource, RES_USER_DETAILS} from "../../../../actions/users";
import {
    CONST_ASK,
    CONST_ASK_LABEL,
    CONST_FEMALE,
    CONST_FEMALE_LABEL, CONST_INDETERMINATE, CONST_INDETERMINATE_LABEL,
    CONST_MALE,
    CONST_MALE_LABEL,
    dropDown,
    radioButton
} from "../../../PatientDynamicItems/OnChangeUtils";
import {getResource, RES_HOUSEKEEPING_ADETS, RES_HOUSEKEEPING_FAVS} from "../../../../actions/housekeeping";
import {BaseComponent} from "../../../BaseComponent";
import {getUserIds} from "../Utils";
import {HM_UserAddPassword} from "../../../../Constants";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Password} from "primereact/password";
import crypto from "crypto";
import * as Actions from "../../../../actions";
import {ProgressBar} from "primereact/progressbar";
import {ColorPicker} from "primereact/components/colorpicker/ColorPicker";


export class ConnectedPersonal extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
            this.state.spSource = props.specialisms;
        } else
            this.state = {

                stateManagementId: props.userDataId,

                canSave: {status: false, count: 0},
                init: false,

                userLoaded: false,
                user: null,

                [HM_UserAddPassword.id]: false,
                showError: false,
                showErrorMessage: '',

                spSource: [],
                spTarget: [],
            };

        this.onChange = this.onChange.bind(this);
        this.onNHSUpdate = this.onNHSUpdate.bind(this);
        this.onShowDialogs = this.onShowDialogs.bind(this);
        this.onSpChange = this.onSpChange.bind(this);
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            if (!this.props.dropDownsLoaded) {
                this.props.getDropDowns();
                this.props.getAppointmentDiaryTypes();
                this.props.getTreatmentFavs();
            } else {
                this.props.getUserDetails({id: this.props.userId});
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {

            if (this.props.message !== prevProps.message) {

                switch (this.props.message.type) {

                    case Actions.RECEIVE_DROPDOWNS:
                        this.props.getUserDetails({id: this.props.userId});
                        break;
                    case Actions.RECEIVE_USER_DETAILS:
                        const refMinusUsers = this.props.specialisms === undefined ? [] : _.differenceWith(this.props.specialisms, this.props.userData.specialisms, (spec1, spec2) => spec1.id === spec2.id);
                        this.setState({
                            user: this.props.userData,
                            userLoaded: true,
                            spSource: refMinusUsers,
                            spTarget: this.props.userData.specialisms
                        });
                        break;
                    default:
                        break;
                }
            }
        }
    }

    onNHSUpdate(event) {
    };

    onChange(event) {

        const state = {...this.state};

        if (event.owner.endsWith('postcode')) {
            _.set(state, event.owner, event.value.toUpperCase());
        } else if (event.owner === 'colour') {

            _.set(state, 'user.red', event.value.r);
            _.set(state, 'user.green', event.value.g);
            _.set(state, 'user.blue', event.value.b);

        } else {
            _.set(state, event.owner, event.value);
        }
        _.set(state, 'canSave.status', true);

        if (state.user.title !== null && event.owner === 'user.title') {

            _.set(state, 'user.title', event.value);

            switch (event.value.genderType) {
                case CONST_FEMALE_LABEL:
                    _.set(state, 'user.gender', CONST_FEMALE);
                    break;
                case CONST_MALE_LABEL:
                    _.set(state, 'user.gender', CONST_MALE);
                    break;
                case CONST_ASK_LABEL:
                    _.set(state, 'user.gender', CONST_ASK);
                    break;
                case CONST_INDETERMINATE_LABEL:
                    _.set(state, 'user.gender', CONST_INDETERMINATE);
                    break;
                default:
                    break;
            }
        }
        this.setState(state, () => {
            this.props.onUserChange(true);
        });
    }

    onSpChange(event) {

        const state = {...this.state};

        const spSource = _.orderBy(event.source, 'description', 'asc');
        const spTarget = _.orderBy(event.target, 'description', 'asc');

        _.set(state, 'spSource', spSource);
        _.set(state, 'spTarget', spTarget);

        _.set(state, 'user.specialisms', spTarget);

        this.setState(state, () => {
            this.props.onUserChange(true);
        });
    }

    spTemplate(specialism) {
        return (
            <div className="p-helper-clearfix">
                <div style={{fontSize: 'small'}}>{specialism.description}</div>
            </div>
        );
    };

    setPassword(password) {

        // Creating a unique salt for a particular user
        const salt = crypto.randomBytes(16).toString('hex');

        // Hashing user's salt and password with 1000 iterations, 64 length and sha512 digest
        const hash = crypto.pbkdf2Sync(password, salt, 1000, 64, `sha512`).toString(`hex`);
        return {hash, salt};
    };

    onShowDialogs() {

        if (this.state[HM_UserAddPassword.id]) {

            const footer = <div>
                <Button label="OK" icon="fa fa-check" onClick={() => {
                    this.setState({[HM_UserAddPassword.id]: false});
                }}/>
                <Button label="Cancel" icon="fas fa-times" onClick={() => {
                    this.setState({[HM_UserAddPassword.id]: false});
                }
                }/>
            </div>;

            return (
                <Dialog header={HM_UserAddPassword.header}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_UserAddPassword.id]: false})
                        }}
                >

                    <div className="p-grid p-fluid">
                        <div className="p-col-3">
                            <label>Password</label>
                        </div>
                        <div className="p-col-9">
                            <Password feedback={false}
                                      onChange={(e) => {
                                          const {hash, salt} = this.setPassword(e.target.value);
                                          this.onChange({owner: 'user.salt', value: salt});
                                          this.onChange({owner: 'user.password', value: hash});
                                      }}/>
                        </div>
                    </div>
                </Dialog>
            )
        }
    }

    render() {

        const {dropDownsLoaded, appointmentDiaryTypesLoaded} = this.props;

        if (!this.state.userLoaded || !dropDownsLoaded || !appointmentDiaryTypesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const specialisms = this.state.user.specialisms === undefined ? [] : this.state.user.specialisms;

        const specialityClassName = specialisms.length === 0 ? 'p-invalid' : '';

        const props = {
            onChange: this.onChange,
            roles: this.props.roles,
            appointmentTypes: this.props.appointmentTypes,
            target: 'user',
            user: this.state.user,
        };

        const colorValue = {r: this.state.user.red, g: this.state.user.green, b: this.state.user.blue};

        let key = Math.floor(Math.random() * 1000);

        return (
            <div className="p-grid p-fluid p-col-12" style={{fontSize: 'small'}}>

                {this.onShowDialogs()}

                <div className="p-col-6">
                    <Name onChange={this.onChange}
                          target='user'
                          showPasswordButton={true}
                          showPasswordCallback={() => {
                              this.setState({[HM_UserAddPassword.id]: true});
                          }}
                          {...this.state}
                          {...this.props}
                    />
                    <Address onChange={this.onChange}
                             style={{marginTop: '5px'}}
                             target='user.contactDetails'
                             header='Main Address'
                             {...this.state}
                             {...this.props}
                    />
                </div>
                <div className="p-lg-6 p-md-6">
                    <Contact onChange={this.onChange}
                             target='user'
                             includeHomePhone={true}
                             includeWorkPhone={true}
                             includeOtherPhone={false}
                             {...this.state}
                             {...this.props}
                    />
                    <Panel header='Miscellaneous' style={{marginTop: '5px'}}>
                        <div className="p-grid form-group">

                            <div className="p-col-2">
                                <label htmlFor="title">Role</label>
                            </div>
                            <div className="p-col-4">
                                {dropDown(props, 'roles', 'myRole', 'description', true)}
                            </div>

                            <div className="p-col-2">
                                <label htmlFor="title">Appointment Type</label>
                            </div>
                            <div className="p-col-4">
                                {dropDown(props, 'appointmentTypes', 'defaultAppointmentType', 'description', false)}
                            </div>

                            <div className="p-col-2">
                                <label htmlFor="title">Recall Template</label>
                            </div>
                            <div className="p-col-4">
                                <Dropdown value={this.state.user.recallTemplateId}
                                          options={this.props.recallTemplates}
                                          optionLabel='description'
                                          onChange={(event) => {
                                              this.onChange({
                                                  owner: 'user.recallTemplateId',
                                                  value: event.value
                                              })
                                          }}
                                          autoWidth={true}
                                />
                            </div>

                            <div className="p-col-2">
                                <label htmlFor="title">Favourite List</label>
                            </div>
                            <div className="p-col-4">
                                <Dropdown key={key++}
                                          value={this.state.user.treatmentFavorite}
                                          options={this.props.treatmentFavourites}
                                          optionLabel='description'
                                          onChange={(event) => {
                                              this.onChange({
                                                  owner: 'user.treatmentFavorite',
                                                  value: event.value
                                              })
                                          }}
                                          autoWidth={true}
                                />
                            </div>

                            <div className="p-col-2">
                                {radioButton(props, 'ownsPatients', 'Owns Patients')}
                            </div>
                            <div className="p-col-4">
                                <ColorPicker format='rgb'
                                             value={colorValue}
                                             onChange={(event) => {
                                                 this.onChange({
                                                     owner: 'colour',
                                                     value: event.value
                                                 })
                                             }}
                                             disabled={false}
                                />
                            </div>

                            <div className="p-col-2">
                                <label htmlFor="title">Default Location</label>
                            </div>
                            <div className="p-col-4">
                                <Dropdown key={key++}
                                          value={this.state.user.location}
                                          options={this.props.locations}
                                          optionLabel='name'
                                          onChange={(event) => {
                                              this.onChange({
                                                  owner: 'user.location',
                                                  value: event.value
                                              })
                                          }}
                                          autoWidth={true}
                                />
                            </div>
                        </div>
                    </Panel>
                    <Panel header='Speciality' style={{marginTop: '5px'}}>
                        <PickList sourceHeader="Possible"
                                  style={{width: '100%', backgroundColor: 'white'}}
                                  className={specialityClassName}
                                  targetHeader="Actual"
                                  itemTemplate={this.spTemplate}
                                  showSourceControls={false}
                                  showTargetControls={false}
                                  source={this.state.spSource}
                                  target={this.state.spTarget}
                                  onChange={this.onSpChange}
                        />
                    </Panel>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        userLoaded, userDataId,
    } = getUserIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        receiveId: state.dropDowns.receiveId,
        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        genders: state.dropDowns.genders,
        specialisms: state.dropDowns.specialisms,
        roles: state.dropDowns.roles,
        locations: state.dropDowns.locations,

        userLoaded,
        userDataId,
        userData: state.users[userDataId],

        appointmentDiaryTypesLoaded: state.housekeeping.appointmentDiaryTypesLoaded,
        appointmentTypes: state.housekeeping.appointmentDiaryTypes.appointmentTypes,

        treatmentFavouritesLoaded: state.housekeeping.treatmentFavouritesLoaded,
        treatmentFavourites: state.housekeeping.treatmentFavourites,

        recallTemplates: [],

        saved: state.users.userDataSaved,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[userDataId],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getTreatmentFavs: () => dispatch(getResource(RES_HOUSEKEEPING_FAVS.GET, {})),
        getUserDetails: (params) => dispatch(getUserResource(RES_USER_DETAILS.GET, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const Personal = connect(mapStateToProps, mapDispatchToProps)(ConnectedPersonal);

export default Personal;