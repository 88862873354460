import React from 'react';
import _ from 'lodash';

import {TeethTickBox} from './TeethTickBox';
import {CHART_TYPE_DFPROG, CHART_TYPE_MIXED, CHART_TYPE_PERMANENT, CHART_TYPE_PRIMARY} from "./OrthoIntraOral2";

export const EmptyPrimaryTeeth = [
    [
        {id: 'URE', number: 5, pres: false, upper: true, text: 'E'},
        {id: 'URD', number: 4, pres: false, upper: true, text: 'D'},
        {id: 'URC', number: 3, pres: false, upper: true, text: 'C'},
        {id: 'URB', number: 2, pres: false, upper: true, text: 'B'},
        {id: 'URA', number: 1, pres: false, upper: true, text: 'A'},
        {id: 'ULA', number: 1, pres: false, upper: true, text: 'A'},
        {id: 'ULB', number: 2, pres: false, upper: true, text: 'B'},
        {id: 'ULC', number: 3, pres: false, upper: true, text: 'C'},
        {id: 'ULD', number: 4, pres: false, upper: true, text: 'D'},
        {id: 'ULE', number: 5, pres: false, upper: true, text: 'E'},],
    [
        {id: 'LRE', number: 5, pres: false, upper: false, text: 'E'},
        {id: 'LRD', number: 4, pres: false, upper: false, text: 'D'},
        {id: 'LRC', number: 3, pres: false, upper: false, text: 'C'},
        {id: 'LRB', number: 2, pres: false, upper: false, text: 'B'},
        {id: 'LRA', number: 1, pres: false, upper: false, text: 'A'},
        {id: 'LLA', number: 1, pres: false, upper: false, text: 'A'},
        {id: 'LLB', number: 2, pres: false, upper: false, text: 'B'},
        {id: 'LLC', number: 3, pres: false, upper: false, text: 'C'},
        {id: 'LLD', number: 4, pres: false, upper: false, text: 'D'},
        {id: 'LLE', number: 5, pres: false, upper: false, text: 'E'},],
    ];

export const EmptyPermanentTeeth = [
    [
        {id: 'UR8', number: 8, pres: false, upper: true, text: '8'},
        {id: 'UR7', number: 7, pres: false, upper: true, text: '7'},
        {id: 'UR6', number: 6, pres: false, upper: true, text: '6'},
        {id: 'UR5', number: 5, pres: false, upper: true, text: '5'},
        {id: 'UR4', number: 4, pres: false, upper: true, text: '4'},
        {id: 'UR3', number: 3, pres: false, upper: true, text: '3'},
        {id: 'UR2', number: 2, pres: false, upper: true, text: '2'},
        {id: 'UR1', number: 1, pres: false, upper: true, text: '1'},
        {id: 'UL1', number: 1, pres: false, upper: true, text: '1'},
        {id: 'UL2', number: 2, pres: false, upper: true, text: '2'},
        {id: 'UL3', number: 3, pres: false, upper: true, text: '3'},
        {id: 'UL4', number: 4, pres: false, upper: true, text: '4'},
        {id: 'UL5', number: 5, pres: false, upper: true, text: '5'},
        {id: 'UL6', number: 6, pres: false, upper: true, text: '6'},
        {id: 'UL7', number: 7, pres: false, upper: true, text: '7'},
        {id: 'UL8', number: 8, pres: false, upper: true, text: '8'},],
    [
        {id: 'LR8', number: 8, pres: false, upper: false, text: '8'},
        {id: 'LR7', number: 7, pres: false, upper: false, text: '7'},
        {id: 'LR6', number: 6, pres: false, upper: false, text: '6'},
        {id: 'LR5', number: 5, pres: false, upper: false, text: '5'},
        {id: 'LR4', number: 4, pres: false, upper: false, text: '4'},
        {id: 'LR3', number: 3, pres: false, upper: false, text: '3'},
        {id: 'LR2', number: 2, pres: false, upper: false, text: '2'},
        {id: 'LR1', number: 1, pres: false, upper: false, text: '1'},
        {id: 'LL1', number: 1, pres: false, upper: false, text: '1'},
        {id: 'LL2', number: 2, pres: false, upper: false, text: '2'},
        {id: 'LL3', number: 3, pres: false, upper: false, text: '3'},
        {id: 'LL4', number: 4, pres: false, upper: false, text: '4'},
        {id: 'LL5', number: 5, pres: false, upper: false, text: '5'},
        {id: 'LL6', number: 6, pres: false, upper: false, text: '6'},
        {id: 'LL7', number: 7, pres: false, upper: false, text: '7'},
        {id: 'LL8', number: 8, pres: false, upper: false, text: '8'},],
    ];

const thePrimaryWorks = (props, chartType) => {

    let teeth = _.get(props, _.get(props, 'teethMixedMissing'));

    if (teeth === undefined)
       teeth = EmptyPrimaryTeeth;
    
    return (
        <div id="tooth-row" style={{padding: '10px', backgroundColor: '#eee', float: 'right'}}>
            <TeethTickBox emptyTeeth={EmptyPrimaryTeeth}
                          teeth={teeth}
                          onChange={props.onChange}
                          owner={_.get(props, 'teethMixedMissing')}
                          chartType={chartType}
            />
        </div>
    )
};

const theMixedWorks = (props, chartType) => {

    let primaryTeeth = _.get(props, _.get(props, 'teethMixedMissing'));
    let permanentTeeth = _.get(props, _.get(props, 'teethMissing'));

    if (primaryTeeth === undefined)
        primaryTeeth = EmptyPrimaryTeeth;

    if (permanentTeeth === undefined)
        permanentTeeth = EmptyPermanentTeeth;

    return (
        <div id="tooth-row" style={{padding: '10px', backgroundColor: '#eee', float: 'right'}}>
            <TeethTickBox emptyPrimaryTeeth={EmptyPrimaryTeeth}
                          emptyPermanentTeeth={EmptyPermanentTeeth}
                          primaryTeeth={primaryTeeth}
                          permanentTeeth={permanentTeeth}
                          onChange={props.onChange}
                          owner={_.get(props, 'teethMixedMissing')}
                          chartType={chartType}
            />
        </div>
    )
};

const thePermanentWorks = (props, chartType) => {

    let teeth = _.get(props, _.get(props, 'teethMissing'));

    if (teeth === undefined)
       teeth = EmptyPermanentTeeth;

    return (
        <div id="tooth-row" style={{padding: '10px', backgroundColor: '#eee', float: 'right'}}>
            <TeethTickBox emptyTeeth={EmptyPermanentTeeth}
                          teeth={teeth}
                          onChange={props.onChange}
                          owner={_.get(props, 'teethMissing')}
                          chartType={chartType}
            />
        </div>
    )
};

const theDFProgWorks = (props, chartType) => {

    let teeth = _.get(props, _.get(props, 'doubtfulPrognosis'));

    if (teeth === undefined)
       teeth = EmptyPermanentTeeth;

    return (
        <div id="tooth-row" style={{padding: '10px', backgroundColor: '#eee', float: 'right'}}>
            <TeethTickBox emptyTeeth={EmptyPermanentTeeth}
                          teeth={teeth}
                          onChange={props.onChange}
                          owner={_.get(props, 'doubtfulPrognosis')}
                          chartType={chartType}
            />
        </div>
    )
};

export const ToothTickbox = (props) => {

    let content = "";
    const chartType = props.selectedAssessment ? props.selectedAssessment.chartType : CHART_TYPE_PRIMARY;

    switch (chartType) {
        case CHART_TYPE_PRIMARY:
            content = thePrimaryWorks(props, chartType);
            break;
        case CHART_TYPE_MIXED:
            content = theMixedWorks(props, chartType);
            break;
        case CHART_TYPE_PERMANENT:
            content = thePermanentWorks(props, chartType);
            break;
        default:
            break;
    }
    return (
        <div>
            {content}
        </div>
    );
}

export const ToothDFProgTickbox = (props) => {

    return (
        <div>
            {theDFProgWorks(props, CHART_TYPE_DFPROG)}
        </div>
    );
}

