import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';

import {calendar, checkBox, inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {APP_MOVING, CLINICIAN, HM_addPatAppointment, SU_STATUS_ACTIVE} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getResource, RES_HOUSEKEEPING_ADETS} from "../../../../actions/housekeeping";
import {ac} from "../../../../index";
import * as DefaultData from "../../DefaultData";

class ConnectedAddPatAppointment extends Component {

    constructor(props) {
        super(props);

        this.state = {

            appointment: _.cloneDeep(DefaultData.appointmentData(ac.getMcId())),
            bookingDetails: {createRecall: false},
            selectedProvider: null,
            first: 0,
            rows: 5,
        };

        this.state.appointment.patient = {id: props.patientId};
        this.state.appointment.bookedBy = {id: props.loginIdentity.id};
        this.state.appointment.updatedBy = {id: props.loginIdentity.id};
        this.state.appointment.bookingDate = new Date();
        this.state.appointment.lastUpdated = new Date();
        this.state.appointment.start = new Date();
        this.state.appointment.end = this.state.appointment.start;
        this.state.appointment.apType = this.props.appointmentTypes[0];
        this.state.appointment.status = APP_MOVING;

        this.onPage = this.onPage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDialogChange = this.onDialogChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.onProviderSelection = this.onProviderSelection.bind(this);
    }

    componentDidMount() {

        this.props.getAppointmentDiaryTypes();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {

            switch (this.props.message.type) {
                default:
                    break;
            }
        }
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows});
        this.save = true;
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onDialogChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onTypeChange(event) {

        const appointment = {...this.state.appointment};
        appointment.apType = event.value;

        this.setState({appointment});
    }

    onProviderSelection(event) {

        const {id} = event.value;

        const appointment = {...this.state.appointment};
        appointment.appointmentWith = {id};

        this.setState({appointment, selectedProvider: event.value});
    }

    render() {

        if (this.props.visible === false || !this.props.appointmentDiaryTypesLoaded) {
            return null;
        }

        // match the providers default appointment type if not return the first in the list
        let appointmentType = _.find(this.props.appointmentTypes, type => type.id === this.state.appointment.apType.id);
        appointmentType = appointmentType === undefined ? this.props.appointmentTypes[0] : appointmentType;

        const appNotDefined = this.state.selectedProvider === null || this.state.appointment.shortDescription.trim() === '';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.appointment, this.state.bookingDetails);
                        }}
                        disabled={appNotDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_addPatAppointment.id)
                        }}/>
            </div>
        );

        const patientName = `Patient : ${this.props.firstName} ${this.props.lastName}`.trim();

        const propsAppointment = {
            onChange: this.onChange,
            target: 'appointment',
            appointment: this.state.appointment,
        };

        const propsDialog = {
            onChange: this.onDialogChange,
            target: 'bookingDetails',
            bookingDetails: this.state.bookingDetails,
        };

        const clinicians = _.filter(this.props.providers, provider => provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);
        const sortedClinicians =  _.sortBy(clinicians, ['lastName', 'firstName'], ['asc', 'asc']);

        return (
            <Dialog header={this.props.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_addPatAppointment.id)
                    }}
            >
                <div className='p-grid'>

                    <div className="p-lg-12 p-md-12">
                        <h2>{patientName}</h2>
                    </div>

                    <div className="p-lg-5 p-md-5">
                        <h2>Provider Selection</h2>
                        <div className="p-grid p-fluid">

                            <DataTable value={sortedClinicians}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.rows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={this.onPage}
                                       first={this.state.first}
                                       selection={this.state.selectedProvider}
                                       onSelectionChange={this.onProviderSelection}
                            >

                                <Column field="fullName"
                                />
                            </DataTable>
                            <div className="p-col-12">
                                {checkBox(propsAppointment, 'workRequired', 'Appointment requires external work returned', false, false)}
                            </div>
                        </div>
                    </div>

                    <div className="p-lg-7 p-md-7">
                        <h2>Appointment Details</h2>
                        <div className="p-grid p-fluid">
                            <div className="p-col-3">
                                <label>Summary</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(propsAppointment, 'shortDescription', '', false, true)}
                            </div>

                            <div className="p-col-3">
                                <label>Comment</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(propsAppointment, 'description', 4, -1, false, false)}
                            </div>

                            <div className="p-col-3">
                                <label>Type</label>
                            </div>
                            <div className="p-col-5">
                                <Dropdown style={{zIndex: 99}} value={appointmentType}
                                          options={this.props.appointmentTypes}
                                          optionLabel='description'
                                          onChange={this.onTypeChange}
                                          autoWidth={false}
                                />
                            </div>
                            <div className="p-col-4">
                                {checkBox(propsDialog, 'createRecall', 'Create Recall', false, false)}
                            </div>

                            <div className="p-col-12">
                                <label>Duration</label>
                            </div>

                            <div className="p-col-12"
                                 style={{
                                     border: 'solid',
                                     borderWidth: '2px',
                                     borderColor: '#f2f2f3',
                                 }}
                            >
                                <div className="p-grid p-fluid">
                                    <div className='p-col-12'>
                                        <label style={{textEmphasis: 'bold'}}>NHS</label>
                                    </div>
                                    <div className='p-col-12'>
                                        {checkBox(propsDialog, 'onReferral', 'Treatment On Referral', false, false)}
                                    </div>
                                    <div className='p-col-4'>
                                        <label>Referral Date</label>
                                    </div>
                                    <div className='p-col-8'>
                                        {calendar(propsDialog, 'referralDate', !this.props.onReferral, false)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,
        userId: state.login.userId,

        appointmentDiaryTypesLoaded: state.housekeeping.appointmentDiaryTypesLoaded,
        appointmentTypes: state.housekeeping.appointmentDiaryTypes.appointmentTypes,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddPatAppointment = connect(mapStateToProps, MapDispatchToProps)(ConnectedAddPatAppointment);

export default AddPatAppointment;
