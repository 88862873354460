import React from 'react';

import {TabPanel} from 'primereact/components/tabview/TabView';
import User from './User';
import AddUser from '../Users/AddUser'

import {TB_SAVE, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_HELP} from "../../../icons";
import {HELP_USER_INDEXS, SM_ADD_USER, SM_USER_DATA} from "../../../actions/stateManagement";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import {CONST_FEMALE} from "../../PatientDynamicItems/OnChangeUtils";

export const getUserIds = (state, ownProps) => {

    const userLoadedId = `${SM_USER_DATA.loaded}_${ownProps.userId}`;
    const userDataId = `${SM_USER_DATA.id}_${ownProps.userId}`;

    let userLoaded = state.users[userLoadedId];
    userLoaded = userLoaded === undefined ? false : userLoaded;

    return {
        userDataId,
        userLoaded,
    }
};

export const userDetailsToolBar = (callbacks, saveIcon, status, activeIndex) => {

    return {
        left:
            <React.Fragment>
                {tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !status, TB_SAVE.text)}
            </React.Fragment>,
        right:
            <React.Fragment>
                {tb_boilerPlateRight((e) => window.open(HELP_USER_INDEXS[activeIndex]), ICON_HELP, 'Knowledge Base', 1, true)}
                {tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text)}
            </React.Fragment>
    }
};

export const onShowUserDetailsPage = ({props, id, firstName, lastName, gender}) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const genderIcon = parseInt(gender, 10) === CONST_FEMALE ? SM_USER_DATA.femaleTabIcon : SM_USER_DATA.maleTabIcon;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_USER_DATA.id}_${id}`} header={`${firstName} ${lastName}`} leftIcon={genderIcon}>
                    <User onCloseClick={onCloseClick}
                          onTabUpdate={onTabUpdate}
                          userId={id.toString()}
                          onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_USER_DATA.id}_${id}`} header={`${firstName} ${lastName} *`} leftIcon={genderIcon}>
                    <User onCloseClick={onCloseClick}
                          onTabUpdate={onTabUpdate}
                          userId={id.toString()}
                          onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )
};

export const getAddUserTabIndex = () => {

    return `${SM_ADD_USER.id}_${Math.floor(Math.random() * 1000)}`;
};

export const showAddUserPage = (props) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const keyId = getAddUserTabIndex();

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={keyId}
                          header={SM_ADD_USER.label}
                          leftIcon={`fa ${SM_ADD_USER.tabIcon}`}>
                    <AddUser onCloseClick={onCloseClick}
                             onTabUpdate={onTabUpdate}
                             id={keyId}
                             onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={keyId}
                          header={`${SM_ADD_USER.label} *`}
                          leftIcon={`fa ${SM_ADD_USER.tabIcon}`}>
                    <AddUser onCloseClick={onCloseClick}
                             onTabUpdate={onTabUpdate}
                             id={keyId}
                             onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )

};
