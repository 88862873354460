import React from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {password} from "../../../PatientDynamicItems/OnChangeUtils";
import {EDI_PerformerPin, EDI_PerformerPinValidation} from "../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";

export const SubmitClaim = (props) => {

    const footer = (
        <div>
            <Button label="OK"
                    icon={ICON_OK}
                    onClick={() => {
                        props.onOK()
                    }}
                    disabled={!props.pinValid}
            />
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(props.dialogId)
                    }}/>
        </div>
    );

    return (
        <Dialog header={EDI_PerformerPinValidation}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(props.dialogId)
                }}>
            <div className="p-grid p-fluid p-col-12 form-group">
                <div className="p-col-5">
                    <label htmlFor="pin">{EDI_PerformerPin}</label>
                </div>
                <div className="p-col-7">
                    {password(props, 'providerPIN', false, false, false)}
                </div>
            </div>
        </Dialog>
    )
};
