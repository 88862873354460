import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {
    calendar,
    dropDown,
    inputCurrencyText,
    inputText,
    inputTextArea
} from '../../../PatientDynamicItems/OnChangeUtils';

export const Organisation = (props) => {

    return (
        <Panel header='Laboratory Details'>
            <div className="p-grid p-fluid form-group">

                <div className="p-col-3">
                    <label htmlFor="registeredOn">Date Registered</label>
                </div>
                <div className="p-col-9">
                    {calendar(props, 'registeredOn', true, false)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="name">Laboratory Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'name', 'Laboratory', false, true)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="mainContact">Main Contact</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'mainContact', 'Contact', false, true)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="balance">Opening Balance</label>
                </div>
                <div className="p-col-9">
                    {inputCurrencyText(props, 'balance', 'Balance', false, false)}
                </div>

                <div className="p-col-12">
                    <label htmlFor="note">Comments</label>
                </div>
                <div className="p-col-12">
                    {inputTextArea(props, 'note', 4, -1, false, false)}
                </div>
            </div>
        </Panel>
    )
};
