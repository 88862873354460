import * as Actions from "./index";
import {ac} from '../index'

const baseURL = '/Tasks';

export const RES_TASKS = {
    ADD: {action: Actions.ADD_TASK, url: `${baseURL}/add`},
};

export const RES_TASKS_DIARY = {
    GET: {
        request: Actions.REQUEST_TASKS_DIARY,
        receive: Actions.RECEIVE_TASKS_DIARY,
        url: `${baseURL}/diaryTasks`
    },
    GET_TASK: {
        request: Actions.REQUEST_TASK_DIARY,
        receive: Actions.RECEIVE_TASK_DIARY,
        url: `${baseURL}/diaryTask`
    },
    SAVE: {action: Actions.SAVE_DIARY_TASK, url: `${baseURL}/diaryTask`},
    CLOSE: {action: Actions.CLOSE_DIARY_TASK, url: `${baseURL}/closeTask`},
};

export const RES_TASKS_OUTSTANDING = {
    GET: {
        request: Actions.REQUEST_TASKS_OUTSTANDING,
        receive: Actions.RECEIVE_TASKS_OUTSTANDING,
        url: `${baseURL}/outstandingTasks`
    },
    SAVE: {action: Actions.SAVE_OUTSTANDING_TASK, url: `${baseURL}/outstandingTask`},
    CLOSE: {action: Actions.CLOSE_OUTSTANDING_TASK, url: `${baseURL}/closeTasks`},
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {
                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const addTask = (task) => {

    return (dispatch) => {
        ac.fetchClient().post(`${RES_TASKS.ADD.url}?mcId=${ac.getMcId()}`, task)
            .then(() => {
            });
    }
};

export const closeDiaryTask = (task, loginIdentityId) => {

    const _task = {...task};
    _task.statusChangedDate = new Date();
    _task.statusChangedBy = {id: loginIdentityId};

    if (_task.patient !== null && _task.patient !== undefined) {
        _task.patient = {id: _task.patient.id};
    }
    _task.consentForms = null;

    return (dispatch) => {
        ac.fetchClient().post(`${RES_TASKS_DIARY.CLOSE.url}?mcId=${ac.getMcId()}`, _task)
            .then(() => {
            });
    }
};
