import * as Actions from './index';
import {ac} from '../index'

const baseURL = '/AccountItems';

export const RES_ACCOUNT_ITEMS_VOID = {
    AccountAdjustment: {action: Actions.VOID_ACCOUNT_ADJUSTMENT, url: `${baseURL}/voidAccountAdjustment`},
    CreditNote: {action: Actions.VOID_CREDIT_NOTE, url: `${baseURL}/voidCreditNote`},
    Invoice: {action: Actions.VOID_INVOICE, url: `${baseURL}/voidInvoice`},
    Payment: {action: Actions.VOID_PAYMENT, url: `${baseURL}/voidPayment`},
    Receipt: {action: Actions.VOID_RECEIPT, url: `${baseURL}/voidReceipt`},
    Refund: {action: Actions.VOID_REFUND, url: `${baseURL}/voidRefund`},
};

export const voidAccountItem = ({url, action}, item) => {

    return (dispatch) => {
        ac.fetchClient().delete(`${url}/?mcId=${ac.getMcId()}`, {params: {...item}})
            .then((res) => {
            });
    }
};
