import React from 'react';
import {Part2Content} from './Sections/Part2Content';
import {Part79Content} from './Sections/Part7_9Content';
import {Part5Content} from './Sections/Part5Content';
import {Part6Content} from './Sections/Part6Content';
import {ExemptionsAndRemissionsContent} from './Sections/ExemptionsAndRemissionsContent';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import _ from "lodash";

import {
    HM_EDI_EDIT_EXPS_REMS,
    HM_EDI_EDIT_PART2,
    HM_EDI_EDIT_PART5,
    HM_EDI_EDIT_PART6,
    HM_EDI_EDIT_PARTS7_9,
    HM_EditClaim,
    HM_NHS_SUPPORTING_DETAILS,
    SU_STATUS_ACTIVE
} from "../../../../Constants";
import {ICON_CANCEL, ICON_DELETE, ICON_OK, ICON_REDO,} from "../../../../icons";
import {
    EDI_CF_Form1_closed,
    EDI_CF_Form1_fitted,
    EDI_CF_Form1_recepited,
    EDI_CF_Form2,
    EDI_CF_regulation11,
    EDI_CR_AssessAndFittedStacked,
    EDI_CR_AssessAndFittedSubmit,
    EDI_CR_AssessAndRefuse,
    EDI_CR_AssessAndReview,
    EDI_CT_FP17,
    EDI_CT_FP17O,
    EDI_ST_Stacked
} from "../Constants";
import {connect} from "react-redux";
import {getPatientIds} from "../../PatientDetails/Utils";
import {getDropDowns, RES_getDropDowns} from "../../../../actions/dropDowns";
import {getAllUsers} from "../../../../actions/users";
import {getResource, RES_NHS_MANAGEMENT_CLAIM, RES_NHS_REGISTRATION} from "../../../../actions/nhsManagement";
import {getResource as getHKResource, RES_TEMPLATE_NOTES} from "../../../../actions/housekeeping";
import {BaseComponent} from "../../../BaseComponent";
import {NHSSupportingDetails} from "../../PatientDetails/dialogs/NHSSupportingDetails";
import moment from "moment";

export class ConnectedEditClaim extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            claimDAO: _.cloneDeep(props.claimDAO),

            claimLoaded: false,
            claim: null,

            patientLoaded: false,
            patient: null,

            activeIndex: 0,

            NHSRegistrationLoaded: false,
            NHSRegistration: null,

            first: 0,
            rows: 5,

            claimAction: {
                resubmitReplaceOrDelete: true,
                SQInd: false,
            },

            globalFilter: '',
        };

        this.index = 0;
        this.tabStack = [];
    }

    componentDidMount() {

        if (!this.props.dropDownsLoaded) {
            this.props.getDropDowns();
        }
        if (!this.props.usersLoaded) {
            this.props.getAllUsersShort();
        }

        this.props.getPatient(this.state.claimDAO.patientId);
        this.props.getPatientNHSRegistration({patientId: this.state.claimDAO.patientId});
        this.props.getClaim(this.state.claimDAO.id);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {

            switch (this.props.message.type) {
                case RES_NHS_MANAGEMENT_CLAIM.PATIENT.receive:

                    this.setState({patientLoaded: true, patient: this.props.patient});
                    break;
                case RES_NHS_REGISTRATION.GET.receive:

                    const NHSRegistration = {...this.props.NHSRegistration};

                    NHSRegistration.nhsNumber = NHSRegistration.nhsNumber === "" ? "0000000000" : NHSRegistration.nhsNumber;
                    this.setState({NHSRegistrationLoaded: true, NHSRegistration});
                    break;
                case RES_NHS_MANAGEMENT_CLAIM.GET.receive:

                    this.setState({claimLoaded: true, claim: {...this.props.claim}});
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {

            case 'globalFilter':
                _.set(state, event.owner, event.value);
                _.set(state, 'first', 0);
                break;
            case 'NHSRegistration.nhsNumber':

                if (event.value.trim().length < 11)
                    _.set(state, event.owner, event.value);
                break;
            case 'NHSRegistration.exemptionsAndRemissions':
                _.set(state, event.owner, event.value);
                break;
            default:
                _.set(state, event.owner, event.value);
                break;
        }
        this.setState(state, () => {
            console.log(`${event.owner}`);
        });
    }

    onTabChange = (index) => {
        this.setState({activeIndex: index}, () => {
            switch (this.tabStack[this.state.activeIndex].id) {
                case HM_EDI_EDIT_PART2.id:
                case HM_EDI_EDIT_EXPS_REMS.id:
                    if (!this.state.NHSRegistrationLoaded) {
                        this.props.getPatientNHSRegistration({patientId: this.state.patient.id});
                    }
                    break;
                case HM_EDI_EDIT_PART5.id:
                    if (!this.props.templateNotesLoaded)
                        this.props.getTemplateNotes();
                    break;
                default:
                    break;
            }
        });
    }

    exemptionsAndRemissions(fp17) {
        return ({
            index: this.index++,
            id: HM_EDI_EDIT_EXPS_REMS.id,
            content: <TabPanel key={HM_EDI_EDIT_EXPS_REMS.id}
                               header={`${HM_EDI_EDIT_EXPS_REMS.label}`}>
                <ExemptionsAndRemissionsContent claim={this.state.claim}
                                                patient={this.state.patient}
                                                nhsDetails={this.state.NHSRegistration}
                                                onChange={this.onChange}
                                                target='NHSRegistration'
                                                {...this.props}
                />
            </TabPanel>
        })
    }

    part2() {

        return ({
            index: this.index++,
            id: HM_EDI_EDIT_PART2.id,
            content: <TabPanel key={HM_EDI_EDIT_PART2.id}
                               header={`${HM_EDI_EDIT_PART2.label}`}>
                <Part2Content claim={this.state.claim}
                              patient={this.state.patient}
                              claimAction={this.state.claimAction}
                              NHSRegistration={this.state.NHSRegistration}
                              titles={this.props.titles}
                              onChange={this.onChange}
                />
            </TabPanel>
        })
    }

    fp17Part7_9() {

        const claimProps = {
            onChange: this.onChange,
            target: 'claim',
            claim: this.state.claim,
            providers: _.filter(this.props.usersShort, user => user.underUDAManagement && user.status === SU_STATUS_ACTIVE),
        };

        return ({
            index: this.index++,
            id: HM_EDI_EDIT_PARTS7_9.id,
            content: <TabPanel key={HM_EDI_EDIT_PARTS7_9.id}
                               header={`${HM_EDI_EDIT_PARTS7_9.label}`}>
                <Part79Content claimProps={claimProps}
                               onChange={this.onChange}
                />
            </TabPanel>
        })
    }

    fp17OPart5() {

        const claimProps = {
            onChange: this.onChange,
            target: 'claim',
            claim: this.state.claim,
            providers: _.filter(this.props.usersShort, user => user.underUDAManagement && user.status === SU_STATUS_ACTIVE),
        };

        const nhsProps = {
            onChange: this.onChange,
            target: 'NHSRegistration',
            NHSRegistration: this.state.NHSRegistration,
        };

        return ({
            index: this.index++,
            id: HM_EDI_EDIT_PART5.id,
            content: <TabPanel key={HM_EDI_EDIT_PART5.id}
                               header={`${HM_EDI_EDIT_PART5.label}`}>
                <Part5Content claimProps={claimProps}
                              patient={this.state.patient}
                              nhsProps={nhsProps}
                              templateNotes={this.props.templateNotes}
                              onChange={this.onChange}
                              onPage={this.onPage}
                              first={this.state.first}
                              rows={this.state.rows}
                              globalFilter={this.state.globalFilter}
                />
            </TabPanel>
        })
    }

    fp17OPart6() {

        const providers = _.filter(this.props.usersShort, user => user.underUDAManagement && user.status === SU_STATUS_ACTIVE);

        return ({
            index: this.index++,
            id: HM_EDI_EDIT_PART6.id,
            content: <TabPanel key={HM_EDI_EDIT_PART6.id}
                               header={`${HM_EDI_EDIT_PART6.label}`}>
                <Part6Content claim={this.state.claim}
                              patient={this.state.patient}
                              NHSRegistration={this.state.NHSRegistration}
                              onChange={this.onChange}
                              providers={providers}
                />
            </TabPanel>
        })
    }

    showFormBasedContent = (claim) => {

        // first clear the stack before re-building
        this.tabStack = [];

        this.tabStack.push(this.part2(claim));

        switch (claim.claimType) {
            case EDI_CT_FP17 :

                this.tabStack.push(this.exemptionsAndRemissions(true));

                this.tabStack.push(this.fp17Part7_9());

                break;
            case EDI_CT_FP17O :

                this.tabStack.push(this.exemptionsAndRemissions(false));

                switch (claim.claimForm) {
                    case EDI_CF_Form1_recepited :
                    case EDI_CF_Form1_fitted :
                        this.tabStack.push(this.fp17OPart5());
                        break;
                    case EDI_CF_Form2 :
                        this.tabStack.push(this.fp17OPart6());
                        break;
                    case EDI_CF_Form1_closed :
                        switch (claim.completionReason) {
                            case EDI_CF_regulation11 :
                                break;
                            case EDI_CR_AssessAndReview :
                            case EDI_CR_AssessAndRefuse :
                            case EDI_CR_AssessAndFittedStacked :
                            case EDI_CR_AssessAndFittedSubmit :
                                this.tabStack.push(this.fp17OPart5());
                                break;
                            default:
                                break
                        }
                        break;
                    default:
                        break
                }
                break;
            default:
                break
        }
    }

    onUpdateNHSSupportingDetails = () => {

        this.onHideMenuEntry(HM_NHS_SUPPORTING_DETAILS.id);
    }

    showDialogs = () => {
        if (this.state[HM_NHS_SUPPORTING_DETAILS.id]) {
            return (
                <NHSSupportingDetails key={HM_NHS_SUPPORTING_DETAILS.id}
                                      NHSRegistration={this.state.NHSRegistration}
                                      onChange={this.onChange}
                                      onHideDialog={this.onHideMenuEntry}
                                      onOkDialog={this.onUpdateNHSSupportingDetails}
                />
            );
        }
    }

    render() {

        if (!this.props.dropDownsLoaded || !this.props.patientLoaded || !this.state.claimLoaded) {
            return null;
        }

        const claimDefined = true;

        let header = `${HM_EditClaim.header} :`;

        if (this.state.claim.claimReferenceNumber) {
            header = `${header} ${this.state.claim.claimReferenceNumber}`;
        } else {
            if (this.state.claimDAO.lastEditDate) {
                header = `${header} ${this.state.claimDAO.fullName} (${moment(this.state.claimDAO.lastEditDate).format('YYYY-MM-DD')})`;
            } else {
                header = `${header} ${this.state.claimDAO.fullName}`;
            }
        }

        this.showFormBasedContent(this.state.claim);

        let footer;

        switch (this.state.claim.status) {
            case EDI_ST_Stacked :
                footer = (
                    <div>
                        <Button label="OK"
                                icon={ICON_OK}
                                onClick={() => {
                                    const claim = {...this.state.claim};
                                    claim.lastEditDate = new Date();
                                    this.props.onOk(claim, this.state.NHSRegistration, this.state.patient, this.state.claimAction, false)
                                }}
                                disabled={!claimDefined}/>
                        <Button label="Delete"
                                icon={ICON_DELETE}
                                onClick={() => {
                                    this.props.onOk(this.state.claim, this.state.NHSRegistration, this.state.patient, this.state.claimAction, false)
                                }}/>
                        <Button label="Cancel"
                                icon={ICON_CANCEL}
                                onClick={() => {
                                    this.props.onHideDialog(HM_EditClaim.id);
                                }}/>
                    </div>
                );
                break;
            default:
                footer = (
                    <div>
                        <Button label="Resubmit"
                                icon={ICON_REDO}
                                onClick={() => {
                                    this.props.onOk(this.state.claim, this.state.NHSRegistration, this.state.patient, this.state.claimAction, true)
                                }}/>
                        <Button label="Delete"
                                icon={ICON_DELETE}
                                onClick={() => {
                                    this.props.onDelete(this.state.claim, this.state.NHSRegistration, this.state.patient, this.state.claimAction, false)
                                }}/>
                        <Button label="Cancel"
                                icon={ICON_CANCEL}
                                onClick={() => {
                                    this.props.onHideDialog(HM_EditClaim.id);
                                }}/>
                    </div>
                );
                break;
        }

        return (
            <Dialog header={header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_EditClaim.id);
                    }}>

                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    <div id="detailPanel">

                        {this.showDialogs()}

                        <TabView scrollable={true}
                                 activeIndex={this.state.activeIndex}
                                 onTabChange={(e) => {
                                     this.onTabChange(e.index)
                                 }}>

                            {this.tabStack.map(tab => {
                                return tab.content
                            })}

                        </TabView>
                    </div>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const props = {...ownProps};
    props.patientId = ownProps.claimDAO.patientId;

    const templateNotesLoaded = Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false;
    const templateNotes = templateNotesLoaded ? state.housekeeping.templateNotes : [];

    const {
        NHSRegistrationLoaded, NHSRegistrationId,
    } = getPatientIds(state, props);

    console.log(state.stateManagement.message.type);

    return {

        message: state.stateManagement.message,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        genders: state.dropDowns.genders,

        patientLoaded: state.nhsManagement.patientLoaded,
        patient: state.nhsManagement.patient,

        NHSRegistrationLoaded,
        NHSRegistration: state.patients[NHSRegistrationId],

        claimLoaded: state.nhsManagement.claimLoaded,
        claim: state.nhsManagement.claim,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        templateNotesLoaded,
        templateNotes,
    }
};

const MapDispatchToProps = dispatch => {

    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getAllUsersShort: () => dispatch(getAllUsers()),
        getPatient: (patientId) => dispatch(getResource(RES_NHS_MANAGEMENT_CLAIM.PATIENT, {patientId})),
        getPatientNHSRegistration: (params) => dispatch(getResource(RES_NHS_REGISTRATION.GET, params)),
        getClaim: (claimId) => dispatch(getResource(RES_NHS_MANAGEMENT_CLAIM.GET, {claimId})),
        getTemplateNotes: () => dispatch(getHKResource(RES_TEMPLATE_NOTES.GET, {})),
    }
};

const EditClaim = connect(MapStateToProps, MapDispatchToProps)(ConnectedEditClaim);

export default EditClaim;
