import React from 'react';

import _ from 'lodash';
import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {deleteHskItem, getResource, RES_TEMPLATE_NOTES_FAVS} from "../../../../actions/housekeeping";
import {setState, SM_TEMPLATE_NOTES, SM_TEMPLATE_NOTES_Favs} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {ICON_PLUS} from "../../../../icons";
import {BaseComponent} from "../../../BaseComponent";
import AddTemplateNoteFavourite from "../dialogs/AddTemplateNoteFavourite";
import {HM_AddNoteFav, HM_DeleteNoteFav, HM_EditNoteFav} from "../Constants";

export class ConnectedTemplateNoteFavouritesSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_TEMPLATE_NOTES_Favs.id,

                templateNotes: [],
                fakeIndex: -1000,
                first: 0,
                firstMembers: 0,
                rows: 5,
                rowsMembers: 5,

                templateNoteFavourites: [],
                sourceTCs: [],
                targetTCs: [],

                selectedTemplateNoteFavourite: {},
                [HM_AddNoteFav.id]: false,
                [HM_EditNoteFav.id]: false,
                [HM_DeleteNoteFav.id]: false,
            };
        }

        this.onFavSelection = this.onFavSelection.bind(this);

        this.addFav = this.addFav.bind(this);
        this.updateFav = this.updateFav.bind(this);

        this.onShowDialogs = this.onShowDialogs.bind(this);

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.templateNoteFavouritesLoaded) {
            this.props.getTemplateNoteFavs();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_TEMPLATE_NOTES_FAVS.GET.receive:
                case RES_TEMPLATE_NOTES_FAVS.SAVE.action:
                case RES_TEMPLATE_NOTES_FAVS.DELETE.action:
                    this.setState({templateNoteFavourites: this.props.templateNoteFavourites}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    favTemplate(templateNote) {
        return (
            <div className="p-helper-clearfix">
                <div style={{fontSize: 'small'}}>{templateNote.description}</div>
            </div>
        );
    };

    addFav(newNoteFavourite) {

        this.onHideMenuEntry(HM_AddNoteFav.id);
        newNoteFavourite.id = this.state.fakeIndex;
        newNoteFavourite.newItem = true;
        this.updateFav(newNoteFavourite);
        this.onFavSelection({value: newNoteFavourite})
    }

    updateFav(editedNoteFavourite) {

        editedNoteFavourite.edited = true;

        const editedNoteFavourites = [...this.state.templateNoteFavourites];

        const index = _.findIndex(editedNoteFavourites, (treatmentFavourite) => {
            return treatmentFavourite.id === editedNoteFavourite.id;
        });

        if (Boolean(editedNoteFavourite.newItem)) {
            editedNoteFavourite.newItem = false;
            editedNoteFavourites.unshift(editedNoteFavourite);
        } else {
            editedNoteFavourites[index] = editedNoteFavourite;
        }

        this.setState({templateNoteFavourites: editedNoteFavourites, [HM_EditNoteFav.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });
        this.onFavSelection({value: editedNoteFavourite});

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_TEMPLATE_NOTES_FAVS.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onShowDialogs() {

        if (this.state[HM_AddNoteFav.id]) {
            return (
                <AddTemplateNoteFavourite
                    editing={false}
                    header={HM_AddNoteFav.header}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addFav}
                    templateNotes={this.props.templateNotes}
                />
            )
        } else if (this.state[HM_EditNoteFav.id]) {
            return (
                <AddTemplateNoteFavourite
                    editing={true}
                    header={HM_EditNoteFav.header}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateFav}
                    templateNotes={this.props.templateNotes}
                    item={this.state.selectedTemplateNoteFavourite}
                />
            )
        } else if (this.state[HM_DeleteNoteFav.id]) {

            const footer = <div>
                <Button label="Yes" icon="fa fa-check" onClick={() => {
                    this.onDeleteFav();
                }}/>
                <Button label="No" icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteNoteFav.id)
                }}/>
            </div>;

            return (

                <Dialog header={HM_DeleteNoteFav.header}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteNoteFav.id]: false})
                        }}>
                    {HM_DeleteNoteFav.message}
                </Dialog>
            )
        }
        return null;
    }

    onDeleteFav = () => {

        this.props.deleteTreatmentFav(this.state.selectedTemplateNoteFavourite.id);

        let noteFavourites = this.state.templateNoteFavourites.filter((favourite) => favourite.id !== this.state.selectedTemplateNoteFavourite.id);

        this.setState({

            templateNoteFavourites: noteFavourites,

            selectedTemplateNoteFavourite: null,
            [HM_DeleteNoteFav.id]: false,
        });
    }

    onFavSelection(event) {

        const selectedTemplateNoteFavourite = event.value;

        let notes = [];

        selectedTemplateNoteFavourite.items.forEach((targetNote) => {
            const index = _.findIndex(this.state.templateNotes, (sourceNote) => targetNote.id === sourceNote.id);
            notes.push(this.state.templateNotes[index]);
        });

        let sourceTCs = this.state.templateNotes.filter((note) => !notes.includes(note));
        let targetTCs = selectedTemplateNoteFavourite.items;

        this.setState({selectedTemplateNoteFavourite: selectedTemplateNoteFavourite, sourceTCs, targetTCs});
    }

    onChange(event) {

        const sourceTCs = _.orderBy(event.source, 'description', 'asc');
        const targetTCs = _.orderBy(event.target, 'description', 'asc');

        this.setState({sourceTCs, targetTCs});
    }

    render() {

        if (!this.props.templateNoteFavouritesLoaded) {
            return null;
        }

        const items = [
            {
                label: HM_EditNoteFav.header,
                icon: HM_EditNoteFav.icon,
                command: this.onShowMenuEntry,
                target: HM_EditNoteFav.id
            },
            {
                label: HM_DeleteNoteFav.header,
                icon: HM_DeleteNoteFav.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteNoteFav.id
            },
        ];

        const members = this.state.selectedTemplateNoteFavourite === null ? [] : this.state.selectedTemplateNoteFavourite.items;

        const favouriteMembers = _.sortBy(members, ['description'], ['asc']);

        const header = <div className='panel-header-centered-content'><label id='panel-header'>Template Note
            Favourites</label>
            <Button tooltip={HM_AddNoteFav.header}
                    tooltipOptions={{position: 'right'}}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_AddNoteFav.id}})
                    }}>
            </Button>
        </div>;

        return (
            <div>
                <Panel header={header}>

                    <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={this.state.templateNoteFavourites}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedTemplateNoteFavourite}
                               onSelectionChange={this.onFavSelection}
                               contextMenuSelection={this.state.selectedTemplateNoteFavourite}
                               onContextMenuSelectionChange={e => this.setState({selectedTemplateNoteFavourite: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field="description"
                                header="Description"
                                style={{width: '75%'}}/>
                        <Column field="code"
                                header="Code"
                                style={{width: '25%'}}/>
                    </DataTable>
                </Panel>

                <Panel header='Template Note Favourite Members' style={{paddingTop: '5px'}}>

                    <DataTable value={favouriteMembers}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               paginator={true}
                               rows={this.state.rowsMembers}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstMembers', 'rowsMembers')}
                               first={this.state.firstMembers}
                    >
                        <Column field="name"
                                header="Name"
                                style={{width: '100%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        templateNotes: state.housekeeping.templateNotes,

        templateNoteFavouritesLoaded: state.housekeeping.templateNoteFavouritesLoaded,
        templateNoteFavourites: state.housekeeping.templateNoteFavourites,

        currentState: state.stateManagement[SM_TEMPLATE_NOTES_Favs.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTemplateNoteFavs: () => dispatch(getResource(RES_TEMPLATE_NOTES_FAVS.GET, {})),
        deleteTreatmentFav: (id) => dispatch(deleteHskItem(RES_TEMPLATE_NOTES_FAVS.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_TEMPLATE_NOTES.id)),
    };
};

const TemplateNoteFavouritesSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedTemplateNoteFavouritesSection);

export default TemplateNoteFavouritesSection;
