import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {calendar, checkBox, CONST_MALE, dropDownId, inputText} from './OnChangeUtils';
import _ from "lodash";
import {
    CLINICIAN,
    HM_AddFollowOnRecall, Male, PJS_USABLE, PP_STATUS,
    REF_INDEPENDENT,
    REF_PATIENT,
    REF_PROVIDER,
    REFERRER_TYPES, SU_STATUS_ACTIVE
} from "../../Constants";
import {SelectButton} from "primereact/selectbutton";
import {referrerTemplate} from "./Utils";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/components/button/Button";
import {ICON_CAPS, ICON_GOTO, ICON_STREET_VIEW} from "../../icons";
import {onShowReferrerDetailsPage} from "../FixedItems/ReferrerDetails/Utils";
import {SM_REFERRER_DETAILS} from "../../actions/stateManagement";

const optionalReferrerSource = (props) => {

    if (!props.portalView && !props.onTablet) {
        let referrerType = REF_INDEPENDENT;
        let referrerTypes = REFERRER_TYPES;

        if (props.patientData.referrer !== undefined && props.patientData.referrer !== null) {

            referrerType = _.find(REFERRER_TYPES, type => props.patientData.referrer.type === type.value).value;
            referrerTypes = _.map(REFERRER_TYPES, type => {
                if (type.value === referrerType) {
                    const _type = {...type};
                    switch (referrerType) {
                        case REF_INDEPENDENT.value:
                            const referrer = _.get(props, 'patientData.referrer');
                            _type.label = `(Ind) ${referrerTemplate(referrer)}`;
                            break;
                        case REF_PROVIDER.value:
                            const provider = _.get(props, 'patientData.referrer.provider');
                            _type.label = `(Pro) ${provider.firstName} ${provider.lastName}`;
                            break;
                        case REF_PATIENT.value:
                            const patient = _.get(props, 'patientData.referrer.patient');
                            _type.label = patient === undefined ? '(Pat)' : `(Pat) ${patient.firstName} ${patient.lastName}`;
                            break;
                        default:
                            break;
                    }
                    return _type;
                } else return type;
            });
        }

        if (props.patientData.referrer) {
            const {id, type, firstName, lastName, companyName} = props.patientData.referrer;
            const row = {id, type, firstName, lastName, gender: CONST_MALE, companyName};

            return [

                <div className="p-col-2">
                    <label htmlFor="referringSource">Referred By</label>
                </div>,
                <div className="p-col-10 force-4-select-row">
                    <SelectButton key='referrer'
                                  value={referrerType}
                                  options={referrerTypes}
                                  onChange={(e) => {
                                      props.onReferrerChange({owner: `patientData.referrer.type`, value: e.value})
                                  }}
                                  disabled={props.portalView}
                    />
                </div>
            ]
        } else {

            return [

                <div key={'referringSource'}
                     className="p-col-2">
                    <label htmlFor="referringSource">Referred By</label>
                </div>,
                <div className="p-col-10 force-4-select-row">
                    <SelectButton key='referrer'
                                  value={referrerType}
                                  options={referrerTypes}
                                  onChange={(e) => {
                                      props.onReferrerChange({owner: `patientData.referrer.type`, value: e.value})
                                  }}
                                  disabled={props.portalView}
                    />
                </div>
            ]
        }
    }
}

const optionalPatientJourney = (props, stage) => {

    const sortedStages = _.sortBy(_.filter(props.journeyStages, stage => stage.status === PJS_USABLE), 'description');

    if (!props.portalView && !props.onTablet) {
        return [
            <div className="p-col-2">
                <label htmlFor="patientJourney">Patient Journey</label>
            </div>,
            <div className="p-col-4">
                <Dropdown id="pj1"
                          placeHolder='Journey Code'
                          value={stage}
                          options={sortedStages}
                          optionLabel={`description`}
                          onChange={e => {

                              if (!props.patientData.patientJourney || (props.patientData.patientJourney && props.patientData.patientJourney.stage.id !== e.value.id)) {
                                  props.onChange({owner: HM_AddFollowOnRecall.ppOwner, value: e.value});
                              }
                          }}
                          filter={true}
                          filterPlaceholder='Patient Journey'
                          filterBy='description'
                />
            </div>,

            <div className="p-col-2"><label>Last Updated</label></div>,
            <div className="p-col-4">
                {calendar(props, 'patientJourney.changedOn', false, false)}
            </div>
        ]
    }
}

const optionalAssignedTo = (props, selectedProvider, providers, className) => {

    if (!props.portalView && !props.onTablet) {
        return (
            <React.Fragment>
                <div className="p-col-2"><label>Assigned To</label></div>
                <div className="p-col-4">
                    <Dropdown key='assignedTo'
                              value={selectedProvider}
                              options={providers}
                              optionLabel='fullName'
                              className={className}
                              onChange={(e) => {
                                  props.onProviderChange({owner: `patientData.provider`, value: e.value})
                              }}
                    />
                </div>

                <div className="p-col-2">
                    <label htmlFor="alternateRef">Alt. Ref.</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'alternateRef', 'Alt. Ref.')}
                </div>
            </React.Fragment>
        )
    }
}

export const General = (props) => {

    const {patientJourney, provider} = props.patientData;
    const stage = patientJourney === null || patientJourney === undefined ? undefined : _.find(props.journeyStages, stage => stage.id === patientJourney.stage.id);

    const providers = _.filter(props.providers, provider => provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);
    const provIndex = props.patientData.provider ? _.findIndex(providers, provider => provider.id === props.patientData.provider.id) : -1;

    if (props.patientData.provider && provIndex < 0) {
        const {id, firstName, lastName} = props.patientData.provider;
        providers.push({id, fullName: `${firstName} ${lastName}`.trim()});
    }
    const selectedProvider = provider === null ? null : _.find(providers, provider => provider.id === props.patientData.provider.id);

    const className = provider === null ? 'p-invalid' : '';

    return (
        <Panel header='General'>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-2">
                    <label htmlFor="name.salutation">Salutation</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'salutation', 'Salutation')}
                </div>

                <div className="p-col-2">
                    <label htmlFor="knownAs">Known As</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'knownAs', 'Known As')}
                </div>

                <div className="p-col-2"><label>Reg. Date</label></div>
                <div className="p-col-4">
                    {calendar(props, 'registeredOn', true)}
                </div>

                <div className="p-col-2">
                    <label htmlFor="occupation">Occupation</label>
                </div>
                <div className="p-col-4">
                    {dropDownId(props, 'occupations', 'occupation', 'description', false, false)}
                </div>

                {optionalReferrerSource(props)}
                {optionalPatientJourney(props, stage)}
                {optionalAssignedTo(props, selectedProvider, providers, className)}

                <div className="p-col-2"><label>Ref. Date</label></div>
                <div className="p-col-4">
                    {calendar(props, 'referralDate', false)}
                </div>

                {(!props.portalView && !props.onTablet) &&
                <React.Fragment>
                    <div className="p-col-2">
                        <label htmlFor="password">Portal Password</label>
                    </div>

                    <div className="p-col-4">
                        <Button label="Set Password (Min 6 Characters)"
                                className="p-button-warning"
                                onClick={() => {
                                    props.onPasswordChange();
                                }}
                        />
                    </div>
                    {/*<div className="p-col-2">*/}
                    {/*    <label htmlFor="blockPortalLogin">Block Portal Login</label>*/}
                    {/*</div>*/}
                    {/*<div className="p-col-2">*/}
                    {/*    {checkBox(props, 'blockPortalLogin', '', false, false)}*/}
                    {/*</div>*/}
                </React.Fragment>
                }
            </div>
        </Panel>
    )
};
