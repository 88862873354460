import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import _ from "lodash";
import {
    inputCurrencyText,
    inputDiscountText,
    inputText,
    outputCurrencyText,
    outputText,
    spinnerStep
} from "../../../PatientDynamicItems/OnChangeUtils";
import {CI_STATUS_USABLE, HM_AddTreatment, HM_EditTreatment} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {chargeData} from "../../DefaultData";
import {getToothNumberRaw} from "../../../Charting/Parts/ChartingUtils";
import {currencyTemplate} from "../../fixedItemUtils";
import {Editor} from "primereact/editor";

export default class AddTreatment extends Component {

    constructor(props) {
        super(props);

        const appointment = Boolean(props.appointment) ? props.appointment : null;
        const patient = Boolean(props.appointment) ? props.appointment.patient : props.treatmentPlan.patient;
        const treatmentPlan = props.treatmentPlan;

        this.all = {
            id: -1,
            mc: ac.getMcId(),
            description: 'All',
            items: props.treatmentCodes.items,
            default: true,
        };

        this.state = {
            selectedTreatmentCode: null,
            favourites: [...props.favourites, this.all],
            favourite: {items: []},
            first: 0,
            rows: 5,
            globalFilter: ''
        };

        if (props.editing) {
            this.state.charge = props.item;
        } else {
            if (props.addFromCharting) {
                this.state.charge = _.cloneDeep(chargeData(ac.getMcId()));
                this.state.charge.chartEntry = props.currentChartedEntry;
                this.state.charge.item = props.currentSelectedItem.chargeableItem;
                this.state.charge.site = getToothNumberRaw(props.currentChartedEntry.jaw, props.currentChartedEntry.position);
            } else {
                this.state.charge = _.cloneDeep(chargeData(ac.getMcId()));
            }
            this.state.charge.appointment = appointment === null ? null : {appointmentId: appointment.appointmentId};
            this.state.charge.chargedBy = appointment === null ? null : {id: appointment.appointmentWith.id};
            this.state.charge.chargedTo = {id: patient.id};
            this.state.charge.treatmentPlan = {id: treatmentPlan.id};
        }

        this.editor = null;
    }

    componentDidMount() {
        this.setState({favourite: _.find(this.state.favourites, favourite => favourite.default === true)});
    }

    clearCharge = () => {

        const charge = _.cloneDeep(chargeData(ac.getMcId()));

        const appointment = Boolean(this.props.appointment) ? this.props.appointment : null;
        const patient = Boolean(this.props.appointment) ? this.props.appointment.patient : this.props.treatmentPlan.patient;
        const treatmentPlan = this.props.treatmentPlan;

        charge.appointment = appointment === null ? null : {appointmentId: appointment.appointmentId};
        charge.chargedBy = appointment === null ? null : {id: appointment.appointmentWith.id};
        charge.chargedTo = {id: patient.id};
        charge.treatmentPlan = {id: treatmentPlan.id};

        this.editor.quill.setContents([{insert: '\n'}]);

        this.setState({charge});
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {
            case 'charge.chargedPrice' :

                if (isNaN(event.value)) {
                    return;
                } else {
                    const value = event.value.length === 0 ? 0.0 : event.value
                    _.set(state, event.owner, value);
                }
                break;
            default:
                _.set(state, event.owner, event.value);
                break;
        }

        this.setState(state);
    }

    onCostChange = (event) => {

        const charge = {...this.state.charge};
        charge.total = charge.quantity * charge.chargedPrice;

        this.setState({charge});
    }

    onSelection = (e) => {

        if (this.props.editing || this.props.addFromCharting)
            return;

        const {cost} = e.value;
        const charge = {...this.state.charge};

        charge.chargedPrice = cost;
        charge.quantity = 1;
        charge.site = '';
        charge.item = e.value;

        this.setState({selectedTreatmentCode: e.value, charge});
    }

    onHide = () => {
        const target = this.props.editing || this.props.addFromCharting ? HM_EditTreatment.id : HM_AddTreatment.id;
        this.props.onHideDialog(target);
    }

    costTemplate(rowData) {

        const tc = rowData;

        if (isNaN(tc.cost)) {
            return currencyTemplate({cost: 0}, 'cost');
        } else {
            return tc.discountCharge ? tc.cost : currencyTemplate(tc, 'cost');
        }
    }

    colourTemplate(rowData) {

        const tc = rowData;

        if (tc.colour === null) {
            return null;
        }

        if (tc.colour === undefined) {
            return null;
        }

        const color = `rgb(${tc.colour.split(':').join(',')})`;
        return <div style={{backgroundColor: color}}>&nbsp;</div>
    }

    dealWithCostOrDiscount = (props, item, quantity, chargedPrice) => {

        if (item !== null && item.discountCharge) {
            return (
                <React.Fragment>
                    <div className="p-col-3">
                        <label>Discount</label>
                    </div>
                    <div className="p-col-9">
                        {inputDiscountText(props, 'chargedPrice', '', false, true)}
                    </div>
                    <div className="p-col-3">
                        <label>Applied Discount</label>
                    </div>
                    <div className="p-col-9">
                        {outputText(quantity * chargedPrice)}
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="p-col-3">
                        <label>Cost</label>
                    </div>
                    <div className="p-col-9">
                        {inputCurrencyText(props, 'chargedPrice', '', false, true)}
                    </div>
                    <div className="p-col-3">
                        <label>Applied Cost</label>
                    </div>
                    <div className="p-col-9">
                        {outputCurrencyText(quantity * chargedPrice)}
                    </div>
                </React.Fragment>
            )
        }
    }

    addLeftColumn(item, quantity, chargedPrice, chartEntry) {

        const width = this.props.editing || this.props.addFromCharting ? "p-col-12" : "p-col-6";

        const props = {
            onChange: this.onChange,
            target: 'charge',
            charge: this.state.charge,
        };

        if (this.props.editing || this.props.addFromCharting) {

            const charted = Boolean(chartEntry);

            return (
                <div className={width}>
                    <div className="p-grid p-fluid form-group p-col-12">
                        <div className="p-col-3">
                            <label>Site</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'site', '', charted, false)}
                        </div>
                        <div className="p-col-3">
                            <label>Quantity</label>
                        </div>
                        <div className="p-col-9">
                            {spinnerStep(props, 'quantity', 0, 100, 1, charted, true)}
                        </div>
                        <div className="p-col-3">
                            <label>Treatment</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'item.description', '', true, false)}
                        </div>
                        <div className="p-col-3">
                            <label>Cost</label>
                        </div>
                        <div className="p-col-9">
                            {inputCurrencyText(props, 'chargedPrice', '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label>Applied Cost</label>
                        </div>
                        <div className="p-col-9">
                            {outputCurrencyText(quantity * chargedPrice)}
                        </div>
                        <div className="p-col-3">
                            <label>Comment</label>
                        </div>
                        <div className="p-col-9">
                            <ScrollPanel style={{width: '100%', height: '250px'}} className='custom'>
                                <Editor value={this.state.charge.comment}
                                        readOnly={false}
                                        style={{height: '200px'}}
                                        ref={el => this.editor = el}
                                />
                            </ScrollPanel>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={width}>
                    <div className="p-grid p-fluid form-group p-col-12">
                        <div className="p-col-3">
                            <label>Favourites</label>
                        </div>
                        <div className="p-col-9">
                            <Dropdown key='favDropDown-2' optionLabel='description'
                                      value={this.state.favourite}
                                      options={this.state.favourites}
                                      onChange={(e) => {
                                          this.setState({favourite: e.value})
                                      }}
                                      autoWidth={false}
                                      scrollHeight='100px'
                            />
                        </div>
                        <div className="p-col-3">
                            <label>Site</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'site', '', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label>Quantity</label>
                        </div>
                        <div className="p-col-9">
                            {spinnerStep(props, 'quantity', 0, 100, 1, false, true)}
                        </div>
                        <div className="p-col-3">
                            <label>Treatment</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'item.description', '', true, false)}
                        </div>

                        {this.dealWithCostOrDiscount(props, item, quantity, chargedPrice)}

                        <div className="p-col-3">
                            <label>Comment</label>
                        </div>
                        <div className="p-col-9">
                            <Editor value={this.state.charge.comment}
                                    readOnly={false}
                                    style={{height: '200px'}}
                                    ref={el => this.editor = el}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }

    addRightColumn() {

        if (this.props.editing || this.props.addFromCharting)
            return null;

        let targetTCs = _.filter(this.state.favourite.items, item => item.status === CI_STATUS_USABLE);
        const favouriteItems = _.sortBy(targetTCs, ['description'], ['asc']);

        return (

            <div className="p-col-6">
                <div style={{'textAlign': 'left', paddingBottom: '10px'}}>
                    <InputText type="search"
                               onInput={(e) => this.setState({globalFilter: e.target.value})}
                               placeholder="Code Search"
                               size="50"
                               autoFocus
                    />
                </div>
                <ScrollPanel style={{
                    width: '100%',
                    height: '500px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'gray'
                }} className='custom'>
                    <DataTable header={''}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               value={favouriteItems}
                               selectionMode="single"
                               paginator={false}
                               selection={this.state.selectedTreatmentCode}
                               onSelectionChange={this.onSelection}
                               globalFilter={this.state.globalFilter}
                               disabled={false}
                    >

                        <Column header=""
                                body={this.colourTemplate}
                                style={{width: '10%'}}/>
                        <Column field="description"
                                header="Description"
                                filer={true}
                                filterMatchMode='contains'
                                style={{width: '55%'}}/>
                        <Column field="code"
                                header="Code"
                                filer={true}
                                filterMatchMode='contains'
                                style={{width: '15%'}}/>
                        <Column header="£/%"
                                body={this.costTemplate}
                                style={{width: '20%', textAlign: 'right'}}/>
                    </DataTable>
                </ScrollPanel>
            </div>
        )
    }

    render() {

        const {item, quantity, chargedPrice, chartEntry} = this.state.charge;

        const addCharge = this.props.addFromCharting ? (quantity > 0) : (item !== null && quantity > 0 && chargedPrice !== null);

        const footer = this.props.editing || this.props.addFromCharting ?
            <div>
                <Button label='OK'
                        icon={ICON_OK}
                        onClick={() => {
                            const charge = {...this.state.charge};
                            charge.comment = this.editor.quill.root.innerHTML;
                            this.props.onOkDialog(charge)
                        }}
                        disabled={!addCharge}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
            :
            <div>
                <Button label='Add Treatment'
                        icon={ICON_OK}
                        onClick={() => {
                            const charge = {...this.state.charge};
                            charge.comment = this.editor.quill.root.innerHTML;
                            this.props.onOkDialog(charge);
                            this.clearCharge();
                        }}
                        disabled={!addCharge}/>
                <Button label="Finish"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>;

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div className="p-grid p-fluid form-group p-col-12">
                    {this.addLeftColumn(item, quantity, chargedPrice, chartEntry)}
                    {this.addRightColumn()}
                </div>
            </Dialog>
        )
    }
}
