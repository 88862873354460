import React from 'react';
import _ from 'lodash';

import {ExemptionsAndRemissionsContent} from '../../NHSManagaement/dialogs/Sections/ExemptionsAndRemissionsContent';
import {Panel} from "primereact/panel";
import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import {Dropdown} from "primereact/dropdown";
import {ETH_GROUP} from "../../../PatientDynamicItems/NHSDetails";
import {ProgressBar} from "primereact/progressbar";

export const NHSRegistrationSection = (props) => {

    if (!props.NHSRegistrationLoaded) {
        return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
    }

    const ethnicOrigin = _.find(ETH_GROUP, group => props.NHSRegistration.ethnicGroup.code === group.code).value;

    return (
        <div className="p-grid"
             style={{fontSize: 'small'}}>

            <div className="p-lg-12 p-md-8">
                <Panel header='NHS Details'>
                    <div className='items-margin d-flex d-align-center'>
                        <label htmlFor="nhsNumber">NHS Number</label>
                        {inputText(props, 'nhsNumber', 'NHS Number', false, true)}
                        <label htmlFor="previousSurname">Previous Surname</label>
                        {inputText(props, 'previousSurname', 'Previous Surname', false, false)}
                        <label htmlFor="ethnicOrigin">Ethnic Origin</label>
                        <Dropdown key={`ethnicOrigin`}
                                  value={ethnicOrigin}
                                  options={ETH_GROUP}
                                  onChange={(e) => {
                                      const code = _.find(ETH_GROUP, group => group.value === e.value).code;
                                      props.onChange({owner: `NHSRegistration.ethnicGroup.code`, value: code})
                                  }}
                        />
                    </div>
                </Panel>
            </div>
            <div className="p-lg-12 p-md-12">
                <ExemptionsAndRemissionsContent
                    nhsDetails={props.NHSRegistration}
                    {...props}
                />
            </div>
        </div>
    )
};
