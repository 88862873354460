export const DETAILS = 0;

export const HM_SubscriptionSave = {
    id: 'HM_SubscriptionSave',
    header: 'Subscription Creation',
    message: 'The form has been modified, save these changes?'
};

export const HM_SubscriptionSaveOK = {
    id: 'HM_SubscriptionSaveOK',
    header: 'Subscription Creation',
    message: 'Subscription Successfully Created'
};

export const HM_SubscriptionSaveNOK = {
    id: 'HM_UserSaveNOK',
    header: 'Subscription Creation',
    message: 'Subscription Creation Failed'
};

export const HM_SendSubscriptionDetails = {
    id: 'HM_SendSubscriptionDetails',
    header: 'Subscription Details',
    message: 'Email Subscription Details'
};
