import React, {Component} from 'react';

import {Calendar} from 'primereact/calendar';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import _ from "lodash";
import moment from "moment";
import {
    JSON_DATE_FORMAT,
    PAT_ACC_TYPE_CURRENT,
    PAT_ACC_TYPE_DATE_RANGE,
    PAT_ACC_TYPES
} from "../../Constants";
import {
    ICON_CANCEL,
    ICON_OK,
} from "../../icons";
import {SelectButton} from "primereact/selectbutton";
import {dropDown} from "./OnChangeUtils";
import {connect} from "react-redux";
import {getObjectStoreIds} from "../FixedItems/Preferences/Utils";
import {getObjectStore, RES_OBJECT_STORE} from "../../actions/objectStore";
import {PRINTASSTATEMENT} from "../FixedItems/Preferences/Constants";
import {getBoolean} from "../FixedItems/fixedItemUtils";
import printJS from "print-js";
import {ac} from "../../index";
import {TB_PRINT_ACCOUNT_STATEMENT, TB_PRINT_ACCOUNT_STATEMENT_PDF} from "../FixedItems/PatientDetails/Constants";
import {TabPanel} from "primereact/components/tabview/TabView";
import AccountHistoryPDF from "../FixedItems/PatientDetails/PDFs/AccountHistoryPDF";

const requiredObject = [PRINTASSTATEMENT];

export class ConnectedAccounReportDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {

            details: {
                type: PAT_ACC_TYPE_CURRENT.value,
                startDate: new Date(),
                endDate: new Date(),
                patientId: props.patientId,
                id: props.patientId,
                createdBy: props.loginIdentity.id,
                accountGroup: null,
                printAsStatement: false,
            },
            objects: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.onOkDialog = this.onOkDialog.bind(this);

        this.state.details.accountGroup = _.find(props.accountGroups, group => group.defaultAG);
    }

    componentDidMount() {
        this.props.getObjectStore(requiredObject);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_OBJECT_STORE.GET.receive:

                    const objects = this.props.objects;
                    const details = {...this.state.details};
                    details.printAsStatement = getBoolean(objects, PRINTASSTATEMENT, false);
                    this.setState({details});
                    break;

                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onStartDateChange(event) {

        const startDate = event.value;
        if (moment(startDate).isAfter(moment(this.state.details.endDate))) return;
        this.onChange(event);
    }

    onEndDateChange(event) {

        const endDate = event.value;
        if (moment(endDate).isBefore(moment(this.state.details.startDate))) return;
        this.onChange(event);
    }

    onOkDialog() {

        if (this.props.identity.id === TB_PRINT_ACCOUNT_STATEMENT.id) {

            const type = this.state.details.type;
            const patientId = this.state.details.patientId;
            const startDate = moment(this.state.details.startDate).format(JSON_DATE_FORMAT);
            const endDate = moment(this.state.details.endDate).format(JSON_DATE_FORMAT);
            const accountGroupId = this.state.details.accountGroup.id;

            printJS({
                printable: `https://${ac.getBASERESTURL()}/Patient/PDFs/patientAccount?mcId=${ac.getMcId()}&id=${patientId}&pdfType=${type}&startDate=${startDate}&endDate=${endDate}&agId=${accountGroupId}`,
                type: 'pdf',
                showModal: 'true',
                documentTitle: 'Account History'
            });
        } else {

            const {onCloseClick, onTabUpdate} = this.props;

            this.props.onPCButtonClick(
                {
                    edited: false,
                    content:
                        <TabPanel key={`${TB_PRINT_ACCOUNT_STATEMENT_PDF.id}_${this.state.details.patientId}`} header={TB_PRINT_ACCOUNT_STATEMENT_PDF.text}
                                  leftIcon={TB_PRINT_ACCOUNT_STATEMENT_PDF.tabIcon}>
                            <AccountHistoryPDF onCloseClick={onCloseClick}
                                               onTabUpdate={onTabUpdate}
                                               id={`${TB_PRINT_ACCOUNT_STATEMENT_PDF.id}_${this.state.details.patientId}`}
                                               details={this.state.details}/>
                        </TabPanel>,
                }
            )
        }
        this.props.onHideDialog(this.props.identity.id);
    }

    render() {

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => this.onOkDialog()}
                />
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => this.props.onHideDialog(this.props.identity.id)}/>
            </div>
        );

        const payProps = {
            onChange: this.onChange,
            accountGroups: this.props.accountGroups,
            target: 'details',
            details: this.state.details,
        };

        return (
            <Dialog header={this.props.identity.text}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => this.props.onHideDialog(this.props.identity.id)}>

                <div className="p-grid p-fluid form-group p-col-12">

                    <div className="p-col-6">
                        <div className="p-grid p-fluid form-group p-col-12">
                            <label>Account Details</label>
                        </div>
                        <div className="p-col-12">
                            <SelectButton value={this.state.details.type}
                                          options={PAT_ACC_TYPES}
                                          onChange={(e) => {
                                              if (e.value !== null) // null indicates no change
                                                  this.onChange({owner: `details.type`, value: e.value})
                                          }}
                            />
                        </div>
                    </div>

                    <div className="p-col-6">
                        <div className="p-grid p-fluid form-group p-col-12">
                            <label>Account Group</label>
                        </div>

                        <div className="p-col-12">
                            {dropDown(payProps, 'accountGroups', 'accountGroup', 'groupName', false, true)}
                        </div>
                    </div>

                    <div className="p-col-6">
                        <div className="p-grid p-fluid form-group p-col-12">
                            <label>Start Date</label>
                        </div>
                        <div className="p-col-12">
                            <Calendar key='startDate'
                                      value={this.state.details.startDate}
                                      onChange={(e) => this.onStartDateChange({
                                          owner: 'details.startDate',
                                          value: e.value
                                      })}
                                      inline={true} showWeek={false}
                                      disabled={this.state.details.type !== PAT_ACC_TYPE_DATE_RANGE.value}
                                      readOnlyInput={true}
                            />
                        </div>
                    </div>

                    <div className="p-col-6">
                        <div className="p-grid p-fluid form-group p-col-12">
                            <label>End Date</label>
                        </div>
                        <div className="p-col-12">
                            <Calendar key='endDate'
                                      value={this.state.details.endDate}
                                      onChange={(e) => this.onEndDateChange({owner: 'details.endDate', value: e.value})}
                                      inline={true} showWeek={false}
                                      disabled={this.state.details.type !== PAT_ACC_TYPE_DATE_RANGE.value}
                                      readOnlyInput={true}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),
    };
};

const AccounReportDialog = connect(mapStateToProps, mapDispatchToProps)(ConnectedAccounReportDialog);

export default AccounReportDialog;
