import _ from 'lodash';
import * as Actions from '../actions/index';
import {RES_REPORT_DAILYCASH} from "../actions/reports";
import {RES_PAYMENT_PLANNING} from "../actions/paymentPlanning";
import {
    SM_OVER_DUE_30,
    SM_OVER_DUE_3160,
    SM_OVER_DUE_6190,
    SM_OVER_DUE_91,
    SM_PATIENT_ACCOUNT_HISTORY_PDF,
    SM_PATIENT_ACCOUNT_ITEM_PDF,
    SM_PATIENT_APPOINTMENT_HISTORY_PDF,
    SM_PATIENT_NOTE_HISTORY_PDF
} from "../actions/stateManagement";

const initialState = {

    yearsCashLoaded: false,
    yearsCash: [],

    yearsCashPDFLoaded: false,
    yearsPDFCash: null,

    yearsShareLoaded: false,
    yearsShare: [],

    yearsSharePDFLoaded: false,
    yearsSharePDF: null,

    monthsCashLoaded: false,
    monthsCash: [],

    monthsCashPDFLoaded: false,
    monthsPDFCash: null,

    weeksSharePDFLoaded: false,
    weeksSharePDF: null,

    dailyCashLoaded: false,
    dailyCash: [],

    dailyCashPDFLoaded: false,
    dailyCashPDF: null,

    dailyTransactionsPDFLoaded: false,
    dailyTransactionsPDF: null,

    invoiceTransactionsDailyLoaded: false,
    receiptTransactionsDailyLoaded: false,
    paymentTransactionsDailyLoaded: false,
    refundTransactionsDailyLoaded: false,

    invoiceTransactionsDailyTotal: 0.0,
    receiptTransactionsDailyTotal: 0.0,
    paymentTransactionsDailyTotal: 0.0,
    refundTransactionsDailyTotal: 0.0,

    monthlyTransactionsPDFLoaded: false,
    monthlyTransactionsPDF: null,

    invoiceTransactionsMonthlyLoaded: false,
    receiptTransactionsMonthlyLoaded: false,
    paymentTransactionsMonthlyLoaded: false,
    refundTransactionsMonthlyLoaded: false,

    invoiceTransactionsMonthlyTotal: 0.0,
    receiptTransactionsMonthlyTotal: 0.0,
    paymentTransactionsMonthlyTotal: 0.0,
    refundTransactionsMonthlyTotal: 0.0,

    weeklyRegistrationsLoaded: false,
    weeklyRegistrations: [],

    weeklyRegistrationsPDFLoaded: false,
    weeklyRegistrationsPDF: [],

    dailyCancelsLoaded: false,
    dailyCancels: [],

    dailyDeletesLoaded: false,
    dailyDeletes: [],

    dailyCancelsPDFLoaded: false,
    dailyCancelsPDF: null,

    weeklyCancelsLoaded: false,
    weeklyCancels: [],

    weeklyDeletesLoaded: false,
    weeklyDeletes: [],

    failedToAttendByWeekLoaded: false,
    failedToAttendByWeek: [],

    failedToAttendByWeekPDFLoaded: false,
    failedToAttendByWeekPDF: null,

    uninvoicedChargesLoaded: false,
    uninvoicedCharges: [],

    incompleteChargesLoaded: false,
    incompleteCharges: [],

    incompleteChargesPDFLoaded: false,
    incompleteChargesPDF: null,

    inCreditsLoaded: false,
    inCredits: [],

    inCreditsPDFLoaded: false,
    inCreditsPDF: null,

    treatmentCodeUsageLoaded: false,
    treatmentCodeUsage: [],

    timeUtilisationLoaded: false,
    timeUtilisation: [],

    writeOffsLoaded: false,
    writeOffs: [],

    writeOffsPDFLoaded: false,
    writeOffsPDF: null,

    voidsLoaded: false,
    voids: [],

    voidsPDFLoaded: false,
    voidsPDF: null,

    paymentPlansActLoaded: false,
    paymentActPlans: [],

    paymentPlansFinLoaded: false,
    paymentFinPlans: [],

    paymentPlansSusLoaded: false,
    paymentSusPlans: [],

    paymentPlansWroLoaded: false,
    paymentWroPlans: [],

    paymentPlansPDFLoaded: false,
    paymentPlansPDF: null,

    treatmentPlansPDFLoaded: false,
    treatmentPlansPDF: null,

    patientJourneysLoaded: false,
    patientJourneys: [],

    patientJourneysPDFLoaded: false,
    patientJourneysPDF: null,

    patientJourneysSSLoaded: false,
    patientJourneysSS: [],

    patientJourneysSSPDFLoaded: false,
    patientJourneysSSPDF: null,

    labWorkDueLoaded: false,
    labWorkDue: [],

    labWorkDuePDFLoaded: false,
    labWorkDuePDF: null,

    xraySummaryByWeekLoaded: false,
    xraySummaryByWeek: null,

    xrayReportsByWeekLoaded: false,
    xrayReportsByWeek: null,

    xrayReportsByWeekPDFLoaded: false,
    xrayReportsByWeekPDF: null,

    copReport1Loaded: false,
    copReport1: [],

    copReport9Loaded: false,
    copReport9: [],

    costCenterLoaded: false,
    costCenter: [],

    costCenterPDFLoaded: false,
    costCenterPDF: null,

    sedationReportsByWeekLoaded: false,
    sedationReportsByWeek: [],

    sedationReportsByWeekPDFLoaded: false,
    sedationReportsByWeekPDF: null,

    sedationSummaryByWeekLoaded: false,
    sedationSummaryByWeek: [],

    sedationSummaryByWeekPDFLoaded: false,
    sedationSummaryByWeekPDF: null,

    reportTexts: {},
};

export const reports = (state = _.cloneDeep(initialState), action) => {

    try {

        switch (action.type) {

            case Actions.RECEIVE_RT_REPORT_TEXT:

                const reportTexts = {...state.reportTexts};
                reportTexts[action.rtType] = action.payload;
                return {
                    ...state,
                    reportTexts,
                };

            case Actions.REQUEST_REPORT_WEEKLY_REGISTRATIONS:
            case Actions.CLEAR_REPORT_WEEKLY_REGISTRATIONS:

                return {
                    ...state,
                    weeklyRegistrationsLoaded: false,
                    weeklyRegistrations: [],
                    weeklyRegistrationsPDFLoaded: false,
                    weeklyRegistrationsPDF: null,
                };

            case Actions.RECEIVE_REPORT_WEEKLY_REGISTRATIONS:

                return {
                    ...state,
                    weeklyRegistrationsLoaded: true,
                    weeklyRegistrations: action.payload,
                };

            case Actions.REQUEST_REPORT_WEEKLY_REGISTRATIONS_PDF:

                return {
                    ...state,
                    weeklyRegistrationsPDFLoaded: false,
                    weeklyRegistrationsPDF: null,
                };

            case Actions.RECEIVE_REPORT_WEEKLY_REGISTRATIONS_PDF:

                return {
                    ...state,
                    weeklyRegistrationsPDFLoaded: true,
                    weeklyRegistrationsPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_DAILYCASH:
            case Actions.CLEAR_REPORT_DAILYCASH:

                return {
                    ...state,
                    dailyCashLoaded: false,
                    dailyCash: [],
                    dailyCashPDFLoaded: false,
                    dailyCashPDF: null,
                };

            case Actions.RECEIVE_REPORT_DAILYCASH:

                return {
                    ...state,
                    dailyCashLoaded: true,
                    dailyCash: action.payload,
                };

            case Actions.REQUEST_REPORT_DAILYCASH_PDF:

                return {
                    ...state,
                    dailyCashPDFLoaded: false,
                    dailyCashPDF: null,
                };

            case Actions.RECEIVE_REPORT_DAILYCASH_PDF:

                return {
                    ...state,
                    dailyCashPDFLoaded: true,
                    dailyCashPDF: action.payload,
                };

            case Actions.CLEAR_REPORT_DAILY_TRANSACTIONS:

                return {
                    ...state,
                    dailyTransactionsPDFLoaded: false,
                    dailyTransactionsPDF: null,

                    invoiceTransactionsDailyLoaded: false,
                    receiptTransactionsDailyLoaded: false,
                    paymentTransactionsDailyLoaded: false,
                    refundTransactionsDailyLoaded: false,
                };

            case Actions.REQUEST_REPORT_DAILY_TRANSACTIONS_PDF:

                return {
                    ...state,
                    dailyTransactionsPDFLoaded: false,
                    dailyTransactionsPDF: null,
                };

            case Actions.RECEIVE_REPORT_DAILY_TRANSACTIONS_PDF:

                return {
                    ...state,
                    dailyTransactionsPDFLoaded: true,
                    dailyTransactionsPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_WEEKLY_SHARE:
            case Actions.CLEAR_REPORT_WEEKLY_SHARE:

                return {
                    ...state,
                    weeklyShareLoaded: false,
                    weeklyShare: [],
                    weeklySharePDFLoaded: false,
                    weeklySharePDF: null,
                };

            case Actions.RECEIVE_REPORT_WEEKLY_SHARE:

                return {
                    ...state,
                    weeklyShareLoaded: true,
                    weeklyShare: action.payload,
                };

            case Actions.CLEAR_REPORT_MONTHS_TRANSACTIONS:

                return {
                    ...state,
                    monthlyTransactionsPDFLoaded: false,
                    monthlyTransactionsPDF: null,

                    invoiceTransactionsMonthlyLoaded: false,
                    receiptTransactionsMonthlyLoaded: false,
                    paymentTransactionsMonthlyLoaded: false,
                    refundTransactionsMonthlyLoaded: false,
                };

            case Actions.REQUEST_REPORT_MONTHS_TRANSACTIONS_PDF:

                return {
                    ...state,
                    monthlyTransactionsPDFLoaded: false,
                    monthlyTransactionsPDF: null,
                };

            case Actions.RECEIVE_REPORT_MONTHS_TRANSACTIONS_PDF:

                return {
                    ...state,
                    monthlyTransactionsPDFLoaded: true,
                    monthlyTransactionsPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_MONTHS_CASH:
            case Actions.CLEAR_REPORT_MONTHS_CASH:

                return {
                    ...state,
                    monthsCashLoaded: false,
                    monthsCash: [],
                    monthsCashPDFLoaded: false,
                    monthsCashPDF: null,
                };

            case Actions.RECEIVE_REPORT_MONTHS_CASH:

                return {
                    ...state,
                    monthsCashLoaded: true,
                    monthsCash: action.payload.cash,
                };

            case Actions.REQUEST_REPORT_MONTHS_CASH_PDF:

                return {
                    ...state,
                    monthsCashPDFLoaded: false,
                    monthsCashPDF: null,
                };

            case Actions.RECEIVE_REPORT_MONTHS_CASH_PDF:

                return {
                    ...state,
                    monthsCashPDFLoaded: true,
                    monthsCashPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_MONTHLY_SHARE:
            case Actions.CLEAR_REPORT_MONTHLY_SHARE:

                return {
                    ...state,
                    monthlyShareLoaded: false,
                    monthlyShare: [],
                    monthlySharePDFLoaded: false,
                    monthlySharePDF: null,
                };

            case Actions.RECEIVE_REPORT_MONTHLY_SHARE:

                return {
                    ...state,
                    monthlyShareLoaded: true,
                    monthlyShare: action.payload,
                };

            case Actions.REQUEST_REPORT_MONTHLY_SHARE_PDF:

                return {
                    ...state,
                    monthlySharePDFLoaded: false,
                    monthlySharePDF: null,
                };

            case Actions.RECEIVE_REPORT_MONTHLY_SHARE_PDF:

                return {
                    ...state,
                    monthlySharePDFLoaded: true,
                    monthlySharePDF: action.payload,
                };

            case Actions.REQUEST_REPORT_YEARS_CASH:
            case Actions.CLEAR_REPORT_YEARS_CASH:

                return {
                    ...state,
                    yearsCashLoaded: false,
                    yearsCash: [],
                    yearsCashPDFLoaded: false,
                    yearsCashPDF: null,
                };

            case Actions.RECEIVE_REPORT_YEARS_CASH:

                return {
                    ...state,
                    yearsCashLoaded: true,
                    yearsCash: action.payload.cash,
                };

            case Actions.REQUEST_REPORT_YEARS_CASH_PDF:

                return {
                    ...state,
                    yearsCashPDFLoaded: false,
                    yearsCashPDF: null,
                };

            case Actions.RECEIVE_REPORT_YEARS_CASH_PDF:

                return {
                    ...state,
                    yearsCashPDFLoaded: true,
                    yearsCashPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_YEARS_SHARE:
            case Actions.CLEAR_REPORT_YEARS_SHARE:

                return {
                    ...state,
                    yearsShareLoaded: false,
                    yearsShare: [],
                    yearsSharePDFLoaded: false,
                    yearsSharePDF: null,
                };

            case Actions.RECEIVE_REPORT_YEARS_SHARE:

                return {
                    ...state,
                    yearsShareLoaded: true,
                    yearsShare: action.payload,
                };

            case Actions.REQUEST_REPORT_YEARS_SHARE_PDF:

                return {
                    ...state,
                    yearsSharePDFLoaded: false,
                    yearsSharePDF: null,
                };

            case Actions.RECEIVE_REPORT_YEARS_SHARE_PDF:

                return {
                    ...state,
                    yearsSharePDFLoaded: true,
                    yearsSharePDF: action.payload,
                };

            case Actions.REQUEST_REPORT_ACT_PPS:

                return {
                    ...state,
                    paymentPlansActLoaded: false,
                };

            case Actions.RECEIVE_REPORT_ACT_PPS:

                return {
                    ...state,
                    paymentPlansActLoaded: true,
                    paymentPlansAct: action.payload,
                };

            case Actions.REQUEST_REPORT_FIN_PPS:

                return {
                    ...state,
                    paymentPlansFinLoaded: false,
                };

            case Actions.RECEIVE_REPORT_FIN_PPS:

                return {
                    ...state,
                    paymentPlansFinLoaded: true,
                    paymentPlansFin: action.payload,
                };

            case Actions.REQUEST_REPORT_SUS_PPS:

                return {
                    ...state,
                    paymentPlansSusLoaded: false,
                };

            case Actions.RECEIVE_REPORT_SUS_PPS:

                return {
                    ...state,
                    paymentPlansSusLoaded: true,
                    paymentPlansSus: action.payload,
                };

            case Actions.REQUEST_REPORT_WRO_PPS:

                return {
                    ...state,
                    paymentPlansWroLoaded: false,
                };

            case Actions.RECEIVE_REPORT_WRO_PPS:

                return {
                    ...state,
                    paymentPlansWroLoaded: true,
                    paymentPlansWro: action.payload,
                };

            case Actions.CLEAR_REPORT_PPS:

                return {
                    ...state,
                    paymentPlansActLoaded: false,
                    paymentPlansAct: [],
                    paymentPlansFinLoaded: false,
                    paymentPlansFin: [],
                    paymentPlansSusLoaded: false,
                    paymentPlansSus: [],
                    paymentPlansWroLoaded: false,
                    paymentPlansWro: [],
                };

            case RES_PAYMENT_PLANNING.SUSPEND.action: {

                const paymentPlansAct = _.filter(state.paymentPlansAct, plan => plan.id !== action.payload.id);
                const paymentPlansSus = _.concat(action.payload, state.paymentPlansSus);

                return {
                    ...state,
                    paymentPlansAct,
                    paymentPlansSus,
                }
            }

            case RES_PAYMENT_PLANNING.RESUME.action: {

                const paymentPlansSus = _.filter(state.paymentPlansSus, plan => plan.id !== action.payload.id);
                const paymentPlansAct = _.concat(action.payload, state.paymentPlansAct);

                return {
                    ...state,
                    paymentPlansAct,
                    paymentPlansSus,
                }
            }

            case RES_PAYMENT_PLANNING.WRITE_OFF.action: {

                const paymentPlansAct = _.filter(state.paymentPlansAct, plan => plan.id !== action.payload.id);
                const paymentPlansSus = _.filter(state.paymentPlansSus, plan => plan.id !== action.payload.id);
                const paymentPlansWro = _.concat(action.payload, state.paymentPlansWro);

                return {
                    ...state,
                    paymentPlansAct,
                    paymentPlansSus,
                    paymentPlansWro,
                }
            }

            case Actions.REQUEST_REPORT_PAT_JOURNEYS:
            case Actions.CLEAR_REPORT_PAT_JOURNEYS:

                return {
                    ...state,
                    patientJourneysLoaded: false,
                    patientJourneys: [],
                    patientJourneysPDFLoaded: false,
                    patientJourneysPDF: null,
                };

            case Actions.RECEIVE_REPORT_PAT_JOURNEYS:

                return {
                    ...state,
                    patientJourneysLoaded: true,
                    patientJourneys: action.payload,
                    patientJourneysPDFLoaded: false,
                    patientJourneysPDF: null,
                };

            case Actions.RECEIVE_REPORT_PAT_JOURNEYS_PDF:

                return {
                    ...state,
                    patientJourneysPDFLoaded: true,
                    patientJourneysPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_WEEKLY_FTA:

                return {
                    ...state,
                    failedToAttendByWeekLoaded: false,
                };

            case Actions.RECEIVE_REPORT_WEEKLY_FTA:

                return {
                    ...state,
                    failedToAttendByWeekLoaded: true,
                    failedToAttendByWeek: action.payload,
                };

            case Actions.REQUEST_REPORT_WEEKLY_FTA_PDF:

                return {
                    ...state,
                    failedToAttendByWeekPDFLoaded: false,
                };

            case Actions.RECEIVE_REPORT_WEEKLY_FTA_PDF:

                return {
                    ...state,
                    failedToAttendByWeekPDFLoaded: true,
                    failedToAttendByWeekPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_PAT_JOURNEYS_SS:
            case Actions.CLEAR_REPORT_PAT_JOURNEYS_SS:

                return {
                    ...state,
                    patientJourneysSSLoaded: false,
                    patientJourneysSS: [],
                    patientJourneysSSPDFLoaded: false,
                    patientJourneysSSPDF: null,
                };

            case Actions.RECEIVE_REPORT_PAT_JOURNEYS_SS:

                return {
                    ...state,
                    patientJourneysSSLoaded: true,
                    patientJourneysSS: action.payload,
                };

            case Actions.RECEIVE_REPORT_PAT_JOURNEYS_SS_PDF:

                return {
                    ...state,
                    patientJourneysSSPDFLoaded: true,
                    patientJourneysSSPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_DAILY_CANCELS:
            case Actions.CLEAR_REPORT_DAILY_CANCELS:

                return {
                    ...state,
                    dailyCancelsLoaded: false,
                    dailyCancels: [],
                    dailyCancelsPDFLoaded: false,
                    dailyCancelsPDF: null,
                };

            case Actions.RECEIVE_REPORT_DAILY_CANCELS:

                return {
                    ...state,
                    dailyCancelsLoaded: true,
                    dailyCancels: action.payload,
                };

            case Actions.REQUEST_REPORT_DAILY_DELETES:

                return {
                    ...state,
                    dailyDeletesLoaded: false,
                };

            case Actions.RECEIVE_REPORT_DAILY_DELETES:

                return {
                    ...state,
                    dailyDeletesLoaded: true,
                    dailyDeletes: action.payload,
                };

            case Actions.REQUEST_REPORT_DAILY_CANCELS_PDF:

                return {
                    ...state,
                    dailyCancelsPDFLoaded: false,
                    dailyCancelsPDF: null,
                };

            case Actions.RECEIVE_REPORT_DAILY_CANCELS_PDF:

                return {
                    ...state,
                    dailyCancelsPDFLoaded: true,
                    dailyCancelsPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_WEEKLY_CANCELS:
            case Actions.CLEAR_REPORT_WEEKLY_CANCELS:

                return {
                    ...state,
                    weeklyCancelsLoaded: false,
                    weeklyCancels: [],
                    weeklyCancelsPDFLoaded: false,
                    weeklyCancelsPDF: null,
                };

            case Actions.RECEIVE_REPORT_WEEKLY_CANCELS:

                return {
                    ...state,
                    weeklyCancelsLoaded: true,
                    weeklyCancels: action.payload,
                };

            case Actions.REQUEST_REPORT_WEEKLY_DELETES:

                return {
                    ...state,
                    weeklyDeletesLoaded: false,
                };

            case Actions.RECEIVE_REPORT_WEEKLY_DELETES:

                return {
                    ...state,
                    weeklyDeletesLoaded: true,
                    weeklyDeletes: action.payload,
                };

            case Actions.REQUEST_REPORT_WEEKLY_CANCELS_PDF:

                return {
                    ...state,
                    weeklyCancelsPDFLoaded: false,
                    weeklyCancelsPDF: null,
                };

            case Actions.RECEIVE_REPORT_WEEKLY_CANCELS_PDF:

                return {
                    ...state,
                    weeklyCancelsPDFLoaded: false,
                    weeklyCancelsPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_UNINVOICED_CHARGES:

                return {
                    ...state,
                    uninvoicedChargesLoaded: false,
                };

            case Actions.RECEIVE_REPORT_UNINVOICED_CHARGES:

                return {
                    ...state,
                    uninvoicedChargesLoaded: true,
                    uninvoicedCharges: action.payload,
                };

            case Actions.REQUEST_REPORT_UNINVOICED_CHARGES_PDF:
            case Actions.CLEAR_REPORT_UNINVOICED_CHARGES_PDF:

                return {
                    ...state,
                    uninvoicedChargesPDFLoaded: false,
                    uninvoicedChargesPDF: null,
                };

            case Actions.RECEIVE_REPORT_UNINVOICED_CHARGES_PDF:

                return {
                    ...state,
                    uninvoicedChargesPDFLoaded: true,
                    uninvoicedChargesPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_INCOMPLETE_CHARGES:

                return {
                    ...state,
                    incompleteChargesLoaded: false,
                };

            case Actions.RECEIVE_REPORT_INCOMPLETE_CHARGES:

                return {
                    ...state,
                    incompleteChargesLoaded: true,
                    incompleteCharges: action.payload,
                };

            case Actions.REQUEST_REPORT_INCOMPLETE_CHARGES_PDF:
            case Actions.CLEAR_REPORT_INCOMPLETE_CHARGES_PDF:

                return {
                    ...state,
                    incompleteChargesPDFLoaded: false,
                    incompleteChargesPDF: null,
                };

            case Actions.RECEIVE_REPORT_INCOMPLETE_CHARGES_PDF:

                return {
                    ...state,
                    incompleteChargesPDFLoaded: true,
                    incompleteChargesPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_WRITE_OFFS:
            case Actions.CLEAR_REPORT_WRITE_OFFS:

                return {
                    ...state,
                    writeOffsLoaded: false,
                    writeOffs: [],
                    writeOffsPDFLoaded: false,
                    writeOffsPDF: null,
                };

            case Actions.RECEIVE_REPORT_WRITE_OFFS:

                return {
                    ...state,
                    writeOffsLoaded: true,
                    writeOffs: action.payload,
                };

            case Actions.REQUEST_REPORT_WRITE_OFFS_PDF:

                return {
                    ...state,
                    writeOffsPDFLoaded: false,
                    writeOffsPDF: null,
                };

            case Actions.RECEIVE_REPORT_WRITE_OFFS_PDF:

                return {
                    ...state,
                    writeOffsPDFLoaded: true,
                    writeOffsPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_VOIDS:
            case Actions.CLEAR_REPORT_VOIDS:

                return {
                    ...state,
                    voidsLoaded: false,
                    voids: [],
                    voidsPDFLoaded: false,
                    voidsPDF: null,
                };

            case Actions.RECEIVE_REPORT_VOIDS:

                return {
                    ...state,
                    voidsLoaded: true,
                    voids: action.payload,
                };

            case Actions.REQUEST_REPORT_VOIDS_PDF:

                return {
                    ...state,
                    voidsPDFLoaded: false,
                    voidsPDF: null,
                };

            case Actions.RECEIVE_REPORT_VOIDS_PDF:

                return {
                    ...state,
                    voidsPDFLoaded: true,
                    voidsPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_IN_CREDIT:
            case Actions.CLEAR_REPORT_IN_CREDIT:

                return {
                    ...state,
                    inCreditsLoaded: false,
                    inCredits: [],
                    inCreditsPDFLoaded: false,
                    inCreditsPDF: null,
                };

            case Actions.RECEIVE_REPORT_IN_CREDIT:

                return {
                    ...state,
                    inCreditsLoaded: true,
                    inCredits: action.payload,
                };

            case Actions.REQUEST_REPORT_IN_CREDIT_PDF:
            case Actions.CLEAR_REPORT_IN_CREDIT_PDF:

                return {
                    ...state,
                    inCreditsPDFLoaded: false,
                    inCreditsPDF: null,
                };

            case Actions.RECEIVE_REPORT_IN_CREDIT_PDF:

                return {
                    ...state,
                    inCreditsPDFLoaded: true,
                    inCreditsPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_TREATMENT_CODE_USAGE:

                return {
                    ...state,
                    treatmentCodeUsageLoaded: false,
                };

            case Actions.RECEIVE_REPORT_TREATMENT_CODE_USAGE:

                return {
                    ...state,
                    treatmentCodeUsageLoaded: true,
                    treatmentCodeUsage: action.payload,
                };

            case Actions.REQUEST_REPORT_CLINICIAN_USAGE:

                return {
                    ...state,
                    timeUtilisationLoaded: false,
                };

            case Actions.RECEIVE_REPORT_CLINICIAN_USAGE:

                const formatDate = date => {
                    const [day, month, year] = date.split('/');
                    return `${year}-${month}-${day}`;
                };

                const timeUtilisation = [];

                Object.keys(action.payload).forEach(key => {
                    action.payload[key].forEach(clinician => {
                        timeUtilisation.push({
                            clinician: key,
                            date: clinician.date,
                            formattedDate: formatDate(clinician.date),
                            available: clinician.available,
                            lunch: clinician.lunch,
                            appointments: clinician.appointments,
                            iAppointments: clinician.iAppointments,
                            events: clinician.events,
                            iEvents: clinician.iEvents,
                        });
                    });
                });

                return {
                    ...state,
                    timeUtilisationLoaded: true,
                    timeUtilisation,
                };

            //    Daily Transactions
            case Actions.REQUEST_REPORT_TRANS_DAYS_INVOICES:

                return {
                    ...state,
                    invoiceTransactionsDailyLoaded: false,
                    invoiceTransactionsDaily: [],
                    invoiceTransactionsDailyTotal: 0.0,
                };

            case Actions.RECEIVE_REPORT_TRANS_DAYS_INVOICES: {

                let invoiceTransactionsDailyTotal = 0.0;

                const invoiceTransactionsDaily = [];

                action.payload.forEach(invoice => {
                    const {patientId, patientName, number, placementOrder, username, total} = invoice;
                    invoiceTransactionsDailyTotal += total;
                    invoiceTransactionsDaily.push({
                        patientId,
                        showHeader: true,
                        patientName,
                        number,
                        placementOrder,
                        username,
                        total,
                    });
                    invoice.charges.forEach((charge) => {
                        const _charge = {...charge};
                        _charge.number = number;
                        invoiceTransactionsDaily.push(_charge);
                    })
                });

                return {
                    ...state,
                    invoiceTransactionsDailyLoaded: true,
                    invoiceTransactionsDaily,
                    invoiceTransactionsDailyTotal,
                };
            }
            case Actions.REQUEST_REPORT_TRANS_DAYS_RECEIPTS:

                return {
                    ...state,
                    receiptTransactionsDailyLoaded: false,
                    receiptTransactionsDaily: [],
                    receiptTransactionsDailyTotal: 0.0,
                };

            case Actions.RECEIVE_REPORT_TRANS_DAYS_RECEIPTS: {

                let receiptTransactionsDailyTotal = 0.0;

                const receiptTransactionsDaily = [];

                action.payload.forEach(receipt => {
                    const {patientId, patientName, number, placementOrder, formattedType, total, username} = receipt;
                    receiptTransactionsDailyTotal += total;
                    receiptTransactionsDaily.push({
                        patientId,
                        showHeader: true,
                        patientName,
                        number,
                        placementOrder,
                        formattedType,
                        total,
                        username
                    });
                    receipt.charges.forEach((charge) => {
                        const _charge = {...charge};
                        _charge.number = number;
                        receiptTransactionsDaily.push(_charge);
                    })
                });

                return {
                    ...state,
                    receiptTransactionsDailyLoaded: true,
                    receiptTransactionsDaily,
                    receiptTransactionsDailyTotal,
                };
            }
            case Actions.REQUEST_REPORT_TRANS_DAYS_PAYMENTS:

                return {
                    ...state,
                    paymentTransactionsDailyLoaded: false,
                    paymentTransactionsDaily: [],
                    paymentTransactionsDailyTotal: 0.0,
                };

            case Actions.RECEIVE_REPORT_TRANS_DAYS_PAYMENTS: {

                let paymentTransactionsDailyTotal = 0.0;

                const paymentTransactionsDaily = [];

                action.payload.forEach(payment => {
                    const {
                        patientId,
                        patientName,
                        number,
                        formattedTotal,
                        placementOrder,
                        formattedType,
                        username
                    } = payment;
                    paymentTransactionsDailyTotal += payment.amount;
                    paymentTransactionsDaily.push({
                        patientId,
                        showHeader: true,
                        payment: true,
                        patientName,
                        number,
                        amount: formattedTotal,
                        placementOrder,
                        formattedType,
                        username,
                    });
                });

                return {
                    ...state,
                    paymentTransactionsDailyLoaded: true,
                    paymentTransactionsDaily,
                    paymentTransactionsDailyTotal,
                };
            }
            case Actions.REQUEST_REPORT_TRANS_DAYS_REFUNDS:

                return {
                    ...state,
                    refundTransactionsDailyLoaded: false,
                    refundTransactionsDaily: [],
                    refundTransactionsDailyTotal: 0.0,
                };

            case Actions.RECEIVE_REPORT_TRANS_DAYS_REFUNDS: {

                let refundTransactionsDailyTotal = 0.0;

                const refundTransactionsDaily = [];

                action.payload.forEach(refund => {
                    const {patientId, patientName, number, formattedAmount, placementOrder} = refund;
                    refundTransactionsDailyTotal += refund.amount;
                    refundTransactionsDaily.push({
                        patientId,
                        showHeader: true,
                        patientName,
                        number,
                        amount: formattedAmount,
                        placementOrder,
                    });
                });

                return {
                    ...state,
                    refundTransactionsDailyLoaded: true,
                    refundTransactionsDaily,
                    refundTransactionsDailyTotal,
                };
            }
            // Monthly Transactions
            case Actions.REQUEST_REPORT_TRANS_MONTHS_INVOICES:

                return {
                    ...state,
                    invoiceTransactionsMonthlyLoaded: false,
                    invoiceTransactionsMonthly: [],
                    invoiceTransactionsMonthlyTotal: 0.0,
                };

            case Actions.RECEIVE_REPORT_TRANS_MONTHS_INVOICES: {

                let invoiceTransactionsMonthlyTotal = 0.0;

                const invoiceTransactionsMonthly = [];

                action.payload.forEach(invoice => {
                    const {patientId, patientName, number, placementOrder, username, total, raisedOn} = invoice;
                    invoiceTransactionsMonthlyTotal += total;
                    invoiceTransactionsMonthly.push({
                        patientId,
                        showHeader: true,
                        patientName,
                        number,
                        placementOrder,
                        username,
                        total,
                        date: raisedOn
                    });
                    invoice.charges.forEach((charge) => {
                        const _charge = {...charge};
                        _charge.number = number;
                        _charge.placementOrder = placementOrder;
                        invoiceTransactionsMonthly.push(_charge);
                    })
                });

                return {
                    ...state,
                    invoiceTransactionsMonthlyLoaded: true,
                    invoiceTransactionsMonthly,
                    invoiceTransactionsMonthlyTotal,
                };
            }
            case Actions.REQUEST_REPORT_TRANS_MONTHS_RECEIPTS:

                return {
                    ...state,
                    receiptTransactionsMonthlyLoaded: false,
                    receiptTransactionsMonthly: [],
                    receiptTransactionsMonthlyTotal: 0.0,
                };

            case Actions.RECEIVE_REPORT_TRANS_MONTHS_RECEIPTS: {

                let receiptTransactionsMonthlyTotal = 0.0;

                const receiptTransactionsMonthly = [];

                action.payload.forEach(receipt => {
                    const {patientId, patientName, number, placementOrder, formattedType, total, username, narrative, date} = receipt;
                    receiptTransactionsMonthlyTotal += total;
                    receiptTransactionsMonthly.push({
                        patientId,
                        showHeader: true,
                        patientName,
                        number,
                        placementOrder,
                        formattedType,
                        total,
                        username,
                        narrative,
                        date
                    });
                    receipt.charges.forEach((charge) => {
                        const _charge = {...charge};
                        _charge.number = number;
                        _charge.placementOrder = placementOrder;
                        receiptTransactionsMonthly.push(_charge);
                    })
                });

                return {
                    ...state,
                    receiptTransactionsMonthlyLoaded: true,
                    receiptTransactionsMonthly,
                    receiptTransactionsMonthlyTotal,
                };
            }
            case Actions.REQUEST_REPORT_TRANS_MONTHS_PAYMENTS:

                return {
                    ...state,
                    paymentTransactionsMonthlyLoaded: false,
                    paymentTransactionsMonthly: [],
                    paymentTransactionsMonthlyTotal: 0.0,
                };

            case Actions.RECEIVE_REPORT_TRANS_MONTHS_PAYMENTS: {

                let paymentTransactionsMonthlyTotal = 0.0;

                const paymentTransactionsMonthly = [];

                action.payload.forEach(payment => {
                    const {
                        patientId,
                        patientName,
                        number,
                        formattedTotal,
                        placementOrder,
                        formattedType,
                        username,
                        narrative,
                        ufdate,
                    } = payment;
                    paymentTransactionsMonthlyTotal += payment.amount;
                    paymentTransactionsMonthly.push({
                        patientId,
                        showHeader: true,
                        payment: true,
                        patientName,
                        number,
                        amount: formattedTotal,
                        placementOrder,
                        formattedType,
                        username,
                        narrative,
                        date: ufdate
                    });
                });

                return {
                    ...state,
                    paymentTransactionsMonthlyLoaded: true,
                    paymentTransactionsMonthly,
                    paymentTransactionsMonthlyTotal,
                };
            }
            case Actions.REQUEST_REPORT_TRANS_MONTHS_REFUNDS:

                return {
                    ...state,
                    refundTransactionsMonthlyLoaded: false,
                    refundTransactionsMonthly: [],
                    refundTransactionsMonthlyTotal: 0.0,
                };

            case Actions.RECEIVE_REPORT_TRANS_MONTHS_REFUNDS: {

                let refundTransactionsMonthlyTotal = 0.0;

                const refundTransactionsMonthly = [];

                action.payload.forEach(refund => {
                    const {patientId, patientName, number, formattedAmount, placementOrder} = refund;
                    refundTransactionsMonthlyTotal += refund.amount;
                    refundTransactionsMonthly.push({
                        patientId,
                        showHeader: true,
                        patientName,
                        number,
                        amount: formattedAmount,
                        placementOrder,
                    });
                });

                return {
                    ...state,
                    refundTransactionsMonthlyLoaded: true,
                    refundTransactionsMonthly,
                    refundTransactionsMonthlyTotal,
                };
            }

            case Actions.REQUEST_OVER_DUE_30:

                return {
                    ...state,
                    [`${SM_OVER_DUE_30.loaded}_${action.id}`]: false,
                    [`${SM_OVER_DUE_30.id}_${action.id}`]: [],
                };

            case Actions.RECEIVE_OVER_DUE_30:

                return {
                    ...state,
                    [`${SM_OVER_DUE_30.loaded}_${action.id}`]: true,
                    [`${SM_OVER_DUE_30.id}_${action.id}`]: action.payload,
                };

            case Actions.REQUEST_OVER_DUE_3160:

                return {
                    ...state,
                    [`${SM_OVER_DUE_3160.loaded}_${action.id}`]: false,
                    [`${SM_OVER_DUE_3160.id}_${action.id}`]: [],
                };

            case Actions.RECEIVE_OVER_DUE_3160:

                return {
                    ...state,
                    [`${SM_OVER_DUE_3160.loaded}_${action.id}`]: true,
                    [`${SM_OVER_DUE_3160.id}_${action.id}`]: action.payload,
                };

            case Actions.REQUEST_OVER_DUE_6190:

                return {
                    ...state,
                    [`${SM_OVER_DUE_6190.loaded}_${action.id}`]: false,
                    [`${SM_OVER_DUE_6190.id}_${action.id}`]: [],
                };

            case Actions.RECEIVE_OVER_DUE_6190:

                return {
                    ...state,
                    [`${SM_OVER_DUE_6190.loaded}_${action.id}`]: true,
                    [`${SM_OVER_DUE_6190.id}_${action.id}`]: action.payload,
                };

            case Actions.REQUEST_OVER_DUE_91:

                return {
                    ...state,
                    [`${SM_OVER_DUE_91.loaded}_${action.id}`]: false,
                    [`${SM_OVER_DUE_91.id}_${action.id}`]: [],
                };

            case Actions.RECEIVE_OVER_DUE_91:

                return {
                    ...state,
                    [`${SM_OVER_DUE_91.loaded}_${action.id}`]: true,
                    [`${SM_OVER_DUE_91.id}_${action.id}`]: action.payload,
                };

            case RES_REPORT_DAILYCASH.CLEAR.action:

                return {
                    ...state,
                    dailyCashLoaded: false,
                    dailyCash: [],
                };

            case Actions.REQUEST_ACCOUNT_ITEM_PDF: {

                const itemState = {...state};
                const loadedIndex = `${SM_PATIENT_ACCOUNT_ITEM_PDF.loaded}_${action.id}`;
                const index = `${SM_PATIENT_ACCOUNT_ITEM_PDF.id}_${action.id}`;

                itemState[loadedIndex] = false;
                itemState[index] = null;

                return itemState;
            }
            case Actions.RECEIVE_ACCOUNT_ITEM_PDF: {

                const itemState = {...state};
                const loadedIndex = `${SM_PATIENT_ACCOUNT_ITEM_PDF.loaded}_${action.id}`;
                const index = `${SM_PATIENT_ACCOUNT_ITEM_PDF.id}_${action.id}`;

                itemState[loadedIndex] = true;
                itemState[index] = action.payload;

                return itemState;
            }
            case Actions.RECEIVE_PATIENT_ACCOUNT_HISTORY_PDF: {

                const historyState = {...state};
                const loadedIndex = `${SM_PATIENT_ACCOUNT_HISTORY_PDF.loaded}_${action.id}`;
                const index = `${SM_PATIENT_ACCOUNT_HISTORY_PDF.id}_${action.id}`;

                historyState[loadedIndex] = true;
                historyState[index] = action.payload;

                return historyState;
            }
            case Actions.RECEIVE_PATIENT_NOTE_HISTORY_PDF: {

                const historyState = {...state};
                const loadedIndex = `${SM_PATIENT_NOTE_HISTORY_PDF.loaded}_${action.id}`;
                const index = `${SM_PATIENT_NOTE_HISTORY_PDF.id}_${action.id}`;

                historyState[loadedIndex] = true;
                historyState[index] = action.payload;

                return historyState;
            }
            case Actions.RECEIVE_PATIENT_APPOINTMENT_HISTORY_PDF: {

                const historyState = {...state};
                const loadedIndex = `${SM_PATIENT_APPOINTMENT_HISTORY_PDF.loaded}_${action.patientId}`;
                const index = `${SM_PATIENT_APPOINTMENT_HISTORY_PDF.id}_${action.patientId}`;

                historyState[loadedIndex] = true;
                historyState[index] = action.payload;

                return historyState;
            }

            case Actions.REQUEST_REPORT_WORK_REQUIRED_DUE:
            case Actions.CLEAR_REPORT_WORK_REQUIRED_DUE:

                return {
                    ...state,
                    labWorkDueLoaded: false,
                    labWorkDue: [],
                    labWorkDuePDFLoaded: false,
                    labWorkDuePDF: null,
                };

            case Actions.RECEIVE_REPORT_WORK_REQUIRED_DUE:

                return {
                    ...state,
                    labWorkDueLoaded: true,
                    labWorkDue: action.payload,
                };

            case Actions.REQUEST_REPORT_WORK_REQUESTED:
            case Actions.CLEAR_REPORT_WORK_REQUESTED:

                return {
                    ...state,
                    labWorkRequestedLoaded: false,
                    labWorkRequested: [],
                    labWorkRequestedPDFLoaded: false,
                    labWorkRequestedPDF: null,
                };

            case Actions.RECEIVE_REPORT_WORK_REQUESTED:

                return {
                    ...state,
                    labWorkRequestedLoaded: true,
                    labWorkRequested: action.payload,
                };

            case Actions.REQUEST_REPORT_WORK_RECEIVED:
            case Actions.CLEAR_REPORT_WORK_RECEIVED:
            case Actions.CLEAR_REPORT_WORK_FOR_APPROVAL:

                return {
                    ...state,
                    labWorkReceivedLoaded: false,
                    labWorkReceived: [],
                    labWorkReceivedPDFLoaded: false,
                    labWorkReceivedPDF: null,
                    labWorkApprovalLoaded: false,
                    labWorkApproval: [],
                    labWorkApprovalPDFLoaded: false,
                    labWorkApprovalPDF: null,
                };

            case Actions.RECEIVE_REPORT_WORK_RECEIVED:

                return {
                    ...state,
                    labWorkReceivedLoaded: true,
                    labWorkReceived: action.payload,
                };

            case Actions.REQUEST_REPORT_WORK_FOR_APPROVAL:

                return {
                    ...state,
                    labWorkApprovalLoaded: false,
                    labWorkApproval: [],
                    labWorkApprovalPDFLoaded: false,
                    labWorkApprovalPDF: null,
                };

            case Actions.RECEIVE_REPORT_WORK_FOR_APPROVAL:

                return {
                    ...state,
                    labWorkApprovalLoaded: true,
                    labWorkApproval: action.payload,
                };

            case Actions.REQUEST_REPORT_COP1:

                return {
                    ...state,
                    copReport1Loaded: false,
                    copReport1: [],
                };

            case Actions.RECEIVE_REPORT_COP1:

                return {
                    ...state,
                    copReport1Loaded: true,
                    copReport1: action.payload,
                };

            case Actions.REQUEST_REPORT_COP9:

                return {
                    ...state,
                    copReport9Loaded: false,
                    copReport9: [],
                };

            case Actions.RECEIVE_REPORT_COP9:

                return {
                    ...state,
                    copReport9Loaded: true,
                    copReport9: action.payload,
                };

            case Actions.REQUEST_REPORT_WEEKLY_XRAY_SUMMARY:
            case Actions.CLEAR_REPORT_WEEKLY_XRAY_SUMMARY:

                return {
                    ...state,
                    xraySummaryByWeekLoaded: false,
                    xraySummaryByWeek: [],
                };

            case Actions.RECEIVE_REPORT_WEEKLY_XRAY_SUMMARY: {

                let index = 0;
                const summary = {
                    totalExc: action.payload[index++],
                    totalAcc: action.payload[index++],
                    totalUna: action.payload[index++],

                    totalBW: action.payload[index++],
                    totalBWExc: action.payload[index++],
                    totalBWAcc: action.payload[index++],
                    totalBWUna: action.payload[index++],
                    totalBWRep: action.payload[index++],

                    totalPA: action.payload[index++],
                    totalPAExc: action.payload[index++],
                    totalPAAcc: action.payload[index++],
                    totalPAUna: action.payload[index++],
                    totalPARep: action.payload[index++],

                    totalOC: action.payload[index++],
                    totalOCExc: action.payload[index++],
                    totalOCAcc: action.payload[index++],
                    totalOCUna: action.payload[index++],
                    totalOCRep: action.payload[index++],

                    totalPAN: action.payload[index++],
                    totalPANExc: action.payload[index++],
                    totalPANAcc: action.payload[index++],
                    totalPANUna: action.payload[index++],
                    totalPANRep: action.payload[index++],

                    totalCM: action.payload[index++],
                    totalCMExc: action.payload[index++],
                    totalCMAcc: action.payload[index++],
                    totalCMUna: action.payload[index++],
                    totalCMRep: action.payload[index++],

                    totalCBCT: action.payload[index++],
                    totalCBCTExc: action.payload[index++],
                    totalCBCTAcc: action.payload[index++],
                    totalCBCTUna: action.payload[index++],
                    totalCBCTRep: action.payload[index],
                };
                return {
                    ...state,
                    xraySummaryByWeekLoaded: true,
                    xraySummaryByWeek: summary,
                };
            }
            case Actions.REQUEST_REPORT_WEEKLY_XRAY_REPORT:
            case Actions.CLEAR_REPORT_WEEKLY_XRAY_REPORT:

                return {
                    ...state,
                    xrayReportsByWeekLoaded: false,
                    xrayReportsByWeek: [],
                    xrayReportsByWeekPDFLoaded: true,
                    xrayReportsByWeekPDF: null,
                };

            case Actions.REQUEST_REPORT_WEEKLY_XRAY_REPORT_PDF:

                return {
                    ...state,
                    xrayReportsByWeekPDFLoaded: true,
                    xrayReportsByWeekPDF: null,
                };

            case Actions.RECEIVE_REPORT_WEEKLY_XRAY_REPORT: {

                let index = 0;
                const summary = {
                    totalExc: action.payload.summary[index++],
                    totalAcc: action.payload.summary[index++],
                    totalUna: action.payload.summary[index++],

                    totalBW: action.payload.summary[index++],
                    totalBWExc: action.payload.summary[index++],
                    totalBWAcc: action.payload.summary[index++],
                    totalBWUna: action.payload.summary[index++],
                    totalBWRep: action.payload.summary[index++],

                    totalPA: action.payload.summary[index++],
                    totalPAExc: action.payload.summary[index++],
                    totalPAAcc: action.payload.summary[index++],
                    totalPAUna: action.payload.summary[index++],
                    totalPARep: action.payload.summary[index++],

                    totalOC: action.payload.summary[index++],
                    totalOCExc: action.payload.summary[index++],
                    totalOCAcc: action.payload.summary[index++],
                    totalOCUna: action.payload.summary[index++],
                    totalOCRep: action.payload.summary[index++],

                    totalPAN: action.payload.summary[index++],
                    totalPANExc: action.payload.summary[index++],
                    totalPANAcc: action.payload.summary[index++],
                    totalPANUna: action.payload.summary[index++],
                    totalPANRep: action.payload.summary[index++],

                    totalCM: action.payload.summary[index++],
                    totalCMExc: action.payload.summary[index++],
                    totalCMAcc: action.payload.summary[index++],
                    totalCMUna: action.payload.summary[index++],
                    totalCMRep: action.payload.summary[index++],

                    totalCBCT: action.payload.summary[index++],
                    totalCBCTExc: action.payload.summary[index++],
                    totalCBCTAcc: action.payload.summary[index++],
                    totalCBCTUna: action.payload.summary[index++],
                    totalCBCTRep: action.payload.summary[index],
                };

                return {
                    ...state,
                    xrayReportsByWeekLoaded: true,
                    xrayReportsByWeekRecords: action.payload.records,
                    xrayReportsByWeekSummary: summary,
                };
            }
            case Actions.RECEIVE_REPORT_WEEKLY_XRAY_REPORT_PDF:

                return {
                    ...state,
                    xrayReportsByWeekPDFLoaded: true,
                    xrayReportsByWeekPDF: action.payload,
                };

            case Actions.REQUEST_REPORT_WEEKLY_SEDATION_SUMMARY:
            case Actions.CLEAR_REPORT_WEEKLY_SEDATION_SUMMARY:

                return {
                    ...state,
                    sedationSummaryByWeekLoaded: false,
                    sedationSummaryByWeek: [],
                };

            case Actions.RECEIVE_REPORT_WEEKLY_SEDATION_SUMMARY: {

                let index = 0;
                const summary = {
                    totalIA_O: action.payload[index++],
                    totalIA_A: action.payload[index++],
                    totalIA_D: action.payload[index++],

                    totalASA_I: action.payload[index++],
                    totalASA_II: action.payload[index++],
                    totalASA_III: action.payload[index++],
                    totalASA_IV: action.payload[index++],
                    totalASA_V: action.payload[index++],

                    totalST_IV: action.payload[index++],
                    totalST_RA: action.payload[index++],
                    totalST_O: action.payload[index++],
                    totalST_TM: action.payload[index++],

                    totalSS_1: action.payload[index++],
                    totalSS_2: action.payload[index++],
                    totalSS_3: action.payload[index++],
                    totalSS_4: action.payload[index++],
                    totalSS_5: action.payload[index++],
                    totalSS_6: action.payload[index++],

                    totalOCON_1: action.payload[index++],
                    totalOCON_2: action.payload[index++],
                    totalOCON_3: action.payload[index++],
                    totalOCON_4: action.payload[index++],

                    totalRec_Normal: action.payload[index++],
                    totalRec_Rapid: action.payload[index++],
                    totalRec_Prolonged: action.payload[index++],

                    totalFB_0: action.payload[index++],
                    totalFB_1: action.payload[index++],
                    totalFB_2: action.payload[index++],
                };
                return {
                    ...state,
                    sedationSummaryByWeekLoaded: true,
                    sedationSummaryByWeek: summary,
                };
            }

            case Actions.REQUEST_REPORT_WEEKLY_SEDATION_REPORT:
            case Actions.CLEAR_REPORT_WEEKLY_SEDATION_REPORT:

                return {
                    ...state,
                    sedationReportsByWeekLoaded: false,
                    sedationReportsByWeek: [],
                };

            case Actions.RECEIVE_REPORT_WEEKLY_SEDATION_REPORT: {
                return {
                    ...state,
                    sedationReportsByWeekLoaded: true,
                    sedationReportsByWeek: action.payload,
                };
            }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {

        console.log(`************************** ${error} **************************`);
        return state;
    }
};