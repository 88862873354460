import React from 'react';
import {BaseComponent} from "../../../BaseComponent";
import {
    PAY_TYPE_CC,
    PAY_TYPE_CHEQUE,
    PAY_TYPE_DC,
    PD_STATE_RECEIPT_AMOUNT,
    PD_STATE_RECEIPT_CD_CARD,
    PD_STATE_RECEIPT_CHEQUE,
    PD_STATE_RECEIPT_NARRATIVE, TB_MAKE_PAYMENT
} from "../../PatientDetails/Constants";
import _ from "lodash";
import {ac} from "../../../../index";
import {paymentData} from "../../DefaultData";
import {PaymentAmount} from "./Pages/PaymentAmount";
import {PaymentCheque} from "./Pages/PaymentCheque";
import {PaymentCDCard} from "./Pages/PaymentCDCard";
import {PaymentNarrative} from "./Pages/PaymentNarrative";

export default class MakePayment extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            dialogState: PD_STATE_RECEIPT_AMOUNT,
            payment: _.cloneDeep(paymentData(ac.getMcId())),
            previousState: PD_STATE_RECEIPT_AMOUNT,
            printControl: {printOnComplete: false},
        };

        this.state.payment.patient = {id: props.patient.id};
        this.state.payment.narrative = props.defaultPaymentNote.text;
        this.state.payment.takenBy = {id: props.loginIdentity.id};
        this.state.payment.date = new Date();
        this.state.payment.paidBy = props.patient.billingGroup === null ? {id: props.patient.id} : {id: props.patient.billingGroup.principlePayee.id}

        if (props.accountGroupId) {
            this.state.payment.accountGroup = _.find(props.accountGroups, group => group.id === props.accountGroupId);
        } else {
            this.state.payment.accountGroup = _.find(props.accountGroups, group => group.defaultAG);
        }
        this.checkForCompletness = this.checkForCompletness.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    checkForCompletness() {

        let finishEnabled = false;
        // based on the selected payment type we will determine the wizard completeness
        switch (this.state.payment.paymentType) {
            case PAY_TYPE_CHEQUE.value:
                finishEnabled = this.state.payment.takenBy.id !== null && this.state.payment.sortCode.length === 8;
                break;
            case PAY_TYPE_CC.value:
            case PAY_TYPE_DC.value:
                finishEnabled = this.state.payment.takenBy.id !== null;
                break;
            default:
                finishEnabled = this.state.payment.takenBy.id !== null;
                break;
        }
        return finishEnabled;
    }

    onChange(event) {

        const state = {...this.state};

        if (event.constructor === Array) {

            event.forEach(item => {
                _.set(state, item.owner, item.value);
            })
        } else {
            _.set(state, event.owner, event.value);
        }
        this.setState(state);
    }

    render() {

        const finishEnabled = this.checkForCompletness();

        switch (this.state.dialogState) {

            case PD_STATE_RECEIPT_AMOUNT:

                return (
                    <PaymentAmount payment={this.state.payment}
                                   identity={TB_MAKE_PAYMENT}
                                   changeType={false}
                                   onChange={this.onChange}
                                   onFinish={this.props.onFinishPayment}
                                   onHideDialog={this.props.onHideDialog}
                                   providers={this.props.providers}
                                   accountGroups={this.props.accountGroups}
                                   accountGroupId={this.props.accountGroupId}
                                   printControl={this.state.printControl}
                                   finishEnabled={finishEnabled}
                    />
                );

            case PD_STATE_RECEIPT_CHEQUE:

                return (
                    <PaymentCheque payment={this.state.payment}
                                   identity={TB_MAKE_PAYMENT}
                                   changeType={false}
                                   onChange={this.onChange}
                                   onFinish={this.props.onFinishPayment}
                                   onHideDialog={this.props.onHideDialog}
                                   finishEnabled={finishEnabled}
                    />
                );

            case PD_STATE_RECEIPT_CD_CARD:

                return (
                    <PaymentCDCard payment={this.state.payment}
                                   identity={TB_MAKE_PAYMENT}
                                   changeType={false}
                                   onChange={this.onChange}
                                   onFinish={this.props.onFinishPayment}
                                   onHideDialog={this.props.onHideDialog}
                                   finishEnabled={finishEnabled}
                    />
                );

            case PD_STATE_RECEIPT_NARRATIVE:

                return (
                    <PaymentNarrative payment={this.state.payment}
                                      identity={TB_MAKE_PAYMENT}
                                      changeType={true}
                                      onChange={this.onChange}
                                      onFinish={this.props.onFinishPayment}
                                      onHideDialog={this.props.onHideDialog}
                                      previousState={this.state.previousState}
                                      finishEnabled={finishEnabled}
                    />
                );

            default:
                break;
        }
    }
}
