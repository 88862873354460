import React from 'react';

export const rn1_7_48 = () => {

    // const imageBase = `/assets/releaseNotes/${packageJson.version}/images`;

    return (

        <div>
            <h2>New Features</h2>
            <hr/>

            <h2>Resolved Issues</h2>

            <h3>Clinician Schedules</h3>
            <h4>Ref: CCF_052</h4>
            <p>Creating new clinician rules was blocked, though existing rules created on the desktop version were editable</p>
            <hr/>
        </div>
    )
};