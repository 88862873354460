import React from "react";

import {SM_LABORATORY_ADD, SM_LABORATORY_DATA, SM_LABORATORY_PATIENT_DATA} from "../../../actions/stateManagement";

import {Panel} from 'primereact/components/panel/Panel';
import {dropDown, inputText} from "../../PatientDynamicItems/OnChangeUtils";

export const PERSONAL = 0;
export const LABORATORY_PATIENTS = 1;

export const TB_LABORATORY_DETAILS = {id: SM_LABORATORY_DATA.id, text: SM_LABORATORY_DATA.label, icon: SM_LABORATORY_DATA.tabIcon};
export const TB_LABORATORY_PATIENT = {id: SM_LABORATORY_PATIENT_DATA.id, text: SM_LABORATORY_PATIENT_DATA.label, icon: SM_LABORATORY_PATIENT_DATA.tabIcon};
export const TB_LABORATORY_ADD = {id: SM_LABORATORY_ADD.id, text: SM_LABORATORY_ADD.label, icon: SM_LABORATORY_ADD.tabIcon};

export const Name = (props) => {

    return (
        <Panel header='Name'>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-3">
                    <label htmlFor="title">Title</label>
                </div>
                <div className="p-col-9">
                    {dropDown(props, 'titles', 'title', 'abbreviation', props.mandatory)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="firstName">First Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'firstName', 'First Name', false, props.mandatory)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="lastName">Last Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'lastName', 'Last Name', false, props.mandatory)}
                </div>
            </div>
        </Panel>
    )
};
