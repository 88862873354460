import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {checkBox, inputText} from './OnChangeUtils';

export const OtherInvestigations = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header='Other Investigations Required'>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIROpg', 'OPG', readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIROpgVal', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIRCeph', 'Ceph.', readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIRCephVal', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIROcclusal', 'Occlusal', readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIROcclusalVal', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIRVitalityTesting', 'Vitality Testing', readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIRVitalityTesting', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIRPhotos', 'Photographs', readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIRPhotos', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIRStudyModels', 'Study Models', readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIRStudyModels', '', readOnly)}
                </div>
            </div>
        </Panel>
    );
};
