import React from 'react';
import {connect} from 'react-redux';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';

import '../../../../images.css';
import {setState, SM_REPORTS_PATIENT_JOURNEY_PDF, stateRequest} from "../../../../actions/stateManagement";
import {CLINICIAN, PJ_STATUS_Usable, SU_STATUS_ACTIVE} from "../../../../Constants";
import {ICON_EXCEL, ICON_PRINT, ICON_SCREEN} from "../../../../icons";
import {getReport, getReportPDF, RES_REPORT_PAT_JOURNEYS} from "../../../../actions/reports";
import {TB_PATIENT_DETAILS} from "../../PatientDetails/Constants";
import {PatientJourneyStageHeadings} from "../Constants";
import * as Actions from "../../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../../actions";
import _ from "lodash";
import {showPatientDetailsPage} from "../../PatientDetails/Utils";
import {dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import {checkBox, CONST_FEMALE, numberText} from "../../../PatientDynamicItems/OnChangeUtils";
import {ProgressBar} from "primereact/progressbar";

import {Dropdown} from "primereact/dropdown";
import {Column} from "primereact/column";
import {getDropDowns, RES_getDropDowns} from "../../../../actions/dropDowns";
import {getResource as getHKResource, RES_HOUSEKEEPING_JSTAGES} from "../../../../actions/housekeeping";
import {Button} from "primereact/components/button/Button";
import {TextPatientJourney, TextPatientJourneyExcel} from "../../../../Messages";
import {PDFBaseComponent} from "../../../PDFBaseComponent";
import PatientJourneyPDF from "../PDFs/PatientJourneyPDF";
import {TabPanel} from "primereact/components/tabview/TabView";
import {ac} from "../../../../index";
import moment from "moment";

class ConnectedPatientJourneyMain extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {

                providers: [],
                patientJourneys: [],

                selections: {
                    provider: null,
                    stage: null,
                    patientJourney: null,
                },

                first: 0,
                rows: 5,

                pdfRequest: PDF_REQUEST_NONE,
                pdfBuffer: null,
                PDFLoaded: false,

                journeyLoading: false,

                checkboxes: {
                    includeArchived: false,
                },
            }
        }

        this.onShowReport = this.onShowReport.bind(this);
        this.onPrintReport = this.onPrintReport.bind(this);
        this.onDownload = this.onDownload.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onShowDetails = this.onShowDetails.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.providerTemplate = this.providerTemplate.bind(this);
        this.buildMenu = this.buildMenu.bind(this);
        this.showDialogs = this.showDialogs.bind(this);

        this.toolbarCallbacks = {

            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getJourneyStages();
            this.props.getDropDowns();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_DROPDOWNS:

                    const providers = [{
                        id: -1,
                        fullName: 'All',
                        status: SU_STATUS_ACTIVE,
                        myRole: CLINICIAN
                    }, ...this.props.providers];

                    this.setState({providers}, () => {
                        this.props.setState(this.props.id, this.state);
                    });
                    break;

                case Actions.RECEIVE_REPORT_PAT_JOURNEYS:

                    const patientJourneys = _.filter(this.props.patientJourneys, journey => this.state.selections.provider.id === -1 || journey.providerId === this.state.selections.provider.id);
                    this.setState({patientJourneys, journeyLoading: false}, () => {
                        this.props.setState(this.props.id, this.state);
                    });
                    break;

                case RES_REPORT_PAT_JOURNEYS.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_PATIENT_JOURNEY_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        let checkProgress = true;

        switch (event.owner) {
            case 'selections.stage':
                if (this.state.selections.stage !== null && (this.state.selections.stage.id === event.value.id)) {
                    return;
                }
                break;
            case 'selections.provider':
                if (this.state.selections.provider !== null && (this.state.selections.provider.id === event.value.id)) {
                    return;
                }
                break;
            default:
                checkProgress = false;
        }

        const state = {...this.state};
        _.set(state, event.owner, event.value);
        _.set(state, 'journeyLoading', checkProgress && this.state.selections.provider !== null && this.state.selections.stage !== null);
        state.pdfBuffer = null;
        state.PDFLoaded = false;

        this.setState(state, () => {
            if (checkProgress && this.state.selections.provider !== null && this.state.selections.stage !== null) {
                this.props.getPatientJourneys({
                    stageId: this.state.selections.stage.id,
                    performerId: this.state.selections.provider.id
                });
            }
            this.props.setState(this.props.id, this.state);
        });
    }

    onDoubleClick(e) {

        this.setState({selectedPatient: e.data});
        this.onShowDetails(e.data);
    }

    onShowDetails({id, firstName, lastName, gender, nhsPatient}) {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient});
    }

    onDownload = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_REPORT_PAT_JOURNEYS.XLS.url}?mcId=${ac.getMcId()}&stageId=${this.state.selections.stage.id}&performerId=${this.state.selections.provider.id}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `pj_${this.state.selections.stage.code}_${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                    a.click();
                });
            });
    }

    onShowReport(event) {

        const onCloseClick = this.props.onSectionCloseClick;
        const onTabUpdate = this.props.onTabUpdate;

        if (this.state.selections.provider === null || this.state.selections.stage === null) return;

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={SM_REPORTS_PATIENT_JOURNEY_PDF.id}
                              header={SM_REPORTS_PATIENT_JOURNEY_PDF.label}
                              leftIcon={SM_REPORTS_PATIENT_JOURNEY_PDF.tabIcon}>
                        <PatientJourneyPDF onCloseClick={onCloseClick}
                                           onTabUpdate={onTabUpdate}
                                           performerId={this.state.selections.provider.id}
                                           stageId={this.state.selections.stage.id}
                                           id={SM_REPORTS_PATIENT_JOURNEY_PDF.id}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport(event) {

        if (this.state.selections.provider === null || this.state.selections.stage === null) return;

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_PATIENT_JOURNEY_PDF.label} : ${dateTemplateShort(this.state.date)}`);
            })
        } else {

            const params = {
                performerId: this.state.selections.provider.id,
                stageId: this.state.selections.stage.id
            };

            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF(params);
            })
        }
    }

    providerTemplate(row) {

        const provider = _.find(this.props.providers, provider => provider.id === row.providerId);
        return provider === undefined ? '' : `${provider.fullName}`.trim();
    };

    showDialogs() {

        const contents = [];
        return contents;
    }

    buildMenu() {

        if (this.state.selections.patientJourney === null)
            return [];

        const detailsIcon = this.state.selections.patientJourney.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;

        const items = [
            {
                label: TB_PATIENT_DETAILS.text, icon: detailsIcon, command: (e) => {
                    const {patientId, firstName, lastName, gender, nhsPatient} = this.state.selections.patientJourney;
                    const patientDetails = {id: patientId, firstName, lastName, gender, nhsPatient};
                    this.toolbarCallbacks[TB_PATIENT_DETAILS.id](patientDetails);
                }
            }];
        return items;
    }

    render() {

        if (!this.props.dropDownsLoaded || !this.props.journeyStagesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>Patient Journey Status Code</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button tooltip={`Show ${TextPatientJourney} Report`}
                        tooltipOptions={{position: 'top'}}
                        onClick={this.onShowReport}
                        style={{fontSize: '15px', marginTop: '-5px'}}
                        icon={ICON_SCREEN}/>
                <Button tooltip={`Print ${TextPatientJourney} Report`}
                        tooltipOptions={{position: 'top'}}
                        onClick={this.onPrintReport}
                        style={{fontSize: '15px', marginTop: '-5px'}}
                        icon={ICON_PRINT}/>
                <Button tooltip={`Download ${TextPatientJourneyExcel} Report`}
                        tooltipOptions={{position: 'top'}}
                        onClick={this.onDownload}
                        style={{fontSize: '15px', marginTop: '-5px'}}
                        icon={ICON_EXCEL}/>
            </div>
        </div>;

        const stage = this.state.selections.stage === null ? undefined : _.find(this.props.journeyStages, stage => stage.id === this.state.selections.stage.id);

        const sortedFilteredStages = _.sortBy(_.filter(this.props.journeyStages, stage => stage.status === PJ_STATUS_Usable || this.state.checkboxes.includeArchived), 'description');

        const providers = _.filter(this.state.providers, provider => provider.status === SU_STATUS_ACTIVE && provider.myRole === CLINICIAN && provider.username !== 'superuser' && provider.username !== 'TED_superuser');

        const sortedProviders = _.sortBy(providers, 'fullName');

        const provider = this.state.selections.provider === null ? undefined : _.find(providers, provider => provider.id === this.state.selections.provider.id);

        const sortedPatientJourneys = _.sortBy(this.state.patientJourneys, ["statusChangedOn", 'lastName', 'firstName']);

        const props = {
            onChange: this.onChange,
            target: 'checkboxes',
            checkboxes: this.state.checkboxes,
        }
        return (

            <Panel headerTemplate={header}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-1">
                        <label id='panel-header'>{` Journey Code `}</label>
                    </div>
                    <div className="p-col-2">
                        <Dropdown id="pj1"
                                  placeHolder='Journey Code'
                                  value={stage}
                                  options={sortedFilteredStages}
                                  optionLabel={`description`}
                                  onChange={e => {
                                      this.onChange({owner: `selections.stage`, value: e.value})
                                  }}
                        />
                    </div>
                    <div className="p-col-1">
                        <label id='panel-header'>{` Clinician `}</label>
                    </div>
                    <div className="p-col-2">
                        <Dropdown id='pj2'
                                  placeHolder='Clinician'
                                  value={provider}
                                  options={sortedProviders}
                                  optionLabel={`fullName`}
                                  onChange={e => {
                                      this.onChange({owner: `selections.provider`, value: e.value})
                                  }}
                        />
                    </div>
                    <div className="p-col-2">
                        <label id='panel-header'>{` No of Patients at Stage `}</label>
                    </div>
                    <div className="p-col-2">
                        {numberText({
                            target: 'total',
                            total: {total: this.state.patientJourneys.length}
                        }, 'total', 'Total', true, false)}
                    </div>
                    <div className="p-col-2">
                        {checkBox(props, 'includeArchived', `Include Archived (${sortedFilteredStages.length})`, false, false)}
                    </div>
                </div>

                <ContextMenu style={{width: '210px'}} model={this.buildMenu()} ref={el => this.cm = el}/>

                <DataTable value={sortedPatientJourneys}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}
                           selection={this.state.selections.patientJourney}
                           onRowDoubleClick={this.onDoubleClick}
                           onSelectionChange={e =>
                               this.onChange({owner: `selections.patientJourney`, value: e.value})
                           }
                           contextMenuSelection={this.state.selections.patient}
                           onContextMenuSelectionChange={e =>
                               this.onChange({owner: `selections.patientJourney`, value: e.value})
                           }
                           onContextMenu={e => {
                               this.cm.show(e.originalEvent)
                           }}
                >

                    <Column field="fullName"
                            header={PatientJourneyStageHeadings[0]}
                            style={{width: '20%'}}
                    />
                    <Column body={row => dateTemplateShort(row.statusChangedOn)}
                            field="statusChangedOn"
                            header={PatientJourneyStageHeadings[1]}
                            style={{width: '15%'}}
                    />
                    <Column body={row => this.providerTemplate(row)}
                            field="provider"
                            header={PatientJourneyStageHeadings[2]}
                            style={{width: '20%'}}
                    />
                    <Column body={row => dateTemplateShort(row.dateOfBirth)}
                            header={PatientJourneyStageHeadings[3]}
                            style={{width: '10%'}}/>
                    <Column body={row => dateTemplateShort(row.nextAppointment)}
                            header={PatientJourneyStageHeadings[4]}
                            style={{width: '10%'}}/>
                    <Column field="shortdescription"
                            header={PatientJourneyStageHeadings[5]}
                            style={{width: '25%'}}/>
                </DataTable>

                {this.showDialogs()}
            </Panel>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        providers: state.dropDowns.providers,

        journeyStagesLoaded: state.housekeeping.journeyStagesLoaded,
        journeyStages: state.housekeeping.journeyStages,

        patientJourneysLoaded: state.reports.patientJourneysLoaded,
        patientJourneys: state.reports.patientJourneys,

        PDFLoaded: state.reports.patientJourneysPDFLoaded,
        pdfBuffer: state.reports.patientJourneysPDF,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getJourneyStages: () => dispatch(getHKResource(RES_HOUSEKEEPING_JSTAGES.GET, {})),
        getPatientJourneys: (params) => dispatch(getReport(RES_REPORT_PAT_JOURNEYS.GET, params)),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_PAT_JOURNEYS.PDF, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const PatientJourneyMain = connect(mapStateToProps, mapDispatchToProps)(ConnectedPatientJourneyMain);

export default PatientJourneyMain;
