import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {HM_setIndependentReferrer} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK, ICON_SEARCH} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {ProgressBar} from "primereact/progressbar";
import {InputText} from "primereact/inputtext";
import {referrerTemplate} from "../../../PatientDynamicItems/Utils";
import {getAllReferrers} from "../../../../actions/findReferrers";
import * as Actions from "../../../../actions";

export class ConnectedSetIndependentReferrer extends Component {

    constructor(props) {
        super(props);

        this.state = {

            referrersLoaded: false,
            referrers: [],

            selectedReferrer: null,
            firstReferrers: 0,
            rowsReferrers: 5,
            useSelection: true,

            globalFilter: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onPageReferrers = this.onPageReferrers.bind(this);
        this.onReferrerSelection = this.onReferrerSelection.bind(this);
    }

    componentDidMount() {

        if (!this.props.referrersLoaded) {
            this.props.getAllReferrers();
        } else {
            const referrer = this.findReferrer();
            this.setState({
                referrers: this.props.referrers,
                referrersLoaded: this.props.referrersLoaded,
                selectedReferrer: referrer
            });
        }
    }

    findReferrer = () => {

        let referrer;

        if (this.props.referrer) {
            referrer = _.find(this.props.referrers, target => target.id === this.props.referrer.id)
        }
        return referrer;
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_REFERRER_SEARCH:

                    const referrer = this.findReferrer();
                    this.setState({
                        referrers: this.props.referrers,
                        referrersLoaded: this.props.referrersLoaded,
                        selectedReferrer: referrer
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onReferrerSelection(event) {

        const details = {...this.state.details};
        details.referrerId = event.value.id;

        this.setState({selectedReferrer: event.value, details});
    }

    onPageReferrers(e) {
        this.setState({firstReferrers: e.first, rowsReferrers: e.rows, useSelection: false})
    }

    render() {

        if (!this.state.referrersLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        let referrers = _.filter(this.state.referrers, referrer => {
            const pattern = `${referrer.firstName} ${referrer.lastName} ${referrer.companyName}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        let firstReferrer = 0;

        if (this.state.useSelection) {
            // Find the index of the selected value
            const selectedIndex = referrers.findIndex(item => item === this.state.selectedReferrer);

            // Calculate the page number
            const pageNumber = Math.floor(selectedIndex / this.state.rowsReferrers);

            // Calculate the starting index for the page
            firstReferrer = pageNumber * this.state.rowsReferrers;
        } else {
            firstReferrer = this.state.firstReferrers;
        }

        const header = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <i className={ICON_SEARCH} style={{margin: '4px 4px 0 0'}}/>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, firstReferrers: 0});
                       }}
                       placeholder="Search"
                       size="50"
                       autoFocus
            />
        </div>;

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.selectedReferrer);
                        }}
                        disabled={this.state.selectedReferrer === null}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_setIndependentReferrer.id)
                        }}/>
            </div>
        );

        return (
            <Dialog header={HM_setIndependentReferrer.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_setIndependentReferrer.id)
                    }}
            >
                <div className="p-grid p-fluid">

                    <DataTable value={referrers}
                               className='p-datatable-gridlines'
                               header={header}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsReferrers}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPageReferrers}
                               first={firstReferrer}
                               selection={this.state.selectedReferrer}
                               onSelectionChange={this.onReferrerSelection}
                               style={{fontSize: 'small'}}
                    >

                        <Column body={referrerTemplate} header='Name' style={{width: '40%'}}/>
                        <Column field="companyName" header='Company' style={{width: '20%'}}/>
                        <Column field="telephone" header='Telephone' style={{width: '20%'}}/>
                        <Column field="email" header='Email' style={{width: '20%'}}/>

                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        referrersLoaded: state.findReferrer.searchComplete,
        referrers: state.findReferrer.results,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getAllReferrers: () => dispatch(getAllReferrers()),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const SetIndependentReferrer = connect(mapStateToProps, MapDispatchToProps)(ConnectedSetIndependentReferrer);

export default SetIndependentReferrer;
