import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputCurrencyText, inputTextArea, outputCurrencyText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import * as DefaultData from "../../DefaultData";
import {TB_ISSUE_CREDIT_NOTE} from "../Constants";
import {InputText} from "primereact/inputtext";

export default class IssueCredit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            creditNote: _.cloneDeep(DefaultData.CreditNote(ac.getMcId())),
        };

        this.state.creditNote.raisedBy = {id: props.loginIdentity.id};
        this.state.creditNote.creditAmount = 0.0;

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onHide() {
        this.props.onHideDialog(TB_ISSUE_CREDIT_NOTE.id);
    }

    render() {

        const creditNoteDefined = (this.state.creditNote.creditAmount > 0.0) ? '' : 'disabled';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            const creditedToDate = this.state.creditNote.creditAmount + this.state.creditedToDate;
                            this.props.onFinishCreditIssue(this.state.creditNote, creditedToDate)
                        }}
                        disabled={creditNoteDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'creditNote',
            creditNote: this.state.creditNote,
        };

        return (
            <Dialog header={TB_ISSUE_CREDIT_NOTE.text}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">

                        <div className="p-col-5">
                            <label>Invoice to Credit Against</label>
                        </div>
                        <div className="p-col-7">
                            <InputText value={this.state.creditAgainstText}
                                       style={{textAlign: 'right'}}
                                       disabled={true}
                            />
                        </div>

                        <div className="p-col-5">
                            <label>Invoice Total</label>
                        </div>
                        <div className="p-col-7">
                            {outputCurrencyText(this.state.paymentTotal)}
                        </div>

                        <div className="p-col-5">
                            <label>Invoice Total Outstanding(£)</label>
                        </div>
                        <div className="p-col-7">
                            {outputCurrencyText(this.state.outstandingTotal)}
                        </div>

                        <div className="p-col-5">
                            <label>Credit Amount</label>
                        </div>
                        <div className="p-col-7">
                            {inputCurrencyText(props, 'creditAmount', 'amount', false, true)},
                        </div>

                        <div className="p-col-5">
                            <label>New Balance</label>
                        </div>
                        <div className="p-col-7">
                            {outputCurrencyText(this.state.newBalance)}
                        </div>

                        <div className="p-col-5">
                            <label>Comment</label>
                        </div>
                        <div className="p-col-7">
                            {inputTextArea(props, 'comment', 5, -1, false, false)}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
