import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from "primereact/components/datatable/DataTable";
import {PickList} from "primereact/picklist";
import {HELP_USER_ACCOUNT_GROUPS, setState, SM_ACCOUNT_GROUPS, stateRequest} from "../../../actions/stateManagement";
import {BaseComponent} from "../../BaseComponent";
import {getAllUsers, getResource} from "../../../actions/users";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {
    HM_AccountGroups,
    HM_AddAccountGroup,
    HM_EditAccountGroup,
    HM_notImplemented,
    SU_STATUS_ACTIVE,
    TB_SAVE_AND_EXIT,
    UP_ACCOUNTGROUPS
} from "../../../Constants";
import {ICON_HELP, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED} from "../../../icons";
import {ProgressBar} from "primereact/progressbar";

import {Column} from "primereact/column";
import {Toolbar} from "primereact/toolbar";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {Button} from "primereact/components/button/Button";
import AddAccountGroup from "./Dialogs/AddAccountGroup";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import * as Actions from "../../../actions";
import {WSM_UPDATES} from "../../../actions";
import {RES_getAccountGroups} from "../../../actions/accountGroups";
import {Dialog} from "primereact/components/dialog/Dialog";

const AG_COLS = ["", "Name", "Principle", "Status", "Default"];

export class ConnectedAccountGroups extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                dummyId: -100,
                stateManagementId: SM_ACCOUNT_GROUPS.id,
                canSave: {status: false, activeIndex: 0, source: Actions.SAVE_ACCOUNTGROUP},
                showSaveDialog: false,

                accountGroups: [],

                selectedAccountGroup: null,
                first: 0,
                rows: 5,

                memberPossibles: [],
                memberActuals: [],
            }
        }
        this.cm = null;
    }

    componentDidMount() {

        if (!this.props.accountGroupsLoaded) {
            this.props.getAccountGroups();
        } else {
            this.initAccountGroups();
        }
    }

    initAccountGroups() {

        const accountGroups = [...this.props.accountGroups];
        accountGroups.forEach(group => {
            const match = _.filter(this.props.usersShort, user => user.id === group.groupPrinciple.id)[0];
            if (match) {
                group.groupPrinciple = match;
            }
        })
        this.setState({accountGroups}, () => {
            this.props.setState(this.state.stateManagementId, this.state, null);
        })
    };

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_ACCOUNTS_GROUPS:
                    this.initAccountGroups();
                    break;
                case WSM_UPDATES:

                    switch (this.props.wsmessage.function) {
                        case UP_ACCOUNTGROUPS:
                            this.props.getAccountGroups();
                            break;
                        default:
                            break;
                    }
                default:
                    break;
            }
        }
    }

    userTemplate(user) {

        return `${user.titleAbbreviation} ${user.fullName}`.trim();
    }

    memberTemplate(member) {

        if (member.fullNameUsername !== undefined) {
            return member.fullNameUsername.trim();
        } else {
            return `${member.title.abbreviation} ${member.firstName} ${member.lastName} (${member.username})`.trim();
        }
    }

    onAGSelection = (e) => {

        const members = e.value.members;

        const memberPossibles = _.filter(this.props.usersShort, user => (_.findIndex(members, member => (member.id === user.id)) < 0 && user.status === SU_STATUS_ACTIVE && user.username !== 'superuser'));
        const memberActuals = _.filter(members, member => member.status === SU_STATUS_ACTIVE && member.username !== 'superuser');

        this.setState({selectedAccountGroup: e.value, memberPossibles, memberActuals}, () => {
            this.props.setState(this.state.stateManagementId, this.state, null);
        });
    }

    onChange = (event) => {

        const accountGroups = [...this.state.accountGroups];

        const selectedAccountGroupIndex = _.findIndex(accountGroups, group => group.id === this.state.selectedAccountGroup.id);

        accountGroups[selectedAccountGroupIndex].members = event.target;
        accountGroups[selectedAccountGroupIndex].edited = true;

        const memberPossibles = _.filter(this.props.usersShort, user => (_.findIndex(accountGroups[selectedAccountGroupIndex].members, member => (member.id === user.id)) < 0 && user.status === SU_STATUS_ACTIVE && user.username !== 'superuser'));
        const memberActuals = _.filter(accountGroups[selectedAccountGroupIndex].members, member => member.status === SU_STATUS_ACTIVE && member.username !== 'superuser');

        this.setState({
            selectedAccountGroup: accountGroups[selectedAccountGroupIndex],
            accountGroups,
            memberPossibles,
            memberActuals,
            canSave: {status: true, activeIndex: 0, source: Actions.SAVE_ACCOUNTGROUP}
        }, () => {
            this.props.setState(this.state.stateManagementId, this.state, null);
            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
        });
    };

    onInsertAccountGroup = (newAccountGroup) => {

        const accountGroup = {...newAccountGroup};
        accountGroup.id = this.state.dummyId--;
        accountGroup.edited = true;

        const accountGroups = [...this.state.accountGroups];
        accountGroups.push(accountGroup);

        this.setState({
            [HM_AddAccountGroup.id]: false,
            [HM_EditAccountGroup.id]: false,
            accountGroups,
            canSave: {status: true, activeIndex: 0, source: Actions.SAVE_ACCOUNTGROUP}
        }, () => {
            this.props.setState(this.state.stateManagementId, this.state, null);
            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
        });
    }

    onUpdateAccountGroup = (editedAccountGroup) => {

        const accountGroup = {...editedAccountGroup};
        accountGroup.edited = true;

        const accountGroups = [...this.state.accountGroups];
        const accountGroupIndex = _.findIndex(this.state.accountGroups, group => group.id === accountGroup.id);

        accountGroups[accountGroupIndex] = accountGroup;

        this.setState({
            [HM_AddAccountGroup.id]: false,
            [HM_EditAccountGroup.id]: false,
            accountGroups,
            selectedAccountGroup: accountGroups[accountGroupIndex],
            canSave: {status: true, activeIndex: 0, source: Actions.SAVE_ACCOUNTGROUP}
        }, () => {
            this.props.setState(this.state.stateManagementId, this.state, null);
            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
        });
    }

    onSaveAccountGroup = () => {

        this.setState({
            [HM_AddAccountGroup.id]: false,
            [HM_EditAccountGroup.id]: false,
            canSave: {status: false, activeIndex: 0, source: Actions.SAVE_ACCOUNTGROUP}
        }, () => {
            this.props.stateRequest({action: this.state.canSave.source});
            this.props.onTabUpdate({key: this.state.stateManagementId}, false);

            if (close) {
                this.props.onCloseClick({key: this.state.stateManagementId});
            }
        })
    }

    showDialogs = () => {

        if (this.state[HM_AddAccountGroup.id]) {
            return (
                <AddAccountGroup
                    editing={false}
                    dialogId={HM_AddAccountGroup.id}
                    header={HM_AddAccountGroup.header}
                    visible={true}
                    providers={this.props.usersShort}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.onInsertAccountGroup}
                />
            )
        } else if (this.state[HM_EditAccountGroup.id]) {
            return (
                <AddAccountGroup
                    editing={true}
                    dialogId={HM_EditAccountGroup.id}
                    header={HM_EditAccountGroup.header}
                    visible={true}
                    providers={this.props.usersShort}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.onUpdateAccountGroup}
                    item={this.state.selectedAccountGroup}
                />
            )

        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onClose = () => {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true}, () => {
                this.props.setState(this.state.stateManagementId, null);
            });
        } else {
            this.onExit();
        }
    }

    onExit = () => {
        this.exitState = TAB_EXIT;
        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
        this.props.onTabCloseClick({key: this.state.stateManagementId});
    }

    render() {

        if (!this.props.accountGroupsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        let key = 1000;

        const header = <div className='panel-header-centered-content'><label
            id='panel-header'>{HM_AccountGroups.groups}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={HM_AddAccountGroup.header}
                    icon={HM_AddAccountGroup.icon}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_AddAccountGroup.id}})
                    }}>
            </Button>
        </div>;

        const items = [
            {
                label: HM_EditAccountGroup.label,
                icon: HM_EditAccountGroup.icon,
                command: this.onShowMenuEntry,
                target: HM_EditAccountGroup.id
            },
        ]

        const canSave = this.state.canSave.status;

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const footer = <div>
            <Button label="Yes" icon="fa fa-check" onClick={() => {
                this.onSaveAccountGroup(true)
            }}/>
            <Button label="No" icon="fas fa-times" onClick={this.onExit}/>
        </div>;

        return (
            <div>

                <Dialog header="Save Resource" visible={this.state.showSaveDialog} width="350px" modal={true} minY={70}
                        footer={footer} onHide={() => {
                    this.setState({showSaveDialog: false})
                }}>
                    The form has been modified, save these changes?
                </Dialog>

                <Toolbar
                    left={<React.Fragment>
                        {tb_boilerPlate2((e) => this.onSaveAccountGroup(false), saveIcon, !canSave, 'Save Changes', 1)}
                    </React.Fragment>}
                    right={<React.Fragment>
                        {tb_boilerPlateRight(() => window.open(HELP_USER_ACCOUNT_GROUPS), ICON_HELP, 'Knowledge Base', key++, true)}
                        {tb_boilerPlateRight(() => this.onClose(), TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                    </React.Fragment>}
                />

                <Panel header={header} style={{paddingTop: '5px'}}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.showDialogs()}

                    <DataTable value={this.state.accountGroups}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'first', 'rows')}
                               first={this.state.first}
                               selection={this.state.selectedAccountGroup}
                               onSelectionChange={this.onAGSelection}
                               contextMenuSelection={this.state.selectedAccountGroup}
                               onContextMenuSelectionChange={e => this.setState({selectedAccountGroup: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column header={AG_COLS[0]}
                                style={{width: '5%'}}/>
                        <Column header={AG_COLS[1]}
                                field='groupName'
                                style={{width: '30%'}}/>
                        <Column header={AG_COLS[2]}
                                body={(row) => this.userTemplate(row.groupPrinciple)}
                                style={{width: '30%'}}/>
                        <Column header={AG_COLS[3]}
                                field='status'
                                style={{width: '20%'}}/>
                        <Column header={AG_COLS[4]}
                                body={(row) => row.defaultAG ? 'Default' : ''}
                                style={{width: '15%'}}/>
                    </DataTable>
                </Panel>

                <Panel header={HM_AccountGroups.members}
                       style={{marginTop: '5px'}}
                >
                    <PickList className='p-lg-12 p-md-12'
                              sourceHeader={HM_AccountGroups.possible}
                              targetHeader={HM_AccountGroups.actual}
                              itemTemplate={this.memberTemplate}
                              showSourceControls={false}
                              showTargetControls={false}
                              source={this.state.memberPossibles}
                              target={this.state.memberActuals}
                              onChange={(e) => {
                                  this.onChange(e);
                              }}
                              sourceStyle={{height: '300px'}} targetStyle={{height: '300px'}}
                    />
                </Panel>
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({action: Actions.CLEAR_ACCOUNTGROUP, id: this.props.id});
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        accountGroupsLoaded: state.users.accountGroupsLoaded,
        accountGroups: state.users.accountGroups,

        currentState: state.stateManagement[SM_ACCOUNT_GROUPS.id],

        onTabCloseClick: state.login.onTabCloseClick,
    }
};

const mapDispatchToProps = dispatch => {
    return {

        getAllUsersShort: () => dispatch(getAllUsers()),
        getAccountGroups: () => dispatch(getResource(RES_getAccountGroups.GET, {})),
        setState: (id, data) => dispatch(setState(id, data)),
        stateRequest: (source) => dispatch(stateRequest(source)),
    };
};

const AccountGroups = connect(mapStateToProps, mapDispatchToProps)(ConnectedAccountGroups);

export default AccountGroups;

AccountGroups.propTypes = {};