import React from "react";
import {SELECTED_ITEM_COLOUR} from "../Constants";
import {ICON_DIARY} from "../../../../icons";
import {COLOR_ChiralPalette} from "../../../Client/Constants";

export class DiaryTaskComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            diaryTask: this.props.diaryTask,
        }
        this.myRef = React.createRef();

        this.paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};
    }

    onDblclickHandler = (e) => {
        this.props.onShowRightClickTasks(e, this.state.diaryTask, true);
        this.props.onItemSelected(this.state.diaryTask);
    }

    onRightClickHandler = (e) => {
        this.props.onShowRightClickTasks(e, this.state.diaryTask, false);
    }

    componentDidMount() {

        try {
            // this.myRef.current.addEventListener('dblclick', this.onDblclickHandler)
            // this.myRef.current.addEventListener('click', this.onClickHandler)
            // this.myRef.current.addEventListener('contextmenu', this.onRightClickHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    render() {

        const {diaryTask} = this.state;

        let index = 0;

        let backgroundColor = diaryTask.id === this.props.selectedItem ? SELECTED_ITEM_COLOUR : null;

        return (
            <div key={index++}
                 id={diaryTask.id}
                 style={{fontWeight: 'bold', ...this.paddingStyle, backgroundColor}}
                 ref={this.myRef}
                 // onClick={this.onClickHandler}
                 onDoubleClick={this.onDblclickHandler}
                 onContextMenu={this.onRightClickHandler}
            >
                <i style={{fontSize: '20px', color: COLOR_ChiralPalette, ...this.paddingStyle}}
                   className={ICON_DIARY}
                />
                {diaryTask.description}
            </div>
        )
    }

    componentWillUnmount() {

        try {
            this.myRef.current.removeEventListener('click', this.rightCLickHandler)
            this.myRef.current.removeEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
