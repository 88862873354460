import _ from 'lodash';
import * as Actions from '../actions/index';
import {standardReturn} from "./users";

const initialState = {

    categoriesLoaded: false,
    categories: []
};

export const orthoAssessmentCategories = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.RECEIVE_ORTHO_CATEGORIES:

                if (!action.loaded) {
                    return {...state, categories: action.payload, categoriesLoaded: true};
                } else return standardReturn(state, action);

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};