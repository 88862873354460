import React, {Component} from 'react';
import _ from "lodash";
import {ac} from '../../../../index'

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {baseURL} from "../../../../actions/personal";
import {ICON_CANCEL} from "../../../../icons";
import {MyFileUpload} from "../MyFileUpload";

export default class UploadPatientDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notes: '',
            totalSize: 0,
        };

        this.fileUploader = null;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onBeforeUpload = (event) => {

        const data = {
            mcId: ac.getMcId(),
            patientId: this.props.patientId,
            userId: this.props.loginId,
            notes: this.state.notes,
            uploadedByPatient: this.props.uploadedByPatient
        };
        const dataStr = JSON.stringify(data);

        event.formData.append('patientDetails', new Blob(), dataStr);
    }

    onBeforeSend = (event) => {
        event.xhr.setRequestHeader('Authorization', ac.getAuthToken());
    }

    onHide = () => {
        this.props.onHideDialog(this.props.dialogId);
    }

    onHideMessage = () => {
        this.setState({totalSize: 0}, () => {
            this.fileUploader.clear();
        })
    }

    onSelect = (event) => {

        let totalSize = this.state.totalSize;

        event.files.forEach(file => totalSize += file.size);

        this.setState({totalSize});
    };

    onRemove = (event) => {

        let totalSize = this.state.totalSize - event.file.size;

        this.setState({totalSize});
    };

    render() {

        const footer = (
            <div>
                <Button label="Close"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const content = [];

        content.push(
            <Dialog header={`${this.props.header} (${this.state.totalSize})`}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div id="detailPanel">

                    <MyFileUpload tooltipOptions={{position: 'top'}}
                                  mode='advanced'
                                  multiple={true}
                                  name={'files'}
                                  url={`https://${ac.getBASERESTURL()}${baseURL}/uploadFiles`}
                                  maxFileSize={20000000}
                                  accept={this.props.access}
                                  onSelect={this.onSelect}
                                  onRemove={this.onRemove}
                                  onBeforeUpload={this.onBeforeUpload}
                                  onBeforeSend={this.onBeforeSend}
                                  onUpload={this.props.onFileUploaded}
                                  ref={ref => this.fileUploader = ref}
                                  onError={this.props.onFileUploadError}
                                  classifications={this.props.classifications}
                    />
                </div>
            </Dialog>
        )
        if (this.state.totalSize > (1024 * 50000)) { //50M Byte max upload size, set by NGINX proxy configuration

            const exceedFooter = (
                <div>
                    <Button label="Close"
                            icon={ICON_CANCEL}
                            onClick={this.onHideMessage}/>
                </div>
            );

            content.push(
                <Dialog header={`Maximum file upload size exceded`}
                        footer={exceedFooter}
                        visible={true}
                        onHide={()=>{}}
                        modal={true}>
                    Maximum upload size 50MB
                </Dialog>
            )
        }
        return content;
    }
};
