import React, {Component} from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_RAR} from "../../../../actions/stateManagement";
import {getPreferenceIds} from "../Utils";
import {getResource, RES_PREFERENCES_RAR} from "../../../../actions/preferences";
import {checkBox, spinner} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {getResource2, RES_TEMPLATES_CONSENT_FORMS} from "../../../../actions/housekeeping";
import {DOC_CONSENT_TEMPLATE} from "../../../../Constants";
import {Checkbox} from "primereact/checkbox";

class ConnectedRecallsAndRemindersSection extends Component {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_RAR.id,

                practiceDetailsLoaded: false,
                practiceDetails: null,

                consentForms: [],
                selectedTemplate: null,

                first: 0,
                rows: 5,
            }
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getPracticeDetails(this.props.practiceDetailsLoaded);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                case RES_TEMPLATES_CONSENT_FORMS.GET.receive:
                    this.buildConsents();
                    break;
                case RES_PREFERENCES_RAR.GET.receive:

                    const practiceDetails = {...this.props.practiceDetails};

                    this.setState({practiceDetails, practiceDetailsLoaded: true}, () => {

                        if (this.props.cfTemplatesLoaded) {
                            this.buildConsents();
                        } else {
                            this.props.getTemplates([DOC_CONSENT_TEMPLATE.name]);
                        }
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    buildConsents = () => {

        const consentForms = _.map(this.props.cfTemplates, template => {

            const index = _.findIndex(this.state.practiceDetails.consentFormIds, formId => formId === template.docId);
            return {id: template.docId, name: template.visibleName, selected: index !== -1}
        });
        this.setState({consentForms});
    }

    onPage = (e) => {
        this.setState({first: e.first, rows: e.rows});
    }

    onChange(event) {

        const state = {...this.state};

        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES_RAR.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            })
        });
    }

    updateConsentIds = (consentForms) => {

        const practiceDetails = {...this.state.practiceDetails};
        practiceDetails.consentFormIds = [];

        consentForms.forEach(form => {
            if (form.selected) {
                practiceDetails.consentFormIds.push(form.id);
            }
        });
        this.setState({consentForms, practiceDetails}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES_RAR.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            })
        });
    }

    consentRequired = (rowData) => {
        return (
            <Checkbox checked={rowData.selected}
                      onChange={(e) => {
                          const consentForms = [...this.state.consentForms];
                          const index = _.findIndex(consentForms, consent => consent.id === rowData.id);
                          consentForms[index].selected = e.checked;
                          this.updateConsentIds(consentForms);
                      }}/>
        );
    }

    render() {

        if (!this.state.practiceDetailsLoaded) {
            return null;
        }

        const props = {
            onChange: this.onChange,
            target: 'practiceDetails',
            practiceDetails: this.state.practiceDetails,
        };

        return (
            <div className="p-grid p-fluid p-col-12">
                <div className="p-grid p-fluid form-group p-col-6">
                    <Panel header='Default Reminder Time' className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-6">
                                <label htmlFor="lateArriverAdjustment">Late Arrivers Pre-appointment
                                    Adjustment(mins)</label>
                            </div>
                            <div className="p-col-6">
                                {spinner(props, 'lateArriverAdjustment', 0, 60, false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header='Default Reminder Look Ahead' className="p-col-12"
                           style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-4">
                                <label htmlFor="reminderLookAhead">Look Ahead (days)</label>
                            </div>
                            <div className="p-col-8">
                                {spinner(props, 'reminderLookAhead', 1, 7, false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header='Appointment Booking Confirmation' className="p-col-12"
                           style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-4">
                                <label htmlFor="confirmationByEmail">Confirm Booking by Email</label>
                            </div>
                            <div className="p-col-8">
                                {checkBox(props, 'confirmationByEmail', '', false, false)}
                            </div>
                            <div className="p-col-4">
                                <label htmlFor="confirmationBySMS">Confirm Booking by SMS</label>
                            </div>
                            <div className="p-col-8">
                                {checkBox(props, 'confirmationBySMS', '', false, false)}
                            </div>

                            <div className="p-col-4">
                                <label htmlFor="confirmationDeleteEmail">Confirm Deletion by Email</label>
                            </div>
                            <div className="p-col-8">
                                {checkBox(props, 'confirmationDeleteEmail', '', false, false)}
                            </div>
                            <div className="p-col-4">
                                <label htmlFor="confirmationDeleteSMS">Confirm Deletion by SMS</label>
                            </div>
                            <div className="p-col-8">
                                {checkBox(props, 'confirmationDeleteSMS', '', false, false)}
                            </div>

                            <div className="p-col-4">
                                <label htmlFor="confirmationRescheduleEmail">Confirm Rescheduling by Email</label>
                            </div>
                            <div className="p-col-8">
                                {checkBox(props, 'confirmationRescheduleEmail', '', false, false)}
                            </div>
                            <div className="p-col-4">
                                <label htmlFor="confirmationRescheduleSMS">Confirm Rescheduling by SMS</label>
                            </div>
                            <div className="p-col-8">
                                {checkBox(props, 'confirmationRescheduleSMS', '', false, false)}
                            </div>
                        </div>
                    </Panel>

                </div>

                <div className="p-grid p-fluid form-group p-col-6">

                <Panel header='Default Recall Times' className="p-col-12" style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-4">
                                <label htmlFor="recallFollowupPeriod">Follow-up Recall Time (days)</label>
                            </div>
                            <div className="p-col-8">
                                {spinner(props, 'recallFollowupPeriod', 1, 100, false, false)}
                            </div>
                            <div className="p-col-4">
                                <label htmlFor="chargeableItemRecallPeriod">Treatment Code Recall (months)</label>
                            </div>
                            <div className="p-col-8">
                                {spinner(props, 'chargeableItemRecallPeriod', 1, 100, false, false)}
                            </div>
                        </div>
                    </Panel>

                    <Panel header='Patient Information Requests on Booking' className="p-col-12"
                           style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-4">
                                <label htmlFor="medicalHistoryOnBooking">Medical History On Booking</label>
                            </div>
                            <div className="p-col-8">
                                {checkBox(props, 'medicalHistoryOnBooking', '', false, false)}
                            </div>
                            <div className="p-col-4">
                                <label htmlFor="questionnaireOnBooking">Questionnaire On Booking</label>
                            </div>
                            <div className="p-col-8">
                                {checkBox(props, 'questionnaireOnBooking', '', false, false)}
                            </div>
                            <div className="p-col-4">
                                <label htmlFor="consentOnBooking">Consent On Booking</label>
                            </div>
                            <div className="p-col-8">
                                {checkBox(props, 'consentOnBooking', '', false, false)}
                            </div>
                        </div>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <DataTable header={'Available Consent Forms'} value={this.state.consentForms}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           selectionMode="single"
                                           paginator={true}
                                           rows={this.state.rows}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={this.onPage}
                                           first={this.state.first}
                                           selection={this.state.selectedTemplate}
                                           onSelectionChange={this.onTemplateSelection}
                                >
                                    <Column field="name" header='Consent For' style={{width: '80%'}}/>
                                    <Column body={this.consentRequired} header='Requested' style={{width: '20%'}}/>
                                </DataTable>
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        practiceDetailsLoaded,
        practiceDetails,
    } = getPreferenceIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        practiceDetailsLoaded,
        practiceDetails,

        cfTemplatesLoaded: state.housekeeping.cfTemplatesLoaded,
        cfTemplates: state.housekeeping.cfTemplates,

        currentState: state.stateManagement[SM_PREFERENCES_RAR.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPracticeDetails: (loaded) => dispatch(getResource(RES_PREFERENCES_RAR.GET, loaded)),
        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_CONSENT_FORMS.GET, types)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const RecallsAndRemindersSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedRecallsAndRemindersSection);

export default RecallsAndRemindersSection;
