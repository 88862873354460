import React from 'react';
import {BaseComponent} from "../../BaseComponent";
import {
    HM_CancelClaim,
    HM_ClaimSchedDetails,
    HM_ClaimsHistory,
    HM_DeleteClaim,
    HM_EditClaim,
    HM_MarkAsDuplicateClaim,
    HM_notImplemented,
    HM_PerformerPinValidation,
    HM_ResubmitClaim,
    HM_SubmitClaim,
    HM_SubmitClaims,
    JSON_DATE_FORMAT
} from "../../../Constants";
import EditClaim from "./dialogs/EditClaim";
import _ from "lodash";
import moment from "moment";
import {TB_USER_DETAILS, TB_USER_UDA_UOA_DETAILS} from "../UserDetails/Constants";
import {onShowUserDetailsPage} from "../UserDetails/Utils";
import {
    EDI_CR_AbandonedFTR,
    EDI_CR_AbandonedPR,
    EDI_CR_AssessAndFittedSubmit,
    EDI_CR_AssessAndRefuse,
    EDI_CR_AssessAndReview,
    EDI_CR_Complete,
    EDI_CR_Discontinued,
    EDI_CR_Regulation11,
    EDI_CR_RepairFittedByOther,
    EDI_CT_FP17,
    EDI_CT_FP17O,
    EDI_REMOVED,
    EDI_SCHEDULED,
    EDI_ST_Deleted,
    EDI_ST_Rejected,
    EDI_ST_Scheduled,
    EDI_ST_Stacked,
    EDI_ST_Submitted
} from "./Constants";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {CONST_FEMALE} from "../../PatientDynamicItems/OnChangeUtils";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {CancelClaim} from "./dialogs/CancelClaim";
import {SubmitClaim} from "./dialogs/SubmitClaim";
import {DeleteClaim} from "./dialogs/DeleteClaim";
import {ClaimScheduleDetails} from "./dialogs/ClaimSheduleDetail";
import {ETH_GROUP} from "../../PatientDynamicItems/NHSDetails";
import {Band} from "../../../NHSConstants";
import * as Capabilities from "../../Capabilities";

export const SHOW_CLAIM_HISTORY = true;
export const HIDE_CLAIM_HISTORY = false;

export default class ClaimsComponent extends BaseComponent {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onCancelClaim = this.onCancelClaim.bind(this);
        this.onSubmitClaim = this.onSubmitClaim.bind(this);
        this.onResubmitClaim = this.onResubmitClaim.bind(this);
        this.onDeleteClaim = this.onDeleteClaim.bind(this);
        this.onMarkAsDuplicate = this.onMarkAsDuplicate.bind(this);

        this.onSelection = this.onSelection.bind(this);
        this.showDialogs = this.showDialogs.bind(this);

        this.buildClaimContextMenu = this.buildClaimContextMenu.bind(this);
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getProviderPerformers();
        }
    }

    onUserReload = () => {
        this.onUserSelectionChange({value: this.state.selectedUser}, true)
    }

    onUserSelectionChange = (e) => {

        if ((e.value.id === this.state.selectedUser.id))
            return;

        if (e.originalEvent) {
            this.usersCm.hide(e.originalEvent);
        }

        this.setState({selectedUser: e.value, firstClaims: 0}, () => {

            switch (this.state.type) {
                case EDI_REMOVED:
                case EDI_SCHEDULED:
                    const claimDate = new moment(this.props.claimDate);
                    this.props.getClaims(e.value.id, claimDate.format(JSON_DATE_FORMAT));
                    break;
                default:
                    this.props.getClaims(e.value.id);
                    break;
            }
        });
    }

    onSelection(e) {

        this.setState({selectedClaim: e.value}, () => {
            this.usersCm.hide(e.originalEvent);
        });
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {
            case 'deletedClaim.providerPIN' :
            case 'provider.providerPIN' :

                if (event.value.length < 7) {
                    if (isNaN(event.value)) {
                        _.set(state, 'pinValid', false);
                    } else {
                        _.set(state, 'pinValid', event.value.length === 6);
                        _.set(state, event.owner, event.value);
                    }
                }
                break;
            default:
                _.set(state, event.owner, event.value);
                break;
        }
        this.setState(state);
    }

    updateClaim = (editedClaim, NHSRegistration, patient, claimAction, resubmit) => {

        const claim = {...editedClaim};
        claim.patientNHSDetails = {...NHSRegistration};
        claim.patientNHSDetails.patient = {id: claim.patientNHSDetails.patient.id};
        claim.patientNHSDetails.ethnicGroup = _.find(ETH_GROUP, group => group.code === claim.patientNHSDetails.ethnicGroup.code).value;
        claim.completedBand = _.find(Band, band => band.description === claim.completedBand.description).id;
        claim.incompletedBand = _.find(Band, band => band.description === claim.incompletedBand.description).id;
        claim.performer = {id: claim.performer.id};
        claim.responseCode = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        claim.SQInd = claimAction.SQInd;

        const details = {
            patient,
            claim,
        }

        switch (claim.status) {
            case EDI_ST_Stacked:
                break;
            default:
                if (!resubmit) {
                    claim.status = EDI_ST_Deleted;
                }
                break;
        }

        const index = _.findIndex(this.state.claims, claim => claim.id === editedClaim.id);
        const claims = [...this.state.claims];
        claims[index].lastEditDate = new Date();

        if (resubmit) {
            this.setState({claims, [HM_ResubmitClaim.id]: true, [HM_EditClaim.id]: false}, () => {
                this.props.saveClaim(details);
            })
        } else {
            this.setState({claims, [HM_EditClaim.id]: false}, () => {
                this.props.saveClaim(details)
            })
        }
    }

    showDialogs() {

        if (this.state[HM_EditClaim.id]) {
            return (
                <EditClaim onHideDialog={this.onHideMenuEntry}
                           onOk={this.updateClaim}
                           claimDAO={this.state.selectedClaim}
                           allowSubmit={this.state.allowSubmit}
                />
            )
        } else if (this.state[HM_CancelClaim.id]) {

            return (
                <CancelClaim selectedClaim={this.state.selectedClaim}
                             target='selectedClaim'
                             onChange={this.onChange}
                             onOK={this.onCancelClaim}
                             onHideDialog={this.onHideMenuEntry}
                />
            )
        } else if (this.state[HM_MarkAsDuplicateClaim.id]) {

            return (
                <CancelClaim selectedClaim={this.state.selectedClaim}
                             target='selectedClaim'
                             onChange={this.onChange}
                             onOK={this.onMarkAsDuplicate}
                             onHideDialog={this.onHideMenuEntry}
                />
            )
        } else if (this.state[HM_SubmitClaim.id]) {

            return (
                <SubmitClaim provider={this.state.provider}
                             target='provider'
                             dialogId={HM_SubmitClaim.id}
                             onChange={this.onChange}
                             onOK={this.onSubmitClaim}
                             onHideDialog={this.onHideMenuEntry}
                             pinValid={this.state.pinValid}
                />
            )
        } else if (this.state[HM_SubmitClaims.id]) {

            return (
                <SubmitClaim provider={this.state.provider}
                             target='provider'
                             dialogId={HM_SubmitClaims.id}
                             onChange={this.onChange}
                             onOK={this.onSubmitClaims}
                             onHideDialog={this.onHideMenuEntry}
                             pinValid={this.state.pinValid}
                />
            )
        } else if (this.state[HM_ResubmitClaim.id]) {

            return (
                <SubmitClaim provider={this.state.provider}
                             target='provider'
                             dialogId={HM_ResubmitClaim.id}
                             onChange={this.onChange}
                             onOK={this.onResubmitClaim}
                             onHideDialog={this.onHideMenuEntry}
                             pinValid={this.state.pinValid}
                />
            )
        } else if (this.state[HM_DeleteClaim.id]) {

            return (
                <DeleteClaim selectedClaim={this.state.selectedClaim}
                             deletedClaim={this.state.deletedClaim}
                             target='deletedClaim'
                             dialogId={HM_DeleteClaim.id}
                             onChange={this.onChange}
                             onOK={this.onDeleteClaim}
                             onHideDialog={this.onHideMenuEntry}
                />
            )
        } else if (this.state[HM_ClaimSchedDetails.id]) {

            return (
                <ClaimScheduleDetails selectedClaim={this.state.selectedClaim}
                                      target='selectedClaim'
                                      onHideDialog={this.onHideMenuEntry}
                />
            )
        } else if (this.state[HM_PerformerPinValidation.id]) {
            return ShowMessageDialog(this, HM_PerformerPinValidation);
        } else {
            return ShowMessageDialog(this, HM_notImplemented);
        }
    }

    onSubmitClaim() {

        if (this.state.selectedUser.performerPIN !== this.state.provider.providerPIN) {
            this.setState({
                [HM_SubmitClaim.id]: false,
                pinValid: false,
                provider: {providerPIN: ''},
                [HM_PerformerPinValidation.id]: true
            });
        } else {
            this.setState({[HM_SubmitClaim.id]: false, pinValid: false, provider: {providerPIN: ''}});

            const stackedToGoClaims = [];

            switch (this.state.selectedClaim.type) {
                case EDI_CT_FP17:
                    stackedToGoClaims.push(this.state.selectedClaim);
                    break;
                case EDI_CT_FP17O:
                    switch (this.state.selectedClaim.completionReason) {
                        case EDI_CR_AbandonedFTR:
                        case EDI_CR_AbandonedPR:
                        case EDI_CR_AssessAndFittedSubmit:
                        case EDI_CR_AssessAndRefuse:
                        case EDI_CR_AssessAndReview:
                        case EDI_CR_Discontinued:
                        case EDI_CR_Regulation11:
                        case EDI_CR_Complete:
                        case EDI_CR_RepairFittedByOther:
                            stackedToGoClaims.push(this.state.selectedClaim.id);
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            this.props.submitClaims(this.state.selectedUser.performerPIN, stackedToGoClaims);
        }
    }

    onSubmitClaims = () => {

        if (this.state.selectedUser.performerPIN !== this.state.provider.providerPIN) {
            this.setState({
                [HM_SubmitClaims.id]: false,
                pinValid: false,
                provider: {providerPIN: ''},
                [HM_PerformerPinValidation.id]: true
            });
        } else {
            this.setState({[HM_SubmitClaims.id]: false, pinValid: false, provider: {providerPIN: ''}}, () => {

                const stackedToGoClaims = [];

                this.state.claims.forEach(claim => {

                    switch (claim.type) {
                        case EDI_CT_FP17:
                            stackedToGoClaims.push(claim.id);
                            break;
                        case EDI_CT_FP17O:
                            switch (claim.completionReason) {
                                case EDI_CR_AbandonedFTR:
                                case EDI_CR_AbandonedPR:
                                case EDI_CR_AssessAndFittedSubmit:
                                case EDI_CR_AssessAndRefuse:
                                case EDI_CR_AssessAndReview:
                                case EDI_CR_Discontinued:
                                case EDI_CR_Regulation11:
                                case EDI_CR_Complete:
                                case EDI_CR_RepairFittedByOther:
                                    stackedToGoClaims.push(claim.id);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                });
                this.props.submitClaims(this.state.selectedUser.performerPIN, stackedToGoClaims);
            });
        }
    }

    onCancelClaim = () => {

        const details = {id: this.state.selectedClaim.id, narrative: this.state.selectedClaim.narrative};

        this.setState({
            [HM_CancelClaim.id]: false,
            pinValid: false,
            provider: {providerPIN: ''}
        }, () => {
            this.props.markClaimAsCancelled(details);
        });
    }

    onResubmitClaim() {

        if (this.state.selectedUser.performerPIN !== this.state.provider.providerPIN) {
            this.setState({[HM_ResubmitClaim.id]: false, [HM_PerformerPinValidation.id]: true});
        } else {
            const claim = {id: this.state.selectedClaim.id, SQInd: this.state.selectedClaim.SQInd};
            this.setState({[HM_ResubmitClaim.id]: false, pinValid: true}, () => {

                const details = {performerPIN: this.state.provider.providerPIN, claimDAO: claim};

                this.props.resubmitClaim(details);
            });
        }
    }

    onDeleteClaim() {

        if (this.state.selectedUser.performerPIN !== this.state.deletedClaim.providerPIN) {
            this.setState({[HM_DeleteClaim.id]: false, [HM_PerformerPinValidation.id]: true});
        } else {
            const claim = {id: this.state.selectedClaim.id, SQInd: this.state.selectedClaim.SQInd};
            this.setState({[HM_DeleteClaim.id]: false, pinValid: true}, () => {

                const details = {
                    performerPIN: this.state.deletedClaim.providerPIN,
                    claimDAO: claim,
                    narrative: this.state.deletedClaim.narrative
                };

                this.props.deleteClaim(details);
            });
        }
    }

    onMarkAsDuplicate() {

        const details = {id: this.state.selectedClaim.id, narrative: this.state.selectedClaim.narrative};

        this.setState({
            [HM_MarkAsDuplicateClaim.id]: false,
            pinValid: false,
            provider: {providerPIN: ''}
        }, () => {
            this.props.markClaimAsDuplicate(details);
        });
    }

    buildUserContextMenu() {

        const menu = [];

        if (this.state.selectedUser.id === null) return [];

        menu.push({
            label: ` ${TB_USER_UDA_UOA_DETAILS.text}`,
            icon: TB_USER_UDA_UOA_DETAILS.icon,
            command: () => {
                this.onShowMenuEntry({item: {target: TB_USER_UDA_UOA_DETAILS.id}});
            },
            target: TB_USER_UDA_UOA_DETAILS.id
        });

        const {id, gender, firstname, lastname} = this.state.selectedUser;
        const icon = gender === CONST_FEMALE ? TB_USER_DETAILS.femaleIcon : TB_USER_DETAILS.maleIcon;

        menu.push({
            label: ` ${TB_USER_DETAILS.text}`,
            icon,
            command: () => {
                onShowUserDetailsPage(
                    {
                        props:
                            {
                                onPCButtonClick: this.props.onPCButtonClick,
                                onCloseClick: this.props.onTabCloseClick,
                                onTabUpdate: this.props.onTabUpdate,
                            }, id, firstName: firstname, lastName: lastname, gender
                    });
            },
            target: TB_USER_DETAILS.id
        });
        return menu;
    }

    buildClaimContextMenu(status, showClaimHistory) {

        if (this.state.selectedClaim === null) return [];

        const menu = [];
        const {patientId, gender, firstname, lastname} = this.state.selectedClaim;

        showClaimHistory && menu.push({
            label: HM_ClaimsHistory.header,
            icon: HM_ClaimsHistory.icon,
            command: () => {
                showPatientDetailsPage(
                    {
                        props:
                            {
                                onPCButtonClick: this.props.onPCButtonClick,
                                onCloseClick: this.props.onTabCloseClick,
                                onTabUpdate: this.props.onTabUpdate,
                            }, id: patientId, firstName: firstname, lastName: lastname, gender, nhsPatient: true
                    });
            },
            target: HM_ClaimsHistory.id
        });

        switch (status) {
            case EDI_ST_Stacked:
                menu.push({
                    label: HM_EditClaim.header,
                    icon: HM_EditClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_EditClaim.id
                });
                menu.push({
                    label: HM_CancelClaim.header,
                    icon: HM_CancelClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_CancelClaim.id
                });
                menu.push({
                    label: HM_SubmitClaim.header,
                    icon: HM_SubmitClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_SubmitClaim.id
                });
                break;
            case EDI_ST_Submitted:
                menu.push({
                    label: HM_EditClaim.header,
                    icon: HM_EditClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_EditClaim.id
                });
                menu.push({
                    label: HM_ResubmitClaim.header,
                    icon: HM_ResubmitClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_ResubmitClaim.id
                });
                menu.push({
                    label: HM_DeleteClaim.header,
                    icon: HM_DeleteClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_DeleteClaim.id
                });
                break;
            case EDI_ST_Rejected:
                menu.push({
                    label: HM_EditClaim.header,
                    icon: HM_EditClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_EditClaim.id
                });
                menu.push({
                    label: HM_MarkAsDuplicateClaim.header,
                    icon: HM_MarkAsDuplicateClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_MarkAsDuplicateClaim.id
                });
                menu.push({
                    label: HM_CancelClaim.header,
                    icon: HM_CancelClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_CancelClaim.id
                });
                menu.push({
                    label: HM_ResubmitClaim.header,
                    icon: HM_ResubmitClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_ResubmitClaim.id
                });
                break;
            case EDI_ST_Scheduled:
                menu.push({
                    label: HM_EditClaim.header,
                    icon: HM_EditClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_EditClaim.id
                });
                menu.push({
                    label: HM_ResubmitClaim.header,
                    icon: HM_ResubmitClaim.icon,
                    command: this.onShowMenuEntry,
                    target: HM_ResubmitClaim.id
                });
                menu.push({
                    label: HM_ClaimSchedDetails.header,
                    icon: HM_ClaimSchedDetails.icon,
                    command: this.onShowMenuEntry,
                    target: HM_ClaimSchedDetails.id
                });
                break;
            default:
                break;
        }
        return menu;
    }

    sortProviderPerformers() {

        if (this.props.capabilities.includes(Capabilities.AID_NHS_SHOW_ONLY_MY_CLAIMS)) {
            return _.filter(this.props.providerPerformers, provider => provider.id === this.props.loginIdentity.id);
        } else {
            return _.sortBy(this.props.providerPerformers, ['lastname', 'firstname'], ['asc', 'asc']);
        }
    }
}