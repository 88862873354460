export const TAB_NO_ACTION = 'TAB_NO_ACTION';
export const TAB_EXIT = 'TAB_EXIT';
export const TAB_SAVE = 'TAB_SAVE';
export const TAB_EXIT_SAVE = 'TAB_EXIT_SAVE';
export const TAB_CHANGE = 'TAB_CHANGE';
export const TAB_PARENT_CHANGE = 'TAB_PARENT_CHANGE';

export const TITLES = 0;
export const TREATMENT_CODES = 1;
export const COST_CENTERS = 2;
export const TREATMENT_FAVS = 3;
export const BASE_CHART_FAVS = 4;
export const CHARTING_ITEMS_MAPPING = 5;
export const CLINICIAN_SCHEDULES = 6;
export const SURGERY_SCHEDULES = 7;
export const DIARY_TYPES = 8;
export const RECALL_TYPES = 9;
export const PATIENT_JOURNEYS = 10;
export const MEDICAL_CONDITIONS = 11;
export const QUESTIONNAIRE = 12;
export const CANCELLATION_REASONS = 13;
export const OCCUPATIONS = 14;
export const ASS_CAT_FORMS = 15;
export const PAT_CONSENT_FORMS = 16;
export const MEDICATIONS = 17;
export const DOCUMENT_CLASSIFICATIONS = 18;

export const ACCOUNT_HISTORY = 0;

export const ASSCAT_Any = {label: 'Any', value: 'Any'};
export const ASSCAT_Patient = {label: 'Patient', value: 'Patient'};
export const ASSCAT_Orthodontic = {label: 'Orthodontic', value: 'Orthodontic'};
export const ASSCAT_Endodontic = {label: 'Endodontic', value: 'Endodontic'};
export const ASSCAT_Periodontic = {label: 'Periodontic', value: 'Periodontic'};
export const ASSCAT_Paediatric = {label: 'Paediatric', value: 'Paediatric'};
export const ASSCAT_Implant = {label: 'Implant', value: 'Implant'};
export const ASSCAT_Prosthodontic = {label: 'Prosthodontic', value: 'Prosthodontic'};
export const ASSCAT_Botox = {label: 'Botox', value: 'Botox'};
export const ASSCAT_FacialAesthetic = {label: 'FacialAesthetic', value: 'FacialAesthetic'};
export const ASSCAT_XrayJustification = {label: 'XrayJustification', value: 'XrayJustification'};

export const ASSCAT_Type = [ASSCAT_Patient, ASSCAT_Orthodontic, ASSCAT_Endodontic, ASSCAT_Periodontic, ASSCAT_Paediatric, ASSCAT_Implant, ASSCAT_Prosthodontic, ASSCAT_Botox, ASSCAT_FacialAesthetic, ASSCAT_XrayJustification];
