import React from 'react';

import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Panel} from 'primereact/components/panel/Panel';
import _ from "lodash";
import {RecallRemType} from "../../Constants";

const colourTemplate = (rowData) => {

    const item = rowData;

    const color = `rgb(${item.red},${item.green},${item.blue})`;
    return <div style={{backgroundColor: color}}>&nbsp;</div>

};

const userTemplate = (props, {id}) => {

    const passIndex = _.findIndex(props.pass, pass => pass.appointmentType.id === id);

    if (passIndex < 0)
        return '-';
    else {
        const user = _.find(props.usersShort, (user) => props.pass[passIndex].provider.id === user.id);

        return user === undefined ? '-' : `${user.titleAbbreviation} ${user.firstName} ${user.lastName}`.trim();
    }
};

const providerTemplate = (option) => {

    return `${option.firstName} ${option.lastName}`.trim();
};

const periodTemplate = (props, {id}) => {

    const passIndex = _.findIndex(props.pass, pass => pass.appointmentType.id === id);

    if (passIndex < 0)
        return '-';
    else {
        return props.pass[passIndex].reminderPeriod;
    }
};

const methodTemplate = (props, {id}) => {

    const passIndex = _.findIndex(props.pass, pass => pass.appointmentType.id === id);

    if (passIndex < 0)
        return '-';
    else {
        return _.find(RecallRemType, (type) => type.name === props.pass[passIndex].reminderMethod).text;
    }
};

//// field editors

const dropdownProviderEditor = (onChange, props, passList, usersShort) => {

    const passIndex = _.findIndex(passList, pass => pass.appointmentType.id === props.value[props.rowIndex][props.field]);

    const mappedUsers = _.map(usersShort, (user) => {
        return {id: user.id, text: providerTemplate(user)}
    });
    const target = passIndex !== -1 ? _.find(mappedUsers, (user) => passList[passIndex].provider.id === user.id) : null;

    return (
        <Dropdown value={target}
                  options={mappedUsers}
                  optionLabel='text'
                  onChange={(e) => {
                      const updatedPass = [...passList];
                      updatedPass[passIndex].provider = _.find(usersShort, user => user.id === e.value.id);
                      updatedPass[passIndex].edited = true;
                      onChange({owner: 'pass', value: updatedPass, replace: true});
                  }}
                  style={{width: '100%'}}
                  placeholder="Select a provider"
        />
    );
};

const dropdownMethodEditor = (onChange, props, passList) => {

    const passIndex = _.findIndex(passList, pass => pass.appointmentType.id === props.value[props.rowIndex].id);
    const target = _.find(RecallRemType, (type) => type.name === passList[passIndex].reminderMethod);

    return (
        <Dropdown value={target}
                  options={RecallRemType}
                  optionLabel='text'
                  dataKey='name'
                  onChange={(e) => {
                      const updatedPass = [...passList];
                      updatedPass[passIndex].reminderMethod = e.value.name;
                      onChange({owner: 'pass', value: updatedPass, replace: true});
                  }}
                  style={{width: '100%'}}
                  placeholder="Select a method"
        />
    );
};

const textIntervalEditor = (onChange, props, passList) => {

    const passIndex = _.findIndex(passList, pass => pass.appointmentType.id === props.value[props.rowIndex].id);

    return <InputText type="text"
                      value={passList[passIndex].reminderPeriod}
                      style={{width: '100%'}}
                      onChange={(e) => {
                          const updatedPass = [...passList];
                          updatedPass[passIndex].reminderPeriod = e.target.value;
                          onChange({owner: 'pass', value: updatedPass, replace: true});
                      }}
    />;
};

export const DefaultProviders = (props) => {

    return (
        <Panel header='Default Providers'>
            <DataTable value={props.appointmentTypes}
                       className='p-datatable-gridlines'
                       style={{fontSize: 'small'}}
            >
                <Column body={colourTemplate} header="" style={{width: '5%'}}/>
                <Column field="description" header="Description" style={{width: '25%'}}/>
                <Column field="id" body={(row) => userTemplate(props, row)} header="Default Provider"
                        style={{width: '40%'}}
                        editor={(rowProps) => dropdownProviderEditor(props.onChange, rowProps, props.pass, props.usersShort)}/>
                <Column field="reminderPeriod" body={(row) => periodTemplate(props, row)} header="Reminder Period"
                        style={{width: '10%'}} editor={(row) => textIntervalEditor(props.onChange, row, props.pass)}/>
                <Column field="reminderMethod" body={(row) => methodTemplate(props, row)} header="Reminder Method"
                        style={{width: '20%'}}
                        editor={(row) => dropdownMethodEditor(props.onChange, row, props.pass)}/>
            </DataTable>
        </Panel>
    )
};
