import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_LOCATIONS,} from "../../../../actions/stateManagement";
import {deleteLocation, getResource, RES_PREFERENCES_LOCATIONS,} from "../../../../actions/preferences";
import _ from "lodash";
import {BaseComponent} from "../../../BaseComponent";
import {getPreferenceIds} from "../Utils";

import {ICON_CANCEL, ICON_DELETE, ICON_EDIT, ICON_OK, ICON_PLUS} from "../../../../icons";
import AddLocation from "../Dialogs/AddLocation";
import {getDropDowns, RES_getDropDowns} from "../../../../actions/dropDowns";

class ConnectedLocationsSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_LOCATIONS.id,

                locationsLoaded: false,
                locations: [],
                first: 0,
                rows: 5,

                fakeIndex: -100,

                selectedLocation: null,
                showAddLocation: false,
                showEditLocation: false,
                showDeleteLocation: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getDropDowns();
            this.props.getAllLocations();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PREFERENCES_LOCATIONS.GET.receive:

                    this.setState({locations: this.props.locations, locationsLoaded: true}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;

                case RES_PREFERENCES_LOCATIONS.SAVE.action:
                    break;

                default:
                    break;
            }
        }
    }

    onSelectionChange = (selection) => {

        this.setState({selectedLocation: selection.value});
    }

    addLocation = (_newLocation) => {

        const newLocation = {..._newLocation};
        newLocation.id = this.state.fakeIndex;

        const newLocations = [...this.state.locations];
        newLocations.unshift(newLocation);

        this.setState({locations: newLocations, showAddLocation: false, fakeIndex: this.state.fakeIndex + 1}, () => {

            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES_LOCATIONS.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            });

            this.props.setState(this.state.stateManagementId, {...this.state, ...{locations: newLocations}});
        })
    }

    updateLocation = (_editedLocation) => {

        const editedLocation = {..._editedLocation};

        const editedLocations = [...this.state.locations];

        const index = _.findIndex(editedLocations, (location) => {
            return location.id === editedLocation.id;
        });

        editedLocations[index] = editedLocation;

        this.setState({locations: editedLocations, showEditLocation: false}, () => {

            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES_LOCATIONS.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            });

            this.props.setState(this.state.stateManagementId, {...this.state, ...{locations: editedLocations}});
        });
    }

    onShowDialogs() {

        if (this.state.showAddLocation) {

            return (
                <AddLocation header='Create New Location'
                             onHideDialog={this.onHideMenuEntry}
                             onOkDialog={this.addLocation}
                             editing={false}
                             countries={this.props.countries}
                />
            )
        } else if (this.state.showEditLocation) {

            return (
                <AddLocation header='Edit Location'
                             onHideDialog={this.onHideMenuEntry}
                             onOkDialog={this.updateLocation}
                             editing={true}
                             countries={this.props.countries}
                             item={this.state.selectedLocation}
                />
            )
        } else if (this.state.showDeleteLocation) {

            const footer = <div>
                <Button label="Yes"
                        icon={ICON_OK}
                        onClick={() => {
                            this.onDeleteContract();
                        }}
                />
                <Button label="No"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.onHideMenuEntry('showDeleteContract')
                        }}
                />
            </div>;

            return (

                <Dialog header="Delete Location"
                        visible={this.state.showDeleteLocation}
                        width="350px"
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({showDeleteLocation: false})
                        }}>
                    Are you sure you want to delete this location?
                </Dialog>
            )
        } else
            return null;
    }

    onDeleteContract = () => {

        this.props.deletelocation(this.state.selectedLocation.id);

        this.setState({
            contracts: [],
            selectedLocation: null,
            showDeleteLocation: false,
        });
    }

    render() {

        if (!this.state.locationsLoaded || !this.props.dropDownsLoaded) {
            return null;
        }


        const items = [
            {label: 'Edit Location', icon: ICON_EDIT, command: this.onShowMenuEntry, target: 'showEditLocation'},
            {label: 'Delete Location', icon: ICON_DELETE, command: this.onShowMenuEntry, target: 'showDeleteLocation'},
        ];

        const header = <div className='panel-header-centered-content'><label id='panel-header'>Locations</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip='Add Location'
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: 'showAddLocation'}})
                    }}>
            </Button>
        </div>;

        return (
            <div>
                <Panel header={header}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={this.state.locations}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode='single'
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedLocation}
                               onSelectionChange={this.onSelectionChange}
                               contextMenuSelection={this.state.selectedLocation}
                               onContextMenuSelectionChange={e => this.setState({selectedLocation: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field='name'
                                header='Name'
                                style={{width: '25%'}}/>
                        <Column field='telephone'
                                header='Telephone'
                                style={{width: '25%'}}/>
                        <Column field='email'
                                header='Email'
                                style={{width: '25%'}}/>
                        <Column field='address.postcode'
                                header='Postcode'
                                style={{width: '25%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        dropDownsLoaded,
        locationsLoaded,
        locations,
    } = getPreferenceIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        dropDownsLoaded,
        countries: state.dropDowns.countries,

        locationsLoaded,
        locations,

        currentState: state.stateManagement[SM_PREFERENCES_LOCATIONS.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),

        getAllLocations: () => dispatch(getResource(RES_PREFERENCES_LOCATIONS.GET)),
        deletelocation: (id) => dispatch(deleteLocation(id)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const LocationsSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocationsSection);

export default LocationsSection;
