import React from "react";
import {PALETTE_GRP_USPR, SELECTED_ITEM_COLOUR} from "../Constants";
import {CONST_FEMALE} from "../../../PatientDynamicItems/OnChangeUtils";
import {SM_USER_DATA} from "../../../../actions/stateManagement";
import {COLOR_ChiralPalette} from "../../../Client/Constants";

export class UnscheduledProvider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            resource: {...this.props.unscheduledResource}
        }
        this.myRef = React.createRef();
        this.onClickHandler = this.onClickHandler.bind(this);

        this.paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};
    }

    onClickHandler = () => {
        this.props.onItemSelected(this.state.resource);
    }

    onRightClickHandler = (e) => {
        this.props.onShowRightClickProviders(e, this.state.resource);
    }

    componentDidMount() {

        try {
            this.myRef.current.addEventListener('click', this.onClickHandler)
            this.myRef.current.addEventListener('contextmenu', this.onRightClickHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    render() {

        const {resource} = this.state;

        resource.paletteType = PALETTE_GRP_USPR;

        let backgroundColor = resource.id === this.props.selectedItem ? SELECTED_ITEM_COLOUR : null;
        const genderIcon = resource.gender === CONST_FEMALE ? SM_USER_DATA.femaleTabIcon : SM_USER_DATA.maleTabIcon;
        const genderColour = resource.gender === CONST_FEMALE ? COLOR_ChiralPalette : '';
        const providerName = `${resource.title === null ? '' : resource.title.abbreviation} ${resource.firstName} ${resource.lastName}`.trim();

        let index = 0;

        return (
            <div key={index++}
                 style={{fontWeight: 'bold', ...this.paddingStyle, backgroundColor}}
                 ref={this.myRef}
            >
                <i className={genderIcon} style={{fontSize: '20px', paddingRight: '15px', color: genderColour}}/>
                {providerName}
            </div>
        )
    }

    componentWillUnmount() {

        try {
            this.myRef.current.removeEventListener('click', this.rightCLickHandler)
            this.myRef.current.removeEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
