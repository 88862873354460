import * as Actions from '../actions/index';
import _ from 'lodash';

const initialState = {
    categoriesLoaded: false,
    categories: []
};

export const endoAssessmentCategories = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.REQUEST_ENDO_CATEGORIES:

                return state;

            case Actions.RECEIVE_ENDO_CATEGORIES:

                return {...state, categories: action.payload.categories, categoriesLoaded: true};

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};