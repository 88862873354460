import React, {Component} from 'react';
import {connect} from "react-redux";

import {Panel} from 'primereact/panel';
import {setState, SM_PREFERENCES, SM_PREFERENCES_HOURS} from "../../../../actions/stateManagement";
import {getPreferenceIds} from "../Utils";
import {getResource, RES_PREFERENCES_HOURS} from "../../../../actions/preferences";
import {checkBox, spinner, spinnerStep} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import * as Actions from "../../../../actions";
import {TB_PracticeOpeningDays, TB_PracticeOpeningHours} from "../Constants";

class ConnectedPracticeHoursSection extends Component {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_HOURS.id,

                practiceDetailsLoaded: false,
                practiceDetails: null,
            }
        }

        this.onChange = this.onChange.bind(this);
        this.showDayRow = this.showDayRow.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getPracticeDetails(false);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PREFERENCES_HOURS.GET.receive:

                    const practiceDetails = {...this.props.practiceDetails};

                    this.setState({practiceDetails, practiceDetailsLoaded: true}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: Actions.SAVE_PREFERENCES,
                    saveState: true,
                    saveObjects: false
                }
            })
        });
    }

    showDayRow(props, label, name, controlName) {

        const stepValue = this.state.practiceDetails.timeDiv;
        const enable = !_.get(this.state.practiceDetails, `open${controlName}`);
        const content = [];

        content.push(
            <div className="p-col-2" style={{paddingBottom: '0.5em'}}>
                <label>{label}</label>
            </div>,
            <div className="p-col-2">{spinner(props, `${name}StartHour`, 1, 23, enable, false)}</div>,
            <div className="p-col-2">{spinnerStep(props, `${name}StartMin`, 0, 59, stepValue, enable, false)}</div>,
            <div className="p-col-2"/>,
            <div className="p-col-2">{spinner(props, `${name}TimeHour`, 1, 23, enable, false)}</div>,
            <div className="p-col-2">{spinnerStep(props, `${name}TimeMin`, 0, 59, stepValue, enable, false)}</div>,
        );
        return content;
    }

    render() {

        if (!this.state.practiceDetailsLoaded) {
            return null;
        }

        const props = {
            onChange: this.onChange,
            target: 'practiceDetails',
            practiceDetails: this.state.practiceDetails,
        };

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15, whiteSpace: 'nowrap' }}>{TB_PracticeOpeningHours.text}</span>
                        {spinnerStep(props, 'timeDiv', 5, 120, 5, false, false, null, TB_PracticeOpeningHours.toolTip)}
                    </div>
                    <div className="items-margin d-flex d-align-center">
                    </div>
                </div>;

        return (
            <div className="p-grid p-fluid p-col-12" style={{fontSize: 'small'}}>
                <div className="p-col-4">
                    <Panel header={TB_PracticeOpeningDays.text}>
                        <div style={{paddingBottom: '0.5em'}}>{checkBox(props, 'openSun', 'Sunday', false, false)}</div>
                        <div style={{paddingBottom: '0.5em'}}>{checkBox(props, 'openMon', 'Monday', false, false)}</div>
                        <div
                            style={{paddingBottom: '0.5em'}}>{checkBox(props, 'openTue', 'Tuesday', false, false)}</div>
                        <div
                            style={{paddingBottom: '0.5em'}}>{checkBox(props, 'openWed', 'Wednesday', false, false)}</div>
                        <div
                            style={{paddingBottom: '0.5em'}}>{checkBox(props, 'openThu', 'Thursday', false, false)}</div>
                        <div style={{paddingBottom: '0.5em'}}>{checkBox(props, 'openFri', 'Friday', false, false)}</div>
                        <div
                            style={{paddingBottom: '0.5em'}}>{checkBox(props, 'openSat', 'Saturday', false, false)}</div>
                    </Panel>
                </div>
                <div className="p-col-8">
                    <Panel headerTemplate={header}>

                        <div className="p-grid p-fluid form-group">

                            <div className="p-col-2"/>
                            <div className="p-col-3">
                                <label htmlFor="smtpServer">Start Time</label>
                            </div>
                            <div className="p-col-3"/>
                            <div className="p-col-3">
                                <label htmlFor="smtpServer">Stop Time</label>
                            </div>
                            <div className="p-col-1"/>

                            {/*--------------------*/}

                            {this.showDayRow(props, 'Sunday', 'sun', 'Sun')}
                            {this.showDayRow(props, 'Monday', 'mon', 'Mon')}
                            {this.showDayRow(props, 'Tuesday', 'tue', 'Tue')}
                            {this.showDayRow(props, 'Wednesday', 'wed', 'Wed')}
                            {this.showDayRow(props, 'Thursday', 'thu', 'Thu')}
                            {this.showDayRow(props, 'Friday', 'fri', 'Fri')}
                            {this.showDayRow(props, 'Saturday', 'sat', 'Sat')}

                        </div>
                    </Panel>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        practiceDetailsLoaded,
        practiceDetails,
    } = getPreferenceIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        practiceDetailsLoaded,
        practiceDetails,

        currentState: state.stateManagement[SM_PREFERENCES_HOURS.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPracticeDetails: (loaded) => dispatch(getResource(RES_PREFERENCES_HOURS.GET, loaded)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const PracticeHoursSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedPracticeHoursSection);

export default PracticeHoursSection;
