import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {calendar, checkBox, dropDown, radioButtonNHS, spinner} from "../../../../PatientDynamicItems/OnChangeUtils";
import {
    EDI_CR_AbandonedFTR,
    EDI_CR_AbandonedFTR_LABEL,
    EDI_CR_AbandonedPR,
    EDI_CR_AbandonedPR_LABEL,
    EDI_CR_Complete,
    EDI_CR_Complete_LABEL,
    EDI_CR_Discontinued,
    EDI_CR_Discontinued_LABEL,
    EDI_CR_Regulation11,
    EDI_CR_Regulation11_LABEL,
    EDI_CR_RepairFittedByOther,
    EDI_CR_RepairFittedByOther_LABEL,
    PART6_AEST_COMP,
    PART6_DATE_OF_COMPLETION_OR,
    PART6_IOTN,
    PART6_IOTN_NOT_APP,
    PART6_PAR_SCORE, PART6_PERFORMER,
    PART6_POST_TREAT_SCORE,
    PART6_PRE_TREAT_SCORE
} from "../../Constants";

export const Part6Content = (props) => {

    const claimProps = {
        providers: props.providers,
        onChange: props.onChange,
        target: 'claim',
        claim: props.claim,
    }

    return (
        <div>
            <Panel header='Part 6'>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-6">
                        {radioButtonNHS(props, 'claim.completionReason', EDI_CR_AbandonedFTR, EDI_CR_AbandonedFTR_LABEL)}
                    </div>
                    <div className="p-col-6"/>
                    <div className="p-col-6">
                        {radioButtonNHS(props, 'claim.completionReason', EDI_CR_AbandonedPR, EDI_CR_AbandonedPR_LABEL)}
                    </div>
                    <div className="p-col-6"/>
                    <div className="p-col-6">
                        {radioButtonNHS(props, 'claim.completionReason', EDI_CR_Discontinued, EDI_CR_Discontinued_LABEL)}
                    </div>
                    <div className="p-col-6"/>
                    <div className="p-col-6">
                        {radioButtonNHS(props, 'claim.completionReason', EDI_CR_Complete, EDI_CR_Complete_LABEL)}
                    </div>
                    <div className="p-col-6"/>
                    <div className="p-col-6">
                        {radioButtonNHS(props, 'claim.completionReason', EDI_CR_RepairFittedByOther, EDI_CR_RepairFittedByOther_LABEL)}
                    </div>
                    <div className="p-col-6"/>
                    <div className="p-col-6">
                        {radioButtonNHS(props, 'claim.completionReason', EDI_CR_Regulation11, EDI_CR_Regulation11_LABEL)}
                    </div>
                    <div className="p-col-6"/>
                </div>
            </Panel>
            <Panel header=' '>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-3">
                        <label>{`${PART6_PERFORMER} `}</label>
                    </div>
                    <div className="p-col-9">
                        {dropDown(claimProps, 'providers', 'performer', 'fullName', false, false)}
                    </div>
                </div>
            </Panel>
            <Panel header=' '>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-3">
                        {checkBox(claimProps, 'parScoreCalculated', PART6_PAR_SCORE, false, false)}
                    </div>
                    <div className="p-col-3">
                        <label>{`${PART6_PRE_TREAT_SCORE} `}</label>
                    </div>
                    <div className="p-col-3">
                        {spinner(claimProps, 'preTreatmentParScore', 0, 99, false, false)}
                    </div>
                    <div className="p-col-3"/>

                    <div className="p-col-3"/>
                    <div className="p-col-3">
                        <label>{`${PART6_POST_TREAT_SCORE} `}</label>
                    </div>
                    <div className="p-col-3">
                        {spinner(claimProps, 'postTreatmentParScore', 0, 99, false, false)}
                    </div>
                    <div className="p-col-3"/>
                </div>
            </Panel>
            <Panel header=' '>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-2">
                        <label>{`${PART6_IOTN} `}</label>
                    </div>
                    <div className="p-col-2">
                        {spinner(claimProps, 'iotnScore', 0, 5, false, false)}
                    </div>
                    <div className="p-col-3">
                        <label>{`${PART6_AEST_COMP} `}</label>
                    </div>
                    <div className="p-col-2">
                        {spinner(claimProps, 'iotnCompScore', 0, 10, false, false)}
                    </div>
                    <div className="p-col-3">
                        {checkBox(props, '', PART6_IOTN_NOT_APP, false, false)}
                    </div>
                </div>
            </Panel>
            <Panel header=' '>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-3">
                        <label>{`${PART6_DATE_OF_COMPLETION_OR} `}</label>
                    </div>
                    <div className="p-col-9">
                        {calendar(props, '', false, false)}
                    </div>
                </div>
            </Panel>
        </div>
    )
};
