import * as Actions from './index';
import {ac} from '../index'
import {RES_CREATE_TP_DOCUMENT} from "./housekeeping";

const baseURL = '/TreatmentPlanning';

export const RES_TREATMENT_PLANNING = {
    SAVE: {action: Actions.SAVE_TPLANNING},
    CLEAR: {action: Actions.CLEAR_TPLANNING},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_TPLANNING},
};

export const RES_TREATMENT_PLAN_HISTORY = {
    GET: {
        url: `${baseURL}/treatmentPlanHistory`,
        request: Actions.REQUEST_TP_HISTORY,
        receive: Actions.RECEIVE_TP_HISTORY
    },
    CLEAR: {action: Actions.CLEAR_TP_HISTORY},
    SAVE: {action: 'SAVE_TP_HISTORY', url: `${baseURL}/treatmentPlan`},
};

export const RES_TREATMENT_PLAN_BY_ID = {
    GET: {
        url: `${baseURL}/treatmentPlanById`,
        request: Actions.REQUEST_TREATMENT_PLAN_BY_TP_ID,
        receive: Actions.RECEIVE_TREATMENT_PLAN_BY_TP_ID
    },
    GET_FOR_TASK: {
        url: `${baseURL}/treatmentPlanById`,
        request: Actions.REQUEST_TREATMENT_PLAN_FOR_TASK,
        receive: Actions.RECEIVE_TREATMENT_PLAN_FOR_TASK
    },
    CLEAR: {action: Actions.CLEAR_TREATMENT_PLAN_BY_TP_ID},
    CLEAR_PDF: {action: Actions.CLEAR_TREATMENT_PLAN_PDF_BY_TP_ID},
    ADD: {action: Actions.ADD_TREATMENT_PLAN, url: `${baseURL}/addTreatmentPlan`},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_TREATMENT_PLAN_BY_TP_ID},
    SAVE: {action: Actions.SAVE_TREATMENT_PLAN_BY_TP_ID, url: `${baseURL}/saveTreatmentPlan`},
    DELETE: {action: Actions.DELETE_TREATMENT_PLAN, url: `${baseURL}/treatmentPlan`},
    COPY: {action: Actions.COPY_TREATMENT_PLAN, url: `${baseURL}/treatmentPlan`},
    PDF: {
        url: `${baseURL}/PDFs/show`,
        request: Actions.REQUEST_TREATMENT_PLAN_PDF,
        receive: Actions.RECEIVE_TREATMENT_PLAN_PDF
    },
};

export const RES_PAYMENT_PLAN_BY_TP_ID = {
    GET: {
        url: `${baseURL}/paymentPlanByTPId`,
        request: Actions.REQUEST_PAYMENT_PLAN_BY_TP_ID,
        receive: Actions.RECEIVE_PAYMENT_PLAN_BY_TP_ID
    },
    CLEAR: {action: Actions.CLEAR_PAYMENT_PLAN},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_PAYMENT_PLAN},
    SAVE: {action: Actions.SAVE_PAYMENT_PLAN, url: `${baseURL}/paymentPlan`},
};

export const RES_TREATMENT_PLAN_BY_APP_ID = {
    GET: {
        url: `${baseURL}/treatmentPlanByappointmentId`,
        request: Actions.REQUEST_TREATMENT_PLAN_BY_APP_ID,
        receive: Actions.RECEIVE_TREATMENT_PLAN_BY_APP_ID
    },
    CLEAR: {action: Actions.CLEAR_TREATMENT_PLAN_BY_APP_ID},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_TREATMENT_PLAN_BY_APP_ID},
    SAVE: {action: Actions.SAVE_TREATMENT_PLAN_BY_APP_ID, url: `${baseURL}/saveTreatmentPlan`},
    POPUPS: {
        request: Actions.REQUEST_APP_ACTIVE_POPUPS,
        receive: Actions.RECEIVE_APP_ACTIVE_POPUPS,
        url: `/Appointments/appointmentsActivePopUps`
    },
};

export const getResource = ({url, request, receive}, id, taskId) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(res => {

                dispatch({type: receive, id: id, payload: res.data, taskId})
            });
        dispatch({type: request, id: id, payload: false});
    }
};

export const clearTreatmentPlan = (id) => {

    return (dispatch) => {
        dispatch({type: RES_TREATMENT_PLAN_BY_ID.CLEAR.action, payload: id});
    }
};

export const createTreatmentPlanDocument = (details) => {

    return (dispatch) => {

        ac.fetchClient().get(`${RES_CREATE_TP_DOCUMENT.CREATE.url}?mcId=${ac.getMcId()}`, {params: {...details}})
            .then(res => {

                dispatch({type: RES_PAYMENT_PLAN_BY_TP_ID.SAVE.action, payload: res.data})
            });
    }
};

export const deleteTreatmentPlan = ({url, action}, patientId, planId) => {

    return (dispatch) => {
        ac.fetchClient().delete(`${url}/?mcId=${ac.getMcId()}&id=${planId}`)
            .then((res) => {
                dispatch({type: action, payload: res.data, patientId, planId});
            });
    }
};

export const copyTreatmentPlan = ({url, action}, params) => {

    return (dispatch) => {
        ac.fetchClient().put(`${url}/?mcId=${ac.getMcId()}&id=${params.planId}&newTitle=${params.newTitle}`)
            .then((res) => {
                dispatch({type: action, payload: res.data, patientId: params.patientId, planId: params.planId});
            });
    }
};
