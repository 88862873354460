import React from "react";
import CSVReader from "react-csv-reader";
import _ from "lodash";
import * as DefaultData from "../DefaultData";
import {ac} from "../../../index";
import {RES_PATIENT_DETAILS, savePatientData} from "../../../actions/personal";
import "./styles.css";
import moment from "moment";
import {BaseComponent} from "../../BaseComponent";
import {connect} from "react-redux";
import {ADD_PATIENT_ALREADY_EXISTS, ADD_PATIENT_OK} from "../../../Constants";

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};

export class ConnectedImportPatientCSV extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            csvData: [],
            csvIndex: -1,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case RES_PATIENT_DETAILS.ADD.receive:
                    switch (this.props.addResult) {

                        case ADD_PATIENT_OK:
                        case ADD_PATIENT_ALREADY_EXISTS:

                            if (this.state.csvIndex < this.state.csvData.length) {
                                this.setState({csvIndex: this.state.csvIndex + 1}, () => {
                                    this.addPatient();
                                })
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    randomDate = () => {
        function randomValueBetween(min, max) {
            return Math.random() * (max - min) + min;
        }

        let date1 = '01-01-1935'
        let date2 = '01-01-2010'
        date1 = new Date(date1).getTime()
        date2 = new Date(date2).getTime()
        if (date1 > date2) {
            return new Date(randomValueBetween(date2, date1));
        } else {
            return new Date(randomValueBetween(date1, date2));

        }
    }

    addPatient = () => {

        const patient = _.cloneDeep(DefaultData.PatientData(ac.getMcId()));
        const NHSRegistration = null;

        const {first_name, last_name, address, city, county, postal, phone1, phone2, email} = this.state.csvData[this.state.csvIndex];

        patient.title = {id: 267};
        patient.firstName = first_name;
        patient.lastName = last_name;
        patient.dateOfBirth = moment(this.randomDate()).format('YYYY-MM-DD');
        patient.homeTelephone = phone1;
        patient.mobile = phone2;
        patient.email = email;
        patient.contactDetails.addressLine1 = address;
        patient.contactDetails.addressLine2 = city;
        patient.contactDetails.county = county;
        patient.contactDetails.postcode = postal;
        patient.patientJourney = {stage: {id: 404}};

        this.props.addPatient(patient, NHSRegistration);
    };

    handleDataLoad = (data) => {

        this.setState({csvData: data, csvIndex: this.state.csvIndex + 1}, () => {
            this.addPatient();
        })
    }

    render() {
        return (
            <CSVReader
                cssClass="react-csv-input"
                label="Select CSV patient data file"
                onFileLoaded={this.handleDataLoad}
                parserOptions={papaparseOptions}
            />
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        addResult: state.patients.addResult,

        loginIdentity: state.login.user,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        addPatient: (patient, NHSRegistration) => dispatch(savePatientData(RES_PATIENT_DETAILS.ADD, patient, NHSRegistration)),
    }
};

const ImportPatientCSV = connect(mapStateToProps, MapDispatchToProps)(ConnectedImportPatientCSV);

export default ImportPatientCSV;

