import _ from 'lodash';

import {
    AdultIncisor,
    AdultMolar,
    AdultPremolar,
    AdultPremolarBicusp,
    JawLower,
    JawUpper,
    SurfBuccal,
    SurfBuccal1,
    SurfBuccal2,
    SurfDistal,
    SurfIncisal,
    SurfLabial,
    SurfLingual,
    SurfLingual1,
    SurfLingual2,
    SurfMesial,
    SurfOcclusal,
    SurfOcclusal1,
    SurfOcclusal2,
    SurfPalatal,
    SurfPalatal1,
    SurfPalatal2
} from "./Constants";

import {
    Points1,
    Points10,
    Points11,
    Points12,
    Points13,
    Points15,
    Points2,
    Points3,
    Points4,
    Points5,
    Points6,
    Points7,
    Points8,
    Points9,
    Points9a,
    Points9b
} from "../Parts/ToothSurface";

const Vertice_1 = [4, 4];
const Vertice_2 = [44, 4];
const Vertice_3 = [44, 44];
const Vertice_4 = [4, 44];
const Vertice_5 = [14, 14];
const Vertice_5i = [14, 19];
const Vertice_6 = [34, 14];
const Vertice_6i = [34, 19];
const Vertice_7 = [34, 34];
const Vertice_7i = [34, 29];
const Vertice_8 = [14, 34];
const Vertice_8i = [14, 29];
const Vertice_9 = [24, 4];
const Vertice_10 = [24, 14];
const Vertice_11 = [24, 34];
const Vertice_12 = [24, 44];

const Line_1_1_2 = _.merge(Vertice_1, Vertice_2);
const Line_2_2_3 = _.merge(Vertice_2, Vertice_3);
const Line_3_3_4 = _.merge(Vertice_3, Vertice_4);
const Line_4_4_1 = _.merge(Vertice_4, Vertice_1);
const Line_5_5_6 = _.merge(Vertice_5, Vertice_6);
const Line_5i_5_6 = _.merge(Vertice_5i, Vertice_6i);
const Line_6_6_7 = _.merge(Vertice_6, Vertice_7);
const Line_6i_6_7 = _.merge(Vertice_6i, Vertice_7i);
const Line_7_7_8 = _.merge(Vertice_7, Vertice_8);
const Line_7i_7_8 = _.merge(Vertice_7i, Vertice_8i);
const Line_8_8_5 = _.merge(Vertice_8, Vertice_5);
const Line_8i_8_5 = _.merge(Vertice_8i, Vertice_5i);
const Line_9_1_5 = _.merge(Vertice_1, Vertice_5);
const Line_9i_1_5 = _.merge(Vertice_1, Vertice_5i);
const Line_10_2_6 = _.merge(Vertice_2, Vertice_6);
const Line_10i_2_6 = _.merge(Vertice_2, Vertice_6i);
const Line_11_3_7 = _.merge(Vertice_3, Vertice_7);
const Line_11i_3_7 = _.merge(Vertice_3, Vertice_7i);
const Line_12_4_8 = _.merge(Vertice_4, Vertice_8);
const Line_12i_4_8 = _.merge(Vertice_4, Vertice_8i);
const Line_13_5_10 = _.merge(Vertice_5, Vertice_10);
const Line_14_6_10 = _.merge(Vertice_6, Vertice_10);
const Line_15_8_11 = _.merge(Vertice_8, Vertice_11);
const Line_16_7_11 = _.merge(Vertice_7, Vertice_11);
const Line_17_1_9 = _.merge(Vertice_1, Vertice_9);
const Line_18_2_9 = _.merge(Vertice_2, Vertice_9);
const Line_19_4_12 = _.merge(Vertice_4, Vertice_12);
const Line_20_3_12 = _.merge(Vertice_3, Vertice_12);
const Line_21_9_10 = _.merge(Vertice_9, Vertice_10);
const Line_22_10_11 = _.merge(Vertice_10, Vertice_11);
const Line_23_11_12 = _.merge(Vertice_11, Vertice_12);

export const elideSurfaces = (tooth, surfaceList) => {

    const mainShapes = [];
    const outlines = [];

    const surfaces = [];

    surfaceList.forEach(targetOrdinal => surfaces.push(
        _.find(tooth.surfaces, item => {
                return item.surface.ordinal === targetOrdinal;
            }
        )));

    const presentSurfaces = _.filter(surfaces, surface => surface !== undefined);

    presentSurfaces.forEach(surface => {

            const left = surface.left;

            switch (tooth.jaw) {
                case JawLower:

                    switch (tooth.type) {
                        case AdultMolar:
                            switch (surface.surface.type) {
                                case SurfLingual1.type:
                                    mainShapes.push(Points2);
                                    outlines.push(Line_17_1_9);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfLingual2, SurfMesial, Line_13_5_10, Line_21_9_10, Line_9_1_5);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfLingual2, SurfDistal, Line_13_5_10, Line_21_9_10, Line_9_1_5);
                                    }
                                    break;
                                case SurfLingual2.type:
                                    mainShapes.push(Points3);
                                    outlines.push(Line_18_2_9);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfLingual1, SurfDistal, Line_14_6_10, Line_21_9_10, Line_10_2_6);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfLingual1, SurfMesial, Line_14_6_10, Line_21_9_10, Line_10_2_6);
                                    }
                                    break;
                                case SurfMesial.type:
                                    if (left) {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfLingual1, SurfBuccal1, Line_8_8_5, Line_9_1_5, Line_12_4_8);
                                    } else {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfLingual2, SurfBuccal2, Line_6_6_7, Line_10_2_6, Line_11_3_7);
                                    }
                                    break;
                                case SurfBuccal2.type:
                                    mainShapes.push(Points6);
                                    outlines.push(Line_20_3_12);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfBuccal1, SurfDistal, Line_16_7_11, Line_23_11_12, Line_11_3_7);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfBuccal1, SurfMesial, Line_16_7_11, Line_23_11_12, Line_11_3_7);
                                    }
                                    break;
                                case SurfBuccal1.type:
                                    mainShapes.push(Points7);
                                    outlines.push(Line_19_4_12);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfBuccal2, SurfMesial, Line_15_8_11, Line_23_11_12, Line_12_4_8);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfBuccal2, SurfDistal, Line_15_8_11, Line_23_11_12, Line_12_4_8);
                                    }
                                    break;
                                case SurfDistal.type:
                                    if (left) {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfLingual2, SurfBuccal2, Line_6_6_7, Line_10_2_6, Line_11_3_7);
                                    } else {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfLingual1, SurfBuccal1, Line_8_8_5, Line_9_1_5, Line_12_4_8);
                                    }
                                    break;
                                case SurfOcclusal1.type:
                                    mainShapes.push(Points9a);
                                    if (left) {
                                        contains2(surfaces, outlines, SurfOcclusal2, SurfBuccal1, SurfLingual1, SurfMesial, Line_22_10_11, Line_15_8_11, Line_13_5_10, Line_8_8_5);
                                    } else {
                                        contains2(surfaces, outlines, SurfOcclusal2, SurfBuccal1, SurfLingual1, SurfDistal, Line_22_10_11, Line_15_8_11, Line_13_5_10, Line_8_8_5);
                                    }
                                    break;
                                case SurfOcclusal2.type:
                                    mainShapes.push(Points9b);
                                    if (left) {
                                        contains2(surfaces, outlines, SurfOcclusal1, SurfBuccal2, SurfLingual2, SurfDistal, Line_22_10_11, Line_16_7_11, Line_14_6_10, Line_6_6_7);
                                    } else {
                                        contains2(surfaces, outlines, SurfOcclusal1, SurfBuccal2, SurfLingual2, SurfMesial, Line_22_10_11, Line_16_7_11, Line_14_6_10, Line_6_6_7);
                                    }
                                    break;
                                default :
                                    break;
                            }
                            break;
                        default :
                            break;
                        case AdultPremolar:
                            switch (surface.surface.type) {
                                case SurfLingual.type:
                                    mainShapes.push(Points1);
                                    outlines.push(Line_1_1_2);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_5_5_6, Line_10_2_6, Line_9_1_5);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_5_5_6, Line_9_1_5, Line_10_2_6);
                                    }
                                    break;
                                case SurfMesial.type:
                                    if (left) {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfLingual, Line_8_8_5, Line_12_4_8, Line_9_1_5);
                                    } else {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfLingual, Line_6_6_7, Line_11_3_7, Line_10_2_6);
                                    }
                                    break;
                                case SurfBuccal.type:
                                    mainShapes.push(Points5);
                                    outlines.push(Line_3_3_4);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_7_7_8, Line_11_3_7, Line_12_4_8);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_7_7_8, Line_12_4_8, Line_11_3_7);
                                    }
                                    break;
                                case SurfDistal.type:
                                    if (left) {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfLingual, Line_6_6_7, Line_11_3_7, Line_10_2_6);
                                    } else {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfLingual, Line_8_8_5, Line_12_4_8, Line_9_1_5);
                                    }
                                    break;
                                case SurfOcclusal.type:
                                    mainShapes.push(Points9);
                                    if (left)
                                        contains2(surfaces, outlines, SurfDistal, SurfBuccal, SurfLingual, SurfMesial, Line_6_6_7, Line_7_7_8, Line_5_5_6, Line_8_8_5);
                                    else
                                        contains2(surfaces, outlines, SurfDistal, SurfBuccal, SurfLingual, SurfMesial, Line_8_8_5, Line_7_7_8, Line_5_5_6, Line_6_6_7);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case AdultIncisor:
                            switch (surface.surface.type) {
                                case SurfIncisal.type:
                                    mainShapes.push(Points15);
                                    if (left)
                                        contains1(surfaces, outlines, SurfDistal, SurfLabial, SurfLingual, SurfMesial, Line_6i_6_7, Line_7i_7_8, Line_5i_5_6, Line_8i_8_5);
                                    else
                                        contains1(surfaces, outlines, SurfDistal, SurfLabial, SurfLingual, SurfMesial, Line_8i_8_5, Line_7i_7_8, Line_5i_5_6, Line_6i_6_7);
                                    break;
                                case SurfLingual.type:
                                    mainShapes.push(Points10);
                                    outlines.push(Line_1_1_2);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfIncisal, SurfDistal, SurfMesial, Line_5i_5_6, Line_10i_2_6, Line_9i_1_5);
                                    } else {
                                        contains1(surfaces, outlines, SurfIncisal, SurfDistal, SurfMesial, Line_5i_5_6, Line_9i_1_5, Line_10i_2_6);
                                    }
                                    break;
                                case SurfMesial.type:
                                    if (left) {
                                        mainShapes.push(Points13);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfIncisal, SurfLabial, SurfLingual, Line_8i_8_5, Line_12i_4_8, Line_9i_1_5);
                                    } else {
                                        mainShapes.push(Points11);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfIncisal, SurfLabial, SurfLingual, Line_6i_6_7, Line_11i_3_7, Line_10i_2_6);
                                    }
                                    break;
                                case SurfLabial.type:
                                    mainShapes.push(Points12);
                                    outlines.push(Line_3_3_4);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfIncisal, SurfDistal, SurfMesial, Line_7i_7_8, Line_11i_3_7, Line_12i_4_8);
                                    } else {
                                        contains1(surfaces, outlines, SurfIncisal, SurfDistal, SurfMesial, Line_7i_7_8, Line_12i_4_8, Line_11i_3_7);
                                    }
                                    break;
                                case SurfDistal.type:
                                    if (left) {
                                        mainShapes.push(Points11);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfIncisal, SurfLabial, SurfLingual, Line_6i_6_7, Line_11i_3_7, Line_10i_2_6);
                                    } else {
                                        mainShapes.push(Points13);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfIncisal, SurfLabial, SurfLingual, Line_8i_8_5, Line_12i_4_8, Line_9i_1_5);
                                    }
                                    break;
                                default :
                                    break;
                            }
                            break;
                    }
                    break;
                default :
                    break;
                case JawUpper:

                    switch (tooth.type) {
                        case AdultMolar:
                            switch (surface.surface.type) {
                                case SurfBuccal1.type:
                                    mainShapes.push(Points2);
                                    outlines.push(Line_17_1_9);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfBuccal2, SurfMesial, Line_13_5_10, Line_21_9_10, Line_9_1_5);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfBuccal2, SurfDistal, Line_13_5_10, Line_21_9_10, Line_9_1_5);
                                    }
                                    break;
                                case SurfBuccal2.type:
                                    mainShapes.push(Points3);
                                    outlines.push(Line_18_2_9);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfBuccal1, SurfDistal, Line_14_6_10, Line_21_9_10, Line_10_2_6);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfBuccal1, SurfMesial, Line_14_6_10, Line_21_9_10, Line_10_2_6);
                                    }
                                    break;
                                case SurfMesial.type:
                                    if (left) {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfPalatal1, SurfBuccal1, Line_8_8_5, Line_12_4_8, Line_9_1_5);
                                    } else {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfPalatal2, SurfBuccal2, Line_6_6_7, Line_11_3_7, Line_10_2_6);
                                    }
                                    break;
                                case SurfPalatal2.type:
                                    mainShapes.push(Points6);
                                    outlines.push(Line_20_3_12);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfPalatal1, SurfDistal, Line_16_7_11, Line_23_11_12, Line_11_3_7);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfPalatal1, SurfMesial, Line_16_7_11, Line_23_11_12, Line_11_3_7);
                                    }
                                    break;
                                case SurfPalatal1.type:
                                    mainShapes.push(Points7);
                                    outlines.push(Line_19_4_12);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfPalatal2, SurfMesial, Line_15_8_11, Line_23_11_12, Line_12_4_8);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfPalatal2, SurfDistal, Line_15_8_11, Line_23_11_12, Line_12_4_8);
                                    }
                                    break;
                                case SurfDistal.type:
                                    if (left) {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal2, SurfPalatal2, SurfBuccal2, Line_6_6_7, Line_11_3_7, Line_10_2_6);
                                    } else {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal1, SurfPalatal1, SurfBuccal1, Line_8_8_5, Line_12_4_8, Line_9_1_5);
                                    }
                                    break;
                                case SurfOcclusal1.type:
                                    mainShapes.push(Points9a);
                                    if (left) {
                                        contains2(surfaces, outlines, SurfOcclusal2, SurfBuccal1, SurfPalatal1, SurfMesial, Line_22_10_11, Line_13_5_10, Line_15_8_11, Line_8_8_5);
                                    } else {
                                        contains2(surfaces, outlines, SurfOcclusal2, SurfBuccal1, SurfPalatal1, SurfDistal, Line_22_10_11, Line_13_5_10, Line_15_8_11, Line_8_8_5);
                                    }
                                    break;
                                case SurfOcclusal2.type:
                                    mainShapes.push(Points9b);
                                    if (left) {
                                        contains2(surfaces, outlines, SurfOcclusal1, SurfBuccal2, SurfPalatal2, SurfDistal, Line_22_10_11, Line_14_6_10, Line_16_7_11, Line_6_6_7);
                                    } else {
                                        contains2(surfaces, outlines, SurfOcclusal1, SurfBuccal2, SurfPalatal2, SurfMesial, Line_22_10_11, Line_14_6_10, Line_16_7_11, Line_6_6_7);
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case AdultPremolar:
                            switch (surface.surface.type) {
                                case SurfBuccal.type:
                                    mainShapes.push(Points1);
                                    outlines.push(Line_1_1_2);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_5_5_6, Line_10_2_6, Line_9_1_5);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_5_5_6, Line_9_1_5, Line_10_2_6);
                                    }
                                    break;
                                case SurfMesial.type:
                                    if (left) {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfPalatal, Line_8_8_5, Line_9_1_5, Line_12_4_8);
                                    } else {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfPalatal, Line_6_6_7, Line_10_2_6, Line_11_3_7);
                                    }
                                    break;
                                case SurfPalatal.type:
                                    mainShapes.push(Points5);
                                    outlines.push(Line_3_3_4);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_7_7_8, Line_11_3_7, Line_12_4_8);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_7_7_8, Line_12_4_8, Line_11_3_7);
                                    }
                                    break;
                                case SurfDistal.type:
                                    if (left) {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfPalatal, Line_6_6_7, Line_10_2_6, Line_11_3_7);
                                    } else {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfPalatal, Line_8_8_5, Line_9_1_5, Line_12_4_8);
                                    }
                                    break;
                                case SurfOcclusal.type:
                                    mainShapes.push(Points9);
                                    if (left) {
                                        contains2(surfaces, outlines, SurfDistal, SurfBuccal, SurfPalatal, SurfMesial, Line_6_6_7, Line_5_5_6, Line_7_7_8, Line_8_8_5);
                                    } else {
                                        contains2(surfaces, outlines, SurfDistal, SurfBuccal, SurfPalatal, SurfMesial, Line_8_8_5, Line_5_5_6, Line_7_7_8, Line_6_6_7);
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case AdultPremolarBicusp:
                            switch (surface.surface.type) {
                                case SurfBuccal.type:
                                    mainShapes.push(Points1);
                                    outlines.push(Line_1_1_2);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_5_5_6, Line_10_2_6, Line_9_1_5);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_5_5_6, Line_9_1_5, Line_10_2_6);
                                    }
                                    break;
                                case SurfMesial.type:
                                    if (left) {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfPalatal, Line_8_8_5, Line_9_1_5, Line_12_4_8);
                                    } else {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfPalatal, Line_6_6_7, Line_10_2_6, Line_11_3_7);
                                    }
                                    break;
                                case SurfPalatal.type:
                                    mainShapes.push(Points5);
                                    outlines.push(Line_3_3_4);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_7_7_8, Line_11_3_7, Line_12_4_8);
                                    } else {
                                        contains1(surfaces, outlines, SurfOcclusal, SurfDistal, SurfMesial, Line_7_7_8, Line_12_4_8, Line_11_3_7);
                                    }
                                    break;
                                case SurfDistal.type:
                                    if (left) {
                                        mainShapes.push(Points4);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfPalatal, Line_6_6_7, Line_10_2_6, Line_11_3_7);
                                    } else {
                                        mainShapes.push(Points8);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfOcclusal, SurfBuccal, SurfPalatal, Line_8_8_5, Line_9_1_5, Line_12_4_8);
                                    }
                                    break;
                                case SurfOcclusal.type:
                                    mainShapes.push(Points9);
                                    if (left) {
                                        contains2(surfaces, outlines, SurfDistal, SurfBuccal, SurfPalatal, SurfMesial, Line_6_6_7, Line_10_2_6, Line_7_7_8, Line_8_8_5);
                                    } else {
                                        contains2(surfaces, outlines, SurfDistal, SurfBuccal, SurfPalatal, SurfMesial, Line_8_8_5, Line_10_2_6, Line_7_7_8, Line_6_6_7);
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case AdultIncisor:
                            switch (surface.surface.type) {
                                case SurfIncisal.type:
                                    mainShapes.push(Points15);
                                    if (left)
                                        contains1(surfaces, outlines, SurfDistal, SurfLabial, SurfPalatal, SurfMesial, Line_6i_6_7, Line_5i_5_6, Line_7i_7_8, Line_8i_8_5);
                                    else
                                        contains1(surfaces, outlines, SurfDistal, SurfLabial, SurfPalatal, SurfMesial, Line_8i_8_5, Line_5i_5_6, Line_7i_7_8, Line_6i_6_7);
                                    break;
                                case SurfLabial.type:
                                    mainShapes.push(Points10);
                                    outlines.push(Line_1_1_2);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfIncisal, SurfDistal, SurfMesial, Line_5i_5_6, Line_10i_2_6, Line_9i_1_5);
                                    } else {
                                        contains1(surfaces, outlines, SurfIncisal, SurfDistal, SurfMesial, Line_5i_5_6, Line_9i_1_5, Line_10i_2_6);
                                    }
                                    break;
                                case SurfMesial.type:
                                    if (left) {
                                        mainShapes.push(Points13);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfIncisal, SurfLabial, SurfPalatal, Line_8i_8_5, Line_9i_1_5, Line_12i_4_8);
                                    } else {
                                        mainShapes.push(Points11);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfIncisal, SurfLabial, SurfPalatal, Line_6i_6_7, Line_10i_2_6, Line_11i_3_7);
                                    }
                                    break;
                                case SurfPalatal.type:
                                    mainShapes.push(Points12);
                                    outlines.push(Line_3_3_4);
                                    if (left) {
                                        contains1(surfaces, outlines, SurfIncisal, SurfDistal, SurfMesial, Line_7i_7_8, Line_11i_3_7, Line_12i_4_8);
                                    } else {
                                        contains1(surfaces, outlines, SurfIncisal, SurfDistal, SurfMesial, Line_7i_7_8, Line_12i_4_8, Line_11i_3_7);
                                    }
                                    break;
                                case SurfDistal.type:
                                    if (left) {
                                        mainShapes.push(Points11);
                                        outlines.push(Line_2_2_3);
                                        contains1(surfaces, outlines, SurfIncisal, SurfLabial, SurfPalatal, Line_6i_6_7, Line_10i_2_6, Line_11i_3_7);
                                    } else {
                                        mainShapes.push(Points13);
                                        outlines.push(Line_4_4_1);
                                        contains1(surfaces, outlines, SurfIncisal, SurfLabial, SurfPalatal, Line_8i_8_5, Line_9i_1_5, Line_12i_4_8);
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    break;
            }
        }
    );
    return {mainShapes, outlines};
};

const contains1 = (surfaces, outlines, target1, target2, target3, points1, points2, points3) => {

    contains(surfaces, outlines, target1, points1);
    contains(surfaces, outlines, target2, points2);
    contains(surfaces, outlines, target3, points3);
};

const contains2 = (surfaces, outlines, target1, target2, target3, target4, points1, points2, points3, points4) => {

    contains(surfaces, outlines, target1, points1);
    contains(surfaces, outlines, target2, points2);
    contains(surfaces, outlines, target3, points3);
    contains(surfaces, outlines, target4, points4);
};

const contains = (surfaces, outlines, target, points) => {

    let targetHit = false;

    try {
        surfaces.forEach((surface) => {
            if (surface.surface.type === target.type)
                targetHit = true;

            if (!targetHit)
                outlines.push(points);
        })
    } catch (error) {
    }
};
