import {tb_boilerPlateRight, tb_boilerPlateRight2, tb_boilerPlateRightCurrency} from "../../Utils";
import moment from "moment";
import {
    __CHIRAL_CONTACT_DETAILS__,
    HM_REP_OVERDUE_ACCOUNTS_EXCEL,
    HM_REP_PAYMENT_PLANS_DOWNLOAD,
    HM_REP_PAYMENT_PLANS_PRINT,
    HM_REP_PAYMENT_PLANS_SHOW,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_EXCEL, ICON_HELP,} from "../../../icons";
import {Column} from "primereact/components/column/Column";
import {dateTemplate} from "../../PatientDynamicItems/Utils";
import {SEC_PP_ACTIVE, SEC_PP_FINISHED, SEC_PP_SUSPENDED, SEC_PP_WRITTEN_OFF} from "./Constants";
import React from "react";
import {PPCOLS} from "./Sections/PaymentPlansBase";
import {
    HELP_REP_OUTSTANDING_ACCS,
    HELP_REP_WEEKS_SHARE,
    SM_TABLET_REGISTRATIONS
} from "../../../actions/stateManagement";
import {TextNegativePatientAccountExcel} from "../../../Messages";

export const ShareType = {
    Invoiced: 'Invoiced',
    Completed: 'Completed',
}

export const UnrollType = {
    InvRecpt: 'InvRecpt',
    CreditNote: 'CreditNote',
    Adjustment: 'Adjustment',
    Refund: 'Refund',
}

export const accountsOutstandingToolBar = (callbacks, totalOutStanding) => {

    let key = Math.floor(Math.random() * 1000);

    return (<React.Fragment>

            {tb_boilerPlateRightCurrency(key++, totalOutStanding, true)}
            {tb_boilerPlateRight2(callbacks[HM_REP_OVERDUE_ACCOUNTS_EXCEL.id], ICON_EXCEL, `Download ${TextNegativePatientAccountExcel} Report`, key++, false)}
            {tb_boilerPlateRight(() => window.open(HELP_REP_OUTSTANDING_ACCS), ICON_HELP, 'Knowledge Base', key++, true)}
            {tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
        </React.Fragment>
    )
};

export const calculateOutstandingAmount = (overDues) => {

    let overDueTotal = 0.0;

    overDues.forEach((overDue) => {
        overDueTotal += overDue.total - overDue.paidToDate;
    });
    return overDueTotal;
};

export const calculateInCreditAmount = (inCredits) => {

    let inCreditAmount = 0.0;

    inCredits.forEach((inCredit) => {
        inCreditAmount += inCredit.accountBalance;
    });
    return inCreditAmount;
};

export const paymentPlanToolBar = (callbacks, plansTotal) => {

    let key = Math.floor(Math.random() * 1000);

    return [

        tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++),
        tb_boilerPlateRight(callbacks[SM_TABLET_REGISTRATIONS.id], HM_REP_PAYMENT_PLANS_DOWNLOAD.icon, HM_REP_PAYMENT_PLANS_DOWNLOAD.label, key++, true),
        tb_boilerPlateRight2(callbacks[HM_REP_PAYMENT_PLANS_PRINT.id], HM_REP_PAYMENT_PLANS_PRINT.icon, HM_REP_PAYMENT_PLANS_PRINT.label, key++, false),
        tb_boilerPlateRight2(callbacks[HM_REP_PAYMENT_PLANS_SHOW.id], HM_REP_PAYMENT_PLANS_SHOW.icon, HM_REP_PAYMENT_PLANS_SHOW.label, key++, false),
        tb_boilerPlateRightCurrency(key, plansTotal, true),
    ];
};

const converter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
});

export const calculatePlanAmount = (plans) => {

    let plansTotal = 0.0;

    plans.forEach((plan) => {
        plansTotal += plan.planTotal;
    });
    return plansTotal;
};

export const addPaymentPlanColumns = (type) => {

    const content = [];
    let key = Math.floor(Math.random() * 1000);

    content.push(<Column field="patientName"
                         header={PPCOLS[0]}
                         key={key++}
                         style={{width: '20%'}}/>
    );
    content.push(<Column body={row => dateTemplate(row.firstInstallment)}
                         header={PPCOLS[1]}
                         key={key++}
                         style={{width: '15%'}}/>
    );
    content.push(<Column body={row => converter.format(row.planTotal)}
                         header={PPCOLS[2]}
                         key={key++}
                         style={{width: '10%', textAlign: 'right'}}/>
    );
    content.push(<Column body={row => converter.format(row.deposit)}
                         header={PPCOLS[3]}
                         key={key++}
                         style={{width: '10%', textAlign: 'right'}}/>
    );
    switch (type) {

        case SEC_PP_ACTIVE:
        case SEC_PP_FINISHED:
            content.push(<Column field="noOfInstallments"
                                 header={PPCOLS[4]}
                                 key={key++}
                                 style={{width: '10%', textAlign: 'right'}}/>
            );
            content.push(<Column field="noUnissed"
                                 header={PPCOLS[5]}
                                 key={key++}
                                 style={{width: '10%', textAlign: 'right'}}/>
            );
            content.push(<Column body={row => converter.format(row.unissedTotal)}
                                 header={PPCOLS[6]}
                                 key={key++}
                                 style={{width: '10%', textAlign: 'right'}}/>
            );
            break;
        case SEC_PP_SUSPENDED:
            content.push(<Column body={(row) => dateTemplate(row.suspendUntil)}
                                 header={PPCOLS[7]}
                                 key={key++}
                                 style={{width: '15%', textAlign: 'right'}}/>
            );
            content.push(<Column field="narrative"
                                 header={PPCOLS[9]}
                                 key={key++}
                                 style={{width: '20%', textAlign: 'right'}}/>
            );
            break;
        case SEC_PP_WRITTEN_OFF:
            content.push(<Column body={(row) => dateTemplate(row.changeDate)}
                                 header={PPCOLS[8]}
                                 key={key++}
                                 style={{width: '15%', textAlign: 'right'}}/>
            );
            content.push(<Column field="narrative"
                                 header={PPCOLS[9]}
                                 key={key++}
                                 style={{width: '20%', textAlign: 'right'}}/>
            );
            break;
        default:
            break;
    }
    return content;
}

export default class ReportErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>Something went wrong in the Report.</h1>
                    <h2>{__CHIRAL_CONTACT_DETAILS__}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}

export const RevenueShareHelperDAO = (sortIndex, showGroupName, data) => {

    return {
        isRevenueShareHelperDAO: true,
        sortIndex,
        isShowGroupName: showGroupName,
        data,
    }
};

const RevenueSplitDAO = (type, groupName, username) => {

    return {
        type,
        groupName,
        isRevenueSplitDAO: true,
        amount: new Array(12).fill(0.0),
        credits: new Array(12).fill(0.0),
        refunds: new Array(12).fill(0.0),
        adjustments: new Array(12).fill(0.0),
    }
}

export const fillLookup = (lower, YEARENDMONTH) => {

    const results = ['Group', 'Username'];

    const date = moment(lower).set({'month': YEARENDMONTH + 1, 'date': 1});

    for (let index = 0; index < 12; index++) {
        results.push(date.format('MMMM'));
        date.add(1, 'month');
    }
    return [...results, 'Sub Total'];
}

export const getVisibleTransactions = (transactions, totalsObject, onlySubtotals) => {

    let newValues = [];

    let totalSplits = RevenueSplitDAO(UnrollType.InvRecpt, "Totals", "");
    let subTotalSplits = RevenueSplitDAO(UnrollType.InvRecpt, "Sub Totals", "");
    let subCNTotalSplits = RevenueSplitDAO(UnrollType.CreditNote, "Sub CN Totals", "");
    let subRefTotalSplits = RevenueSplitDAO(UnrollType.Refund, "Sub Ref Totals", "");
    let subAdjTotalSplits = RevenueSplitDAO(UnrollType.Adjustment, "Sub Adj Totals", "");

    transactions.forEach(item => {

        let providerTotal = 0.0;

        for (let month = 0; month <= 12; month++) {
            providerTotal = providerTotal + item.data.amount[month];

            switch (item.data.type) {
                case UnrollType.Adjustment :
                    totalsObject.totals[month] = totalsObject.totals[month] + item.data.adjustments[month];
                    totalsObject.subAdjTotals[month] = totalsObject.subAdjTotals[month] + item.data.adjustments[month];
                    subAdjTotalSplits.adjustments[month] = totalsObject.subAdjTotals[month];
                    totalSplits.amount[month] = totalsObject.totals[month];
                    break;
                case UnrollType.InvRecpt :
                    totalsObject.subTotals[month] = totalsObject.subTotals[month] + item.data.amount[month];
                    totalsObject.totals[month] = totalsObject.totals[month] + item.data.amount[month];
                    subTotalSplits.amount[month] = totalsObject.subTotals[month];
                    totalSplits.amount[month] = totalsObject.totals[month];
                    break;
                case UnrollType.CreditNote :
                    totalsObject.totals[month] = totalsObject.totals[month] - item.data.credits[month];
                    totalsObject.subCNTotals[month] = totalsObject.subCNTotals[month] + item.data.credits[month];
                    subCNTotalSplits.credits[month] = totalsObject.subCNTotals[month];
                    totalSplits.amount[month] = totalsObject.totals[month];
                    break;
                case UnrollType.Refund :
                    totalsObject.totals[month] = totalsObject.totals[month] - item.data.refunds[month];
                    totalsObject.subRefTotals[month] = totalsObject.subRefTotals[month] + item.data.refunds[month];
                    subRefTotalSplits.refunds[month] = totalsObject.subRefTotals[month];
                    totalSplits.amount[month] = totalsObject.totals[month];
                    break;
                default:
                    break;
            }
        }
        newValues.push(item);
    });

    if (onlySubtotals) {
        newValues = [];
        newValues.push(subTotalSplits);
    } else {
        newValues.unshift("Blank Line");
        newValues.unshift(subRefTotalSplits);
        newValues.unshift("Blank Line");
        newValues.unshift(subCNTotalSplits);
        newValues.unshift("Blank Line");
        newValues.unshift(subAdjTotalSplits);
        newValues.unshift("Blank Line");
        newValues.unshift(subTotalSplits);
        newValues.unshift("Blank Line");
        newValues.unshift(totalSplits);
    }
    return newValues;
}
