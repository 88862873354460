import React from 'react';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {
    setState,
    SM_NHS_MANAGEMENT,
    SM_NHS_MANAGEMENT_SUBMITTED,
    stateRequest
} from "../../../../actions/stateManagement";
import {getNHSManagementIds} from "../Utils";
import {
    deleteClaim,
    getResource,
    RES_getProviderPerformers,
    RES_NHS_MANAGEMENT_CLAIM,
    RES_NHS_MANAGEMENT_CLAIMS,
    RES_NHS_MANAGEMENT_SUBMITTED,
    resubmitClaim,
    saveClaim
} from "../../../../actions/nhsManagement";
import * as Actions from "../../../../actions";
import {
    claimReferenceTemplate,
    EDI_CLAIM_TOTAL,
    EDI_ST_Submitted,
    EDI_SUBMITTED,
    EDI_SUBMITTED_TITLE,
    formTemplate,
    nameTemplate,
    ppPerformerNumberTemplate,
} from "../Constants";

import ClaimsComponent from "../ClaimsComponent";
import {InputText} from "primereact/inputtext";
import {dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";
import _ from "lodash";

export class ConnectedSubmittedSection extends ClaimsComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                type: EDI_SUBMITTED,

                stateManagementId: SM_NHS_MANAGEMENT_SUBMITTED.id,

                selectedUser: {id: null},
                firstClinicians: 0,
                rowsClinicians: 5,

                claims: [],
                selectedClaim: null,
                deletedClaim: {providerPIN: '', narrative: ''},

                claimedTotal: 0,

                firstClaims: 0,
                rowsClaims: 5,

                pinValid: false,
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:
                    break;
                case RES_NHS_MANAGEMENT_SUBMITTED.GET.request:
                    this.setState({claims: [], claimsLoaded: false, claimedTotal: 0});
                    break;
                case RES_NHS_MANAGEMENT_CLAIM.SAVE.action:
                case RES_NHS_MANAGEMENT_CLAIM.DELETE.action:
                case RES_NHS_MANAGEMENT_CLAIMS.RESUBMIT.receive:
                    this.onUserReload();
                    break;
                case RES_NHS_MANAGEMENT_SUBMITTED.GET.receive:

                    let claimedTotal = 0;
                    this.props.claims.forEach((claim) => claimedTotal += claim.units);

                    this.setState({claims: this.props.claims, claimedTotal}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.props.providerPerformersLoaded || (this.state.selectedUser.id !== null && !this.props.claimsLoaded)) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const sortedClaims = _.sortBy(this.state.claims, ['id'], ['asc']);

        const claimsHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{EDI_SUBMITTED_TITLE}</span>
                <span className='p-panel-title' style={{marginRight: 15}}>{EDI_CLAIM_TOTAL}</span>
                <InputText value={this.state.claimedTotal}
                           style={{textAlign: 'right'}}
                           disabled={true}
                />
            </div>
        </div>;

        return (
            <div>
                <Panel header='Performers' style={{marginBottom: '5px'}}>

                    <ContextMenu style={{width: 250}} model={this.buildUserContextMenu()}
                                 ref={el => this.usersCm = el}/>

                    <DataTable value={this.sortProviderPerformers()}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsClinicians}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstClinicians', 'rowsClinicians')}
                               first={this.state.firstClinicians}
                               selection={this.state.selectedUser}
                               onSelectionChange={this.onUserSelectionChange}
                               contextMenuSelection={this.state.selectedUser}
                               onContextMenuSelectionChange={this.onUserSelectionChange}
                               onContextMenu={e => this.usersCm.show(e.originalEvent)}
                    >

                        <Column header="Name"
                                body={nameTemplate}
                                style={{width: '70%'}}/>
                        <Column header="Performer Number"
                                body={ppPerformerNumberTemplate}
                                style={{textAlign: 'right', width: '30%'}}/>
                    </DataTable>
                </Panel>

                <Panel headerTemplate={claimsHeader}>

                    <ContextMenu style={{width: 250}} model={this.buildClaimContextMenu(EDI_ST_Submitted)}
                                 ref={el => this.cm = el}/>

                    {this.showDialogs()}

                    <DataTable value={sortedClaims}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsClaims}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstClaims', 'rowsClaims')}
                               first={this.state.firstClaims}
                               selection={this.state.selectedClaim}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedClaim}
                               onContextMenuSelectionChange={this.onSelection}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent);
                               }}
                    >

                        <Column body={(row) => dateTemplateShort(row.stackingDate)}
                                header="Stacked On"
                                style={{width: '10%'}}/>

                        <Column body={(row) => dateTemplateShort(row.submissionDate)}
                                header="Submitted On"
                                style={{width: '10%'}}/>

                        <Column field='count'
                                header="Count"
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column body={(row) => claimReferenceTemplate(row)}
                                header="Claim"
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column body={(row) => formTemplate(row)}
                                header="Form"
                                style={{width: '25%'}}/>

                        <Column field="units"
                                header="Units"
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column field="journeyCode"
                                header="Stage"
                                style={{width: '5%'}}/>

                        <Column body={(row) => nameTemplate(row)}
                                header="Patient"
                                style={{width: '15%'}}/>

                        <Column field="narrative"
                                header="Comment"
                                style={{width: '20%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        providerPerformersLoaded,
        providerPerformers,

        submittedLoaded,
        submitted,
    } = getNHSManagementIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        providerPerformersLoaded,
        providerPerformers,

        claimsLoaded: submittedLoaded,
        claims: submitted,

        currentState: state.stateManagement[SM_NHS_MANAGEMENT_SUBMITTED.id],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getProviderPerformers: () => dispatch(getResource(RES_getProviderPerformers.GET, {})),
        getClaims: (ppId) => dispatch(getResource(RES_NHS_MANAGEMENT_SUBMITTED.GET, {ppId})),
        saveClaim: (details) => dispatch(saveClaim(RES_NHS_MANAGEMENT_CLAIM.SAVE, details)),
        resubmitClaim: (details) => dispatch(resubmitClaim(RES_NHS_MANAGEMENT_CLAIMS.RESUBMIT, details)),
        deleteClaim: (details) => dispatch(deleteClaim(RES_NHS_MANAGEMENT_CLAIM.DELETE, details)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data, SM_NHS_MANAGEMENT.id)),
    }
};

const SubmittedSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedSubmittedSection);

export default SubmittedSection;

