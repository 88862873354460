import React from 'react';

import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Button} from 'primereact/components/button/Button';

import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {BaseComponent} from "../../../BaseComponent";

import {getReferrerIds} from "../Utils";
import {getRefPatients, RES_REFERRER_PATIENTS} from "../../../../actions/findReferrers";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {TB_PATIENT_DETAILS} from "../../PatientDetails/Constants";
import {CONST_FEMALE} from "../../../PatientDynamicItems/OnChangeUtils";
import {TB_REFERRER_PATIENT} from "../Constants";
import {showPatientDetailsPage} from "../../PatientDetails/Utils";
import {ProgressBar} from "primereact/progressbar";
import {ICON_PRINT, ICON_SCREEN} from "../../../../icons";

export class ConnectedReferredPatients extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedPatient: null,

            first: 0,
            rows: 5,
        };

        this.onShowDetails = this.onShowDetails.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);

        this.toolbarCallbacks = {

            [TB_REFERRER_PATIENT.id]: this.onShowDetails,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getRefPatients(this.props.referrerId);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onShowDetails({id, firstName, lastName, gender, nhsPatient}) {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient});
    }

    onDoubleClick(e) {

        this.setState({selectedPatient: e.data});
        this.onShowDetails(e.data);
    }

    buildMenu() {

        if (this.state.selectedPatient === null) return [];

        const genderIcon = this.state.selectedPatient.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;

        return [
            {
                label: TB_REFERRER_PATIENT.text, icon: genderIcon, command: (e) => {
                    this.toolbarCallbacks[TB_REFERRER_PATIENT.id](this.state.selectedPatient);
                }
            },
        ]
    }

    render() {

        if (!this.props.referrerPatientsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }


        const items = this.buildMenu();

        const header = <div className="p-helper-clearfix"
                            style={{'lineHeight': '1.87em'}}>Referred Patients
            <Button style={{'float': 'right'}} icon={ICON_SCREEN}/>
            <Button style={{'float': 'right'}} icon={ICON_PRINT}/>
        </div>;

        return (
            <div className="p-grid">

                <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                <DataTable value={this.props.referrerPatients}
                           header={header}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}
                           selection={this.state.selectedPatient}
                           onRowDoubleClick={this.onDoubleClick}
                           onSelectionChange={this.onSelection}
                           contextMenuSelection={this.state.selectedPatient}
                           onContextMenuSelectionChange={e => this.setState({selectedPatient: e.value})}
                           onContextMenu={e => {
                               this.cm.show(e.originalEvent);
                           }}
                >
                    <Column field='fullName' header="Patient" style={{width: '75%'}}/>
                    <Column body={row => dateTemplate(row.registrationDate)} header="Registered On"
                            style={{width: '25%'}}/>
                </DataTable>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {referrerPatientsId, referrerPatientsLoaded} = getReferrerIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        referrerPatientsLoaded,
        referrerPatientsId,
        referrerPatients: state.referrers[referrerPatientsId],

        currentState: state.stateManagement[referrerPatientsId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getRefPatients: (referrerId) => dispatch(getRefPatients(RES_REFERRER_PATIENTS.GET, referrerId)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const ReferredPatients = connect(mapStateToProps, mapDispatchToProps)(ConnectedReferredPatients);

export default ReferredPatients;