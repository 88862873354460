import React from 'react';
import {ac} from "../../../../index";

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import _ from "lodash";
import {
    inputCurrencyText,
    inputDiscountText,
    inputText,
    inputTextArea,
    outputCurrency,
    outputCurrencyText,
    outputText,
    spinnerStep
} from "../../../PatientDynamicItems/OnChangeUtils";
import {
    CH_INVOICE,
    CI_STATUS_USABLE,
    HM_PATIENT_SALES,
    SU_STATUS_ACTIVE
} from "../../../../Constants";
import {
    ICON_CANCEL,
    ICON_MINUS,
    ICON_OK,
    ICON_PLUS,
} from "../../../../icons";
import {chargeData} from "../../DefaultData";
import {connect} from "react-redux";
import {getResource as getHKResource, RES_HOUSEKEEPING_TCODES} from "../../../../actions/housekeeping";
import {getResource as getCHResource, RES_chartResources} from "../../../../actions/ChartResources";
import * as Actions from "../../../../actions";
import {TB_PATIENT_SALES} from "../Constants";
import {BaseComponent} from "../../../BaseComponent";
import {currencyTemplate} from "../../fixedItemUtils";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";
import {findAccountGroup} from "../../AppointmentDetails/Utils";

class ConnectedAddPatientSale extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            dummyId: -100,
            selectedTreatmentCode: null,
            charge: _.cloneDeep(chargeData(ac.getMcId())),
            saleItems: [],
            saleTotal: 0.0,
            selectedSaleItem: null,
            selectedProvider: null,
            favourite: {items: []},
            firstCode: 0,
            codeRows: 5,
            firstItem: 0,
            itemRows: 5,
            globalFilter: '',
            selectedAccountGroup: null,
            needAccountGroup: false,
        };

        this.state.charge.appointment = null;
        this.state.charge.chargedBy = null;
        this.state.charge.chargedTo = {id: props.patient.id};
        this.state.charge.treatmentPlan = null;

        this.onChange = this.onChange.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onCostChange = this.onCostChange.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.onSaleItemSelection = this.onSaleItemSelection.bind(this);
        this.onAddSaleItem = this.onAddSaleItem.bind(this);
        this.onRemoveSaleItem = this.onRemoveSaleItem.bind(this);
        this.clearCharge = this.clearCharge.bind(this);
        this.onCompleteSale = this.onCompleteSale.bind(this);

        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {

        this.props.getHousekeepingTCodes();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_HOUSEKEEPING_TCODES:
                    this.props.getChartResources();
                    break;

                case Actions.RECEIVE_CHART_RESOURCES:

                    const All = {
                        id: -1,
                        mc: ac.getMcId(),
                        description: 'All',
                        items: this.props.treatmentCodes.items,
                        default: true,
                    };

                    const favourites = [All, ...this.props.resources.favourites];

                    let providers = _.filter(this.props.usersShort, user => {
                        return user.status === SU_STATUS_ACTIVE;
                    });

                    this.setState({
                        providers,
                        favourites,
                        favourite: _.find(favourites, favourite => favourite.default === true)
                    });
                    break;
                default:
                    break;
            }
        }
    }

    clearCharge() {

        const charge = _.cloneDeep(chargeData(ac.getMcId()));

        charge.appointment = null;
        charge.chargedBy = null;
        charge.chargedTo = {id: this.props.patient.id};
        charge.treatmentPlan = null;

        this.setState({charge});
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {
            case 'charge.chargedPrice' :

                if (isNaN(event.value)) {
                    return;
                } else {
                    const value = event.value.length === 0 ? 0.0 : event.value
                    _.set(state, event.owner, value);
                }
                break;
            default:
                _.set(state, event.owner, event.value);
                break;
        }

        this.setState(state);
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows});
    }

    onCostChange(event) {

        const charge = {...this.state.charge};
        charge.total = charge.quantity * charge.chargedPrice;

        this.setState({charge});
    }

    onSaleItemSelection(e) {

        this.setState({selectedSaleItem: e.value});
    }

    onCompleteSale() {

        this.onHide();

        const saleItems = [...this.state.saleItems];

        saleItems.forEach(item => item.accountGroup = this.state.selectedAccountGroup);

        const patientSale = {
            saleTotal: this.state.saleTotal,
            saleItems,
            provider: this.state.selectedProvider,
            accountGroup: this.state.selectedAccountGroup,
            needAccountGroup: this.state.needAccountGroup,
        };

        this.props.onFinishPatientSale(patientSale);
    }

    onSelection(e) {

        const {cost} = e.value;
        const charge = {...this.state.charge};

        charge.chargedPrice = cost;
        charge.quantity = 1;
        charge.site = '';
        charge.item = e.value;
        charge.status = CH_INVOICE;
        charge.chargedBy = {id: this.props.patient.provider.id};

        this.setState({selectedTreatmentCode: e.value, charge});
    }

    onHide() {
        this.props.onHideDialog(TB_PATIENT_SALES.id);
    }

    costTemplate(rowData) {

        const tc = rowData;

        if (isNaN(tc.cost)) {
            return currencyTemplate({cost: 0}, 'cost');
        } else {
            return tc.discountCharge ? tc.cost : currencyTemplate(tc, 'cost');
        }
    }

    colourTemplate(rowData) {

        const tc = rowData;

        if (tc.colour === null) {
            return null;
        }

        if (tc.colour === undefined) {
            return null;
        }

        const color = `rgb(${tc.colour.split(':').join(',')})`;
        return <div style={{backgroundColor: color}}>&nbsp;</div>
    }

    codeTemplate(rowData) {

        return rowData.item.code;
    }

    descriptionTemplate(rowData) {

        return rowData.item.description;
    }

    feeTemplate(rowData) {

        if (rowData.item.discountCharge) {
            return rowData.chargedPrice;
        } else {
            return outputCurrency(rowData.chargedPrice);
        }
    }

    dealWithCostOrDiscount = (props, item, quantity, chargedPrice) => {

        if (item && item.discountCharge) {
            return (
                <React.Fragment>
                    <div className="p-col-3">
                        <label>Discount</label>
                    </div>
                    <div className="p-col-9">
                        {inputDiscountText(props, 'chargedPrice', '', false, true)}
                    </div>
                    <div className="p-col-3">
                        <label>Applied Discount</label>
                    </div>
                    <div className="p-col-9">
                        {outputText(quantity * chargedPrice)}
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="p-col-3">
                        <label>Cost</label>
                    </div>
                    <div className="p-col-9">
                        {inputCurrencyText(props, 'chargedPrice', '', false, true)}
                    </div>
                    <div className="p-col-3">
                        <label>Applied Cost</label>
                    </div>
                    <div className="p-col-9">
                        {outputCurrencyText(quantity * chargedPrice)}
                    </div>
                </React.Fragment>
            )
        }
    }

    addLeftColumn(item, quantity, chargedPrice) {

        const disableQTY = item === null || item.discountCharge;

        const props = {
            onChange: this.onChange,
            target: 'charge',
            charge: this.state.charge,
        };

        const className = this.state.selectedProvider === null ? 'p-invalid' : '';
        const providers = _.filter(this.state.providers, provider => provider.status === SU_STATUS_ACTIVE && provider.username !== 'superuser' && provider.username !== 'TED_superuser');

        return (
            <div className="p-col-6">
                <div className="p-grid form-group p-col-12">
                    <div className="p-col-3">
                        <label>Favourites</label>
                    </div>
                    <div className="p-col-9">
                        <Dropdown key='favDropDown-2' optionLabel='description'
                                  value={this.state.favourite}
                                  options={this.state.favourites}
                                  onChange={(e) => {
                                      this.setState({favourite: e.value})
                                  }}
                                  autoWidth={false}
                                  scrollHeight='100px'
                        />
                    </div>
                    <div className="p-col-3">
                        <label>Provider</label>
                    </div>
                    <div className="p-col-9">
                        <Dropdown key='assignedTo'
                                  value={this.state.selectedProvider}
                                  options={providers}
                                  optionLabel='fullName'
                                  className={className}
                                  onChange={(e) => {
                                      const {needAccountGroup, accountGroup} = findAccountGroup(this.props.accountGroups, e.value.id);
                                      this.setState({selectedProvider: e.value, selectedAccountGroup: accountGroup, needAccountGroup})
                                  }}
                        />
                    </div>
                    <div className="p-col-3">
                        <label>Quantity</label>
                    </div>
                    <div className="p-col-9">
                        {spinnerStep(props, 'quantity', 0, 100, 1, disableQTY, true)}
                    </div>
                    <div className="p-col-3">
                        <label>Treatment</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'item.description', '', true, false)}
                    </div>

                    {this.dealWithCostOrDiscount(props, item, quantity, chargedPrice)}

                    <div className="p-col-3">
                        <label>Comment</label>
                    </div>
                    <div className="p-col-9">
                        {inputTextArea(props, 'comment', 2, -1, false, false)}
                    </div>
                </div>
            </div>
        )
    }

    addRightColumn() {

        let favouriteItems = _.sortBy(this.state.favourite.items, ['description'], ['asc']);
        favouriteItems = _.filter(favouriteItems, item => item.status === CI_STATUS_USABLE);

        return (

            <div className="p-col-6">
                <div style={{'textAlign': 'left', paddingBottom: '10px'}}>
                    <InputText type="search"
                               onInput={(e) => this.setState({globalFilter: e.target.value})}
                               placeholder="Code Search"
                               size="50"
                               autoFocus
                    />
                </div>
                <ScrollPanel style={{
                    width: '100%',
                    height: '275px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'gray'
                }} className='custom'>
                    <DataTable header=""
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               value={favouriteItems}
                               selectionMode="single"
                               paginator={false}
                               selection={this.state.selectedTreatmentCode}
                               onSelectionChange={this.onSelection}
                               globalFilter={this.state.globalFilter}
                               disabled={false}
                    >

                        <Column header=""
                                body={this.colourTemplate}
                                style={{width: '10%'}}/>
                        <Column field="description"
                                header="Description"
                                filer={true}
                                filterMatchMode='contains'
                                style={{width: '55%'}}/>
                        <Column field="code"
                                header="Code"
                                filer={true}
                                filterMatchMode='contains'
                                style={{width: '15%'}}/>
                        <Column header="£/%"
                                body={this.costTemplate}
                                style={{width: '20%', textAlign: 'right'}}/>
                    </DataTable>
                </ScrollPanel>
            </div>
        )
    }

    onAddSaleItem(charge) {

        const saleItems = [...this.state.saleItems];
        charge.id = this.state.dummyId;
        saleItems.push(charge);

        let saleTotal = 0.0;

        if (charge.item.discountCharge) {
            saleTotal = this.state.saleTotal - (this.state.saleTotal * (charge.chargedPrice / 100));
        } else {
            saleTotal = this.state.saleTotal + charge.quantity * charge.chargedPrice;
        }
        this.setState({saleTotal, saleItems, selectedTreatmentCode: null, dummyId: this.state.dummyId + 1});
    }

    onRemoveSaleItem() {

        const saleItems = _.filter(this.state.saleItems, item => item.id !== this.state.selectedSaleItem.id);
        const charge = this.state.selectedSaleItem;
        const saleTotal = this.state.saleTotal - charge.quantity * charge.chargedPrice;
        this.setState({saleTotal, saleItems, selectedSaleItem: null});
    }

    render() {

        if (!this.props.resourcesLoaded || !this.props.treatmentCodesLoaded) {
            return null;
        }

        const {item, quantity, chargedPrice} = this.state.charge;

        const addCharge = item !== null && quantity > 0 && chargedPrice !== null && this.state.selectedProvider != null;
        const removeCharge = this.state.selectedSaleItem != null;
        const completeSale = this.state.saleItems.length > 0 && this.state.selectedProvider != null;

        const footer =
            <div>
                <Button label='Sale Item'
                        icon={ICON_PLUS}
                        onClick={() => {
                            this.onAddSaleItem(this.state.charge);
                            this.clearCharge();
                        }}
                        disabled={!addCharge}/>
                <Button label='Sale Item'
                        icon={ICON_MINUS}
                        onClick={() => {
                            this.onRemoveSaleItem();
                        }}
                        disabled={!removeCharge}/>
                <Button label="Complete Sale"
                        icon={ICON_OK}
                        onClick={this.onCompleteSale}
                        disabled={!completeSale}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>;

        return (
            <Dialog header={`${HM_PATIENT_SALES.header} : ${outputCurrency(this.state.saleTotal)}`}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div className="p-grid p-fluid p-col-12">
                    {this.addLeftColumn(item, quantity, chargedPrice)}
                    {this.addRightColumn()}
                </div>

                <div className="p-col-12">
                    <DataTable header={'Items'}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               value={this.state.saleItems}
                               selectionMode="single"
                               selection={this.state.selectedSaleItem}
                               onSelectionChange={this.onSaleItemSelection}
                    >

                        <Column header="Code"
                                body={this.codeTemplate}
                                style={{width: '15%'}}/>
                        <Column header="Description"
                                body={this.descriptionTemplate}
                                style={{width: '55%'}}/>
                        <Column field="quantity"
                                header="QTY"
                                style={{width: '15%', textAlign: 'right'}}/>
                        <Column header="Fee / %Disc"
                                body={this.feeTemplate}
                                style={{width: '15%', textAlign: 'right'}}/>
                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const MapStateToProps = (state) => {
    return {

        message: state.stateManagement.message,

        resourcesLoaded: state.chartResources.resourcesLoaded,
        resources: state.chartResources.resourcesLoaded ? state.chartResources.resources.resources : null,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        treatmentCodesLoaded: state.housekeeping.treatmentCodesLoaded,
        treatmentCodes: state.housekeeping.treatmentCodes,
    }
};


const MapDispatchToProps = dispatch => {
    return {

        getChartResources: () => dispatch(getCHResource(RES_chartResources.GET)),
        getHousekeepingTCodes: () => dispatch(getHKResource(RES_HOUSEKEEPING_TCODES.GET, {})),
    }
};

const AddPatientSale = connect(MapStateToProps, MapDispatchToProps)(ConnectedAddPatientSale);

export default AddPatientSale;

