import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {HM_AddTreatment, TREATMENTS_APP} from "../../../../Constants";
import {TB_SHOW_TREATMENT_ADD} from "../../PatientDetails/Constants";

export default class Treatments extends React.Component {

    render() {
        const {provided, innerRef, children, treatmentDisplay} = this.props;
        const headerContent = treatmentDisplay === TREATMENTS_APP ?
                <Button tooltip={HM_AddTreatment.header}
                        tooltipOptions={{position: 'right'}}
                        icon={HM_AddTreatment.icon}
                        onClick={(e) => {
                            this.props.onShowAddEditTreatment(false, null, this.props.appointmentId, TB_SHOW_TREATMENT_ADD.id);
                        }}>
                </Button> : null;

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>Treatments</span>
                        {headerContent}
                    </div>
                    <div className="items-margin d-flex d-align-center">
                    </div>
                </div>;

        return (
            <div {...provided.droppableProps} ref={innerRef}>
                <Panel headerTemplate={header}>
                    {children}
                </Panel>
            </div>
        );
    }
};