import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {categoryDropDown} from "./OnChangeUtils";

export const DevStage = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header='Developmental Stage'>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>Stage</label>
                </div>
                <div className="p-lg-9 p-md-9">
                    {categoryDropDown(props, 'DevelopmentalStage', 'devStageACE', readOnly)}
                </div>
            </div>
        </Panel>
    );
};
