import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {HM_AddTemplateNote, HM_EditTemplateNote} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Editor} from "primereact/editor";

export default class AddTemplateNote extends Component {

    constructor(props) {
        super(props);

        if (props.editing) {
            this.state = {
                templateNote: {...props.note}
            }
        } else {
            this.state = {
                templateNote: {
                    id: null,
                    mc: ac.getMcId(),
                    name: '',
                    content: '',
                },
            }
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
            if (this.props.editing) {
                this.setState({templateNote: this.props.item})
            }
        }
    }

    handleEvent(e) {
        console.log(`handleEvent :: ${e}`);
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    render() {

        const tnDefined = this.state.templateNote.name === '';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.templateNote)
                        }}
                        disabled={tnDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(this.props.target)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'templateNote',
            templateNote: this.state.templateNote,
        };

        return (
            <Dialog header={this.props.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        const target = this.props.editing ? HM_EditTemplateNote.id : HM_AddTemplateNote.id;
                        this.props.onHideDialog(target)
                    }}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <label>Name</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'name', 'Name', false, true)}
                        </div>
                        <div className="p-col-12">
                            <label>Content</label>
                        </div>
                        <div className="p-col-12">
                            <Editor value={props.templateNote.content}
                                    onTextChange={(e) => this.onChange({
                                        owner: 'templateNote.content',
                                        value: e.htmlValue
                                    })}
                                    style={{height: '300px'}}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
