import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {inputText, inputTextArea, password} from "../../../PatientDynamicItems/OnChangeUtils";

export const Details = (props) => {

    return (
        <Panel header='Subscription Administration Details'>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-3">
                    <label htmlFor="passwd">Password for superuser</label>
                </div>
                <div className="p-col-9">
                    {password(props, 'passwd', false, false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="name">Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'name', 'Name', false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="description">Description</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'description', 'Description', false, false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="contact">Comments</label>
                </div>
                <div className="p-col-9">
                    {inputTextArea(props, 'comments', 5, -1, false, false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="contact">Contact</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'contact', 'Contact', false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="telephone">Telephone</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'telephone', 'Telephone', false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="email">Email</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'email', 'Email', false, true)}
                </div>
            </div>
        </Panel>
    )
};
