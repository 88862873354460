import React from 'react';
import moment from "moment";
import {connect} from "react-redux";
import _ from "lodash";
import {Button} from 'primereact/components/button/Button';
import {Calendar} from 'primereact/components/calendar/Calendar';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';

import {TreeTable} from 'primereact/components/treetable/TreeTable';

import {
    closeRecall,
    getResource,
    HM_RecallsActionPrint,
    HM_RecallsClinicians,
    HM_RecallsClose,
    HM_RecallsGJActionSendElectronic,
    HM_RecallsGroupJoint,
    HM_RecallsIndividual,
    HM_RecallsIndividualActionSendElectronic,
    HM_RecallsMakeApp,
    HM_RecallsPatSettings,
    HM_RecallsSend,
    RES_getRecallsDue,
    RES_PatientRecall,
    sendRecall,
    updatePatientRecallDetails
} from "../../../actions/recalls";
import {HELP_RECALLS_DUE, setState, SM_RECALLS_DUE, stateRequest} from "../../../actions/stateManagement";
import {getAllUsers} from "../../../actions/users";
import {
    HM_notImplemented,
    JSON_DATE_FORMAT,
    RR_selectedItem,
    RRT_RECALL_EMAIL,
    RRT_RECALL_HOMEPHONE,
    RRT_RECALL_MOBILE,
    RRT_RECALL_OTHERPHONE,
    RRT_RECALL_SMS,
    RRT_RECALL_SNAILMAIL,
    RRT_RECALL_WORKPHONE,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_HELP} from "../../../icons";
import {ShowMessageDialog, ShowQuestionDialog} from "../Diary/components/EventComponent";
import {RecallReminderPatientDetails} from "../../PatientDynamicItems/RecallReminderPatientDetails";
import * as Actions from "../../../actions";
import {calcAllRecalls} from "./calcAllRecalls";
import {dateTemplate, dateTemplateShort, durationTemplate2} from "../../PatientDynamicItems/Utils";
import {RecallReminderComponent} from "../../RecallReminderComponent";
import {SMSBalance} from "../../PatientDynamicItems/SMSBalance";
import {getSMSCreditLimit, RES_PATIENT_SMS} from "../../../actions/personal";
import {ProgressBar} from "primereact/progressbar";
import {
    anyEmailsOrSMSRecalls,
    anySnailMailRecalls,
    getGroupJointEmailsOrSMSRecalls,
    getIndividualEmailsOrSMSRecalls,
    showPatientDetailsPage
} from "../PatientDetails/Utils";
import {CONST_FEMALE, CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import RecallsDueErrorBoundary from "./Utils";
import {contactRecallMethodTemplate, tb_boilerPlate, tb_boilerPlateRight} from "../../Utils";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {DIARY_BACK_MONTH, DIARY_FOR_MONTH} from "../Diary/Constants";

class ConnectedRecallsDue extends RecallReminderComponent {

    constructor(props) {
        super(props);

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            const currentDate = new Date();
            const targetDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

            this.state = {

                stateManagementId: SM_RECALLS_DUE.id,
                canSave: {status: false, activeIndex: 0, source: RES_getRecallsDue.CLEAR},

                selectedDate: targetDate,

                individualRecalls: [],
                groupJointRecalls: [],

                selectedClinician: {id: null},
                selectedIndividualRecall: null,
                selectedGroupJointRecallKey: null,

                selectedRecall: RR_selectedItem,

                firstClinician: 0,
                clinicianRows: 5,

                firstIndividual: 0,
                individualRows: 5,

                firstGroup: 0,
                groupRows: 5,

                [HM_notImplemented.id]: false,
                [HM_RecallsIndividualActionSendElectronic.id]: false,
                [HM_RecallsGJActionSendElectronic.id]: false,
            }
        }
        this.state.selectedRecall.isAppointmentDateShown = false;

        this.cm1 = null;
        this.cm2 = null;
        this.closeItem = null;
        this.sendItem = null;

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getSMSCreditLimit();
            this.props.getAllUsersShort();
            this.props.getRecalls({date: moment(this.state.selectedDate).format(JSON_DATE_FORMAT)});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_RECALLS_DUE:

                    const counts = _.countBy(this.props.recallsDue, (recall) => {
                        return recall.providerId
                    });
                    const activeUsers = _.filter(this.props.usersShort, user => counts[`${user.id}`] !== undefined);

                    this.setState({
                        activeUsers,
                        recallsDue: this.props.recallsDue,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;
                case Actions.RECEIVE_CREDIT_LIMIT:
                default:
                    break;
            }
        }
    }

    onChangeLive = (reminderOrdinal, recallOrdinal, value) => {

        const selectedRecall = {...this.state.selectedRecall};

        switch (recallOrdinal) {
            case RRT_RECALL_HOMEPHONE
                .ordinal:
                selectedRecall.homeTelephone = value;
                break;
            case RRT_RECALL_WORKPHONE.ordinal:
                selectedRecall.workTelephone = value;
                break;
            case RRT_RECALL_OTHERPHONE.ordinal:
                selectedRecall.otherTelephone = value;
                break;
            case RRT_RECALL_MOBILE.ordinal:
                selectedRecall.mobile = value;
                break;
            case RRT_RECALL_EMAIL.ordinal:
                selectedRecall.email = value;
                break;
            default:
                break;
        }
        const index = _.findIndex(this.state.recallsDue, recall => recall.id === selectedRecall.id);
        const recallsDue = [...this.state.recallsDue];
        recallsDue[index] = selectedRecall;

        const clinicianRecalls = _.filter(recallsDue, recall => recall.providerId === this.state.selectedClinician.id);
        const {nonGroupResult, groupResult} = calcAllRecalls(this.state.selectedClinician, clinicianRecalls);

        this.setState({
            recallsDue,
            individualRecalls: nonGroupResult,
            groupJointRecalls: groupResult,
            selectedRecall
        }, () => {
            this.props.updatePatientRecallDetails(this.state.selectedRecall);
            this.props.setState(this.state.stateManagementId, this.state);
        });
    };

    onChangeMethodLive = (reminderOrdinal, recallOrdinal) => {

        const selectedRecall = {...this.state.selectedRecall};

        switch (recallOrdinal) {
            case RRT_RECALL_HOMEPHONE.ordinal:
                selectedRecall.recallMethod = RRT_RECALL_HOMEPHONE.ordinal;
                break;
            case RRT_RECALL_WORKPHONE.ordinal:
                selectedRecall.recallMethod = RRT_RECALL_WORKPHONE.ordinal;
                break;
            case RRT_RECALL_OTHERPHONE.ordinal:
                selectedRecall.recallMethod = RRT_RECALL_OTHERPHONE.ordinal;
                break;
            case RRT_RECALL_MOBILE.ordinal:
                selectedRecall.recallMethod = RRT_RECALL_MOBILE.ordinal;
                break;
            case RRT_RECALL_SMS.ordinal:
                selectedRecall.recallMethod = RRT_RECALL_SMS.ordinal;
                break;
            case RRT_RECALL_EMAIL.ordinal:
                selectedRecall.recallMethod = RRT_RECALL_EMAIL.ordinal;
                break;
            case RRT_RECALL_SNAILMAIL.ordinal:
                selectedRecall.recallMethod = RRT_RECALL_SNAILMAIL.ordinal;
                break;
            default:
                break;
        }
        const index = _.findIndex(this.state.recallsDue, recall => {
            return recall.id === selectedRecall.id;
        });
        const recallsDue = [...this.state.recallsDue];
        recallsDue[index] = selectedRecall;

        const clinicianRecalls = _.filter(recallsDue, recall => recall.providerId === this.state.selectedClinician.id);
        const {nonGroupResult, groupResult} = calcAllRecalls(this.state.selectedClinician, clinicianRecalls);

        this.setState({
            recallsDue,
            individualRecalls: nonGroupResult,
            groupJointRecalls: groupResult,
            selectedRecall
        }, () => {
            this.props.updatePatientRecallDetails(this.state.selectedRecall);
            this.props.setState(this.state.stateManagementId, this.state);
        });
    };

    onCloseClick = () => {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: this.state.stateManagementId});
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {
            case 'selectedDate' :
                state.selectedDate = event.value;
                break;
            case 'back' :
                state.selectedDate = moment(state.selectedDate).add(-1, 'M').toDate()
                break;
            case 'forward' :
                state.selectedDate = moment(state.selectedDate).add(1, 'M').toDate()
                break;
            default:
                break;
        }

        state.first = 0;
        state.firstIndividual = 0;
        state.firstGroup = 0;

        this.setState(state, () => {
            this.props.getRecalls({date: moment(this.state.selectedDate).format(JSON_DATE_FORMAT)});
            this.props.setState(this.state.stateManagementId, this.state);
        });
    }

    onClinicianChange = ({value}) => {

        const clinicianRecalls = _.filter(this.state.recallsDue, recall => recall.providerId === value.id);
        const {nonGroupResult, groupResult} = calcAllRecalls(value.id, clinicianRecalls);

        this.setState({

            firstIndividual: 0,
            individualRows: 5,

            firstGroup: 0,
            groupRows: 5,

            selectedClinician: value,
            individualRecalls: nonGroupResult,
            groupJointRecalls: groupResult
        }, () => {
            this.props.setState(this.state.stateManagementId, this.state);
        });
    }

    closeRecall = () => {

        let remainingRecalls = [];
        let detailList = [];

        if (this.closeItem.id === undefined) { // undefined as joint / groups return a key not the object

            if (isNaN(this.closeItem)) { // if item is a string it is a group / joint key

                remainingRecalls = [...this.state.recallsDue];

                this.state.groupJointRecalls.forEach(group => {

                    if (group.key === this.closeItem) {

                        group.children.forEach(child => {
                            remainingRecalls = _.filter(remainingRecalls, recall => child.data.id !== recall.id);

                            detailList.push({
                                recallId: child.data.id,
                                patientId: child.data.patientId,
                                recallTypeId: child.data.recallTypeId,
                            });
                        });
                    }
                });
            } else { // otherwise it is a recall id.

                remainingRecalls = _.filter(this.state.recallsDue, recall => this.closeItem !== recall.id);

                const closeRecall = _.find(this.state.recallsDue, recall => recall.id === this.closeItem);

                detailList.push({
                    recallId: closeRecall.id,
                    patientId: closeRecall.patientId,
                    recallTypeId: closeRecall.recallTypeId,
                });
            }
        } else {
            remainingRecalls = _.filter(this.state.recallsDue, recall => this.closeItem.id !== recall.id);

            detailList.push({
                recallId: this.closeItem.id,
                patientId: this.closeItem.patientId,
                recallTypeId: this.closeItem.recallTypeId,
            });
        }

        this.setState({recallsDue: remainingRecalls, [HM_RecallsClose.id]: false}, () => {

            detailList.forEach(details => {
                this.props.closeRecall(details);
            });
            this.onClinicianChange({value: this.state.selectedClinician});
        });
    }

    sendIndividualRecalls = () => {

        const {emailSendList, smsSendList} = getIndividualEmailsOrSMSRecalls(this.state.individualRecalls);

        this.props.sendEmailRecall(emailSendList);
        this.props.sendSMSRecall(smsSendList);

        const remainingRecalls = [];

        this.state.recallsDue.forEach(recall => {
            let match = false;
            emailSendList.forEach(email => {
                if (recall.id === email) {
                    match = true;
                }
            });
            smsSendList.forEach(sms => {
                if (recall.id === sms) {
                    match = true;
                }
            });
            if (!match) {
                remainingRecalls.push(recall);
            }
        });

        this.setState({recallsDue: remainingRecalls, [HM_RecallsIndividualActionSendElectronic.id]: false}, () => {
            this.onClinicianChange({value: this.state.selectedClinician});
        });
    }

    sendGroupJointRecalls() {

        const {emailSendList, smsSendList} = getGroupJointEmailsOrSMSRecalls(this.state.groupJointRecalls, false);

        this.props.sendEmailRecall(emailSendList);
        this.props.sendSMSRecall(smsSendList);
    }

    sendRecall = () => {

        let remainingRecalls = [];
        let smsSendList = [];
        let emailSendList = [];

        if (this.sendItem.id === undefined) { // undefined as joint / groups return a key not the object

            if (isNaN(this.sendItem)) { // if item is a string it is a group / joint key

                remainingRecalls = [...this.state.recallsDue];

                this.state.groupJointRecalls.forEach(group => {

                    if (group.key === this.sendItem) {

                        group.children.forEach(child => {
                            remainingRecalls = _.filter(remainingRecalls, recall => child.data.id !== recall.id);

                            switch (child.data.recallMethod) {
                                case RRT_RECALL_EMAIL.ordinal :
                                    emailSendList.push(child.data.id);
                                    break;
                                case RRT_RECALL_SMS.ordinal :
                                    smsSendList.push(child.data.id);
                                    break;
                                default:
                            }
                        });
                    }
                });
            } else { // otherwise it is a recall id.

                remainingRecalls = _.filter(this.state.recallsDue, recall => this.sendItem !== recall.id);

                const closeRecall = _.find(this.state.recallsDue, recall => recall.id === this.sendItem);

                switch (closeRecall.recallMethod) {
                    case RRT_RECALL_EMAIL.ordinal :
                        emailSendList.push(closeRecall.id);
                        break;
                    case RRT_RECALL_SMS.ordinal :
                        smsSendList.push(closeRecall.id);
                        break;
                    default:
                }
            }
        } else {
            remainingRecalls = _.filter(this.state.recallsDue, recall => this.sendItem.id !== recall.id);

            switch (this.sendItem.recallMethod) {
                case RRT_RECALL_EMAIL.ordinal :
                    emailSendList.push(this.sendItem.id);
                    break;
                case RRT_RECALL_SMS.ordinal :
                    smsSendList.push(this.sendItem.id);
                    break;
                default:
            }
        }

        this.setState({recallsDue: remainingRecalls, [HM_RecallsSend.id]: false}, () => {

            if (smsSendList.length > 0)
                this.props.sendSMSRecall(smsSendList);

            if (emailSendList.length > 0)
                this.props.sendEmailRecall(emailSendList);

            this.onClinicianChange({value: this.state.selectedClinician});
        });
    }

    showDialogs() {

        if (this.state[HM_RecallsClose.id]) {
            return ShowQuestionDialog(this, HM_RecallsClose, this.closeRecall)
        } else if (this.state[HM_RecallsSend.id]) {
            return ShowQuestionDialog(this, HM_RecallsSend, this.sendRecall)
        } else if (this.state[HM_RecallsIndividualActionSendElectronic.id]) {
            return ShowQuestionDialog(this, HM_RecallsIndividualActionSendElectronic, this.sendIndividualRecalls)
        } else if (this.state[HM_RecallsGJActionSendElectronic.id]) {
            return ShowQuestionDialog(this, HM_RecallsGJActionSendElectronic, this.sendGroupJointRecalls)
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onShowDetails = ({id, firstName, lastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    buildMenu(individual) {

        let items = [];

        const {selectedRecall} = this.state;

        if (individual) {
            items.push({
                label: HM_RecallsPatSettings.label,
                icon: HM_RecallsPatSettings.icon,
                command: () => {
                    const {patientId, patientFirstName, patientLastName, bg1Id, female, nhsPatient} = this.state.selectedIndividualRecall;

                    const details = {
                        id: patientId,
                        firstName: patientFirstName,
                        lastName: patientLastName,
                        gender: female ? CONST_FEMALE : CONST_MALE,
                        nhsPatient,
                    };
                    this.onShowDetails(details, bg1Id);
                }
            });

            if (selectedRecall.recallMethod === RRT_RECALL_EMAIL.ordinal || selectedRecall.recallMethod === RRT_RECALL_SMS.ordinal) {
                items.push({
                    label: HM_RecallsSend.label,
                    icon: HM_RecallsSend.icon,
                    command: () => {
                        this.sendItem = this.state.selectedIndividualRecall;
                        this.onShowMenuEntry({item: {target: HM_RecallsSend.id}})
                    },
                });
            }

            items.push({
                label: HM_RecallsMakeApp.label,
                icon: HM_RecallsMakeApp.icon,
                command: this.onShowMenuEntry,
                target: HM_notImplemented.id
            });

            items.push({
                label: HM_RecallsClose.label, icon: HM_RecallsClose.icon,
                command: () => {
                    this.closeItem = this.state.selectedIndividualRecall;
                    this.onShowMenuEntry({item: {target: HM_RecallsClose.id}})
                },
            });
        } else {

            if (isNaN(this.state.selectedGroupJointRecallKey)) {

                if (selectedRecall.recallMethod === RRT_RECALL_EMAIL.ordinal || selectedRecall.recallMethod === RRT_RECALL_SMS.ordinal) {
                    items.push({
                        label: HM_RecallsSend.label,
                        icon: HM_RecallsSend.icon,
                        command: () => {
                            this.sendItem = this.state.selectedGroupJointRecallKey;
                            this.onShowMenuEntry({item: {target: HM_RecallsSend.id}})
                        },
                    })
                }

            } else {

                items.push({
                    label: HM_RecallsPatSettings.label,
                    icon: HM_RecallsPatSettings.icon,
                    command: () => {

                        const showRecall = _.find(this.state.recallsDue, recall => recall.id === this.state.selectedGroupJointRecallKey);

                        const {patientId, patientFirstName, patientLastName, bg1Id, female, nhsPatient} = showRecall;

                        const details = {
                            id: patientId,
                            firstName: patientFirstName,
                            lastName: patientLastName,
                            gender: female ? CONST_FEMALE : CONST_MALE,
                            nhsPatient,
                        };
                        this.onShowDetails(details, bg1Id);
                    }
                });

                if (selectedRecall.recallMethod === RRT_RECALL_EMAIL.ordinal || selectedRecall.recallMethod === RRT_RECALL_SMS.ordinal) {
                    items.push({
                        label: HM_RecallsSend.label,
                        icon: HM_RecallsSend.icon,
                        command: () => {
                            this.sendItem = this.state.selectedGroupJointRecallKey;
                            this.onShowMenuEntry({item: {target: HM_RecallsSend.id}})
                        },
                    })
                }

                items.push({
                    label: HM_RecallsMakeApp.label,
                    icon: HM_RecallsMakeApp.icon,
                    command: this.onShowMenuEntry,
                    target: HM_notImplemented.id
                });

                items.push({
                    label: HM_RecallsClose.label, icon: HM_RecallsClose.icon,
                    command: () => {
                        this.closeItem = this.state.selectedGroupJointRecallKey;
                        this.onShowMenuEntry({item: {target: HM_RecallsClose.id}})
                    },
                });
            }
        }
        return items;
    }

    render() {

        if (!this.props.usersLoaded || !this.props.recallsDueLoaded || !this.props.creditLimitLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const electronicIndividuals = anyEmailsOrSMSRecalls(this.state.individualRecalls);
        const snailMailIndividuals = anySnailMailRecalls(this.state.individualRecalls);

        const headerRecallsIndividual = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{ marginRight: 15 }}>{HM_RecallsIndividual.header}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button tooltipOptions={{position: 'right'}}
                    icon={HM_RecallsIndividualActionSendElectronic.icon}
                    label={HM_RecallsIndividualActionSendElectronic.label}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_RecallsIndividualActionSendElectronic.id}});
                    }}
                    disabled={!electronicIndividuals}
                />
                <Button tooltipOptions={{position: 'right'}}
                    icon={HM_RecallsActionPrint.icon}
                    label={HM_RecallsActionPrint.label}
                    onClick={(e) => {
                        this.toolbarCallbacks[HM_notImplemented.id]();
                    }}
                    disabled={!snailMailIndividuals}
                />
            </div>
        </div>

        const electronicGroupJoints = anyEmailsOrSMSRecalls(this.state.groupJointRecalls);
        const snailMailGroupJoints = anySnailMailRecalls(this.state.groupJointRecalls);

        const headerRecallsGroupJoint = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{ marginRight: 15 }}>{HM_RecallsGroupJoint.header}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button tooltipOptions={{position: 'right'}}
                    icon={HM_RecallsGJActionSendElectronic.icon}
                    label={HM_RecallsGJActionSendElectronic.label}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_RecallsGJActionSendElectronic.id}});
                    }}
                    disabled={!electronicGroupJoints}
                />
            <Button tooltipOptions={{position: 'right'}}
                    icon={HM_RecallsActionPrint.icon}
                    label={HM_RecallsActionPrint.label}
                    onClick={(e) => {
                        this.toolbarCallbacks[HM_notImplemented.id]();
                    }}
                    disabled={!snailMailGroupJoints}
            />
            </div>
        </div>;

        const individualItems = this.buildMenu(true);
        const jointGroupItems = this.buildMenu(false);

        const individualRecalls = _.orderBy(this.state.individualRecalls, recall => {
            return recall.date === null ? '' : moment(recall.date).format('YYYYMMDD');
        }, ['asc']);

        let key = 1000;

        return (
            <RecallsDueErrorBoundary>
                <div>
                    {this.showDialogs()}

                    <Toolbar
                            left = {<React.Fragment>
                                {tb_boilerPlate(() => this.onChange({owner: 'back'}), DIARY_BACK_MONTH.icon, DIARY_BACK_MONTH.tooltip, key++)}
                                {tb_boilerPlate(() => this.onChange({owner: 'forward'}), DIARY_FOR_MONTH.icon, DIARY_FOR_MONTH.tooltip, key++)}
                                <Calendar value={this.state.selectedDate}
                                          dateFormat="mm/yy"
                                          view='month'
                                          onChange={(e) => this.onChange({owner: 'selectedDate', value: e.value})}
                                          readOnlyInput={true}/>
                            </React.Fragment>}

                            right={<React.Fragment>
                                {tb_boilerPlateRight((e) => window.open(HELP_RECALLS_DUE), ICON_HELP, 'Knowledge Base', key++, true)}
                                {tb_boilerPlateRight(this.onCloseClick, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                            </React.Fragment>}
                    />

                    <div className="p-grid" style={{fontSize: 'small'}}>
                        <div className='p-col-9'>

                            <Panel header={HM_RecallsClinicians.header}
                                   style={{paddingTop: '5px'}}
                            >

                                <DataTable value={this.state.activeUsers}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           paginator={true}
                                           rows={this.state.clinicianRows}
                                           first={this.state.firstClinician}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => {
                                               this.onPageFlex(e, 'firstClinician', 'clinicianRows');
                                           }}
                                           selectionMode="single"
                                           selection={this.state.selectedClinician}
                                           onSelectionChange={this.onClinicianChange}
                                >

                                    <Column body={row => `${row.firstName} ${row.lastName}`}
                                            header="Name"
                                            style={{width: '50%'}}/>
                                    <Column body={row => `${row.username}`}
                                            header="Username"
                                            style={{width: '50%'}}/>
                                </DataTable>
                            </Panel>

                            <Panel headerTemplate={headerRecallsIndividual} style={{paddingTop: '5px'}}>

                                <ContextMenu style={{width: 250}} model={individualItems} ref={el => this.cm1 = el}/>

                                <DataTable value={individualRecalls}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           paginator={true}
                                           rows={this.state.individualRows}
                                           first={this.state.firstIndividual}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => {
                                               this.onPageFlex(e, 'firstIndividual', 'individualRows');
                                           }}
                                           selectionMode="single"
                                           selection={this.state.selectedIndividualRecall}
                                           onSelectionChange={(e) => {
                                               const row = {...e.value};
                                               row.isAppointmentDateShown = false;
                                               this.setState({selectedIndividualRecall: e.value, selectedRecall: row}, () => {
                                                   this.props.setState(this.state.stateManagementId, this.state);
                                               })
                                           }}
                                           contextMenuSelection={this.state.selectedIndividualRecall}
                                           onContextMenuSelectionChange={e => {

                                               const row = {...e.value};
                                               row.isAppointmentDateShown = false;
                                               this.setState({selectedIndividualRecall: e.value, selectedRecall: row}, () => {
                                                   this.props.setState(this.state.stateManagementId, this.state);
                                               })
                                           }}
                                           onContextMenu={e => {
                                               this.cm2.hide(e.originalEvent);
                                               this.cm1.show(e.originalEvent);
                                           }}
                                >

                                    <Column field="a"
                                            header=""
                                            style={{width: '2%'}}/>
                                    <Column body={row => contactRecallMethodTemplate(row.recallMethod)}
                                            header=""
                                            style={{width: '4%'}}/>
                                    <Column body={row => this.apTypeTemplate(row)}
                                            header=""
                                            style={{width: '4%'}}/>
                                    <Column body={row => dateTemplateShort(row.date)}
                                            header="Date"
                                            style={{width: '12%'}}/>
                                    <Column body={durationTemplate2}
                                            header={<i className="fa fa-hourglass-half"/>}
                                            style={{icon: 'fa fa-asterisk', width: '4%'}}/>
                                    <Column body={row => dateTemplate(row.appointmentDate)}
                                            header="Last Appointment"
                                            style={{width: '12%'}}/>
                                    <Column body={row => this.patientTemplate(row)}
                                            header="Patient"
                                            style={{width: '25%'}}/>
                                    <Column field="comment"
                                            header="Comment"
                                            style={{width: '25%'}}/>
                                    <Column body={row => this.statusTemplate(row)}
                                            header="Status"
                                            style={{width: '6%'}}/>
                                    <Column field="recallCount"
                                            header="Count"
                                            style={{width: '4%'}}/>
                                    <Column field="recallLimit"
                                            header="Limit"
                                            style={{width: '4%'}}/>
                                </DataTable>
                            </Panel>

                            <Panel headerTemplate={headerRecallsGroupJoint} style={{paddingTop: '5px'}}>

                                <ContextMenu style={{width: 250}} model={jointGroupItems} ref={el => this.cm2 = el}/>

                                <TreeTable value={this.state.groupJointRecalls}
                                           paginator={true}
                                           rows={this.state.groupRows}
                                           first={this.state.firstGroup}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => {
                                               this.setState({selectedRecall: RR_selectedItem});
                                               this.onPageFlex(e, 'firstGroup', 'groupRows');
                                           }}
                                           selectionMode="single"
                                           selectionKeys={this.state.selectedGroupJointRecallKey}
                                           onSelectionChange={(e) => {
                                               let target = null;
                                               _.find(this.state.groupJointRecalls, recall => {
                                                   target = _.find(recall.children, child => child.key === e.value);
                                                   return target !== undefined;
                                               });
                                               this.setState({
                                                   selectedGroupJointRecallKey: e.value,
                                                   selectedRecall: target === undefined ? RR_selectedItem : target.data
                                               }, () => {
                                                   this.props.setState(this.state.stateManagementId, this.state);
                                               })
                                           }}
                                           style={{fontSize: 'small'}}
                                           contextMenuSelectionKey={this.state.selectedGroupJointRecallKey}
                                           onContextMenuSelectionChange={e => {
                                               this.setState({
                                                   selectedGroupJointRecallKey: e.value,
                                               }, () => {
                                                   this.props.setState(this.state.stateManagementId, this.state);
                                               })
                                           }}
                                           onContextMenu={e => {
                                               this.cm1.hide(e.originalEvent);
                                               this.cm2.show(e.originalEvent);
                                           }}
                                           className='p-treetable-gridlines'
                                >

                                    <Column columnKey='0' field="a"
                                            header=""
                                            style={{width: '2%'}}
                                            expander
                                    />
                                    <Column columnKey='1' body={row => this.contactRecallMethodTreeTemplate(row)}
                                            header=""
                                            style={{width: '4%'}}/>
                                    <Column columnKey='2' body={row => this.apTypeTreeTemplate(row)}
                                            header=""
                                            style={{width: '4%'}}/>
                                    <Column columnKey='3' body={row => this.dateTreeTemplateShort(row)}
                                            header="Date"
                                            style={{width: '12%'}}/>
                                    <Column columnKey='4' body={row => this.durationTreeTemplate(row)}
                                            header={<i className="fa fa-hourglass-half"/>}
                                            style={{icon: 'fa fa-asterisk', width: '4%'}}/>
                                    <Column columnKey='5' field="lastAppointment"
                                            header="Last Appointment"
                                            style={{width: '12%'}}/>
                                    <Column columnKey='6' body={row => this.patientTreeTemplate(row)}
                                            header="Patient"
                                            style={{width: '25%'}}/>
                                    <Column columnKey='7' field="comment"
                                            header="Comment"
                                            style={{width: '25%'}}/>
                                    <Column columnKey='8' body={row => this.statusTreeTemplate(row)}
                                            header="Status"
                                            style={{width: '6%'}}/>
                                    <Column columnKey='9' body={row => this.countTreeTemplate(row)}
                                            header="Count"
                                            style={{width: '4%'}}/>
                                    <Column columnKey='10' body={row => this.limitTreeTemplate(row)}
                                            header="Limit"
                                            style={{width: '4%'}}/>
                                </TreeTable>
                            </Panel>
                        </div>
                        <div className='p-col-3'>
                            <RecallReminderPatientDetails selectedItem={this.state.selectedRecall}
                                                          contactMethod={this.state.selectedRecall.recallMethod}
                                                          onChange={this.onChangeLive}
                                                          onChangeMethod={this.onChangeMethodLive}
                            />
                            <SMSBalance balance={this.props.creditLimit}/>
                        </div>
                    </div>
                </div>
            </RecallsDueErrorBoundary>
        );
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT_SAVE :
            case TAB_EXIT :
                this.props.stateRequest(this.state.canSave.source);
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = state => {

    const recallsDueLoaded = state.recallsAndReminders.recallsDueLoaded;
    const recallsDue = recallsDueLoaded ? state.recallsAndReminders.recallsDue : [];

    return {

        message: state.stateManagement.message,

        recallsDueLoaded,
        recallsDue,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        creditLimitLoaded: state.patients.creditLimitLoaded,
        creditLimit: state.patients.creditLimit,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_RECALLS_DUE.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getRecalls: (params) => dispatch(getResource(RES_getRecallsDue.GET, params)),
        getSMSCreditLimit: () => dispatch(getSMSCreditLimit(RES_PATIENT_SMS.CREDIT_LIMIT)),

        closeRecall: (details) => dispatch(closeRecall(RES_getRecallsDue.CLOSE, details)),

        sendSMSRecall: (sendList) => dispatch(sendRecall(RES_getRecallsDue.SEND_SMS, sendList)),
        sendEmailRecall: (sendList) => dispatch(sendRecall(RES_getRecallsDue.SEND_EMAIL, sendList)),

        updatePatientRecallDetails: (selectedRecall) => dispatch(updatePatientRecallDetails(RES_PatientRecall.UPDATE, selectedRecall)),

        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const RecallsDue = connect(mapStateToProps, mapDispatchToProps)(ConnectedRecallsDue);

export default RecallsDue;
