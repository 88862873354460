import React from 'react';
import {connect} from 'react-redux';

import {Panel} from 'primereact/components/panel/Panel';
import {Button} from 'primereact/components/button/Button';

import {AssessmentHistory} from '../../../PatientDynamicItems/AssessmentHistory';
import {PatAssMedHistory} from '../../../PatientDynamicItems/PatAssMedHistory';
import {PatAssExtraOral} from '../../../PatientDynamicItems/PatAssExtraOral';
import {PatAssIntraOral} from '../../../PatientDynamicItems/PatAssIntraOral';
import {ToothwearIndex} from '../../../PatientDynamicItems/ToothwearIndex';
import {Mobility} from '../../../PatientDynamicItems/Mobility';
import {BEWE} from '../../../PatientDynamicItems/BEWE';

import {getResource as getCatResource, RES_PAT_CATEGORIES} from '../../../../actions/categories';
import {
    getResource2 as getPatResource,
    RES_PATIENT_ASSESSMENTS,
    RES_PATIENT_ASSESSMENTS_APPOINTMENT
} from "../../../../actions/personal";
import {UPDATE_TAB} from "../../../../actions";
import {
    HM_AddPatientAssessment, HM_DeleteAssessment,
    HM_EditAssessment,
    HM_notImplemented,
    HM_PATIENT_ASSESSMENT_PRINT,
    HM_PATIENT_ASSESSMENT_SHOW
} from "../../../../Constants";
import {ShowMessageDialog} from "../../Diary/components/EventComponent";
import {BaseComponent} from "../../../BaseComponent";
import _ from "lodash";
import {setState} from "../../../../actions/stateManagement";
import * as DefaultData from "../../DefaultData";
import {ac} from "../../../../index";
import {ProgressBar} from "primereact/progressbar";

class ConnectedPatientAssessmentSection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.requesterRef,

                requesterLoadedRef: props.requesterLoadedRef,
                requesterRef: props.requesterRef,

                assessmentHistory: [],
                fakeIndex: -1000,

                readOnly: true,

                [HM_notImplemented.id]: false,
                [HM_EditAssessment.id]: false,
                [HM_DeleteAssessment.id]: false,
            }
        }

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
            [HM_EditAssessment.id]: this.onEditAssessment,
            [HM_DeleteAssessment.id]: this.onDeleteAssessment,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getPatientCategories();
            this.props.getAssessmentHistory(this.props.requester, this.props.requesterLoadedRef, this.props.requesterRef, {patientId: this.props.patientId});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_ASSESSMENTS_APPOINTMENT.GET.receive:
                case RES_PATIENT_ASSESSMENTS.GET.receive:
                    this.setState({assessmentHistory: this.props.patientAssessmentHistory});
                    break;
                case RES_PATIENT_ASSESSMENTS_APPOINTMENT.SAVE.action:
                case RES_PATIENT_ASSESSMENTS.SAVE.action:
                    break;
                default:
                    break;
            }
        }
    }

    onEditAssessment = (e) => {
        this.setState({readOnly: false});
    };

    onDeleteAssessment = (e) => {
        this.setState({readOnly: false});
    };

    onAssessmentChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        state.selectedAssessment.edited = true;

        this.setState(state, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, {...this.state}, this.props.patientDataId);
        })
    }

    onSelectionChanged = (assessment) => {
        this.setState({selectedAssessment: assessment}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state}, this.props.patientDataId);
        })
    }

    onAddAssessment = () => {

        const newAssessment = DefaultData.patientAssessment(ac.getMcId(), this.props.categories);
        newAssessment.id = this.state.fakeIndex;
        newAssessment.patient = {id: this.props.patientId};
        newAssessment.assessedBy = this.props.loginIdentity;

        const assessments = [...this.state.assessmentHistory];
        assessments.unshift(newAssessment);

        this.setState({
            assessmentHistory: assessments,
            selectedAssessment: newAssessment,
            fakeIndex: this.state.fakeIndex + 1
        }, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, {...this.state}, this.props.patientDataId);
        });
    };

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    render() {

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>Patient Assessment</span>
                    </div>
                    <div className="items-margin d-flex d-align-center">
                        <Button icon={HM_PATIENT_ASSESSMENT_SHOW.icon}
                                tooltip={HM_PATIENT_ASSESSMENT_SHOW.label}
                                tooltipOptions={{position: 'top'}}
                                onClick={(e) => {
                                    this.toolbarCallbacks[HM_notImplemented.id]();
                                }}
                        />
                        <Button icon={HM_PATIENT_ASSESSMENT_PRINT.icon}
                                tooltip={HM_PATIENT_ASSESSMENT_PRINT.label}
                                tooltipOptions={{position: 'top'}}
                                onClick={(e) => {
                                    this.toolbarCallbacks[HM_notImplemented.id]();
                                }}
                        />
                    </div>
                </div>;

        if (!this.props.categoriesLoaded || !this.props.patientAssessmentHistoryLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const {readOnly} = this.state;

        return (
            <Panel headerTemplate={header}>

                {this.showDialogs()}

                <div className="p-grid"
                     style={{fontSize: 'small'}}
                >
                    <div className="p-lg-12 p-md-12 p-col-nopad">
                        <AssessmentHistory onSelectionChanged={this.onSelectionChanged}
                                           toolbarCallbacks={this.toolbarCallbacks}
                                           assessment={this.state.selectedAssessment}
                                           list={this.state.assessmentHistory}
                                           assessmentType={HM_AddPatientAssessment}
                                           onAddAssessment={this.onAddAssessment}
                        />
                    </div>
                    <div className="p-lg-12 p-md-12 p-col-nopad">
                        <PatAssMedHistory target='selectedAssessment'
                                          onChange={this.onAssessmentChange}
                                          selectedAssessment={this.state.selectedAssessment}
                                          categories={this.props.categories}
                                          readOnly={readOnly}
                        />
                    </div>
                    <div className="p-l2 p-md-12 p-col-nopad">
                        <PatAssExtraOral target='selectedAssessment'
                                         onChange={this.onAssessmentChange}
                                         selectedAssessment={this.state.selectedAssessment}
                                         categories={this.props.categories}
                                         readOnly={readOnly}
                        />
                    </div>
                    <div className="p-l2 p-md-12 p-col-nopad">
                        <PatAssIntraOral target='selectedAssessment'
                                         onChange={this.onAssessmentChange}
                                         selectedAssessment={this.state.selectedAssessment}
                                         categories={this.props.categories}
                                         readOnly={readOnly}
                        />
                    </div>
                    <div className="p-l2 p-md-12 p-col-nopad">
                        <div>
                            <ToothwearIndex target='selectedAssessment'
                                            onChange={this.onAssessmentChange}
                                            selectedAssessment={this.state.selectedAssessment}
                                            categories={this.props.categories}
                                            readOnly={readOnly}

                            />
                        </div>
                        <div>
                            <Mobility target='selectedAssessment'
                                      onChange={this.onAssessmentChange}
                                      selectedAssessment={this.state.selectedAssessment}
                                      categories={this.props.categories}
                                      readOnly={readOnly}
                            />
                        </div>
                    </div>
                    <div className="p-6 p-md-6 p-col-nopad">
                        <BEWE target='selectedAssessment'
                              onChange={this.onAssessmentChange}
                              selectedAssessment={this.state.selectedAssessment}
                              categories={this.props.categories}
                              readOnly={readOnly}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
}

const MapStateToProps = (state, ownProps) => {

    const {requesterLoadedRef, requesterRef} = ownProps;

    const patientAssessmentHistoryLoaded = state.patients[requesterLoadedRef];
    const patientAssessmentHistory = patientAssessmentHistoryLoaded ? state.patients[requesterRef] : [];

    return {

        message: state.stateManagement.message,

        categoriesLoaded: state.pac.categoriesLoaded,
        categories: state.pac.categories.categories,
        BPEScore: state.pac.categories.BPEScore,
        BEWEScore: state.pac.categories.BEWEScore,

        patientAssessmentHistoryLoaded,
        patientAssessmentHistory,

        loginIdentity: state.login.user,

        currentState: state.stateManagement[requesterRef],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getPatientCategories: () => dispatch(getCatResource(RES_PAT_CATEGORIES.GET)),
        getAssessmentHistory: (getRequest, requesterLoadedRef, requesterRef, patientId) => dispatch(getPatResource(getRequest, requesterLoadedRef, requesterRef, patientId)),

        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const PatientAssessmentSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedPatientAssessmentSection);

export default PatientAssessmentSection;
