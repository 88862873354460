export const ICON_CALC = 'fas fa-calculator';
export const ICON_PLUS = 'fa fa-plus';
export const ICON_RESET = 'fa-solid fa-power-off';
export const ICON_MINUS = 'fa fa-minus';
export const ICON_ZOOM_IN = 'fas fa-search-plus';
export const ICON_ZOOM_OUT = 'fas fa-search-minus';
export const ICON_DOWNLOAD = 'fas fa-download';
export const ICON_UPLOAD = 'fas fa-upload';
export const ICON_LOCK = 'fa-solid fa-lock-keyhole';
export const ICON_EXCEL = 'far fa-file-excel';
export const ICON_SEARCH = 'fas fa-search';
export const ICON_HAND = 'far fa-hand-paper';
export const ICON_BARCODE = 'fas fa-barcode';
export const ICON_INFO = 'fas fa-info';
export const ICON_EDIT = 'fas fa-edit';
export const ICON_GIFT = 'fas fa-gift';
export const ICON_CERTIFICATE = 'fas fa-certificate';
export const ICON_PICKUP = 'fas fa-motorcycle';
export const ICON_VIEW = 'far far-eye';
export const ICON_GOTO = 'fas fa-sign-out-alt';
export const ICON_LOCATION_ARROW = 'far fa-location-arrow';
export const ICON_COPY = 'far fa-copy';
export const ICON_SYNC = 'fa-solid fa-arrow-right-arrow-left';
export const ICON_SETTINGS = 'fas fa-cog';
export const ICON_DELETE = 'fa fa-times';
export const ICON_BAN = 'fas fa-ban';
export const ICON_CANCEL = 'fas fa-ban';
export const ICON_OK = 'fa fa-check';
export const ICON_CONFIRM = 'fas fa-flag';
export const ICON_SEND = 'fas fa-mail-bulk';
export const ICON_CLOSE = 'far fa-times-circle';
export const ICON_SHOW = 'fas fa-file-pdf';
export const ICON_SCREEN = 'fas fa-desktop';
export const ICON_PRINT = 'fas fa-print';
export const ICON_EMAIL = 'fas fa-at';
export const ICON_SMAIL = 'fas fa-envelope';
export const ICON_PHONE = 'fas fa-phone';
export const ICON_MOBILE = 'fas fa-mobile-alt';
export const ICON_RENAME = 'fas fa-long-arrow-alt-right';
export const ICON_DIARY = 'far fa-calendar-alt';
export const ICON_MONEY = 'fas fa-pound-sign';
export const ICON_MOVE = 'fas fa-arrow-circle-right';
export const ICON_CHANGE = 'fas fa-exchange-alt';
export const ICON_NHS = 'fas fa-notes-medical';
export const ICON_NEXT = 'fas fa-chevron-right';
export const ICON_PREV = 'fas fa-chevron-left';
export const ICON_REDO = 'fas fa-redo';
export const ICON_CAMERA = 'fas fa-camera';
export const ICON_XRAY = 'fas fa-x-ray';
export const ICON_PRIVACY_ON = 'fas fa-lock-open';
export const ICON_PRIVACY_OFF = 'fas fa-lock';
export const ICON_ZONING_ON = 'fa-duotone fa-solid fa-grid-horizontal';
export const ICON_ZONING_OFF = 'fa-thin fa-grid-horizontal';
export const ICON_CAPS = 'fas fa-list-ul';
export const ICON_NOTE = 'far fa-sticky-note';
export const ICON_PROBLEM = 'fas fa-exclamation-triangle';
export const ICON_STAR = 'fas fa-star-of-life';
export const ICON_TEMPLATE = 'far fa-file-alt';
export const ICON_HOUSE_KEEPING = 'fas fa-tools';
export const ICON_UTILITIES = 'fas fa-toolbox';
export const ICON_MAIL_SHOTS = 'far fa-paper-plane';
export const ICON_IMPORT = 'fas fa-file-import';
export const ICON_PREFS = 'fas fa-cog';
export const ICON_SORT_ORDER = 'fas fa-sort-amount-up-alt';
export const ICON_USERS = 'fas fa-users';
export const ICON_USER_PLUS = 'fas fa-user-plus';
export const ICON_USER_MINUS = 'fas fa-user-minus';
export const ICON_PATIENTS = 'fas fa-user-friends';
export const ICON_REFERRERS = 'fas fa-people-arrows';
export const ICON_LAB = 'fas fa-wrench';
export const ICON_GROUP = 'fas fa-user-friends';
export const ICON_TABLET = 'fas fa-tablet-alt';
export const ICON_SAVE_ENABLED = 'fas fa-save';
export const ICON_SAVE_DISABLED = 'far fa-save';
export const ICON_SHARE = 'fas fa-share-alt';
export const ICON_RESEND = 'fas fa-share-square';
export const ICON_PIE_CHART = 'fas fa-chart-pie';
export const ICON_LINE_CHART = 'fas fa-chart-bar';
export const ICON_HELP = 'fas fa-question-circle';
export const ICON_SITE_MAP = 'fas fa-sitemap';
export const ICON_SMS = 'fas fa-sms';
export const ICON_TREATMENT_PLAN = 'fas fa-clipboard-list';
export const ICON_PAYMENT_PLAN = 'fas fa-list-ol';
export const ICON_EXCLAMATION = 'fas fa-exclamation';
export const ICON_PAUSE = 'fas fa-pause';

export const SHOW24 = ICON_SCREEN;
export const PRINT24 = ICON_PRINT;

export const ICON_PILL = 'fas fa-pills'
export const ICON_POUND = 'fas fa-pound-sign'

export const ICON_ATTENDED = 'fa-regular fa-square-check'
export const ICON_NOT_ATTENDED = 'fa-regular fa-square'
export const ICON_TRANSFER = 'fa-solid fa-right-left'
