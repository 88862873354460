import React from 'react';
import _ from 'lodash';

import {
    JawUpper,
    LOWER_IMPACTED_OFFSET,
    LOWER_OVER_ERUPTED_OFFSET,
    LOWER_PARTIALLY_ERUPTED_OFFSET,
    LOWER_UNERUPTED_OFFSET, TMApicectomy,
    TOOTH_GAP,
    TOOTH_HEIGHT,
    TOOTH_SIZE,
    UPPER_IMPACTED_OFFSET,
    UPPER_OVER_ERUPTED_OFFSET,
    UPPER_PARTIALLY_ERUPTED_OFFSET,
    UPPER_UNERUPTED_OFFSET,
} from "../Model/Constants";
import {
    LowerCrownPoints1,
    LowerCrownPoints2,
    LowerJacketPoints,
    LowerOnlayPoints1,
    LowerOnlayPoints2,
    LowerPostAndCorePoints, LowerVeneerChippedPoints,
    LowerVeneerPoints,
    PostPoints,
    UpperCrownPoints1,
    UpperCrownPoints2,
    UpperJacketPoints,
    UpperOnlayPoints1,
    UpperOnlayPoints2,
    UpperPostAndCorePoints, UpperVeneerChippedPoints,
    UpperVeneerPoints
} from "../Model/Points";

export const getToothNumber = (data) => {
    return `${data.jaw === JawUpper ? 'U' : 'L'}${data.pos.perm}`
};

export const getToothNumberRaw = (jaw, perm) => {
    return `${jaw === JawUpper ? 'U' : 'L'}${perm}`
};

export const getTranslate = (data, ux, uy, lx, ly, canals) => {

    if (data.jaw === JawUpper) {

        let u2y = uy;
        if (data.unerupted)
            u2y += UPPER_UNERUPTED_OFFSET;
        else if (data.impacted)
            u2y += UPPER_IMPACTED_OFFSET;
        else if (data.partiallyErupted)
            u2y += UPPER_PARTIALLY_ERUPTED_OFFSET;
        else if (data.overErupted)
            u2y += UPPER_OVER_ERUPTED_OFFSET;

        if (canals) {
            switch (data.tm) {
                case TMApicectomy :
                    u2y += 30;
                    break;
                default:
                    break;
            }
        }
        return `translate(${(TOOTH_GAP + TOOTH_SIZE) * data.pos.offset + ux + 5}, ${TOOTH_GAP + u2y})`;
    } else {

        let l2y = ly;
        if (data.unerupted)
            l2y += LOWER_UNERUPTED_OFFSET;
        else if (data.impacted)
            l2y += LOWER_IMPACTED_OFFSET;
        else if (data.partiallyErupted)
            l2y += LOWER_PARTIALLY_ERUPTED_OFFSET;
        else if (data.overErupted)
            l2y += LOWER_OVER_ERUPTED_OFFSET;

        return `translate(${(TOOTH_GAP + TOOTH_SIZE) * data.pos.offset + lx + 5}, ${(TOOTH_HEIGHT + TOOTH_GAP) + TOOTH_GAP + l2y})`;
    }
};

export const equals = (item, target) => {
    return (item === undefined ? false : item.pos.offset === target.pos.offset) && (item.jaw === target.jaw)
};

export const equalsOffset = (item, jaw, offset) => {
    return (item === undefined ? false : item.pos.offset === offset) && (item.jaw === jaw)
};

export const getCondition = (conditions, target) => {

    return _.find(conditions, (condition) => {
        return condition.code === target;
    });
};

export const getMaterial = (materials, target) => {

    return _.find(materials, (material) => {
        return material.code === target;
    });
};

export const getItem = (chartingItems, target) => {

    return _.find(chartingItems, (chartingItem) => {
        return chartingItem.code === target;
    });
};

export const getMaterials = (materials, item) => {

    const matches = [];
    item.possibleMaterials.forEach((itemMaterial) => {

        matches.push(_.find(materials, (material) => {
            return material.id === itemMaterial.id;
        }));
    });
    return matches;
};

export const getItemMaterials = (resources, target) => {

    let chartingItem = getItem(resources.chartingItems, target);
    return getMaterials(resources.materials, chartingItem);
};

export const addRootExtras = (data) => {

    const extras = [];
    const {post, postAndCore, bondedCrown, fullCrown, jacketCrown, veneer, onlay, fractured, chipped} = data;

    if (veneer.present) {

        const mat = veneer.material;

        let points = [];

        if (data.jaw === JawUpper) {
            points = chipped ? UpperVeneerChippedPoints : UpperVeneerPoints;
        } else {
            points = chipped ? LowerVeneerChippedPoints : LowerVeneerPoints;
        }

        const rgb = `rgb(${mat.red},${mat.green},${mat.blue})`;

        extras.push(
            <polygon key={mat.red}
                     points={points}
                     style={{fill: rgb, strokeWidth: '1px', stroke: rgb, strokeOpacity: 1}}
                     transform={getTranslate(data, 1, 0, 1, 57)}
            />
        );
    }

    if (bondedCrown.present) {

        const mat = bondedCrown.material;

        let topPoints = [];
        let rootPoints = [];

        if (data.jaw === JawUpper) {
            topPoints = UpperCrownPoints1;
            rootPoints = UpperCrownPoints2;
        } else {
            topPoints = LowerCrownPoints1;
            rootPoints = LowerCrownPoints2;
        }

        const rgb = `rgb(${mat.red},${mat.green},${mat.blue})`;

        extras.push(
            <polygon key={mat.green}
                     points={topPoints}
                     style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 1, 99, 1, 0)}
            />
        );

        extras.push(
            <polygon key={mat.blue}
                     points={rootPoints}
                     style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 1, 55, 1, 57)}
            />
        );
    }

    if (fullCrown.present) {

        const mat = fullCrown.material;

        let topPoints = [];
        let rootPoints = [];

        if (data.jaw === JawUpper) {
            topPoints = UpperCrownPoints1;
            rootPoints = UpperCrownPoints2;
        } else {
            topPoints = LowerCrownPoints1;
            rootPoints = LowerCrownPoints2;
        }

        const rgb = `rgb(${mat.red},${mat.green},${mat.blue})`;

        extras.push(
            <polygon key={mat.green}
                     points={topPoints}
                     style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 1, 99, 1, 0)}
            />
        );

        extras.push(
            <polygon key={mat.blue}
                     points={rootPoints}
                     style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 1, 55, 1, 57)}
            />
        );
    }

    if (jacketCrown.present) {

        const mat = jacketCrown.material;

        let topPoints = [];
        let rootPoints = [];

        if (data.jaw === JawUpper) {
            topPoints = UpperCrownPoints1;
            rootPoints = UpperJacketPoints;
        } else {
            topPoints = LowerCrownPoints1;
            rootPoints = LowerJacketPoints;
        }

        const rgb = `rgb(${mat.red},${mat.green},${mat.blue})`;

        extras.push(
            <polygon key={mat.green}
                     points={topPoints}
                     style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 1, 99, 1, 0)}
            />
        );

        extras.push(
            <polygon key={mat.blue}
                     points={rootPoints}
                     style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 1, 64, 1, 57)}
            />
        );
    }

    if (onlay.present) {

        const mat = onlay.material;

        let topPoints = [];
        let rootPoints = [];

        if (data.jaw === JawUpper) {
            topPoints = UpperOnlayPoints1;
            rootPoints = UpperOnlayPoints2;
        } else {
            topPoints = LowerOnlayPoints1;
            rootPoints = LowerOnlayPoints2;
        }

        const rgb = `rgb(${mat.red},${mat.green},${mat.blue})`;

        extras.push(
            <polygon key={mat.green}
                     points={topPoints}
                     style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 1, 99, 1, 0)}
            />
        );

        extras.push(
            <polygon key={mat.blue}
                     points={rootPoints}
                     style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 1, 74, 1, 57)}
            />
        );
    }
    if (post.present) {

        extras.push(
            <polygon key={'post'}
                     points={PostPoints}
                     style={{fill: 'black', strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 22, 58, 22, 70)}
            />
        );
    }
    if (postAndCore.present) {

        const mat = postAndCore.material;

        let rootPoints = [];

        if (data.jaw === JawUpper) {
            rootPoints = UpperPostAndCorePoints
        } else {
            rootPoints = LowerPostAndCorePoints;
        }

        const rgb = `rgb(${mat.red},${mat.green},${mat.blue})`;

        extras.push(
            <polygon key={'postAcore'}
                     points={rootPoints}
                     style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                     transform={getTranslate(data, 15, 58, 15, 70)}
            />
        );
    }

    if (fractured) {

        let fracturePoints = [];
        let fractureTranslate = [];

        if (data.jaw === JawUpper) {
            fracturePoints = chipped ? [40,83, 4,58] : [44,88, 4,58];
            fractureTranslate = getTranslate(data,  1,0, 0,0);
        } else {
            fracturePoints = chipped ? [40,83, 6,60] : [44,88, 4,58];
            fractureTranslate = chipped ? getTranslate(data,  0, 0, 5, 8) : getTranslate(data,  0, 0, 1, 3);
        }

        extras.push(
            <polyline key={'fractured'}
                     points={fracturePoints}
                     style={{strokeWidth: '1px', stroke: 'red'}}
                     transform={fractureTranslate}
            />
        );
    }
    return extras;
};

export const addPlaceHolderExtras = (data) => {

    return [];
};

export const _getCommandContextMenuItems = (mouth, target) => {

    const res = mouth.props.resources;

    let chartingItem = getItem(res.chartingItems, target.toString());

    const materials = [];

    getMaterials(res.materials, chartingItem).forEach((material) => {

        materials.push(
            {
                label: material.name, icon: material.name, command: () => {

                    if (mouth.props.baseView) {
                        target.executeBaseOption(mouth, target.target, material);
                    } else {
                        target.executeTreatmentOption(mouth, target.target, material);
                    }
                }
            }
        )
    });

    return [
        ...materials,
        {separator: true},
    ];
};
