import React from "react";

export class EventComponent extends React.Component {

    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }

    rightCLickHandler = (e) => {
        this.props.onShowRightClick(e, this.props.diary, this.props.event);
    }

    componentDidMount() {

        try {
            this.myRef.current.parentNode.parentNode.parentNode.addEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    render() {
        let key = Math.floor(Math.random() * 1000);
        return <React.Fragment>
            <div id={this.props.event.id}
                 style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-around'}}
                 ref={this.myRef}
                 key={`dec1_${key}`}
            >
                <div>{this.props.event.title}</div>
            </div>
            <div style={{textAlign: 'center'}}
                 key={`dec2_${key}`}
            >
                {this.props.event.comment}
            </div>
        </React.Fragment>;
    }

    componentWillUnmount() {

        try {
            this.myRef.current.parentNode.parentNode.parentNode.removeEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
