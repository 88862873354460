import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Calendar} from 'primereact/calendar';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReport, getReportPDF, RES_REPORT_WRITE_OFFS} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {dateTemplateShort, woPatientTemplate, woUserTemplate} from "../../PatientDynamicItems/Utils";
import {HM_AccountGroupSelector, HM_REP_WRITE_OFFS, JSON_DATE_FORMAT, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PRINT, ICON_SCREEN,} from "../../../icons";
import _ from "lodash";
import * as Actions from "../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import AccountGroupSelector from "./dialogs/AccountGroupSelector";
import {
    HELP_REP_WRITE_OFFS,
    setState,
    SM_REPORTS_DAILY_CASH,
    SM_REPORTS_WRITTEN_OFFS,
    SM_REPORTS_WRITTEN_OFFS_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import {TextWriteOffs} from "../../../Messages";
import {TabPanel} from "primereact/components/tabview/TabView";
import AccountWriteOffsPDF from "./PDFs/AccountWriteOffsPDF";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_YEAR, DIARY_FOR_YEAR} from "../Diary/Constants";

const WOS_COLS = ["Write Off Date", "Patient", "Written Off By", "Total"];

export class ConnectedWriteOffs extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                currentDate: new Date(),
                writeOffs: [],
                selectedAccountGroup: null,
                selectedItem: null,
                rows: 5,
                first: 0,

                pdfRequest: PDF_REQUEST_NONE,

                [HM_AccountGroupSelector.id]: true,
            }
        }
        this.cm = null;

        this.onShowReport = this.onShowReport.bind(this);
        this.onPrintReport = this.onPrintReport.bind(this);
        this.onDownload = this.onDownload.bind(this);

        this.showDialogs = this.showDialogs.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onShowDetails = this.onShowDetails.bind(this);
        this.onShowDetails = this.onShowDetails.bind(this);
        this.onSetAccountGroup = this.onSetAccountGroup.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_REPORT_WRITE_OFFS:
                    this.setState({writeOffs: this.props.writeOffs, writeOffsLoaded: true}, () => {
                        this.props.setState(SM_REPORTS_WRITTEN_OFFS.id, this.state);
                    });
                    break;
                case RES_REPORT_WRITE_OFFS.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_WRITTEN_OFFS_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(SM_REPORTS_WRITTEN_OFFS.id, this.state);
        });
    }

    onDateChange(forward) {

        let date = this.state.currentDate;

        date = moment(date).add(forward ? 1 : -1, 'years');
        date = date.toDate();

        this.props.getReport({date: moment(date).format(JSON_DATE_FORMAT), id: this.state.selectedAccountGroup.id});
        this.setState({currentDate: date, pdfBuffer: null, PDFLoaded: false}, () => {
            this.props.setState(SM_REPORTS_DAILY_CASH.id, this.state);
        });
    }

    onSetAccountGroup(accountGroup) {

        this.setState({
            selectedAccountGroup: accountGroup,
            [HM_AccountGroupSelector.id]: false,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.getReport({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT), id: accountGroup.id});
        });
    }

    onShowReport(event) {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const daysDate = this.state.currentDate;
        const formattedDate = moment(daysDate).format(JSON_DATE_FORMAT);

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={SM_REPORTS_WRITTEN_OFFS_PDF.id}
                              header={SM_REPORTS_WRITTEN_OFFS_PDF.label}
                              leftIcon={SM_REPORTS_WRITTEN_OFFS_PDF.tabIcon}>
                        <AccountWriteOffsPDF onCloseClick={onCloseClick}
                                             onTabUpdate={onTabUpdate}
                                             date={formattedDate}
                                             selectedAccountGroup={this.state.selectedAccountGroup}
                                             id={SM_REPORTS_WRITTEN_OFFS_PDF.id}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport(event) {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_WRITTEN_OFFS_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    id: this.state.selectedAccountGroup.id
                });
            })
        }
    }

    onDownload() {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_REPORTS_WRITTEN_OFFS_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {
                this.props.getReportPDF({
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    id: this.state.selectedAccountGroup.id
                });
            })
        }
    }

    onShowDetails({id, firstName, lastName, gender, nhsPatient}, groupId) {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    showDialogs() {

        const contents = [];

        if (this.state[HM_AccountGroupSelector.id]) {
            contents.push(
                <AccountGroupSelector key={HM_AccountGroupSelector.id}
                                      onOkDialog={this.onSetAccountGroup}
                />
            )
        }
        return contents;
    }

    render() {

        if (this.state.selectedAccountGroup !== null && (!this.props.writeOffsLoaded)) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }


        const items = [
            {label: 'Show this patients summary', icon: '', command: this.onShowDetails},
        ];

        const accountGroupName = this.state.selectedAccountGroup === null ? '-' : this.state.selectedAccountGroup.groupName;
        const sortedItems = _.sortBy(this.state.writeOffs, ['id'], ['asc']);
        const header = `${HM_REP_WRITE_OFFS.header}${moment(this.state.currentDate).format('YYYY')} - ${accountGroupName}`;

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate(() => this.onDateChange(false), DIARY_BACK_YEAR.icon, DIARY_BACK_YEAR.tooltip, key++)}
                            {tb_boilerPlate(() => this.onDateChange(true), DIARY_FOR_YEAR.icon, DIARY_FOR_YEAR.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="yy"
                                      view='year'
                                      onChange={(e) => this.onChange({owner: 'currentDate', value: e.value})}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight2(this.onShowReport, ICON_SCREEN, 'Show ' + TextWriteOffs, key++, false)}
                            {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextWriteOffs, key++, false)}
                            {tb_boilerPlateRight2(this.onDownload, ICON_DOWNLOAD, `Download ${TextWriteOffs} Report`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_WRITE_OFFS), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel header={header} style={{paddingTop: '5px'}}>

                        <ContextMenu model={items}
                                     style={{width: '250px'}}
                                     ref={el => this.cm = el}/>

                        <DataTable value={sortedItems}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selectedItem}
                                   onSelectionChange={e => this.onChange({
                                       owner: 'selectedItem',
                                       value: e.value
                                   })}
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={(e) => {
                                       this.onChange({
                                           owner: 'selectedItem',
                                           value: e.value
                                       });
                                   }}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent);
                                   }}
                        >
                            <Column header={WOS_COLS[0]}
                                    body={(row) => dateTemplateShort(row.date)}
                                    style={{width: '10%'}}/>
                            <Column header={WOS_COLS[1]}
                                    body={(row) => woPatientTemplate(row)}
                                    style={{width: '40%'}}/>
                            <Column header={WOS_COLS[2]}
                                    body={(row) => woUserTemplate(row)}
                                    style={{width: '40%', textAlign: 'right'}}/>
                            <Column field="decoratedTotal"
                                    header={WOS_COLS[3]}
                                    style={{width: '10%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_WRITE_OFFS.CLEAR);
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        writeOffsLoaded: state.reports.writeOffsLoaded,
        writeOffs: state.reports.writeOffs,

        PDFLoaded: state.reports.writeOffsPDFLoaded,
        pdfBuffer: state.reports.writeOffsPDF,

        currentState: state.stateManagement[SM_REPORTS_WRITTEN_OFFS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReport(RES_REPORT_WRITE_OFFS.GET, params)),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_WRITE_OFFS.PDF, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const WriteOffs = connect(mapStateToProps, mapDispatchToProps)(ConnectedWriteOffs);

export default WriteOffs;
