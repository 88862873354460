import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {
    HM_createPatDocument,
    PP_STATUS_PROPOSED,
    TP_ACCEPTED,
    TP_PROPOSED
} from "../../../../Constants";
import {
    ICON_CANCEL,
    ICON_OK,
} from "../../../../icons";
import {setState, SM_TreatmentPlan, stateRequest} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import {TB_ADD_PAYMENT_PLAN} from "../../PatientDetails/Constants";
import {getTreatmentPlanIds} from "../../TreatmentPlanning/Utils";
import {
    getResource as getTPResource,
    getResource,
    RES_TREATMENT_PLAN_BY_ID,
    RES_TREATMENT_PLAN_HISTORY
} from "../../../../actions/treatmentPlanning";
import {paymentPlanData} from "../../DefaultData";
import {ac} from "../../../../index";

export class ConnectedAddPaymentPlan extends Component {

    constructor(props) {
        super(props);

        const paymentPlan = _.cloneDeep(paymentPlanData(ac.getMcId()));

        paymentPlan.id = props.fakePaymentPlanId;
        paymentPlan.title = 'New Payment Plan';
        paymentPlan.description = '';
        paymentPlan.status = PP_STATUS_PROPOSED.name;
        paymentPlan.createdBy = {id: props.loginIdentity.id};

        this.state = {
            treatmentPlanHistoryLoaded: false,
            treatmentPlanHistory: [],

            selectedTreatmentPlan: null,
            first: 0,
            rows: 5,

            paymentPlan,
        };

        this.onPage = this.onPage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onTreatmentPlanSelection = this.onTreatmentPlanSelection.bind(this);
    }

    componentDidMount() {
        this.props.getTPHistory(this.props.patientId);
    }

    componentDidUpdate(prevProps, prevState, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_TREATMENT_PLAN_HISTORY.GET.receive:

                    this.setState({
                        treatmentPlanHistory: this.props.treatmentPlanHistory,
                        treatmentPlanHistoryLoaded: this.props.treatmentPlanHistoryLoaded
                    });
                    break;

                case RES_TREATMENT_PLAN_BY_ID.GET.receive:

                    const paymentPlan = {...this.state.paymentPlan};

                    const {treatmentPlan} = this.props;

                    let planTotalCost = 0.0;
                    let discount = null;

                    treatmentPlan.unassignedCharges.forEach(charge => {
                        if (charge.item.discountCharge) {
                            discount = charge.chargedPrice;
                        } else {
                            planTotalCost = planTotalCost + (charge.chargedPrice * charge.quantity);
                        }
                    });

                    if (discount !== null) {
                        planTotalCost = planTotalCost - (planTotalCost * discount / 100.0);
                    }

                    paymentPlan.treatmentPlan = {id: treatmentPlan.id};
                    paymentPlan.patient = {id: treatmentPlan.patient.id};
                    paymentPlan.arrangedBy = {id: this.props.loginIdentity.id};
                    paymentPlan.planTotal = planTotalCost;
                    paymentPlan.totalPayment = planTotalCost;
                    paymentPlan.chargeableItem = null;
                    paymentPlan.displayedNoOfInstallments = paymentPlan.numberOfinstallments === 1 ? 1 : paymentPlan.numberOfinstallments - 1;
                    paymentPlan.totalPaymentMinusDeposit = paymentPlan.planTotal - paymentPlan.deposit;

                    this.setState({
                        treatmentPlanLoaded: this.props.treatmentPlanLoaded,
                        treatmentPlan: this.props.treatmentPlan,
                        paymentPlan,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onTreatmentPlanSelection(event) {

        this.setState({selectedTreatmentPlan: event.value}, () => {

            this.props.getTreatmentPlanById(event.value.id);
        });
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows})
    }

    render() {

        if (!this.state.treatmentPlanHistoryLoaded) {
            return null;
        }

        const planNotDefined = this.state.selectedTreatmentPlan === null;

        const plans = _.filter(this.state.treatmentPlanHistory.plans, plan => {
            return (plan.status === TP_ACCEPTED.name || plan.status === TP_PROPOSED.name);
        });

        const sortedPlans = _.orderBy(plans, 'id', 'desc');

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.paymentPlan);
                        }}
                        disabled={planNotDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(TB_ADD_PAYMENT_PLAN.id)
                        }}/>
            </div>
        );

        return (
            <Dialog header={TB_ADD_PAYMENT_PLAN.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_createPatDocument.id)
                    }}
            >
                <div className="p-grid p-fluid">
                    <DataTable value={sortedPlans}
                               header={`Treatment Plans`}
                               editable={true}
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selectionMode="single"
                               selection={this.state.selectedTreatmentPlan}
                               onSelectionChange={this.onTreatmentPlanSelection}
                    >
                        <Column body={row => dateTemplateShort(row.created)} header="Created On"
                                style={{width: '20%', textAlign: 'right'}}/>
                        <Column field="title" header="Title" style={{width: '25%'}}/>
                        <Column field="description" header="Description" style={{width: '45%'}}/>
                        <Column field="status" header="Status" style={{width: '10%'}}/>
                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        treatmentPlanHistoryLoaded,
        treatmentPlanHistoryId,
        treatmentPlanHistory,
    } = getTreatmentPlanIds(state, ownProps);

    const paymentPlanAddtionTPId = Boolean(state.treatmentPlanning.paymentPlanAddtionTPId) ? state.treatmentPlanning.paymentPlanAddtionTPId : null;
    const treatmentPlanLoadedId = Boolean(state.treatmentPlanning.paymentPlanAddtionTPId) ? `${SM_TreatmentPlan.loaded}_${paymentPlanAddtionTPId}` : null;
    const treatmentPlanId = Boolean(state.treatmentPlanning.paymentPlanAddtionTPId) ? `${SM_TreatmentPlan.id}_${paymentPlanAddtionTPId}` : null;

    const treatmentPlanLoaded = Boolean(state.treatmentPlanning[treatmentPlanLoadedId]) ? state.treatmentPlanning[treatmentPlanLoadedId] : false;
    const treatmentPlan = treatmentPlanLoaded ? state.treatmentPlanning[treatmentPlanId] : null;

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        treatmentPlanHistoryLoaded,
        treatmentPlanHistoryId,
        treatmentPlanHistory,

        treatmentPlanLoaded,
        treatmentPlan,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTPHistory: (patientId) => dispatch(getResource(RES_TREATMENT_PLAN_HISTORY.GET, patientId)),
        getTreatmentPlanById: (treatmentPlanId) => dispatch(getTPResource(RES_TREATMENT_PLAN_BY_ID.GET, treatmentPlanId, null)),

        stateRequest: (source, treatmentPlan) => dispatch(stateRequest(source, treatmentPlan)),

        setState: (id, data, parent) => dispatch(setState(id, data, parent)),
    }
};

const AddPaymentPlan = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddPaymentPlan);

export default AddPaymentPlan;
