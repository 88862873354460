import React from 'react';
import {BaseComponent} from "../../../BaseComponent";
import {showPatientDetailsPage} from "../../PatientDetails/Utils";
import _ from "lodash";
import {ShowQuestionDialog} from "../../Diary/components/EventComponent";
import {
    HM_VIEW_WORK_REQUIRED,
    HM_WORK_REQUIRED_APPROVED,
    HM_WORK_REQUIRED_CANCELLED,
    WORK_STATUS_APPROVED
} from "../../../../Constants";
import {ReceiveWorkRequired} from "../../PatientDetails/dialogs/ReceiveWorkRequired";
import {SM_WORK_RECEIVED_FOR_APPROVAL, SM_WORK_RECEIVED_REPORT} from "../../../../actions/stateManagement";
import {buildWRMenu} from "../../../Utils";
import AddWorkRequired from "../../PatientDetails/dialogs/AddWorkRequired";

export class WorkReceivedBase extends BaseComponent {

    constructor(props) {

        super(props);

        this.items = [];

        this.cm = null;
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(SM_WORK_RECEIVED_REPORT.id, this.state);
        });
    }

    onCancelRequest = () => {

    }

    showDialogs = () => {

        if (this.state[HM_VIEW_WORK_REQUIRED.id]) {

            return (
                <AddWorkRequired target={HM_VIEW_WORK_REQUIRED}
                                 appointmentId={this.state.labEntry.appointmentId}
                                 patientId={this.state.labEntry.patientId}
                                 onOKDialog={() => this.onHideMenuEntry(HM_VIEW_WORK_REQUIRED.id)}
                                 onHideDialog={() => this.onHideMenuEntry(HM_VIEW_WORK_REQUIRED.id)}
                                 labEntry={this.state.labEntry}
                                 view={true}
                                 usersShort={this.props.usersShort}
                />
            )
        } else if (this.state[HM_WORK_REQUIRED_APPROVED.id]) {

            return (
                <ReceiveWorkRequired
                    labEntry={this.state.labEntry}
                    onChange={this.onChange}
                    users={this.props.usersShort}
                    loginIdentity={this.props.loginIdentity}
                    markWork={this.markWorkApproved}
                    onHideDialog={this.onHideMenuEntry}
                    target={HM_WORK_REQUIRED_APPROVED}
                />
            )
        } else if (this.state[HM_WORK_REQUIRED_APPROVED.id]) {

            return ShowQuestionDialog(this, HM_WORK_REQUIRED_CANCELLED, this.onCancelRequest);
        }
    }

    onShowDetails = (item) => {

        const {patientId, firstName, lastName, gender, nhsPatient, groupId} = item;
        showPatientDetailsPage({props: this.props, id: patientId, firstName, lastName, gender, nhsPatient, groupId});
    }

    markWorkApproved = (labEntry) => {

        const _labEntry = _.cloneDeep(labEntry);
        _labEntry.status = WORK_STATUS_APPROVED.name;

        const labWorkApproval = [...this.state.labWorkApproval];
        const index = _.findIndex(labWorkApproval, entry => entry.id === _labEntry.id);

        if (index >= 0) {
            labWorkApproval[index] = _labEntry;

            const approvedBy = _.find(this.props.usersShort, user => user.id === _labEntry.approvedById);
            _labEntry.approvedBy = approvedBy.fullName;
        }
        this.setState({labWorkApproval, labEntry: null, [HM_WORK_REQUIRED_APPROVED.id]: false}, () => {
            this.props.markWorkApproved(_labEntry);
            this.props.setState(SM_WORK_RECEIVED_FOR_APPROVAL.id, this.state);
        })
    }

    buildMenu = () => {

        this.items = buildWRMenu({
            selectedLabEntry: this.state.selectedLabEntry,
            toolbarCallbacks: this.toolbarCallbacks,
            showDetails: true,
            onShowDetails: this.onShowDetails
        });
    }
}
