import React, {useEffect, useRef} from "react";

const ZoneEvent = props => {

    const divRef = useRef(null);

    // Handler for the context menu event (right-click)
    const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent the default context menu from appearing
        props.onContextMenu(event, parseInt(divRef.current.id, 10));
        // You can customize this alert or display a custom context menu as needed
    };

    // Use the useEffect hook to add the event listener when the component mounts
    useEffect(() => {
        const currentDiv = divRef.current;

        if (currentDiv) {
            // Add the context menu event listener
            currentDiv.parentNode.parentNode.parentNode.addEventListener('contextmenu', handleContextMenu);
        } else {
            console.log(`currentDiv.addEventListener => ${divRef}`)
        }

        // Cleanup function to remove the listener when the component unmounts
        return () => {
            if (currentDiv) {
                currentDiv.parentNode.parentNode.parentNode.removeEventListener('contextmenu', handleContextMenu);
            } else {
                console.log(`currentDiv.removeEventListener => ${divRef}`)
            }
        };
    }, []); // Empty dependency array ensures this runs only once on mount/unmount

    return (<React.Fragment>
            <div id={props.event.event.id}
                 style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-around'}}
                 ref={divRef}
            >
                <div>{props.event.title}</div>
            </div>
            <div style={{textAlign: 'center'}}>{props.event.comment}</div>
        </React.Fragment>
    );
}

export default ZoneEvent;