import React from "react";
import {__CHIRAL_CONTACT_DETAILS__} from "../../../Constants";

export default class TemplateNoteErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>Something went wrong in the Template Note section.</h1>
                    <h2>{__CHIRAL_CONTACT_DETAILS__}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
