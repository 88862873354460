import React from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';
import 'nanoscroller';

import 'primereact/resources/primereact.min.css';
import 'nanoscroller/bin/css/nanoscroller.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import {ClientAppTopbar} from "./ClientAppTopbar";
import {ScrollPanel} from "primereact/scrollpanel";
import {AppMenu} from "../../AppMenu";
import Dashboard from "./Dashboard";
import {
    HM_ABOUT_CHIRAL_CLIENT,
    HM_COMPLETE_GDPR,
    HM_COMPLETE_OTHERS,
    HM_HELP_CHIRAL_CLIENT,
    HM_LOGOUT,
    OUTCOME_ConsentGiven,
    OUTCOME_ConsentNotGiven
} from "../../Constants";
import {Dialog} from "primereact/dialog";
import {Card} from "primereact/card";
import packageJson from "../../package";
import {BaseComponent} from "../BaseComponent";
import {ProgressBar} from "primereact/progressbar";
import * as Actions from "../../actions";
import {
    SE_ADD_MEDICAL_HISTORY,
    SE_ADD_PERSONAL_DETAILS,
    SE_ADD_QUESTIONNAIRE,
    SE_APPOINTMENT_HISTORY,
    SE_DOCUMENTS,
    SE_NONE,
    SE_PAYMENT_PLANNING,
    SE_PRESCRIPTION_HISTORY,
    SE_SIGN_CONSENT,
    SE_SIGN_GDPR_CONSENT,
    SE_SIGN_GDPR_CONSENT_RETRY,
    SE_TREATMENT_PLANNING,
    SE_UPLOAD_XRAY_IMAGE,
    SM_CLIENT_APPOINTMENT_HISTORY,
    SM_CLIENT_APPOINTMENTS,
    SM_CLIENT_DOCUMENTS,
    SM_CLIENT_PATIENT_DETAIL,
    SM_CLIENT_PATIENT_DETAILS,
    SM_CLIENT_PAYMENT_PLANNING,
    SM_CLIENT_PAYMENT_PLANNING_CONSENT,
    SM_CLIENT_PAYMENT_PLANS,
    SM_CLIENT_PRESCRIPTION_HISTORY,
    SM_CLIENT_PRESCRIPTIONS,
    SM_CLIENT_TREATMENT_PLANNING,
    SM_CLIENT_TREATMENT_PLANNING_CONSENT,
    SM_CLIENT_TREATMENT_PLANS,
    SM_CLIENT_UPLOAD_IMAGE,
} from "./Constants";
import {getResource, RES_PATIENT_DETAILS, RES_PATIENT_MHFS, RES_PATIENT_QUESTIONNAIRE} from "../../actions/personal";
import {CLIENT_KNOWLEDGE_BASE, setState, SM_PATIENT_PORTAL_DATA} from "../../actions/stateManagement";
import {doLogout} from "../../actions/login";
import ClientWebsocketHandler from "../ClientWebsocketHandler";
import ClientUpdatesWebsocketHandler from "../ClientUpdatesWebsocketHandler";
import {ac} from "../../index";
import {RES_HOUSEKEEPING_ADETS} from "../../actions/housekeeping";
import {TAB_MedicalHistoryUpdate, TAB_PatientConsentForms, TAB_Questionnaire} from "../Tablet/Constants";
import {ShowMessageDialog} from "../FixedItems/Diary/components/EventComponent";
import {RES_TAB_PATIENT_CONSENTS} from "../../actions/tablet";

class ConnectedClientLoggedIn extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            ac.setClientView(true);

            this.state = {

                outcome: OUTCOME_ConsentNotGiven,
                command: SE_NONE,
                patientDataLoaded: false,

                medicalHistory: false,
                consentForms: false,
                questionnaire: false,

                layoutMode: 'static',
                profileMode: 'inline',
                layoutCompact: true,
                overlayMenuActive: false,
                staticMenuDesktopInactive: false,
                staticMenuMobileActive: false,
                rotateMenuButton: false,
                topbarMenuActive: false,
                activeTopbarItem: null,
                darkMenu: false,
                menuActive: false,
                theme: 'blue',
                layout: 'blue',
                version: 'v3',
            }
        }
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.showDialogs = this.showDialogs.bind(this);

        this.menu = [];
    }


    componentDidMount() {

        if (!this.state.patientDataLoaded) {
            this.props.getPatientDetails({id: this.props.patientId});
            this.props.getAppointmentDiaryTypes();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.message === undefined || prevProps.message === undefined) return;

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_PORTAL_DATA:

                    let consentForms = false;
                    let medicalHistory = false;
                    let questionnaire = false;

                    this.props.patientData.members.forEach(member => {
                        switch (member) {
                            case TAB_MedicalHistoryUpdate:
                                medicalHistory = true;
                                break;
                            case TAB_PatientConsentForms:
                                consentForms = true;
                                break;
                            case TAB_Questionnaire:
                                questionnaire = true;
                                break;
                            default:
                                break;
                        }
                    })

                    this.setState({
                        patientDataLoaded: true,
                        patientData: this.props.patientData,
                        medicalHistory,
                        consentForms,
                        questionnaire
                    }, () => {
                        this.props.setState(this.props.patientDataId, {...this.state});
                        this.createMenu();
                    });
                    break;
                case RES_PATIENT_MHFS.SAVE.action:
                    this.setState({medicalHistory: false})
                    break;
                case RES_TAB_PATIENT_CONSENTS.SAVE.receive:
                    this.setState({consentForms: false})
                    break;
                case RES_PATIENT_QUESTIONNAIRE.SAVE.action:
                    this.setState({questionnaire: false})
                    break;

                default:
                    break;
            }
        }
    }

    onMenuClick(event) {
        this.menuClick = true;

        if (!this.isHorizontal()) {
            setTimeout(() => {
                this.layoutMenuScroller.moveBar();
            }, 500);
        }
    }

    onMenuButtonClick(event) {

        if (!this.state.patientData.portalLoginGDPRRequired) {

            this.menuClick = true;
            this.setState(({
                rotateMenuButton: !this.state.rotateMenuButton,
                topbarMenuActive: false
            }));

            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            } else {
                if (this.isDesktop())
                    this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
                else
                    this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});
            }
        }
        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        switch (event.item) {
            case HM_HELP_CHIRAL_CLIENT.id :
                try {
                    window.open(CLIENT_KNOWLEDGE_BASE);
                } catch (e) {
                    alert(e);
                }

                break;
            case HM_ABOUT_CHIRAL_CLIENT.id :
                this.onShowMenuEntry({item: {target: HM_ABOUT_CHIRAL_CLIENT.id}});
                break;
            case HM_LOGOUT.id :
                this.props.doLogout();
                window.location.hash = `/Client/${this.props.patientData.portalReference}`
                break;
            default:
        }

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });

        event.originalEvent.preventDefault();
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        if (!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeTheme(theme) {
        this.setState({theme: theme});
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('theme-css', theme, 'theme');
        } else {
            this.changeStyleSheetUrl('theme-css', theme + '-v4', 'theme');
        }
    }

    changeLayout(layout, special) {
        this.setState({layout: layout});
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('layout-css', layout, 'layout');
        } else {
            this.changeStyleSheetUrl('layout-css', layout + '-v4', 'layout');
        }

        if (special) {
            this.setState({
                darkMenu: true
            })
        }
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);
    }

    menuCommand = (command) => {

        if (this.state.patientData.portalLoginGDPRRequired || this.state.consentForms || this.state.medicalHistory || this.state.questionnaire) {

            if (this.state.patientData.portalLoginGDPRRequired) {
                this.setState({command: SE_NONE, [HM_COMPLETE_GDPR.id]: true});
            } else {

                if (command === SE_ADD_MEDICAL_HISTORY || command === SE_ADD_QUESTIONNAIRE || command === SE_SIGN_CONSENT || command === SE_NONE) {
                    this.setState({command});
                } else {
                    this.setState({command: SE_NONE, [HM_COMPLETE_OTHERS.id]: true});
                }
            }
        } else {
            this.setState({command});
        }
    }

    createMenu() {

        this.menu = [];

        if (this.state.patientData.patientDetails) {

            const patientDetailsMenu = {
                label: SM_CLIENT_PATIENT_DETAILS.label, icon: SM_CLIENT_PATIENT_DETAILS.icon,
                items: []
            }

            patientDetailsMenu.items.push(
                {
                    label: SM_CLIENT_PATIENT_DETAIL.label, icon: SM_CLIENT_PATIENT_DETAIL.icon,
                    command: () => {
                        this.menuCommand(SE_ADD_PERSONAL_DETAILS);
                    }
                })

            if (this.state.patientData.imageUpload) {
                patientDetailsMenu.items.push(
                    {
                        label: SM_CLIENT_UPLOAD_IMAGE.label, icon: SM_CLIENT_UPLOAD_IMAGE.icon,
                        command: () => {
                            this.menuCommand(SE_UPLOAD_XRAY_IMAGE);
                        }
                    })
            }

            this.menu.push(
                patientDetailsMenu
            );
        }

        if (this.state.patientData.appointmentHistory) {

            this.menu.push(
                {
                    label: SM_CLIENT_APPOINTMENTS.label, icon: SM_CLIENT_APPOINTMENTS.icon,
                    items: [
                        {
                            label: SM_CLIENT_APPOINTMENT_HISTORY.label, icon: SM_CLIENT_APPOINTMENT_HISTORY.icon,
                            command: () => {
                                this.menuCommand(SE_APPOINTMENT_HISTORY);
                            }
                        },
                    ]
                })
        }

        if (this.state.patientData.documentHistory) {

            this.menu.push(
                {
                    label: SM_CLIENT_DOCUMENTS.label, icon: SM_CLIENT_DOCUMENTS.icon,
                    items: [
                        {
                            label: SM_CLIENT_DOCUMENTS.label, icon: SM_CLIENT_DOCUMENTS.icon,
                            command: () => {
                                this.menuCommand(SE_DOCUMENTS);
                            }
                        },
                    ]
                })
        }

        if (this.state.patientData.prescriptionHistory) {

            this.menu.push(
                {
                    label: SM_CLIENT_PRESCRIPTIONS.label, icon: SM_CLIENT_PRESCRIPTIONS.icon,
                    items: [
                        {
                            label: SM_CLIENT_PRESCRIPTION_HISTORY.label, icon: SM_CLIENT_PRESCRIPTION_HISTORY.icon,
                            command: () => {
                                this.menuCommand(SE_PRESCRIPTION_HISTORY);
                            }
                        },
                    ]
                })
        }

        if (this.state.patientData.treatmentPlans) {

            this.menu.push(
                {
                    label: SM_CLIENT_TREATMENT_PLANS.label, icon: SM_CLIENT_TREATMENT_PLANS.icon,
                    items: [
                        {
                            label: SM_CLIENT_TREATMENT_PLANNING.label, icon: SM_CLIENT_TREATMENT_PLANNING.icon,
                            command: () => {
                                this.menuCommand(SE_TREATMENT_PLANNING);
                            }
                        },
                        {
                            label: SM_CLIENT_TREATMENT_PLANNING_CONSENT.label,
                            icon: SM_CLIENT_TREATMENT_PLANNING_CONSENT.icon,
                            command: () => {
                                // this.menuCommand(SE_TREATMENT_PLANNING_CONSENT);
                            }
                        }
                    ]
                })
        }

        if (this.state.patientData.paymentPlans) {

            this.menu.push(
                {
                    label: SM_CLIENT_PAYMENT_PLANS.label, icon: SM_CLIENT_PAYMENT_PLANS.icon,
                    items: [
                        {
                            label: SM_CLIENT_PAYMENT_PLANNING.label, icon: SM_CLIENT_PAYMENT_PLANNING.icon,
                            command: () => {
                                this.menuCommand(SE_PAYMENT_PLANNING);
                            }
                        },
                        {
                            label: SM_CLIENT_PAYMENT_PLANNING_CONSENT.label,
                            icon: SM_CLIENT_PAYMENT_PLANNING_CONSENT.icon,
                            command: () => {
                                // this.menuCommand(SE_PAYMENT_PLANNING_CONSENT);
                            }
                        }
                    ]
                })
        }
    }

    showDialogs() {

        const content = [];

        if (this.state[HM_ABOUT_CHIRAL_CLIENT.id]) {

            const header = (
                <img alt="Card" src='/src/images/clientLogin2.jpg'/>
            );

            content.push(
                <Dialog header='About'
                        onHide={() => this.onHideMenuEntry(HM_ABOUT_CHIRAL_CLIENT.id)}
                        visible={true}
                >
                    <Card title={HM_ABOUT_CHIRAL_CLIENT.header}
                          className='ui-card-shadow'
                          header={header}
                    >
                        <div>
                            Chiral Systems Ltd <i className="far fa-copyright"/> 2020
                            v{packageJson.version}
                            <hr/>
                            {HM_ABOUT_CHIRAL_CLIENT.message}
                        </div>
                    </Card>
                </Dialog>
            )
        } else {
            content.push(ShowMessageDialog(this, HM_COMPLETE_GDPR));
            content.push(ShowMessageDialog(this, HM_COMPLETE_OTHERS));
        }
        return content;
    }

    consentControlCallBack = (outcome) => {

        const patientData = {...this.state.patientData};
        patientData.portalLoginGDPRRequired = false;
        patientData.outcome = outcome;

        this.setState({patientData, [HM_COMPLETE_GDPR.id]: false});
    }

    updateNextOnlineAppointment = (start, end) => {

        const patientData = {...this.state.patientData};
        patientData.nextAppointmentStart = start;
        patientData.nextAppointmentEnd = end;
        this.setState({patientData});
    }

    updateNextVCAppointment = (start, end) => {

        const patientData = {...this.state.patientData};
        patientData.nextVCAppointmentStart = start;
        patientData.nextVCAppointmentEnd = end;
        this.setState({patientData});
    }

    showGDPRORNot = () => {

        if (this.state.patientData.portalLoginGDPRRequired || this.state.command === SE_SIGN_GDPR_CONSENT) {

            return (
                <div className="layout-main">
                    <Dashboard command={SE_SIGN_GDPR_CONSENT}
                               menuCommand={this.menuCommand}
                               patientData={this.state.patientData}
                               loginIdentity={{id: null}}
                               consentControlCallBack={this.consentControlCallBack}
                               date={new Date()}
                               appointmentTypes={this.props.appointmentTypes}
                               diaryEventTypes={this.props.diaryEventTypes}
                               updateNextOnlineAppointment={this.updateNextOnlineAppointment}
                               updateNextVCAppointment={this.updateNextVCAppointment}
                               consentForms={false}
                               medicalHistory={false}
                               questionnaire={false}
                    />
                </div>
            )
        } else {

            if (this.state.patientData.outcome === OUTCOME_ConsentGiven) {

                if (this.state.consentForms || this.state.medicalHistory || this.state.questionnaire) {
                    return (
                        <div className="layout-main">
                            <Dashboard command={this.state.command}
                                       menuCommand={this.menuCommand}
                                       patientData={this.state.patientData}
                                       loginIdentity={{id: null}}
                                       consentControlCallBack={this.consentControlCallBack}
                                       date={new Date()}
                                       appointmentTypes={this.props.appointmentTypes}
                                       diaryEventTypes={this.props.diaryEventTypes}
                                       updateNextOnlineAppointment={this.updateNextOnlineAppointment}
                                       updateNextVCAppointment={this.updateNextVCAppointment}
                                       consentForms={this.state.consentForms}
                                       medicalHistory={this.state.medicalHistory}
                                       questionnaire={this.state.questionnaire}
                            />
                        </div>
                    )
                } else {
                    return (
                        <div className="layout-main">
                            <Dashboard command={this.state.command}
                                       menuCommand={this.menuCommand}
                                       patientData={this.state.patientData}
                                       loginIdentity={{id: null}}
                                       consentControlCallBack={this.consentControlCallBack}
                                       date={new Date()}
                                       appointmentTypes={this.props.appointmentTypes}
                                       diaryEventTypes={this.props.diaryEventTypes}
                                       updateNextOnlineAppointment={this.updateNextOnlineAppointment}
                                       updateNextVCAppointment={this.updateNextVCAppointment}
                                       consentForms={false}
                                       medicalHistory={false}
                                       questionnaire={false}
                            />
                        </div>
                    )
                }
            } else {
                return (
                    <div className="layout-main">
                        <Dashboard command={SE_SIGN_GDPR_CONSENT_RETRY}
                                   menuCommand={this.menuCommand}
                                   patientData={this.state.patientData}
                                   loginIdentity={{id: null}}
                                   consentControlCallBack={this.consentControlCallBack}
                                   date={new Date()}
                                   appointmentTypes={this.props.appointmentTypes}
                                   diaryEventTypes={this.props.diaryEventTypes}
                                   updateNextOnlineAppointment={this.updateNextOnlineAppointment}
                                   updateNextVCAppointment={this.updateNextVCAppointment}
                                   consentForms={false}
                                   medicalHistory={false}
                                   questionnaire={false}
                        />
                    </div>
                )
            }
        }
    }

    render() {

        if (!this.state.patientDataLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

      

        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu-container', {'layout-menu-dark': this.state.darkMenu});

        return <div className={layoutClassName} style={{background: 'aliceblue'}}>

            {this.showDialogs()}

            <div>

                <ClientUpdatesWebsocketHandler/>
                <ClientWebsocketHandler/>

                <ClientAppTopbar profileMode={this.state.profileMode} horizontal={this.props.horizontal}
                                 topbarMenuActive={this.state.topbarMenuActive}
                                 activeTopbarItem={this.state.activeTopbarItem}
                                 onMenuButtonClick={this.onMenuButtonClick}
                                 onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                                 onTopbarItemClick={this.onTopbarItemClick}
                                 patientData={this.state.patientData}
                />

                <div className={menuClassName} onClick={this.onMenuClick}>
                    <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{height: '100%'}} className='custom'>
                        <div className="menu-scroll-content">
                            <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick}
                                     onRootMenuItemClick={this.onRootMenuItemClick}
                                     layoutMode={this.state.layoutMode} active={this.state.menuActive}/>
                        </div>
                    </ScrollPanel>
                </div>

                {this.showGDPRORNot()}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {

    const patientDataLoadedId = `${SM_PATIENT_PORTAL_DATA.loaded}`;
    const patientDataId = `${SM_PATIENT_PORTAL_DATA.id}`;

    const appointmentDiaryTypesLoaded = Boolean(state.housekeeping.appointmentDiaryTypesLoaded);
    const appointmentTypes = appointmentDiaryTypesLoaded ? state.housekeeping.appointmentDiaryTypes.appointmentTypes : [];
    const diaryEventTypes = appointmentDiaryTypesLoaded ? state.housekeeping.appointmentDiaryTypes.diaryEventTypes : [];

    return {

        message: state.stateManagement.message,

        patientId: state.login.patientId,
        patientDataId,

        patientDataLoaded: state.patients[patientDataLoadedId],
        patientData: state.patients[patientDataId],

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,

        loggedIn: state.login.patientLoggedIn,

        currentState: state.stateManagement[patientDataId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPatientDetails: (params) => dispatch(getResource(RES_PATIENT_DETAILS.GET_PORTAL_DATA, params)),
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),

        setState: (id, data) => dispatch(setState(id, data)),
        doLogout: () => dispatch(doLogout(Actions.CLIENT_LOGOUT)),
    };
};

const ClientLoggedIn = connect(mapStateToProps, mapDispatchToProps)(ConnectedClientLoggedIn);

export default ClientLoggedIn;