import React, {Component} from 'react';
import Script from 'react-load-script';
import {Panel} from 'primereact/components/panel/Panel';

import {SM_TAB_PATIENT_DETAILS} from "../../../actions/stateManagement";
import {Button} from "primereact/button";
import {ac} from "../../../index";

class PatientFeedback extends Component {

    constructor(props) {
        super(props);

        this.offsetHeight = '0px'
    }

    onReturn() {

        window.location.hash = '/main/tablet';
    }

    handleScriptCreate() {
    }

    handleScriptError() {
    }

    handleScriptLoad() {
        this.initDocEditorViewport.call(this);

        const filename = 'IDHManual.docx';
        const documentUrl = `https://${ac.getBASERESTURL()}/Patients/openPatientDocument/${1}/${filename}`;

        let config = {
            "document": {
                "fileType": "docx",
                title: 'IDH Manual',
                "url": documentUrl
            },
            "editorConfig": {
                // mode: "view"
            }
        };

        new window.DocsAPI.DocEditor("placeholder", config);
    }

    initDocEditorViewport() {
        const docEditorViewportContainerEl = document.getElementById('onlyoffice-container');

        if (docEditorViewportContainerEl) {
            this.setOffsetHeight.call(this);
            docEditorViewportContainerEl.style.height = this.getDocEditorViewportHeight.call(this);
        }
    }

    setOffsetHeight() {
        const panelHeaderSelector = '#onlyoffice-panel-header-only .p-panel-titlebar';
        const panelHeaderEl = document.querySelector(panelHeaderSelector);

        if (panelHeaderEl) this.offsetHeight = `${panelHeaderEl.offsetHeight}px`;
    }

    getDocEditorViewportHeight() {
        return `calc(100% - ${this.offsetHeight})`
    }

    render() {

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{SM_TAB_PATIENT_DETAILS.label}</label>
            <Button icon='fa fa-reply'
                    onClick={this.onReturn}>
            </Button>
        </div>;

        return [

            <Panel id='onlyoffice-panel-header-only' header={header}/>,
            <div id="onlyoffice-container">
                <div id="placeholder"/>
                ,
            </div>,
            <Script url={ac.getDOCUMENT_SERVER_API()}
                    onCreate={this.handleScriptCreate.bind(this)}
                    onError={this.handleScriptError.bind(this)}
                    onLoad={this.handleScriptLoad.bind(this)}
            />
        ]
    }

    componentDidMount() {

    }
}

export default PatientFeedback;