import React from 'react';
import _ from 'lodash';

import {Panel} from 'primereact/components/panel/Panel';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from "primereact/datatable";
import {
    _00,
    _01,
    _02,
    _03,
    _04,
    _05,
    _06,
    _13,
    _14,
    _15,
    _16,
    _17,
    _18,
    _25,
    _26,
    _27,
    _28,
    _30,
    _31,
    _32,
    _33,
    _34,
    _35,
    _36,
    _37,
    _71,
    _72,
    _73,
    _74,
    _75,
    _76,
    _77,
    _78,
    _79,
    _80,
    _81,
    _82,
    _83,
    _84,
    ExemptionAndRemissionCodes
} from "../../../../PatientDynamicItems/NHSDetails";
import {HM_NHS_SUPPORTING_DETAILS} from "../../../../../Constants";

const SupportingDetailEntries = [_01, _02, _03, _04, _05, _13, _14, _15, _16, _17, _18, _25, _26, _31, _32, _33, _34, _83, _84];

const getTickState = ({exemptionsAndRemissions, exemptionShown}, i, j) => {

    if (exemptionsAndRemissions[i.ordinal] || exemptionsAndRemissions[j.ordinal]) {
        if (exemptionShown[i.ordinal])
            return ExemptionAndRemissionCodes[j.ordinal];
        else
            return ExemptionAndRemissionCodes[i.ordinal];
    } else
        return ExemptionAndRemissionCodes[j.ordinal];
};

const filterExemptionAndRemissionCodes = (props) => {

    const content = [];

    ExemptionAndRemissionCodes.forEach(baseCode => {
        switch (baseCode) {
            case _00 :
                content.push(ExemptionAndRemissionCodes[_00.ordinal]);
                break;
            case _01 :
                content.push(getTickState(props.nhsDetails, _01, _02));
                break;
            case _03 :
                content.push(getTickState(props.nhsDetails, _03, _04));
                break;
            case _05 :
                content.push(getTickState(props.nhsDetails, _06, _05));
                break;
            case _13 :
                content.push(getTickState(props.nhsDetails, _13, _14));
                break;
            case _15 :
                content.push(getTickState(props.nhsDetails, _15, _16));
                break;
            case _17 :
                content.push(getTickState(props.nhsDetails, _17, _18));
                break;
            case _25 :
                content.push(getTickState(props.nhsDetails, _25, _26));
                break;
            case _27 :
                content.push(getTickState(props.nhsDetails, _27, _28));
                break;
            case _30 :
                content.push(ExemptionAndRemissionCodes[_30.ordinal]);
                break;
            case _31 :
                content.push(getTickState(props.nhsDetails, _31, _32));
                break;
            case _33 :
                content.push(getTickState(props.nhsDetails, _33, _34));
                break;
            case _35 :
                content.push(ExemptionAndRemissionCodes[_35.ordinal]);
                break;
            case _37 :
                content.push(getTickState(props.nhsDetails, _37, _36));
                break;
            case _71 :
                content.push(getTickState(props.nhsDetails, _75, _71));
                break;
            case _72 :
                content.push(getTickState(props.nhsDetails, _76, _72));
                break;
            case _73 :
                content.push(getTickState(props.nhsDetails, _77, _73));
                break;
            case _74 :
                content.push(getTickState(props.nhsDetails, _78, _74));
                break;
            case _79 :
                content.push(getTickState(props.nhsDetails, _80, _79));
                break;
            case _81 :
                content.push(getTickState(props.nhsDetails, _82, _81));
                break;
            case _83 :
                content.push(getTickState(props.nhsDetails, _84, _83));
                break;
            default:
                break;
        }
    });
    return content;
};

const getIndexes = (row) => {

    let i = -1;
    let j = -1;

    switch (row.ordinal) {
        case _00.ordinal :
            i = _00.ordinal;
            j = _00.ordinal;
            break;
        case _01.ordinal :
        case _02.ordinal :
            i = _01.ordinal;
            j = _02.ordinal;
            break;
        case _03.ordinal :
        case _04.ordinal :
            i = _03.ordinal;
            j = _04.ordinal;
            break;
        case _06.ordinal :
        case _05.ordinal :
            i = _06.ordinal;
            j = _05.ordinal;
            break;
        case _13.ordinal :
        case _14.ordinal :
            i = _13.ordinal;
            j = _14.ordinal;
            break;
        case _15.ordinal :
        case _16.ordinal :
            i = _15.ordinal;
            j = _16.ordinal;
            break;
        case _17.ordinal :
        case _18.ordinal :
            i = _17.ordinal;
            j = _18.ordinal;
            break;
        case _25.ordinal :
        case _26.ordinal :
            i = _25.ordinal;
            j = _26.ordinal;
            break;
        case _27.ordinal :
        case _28.ordinal :
            i = _27.ordinal;
            j = _28.ordinal;
            break;
        case _30.ordinal :
            i = _30.ordinal;
            j = _30.ordinal;
            break;
        case _31.ordinal :
        case _32.ordinal :
            i = _31.ordinal;
            j = _32.ordinal;
            break;
        case _33.ordinal :
        case _34.ordinal :
            i = _33.ordinal;
            j = _34.ordinal;
            break;
        case _35.ordinal :
            i = _35.ordinal;
            j = _35.ordinal;
            break;
        case _37.ordinal :
        case _36.ordinal :
            i = _37.ordinal;
            j = _36.ordinal;
            break;
        case _75.ordinal :
        case _71.ordinal :
            i = _75.ordinal;
            j = _71.ordinal;
            break;
        case _76.ordinal :
        case _72.ordinal :
            i = _76.ordinal;
            j = _72.ordinal;
            break;
        case _77.ordinal :
        case _73.ordinal :
            i = _77.ordinal;
            j = _73.ordinal;
            break;
        case _78.ordinal :
        case _74.ordinal :
            i = _78.ordinal;
            j = _74.ordinal;
            break;
        case _80.ordinal :
        case _79.ordinal :
            i = _80.ordinal;
            j = _79.ordinal;
            break;
        case _82.ordinal :
        case _81.ordinal :
            i = _82.ordinal;
            j = _81.ordinal;
            break;
        case _84.ordinal :
        case _83.ordinal :
            i = _84.ordinal;
            j = _83.ordinal;
            break;
        default:
            break;
    }
    return {i, j};
};

const codeTemplate = (props, row) => {

    const {i, j} = getIndexes(row);

    const ticked = props.nhsDetails.exemptionsAndRemissions && (props.nhsDetails.exemptionsAndRemissions[i] || props.nhsDetails.exemptionsAndRemissions[j]);

    return <Checkbox checked={ticked}
                     onChange={e => {
                         const exemptionsAndRemissions = [...props.nhsDetails.exemptionsAndRemissions];
                         exemptionsAndRemissions[i] = e.checked;
                         exemptionsAndRemissions[j] = e.checked;
                         props.onChange({
                             owner: `${props.target}.exemptionsAndRemissions`,
                             value: exemptionsAndRemissions
                         });

                         if (!e.checked) {
                             const exemptionShown = [...props.nhsDetails.exemptionShown];
                             exemptionShown[i] = false;
                             exemptionShown[j] = false;
                             props.onChange({owner: `${props.target}.exemptionShown`, value: exemptionShown});
                         }
                     }}
    />
};

const seenTemplate = (props, row) => {

    const {i, j} = getIndexes(row);

    const seen = props.nhsDetails.exemptionShown[i] || props.nhsDetails.exemptionShown[j];

    return <Checkbox checked={seen}
                     onChange={e => {

                         const ticked = props.nhsDetails.exemptionsAndRemissions[i] || props.nhsDetails.exemptionsAndRemissions[j];

                         if (ticked) {
                             const exemptionShown = [...props.nhsDetails.exemptionShown];
                             exemptionShown[i] = e.checked;
                             exemptionShown[j] = e.checked;
                             props.onChange({owner: `${props.target}.exemptionShown`, value: exemptionShown});

                             const needsSupportingDetails = _.findIndex(SupportingDetailEntries, entry => entry.ordinal === i || entry.ordinal === j) !== -1;

                             if (e.checked && needsSupportingDetails) {
                                 props.onChange({owner: HM_NHS_SUPPORTING_DETAILS.id, value: e.checked});
                             }
                         }
                     }}
    />
};

export const ExemptionsAndRemissionsContent = (props) => {

    if (props.nhsDetails === undefined)
        return null;

    const visibleExemptionAndRemissionCodes = filterExemptionAndRemissionCodes(props);

    return (
        <div>
            <Panel header='Exemptions and Remissions'>
                <div>
                    <DataTable value={visibleExemptionAndRemissionCodes}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                    >
                        <Column body={(row) => codeTemplate(props, row)} header='Code' style={{width: '7%'}}/>
                        <Column field="description" header="Description" style={{width: '48%'}}/>
                        <Column body={(row) => seenTemplate(props, row)} header="Exemption Shown"
                                style={{width: '10%'}}/>
                        <Column field="supportingDetails" header="Supporting Details" style={{width: '35%'}}/>
                    </DataTable>
                </div>
            </Panel>
        </div>
    )
};
