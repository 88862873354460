import React from 'react';
import {JawUpper} from "../Model/Constants";
import {getTranslate} from "./ChartingUtils";
import {getRandomKey} from "../Utils/general";
import {ToothBaseComponent} from "./ToothBaseComponent";

export class ImplantFixture extends ToothBaseComponent {

    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {

        let image = '';

        if (this.props.data.jaw === JawUpper) {
            image = 'implantFixtureU.png';
        } else {
            image = 'implantFixtureL.png';
        }

        const key = `${this.props.data.pos.offset}-${this.props.data.jaw}`;

        return (
            <g key={getRandomKey()} onContextMenu={(event) => {

                event.preventDefault();

                if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
                    this.props.data.currentCommand.setTarget(this.props.data);
                }
                this.props.mouth.setCurrentItem(this.props.data);
                this.props.cm(event);
            }}
               onMouseEnter={() => this.handleEnter()}
               onMouseLeave={() => this.handleLeave()}
               style={{cursor: this.state.cursor}}
               onClick={() => {
                   if (this.props.data.currentCommand != null && this.props.data.currentCommand.canExecute(this))
                       this.props.onClick(this.props.data);
               }}
            >
                <image alt='' key={key+'i'} x='0' y="0" width="20" height="70" href={`/assets/images/${image}`}
                       transform={getTranslate(this.props.data, 15, -5, 15, 85)}
                />
            </g>
        )
    }
}
