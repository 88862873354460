import React from 'react';
import * as Sections from "./Constants";
import {TAB_EXIT, TAB_EXIT_SAVE} from "./Constants";
import {
    HM_notImplemented,
    TB_SAVE,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {
    ICON_HELP,
    ICON_SAVE_DISABLED,
    ICON_SAVE_ENABLED,
} from "../../../icons";

import {connect} from 'react-redux';
import '../../../images.css';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';

import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Dialog} from 'primereact/components/dialog/Dialog';
import TitlesSection from './Sections/Titles';
import TreatmentCodesSection from './Sections/TreatmentCodes';
import RecallTypesSection from './Sections/RecallTypes';
import OccupationsSection from './Sections/Occupations';
import ClinicianSchedulesSection from './Sections/ClinicianSchedules';
import DiaryTypesSection from './Sections/DiaryTypes';
import AssCatFormsSection from './Sections/AssCatForms';
import CostCentersSection from './Sections/CostCenters';
import FavouritesSection from './Sections/Favourites';
import BaseChartFavouritesSection from './Sections/BaseChartFavourites';
import PatientJourneysSection from './Sections/PatientJourney';

import MedicalConditionsSection from './Sections/MedicalConditions';
import CancellationReasonsSection from "./Sections/CancellationReasons";
import * as Capabilities from '../../../components/Capabilities';
import {
    HELP_HSK_INDEXS,
    setState,
    SM_CHART_ITEM_MAPPING,
    SM_HOUSEKEEPING,
    SM_HOUSEKEEPING_ADETS,
    SM_HOUSEKEEPING_ASSCATS,
    SM_HOUSEKEEPING_BASE_CHART_FAVS,
    SM_HOUSEKEEPING_CCS,
    SM_HOUSEKEEPING_CRS,
    SM_HOUSEKEEPING_CSCHED,
    SM_HOUSEKEEPING_FAVS,
    SM_HOUSEKEEPING_JSTAGES,
    SM_HOUSEKEEPING_MCS, SM_HOUSEKEEPING_MEDICATIONS,
    SM_HOUSEKEEPING_OCCS,
    SM_HOUSEKEEPING_QUES,
    SM_HOUSEKEEPING_RECTYPES,
    SM_HOUSEKEEPING_TCODES,
    SM_HOUSEKEEPING_TITLES,
    SM_HOUSEKEEPING_DOC_CLASSIFICATIONS,
    stateRequest
} from "../../../actions/stateManagement";
import {RES_HOUSEKEEPING} from "../../../actions/housekeeping";
import {TabBaseComponent} from "../../TabBaseComponent";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import _ from "lodash";
import * as Actions from "../../../actions";
import {UPDATE_TAB} from "../../../actions";
import HousekeepingErrorBoundary from "./Utils";
import QuestionnairesSection from "./Sections/Questionnaires";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import MedicationSection from "./Sections/Medications";
import DocumentClassification from "./Sections/DocumentClassification";

class ConnectedHousekeeping extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING.id,

                canSave: {status: false, activeIndex: 0, source: {action: RES_HOUSEKEEPING.CLEAR.action}},
                showSaveDialog: false,
            }
        }

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onClose,

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        super.componentDidMount();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message.type !== prevProps.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING.SAVE.action:

                    const canSave = {...this.state.canSave};
                    canSave.status = false;

                    this.setState({canSave}, () => {
                        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onClose() {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true}, () => {
                this.props.setState(this.state.stateManagementId, this.state);
            })
        } else {
            this.onExit(false);
        }
    }

    onExit(save) {

        const source = {
            id: this.state.stateManagementId,
            action: save ? Actions.SAVE_CLEAR_HOUSEKEEPING : Actions.CLEAR_HOUSEKEEPING,
            saveState: true,
            saveObjects: true,
        };

        this.exitState = save ? TAB_EXIT_SAVE : TAB_EXIT;

        this.setState({showSaveDialog: false, canSave: {status: true, source}}, () => {
            this.props.onTabCloseClick({key: this.state.stateManagementId});
        });
    }

    onChange(event) {

        const source = {
            id: this.state.stateManagementId,
            action: RES_HOUSEKEEPING.SAVE.action,
            saveState: true,
            saveObjects: false,
            patientId: this.props.patientId,
        };

        const newState = {...this.state};

        if (event === null) {
            this.setState({canSave: {status: true, source, activeIndex: newState.canSave.activeIndex}}, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else if (event.owner === UPDATE_TAB) {
            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});
            this.setState(newState, () => {
                this.props.onTabUpdate({key: SM_HOUSEKEEPING.id}, true);
            });
        } else {
            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        }
    }

    onHelpRequest = () => {

        window.open(HELP_HSK_INDEXS[this.state.canSave.activeIndex]);
    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.TITLES,
                id: SM_HOUSEKEEPING_TITLES.id,
                helpUrl: SM_HOUSEKEEPING_TITLES.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_TITLES.id}
                                   header={SM_HOUSEKEEPING_TITLES.label}>
                    <HousekeepingErrorBoundary>
                        <TitlesSection onChange={this.onChange}
                                       {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_TREATMENT_CODES,
            {
                section: Sections.TREATMENT_CODES,
                id: SM_HOUSEKEEPING_TCODES.id,
                helpUrl: SM_HOUSEKEEPING_TITLES.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_TCODES.id}
                                   header={SM_HOUSEKEEPING_TCODES.label}>
                    <HousekeepingErrorBoundary>
                        <TreatmentCodesSection onChange={this.onChange}
                                               {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_COST_CENTRES,
            {
                section: Sections.COST_CENTERS,
                id: SM_HOUSEKEEPING_CCS.id,
                helpUrl: SM_HOUSEKEEPING_CCS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_CCS.id}
                                   header={SM_HOUSEKEEPING_CCS.label}>
                    <HousekeepingErrorBoundary>
                        <CostCentersSection onChange={this.onChange}
                                            {...this.props}
                                            titlesLoaded={true}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.TREATMENT_FAVS,
                id: SM_HOUSEKEEPING_FAVS.id,
                helpUrl: SM_HOUSEKEEPING_FAVS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_FAVS.id}
                                   header={SM_HOUSEKEEPING_FAVS.label}>
                    <HousekeepingErrorBoundary>
                        <FavouritesSection onChange={this.onChange}
                                           {...this.props}
                                           titlesLoaded={true}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.BASE_CHART_FAVS,
                id: SM_HOUSEKEEPING_BASE_CHART_FAVS.id,
                helpUrl: SM_HOUSEKEEPING_BASE_CHART_FAVS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_BASE_CHART_FAVS.id}
                                   header={SM_HOUSEKEEPING_BASE_CHART_FAVS.label}>
                    <HousekeepingErrorBoundary>
                        <BaseChartFavouritesSection onChange={this.onChange}
                                                    {...this.props}
                                                    titlesLoaded={true}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_CHARTING_MAPPING,
            {
                section: Sections.CHARTING_ITEMS_MAPPING,
                id: SM_CHART_ITEM_MAPPING.id,
                helpUrl: SM_CHART_ITEM_MAPPING.helpUrl,
                tab: null,
                content: <TabPanel key={SM_CHART_ITEM_MAPPING.id}
                                   header={SM_CHART_ITEM_MAPPING.label}>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_CLINICIANS_SCHEDULES,
            {
                section: Sections.CLINICIAN_SCHEDULES,
                id: SM_HOUSEKEEPING_CSCHED.id,
                helpUrl: SM_HOUSEKEEPING_CSCHED.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_CSCHED.id}
                                   header={SM_HOUSEKEEPING_CSCHED.label}>
                    <HousekeepingErrorBoundary>
                        <ClinicianSchedulesSection onChange={this.onChange}
                                                   {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_AddDiaryEventTypes,
            {
                section: Sections.DIARY_TYPES,
                id: SM_HOUSEKEEPING_ADETS.id,
                helpUrl: SM_HOUSEKEEPING_ADETS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_ADETS.id}
                                   header={SM_HOUSEKEEPING_ADETS.label}>
                    <HousekeepingErrorBoundary>
                        <DiaryTypesSection onChange={this.onChange}
                                           {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_AddRecallTypes,
            {
                section: Sections.RECALL_TYPES,
                id: SM_HOUSEKEEPING_RECTYPES.id,
                helpUrl: SM_HOUSEKEEPING_RECTYPES.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_RECTYPES.id}
                                   header={SM_HOUSEKEEPING_RECTYPES.label}>
                    <HousekeepingErrorBoundary>
                        <RecallTypesSection onChange={this.onChange}
                                            {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_PATIENTJOURNEYSTAGE,
            {
                section: Sections.PATIENT_JOURNEYS,
                id: SM_HOUSEKEEPING_JSTAGES.id,
                helpUrl: SM_HOUSEKEEPING_JSTAGES.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_JSTAGES.id}
                                   header={SM_HOUSEKEEPING_JSTAGES.label}>
                    <HousekeepingErrorBoundary>
                        <PatientJourneysSection onChange={this.onChange}
                                                {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_MEDICAL_CONDITIONS,
            {
                section: Sections.MEDICAL_CONDITIONS,
                id: SM_HOUSEKEEPING_MCS.id,
                helpUrl: SM_HOUSEKEEPING_MCS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_MCS.id}
                                   header={SM_HOUSEKEEPING_MCS.label}>
                    <HousekeepingErrorBoundary>
                        <MedicalConditionsSection onChange={this.onChange}
                                                  {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_MEDICAL_CONDITIONS,
            {
                section: Sections.MEDICATIONS,
                id: SM_HOUSEKEEPING_MEDICATIONS.id,
                helpUrl: SM_HOUSEKEEPING_MEDICATIONS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_MEDICATIONS.id}
                                   header={SM_HOUSEKEEPING_MEDICATIONS.label}>
                    <HousekeepingErrorBoundary>
                        <MedicationSection onChange={this.onChange}
                                                  {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_MEDICAL_CONDITIONS,
            {
                section: Sections.QUESTIONNAIRE,
                id: SM_HOUSEKEEPING_QUES.id,
                helpUrl: SM_HOUSEKEEPING_QUES.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_QUES.id}
                                   header={SM_HOUSEKEEPING_QUES.label}>
                    <HousekeepingErrorBoundary>
                        <QuestionnairesSection onChange={this.onChange}
                                               {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.CANCELLATION_REASONS,
                id: SM_HOUSEKEEPING_CRS.id,
                helpUrl: SM_HOUSEKEEPING_CRS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_CRS.id}
                                   header={SM_HOUSEKEEPING_CRS.label}>
                    <HousekeepingErrorBoundary>
                        <CancellationReasonsSection onChange={this.onChange}
                                                    {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.OCCUPATIONS,
                id: SM_HOUSEKEEPING_OCCS.id,
                helpUrl: SM_HOUSEKEEPING_OCCS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_OCCS.id}
                                   header={SM_HOUSEKEEPING_OCCS.label}>
                    <HousekeepingErrorBoundary>
                        <OccupationsSection onChange={this.onChange}
                                            {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_HK_ASSESSMENT_CATEGORIES,
            {
                section: Sections.ASS_CAT_FORMS,
                id: SM_HOUSEKEEPING_ASSCATS.id,
                helpUrl: SM_HOUSEKEEPING_ASSCATS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_ASSCATS.id}
                                   header={SM_HOUSEKEEPING_ASSCATS.label}>
                    <HousekeepingErrorBoundary>
                        <AssCatFormsSection onChange={this.onChange}
                                            {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.DOCUMENT_CLASSIFICATIONS,
                id: SM_HOUSEKEEPING_DOC_CLASSIFICATIONS.id,
                helpUrl: SM_HOUSEKEEPING_DOC_CLASSIFICATIONS.helpUrl,
                content: <TabPanel key={SM_HOUSEKEEPING_DOC_CLASSIFICATIONS.id}
                                   header={SM_HOUSEKEEPING_DOC_CLASSIFICATIONS.label}>
                    <HousekeepingErrorBoundary>
                        <DocumentClassification onChange={this.onChange}
                                            {...this.props}
                        />
                    </HousekeepingErrorBoundary>
                </TabPanel>
            });

        const footer = <div>
            <Button label="Yes" icon="fa fa-check" onClick={() => {
                this.onExit(this.state.canSave.status);
            }}/>
            <Button label="No"
                    icon="fas fa-times"
                    onClick={() => this.onCancel(RES_HOUSEKEEPING.CLEAR.action)}
            />
        </div>;

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;
        const saveText = this.state.canSave.status ? 'Save & Exit' : 'Exit';

        return (
            <div id="detailPanel">

                {this.showDialogs()}

                <Dialog header="Save Resource"
                        footer={footer}
                        visible={this.state.showSaveDialog}
                        modal={true}
                        minY={70}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>
                    The form has been modified, save these changes?
                </Dialog>

                <Toolbar left={
                                tb_boilerPlate2(this.onSaveNoDialog, saveIcon, !this.state.canSave.status, TB_SAVE.text, 1)
                         }
                         right={
                             <React.Fragment>
                                 {tb_boilerPlateRight(this.onHelpRequest, ICON_HELP, 'Knowledge Base', 2, true)}
                                 {tb_boilerPlateRight(() => {
                                     this.exitState = TAB_EXIT_SAVE;
                                     this.onSaveExit()
                                 }, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 3)}
                             </React.Fragment>
                         }
                    />
                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = state => {

    return {
        message: state.stateManagement.message,

        titlesLoaded: state.housekeeping.titlesLoaded,
        titles: state.housekeeping.titles,

        treatmentCodesLoaded: state.housekeeping.treatmentCodesLoaded,
        treatmentCodes: state.housekeeping.treatmentCodes,

        journeyStagesLoaded: state.housekeeping.journeyStagesLoaded,
        recallTypes: state.housekeeping.recallTypes,

        treatmentFavouritesLoaded: state.housekeeping.treatmentFavouritesLoaded,
        treatmentFavourites: state.housekeeping.treatmentFavourites,

        costCentersLoaded: state.housekeeping.costCentersLoaded,
        cancellationReasons: state.housekeeping.cancellationReasons,

        appointmentDiaryTypesLoaded: state.housekeeping.appointmentDiaryTypesLoaded,
        appointmentDiaryTypes: state.housekeeping.appointmentDiaryTypes,

        capabilities: state.login.capabilities,

        currentState: state.stateManagement[SM_HOUSEKEEPING.id],
    };
};

const MapDispatchToProps = dispatch => {

    return {

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const Housekeeping = connect(MapStateToProps, MapDispatchToProps)(ConnectedHousekeeping);

export default Housekeeping;

