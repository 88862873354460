import _ from "lodash";
import {ac} from "../../index";
import {RES_EMAIL_SHOT, RES_SMS_SHOT} from "../mailShots";
import {MSOperators} from "../../Constants";

export const addEmailShot = (dispatch, _shot) => {

    const shot = {..._shot};
    const operators = _.map(shot.operators, data => _.find(MSOperators, operator => operator.value === data.value).ordinal);
    shot.operators = operators;

    ac.fetchClient().post(`${RES_EMAIL_SHOT.ADD.url}?mcId=${ac.getMcId()}`, shot)
        .then(res => {

            dispatch({type: RES_EMAIL_SHOT.ADD.action, payload: res.data})
        });
};

export const saveEmailShot = (dispatch, _shot, clear) => {

    const shot = {..._shot};
    const operators = _.map(shot.operators, data => _.find(MSOperators, operator => operator.value === data).ordinal);
    shot.operators = operators;

    ac.fetchClient().post(`${RES_EMAIL_SHOT.SAVE.url}?mcId=${ac.getMcId()}`, shot)
        .then(res => {

            if (clear) {
                dispatch({type: RES_EMAIL_SHOT.CLEAR.action, payload: res.data})
            } else {
                dispatch({type: RES_EMAIL_SHOT.SAVE.action, payload: res.data})
            }
        });
};

export const addSMSShot = (dispatch, _shot) => {

    const shot = {..._shot};
    const operators = _.map(shot.operators, data => _.find(MSOperators, operator => operator.value === data.value).ordinal);
    shot.operators = operators;

    ac.fetchClient().post(`${RES_SMS_SHOT.ADD.url}?mcId=${ac.getMcId()}`, shot)
        .then(res => {

            dispatch({type: RES_SMS_SHOT.ADD.action, payload: res.data})
        });
};

export const saveSMSShot = (dispatch, _shot, clear) => {

    const shot = {..._shot};
    const operators = _.map(shot.operators, data => _.find(MSOperators, operator => operator.value === data).ordinal);
    shot.operators = operators;

    ac.fetchClient().post(`${RES_SMS_SHOT.SAVE.url}?mcId=${ac.getMcId()}`, shot)
        .then(res => {

            if (clear) {
                dispatch({type: RES_SMS_SHOT.CLEAR.action, payload: res.data})
            } else {
                dispatch({type: RES_SMS_SHOT.SAVE.action, payload: res.data})
            }
        });
};
