// Preferences sections

export const ACCOUNTING = 0;
export const AVAILABLE_PRINTERS = 2;
export const CHARTING_SYSTEMS = 3;
export const DATABASE_BACKUP = 4;
export const DIARY = 5;
export const DIARY_COLOUR_SELECTION = 6;
export const EMAIL_ACCOUNT_SETTINGS = 7;
export const ESENDEX_SETTINGS = 8;
export const NHS_REGISTRATION = 9;
export const PRACTICE_DETAILS = 10;
export const PRACTICE_OPENING_HOURS = 11;
export const PRACTICE_OPENING_LOCATIONS = 12;
export const RECALLS_REMINDERS = 13;
export const RECALLS_REPORTS = 14;
export const XRAY_GATEWAY = 15;

// invoice related

export const SCHEDULED = 'SCHEDULED';
export const NOW = 'NOW';

export const INVOICE_PREFS = [{label: 'Invoice Now', value: NOW}, {label: 'Invoice Schedule', value: SCHEDULED}];

export const YEARENDMONTH = "YEARENDMONTH";
export const INVOICE_DETAILED_VIEW = "INVOICE_DETAILED_VIEW";
export const OVERDUE_BALANCE_FORMAT = "OVERDUE_BALANCE_FORMAT";
export const LIMIT_RECEIPT = "LIMIT_RECEIPT";
export const PRINTASSTATEMENT = "PRINTASSTATEMENT";

// QB Related

export const QB_START_ROW = "QB_START_ROW";
export const QB_DATE_COL = "QB_DATE_COL";
export const QB_MEMO_COL = "QB_MEMO_COL";
export const QB_AMOUNT_COL = "QB_AMOUNT_COL";
export const QB_NAME_COL = "QB_NAME_COL";
export const QB_ACCOUNT_COL = "QB_ACCOUNT_COL";
export const QB_SPLIT_COL = "QB_SPLIT_COL";
export const QB_PASSWORD = "QB_PASSWORD";

// charting related

export const ChartNumbering_UK = "ChartNumbering_UK";
export const ChartNumbering_US = "ChartNumbering_US";
export const ChartNumbering_EURO = "ChartNumbering_EURO";
export const DEFAULTSCHEME = ChartNumbering_UK;

export const SCHEMECATS = [{label: 'British', value: ChartNumbering_UK}, {
    label: "American",
    value: ChartNumbering_US
}, {label: "European", value: ChartNumbering_EURO}];

export const ChartingEnabled = 'ChartingEnabled';
export const PerioChartingEnabled = 'PerioChartingEnabled';
export const IncompleteVisualEnabled = 'IncompleteVisualEnabled';
export const ChartingScheme = 'ChartingScheme';

// hours related
export const TB_PracticeOpeningDays = {text: 'Practice Opening Days'};
export const TB_PracticeOpeningHours = {
    text: 'Practice Opening Hours',
    toolTip: 'Set the diary slot duration (minutes)'
};

// diary navigation related
export const OpenPatientEditorPreference = "OpenPatientEditorPreference";
export const OpenAppointmentEditorPreference = "OpenAppointmentEditorPreference";
export const IncrementalSearch = "IncrementalSearch";

export const ACTIVECANCELLATIONLIST = "ACTIVECANCELLATIONLIST";
export const ACL_ONDELETE = "ACL_ONDELETE";
export const ACL_ONCANCEL = "ACL_ONCANCEL";
export const ACL_ONMOVE = "ACL_ONMOVE";
export const ACL_ONCHANGEDURATION = "ACL_ONCHANGEDURATION";

export const ACTIVATEPOPUPONARRIVAL = "ACTIVATEPOPUPONARRIVAL";

export const TIMEINTERVALES_1224 = "TIMEINTERVALES_1224";

export const LUNCH_WARNING = {special: true, username: 'Lunch', value: "LUNCH_WARNING"};
export const LUNCH_COLOUR = {special: true, username: 'Lunch', value: "LUNCH_COLOUR"};
export const RESERVED_COLOUR = {special: true, username: 'Reserved', value: "RESERVED_COLOUR"};
export const HOLIDAY_COLOUR = {special: true, username: 'Holiday', value: "HOLIDAY_COLOUR"};
export const OUTOFHOURS_COLOUR = {special: true, username: 'Out of Hours', value: "OUTOFHOURS_COLOUR"};

export const DEFAULT_DIARY_COLOUR = {red: 128, green: 128, blue: 128};

export const DIARY_SORT_ORDER = "DIARY_SORT_ORDER";

export const APPOINTMENT_COLOUR_SCHEME = "APPOINTMENT_COLOUR_SCHEME";
export const PROVIDER_SCHEME = "PROVIDER_SCHEME";
export const APPTYPE_SCHEME = "APPTYPE_SCHEME";

export const COP_REPORTS = "COP_REPORTS";

export const SELECT_APPOINTEMT_DBLCLK = "SELECT_APPOINTEMT_DBLCLK";
export const TIME_INDICATOR_COLOUR = "TIME_INDICATOR_COLOUR";

export const DAY_LIST_TEXT_FORMAT = "DAY_LIST_TEXT_FORMAT";

// Appointment time monitoring
export const PATIENT_TIME_MONITORING = "PATIENT_TIME_MONITORING";

// Appointment confirmation on reminder sending
export const APPOINTMENT_CONFIRM_ON_REMINDER = "APPOINTMENT_CONFIRM_ON_REMINDER";

export const IN_CHAIR = "IN_CHAIR";
export const LEFT_CHAIR = "LEFT_CHAIR";

export const PAT_REALLOC_SUPPRESS = "PAT_REALLOC_SUPPRESS";

export const NHSNUMBER_REQUIRED = "NHSNUMBER_REQUIRED";

export const SUPPRESS_APPT_DETAILS = "SUPPRESS_APPT_DETAILS";

export const ARRIVALSOUND = "ARRIVALSOUND";

export const SOUND1 = "wavs/beep.wav";
export const SOUND2 = "wavs/DaBellsG5.wav";
export const SOUND3 = "wavs/chime_arpeggios_3.wav";

export const SOUNDS = [{label: 'Beep', value: SOUND1}, {label: 'Bells', value: SOUND2}, {
    label: 'Chime',
    value: SOUND3
}];

export const GotoPatientView_Summary = "GotoPatientView_Summary";
export const GotoPatientView_Treatment = "GotoPatientView_Treatment";
export const GotoPatientView_Account = "GotoPatientView_Account";
export const GotoPatientView_Recalls = "GotoPatientView_Recalls";
export const GotoPatientView_Reminders = "GotoPatientView_Reminders";
export const GotoPatientView_Personal = "GotoPatientView_Personal";
export const GotoPatientView_Notes = "GotoPatientView_Notes";
export const GotoPatientView_Documents = "GotoPatientView_Documents";

export const PATNAVS = [
    {label: 'Summary', value: GotoPatientView_Summary},
    {label: 'Treatment', value: GotoPatientView_Treatment},
    {label: 'Account', value: GotoPatientView_Account},
    {label: 'Recalls', value: GotoPatientView_Recalls},
    {label: 'Reminders', value: GotoPatientView_Reminders},
    {label: 'Personal', value: GotoPatientView_Personal},
    {label: 'Notes', value: GotoPatientView_Notes},
    {label: 'Documents', value: GotoPatientView_Documents},
];

export const GotoPatientView_Preference = "GotoPatientView_Preference";
export const GotoAppointmentView_Preference = "GotoAppointmentView_Preference";

export const GotoUserView = "GotoUserView";
export const GotoUserView_AH = "GotoUserView_AH";

export const GotoInvoice = "GotoInvoice";
export const GotoMakePayment = "GotoMakePayment";

export const GotoAppointmentSimpleCharting = "GotoAppointmentSimpleCharting";
export const GotoAppointmentTreatment = "GotoAppointmentTreatment";
export const GotoAppointmentComment = "GotoAppointmentComment";
export const GotoEventComment = "GotoEventComment";

export const GotoAppointment_Treatments = "GotoAppointment_Treatments";
export const GotoAppointment_TreatmentHistory = "GotoAppointment_TreatmentHistory";
export const GotoAppointment_Notes = "MPEAppointmentNotes2";
export const GotoAppointment_Documents = "DocumentsStore2";
export const GotoAppointment_OrthoASF = "AssessmentForm";
export const GotoAppointment_PatASF = "PatientAssessmentForm";

export const APPNAVS = [
    {label: 'Treatments', value: GotoAppointment_Treatments},
    {label: 'Treatment History', value: GotoAppointment_TreatmentHistory},
    {label: 'Notes', value: GotoAppointment_Notes},
    {label: 'Documents', value: GotoAppointment_Documents},
    {label: 'Ortho Assessment', value: GotoAppointment_OrthoASF},
    {label: 'Patient Assessment', value: GotoAppointment_PatASF},
];

// Sedation related

export const SEDATION_ASSISTANT = "SEDATION_ASSISTANT";

// Esendex related

export const ESENDEX_USERNAME = "ESENDEX_USERNAME";
export const ESENDEX_PASSWORD = "ESENDEX_PASSWORD";
export const ESENDEX_ACCOUNT = "ESENDEX_ACCOUNT";
export const ESENDEX_ALIAS = "ESENDEX_ALIAS";

export const HM_PREFERENCES_REPORT = {
    id: 'HM_PREFERENCES_REPORT',
    label: `Edit Report Text`,
    icon: 'fas fa-edit'
};

// Patient related

export const FLATTEN_PATIENT_DOCUMENTS = "FLATTEN_PATIENT_DOCUMENTS";
export const SHOW_MEDICAL_HISTORY = "SHOW_MEDICAL_HISTORY";

// Keap related

export const KEAP_INTEGRATION = "KEAP_INTEGRATION";