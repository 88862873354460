import {AdultPreMolar} from "../Parts/AdultPreMolar";
import {AdultIncisor} from "../Parts/AdultIncisor";
import {AdultMolar} from "../Parts/AdultMolar";
import {AdultPreMolarBicusp} from "../Parts/AdultPreMolarBicusp";
import {PlaceHolder} from "../PlaceHolder";
import _ from "lodash";
import {equals, equalsOffset} from "../Parts/ChartingUtils";
import * as TC from "../Model/Constants";
import {BondedBridge, FixedBondedOrthodonticRetainer, FixedBondedOrthodonticRetainerEP} from "../Model/Constants";
import {ChartItemBase} from "./ChartItemBase";

export class ComOrthoFixedRetainer extends ChartItemBase {

    constructor() {
        super();

        this.state = {};
        this.target = null;
        this.mouth = null;
        this.started = false;
        this.start = null;
        this.end = null;
        this.selection = [];
        this.showDialog = false;
        this.mouth = null;
        this.jaw = null;
        this.result = null;

        this.setTarget = this.setTarget.bind(this);
        this.executeBase = this.executeBase.bind(this);
        this.executeBaseCancel = this.executeBaseCancel.bind(this);
        this.executeBaseEnd = this.executeBaseEnd.bind(this);
        this.executeTreatment = this.executeTreatment.bind(this);
        this.executeTreatmentCancel = this.executeTreatmentCancel.bind(this);
        this.executeTreatmentEnd = this.executeTreatmentEnd.bind(this);
    };

    toString() {
        return BondedBridge;
    }

    canExecute(target) {

        try {
            return ((target instanceof AdultIncisor) ||
                (target instanceof AdultMolar) ||
                (target instanceof AdultPreMolar) ||
                (target instanceof AdultPreMolarBicusp) ||
                (target instanceof PlaceHolder)) && (
                this.jaw === null ||
                this.jaw === target.props.data.jaw
            )
        } catch (e) {
            return false;
        }
    }

    executeBase(mouth, target) {

        this.mouth = mouth;
        this.jaw = target.jaw;

        if (this.started) {

            this.end = target.pos.offset;

            let adultPlaceHolders = mouth.getAdultPlaceholders();
            let adultTeeth = mouth.getAdultTeeth();
            let adultOthers = this.mouth.getAdultOthers();

            // first check if left to right selection
            if (parseInt(this.start, 10) > parseInt(this.end, 10)) {

                let swap = this.start;
                this.start = this.end;
                this.end = swap;
            }
            for (let index = this.start; index <= this.end; index++) {
                let tooth = _.find(adultTeeth, (item) => {
                    return equalsOffset(item, target.jaw, index);
                });
                if (tooth != null && tooth.present) {
                    tooth.selected = true;
                    this.selection.push(tooth);
                } else {
                    let placeHolder = _.find(adultPlaceHolders, (item) => {
                        return equalsOffset(item, target.jaw, index);
                    });
                    placeHolder.selected = true;
                    this.selection.push(placeHolder);
                }
            }
            this.showDialog = true;
            mouth.updatePlaceHoldersAndTeeth(adultPlaceHolders, adultTeeth, adultOthers);

        } else {
            this.started = true;

            this.start = target.pos.offset;

            if (target.ctype === TC.FixedBondedOrthodonticRetainerEP) {
                let adultPlaceHolders = mouth.getAdultPlaceholders();
                let placeHolder = _.find(adultPlaceHolders, (item) => {
                    return equals(item, target);
                });

                placeHolder.selected = true;

                mouth.updatePlaceHolders(adultPlaceHolders);
            } else {
                let adultTeeth = mouth.getAdultTeeth();
                let tooth = _.find(adultTeeth, (item) => {
                    return equals(item, target);
                });

                tooth.selected = true;

                mouth.updateTeeth(adultTeeth);
            }
        }
        this.mouth = mouth;
    }

    executeBaseCancel() {

        let adultPlaceHolders = this.mouth.getAdultPlaceholders();
        let adultTeeth = this.mouth.getAdultTeeth();
        let adultOthers = this.mouth.getAdultOthers();

        for (let index = this.start; index <= this.end; index++) {
            let tooth = _.find(adultTeeth, (item) => {
                return equalsOffset(item, this.jaw, index);
            });
            if (tooth != null && tooth.present) {
                tooth.selected = false;
                this.selection.push(tooth);
            } else {
                let placeHolder = _.find(adultPlaceHolders, (item) => {
                    return equalsOffset(item, this.jaw, index);
                });
                placeHolder.selected = false;
                placeHolder.partOfSpan = false;
                this.selection.push(placeHolder);
            }
        }

        this.showDialog = false;
        this.clear();

        this.mouth.updatePlaceHoldersAndTeeth(adultPlaceHolders, adultTeeth, adultOthers);
    }

    executeBaseEnd(result) {

        let adultPlaceHolders = this.mouth.getAdultPlaceholders();
        let adultTeeth = this.mouth.getAdultTeeth();
        let adultOthers = this.mouth.getAdultOthers();

        for (let index = this.start; index <= this.end; index++) {

            let placeHolder = _.find(adultPlaceHolders, (item) => {
                return equalsOffset(item, this.jaw, index);
            });

            if (placeHolder.present) {
                let tooth = _.find(adultTeeth, (item) => {
                    return equalsOffset(item, this.jaw, index);
                });

                if (tooth !== undefined)
                    tooth.selected = false;
            }
            placeHolder.selected = false;
        }

        this.showDialog = false;

        let entry = this.createNewEntry(this.mouth, FixedBondedOrthodonticRetainer, true);
        entry.comment = result.comment;
        entry.condition = result.condition;
        entry.material = result.material;
        entry.jaw = this.jaw;
        entry.spanStart = result.start;
        entry.spanEnd = result.end;
        entry.attachments = [];

        for (let index = 0; index < 16; index++) {
            entry.attachments[index] = result.span[index].state;
        }

        const other = {
            ctype: FixedBondedOrthodonticRetainerEP,
            material: result.material,
            condition: result.condition,
            comment: result.comment,
            jaw: this.jaw,
            start: this.start,
            end: this.end,
            span: result.span,
        };

        adultOthers.push(other);

        this.mouth.updatePlaceHoldersAndTeeth(adultPlaceHolders, adultTeeth, adultOthers);
        this.mouth.props.addChartEntry(entry);
        // fire on change for save icon etc
        this.mouth.props.onChange({owner: 'baseChartingEntered', value: true});
        this.clear();
    }

    executeTreatment(mouth, target) {

        this.mouth = mouth;
        this.jaw = target.jaw;

        if (this.started) {

            this.end = target.pos.offset;

            let adultPlaceHolders = mouth.getAdultPlaceholders();
            let adultTeeth = mouth.getAdultTeeth();
            let adultOthers = this.mouth.getAdultOthers();

            // first check if left to right selection
            if (parseInt(this.start, 10) > parseInt(this.end, 10)) {

                let swap = this.start;
                this.start = this.end;
                this.end = swap;
            }
            for (let index = this.start; index <= this.end; index++) {
                let tooth = _.find(adultTeeth, (item) => {
                    return equalsOffset(item, target.jaw, index);
                });
                if (tooth != null && tooth.present) {
                    tooth.selected = true;
                    this.selection.push(tooth);
                } else {
                    let placeHolder = _.find(adultPlaceHolders, (item) => {
                        return equalsOffset(item, target.jaw, index);
                    });
                    placeHolder.selected = true;
                    this.selection.push(placeHolder);
                }
            }
            this.showDialog = true;
            mouth.updatePlaceHoldersAndTeeth(adultPlaceHolders, adultTeeth, adultOthers);

        } else {
            this.started = true;

            this.start = target.pos.offset;

            if (target.ctype === TC.PlaceHolderEP) {
                let adultPlaceHolders = mouth.getAdultPlaceholders();
                let placeHolder = _.find(adultPlaceHolders, (item) => {
                    return equals(item, target);
                });

                placeHolder.selected = true;

                mouth.updatePlaceHolders(adultPlaceHolders);
            } else {
                let adultTeeth = mouth.getAdultTeeth();
                let tooth = _.find(adultTeeth, (item) => {
                    return equals(item, target);
                });

                tooth.selected = true;

                mouth.updateTeeth(adultTeeth);
            }
        }
        this.mouth = mouth;
    }

    executeTreatmentCancel() {

        let adultPlaceHolders = this.mouth.getAdultPlaceholders();
        let adultTeeth = this.mouth.getAdultTeeth();
        let adultOthers = this.mouth.getAdultOthers();

        for (let index = this.start; index <= this.end; index++) {
            let tooth = _.find(adultTeeth, (item) => {
                return equalsOffset(item, this.jaw, index);
            });
            if (tooth != null && tooth.present) {
                tooth.selected = false;
                this.selection.push(tooth);
            } else {
                let placeHolder = _.find(adultPlaceHolders, (item) => {
                    return equalsOffset(item, this.jaw, index);
                });
                placeHolder.selected = false;
                placeHolder.partOfSpan = false;
                this.selection.push(placeHolder);
            }
        }

        this.showDialog = false;
        this.clear();

        this.mouth.updatePlaceHoldersAndTeeth(adultPlaceHolders, adultTeeth, adultOthers);
    }

    executeTreatmentEnd(result) {

        // save this for the callback
        this.result = result;

        let entry = this.createNewEntry(this.mouth, BondedBridge, false);
        entry.comment = result.comment;
        entry.condition = result.condition;
        entry.material = result.material;
        entry.jaw = this.jaw;
        entry.spanStart = result.start;
        entry.spanEnd = result.end;
        entry.attachments = [];

        for (let index = 0; index < 16; index++) {
            entry.attachments[index] = result.span[index].state;
        }

        this.mouth.props.addTreatmentHandler(entry);
    }

    setTarget(target) {
        this.target = target;
    }

    getCommandContextMenuItems() {
        return [];
    }
}
