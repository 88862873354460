import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {Panel} from 'primereact/components/panel/Panel';

import {calendar, dropDown, inputText, selectGendersButton} from '../PatientDynamicItems/OnChangeUtils';
import {Checkbox} from "primereact/checkbox";
import {SU_STATUS_ACTIVE, SU_STATUS_INACTIVE} from "../../Constants";

const optionalInfo = (props) => {

    if (props.includeOptionalInfo) {
        return [
            <div className="p-col-4">
                <label htmlFor="name.journeyStage">Journey Stage</label>
            </div>,
            <div className="p-col-8">
                <Dropdown id="name.journeyStage"
                          placeHolder='Journey Stage'
                          value={props.value.stage}
                          options={props.stages}
                          onChange={props.onStageChange}
                          autoWidth={false}
                />
            </div>,
            <div className="p-col-4"><label>Changed On</label></div>,
            <div className="p-col-8"><label>{props.value.changedOn}</label></div>,
            <div className="p-col-4"><label>Patient ID</label></div>,
            <div className="p-col-8"><label>{props.value.patientId}</label></div>,
        ]
    }
};

const showOptionalPasswordButton = ({showPasswordButton, showPasswordCallback}) => {

    if (showPasswordButton) {
        return (
            <React.Fragment>
                <div className="p-col-2"/>
                <div className="p-col-4">
                    <Button label="Set Password"
                            className="p-button-warning"
                            onClick={() => {
                                showPasswordCallback();
                            }}
                    />
                </div>
            </React.Fragment>
        );
    } else {
        return <div className="p-col-6"/>
    }
};

const showTitleLine = (props) => {

    if (props.showPasswordButton) { // if this is true the user is being edited
        return (
            <React.Fragment>
                <div className="p-col-2">
                    <label htmlFor="title">Title</label>
                </div>
                <div className="p-col-10">
                    {dropDown(props, 'titles', 'title', 'abbreviation', true)}
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className="p-col-2">
                    <label htmlFor="title">Title</label>
                </div>
                <div className="p-col-6">
                    {dropDown(props, 'titles', 'title', 'abbreviation', true)}
                </div>
                <div className="p-col-4">
                    <Checkbox checked={props.user.status === SU_STATUS_ACTIVE} onChange={(e) => {
                        props.onChange({owner: 'user.status', value: e.checked ? SU_STATUS_ACTIVE : SU_STATUS_INACTIVE})
                    }}/>
                    <label key='createActive' className='p-checkbox-label'>Create Active</label>
                </div>
            </React.Fragment>
        );
    }
};

export const Name = (props) => {

    return (
        <Panel header='Name'>
            <div className="p-grid p-fluid form-group">

                {optionalInfo(props)}
                {showTitleLine(props)}

                <div className="p-col-2">
                    <label htmlFor="firstName">First Name</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'firstName', 'First Name', false, true)}
                </div>

                <div className="p-col-2">
                    <label htmlFor="usernameNew">Username</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'username', 'Username', false, true)}
                </div>

                <div className="p-col-2">
                    <label htmlFor="middleNameNew">Middle Name</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'middleName', 'Middle Name', false)}
                </div>

                {showOptionalPasswordButton(props)}

                <div className="p-col-2">
                    <label htmlFor="lastName">Last Name</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'lastName', 'Last Name', false, true)}
                </div>
                <div className="p-col-2">
                    <label htmlFor="gender">Gender</label>
                </div>
                <div className="p-col-10">
                    {selectGendersButton(props, 'gender')}
                </div>

                <div className="p-col-2">
                    <label htmlFor="dob">GDC Number</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'gdcNumber', 'GDC Number', false, false)}
                </div>

                <div className="p-col-2">
                    <label htmlFor="dob">Date of Birth</label>
                </div>
                <div className="p-col-4">
                    {calendar(props, 'dateOfBirth')}
                </div>
            </div>
        </Panel>
    )
};
