import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from 'primereact/components/inputtext/InputText';

import {
    PD_STATE_RECEIPT_AMOUNT,
    PD_STATE_RECEIPT_CHEQUE,
    PD_STATE_RECEIPT_NARRATIVE,
    TB_RECEIPT_CHEQUE
} from "../../../PatientDetails/Constants";
import {inputText} from "../../../../PatientDynamicItems/OnChangeUtils";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";

function optionalAmountInfo(props) {

    if (!props.changeType) {

        const content = [];

        content.push(
            <div className="p-col-3">
                <label>Amount (£)</label>
            </div>
        );
        content.push(
            <div className="p-col-9">
                <InputText type="text"
                           disabled={false}
                           style={{fontSize: 'x-large', textAlign: 'right'}}
                           value={props.payment.amount}
                           keyfilter='pnum'
                           onChange={(e) => {
                               props.onChange({owner: 'payment.amount', value: e.target.value});
                           }}
                />
            </div>
        );
        return content;
    }
}

export const PaymentCheque = (props) => {

    const payProps = {
        onChange: (e) => {
            props.onChange({owner: e.owner, value: e.value.toUpperCase()});
        },
        target: 'payment',
        payment: props.payment,
    };

    const sortCode = props.payment.sortCode.split('-');

    const footer =
        <div>
            <Button label='Back'
                    icon="fa fa-chevron-left"
                    onClick={() => {
                        props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_AMOUNT});
                    }}/>
            <Button label='Next'
                    icon="fa fa-chevron-right"
                    onClick={() => {
                        props.onChange([{owner: 'previousState', value: PD_STATE_RECEIPT_CHEQUE}, {
                            owner: 'dialogState',
                            value: PD_STATE_RECEIPT_NARRATIVE
                        }]);
                    }}/>
            <Button label='Finish'
                    icon={ICON_OK}
                    disabled={!props.finishEnabled}
                    onClick={() => {
                        props.onFinish(props.payment);
                    }}/>
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.identity.id)}/>
        </div>;

    return (
        <Dialog style={{width: "50%"}}
                header={TB_RECEIPT_CHEQUE.header}
                footer={footer}
                visible={true}
                modal={true}
                resizable={false}
                onHide={e => props.onHideDialog(props.identity.id)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <label>{TB_RECEIPT_CHEQUE.text}</label>
                </div>

                {optionalAmountInfo(props)}

                <div className="p-col-3">
                    <label>Sort Code</label>
                </div>
                <div className="p-col-1">
                    <InputText type="text"
                               keyfilter="pint"
                               disabled={false}
                               value={sortCode[0]}
                               onChange={(e) => {
                                   if (e.target.value.length < 3) {
                                       props.onChange({
                                           owner: 'payment.sortCode',
                                           value: `${e.target.value}-${sortCode[1]}-${sortCode[2]}`
                                       })
                                   }
                               }}
                    />
                </div>
                <div className="p-col-1">
                    <InputText type="text"
                               keyfilter="pint"
                               disabled={false}
                               value={sortCode[1]}
                               onChange={(e) => {
                                   if (e.target.value.length < 3) {
                                       props.onChange({
                                           owner: 'payment.sortCode',
                                           value: `${sortCode[0]}-${e.target.value}-${sortCode[2]}`
                                       })
                                   }
                               }}
                    />
                </div>
                <div className="p-col-1">
                    <InputText type="text"
                               keyfilter="pint"
                               disabled={false}
                               value={sortCode[2]}
                               onChange={(e) => {
                                   if (e.target.value.length < 3) {
                                       props.onChange({
                                           owner: 'payment.sortCode',
                                           value: `${sortCode[0]}-${sortCode[1]}-${e.target.value}`
                                       })
                                   }
                               }}
                    />
                </div>
                <div className="p-col-6"/>

                <div className="p-col-3">
                    <label>Cheque No.</label>
                </div>
                <div className="p-col-9">
                    {inputText(payProps, 'chequeNumber', '', false, false)}
                </div>
            </div>
        </Dialog>
    )
};