import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";

import {HM_SEND_SMS} from "../../../../Constants";
import {InputText} from "primereact/components/inputtext/InputText";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";

export default class SendSMS extends Component {

    constructor(props) {
        super(props);

        this.state = {
            details: {
                sentById: props.loginIdentity.id,
                patientId: props.patientId,
                patientFullname: this.props.patientFullname,
                mobile: props.mobile,
                message: '',
            },

            firstNote: 0,
            noteRows: 5,

            selectedNote: null,

            globalFilter: '',
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onDoubleClick = (e) => {

        const details = {...this.state.details};
        details.message = `${details.message} ${e.data.content}`;

        this.setState({details});
    }

    render() {

        const blockSendOK = this.state.details.mobile.trim() === '' || this.state.details.message.trim() === '';

        const header = `${HM_SEND_SMS.header} : ${this.state.details.patientFullname}`;

        const footer = (
            <div>
                <Button label="Send"
                        icon='fas fa-check'
                        onClick={() => {
                            this.props.onOkDialog(this.state.details);
                        }}
                        disabled={blockSendOK}
                />
                <Button label="Cancel"
                        icon='fa fa-times'
                        onClick={() => {
                            this.props.onHideDialog(HM_SEND_SMS.id);
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'details',
            details: this.state.details,
        };

        let templates = _.filter(this.props.templateNotes, template => {
            const pattern = `${template.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'name', 'asc');

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, first: 0});
                       }}
                       placeholder="Search"
                       autoFocus
            />
            <Dropdown options={[]}/>
        </div>;

        return (
            <Dialog style={{width: "50%"}}
                    header={header}
                    footer={footer}
                    visible={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_SEND_SMS.id)
                    }}>
                <div className='p-grid'>
                    <div className="p-col-7">
                        <div className='p-grid p-fluid'>
                            <div className="p-col-3">
                                <label>Mobile Number</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'mobile', '', true, false)}
                            </div>
                            <div className="p-col-3">
                                <label>Message</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'message', 7, 30, false, false)}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-5">
                        <DataTable header={tableHeader}
                                   value={sortedTemplates}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.noteRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'firstNote', 'noteRows')}
                                   first={this.state.firstNote}
                                   selection={this.state.selectedNote}
                                   onRowDoubleClick={this.onDoubleClick}
                                   style={{fontSize: 'small'}}
                        >
                            <Column field="name"/>
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        )
    }
};
