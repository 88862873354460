import React from 'react';
import moment from "moment";
import {BaseComponent} from "./BaseComponent";
import {Document, Page, pdfjs} from "react-pdf";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "./FixedItems/Housekeeping/Constants";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Button} from "primereact/components/button/Button";
import {
    HM_ShowAdjustment,
    HM_ShowCreditNote,
    HM_ShowInvoice,
    HM_ShowPayment,
    HM_ShowReceipt,
    HM_ShowRefund,
    JSON_DATE_FORMAT
} from "../Constants";
import {
    ICON_DOWNLOAD,
} from "../icons";
import {Panel} from "primereact/panel";
import {
    SM_PATIENT_ACCOUNT_HISTORY_PDF,
    SM_PATIENT_ACCOUNT_ITEM_PDF,
    SM_PATIENT_APPOINTMENT_HISTORY_PDF,
    SM_PATIENT_DOCUMENT_PDF,
    SM_PATIENT_NOTE_HISTORY_PDF,
    SM_PAYMENT_PLANS_ACTIVE,
    SM_PAYMENT_PLANS_FINISHED,
    SM_PAYMENT_PLANS_SUSPENDED,
    SM_PAYMENT_PLANS_WRITTEN_OFF,
    SM_REPORTS_ACCOUNTS_IN_CREDIT_PDF,
    SM_REPORTS_DAILY_CANCELS_PDF,
    SM_REPORTS_DAILY_CASH_PDF,
    SM_REPORTS_DAILY_DELETES_PDF,
    SM_REPORTS_DAILY_TRANSACTIONS_PDF,
    SM_REPORTS_INCOMPLETE_CHARGES_PDF,
    SM_REPORTS_MONTHS_CASH_PDF,
    SM_REPORTS_OUTSTANDING_ACCOUNTS_PDF,
    SM_REPORTS_PATIENT_JOURNEY_PDF,
    SM_REPORTS_PATIENT_JOURNEY_SS_PDF,
    SM_REPORTS_PAYMENT_PLANS_PDF,
    SM_REPORTS_UNINVOICED_CHARGES_PDF,
    SM_REPORTS_VOIDED_TRANSACTIONS_PDF,
    SM_REPORTS_WEEKLY_CANCELS_PDF,
    SM_REPORTS_WEEKLY_DELETES_PDF,
    SM_REPORTS_WEEKLY_FAILS_PDF, SM_REPORTS_WEEKS_SHARE_PDF,
    SM_REPORTS_WRITTEN_OFFS_PDF,
    SM_REPORTS_YEARS_CASH_PDF,
    SM_XRAY_REPORT_PDF
} from "../actions/stateManagement";
import printJS from "print-js";
import {RES_REPORT_ACC_ITEMS} from "../actions/reports";
import {SM_CLIENT_TREATMENT_PLAN_PDF} from "./Client/Constants";

export class PDFBaseComponent extends BaseComponent {

    constructor(props) {
        super(props);

        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        this.exitState = TAB_PARENT_CHANGE;

        this.ref = React.createRef();
    }

    componentDidMount() {

        if (!this.props.PDFLoaded) {

            let params = {};

            switch (this.state.pdfType) {

                case SM_REPORTS_PATIENT_JOURNEY_SS_PDF.id:
                case SM_REPORTS_ACCOUNTS_IN_CREDIT_PDF.id:
                case SM_REPORTS_UNINVOICED_CHARGES_PDF.id:
                case SM_REPORTS_INCOMPLETE_CHARGES_PDF.id:
                    this.props.getReport(params);
                    break;
                case SM_XRAY_REPORT_PDF.id:
                case SM_REPORTS_DAILY_CANCELS_PDF.id:
                case SM_REPORTS_DAILY_DELETES_PDF.id:
                case SM_REPORTS_WEEKLY_CANCELS_PDF.id:
                case SM_REPORTS_WEEKLY_DELETES_PDF.id:
                case SM_REPORTS_WEEKLY_FAILS_PDF.id: {
                    params = {date: this.props.date};
                    this.props.getReport(params);
                    break;
                }
                case SM_REPORTS_DAILY_CASH_PDF.id:
                case SM_REPORTS_YEARS_CASH_PDF.id:
                case SM_REPORTS_DAILY_TRANSACTIONS_PDF.id:
                case SM_REPORTS_WRITTEN_OFFS_PDF.id:
                case SM_REPORTS_MONTHS_CASH_PDF.id:
                case SM_REPORTS_WEEKS_SHARE_PDF.id:
                case SM_REPORTS_VOIDED_TRANSACTIONS_PDF.id: {
                    params = {date: this.props.date, id: this.props.selectedAccountGroup.id};
                    this.props.getReport(params);
                    break;
                }
                case SM_REPORTS_PATIENT_JOURNEY_PDF.id:
                    params = {
                        performerId: this.props.performerId,
                        stageId: this.props.stageId,
                    };
                    this.props.getReport(params);
                    break;
                case SM_PATIENT_ACCOUNT_ITEM_PDF.id: {

                    const {report, reportFunction, itemId, period, target} = this.props.options;
                    let targetReport = '';
                    switch (target) {
                        case HM_ShowInvoice.id:
                            params = {report, reportFunction, id: itemId, period}
                            targetReport = RES_REPORT_ACC_ITEMS.INV;
                            break;
                        case HM_ShowReceipt.id:
                            params = {report, reportFunction, id: itemId}
                            targetReport = RES_REPORT_ACC_ITEMS.RCT;
                            break;
                        case HM_ShowRefund.id:
                            params = {report, reportFunction, id: itemId}
                            targetReport = RES_REPORT_ACC_ITEMS.REF;
                            break;
                        case HM_ShowPayment.id:
                            params = {report, reportFunction, id: itemId}
                            targetReport = RES_REPORT_ACC_ITEMS.PAY;
                            break;
                        case HM_ShowCreditNote.id:
                            params = {report, reportFunction, id: itemId}
                            targetReport = RES_REPORT_ACC_ITEMS.CRN;
                            break;
                        case HM_ShowAdjustment.id:
                            params = {report, reportFunction, id: itemId}
                            targetReport = RES_REPORT_ACC_ITEMS.ADJ;
                            break;
                        default:
                            break;
                    }
                    this.props.getReport(targetReport, params);
                    break;
                }
                case SM_REPORTS_OUTSTANDING_ACCOUNTS_PDF.id:
                    params = {agId: this.props.agId};
                    this.props.getReport(params);
                    break;
                case SM_PATIENT_APPOINTMENT_HISTORY_PDF.id:
                    params = {patientId: this.props.patientId};
                    this.props.getReport(params);
                    break;
                case SM_PATIENT_DOCUMENT_PDF.id:

                    const key = Math.random().toString(36).slice(2);
                    const {filename, type, docId} = this.props.options;
                    params = {docId, filename, type, key};
                    this.props.getReport(params);
                    break;
                case SM_PAYMENT_PLANS_ACTIVE.id:
                case SM_PAYMENT_PLANS_SUSPENDED.id:
                case SM_PAYMENT_PLANS_WRITTEN_OFF.id:
                case SM_PAYMENT_PLANS_FINISHED.id:
                    this.props.getReport(params);
                    break;
                case SM_REPORTS_PAYMENT_PLANS_PDF.id:
                    params = {
                        id: this.props.planId,
                        planId: this.props.planId,
                        templateId: this.props.templateId,
                    };
                    this.props.getReport(params);
                    break;
                case SM_CLIENT_TREATMENT_PLAN_PDF.id:
                    params = {
                        id: this.props.planId,
                        planId: this.props.planId,
                        templateId: this.props.templateId,
                    };
                    this.props.getReport(params);
                    break;
                case SM_PATIENT_ACCOUNT_HISTORY_PDF.id:
                    params = {
                        patientId: this.props.details.patientId,
                        id: this.props.details.patientId,
                        pdfType: this.props.details.type,
                        startDate: moment(this.props.details.startDate).format(JSON_DATE_FORMAT),
                        endDate: moment(this.props.details.endDate).format(JSON_DATE_FORMAT),
                        agId: this.props.details.accountGroup.id,
                    }
                    this.props.getReport(params);
                    break;
                case SM_PATIENT_NOTE_HISTORY_PDF.id:
                    params = {
                        patientId: this.props.details.patientId,
                        id: this.props.details.patientId,
                        pdfType: this.props.details.type,
                        dateRange: this.props.details.dateRange,
                        startDate: moment(this.props.details.startDate).format(JSON_DATE_FORMAT),
                        endDate: moment(this.props.details.endDate).format(JSON_DATE_FORMAT),
                        patientNote: this.props.details.patientNote,
                        treatmentNote: this.props.details.treatmentNote,
                        financialNote: this.props.details.financialNote,
                    }
                    this.props.getReport(params);
                    break;
                default:
                    break;
            }
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    onPrintReport(title) {

        try {
            printJS({
                printable: this.arrayBufferToBase64(this.state.pdfBuffer),
                type: 'pdf',
                base64: true,
                showModal: 'true',
                documentTitle: title,
            });
        } catch (e) {
        }
    }

    onDownload = (title) => {

        let url = window.URL.createObjectURL(new Blob([this.state.pdfBuffer]));
        let a = document.createElement('a');
        a.href = url;
        a.download = `${title}${moment(this.props.date).format('YYYY_MM_DD')}.pdf`;
        a.click();
    }

    onDocumentLoadSuccess = ({numPages}) => {

        if (this.state.fullyLoaded) return;
        
        if (!this.state.numPages) {
            this.setState({numPages});
        } else {
            this.setState({fullyLoaded: true})
        }
    };

    onLoadError(error) {
        console.log(error);
    }

    createPDFToolBar = (tooltip, filename) => {

        return (
            <Toolbar
                    left={
                        <Button tooltip={`Download ${tooltip}`}
                                tooltipOptions={{position: 'top'}}
                                onClick={() => this.onDownload(filename)}
                                icon={ICON_DOWNLOAD}/>
                    }
                    right={
                        <Button className="p-button-danger"
                                icon="fa fa-times"
                                onClick={() => {
                                    const itemId = isNaN(this.props.id) ? this.props.id : this.props.id.toString();
                                    this.props.onCloseClick({key: itemId});
                                }}/>
                    }
            />
        )
    }

    createPDFHeader(numPages, index, header) {

        return <div className='panel-header-centered-content'><label id='panel-header'>{header}</label>
            <div className="p-toolbar-group-right">
                <label id='panel-header'>{`Page ${index + 1} of ${numPages}`}</label>
            </div>
        </div>;

    }

    createPDFContainer = (header) => {

        const parameterObject = {
            data: this.state.pdfBuffer,
        };

        return (
            <Document
                file={parameterObject}
                onLoadSuccess={this.onDocumentLoadSuccess}
                onLoadError={this.onLoadError}
                onItemClick={() => {
                }}
            >
                {
                    Array.from(
                        new Array(this.state.numPages),
                        (el, index) => (
                            <Panel header={this.createPDFHeader(this.state.numPages, index, header)}
                                   key={`panel_${index + 1}`}
                                   style={{paddingTop: '10px'}}>
                                <Page key={`page_${index + 1}`}
                                      pageNumber={index + 1}
                                      width={900}
                                      renderTextLayer={false}
                                />
                            </Panel>
                        ),
                    )
                }
            </Document>
        )
    }

    onExit = () => {

        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: this.props.id});
    }

    _componentWillUnmount(action) {

        window.removeEventListener('resize', this.updateWindowDimensions);

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest(action);
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}
