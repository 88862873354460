import React from 'react';
import _ from "lodash";
import {connect} from "react-redux";

import {Column} from "primereact/column";
import {ContextMenu} from "primereact/contextmenu";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {CLINICIAN, JSON_DATE_FORMAT, SU_STATUS_ACTIVE, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_EXCEL, ICON_HELP, ICON_PRINT, ICON_SCREEN,} from "../../../icons";
import {CONST_FEMALE} from "../../PatientDynamicItems/OnChangeUtils";
import * as Actions from "../../../actions";
import {dateTemplateMonthYear, dateTemplateShort, durationTemplate} from "../../PatientDynamicItems/Utils";
import {TB_PATIENT_DETAILS} from "../PatientDetails/Constants";
import {ProgressBar} from "primereact/progressbar";
import {HELP_REP_COP9, setState} from "../../../actions/stateManagement";
import {ac} from "../../../index";
import {getResource as getCHResource, RES_chartResources} from "../../../actions/ChartResources";
import {CopReport9Headings} from "./Constants";
import {getAllUsers} from "../../../actions/users";
import {getReport, RES_REPORT_COP_REPORT_9, RES_REPORT_DAILYCASH} from "../../../actions/reports";
import moment from "moment";
import {Calendar} from "primereact/calendar";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2, tb_boilerPlateRightText} from "../../Utils";
import {DIARY_BACK_MONTH, DIARY_FOR_MONTH} from "../Diary/Constants";
import {TextCop9Report, TextCop9XLS} from "../../../Messages";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import {showPatientDetailsPage} from "../PatientDetails/Utils";

export class ConnectedCopReport9 extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {
                currentDate: new Date(),

                copReport9: [],
                codeTotal: 0,
                patientTotal: 0,

                favourites: [],
                providers: [],

                selections: {
                    favourite: {items: []},
                    code: null,
                    provider: {id: -1},
                    copReport9: {id: null, gender: CONST_FEMALE},
                },

                first: 0,
                rows: 5,
            }
        }

        this.onChange = this.onChange.bind(this);
        this.showDialogs = this.showDialogs.bind(this);
        this.buildMenu = this.buildMenu.bind(this);

        this.toolbarCallbacks = {

            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
        }
    }

    componentDidMount() {

        this.props.getAllUsersShort();
        this.props.getChartResources();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_CHART_RESOURCES:

                    let allCodes = [];
                    this.props.resources.favourites.forEach(favourite => {
                        allCodes = _.concat(allCodes, favourite.items);
                    });

                    const All = {
                        id: -1,
                        mc: ac.getMcId(),
                        description: 'All',
                        items: allCodes,
                        default: true,
                    };

                    const favourites = [All, ...this.props.resources.favourites];

                    let providers = _.filter(this.props.usersShort, user => {
                        return user.status === SU_STATUS_ACTIVE;
                    });

                    const AllProviders = {
                        id: -1,
                        mc: ac.getMcId(),
                        fullName: 'All',
                        status: SU_STATUS_ACTIVE,
                        default: true,
                    };

                    const allProviders = [AllProviders, ...providers];

                    const selections = {...this.state.selections};
                    selections.favourite = _.find(favourites, favourite => favourite.default);

                    this.setState({
                        providers: allProviders,
                        favourites,
                        selections,
                    });
                    break;
                case Actions.RECEIVE_REPORT_COP9:

                    let codeTotal = 0;
                    this.props.copReport9.forEach(code => codeTotal += code.quantity);

                    const patientTotal = _.size(_.countBy(this.props.copReport9, (usage) => {
                        return usage.patientId
                    }));

                    this.setState({copReport9: this.props.copReport9, codeTotal, patientTotal});
                    break;
                default:
                    break;
            }
        }
    }

    onDownload = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_REPORT_COP_REPORT_9.XLS.url}?mcId=${ac.getMcId()}&currentItem=${this.state.selections.code.id}&currentProvider=${this.state.selections.provider.id}&date=${moment(this.state.currentDate).format(JSON_DATE_FORMAT)}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `cop9${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                    a.click();
                });
            });
    }

    onShowDetails = () => {

        const {patientId, firstName, lastName, gender, nhsPatient, groupId} = this.state.selections.copReport9;
        showPatientDetailsPage({props: this.props, id: patientId, firstName, lastName, gender, nhsPatient, groupId});
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {
            case 'selections.favourite' :
                state.selections.favourite = event.value;
                break;
            case 'selections.code' :
                state.selections.code = event.value;
                break;
            case 'selections.provider' :
                state.selections.provider = event.value;
                break;
            case 'selections.copReport9' :
                state.selections.copReport9 = event.value;
                this.setState({state})
                return;
            case 'currentDate' :
                state.currentDate = event.value;
                break;
            case 'back' :
                state.currentDate = moment(state.currentDate).add(-1, 'M').toDate()
                break;
            case 'forward' :
                state.currentDate = moment(state.currentDate).add(1, 'M').toDate()
                break;
            default:
                break;
        }

        state.first = 0;
        state.selections.copReport9 = {id: null, gender: CONST_FEMALE};

        this.setState(state, () => {
            if (this.state.selections.code != null && this.state.selections.provider != null) {
                this.props.getReport({
                    currentItem: this.state.selections.code.id,
                    currentProvider: this.state.selections.provider.id,
                    date: moment(this.state.currentDate).format('YYYY-MM-DD'),
                });
                this.props.setState(this.props.id, this.state);
            }
        });
    }

    patientTemplate(row) {
        return `${row.firstName} ${row.lastName}`.trim();
    }

    providerTemplate(row) {
        return `${row.suFirstName} ${row.suLastName}`.trim();
    };

    buildMenu() {

        if (this.state.selections.patientJourney === null)
            return [];

        const detailsIcon = this.state.selections.copReport9.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;

        const items = [
            {
                label: TB_PATIENT_DETAILS.text, icon: detailsIcon, command: (e) => {
                    const {
                        patientId,
                        firstName,
                        lastName,
                        gender,
                        nhsPatient
                    } = this.state.selections.copReport9;
                    const patientDetails = {id: patientId, firstName, lastName, gender, nhsPatient};
                    this.toolbarCallbacks[TB_PATIENT_DETAILS.id](patientDetails);
                }
            }];
        return items;
    }

    showDialogs() {

        const contents = [];
        return contents;
    }

    allocatedToTemplate = (row) => {

        return `${row.atFirstName} ${row.atLastName} (${row.atUsername})`;
    }

    render() {

        if (!this.props.resourcesLoaded || !this.props.usersLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const providers = _.filter(this.state.providers, provider => provider.status === SU_STATUS_ACTIVE && provider.username !== 'superuser' && provider.username !== 'TED_superuser' && provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);
        const provider = this.state.selections.provider === null ? undefined : _.find(providers, provider => (provider.id === this.state.selections.provider.id));

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title'
                      style={{marginRight: 15}}>Croydon Report 9 : {`(${dateTemplateMonthYear(this.state.currentDate)})`}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <label id='panel-header'>{` Favourites `}</label>
                <Dropdown id="pj1"
                          placeHolder='Favourites'
                          value={this.state.selections.favourite}
                          options={this.state.favourites}
                          optionLabel={`description`}
                          onChange={e => {
                              this.onChange({owner: `selections.favourite`, value: e.value})
                          }}
                />
                <label id='panel-header'>{` Treatment Code `}</label>
                <Dropdown id="pj1"
                          placeHolder='Treatment Code'
                          value={this.state.selections.code}
                          options={this.state.selections.favourite.items}
                          optionLabel={`description`}
                          onChange={e => {
                              this.onChange({owner: `selections.code`, value: e.value})
                          }}
                />
                <label id='panel-header'>{` Clinician `}</label>
                <Dropdown id='pj2'
                          placeHolder='Clinician'
                          value={provider}
                          options={providers}
                          optionLabel={`fullName`}
                          onChange={e => {
                              this.onChange({owner: `selections.provider`, value: e.value})
                          }}
                />
            </div>
        </div>;

        const sortedCopReport9 = _.orderBy(this.state.copReport9, [(o) => {
            return moment(o.chargedOn).format('YYYY-MM-DDTHH:mm');
        }, 'lastName'], ['asc', 'asc']);

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate(() => this.onChange({owner: 'back'}), DIARY_BACK_MONTH.icon, DIARY_BACK_MONTH.tooltip, key++)}
                            {tb_boilerPlate(() => this.onChange({owner: 'forward'}), DIARY_FOR_MONTH.icon, DIARY_FOR_MONTH.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="mm/yy"
                                      view='month'
                                      onChange={(e) => this.onChange({owner: 'currentDate', value: e.value})}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRightText(key++, `No of Patients ${this.state.patientTotal}`, true)}
                            {tb_boilerPlateRightText(key++, `Code Count ${this.state.codeTotal}`, true)}
                            {tb_boilerPlateRight2(this.onShowReport, ICON_SCREEN, 'Show ' + TextCop9Report, key++, false)}
                            {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextCop9Report, key++, false)}
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${TextCop9XLS}`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_COP9), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                        <ContextMenu style={{width: '210px'}} model={this.buildMenu()} ref={el => this.cm = el}/>

                        <DataTable value={sortedCopReport9}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selections.copReport9}
                                   onSelectionChange={e => this.onChange({
                                       owner: `selections.copReport9`,
                                       value: e.value
                                   })}
                                   contextMenuSelection={this.state.selections.patient}
                                   onContextMenuSelectionChange={e => this.onChange({
                                       owner: `selections.copReport9`,
                                       value: e.value
                                   })}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent)
                                   }}
                        >

                            <Column body={row => this.patientTemplate(row)}
                                    header={CopReport9Headings[0]}
                                    style={{width: '20%'}}
                            />
                            <Column body={row => dateTemplateShort(row.chargedOn)}
                                    header={CopReport9Headings[1]}
                                    style={{width: '20%'}}
                            />
                            <Column body={row => this.providerTemplate(row)}
                                    header={CopReport9Headings[2]}
                                    style={{width: '20%'}}
                            />
                            <Column body={row => dateTemplateShort(row.dateOfBirth)}
                                    header={CopReport9Headings[3]}
                                    style={{width: '10%'}}/>
                            <Column field="quantity"
                                    header={CopReport9Headings[4]}
                                    style={{width: '10%', textAlign: 'right'}}/>
                            <Column body={this.allocatedToTemplate}
                                    header={CopReport9Headings[5]}
                                    style={{width: '20%'}}/>
                            <Column body={row => durationTemplate(row.startdt, row.enddt)}
                                    header={CopReport9Headings[6]}
                                    style={{width: '20%', textAlign: 'right'}}/>
                        </DataTable>

                        {this.showDialogs()}
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        resourcesLoaded: state.chartResources.resourcesLoaded,
        resources: state.chartResources.resourcesLoaded ? state.chartResources.resources.resources : null,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        copReport9Loaded: state.reports.copReport9Loaded,
        copReport9: state.reports.copReport9,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {

        getAllUsersShort: () => dispatch(getAllUsers()),
        getChartResources: () => dispatch(getCHResource(RES_chartResources.GET)),
        getReport: (params) => dispatch(getReport(RES_REPORT_COP_REPORT_9.GET, params)),

        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const CopReport9 = connect(mapStateToProps, mapDispatchToProps)(ConnectedCopReport9);

export default CopReport9;
