import React from 'react';
import {connect} from "react-redux";
import Websocket from 'react-websocket';
import {
    handleAppointmentMessage,
    handleInvoiceMessage,
    handleSDPMessage,
    handleUpdateMessage,
    handleUserMessage
} from "../actions/websockets";
import {ac} from "../index";
import {SM_CLOUD_LOGGED_IN} from "../actions/stateManagement";
import {HM_LOGOUT} from "../Constants";
import {doLogout} from "../actions/login";
import * as Actions from "../actions";

class ConnectedWebsocketHandler extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userTopicWSState: false,
            messages: [],
            loginSent: false,
        }
        this.userTopicWS = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.messageToSend && (this.props.messageToSend !== prevProps.messageToSend)) {

            try {
                if (this.state.userTopicWSState) {
                    this.sendMessage(this.props.messageToSend.message)
                } else {
                    this.state.messages.push(this.props.messageToSend.message);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    sendMessage = (message) => {

        message.uuid = ac.getUUID();

        switch (message.type) {
            case SM_CLOUD_LOGGED_IN.id:

                if (!this.state.loginSent) {
                    this.setState({loginSent: true}, () => {
                        this.userTopicWS.sendMessage(JSON.stringify(message));
                    })
                }
                break;
            case HM_LOGOUT.id:

                this.props.doLogout();
                window.location.hash = '/';
                this.userTopicWS.sendMessage(JSON.stringify(message));
                break;
            default:
                this.userTopicWS.sendMessage(JSON.stringify(message));
                break;
        }
    }

    onError(error) {
        throw new Error(error)
    }

    onOpenUpdatesTopic() {
    }

    onOpenAppointmentTopic() {
    }

    onOpenInvoiceTopic() {
    }

    onOpenSDP() {
    }

    onOpenUserTopic = () => {

        const messages = [...this.state.messages];

        this.setState({userTopicWSState: true, messages: []}, () => {
            while (messages.length > 0) {
                this.sendMessage(messages.pop());
            }
        })
    }

    onCloseUpdatesTopic() {
    }

    onCloseAppointmentTopic() {
    }

    onCloseInvoiceTopic() {
    }

    onCloseSDP() {
    }

    onCloseUserTopic = () => {
        this.setState({userTopicWSState: false});
    }

    render() {
        try {

            return (
                <div>
                    <Websocket url={ac.getBASEWSURL() + '/UpdatesTopic'}
                               onOpen={this.onOpenUpdatesTopic}
                               onError={this.onError}
                               onMessage={this.props.handleUpdateMessage.bind(this)}
                               onClose={this.onCloseUpdatesTopic}
                    />
                    <Websocket url={ac.getBASEWSURL() + '/AppointmentTopic'}
                               onOpen={this.onOpenAppointmentTopic}
                               onError={this.onError}
                               onMessage={this.props.handleAppointmentMessage.bind(this)}
                               onClose={this.onCloseAppointmentTopic}
                    />
                    <Websocket url={ac.getBASEWSURL() + '/InvoiceTopic'}
                               onOpen={this.onOpenInvoiceTopic}
                               onError={this.onError}
                               onMessage={this.props.handleInvoiceMessage.bind(this)}
                               onClose={this.onCloseInvoiceTopic}
                    />
                    <Websocket url={ac.getBASEWSURL() + '/UsersTopic'}
                               onOpen={this.onOpenUserTopic}
                               onError={this.onError}
                               onMessage={this.props.handleUserMessage.bind(this)}
                               onClose={this.onCloseUserTopic}
                               ref={(ref => {
                                   this.userTopicWS = ref;
                               })}
                    />
                    <Websocket url={ac.getBASEWSURL() + '/SDP'}
                               onOpen={this.onOpenSDP}
                               onError={this.onError}
                               onMessage={this.props.handleSDPMessage.bind(this)}
                               onClose={this.onCloseSDP}
                    />
                </div>
            );
        } catch (error) {
            return <h1>{`Websocket connect error : ${error}`}</h1>;
        }
    }
}

const mapStateToProps = (state) => {

    return {
        messageToSend: state.websockets.userMessageToSend,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleUpdateMessage: (data) => dispatch(handleUpdateMessage(data)),
        handleAppointmentMessage: (data) => dispatch(handleAppointmentMessage(data)),
        handleInvoiceMessage: (data) => dispatch(handleInvoiceMessage(data)),
        handleUserMessage: (data) => dispatch(handleUserMessage(data)),
        handleSDPMessage: (data) => dispatch(handleSDPMessage(data)),
        doLogout: () => dispatch(doLogout(Actions.LOGOUT)),
    };
};

const WebsocketHandler = connect(mapStateToProps, mapDispatchToProps)(ConnectedWebsocketHandler);

export default WebsocketHandler;
