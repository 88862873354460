import React, {Component} from 'react';
import _ from 'lodash';
import {PALETTE_GRP_TP_APP, SELECTED_ITEM_COLOUR} from "../Constants";
import {COLOR_ChiralPalette} from "../../../Client/Constants";

export default class TPPlaceAppPalette extends Component {

    constructor(props) {
        super(props);

        const unplacedAppointment = {...props.unplacedAppointment};
        const {patient} = unplacedAppointment;

        this.state = {

            selectedItem: unplacedAppointment.appointmentId,
            unplacedAppointment,

            diaryPaletteGroups: [
                {
                    label: `Place Appointment for : ${patient.firstName} ${patient.lastName}`,
                    open: true,
                    type: PALETTE_GRP_TP_APP,
                    contents: []
                },
            ],
        };

        this.paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};
    }

    componentDidMount() {

        this.props.onEventSelected({
            paletteType: PALETTE_GRP_TP_APP,
            id: this.state.unplacedAppointment.appointmentId,
            unplacedAppointment: this.state.unplacedAppointment,
        });
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {
        }
    }

    changeGroupState = (group) => {

        const diaryPaletteGroups = _.cloneDeep(this.state.diaryPaletteGroups);

        let thisGroup = _.find(diaryPaletteGroups, (target) => {
            return group.label === target.label;
        });
        thisGroup.open = !thisGroup.open;

        this.setState({diaryPaletteGroups});
    }

    insertTPAppointment = (group) => {

        const results = [];

        let key = 1000;

        if (group.open) {

            const {unplacedAppointment} = this.state;
            let backgroundColor = unplacedAppointment.appointmentId === this.state.selectedItem ? SELECTED_ITEM_COLOUR : null;

            results.push(
                <div key={`itpa_${key++}`}
                     className='event26'
                     id={unplacedAppointment.appointmentId}
                     onClick={(event) => {
                         this.props.onEventSelected({
                             paletteType: PALETTE_GRP_TP_APP,
                             id: unplacedAppointment.appointmentId,
                             unplacedAppointment
                         });
                         this.setState({selectedItem: parseInt(event.target.id, 10)})
                     }}
                     style={{fontWeight: 'bold', ...this.paddingStyle, backgroundColor}}>
                        <span className='fas fa-calendar-plus'
                              style={{
                                  fontSize: '20px',
                                  color: COLOR_ChiralPalette,
                                  marginRight: '5px',
                                  marginTop: '5px'
                              }}/>
                    {`${unplacedAppointment.appointmentWith.username} : ${unplacedAppointment.title} : ${unplacedAppointment.shortDescription}`}
                </div>
            )
        }
        return results;
    };

    buildPalette = () => {

        let results = [];

        let key = Math.floor(Math.random() * 1000);

        this.state.diaryPaletteGroups.forEach((group, index) => {

            let groupContent = [];
            let groupCount = 0;

            switch (group.type) {
                case PALETTE_GRP_TP_APP:

                    groupContent = this.insertTPAppointment(group);
                    break;
                default:
                    break;
            }

            results.push(
                <div key={key++}
                     style={{backgroundColor: '#f5f5f5', borderTop: '1px solid grey', borderBottom: '1px dotted grey'}}
                     onClick={(item) => {
                         this.changeGroupState(group);
                     }}
                >
                    <i style={this.paddingStyle}
                         className="fa icons8Open"/>
                    {(groupCount === 0 || group.open) ? group.label : `${group.label}+`}
                </div>
            );
            results = [...results, ...groupContent];
        });
        return results;
    };

    render() {

        return (
            <div className='p-sm-2' style={{display: 'flex', flexFlow: 'column'}}>
                <div style={{backgroundColor: '#f5f5f5', borderBottom: '1px solid grey'}}>
                    <i style={this.paddingStyle}
                         className="fa paletteView16"/>Place Planned Appointment
                </div>
                <div className="diaryPaletteEntry"
                     style={{fontWeight: 'bold'}}
                     onClick={(item) => {
                         this.setState({selectedItem: null});
                         this.props.onReturnFromPlacement();
                     }}
                >
                    <i style={this.paddingStyle}
                         className="fa arrow16"/>Cancel Placement
                </div>
                {this.buildPalette(this.props)}
            </div>
        )
    };
}
