import _ from 'lodash';
import * as Actions from '../actions/index';
import {
    SM_LABORATORY_DETAILS_DATA,
    SM_LABORATORY_PATIENT_DATA,
    SM_PATIENT_FOR_APPS,
    SM_PATIENT_LAB_HISTORY
} from "../actions/stateManagement";
import {RES_TREATMENT_PLAN_BY_APP_ID} from "../actions/treatmentPlanning";

const initialState = {

    // Referrer data
};

export const laboratories = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Referrer Related
            case Actions.RECEIVE_LABORATORY_DETAILS:

                const laboratoryDataLoadedId = `${SM_LABORATORY_DETAILS_DATA.loaded}_${action.id}`;
                const laboratoryDataId = `${SM_LABORATORY_DETAILS_DATA.id}_${action.id}`;

                return {
                    ...state,

                    [laboratoryDataLoadedId]: true,
                    [laboratoryDataId]: action.payload,
                }

            case Actions.RECEIVE_LABORATORY_PATIENTS: {

                const laboratoryPatientLoadedId = `${SM_LABORATORY_PATIENT_DATA.loaded}_${action.id}`;
                const laboratoryPatientId = `${SM_LABORATORY_PATIENT_DATA.id}_${action.id}`;

                return {
                    ...state,

                    [laboratoryPatientLoadedId]: true,
                    [laboratoryPatientId]: action.payload,
                };
            }
            case Actions.CLEAR_LABORATORY_DETAILS:
            case Actions.SAVE_CLEAR_LABORATORY_DETAILS: {

                let labDetailsState = {...state};

                delete labDetailsState[`${SM_LABORATORY_DETAILS_DATA.loaded}_${action.smRef}`];
                delete labDetailsState[`${SM_LABORATORY_DETAILS_DATA.id}_${action.smRef}`];
                delete labDetailsState[`${SM_LABORATORY_PATIENT_DATA.loaded}_${action.smRef}`];
                delete labDetailsState[`${SM_LABORATORY_PATIENT_DATA.id}_${action.smRef}`];

                return labDetailsState;
            }
            case Actions.RECEIVE_LABORATORY_ADD:

                return {
                    ...state,
                    result: action.payload.result,
                    laboratory: action.payload.laboratory,
                };

            case Actions.REQUEST_FOR_APPOINTMENTS: {

                const patientForAppsLoadedId = `${SM_PATIENT_FOR_APPS.loaded}_${action.patientId}`;
                const patientForAppsId = `${SM_PATIENT_FOR_APPS.id}_${action.patientId}`;

                return {
                    ...state,

                    [patientForAppsLoadedId]: false,
                    [patientForAppsId]: [],
                };
            }

            case Actions.RECEIVE_FOR_APPOINTMENTS: {

                const patientForAppsLoadedId = `${SM_PATIENT_FOR_APPS.loaded}_${action.patientId}`;
                const patientForAppsId = `${SM_PATIENT_FOR_APPS.id}_${action.patientId}`;
                return {
                    ...state,

                    [patientForAppsLoadedId]: true,
                    [patientForAppsId]: action.payload,
                };
            }

            case Actions.RECEIVE_WORK_REQUIRED_REQUEST: {

                const patientLabHistoryLoadedId = `${SM_PATIENT_LAB_HISTORY.loaded}_${action.id}`;
                const patientLabHistoryId = `${SM_PATIENT_LAB_HISTORY.id}_${action.id}`;

                let patientLabHistory = [...state[patientLabHistoryId]];

                if (state[patientLabHistoryLoadedId]) {
                    patientLabHistory.push(action.payload);
                }

                return {
                    ...state,

                    [patientLabHistoryLoadedId]: true,
                    [patientLabHistoryId]: patientLabHistory,
                };
            }

            case Actions.RECEIVE_WORK_REQUIRED_FOR_PATIENT: {

                const patientLabHistoryLoadedId = `${SM_PATIENT_LAB_HISTORY.loaded}_${action.id}`;
                const patientLabHistoryId = `${SM_PATIENT_LAB_HISTORY.id}_${action.id}`;
                return {
                    ...state,

                    [patientLabHistoryLoadedId]: true,
                    [patientLabHistoryId]: action.payload,
                };
            }

            case RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action: {

                // this is just for appointment pages
                let clearLabState = {...state};
                delete clearLabState[`${SM_PATIENT_LAB_HISTORY.loaded}_${action.id}`];
                delete clearLabState[`${SM_PATIENT_LAB_HISTORY.id}_${action.id}`];

                return clearLabState;
            }

            case Actions.RECEIVE_MARK_WORK_RECEIVED: {

                const patientLabHistoryLoadedId = `${SM_PATIENT_LAB_HISTORY.loaded}_${action._labEntry.appointmentId}`;

                if (state[patientLabHistoryLoadedId]) {
                    const patientLabHistoryId = `${SM_PATIENT_LAB_HISTORY.id}_${action._labEntry.appointmentId}`;

                    const history = [...state[patientLabHistoryId]];
                    const index = _.findIndex(history, entry => entry.id === action.payload.id);
                    history[index] = action.payload;

                    return {
                        ...state,

                        [patientLabHistoryLoadedId]: true,
                        [patientLabHistoryId]: history,
                    };
                } else return state;
            }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};