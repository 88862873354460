import React from 'react';

import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';

import {setState, stateRequest} from "../../../actions/stateManagement";
import {connect} from "react-redux";
import {
    HM_notImplemented,
    HM_RESUME_PAYMENT_PLAN,
    HM_SUSPEND_PAYMENT_PLAN,
    HM_WRITE_OFF_PAYMENT_PLAN,
    PP_STATUS_ACCEPTED,
    PP_STATUS_FINISHED,
    PP_STATUS_PROPOSED,
    PP_STATUS_SUSPENDED
} from "../../../Constants";
import {
    ICON_DELETE,
    ICON_EDIT,
    ICON_PLUS,
} from "../../../icons";
import {
    TB_ADD_PAYMENT_PLAN,
    TB_DELETE_PAYMENT_PLAN,
    TB_EDIT_PAYMENT_PLAN,
    TB_PATIENT_PPLANS_RESUME,
    TB_PATIENT_PPLANS_SUSPEND,
    TB_PATIENT_PPLANS_WRITEOFF
} from "../PatientDetails/Constants";
import {getPaymentPlanIds} from "./Utils";
import {dateTemplateShort, dateTemplateShortPP} from "../../PatientDynamicItems/Utils";
import {TabBaseComponent} from "../../TabBaseComponent";

import {
    changeStatus,
    deletePaymentPlan,
    getResource,
    RES_PAYMENT_PLAN_BY_ID,
    RES_PAYMENT_PLAN_HISTORY,
    RES_PAYMENT_PLANNING
} from "../../../actions/paymentPlanning";
import {ProgressBar} from "primereact/progressbar";
import AddPaymentPlan from "./dialogs/AddPaymentPlan";
import {ShowMessageDialog, ShowQuestionDialog} from "../Diary/components/EventComponent";
import {currencyTemplate} from "../fixedItemUtils";
import {DELETE_PAYMENT_PLAN} from "../../../actions";
import ChangePlanStatus from "./dialogs/ChangePlanStatus";
import _ from "lodash";

export class ConnectedPaymentPlanHistory extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.paymentPlanHistoryId,
                first: 0,
                rows: 5,
                canSave: {status: false},
                paymentPlanHistoryLoaded: false,
                selectedPaymentPlan: null,

                showAddPlan: false,

                fakePaymentPlanId: -100,

                [TB_ADD_PAYMENT_PLAN.id]: false,
            }
        }

        this.onSelection = this.onSelection.bind(this);
        this.onRowSelection = this.onRowSelection.bind(this);

        this.showDialogs = this.showDialogs.bind(this);
        this.showContextMenu = this.showContextMenu.bind(this);
        this.buildItems = this.buildItems.bind(this);

        this.onAddPaymentPlan = this.onAddPaymentPlan.bind(this);

        this.onDeletePlan = this.onDeletePlan.bind(this);

        this.suspend = this.suspend.bind(this);
        this.resume = this.resume.bind(this);
        this.writeOff = this.writeOff.bind(this);

        this.menuItems = [];

        this.toolbarCallbacks = {

            [TB_ADD_PAYMENT_PLAN.id]: this.onAddPaymentPlan,
            [TB_EDIT_PAYMENT_PLAN.id]: this.onRowSelection,
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getPPHistory(this.props.patientId);
        }
    }

    componentDidUpdate(prevProps, preState, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PAYMENT_PLAN_HISTORY.GET.receive:
                case RES_PAYMENT_PLANNING.SUSPEND_PAT.action:
                case RES_PAYMENT_PLANNING.RESUME_PAT.action:
                case RES_PAYMENT_PLANNING.WRITE_OFF_PAT.action:
                case DELETE_PAYMENT_PLAN:

                    this.setState({
                        paymentPlanHistory: this.props.paymentPlanHistory,
                        paymentPlanHistoryLoaded: this.props.paymentPlanHistoryLoaded
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;

                case RES_PAYMENT_PLAN_BY_ID.ADD.action:

                    this.props.getPPHistory(this.props.patientId);
                    break;

                default:
                    break;
            }
        }
    }

    onRowSelection(e) {
        this.props.onAddPPTab(this.state.selectedPaymentPlan);
    }

    onSelection(e) {

        this.setState({selectedPaymentPlan: e.value}, () => {
            this.props.onChange({owner: 'selectedPaymentPlan', value: this.state.selectedPaymentPlan});
            this.props.setState(this.state.stateManagementId, {...this.state});

            this.buildItems();
        });
    }

    onDeletePlan() {

        this.setState({[TB_DELETE_PAYMENT_PLAN.id]: false}, () => {
            this.props.deletePaymentPlan(this.props.patientId, this.state.selectedPaymentPlan.id);
        });
    }

    suspend(plan) {

        this.onHideMenuEntry(HM_SUSPEND_PAYMENT_PLAN.id)
        this.props.suspend(plan);
    }

    resume(plan) {

        this.onHideMenuEntry(HM_RESUME_PAYMENT_PLAN.id)
        this.props.resume(plan);
    }

    writeOff(plan) {

        this.onHideMenuEntry(HM_WRITE_OFF_PAYMENT_PLAN.id)
        this.props.writeOff(plan);
    }

    showDialogs() {

        if (this.state[TB_ADD_PAYMENT_PLAN.id]) {

            return (
                <AddPaymentPlan patientId={this.props.patientId}
                                fakePaymentPlanId={this.state.fakePaymentPlanId}
                                onOkDialog={this.onAddPaymentPlan}
                                onHideDialog={(target) => this.onHideMenuEntry(target)}
                />
            )
        } else if (this.state[TB_DELETE_PAYMENT_PLAN.id]) {

            return ShowQuestionDialog(this, TB_DELETE_PAYMENT_PLAN, this.onDeletePlan)

        } else if (this.state[HM_SUSPEND_PAYMENT_PLAN.id]) {

            return <ChangePlanStatus paymentPlan={this.state.selectedPaymentPlan}
                                     loginIdentity={this.props.loginIdentity}
                                     onOkDialog={this.suspend}
                                     onHideDialog={this.onHideMenuEntry}
                                     target={HM_SUSPEND_PAYMENT_PLAN}
            />

        } else if (this.state[HM_RESUME_PAYMENT_PLAN.id]) {

            return <ChangePlanStatus paymentPlan={this.state.selectedPaymentPlan}
                                     loginIdentity={this.props.loginIdentity}
                                     onOkDialog={this.resume}
                                     onHideDialog={this.onHideMenuEntry}
                                     target={HM_RESUME_PAYMENT_PLAN}
            />
        } else if (this.state[HM_WRITE_OFF_PAYMENT_PLAN.id]) {

            return <ChangePlanStatus paymentPlan={this.state.selectedPaymentPlan}
                                     loginIdentity={this.props.loginIdentity}
                                     onOkDialog={this.writeOff}
                                     onHideDialog={this.onHideMenuEntry}
                                     target={HM_WRITE_OFF_PAYMENT_PLAN}
            />
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onAddPaymentPlan(paymentPlan) {

        paymentPlan.accountGroup = _.find(this.props.accountGroups, group => group.defaultAG);

        const source = {
            id: this.state.stateManagementId,
            action: RES_PAYMENT_PLAN_BY_ID.ADD.action,
            saveState: true,
            saveObjects: false,
        };

        this.setState({
            [TB_ADD_PAYMENT_PLAN.id]: false,
            fakeTreatmentPlanId: this.state.fakeTreatmentPlanId + 1
        }, () => {
            this.props.stateRequest(source, paymentPlan);
        })
    }

    showContextMenu() {

        if (this.state.selectedPaymentPlan !== null && this.state.selectedPaymentPlan.status !== PP_STATUS_FINISHED.name) {

            return (
                <ContextMenu style={{width: 250}} model={this.menuItems} ref={el => this.cm = el}/>
            )
        }
    }

    buildItems() {

        this.menuItems = [];

        if (this.state.selectedPaymentPlan === null) return;

        switch (this.state.selectedPaymentPlan.status) {

            case PP_STATUS_PROPOSED.name:
                this.menuItems.push(
                    {
                        label: TB_EDIT_PAYMENT_PLAN.text,
                        icon: ICON_EDIT,
                        command: (e) => {
                            this.toolbarCallbacks[TB_EDIT_PAYMENT_PLAN.id](this.state.selectedPaymentPlan);
                        },
                    },
                );
                this.menuItems.push(
                    {
                        label: TB_DELETE_PAYMENT_PLAN.text,
                        icon: ICON_DELETE,
                        command: (e) => {
                            this.onShowMenuEntry({item: {target: TB_DELETE_PAYMENT_PLAN.id}})
                        },
                    }
                )
                break;

            case PP_STATUS_ACCEPTED.name:
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS_SUSPEND.text,
                        icon: TB_PATIENT_PPLANS_SUSPEND.icon,
                        command: (e) => {
                            this.onShowMenuEntry({item: {target: HM_SUSPEND_PAYMENT_PLAN.id}})
                        },
                    }
                )
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS_WRITEOFF.text,
                        icon: TB_PATIENT_PPLANS_WRITEOFF.icon,
                        command: (e) => {
                            this.onShowMenuEntry({item: {target: HM_WRITE_OFF_PAYMENT_PLAN.id}})
                        },
                    }
                )
                break;

            case PP_STATUS_SUSPENDED.name:
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS_RESUME.text, icon: TB_PATIENT_PPLANS_RESUME.icon, command: (e) => {
                            this.onShowMenuEntry({item: {target: HM_RESUME_PAYMENT_PLAN.id}})
                        }
                    },
                );
                this.menuItems.push(
                    {
                        label: TB_PATIENT_PPLANS_WRITEOFF.text,
                        icon: TB_PATIENT_PPLANS_WRITEOFF.icon,
                        command: (e) => {
                            this.onShowMenuEntry({item: {target: TB_PATIENT_PPLANS_WRITEOFF.id}})
                        },
                    }
                )
                break;
            default:
                break;
        }
    }

    render() {

        if (!this.state.paymentPlanHistoryLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const panelHeader = <div className='panel-header-centered-content'><label id='panel-header'>Payment Plans</label>
            <Button tooltip='Add Plan'
                    tooltipOptions={{position: 'right'}}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: TB_ADD_PAYMENT_PLAN.id}})
                    }}>
            </Button>
        </div>;

        const notFinished = this.state.selectedPaymentPlan !== null && this.state.selectedPaymentPlan.status !== PP_STATUS_FINISHED.name;

        const sortedPaymentPlanHistory = _.orderBy(this.state.paymentPlanHistory, 'id', 'desc');

        return (
            <div
                style={{fontSize: 'small'}}
            >
                <Panel header={panelHeader}>

                    {this.showDialogs()}
                    {this.showContextMenu()}

                    <div className="p-grid">
                        <DataTable value={sortedPaymentPlanHistory}
                                   style={{fontSize: 'small'}}
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'first', 'rows')}
                                   first={this.state.first}
                                   selectionMode="single"
                                   onRowDoubleClick={this.onRowSelection}
                                   selection={this.state.selectedPaymentPlan}
                                   onSelectionChange={this.onSelection}
                                   contextMenuSelection={this.state.selectedPaymentPlan}
                                   onContextMenuSelectionChange={this.onSelection}
                                   onContextMenu={e => {
                                       if (notFinished) this.cm.show(e.originalEvent)
                                   }}
                        >
                            <Column field="tpTitle"
                                    header="Treatment Plan" style={{width: '20%'}}/>
                            <Column field="firstInstallment" body={row => dateTemplateShort(row.firstInstallment)}
                                    header="First Instalment" style={{width: '10%'}}/>
                            <Column field="planTotal" body={(row) => currencyTemplate(row, 'planTotal')}
                                    header="Plan Total"
                                    style={{width: '10%', textAlign: 'right'}}/>
                            <Column field="deposit" body={(row) => currencyTemplate(row, 'deposit')} header="Deposit"
                                    style={{width: '10%', textAlign: 'right'}}/>
                            <Column field="noOfInstallments" header="No. Of Instalments"
                                    style={{width: '10%', textAlign: 'right'}}/>
                            <Column field="noUnissed" header="No. Un-Issued"
                                    style={{width: '10%', textAlign: 'right'}}/>
                            <Column field="unissedTotal" body={(row) => currencyTemplate(row, 'unissedTotal')}
                                    header="Un-Issued Balance" style={{width: '10%', textAlign: 'right'}}/>
                            <Column field="status"
                                    header="Status" style={{width: '10%', textAlign: 'right'}}/>
                            <Column field="suspendUntil" body={row => dateTemplateShortPP(row)}
                                    header="Suspended Until" style={{width: '10%'}}/>
                        </DataTable>
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        paymentPlanHistoryLoaded,
        paymentPlanHistoryId,
        paymentPlanHistory,
    } = getPaymentPlanIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        paymentPlanHistoryLoaded,
        paymentPlanHistoryId,
        paymentPlanHistory,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[paymentPlanHistoryId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPPHistory: (patientId) => dispatch(getResource(RES_PAYMENT_PLAN_HISTORY.GET, patientId)),
        deletePaymentPlan: (patientId, planId) => dispatch(deletePaymentPlan(RES_PAYMENT_PLAN_BY_ID.DELETE, patientId, planId)),
        suspend: (params) => dispatch(changeStatus(RES_PAYMENT_PLANNING.SUSPEND_PAT, params)),
        resume: (params) => dispatch(changeStatus(RES_PAYMENT_PLANNING.RESUME_PAT, params)),
        writeOff: (params) => dispatch(changeStatus(RES_PAYMENT_PLANNING.WRITE_OFF_PAT, params)),

        stateRequest: (source, paymentPlan) => dispatch(stateRequest(source, paymentPlan)),

        setState: (id, data, parent) => dispatch(setState(id, data, parent)),
    }
};

const PaymentPlanHistory = connect(mapStateToProps, mapDispatchToProps)(ConnectedPaymentPlanHistory);

export default PaymentPlanHistory;