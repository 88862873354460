import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {dropDownArray} from './OnChangeUtils';

const theWorks = (props) => {

    const readOnly = props.readOnly;

    return (
        <div id="tooth-wear-row" className="p-dropdown-auto-width">
            <div>
                {dropDownArray(props, 'mob0', 1, readOnly, false)}
                <label>8</label>
                {dropDownArray(props, 'mob16', 17, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob1', 2, readOnly, false)}
                <label>7</label>
                {dropDownArray(props, 'mob17', 18, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob2', 3, readOnly, false)}
                <label>6</label>
                {dropDownArray(props, 'mob18', 19, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob3', 4, readOnly, false)}
                <label>5</label>
                {dropDownArray(props, 'mob19', 20, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob4', 5, readOnly, false)}
                <label>4</label>
                {dropDownArray(props, 'mob20', 21, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob5', 6, readOnly, false)}
                <label>3</label>
                {dropDownArray(props, 'mob21', 22, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob6', 7, readOnly, false)}
                <label>2</label>
                {dropDownArray(props, 'mob22', 23, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob7', 8, readOnly, false)}
                <label>1</label>
                {dropDownArray(props, 'mob23', 24, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob8', 9, readOnly, false)}
                <label>1</label>
                {dropDownArray(props, 'mob24', 25, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob9', 10, readOnly, false)}
                <label>2</label>
                {dropDownArray(props, 'mob25', 26, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob10', 11, readOnly, false)}
                <label>3</label>
                {dropDownArray(props, 'mob26', 27, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob11', 12, readOnly, false)}
                <label>4</label>
                {dropDownArray(props, 'mob27', 28, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob12', 13, readOnly, false)}
                <label>5</label>
                {dropDownArray(props, 'mob28', 29, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob13', 14, readOnly, false)}
                <label>6</label>
                {dropDownArray(props, 'mob29', 30, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob14', 15, readOnly, false)}
                <label>7</label>
                {dropDownArray(props, 'mob30', 31, readOnly, false)}
            </div>
            <div>
                {dropDownArray(props, 'mob15', 16, readOnly, false)}
                <label>8</label>
                {dropDownArray(props, 'mob31', 32, readOnly, false)}
            </div>
        </div>
    )
};

export const Mobility = (props) => {

    const newProps = {...props};

    newProps.name = 'Mobility';

    return (
        <Panel header='Mobility'>
            <div>
                {theWorks(newProps)}
            </div>
        </Panel>
    );
};
