import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import 'nanoscroller';
import {Button} from "primereact/button";

import WebsocketHandler from "../WebsocketHandler";

import 'primereact/resources/primereact.min.css';
import 'nanoscroller/bin/css/nanoscroller.css';
import '@fortawesome/fontawesome-pro/css/all.css';

import {doLogout} from "../../actions/login";
import {
    SM_TAB_CONCIEGE,
    SM_TAB_LOGOUT,
    SM_TAB_PATIENT_CONSENT,
    SM_TAB_PATIENT_DETAILS,
    SM_TAB_PATIENT_MED_CON,
    SM_TAB_PATIENT_QUESTIONNAIRE,
    SM_TAB_PATIENT_SIGN_PPS,
    SM_TAB_PATIENT_SIGN_TREATS
} from "../../actions/stateManagement";
import {tabletLayoutClassName} from "./Constants";
import {ac} from "../../index";

class ConnectedTablet extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.logout = this.logout.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props !== prevProps) {

            if (!this.props.loggedIn) {

                window.location.hash = '/';
            }
        }
    }

    componentDidMount() {
    }

    logout() {
        window.location = ac.getLogoutURL();
    }

    insertContent = () => {

        const content = []

        if (this.props.practiceDetails.tabConciege) {
            content.push(
                <div key={SM_TAB_CONCIEGE.id} className="p-md-12">
                    <Link to={SM_TAB_CONCIEGE.route}>
                        <Button key={2} label={SM_TAB_CONCIEGE.label}
                                style={{flexGrow: '1', fontSize: '18px'}}/>
                    </Link>
                </div>
            );
        }

        if (this.props.practiceDetails.tabPatientDetails) {
            content.push(
                <div key={SM_TAB_PATIENT_DETAILS.id} className="p-md-12">
                    <Link to={SM_TAB_PATIENT_DETAILS.route}>
                        <Button key={2} label={SM_TAB_PATIENT_DETAILS.label}
                                style={{flexGrow: '1', fontSize: '18px'}}/>
                    </Link>
                </div>
            );
        }

        if (this.props.practiceDetails.tabMedicalHistory) {
            content.push(
                <div key={SM_TAB_PATIENT_MED_CON.id} className="p-md-12">
                    <Link to={SM_TAB_PATIENT_MED_CON.route}>
                        <Button key={2} label={SM_TAB_PATIENT_MED_CON.label}
                                style={{flexGrow: '1', fontSize: '18px'}}/>
                    </Link>
                </div>
            );
        }

        if (this.props.practiceDetails.tabConsentForms) {
            content.push(
                <div key={SM_TAB_PATIENT_CONSENT.id} className="p-md-12">
                    <Link to={SM_TAB_PATIENT_CONSENT.route}>
                        <Button key={2} label={SM_TAB_PATIENT_CONSENT.label}
                                style={{flexGrow: '1', fontSize: '18px'}}/>
                    </Link>
                </div>
            );
        }

        if (this.props.practiceDetails.tabQuestionnaire) {
            content.push(
                <div key={SM_TAB_PATIENT_QUESTIONNAIRE.id} className="p-md-12">
                    <Link to={SM_TAB_PATIENT_QUESTIONNAIRE.route}>
                        <Button key={2} label={SM_TAB_PATIENT_QUESTIONNAIRE.label}
                                style={{flexGrow: '1', fontSize: '18px'}}/>
                    </Link>
                </div>
            )
        }

        if (this.props.practiceDetails.tabTreatmentPlans) {
            content.push(
                <div key={SM_TAB_PATIENT_SIGN_TREATS.id} className="p-md-12">
                    <Link to={SM_TAB_PATIENT_SIGN_TREATS.route}>
                        <Button key={2} label={SM_TAB_PATIENT_SIGN_TREATS.label}
                                style={{flexGrow: '1', fontSize: '18px'}}/>
                    </Link>
                </div>
            );
        }

        if (this.props.practiceDetails.tabPaymentPlans) {
            content.push(
                <div key={SM_TAB_PATIENT_SIGN_PPS.id} className="p-md-12">
                    <Link to={SM_TAB_PATIENT_SIGN_PPS.route}>
                        <Button key={2} label={SM_TAB_PATIENT_SIGN_PPS.label}
                                style={{flexGrow: '1', fontSize: '18px'}}/>
                    </Link>
                </div>
            );
        }
        return content;
    }

    render() {

        if (!this.props.loggedIn) {

            window.location.hash = '/';
        }

        return (

            <div className={tabletLayoutClassName} id='mainPCC'>

                <WebsocketHandler/>

                <div>
                    <div className="p-grid">
                        <div className="p-xl-8 p-xl-offset-2">
                            <div className="card card-w-title card-bg-solid">
                                <span className="logo"><i className='fa mainLogoImg'/></span>
                                <div className="p-grid control-panel-dashboard">

                                    {this.insertContent()}

                                    <hr className="p-col-12"/>

                                    <div className="p-md-12">
                                        <Button key={2} label={SM_TAB_LOGOUT.label}
                                                style={{flexGrow: '1', fontSize: '18px'}}
                                                onClick={this.logout}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-mask"/>

                    {/*<AppFooter/>*/}
                    <span className="bg-shape bg-shape-2"/>
                    <span className="bg-shape bg-shape-1"/>
                    <span className="bg-shape bg-shape-3"/>
                </div>
            </div>);

    }
}

const mapStateToProps = (state) => {

    const practiceDetailsLoaded = Boolean(state.preferences.practiceDetailsLoaded);
    const practiceDetails = practiceDetailsLoaded ? state.preferences.practiceDetails : [];

    return {

        practiceDetails,

        capabilities: state.login.capabilities,
        loggedIn: state.login.loggedIn,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        doLogout: () => dispatch(doLogout()),
    };
};

const Tablet = connect(mapStateToProps, mapDispatchToProps)(ConnectedTablet);

export default Tablet;