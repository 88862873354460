import React from 'react';

function ImageDropComponent(props) {
    const handleDragOver = (e) => {
        e.preventDefault(); // Necessary to allow for the drop
    };

    const handleDrop = (e) => {
        e.preventDefault(); // Prevent the browser's default file open behavior

        if (e.dataTransfer.items) {
            const items = e.dataTransfer.items;
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (file && file.type.startsWith('image/')) {
                        sendFileToServer(file);
                    }
                }
            }
        } else if (e.dataTransfer.files) {
            const files = e.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type.startsWith('image/')) {
                    sendFileToServer(file);
                }
            }
        }
    };

    const sendFileToServer = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const imageData = e.target.result;
            // Assuming we have a function to call the backend server
            props.uploadImageData(imageData);
        };
        reader.readAsDataURL(file);
    };

    return (
        <div
            style={{
                width: '75px',
                height: '75px',
                border: '2px dashed #ccc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <i className="fa-regular fa-square-dashed-circle-plus" style={{fontSize: '2em', transition: 'transform 0.3s ease-in-out'}}
               onMouseOver={e => e.currentTarget.style.transform = 'scaleY(-1)'}
               onMouseOut={e => e.currentTarget.style.transform = 'scaleY(1)'}>
            </i>
        </div>
    );
}

export default ImageDropComponent;
