import _ from "lodash";
import {equals} from "../Parts/ChartingUtils";
import {AdultPreMolar} from "../Parts/AdultPreMolar";
import {AdultIncisor} from "../Parts/AdultIncisor";
import {AdultMolar} from "../Parts/AdultMolar";
import {AdultPreMolarBicusp} from "../Parts/AdultPreMolarBicusp";
import {ChartItemBase} from "./ChartItemBase";
import {DriftedLeft} from "../Model/Constants";

export class ComDriftedLeft extends ChartItemBase {

    constructor() {
        super();

        this.state = {};
        this.target = null;
        this.mouth = null;
        this.adultPlaceHolders = null;
        this.placeHolder = null;

        this.setTarget = this.setTarget.bind(this);
        this.executeTreatment = this.executeTreatment.bind(this);
    };

    toString() {
        return DriftedLeft;
    }

    canExecute(target) {

        try {

            return (target instanceof AdultIncisor) ||
                (target instanceof AdultMolar) ||
                (target instanceof AdultPreMolar) ||
                (target instanceof AdultPreMolarBicusp)
        } catch (e) {
            return false;
        }
    }

    executeBase(mouth, target) {

        this.mouth = mouth;

        let adultPlaceHolders = mouth.getAdultPlaceholders();
        let placeHolder = _.find(adultPlaceHolders, (item) => {
            return equals(item, target);
        });

        // set the chart entry
        let entry = this.createNewEntry(mouth, DriftedLeft, true);
        entry.position = placeHolder.pos.perm;
        entry.jaw = placeHolder.jaw;

        mouth.props.addChartEntry(entry);
    }

    executeTreatment(mouth, target) {

        this.mouth = mouth;

        this.adultPlaceHolders = mouth.getAdultPlaceholders();
        this.placeHolder = _.find(this.adultPlaceHolders, (item) => {
            return equals(item, target);
        });

        // set the chart entry
        let entry = this.createNewEntry(mouth, DriftedLeft, false);
        entry.position = this.placeHolder.pos.perm;
        entry.jaw = this.placeHolder.jaw;

        mouth.props.addTreatmentHandler(entry);
    }

    setTarget(target) {
        this.target = target;
    }

    getCommandContextMenuItems() {
        return [];
    }

    clear() {
    }
}
