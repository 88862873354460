import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {Draggable, Droppable} from 'react-beautiful-dnd'

import {Accordion, AccordionTab} from 'primereact/components/accordion/Accordion';
import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';

import {getChargeTransactionStatus, getChartedItemDetails} from "../../../Charting/Utils/general";
import {
    APP_CREATED,
    CH_INVOICE,
    CH_INVOICED,
    CH_RECEIPTED,
    CLINICIAN,
    HM_AddTreatment,
    HM_DeleteTreatment,
    HM_EditTreatment,
    NURSE,
    SU_STATUS_ACTIVE,
    TREATMENTS_APP
} from "../../../../Constants";
import Treatments from "../../TreatmentPlanning/Sections/Treatments";
import Treatment from "../../TreatmentPlanning/Sections/Treatment";
import AddTreatment from "../../AppointmentDetails/Dialogs/AddTreatment";
import {checkBox, dropDown, dropDownId, spinnerStep} from "../../../PatientDynamicItems/OnChangeUtils";
import {BaseComponent} from "../../../BaseComponent";
import {durationConverter2} from "../../../PatientDynamicItems/Utils";

import {TB_SHOW_TREATMENT_ADD, TB_SHOW_TREATMENT_EDIT} from "../../PatientDetails/Constants";
import {Editor} from "primereact/editor";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";

export default class Appointment extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            appointment: {...props.appointment},
            selectedCharge: {id: null},
            templateNoe: null,
            [HM_DeleteTreatment.id]: false,

            duration: durationConverter2(props.appointment),

            first: 0,
            rows: 5,

            globalFilter: '',
        };

        this.cmAdd = null;
        this.cmAddEditDelete = null;
        this.accountGroup = null;
        this.editor = null;

        this.addAppointmentDetails = this.addAppointmentDetails.bind(this);
        this.addAppointmentComment = this.addAppointmentComment.bind(this);
        this.onAppointmentDurationChange = this.onAppointmentDurationChange.bind(this);
        this.onAppointmentDetailsChange = this.onAppointmentDetailsChange.bind(this);
        this.onAccountGroupChange = this.onAccountGroupChange.bind(this);
    }

    componentDidMount() {

        let appointment = {...this.state.appointment};
        appointment.charges.forEach((charge) => {
            charge.transText = getChargeTransactionStatus(charge)
        });

        this.setState({appointment});
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.appointment !== prevProps.appointment) {

            this.setState({
                appointment: this.props.appointment,
            })
        }
    }

    dealWithInsert = (e) => {

        try {

            const appointment = {...this.state.appointment};

            const position = this.editor.quill.getSelection().index;
            const delta =this.editor.quill.clipboard.convert(e.data.content);
            this.editor.quill.insertText(position, delta.ops[0].insert)

            appointment.description = this.editor.quill.getContents().ops[0].insert;

            this.props.onUpdateAppointment(appointment);
        } catch (error) {
            console.log(error);
        }
    }

    onDoubleClick = (e) => {

        this.editor.quill.focus();

        this.setState({flip: !this.state.flip}, () => {
            this.dealWithInsert(e);
        });
    }

    onAppointmentDurationChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        const start = moment(state.appointment.start);
        const end = moment(state.appointment.start);

        if (event.owner === 'duration.hour') {
            end.add(event.value, 'hour').add(state.duration.minute, 'minute');
        } else {
            end.add(state.duration.hour, 'hour').add(event.value, 'minute');
        }
        state.appointment.start = start.valueOf();
        state.appointment.end = end.valueOf();

        this.props.onUpdateAppointment(state.appointment);
    }

    onAppointmentDetailsChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.props.onUpdateAppointment(state.appointment);
    }

    addAppointmentDetails() {

        const durationProps = {
            onChange: this.onAppointmentDurationChange,
            target: 'duration',
            duration: this.state.duration
        };

        const props = {
            onChange: this.onAppointmentDetailsChange,
            target: 'appointment',
            appointment: this.state.appointment
        };

        const durationFixed = this.state.appointment.status === APP_CREATED;

        return (
            <Accordion>
                <AccordionTab key={55} header='Details'>
                    <div className="p-grid p-fluid form-group p-col-12">

                        <div className="p-col-2"><label>Time Required</label></div>
                        <div className="p-col-2">
                            {spinnerStep(durationProps, 'hour', 0, 100, 1, durationFixed, false)}
                        </div>
                        <div className="p-col-1"><label>hr</label></div>

                        <div className="p-col-2">
                            {spinnerStep(durationProps, 'minute', 0, 45, 15, durationFixed, false)}
                        </div>
                        <div className="p-col-1"><label>min</label></div>
                        <div className="p-col-4">
                            {checkBox(props, 'workRequired', 'Work Required', false, false)}
                        </div>
                    </div>
                    <div className="p-grid p-fluid form-group p-col-12">

                        <div className="p-col-2"><label>Interval</label></div>
                        <div className="p-col-2">
                            {spinnerStep(props, 'maximumInterval', 0, 1000, 1, false, false)}
                        </div>
                        <div className="p-col-1">
                            <label>max</label>
                        </div>
                        <div className="p-col-2">
                            {spinnerStep(props, 'minimumInterval', 0, 1000, 1, false, false)}
                        </div>
                        <div className="p-col-2">
                            <label>min days</label>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        );
    }

    addAppointmentComment(appointment) {

        let templates = _.filter(this.props.templateNotes, template => {
            const pattern = `${template.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'name', 'asc');

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'flex-end'}}>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, first: 0});
                       }}
                       placeholder="Search" size="50"
                       autoFocus
            />
            <Dropdown options={[]}/>
        </div>;

        return (
            <Accordion>
                <AccordionTab key={appointment.appointmentId} header='Comments'>
                    <div style={{display: 'flex', fontSize: 'small'}}>
                        <div className="p-col-6">
                            <Editor value={this.state.appointment.description}
                                    style={{height: '300px'}}
                                    ref={el => this.editor = el}
                            />
                        </div>
                        <div className="p-col-6">
                            <DataTable header={tableHeader}
                                       value={sortedTemplates}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.rows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={this.onPage}
                                       first={this.state.first}
                                       selection={this.state.templateNote}
                                       onRowDoubleClick={this.onDoubleClick}
                                       onSelectionChange={
                                           (e) => this.setState({templateNote: e.value})
                                       }
                            >
                                <Column field="name"/>
                            </DataTable>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        )
    }

    onAccountGroupChange() {
    }

    addTreatmentsHeader() {

        const borderStyle = {borderStyle: 'solid', borderColor: '#ebebeb', borderWidth: '1px'};

        if (this.props.treatmentDisplay === TREATMENTS_APP) {
            const colWidth = this.props.showCompleteness ? 'p-col-4' : 'p-col-5';

            return (
                <div className="p-grid p-fluid form-group p-col-12">
                    <div style={borderStyle} className="p-col-1">Code</div>
                    <div style={borderStyle} className={colWidth}>Description</div>
                    <div style={{...borderStyle, textAlign: 'right'}} className="p-col-1">Qty</div>
                    <div style={{...borderStyle, textAlign: 'right'}} className="p-col-1">Fee</div>
                    <div style={borderStyle} className="p-col-1">Transaction</div>
                    <div style={borderStyle} className="p-col-4">Comment</div>
                </div>
            );
        } else {

            return (
                <div className="p-grid p-fluid form-group p-col-12">
                    <div style={borderStyle} className="p-col-1">Code</div>
                    <div style={borderStyle} className='p-col-6'>Description</div>
                    <div style={borderStyle} className="p-col-2">Details</div>
                    <div style={{...borderStyle, textAlign: 'right'}} className="p-col-1">Qty</div>
                    <div style={{...borderStyle, textAlign: 'right'}} className="p-col-2">Fee</div>
                </div>
            );
        }
    }

    addContextMenus() {

        if (this.props.showContextMenus) {

            const itemsAdd = [
                {
                    label: HM_AddTreatment.label,
                    icon: HM_AddTreatment.icon,
                    command: () => this.props.onShowAddEditTreatment(false, null, this.props.appointmentId, TB_SHOW_TREATMENT_ADD.id),
                    target: HM_AddTreatment.id
                },
            ];

            const itemsAddEditDelete = [
                {
                    label: HM_AddTreatment.label,
                    icon: HM_AddTreatment.icon,
                    command: () => this.props.onShowAddEditTreatment(false, null, this.props.appointmentId, TB_SHOW_TREATMENT_ADD.id),
                    target: HM_AddTreatment.id
                },
                {
                    label: HM_EditTreatment.label,
                    icon: HM_EditTreatment.icon,
                    command: () => this.props.onShowAddEditTreatment(false, null, this.props.appointmentId, TB_SHOW_TREATMENT_EDIT.id),
                    target: HM_EditTreatment.id
                },
                {
                    label: HM_DeleteTreatment.header,
                    icon: HM_DeleteTreatment.icon,
                    command: this.onShowMenuEntry,
                    target: HM_DeleteTreatment.id
                },
            ];

            const content = [];
            content.push(
                <ContextMenu key='cm1'
                    // appendTo={document.getElementById(`_app_${this.state.appointment.appointmentId}`)}
                             style={{width: 250}}
                             model={itemsAdd}
                             ref={el => this.cmAdd = el}/>,
                <ContextMenu key='cm2'
                    // appendTo={document.getElementById(`_app_${this.state.appointment.appointmentId}`)}
                             style={{width: 250}}
                             model={itemsAddEditDelete}
                             ref={el => this.cmAddEditDelete = el}/>
            );
            return content;
        }
    }

    showDialogs() {

        if (this.props.showAddTreatment && this.props.showContextMenus && this.props.selectedAppointmentId === this.state.appointment.appointmentId) {

            return (
                <AddTreatment editing={false}
                              addFromCharting={this.props.addFromCharting}
                              currentSelectedItem={this.props.currentSelectedItem}
                              currentChartedEntry={this.props.currentChartedEntry}
                              appointment={this.state.appointment}
                              treatmentPlan={this.props.treatmentPlan}
                              header={HM_AddTreatment.header}
                              visible={true}
                              onHideDialog={(e) => {
                                  this.props.onHideAddEditTreatment();
                              }}
                              onOkDialog={(treatment) => {
                                  treatment.accountGroup = this.accountGroup !== undefined ? this.accountGroup : null;
                                  this.props.onAddTreatment(treatment);
                              }}
                              treatmentCodes={this.props.treatmentCodes}
                              favourites={this.props.favourites}
                />
            )
        } else if (this.props.showEditTreatment && this.props.showContextMenus && this.props.selectedAppointmentId === this.state.appointment.appointmentId) {
            return (
                <AddTreatment editing={true}
                              addFromCharting={this.props.addFromCharting}
                              currentChartedEntry={this.props.currentChartedEntry}
                              appointment={this.state.appointment}
                              treatmentPlan={this.props.treatmentPlan}
                              header={HM_EditTreatment.header}
                              visible={true}
                              onHideDialog={(e) => {
                                  this.props.onHideAddEditTreatment();
                              }}
                              onOkDialog={(treatment) => {
                                  this.props.onEditTreatment(treatment);
                              }}
                              treatmentCodes={this.props.treatmentCodes}
                              favourites={this.props.favourites}
                              item={this.state.selectedCharge}
                />
            )
        } else if (this.state[HM_DeleteTreatment.id] && this.props.showContextMenus) {
            const footer = <div>
                <Button label="Yes" icon="fa fa-check" onClick={() => {
                    this.props.onDeleteTreatment(this.state.selectedCharge);
                    this.onHideMenuEntry(HM_DeleteTreatment.id)
                }}/>
                <Button label="No" icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteTreatment.id)
                }}/>
            </div>;
            return (
                <Dialog style={{width: "50%"}}
                        header={HM_DeleteTreatment.header}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.onHideMenuEntry(HM_DeleteTreatment.id)
                        }}>
                    {HM_DeleteTreatment.message}
                </Dialog>
            )
        }
    }

    render() {

        const {selectedCharge} = this.props;
        const selectedChargeId = Boolean(selectedCharge) ? selectedCharge.id : null;

        const providerAccountGroups = this.state.appointment.appointmentWith === null ? [] : this.props.accountGroups.filter(group => !(_.findIndex(group.members, member => member.id === this.state.appointment.appointmentWith.id) < 0));

        this.accountGroup = providerAccountGroups[0];

        const acProps = {
            accountGroups: providerAccountGroups,
            onChange: this.onAccountGroupChange,
            target: 'this',
            this: this,
        };

        const appProps = {
            providers: _.filter(this.props.providers, provider => provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE),
            nurses: _.filter(this.props.providers, provider => provider.myRole === NURSE && provider.status === SU_STATUS_ACTIVE),
            appointmentTypes: this.props.appointmentTypes,
            onChange: this.onAppointmentDetailsChange,
            target: 'appointment',
            appointment: this.state.appointment,
        };

        let charges = _.orderBy(this.state.appointment.charges, 'id', 'asc');
        charges = _.map(charges, (charge) => {
            if (charge.chartEntry) {
                charge.details = getChartedItemDetails(charge.chartEntry)
            } else {
                charge.details = charge.site;
            }
            return charge;
        });

        return (

            <div id={`_app_${this.state.appointment.appointmentId}`}
                 className="content-section implementation"
                 style={{position: 'relative'}}
                 ref={el => this.treatmentDiv = el}
                 onContextMenu={(e) => {
                     // if (this.props.showContextMenus) {
                     //     this.cmAddEditDelete.hide(e);
                     //     this.cmAdd.show(e)
                     // }
                 }}
            >
                {this.addContextMenus()}
                {this.showDialogs()}

                <Droppable droppableId={`appointment_${this.state.appointment.appointmentId}`}>
                    {provided => (
                        <Treatments onShowMenuEntry={this.onShowMenuEntry} provided={provided}
                                    innerRef={provided.innerRef}
                                    onShowAddEditTreatment={this.props.onShowAddEditTreatment}
                                    appointmentId={this.state.appointment.appointmentId}
                                    treatmentDisplay={this.props.treatmentDisplay}
                        >

                            {this.addTreatmentsHeader()}

                            {/*iterate through all treatments*/}
                            {charges.map((charge, index) => {
                                return (
                                    <Draggable draggableId={`ap_${charge.id}`} index={index} key={`ap_${charge.id}`}>
                                        {(provided2, snapshot2) => (
                                            <Treatment charge={charge}
                                                       treatmentDisplay={this.props.treatmentDisplay}
                                                       provided={provided2}
                                                       snapshot={snapshot2}
                                                       showCompleteness={this.props.showCompleteness}
                                                       innerRef={provided2.innerRef}
                                                       highlighted={charge.id === selectedChargeId}
                                                       onSelection={() => {
                                                           this.setState({selectedCharge: charge})
                                                       }}
                                                       onContextMenu={(e) => {

                                                           const _e = _.cloneDeep(e);

                                                           if (this.props.showContextMenus) {
                                                               this.cmAdd.hide(e);

                                                               if (![CH_RECEIPTED, CH_INVOICE, CH_INVOICED].includes(charge.status)) {
                                                                   _e.pageY = window.screen.height / 2;//_e.pageY - this.treatmentDiv.offsetTop;
                                                                   this.cmAddEditDelete.show(_e);
                                                               }
                                                               this.setState({selectedCharge: charge})
                                                           }
                                                       }}
                                                       onComplete={this.props.onComplete}
                                            />
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </Treatments>
                    )}
                </Droppable>
                <div className="p-grid p-fluid form-group" style={{paddingTop: '5px'}}>
                    <div className="p-col-1">
                        <label htmlFor="title">With</label>
                    </div>
                    <div className="p-col-2">
                        {dropDownId(appProps, 'providers', 'appointmentWith', 'fullName', false, false)}
                    </div>
                    <div className="p-col-1">
                        <label htmlFor="title">Attendant Nurse</label>
                    </div>
                    <div className="p-col-2">
                        {dropDownId(appProps, 'nurses', 'attendantNurse', 'fullName', false, false)}
                    </div>
                    <div className="p-col-3">
                        {dropDownId(appProps, 'appointmentTypes', 'apType', 'description', false, false)}
                    </div>
                    <div className="p-col-3">
                        {dropDown(acProps, 'accountGroups', 'accountGroup', 'groupName', false, false)}
                    </div>
                </div>
                {this.addAppointmentDetails(this.state.appointment)}
                {this.addAppointmentComment(this.state.appointment)}
            </div>
        )
    }
}