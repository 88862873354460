import * as Actions from "./index";
import {ac} from '../index'

const baseURL = '/Preferences';

export const RES_PREFERENCES = {
    CLEAR: {action: Actions.CLEAR_PREFERENCES},
    SAVE: {action: Actions.SAVE_PREFERENCES},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_PREFERENCES}
};

export const RES_PREFERENCES_PDS = {
    GET: {request: 'REQUEST_PREFERENCES_PDS', receive: 'RECEIVE_PREFERENCES_PDS', url: '/Preferences/practiceDetails'},
    SAVE: {action: 'SAVE_PREFERENCES_PDS', url: '/Preferences/practiceDetails'},
};

export const RES_PREFERENCES_ACS = {
    GET: {request: 'REQUEST_PREFERENCES_ACS', receive: 'RECEIVE_PREFERENCES_ACS', url: '/Preferences/practiceDetails'},
    SAVE: {action: 'SAVE_PREFERENCES_ACS', url: '/Preferences/practiceDetails'},
};

export const RES_PREFERENCES_DCS = {
    SAVE: {action: 'SAVE_PREFERENCES_DCS', url: '/Preferences/setColours'},
};

export const RES_PREFERENCES_EMAIL = {
    GET: {
        request: 'REQUEST_PREFERENCES_EMAIL',
        receive: 'RECEIVE_PREFERENCES_EMAIL',
        url: '/Preferences/practiceDetails'
    },
    SAVE: {action: 'SAVE_PREFERENCES_EMAIL', url: '/Preferences/practiceDetails'},
};

export const RES_PREFERENCES_HOURS = {
    GET: {
        request: 'REQUEST_PREFERENCES_HOURS',
        receive: 'RECEIVE_PREFERENCES_HOURS',
        url: '/Preferences/practiceDetails'
    },
    SAVE: {action: 'SAVE_PREFERENCES_HOURS', url: '/Preferences/practiceDetails'},
};

export const RES_PREFERENCES_NHS = {
    GET: {request: 'REQUEST_PREFERENCES_NHS', receive: 'RECEIVE_PREFERENCES_NHS', url: '/Preferences/nhsContracts'},
    SAVE: {action: 'SAVE_PREFERENCES_NHS', url: '/Preferences/nhsContracts'},
    DELETE: {action: 'DELETE_PREFERENCES_NHS', url: '/Preferences/deleteContract'}
};

export const RES_PREFERENCES_LOCATIONS = {
    GET: {
        request: Actions.REQUEST_PREFERENCES_LOCATIONS,
        receive: Actions.RECEIVE_PREFERENCES_LOCATIONS,
        url: '/Preferences/locations'
    },
    SAVE: {
        action: Actions.SAVE_PREFERENCES_LOCATIONS,
        url: '/Preferences/locations'
    },
    DELETE: {
        action: Actions.DELETE_PREFERENCES_LOCATIONS,
        url: '/Preferences/deleteLocation'
    }
};

export const RES_PREFERENCES_RAR = {
    GET: {request: 'REQUEST_PREFERENCES_RAR', receive: 'RECEIVE_PREFERENCES_RAR', url: '/Preferences/practiceDetails'},
    SAVE: {action: 'SAVE_PREFERENCES_RAR', url: '/Preferences/practiceDetails'},
};

export const RES_PREFERENCES_REPORTS = {
    GET: {
        request: Actions.REQUEST_PREFERENCES_REPORTS,
        receive: Actions.RECEIVE_PREFERENCES_REPORTS,
        url: '/Preferences/reportTexts'
    },
    SAVE: {action: Actions.SAVE_PREFERENCES_REPORTS, url: '/Preferences/reportTexts'},
};

export const RES_PREFERENCES_PATIENT_CONSENT_FORMS = {
    GET: {
        request: Actions.REQUEST_PREFERENCES_PATIENT_CONSENT_FORMS,
        receive: Actions.RECEIVE_PREFERENCES_PATIENT_CONSENT_FORMS,
        url: `${baseURL}/consentForms`
    },
    SAVE: {action: Actions.SAVE_PREFERENCES_PATIENT_CONSENT_FORMS, url: `${baseURL}/reportTexts`},
    DELETE: {action: Actions.DELETE_PREFERENCES_PATIENT_CONSENT_FORMS, url: `${baseURL}/deleteConsentForms`},
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data, params})
            });
        dispatch({type: request, payload: false})
    }
};

export const deleteContract = (id) => {

    return (dispatch) => {

        ac.fetchClient().get(`${RES_PREFERENCES_NHS.url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(() => {

                dispatch({type: RES_PREFERENCES_NHS.DELETE.action, payload: id})
            });
    }
};

export const deleteLocation = (id) => {

    return (dispatch) => {

        ac.fetchClient().get(`${RES_PREFERENCES_LOCATIONS.url}?mcId=${ac.getMcId()}&id=${id}`)
            .then(() => {

                dispatch({type: RES_PREFERENCES_LOCATIONS.DELETE.action, payload: id})
            });
    }
};
