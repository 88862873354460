import {ICON_GOTO, ICON_PRINT, ICON_SHOW} from "../../../icons";

export const UnrollType = {

    Inv: 'Inv',
    Recpt: 'Recpt',
    Pay: 'Pay',
    Subtotals: 'Subtotals',
    Refund: 'Refund',
    Credit: 'Credit',
}

export const TB_PP_PATIENT = {
    id: 'TB_PP_PATIENT',
    text: 'Show Patient Summary',
    icon: 'fa paymentPlan24'
};

export const PatientJourneyStageHeadings = ["Patient", "Date of status change", "Clinician", "Date of Birth", "Next Appointment", "Summary"];
export const TreatmentCodeUsageHeadings = ["Qty", "Date of charge", "Treatment Code", "Clinician", "Patient", "Total"];
export const ClinicianUsageHeadings = ["Clinician", "Date", "Scheduled Time", "Lunch" , "Appointment Time", "No. Appointments", "Event Time", "No. Events"];
export const CopReport9Headings = ["Patient", "Date of charge", "Clinician", "Date of Birth", "Quantity", "Allocated To", "Duration"];

export const OVER_DUE_30 = 0;
export const OVER_DUE_3160 = 1;
export const OVER_DUE_6190 = 2;
export const OVER_DUE_91 = 3;
export const OVER_DUE_ALL = 4;

export const HM_SHOW_INVOICE = {
    id: 'HM_SHOW_INVOICE',
    header: 'Show Invoice',
    message: 'Show Invoice',
    icon: ICON_SHOW
};

export const HM_PRINT_INVOICE = {
    id: 'HM_PRINT_INVOICE',
    header: 'Print Invoice',
    message: 'Print Invoice',
    icon: ICON_PRINT
};

export const HM_SHOW_INVOICE_APPOINTMENT = {
    id: 'HM_SHOW_INVOICE_APPOINTMENT',
    header: 'Show this invoice\'s appointment',
    message: 'Show this invoice\'s appointment',
    icon: ICON_GOTO
};

export const HM_SHOW_INVOICE_PATIENT_ACCOUNTS = {
    id: 'HM_SHOW_INVOICE_PATIENT_ACCOUNTS',
    header: 'Show this patient\'s account',
    message: 'Show this patient\'s account',
    icon: ICON_GOTO
};

export const SEC_PP_ACTIVE = 0;
export const SEC_PP_FINISHED = 1;
export const SEC_PP_SUSPENDED = 2;
export const SEC_PP_WRITTEN_OFF = 3;

export const SEC_PJ_MAIN = 0;
export const SEC_PJ_SNAP_SHOT = 1;

export const SEC_WR_RECEIVED = 0;
export const SEC_WR_RECEIVED_FOR_APPROVAL = 1;
