import React from 'react';
import {connect} from "react-redux";
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Toast} from 'primereact/components/toast/Toast';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {HM_notImplemented, TB_SAVE_AND_EXIT} from "../../../../Constants";
import {ICON_HELP} from "../../../../icons";
import {showPatientDetailsPage} from "../../PatientDetails/Utils";
import {HELP_REP_KPIS, setState, SM_PATIENT_SEARCH} from "../../../../actions/stateManagement";
import {TB_PATIENT_DETAILS} from "../../PatientDetails/Constants";
import {BaseComponent} from "../../../BaseComponent";
import {CONST_FEMALE} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate, rowBGColour} from "../../../PatientDynamicItems/Utils";

import {ProgressBar} from "primereact/progressbar";
import {getDDData, RES_KPI_DATA_SETS} from "../../../../actions/kpis";
import * as Actions from "../../../../actions";
import {genderTemplate} from "../Utils";
import {tb_boilerPlateRight} from "../../../Utils";

export class ConnectedReferralSourcesDD extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                dataLoaded: false,
                results: [],
                dataTableSelectValue: null,

                first: 0,
                rows: 5,
            };
        }

        this.onShowDetails = this.onShowDetails.bind(this);
        this.titleTemplate = this.titleTemplate.bind(this);
        this.showDialogs = this.showDialogs.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.buildMenu = this.buildMenu.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);

        this.toolbarCallbacks = {

            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!this.state.dataLoaded) {
            this.props.getDDData(this.props.collIndex);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_KPI_REFERRALS_DD:

                    this.setState({results: this.props.results, dataLoaded: true});
                    break;

                default:
                    break;
            }
        }
    }

    onClose() {
        if (this.state.canSave.status) {
            this.props.setState(this.props.id, null);
            this.setState({showSaveDialog: true});
        } else {
            this.onExit();
        }
    }

    onExit() {

        this.props.onCloseClick({key: this.props.dataId});
    }

    onCloseClick() {
        this.props.onCloseClick({key: this.props.dataId});
    }

    onShowDetails({contactListPatientId, firstName, lastName, gender, nhsPatient}, groupId) {

        showPatientDetailsPage({props: this.props, id: contactListPatientId, firstName, lastName, gender, nhsPatient, groupId: null});
    }

    onSelectionChanged(e) {

        this.setState({dataTableSelectValue: e.value});
    }

    onDoubleClick(e) {

        this.setState({dataTableSelectValue: e.data});
    }

    showDialogs() {
    }

    titleTemplate(rowData) {
        return rowData.title.abbreviation;
    }

    buildMenu() {

        if (this.state.dataTableSelectValue === null) return [];

        const detailsIcon = this.state.dataTableSelectValue.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;

        return [
            {
                label: TB_PATIENT_DETAILS.text, icon: detailsIcon, command: (e) => {
                    this.toolbarCallbacks[TB_PATIENT_DETAILS.id](this.state.dataTableSelectValue);
                }
            },
        ]
    }

    render() {

        if (!this.state.dataLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = this.buildMenu();

        let key = 1000;

        return (
            <div id="detailPanel">

                <Toast ref={(el) => this.tabletGrowl = el}/>

                <Toolbar right={<React.Fragment>
                            {tb_boilerPlateRight(() => window.open(HELP_REP_KPIS), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onCloseClick, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                />

                <Panel header={`${this.props.header} : ${this.state.results.length}`} style={{paddingTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                    <DataTable id={SM_PATIENT_SEARCH.id}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               value={this.state.results}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.dataTableSelectValue}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.dataTableSelectValue}
                               onContextMenuSelectionChange={this.onSelectionChanged}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                               rowClassName={rowBGColour}
                    >

                        <Column body={genderTemplate} header="" style={{width: '5%'}}/>
                        <Column body={(row) => dateTemplate((row.regDate))} header="Registered On" style={{width: '15%'}}/>
                        <Column field='patientName' header="Patient" style={{width: '35%'}}/>
                        <Column body={(row) => dateTemplate(row.dob)} header="DOB" style={{width: '15%'}}/>
                        <Column field='dentist' header="Provider" style={{width: '30%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const dataLoadedId = `${RES_KPI_DATA_SETS.REFERRALS_DD.id}Loaded_${ownProps.collIndex}`;
    const dataId = `${RES_KPI_DATA_SETS.REFERRALS_DD.id}_${ownProps.collIndex}`;

    return {
        message: state.stateManagement.message,

        dataLoaded: state.kpis[dataLoadedId],
        dataId,
        results: state.kpis[dataId],
        currentState: state.stateManagement[dataId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDDData: (collIndex) => dispatch(getDDData(RES_KPI_DATA_SETS.REFERRALS_DD, collIndex)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const ReferralSourcesDD = connect(mapStateToProps, mapDispatchToProps)(ConnectedReferralSourcesDD);

export default ReferralSourcesDD;
