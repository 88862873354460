import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {
    checkBox,
    dropDown,
    inputText,
    inputTextEmail,
    inputTextSized,
    MINIMUM_MOBILE_NUMBER, MINIMUM_TELEPHONE_NUMBER
} from '../PatientDynamicItems/OnChangeUtils';

const optionalContactComment = (props, member) => {

    if (props.portalView || props.onTablet) {
        return (
            <div key={`${member}_4`} className="p-col-6"/>
        )
    } else {
        return (
            <div key={`${member}_4`} className="p-col-6">
                {inputText(props, member, 'Comment', false, false)}
            </div>
        )
    }
}

const optionalHomePhone = (props) => {

    const homePhoneMandatory = props.homePhoneMandatory === undefined ? false : props.homePhoneMandatory;

    if (props.includeHomePhone) {
        return <React.Fragment>
            <div key='1' className="p-col-2">
                <label htmlFor="homeTelephone">Home Phone</label>
            </div>
            <div key='2' className="p-col-1">
                {checkBox(props, 'homeTelephoneCB', '', false, false)}
            </div>
            <div key='3' className="p-col-3">
                {inputTextSized(props, 'homeTelephone', 'Home Phone', false, homePhoneMandatory, MINIMUM_TELEPHONE_NUMBER)}
            </div>
            {optionalContactComment(props, 'homeTelephoneComment')}
        </React.Fragment>
    }
};

const optionalWorkPhone = (props) => {

    const workPhoneMandatory = props.workPhoneMandatory === undefined ? false : props.workPhoneMandatory;

    if (props.includeWorkPhone) {
        return <React.Fragment>
            <div key='1' className="p-col-2">
                <label htmlFor="workTelephone">Work Phone</label>
            </div>
            <div key='2' className="p-col-1">
                {checkBox(props, 'workTelephoneCB', '', false, false)}
            </div>
            <div key='3' className="p-col-3">
                {inputTextSized(props, 'workTelephone', 'Work Phone', false, workPhoneMandatory, MINIMUM_TELEPHONE_NUMBER)}
            </div>
            {optionalContactComment(props, 'workTelephoneComment')}
        </React.Fragment>
    }
};

const optionalOtherPhone = (props) => {

    if (props.includeOtherPhone) {
        return <React.Fragment>
            <div key='5' className="p-col-2">
                <label htmlFor="otherTelephone">Other Phone</label>
            </div>
            <div key='2' className="p-col-1">
                {checkBox(props, 'otherTelephoneCB', '', false, false)}
            </div>
            <div key='3' className="p-col-3">
                {inputTextSized(props, 'otherTelephone', 'Other Phone', false, false, MINIMUM_TELEPHONE_NUMBER)}
            </div>
            {optionalContactComment(props, 'otherTelephoneComment')}
        </React.Fragment>
    }
};

const optionalInfo = (props) => {

    if (props.includeOptionalInfo) {
        return <React.Fragment>
            <div key='1' className="p-col-2">
                <label htmlFor="useAlternativeAddress">Use Alternate Address</label>
            </div>
            <div key='2' className="p-col-10">
                {checkBox(props, 'useAlternativeAddress')}
            </div>
        </React.Fragment>
    }
};

const optionalOccupation = (props) => {

    if (props.includeOccupation) {
        return <React.Fragment>
            <div className="p-col-4">
                <label htmlFor="occupation">Occupation</label>
            </div>
            <div className="p-col-8">
                {dropDown(props, 'occupations', 'occupation', 'description')}
            </div>
        </React.Fragment>
    }
};

export const Contact = (props) => {

    const panelStyle = props.addMargin ? {margin: '15px 0px 0px 0px'} : {};
    return (
        <Panel header='Contact' style={panelStyle}>
            <div className="p-grid p-fluid form-group">

                {optionalHomePhone(props)}
                {optionalWorkPhone(props)}
                {optionalOtherPhone(props)}

                <div className="p-col-2">
                    <label htmlFor="mobile">Mobile</label>
                </div>
                <div className="p-col-1">
                    {checkBox(props, 'mobileCB', '', false, false)}
                </div>
                <div className="p-col-3">
                    {inputTextSized(props, 'mobile', 'Mobile', false, true, MINIMUM_MOBILE_NUMBER)}
                </div>
                {optionalContactComment(props, 'mobileComment')}

                <div className="p-col-2">
                    <label htmlFor="email">Email</label>
                </div>
                <div className="p-col-1">
                    {checkBox(props, 'emailCB', '', false, false)}
                </div>
                <div className="p-col-3">
                    {inputTextEmail(props, 'email', 'Email', false, true)}
                </div>
                {optionalContactComment(props, 'emailComment')}

                {optionalInfo(props)}
                {optionalOccupation(props)}
            </div>
        </Panel>
    )
};
