import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from 'primereact/components/inputtext/InputText';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';

import {PD_STATE_RECEIPT_AMOUNT, TB_PATIENT_SALES} from "../../../PatientDetails/Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";
import {outputCurrency} from "../../../../PatientDynamicItems/OnChangeUtils";

export const PaymentOptions = (props) => {

    const footer =
        <div>
            <Button label='Issue Receipt'
                    icon={ICON_OK}
                    onClick={() => {
                        props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_AMOUNT});
                    }}/>
            <Button label='Issue Invoice'
                    icon={ICON_OK}
                    onClick={() => {
                        props.onFinish(props.accountGroup, false);
                    }}/>
            <Button label='Issue Print Invoice'
                    icon={ICON_OK}
                    onClick={() => {
                        props.onFinish(props.accountGroup, true);
                    }}/>
            <Button label="Cancel"
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.ownerId)}/>
        </div>;

    return (
        <Dialog style={{width: "50%"}}
                header={TB_PATIENT_SALES.text}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={e => props.onHideDialog(props.ownerId)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <label>This Sale</label>
                </div>
                <div className="p-col-12">
                    <InputText type="text"
                               disabled={true}
                               style={{fontSize: 'x-large', textAlign: 'right'}}
                               value={outputCurrency(props.salesTotal)}
                    />
                </div>
                <div className="p-col-12">
                    <label>Comment</label>
                </div>
                <div className="p-col-12">
                    <InputTextarea rows={5}
                                   cols={null}
                                   autoResize={true}
                    />
                </div>
                <div className="p-col-12">
                    <label>Total Due</label>
                </div>
                <div className="p-col-12">
                    <InputText type="text"
                               disabled={true}
                               style={{fontSize: 'x-large', textAlign: 'right'}}
                               value={outputCurrency(props.balance)}
                    />
                </div>
            </div>
        </Dialog>
    );
};