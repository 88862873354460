import React from 'react';
import {
    setState,
    SM_PATIENT_MHF,
    SM_TAB_PATIENT_MED_CON,
    SM_TAB_PATIENT_MED_CON_SIG,
    stateRequest
} from "../../../actions/stateManagement";

import _ from "lodash";
import {MedicalHistoryForm} from "../../FixedItems/DefaultData";
import {ProgressBar} from "primereact/progressbar";
import {Card} from "primereact/card";
import {ac} from "../../../index";
import {TAB_MedicalHistoryUpdate, tabletLayoutClassName} from "../Constants";
import {InputTextarea} from "primereact/inputtextarea";
import {getMedicalHistory, RES_PATIENT_MHFS} from "../../../actions/personal";
import {connect} from "react-redux";
import {Panel} from "primereact/panel";
import SignatureCanvas from "react-signature-canvas";
import {Button} from "primereact/components/button/Button";
import * as Actions from "../../../actions";
import {HM_COMPLETE_MHF, HM_TABLET_CONFIRM_MHF, HM_TABLET_NO_CHANGE_MHF, MHF_ST_First} from "../../../Constants";
import {ShowMessageDialog, ShowQuestionDialog} from "../../FixedItems/Diary/components/EventComponent";
import {SelectButton} from "primereact/selectbutton";
import {BaseComponent} from "../../BaseComponent";
import {removeFromLists, RES_TABLET_LISTS} from "../../../actions/tablet";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";

class ConnectedTabPatientMedConsMain extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: this.props.dataId,
                medicalHistoryForm: _.cloneDeep(MedicalHistoryForm(ac.getMcId())),
                patientId: parseInt(props.match.params.patientId, 10),
                memberId: parseInt(props.match.params.memberId, 10),
                mhfContents: [],
                image: null,
                completed: false,

                medicalHistoryFormLoaded: false,
            }
        }

        this.sigCanvas = {};

        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.insertConditions = this.insertConditions.bind(this);
    }

    componentDidMount() {

        if (!this.props.medicalConditionsLoaded) {
            this.props.getDropDowns();
            this.props.getMedicalHistory(this.state.patientId);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_MHF:

                    const medicalHistoryForm = {...this.props.medicalHistoryForm};

                    if (medicalHistoryForm.state === MHF_ST_First) {
                        medicalHistoryForm.entries.forEach(entry => entry.yes = null);
                    }

                    let completed = true;

                    let dummyId = -1000;

                    medicalHistoryForm.entries.forEach(entry => {
                        if (!entry.condition.formHeader) {
                            if (entry.yes === null) {
                                completed = false;
                            }
                        }
                        entry.id = dummyId++;
                    });

                    this.setState({medicalHistoryFormLoaded: true, medicalHistoryForm, completed}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case Actions.CLEAR_PATIENT_MHF:

                    window.location.hash = `${SM_TAB_PATIENT_MED_CON.exitRoute}/${TAB_MedicalHistoryUpdate}/${this.state.patientId}`;
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const medicalHistoryForm = {...this.state.medicalHistoryForm};
        const entry = _.find(medicalHistoryForm.entries, entry => entry.id === event.id);

        _.set(entry, event.owner, event.value);

        if (event.owner === 'yes') {

            let completed = true;

            medicalHistoryForm.entries.forEach(entry => {
                if (!entry.condition.formHeader)
                    if (entry.yes === null) {
                        completed = false;
                    }
            });
            this.setState({medicalHistoryForm, completed}, () => {
                this.props.setState(this.state.stateManagementId, {...this.state});
            });
        } else {
            this.setState({medicalHistoryForm}, () => {
                this.props.setState(this.state.stateManagementId, {...this.state});
            });
        }
    }

    onSave = () => {

        if (this.state.completed) {
            this.setState({[HM_TABLET_CONFIRM_MHF.id]: true, sigCanvas: this.sigCanvas});
        } else {
            this.setState({[HM_COMPLETE_MHF.id]: true});
        }
    }

    onNoChange = () => {
        this.setState({[HM_TABLET_NO_CHANGE_MHF.id]: true});
    }

    onClear() {

        this.sigCanvas.clear();
    }

    onExit = () => {

        const source = {
            id: this.state.stateManagementId,
            action: Actions.SAVE_PATIENT_MHF,
            saveState: true,
            saveObjects: false,
            smRef: this.state.patientId,
            image: this.sigCanvas.toDataURL('image/png'),
        };

        this.props.stateRequest(source);
    }

    onExitNoChange = () => {

        const source = {
            id: this.state.stateManagementId,
            action: Actions.PATIENT_MHF_NO_CHANGE,
            saveState: true,
            saveObjects: false,
            smRef: this.state.patientId,
        };

        this.props.stateRequest(source);
    }

    onCancel() {

        const tabletMember = {patientId: this.state.patientId, type: TAB_MedicalHistoryUpdate};
        this.props.removeFromLists(tabletMember);

        window.location.hash = `${SM_TAB_PATIENT_MED_CON.exitRoute}/${TAB_MedicalHistoryUpdate}/${this.state.patientId}`;
    }

    insertConditions() {

        const conditions = [];
        const formEntries = _.sortBy(this.state.medicalHistoryForm.entries, 'condition.medicalHistoryFormIndex', 'asc');

        const selectItems = [
            {label: 'Yes', value: true},
            {label: 'No', value: false}
        ];

        formEntries.forEach((entry) => {

            if (entry.condition.includeInMedicalistoryForm) {

                if (entry.condition.formHeader) {
                    conditions.push(
                        <div style={{
                            backgroundColor: 'rgb(151,188,215)',
                            marginTop: '5px',
                            marginBottom: '5px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            display: 'flex', flexDirection: 'column'
                        }}
                        >
                            <span style={{textAlign: 'center'}}>{entry.condition.formEntry}</span>
                        </div>
                    )
                } else {
                    conditions.push(
                        <Card header={entry.condition.formEntry}
                        >
                            <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>
                                <div className="p-col-4">
                                    <SelectButton value={entry.yes}
                                                  options={selectItems}
                                                  onChange={(e) =>
                                                      this.onChange({id: entry.id, owner: 'yes', value: e.value})
                                                  }/>
                                </div>
                                <div className="p-col-8">
                                    <InputTextarea value={entry.comment}
                                                   autoResize={true}
                                                   rows={3}
                                                   onChange={(e) => {
                                                       this.onChange({
                                                           id: entry.id,
                                                           owner: 'comment',
                                                           value: e.target.value
                                                       })
                                                   }}
                                    />
                                </div>
                            </div>
                        </Card>
                    )
                }
            }
        });
        return conditions;
    }

    showDialogs() {

        const content = [];
        if (this.state[HM_COMPLETE_MHF.id]) {
            content.push(ShowMessageDialog(this, HM_COMPLETE_MHF));
        } else if (this.state[HM_TABLET_CONFIRM_MHF.id]) {
            content.push(ShowQuestionDialog(this, HM_TABLET_CONFIRM_MHF, this.onExit));
        } else if (this.state[HM_TABLET_NO_CHANGE_MHF.id]) {
            content.push(ShowQuestionDialog(this, HM_TABLET_NO_CHANGE_MHF, this.onExitNoChange));
        }
        return content;
    }

    render() {

        if (!this.state.medicalHistoryFormLoaded || !this.props.dropDownsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const disableNoChange = this.state.medicalHistoryForm.state === MHF_ST_First;

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title'>{SM_TAB_PATIENT_MED_CON.label}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button label="Save" icon="pi pi-check"
                        className="p-button-success"
                        onClick={this.onSave}
                />
                <i className="pi pi-bars p-toolbar-separator"
                   style={{marginRight: '.25em'}}/>
                <Button label="No Change" icon="pi pi-check"
                        className="p-button-info"
                        onClick={this.onNoChange}
                        disabled={disableNoChange}
                />
                <i className="pi pi-bars p-toolbar-separator"
                   style={{marginRight: '.25em'}}/>
                <Button label="Cancel"
                        icon="pi pi-times"
                        className="p-button-warning"
                        onClick={this.onCancel}
                />
            </div>
        </div>;

        const sigHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{SM_TAB_PATIENT_MED_CON_SIG.label}</span>
                <Button label="Clear" icon="pi pi-plus"
                        onClick={this.onClear}/>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button label="Save" icon="pi pi-check"
                        className="p-button-success"
                        onClick={this.onSave}
                />
                <i className="pi pi-bars p-toolbar-separator"
                   style={{marginRight: '.25em'}}/>
                <Button label="No Change" icon="pi pi-check"
                        className="p-button-info"
                        onClick={this.onNoChange}
                        disabled={disableNoChange}
                />
                <i className="pi pi-bars p-toolbar-separator"
                   style={{marginRight: '.25em'}}/>
                <Button label="Cancel"
                        icon="pi pi-times"
                        className="p-button-warning"
                        onClick={this.onCancel}
                />
            </div>
        </div>;

        return (

            <div className={tabletLayoutClassName} id='mainPCC'>

                {this.showDialogs()}

                <div>
                    <div className="p-grid">
                        <div className="p-xl-8 p-xl-offset-2">
                            <div className="card card-w-title card-bg-solid">
                                <span className="logo"><i className='fa mainLogoImg'/></span>
                                <div className="p-grid control-panel-dashboard">

                                    <div className="p-col-12">
                                        <Panel headerTemplate={header} className="p-col-12">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">

                                                    {this.insertConditions()}

                                                </div>
                                            </div>
                                        </Panel>

                                    </div>

                                    <div className="p-col-12">
                                        <Panel headerTemplate={sigHeader} className="p-col-12">
                                            <div className="p-grid p-fluid form-group"
                                                 style={{fontSize: 'small'}}
                                            >
                                                <div className="p-col-12">
                                                    <SignatureCanvas penColor='darkBlue'
                                                                     ref={(ref) => {
                                                                         this.sigCanvas = ref
                                                                     }}
                                                                     canvasProps={{
                                                                         width: 500,
                                                                         height: 200,
                                                                         className: 'sigCanvas'
                                                                     }}/>
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-mask"/>

                    {/*<AppFooter/>*/}
                    <span className="bg-shape bg-shape-2"/>
                    <span className="bg-shape bg-shape-1"/>
                    <span className="bg-shape bg-shape-3"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const dropDownsLoaded = Boolean(state.dropDowns.dropDownsLoaded);

    const loadedId = `${SM_PATIENT_MHF.loaded}_${ownProps.match.params.patientId}`;
    const dataId = `${SM_PATIENT_MHF.id}_${ownProps.match.params.patientId}`;

    return {

        dataId,
        loaded: state.patients[loadedId],
        medicalHistoryForm: state.patients[dataId],

        dropDownsLoaded,
        titles: state.dropDowns.titles,
        countries: state.dropDowns.countries,

        message: state.stateManagement.message,

        loginIdentity: state.login.user,
        userId: state.login.userId,

        currentState: state.stateManagement[dataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getMedicalHistory: (patientId) => dispatch(getMedicalHistory(RES_PATIENT_MHFS.GET_TABLET, patientId)),
        removeFromLists: (tabletMember) => dispatch(removeFromLists(RES_TABLET_LISTS.REMOVE_FROM, tabletMember)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TabPatientMedConsMain = connect(mapStateToProps, MapDispatchToProps)(ConnectedTabPatientMedConsMain);

export default TabPatientMedConsMain;
