import React from 'react';
import _ from 'lodash';

import {Panel} from 'primereact/components/panel/Panel';
import {BG_GROUP_MEMBERS, BG_PRINCIPLE, HM_BG_REMOVE_MEMBER, HM_BG_SHOW_MEMBER,} from "../../Constants";
import {ICON_GOTO, ICON_USER_MINUS} from "../../icons";
import {dateTemplateShort} from "./Utils";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {CONST_MALE, inputText, inputTextArea, outputCurrency} from "./OnChangeUtils";
import {ContextMenu} from "primereact/contextmenu";
import {Checkbox} from "primereact/checkbox";
import {BaseComponent} from "../BaseComponent";
import {nhsTemplate} from "../FixedItems/Patients/Utils";
import {ICON_PATIENT_FEMALE, ICON_PATIENT_MALE} from "../FixedItems/PatientDetails/Constants";

const Headings = ["Send to Principle / Name", "DOB", "Balance", "Journey Code"];

export class BillingGroup extends BaseComponent {

    constructor(props) {
        super(props);
    }

    sendToPrincipleTemplate = (props, row) => {

        if (row === undefined)
            return null;
        else
            return (<Checkbox onChange={e => {
                    this.props.onChange({owner: 'memberSelection.sendToPrinciple', value: e.checked});
                }}
                              checked={row.sendToPrinciple}
                />
            )
    };

    genderTemplate(rowData) {
        return rowData.gender === CONST_MALE ?
            <span style={{color: "#70d0f6", fontSize: 20}} className={ICON_PATIENT_MALE}/> :
            <span style={{color: "#1a92d0", fontSize: 20}} className={ICON_PATIENT_FEMALE}/>;
    }

    render() {

        const noteProps = {
            onChange: () => {
            },
            target: 'billingGroup',
            billingGroup: this.props.billingGroup,
        };

        const items = [];

        const removeEnabled = (!this.props.fromFind && this.props.memberSelection) && this.props.memberSelection.id === parseInt(this.props.patientId, 10);

        items.push({
                label: HM_BG_REMOVE_MEMBER.header,
                icon: ICON_USER_MINUS,
                disabled: removeEnabled,
                command: (e) => this.props.onRemoveFromBillingGroup(false)
            });

        if (this.props.fromFind) {
            items.push({label: HM_BG_SHOW_MEMBER.header, icon: ICON_GOTO, command: (e) => this.props.onShowDetails(e)});
        }

        const groupees = this.props.billingGroup === null || this.props.billingGroup === undefined ? [] : this.props.billingGroup.groupees;
        const sortedGroupees = _.orderBy(groupees, 'name', 'asc');

        return (
            <React.Fragment>
                <div className="p-col-4">
                    <Panel header={BG_PRINCIPLE}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">
                                <label htmlFor="principleName">Name</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(noteProps, 'principleName', '', true, false)}
                            </div>

                            <div className="p-col-3">
                                <label htmlFor="description">Description</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(noteProps, 'description', '', true, false)}
                            </div>

                            <div className="p-col-3">
                                <label htmlFor="comments">Comments</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(noteProps, 'comments', 10, -1, true, false)}
                            </div>
                        </div>
                    </Panel>
                </div>

                <div className="p-col-8">
                    <Panel header={BG_GROUP_MEMBERS}>

                        <ContextMenu style={{width: 290}} model={items} ref={el => this.cm = el}/>

                        <DataTable value={sortedGroupees}
                                   className='p-datatable-gridlines'
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.props.memberRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.props.onPageFlex(e, 'firstMember', 'memberRows')}
                                   first={this.props.firstMember}
                                   selection={this.props.memberSelection}
                                   onSelectionChange={e => {
                                       this.props.onSelectionChange({owner: 'memberSelection', value: e.value})
                                   }}
                                   contextMenuSelection={this.props.memberSelection}
                                   onContextMenuSelectionChange={e =>
                                       this.props.onSelectionChange({owner: 'memberSelection', value: e.value})
                                   }
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent);
                                   }}
                        >
                            <Column body={(row) => this.sendToPrincipleTemplate(this.props, row)}
                                    style={{width: '5%'}}/>
                            <Column body={this.genderTemplate} header="" style={{width: '5%'}}/>
                            <Column body={nhsTemplate} style={{width: '10%'}}/>
                            <Column field='name'
                                    header={Headings[0]}
                                    style={{width: '35%'}}/>
                            <Column field='journeyCode'
                                    header={Headings[3]}
                                    style={{width: '15%'}}/>
                            <Column body={row => dateTemplateShort(row.dob)}
                                    header={Headings[1]}
                                    style={{width: '20%'}}/>
                            <Column body={row => outputCurrency(row.accountBalance)}
                                    header={Headings[2]}
                                    style={{width: '20%', textAlign: 'right'}}
                            />
                        </DataTable>
                    </Panel>
                </div>
            </React.Fragment>
        );
    }
}
