import React from 'react';
import {connect} from "react-redux";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {HM_AccountGroupSelector, HM_notImplemented, JSON_DATE_FORMAT, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PRINT, ICON_SCREEN} from "../../../icons";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {
    HELP_REP_MONTHS_SHARE,
    setState,
    SM_REPORTS_COST_CENTERS,
    SM_REPORTS_COST_CENTERS_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import {getReport, getReportPDF, RES_REPORT_COST_CENTER} from "../../../actions/reports";
import moment from "moment/moment";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {DIARY_BACK_MONTH, DIARY_FOR_MONTH} from "../Diary/Constants";
import {Calendar} from "primereact/calendar";
import {TextCostCenter} from "../../../Messages";
import ReportErrorBoundary from "./Utils";
import AccountGroupSelector from "./dialogs/AccountGroupSelector";

export class ConnectedCostCenters extends PDFBaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),

            costCenterLoaded: false,
            costCenter: [],
        };
    }

    componentDidMount() {

        if (!this.props.costCenterLoaded) {
            this.props.getCostCenterReport(this.state.currentDate);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_COST_CENTER.GET.receive:
                    this.setState({costCenter: [...this.props.costCenter], costCenterLoaded: true}, () => {
                        this.props.setState(SM_REPORTS_COST_CENTERS.id, this.state);
                    });
                    break;
                case RES_REPORT_COST_CENTER.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_COST_CENTERS_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {
            case 'currentDate' :
                state.currentDate = event.value;
                break;
            case 'back' :
                state.currentDate = moment(state.currentDate).add(-1, 'M').toDate()
                break;
            case 'forward' :
                state.currentDate = moment(state.currentDate).add(1, 'M').toDate()
                break;
            case 'selectedItem' :
                state.selectedItem = event.value;
                this.setState(state);
                return;
            default:
                break;
        }

        state.first = 0;
        state.selectedItem = null;
        state.monthsCashLoaded = false;

        this.setState({...state, pdfBuffer: null, PDFLoaded: false}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
            this.props.getCostCenterReport(this.state.currentDate);
        });
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_COST_CENTERS_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    onDownload = () => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_REPORTS_COST_CENTERS_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    showDialogs = () => {

        return [];
    }

    render() {

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate(() => this.onChange({owner: 'back'}), DIARY_BACK_MONTH.icon, DIARY_BACK_MONTH.tooltip, key++)}
                            {tb_boilerPlate(() => this.onChange({owner: 'forward'}), DIARY_FOR_MONTH.icon, DIARY_FOR_MONTH.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="mm/yy"
                                      view='month'
                                      onChange={(e) => this.onChange({owner: 'currentDate', value: e.value})}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight2(this.onShowReport, ICON_SCREEN, 'Show ' + TextCostCenter, key++, false)}
                            {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextCostCenter, key++, false)}
                            {tb_boilerPlateRight2(this.onDownload, ICON_DOWNLOAD, `Download ${TextCostCenter} Report`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_MONTHS_SHARE), ICON_HELP, 'Knowledge Base', key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel header={`Cost Centers : ${HM_notImplemented.header}`} style={{paddingTop: '5px'}}>
                        <div className="p-grid p-fluid form-group">
                        </div>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        costCenterLoaded: state.reports.costCenterLoaded,
        costCenter: state.reports.costCenter,

        PDFLoaded: state.reports.costCenterPDFLoaded,
        pdfBuffer: state.reports.costCenterPDF,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_REPORTS_COST_CENTERS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {

        getCostCenterReport: (date) => dispatch(getReport(RES_REPORT_COST_CENTER.GET, {date: moment(date).format(JSON_DATE_FORMAT)})),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_COST_CENTER.PDF, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const CostCenters = connect(mapStateToProps, mapDispatchToProps)(ConnectedCostCenters);

export default CostCenters;

CostCenters.propTypes = {};