import React from 'react';
import {Panel} from "primereact/components/panel/Panel";
import {Button} from "primereact/components/button/Button";
import {SE_NONE, SE_ONLINE_VC_BOOKED, SM_CLIENT_BOOK_VIDEO_CALL} from "./Constants";
import {ClientComponent} from "./ClientComponent";
import {Calendar} from "primereact/components/calendar/Calendar";
import {setState} from "../../actions/stateManagement";
import moment from "moment";
import {connect} from "react-redux";
import {getPortalDiaryEvents, onlineBooking, RES_diaryEvents} from "../../actions/diary";
import {dateTimeTemplate} from "../Tablet/Utils";
import _ from "lodash";
import {ShowQuestionDialog} from "../FixedItems/Diary/components/EventComponent";
import {
    DE_TYPE_VIDEO_CONF,
    HM_PORTAL_BOOK_VIDEO_CALL,
    HM_PORTAL_BOOK_VIDEO_CALL_NOTE,
    UP_ADD_DIARYEVENT,
    UP_DEL_DIARYEVENT,
    UP_DIARYEVENT
} from "../../Constants";
import BookingNote from "./dialogs/BookingNote";
import * as Actions from "../../actions";
import {getAllUsers} from "../../actions/users";

export class ConnectedBookVideoCall extends ClientComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),
            videoCallsLoaded: false,
            videoCalls: [],
            selectedVideoCall: null,

            [HM_PORTAL_BOOK_VIDEO_CALL.id]: false,
        };
    }

    componentDidMount() {

        this.props.getAllUsersShort();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message || prevProps.wsmessage !== this.props.wsmessage) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:

                    this.setState({usersLoaded: true}, () => {

                        const endDate = moment(this.state.currentDate).add(1, 'week').toDate();
                        this.props.getDiaryEvents(this.state.currentDate, endDate);
                    })
                    break;
                case RES_diaryEvents.GET_PORTAL_VC.receive:

                    this.setState({videoCalls: this.props.videoCalls, videoCallsLoaded: true});
                    break;
                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_ADD_DIARYEVENT: {

                            const {content} = this.props.wsmessage;

                            if (content.type.type === DE_TYPE_VIDEO_CONF.name) {
                                this.setState({videoCalls: [...this.state.videoCalls, this.extractOnline(content)]});
                            }
                            break;
                        }

                        case UP_DIARYEVENT: {

                            const {content} = this.props.wsmessage;

                            if (content.type.type === DE_TYPE_VIDEO_CONF.name) {

                                const targetIndex = _.findIndex(this.state.videoCalls, videoCall => videoCall.id === content.id);

                                const videoCalls = [...this.state.videoCalls];
                                videoCalls[targetIndex].start = content.start;
                                videoCalls[targetIndex].end = content.end;

                                this.setState({videoCalls});
                            }
                            break;
                        }
                        case UP_DEL_DIARYEVENT: {

                            const {content} = this.props.wsmessage;

                            if (content.diaryEventType.type === DE_TYPE_VIDEO_CONF.name) {
                                let videoCalls = [...this.state.videoCalls];
                                videoCalls = _.filter(videoCalls, videoCall => videoCall.id !== this.props.wsmessage.content.id);
                                this.setState({videoCalls});
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    extractOnline = (content) => {

        const videoCallFor = _.find(this.props.usersShort, user => user.id === content.eventForId);

        return {
            id: content.id,
            start: content.start,
            end: content.end,
            username: videoCallFor.username,
            firstName: videoCallFor.firstName,
            lastName: videoCallFor.lastName,
            red: content.type.red,
            green: content.type.green,
            blue: content.type.blue,
            description: content.type.title,
            type: content.type,
        }
    }

    onDateChange = (e) => {

        let date = e.value;

        if (moment(date).isBefore(moment(), 'day')) {
            date = new Date();
        }

        const endDate = moment(date).add(1, 'week').toDate();

        this.props.getDiaryEvents(this.state.currentDate, endDate);

        this.setState({
            currentDate: date,
            videoCallsLoaded: false,
            videoCalls: [],
        })
    }

    onDateChange2 = (forward) => {

        let date = moment(this.state.currentDate).add(forward ? 1 : -1, 'weeks');

        if (date.isBefore(moment(), 'day')) {
            date = moment();
        }

        const endDate = moment(date).add(1, 'week').toDate();
        date = date.toDate();

        this.props.getDiaryEvents(date, endDate);

        this.setState({
            currentDate: date,
            videoCallsLoaded: false,
            videoCalls: [],
        });
    }

    bookVideoCall = (diaryNote) => {
        this.setState({[HM_PORTAL_BOOK_VIDEO_CALL_NOTE.id]: false}, () => {
            this.props.videoBooking({
                patientId: this.props.patientId,
                online: this.state.selectedVideoCall,
                note: diaryNote,
                status: SE_ONLINE_VC_BOOKED,
            });
        });
    }

    confirmBookVideoCall = () => {
        this.setState({[HM_PORTAL_BOOK_VIDEO_CALL.id]: false, [HM_PORTAL_BOOK_VIDEO_CALL_NOTE.id]: true});
    }

    insertVideoCalls() {

        const contents = [];

        const sortedVideoCalls = _.orderBy(this.state.videoCalls, [(videoCall) => {
            return moment(new Date(videoCall.start)).format('YYYY-MM-DDTHH:mm');
        }, 'username'], ['asc', 'asc']);

        sortedVideoCalls.forEach((videoCall) => {

            const colour = `rgb(${videoCall.red},${videoCall.green},${videoCall.blue})`

            contents.push(
                <div key={videoCall.id}
                     style={{paddingBottom: '1rem', paddingRight: '1rem'}}
                     onClick={(e) => {
                         this.setState({selectedVideoCall: videoCall, [HM_PORTAL_BOOK_VIDEO_CALL.id]: true})
                     }}>
                    <div className="overview-box overview-box-2" style={{backgroundColor: colour}}>
                        <div className="overview-box-title">
                            <i className="fa fa-video"/>
                            <span>{videoCall.title}</span>
                        </div>
                        <div
                            className="overview-box-count">{dateTimeTemplate(new Date(videoCall.start), new Date(videoCall.end))}</div>
                        <div
                            className="overview-box-stats">{`${videoCall.firstName} ${videoCall.lastName}`.trim()}</div>
                    </div>
                </div>
            )
        })
        return contents;
    }

    showDialogs = () => {

        if (this.state[HM_PORTAL_BOOK_VIDEO_CALL_NOTE.id]) {

            return (
                <BookingNote target={HM_PORTAL_BOOK_VIDEO_CALL_NOTE}
                             onHideDialog={this.onHideMenuEntry}
                             onOkDialog={this.bookVideoCall}
                />
            )
        } else {
            return (
                ShowQuestionDialog(this, HM_PORTAL_BOOK_VIDEO_CALL, this.confirmBookVideoCall)
            )
        }
    }

    render() {

        if (!this.state.videoCallsLoaded || !this.state.usersLoaded) {
            return null;
        }

      

        const header =
            <div>
                <div className="appointment-booking-header">
                    <div className="left">
                        <label id='panel-header'>{SM_CLIENT_BOOK_VIDEO_CALL.detail}</label>
                        <div>
                            <Button tooltip='Back a week'
                                    tooltipOptions={{position: 'top'}}
                                    icon="fa fa-chevron-left"
                                    onClick={() => this.onDateChange2(false)}/>
                            <Button tooltip='Forward a week'
                                    tooltipOptions={{position: 'top'}}
                                    icon="fa fa-chevron-right"
                                    onClick={() => this.onDateChange2(true)}/>
                        </div>
                        <div>
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}
                                      icon='fas fa-calendar-day'
                                      showIcon={true}/>
                        </div>
                    </div>
                    <div>
                        <Button label={SM_CLIENT_BOOK_VIDEO_CALL.exitLabel} icon={SM_CLIENT_BOOK_VIDEO_CALL.exitIcon}
                                className="p-button-success"
                                onClick={() => this.onSave(SE_NONE)}
                        />
                    </div>
                </div>
            </div>;

        return (

            <div className="p-col-12">

                {this.showDialogs()}

                <Panel header={header} className="no-pad">
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {this.insertVideoCalls()}
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        wsmessage: state.websockets.message,

        videoCallsLoaded: state.tablet.videoCallsLoaded,
        videoCalls: state.tablet.videoCalls,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        currentState: state.stateManagement[SM_CLIENT_BOOK_VIDEO_CALL.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getDiaryEvents: (start, end) => dispatch(getPortalDiaryEvents(RES_diaryEvents.GET_PORTAL_VC, start, end)),
        videoBooking: (params) => dispatch(onlineBooking(RES_diaryEvents.PORTAL_VIDEO_BOOKING, params)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const BookVideoCall = connect(mapStateToProps, mapDispatchToProps)(ConnectedBookVideoCall);

export default BookVideoCall;

