import React from 'react';
import {TextWriteOffs} from "../../../../Messages";
import {setState, SM_REPORTS_WRITTEN_OFFS_PDF} from "../../../../actions/stateManagement";
import {getReportPDF, RES_REPORT_WRITE_OFFS} from "../../../../actions/reports";
import {connect} from "react-redux";
import {ProgressBar} from "primereact/progressbar";
import {PDFBaseComponent} from "../../../PDFBaseComponent";

class ConnectedAccountWriteOffsPDF extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                pdfType: SM_REPORTS_WRITTEN_OFFS_PDF.id,
                numPages: null,
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_WRITE_OFFS.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_WRITTEN_OFFS_PDF.id, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.state.PDFLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (
            <div className="p-col-12 p-lg-12">

                {this.createPDFToolBar(TextWriteOffs, SM_REPORTS_WRITTEN_OFFS_PDF.filename)}
                {this.createPDFContainer(SM_REPORTS_WRITTEN_OFFS_PDF.label)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        PDFLoaded: state.reports.writeOffsPDFLoaded,
        pdfBuffer: state.reports.writeOffsPDF,

        currentState: state.stateManagement[SM_REPORTS_WRITTEN_OFFS_PDF.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReportPDF(RES_REPORT_WRITE_OFFS.PDF, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const AccountWriteOffsPDF = connect(mapStateToProps, mapDispatchToProps)(ConnectedAccountWriteOffsPDF);

export default AccountWriteOffsPDF;
