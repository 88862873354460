import React, {Component} from 'react';
import propTypes from 'prop-types';
import {connect} from "react-redux";
import {Bar} from "react-chartjs-2";
import {getDataSets, RES_KPI_DATA_SETS} from "../../../../actions/kpis";
import {ProgressBar} from "primereact/progressbar";
import {TabPanel} from "primereact/tabview";
import ActivePatientsDD from "../DrillDowns/ActivePatientsDD";
import {ICON_INFO} from "../../../../icons";

class ConnectedActivePatientsSection extends Component {

    constructor(props) {
        super(props);
        this.chartReference = React.createRef();

        this.state = {
            dataLoaded: false,
            data: [],
        };

        this.onClick = this.onClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps !== this.props) {
            if (this.props.dataLoaded) {

                this.setState({data: this.props.data, dataLoaded: true})
            }
        }
    }

    componentDidMount() {

        if (!this.state.dataLoaded) {
            this.props.getData();
        }
    }

    onClick(_evt, _element) {

        // block this out for now
        if (1<2)
            return;

        const element = Array.isArray(_evt) ? _evt : _element;

        if (element.length === 0) return;

        const {_index, _chart} = element[0];
        const {_data} = _chart.tooltip;
        const {labels} = _data;

        const header = `${RES_KPI_DATA_SETS.ACTIVE_PATIENTS.name} : ${labels[_index]}`;

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={`${RES_KPI_DATA_SETS.ACTIVE_PATIENTS_DD.id}_${_index}`}
                              header={header}
                              leftIcon={ICON_INFO}>
                        <ActivePatientsDD header={header}
                                          collIndex={_index}
                                          onCloseClick={this.props.onCloseClick}
                                          onTabUpdate={this.props.onTabUpdate}
                                          onPCButtonClick={this.props.onPCButtonClick}
                        />
                    </TabPanel>,
            }
        )
    }

    render() {

        if (!this.state.dataLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const options = {
            title: {
                display: true,
                text: 'Active Patients',
                fontSize: 16
            },
            legend: {
                position: 'bottom'
            },
            responsive: true,
            height: '300px',
            'onClick': this.onClick,
        };

        return (
            <Bar ref={this.chartReference}
                 data={this.state.data}
                 options={options}
                 getElementAtEvent={this.onClick}
            />)
    }
}

const MapStateToProps = state => {
    return {
        dataLoaded: state.kpis.activePatientsLoaded,
        data: state.kpis.activePatients,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getData: () => dispatch(getDataSets(RES_KPI_DATA_SETS.ACTIVE_PATIENTS)),
    }
};

const ActivePatientsSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedActivePatientsSection);

export default ActivePatientsSection;

ActivePatientsSection.propTypes = {
    data: propTypes.object,
    dataLoaded: propTypes.bool,
};