import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText, inputTextAreaKeyed} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {HM_PREFERENCES_REPORT} from "../Constants";
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";

export default class EditReportText extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reportText: {...props.reportText},
            selectedField: null,
        };

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevSate, snapshot) {

        if (this.props !== prevProps) {
        }
    }

    wrapFields(fields) {

        const wrappedFields = fields.map((field, index) => {
            return {label: field, value: index}
        });
        return wrappedFields;
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);
        _.set(state, 'reportText.edited', true);

        this.setState(state);
    }

    onHide() {
        this.props.onHideDialog(HM_PREFERENCES_REPORT.id);
    }

    insertTextAtCursor(el, text) {
        let val = el.value, endIndex, range, doc = el.ownerDocument;
        if (typeof el.selectionStart == "number"
            && typeof el.selectionEnd == "number") {
            endIndex = el.selectionEnd;
            el.value = val.slice(0, endIndex) + text + val.slice(endIndex);
            const newText = el.value;
            el.selectionStart = el.selectionEnd = endIndex + text.length;
            this.onChange({owner: 'reportText.text', value: newText});
        } else if (doc.selection !== "undefined" && doc.selection.createRange) {
            el.focus();
            range = doc.selection.createRange();
            range.collapse(false);
            range.text = text;
            range.select();
        }
    }

    onInsertText(value) {

        const text = `{${this.state.reportText.type.fieldSets[value]}}`;
        const reportText = document.getElementById('reportText');
        this.insertTextAtCursor(reportText, text);
    }

    render() {

        const reportText = this.state.reportText;
        const reportTextDefined = (reportText.name !== '' && reportText.text !== '') ? '' : 'disabled';

        const footer = (
            <div>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.reportText)
                        }}
                        disabled={reportTextDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const reportProps = {
            onChange: this.onChange,
            target: 'reportText',
            reportText: this.state.reportText,
        };

        const wrappedFields = this.wrapFields(this.state.reportText.type.fieldSets);

        return (
            <Dialog style={{width: "50%"}}
                    header={HM_PREFERENCES_REPORT.label}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <Panel header='Report Text'>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label htmlFor="name">Name</label>
                        </div>
                        <div className="p-col-12">
                            {inputText(reportProps, 'name', '', false, true)}
                        </div>
                        <div className="p-col-12">
                            <label htmlFor='report.comment'>Comment</label>
                        </div>
                        <div className="p-col-12">
                            {inputText(reportProps, 'comment', '', false, false)}
                        </div>
                        <div className="p-col-12">
                            {inputTextAreaKeyed('reportText', reportProps, 'text', 11, 30, false)}
                        </div>
                    </div>
                </Panel>
                <Panel header='Predefined Fields'>
                    <div className="p-grid p-fluid">
                        <div className="p-col-8">
                            <Dropdown options={wrappedFields} value={this.state.selectedField}
                                      onChange={(e) => this.setState({selectedField: e.value})}/>
                        </div>
                        <div className="p-col-4">
                            <Button disabled={this.state.selectedField === null} label='Insert Field'
                                    onClick={() => {
                                        this.onInsertText(this.state.selectedField);
                                    }}/>
                        </div>
                    </div>
                </Panel>
            </Dialog>
        )
    }
};
