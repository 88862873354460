//

export const NoBand = {id: 'NoBand', description: "", ordinal: 0};
export const Band1 = {id: 'Band1', description: "Band 1", ordinal: 1};
export const Band2 = {id: 'Band2', description: "Band 2", ordinal: 2};
export const Band3 = {id: 'Band3', description: "Band 3", ordinal: 3};
export const Urgent = {id: '', description: "Urgent Treatment", ordinal: 4};
export const Regulation11 = {id: 'Regulation11', description: "Select Band", ordinal: 5};
export const prescriptionOnly = {id: 'prescriptionOnly', description: "Prescription Only", ordinal: 6};
export const dentureRepairs = {id: 'dentureRepairs', description: "Denture Repairs", ordinal: 7};
export const bridgeRepairs = {id: 'bridgeRepairs', description: "Bridge Repairs", ordinal: 8};
export const arrestOfBleeding = {id: 'arrestOfBleeding', description: "Arrest of Bleeding", ordinal: 9};
export const removalOfSutures = {id: 'removalOfSutures', description: "Removal Of Sutures", ordinal: 10};

export const Band = [NoBand, Band1, Band2, Band3, Urgent, Regulation11, prescriptionOnly, dentureRepairs, bridgeRepairs, arrestOfBleeding, removalOfSutures];

//

export const _0000 = {id: '_0000', mappable: false, udaValue: 0.0, text: "Non NHS Code"}; //0
export const _9025 = {id: '_9025', mappable: false, udaValue: 0.0, text: "Ethnicity", i: 1, j: 16}; //1
export const _9150 = {
    id: '_9150',
    mappable: false,
    band: NoBand,
    udaValue: 1.0,
    text: "Treatment Band, (01 is band 1, 02 is band 2, 03 is band 3 and 04 is urgent treatment)",
    i: 1,
    j: 4
}; //2
export const _9152 = {id: '_9152', mappable: true, udaValue: 0.0, text: "Domiciliary Services"}; //3
export const _9153 = {id: '_9153', mappable: true, udaValue: 0.0, text: "Free Repair/Replacement"}; //4
export const _9154 = {id: '_9154', mappable: true, udaValue: 1.0, text: "Denture Repairs"}; //5
export const _9155 = {id: '_9155', mappable: true, udaValue: 1.2, text: "Arrest of Bleeding"}; //6
export const _9156 = {id: '_9156', mappable: true, udaValue: 1.0, text: "Removal of Sutures"}; //7
export const _9157 = {id: '_9157', mappable: true, udaValue: 1.2, text: "Bridge Repairs"}; //8
export const _9158 = {id: '_9158', mappable: true, udaValue: 0.0, text: "Prescription Only"}; //9
export const _9162 = {id: '_9162', mappable: true, udaValue: 12.0, text: "Regulation 11 general"}; //10
export const _9163 = {id: '_9163', mappable: false, udaValue: 0.0, text: "Further treatment within 2 months"}; //11
export const _9164 = {
    id: '_9164',
    mappable: false,
    udaValue: 0.0,
    text: "Incomplete treatment (01 is band 1, 02 is band 2 and 03 is band 3)",
    i: 1,
    j: 4
}; //12
export const _9166 = {id: '_9166', mappable: true, udaValue: 0.0, text: "Sedation Services"}; //13
export const _9172 = {
    id: '_9172',
    mappable: false,
    udaValue: 0.0,
    text: "NICE Guidance = (01-24 range)",
    i: 1,
    j: 24
}; //14
export const _9173 = {id: '_9173', mappable: false, udaValue: 0.0, text: "Better Oral Health"}; //15
export const _9301 = {id: '_9301', mappable: true, band: Band1, udaValue: 1.0, text: "Scale and Polish"}; //16
export const _9302 = {id: '_9302', mappable: true, band: Band1, udaValue: 1.0, text: "Fluoride Varnish"}; //17
export const _9303 = {id: '_9303', mappable: true, band: Band1, udaValue: 1.0, text: "Fissure Sealants"}; //18
export const _9304 = {
    id: '_9304',
    mappable: true,
    band: Band1,
    udaValue: 1.0,
    text: "Radiographs (01-28 range)",
    i: 0,
    j: 28
}; //19
export const _9305 = {
    id: '_9305',
    mappable: true,
    band: Band2,
    udaValue: 2.0,
    text: "Endodontic treatment (01-32 range)",
    i: 1,
    j: 32
}; //20
export const _9306 = {
    id: '_9306',
    mappable: true,
    band: Band2,
    udaValue: 3.0,
    text: "Permanent Fillings / Sealant Rests (01-32 range)",
    i: 1,
    j: 32
}; //21
export const _9307 = {
    id: '_9307',
    mappable: true,
    band: Band2,
    udaValue: 3.0,
    text: "Extractions (01-32 range)",
    i: 1,
    j: 32
}; //22
export const _9308 = {
    id: '_9308',
    mappable: true,
    band: Band3,
    udaValue: 12.0,
    text: "Crowns provided (01-32 range)",
    i: 1,
    j: 32
}; //23
export const _9309 = {
    id: '_9309',
    mappable: true,
    band: Band3,
    udaValue: 12.0,
    text: "Upper Denture � Acrylic (01-14 range)",
    i: 1,
    j: 32
}; //24
export const _9310 = {
    id: '_9310',
    mappable: true,
    band: Band3,
    udaValue: 12.0,
    text: "Lower Denture � Acrylic (01-14 range)",
    i: 1,
    j: 32
}; //25
export const _9311 = {
    id: '_9311',
    mappable: true,
    band: Band3,
    udaValue: 12.0,
    text: "Upper Denture � Metal (01-14 range)",
    i: 1,
    j: 14
}; //26
export const _9312 = {
    id: '_9312',
    mappable: true,
    band: Band3,
    udaValue: 12.0,
    text: "Lower Denture � Metal (01-14 range)",
    i: 1,
    j: 14
}; //27
export const _9313 = {
    id: '_9313',
    mappable: true,
    band: Band3,
    udaValue: 12.0,
    text: "Veneer(s) applied (01-12 range)",
    i: 1,
    j: 12
}; //28
export const _9314 = {
    id: '_9314',
    mappable: true,
    band: Band3,
    udaValue: 12.0,
    text: "Inlay(s) (01-20 range)",
    i: 1,
    j: 20
}; //29
export const _9315 = {
    id: '_9315',
    mappable: true,
    band: Band3,
    udaValue: 12.0,
    text: "Bridge unit(s) fitted (01-28 range)",
    i: 1,
    j: 28
}; //30
export const _9316 = {id: '_9316', mappable: true, udaValue: 0.0, text: "Referral for AMS"}; //31
export const _9317 = {id: '_9317', mappable: true, band: Band1, udaValue: 1.0, text: "Examination"}; //32
export const _9318 = {
    id: '_9318',
    mappable: true,
    udaValue: 0.0,
    text: "Antibiotic Items prescribed (01-04 range)",
    i: 1,
    j: 4
}; //33
export const _9399 = {id: '_9399', mappable: true, band: NoBand, udaValue: 1.0, text: "Other Treatment"}; //34
export const _9501 = {id: '_9501', mappable: true, udaValue: 0.0, text: "Urgent Appointments"}; //35
export const _9502 = {id: '_9502', mappable: false, udaValue: 0.0, text: "New Patient"}; //36
export const _9504 = {id: '_9504', mappable: true, udaValue: 0.0, text: "Basic Periodontal Exam"}; //37
export const _9505 = {id: '_9505', mappable: false, udaValue: 0.0, text: "Visible Plaque"}; //38
export const _9506 = {id: '_9506', mappable: true, udaValue: 0.0, text: "Dental Care Assessment"}; //39
export const _9507 = {id: '_9507', mappable: true, udaValue: 0.0, text: "Tooth Decay Assessment"}; //40
export const _9509 = {id: '_9509', mappable: true, udaValue: 0.0, text: "Soft Tissue Assessment"}; //41
export const _9511 = {id: '_9511', mappable: false, udaValue: 0.0, text: "Health Promotion (smoking status)"}; //42
export const _9512 = {id: '_9512', mappable: false, udaValue: 0.0, text: "Health Promotion (smoking cessation)"}; //43
export const _9513 = {id: '_9513', mappable: false, udaValue: 0.0, text: "Toothbrushing Advice"}; //44
export const _9515 = {id: '_9515', mappable: false, udaValue: 0.0, text: "Continuity of Care (written care plan)"}; //45
export const _9012 = {id: '_9012', mappable: false, band: Band1, udaValue: 1.0, text: "Assess and Review"}; //46
export const _9013 = {id: '_9013', mappable: false, band: Band1, udaValue: 1.0, text: "Assess and Refuse treatment"}; //47
export const _9014 = {id: '_9014', mappable: false, band: Band1, udaValue: 1.0, text: "Assess and Appliance fitted"}; //48
export const _9015 = {id: '_9015', mappable: false, udaValue: 0.0, text: "Index Orthodontic treatment need = {id: '', mappable: 01-05 range)", i: 1, j: 5 }; //49
export const _9017 = {id: '_9017', mappable: false, udaValue: 0.0, text: "Day of referral = {id: '', mappable: 01-31 range)", i: 1, j: 31}; //50
export const _9018 = {id: '_9018', mappable: false, udaValue: 0.0, text: "Month of Referral = {id: '', mappable: 01-12 range)", i: 1, j: 12};
export const _9019 = {
    id: '_9019',
    mappable: false,
    udaValue: 0.0,
    text: "Year of Referral (00-99 range)",
    i: 0,
    j: 99
}; //52
export const _9020 = {
    id: '_9020',
    mappable: false,
    udaValue: 0.0,
    text: "Day of Assessment (01-31 range)",
    i: 1,
    j: 31
}; //53
export const _9021 = {
    id: '_9021',
    mappable: false,
    udaValue: 0.0,
    text: "Month of Assessment (01-12 range)",
    i: 1,
    j: 12
}; //54
export const _9022 = {
    id: '_9022',
    mappable: false,
    udaValue: 0.0,
    text: "Year of Assessment (00-99 range)",
    i: 0,
    j: 99
}; //55
export const _9159 = {
    id: '_9159',
    mappable: true,
    udaValue: 0.8,
    text: "Repair to Appliance fitted by another dentist"
}; //56
export const _9161 = {
    id: '_9161',
    mappable: false,
    udaValue: 0.0,
    text: "Trt Abandoned (01), Discontinued (02) or Completed (03)",
    i: 1,
    j: 3
}; //57
export const _9165 = {
    id: '_9165',
    mappable: true,
    udaValue: 0.0,
    text: "Aesthetic Component (01-10 range)",
    i: 1,
    j: 10
}; //58
export const _9167 = {id: '_9167', mappable: true, udaValue: 0.0, text: "Regulation 11 Orthodontic"}; //59
export const _9168 = {id: '_9168', mappable: false, udaValue: 0.0, text: "Assess and Appliance fitted"}; //60
export const _9169 = {
    id: '_9169',
    mappable: false,
    udaValue: 0.0,
    text: "Day of Appliance fitted (01-31 range)",
    i: 1,
    j: 31
}; //61
export const _9170 = {
    id: '_9170',
    mappable: false,
    udaValue: 0.0,
    text: "Month of Appliance Fitted (01-12 range)",
    i: 1,
    j: 12
}; //62
export const _9171 = {
    id: '_9171',
    mappable: false,
    udaValue: 0.0,
    text: "Year of Appliance Fitted (00-99 range)",
    i: 0,
    j: 99
}; //63
export const _9401 = {id: '_9401', mappable: true, band: Band3, udaValue: 1.0, text: "Removable upper appliance"}; //64
export const _9402 = {id: '_9402', mappable: true, band: Band3, udaValue: 1.0, text: "Removable lower appliance"}; //65
export const _9403 = {id: '_9403', mappable: true, band: Band3, udaValue: 1.0, text: "Functional Appliance"}; //66
export const _9404 = {id: '_9404', mappable: true, band: Band3, udaValue: 1.0, text: "Fixed Upper Appliance"}; //67
export const _9405 = {id: '_9405', mappable: true, band: Band3, udaValue: 1.0, text: "Fixed Lower Appliance"}; //68
export const _9406 = {id: '_9406', mappable: true, band: Band3, udaValue: 1.0, text: "Retainer Upper"}; //69
export const _9407 = {id: '_9407', mappable: true, band: Band3, udaValue: 1.0, text: "Retainer Lower"}; //70
export const _9408 = {
    id: '_9408',
    mappable: true,
    band: Band1,
    udaValue: 1.0,
    text: "Extractions (ranges 1-8 and UR, UL, LR, LL)",
    i: 1,
    j: 8
}; //71
export const _9409 = {id: '_9409', mappable: false, udaValue: 0.0, text: "Trt abandoned � patient FTR"}; //72
export const _9410 = {id: '_9410', mappable: false, udaValue: 0.0, text: "Trt abandoned � patient requested"}; //73
export const _9411 = {id: '_9411', mappable: false, udaValue: 0.0, text: "PAR scores calculated"}; //74
export const _9161_1 = {
    id: '_9161_1',
    mappable: false,
    udaValue: 0.0,
    text: "Treatment Abandoned - patient FTR",
    i: 1,
    j: 4
}; //75
export const _9161_2 = {
    id: '_9161_2',
    mappable: false,
    udaValue: 0.0,
    text: "Treatment Abandoned - patient requested",
    i: 1,
    j: 3
}; //76
export const _9161_3 = {id: '_9161_3', mappable: false, udaValue: 0.0, text: "Treatment Discontinued", i: 1, j: 3}; //77
export const _9161_4 = {id: '_9161_4', mappable: false, udaValue: 0.0, text: "Treatment Completed", i: 1, j: 3}; //78
export const _9014_1 = {
    id: '_9014_1',
    mappable: false,
    band: Band1,
    udaValue: 1.0,
    text: "Assess and Appliance TO BE FITTED"
}; //79
export const _9901 = {id: '_9901', mappable: false, band: Band1, udaValue: 1.0, text: "NHS Other Treatment Band 1"}; //80
export const _9902 = {id: '_9902', mappable: false, band: Band2, udaValue: 3.0, text: "NHS Other Treatment Band 2"}; //81
export const _9903 = {id: '_9903', mappable: false, band: Band3, udaValue: 12.0, text: "NHS Other Treatment Band 3"}; //82

// As of 01/04/2019

export const _9412 = {id: '_9412', mappable: false, udaValue: 0.0, text: "Photographs"}; //83
export const _9413 = {id: '_9413', mappable: false, udaValue: 0.0, text: "Pre-Treatment Par Scores"}; //84
export const _9414 = {id: '_9414', mappable: false, udaValue: 0.0, text: "Post-Treatment Par Scores"}; //85
export const _9415 = {
    id: '_9415',
    mappable: false,
    udaValue: 0.0,
    text: "Proposed v Completed/abandoned/ discontinued treatment"
}; //86

export const _9175 = {id: '_9175', mappable: false, udaValue: 0.0, text: "Patient declined Email"}; //87 
export const _9176 = {id: '_9176', mappable: false, udaValue: 0.0, text: "Patient declined Mobile"}; //88 
export const _9177 = {id: '_9177', mappable: false, udaValue: 0.0, text: "Commissioner approval"}; //89

// charges

export const YEAR_INDEX = 5;

export const BandCharges = [
    [// 2019
        [22.70, 62.10, 269.30, 80.70, 161.40],      // england
        [14.30, 46.00, 199.10, 59.70, 119.40],      // wales
        [18.50, 50.50, 219.00, 65.70, 131.40],      // IOM
    ],
    [// 2020
        [22.70, 62.10, 269.30, 80.70, 161.40],      // england
        [14.30, 46.00, 199.10, 59.70, 119.40],      // wales
        [18.50, 50.50, 219.00, 65.70, 131.40],      // IOM
    ],
    [// 2021
        [23.80, 65.20, 282.80, 84.80, 23.80],      // england
        [14.30, 46.00, 199.10, 59.70, 119.40],      // wales
        [18.50, 50.50, 219.00, 65.70, 131.40],      // IOM
    ],
    [// 2022
        [23.80, 65.20, 282.80, 84.80, 23.80],      // england
        [14.30, 46.00, 199.10, 59.70, 119.40],      // wales
        [18.50, 50.50, 219.00, 65.70, 131.40],      // IOM
    ],
    [// 2023
        [23.80, 65.20, 282.80, 84.80, 23.80],      // england
        [14.30, 46.00, 199.10, 59.70, 119.40],      // wales
        [18.50, 50.50, 219.00, 65.70, 131.40],      // IOM
    ],
    [// 2024
        [26.80, 73.20, 319.10, 95.70, 191.40],      // england
        [20.00, 60.00, 260.00, 78.00, 156.00],      // wales
        [18.50, 50.50, 219.00, 65.70, 131.40],      // IOM
    ],
];

export const Codes9000OtherTreatments = [_9901, _9902, _9903];

export const Codes9000OrthoStart = [_9012, _9013, _9014];

export const Codes9000OrthoCompletion = [_9161_1, _9161_2, _9161_3, _9161_4];

export const Codes9000TreatmentCategory = [_9150, _9154, _9155, _9156, _9157, _9158, _9162];

export const Codes9000GeneralDataSet = [_9025, _9150, _9152, _9153, _9154, _9155, _9156, _9157, _9162, _9163, _9164, _9166, _9172, _9173];

export const Codes9000ClinicalDataSet = [_9301, _9302, _9303, _9304, _9305, _9306, _9307, _9308, _9309, _9310, _9311, _9312, _9313, _9314, _9315, _9316, _9317, _9318, _9399];

export const Codes9000KPIDataSet = [_9501, _9502, _9504, _9505, _9506, _9507, _9509, _9511, _9512, _9513, _9515];

export const Codes9000OrthodonticDataSet = [_9304, _9401, _9402, _9403, _9404, _9405, _9406, _9407, _9408];

export const Codes9000FurtherTreatmentExclusions = [_9150, _9164, _9163];

export const Part_7_Nice_Guidance = "I have given preventative advice and recommended a recall interval, taking into account NICE guidance, that I regard as appropriate to the patient's current oral health";

export const Part_9_Declaration_1 = "All the necessary care and treatment that the patient is willing to undergo will be provided";
export const Part_9_Declaration_2 = "All the currently necessary care and treatment that the patient is willing to undergo has been carried out";
export const Part_9_Declaration_3 = "I declare that I am properly entitled to practise under the current dental regulations and that the information I have given on this form is correct and complete. I understand that if it is not, appropriate action may be taken. For the purposes of verification of this and the prevention and detection of fraud and incorrectness, I consent to the disclosure of relevant information from this form to and by the NHS Business Services Authority";