import _ from 'lodash';
import * as Actions from '../actions/index';
import {SM_TAB_PATIENT_CONSENT} from "../actions/stateManagement";

const initialState = {

    membersLoaded: false,
    members: [],

    medConsMembersLoaded: false,
    medConsMembers: [],

    patientConsentMembersLoaded: false,
    patientConsentMembers: [],

    patientQuestionnairesLoaded: false,
    patientQuestionnaires: [],

    treatmentPlansLoaded: false,
    treatmentPlans: [],

    paymentPlansLoaded: false,
    paymentPlans: [],

    onlinesLoaded: true,
    onlines: [],

    videoCallsLoaded: true,
    videoCalls: [],

    possibleOtherFormsLoaded: false,
    possibleOtherForms: [],
};

export const tablet = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.RECEIVE_TAB_PATIENT_DETAILS:
                return {
                    ...state,
                    membersLoaded: true,
                    members: action.payload,
                };

            case Actions.RECEIVE_TAB_PATIENT_MED_CONS:
                return {
                    ...state,
                    medConsMembersLoaded: true,
                    medConsMembers: action.payload,
                };

            case Actions.RECEIVE_TAB_PATIENT_CONSENTS:
                return {
                    ...state,
                    patientConsentMembersLoaded: true,
                    patientConsentMembers: action.payload,
                };

            case Actions.RECEIVE_TAB_PATIENT_QUESTIONNAIRES:
                return {
                    ...state,
                    patientQuestionnairesLoaded: true,
                    patientQuestionnaires: action.payload,
                };

            case Actions.RECEIVE_TAB_PATIENT_TP_SIGNS:
                return {
                    ...state,
                    treatmentPlansLoaded: true,
                    treatmentPlans: action.payload,
                };

            case Actions.RECEIVE_TAB_PATIENT_PP_SIGNS:
                return {
                    ...state,
                    paymentPlansLoaded: true,
                    paymentPlans: action.payload,
                };

            case Actions.RECEIVE_DIARY_EVENTS_PORTAL:
            case Actions.RECEIVE_DIARY_EVENTS_PORTAL_UA:
                return {
                    ...state,
                    onlinesLoaded: true,
                    onlines: action.payload,
                };

            case Actions.RECEIVE_DIARY_EVENTS_PORTAL_VC:
                return {
                    ...state,
                    videoCallsLoaded: true,
                    videoCalls: action.payload,
                };

            case Actions.RECEIVE_TAB_PATIENT_CONSENT_FORM_PDF:

                const loadedId = `${SM_TAB_PATIENT_CONSENT.loaded}_${action.id}`;
                const dataId = `${SM_TAB_PATIENT_CONSENT.id}_${action.id}`;

                return {
                    ...state,
                    [loadedId]: true,
                    [dataId]: action.payload,
                };

            case Actions.RECEIVE_GDPR_CONSENT_FORM_PDF:

                const gdprLoadedId = `${Actions.RECEIVE_GDPR_CONSENT_FORM_PDF}Loaded`;
                const gdprDataId = Actions.RECEIVE_GDPR_CONSENT_FORM_PDF;

                return {
                    ...state,
                    [gdprLoadedId]: true,
                    [gdprDataId]: action.payload,
                };

            case Actions.REQUEST_TAB_POSSIBLES:
                return {
                    ...state,
                    possibleOtherFormsLoaded: false,
                    possibleOtherForms: [],
                };

            case Actions.RECEIVE_TAB_POSSIBLES:
                return {
                    ...state,
                    possibleOtherFormsLoaded: true,
                    possibleOtherForms: action.payload,
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};