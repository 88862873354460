import React from 'react';
import _ from 'lodash';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Button} from 'primereact/components/button/Button';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    DOC_SYSTEM_TEMPLATE,
    HM_CopyTemplateSystem,
    HM_CreateTemplateSystem,
    HM_DeleteTemplateSystem,
    HM_EditTemplateSystem,
    HM_notImplemented, ME_SystemTemplates,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    UP_TEMPLATE_ADDED
} from "../../../Constants";
import {ICON_HELP, ICON_PLUS, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED, ICON_SEARCH, ICON_SHARE,} from "../../../icons";
import {connect} from "react-redux";
import {HELP_TEMPLATES_SYSTEM, setState, SM_templatesSystem} from "../../../actions/stateManagement";
import {
    copyTemplate,
    deleteHskItem,
    getResource,
    RES_REPORT_TYPES,
    RES_TEMPLATES_SYSTEM,
    saveSystemTemplate,
    updateSystemTemplate
} from "../../../actions/housekeeping";
import AddSystemTemplate from "./dialogs/AddSystemTemplate";
import EditTemplate from "./dialogs/EditTemplate";
import CopyTemplate from "./dialogs/CopyTemplate";
import {ShowMessageDialog} from "../Diary/components/EventComponent";

import {InputText} from "primereact/inputtext";
import {TemplateComponent} from "./TemplateComponent";
import {ProgressBar} from "primereact/progressbar";
import {WSM_UPDATES} from "../../../actions";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";

export class ConnectedSystemTemplates extends TemplateComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_templatesSystem.id,

                templates: [],
                canSave: {status: false, activeIndex: 0},
                showSaveDialog: false,
                [HM_CreateTemplateSystem.id]: false,
                [HM_EditTemplateSystem.id]: false,
                [HM_DeleteTemplateSystem.id]: false,
                [HM_CopyTemplateSystem.id]: false,
                [HM_notImplemented.id]: false,
                template: {deletable: false},

                first: 0,
                rows: 5,

                globalFilter: '',
            }
        }

        this.showDialogs = this.showDialogs.bind(this);

        this.onExit = this.onExit.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.onDeleteTemplate = this.onDeleteTemplate.bind(this);
        this.onAddTemplate = this.onAddTemplate.bind(this);
        this.onEditTemplate = this.onEditTemplate.bind(this);
        this.reportTemplate = this.reportTemplate.bind(this);
        this.onOnlyOfficeOpen = this.onOnlyOfficeOpen.bind(this);

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getReportTypes();
            this.props.getTemplates();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_TEMPLATES_SYSTEM.GET.receive:
                    this.setState({templatesLoaded: true, templates: this.props.templates}, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;
                case RES_TEMPLATES_SYSTEM.SAVE.action:
                    break;
                case WSM_UPDATES:

                    switch (this.props.wsmessage.function) {
                        case UP_TEMPLATE_ADDED:
                            if (this.props.wsmessage.content.type === DOC_SYSTEM_TEMPLATE.name) {
                                const templates = [...this.state.templates];
                                templates.push(this.props.wsmessage.content);
                                this.setState({templatesLoaded: true, templates}, () => {
                                    this.props.setState(this.state.stateManagementId, this.state);
                                });
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            this.save = true;
        }
    }

    showDialogs() {

        const contents = [];

        if (this.state[HM_DeleteTemplateSystem.id]) {

            const footer = <div>
                <Button label="Yes" icon="fa fa-check" onClick={() => {
                    this.onDeleteTemplate(HM_DeleteTemplateSystem.id);
                }}/>
                <Button label="No" icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteTemplateSystem.id)
                }}/>
            </div>;

            contents.push(
                <Dialog key={HM_DeleteTemplateSystem.id}
                        header={HM_DeleteTemplateSystem.header}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.onHideMenuEntry(HM_CreateTemplateSystem.id);
                        }}>
                    {HM_DeleteTemplateSystem.message}
                </Dialog>
            )
        } else if (this.state[HM_CreateTemplateSystem.id]) {
            contents.push(
                <AddSystemTemplate key={HM_CreateTemplateSystem.id}
                                   origin={HM_CreateTemplateSystem.id}
                                   header={HM_CreateTemplateSystem.header}
                                   loginIdentity={this.props.loginIdentity}
                                   visible={true}
                                   onHideDialog={this.onHideMenuEntry}
                                   onSaveDialog={(template) => {
                                       this.onHideMenuEntry(HM_CreateTemplateSystem.id);
                                       this.onAddTemplate(template);
                                   }}
                                   reportTypes={this.props.reportTypes}
                />
            )
        } else if (this.state[HM_EditTemplateSystem.id]) {
            contents.push(
                <EditTemplate key={HM_EditTemplateSystem.id}
                              origin={HM_EditTemplateSystem.id}
                              visible={true}
                              onHideDialog={this.onHideMenuEntry}
                              onSaveDialog={(template) => {
                                  this.onHideMenuEntry(HM_EditTemplateSystem.id);
                                  this.onEditTemplate(template);
                              }}
                              item={this.state.template}
                />
            )
        } else if (this.state[HM_CopyTemplateSystem.id]) {

            const templateCopy = {...this.state.template};
            templateCopy.id = null;

            contents.push(
                <CopyTemplate key={HM_CopyTemplateSystem.id}
                              origin={HM_CopyTemplateSystem.id}
                              visible={true}
                              onHideDialog={this.onHideMenuEntry}
                              onSaveDialog={(template) => {
                                  this.onHideMenuEntry(HM_CopyTemplateSystem.id);
                                  this.onCopyTemplate(template);
                              }}
                              template={templateCopy}
                />
            )
        }
        contents.push(ShowMessageDialog(this, HM_notImplemented));

        return contents;
    }

    render() {

        if (!this.state.templatesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }


        const items = [
            {
                label: HM_EditTemplateSystem.label,
                icon: HM_EditTemplateSystem.icon,
                command: this.onShowMenuEntry,
                target: HM_EditTemplateSystem.id
            },
            {
                label: HM_CopyTemplateSystem.label,
                icon: HM_CopyTemplateSystem.icon,
                command: this.onShowMenuEntry,
                target: HM_CopyTemplateSystem.id
            },
            {
                label: HM_DeleteTemplateSystem.label,
                icon: HM_DeleteTemplateSystem.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteTemplateSystem.id,
                disabled: !this.state.template.deletable
            },
        ];

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>{ME_SystemTemplates.label}</span>
                        <Button tooltip={HM_CreateTemplateSystem.header}
                                tooltipOptions={{position: 'right'}}
                                icon={ICON_PLUS}
                                onClick={(e) => {
                                    this.onShowMenuEntry({item: {target: HM_CreateTemplateSystem.id}})
                                }}/>
                    </div>
                    <div className="items-margin d-flex d-align-center">
                        <InputText type="search"
                                   value={this.state.globalFilter}
                                   onInput={(e) => {
                                       this.setState({globalFilter: e.target.value, first: 0}, () => {
                                           this.props.setState(this.state.stateManagementId, this.state);
                                       });
                                   }}
                                   placeholder="Search" size="50"
                                   autoFocus
                        />
                    </div>
                </div>;

        const footer = <div>
            <Button label="Yes" icon="fa fa-check" onClick={() => {
                this.onSave(true)
            }}/>
            <Button label="No" icon="fas fa-times" onClick={this.onExit}/>
        </div>;

        let templates = _.filter(this.state.templates, template => {
            const pattern = `${template.visibleName} ${template.notes}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'visibleName', 'asc');

        let key = 1000;

        return (
            <div>

                <Toolbar left={
                    <React.Fragment>
                        {tb_boilerPlate2(() => this.onSave(false), saveIcon, !this.state.canSave.status, TB_SAVE.text, key++)}
                        {tb_boilerPlate2(() => this.toolbarCallbacks[HM_notImplemented.id](), ICON_SHARE, false, 'Assign System Document Template', key++)}
                    </React.Fragment>
                }
                         right={
                             <React.Fragment>
                                 {tb_boilerPlateRight((e) => window.open(HELP_TEMPLATES_SYSTEM), ICON_HELP, 'Knowledge Base', key++, true)}
                                 {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                             </React.Fragment>
                         }
                />
                <Dialog header="Save Resource"
                        visible={this.state.showSaveDialog}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>
                    The form has been modified, save these changes?
                </Dialog>

                {this.showDialogs()}

                <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>
                    <div className="p-col-12">
                        <Panel headerTemplate={header}>

                            <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                            <DataTable value={sortedTemplates}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       selection={this.state.template}
                                       onRowDoubleClick={(event) => {
                                           this.onOnlyOfficeOpen()
                                       }}
                                       contextMenuSelection={this.state.template}
                                       onContextMenuSelectionChange={this.onSelection}
                                       onContextMenu={e => this.cm.show(e.originalEvent)}
                                       paginator={true}
                                       rows={this.state.rows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onSelectionChange={this.onSelection}
                                       first={this.state.first}
                                       onPage={this.onPage}
                            >
                                <Column field="deletable"
                                        style={{width: '5%'}}
                                        body={this.deletableTemplate}
                                />
                                <Column field="visibleName"
                                        header="Name"
                                        style={{width: '40%'}}/>
                                <Column field=''
                                        header="Report"
                                        style={{width: '15%'}}
                                        body={this.reportTemplate}
                                />
                                <Column field="notes"
                                        header="Notes"
                                        style={{width: '40%'}}/>
                            </DataTable>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        reportTypesLoaded: state.housekeeping.reportTypesLoaded,
        reportTypes: state.housekeeping.reportTypes,
        docTypes: state.housekeeping.docTypes,

        templatesLoaded: state.housekeeping.systemTemplatesLoaded,
        templates: state.housekeeping.systemTemplates,

        loginIdentity: state.login.user,

        systemTemplateResult: state.housekeeping.systemTemplateResult,

        currentState: state.stateManagement[SM_templatesSystem.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReportTypes: () => dispatch(getResource(RES_REPORT_TYPES.GET)),
        getTemplates: () => dispatch(getResource(RES_TEMPLATES_SYSTEM.GET)),
        saveTemplate: (newTemplate) => dispatch(saveSystemTemplate(newTemplate)),
        updateTemplate: (updateTemplate) => dispatch(updateSystemTemplate(updateTemplate)),
        copyTemplate: (newTemplate) => dispatch(copyTemplate(newTemplate)),
        deleteTemplate: (id) => dispatch(deleteHskItem(RES_TEMPLATES_SYSTEM.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const SystemTemplates = connect(mapStateToProps, mapDispatchToProps)(ConnectedSystemTemplates);

export default SystemTemplates;
