import moment from 'moment';
import React from "react";
import _ from "lodash";
import {Document, Page} from "react-pdf";
import {Panel} from "primereact/panel";
import {SM_TAB_GDPR_CONSENT} from "../../actions/stateManagement";

export const titleTemplate = (rowData) => {
    return rowData.title;
};

export const fullNameTemplate = (rowData) => {
    return `${rowData.title} ${rowData.firstname} ${rowData.lastname}`.trim();
};

export const dateTemplate = (date) => {
    return date === null ? '-' : moment(date).format('MMMM Do YYYY');
};

export const dateTimeTemplate = (start, end) => {
    return start === null ? '-' : `${moment(start).format('ddd, DD MMM HH:mm')} - ${moment(end).format('HH:mm')}`;
};

export const dateTimeTemplate2 = (time) => {
    return time === null ? '-' : `${moment(time).format('ddd, DD MMM HH:mm')}`;
};

export const timeTemplate = (start, end) => {
    return start === null ? '-' :
        <span style={{fontSize: "12px"}}>{moment(start).format('HH:mm')} - {moment(end).format('HH:mm')}</span>;
};

export const dateTimeTemplateClient = (start, end) => {
    return start === null ? 'No future appointment booked yet' : `${moment(start).format('ddd, DD MMM HH:mm')} - ${moment(end).format('HH:mm')}`;
};

export const genderTemplate = (rowData) => {
    return rowData['gender'] === 1 ? 'Male' : 'Female';
};

// PDF Consent functions

export const PDFHeader = (numPages, index, header) => {

    return <div className='panel-header-centered-content'><label id='panel-header'>{header}</label>
        <div className="p-toolbar-group-right">
            <label id='panel-header'>{`Page ${index + 1} of ${numPages}`}</label>
        </div>
    </div>;

}

export const PDFConsentSection = (props) => {

    let visibleName = ''

    if (props.GDPRConsent) {
        visibleName = SM_TAB_GDPR_CONSENT.label;
    } else {
        const template = _.find(props.cfTemplates, template => template.docId === props.consentForm.templateId);
        visibleName = template.visibleName;
    }
    const parameterObject = {
        data: props.pdf,
    };

    return (
        <div className="p-grid p-fluid">

            <div className="p-col-12">

                <Document
                    file={parameterObject}
                    onLoadSuccess={props.onDocumentLoadSuccess}
                    onLoadError={props.onLoadError}
                    onItemClick={() => {
                    }}
                >
                    {
                        Array.from(
                            new Array(props.numPages),
                            (el, index) => (
                                <Panel header={PDFHeader(props.numPages, index, visibleName)}
                                       key={`panel_${index + 1}`}
                                       style={{paddingTop: '10px'}}>
                                    <Page key={`page_${index + 1}`}
                                          pageNumber={index + 1}
                                          width={650}
                                          renderTextLayer={false}
                                    />
                                </Panel>
                            ),
                        )
                    }
                </Document>
            </div>
        </div>
    )
}
