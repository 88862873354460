import React, {Component} from 'react';
import * as TC from "../Model/Constants";
import {JawLower, JawUpper, TOOTH_GAP, TOOTH_HEIGHT, TOOTH_SIZE} from "../Model/Constants";
import {getRandomKey} from "../Utils/general";
import {elideSurfaces} from "../Model/ElideSurfaces";

export class Filling extends Component {

    constructor() {
        super();
        this.state = {};
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    render() {

        let translate = '';

        const {mainShapes, outlines} = elideSurfaces(this.props.tooth, this.props.data.surfaceList);

        switch (this.props.tooth.jaw) {
            case JawLower:
                translate = `translate(${(TOOTH_GAP + TOOTH_SIZE) * this.props.tooth.pos.offset + 6} ,${(TOOTH_HEIGHT + TOOTH_GAP) + TC.TOOTH_GAP})`;
                break;
            case JawUpper:
                translate = `translate(${(TOOTH_GAP + TOOTH_SIZE) * this.props.tooth.pos.offset + 6}, ${TOOTH_GAP + 100})`;
                break;
            default :
                break;
        }

        const lines = [];

        let pushIt = false;
        let line = [0, 0, 0, 0];

        outlines.forEach(outline => {

            if (!pushIt) {
                line[0] = outline[0];
                line[1] = outline[1];
            } else {
                line[2] = outline[0];
                line[3] = outline[1];
                lines.push(line);
            }
            pushIt = !pushIt;
        });

        const materialColor = `rgb(${this.props.data.material.red},${this.props.data.material.green},${this.props.data.material.blue})`;

        let key = Math.floor(Math.random() * 1000);

        return (
            <g key={getRandomKey()}>

                {mainShapes.map(shape => {

                    return (
                        <polygon key={key+=1}
                            points={shape}
                            style={{fill: materialColor}}
                            transform={translate}
                            onClick={(event, test) => {
                            }}
                        />
                    )
                })}
                {lines.map(line => {

                    return (
                        <polyline key={key+=1}
                                  style={{stroke: 'black'}}
                                  points={line}
                                  transform={translate}
                        />
                    )
                })}
            </g>
        )
    }
}
