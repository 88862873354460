import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import _ from "lodash";
import {deleteHskItem, RES_HOUSEKEEPING_DOC_CLASSIFICATIONS} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_DOC_CLASSIFICATIONS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import {HM_AddDocClassification, HM_DeleteDocClassification, HM_EditDocClassification,} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import {InputText} from "primereact/inputtext";
import AddDocumentClassification from "../dialogs/AddDocumentClassification";

export class ConnectedDocumentClassificationSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_DOC_CLASSIFICATIONS.id,

                classificationsLoaded: props.classificationsLoaded,
                classifications: [...props.classifications],
                fakeIndex: -1000,
                first: 0,
                rows: 5,

                globalFilter: '',

                selectedClassification: null,
                [HM_AddDocClassification.id]: false,
                [HM_EditDocClassification.id]: false,
                [HM_DeleteDocClassification.id]: false,
            }
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.GET.receive:
                case RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.SAVE.action:
                case RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.DELETE.action:
                    this.setState({
                        classificationsLoaded: true,
                        classifications: this.props.classifications,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onSelection = (e) => {
        this.setState({selectedClassification: e.value});
    }

    addClassification = (newClassification) => {

        const newClassifications = [...this.state.classifications];

        newClassification.id = this.state.fakeIndex;
        newClassifications.push(newClassification);

        this.setState({classifications: newClassifications, [HM_AddDocClassification.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
            });
        });
    }

    updateClassification = (editedClassification) => {

        const editedClassifications = [...this.state.classifications];
        editedClassification.edited = true;

        const index = _.findIndex(editedClassifications, (classifications) => {
            return classifications.id === editedClassification.id;
        });

        editedClassifications[index] = editedClassification;

        this.setState({classifications: editedClassifications, [HM_EditDocClassification.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state})

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
            });
        });
    }

    showDialogs = () => {

        if (this.state[HM_AddDocClassification.id]) {
            return (
                <AddDocumentClassification
                    editing={false}
                    header={HM_AddDocClassification.header}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addClassification}
                />
            )
        } else if (this.state[HM_EditDocClassification.id]) {
            return (
                <AddDocumentClassification
                    editing={true}
                    header={HM_EditDocClassification.header}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateClassification}
                    item={this.state.selectedClassification}
                />
            )
        } else if (this.state[HM_DeleteDocClassification.id]) {
            const footer = <div>
                <Button label="Yes" icon="fa fa-check" onClick={() => {
                    this.onDelete();
                }}/>
                <Button label="No" icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteDocClassification.id)
                }}/>
            </div>;

            return (

                <Dialog header={HM_DeleteDocClassification.header}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteDocClassification.id]: false})
                        }}>
                    {HM_DeleteDocClassification.message}
                </Dialog>
            )
        }
    }

    onDelete = () => {

        this.props.deleteDocClassification(this.state.selectedClassification.id);

        this.setState({
            selectedClassification: null,
            [HM_DeleteDocClassification.id]: false,
        });
    }

    render() {

        if (!this.props.classificationsLoaded) {
            return null;
        }

        const items = [
            {
                label: HM_EditDocClassification.header,
                icon: HM_EditDocClassification.icon,
                command: this.onShowMenuEntry,
                target: HM_EditDocClassification.id
            },
            {
                label: HM_DeleteDocClassification.header,
                icon: HM_DeleteDocClassification.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteDocClassification.id
            },
        ];

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>Document Classifications</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={HM_AddDocClassification.header}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_AddDocClassification.id}})
                        }}/>
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => {
                               this.setState({globalFilter: e.target.value, first: 0});
                           }}
                           placeholder="Search"
                           size="50"
                           autoFocus
                />
            </div>
        </div>;

        const filteredClassifications = _.filter(this.state.classifications, classification => {
            const pattern = `${classification.description}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const sortedClassifications = _.orderBy(filteredClassifications, 'code', 'asc');

        return (
            <Panel headerTemplate={header}>

                <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                {this.showDialogs()}

                <DataTable value={sortedClassifications}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}
                           selection={this.state.selectedClassification}
                           onSelectionChange={this.onSelection}
                           contextMenuSelection={this.state.selectedClassification}
                           onContextMenuSelectionChange={e => this.setState({selectedClassification: e.value})}
                           onContextMenu={e => this.cm.show(e.originalEvent)}
                >
                    <Column field="colour"
                            header=""
                            body={this.colourTemplate}
                            style={{width: '5%'}}/>
                    <Column field="code"
                            header="Code"
                            style={{width: '10%'}}/>
                    <Column field="description"
                            header="Description"
                            style={{width: '25%'}}/>
                    <Column field="status"
                            header="Status"
                            style={{width: '10%'}}/>
                </DataTable>
            </Panel>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        classificationsLoaded,
        classifications,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        classificationsLoaded,
        classifications,

        currentState: state.stateManagement[SM_HOUSEKEEPING_DOC_CLASSIFICATIONS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteDocClassification: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_DOC_CLASSIFICATIONS.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const DocumentClassificationSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedDocumentClassificationSection);

export default DocumentClassificationSection;
