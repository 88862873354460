import React from 'react';

import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {
    HM_AddPatientMedConHistory,
    HM_AddPatientMH,
    HM_addPatMedCondition,
    HM_DeletePatientMH,
    HM_deletePatMedCondition,
    HM_EditPatientMH,
    HM_editPatMedCondition,
    HM_notImplemented,
    HM_PrintPatientMH,
    HM_printPatMedCondition,
    MC_LEVELS
} from "../../../../Constants";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate, dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import _ from "lodash";
import {ProgressBar} from "primereact/progressbar";
import {Button} from "primereact/components/button/Button";
import {InputText} from "primereact/components/inputtext/InputText";
import * as Capabilities from "../../../Capabilities";

const createdByTemplate = (user) => {
    return `${user.title === null ? '' : user.title.abbreviation} ${user.firstName} ${user.lastName}`.trim();
};

const questionEntryTemplate = (row) => {
    return row.condition.formEntry;
};

const yesEntryTemplate = (row) => {

    if (row.condition.formHeader) return null;
    return row.yes ? <i alt='Yes' className='fa tick16'/> : null;
};

const noEntryTemplate = (row) => {

    if (row.condition.formHeader) return null;
    return !row.yes ? <i alt='No' className='fa tick16'/> : null;
};

export const MedConsHistorySection = (props) => {

    if (!props.patientMHFSLoaded || !props.patientMCONSLoaded) {
        return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
    }
    let conditionItems = [];

    if (_.includes(props.capabilities, Capabilities.AID_EDIT_PATIENT_MEDCON)) {
        conditionItems.push(
            {
                label: HM_editPatMedCondition.header,
                icon: HM_editPatMedCondition.icon,
                command: () => props.toolbarCallbacks[HM_editPatMedCondition.id](),
                target: HM_editPatMedCondition.id
            });
    }

    if (_.includes(props.capabilities, Capabilities.AID_DEL_PATIENT_MEDCON)) {
        conditionItems.push(
            {
                label: HM_deletePatMedCondition.header,
                icon: HM_deletePatMedCondition.icon,
                command: () => props.toolbarCallbacks[HM_deletePatMedCondition.id](),
                target: HM_deletePatMedCondition.id
            });
    }

    conditionItems.push(
        {
            label: HM_printPatMedCondition.label,
            icon: HM_printPatMedCondition.icon,
            command: () => props.toolbarCallbacks[HM_notImplemented.id](),
            target: HM_printPatMedCondition.id
        });

    let conditionCm = null;

    let historyItems = [];

    if (_.includes(props.capabilities, Capabilities.AID_EDIT_PATIENT_MHF)) {
        historyItems.push(
            {
                label: HM_EditPatientMH.label,
                icon: HM_EditPatientMH.icon,
                command: () => props.toolbarCallbacks[HM_EditPatientMH.id](),
                target: HM_EditPatientMH.id
            });
    }

    if (_.includes(props.capabilities, Capabilities.AID_DEL_PATIENT_MHF)) {
        historyItems.push(
            {
                label: HM_DeletePatientMH.label,
                icon: HM_DeletePatientMH.icon,
                command: () => props.toolbarCallbacks[HM_DeletePatientMH.id](),
                target: HM_DeletePatientMH.id
            });
    }

    historyItems.push(
        {
            label: HM_PrintPatientMH.label,
            icon: HM_PrintPatientMH.icon,
            command: () => props.toolbarCallbacks[HM_PrintPatientMH.id](),
            target: HM_PrintPatientMH.id
        });

    let historyCm = null;

    const selectedConditionName = props.selectedCondition === null ? '' : props.selectedCondition.condition.name;
    const selectedConditionSeverity = props.selectedCondition === null ? '' : MC_LEVELS[props.selectedCondition.condition.level].text;
    const selectedConditionDescription = props.selectedCondition === null ? '' : props.selectedCondition.condition.description;
    const selectedConditionAction = props.selectedCondition === null ? '' : props.selectedCondition.condition.action;

    const selectedConditionDateMade = props.selectedCondition === null ? '' : props.selectedCondition.created;
    const selectedConditionMadeBy = props.selectedCondition === null ? '' : createdByTemplate(props.selectedCondition.createdBy);
    const selectedConditionNote = props.selectedCondition === null ? '' : props.selectedCondition.description;

    const medConsHeader = <div className='panel-header-centered-content'><label id='panel-header'>Conditions</label>
        <Button tooltip={HM_addPatMedCondition.header}
                tooltipOptions={{position: 'right'}}
                icon={HM_addPatMedCondition.icon}
                onClick={(e) => {
                    props.toolbarCallbacks[HM_addPatMedCondition.id]();
                }}>
        </Button>
    </div>;

    const medConHistoriesHeader = <div className='panel-header-centered-content'><label id='panel-header'>Medical
        Histories</label>
        <Button tooltip={HM_AddPatientMedConHistory.header}
                tooltipOptions={{position: 'right'}}
                icon={HM_AddPatientMedConHistory.icon}
                onClick={(e) => {
                    props.toolbarCallbacks[HM_AddPatientMH.id]();
                }}>
        </Button>
    </div>;

    const detailsProps = {
        onChange: () => {
        },
        target: 'condition',
        condition: {
            name: selectedConditionName,
            severity: selectedConditionSeverity,
            description: selectedConditionDescription,
            action: selectedConditionAction
        }
    };

    const noteProps = {
        onChange: () => {
        },
        target: 'notes',
        notes: {
            dateMade: selectedConditionDateMade,
            madeBy: selectedConditionMadeBy,
            note: selectedConditionNote,
        }
    };

    const entries = props.selectedMedicalHistory === null ? [] : props.selectedMedicalHistory.entries;
    const filteredEntiries = _.filter(entries, entry => entry.condition.includeInMedicalistoryForm);
    const sortedEntries = _.orderBy(filteredEntiries, 'condition.medicalHistoryFormIndex', 'asc');

    const rowClassName = (rowData) => {
        return {'p-mhform-header': rowData.condition.formHeader};
    }

    const histories = _.orderBy(props.patientMHFS, 'id', 'desc');

    const created = props.selectedCondition ? dateTemplate(props.selectedCondition.created) : '';

    return (
        <div>
            <div className="p-grid"
                 style={{fontSize: 'small'}}
            >
                <div className="p-col-4">
                    <Panel header={medConsHeader}>

                        <ContextMenu style={{width: 250}} model={conditionItems} ref={el => conditionCm = el}/>

                        <DataTable value={props.patientMCONS}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={props.conditionRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => {
                                       props.onPage(e, 'firstCondition', 'conditionRows');
                                   }}
                                   first={props.firstCondition}
                                   selection={props.selectedCondition}
                                   onSelectionChange={(e) => props.setValue('selectedCondition', e.value)}
                                   contextMenuSelection={props.selectedCondition}
                                   onContextMenuSelectionChange={(e) => props.setValue('selectedCondition', e.value)}
                                   onContextMenu={e => conditionCm.show(e.originalEvent)}
                        >
                            <Column field="created" body={row => dateTemplateShort(row.created)} header="Added On"
                                    style={{width: '30%'}}/>
                            <Column field="condition.name" header='Condition' style={{width: '70%'}}/>
                        </DataTable>
                    </Panel>
                </div>
                <div className="p-col-4">
                    <Panel header='Details'>
                        <div className="p-grid p-fluid form-group p-col-12"
                             style={{fontSize: 'small'}}
                        >
                            <div className="p-col-4">
                                <label>Name</label>
                            </div>
                            <div className="p-col-8">
                                {inputText(detailsProps, 'name', '', true, false)}
                            </div>
                            <div className="p-col-4">
                                <label>Severity</label>
                            </div>
                            <div className="p-col-8">
                                {inputText(detailsProps, 'severity', '', true, false)}
                            </div>
                            <div className="p-col-12">
                                <label>Description</label>
                            </div>
                            <div className="p-col-12">
                                {inputTextArea(detailsProps, 'description', 4, -1, true, false)}
                            </div>
                            <div className="p-col-12">
                                <label>Action (if any required)</label>
                            </div>
                            <div className="p-col-12">
                                {inputTextArea(detailsProps, 'action', 4, -1, true, false)}
                            </div>
                        </div>
                    </Panel>
                </div>
                <div className="p-col-4">
                    <Panel header='Patient Note'>
                        <div className="p-grid p-fluid form-group p-col-12"
                             style={{fontSize: 'small'}}
                        >
                            <div className="p-col-4">
                                <label>Date Made</label>
                            </div>
                            <div className="p-col-8">
                                <InputText type="text"
                                           disabled={true}
                                           value={created}
                                />
                            </div>
                            <div className="p-col-4">
                                <label>Made By</label>
                            </div>
                            <div className="p-col-8">
                                {inputText(noteProps, 'madeBy', '', true, false)}
                            </div>
                            <div className="p-col-12">
                                {inputTextArea(noteProps, 'note', 14, -1, true, false)}
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
            <div className="p-grid"
                 style={{fontSize: 'small'}}
            >
                <div className="p-col-3">
                    <Panel header={medConHistoriesHeader}>

                        <ContextMenu style={{width: 250}} model={historyItems} ref={el => historyCm = el}/>

                        <DataTable value={histories}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={props.medicalHistoryRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => {
                                       props.onPage(e, 'firstMedicalHistory', 'medicalHistoryRows');
                                   }}
                                   first={props.firstMedicalHistory}
                                   selection={props.selectedMedicalHistory}
                                   onSelectionChange={(e) => props.setValue('selectedMedicalHistory', e.value)}
                                   contextMenuSelection={props.selectedMedicalHistory}
                                   onContextMenuSelectionChange={(e) => props.setValue('selectedMedicalHistory', e.value)}
                                   onContextMenu={e => historyCm.show(e.originalEvent)}
                        >
                            <Column field="createdOn" body={row => dateTemplate(row.createdOn)} header="Added On"
                                    style={{width: '100%'}}/>
                        </DataTable>
                    </Panel>
                </div>
                <div className="p-col-9">
                    <Panel header='Medical History'>
                        <DataTable value={sortedEntries}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   rowClassName={rowClassName}
                        >
                            <Column body={questionEntryTemplate} header="Question" style={{width: '55%'}}/>
                            <Column body={yesEntryTemplate} header="Yes" style={{width: '5%'}}/>
                            <Column body={noEntryTemplate} header="No" style={{width: '5%'}}/>
                            <Column field="comment" header="Comment" style={{width: '35%'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </div>
        </div>
    )
};
