import * as Actions from './index';
import {SM_FORCE_LOGOUT} from "./stateManagement";

export const handleUpdateMessage = (data) => {

    return (dispatch) => {

        // don't propagate the heartbeat message;
        if (data === `{function: "heartbeat"}`)
            return;

        dispatch(
            {
                type: Actions.WSM_UPDATES,
                payload: JSON.parse(data)
            });
    }
};

export const handleAppointmentMessage = (data) => {

    return (dispatch) => {

        // don't propagate the heartbeat message;
        if (data === `{function: "heartbeat"}`)
            return;

        dispatch(
            {
                type: Actions.WSM_APPOINTMENTS,
                payload: JSON.parse(data)
            });
    }
};

export const handleInvoiceMessage = (data) => {

    return (dispatch) => {

        // don't propagate the heartbeat message;
        if (data === `{function: "heartbeat"}`)
            return;

        dispatch(
            {
                type: Actions.WSM_INVOICES,
                payload: JSON.parse(data)
            });
    }
};

export const handleUserMessage = (data) => {

    return (dispatch) => {

        // don't propagate the heartbeat message;
        if (data === `{function: "heartbeat"}`)
            return;

        const payload = JSON.parse(data);

        if (payload.function === SM_FORCE_LOGOUT.id) {

            dispatch({type: Actions.FORCE_LOGOUT});

        } else {
            dispatch(
                {
                    type: Actions.WSM_USERS,
                    payload,
                });
        }
    }
};

export const handleSDPMessage = (data) => {

    return (dispatch) => {

        dispatch(
            {
                type: Actions.WSM_RECEIVE,
                payload: data
            });
    }
};

export const dispatchMessage = (action, data) => {

    return (dispatch) => {

        dispatch(
            {
                type: action,
                payload: data
            });
    }
};
