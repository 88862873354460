import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';
import _ from "lodash";
import {DOC_PAYMENT_PLAN_PROPOSAL_TEMPLATE, HM_CREATE_PAYMENT_PLAN_DOC,} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getResource2, RES_TEMPLATES_PAYMENT_PLANS} from "../../../../actions/housekeeping";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {TB_CREATE_PAYMENT_PLAN_DOC} from "../../PatientDetails/Constants";
import {ScrollPanel} from "primereact/scrollpanel";
import {BaseComponent} from "../../../BaseComponent";

export class ConnectedCreatePaymentPlanDocument extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {

            details: {
                ppId: props.paymentPlan.id,
                templateId: null,
                patientId: props.patientId,
                visibleName: '',
                notes: '',
                createbyId: props.loginIdentity.id,
            },
            selectedTemplate: null,
            first: 0,
            rows: 5,
        };

        this.onChange = this.onChange.bind(this);
        this.onTemplateSelection = this.onTemplateSelection.bind(this);
    }

    componentDidMount() {

        this.props.getTemplates([DOC_PAYMENT_PLAN_PROPOSAL_TEMPLATE.name]);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.messageType !== prevProps.messageType) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onTemplateSelection(event) {

        const visibleName = `${event.value.visibleName} - ${dateTemplate(new Date())}`;
        const details = {...this.state.details};
        details.visibleName = visibleName;
        details.templateId = event.value.docId;

        this.setState({selectedTemplate: event.value, details});
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows});
    }

    onOk() {
    }

    render() {

        if (!this.props.ppTemplatesLoaded) {
            return null;
        }

        const docNotDefined = this.state.selectedTemplate === null || this.state.details.name === '';

        const footer = (
            <div>
                <Button label="Send To Tablet"
                        icon={ICON_OK}
                        onClick={() => {
                        }}
                        disabled={true}/>
                <Button label="OK"
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOKDialog(this.state.details);
                        }}
                        disabled={docNotDefined}/>
                <Button label="Cancel"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(TB_CREATE_PAYMENT_PLAN_DOC.id)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'details',
            details: this.state.details,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={HM_CREATE_PAYMENT_PLAN_DOC.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(TB_CREATE_PAYMENT_PLAN_DOC.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    <DataTable value={this.props.ppTemplates}
                               className='p-datatable-gridlines'
                               header='Templates'
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => {
                                   this.onPageFlex(e, 'first', 'rows');
                               }}
                               first={this.state.first}
                               selection={this.state.selectedTemplate}
                               onSelectionChange={this.onTemplateSelection}
                               style={{fontSize: 'small'}}
                    >

                        <Column field="visibleName" header='Template Name' style={{width: '50%'}}/>
                        <Column field="notes" header='notes' style={{width: '50%'}}/>

                    </DataTable>
                    <Panel header='Document Details' style={{paddingTop: '5px'}}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-3">
                                <label>Document Name</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'visibleName', 'Document Name ...', false, true)}
                            </div>

                            <div className="p-col-3">
                                <label>Notes</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'notes', 5, 100, false, false)}
                            </div>
                        </div>
                    </Panel>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        ppTemplatesLoaded: state.housekeeping.ppTemplatesLoaded,
        ppTemplates: state.housekeeping.ppTemplates,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_PAYMENT_PLANS.GET, types)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const CreatePaymentPlanDocument = connect(mapStateToProps, MapDispatchToProps)(ConnectedCreatePaymentPlanDocument);

export default CreatePaymentPlanDocument;
