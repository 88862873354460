import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from "primereact/inputtext";
import {HM_SMS_CREDIT_LIMIT} from "../../../Constants";
import {ICON_CANCEL} from "../../../icons";
import {getSMSCreditLimit, RES_PATIENT_SMS} from "../../../actions/personal";
import {connect} from "react-redux";
import ReportErrorBoundary from "./Utils";

export class ConnectedSMSCreditLimit extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {

        this.props.getSMSCreditLimit();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    render() {

        if (!this.props.creditLimitLoaded) {
            return null;
        }

        const footer = (
            <div>
                <Button label="Close"
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_SMS_CREDIT_LIMIT.id)
                        }}/>
            </div>
        );

        return (
            <ReportErrorBoundary>
            <Dialog header={HM_SMS_CREDIT_LIMIT.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_SMS_CREDIT_LIMIT.id)
                    }}
            >
                <InputText type="text"
                           style={{fontSize: '30px', textAlign: 'right'}}
                           disabled={true}
                           value={this.props.creditLimit}
                />
            </Dialog>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        creditLimitLoaded: state.patients.creditLimitLoaded,
        creditLimit: state.patients.creditLimit,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        getSMSCreditLimit: () => dispatch(getSMSCreditLimit(RES_PATIENT_SMS.CREDIT_LIMIT)),
    }
};

const SMSCreditLimit = connect(mapStateToProps, MapDispatchToProps)(ConnectedSMSCreditLimit);

export default SMSCreditLimit;
