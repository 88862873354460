import React from "react";
import {showAppointmentText, showConfirmed, showNHSPatient, showWorkRequired} from "./EventComponent";

export class AppointmentComponent extends React.Component {

    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }

    rightCLickHandler = (e) => {
        this.props.onShowRightClick(e, this.props.diary, this.props.event);
    }

    componentDidMount() {

        try {
            this.myRef.current.parentNode.parentNode.parentNode.addEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    render() {

        const {id, assignedToId, attended, source} = this.props.event;

        let colour = '';

        if (attended) {
            colour = '#22ff88';
        }
        if (source.inSurgeryTime !== null) {
            colour = '#ffda22';
        }
        if (source.checkedOutTime !== null) {
            colour = '#22ffff';
        }

        return (
            <div id={id}
                 ref={this.myRef}
            >
                {showWorkRequired(this.props.event)}
                {showConfirmed(this.props.event)}
                {showNHSPatient(this.props.event)}
                <span style={{backgroundColor: colour}}>
                    {showAppointmentText(this.props.event, this.props.privacyOn, this.props.practiceDetails)}
                </span>
            </div>
        )
    }

    componentWillUnmount() {

        try {
            this.myRef.current.parentNode.parentNode.parentNode.removeEventListener('contextmenu', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
