import React from 'react';
import _ from "lodash";
import moment from 'moment';
import {
    APP_CANCELLED,
    APP_CANCELLED_FOLLOWUP,
    APP_CREATED,
    CH_SAVED,
    PP_STATUS_SUSPENDED,
    SystemReason,
    SystemReasonText2,
    UserReasonText2
} from "../../Constants";
import {ICON_ATTENDED, ICON_EXCLAMATION, ICON_NOT_ATTENDED, ICON_PILL, ICON_TREATMENT_PLAN} from "../../icons";
import {outputCurrency} from "./OnChangeUtils";
import {getDateDiffRaw} from "../Utils";
import {
    EDI_CF_Form1_closed,
    EDI_CF_Form1_fitted,
    EDI_CF_Form1_recepited,
    EDI_CR_AssessAndFittedStacked,
    EDI_CR_AssessAndFittedSubmit,
    EDI_CR_AssessAndRefuse,
    EDI_CR_AssessAndReview
} from "../FixedItems/NHSManagaement/Constants";

export const colourTemplate = (item, props) => {

    try {
        const appointmentType = _.find(props.appointmentTypes, type => type.id === item.type);

        const color = `rgb(${appointmentType.red},${appointmentType.green},${appointmentType.blue})`;
        return <div style={{backgroundColor: color}}>&nbsp;</div>
    } catch (error) {
        return <div>&nbsp;</div>
    }

};

export const rowBGColour = (rowData) => {

    if (rowData.principlePayee) {
        return {'bg-blue': true};
    } else if (rowData.billee) {
        return {'bg-green': true}
    } else
        return {'p-white': true};
};

export const typeTemplate = (item, props) => {

    try {
        const appointmentType = _.find(props.appointmentTypes, type => type.id === item.type);
        return appointmentType.name;
    } catch (error) {
        return <div>-</div>
    }

};

export const fullNameUsernameTemplate = (item, props) => {

    const user = _.find(props.usersShort, user => user.id === item.providerId);
    return user ? `${user.fullNameUsername}`.trim() : '';
};

export const psrUsernameTemplate = (item, props, target) => {

    const user = _.find(props.usersShort, user => user.id === item[target]);
    return user ? `${user.username}`.trim() : '-';
};

export const psrUsernameFromIdTemplate = (id, usersShort) => {

    const user = _.find(usersShort, user => user.id === id);
    return user ? `(${user.username})`.trim() : '';
};

export const shorterDateTemplate = (date) => {

    try {
        const _date = moment(date);
        return _date.isValid() ? _date.format('ddd, MMM Do YYYY') : '-';
    } catch (ex) {
        return '-';
    }
};

export const dateTemplate = (date) => {

    try {
        const _date = moment(date);
        return _date.isValid() ? _date.format('dddd, MMMM Do YYYY') : '-';
    } catch (ex) {
        return '-';
    }
};

export const scheduleDateTemplate = (start, open, date) => {

    try {
        if (start) {
            const _date = moment(date);
            return open ? 'Open Start' : _date.isValid() ? _date.format('dddd, MMMM Do YYYY') : '-';
        } else {
            const _date = moment(date);
            return open ? 'Open End' : _date.isValid() ? _date.format('dddd, MMMM Do YYYY') : '-';
        }
    } catch (ex) {
        return '-';
    }
};

export const dateTemplateShort = (date) => {

    try {
        if (date) {
            const _date = moment(date);
            return _date.isValid() ? _date.format('Do MMMM YYYY') : '-';
        } else {
            return '-';
        }
    } catch (ex) {
        return '-';
    }
};

export const commissioningApprovalTemplate = (row) => {

    try {

        let showCommApproval = false;

        switch (row.claimForm) {

            case EDI_CF_Form1_recepited:
            case EDI_CF_Form1_closed:
                switch (row.completionReason) {
                    case EDI_CR_AssessAndRefuse:
                    case EDI_CR_AssessAndReview:
                    case EDI_CR_AssessAndFittedStacked:
                        showCommApproval = true;
                        break;
                    default:
                        break;
                }
                break;
            case EDI_CF_Form1_fitted:
                switch (row.completionReason) {
                    case EDI_CR_AssessAndFittedSubmit:
                        showCommApproval = true;
                        break;
                    default:
                        break;
                }
                break;
        }
        if (showCommApproval) {
            const age = getDateDiffRaw(row.dateOfBirth, row.referralDate);
            const commApprovalRequired = (age[0] >= 18) && moment(row.referralDate).isAfter(new moment('01-04-2019', 'MM-DD-YYYY'))

            return commApprovalRequired ? <i className={ICON_EXCLAMATION}/> : null;
        } else {
            return null;
        }
    } catch (ex) {
        return null;
    }
};

export const dateTemplateShortPP = (row) => {

    try {

        if (row.status === PP_STATUS_SUSPENDED.name) {
            const _date = moment(row.suspendUntil);
            return _date.isValid() ? _date.format('Do MMMM YYYY') : '-';
        } else {
            return '-';
        }
    } catch (ex) {
        return '-';
    }
};

export const dateTemplateMonthYear = (date) => {

    try {
        const _date = moment(date);
        return _date.isValid() ? _date.format('MMMM YYYY') : '-';
    } catch (ex) {
        return '-';
    }
};

export const dateTemplateVeryShort = (date) => {

    try {
        const _date = moment(date);
        return _date.isValid() ? _date.format('YYYY-MM-DD') : '-';
    } catch (ex) {
        return '-';
    }
};

export const patientTemplate = ({title, firstname, lastname}) => {
    return `${title} ${firstname} ${lastname}`.trim();
};

export const attendedTemplate = (row) => {

    return <div style={{fontSize: '17px'}} className={`${row.attended ? ICON_ATTENDED : ICON_NOT_ATTENDED}`}/>
};

export const ageTemplate = (birthDate) => {

    try {
        let a = moment(new Date());
        let b = moment(birthDate);

        let years = a.diff(b, 'year');
        b.add(years, 'years');

        let months = a.diff(b, 'months');
        b.add(months, 'months');

        return `${years} Year(s) ${months} Month(s)`
    } catch (ex) {
        return '-';
    }
};

export const invoiceTemplate = (fee, qty) => {
    return outputCurrency(fee * qty);
};

export const chargeStatusTemplate = (status) => {

    switch (status) {
        case CH_SAVED:
            return "Saved";
        default:
            return "Unknown status";
    }
};

export const placementTemplate = (date) => {

    if (date == null) {
        return 'Not yet placed';
    } else {
        return dateTemplateShort(date);
    }
};

export const ucTPTemplate = (partOfPlan) => {

    return partOfPlan ? <div style={{fontSize: '17px'}} className={ICON_TREATMENT_PLAN}/> : null;
};

export const startTemplate = (date) => {
    return moment(date).format('HH:mm');
};

export const timeTemplate2 = (date) => {
    try {
        const _date = moment(date);

        if (date !== null && _date.isValid()) {
            return moment(date).format('HH:mm');
        } else {
            return date === null ? '-' : date;
        }
    } catch (ex) {
        return '-';
    }
};

export const startTemplate2 = (date) => {
    const t1 = moment.utc(date.slice(0, 16));

    return moment.utc(t1).format('HH:mm');
};

export const durationTemplate = (start, end) => {

    if (start === null || end === null) {
        return '-';
    } else {
        const t1 = moment.utc(start);
        const t2 = moment.utc(end);
        const mDuration = moment.duration(t2.diff(t1));

        return `${mDuration.hours()}:${mDuration.minutes()}`
    }
};

export const durationTemplate2 = (row) => {

    if (row.durationMins === null || row.durationMins === undefined) {
        return '-';
    } else {
        const duration = moment.duration(row.durationMins, 'minutes');
        return moment.utc(duration.asMilliseconds()).format('HH:mm');
    }
};

export const durationConverter = (time) => {

    if (time === null) {
        return {hour: 0, minute: 0};
    } else {
        const startDuration = moment.utc(time).hour(0).minute(0);
        const endDuration = moment.utc(time);
        const mDuration = moment.duration(endDuration.diff(startDuration));

        return {hour: mDuration.hours(), minute: mDuration.minutes()};
    }
};

export const durationConverterTime = ({start, end}) => {

    if (start === null || end === null) {
        return `00:00`;
    } else {
        const t1 = moment.utc(start.slice(0, 16));
        const t2 = moment.utc(end.slice(0, 16));
        const duration = moment.duration(t2 - t1);
        return moment.utc(duration.asMilliseconds()).format('HH:mm');
    }
};

export const durationConverter2 = ({start, end}) => {

    if (start === null || end === null) {
        return {hour: 0, minute: 0};
    } else {
        const t1 = moment.utc(start);
        const t2 = moment.utc(end);
        const duration = moment.duration(t2.diff(t1));

        return {hour: duration.hours(), minute: duration.minutes()};
    }
};

export const referrerTemplate = (row) => {

    if (row) {
        const {firstName, lastName, companyName} = row;
        const _companyName = companyName === null ? '' : companyName.trim();

        const fullname = `${firstName} ${lastName}`.trim();

        return fullname === '' ? _companyName : _companyName.trim() === '' ? fullname : `${fullname} (${_companyName})`;
    } else return '';
};

export const woPatientTemplate = (row) => {

    const {title, firstname, lastname} = row;

    return `${title} ${firstname} ${lastname}`.trim();
};

export const woUserTemplate = (row) => {

    const {byTitle, byFirstname, byLastname} = row;

    return `${byTitle} ${byFirstname} ${byLastname}`.trim();
};

export const baseTreatmentIconTemplate = (treatmentPlan, row) => {

    const entryIndex = _.findIndex(treatmentPlan.unassignedCharges, charge => charge.chartEntry && charge.chartEntry.id === row.id);
    return entryIndex < 0 ? null : <i className={ICON_PILL}/>;
}

export const baseTreatmentTextTemplate = (treatmentPlan, row) => {

    const entryIndex = _.findIndex(treatmentPlan.unassignedCharges, charge => charge.chartEntry && charge.chartEntry.id === row.id);
    return entryIndex < 0 ? row.item.longDescription : treatmentPlan.unassignedCharges[entryIndex].item.description;
}

export const appointmentHistoryTemplate = (row) => {

    const {status, cancellationComment, cancellationReason, shortDescription} = row;

    switch (status) {
        case APP_CANCELLED:
            try {
                const type = cancellationReason.type === SystemReason ? SystemReasonText2 : UserReasonText2;
                return `[${type} : ${cancellationReason.reason}] (${cancellationComment.trim()}) : ${shortDescription}`.trim();
            } catch (error) {
                return '-';
            }
        case APP_CANCELLED_FOLLOWUP:
            return "";
        case APP_CREATED:
            return `${shortDescription}`.trim();
        default:
            return "";
    }
};
