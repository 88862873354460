import _ from 'lodash';
import * as Actions from './index';
import {ac} from "../index";
import {SM_EMAIL_MAIL_SHOT, SM_SMS_MAIL_SHOT} from "./stateManagement";

const EmailBaseURL = '/MailShots/Email';
const SMSBaseURL = '/MailShots/SMS';

export const RES_EMAIL_SHOT = {
    GET: {
        url: `${EmailBaseURL}/mailShots`,
        request: Actions.REQUEST_EMS_HISTORY,
        receive: Actions.RECEIVE_EMS_HISTORY
    },
    GET_BY_ID: {
        url: `${EmailBaseURL}/mailShot`,
        request: Actions.REQUEST_EMS,
        receive: Actions.RECEIVE_EMS
    },
    ADD: {
        url: `${EmailBaseURL}/addMailShot`,
        action: Actions.ADD_EMS,
    },
    SAVE: {
        url: `${EmailBaseURL}/saveMailShot`,
        action: Actions.SAVE_EMS,
    },
    SAVE_CLEAR: {
        url: `${EmailBaseURL}/saveMailShot`,
        action: Actions.SAVE_CLEAR_EMS,
    },
    CLEAR: {action: Actions.CLEAR_EMS},
    SELECT: {
        url: `${EmailBaseURL}/mailShotRecipients`,
        request: Actions.REQUEST_EMS_RECIPIENTS,
        receive: Actions.RECEIVE_EMS_RECIPIENTS
    },
    SEND: {
        url: `${EmailBaseURL}/sendMailShot`,
        request: Actions.REQUEST_SEND_EMS,
        receive: Actions.RECEIVE_SEND_EMS
    },
};

export const RES_SMS_SHOT = {
    GET: {
        url: `${SMSBaseURL}/mailShots`,
        request: Actions.REQUEST_SMS_HISTORY,
        receive: Actions.RECEIVE_SMS_HISTORY
    },
    GET_BY_ID: {
        url: `${SMSBaseURL}/mailShot`,
        request: Actions.REQUEST_SMS,
        receive: Actions.RECEIVE_SMS
    },
    ADD: {
        url: `${SMSBaseURL}/addMailShot`,
        action: Actions.ADD_SMS,
    },
    SAVE: {
        url: `${SMSBaseURL}/saveMailShot`,
        action: Actions.SAVE_SMS,
    },
    SAVE_CLEAR: {
        url: `${SMSBaseURL}/saveMailShot`,
        action: Actions.SAVE_CLEAR_SMS
    },
    CLEAR: {action: Actions.CLEAR_SMS},
    SELECT: {
        url: `${SMSBaseURL}/mailShotRecipients`,
        request: Actions.REQUEST_SMS_RECIPIENTS,
        receive: Actions.RECEIVE_SMS_RECIPIENTS
    },
    SEND: {
        url: `${SMSBaseURL}/sendMailShot`,
        request: Actions.REQUEST_SEND_SMS,
        receive: Actions.RECEIVE_SEND_SMS
    },
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params});
    }
}

export const sendEmailMailShot = ({url, request, receive}, params) => {

    return (dispatch, getState) => {

        const emsShotDataId = `${SM_EMAIL_MAIL_SHOT.id}_${params.msId}`;
        const emsShotData = getState().stateManagement[emsShotDataId]

        const recipients = _.map(emsShotData.data.recipients, recipient => recipient.id);

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}&emsId=${params.msId}&test=false`, recipients)
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params});
    }
}

export const sendEmailMailShotTest = ({url, request, receive}, params) => {

    return (dispatch) => {

        const recipients = [params.to];

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}&emsId=${params.msId}&test=true`, recipients)
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params});
    }
}

export const sendSMSMailShot = ({url, request, receive}, params) => {

    return (dispatch, getState) => {

        const smsShotDataId = `${SM_SMS_MAIL_SHOT.id}_${params.msId}`;
        const smsShotData = getState().stateManagement[smsShotDataId]

        const recipients = _.map(smsShotData.data.recipients, recipient => recipient.id);

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}&smsId=${params.msId}&test=false`, recipients)
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params});
    }
}

export const sendSMSMailShotTest = ({url, request, receive}, params) => {

    return (dispatch) => {

        const recipients = [params.to];

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}&smsId=${params.msId}&test=true`, recipients)
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params});
    }
}
