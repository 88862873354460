import React from "react";
import {SELECTED_ITEM_COLOUR} from "../Constants";
import {COLOR_ChiralPalette} from "../../../Client/Constants";

export class PaletteAppointmentComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menuItem: this.props.menuItem,
            selectedItem: this.props.selectedItem,
        }
        this.myRef = React.createRef();
        this.onClickHandler = this.onClickHandler.bind(this);

        this.paddingStyle = {paddingTop: 5, paddingLeft: 10, paddingRight: 5};
    }

    onClickHandler = () => {
        this.props.onItemSelected(this.state.menuItem);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedItem !== prevProps.selectedItem) {
            this.setState({selectedItem: this.props.selectedItem})
        }
    }

    componentDidMount() {

        try {
            this.myRef.current.addEventListener('click', this.onClickHandler)
        } catch (error) {
            console.log(`Missing parent event node adding listener`);
        }
    }

    render() {

        const {menuItem} = this.state;
        let index = 0;

        let backgroundColor = menuItem.id === this.state.selectedItem ? SELECTED_ITEM_COLOUR : null;

        return (
            <div key={index}
                 style={{fontWeight: 'bold', backgroundColor}}
                 ref={this.myRef}
                 className='diary-palette-component'
            >
                <span className='fas fa-calendar-plus'
                      style={{fontSize: '20px', color: COLOR_ChiralPalette, marginRight: '5px', marginTop: '5px', paddingLeft: 10}}/>
                {menuItem.label}
            </div>
        )
    }

    componentWillUnmount() {

        try {
            this.myRef.current.removeEventListener('click', this.rightCLickHandler)
        } catch (error) {
            console.log(`Missing parent event node removing listener`);
        }
    }
}
