// Template Note sections

import * as icons from "../../../icons";

export const TEMPLATE_NOTES = 0;
export const TEMPLATE_NOTE_FAVS = 1;


export const HM_AddNoteFav = {
    id: 'HM_AddNoteFav',
    label: 'Add Template Note Favourite',
    header: 'Create New Template Note Favourite',
    message: '',
    icon: icons.ICON_PLUS
};
export const HM_EditNoteFav = {
    id: 'HM_EditNoteFav',
    label: 'Edit Template Note Favourite',
    header: 'Edit Template Note Favourite',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteNoteFav = {
    id: 'HM_DeleteNoteFav',
    label: 'Delete Template Note Favourite',
    header: 'Delete Template Note Favourite',
    message: 'Are you sure you want to delete this template note favourite?',
    icon: icons.ICON_DELETE
};
