import React, {Component} from 'react';
import _ from 'lodash';

import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';

import * as TC from './Model/Constants';
import {
    BondedBridgeEP,
    BridgeOnImplantEP,
    CrownOnImplantEP,
    DentureEP,
    FixedBondedOrthodonticRetainerEP,
    ImplantFixtureEP,
    JawUpper,
    TMApicectomy,
    TMRetained,
    TMRootCanal
} from './Model/Constants';
import {Tooth} from "./Tooth";
import {PlaceHolder} from "./PlaceHolder";
import {equals} from "./Parts/ChartingUtils";
import {getChartContextMenuItems} from "./Palettes/BasePalette";
import {CrownOnImplant} from "./Parts/CrownOnImplant";
import {Denture} from "./Parts/Denture";
import {ImplantFixture} from "./Parts/ImplantFixture";
import {DiagBondedBridge} from "./Dialogs/DiagBondedBridge";
import {BondedBridge} from "./Parts/BondedBridge";
import {DiagImplantBridge} from "./Dialogs/DiagImplantBridge";
import {ImplantBridge} from "./Parts/ImplantBridge";
import {FixedBondedOrthoRetainer} from "./Parts/FixedBondedOrthoRetainer";
import {addSurface, getRandomKey, toothEquals} from "./Utils/general";
import {DiagDenture} from "./Dialogs/DiagDenture";
import {DiagFixedRetainer} from "./Dialogs/DiagFixedRetainer";

export default class Mouth extends Component {

    constructor(props) {

        super(props);

        this.state = {
            adultPlaceHolders: [],
            adultTeeth: [],
            adultOthers: [],
            childPlaceHolders: [],
            childTeeth: [],
            childOthers: [],
            implants: [],
            extras: [],
            selectedTooth: null,
            loaded: false,
        };

        this.onShowRightClick = this.onShowRightClick.bind(this);
        this.getToothByOffset = this.getToothByOffset.bind(this);

        this.onPlaceholderClick = this.onPlaceholderClick.bind(this);
        this.updatePlaceHolders = this.updatePlaceHolders.bind(this);
        this.updatePlaceHoldersAndTeeth = this.updatePlaceHoldersAndTeeth.bind(this);

        this.onToothClick = this.onToothClick.bind(this);
        this.setSelectedTooth = this.setSelectedTooth.bind(this);

        this.updateTeeth = this.updateTeeth.bind(this);

        this.onBridgeClick = this.onBridgeClick.bind(this);

        this.showOptionalDialogs = this.showOptionalDialogs.bind(this);

        this.updateExtras = this.updateExtras.bind(this);

        this.currentItem = null;

    };

    componentDidMount() {

        this.initMouth();
    }

    componentDidUpdate(prevProps, ps, ss) {

        const needInit = (this.props.charting !== prevProps.charting) || (this.props.chargesWithCharting !== prevProps.chargesWithCharting);

        if (needInit) {
            this.initMouth();
        }
    }

    getSpan() {

        return [
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
        ];
    }

    reloadCharting = (state) => {

        // if it's a base entry always show.
        // if it's any view and completed treatment entry show

        let entries = _.filter(this.props.charting.entries, entry => entry.baseEntry || (!this.props.baseView && !entry.baseEntry) || (this.props.baseView && !entry.baseEntry && entry.complete));

        const {unassignedCharges} = this.props.parent.state.treatmentPlan ? this.props.parent.state.treatmentPlan : this.props.parent.props.treatmentPlan;
        entries = _.filter(entries, entry => {
            const index = _.findIndex(unassignedCharges, charge => charge.chartEntry && (charge.chartEntry.id === entry.id));
            return entry.complete || index>-1;
        });

        entries.forEach((entry) => {

            // calculate both the tooth and place holder related to this position
            let tooth = this.getTooth(state, entry);
            let ph = this.getPlaceHolder(state, entry);

            switch (entry.item.code) {

                case TC.Apicectomy:
                    if (tooth === undefined)
                        break;
                    tooth.tm = TMApicectomy;
                    // tooth.tm = tooth.tm === TMNone ? TMApicectomy : tooth.tm === TMRootCanal ? TMRootCanalApectomy : TMApicectomy;
                    break;
                case TC.BondedCrown:
                    if (tooth === undefined)
                        break;
                    tooth.bondedCrown = {present: true, material: entry.material};
                    break;
                case TC.BondedBridge: {

                    const span = this.getSpan();

                    for (let index = entry.spanStart; index <= entry.spanEnd; index++) {
                        span[index].active = true;
                        span[index].state = entry.attachments[index];

                        this.getPlaceHolderByOffset(state, entry.jaw, index).partOfSpan = true;

                        if (!span[index].state)
                            _.remove(state.adultTeeth, (tooth) => (tooth.jaw === entry.jaw) && (tooth.pos.offset === index));
                    }

                    state.adultOthers.push({
                        ctype: BondedBridgeEP,
                        material: entry.material,
                        condition: entry.condition,
                        comment: entry.comment,
                        jaw: entry.jaw,
                        start: entry.spanStart,
                        end: entry.spanEnd,
                        span,
                    });
                }
                    break;
                case TC.FixedBondedOrthodonticRetainer: {

                    const span = this.getSpan();

                    for (let index = entry.spanStart; index <= entry.spanEnd; index++) {
                        span[index].active = true;
                        span[index].state = entry.attachments[index];
                    }

                    state.adultOthers.push({
                        ctype: FixedBondedOrthodonticRetainerEP,
                        material: entry.material,
                        condition: entry.condition,
                        comment: entry.comment,
                        jaw: entry.jaw,
                        start: entry.spanStart,
                        end: entry.spanEnd,
                        span,
                    });
                }
                    break;
                case TC.BridgeOnImplant: {

                    const span = this.getSpan();

                    for (let index = entry.spanStart; index <= entry.spanEnd; index++) {
                        span[index].active = true;
                        span[index].state = entry.attachments[index];
                        span[index].vital = entry.viables[index];

                        this.getPlaceHolderByOffset(state, entry.jaw, index).partOfSpan = true;

                        if (!span[index].vital)
                            _.remove(state.adultTeeth, (tooth) => (tooth.jaw === entry.jaw) && (tooth.pos.offset === index));
                    }

                    state.adultOthers.push({
                        ctype: BridgeOnImplantEP,
                        material: entry.material,
                        condition: entry.condition,
                        comment: entry.comment,
                        jaw: entry.jaw,
                        start: entry.spanStart,
                        end: entry.spanEnd,
                        span,
                    });
                }
                    break;
                case TC.Caries:
                    if (tooth === undefined)
                        break;
                    tooth.charting.push(entry);
                    break;
                case TC.ChippedTooth:
                    if (tooth === undefined)
                        break;
                    tooth.chipped = true;
                    break;
                case TC.ClosedGap :
                    ph.drifted = TC.DirClosed;
                    break;
                case TC.CrownOnImplant :
                    if (tooth === undefined)
                        break;
                    state.adultOthers.push({
                        ctype: CrownOnImplantEP,
                        adult: false,
                        jaw: ph.jaw,
                        pos: ph.pos,
                        material: entry.material
                    });
                    _.remove(state.adultTeeth, (tooth) => (tooth.jaw === entry.jaw) && (tooth.pos.perm === entry.position));
                    break;
                case TC.DeciduousTooth :
                    if (tooth === undefined)
                        break;
                    tooth.deciduous = true;
                    break;
                case TC.Denture:

                    const span = this.getSpan();

                    for (let index = entry.spanStart; index <= entry.spanEnd; index++) {
                        span[index].active = true;
                        span[index].state = entry.attachments[index];

                        this.getPlaceHolderByOffset(state, entry.jaw, index).partOfSpan = entry.attachments[index];
                    }

                    state.adultOthers.push({
                        ctype: DentureEP,
                        material: entry.material,
                        condition: entry.condition,
                        comment: entry.comment,
                        jaw: entry.jaw,
                        start: entry.spanStart,
                        end: entry.spanEnd,
                        span,
                    });
                    break;
                case TC.DriftedLeft :
                    ph.drifted = TC.DirLeft;
                    break;
                case TC.DriftedRight :
                    ph.drifted = TC.DirRight;
                    break;
                case TC.FracturedTooth :
                    if (tooth === undefined)
                        break;
                    tooth.fractured = true;
                    break;
                case TC.TemporaryFilling :
                case TC.Filling :
                    if (tooth === undefined)
                        break;
                    tooth.charting.push(entry);
                    break;
                case TC.FullCrown :
                case TC.TemporaryCrown :
                    if (tooth === undefined)
                        break;
                    tooth.fullCrown = {present: true, material: entry.material};
                    break;
                case TC.Impacted :
                    if (tooth === undefined)
                        break;
                    tooth.impacted = true;
                    break;
                case TC.ImplantFixture :
                    state.adultOthers.push({
                        ctype: ImplantFixtureEP,
                        adult: false,
                        jaw: ph.jaw,
                        pos: ph.pos,
                        material: entry.null
                    });
                    _.remove(state.adultTeeth, (tooth) => (tooth.jaw === entry.jaw) && (tooth.pos.perm === entry.position));
                    break;
                case TC.JacketCrown :
                    if (tooth === undefined)
                        break;
                    tooth.jacketCrown = {present: true, material: entry.material};
                    break;
                case TC.MissingTooth:
                    _.remove(state.adultTeeth, (tooth) => (tooth.jaw === entry.jaw) && (tooth.pos.perm === entry.position));
                    break;
                case TC.ExtractTooth:
                    _.remove(state.adultTeeth, (tooth) => (tooth.jaw === entry.jaw) && (tooth.pos.perm === entry.position));
                    break;
                case TC.Onlay :
                    if (tooth === undefined)
                        break;
                    tooth.onlay = {present: true, material: entry.material};
                    break;
                case TC.Inlay :
                    if (tooth === undefined)
                        break;
                    tooth.charting.push(entry);
                    break;
                case TC.OverErupted :
                    if (tooth === undefined)
                        break;
                    tooth.overErupted = true;
                    break;
                case TC.PartiallyErupted :
                    if (tooth === undefined)
                        break;
                    tooth.partiallyErupted = true;
                    break;
                case TC.Post :
                    if (tooth === undefined)
                        break;
                    tooth.post.present = true;
                    break;
                case TC.Post_And_Core :
                    if (tooth === undefined)
                        break;
                    tooth.postAndCore = {present: true, material: entry.material};
                    break;
                case TC.RetainedRoot :
                    if (tooth === undefined)
                        break;
                    tooth.tm = TMRetained;
                    break;
                case TC.RootCanalTreatment :
                    if (tooth === undefined)
                        break;
                    tooth.tm = TMRootCanal;
                    // tooth.tm = tooth.tm === TMNone ? TMRootCanal : tooth.tm === TMApicectomy ? TMRootCanalApectomy : TMRootCanal;
                    break;
                case TC.Unerupted :
                    if (tooth === undefined)
                        break;
                    tooth.unerupted = true;
                    break;
                case TC.Veneer :
                    if (tooth === undefined)
                        break;
                    tooth.veneer = {present: true, material: entry.material};
                    break;
                case TC.Watch :
                    if (tooth === undefined)
                        break;
                    tooth.watch = true;
                    break;
                default :
                    break;
            }
        });
    }

    getChildTeeth() {
        return this.state.childTeeth;
    }

    getChildPlaceholders() {
        return this.state.childPlaceHolders;
    }

    getChildOthers() {
        return this.state.childOthers;
    }

    getAdultTeeth() {
        return this.state.adultTeeth;
    }

    getAdultPlaceholders() {
        return this.state.adultPlaceHolders
    }

    getAdultOthers() {
        return this.state.adultOthers;
    }

    getImplants() {
        return this.state.implants;
    }

    newTooth(mouthData, jaw, toothType, position, adult) {

        const jawIndex = jaw === TC.JawUpper ? 0 : 1;

        if (adult) {
            mouthData.adultPlaceHolders.push({
                ctype: TC.PlaceHolderEP,
                adult: true,
                jaw: jaw,
                type: toothType,
                pos: position,
                present: this.props.charting.adultPresent[jawIndex][position.offset],
                charting: [],
                drifted: null,
                selected: false,
                partOfSpan: false,
            });
            if (this.props.charting.adultPresent[jawIndex][position.offset]) {

                const data = {
                    ctype: TC.ToothEP,
                    adult: true,
                    jaw: jaw,
                    type: toothType,
                    pos: position,
                    present: this.props.charting.adultPresent[jawIndex][position.offset],
                    charting: [],
                    surfaces: null,
                    unerupted: false,
                    partiallyErupted: false,
                    overErupted: false,
                    impacted: false,
                    deciduous: false,
                    fractured: false,
                    chipped: false,
                    watch: false,
                    tm: TC.TMNone,
                    post: {present: false, material: {}},
                    postAndCore: {present: false, material: {}},
                    bondedCrown: {present: false, material: {}},
                    fullCrown: {present: false, material: {}},
                    jacketCrown: {present: false, material: {}},
                    veneer: {present: false, material: {}},
                    onlay: {present: false, material: {}},
                    dontShowInChart: false,
                    selected: false,
                };
                data.surfaces = addSurface(data);
                mouthData.adultTeeth.push(data);
            }
        } else {
            mouthData.childPlaceHolders.push({
                ctype: TC.PlaceHolderEP,
                adult: false,
                jaw: jaw,
                type: toothType,
                pos: position,
                present: this.props.charting.childPresent[jawIndex][position.offset],
                charting: [],
                drifted: null,
                selected: false,
                partOfSpan: false,
            });
            if (this.props.charting.childPresent[jawIndex][position.offset]) {

                const data = {
                    ctype: TC.ToothEP,
                    adult: false,
                    jaw: jaw,
                    type: toothType,
                    pos: position,
                    present: this.props.charting.childPresent[jawIndex][position.offset],
                    charting: [],
                    surfaces: null,
                    unerupted: false,
                    partiallyErupted: false,
                    overErupted: false,
                    impacted: false,
                    deciduous: false,
                    fractured: false,
                    chipped: false,
                    watch: false,
                    tm: TC.TMNone,
                    post: {present: false, material: {}},
                    postAndCore: {present: false, material: {}},
                    bondedCrown: {present: false, material: {}},
                    fullCrown: {present: false, material: {}},
                    jacketCrown: {present: false, material: {}},
                    veneer: {present: false, material: {}},
                    onlay: {present: false, material: {}},
                    dontShowInChart: false,
                    selected: false,
                };
                data.surfaces = addSurface(data);
                mouthData.childTeeth.push(data);
            }
        }
    }

    getTooth(state, entry) {

        return _.find(state.adultTeeth, tooth => tooth.jaw === entry.jaw && tooth.pos.perm === entry.position);
    }

    getToothIndex = (state, entry) => {

        return _.findIndex(state.adultTeeth, tooth => tooth.jaw === entry.jaw && tooth.pos.perm === entry.position);
    }

    // used
    getToothByOffset(state, jaw, offset) {

        if (state)
            return _.find(state.adultTeeth, tooth => tooth.jaw === jaw && tooth.pos.offset === offset);
        else
            return _.find(this.state.adultTeeth, tooth => tooth.jaw === jaw && tooth.pos.offset === offset);
    }

    getToothIndexByOffset(state, jaw, offset) {

        return _.findIndex(state.adultTeeth, tooth => tooth.jaw === jaw && tooth.pos.offset === offset);
    }

    getPlaceHolder(state, entry) {
        return _.find(state.adultPlaceHolders, ph => ph.jaw === entry.jaw && ph.pos.perm === entry.position);
    }

    getPlaceHolderIndex(state, entry) {
        return _.findIndex(state.adultPlaceHolders, ph => ph.jaw === entry.jaw && ph.pos.perm === entry.position);
    }

    getPlaceHolderByOffset(state, jaw, offset) {
        return _.find(state.adultPlaceHolders, ph => ph.jaw === jaw && ph.pos.offset === offset);
    }

    getPlaceHolderIndexByOffset(jaw, offset) {
        return _.find(this.state.adultPlaceHolders, ph => ph.jaw === jaw && ph.pos.offset === offset);
    }

    onShowRightClick(e) {
        this.contextMenu.show(e);
    }

    onPlaceholderClick(placeholder) {

        if (this.props.currentCommand !== null) {
            if (this.props.baseView)
                this.props.currentCommand.executeBase(this, placeholder);
            else
                this.props.currentCommand.executeTreatment(this, placeholder);
        }
    }

    updatePlaceHoldersAndTeeth(adultPlaceHolders, adultTeeth, adultOthers) {

        if (adultOthers !== null)
            this.setState({...this.state, adultPlaceHolders, adultTeeth, adultOthers});
        else
            this.setState({...this.state, adultPlaceHolders, adultTeeth});
    }

    updatePlaceHolders(adultPlaceHolders) {
        this.setState({...this.state, adultPlaceHolders});
    }

    updateExtras(extras) {
        this.setState({...this.state, extras});
    }

    onToothClick(item, tooth) {

        if (this.props.currentCommand !== null) {

            if (this.state.selectedTooth !== tooth) {

                this.setState({selectedTooth: tooth})
            }
            if (this.props.baseView)
                this.props.currentCommand.executeBase(this, item);
            else
                this.props.currentCommand.executeTreatment(this, item);
        }
    }

    setSelectedTooth(tooth, surface) {

        const selectedTooth = {...tooth};

        if (this.state.selectedTooth !== undefined && this.state.selectedTooth !== null && !toothEquals(selectedTooth, this.state.selectedTooth)) {

            const currentSelectedTooth = {...this.state.selectedTooth};

            currentSelectedTooth.surfaces.forEach((surface, index) => {
                surface.selected = false;
            });
            this.setState({currentSelectedTooth});
        }

        if (surface !== undefined) {

            selectedTooth.surfaces.forEach((target, index) => {
                if (surface.type === target.type) {
                    surface.selected = true;
                }
            });
        }
        this.setState({selectedTooth})
    }

    clearSelection() {

        const currentSelectedTooth = {...this.state.selectedTooth};

        currentSelectedTooth.surfaces.forEach((surface, index) => {
            surface.selected = false;
        });
        this.setState({currentSelectedTooth});
    }

    onBridgeClick(bridge) {
    }

    updateTeeth(adultTeeth) {
        this.setState({...this.state, adultTeeth});
        this.props.parent.save = true;
    }

    updateOthers(adultOthers) {
        this.setState({...this.state, adultOthers});
        this.props.parent.save = true;
    }

    isToothPresentAt(tooth) {

        let isPresent = false;

        if (this.props.charting.chart.adultChart)
            isPresent = this.getAdultTeeth()[tooth.jaw][tooth.pos.offset];
        else // this must be corrected when deciduous charting is enabled
            isPresent = this.getAdultTeeth()[tooth.jaw][tooth.pos.offset];

        return isPresent;
    }

    isToothPresentAtIndex(jaw, index) {

        let isPresent = false;
        const jawIndex = jaw === JawUpper ? 0 : 1;

        if (this.props.charting.chart.adultChart)
            isPresent = this.getAdultTeeth()[jawIndex][index];
        else // this must be corrected when deciduous charting is enabled
            isPresent = this.getAdultTeeth()[jawIndex][index];

        return isPresent;
    }

    initMouth = () => {

        const mouthData = {
            adultPlaceHolders: [],
            adultTeeth: [],
            adultOthers: [],
            childPlaceHolders: [],
            childTeeth: [],
            childOthers: []
        };

        // this.newTooth(data, data, TC.JawUpper, TC.ChildMolar, TC.R5, false);
        // this.newTooth(data, TC.JawUpper, TC.ChildMolar, TC.R4, false);
        // this.newTooth(data, TC.JawUpper, TC.AdultIncisor, TC.R3, false);
        // this.newTooth(data, TC.JawUpper, TC.AdultIncisor, TC.R2, false);
        // this.newTooth(data, TC.JawUpper, TC.AdultIncisor, TC.R1, false);
        //
        // this.newTooth(data, TC.JawUpper, TC.AdultIncisor, TC.L1, false);
        // this.newTooth(data, TC.JawUpper, TC.AdultIncisor, TC.L2, false);
        // this.newTooth(data, TC.JawUpper, TC.AdultIncisor, TC.L3, false);
        // this.newTooth(data, TC.JawUpper, TC.ChildMolar, TC.L4, false);
        // this.newTooth(data, TC.JawUpper, TC.ChildMolar, TC.L5, false);
        //
        // // JawLower Child Jaw
        // this.newTooth(data, TC.JawLower, TC.ChildMolar, TC.R5, false);
        // this.newTooth(data, TC.JawLower, TC.ChildMolar, TC.R4, false);
        // this.newTooth(data, TC.JawLower, TC.AdultIncisor, TC.R3, false);
        // this.newTooth(data, TC.JawLower, TC.AdultIncisor, TC.R2, false);
        // this.newTooth(data, TC.JawLower, TC.AdultIncisor, TC.R1, false);
        //
        // this.newTooth(data, TC.JawLower, TC.AdultIncisor, TC.L1, false);
        // this.newTooth(data, TC.JawLower, TC.AdultIncisor, TC.L2, false);
        // this.newTooth(data, TC.JawLower, TC.AdultIncisor, TC.L3, false);
        // this.newTooth(data, TC.JawLower, TC.ChildMolar, TC.L4, false);
        // this.newTooth(data, TC.JawLower, TC.ChildMolar, TC.L5, false);

        // Add the Adult Teeth
        // JawUpper Adult Jaw

        this.newTooth(mouthData, TC.JawUpper, TC.AdultMolar, TC.R8, true);

        this.newTooth(mouthData, TC.JawUpper, TC.AdultMolar, TC.R7, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultMolar, TC.R6, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultPremolar, TC.R5, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultPremolarBicusp, TC.R4, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultIncisor, TC.R3, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultIncisor, TC.R2, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultIncisor, TC.R1, true);

        this.newTooth(mouthData, TC.JawUpper, TC.AdultIncisor, TC.L1, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultIncisor, TC.L2, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultIncisor, TC.L3, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultPremolarBicusp, TC.L4, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultPremolar, TC.L5, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultMolar, TC.L6, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultMolar, TC.L7, true);
        this.newTooth(mouthData, TC.JawUpper, TC.AdultMolar, TC.L8, true);

        // JawLower Adult Jaw

        this.newTooth(mouthData, TC.JawLower, TC.AdultMolar, TC.R8, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultMolar, TC.R7, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultMolar, TC.R6, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultPremolar, TC.R5, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultPremolar, TC.R4, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultIncisor, TC.R3, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultIncisor, TC.R2, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultIncisor, TC.R1, true);

        this.newTooth(mouthData, TC.JawLower, TC.AdultIncisor, TC.L1, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultIncisor, TC.L2, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultIncisor, TC.L3, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultPremolar, TC.L4, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultPremolar, TC.L5, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultMolar, TC.L6, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultMolar, TC.L7, true);
        this.newTooth(mouthData, TC.JawLower, TC.AdultMolar, TC.L8, true);

        const state = {...this.state, ...mouthData, loaded: true};

        this.reloadCharting(state);

        this.setState(state);
    };

    replaceTooth(tooth, others) {

        let adultTeeth = [...this.getAdultTeeth()];

        let adultPlaceHolders = [...this.getAdultPlaceholders()];
        let placeholder = _.find(adultPlaceHolders, (target) => {
            return equals(tooth, target);
        });

        placeholder.present = true;

        if (others != null) {
            this.setState({...this.state, adultPlaceHolders, adultTeeth, adultOthers: others});
        } else {
            this.setState({...this.state, adultPlaceHolders, adultTeeth});
        }
    }

    showOptionalDialogs() {

        if (this.props.currentCommand != null && this.props.currentCommand.showDialog) {
            switch (this.props.currentCommand.toString()) {
                case TC.BondedBridge:
                    return (
                        <DiagBondedBridge data={this.props}
                                          baseView={this.props.baseView}
                                          getToothByOffset={this.getToothByOffset}
                        />
                    );
                case TC.BridgeOnImplant:
                    return (
                        <DiagImplantBridge data={this.props}
                                           baseView={this.props.baseView}
                        />
                    );
                case TC.Denture:
                    return (
                        <DiagDenture data={this.props}
                                     baseView={this.props.baseView}
                                     getToothByOffset={this.getToothByOffset}
                        />
                    );
                case TC.FixedBondedOrthodonticRetainer:
                    return (
                        <DiagFixedRetainer data={this.props}
                                           baseView={this.props.baseView}
                        />
                    );
                default :
                    break;
            }
        }
    }

    setCurrentItem = (item) => {

        this.currentItem = item;
    }

    render() {

        const chartContextMenuItems = getChartContextMenuItems(this);

        // As SVG has no z-index, the render order gives stacking order
        // As a result we must pull out any denture items as they must appear behind the teeth
        // that is rendered before the teeth.

        const dentures = this.state.adultOthers.filter(other => other.ctype === TC.DentureEP);
        const orthoRetainers = this.state.adultOthers.filter(other => other.ctype === TC.FixedBondedOrthodonticRetainerEP);

        const others = this.state.adultOthers.filter(other => (other.ctype !== TC.DentureEP && other.ctype !== TC.FixedBondedOrthodonticRetainerEP));

        const children = [...this.state.adultPlaceHolders, ...this.state.adultTeeth, ...dentures, ...orthoRetainers, ...others];
        const sortedChildren = _.sortBy(children, ['ctype'], ['asc']);

        const bgColour = this.props.baseView ? TC.MouthBaseBGColor : TC.MouthBGColor;

        return (
            <div>

                <ContextMenu style={{width: 225}} model={chartContextMenuItems}
                             ref={(el) => {
                                 this.contextMenu = el
                             }}/>

                <svg key={getRandomKey()} width={TC.MOUTH_WIDTH} height={TC.MOUTH_HEIGHT}>
                    <g key={getRandomKey()} onContextMenu={(event, i) => {
                        event.preventDefault();
                    }}>
                        <rect width={this.props.width} height={this.props.height} style={{fill: bgColour}}/>
                        {sortedChildren.map((child, index) => {

                            child['baseView'] = this.props.baseView;
                            child['currentCommand'] = this.props.currentCommand;

                            switch (child.ctype) {
                                case TC.PlaceHolderEP:
                                    return <PlaceHolder cm={this.onShowRightClick}
                                                        key={index}
                                                        data={child}
                                                        onClick={this.onPlaceholderClick}
                                                        mouth={this}
                                    />;
                                case TC.ToothEP:
                                    return <Tooth cm={this.onShowRightClick}
                                                  toothNumber={index}
                                                  key={index}
                                                  data={child}
                                                  onClick={this.onToothClick}
                                                  mouth={this}
                                    />;
                                case TC.CrownOnImplantEP:
                                    return <CrownOnImplant cm={this.onShowRightClick}
                                                           toothNumber={index}
                                                           key={index}
                                                           data={child}
                                                           onClick={this.onToothClick}
                                                           mouth={this}
                                    />;
                                case TC.ImplantFixtureEP:
                                    return <ImplantFixture cm={this.onShowRightClick}
                                                           toothNumber={index}
                                                           key={index}
                                                           data={child}
                                                           onClick={this.onToothClick}
                                                           mouth={this}
                                    />;
                                case TC.BondedBridgeEP:
                                    return <BondedBridge cm={this.onShowRightClick}
                                                         key={index}
                                                         data={child}
                                                         onClick={this.onBridgeClick}
                                                         mouth={this}
                                    />;
                                case TC.BridgeOnImplantEP:
                                    return <ImplantBridge cm={this.onShowRightClick}
                                                          key={index}
                                                          data={child}
                                                          onClick={this.onBridgeClick}
                                                          mouth={this}
                                    />;
                                case TC.DentureEP:
                                    return <Denture cm={this.onShowRightClick}
                                                    key={index}
                                                    data={child}
                                                    onClick={this.onBridgeClick}
                                                    mouth={this}
                                    />;
                                case TC.FixedBondedOrthodonticRetainerEP:
                                    return <FixedBondedOrthoRetainer cm={this.onShowRightClick}
                                                                     key={index}
                                                                     data={child}
                                                                     onClick={this.onBridgeClick}
                                                                     mouth={this}
                                    />;
                                default:
                                    return null;
                            }
                        })}
                    </g>
                </svg>
                {this.showOptionalDialogs()}
            </div>
        )
    }
}
